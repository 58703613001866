import { useMemo, useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchTokenHoldings,
  getNetworthData,
  getNetworthGraphData,
} from "api/profile.api";
import { QUERY_KEYS } from "utils/query_utils";
import { useHistory } from "react-router-dom";
import { Chart } from "utils/constants";
import { isProfilePage } from "utils/misc";

const useTokenHoldings = ({
  identifier: identifierAnyType,
  maxHoldings = null,
  forProtocolTreasury = false,
  onlyFireSlowPath = false,
  enabled = true,
}) => {
  const queryClient = useQueryClient();
  const [isNetworthCalled, setIsNetworthCalled] = useState(false);
  const identifier = useMemo(
    () => (identifierAnyType ? `${identifierAnyType}` : null),
    [identifierAnyType]
  );
  const history = useHistory();
  const location = history.location;
  const isHomePage = location.pathname?.includes("/home");
  const isProfile = isProfilePage(location.pathname);
  const endpoint = forProtocolTreasury
    ? "/api/v4/get_protocol_token_holdings"
    : "/api/0xppl/get_token_holdings";

  const queryKey = useCallback(
    ({ slowPath }) => {
      return QUERY_KEYS.TOKEN_HOLDINGS({
        identifier,
        slowPath,
        forProtocolTreasury,
      });
    },
    [forProtocolTreasury, identifier]
  );

  const querySlowPath = useQuery({
    queryKey: queryKey({ slowPath: true }),
    queryFn: () =>
      fetchTokenHoldings({
        identifier,
        endpoint,
        maxHoldings: maxHoldings,
        slowPath: true,
      }),
    enabled: enabled && identifier?.length > 0,
    retry: 1,
    retryOnMount: false,
    staleTime: 1000 * 60,
  });
  const queryFastPath = useQuery({
    queryKey: queryKey({ slowPath: false }),
    queryFn: () =>
      fetchTokenHoldings({
        identifier,
        endpoint,
        maxHoldings: maxHoldings ?? null,
        slowPath: false,
      }),
    enabled: enabled && identifier?.length > 0,
    retry: 1,
    retryOnMount: false,
    staleTime: 1000 * 60,
    disabled: onlyFireSlowPath,
  });

  const onSuccess = useCallback(async () => {
    if (isHomePage || isProfile) {
      setIsNetworthCalled(true);
      await queryClient.fetchQuery({
        queryKey: ["networth", identifier],
        queryFn: () => getNetworthData({ identifier }),
      });
      await queryClient.fetchQuery({
        queryKey: QUERY_KEYS.NETWORTH_GRAPH({
          identity: identifier,
          duration: Chart.DURATION["7D"],
        }),
        queryFn: () =>
          getNetworthGraphData({
            duration: Chart.DURATION_DAYS[Chart.DURATION["7D"]],
            identifier,
          }),
      });
    }
  }, [queryClient, identifier, isHomePage, isProfile]);

  const { isSuccess, data } = querySlowPath;
  useEffect(() => {
    if (isSuccess && !isNetworthCalled) {
      onSuccess(data);
    }
  }, [isSuccess, data, onSuccess, isNetworthCalled]);
  if (querySlowPath.data || onlyFireSlowPath) {
    return { ...querySlowPath };
  }
  return { ...queryFastPath };
};

export default useTokenHoldings;
