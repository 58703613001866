import classes from "../../Settings.module.css";
import BasicInfoRowTile from "components/BasicInfoRowTile/BasicInfoRowTile";
import {
  CustomRow,
  Divider,
  InfoBox,
  CustomText,
} from "components/UI/Components/Components";
import TelegramLoginButton from "components/External/TelegramLoginButton";

const Socials = ({ userData }) => {
  return (
    <div className={classes.detailsContainer}>
      <CustomRow
        width="100%"
        justifyContent="space-between"
        alignItems="center">
        <span className={classes.heading}>Socials</span>
      </CustomRow>
      <InfoBox>
        <div className={classes.basicInfoColumn}>
          <BasicInfoRowTile
            title="Twitter"
            value={userData?.identity?.socials?.twitter}
          />
          <BasicInfoRowTile
            title="Telegram"
            children={<TelegramLoginButton />}
          />
          <Divider />
          <CustomText text="Note: Telegram does not share your number with 0xppl" />
        </div>
      </InfoBox>
    </div>
  );
};

export default Socials;
