import classes from "./ProtocolPage.module.css";
import { useParams } from "react-router-dom";
import ProtocolHeader from "./ProtocolHeader/ProtocolHeader";
import ProtocolDetails from "./ProtocolDetails/ProtocolDetails";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const ProtocolPage = ({ scrollableRef }) => {
  const { protocol, active_tab } = useParams();
  return (
    <BaseWrapper mixpanelEvent="ProtocolPage">
      <div className={classes.protocol}>
        <ProtocolHeader protocol={protocol} active_tab={active_tab} />
        <ProtocolDetails
          protocol={protocol}
          active_tab={active_tab}
          scrollableRef={scrollableRef}
        />
      </div>
    </BaseWrapper>
  );
};

export default ProtocolPage;
