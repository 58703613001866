import { useQuery } from "@tanstack/react-query";
import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";

import { fetchGraphData, fetchTimelineResponse } from "api/graph.api";
import {
  computeUrl,
  getRootNodes,
  TIMELINE_HEIGHT,
} from "../utils/explorer_utils";
import { GraphExplorerChildUI, MiniGraph } from "./GraphExplorerChildUI";
import Refetch from "components/UI/Refetch/Refetch";
import { PaddingComponent } from "components/UI/Components/Components";
import FeatureGate from "components/V2/FeatureGate/FeatureGate";
import { GraphExplorerContext } from "../NavigatableExplorer";
import { ONE_DAY } from "utils/constants";
import { getGraphKey } from "utils/misc";
import { getFiltersData } from "api/profile.api";

export const GraphExplorer = ({
  urlData,
  updateUrl,
  queryKey,
  isInitialFromPrefetch,
  mini = false,
  graph_depth_threshold,
  graph_node_count_threshold,
  nodeSize = 16,
}) => {
  const [identifiers, setIdentifiers] = useState(urlData.identifiers);
  const { isContractGraph } = useContext(GraphExplorerContext);
  const [isIdentityGraph, setIsIdentityGraph] = useState(
    urlData.buildIdentityNodes
  );
  const [selectedNode, setSelectedNode] = useState();
  const [selectedLink, setSelectedLink] = useState(null);
  const defaultGraphPath = computeUrl({
    identifiers,
    buildIdentityNodes: true,
    isContractGraph: false,
  });
  const lastPath = useRef(defaultGraphPath);
  const { data: timelineData, isLoading: isTimelineLoading } = useQuery({
    queryKey: ["timeline", identifiers],
    queryFn: () => fetchTimelineResponse({ identifiers }),
    retry: 2,
    retryOnMount: false,
    staleTime: 1000 * 60 * 5,
  });
  useQuery({
    queryKey: ["explorer-chains", identifiers],
    queryFn: () => getFiltersData({ identifiers }),
    enabled: !mini,
    retry: 2,
    staleTime: ONE_DAY,
  });
  const { timelines, sliderStart, sliderEnd } = timelineData ?? {};

  //filters
  const [selectedActiveChains, setSelectedActiveChains] = useState(
    urlData.selectedChains
  );
  const [selectedActiveTokens, setSelectedActiveTokens] = useState(
    urlData.tokenAddresses
  );
  const [tokenTuples, setTokenTuples] = useState(urlData.tokenTuples ?? []);
  const [filterChains, setFilterChains] = useState(urlData.filterChains ?? []);
  const [selectLinkId, setSelectLinkId] = useState(null);

  const [hoveredEdge, setHoveredEdge] = useState(null);

  const [timeRange, setTimeRange] = useState(urlData?.timeRange);

  const [[width, height], setDimensions] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const baseHeight = height - 48 * 3;
  const explorerHeight =
    timelines?.length > 0 ? baseHeight - 10 : baseHeight + TIMELINE_HEIGHT;
  useLayoutEffect(() => {
    const updateDimensions = () => {
      setDimensions([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  let startTime = "";
  let endTime = "";
  if (timeRange?.length) {
    startTime = timeRange[0];
    endTime = timeRange[1];
  }

  const handleChange = useCallback(
    (event, newValue) => {
      const [gotStart, gotEnd] = newValue ?? [null, null];
      if (
        gotStart == null ||
        gotEnd == null ||
        sliderStart == null ||
        sliderEnd == null
      ) {
        return;
      }
      let startToSet = gotStart,
        endToSet = gotEnd;
      // If gotStart is within 1% of expectedStart, set it to expectedStart
      if (Math.abs(gotStart - sliderStart) < 0.01 * (sliderEnd - sliderStart)) {
        startToSet = sliderStart;
      }
      // If gotEnd is within 1% of expectedEnd, set it to expectedEnd
      if (Math.abs(sliderEnd - gotEnd) < 0.01 * (sliderEnd - sliderStart)) {
        endToSet = sliderEnd;
      }
      setTimeRange([startToSet, endToSet]);
    },
    [sliderStart, sliderEnd]
  );

  const frontendShareablePath = useMemo(
    () =>
      computeUrl({
        identifiers,
        selectedActiveTokens,
        selectedActiveChains,
        tokenTuples,
        filterChains,
        timeRange,
        buildIdentityNodes: isIdentityGraph,
        isContractGraph,
        sliderTimeRange: [sliderStart, sliderEnd],
        graph_node_count_threshold,
        graph_depth_threshold,
      }),
    [
      identifiers,
      selectedActiveTokens,
      selectedActiveChains,
      timeRange,
      isIdentityGraph,
      isContractGraph,
      sliderStart,
      sliderEnd,
      graph_node_count_threshold,
      graph_depth_threshold,
      tokenTuples,
      filterChains,
    ]
  );
  const {
    data: chainData,
    isError: isErrorInGraph,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: getGraphKey({
      identifiers,
      isContracts: isContractGraph,
      isIdentityGraph,
      tokens: selectedActiveTokens,
      chains: selectedActiveChains,
      isMini: mini,
    }),
    queryFn: ({ signal }) =>
      fetchGraphData(frontendShareablePath, signal, tokenTuples, filterChains),
    retry: 2,
    retryOnMount: false,
    staleTime: ONE_DAY,
    // enabled: false,
  });
  const getUpdatedIdentifiers = useCallback(() => {
    const rootNodes = getRootNodes(chainData?.data?.graph?.nodes);
    if (!rootNodes) return null;
    const newIdentifiers = rootNodes?.map((node) => node.id);
    newIdentifiers.sort();
    return newIdentifiers.join(",");
  }, [chainData]);

  useEffect(() => {
    const modifiedIdentifiers = getUpdatedIdentifiers() || identifiers;
    updateUrl?.({
      identifiers: modifiedIdentifiers,
      selectedActiveTokens,
      selectedActiveChains,
      tokenTuples,
      filterChains,
      timeRange,
      buildIdentityNodes: isIdentityGraph,
      isContractGraph,
      sliderTimeRange: [sliderStart, sliderEnd],
    });
  }, [
    updateUrl,
    chainData,
    identifiers,
    getUpdatedIdentifiers,
    selectedActiveTokens,
    selectedActiveChains,
    tokenTuples,
    filterChains,
    setTokenTuples,
    setFilterChains,
    timeRange,
    isIdentityGraph,
    sliderStart,
    isContractGraph,
    sliderEnd,
  ]);

  const resetSelectedNode = useCallback((chainData_, nodeOnly) => {
    const rootNodes = getRootNodes(chainData_?.data?.graph?.nodes);
    // NOTE: We shouldn't always draw an edge when there are 2 root nodes
    if (rootNodes.length === 2 && !nodeOnly) {
      const [first, second] = rootNodes;
      const link = chainData_?.data?.graph?.links.find((link) => {
        if (link.source === first.id && link.target === second.id) {
          return true;
        }
        if (link.source === second.id && link.target === first.id) {
          return true;
        }
        return false;
      });

      if (link) {
        setSelectedNode(null);
        setSelectLinkId({
          source: link.source,
          target: link.target,
        });
      } else {
        setSelectedNode(rootNodes[0]);
        setSelectedLink(null);
      }
    } else {
      setSelectedNode(rootNodes[0]);
      setSelectedLink(null);
    }
  }, []);

  const fetchGraph = useCallback(() => {
    resetSelectedNode(chainData, true);
    refetch();
  }, [chainData, refetch, resetSelectedNode]); // Post fetchGraph, reset selected node per new data
  useEffect(() => {
    if (chainData) {
      resetSelectedNode(chainData, false);
    }
  }, [chainData, resetSelectedNode]);

  const onTimeRangeCommited = useCallback(
    (event, newValue) => {
      fetchGraph();
    },
    [fetchGraph]
  );

  const pickTs = (sliderTime, edgeTime) =>
    sliderTime === edgeTime || edgeTime === null ? null : sliderTime;
  useEffect(() => {
    if (lastPath.current !== frontendShareablePath) {
      fetchGraph();
      lastPath.current = frontendShareablePath;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frontendShareablePath]);

  useEffect(() => {
    if (timeRange?.length)
      setTimeRange(([prevStart, prevEnd]) => {
        const start = prevStart ?? sliderStart;
        const end = prevEnd ?? sliderEnd;
        return [start, end];
      });
  }, [sliderStart, sliderEnd, timeRange?.length]);

  if (isErrorInGraph) {
    return (
      <>
        <PaddingComponent height="16px" />
        <PaddingComponent padding="128px 0">
          <Refetch onClick={() => refetch()} />
        </PaddingComponent>
      </>
    );
  }

  if (mini) {
    return (
      <MiniGraph
        mini
        isFetching={isFetching}
        isLoading={isLoading}
        width={240}
        height={164}
        data={chainData?.data?.graph}
        nodeSize={nodeSize}
      />
    );
  }
  return (
    <FeatureGate featureName="explorer">
      <GraphExplorerChildUI
        getUpdatedIdentifiers={getUpdatedIdentifiers}
        setIdentifiers={setIdentifiers}
        identifiers={identifiers}
        isIdentityGraph={isIdentityGraph}
        setIsIdentityGraph={setIsIdentityGraph}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        selectedLink={selectedLink}
        setSelectedLink={setSelectedLink}
        selectLinkId={selectLinkId}
        setSelectLinkId={setSelectLinkId}
        hoveredEdge={hoveredEdge}
        setHoveredEdge={setHoveredEdge}
        chainData={chainData}
        isLoading={isLoading}
        isFetching={isFetching}
        fetchGraph={fetchGraph}
        timelines={timelines}
        sliderStart={sliderStart}
        sliderEnd={sliderEnd}
        onTimeRangeCommited={onTimeRangeCommited}
        handleChange={handleChange}
        timeRange={timeRange}
        width={width}
        height={height}
        explorerHeight={explorerHeight}
        selectedActiveChains={selectedActiveChains}
        setSelectedActiveChains={setSelectedActiveChains}
        selectedActiveTokens={selectedActiveTokens}
        setSelectedActiveTokens={setSelectedActiveTokens}
        tokenTuples={tokenTuples}
        setTokenTuples={setTokenTuples}
        filterChains={filterChains}
        setFilterChains={setFilterChains}
        isTimelineLoading={isTimelineLoading}
        startTime={startTime}
        endTime={endTime}
        pickTs={pickTs}
        nodeSize={nodeSize}
      />
    </FeatureGate>
  );
};
