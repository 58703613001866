import {
  ChartLineUp,
  EnvelopeSimple,
  Trophy,
  UsersFour,
} from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { signedRequest } from "api/api";
import { getInviteDetails, getProfileData } from "api/profile.api";
import InviteCodesAvailable from "assets/svg/invite-codes-available.svg";
import NoInviteCodes from "assets/svg/no-invite-codes.svg";
import withLoader from "components/core/withLoader";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import buttonClasses from "components/UI/Buttons/buttons.module.css";
import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
  CustomText,
  Divider,
  FlexGrow,
  InfoBox,
  InfoBoxContainer,
  PaddingComponent,
  RightPanelCrossIcon,
  VerticalDivider,
} from "components/UI/Components/Components";
import UserAvatar from "components/UserAvatar/UserAvatar";
import Copyable from "components/ValueType/Copyable/Copyable";
import { AuthContext } from "contextStates/AuthContext";
import { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import { trackEvent } from "utils/event_tracking";
import { getFormattedMonthNameDate } from "utils/profile_utils";
import InviteGraph from "./InviteGraph";
import classes from "./ReferFriends.module.css";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { Info } from "@phosphor-icons/react";
import { getCssVariable } from "utils/misc";
import { CheckCircle, Sparkle } from "@phosphor-icons/react";
import GenericButton from "../GenericButton/GenericButton";
import { CircularProgress } from "../../../../node_modules/@mui/material/index";
import { useMutation } from "@tanstack/react-query";
import { GlobalContext } from "contextStates/Global";
import usePageTitle from "customHooks/usePageTitle";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import { Copy } from "@phosphor-icons/react";
const claimInviteCodes = async () => {
  // Code for claiming invite codes
  await signedRequest({
    path: "/api/access_control/claim_invite_codes",
    method: "post",
  });
};
let timer;
const ClaimInviteCodesBanner = ({ onSuccess, codeCount, onFinish }) => {
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const [isDone, setIsDone] = useState(false);
  const { mutate: claim, isPending: isLoading } = useMutation({
    mutationFn: claimInviteCodes,
    onSuccess: () => {
      setIsDone(true);
      onSuccess();
    },
    onError: () => {
      handleErrorSnackbar(
        null,
        "Error claiming invite codes, please try again."
      );
    },
  });
  useEffect(() => {
    if (isDone) {
      timer = setTimeout(() => {
        onFinish();
      }, 6000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isDone, onFinish]);
  return (
    <PaddingComponent padding="12px 16px" className={classes.claimBanner}>
      <CustomRow alignItems="center" justifyContent="space-between">
        <CustomRow alignItems="center" gap="8px">
          <Sparkle size={20} weight="fill" color="var(--warning)" />
          <p>
            You have <strong>{codeCount}</strong> unclaimed invite codes. Invite
            your degen friends to 0xPPL!
          </p>
        </CustomRow>
        {!isDone ? (
          <GenericButton
            background="var(--text-1)"
            color="var(--base)"
            borderRadius="16px"
            disabled={isLoading}
            customClass={classes.claimButton}
            onClick={claim}>
            {!isLoading ? (
              "Claim Now!"
            ) : (
              <CustomRow alignItems="center" gap="4px">
                <span>Claiming</span>{" "}
                <CircularProgress size={16} color="inherit" />
              </CustomRow>
            )}
          </GenericButton>
        ) : (
          <CheckCircle size={32} color="var(--success)" weight="fill" />
        )}
      </CustomRow>
    </PaddingComponent>
  );
};

const ReferFriends = ({ queryData, refetch }) => {
  const {
    invite_codes,
    invited_users_cnt,
    networth_of_invited_users,
    referral_rank,
    unclaimed_invite_codes_count = 0,
  } = queryData ?? {};
  const [hasUnclaimedInviteCodes, setHasUnclaimedInviteCodes] = useState(
    unclaimed_invite_codes_count > 0
  );
  const [isInvitePanelOpen, setIsInvitePanelOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const { identityDetails } = useContext(AuthContext);
  const { identifier } = useParams();
  const { data: profileData } = useQuery({
    queryKey: ["profile", identifier],
    queryFn: () => getProfileData({ identifier }),
    retry: 0,
    enabled: !!identifier,
  });
  usePageTitle({ title: "Refer Friends - 0xPPL" });
  const inviteCodeTimestamps = queryData?.invite_code_shared_ts ?? null;
  const inviteCode = queryData?.invite_codes?.[0] ?? null;
  const copyInviteCode = () => {
    try {
      navigator.clipboard.writeText(`https://0xppl.com/gm?code=${inviteCode}`);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <BaseWrapper mixpanelEvent="ReferFriends">
      <>
        <PaddingComponent padding="16px 16px 8px">
          <CustomText
            text={identifier ? "Referral Details" : "Refer Friends"}
            fontSize="15px"
            color="var(--text-1)"
            fontWeight="600"
          />
          {profileData && (
            <PaddingComponent padding="16px 0">
              <UserAvatar actor={profileData?.data?.data?.details} />
            </PaddingComponent>
          )}
        </PaddingComponent>
        {!identifier && hasUnclaimedInviteCodes && (
          <ClaimInviteCodesBanner
            codeCount={unclaimed_invite_codes_count}
            onSuccess={() => {
              refetch();
            }}
            onFinish={() => {
              setHasUnclaimedInviteCodes(false);
            }}
          />
        )}
        <Divider />

        <PaddingComponent height="16px" />
        {!identifier && (
          <PaddingComponent paddingBottom="16px">
            <InfoBox padding="0">
              <CustomRow gap="16px">
                <CustomColumn
                  flexShrink={0}
                  alignItems="flex-start"
                  justifyContent="flex-start">
                  <div className={classes.invite}>
                    <EnvelopeSimple
                      size={24}
                      weight="duotone"
                      color="var(--text-1)"
                    />
                    <div className={classes.inviteCounter}>
                      {invite_codes.length}
                    </div>
                  </div>
                </CustomColumn>
                <CustomColumn
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="8px"
                  padding="16px 0">
                  <CustomText
                    text="Send an invite to your friends"
                    textAlign="left"
                    fontSize="17px"
                    color="var(--text-1)"
                    fontWeight="600"
                  />
                  <CustomText
                    textAlign="left"
                    text="Use this link to invite awesome people to 0xPPL. Trusting you to bring great folks to our app."
                    fontSize="13px"
                  />
                  {inviteCode && (
                    <button
                      onClick={() => {
                        copyInviteCode();
                        trackEvent("view_invite_codes");
                      }}
                      className={`${buttonClasses.primaryButton} ${copied ? classes.copied : ""} ${classes.copyBtn}`}>
                      <ZxFlex>
                        {copied ? "Copied" : "Copy code"}
                        {copied ? <CheckCircle weight="fill" /> : <Copy />}
                      </ZxFlex>
                    </button>
                  )}
                </CustomColumn>
                <CustomColumn flexShrink={0}>
                  <img
                    alt="Invite codes"
                    src={
                      invite_codes?.length > 0
                        ? InviteCodesAvailable
                        : NoInviteCodes
                    }
                    width={150}
                  />
                </CustomColumn>
              </CustomRow>
            </InfoBox>
          </PaddingComponent>
        )}
        <PaddingComponent padding="0 16px">
          <CustomText
            text="Invite Summary"
            fontSize="15px"
            color="var(--text-1)"
            fontWeight="600"
          />
          <div className={classes.summary}>
            <CustomRow justifyContent="space-between">
              <CustomColumn>
                <CustomRow alignItems="center" justifyContent="space-between">
                  <UsersFour
                    size={32}
                    weight="duotone"
                    color="var(--text-2)"></UsersFour>
                  <CustomText
                    text={invited_users_cnt}
                    fontSize="36px"
                    fontWeight="bold"
                    color="var(--text-1)"
                  />
                </CustomRow>
                <CustomText
                  text="INVITED MEMBERS"
                  letterSpacing="0.05em"
                  fontWeight="500"
                  fontSize="12px"
                />
              </CustomColumn>
              <VerticalDivider />
              <CustomColumn>
                <CustomRow alignItems="center" justifyContent="space-between">
                  <ChartLineUp
                    size={32}
                    weight="duotone"
                    color="var(--text-2)"></ChartLineUp>
                  <CustomText
                    text={networth_of_invited_users?.display_value}
                    fontSize="36px"
                    fontWeight="bold"
                    color="var(--text-1)"
                  />
                </CustomRow>
                <CustomText
                  text="TOTAL NETWORTH OF INVITED"
                  letterSpacing="0.05em"
                  fontWeight="500"
                  fontSize="12px"
                />
              </CustomColumn>
              <VerticalDivider />
              <CustomColumn>
                <CustomRow alignItems="center" justifyContent="space-between">
                  <Trophy
                    size={32}
                    weight="duotone"
                    color="var(--text-2)"></Trophy>
                  <CustomText
                    text={referral_rank || "-"}
                    fontSize="36px"
                    fontWeight="bold"
                    color="var(--text-1)"
                  />
                </CustomRow>
                <CustomRow gap="4px" alignItems="center">
                  <CustomText
                    text="REFERRAL RANK"
                    letterSpacing="0.05em"
                    fontWeight="500"
                    fontSize="12px"
                  />
                  <PPLxTooltip
                    title={
                      <span className={classes.rankInfo}>
                        Your rank is influenced by the verified net worth,
                        referrals, and Twitter activity of those you bring in.
                        Use your codes wisely!
                      </span>
                    }
                    placement="top"
                    arrow>
                    <CustomColumn
                      className={classes.tooltipIcon}
                      justifyContent="center">
                      <Info size={16} color={getCssVariable("--text-2")} />
                    </CustomColumn>
                  </PPLxTooltip>
                </CustomRow>
              </CustomColumn>
            </CustomRow>
          </div>
        </PaddingComponent>
        <PaddingComponent padding="0 16px 16px">
          <CustomText
            text="Invite Growth Chart"
            fontSize="15px"
            color="var(--text-1)"
            fontWeight="600"
          />
          <InviteGraph
            identifier={identifier ?? identityDetails?.current?.identity?.id}
            inviteCodes={!identifier ? invite_codes : null}
            openInviteCodesPanel={() => {
              if (!identifier) setIsInvitePanelOpen(true);
            }}
          />
        </PaddingComponent>
        {createPortal(
          <RightPanel
            onClose={() => {
              setIsInvitePanelOpen(false);
            }}>
            {isInvitePanelOpen && (
              <InviteCodePanel
                inviteCodes={invite_codes}
                onClose={() => {
                  setIsInvitePanelOpen(false);
                }}
                inviteCodeTimestamps={inviteCodeTimestamps}
                refetch={refetch}
              />
            )}
          </RightPanel>,
          document.getElementById("rightPanel")
        )}
      </>
    </BaseWrapper>
  );
};

export const ReferFriendsShimmer = () => {
  return (
    <CustomColumn>
      <PaddingComponent padding="16px">
        <CustomSkeleton height={"180px"} />
      </PaddingComponent>
      <PaddingComponent padding="8px">
        <CustomSkeleton height={"100px"} />
      </PaddingComponent>
    </CustomColumn>
  );
};

const InviteCodePanel = ({
  onClose,
  inviteCodes,
  inviteCodeTimestamps,
  refetch,
}) => {
  const onCodeCopy = async (code) => {
    try {
      await signedRequest({
        path: "/api/access_control/track_invite_code_shared_ts",
        method: "post",
        bodyText: JSON.stringify({
          code: code,
        }),
      });
      refetch();
    } catch (er) {
      return;
    }
  };
  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <RightPanelCrossIcon onClick={onClose}></RightPanelCrossIcon>
        <span
          className={
            classes.title
          }>{`Invite Codes (${inviteCodes?.length})`}</span>
        <span className={classes.subtitle}>
          Share each invite code to get your friends on 0xPPL
        </span>
      </div>
      <div>
        <PaddingComponent padding="16px 20px">
          <InfoBoxContainer
            header={
              <CustomRow>
                <FlexGrow>
                  <CustomText
                    text="INVITE CODE"
                    fontSize="11px"
                    fontWeight="500"
                    color="var(--text-2)"></CustomText>
                </FlexGrow>
                <div>
                  <CustomText
                    text="INVITE LINK"
                    fontSize="11px"
                    fontWeight="500"
                    color="var(--text-2)"></CustomText>
                </div>
              </CustomRow>
            }>
            {inviteCodes?.map((code) => {
              const timestamp = inviteCodeTimestamps
                ? inviteCodeTimestamps[code] ?? null
                : null;
              return (
                <CustomRow padding="8px 0">
                  <CustomColumn flexGrow={1} alignItems="flex-start">
                    <CustomText
                      fontSize="17px"
                      text={code}
                      color="var(--text-1)"
                    />
                    {timestamp && (
                      <CustomText
                        fontSize="11px"
                        text={`You copied this invite on ${getFormattedMonthNameDate(
                          { date: timestamp, showTime: true }
                        )}`}
                      />
                    )}
                  </CustomColumn>
                  <Copyable
                    content={"https://0xppl.com/gm?code=" + code}
                    disableHoverEffects
                    copyIcon={
                      <div
                        className={`${buttonClasses.cancelButton} ${buttonClasses.roundedSmall}`}
                        onClick={() => onCodeCopy(code)}>
                        Copy
                      </div>
                    }
                  />
                </CustomRow>
              );
            })}
          </InfoBoxContainer>
        </PaddingComponent>
      </div>
    </div>
  );
};

export default withLoader(ReferFriends, getInviteDetails, {
  queryKey: (props) => ["invite-details", props.identifier],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 0,
  renderNotFound: false,
  CustomLoader: ReferFriendsShimmer,
});
