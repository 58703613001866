import { MyLocationOutlined } from "@mui/icons-material";
import {
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
} from "@phosphor-icons/react";
import { ThemeContext } from "contextStates/Theme.context";
import { dark, light } from "customHooks/useTheme";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ForceGraph2D } from "react-force-graph";
import { abbreviateNumber } from "utils/misc";
import GraphLoader from "../Profile/ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";
import classes from "./UniversalGraph.module.css";

function evenlySpaceElements(n) {
  const placements = [];

  if (n === 1) {
    placements.push(0.5);
  } else {
    const interval = 1 / (n + 1);

    for (let i = 1; i <= n; i++) {
      placements.push(i * interval);
    }
  }

  return placements;
}

const EDGE_ANGLE = 0.2;

function getCurve(n, i = 0) {
  const placements = evenlySpaceElements(n);

  return placements[i] * EDGE_ANGLE - EDGE_ANGLE / 2;
}

const getKey = (string1, string2) => {
  return [`${string1}`, `${string2}`]
    .sort((a, b) => a.localeCompare(b))
    .join("->");
};

export const UniversalGraph = ({
  graph,
  metadata,
  height = 480,
  width = 480,
  mini = false,
  setSelectedLink,
  setSelectedNode,
  selectedNode,
  selectedLink,
  selectLink,
  hoveredEdge,
  nodeSize,
}) => {
  const images = graph?.nodes
    .map((node) => {
      return node.data.details?.display_picture;
    })
    .filter((value) => value != null);
  const linkRotation = {};

  for (let i = 0; i < graph?.links.length; i++) {
    const link = graph?.links[i];
    const { source, target } = link;
    let key;
    if (source.id && target.id) {
      key = getKey(source.id, target.id);
    } else {
      key = getKey(source, target);
    }

    if (!linkRotation[key]) {
      linkRotation[key] = 1;
      link.curveIndex = 0;
    } else {
      linkRotation[key] += 1;
      link.curveIndex = linkRotation[key] - 1;
    }
  }

  for (let i = 0; i < graph?.links.length; i++) {
    const link = graph?.links[i];
    const { source, target } = link;
    let key;
    if (source.id && target.id) {
      key = getKey(source.id, target.id);
    } else {
      key = getKey(source, target);
    }

    const curve = getCurve(linkRotation[key], link.curveIndex);
    link.curve = curve;
  }

  // Graph Attributes
  // const stringStrength = 10;
  // const forceCollide = 50;
  // const forceManyBody = 0;

  // useEffect(() => {
  //   fg.current.d3Force("link").strength((link) => stringStrength);
  //   fg.current.d3Force("collide", d3Ref.current.forceCollide(forceCollide));

  //   fg.current.d3Force(
  //     "charge",
  //     d3Ref.current.forceManyBody().strength(forceManyBody)
  //   );

  //   fg.current.d3Force(
  //     "center",
  //     d3Ref.current.forceCenter(width / 2, height / 2)
  //   );
  // }, [fg, d3Ref, forceManyBody]);

  // useEffect(async () => {
  //   const images = {};
  //   graph.nodes.forEach(async (node) => {
  //     const img = new Image();
  //     img.src = node.data.address_profile?.display_picture;

  //     await new Promise((resolve, reject) => {
  //       img.onload = () => {
  //         resolve();
  //       };
  //       img.onerror = (error) => {
  //         reject(error);
  //       };
  //     });

  //     images[node.id] = img;
  //   });
  //   setImages(images);
  // }, [graph.nodes]);
  return (
    <div>
      {/* <LeftPanelList
        graph={graph}
        selectedNode={selectedNode}
        selectedLink={selectedLink}
        onRowClick={(node) => {
          setSelectedNode(node);
          setSelectedLink(null);
        }}
      />
      <VerticalSplitDiv> */}
      <PreloadImages
        images={images}
        graph={graph}
        height={height}
        width={width}
        setSelectedLink={setSelectedLink}
        setSelectedNode={setSelectedNode}
        selectedNode={selectedNode}
        selectedLink={selectedLink}
        selectLink={selectLink}
        hoveredEdge={hoveredEdge}
        mini={mini}
        nodeSize={nodeSize}
      />

      {/* <FixedWidthDiv>
          {selectedNode && (
            <SelectedNode node={selectedNode} metadata={graph.metadata} />
          )}

          {selectedLink && (
            <SelectedEdge metadata={graph.metadata} link={selectedLink} />
          )}
        </FixedWidthDiv>
      </VerticalSplitDiv> */}
    </div>
  );
};

const PreloadImages = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState({});
  const { images } = props;
  useEffect(() => {
    const imagePromises = images?.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve({ src, img });
        img.src = src;
        setTimeout(() => reject(new Error("Timeout")), 1000);
      });
    });

    Promise.allSettled(imagePromises)
      .then((results) => {
        const imagesMap = results
          .filter((result) => {
            return result.status === "fulfilled";
          })
          .reduce((acc, value) => {
            const { src, img } = value.value;
            return { ...acc, [src]: img };
          }, {});
        setLoadedImages(imagesMap);
        setIsLoading(false);
      })
      .catch((error) => {
        setLoadedImages({});
        setIsLoading(false);
      });
  }, [images]);
  return isLoading ? (
    <GraphLoader width={props.width} height={props.height} />
  ) : (
    <Graph
      nodeSize={props.nodeSize}
      mini={props.mini}
      {...props}
      images={loadedImages}
    />
  );
};

const isLinked = (id1, id2, links) => {
  for (let i = 0; i < links.length; i++) {
    if (links[i].source.id === id1 && links[i].target.id === id2) {
      return true;
    }
    if (links[i].source.id === id2 && links[i].target.id === id1) {
      return true;
    }
  }

  return false;
};

const nodeScale = (node, selectedNode, selectedLink, links, hoveredEdge) => {
  if (selectedNode && selectedNode.id === node.id) {
    return 1.25;
  }
  if (selectedLink && selectedLink.source.id === node.id) {
    return 1;
  }
  if (selectedLink && selectedLink.target.id === node.id) {
    return 1;
  }
  if (hoveredEdge && isHoveredNode(node, hoveredEdge)) {
    return 1;
  }

  if (selectedNode && links && isLinked(node.id, selectedNode.id, links)) {
    return 0.75;
  }

  if (
    links &&
    selectedLink &&
    isLinked(node.id, selectedLink.target.id, links)
  ) {
    return 0.75;
  }
  if (
    links &&
    selectedLink &&
    isLinked(node.id, selectedLink.source.id, links)
  ) {
    return 0.75;
  }

  return 0.5;
};

const isAddressPartOfProfile = (address, profile) => {
  if (profile == null) {
    return false;
  }
  if (profile.type === "address") {
    return profile.details.address === address;
  } else {
    return profile.details.bundle?.address_infos
      .map((info) => info.address)
      .includes(address);
  }
};

const isHoveredEdge = (link, hoveredEdge) => {
  const { source, target } = link;
  const { from_address, to_address } = hoveredEdge;
  const { data: sourceData } = source;
  const { data: targetData } = target;
  return (
    (isAddressPartOfProfile(from_address, sourceData) &&
      isAddressPartOfProfile(to_address, targetData)) ||
    (isAddressPartOfProfile(from_address, targetData) &&
      isAddressPartOfProfile(to_address, sourceData))
  );
};

const isHoveredNode = (node, hoveredEdge) => {
  return (
    isAddressPartOfProfile(hoveredEdge.from_address, node.data) ||
    isAddressPartOfProfile(hoveredEdge.to_address, node.data)
  );
};

const Graph = ({
  graph,
  height,
  width,
  images,
  setSelectedLink,
  setSelectedNode,
  selectedNode,
  selectedLink,
  selectLink,
  hoveredEdge,
  mini,
  nodeSize,
}) => {
  const { theme } = useContext(ThemeContext);
  const themeColors = theme === "dark" ? dark : light;
  const fg = useRef();
  const d3Ref = useRef(null);
  const firstLoad = useRef(true);
  d3Ref.current = window.d3;
  const nodeRelativeSize = 8;
  const zoomToFit = useCallback(() => {
    fg.current.zoomToFit(400, 10);
  }, [fg]);

  const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
  const zoomIn = () => {
    const newZoomLevel = currentZoomLevel * 1.2;
    setCurrentZoomLevel(newZoomLevel);
    fg.current.zoom(newZoomLevel, 400);
  };

  const zoomOut = () => {
    const newZoomLevel = currentZoomLevel * 0.8;
    setCurrentZoomLevel(newZoomLevel);
    fg.current.zoom(newZoomLevel, 400);
  };

  // Handle zoom level change
  const handleZoomChange = (zoomLevel) => {
    setCurrentZoomLevel(zoomLevel.k);
  };

  const focusOnMainNode = useCallback(() => {
    setSelectedNode(graph?.nodes[0]);
    setSelectedLink(null);
    zoomToFit();
  }, [graph, setSelectedNode, setSelectedLink, zoomToFit]);
  return (
    <div className={classes.graph_container}>
      <ForceGraph2D
        cooldownTicks={100}
        onEngineStop={() => {
          if (graph?.nodes?.length > 5 && firstLoad.current) {
            fg.current.zoomToFit(400, 10);
            firstLoad.current = false;

            if (selectLink) {
              setSelectedLink(
                graph.links.find((link) => {
                  return (
                    link.source.id === selectLink.source &&
                    link.target.id === selectLink.target
                  );
                })
              );
            }
          }
        }}
        enableZoomInteraction
        enablePanInteraction
        enableNodeDrag={true}
        enableNavigationControls
        onZoomEnd={(zoomLevel) => handleZoomChange(zoomLevel)}
        nodeRelSize={nodeRelativeSize}
        nodeVal={nodeScale}
        graphData={graph}
        linkWidth={0.8}
        ref={fg}
        linkColor={(link) => {
          if (
            selectedLink &&
            ((selectedLink.source.id === link.source.id &&
              selectedLink.target.id === link.target.id) ||
              (selectedLink.source.id === link.target.id &&
                selectedLink.target.id === link.source.id))
          ) {
            return themeColors["primary-color"];
          }

          if (hoveredEdge && isHoveredEdge(link, hoveredEdge)) {
            return themeColors["primary-color"];
          }

          if (selectedNode) {
            if (selectedNode.id === link.source.id) {
              return themeColors.error;
            }
            if (selectedNode.id === link.target.id) {
              return themeColors.success;
            }
          }
          return themeColors["explorer-edge"];
        }}
        linkCurvature={(link) => {
          return link.curve;
        }}
        height={height}
        width={width}
        onNodeClick={(node) => {
          setSelectedLink(null);
          setSelectedNode(node);
        }}
        onLinkClick={(link) => {
          setSelectedNode(null);
          setSelectedLink(link);
        }}
        nodeLabel={(node) => {
          return node?.data?.details?.display_name;
        }}
        linkLabel={(link) => {
          const { source, target, data } = link;
          if (data && data.summary) {
            const sentKey = `${source.id}->${target.id}`;
            const receivedKey = `${target.id}->${source.id}`;
            return ` $${abbreviateNumber(
              data.summary[sentKey].total_usd_value_transfer +
                data.summary[receivedKey].total_usd_value_transfer
            )}`;
          }
          return "";
        }}
        nodeCanvasObject={(node, ctx) => {
          const size =
            nodeSize *
            nodeScale(
              node,
              selectedNode,
              selectedLink,
              graph.links,
              hoveredEdge
            );

          const greyPadding =
            2 *
            nodeScale(
              node,
              selectedNode,
              selectedLink,
              graph.links,
              hoveredEdge
            );

          const whitePadding =
            1.5 *
            nodeScale(
              node,
              selectedNode,
              selectedLink,
              graph.links,
              hoveredEdge
            );
          ctx.beginPath();
          ctx.arc(
            node.x,
            node.y,
            greyPadding + size / 2,
            0,
            2 * Math.PI,
            false
          );
          if (
            (selectedNode && selectedNode.id === node.id) ||
            (selectedLink &&
              (selectedLink.source.id === node.id ||
                selectedLink.target.id === node.id)) ||
            (hoveredEdge && isHoveredNode(node, hoveredEdge))
          ) {
            ctx.fillStyle = "#0648D7";
          } else {
            ctx.fillStyle = themeColors["explorer-edge"];
          }
          ctx.fill();
          ctx.closePath();

          ctx.beginPath();
          ctx.fillStyle = "white";
          ctx.arc(
            node.x,
            node.y,
            size / 2 + whitePadding,
            0,
            2 * Math.PI,
            false
          );
          ctx.fill();
          ctx.closePath();

          const x = node.x;
          const y = node.y;
          const img = images[node.data.details?.display_picture] ?? null;
          //   images[
          //     "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png"
          //   ];

          if (img) {
            ctx.save();
            ctx.beginPath();
            ctx.createImageData(size, size);
            ctx.arc(x, y, size / 2, 0, 2 * Math.PI, false);
            ctx.clip(); // Apply clip path for the border radius
            ctx.drawImage(img, x - size / 2, y - size / 2, size, size);
            ctx.closePath();
          } else {
            ctx.beginPath();
            ctx.fillStyle = "#68768420";
            ctx.arc(node.x, node.y, size / 2, 0, 2 * Math.PI, false);
            ctx.fill();
            ctx.closePath();

            ctx.fillStyle = "#687684";
            ctx.font = `${size / 3}px Arial`;
            ctx.fontWeight = "bold";
            const text =
              node?.data?.details?.display_name?.startsWith("0x") &&
              !node?.data?.details?.user_account
                ? node?.data?.details?.address?.slice(2, 6).toUpperCase()
                : node?.data?.details?.display_name?.slice(0, 4).toUpperCase();
            const textX = x;

            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(text, textX, y + size / 20);
          }

          //  ctx.restore();
        }}
      />

      {/* Bottom right action buttons for explorer */}
      {!mini && (
        <div className={classes.buttons}>
          <div
            className={classes.single_button}
            onClick={focusOnMainNode}
            title="Focus and Zoom">
            <MyLocationOutlined />
          </div>
          <div
            className={classes.single_button}
            onClick={zoomIn}
            title="Click to zoom in">
            <MagnifyingGlassPlus size={22} weight="bold" />
          </div>
          <div
            className={classes.single_button}
            onClick={zoomOut}
            title="Click to zoom out">
            <MagnifyingGlassMinus size={22} weight="bold" />
          </div>
        </div>
      )}
    </div>
  );
};

export default UniversalGraph;
