import { getConnectTwitter } from "api/feed.api";

export const connectTwitter = async ({
  onSuccess,
  onError,
  setIsTwitterConnectLoading,
}) => {
  setIsTwitterConnectLoading(true);
  const { redirect_url } = await getConnectTwitter();
  if (redirect_url === null) {
    onError();
    return;
  }
  const popupWindow = window.open(
    redirect_url,
    "_blank",
    "width=800,height=600"
  );

  if (window.focus) {
    popupWindow?.focus();
  }

  window.addEventListener("message", function (event) {
    // Check the origin of the message for security purposes
    if (event.origin !== window.location.origin) {
      return;
    }

    // event.data will contain the message sent from the other window
    if (event.data.status === "success") {
      onSuccess();
    } else if (event.data.status === "error") {
      onError();
    }
  });
};
