import styled from "styled-components/macro";
export const CircledNumber = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(6, 72, 215, 0.5) 0%, #0648d7 100%);
  border: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  color: var(--base);
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: normal;
`;
