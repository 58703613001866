import { CursorDiv, CustomRow, FlexBox, PaddingComponent } from "./Components";
import { X } from "@phosphor-icons/react";
import classes from "components/UI/Buttons/buttons.module.css";
import styled from "styled-components/macro";
import { useState } from "react";
import { appDownloadLink } from "utils/constants";
import { openInNewTab } from "utils/misc";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const MobileDownloadWrapper = styled.div`
  width: 100vw;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  background-color: var(--base);
  z-index: 7;
  @media (max-width: 768px) {
    & {
      top: 0;
      bottom: auto;
      background-color: var(--elevation-1);
      backdrop-filter: blur(10px);
    }
  }
`;

const DownloadText = styled.span`
  color: var(--text-1);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: normal;
`;

const MobileDownloadFooter = () => {
  const [isVisible, setIsVisible] = useState(true);
  if (!isVisible) return null;
  return (
    <BaseWrapper mixpanelEvent="MobileDownloadFooter">
      <MobileDownloadWrapper>
        <div style={{ width: "100vw" }}>
          <PaddingComponent padding="16px 12px">
            <CustomRow alignItems="center">
              <FlexBox flex="1">
                <CustomRow>
                  <CursorDiv
                    onClick={() => {
                      setIsVisible(false);
                    }}>
                    <X size={24} color="var(--text-2)" />
                  </CursorDiv>
                  <PaddingComponent width="16px" />
                  <DownloadText>
                    Stay updated on the go.<br></br>Download our mobile app
                  </DownloadText>
                </CustomRow>
              </FlexBox>
              <PaddingComponent paddingRight="16px">
                <CursorDiv
                  onClick={() => {
                    openInNewTab(appDownloadLink);
                  }}>
                  <button className={classes.primaryButton}>Download</button>
                </CursorDiv>
              </PaddingComponent>
            </CustomRow>
          </PaddingComponent>
        </div>
      </MobileDownloadWrapper>
    </BaseWrapper>
  );
};

export default MobileDownloadFooter;
