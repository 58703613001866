import React, { useContext } from "react";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { EthersExtension } from "@dynamic-labs/ethers-v5";

import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "contextStates/Theme.context";
import { DYNAMIC_WALLET_ENVIRONMENT_ID } from "utils/constants";
import { TRACKING_EVENTS, trackEvent } from "utils/event_tracking";

const DynamicWalletProvider = ({ children }) => {
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  return (
    <DynamicContextProvider
      theme={theme}
      settings={{
        initialAuthenticationMode: "connect-only",
        environmentId: DYNAMIC_WALLET_ENVIRONMENT_ID,
        walletConnectorExtensions: [EthersExtension],
        walletConnectors: [EthereumWalletConnectors, SolanaWalletConnectors],
        events: {
          onAuthFlowCancel: () => {
            trackEvent(TRACKING_EVENTS.WALLET_CONNECT.POPUP_DISMISS, {
              trigger_page: location.pathname,
            });
          },
          onAuthFlowOpen: () => {
            trackEvent(TRACKING_EVENTS.WALLET_CONNECT.POPUP_SHOWN, {
              trigger_page: location.pathname,
            });
          },
          onWalletAdded: (args) => {
            const type = args?.wallet?.connector?.name ?? "unknown";
            trackEvent(TRACKING_EVENTS.WALLET_CONNECT.WALLET_CONNECTED, {
              trigger_page: location.pathname,
              selected_wallet_type: type,
              selected_wallet_address: args?.wallet?.address,
            });
          },
        },
      }}>
      {children}
    </DynamicContextProvider>
  );
};

export default DynamicWalletProvider;
