import classes from "./v3LiquidityProviderDetails.module.css";
import {
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableTitle,
} from "components/UI/Table/Table";
import { TokenBalance, TokenInfoWrapper } from "./TokenInfoWrapper";
import inRangeIcon from "assets/svg/profile/in_range_icon.svg";
import notInRangeIcon from "assets/svg/profile/not_in_range_icon.svg";

const V3LiquidityProviderDetails = ({ label, holdings, totalUsdValue }) => {
  return (
    <Table>
      <TableTitle
        title={label}
        subTitle={
          <div className={classes.totalUsdValue}>
            Total Value: {totalUsdValue}
          </div>
        }
      />
      <TableHeader>
        <TableCell alignLeft>Pool</TableCell>
        <TableCell alignLeft>Range</TableCell>
        <TableCell alignLeft style={{ flexBasis: "35%" }}>
          Rewards
        </TableCell>
        <TableCell alignRight style={{ flexBasis: "15%" }}>
          USD Value
        </TableCell>
      </TableHeader>
      {holdings.map((holdingsItem, index) => (
        <TableRow key={`generic-protocols-${index}`} alignToFlexStart>
          <TableCell alignLeft>
            <Pools pools={holdingsItem.pool} balances={holdingsItem.balance} />
          </TableCell>

          <TableCell alignLeft>
            <Range range={holdingsItem.range} />
          </TableCell>
          <TableCell alignLeft style={{ flexBasis: "35%" }}>
            <Rewards rewards={holdingsItem.rewards} />
          </TableCell>
          <TableCell alignRight style={{ flexBasis: "15%" }}>
            {holdingsItem.usd_value?.display_value}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
const Range = ({ range }) => {
  // TODO(shaheer): remove this check when we have the same API response for all v3 liquidity providers types
  return !!range.range_values ? (
    <div className={`${classes.range} ${classes.spacedColumns}`}>
      <div className={classes.rangeValueWrapper}>
        <div className={classes.rangeValues}>{range.range_values}</div>
        <div className={classes.rangeStatus}>
          {range.is_in_range ? (
            <img src={inRangeIcon} alt="in-range" />
          ) : (
            <img src={notInRangeIcon} alt="in-range" />
          )}
        </div>
      </div>
      <div className={classes.rangeTokens}>{range.token_pair}</div>
    </div>
  ) : (
    <div className={classes.range}>
      <div className={classes.rangeValue}>{range.display_value}</div>
      <div className={classes.rangeStatus}>
        {range.is_in_range ? (
          <img src={inRangeIcon} alt="in-range" />
        ) : (
          <img src={notInRangeIcon} alt="in-range" />
        )}
      </div>
    </div>
  );
};
const Pools = ({ pools, balances }) => {
  return (
    <div className={`${classes.pools} ${classes.spacedColumns}`}>
      {pools.map((poolItem, index) => (
        <TokenInfoWrapper
          key={"pool-item" + index}
          token_url={poolItem?.token_url}
          token_icon={poolItem.token_icon}
          info={<TokenBalance balance={balances?.[index]} />}
        />
      ))}
    </div>
  );
};

export const Rewards = ({ rewards }) => {
  if (!rewards) return <span>-</span>;
  return (
    <div className={classes.spacedColumns}>
      {rewards.map((rewardItem, index) => (
        <TokenInfoWrapper
          key={"pool-item" + index}
          token_url={rewardItem?.token_url}
          token_icon={rewardItem?.token_icon}
          info={
            <div className={classes.rewardsItem}>
              <div className={classes.rewardsToken}>
                {rewardItem.balance?.display_value}
              </div>
              <div className={classes.rewardsUsdValue}>
                {rewardItem.value_usd?.display_value}
              </div>
            </div>
          }
        />
      ))}
    </div>
  );
};

export default V3LiquidityProviderDetails;
