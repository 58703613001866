import {
  addMembersToGroup,
  addWalletsToGroup,
  generateGroupId,
  upsertGroupDetails,
} from "api/messaging.api";
import { useChatContext } from "stream-chat-react";
import { createContext, useContext, useState } from "react";
import { AuthContext } from "contextStates/AuthContext";
import { GlobalContext } from "contextStates/Global";
import { ChannelTypes } from "../../helpers";
import { logSentryEvent } from "utils/sentry";

export const CreateGroupContext = createContext({});
export const useGroupCreate = () => {
  const { client, setActiveChannel } = useChatContext();
  const [groupType, setGroupType] = useState(null);
  const [groupName, setGroupName] = useState("");
  const isStep1Valid = groupType !== null;
  const [selectedWallets, setSelectedWallets] = useState([]);
  const [defaultWallets, setDefaultWallets] = useState([]);
  const [showWalletList, setShowWalletList] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupImage, setGroupImage] = useState("");
  const [isChannelCreated, setIsChannelCreated] = useState(false);
  const isStep3Valid = isStep1Valid && groupName.length > 0;

  const { identityDetails } = useContext(AuthContext);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const profileStreamChatId =
    identityDetails.current.identity.stream_chat_details.id;
  const profileIdentityId = identityDetails.current.identity.id;
  const createGroup = async () => {
    try {
      // API call to create group_id
      const groupId = await generateGroupId();
      if (!groupId?.id) throw new Error("Failed to create group");
      // Upsert group details - name and image
      const upsertRes = await upsertGroupDetails({
        groupId: groupId?.id,
        name: groupName,
        image: groupImage?.result ?? null,
      });
      await addMembersToGroup({
        groupId: groupId.id,
        members:
          groupType === ChannelTypes.STREAMING_GROUP
            ? [profileStreamChatId]
            : [profileStreamChatId, ...selectedUsers],
        groupType,
      });
      if (
        groupType === ChannelTypes.STREAMING_GROUP &&
        selectedWallets &&
        selectedWallets.length > 0
      ) {
        // API call to add wallets to group
        await addWalletsToGroup({
          groupId: groupId.id,
          wallets: selectedWallets,
          identity: profileIdentityId,
        });
      }
      const channelMembers =
        groupType === ChannelTypes.STREAMING_GROUP
          ? [profileStreamChatId]
          : [profileStreamChatId, ...selectedUsers];
      const channelInvites =
        groupType === ChannelTypes.STREAMING_GROUP ? [...selectedUsers] : [];
      const channel = client.channel("messaging", groupId.id, {
        name: groupName,
        image: upsertRes?.display_picture?.length
          ? upsertRes?.display_picture
          : null,
        channel_type: groupType,
        members: [...channelMembers],
        invites: [...channelInvites],
      });
      const watchedChannel = await channel.watch();
      await channel.sendMessage({
        text: `${watchedChannel.membership?.user?.name} created ${groupName} group.`,
        type: "system",
      });
      setActiveChannel(watchedChannel);

      setIsChannelCreated(true);
    } catch (err) {
      logSentryEvent(err, {
        message: "Failed to create message group",
      });
      handleErrorSnackbar?.(null, "Failed to create group, please try again.");
    }
  };
  const resetValues = () => {
    setGroupType(null);
    setGroupName("");
    setSelectedWallets([]);
    setSelectedUsers([]);
    setGroupImage("");
    setIsChannelCreated(false);
  };
  return {
    groupType,
    setGroupType,
    isStep1Valid,
    selectedWallets,
    setSelectedWallets,
    showWalletList,
    setShowWalletList,
    selectedUsers,
    setSelectedUsers,
    groupName,
    setGroupName,
    defaultWallets,
    setDefaultWallets,
    groupImage,
    setGroupImage,
    createGroup,
    resetValues,
    isChannelCreated,
    isStep3Valid,
  };
};
