import React from "react";
import Modal from "@mui/material/Modal";
import { ReactComponent as CloseModalIcon } from "assets/svg/feed/close_modal_icon.svg";
import classes from "./newPostModal.module.css";
import CustomLinkTabBar from "../UI/Components/CustomLinkTabBar";

const NewPostModal = ({
  isModalOpen = false,
  handleClose = () => {},
  children,
  tabsList,
  activeTab,
  setActiveTab,
  isScheduling,
}) => {
  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <div className={classes.modalWrapper}>
        <div className={classes.modalContainer}>
          <div className={classes.modalTitleWrapper} id="modal-modal-title">
            {!isScheduling && (
              <CustomLinkTabBar
                tabsList={tabsList}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isLinkTabBar={false}
              />
            )}
            <button className={classes.close} onClick={handleClose}>
              <CloseModalIcon />
            </button>
          </div>
          <div className={classes.modalContentWrapper}>{children}</div>
        </div>
      </div>
    </Modal>
  );
};

export default NewPostModal;
