import { useState, useContext, useEffect } from "react";
import { useParams, useHistory, Switch, Route } from "react-router-dom";
import ProfileHeader from "./ProfileHeader/ProfileHeader";
import ProfileDetails from "./ProfileDetails/ProfileDetails";
import {
  prefetchBundleInfo,
  prefetchNetworth,
  prefetchActivitySummary,
  useGetProfileData,
} from "api/profile.api";
import { useQueryClient } from "@tanstack/react-query";

import ProfileContextProvider, {
  ProfileContext,
} from "contextStates/ProfileContext";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import useTokenHoldings from "./ProfileDetails/Portfolio/TokenHoldings/useTokenHoldings";
import useFetchProtocolHoldings from "customHooks/fetch/useFetchProtocolHoldings";
import useProfileNftHoldings from "./ProfileDetails/NFTHoldings/useProfileNftHoldings";
import DynamicWalletProvider from "components/DynamicWalletProvider/DynamicWalletProvider";
import Settings from "../Settings/Settings";
import { PaddingComponent } from "components/UI/Components/Components";
const Profile = ({ scrollableRef }) => {
  let { identifier } = useParams();
  const queryClient = useQueryClient();
  //this isLoading is intentional to prefetch before rendering the page
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    prefetchBundleInfo({ identifier, queryClient });
    prefetchNetworth({ identifier, queryClient });
    prefetchActivitySummary({ identifier, queryClient });
    setIsLoading(false);
  }, [identifier, queryClient]);
  if (isLoading) {
    return null;
  }

  return (
    <BaseWrapper mixpanelEvent="Profile" mixpanelproperties={{ identifier }}>
      <ProfileContextProvider>
        <ProfilePage scrollableRef={scrollableRef} />
      </ProfileContextProvider>
    </BaseWrapper>
  );
};

const ProfilePage = ({ scrollableRef }) => {
  let { identifier, active_tab } = useParams();
  const history = useHistory();
  const { setIdentifier, setProfileData } = useContext(ProfileContext);
  const { data: profileData } = useGetProfileData({ identifier });
  useEffect(() => {
    if (profileData) {
      setProfileData(profileData?.data?.data ?? null);
    }
  }, [profileData, setProfileData]);
  useEffect(() => {
    setIdentifier(identifier);
  }, [identifier, setIdentifier]);

  useEffect(() => {
    if (!active_tab) {
      history.push(`/${identifier}/activities`);
    }
  }, [active_tab, identifier, history]);

  // fetch portfolio data
  useTokenHoldings({
    identifier,
    forProtocolTreasury: false,
  });
  useFetchProtocolHoldings({ identifier, forProtocolTreasury: false });
  useProfileNftHoldings({ identifier, selectedGroupIds: [] });

  const [profileType, setProfileType] = useState(null);

  // Get Identity Data
  // Pass the identity ID to child components
  // Child components will have api calls, loaders
  return (
    <DynamicWalletProvider>
      <Switch>
        <Route path="/:identifier/settings">
          <PaddingComponent padding="0 16px">
            <Settings />
          </PaddingComponent>
        </Route>
        {/* all other routes */}
        <div style={{ backgroundColor: "var(--base)", borderRadius: "8px" }}>
          <ProfileHeader
            identifier={identifier}
            setProfileType={setProfileType}
            profileType={profileType}
          />
          <ProfileDetails
            identifier={identifier}
            profileType={profileType}
            scrollableRef={scrollableRef}
          />
        </div>
      </Switch>
    </DynamicWalletProvider>
  );
};

export default Profile;
