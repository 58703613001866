import LendingHoldingDetails from "./LendingHoldingDetails";
import V3LiquidityProviderDetails from "./V3LiquidityProviderDetails";
import GenericHoldingDetails from "./GenericHoldingDetails";
import PerpetualPositionDetails from "./PerpetualPositionDetails";
import { protocolTypeToLabel } from "utils/misc.js";
import classes from "./holdingDetails.module.css";
import { useParams } from "react-router-dom";
import { PolyMarketHoldingDetails } from "./PolyMarketHoldingDetails";
import { useGetProfileData } from "api/profile.api";
import { ProfileContext } from "contextStates/ProfileContext";
import { useContext } from "react";

const HoldingDetails = (props) => {
  const {
    setCurrentPositionIndex,
    setCurrentProtocolIndex,
    setCurrentHoldingsLabel,
    protocolIndex,
    holdings,
    protocolHoldingItem,
    setIsRightPaneOpen,
    setCurrentTxnIdentifiers,
    setCurrentPoolAddress,
  } = props;
  const { identifier } = useParams();
  const { selectedWalletAddresses } = useContext(ProfileContext);
  const { data: profile } = useGetProfileData({ identifier });
  const isAddressPage =
    profile?.data?.data?.type !== "identity" &&
    selectedWalletAddresses.current?.length > 0;
  if (protocolHoldingItem.polymarket_holding) {
    return <PolyMarketHoldingDetails {...props} />;
  }
  return Object.entries(holdings || {}).map(([label, value], index) => {
    const transformedLabel = protocolTypeToLabel(label);
    if (!value || value?.holdings.length === 0) {
      return null;
    }

    if (["Lending", "LeveragedFarming"].includes(label)) {
      if (
        value.holdings_by_address &&
        Object.keys(value.holdings_by_address).length > 0 &&
        isAddressPage
      ) {
        // multiple holdings across wallets
        return Object.entries(value.holdings_by_address).map(
          ([address, holdings], addressIndex) => {
            return (
              <LendingHoldingDetails
                key={`lending-holding-details-${index}-${addressIndex}`}
                address={address}
                label={transformedLabel}
                value={
                  value?.holdings
                    ? value
                    : { holdings: value.holdings_by_address[address] }
                }
              />
            );
          }
        );
      }
      return (
        <LendingHoldingDetails
          key={`lending-holding-details-${index}`}
          label={label}
          value={Array.isArray(value) ? { holdings: value } : value}
        />
      );
    }

    if (label === "PerpetualPosition") {
      return (
        <div
          className={classes.expandedProtocol}
          key={`perpetual-position-details-${index}`}>
          <PerpetualPositionDetails
            label={transformedLabel}
            holdings={value.holdings}
            totalUsdValue={value?.total_usd_value?.display_value}
          />
        </div>
      );
    }

    if (label === "DEX" && value?.holdings?.[0]?.range) {
      return (
        <div
          className={classes.expandedProtocol}
          key={`v3-liquidity-details-${index}`}>
          <V3LiquidityProviderDetails
            label="V3 Liquidity Pool"
            holdings={value.holdings}
            totalUsdValue={value?.total_usd_value?.display_value}
          />
        </div>
      );
    }
    if (
      value.holdings_by_address &&
      Object.keys(value.holdings_by_address).length > 0 &&
      isAddressPage
    ) {
      // multiple holdings across wallets
      return Object.entries(value.holdings_by_address).map(
        ([address, value], addressIndex) => {
          return (
            <div className={classes.expandedProtocol}>
              <GenericHoldingDetails
                key={`generic-holding-details-${index}`}
                setCurrentPositionIndex={setCurrentPositionIndex}
                setCurrentProtocolIndex={setCurrentProtocolIndex}
                setCurrentHoldingsLabel={setCurrentHoldingsLabel}
                holdingsLabel={label}
                protocolIndex={protocolIndex}
                label={transformedLabel}
                holdings={Array.isArray(value) ? value : value?.holdings ?? []}
                totalUsdValue={value?.total_usd_value?.display_value}
                setIsRightPaneOpen={setIsRightPaneOpen}
                protocolHoldingItem={protocolHoldingItem}
                setCurrentTxnIdentifiers={setCurrentTxnIdentifiers}
                setCurrentPoolAddress={setCurrentPoolAddress}
                address={address}
              />
            </div>
          );
        }
      );
    }
    return (
      <div className={classes.expandedProtocol}>
        <GenericHoldingDetails
          key={`generic-holding-details-${index}`}
          setCurrentPositionIndex={setCurrentPositionIndex}
          setCurrentProtocolIndex={setCurrentProtocolIndex}
          setCurrentHoldingsLabel={setCurrentHoldingsLabel}
          holdingsLabel={label}
          protocolIndex={protocolIndex}
          label={transformedLabel}
          holdings={value.holdings}
          totalUsdValue={value?.total_usd_value?.display_value}
          setIsRightPaneOpen={setIsRightPaneOpen}
          protocolHoldingItem={protocolHoldingItem}
          setCurrentTxnIdentifiers={setCurrentTxnIdentifiers}
          setCurrentPoolAddress={setCurrentPoolAddress}
        />
      </div>
    );
  });
};
export default HoldingDetails;
