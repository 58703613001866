import classes from "./WalletDetails.module.css";

const WalletDetailsTabBar = ({ activeTab, setActiveTab, tabsList }) => {
  const TabBar = ({ title, index }) => {
    return (
      <div
        onClick={() => {
          setActiveTab(index);
        }}
        className={`${classes.tabbarlink} ${
          activeTab === index ? classes.active : ""
        }`}>
        {title}
      </div>
    );
  };
  return (
    <div className={classes.tabbar}>
      {tabsList.map((title, index) => (
        <div key={`wallet-details-tab-bar-${index}`}>
          <TabBar title={title} index={index}></TabBar>
        </div>
      ))}
    </div>
  );
};

export default WalletDetailsTabBar;
