import { useFetchTokenSwapTableData } from "api/token_page.api";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ZxTable from "zxComponents/common/ZxTable/ZxTable";
import { getZxTokenRecentTransfersColumns } from "./ZxTokenRecentTransfers.utils";
import ZxTokenFilterSearch from "../ZxTokenFilterSearch/ZxTokenFilterSearch";
import styles from "./ZxTokenRecentTransfers.module.scss";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
let timer;
const ZxTokenRecentTransfers = ({
  chain,
  address,
  showFilter = false,
  filter = null,
  updateFilter = () => {},
  isFolloweeSwaps = false,
}) => {
  const isIntervalSet = useRef(false);
  const isAnimating = useRef(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const toggleSearchModal = (v = null) => {
    setIsSearchModalOpen(v === null ? !isSearchModalOpen : v);
  };
  const {
    data,
    isLoading,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
  } = useFetchTokenSwapTableData({
    queryKey: isFolloweeSwaps ? "followee_swaps" : "recent_swaps",
    chainId: chain,
    contractAddress: address,
    type: isFolloweeSwaps ? "follow" : "recent",
    filter: isFolloweeSwaps ? null : filter,
  });
  const { rows, profiles } = useMemo(() => {
    let profiles = {};
    const rows = data?.pages
      ?.map((page, idx) => {
        profiles = { ...profiles, ...page?.address_profiles };
        let animateCount = 0;
        return page?.recent_transfer_data?.map((t, vidx) => {
          const type = t.type?.toLowerCase();
          const isBuy = type === "buy" || type === "received";
          const shouldAnimate =
            idx === 0 && isAnimating.current && animateCount < 10;
          if (shouldAnimate) {
            animateCount++;
          }
          return {
            ...t,
            animate: shouldAnimate,
            animationTiming: 500,
            animation: styles[isBuy ? "animateBuy" : "animateSell"],
            preAnimationClass: shouldAnimate ? styles.preAnimate : "",
            animationDelay: 100 * vidx,
          };
        });
      })
      .flat();
    return { rows, profiles };
  }, [data?.pages]);

  useEffect(() => {
    if (rows?.length < 10 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [rows, hasNextPage, isFetchingNextPage, fetchNextPage]);
  useEffect(() => {
    if (data && !isIntervalSet.current) {
      isIntervalSet.current = true;
      isAnimating.current = true;
      timer = setInterval(() => {
        fetchPreviousPage();
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    return () => {
      isIntervalSet.current = false;
      clearInterval(timer);
    };
  }, [chain, address]);
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <ZxTokenFilterSearch
        isOpen={isSearchModalOpen}
        onClose={toggleSearchModal}
        onApply={(profile) => updateFilter(profile)}
        filter={filter}
      />
      <ZxTable
        data={rows ?? null}
        isLoading={isLoading}
        isError={isError}
        columns={getZxTokenRecentTransfersColumns({
          showFilter,
          filter,
          updateFilter,
          toggleSearchModal,
          isFolloweeSwaps,
        })}
        isTokenPageTable
        columnHeight={40}
        commonProps={{ profiles }}
        infiniteScroll
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </div>
  );
};

export default withErrorBoundary(ZxTokenRecentTransfers);
