import { signedRequest } from "./api";

export const postLensAuth = async ({ refreshToken, accessToken }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/connect_lens",
    bodyText: JSON.stringify({
      refresh_token: refreshToken,
      access_token: accessToken,
    }),
  });

  return response.data;
};
