import { sanitize } from "dompurify";
import { Link } from "react-router-dom";
import styles from "./searchResultItem.module.css";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import showMoreIcon from "assets/svg/header/show_more_icon.svg";
import ResultItemHints from "./ResultItemHints";
import { inferImageType, useRecentSearchResults } from "./searchbar_utils";
import {
  CustomColumn,
  CustomRow,
  TypeTag,
} from "components/UI/Components/Components";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import { trackEvent } from "utils/event_tracking";
const SearchResultItem = ({
  selected,
  result,
  setIsSearchInputFocused,
  isRecentSearchItem = false,
  searchUuid,
  index,
}) => {
  const { updateRecentSearches } = useRecentSearchResults();
  const handleResultClick = () => {
    setIsSearchInputFocused(false);
    updateRecentSearches(result);
    trackEvent("search_query", {
      uuid: searchUuid,
      selected_index: index,
    });
  };
  if (result.type === "see_all")
    return (
      <Link
        className={`${styles.showAllWrapper} ${styles.searchResultItem} ${
          selected && styles.selected
        }`}
        to={result?.data?.link}
        onClick={() => setIsSearchInputFocused(false)}>
        <div className={styles.showMoreIcon}>
          <img src={showMoreIcon} alt="show more icon" />
        </div>
        <div className={styles.showAllText}>{result?.label}</div>
      </Link>
    );

  const searchResult = result.data;
  const link = searchResult.link;
  const displayName = searchResult.display_name;
  const info =
    result?.info && result?.info?.display_value
      ? result.info.display_value
      : null;
  return (
    <Link
      to={link}
      onClick={handleResultClick}
      className={`${styles.searchResultItem} ${selected && styles.selected} ${
        isRecentSearchItem ? styles.isRecentSearchItem : ""
      }`}>
      <div className={`${styles.itemInfoWrapper}`}>
        <IconWithChain
          src={searchResult.display_picture}
          alt={displayName}
          chain={searchResult.chain || searchResult.address_chain}
          imageType={inferImageType(result)}
          name={displayName}
          key={`${displayName}-avatar-${searchResult.display_picture}`}
          showImageFullHeightWidth
          chainHeight="16px"
          chainWidth="16px"
          iconHeight="32px"
          iconWidth="32px"
        />

        <CustomColumn gap="4px" justifyContent="center">
          <div className={styles.infoWrapper}>
            <div className={styles.info}>
              <CustomRow gap="4px" alignItems="center">
                <div
                  className={styles.name}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(displayName),
                  }}></div>
                <VerifiedBadge profile={searchResult} />
              </CustomRow>
              {result?.tag && <TypeTag text={result.tag} />}
              {info && <div className={styles.meta}>{info}</div>}
            </div>
          </div>
          {!isRecentSearchItem && (
            <ResultItemHints
              searchHints={result?.search_hints}
              marginLeft="0"
            />
          )}
        </CustomColumn>
      </div>
    </Link>
  );
};

export default SearchResultItem;
