import withLoader from "components/core/withLoader";
import { getInviteGraph } from "api/profile.api";
import {
  CustomColumn,
  CustomRow,
  CustomText,
} from "components/UI/Components/Components";

import { ReactFlowProvider } from "reactflow";
import Image from "components/UI/Image";
import ProfileHoverCard from "../Profile/ProfileHoverCard/ProfileHoverCard";
import Loader from "components/UI/Loader/Loader";
import FlowchartRenderer from "./FlowchartRenderer";
import { XLogo } from "@phosphor-icons/react";
import { getCssVariable, getTrimmedString } from "utils/misc";

const getEmojiForCount = (count) => {
  if (count > 1000000) {
    return "🔥";
  }
  if (count > 100000) {
    return "🚀";
  }
  if (count > 10000) {
    return "✨";
  }
  return "";
};

const getNodeItem = (node, isIdentityNode) => {
  const { profile } = node;
  const hasXFollowers = profile?.twitter_follower_count?.value > 0;
  const xFollowerCount = profile?.twitter_follower_count?.display_value;
  return (
    <ProfileHoverCard actor={node.profile}>
      <CustomColumn gap="4px">
        <CustomRow gap="8px" className="invite-node-container">
          <Image
            src={node.profile?.display_picture}
            className="invite-graph_avatar"
          />

          <CustomColumn alignItems="flex-start">
            <CustomText
              text={getTrimmedString(node.profile?.display_name, 12)}
              color="var(--text-1)"
              fontWeight="500"
              whiteSpace="nowrap"
              fontSize="13px"
            />
            <CustomText
              text={
                node.profile?.net_worth?._Any?.total_usd_value?.display_value
              }
              fontSize="12px"
              fontWeight="500"
              color="var(--text-1)"
            />
          </CustomColumn>
        </CustomRow>

        <CustomRow
          justifyContent="center"
          className="x-followers-count"
          alignItems="center"
          gap="4px">
          <XLogo size={12} color={getCssVariable("--text-2")} />
          <CustomText
            text={
              hasXFollowers
                ? `${xFollowerCount} Follower${
                    xFollowerCount > 1 ? "s" : ""
                  } ${getEmojiForCount(xFollowerCount)}`
                : `-`
            }
            fontWeight="400"
            fontSize="12px"
            lineHeight="15px"
          />
        </CustomRow>
      </CustomColumn>
    </ProfileHoverCard>
  );
};

const InviteGraph = ({
  queryData,
  identifier,
  inviteCodes,
  openInviteCodesPanel,
}) => {
  return (
    <div style={{ height: "calc(100vh - 400px)", width: "100%" }}>
      <ReactFlowProvider>
        <FlowchartRenderer
          identifier={identifier}
          queryData={queryData}
          getNodeItem={getNodeItem}
          plusIconProps={{
            onClick: openInviteCodesPanel,
            count: inviteCodes?.length,
          }}
        />
      </ReactFlowProvider>
    </div>
  );
};

export default withLoader(InviteGraph, getInviteGraph, {
  queryKey: ({ identifier }) => ["invite-graph", identifier],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 0,
  renderNotFound: true,
  CustomLoader: Loader,
});
