import classes from "./FollowedByFollowers.module.css";
import CallMadeSharpIcon from "@mui/icons-material/CallMadeSharp";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";

const InteractionFooter = ({
  text,
  noTopMargin,
  profiles,
  identities,
  nativeTokensInfo,
}) => {
  return (
    <div
      className={classes.container}
      style={{
        marginTop: noTopMargin ? 0 : 10,
      }}>
      <CallMadeSharpIcon
        className={classes.icon}
        variant="standard"
        color="inherit"
        sx={{ fontSize: 18 }}
      />
      <FeedDescription
        templateString={text}
        profiles={profiles}
        identities={identities}
        nativeTokensInfo={nativeTokensInfo}
        fontColor="inherit"
        fontSize="inherit"
      />
    </div>
  );
};

export default InteractionFooter;
