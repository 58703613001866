import classes from "./WalletDetails.module.css";
import CommunitySourcesWallet from "./CommunitySourcesWallet";
// import InfoTile, { INFO_TILE_TYPE } from "./InfoTile";

const BundleDetails = ({ bundleData, wallet }) => {
  return (
    <div className={classes.detailsContainer}>
      {/* <InfoTile
        infoTileType={INFO_TILE_TYPE.SUCCESS}
        text={"Wallet verified by Defidad.eth on 23rd May,  19"}
      /> */}
      <CommunitySourcesWallet bundleData={bundleData} wallet={wallet} />
    </div>
  );
};

export default BundleDetails;
