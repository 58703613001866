import CurrentEngagements from "components/ActivityEngagements/CurrentEngagements/CurrentEngagements";
import PnlTokenList from "components/FeedItemV2/PnlTokenList/PnlTokenList";
import { PostGeneratedComment } from "components/FeedItemV2/WelcomePostsList/WelcomePostActions";
import {
  CustomColumn,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import React, { useState } from "react";
import styles from "./TopPnl.module.scss";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import ZxCard from "zxComponents/common/ZxCard/ZxCard";
import ActivityEngagements from "components/ActivityEngagements/ActivityEngagements";
import { useQuery } from "@tanstack/react-query";
import { getEngagementsQueryKey } from "components/ActivityEngagements/utils";
import { ONE_DAY } from "utils/constants";
import { getEngagements } from "api/feed.api";
import { FEED_CARDS } from "../ZxFeed.utils";
import ConditionalLink from "shared/ConditionalLink";
import { shuffleArray, stopEventPropagation } from "utils/misc";

const noOp = () => {};
const TopPnlCard = ({
  card,
  location,
  comments,
  minWidth,
  minHeight,
  theme,
  hideEngagements = false,
  showBio = true,
  showFollowButton = true,
  showBorder = false,
  tokenListHeight = "40px",
  hidePnlComments = false,
  showExtra = true,
  showNetworth = true,
  avatarSize = 40,
  hideActor = false,
  cardPadding = "16px",
  baseBackground = false,
}) => {
  const [pnlPercent, setPnlPercent] = useState(null);
  const { data: engagements } = useQuery({
    queryKey: getEngagementsQueryKey(card.type, card.id),
    queryFn: () => getEngagements({ id: card.id, type: card.type }),
    staleTime: ONE_DAY,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    initialData: card.engagements,
  });
  if (!card) return null;
  const baseUrl = location.pathname;
  const numbers = [0, 50, 200, -50, -100];

  const closest = numbers.reduce((prev, curr) => {
    return Math.abs(curr - pnlPercent) <= Math.abs(prev - pnlPercent)
      ? curr
      : prev;
  });
  const commentList = comments ? comments[closest] : null;
  const isDark = theme === "dark";
  const actor =
    card.identities?.[card.actor?.identity_id] ??
    card.profiles?.[card.actor?.address] ??
    card.actor;
  return (
    <ZxCard
      minWidth={minWidth}
      minHeight={minHeight}
      padding={cardPadding}
      border={showBorder ? "1px solid var(--border-dark)" : "none"}
      background={
        isDark && !baseBackground
          ? FEED_CARDS.PNL.darkModebackground
          : "var(--base)"
      }>
      <div
        className={styles.cardContainer}
        onClick={(e) => stopEventPropagation(e)}>
        <ConditionalLink to={card?.link ?? `/${card.type}/${card.id}`}>
          {!hideActor && (
            <ZxActor
              actor={actor}
              timestamp={card.timestamp}
              showBio={showBio}
              showFollowButton={showFollowButton}
              nameLength={10}
              showNetworth={showNetworth}
              darkFollowButton={isDark}
              size={avatarSize}
            />
          )}
          <PnlTokenList
            groupedActivity={card}
            link={card.link ?? `/${card.type}/${card.id}`}
            setCombinedPnlPercentage={setPnlPercent}
            setShowPnlComments={noOp}
            profiles={card.profiles}
            minHeight={tokenListHeight}
            listSize={2}
            showExtra={showExtra}
            isHomePage={false}
          />
          {!hideEngagements && (
            <PaddingComponent paddingTop="8px">
              <CurrentEngagements
                minHeight="24px"
                type={card.type}
                contentId={card.id}
                bookmarkLink={
                  baseUrl.includes(`/bookmarks`)
                    ? baseUrl
                    : `${baseUrl}/bookmarks`
                }
                repostLink={
                  baseUrl.includes(`/reposts`) ? baseUrl : `${baseUrl}/reposts`
                }
                reactionsLink={
                  baseUrl.includes(`/likes`) ? baseUrl : `${baseUrl}/likes`
                }
              />
            </PaddingComponent>
          )}
        </ConditionalLink>
        {!hidePnlComments && (
          <div className={styles.commentsContainer}>
            {commentList && commentList.length > 0 && pnlPercent !== null ? (
              shuffleArray([...commentList]).map((cm, index) => {
                return (
                  <PostGeneratedComment
                    key={index}
                    comment={cm}
                    contentId={card.id}
                    contentType={card.type}
                    gotoNextPost={() => {}}
                  />
                );
              })
            ) : (
              <PaddingComponent height="40px" />
            )}
          </div>
        )}
        {!hideEngagements && (
          <CustomColumn
            className={styles.engagementContainer}
            gap="4px"
            overflowY="visible"
            overflowX="visible">
            <Divider margin="0 0 8px 0" />
            <ActivityEngagements
              engagements={engagements}
              hideCount
              showCurrEngagaments={false}
              postDetails={card}
              contentId={card.id}
              type={card.type}
              combinedPnlPercentage={pnlPercent}
              showPnlComments={false}
              queryKey={getEngagementsQueryKey(card.type, card.id)}
            />
          </CustomColumn>
        )}
      </div>
    </ZxCard>
  );
};

export default TopPnlCard;
