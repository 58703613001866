const { produce } = require("immer");

export const addIdentity = (identitiesById, identity) => {
  if (identitiesById == null) identitiesById = {};

  return produce(identitiesById, (draft) => {
    if (!!identity?.id) {
      draft[identity.id] = identity;
    }
  });
};
