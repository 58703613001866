import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
  RichUtils,
  getDefaultKeyBinding,
} from "draft-js";
import Editor from "@draft-js-plugins/editor";
import "draft-js-emoji-plugin/lib/plugin.css";
import "@draft-js-plugins/mention/lib/plugin.css";
import createMentionPlugin from "@draft-js-plugins/mention";
import { getSearchResults } from "api/search.api";
import { produce } from "immer";
import { useQuery } from "@tanstack/react-query";
import mentionsStyles from "./mentionsStyles.module.css";
import AnimatedSearchLoader from "components/Pages/AppModule/Header/components/AnimatedSearchLoader";
import { IMAGE_TYPES } from "components/UI/Image";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import createLinkifyPlugin, { extractLinks } from "@draft-js-plugins/linkify";
import { getFlattenedUniqueUrlsFromRawLinks } from "../CrossPost/cross_post_utils";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";

const SUPPORTED_RESULT_TYPES = ["identity", "address", "token"];

const PostTextEditor = forwardRef(
  (
    {
      setPostContent,
      editorRef,
      placeholder,
      setRawLinks = () => {},
      initialContent,
      initialRawContent,
      isSubmitDisabled,
      onSubmit,
      index,
      onFocus,
      isSuccess = false,
      emojiPlugin,
      updatePreviewImages,
    },
    ref
  ) => {
    let contentState = ContentState.createFromText(initialContent ?? "");
    let initialEditorState = EditorState.createWithContent(
      convertFromRaw(initialRawContent ?? convertToRaw(contentState))
    );

    const [editorState, setEditorState] = useState(initialEditorState);

    const clearEditorContent = useCallback(() => {
      setEditorState(EditorState.createEmpty());
      setPostContent("");
    }, [setPostContent]);

    useImperativeHandle(ref, () => ({
      clearEditorContent,
    }));

    const onChange = useCallback(
      (newEditorState) => {
        setEditorState(newEditorState);
        const rawContentState = convertToRaw(
          newEditorState.getCurrentContent()
        );
        const currentText = newEditorState.getCurrentContent().getPlainText();
        //plain text entered by user
        setPostContent(currentText, rawContentState);
        const links = extractLinks(currentText);
        const flattened = getFlattenedUniqueUrlsFromRawLinks(links);
        //set the raw links at particular index as rawLinks is array of array
        setRawLinks((rawLinks) => {
          const newRawLinks = [...rawLinks];
          newRawLinks[index] = flattened;
          return newRawLinks;
        });
      },
      [setPostContent, setRawLinks, index]
    );

    const { MentionSuggestions, plugins } = useMemo(() => {
      const mentionPlugin = createMentionPlugin({
        supportWhitespace: true,
        mentionRegExp: "(.|\\s)",
        theme: mentionsStyles,
        mentionTrigger: "@",
      });
      const { MentionSuggestions } = mentionPlugin;
      const linkifyPlugin = createLinkifyPlugin();
      const plugins = [mentionPlugin, linkifyPlugin];
      if (emojiPlugin) {
        plugins.push(emojiPlugin);
      }
      return { plugins, MentionSuggestions };
    }, [emojiPlugin]);

    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const { data: searchResults, isLoading: isMentionsLoading } =
      useFetchSearchResults({ searchTerm: searchTerm });

    const onOpenChange = useCallback((_open) => {
      setOpen(_open);
    }, []);
    const onSearchChange = useCallback(
      ({ value }) => {
        setSearchTerm(value);
        setSuggestions(
          produce((draft) => {
            // Add loading at the start of draft
            if (isMentionsLoading && !draft[0]?.__loading) {
              draft.unshift({
                name: "loading",
                __loading: true,
              });
            }
          })
        );
      },
      [isMentionsLoading]
    );

    useEffect(() => {
      if (searchResults != null) {
        // exclude results not in supported result types
        const filteredResults = searchResults.filter((r) =>
          SUPPORTED_RESULT_TYPES.includes(r.type)
        );
        const mentionSuggestions = produce(filteredResults, (draft) => {
          // make the response compatible with the mention plugin
          // remove emoji from name as regex doesnt matches name with emoji
          draft.forEach((r, idx) => {
            r.name = r.data.display_name.replace(
              /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu,
              ""
            );
            r.link = r.data.link;
            r.avatar = r.data.display_picture;
            r.is_token = r.data.is_token;
            r.id = idx;
          });
        });
        setSuggestions(mentionSuggestions);
      }
    }, [searchResults]);

    const handleKeyCommand = (command, editorState) => {
      if (command === "submit" && !isSubmitDisabled) {
        onSubmit?.();
        if (isSuccess) {
          setEditorState(EditorState.createEmpty()); // Clear editor
        }
        return "handled";
      }

      const newState = RichUtils.handleKeyCommand(editorState, command);

      if (newState && isSuccess) {
        setEditorState(newState);
        return "handled";
      }
      return "not-handled";
    };

    const mapKeyToEditorCommand = (e) => {
      if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
        return "submit";
      }
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        return undefined;
      }
      return getDefaultKeyBinding(e);
    };

    const handlePaste = (event) => {
      if (event.clipboardData && event.clipboardData.items) {
        var items = event.clipboardData.items;

        for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            var blob = items[i].getAsFile();
            var reader = new FileReader();
            reader.onload = function (event) {
              updatePreviewImages({ result: event.target.result });
            };
            reader.readAsDataURL(blob);
          }
        }
      }
    };

    return (
      <div onPaste={handlePaste}>
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          onFocus={onFocus}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          placeholder={placeholder ?? "What's on your mind today?"}
          style={{ minHeight: "3em" }}
        />
        <MentionSuggestions
          open={open}
          entryComponent={CustomEntry}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
        />
      </div>
    );
  }
);

const CustomEntry = (props) => {
  const { mention, theme, searchValue, ...parentProps } = props;

  const isToken = mention.is_token;
  const isIdentity = mention.type === "identity";
  const isEns = mention.type === "address" && mention.data.ens_name && !isToken;
  const imageType = !isToken ? IMAGE_TYPES.AVATAR : IMAGE_TYPES.TOKEN;

  const isLoadingComponent = mention.__loading;

  if (isLoadingComponent) {
    return (
      <div
        className={`${mentionsStyles.loader} ${parentProps.className ?? ""}`}
        key={`${mention.name}`}
        tabIndex={-1}
        aria-selected="false"
        aria-disabled="true">
        <AnimatedSearchLoader />
      </div>
    );
  }
  return (
    <div {...parentProps} key={`${mention.name}`}>
      <IconWithChain
        src={mention?.avatar}
        chain={mention?.data.address_chain}
        alt={mention?.name}
        imageType={imageType}
        showImageFullHeightWidth
        iconHeight="24px"
        iconWidth="24px"
        chainHeight="12px"
        chainWidth="12px"
      />

      <span className={theme.mentionSuggestionsEntryText}>{mention.name}</span>
      <VerifiedBadge profile={mention?.data} />
      {isIdentity ? <span className={theme.resultType}>Profile</span> : ""}
      {isEns ? <span className={theme.resultType}>Address</span> : ""}
      {isToken ? <span className={theme.resultType}>Token</span> : ""}
    </div>
  );
};

export default PostTextEditor;

/***** HOOKS *****/
const useFetchSearchResults = ({ searchTerm }) => {
  return useQuery({
    queryKey: [
      "search_result",
      `source:identity source:token source:ens ${searchTerm}`,
    ],
    queryFn: (props) => getSearchResults({ ...props }),
    enabled: !!searchTerm,
  });
};
