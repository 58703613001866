import classes from "./selectedTag.module.css";
import { Edit } from "@mui/icons-material";
import { PaddingComponent } from "../Components/Components";

const EditButton = ({ onEdit }) => {
  return (
    <div onClick={onEdit} className={classes.editBox}>
      <Edit className={classes.editIcon} fontSize="16px" />
      <PaddingComponent width="6px" />
      <span className={classes.editText}>Edit</span>
    </div>
  );
};

export default EditButton;
