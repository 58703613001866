import {
  CustomRow,
  CustomText,
  PaddingComponent,
  RightPanelCrossIcon,
} from "components/UI/Components/Components";
import { Handshake } from "@phosphor-icons/react";
import classes from "./ManageAccess.module.css";
import { useState, useContext, useCallback } from "react";
import CustomLinkTabBar from "components/UI/Components/CustomLinkTabBar";
import { useParams, useHistory, useLocation } from "react-router-dom";
import ActiveConnections from "components/V2/Profile/ProfileDetails/wallets/ManageAccess/ActiveConnections";
import SentConnectionRequests from "components/V2/Profile/ProfileDetails/wallets/ManageAccess/SentConnectionRequests";
import ReceivedConnectionRequests from "components/V2/Profile/ProfileDetails/wallets/ManageAccess/ReceivedConnectionRequests";
import { GlobalContext } from "contextStates/Global";
import { ProfileContext } from "contextStates/ProfileContext";

const ManageAccess = ({ pendingRequestsCount }) => {
  const { featureFlags } = useContext(GlobalContext);
  const { isLoggedInUserProfile } = useContext(ProfileContext);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const onClickManage = useCallback(() => {
    let link = "/" + params.identifier + "/wallets/manage-access";
    if (pendingRequestsCount) {
      link += "/connections";
    }
    link += location.search;
    history.push(link);
  }, [pendingRequestsCount, params.identifier, history, location]);

  // check current user
  if (!isLoggedInUserProfile) return null;

  // check flag
  if (!featureFlags.is_showing_private_wallets) return null;

  return (
    <>
      <CustomRow
        gap="4px"
        alignItems="center"
        cursor="pointer"
        onClick={onClickManage}>
        <Handshake width={18} height={18} color="var(--primary-color)" />
        <CustomText
          text="Manage Access"
          fontSize="15px"
          fontWeight="600"
          color="var(--primary-color)"
        />
        {pendingRequestsCount ? (
          <div className={classes.notificationDot}>{pendingRequestsCount}</div>
        ) : (
          ""
        )}
      </CustomRow>
    </>
  );
};

export const ManageConnectionsPanel = ({
  connectionRequetsData,
  loggedInUserDetails,
}) => {
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab === "connections" ? 1 : 0);
  const history = useHistory();
  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <RightPanelCrossIcon
          onClick={() => {
            history.goBack();
          }}></RightPanelCrossIcon>
        <span className={classes.title}>Manage Access</span>
        <span className={classes.subtitle}>
          Exchange view access of Private Wallets
        </span>
        <PaddingComponent height="16px" />
      </div>
      <PaddingComponent padding="8px 16px">
        <CustomLinkTabBar
          tabsList={["Active Connections", "Connection Requests"]}
          isLinkTabBar={false}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === 0 && (
          <ActiveConnections loggedInUserDetails={loggedInUserDetails} />
        )}
        {activeTab === 1 && (
          <ConnectionRequests
            connectionRequetsData={connectionRequetsData}
            loggedInUserDetails={loggedInUserDetails}
          />
        )}
      </PaddingComponent>
    </div>
  );
};

const ConnectionRequests = ({ loggedInUserDetails }) => {
  const [tab, setTab] = useState("received");
  return (
    <>
      <PaddingComponent padding="16px 0 0">
        <CustomRow gap="8px">
          <div
            onClick={() => setTab("received")}
            className={`${classes.tab} ${
              tab === "received" ? classes.selected : ""
            }`}>
            Received
          </div>
          <div
            onClick={() => setTab("sent")}
            className={`${classes.tab} ${
              tab === "sent" ? classes.selected : ""
            }`}>
            Sent
          </div>
        </CustomRow>
      </PaddingComponent>
      {tab === "received" && (
        <ReceivedConnectionRequests loggedInUserDetails={loggedInUserDetails} />
      )}
      {tab === "sent" && (
        <SentConnectionRequests loggedInUserDetails={loggedInUserDetails} />
      )}
    </>
  );
};

export default ManageAccess;
