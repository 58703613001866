import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "utils/query_utils";
import { fetchCodexBars } from "components/V2/TradingViewGraph/TradingView.utils";
import { useInView } from "react-intersection-observer";
import styles from "./LocalTrendingFeedItem.module.scss";
import Graph from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/Graph";
import { abbreviateNumber } from "utils/misc";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";

const LocalTrendingFeedChart = ({
  groupedActivity,
  fetchData = false,
  chain,
  address,
  hasOtherActors = false,
}) => {
  const [ref, inView] = useInView();
  const [graphData, setGraphData] = useState(null);
  const { data } = useQuery({
    queryKey: QUERY_KEYS.TOKEN_PRICE_CHART({
      chainId: chain,
      contractAddress: address,
    }),
    queryFn: () => {
      // get 24h ago timestamp
      const from = Math.floor(Date.now() / 1000) - 86400;
      return fetchCodexBars({
        resolution: "15",
        from,
        to: Math.floor(Date.now() / 1000),
        countBack: 30,
        chain,
        poolAddress: address,
      });
    },
    enabled: fetchData && inView && fetchData,
    staleTime: 1000 * 5,
  });
  useEffect(() => {
    if (groupedActivity?.graph_points?.length > 0) {
      setGraphData(
        groupedActivity.graph_points.map((g) => {
          const [t, , , , c] = g;
          return {
            x: t * 1000,
            y: {
              value: c,
              display_value: `$${abbreviateNumber(c)}`,
            },
          };
        })
      );
    }
    if (data) {
      setGraphData(data);
    }
  }, [data, groupedActivity]);
  return (
    <div ref={ref} className={styles.graphContainer}>
      {graphData && (
        <Graph
          graphData={{ data: graphData }}
          showRightPrice
          showRightCircle
          parentWidth={400}
          parentHeight={hasOtherActors ? 260 : 200}
          horizontalGridLines={4}
          showCrossHair
          hideHover={false}
          margin={{ left: 0, right: 20, bottom: 0, top: 0 }}
          isYAxisVisible={false}
          xTickFormatter={(t) => {
            const date = new Date(t * 1000);
            let hours = date.getHours();
            if (hours < 10) hours = `0${hours}`;
            return `${hours}:${date.getMinutes()}`;
          }}
          isReverse={false}
        />
      )}
    </div>
  );
};

export default withErrorBoundary(LocalTrendingFeedChart);
