import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import React from "react";

const withNullableError = (Component) => {
  const WithNullableError = (props) => {
    return (
      <ErrorBoundary fallback={null}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
  return WithNullableError;
};

export default withNullableError;
