import { getNetworth } from "utils/profile_utils";
import { TableRowTextComponent } from "../../components/WalletComponents";
import AddressComponent from "components/UI/Components/AddressComponent";
import WalletRowComponent, {
  TableRowItem,
} from "../../components/WalletRowComponent";

const OtherUserBundleWalletTile = ({ bundleData, wallet, index }) => {
  return (
    <WalletRowComponent>
      <TableRowItem flex={3}>
        <AddressComponent index={index + 1} address={wallet.address} />
      </TableRowItem>
      <TableRowItem flex={3}>
        <TableRowTextComponent
          text={wallet.reason ? wallet.reason : "-"}
          isTooltip></TableRowTextComponent>
      </TableRowItem>
      <TableRowItem alignment="right" flex={2}>
        <TableRowTextComponent
          text={getNetworth(wallet)}></TableRowTextComponent>
      </TableRowItem>
    </WalletRowComponent>
  );
};

export default OtherUserBundleWalletTile;
