import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import styles from "./TokenPageRightBar.module.scss";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import {
  CustomSkeleton,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import {} from "hugeicons-react";
import {
  ContractsIcon,
  UserSharingIcon,
  ArrowRight01Icon,
} from "hugeicons-react";
import { getTrimedAddress } from "utils/misc";
import ZxCopy from "zxComponents/common/ZxCopy/ZxCopy";
import ConditionalLink from "shared/ConditionalLink";
import Image from "components/UI/Image";
export const LiquidityFDVBar = ({ fdv, liquidity }) => {
  return (
    <ZxFlex
      className={styles.liquidityFDVBar}
      justify="space-between"
      width="100%">
      <ZxFlex dir="column" className={styles.liquidity} flex="1">
        <ZxText style={TSTYLES.secondaryTitle} color="text-3" fontSize="10px">
          LIQUIDITY
        </ZxText>
        <ZxText style={TSTYLES.title1} fontSize="16px">
          {liquidity}
        </ZxText>
      </ZxFlex>
      <ZxFlex dir="column" flex="1">
        <ZxText style={TSTYLES.secondaryTitle} color="text-3" fontSize="10px">
          FDV
        </ZxText>
        <ZxText style={TSTYLES.title1} fontSize="16px">
          {fdv}
        </ZxText>
      </ZxFlex>
    </ZxFlex>
  );
};

export const VolumeInfo = ({
  totalVolume,
  buys,
  sells,
  buyPercent,
  sellPercent,
  buyVolume,
  sellVolume,
  buyVolumePercent,
  sellVolumePercent,
  volumeTitle,
}) => {
  return (
    <ZxFlex
      dir="column"
      className={styles.volumeInfo}
      align="flex-start"
      width="100%">
      <ZxFlex justify="space-between" width="100%">
        <ZxText style={TSTYLES.title1} fontSize="13px">
          {volumeTitle}
        </ZxText>
        <ZxText style={TSTYLES.title1} fontSize="15px">
          {totalVolume}
        </ZxText>
      </ZxFlex>
      <Divider margin="4px 0" borderColor="var(--border-light)" />
      {/* Buy bar */}
      <ZxFlex dir="column" width="100%" gap="8px" align="flex-start">
        <ZxFlex gap="8px" justify="space-between" width="100%">
          <ZxFlex dir="column" gap="4px" align="flex-start">
            <ZxText style={TSTYLES.secondary1} fontSize="11px">
              Buys
            </ZxText>
            <ZxFlex>
              <ZxText fontSize="13px" style={TSTYLES.title1}>
                {buys}
              </ZxText>
            </ZxFlex>
          </ZxFlex>

          <ZxFlex dir="column" align="flex-end">
            <ZxFlex>
              <ZxText style={TSTYLES.secondary1} fontSize="11px">
                Sells
              </ZxText>
            </ZxFlex>
            <ZxFlex>
              <ZxText fontSize="13px" style={TSTYLES.title1}>
                {sells}
              </ZxText>
            </ZxFlex>
          </ZxFlex>
        </ZxFlex>
        <ZxFlex width="100%" className={styles.bar}>
          <div className={styles.buyBar} style={{ width: `${buyPercent}%` }} />
          <div
            className={styles.sellBar}
            style={{ width: `${sellPercent}%` }}
          />
        </ZxFlex>
        {/* sell bar */}
      </ZxFlex>
      <PaddingComponent height="8px" />
      {/* volume */}
      <ZxFlex dir="column" width="100%" gap="8px">
        <ZxFlex gap="8px" justify="space-between" width="100%">
          <ZxFlex dir="column" gap="4px" align="flex-start">
            <ZxText style={TSTYLES.secondary1} fontSize="11px">
              Buy Vol
            </ZxText>
            <ZxFlex>
              <ZxText fontSize="13px" style={TSTYLES.title1}>
                {buyVolume}
              </ZxText>
            </ZxFlex>
          </ZxFlex>

          <ZxFlex dir="column" align="flex-end">
            <ZxFlex>
              <ZxText style={TSTYLES.secondary1} fontSize="11px">
                Sell Vol
              </ZxText>
            </ZxFlex>
            <ZxFlex>
              <ZxText fontSize="13px" style={TSTYLES.title1}>
                {sellVolume}
              </ZxText>
            </ZxFlex>
          </ZxFlex>
        </ZxFlex>
        <ZxFlex width="100%" className={styles.bar}>
          <div
            className={styles.buyBar}
            style={{ width: `${buyVolumePercent}%` }}
          />
          <div
            className={styles.sellBar}
            style={{ width: `${sellVolumePercent}%` }}
          />
        </ZxFlex>
        {/* sell bar */}
      </ZxFlex>
    </ZxFlex>
  );
};

export const TokenInfo = ({ deployer, token, deployedAt }) => {
  return (
    <ZxFlex className={styles.tokenInfo} dir="column" align="flex-start">
      <ZxText style={TSTYLES.title1} fontSize="13px">
        Token Info
      </ZxText>
      <Divider margin="8px 0" />
      {/* CA */}
      {!token ? (
        <ZxFlex dir="column" width="100%" gap="8px">
          {[1, 2, 3, 4].map((i) => (
            <CustomSkeleton
              width="100%"
              variant="rectangular"
              height="24px"
              borderRadius="4px"
              key={i}
            />
          ))}
        </ZxFlex>
      ) : (
        <>
          <ZxFlex dir="column" width="100%">
            <ZxFlex justify="space-between" width="100%">
              <ZxFlex gap="8px">
                <ContractsIcon
                  color="var(--text-2)"
                  size={16}
                  strokeWidth="2px"
                />
                <ZxText fontSize="13px">CA </ZxText>
              </ZxFlex>
              {/* CA */}
              <ZxFlex>
                <ZxText fontSize="13px" style={TSTYLES.title1}>
                  {" "}
                  {getTrimedAddress(token?.profile?.address)}
                </ZxText>
                <ZxCopy
                  value={token?.profile?.address}
                  iconColor="var(--text-1)"
                />
              </ZxFlex>
            </ZxFlex>
            {/* CREATOR */}
          </ZxFlex>
          {deployer && <Divider margin="8px 0" />}
          {/* CREATOR */}
          {deployer && (
            <ZxFlex dir="column" width="100%">
              <ZxFlex justify="space-between" width="100%">
                <ZxFlex gap="8px">
                  <UserSharingIcon
                    color="var(--text-2)"
                    size={16}
                    strokeWidth="2px"
                  />
                  <ZxText fontSize="13px">Creator </ZxText>
                </ZxFlex>
                {/* CA */}
                <ConditionalLink to={deployer?.link}>
                  <ZxFlex>
                    <Image
                      src={deployer?.display_picture}
                      name={deployer?.display_name}
                      width="16px"
                      height="16px"
                      style={{ borderRadius: "50%" }}
                    />
                    <ZxText fontSize="13px" style={TSTYLES.title1}>
                      {" "}
                      {getTrimedAddress(deployer?.address)}
                    </ZxText>
                    <ArrowRight01Icon
                      size={16}
                      color="var(--text-1)"
                      strokeWidth="2px"
                    />
                  </ZxFlex>
                </ConditionalLink>
              </ZxFlex>
              {/* CREATOR */}
            </ZxFlex>
          )}
          {/* CRATED AT */}
          {/* {deployedAt && (
            <ZxFlex dir="column" width="100%">
              <ZxFlex justify="space-between" width="100%">
                <ZxFlex gap="8px">
                  <Calendar03Icon
                    color="var(--text-2)"
                    size={16}
                    strokeWidth="2px"
                  />
                  <ZxText fontSize="13px">Created </ZxText>
                </ZxFlex>
                <TimeLabel
                  timestamp={deployedAt}
                  color="var(--text-1)"
                  fontWeight="500"
                />
              </ZxFlex>
            </ZxFlex>
          )}
          <Divider margin="8px 0" /> */}
          {/* Twitter search */}
        </>
      )}
    </ZxFlex>
  );
};
