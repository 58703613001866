import { isValidInviteCode, moveOnboardingState } from "api/waitlist.api";
import LoaderButton from "components/UI/LoaderButton/LoaderButton";
import { GlobalContext } from "contextStates/Global";
import { useContext, useState } from "react";
import useNavigateNewOnboarding from "../NavigateOnboarding";
import OnboardingBg from "../OnboardingBg";
import classes from "./InviteCode.module.css";
import { getOnboardingData } from "api/waitlist.api";
import { useHistory } from "react-router-dom";
import {
  CustomRow,
  SpinnerComponent,
} from "components/UI/Components/Components";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { trackEvent } from "utils/event_tracking";
import { logSentryEvent } from "utils/sentry";

const InviteCode = () => {
  const [inviteCode, setInviteCode] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isSkipLoading, setIsSkipLoading] = useState(false);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const [navigateOnboarding, setNavigateOnboarding] = useState(false);
  const loadingNavigation = useNavigateNewOnboarding(
    navigateOnboarding,
    setNavigateOnboarding
  );
  const history = useHistory();

  const disabledButton = !inviteCode || inviteCode.length < 8;

  const handleSubmit = async () => {
    trackEvent("onboarding_invite_code_submit");
    setIsButtonLoading(true);
    let code = inviteCode;
    // removing the code from the link if they have pasted the whole link
    if (inviteCode.includes("code=")) {
      code = inviteCode.split("code=")[1];
      setInviteCode(code);
    }
    try {
      const data = {
        invite_code: code,
        identifier: null,
      };
      const isValid = await isValidInviteCode({ inviteCode: code });
      if (!isValid) throw new Error("Invalid invite code");
      const authRes = await getOnboardingData(data);
      if (authRes.status === 200) {
        const curStep = authRes?.data?.data?.current_step;
        const currRoute = `/onboarding/${curStep}`;
        history.push(currRoute);
      }
    } catch (err) {
      handleErrorSnackbar(
        null,
        "Looks like your invite code is invalid, please try again."
      );
    } finally {
      setIsButtonLoading(false);
    }
  };

  const handleMoveToNextState = async (e) => {
    trackEvent("onboarding_invite_code_skip");
    setIsSkipLoading(true);
    try {
      let updateOnboarding = await moveOnboardingState({
        currentStep: "add_invite_code",
      });
      if (updateOnboarding.status === 200) {
        setNavigateOnboarding(true);
      }
    } catch (err) {
      logSentryEvent(err, {
        message: "Error in handleMoveToNextState",
      });
      handleErrorSnackbar(null, "Something went wrong, please try again.");
      setIsSkipLoading(false);
    }
  };

  return (
    <BaseWrapper mixpanelEvent="OnboardingInviteCode">
      <OnboardingBg>
        <div className={classes.bioContent}>
          <div className={classes.header}>
            <h1 className={classes.mainTitle}>Got an invite code?</h1>
            <p className={classes.subtitle}>
              Join the degen fam, use code to join 0xPPL
            </p>
          </div>

          <div className={classes.inputContainer}>
            <input
              type="text"
              value={inviteCode}
              onChange={(e) => setInviteCode(e.target.value)}
              placeholder="Enter code here..."
              className={classes.input}
            />
          </div>

          <LoaderButton
            onClick={handleSubmit}
            height={48}
            borderRadius={12}
            checkLogin={false}
            disabled={disabledButton}
            loadingText={"Verifying..."}
            className={`${classes.continueButton} ${
              disabledButton ? classes.disabled : ""
            }`}
            loading={!isSkipLoading && (isButtonLoading || loadingNavigation)}>
            Submit
          </LoaderButton>
          <CustomRow
            role="button"
            className={classes.noInviteCode}
            gap="8px"
            alignItems="center"
            justifyContent="center"
            onClick={handleMoveToNextState}>
            {isSkipLoading ? (
              <SpinnerComponent color="var(--primary-color" size={24} />
            ) : (
              <span>I don’t have an invite code</span>
            )}
          </CustomRow>
        </div>
      </OnboardingBg>
    </BaseWrapper>
  );
};

export default InviteCode;
