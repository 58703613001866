import {
  CustomColumn,
  CustomRow,
  CustomTextSpan,
  Divider,
  PaddingComponent,
  LoadingHoverCard,
} from "components/UI/Components/Components";
import { Detective } from "@phosphor-icons/react";
import classes from "./DevTools.module.css";
import buttonClasses from "components/UI/Buttons/buttons.module.css";
import { useState, useEffect } from "react";
import Spinner from "shared/buttonloadingSpinner";
import CustomTextField from "components/FollowEditButton/CustomTextField";
import ImpersonatorBox from "./ImpersonatorBox";
import CustomDropdownButton from "components/UI/Components/CustomDropDown";
import { fetchChainIds } from "api/feed.api";
import withLoader from "components/core/withLoader";
import {
  initWeb3Wallet,
  updateSession,
  killSession,
  initialiseWeb3Wallet,
} from "./ImpersonateWalletConnect";
import Image from "components/UI/Image";
import {
  saveImpersonateSession,
  getImpersonateSession,
  getChainNameAndIdDict,
  getChainNamesAndIdsListFromSession,
  isValidSession,
} from "./ImpersonatorUtils";
import ConditionalLink from "shared/ConditionalLink";

const ImpersonatePopUp = ({ queryData, setIsDevToolsConnected }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [walletConnectUri, setWalletConnectUri] = useState("");
  const [selectedChain, setChain] = useState(null);
  const [address, setAddress] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const isDisabled = !walletConnectUri || !address;
  const chainIdsAndNames = getChainNameAndIdDict({ chainsData: queryData });
  const [error, setError] = useState(null);
  const [chainNamesListFromSession, setChainNamesListFromSession] = useState(
    []
  );

  useEffect(() => {
    initialiseWeb3Wallet();
    const chainsList = getChainNamesAndIdsListFromSession({
      chainsData: queryData,
    });
    if (chainsList) {
      setChainNamesListFromSession(chainsList);
      setChain(chainsList[0]);
    }
    const session = getImpersonateSession();
    if (isValidSession(session)) {
      setIsConnected(true);
    }
  }, [queryData]);
  const disconnectSession = async ({ topic }) => {
    setIsConnected(false);
    setChainNamesListFromSession([]);
    await killSession({ topic });
    setWalletConnectUri("");
    setIsDevToolsConnected(false);
  };

  const changeChainAndUpdateSession = async ({ chain }) => {
    if (!chain || getImpersonateSession() == null) return;
    const [isSuccess, error] = await updateSession({
      newChainId: chainIdsAndNames[chain],
      newAddress: address,
    });
    if (isSuccess) {
      setChain(chain);
    } else {
      setError(error);
    }
  };

  const onUserDeleted = () => {
    setIsConnected(false);
    setChainNamesListFromSession([]);
    //delete session
    saveImpersonateSession(false);
  };
  const onSubmit = async () => {
    if (isLoading || isDisabled) return;
    const onConnected = (session) => {
      setIsLoading(false);
      if (isValidSession(session)) {
        const chainNamesList = getChainNamesAndIdsListFromSession({
          chainsData: queryData,
        });
        setChainNamesListFromSession(chainNamesList);
        setIsConnected(true);
        setChain(chainNamesList[0]);
        setIsDevToolsConnected(true);
      } else {
        setIsConnected(false);
        setError("Counldn't connect to wallet!");
      }
    };
    try {
      setIsLoading(true);
      setError("");
      await initWeb3Wallet({
        address: address,
        uri: walletConnectUri,
        chainId: chainIdsAndNames[selectedChain],
        onConnected: onConnected,
        onDisconnect: disconnectSession,
      });
    } catch (e) {
      setIsLoading(false);
      setIsConnected(false);
      setError(e.message);
    }
  };
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={classes.popUpContainer}>
      <CustomColumn>
        <CustomTextSpan fontWeight="600">Dev Tools</CustomTextSpan>
        <PaddingComponent height="10px" />
        <Divider />
        <PaddingComponent height="16px" />
        <CustomTextSpan fontSize="13px" color="var(--text-2)" fontWeight="600">
          Read/ Write Contracts on 0xPPL as
        </CustomTextSpan>
        <PaddingComponent height="10px" />
        <ImpersonatorBox
          isDevToolsPopUp={true}
          onWalletSelected={(address) => {
            setAddress(address);
          }}
          onRemoveUser={onUserDeleted}
        />
        <PaddingComponent height="10px" />
        <CustomTextSpan fontSize="13px" color="var(--text-2)">
          You can search or visit any contract on 0xPPL to start accessing it as
          the selected identity.
        </CustomTextSpan>
        <PaddingComponent height="18px" />
        <Divider />
        <PaddingComponent height="16px" />
        <CustomRow>
          <Detective size={24} color="var(--text-1)" />
          <PaddingComponent width="16px" />
          <CustomTextSpan fontWeight="600">Impersonate User</CustomTextSpan>
        </CustomRow>
        <PaddingComponent height="8px" />
        <CustomTextSpan fontSize="13px" color="var(--text-2)">
          Login to DApps via WalletConnect with the selected user’s identity
        </CustomTextSpan>
        {!isConnected && (
          <CustomColumn>
            <PaddingComponent height="8px" />
            <CustomTextSpan
              fontSize="13px"
              color="var(--text-2)"
              fontWeight="600">
              Wallet Connect URI (of DApp)
            </CustomTextSpan>
            <PaddingComponent height="8px" />
            <CustomTextField
              size="small"
              placeholder="wc:abc123"
              fullWidth
              value={walletConnectUri}
              onChange={(e) => {
                setWalletConnectUri(e.target.value);
              }}
            />
            <PaddingComponent height="8px" />
            <CustomTextSpan fontSize="13px" color="var(--text-2)">
              Visit any DApp and select WalletConnect. <br></br>Click “Copy to
              Clipboard” beneath the QR code and paste it over here.
            </CustomTextSpan>
          </CustomColumn>
        )}
        <PaddingComponent height="16px" />
        {isConnected ? (
          <ImpersonateConnected
            disconnectSession={disconnectSession}
            selectedChain={selectedChain}
            chainNamesListFromSession={chainNamesListFromSession}
            changeChainAndUpdateSession={changeChainAndUpdateSession}
          />
        ) : (
          <ConnectButton
            isDisabled={isDisabled}
            onClick={onSubmit}
            isLoading={isLoading}
            isConnected={isConnected}
            isError={false}
            errorMessage={error}
          />
        )}
        <PaddingComponent height="8px" />
      </CustomColumn>
    </div>
  );
};

const ChainNamesDropdown = ({
  chainNamesListFromSession,
  selectedChain,
  setChain,
}) => {
  return (
    <CustomColumn>
      <PaddingComponent height="16px" />
      <CustomTextSpan fontSize="13px" color="var(--text-2)" fontWeight="600">
        Onchain
      </CustomTextSpan>
      <PaddingComponent height="8px" />
      <CustomDropdownButton
        onSelectItem={(item) => {
          setChain(item);
        }}
        title={selectedChain ?? "Select Chain"}
        items={Object.values(chainNamesListFromSession)}
      />
    </CustomColumn>
  );
};

const ConnectButton = ({
  isLoading,
  isConnected,
  isError,
  errorMessage,
  onClick,
  isDisabled,
}) => {
  const buttonLabel = isConnected ? "Disconnect" : "Connect";

  return (
    <CustomRow alignItems="center" gap="8px">
      <button
        onClick={onClick}
        className={
          isConnected
            ? `${buttonClasses.errorButton} ${buttonClasses.rounded}`
            : buttonClasses.primaryButton
        }
        disabled={isDisabled}
        type="submit">
        {isLoading ? (
          <Spinner
            style={{
              stroke: "var(--text-white)",
              background: "transparent",
              width: "30px",
              height: "12px",
              alignSelf: "center",
            }}
          />
        ) : (
          buttonLabel
        )}
      </button>
      {isError && (
        <CustomTextSpan color="var(--error)">
          {errorMessage ?? "Error: Something went wrong"}
        </CustomTextSpan>
      )}
    </CustomRow>
  );
};

const ImpersonateConnected = ({
  disconnectSession,
  chainNamesListFromSession,
  selectedChain,
  changeChainAndUpdateSession,
}) => {
  const session = getImpersonateSession();
  return (
    <CustomColumn>
      <CustomTextSpan fontSize="13px" color="var(--success)" fontWeight="600">
        Connected to:
      </CustomTextSpan>
      <PaddingComponent height="8px" />
      <CustomRow alignItems="center">
        <ConditionalLink
          sameTab={false}
          to={{ pathname: session?.peer.metadata?.url }}>
          <Image
            className={classes.peerIcon}
            src={session?.peer.metadata?.icons[0]}
          />
        </ConditionalLink>
        <PaddingComponent width="12px" />
        <CustomColumn>
          <CustomTextSpan fontWeight="600">
            {session?.peer.metadata?.name}
          </CustomTextSpan>
          <ConditionalLink
            sameTab={false}
            to={{ pathname: session?.peer.metadata?.url }}>
            <CustomTextSpan fontSize="13px" color="var(--text-2)">
              {session?.peer.metadata?.url}
            </CustomTextSpan>
          </ConditionalLink>
        </CustomColumn>
      </CustomRow>
      {chainNamesListFromSession.length > 0 && (
        <ChainNamesDropdown
          chainNamesListFromSession={chainNamesListFromSession}
          selectedChain={selectedChain}
          setChain={(chain) => {
            changeChainAndUpdateSession({ chain });
          }}
        />
      )}
      <PaddingComponent height="16px" />
      <ConnectButton
        isDisabled={false}
        onClick={disconnectSession}
        isLoading={false}
        isConnected={true}
        isError={false}
        errorMessage=""
      />
    </CustomColumn>
  );
};

export default withLoader(ImpersonatePopUp, fetchChainIds, {
  queryKey: (_) => ["chain_ids_list"],
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  retry: 2,
  CustomLoader: () => <LoadingHoverCard minWidth={348} />,
  renderNotFound: false,
});
