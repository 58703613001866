import React, { useMemo } from "react";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import classes from "./navigationButtons.module.css";
const NavigationButtons = ({
  handleNextImage,
  handlePrevImage,
  currentImageIndex,
  totalImages,
}) => {
  const isPrevDisabled = useMemo(
    () => currentImageIndex === 0,
    [currentImageIndex]
  );

  const isNextDisabled = useMemo(
    () => currentImageIndex + 1 === totalImages,
    [currentImageIndex, totalImages]
  );

  return (
    <div className={classes.actionButtons}>
      <button
        className={`${classes.slideButton} ${classes.slideButtonPrev} ${classes.actionButton}`}
        disabled={isPrevDisabled}
        onClick={handlePrevImage}>
        <CaretLeft size={16} />
      </button>
      <button
        className={`${classes.slideButton} ${classes.slideButtonNext} ${classes.actionButton}`}
        disabled={isNextDisabled}
        onClick={handleNextImage}>
        <CaretRight size={16} />
      </button>
    </div>
  );
};

export default NavigationButtons;
