import React from "react";
import Image from "../Image";
import { ArrowSquareOut } from "@phosphor-icons/react";
import { CustomText } from "../Components/Components";

import styles from "./TokenTwitterPill.module.css";
import twitterIcon from "assets/svg/twitter_x_icon.svg";
import { getTrimmedString } from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";
const TokenTwitterPill = ({ symbol, link }) => {
  return (
    <ConditionalLink to={{ pathname: link }} sameTab={false}>
      <div className={styles.container}>
        <div className={styles["text-left"]}>
          <Image src={twitterIcon} alt="twitter" className={styles["logo"]} />
          <CustomText
            text="Tweets about "
            color="var(--text-1)"
            fontSize="15px"
            lineHeight="24px"
          />
          <CustomText
            text={getTrimmedString(symbol, 10)}
            color="var(--text-1)"
            fontSize="15px"
            lineHeight="24px"
            fontWeight="600"
          />
        </div>
        <ArrowSquareOut size={15} color="var(--primary-color)" />
      </div>
    </ConditionalLink>
  );
};

export default TokenTwitterPill;
