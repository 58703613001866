import classes from "./Filters.module.css";
import ChainRowTile from "./ChainRowTile";
import withLoader from "components/core/withLoader";
import { getNetworthData } from "api/profile.api";

const ChainFilterPopUp = ({ queryData, chainsSelected, setChainsSelected }) => {
  const netWorthList = Object.entries(queryData.data.data)
    .sort((a, b) => b[1].total_usd_value.value - a[1].total_usd_value.value)
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
  return (
    <div className={classes.chainFiltersList}>
      <span className={`${classes.title} ${classes.horizontalPadding}`}>
        TOP CHAINS
      </span>
      {Object.entries(netWorthList).map((entry, index) => {
        if (entry[0] === "_Any") return null;

        return (
          <div key={`chain-filter-${entry[0]}-${index}`}>
            <ChainRowTile
              chainsSelected={chainsSelected}
              setChainsSelected={setChainsSelected}
              chain={entry[0]}
              chainDetails={entry[1]}
              index={index}
            />
          </div>
        );
      })}
    </div>
  );
};

export default withLoader(ChainFilterPopUp, getNetworthData, {
  queryKey: (props) => ["networth", props.identifier],
  CustomLoader: () => null,
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  showRefetch: false,
  renderNotFound: false,
});
