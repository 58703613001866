import React from "react";
import styles from "./BuySellButton.module.css";
import { CustomRow, CustomText } from "components/UI/Components/Components";
import ConditionalLink from "shared/ConditionalLink";
import { Minus, Plus } from "@phosphor-icons/react";
const BuySellButton = ({ action, link }) => {
  return (
    <ConditionalLink to={{ path: link }} sameTab={false}>
      <button
        className={`${styles.button} ${
          action === "buy" ? styles["buy"] : styles["sell"]
        }`}>
        <CustomRow gap="4px" alignItems="center">
          {action === "buy" ? (
            <Plus size="16" color="#fff" weight="bold" />
          ) : (
            <Minus size="16" color="#fff" weight="bold" />
          )}
          <CustomText
            text={action === "buy" ? "Buy" : "Sell"}
            fontWeight="600"
            color="#fff"
            fontSize="15px"
            lineHeight="22.5px"
            letterSpacing="-0.02em"
          />
        </CustomRow>
      </button>
    </ConditionalLink>
  );
};

export default BuySellButton;
