import React from "react";
import { Copy01Icon, CheckmarkCircle01Icon } from "hugeicons-react";
import ZxFlex from "../ZxFlex/ZxFlex";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import styles from "./ZxCopy.module.scss";
import { PaddingComponent } from "components/UI/Components/Components";
import ZxText from "../ZxText/ZxText";
const ZxCopy = ({
  value,
  iconColor = "var(--text-2)",
  isButton = false,
  title = "Copy",
  buttonText = null,
}) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const onCopy = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };
  return (
    <ZxFlex className={styles.container} onClick={onCopy}>
      <PPLxTooltip title={isCopied ? "Copied" : title} arrow>
        {!isButton ? (
          isCopied ? (
            <CheckmarkCircle01Icon
              size={16}
              color={iconColor}
              strokeWidth="2px"
            />
          ) : (
            <Copy01Icon size={16} color={iconColor} strokeWidth="2px" />
          )
        ) : (
          <PaddingComponent
            padding="8px"
            backgroundColor="var(--elevation-1)"
            borderRadius="8px">
            <ZxFlex>
              <ZxText color="text-2" fontSize="12px">
                {buttonText}
              </ZxText>
              {isCopied ? (
                <CheckmarkCircle01Icon
                  size={16}
                  color={iconColor}
                  strokeWidth="2px"
                />
              ) : (
                <Copy01Icon size={16} color={iconColor} strokeWidth="2px" />
              )}
            </ZxFlex>
          </PaddingComponent>
        )}
      </PPLxTooltip>
    </ZxFlex>
  );
};

export default ZxCopy;
