import { useState } from "react";
import { CustomText, InfoBox } from "components/UI/Components/Components";
import SelectedNodeTransfers from "components/V2/Explorer/SelectedNodeTransfers";

const UsersTokenTransfersList = ({
  identifier,
  holding,
  tokenAddress,
  setAllTokenTransfers,
  onNewData = () => {},
}) => {
  const [tokenTransfersCount, setTokenTransfersCount] = useState(0);
  return (
    <InfoBox padding="16px" alignItems="flex-start">
      <CustomText
        text={`Your Token Transfers ${tokenTransfersCount > 0 ? `(${tokenTransfersCount})` : ""}`}
        color="var(--text-1)"
        fontWeight="600"
        fontSize="15px"
      />
      <SelectedNodeTransfers
        identifier={identifier}
        selectedActiveTokens={[tokenAddress]}
        selectedActiveChains={[holding?.str_chain_id]}
        setAllTokenTransfers={setAllTokenTransfers}
        setTokenTransfersCount={setTokenTransfersCount}
        isRightPane
        maxHeight="250px"
        onNewData={onNewData}
      />
    </InfoBox>
  );
};

export default UsersTokenTransfersList;
