import { CustomSkeleton } from "components/UI/Components/Components";
import classes from "./TopTokenCard.module.css";

const Ghost = () => {
  return (
    <div className={classes.ghost}>
      <CustomSkeleton />
      <CustomSkeleton />

      <CustomSkeleton />
      <CustomSkeleton />
      <CustomSkeleton />
    </div>
  );
};
export default Ghost;
