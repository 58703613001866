import React, { useState } from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { TwitterGhost } from "components/V2/News/TwitterGhost";
import { PaddingComponent } from "components/UI/Components/Components";

const TwitterThreads = ({ referenceThreads }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      {isLoading &&
        referenceThreads?.map((thread, index) => (
          <PaddingComponent paddingBottom="12px">
            <TwitterGhost key={index} />
          </PaddingComponent>
        ))}
      {referenceThreads?.map((thread, index) => (
        <TwitterTweetEmbed
          key={`twitter-reference-${thread}-${index}`}
          tweetId={thread}
          onLoad={() => setIsLoading(false)}
        />
      ))}
    </div>
  );
};

export default TwitterThreads;
