import classes from "./WalletsPage.module.css";
import WalletHeader from "./components/walletsHeader";
import WalletsTableHeader from "./components/walletsTableHeader";
import classesTable from "./components/walletTableHeader.module.css";
import RemovedByYouRowTile from "./RemovedByYouRowTile";
import { ReactComponent as RemovedByYouIcon } from "assets/svg/removed_by_you.svg";

const RemovedByYou = ({ bundleData, refetch }) => {
  const removedByYou = bundleData.removed_by_you ?? [];
  const count = removedByYou?.length ?? 0;

  if (count === 0) return null;

  return (
    <div className={classes.walletsBox}>
      <WalletHeader
        title={"Removed By You"}
        count={count}
        iconRender={<RemovedByYouIcon />}
      />
      <div className={classesTable.table}>
        <WalletsTableHeader
          items={[
            { name: "WALLET", flex: 2 },
            { name: "NETWORTH", flex: 2 },
            { name: "SOURCED BY", flex: 2 },
            { name: "REASON", flex: 3 },
            { name: "ACTIONS", alignment: "right", flex: 2 },
          ]}></WalletsTableHeader>
        {removedByYou?.map((wallet, index) => (
          <RemovedByYouRowTile
            key={index}
            bundleData={bundleData}
            identities={bundleData.identity_details}
            wallet={wallet}
            index={index}
            refetch={refetch}
          />
        ))}
      </div>
    </div>
  );
};

export default RemovedByYou;
