import React, { Fragment } from "react";
import {
  InfoBoxContainer,
  PaddingComponent,
} from "components/UI/Components/Components";
import BasicInfoRowTile from "components/BasicInfoRowTile/BasicInfoRowTile";

const AdditionalDetails = ({ data }) => {
  if (!data) {
    return null;
  }
  return (
    <InfoBoxContainer header="Additional Details">
      {data.map((item, index) => (
        <Fragment>
          <BasicInfoRowTile
            key={`data-${item.label}-${index}`}
            title={item.label}
            value={
              !!item.children ? (
                <div>
                  {item.children.map((child, childIndex) => (
                    <div
                      key={`child-${child.label}-${childIndex}`}>{`${child.label}: ${child.display_value}`}</div>
                  ))}
                </div>
              ) : (
                item.display_value
              )
            }>
            {/* {!!item.display_value ? item.display_value : } */}
          </BasicInfoRowTile>
          <PaddingComponent height="10px" />
        </Fragment>
      ))}
    </InfoBoxContainer>
  );
};

export default AdditionalDetails;
