// ConnectedWallets.js

import React, { useContext, useState } from "react";
import { AuthContext } from "contextStates/AuthContext";
import { useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import classes from "./ConnectedWallets.module.css";
import OnboardingBg from "../OnboardingBg";
import { Eye } from "@phosphor-icons/react";
import banner1 from "assets/svg/connected-wallet-bnr-1.svg";
import banner2 from "assets/svg/connected-wallet-bnr-2.svg";
import { getOnboardingNetWorth } from "api/waitlist.api";
import { getTrimedAddress } from "utils/misc";
import {
  PaddingComponent,
  SpinnerComponent,
} from "components/UI/Components/Components";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { trackEvent } from "utils/event_tracking";

const ConnectedWallets = () => {
  const { getUserAddress } = useContext(AuthContext);
  const [wallet] = useState(getUserAddress());
  const history = useHistory();
  if (!wallet) {
    history.push("/onboarding/connect_wallet");
  }
  const {
    data: networth,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getNetWorth", wallet],
    queryFn: () => getOnboardingNetWorth({ identifier: wallet }),
    enabled: !!wallet,
  });
  if (isError) {
    history.push("/onboarding/connect_wallet");
  }
  return (
    <BaseWrapper mixpanelEvent="OnboardingConnectedWallets">
      <OnboardingBg>
        <div className={classes.mainContent}>
          <div className={classes.walletsSection}>
            <span className={classes.mainTitle}>Connected Wallet</span>
            <PaddingComponent height="8px" />
            <div className={classes.walletInfo}>
              <div className={classes.walletAddress}>
                <Eye size={24} color="#0648d7" weight="duotone" />
                <span style={{ marginLeft: "5px" }}>
                  {getTrimedAddress(wallet)}
                </span>
              </div>
              <span className={classes.walletAmount}>
                {isLoading ? (
                  <SpinnerComponent color="var(--primary-color)" size="16" />
                ) : (
                  networth?.display_value
                )}
              </span>
            </div>
          </div>
          <div className={classes.moreWalletsSection}>
            <span className={classes.mainTitle}>
              Do more with wallets in the app
            </span>
            <PaddingComponent height="8px" />
            <img src={banner1} alt="wallet" className={classes.banner} />
            <img src={banner2} alt="wallet" className={classes.banner} />
          </div>
          <button
            className={classes.continueButton}
            onClick={() => {
              trackEvent("connected_wallets_continue_btn");
              history.push("/onboarding/connect_socials");
            }}>
            Continue
          </button>
        </div>
      </OnboardingBg>
    </BaseWrapper>
  );
};

export default ConnectedWallets;
