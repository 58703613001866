import { useAuthContext } from "contextStates/AuthContext";

const useIsLoggedInUser = (profile) => {
  const { loggedInUser } = useAuthContext();
  if (!profile || !loggedInUser) return false;
  if (profile?.type === "address") return false;
  return (
    profile?.id === loggedInUser?.id ||
    profile?.id === loggedInUser?.identity_id ||
    profile?.username === loggedInUser?.username ||
    false
  );
};

export default useIsLoggedInUser;
