import classes from "./WalletDetails.module.css";
import { RightPanelCrossIcon } from "components/UI/Components/Components";
import WalletTabBar from "./WalletDetailsTabBar";
import { getNetWorthV2 } from "utils/profile_utils";
import {
  getShortDotFormatAddress,
  getFormattedMonthNameDate,
} from "utils/profile_utils";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const WalletDetailsHeader = ({
  activeTab,
  setActiveTab,
  bundleData,
  wallet,
}) => {
  const { identifier } = useParams();
  const history = useHistory();
  const addedOn = getFormattedMonthNameDate({ date: wallet.created_at });
  const addedOnString = addedOn ? `• Added to bundle on ${addedOn}` : "";
  const title = getShortDotFormatAddress({ address: wallet.address });
  const tabs =
    Object.keys(wallet?.sourced_by)?.length > 0
      ? ["Portfolio", "Bundle Details"]
      : ["Portfolio"];
  return (
    <div className={classes.header}>
      <div className={classes.wrapper}>
        <RightPanelCrossIcon
          onClick={() => {
            history.length > 2
              ? history.goBack()
              : history.push(`/${identifier}/wallets`);
          }}
        />
        <Link
          to={`/${wallet.address}`}
          style={{ paddingBottom: "4px" }}
          className={classes.title}>
          {title}
        </Link>
        <span style={{ paddingBottom: "16px" }} className={classes.subtitle}>
          {`${getNetWorthV2(wallet)} ${addedOnString}`}
        </span>
      </div>
      <WalletTabBar
        tabsList={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}></WalletTabBar>
    </div>
  );
};
export default WalletDetailsHeader;
