import classes from "./FollowedByFollowers.module.css";
import ResultItemHints from "components/Pages/AppModule/Header/components/ResultItemHints";
import { CustomColumn } from "components/UI/Components/Components";

const MatchedFromFooter = ({ searchHints, noTopMargin }) => {
  return (
    <div
      className={classes.container}
      style={{
        marginTop: noTopMargin ? 0 : 10,
      }}>
      <CustomColumn>
        <ResultItemHints searchHints={searchHints} />
      </CustomColumn>
    </div>
  );
};

export default MatchedFromFooter;
