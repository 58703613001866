import { CircledNumber } from "components/UI/Components/CircledNumber";
import React, { useEffect, useState } from "react";
import {
  CustomColumn,
  CustomRow,
  FlexGrow,
  PaddingComponent,
  InfoBox,
} from "components/UI/Components/Components";
import { Camera, QrCode, DownloadSimple } from "@phosphor-icons/react";
import classes from "./MobileDownloadStep.module.css";
import { getQRCode, QRCodeLogin } from "components/UI/QrCode/QrCode";

const MobileDownloadStep1 = ({ showNumber = true }) => {
  return (
    <PaddingComponent padding="16px" height="100%">
      <CustomRow height="100%" width="100%">
        {showNumber && <CircledNumber>1</CircledNumber>}
        <FlexGrow>
          <CustomColumn
            height="100%"
            gap="8px"
            justifyContent="center"
            alignItems="center">
            <InfoBox
              padding="4px"
              radius="8px"
              width="136px"
              height="136px"
              background="#fff">
              <QrCodeComponent />
            </InfoBox>
            <PaddingComponent height="8px" />
            <StepInfo title="Open your phone camera" Icon={Camera} />
            <StepInfo title="Scan the above QR code" Icon={QrCode} />
            <StepInfo
              title="Download the iOS/Android app"
              Icon={DownloadSimple}
            />
          </CustomColumn>
        </FlexGrow>
      </CustomRow>
    </PaddingComponent>
  );
};

const QrCodeComponent = () => {
  const [deeplinkUrl, setDeepLinkUrl] = useState(null);
  useEffect(() => {
    getQRCode().then((res) => {
      setDeepLinkUrl(res?.data?.data?.dynamic_url);
    });
  }, []);
  if (!deeplinkUrl) return null;
  return <QRCodeLogin deeplinkUrl={deeplinkUrl} size={128} />;
};

const StepInfo = ({ title, Icon }) => {
  return (
    <CustomRow gap="4px" alignItems="center">
      <Icon size={18} weight="duotone" color="var(--primary-color)" />
      <span className={classes.step1Info}>{title}</span>
    </CustomRow>
  );
};

export default MobileDownloadStep1;
