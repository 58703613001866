import React, { useContext, useEffect, useMemo } from "react";
import classes from "./groupActivityDetailsV2.module.css";
import GroupedActivityDetailsEngagements from "./GroupedActivityDetailsEngagements";
import withLoader from "components/core/withLoader";
import { getPostDetails, useGetEngagements } from "api/feed.api";
import FeedPost from "../../FeedItemV2/FeedPost";
import { useParams } from "react-router-dom";
import Comments from "./Comments";
import StickyTabs from "components/StickyTabs/StickyTabs";
import { AuthContext } from "contextStates/AuthContext";
import { addIdentity } from "../mutators";
import GroupActivityDetailsShimmer from "./GroupActivityDetailsShimmer";
import {
  ancestorsToQueryKeys,
  getCommentCount,
  transformGroupedActivityQuery,
} from "utils/misc";
import { QueryKeyConstants } from "utils/constants";
import ParentPost from "./ParentPost";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import { CustomColumn } from "components/UI/Components/Components";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const PostDetails = ({ queryData: post, refetch }) => {
  const { active_tab } = useParams();

  const { setTitle } = useContext(LeftSideHeaderContext);
  useEffect(() => {
    setTitle("Post Details");
  }, [setTitle]);

  const { data: postEngagements } = useGetEngagements({
    id: post?.id,
    type: post?.type,
  });
  const commentCount = getCommentCount(post, postEngagements);
  const { identityDetails } = useContext(AuthContext);

  const identities = useMemo(
    () => addIdentity(post?.identities, identityDetails?.current?.identity),
    [post?.identities, identityDetails]
  );

  if (!post) return null;
  const ancestors = post?.ancestors ?? [];

  return (
    <BaseWrapper mixpanelEvent="PostDetails">
      <CustomColumn>
        <div>
          {ancestors &&
            [...ancestors]
              .reverse()
              .map((ancestor, idx) => (
                <ParentPost
                  parent={ancestor}
                  key={idx}
                  isGroupedActivityDetails={true}
                  noPadding={true}
                />
              ))}

          <FeedPost
            post={post}
            identities={identities}
            profiles={post?.profiles}
            nested
            hideEngagements
          />
          <GroupedActivityDetailsEngagements
            engagements={postEngagements}
            contentId={post?.id}
            identities={post?.identities}
            type={post?.type}
            contentTimestamp={post?.timestamp}
            queryKey={[...transformGroupedActivityQuery(post.id, post.type)]}
          />
        </div>
        <StickyTabs
          activeTab={active_tab ?? "comments"}
          commentsCount={commentCount}
          hideTransactions
          hideReferenceActivities
        />
        <div className={classes.commentsContainer}>
          {active_tab === "comments" && (
            <Comments
              key={post?.id}
              comments={postEngagements?.replies?.threads}
              contentId={post?.id}
              type={post?.type}
              postDetails={post}
              identities={identities}
              profiles={post?.profiles}
              parentId={post?.id}
              showNewComment
              queryKeys={[
                ...ancestorsToQueryKeys(post?.ancestors),
                transformGroupedActivityQuery(post?.id, post?.type, "", false),
              ]}
            />
          )}
        </div>
      </CustomColumn>
    </BaseWrapper>
  );
};

export default withLoader(
  PostDetails,
  (props) => getPostDetails(props?.postId),
  {
    queryKey: (props) => [
      QueryKeyConstants.GROUPED_ACTIVITY,
      props.postId,
      "post",
    ],
    queryOptions: (props) => ({
      enabled: !!props?.postId,
      retry: 1,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    }),
    CustomLoader: () => <GroupActivityDetailsShimmer type="post_details" />,
    refetchContainerClass: classes.refetchContainer,
  }
);
