import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getDuration } from "utils/misc";
import classes from "./TransactionsRightPanel.module.css";
import { Hourglass, CheckCircle, WarningCircle } from "@phosphor-icons/react";
import BasicInfoRowTile from "components/BasicInfoRowTile/BasicInfoRowTile";
import Spinner from "shared/buttonloadingSpinner";
import { usePendingTransactionPolling } from "./transaction_utils";

const STATUS_INFO = {
  Success: {
    icon: <CheckCircle className={classes.icon} size={16} />,
    text: "Success",
  },
  Pending: {
    icon: <Hourglass className={classes.icon} size={16} />,
    text: "Pending",
    extraText: "Mempool",
  },
  Failed: {
    icon: <WarningCircle className={classes.icon} size={16} />,
    text: "Failed",
  },
};

const TransactionStatus = ({
  blockTimestamp,
  confirmationsCount,
  transactionData,
}) => {
  const { activityId } = useParams();
  const [status, setStatus] = useState(null);
  const activity = activityId
    ? transactionData?.activities?.find((ac) => ac?.id === activityId)
    : transactionData?.activities?.[0];
  const transaction = activity?.transactions?.[0];
  const txnHash = transaction?.tx_id ?? null;
  const chainId = transactionData?.activities?.[0]?.chain_id ?? null;
  const { IsTxnPending } = usePendingTransactionPolling({
    txnHash,
    chainId,
    status,
    onSuccess: (s) => setStatus(s === 1 ? "Success" : "Failed"),
  });
  const currentStatus = status ? STATUS_INFO[status] ?? null : null;

  if (!transactionData || !txnHash) return null;
  return (
    <BasicInfoRowTile title="Status">
      <div
        className={`${classes.detailsRow} ${status ? classes?.[status] : ""}`}>
        {!!IsTxnPending && (
          <Spinner
            style={{
              stroke: "var(--text-1)",
              background: "transparent",
              width: "20px",
              height: "20px",
              alignSelf: "center",
            }}
          />
        )}
        {status && (
          <>
            {currentStatus.icon}
            <span className={classes.text}>{currentStatus.text}</span>
            {status === "Success" && !!confirmationsCount && (
              <span className={classes.subText}>{`${
                confirmationsCount > 100 ? "100+" : confirmationsCount
              } Block Confirmations `}</span>
            )}
            {currentStatus.extraText && (
              <span className={classes.subText}>{currentStatus.extraText}</span>
            )}
          </>
        )}
        {!!blockTimestamp && (
          <span className={classes.subText}>{getDuration(blockTimestamp)}</span>
        )}
      </div>
    </BasicInfoRowTile>
  );
};

export default TransactionStatus;
