import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "components/UI/Loader/Loader";
import Refetch from "components/UI/Refetch/Refetch";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { Redirect } from "react-router-dom";
import { useEffect, useMemo } from "react";

const withLoader = (
  WrappedComponent,
  queryFn,
  {
    queryKey: queryKeyFunc = () => `${[Math.random()]}`,
    queryOptions = () => {
      return {};
    },
    showLoaderWhileFetching = false,
    CustomLoader = Loader,
    customError,
    onSuccess,
    renderNotFound = false,
    showRefetch = true,
    containerClass = "",
    refetchContainerClass = "",
    errorSize = "100px",
  }
) => {
  const WithLoader = ({ refetchRef, resetOnRefetch, ...props }) => {
    const queryKey = useMemo(() => queryKeyFunc(props), [props]);
    const options = queryOptions(props);
    const queryClient = useQueryClient();
    const {
      data: queryData,
      isLoading,
      isError,
      isFetching,
      refetch,
      error,
    } = useQuery({
      queryKey,
      queryFn: () => queryFn(props),
      onSuccess: (resp) => {
        if (onSuccess) {
          onSuccess(resp, queryClient);
        }
      },
      retry: options.retry !== undefined ? options.retry : 2,
      refetchInterval: options.refetchInterval || false,
      refetchOnWindowFocus: options.refetchOnWindowFocus || false,
      refetchOnMount: options.refetchOnMount || false,
      ...options,
    });
    if (process.env.NODE_ENV === "development" && error) {
      console.log(error);
    }

    useEffect(() => {
      if (refetchRef) {
        refetchRef.current = () => {
          if (resetOnRefetch) queryClient.removeQueries(queryKey);
          refetch();
        };
      }
    }, [refetchRef, resetOnRefetch, refetch, queryClient, queryKey]);

    if (isLoading) {
      return <CustomLoader {...props} />;
    }
    if (showLoaderWhileFetching && isFetching) {
      return <CustomLoader {...props} />;
    }
    if (isError) {
      if (
        renderNotFound &&
        (error.toString().includes("status code 404") ||
          error.toString().includes("status code 400"))
      ) {
        return <Redirect to="/404" />;
      }
      if (showRefetch) {
        if (customError !== undefined) {
          return customError;
        }
        let text = error?.response?.data?.errors?.errors;
        if (Array.isArray(text)) {
          text = text[0]?.msg ?? text[0]?.message ?? "Something went wrong";
        }
        return (
          <Refetch
            size={errorSize}
            onClick={refetch}
            text={text}
            title={props.title}
            containerClass={refetchContainerClass}
          />
        );
      }
      return null;
    }

    return (
      <ErrorBoundary
        containerClass={`${containerClass}`}
        fallback={customError}>
        <WrappedComponent
          queryData={queryData}
          queryKey={queryKey}
          refetch={refetch}
          isFetching={isFetching}
          isLoading={isLoading}
          isError={isError}
          {...props}
        />
      </ErrorBoundary>
    );
  };

  return WithLoader;
};

export default withLoader;
