import React from "react";
import { CustomRow } from "components/UI/Components/Components";
import CustomSwitchButton from "components/UI/Components/CustomSwitch";
import classes from "./parseToggleSwitch.module.css";

const ParseToggleSwitch = ({
  showParsed,
  setShowParsed,
  leftText,
  rightText,
  isDisabled = false,
  enableSwitch,
}) => {
  return (
    <ToggleSwitch
      enabled={!showParsed} // Parsed is the left side of the toggle
      setIsEnabled={(state) => setShowParsed(!state)}
      leftText={leftText}
      isDisabled={isDisabled}
      rightText={rightText}
      enableSwitch={enableSwitch}
    />
  );
};

//its re-usable component
export const ToggleSwitch = ({
  enabled,
  setIsEnabled,
  leftText = "",
  rightText = "",
  isDisabled = false,
  enableSwitch = true,
}) => {
  return (
    <div className={`${isDisabled ? classes.disabled : ""}`}>
      <CustomRow
        gap="6px"
        justifyContent="center"
        alignItems="center"
        background="none">
        {leftText && (
          <span
            className={
              !enabled
                ? classes.switchSelectedText
                : classes.switchUnselectedText
            }>
            {leftText}
          </span>
        )}
        {enableSwitch && (
          <CustomSwitchButton
            toggle={isDisabled ? false : enabled}
            isDisabled={isDisabled}
            setToggle={() => {
              setIsEnabled(!enabled);
            }}
          />
        )}

        <span
          className={
            enabled ? classes.switchSelectedText : classes.switchUnselectedText
          }>
          {rightText}
        </span>
      </CustomRow>
    </div>
  );
};
export default ParseToggleSwitch;
