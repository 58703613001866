import buttonClasses from "components/UI/Buttons/buttons.module.css";
import formClasses from "components/UI/PopUpForm/PopUpForm.module.css";
import { useState } from "react";
import classes from "components/V2/Profile/ProfileDetails/Filters/Filters.module.css";
import ExplorerChainFilterPopUp from "./ExplorerChainFilterPopUp";
import ExplorerTokensFilterList from "./ExplorerTokensFilterList";
import CustomLinkTabBar, {
  SIZE,
} from "components/UI/Components/CustomLinkTabBar";

const ExplorerFilterPopUp = ({
  identifiers,
  setShowPopUpCard,
  setSelectedChains,
  selectedActiveChains,
  selectedActiveTokens,
  setSelectedTokens,
  onSuccess,
  tokenTuples,
  setTokenTuples,
  filterChains,
  setFilterChains,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [chainsSelected, setChainsSelected] = useState(selectedActiveChains);
  const [tokensSelected, setTokensSelected] = useState(selectedActiveTokens);
  const [tuplesSelected, setTuplesSelected] = useState(tokenTuples);
  const [filterChainsSelected, setFilterChainsSelected] =
    useState(filterChains);
  const disabled = false; // currently no way to clear selection in UI, cannot disable yet
  // const disabled = chainsSelected.length === 0 && tokensSelected.length === 0;

  const onApply = () => {
    setSelectedChains(chainsSelected);
    setSelectedTokens(tokensSelected);
    setTokenTuples(tuplesSelected);
    setFilterChains(filterChainsSelected);
    setShowPopUpCard(false);
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <div className={formClasses.popup_form}>
      <div className={classes.formPadding}>
        <div className={classes.headerRow}>
          <span className={classes.headerByText}> By: </span>
          <CustomLinkTabBar
            isEnabled={true}
            isLinkTabBar={false}
            size={SIZE.SMALL}
            tabsList={["Chains", "Tokens"]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}></CustomLinkTabBar>
        </div>
        {activeTab === 0 && (
          <ExplorerChainFilterPopUp
            identifiers={identifiers}
            setChainsSelected={setFilterChainsSelected}
            chainsSelected={filterChainsSelected}
          />
        )}
        {activeTab === 1 && (
          <ExplorerTokensFilterList
            identifiers={identifiers}
            setTokensSelected={setTokensSelected}
            tokensSelected={tokensSelected}
            setChainsSelected={setChainsSelected}
            chainsSelected={chainsSelected}
            tokenTuples={tuplesSelected}
            setTokenTuples={setTuplesSelected}
            filterChains={filterChainsSelected}
            setFilterChains={setFilterChainsSelected}
          />
        )}
      </div>
      <div className={formClasses.divider}></div>
      <div className={classes.buttonPadding}>
        <button
          className={`${buttonClasses.popupButton} ${
            disabled ? buttonClasses.disabled : ""
          }`}
          onClick={onApply}
          disabled={disabled}>
          <div>Apply</div>
        </button>
      </div>
    </div>
  );
};

export default ExplorerFilterPopUp;
