import React, { useState } from "react";
import styles from "./ZxTokenStats.module.scss";
import rightBarStyles from "../ZxTokenPage/TokenPageRightBar/TokenPageRightBar.module.scss";
import { useGetTokenPriceChange, useGetTokenStats } from "api/token_page.api";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { abbreviateNumber } from "utils/misc";
import { CustomSkeleton } from "components/UI/Components/Components";
import {
  LiquidityFDVBar,
  VolumeInfo,
} from "../ZxTokenPage/TokenPageRightBar/TokenPageRightBar.utils";
const indexMap = {
  0: "5M",
  1: "1H",
  2: "4H",
  3: "24H",
};
const ZxTokenStats = ({
  chain,
  address,
  tokenType,
  poolAddress,
  tokenPrice,
  fdv,
  liquidity,
}) => {
  const {
    data,
    isLoading: isStatsLoading,
    isError: isStatsError,
  } = useGetTokenStats({ chain, address: poolAddress, tokenType });
  const {
    data: priceChange,
    isLoading: isPriceChangeLoading,
    isError: isPriceChangeError,
  } = useGetTokenPriceChange({
    chain,
    address: poolAddress,
  });
  const [activeTab, setActiveTab] = useState(3);
  const { stats_min5, stats_hour1, stats_hour4, stats_day1 } = data ?? {};
  const { priceChange5m, priceChange1, priceChange4, priceChange24 } =
    priceChange ?? {};
  const stats = [stats_min5, stats_hour1, stats_hour4, stats_day1];
  const changes = [priceChange5m, priceChange1, priceChange4, priceChange24];
  const currentStats = stats[activeTab];
  const buys = currentStats?.buys?.currentValue;
  const sells = currentStats?.sells?.currentValue;
  const total = buys + sells;
  const buyPercent = (buys / total) * 100;
  const sellPercent = (sells / total) * 100;
  const buyVolume = currentStats?.buyVolume?.currentValue ?? 0;
  const sellVolume = currentStats?.sellVolume?.currentValue ?? 0;
  const totalVolume = Number(buyVolume) + Number(sellVolume);
  const buyVolumePercent = (buyVolume / totalVolume) * 100;
  const sellVolumePercent = (sellVolume / totalVolume) * 100;
  if ((!priceChange && !isPriceChangeLoading) || isPriceChangeError)
    return null;
  return (
    <div className={rightBarStyles.card}>
      <ZxFlex dir="column" gap="12px" width="100%" align="flex-start">
        {fdv && liquidity && (
          <LiquidityFDVBar fdv={fdv} liquidity={liquidity} />
        )}
        {isPriceChangeLoading || !priceChange ? (
          <CustomSkeleton
            variant="rectangular"
            borderRadius="4px"
            width="100%"
            height="44px"
          />
        ) : !isPriceChangeError ? (
          <div className={styles.priceContainer}>
            {stats.map((stat, index) => {
              const change = changes[index] ? Number(changes[index]) : null;
              const isLast = index === stats.length - 1;
              const isFirst = index === 0;
              const isActive = activeTab === index;
              const bgClass =
                change > 0 ? styles.activeGreen : styles.activeRed;
              const abbr = abbreviateNumber(change * 100);
              const trim = abbr?.length > 5 ? abbr.slice(0, 5) : abbr;
              return (
                <ZxFlex
                  dir="column"
                  cursor="pointer"
                  key={index}
                  onClick={() => setActiveTab(index)}
                  className={`${styles.priceChange} ${isLast ? styles.last : ""} ${isFirst ? styles.first : ""} ${isActive ? bgClass : ""}`}>
                  <ZxText style={TSTYLES.secondaryLabel}>
                    {indexMap[index]}
                  </ZxText>
                  <ZxText
                    style={TSTYLES.title1}
                    fontSize="11px"
                    color={
                      change ? (change >= 0 ? "success" : "error") : "text-1"
                    }>
                    {change ? trim : "-"}
                    {change ? "%" : ""}
                  </ZxText>
                </ZxFlex>
              );
            })}
          </div>
        ) : null}
        {isStatsLoading || !data ? (
          <ZxFlex dir="column" gap="8px" width="100%">
            {[1, 2, 3].map((i) => (
              <CustomSkeleton
                key={i}
                variant="rectangular"
                borderRadius="4px"
                width="100%"
                height="24px"
              />
            ))}
          </ZxFlex>
        ) : isStatsError ? null : (
          <VolumeInfo
            totalVolume={`$${abbreviateNumber(stats[activeTab]?.volume?.currentValue)}`}
            buys={abbreviateNumber(buys ?? 0)}
            sells={abbreviateNumber(sells ?? 0)}
            volumeTitle={`${indexMap[activeTab]?.toLowerCase()} Volume`}
            buyVolume={abbreviateNumber(
              stats[activeTab]?.buyVolume?.currentValue ?? 0
            )}
            sellVolume={abbreviateNumber(
              stats[activeTab]?.sellVolume?.currentValue ?? 0
            )}
            buyVolumePercent={buyVolumePercent}
            sellVolumePercent={sellVolumePercent}
            buyPercent={buyPercent}
            sellPercent={sellPercent}
          />
        )}
      </ZxFlex>
    </div>
  );
};

export default ZxTokenStats;
