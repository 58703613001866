const useFilterNfts = (nftItems, isExpanded, filterNfts) => {
  const nftItemsCount = nftItems.length;
  let filteredNfts = nftItems;
  let remainingItems = [],
    remainingItemsCount = 0;
  if (filterNfts) {
    if (nftItemsCount > 4 && !isExpanded) {
      filteredNfts = nftItems.slice(0, 3);
      remainingItems = nftItems.slice(3);
      remainingItemsCount = remainingItems.length;

      if (remainingItems.length < 4) {
        remainingItems = [
          ...remainingItems,
          ...Array(4 - remainingItems.length).fill(null),
        ];
      }
    }
  }
  return {
    filteredNfts,
    remainingItems,
    remainingItemsCount,
  };
};
export default useFilterNfts;
