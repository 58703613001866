import { CustomRow } from "components/UI/Components/Components";
import classes from "../ScreenerRightPanel.module.css";
const CheckboxText = ({ text, isActive, isToggleable = true, setIsActive }) => {
  return (
    <CustomRow gap="12px" alignItems="center">
      <input
        className={classes.checkbox}
        type="checkbox"
        disabled={!isToggleable}
        checked={isActive}
        onChange={() => {
          if (!isToggleable) return;
          setIsActive(!isActive);
        }}
      />
      <span className={classes.checkboxText}>{text}</span>
    </CustomRow>
  );
};

export default CheckboxText;
