import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import React, { useContext, useEffect } from "react";
import styles from "./ProfleEditPage.module.scss";
import {
  ProfileDPUploadButton,
  ProfileEditPageHeader,
  ProfileEditPageLoader,
} from "./ProfileEditPage.utils";
import { Divider } from "components/UI/Components/Components";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import { useEditProfileMutation, useGetProfileData } from "api/profile.api";
import GenericFallBackUI from "components/ErrorBoundary/GenericFallBackUI";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ProfileEditForm from "./ProfileEditForm";
import DynamicWalletProvider from "components/DynamicWalletProvider/DynamicWalletProvider";
import useIsLoggedInUser from "customHooks/useIsLoggedInUser";
import { GlobalContext } from "contextStates/Global";
import { fileToBase64 } from "utils/misc";
import useQueryParams from "customHooks/useQueryParams";
const ProfileEditPage = ({ identifier }) => {
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const {
    data: profileData,
    isLoading: isProfileLoading,
    isError: isProfileError,
    refetch: refetchProfile,
  } = useGetProfileData({ identifier });
  const queryParams = useQueryParams();
  const isLoggedInUser = useIsLoggedInUser(profileData?.data?.data?.details);
  const isMyProfile = profileData ? isLoggedInUser : true;
  const history = useHistory();
  const profile = profileData?.data?.data?.details;
  const form = useForm({
    defaultValues: {
      display_name: "",
      display_picture: "",
      bio: "",
      "socials.twitter": "",
      "socials.lens": "",
      "socials.farcaster": "",
      "socials.github": "",
    },
    trigger: "onChange",
    mode: "onChange",
  });

  const { mutate: updateProfile, isPending } = useEditProfileMutation({
    onError: (err) => {
      console.log({ err });
      handleErrorSnackbar(null, "Failed to update profile");
    },
    onSuccess: (data) => {
      const updatedProfile = data?.data?.data?.identity;
      if (updatedProfile) {
        refetchProfile();
        handleSuccessSnackbar("Profile updated successfully");
      }
    },
  });
  const {
    watch,
    formState: { errors, isDirty },
    reset: resetForm,
  } = form;
  useEffect(() => {
    const twitterStatus = queryParams.get("twitter_success");
    if (twitterStatus === "true") {
      handleSuccessSnackbar?.("Twitter connected successfully");
    } else if (twitterStatus === "false") {
      handleErrorSnackbar(null, "Failed to connect twitter");
    }
    history.replace(`/${identifier}/edit`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, history, identifier, handleSuccessSnackbar]);
  const hasErrors = Object.keys(errors).length > 0;
  const submitForm = async (e) => {
    e.preventDefault();
    const { display_name, bio, dp_file } = watch();
    const b64string = dp_file
      ? { display_picture: await fileToBase64(dp_file) }
      : {};
    const params = {
      name: display_name,
      bio,
      linkedIn: watch("socials.linkedin"),
      github: watch("socials.github"),
      farcaster: watch("socials.farcaster"),
      telegram: watch("socials.telegram"),
      ...b64string,
    };
    await updateProfile({ ...params });
  };
  useEffect(() => {
    if (profile) {
      resetForm({
        display_name: profile.display_name,
        bio: profile.bio,
        display_picture: profile.display_picture,
        "socials.twitter": profile.socials?.twitter ?? "",
        "socials.lens": profile.socials?.lens ?? "",
        "socials.farcaster": profile.socials?.farcaster ?? "",
        "socials.github": profile.socials?.github ?? "",
        "socials.telegram": profile.socials?.telegram ?? "",
        "socials.linkedin": profile.socials?.linkedin ?? "",
        dp_file: null,
      });
    }
  }, [profile, resetForm]);
  if (profile && !isMyProfile) {
    history.replace(`/home`);
    return null;
  }
  if (isProfileLoading) {
    return (
      <div className={styles.container}>
        <ProfileEditPageHeader identifier={identifier} />
        <Divider margin="16px 0" />
        <ProfileEditPageLoader />
      </div>
    );
  }
  if (isProfileError) {
    return <GenericFallBackUI text="Failed to fetch profile data" />;
  }
  return (
    <DynamicWalletProvider>
      <div className={styles.container}>
        <ProfileEditPageHeader
          onSave={submitForm}
          isLoading={isPending}
          disabled={hasErrors || (!isDirty && !watch("dp_file"))}
          identifier={identifier}
        />
        <Divider margin="16px 0" />
        <ZxActor
          actor={profile}
          overrideSrc={
            watch("dp_file") ? URL.createObjectURL(watch("dp_file")) : null
          }
          showFollowButton={false}
          nameLength={64}
          size="80px"
          dpBorderRadius="16px"
          showNetworth={false}
          columnJustify="flex-start"
          dpClassName={styles.dp}
          secondLineRender={() => <ProfileDPUploadButton form={form} />}
        />
        <Divider margin="20px 0" />
        <ProfileEditForm
          form={form}
          isLoading={isPending}
          profile={profile}
          identifier={identifier}
          refetchProfile={refetchProfile}
        />
      </div>
    </DynamicWalletProvider>
  );
};

export default withErrorBoundary(ProfileEditPage);
