import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import processData, { reversePorcessedData } from "./processData";
import classes from "./ScreenerQuery.module.css";
import { signedRequest } from "api/api";
import { Root } from "./Screener.components";
import QueryExamples from "./QueryExamples/QueryExamples";
import QueryLine from "./QueryLine/QueryLine";
import TrendingQueries from "./TrendingQueries/TrendingQueries";
import { Divider, PaddingComponent } from "components/UI/Components/Components";
import { defaultQuery } from "./DefaultQuery";
import { trackEvent } from "utils/event_tracking";

const ScreenerQuery = ({
  setData,
  queryTokens,
  isEditingMode,
  setIsEditingMode,
  additionalColumnsData,
  source,
}) => {
  const [isQueryRunning, setIsQueryRunning] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [addedQueries, setAddedQueries] = useState([]);

  useEffect(() => {
    if (queryTokens) {
      const reverseData = reversePorcessedData(queryTokens);
      setAddedQueries(reverseData);
    } else {
      setAddedQueries([defaultQuery(true)]);
    }
  }, [queryTokens]);

  const onRunQuery = () => {
    trackEvent("screener_run_query_clicked");
    const data = processData(addedQueries);

    setIsQueryRunning(true);
    setIsError(false);
    setErrorMessages([]);
    signedRequest({
      method: "post",
      path: "/api/screener/runquery",
      bodyText: JSON.stringify({
        input_tokens: data,
        additional_columns: additionalColumnsData ? additionalColumnsData : [],
      }),
    })
      .then((data) => {
        if (data.data.status === "error") {
          setIsError(true);
          if (data?.data?.data?.error_messages?.length > 0) {
            setErrorMessages(data.data.data.error_messages);
          } else {
            // Shouldn't happen, but just in case.
            setErrorMessages(["Something went wrong"]);
          }
        } else {
          setData(data.data);
          if (setIsEditingMode) {
            setIsEditingMode(false);
          }
        }
        setIsQueryRunning(false);
      })
      .catch((err) => {
        source === "mainPage" && setData([]);
        setIsQueryRunning(false);
        setErrorMessages(["Internal server error"]);
        setIsError(true);
      });
  };

  const flexDirectionClass = isEditingMode
    ? classes.editMode
    : classes.notEditMode;

  const handleDeleteQuery = (index) => {
    const newQueries = addedQueries.filter((_, i) => i !== index);
    setAddedQueries(newQueries);
  };

  return (
    <div className={`${classes.screener_query} ${flexDirectionClass}`}>
      <div className={classes.queryContainer}>
        <div className={classes.queryInputContainer}>
          <Root>
            <div className={classes.query_example_box}>
              {addedQueries?.map((query, index) => (
                <QueryLine
                  key={query.id}
                  index={index}
                  addedQueries={addedQueries}
                  setAddedQueries={setAddedQueries}
                  handleDeleteQuery={handleDeleteQuery}
                  showDeleteBtn={addedQueries.length > 1}
                  currentQuery={query}
                />
              ))}
              <button
                className={classes.addQueryBtn}
                onClick={() => {
                  setAddedQueries([...addedQueries, defaultQuery()]);
                }}>
                + Add condition
              </button>
            </div>
          </Root>
        </div>
        <PaddingComponent padding="16px 0">
          <Divider />
        </PaddingComponent>
        <div className={classes.queryButtonContainer}>
          {isError && (
            <p className={classes.error}>
              {errorMessages.map((item) => (
                <p>{item}</p>
              ))}
            </p>
          )}
          <button
            disabled={isQueryRunning} // Disable if no query is added
            className={classes.runQueryButton}
            onClick={onRunQuery}>
            {!isQueryRunning && <div>Run Query</div>}
            {isQueryRunning && <CircularProgress color="inherit" size={12} />}
          </button>
        </div>
      </div>

      {queryTokens === undefined && (
        <div className={classes.query_example}>
          <TrendingQueries />
          <QueryExamples />
        </div>
      )}
    </div>
  );
};

export default ScreenerQuery;
