import classes from "./ExternalLinks.module.css";
import etherScanIcon from "assets/svg/contracts/etherscan.svg";
import openSea from "assets/svg/opensea_external_link.svg";
import magicEden from "assets/png/magic-eden.webp";
import looksRare from "assets/svg/looksrare_external_link.svg";
import blur from "assets/svg/blur_external_link.svg";
import x2y2 from "assets/svg/x2y2_external_link.svg";
import dexscreenerIcon from "assets/svg/dexscreener.svg";
import birdeyeIcon from "assets/svg/birdeye.svg";
import { CustomRow, IconWrapper } from "components/UI/Components/Components";
import GitHubIcon from "@mui/icons-material/GitHub";
import { farcasterIcon } from "utils/assets";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { RedirectLink } from "components/UI/Components/Components";
import { DiscordLogo } from "@phosphor-icons/react";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { TelegramIcon, NewTwitterIcon, Globe02Icon } from "hugeicons-react";

const ExternalLinks = ({
  links,
  isVerticalDivider = false,
  square = false,
  iconWrapperColor = "var(--elevation-2)",
}) => {
  const iconStyling = {
    height: "18px",
    width: "18px",
    fontSize: "18px",
    color: "var(--text-2)",
  };
  const explorerLink = links?.explorer_link ?? links?.explorer_url ?? null;
  return (
    <div className={classes.socials}>
      {links?.opensea && (
        <PPLxTooltip title="Opensea" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.opensea}>
              <img
                className={classes.imageIcon}
                src={openSea}
                alt="opensea-icon"
              />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.looksrare && (
        <PPLxTooltip title="Looksrare" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.looksrare}>
              <img
                className={classes.imageIcon}
                src={looksRare}
                alt="looksrare-icon"
              />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.blur && (
        <PPLxTooltip title="Blur" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.blur}>
              <img className={classes.imageIcon} src={blur} alt="blur-icon" />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.magiceden && (
        <PPLxTooltip title="Magic Eden" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.magiceden}>
              <img
                className={`${classes.imageIcon} ${classes.round}`}
                src={magicEden}
                alt="blur-icon"
              />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.x2y2 && (
        <PPLxTooltip title="X2Y2" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.x2y2}>
              <img className={classes.imageIcon} src={x2y2} alt="x2y2-icon" />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.twitter && (
        <PPLxTooltip title="Twitter" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.twitter}>
              <CustomRow>
                <NewTwitterIcon
                  strokeWidth="2px"
                  color="var(--text-2)"
                  size="16px"
                />
              </CustomRow>
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.lens && (
        <PPLxTooltip title="Lens" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.lens}>
              <img
                className={classes.imageIcon}
                src={"/lens_external_link.png"}
                alt="lens-icon"
              />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.farcaster && (
        <PPLxTooltip title="Farcaster" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.farcaster}>
              <img
                className={classes.imageIcon}
                src={farcasterIcon}
                alt="farcaster-icon"
              />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.discord && (
        <PPLxTooltip title="Discord" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.discord}>
              <DiscordLogo weight="fill" color="var(--text-2)" />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.github && (
        <PPLxTooltip title="Github" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.github}>
              <CustomRow>
                <GitHubIcon sx={iconStyling} />
              </CustomRow>
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.telegram && (
        <PPLxTooltip title="Telegram" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.telegram}>
              <CustomRow>
                <TelegramIcon
                  color="var(--text-2)"
                  size="16px"
                  strokeWidth="2px"
                />
              </CustomRow>
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.linkedin && (
        <PPLxTooltip title="LinkedIn" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.linkedin}>
              <CustomRow>
                <LinkedInIcon sx={iconStyling} />
              </CustomRow>
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.tenderly_url && (
        <PPLxTooltip title="Tenderly" placement="top" arrow>
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.tenderly_url}>
              <Globe02Icon
                color="var(--text-2)"
                size="16px"
                strokeWidth="2px"
              />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {explorerLink && (
        <PPLxTooltip title="Explorer" arrow placement="top">
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={explorerLink}>
              <img
                className={classes.imageIcon}
                src={
                  explorerLink && explorerLink?.includes("solscan")
                    ? "/png/solscan.png"
                    : etherScanIcon
                }
                alt="explorer-icon"
              />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {(links?.external_link || links?.website) && (
        <PPLxTooltip title="Website" arrow placement="top">
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.external_link ?? links?.website}>
              <CustomRow>
                <Globe02Icon
                  color="var(--text-2)"
                  size="16px"
                  strokeWidth="2px"
                />
              </CustomRow>
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}

      {links?.dexscreener && (
        <PPLxTooltip title="Dexscreener" arrow placement="top">
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.dexscreener}>
              <img
                src={dexscreenerIcon}
                alt="website-icon"
                className={classes.imageIcon}
              />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}
      {links?.birdeye && (
        <PPLxTooltip title="Birdeye" arrow placement="top">
          <IconWrapper color={iconWrapperColor} noBorder>
            <RedirectLink link={links?.birdeye}>
              <img
                src={birdeyeIcon}
                alt="website-icon"
                className={classes.imageIcon}
              />
            </RedirectLink>
          </IconWrapper>
        </PPLxTooltip>
      )}

      {isVerticalDivider && <div className={classes.verticalDivider}></div>}
    </div>
  );
};

export default ExternalLinks;
