import classes from "./FilterRowTile.module.css";
import formClasses from "components/UI/PopUpForm/PopUpForm.module.css";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { getChainMetaData } from "utils/misc";

const ProtocolTokenRowTile = ({
  tokensMetaData,
  protocol,
  setProtocolsSelected,
  protocolsSelected,
  index,
}) => {
  const chainMetaData = getChainMetaData(protocol.chain_id);
  const filteredProtocol = protocolsSelected.filter((element) => {
    return (
      element.contract_address === protocol.contract_address &&
      element.chain_id === protocol.chain_id
    );
  });
  const isActive = filteredProtocol.length > 0;
  const tokenActiveClass = isActive ? classes.active : "";
  const metadata = tokensMetaData[protocol.contract_address];
  return (
    <div className={`${classes.box} ${tokenActiveClass}`}>
      <div className={classes.spacingRowTile}>
        <div className={classes.rowTile}>
          <input
            type="checkbox"
            checked={isActive}
            onChange={() => {
              if (isActive) {
                //get all prootcols except this and filter then update
                const updatedTokens = protocolsSelected.filter(function (
                  element
                ) {
                  const isSame =
                    element.contract_address === protocol.contract_address &&
                    element.chain_id === protocol.chain_id;
                  return !isSame;
                });
                setProtocolsSelected([...updatedTokens]);
                return;
              }
              //just add this protocol
              setProtocolsSelected([...protocolsSelected, protocol]);
            }}></input>
          <div className={classes.tokenIconWrapper}>
            <Image
              className={classes.tokenIcon}
              src={metadata?.logo}
              imageType={IMAGE_TYPES.TOKEN}
              alt={`${metadata?.symbol}-icon`}
            />
            {protocol?.chain_id !== "Ethereum" && (
              <img
                className={classes.tokenChainIcon}
                src={chainMetaData?.overlayIcon}
                alt={`${protocol?.chain_id}-overlay-icon`}
              />
            )}
          </div>
          <span className={classes.title}>{metadata?.name}</span>
        </div>
        <div className={classes.subtitle}>{protocol?.chain_id}</div>
      </div>
      <div className={formClasses.divider}></div>
    </div>
  );
};

export default ProtocolTokenRowTile;
