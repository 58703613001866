import React, { useEffect, useContext, useState, Fragment } from "react";
import { GalleryContext } from "contextStates/Gallery";
import { ReactComponent as CloseIcon } from "assets/svg/feed/close_icon.svg";
import ActionButtons from "./ActionButtons/ActionButtons";
import { Media } from "./Media/Media";
import { sanitize } from "dompurify";
import { Modal, Backdrop } from "@mui/material";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useEventListener from "customHooks/useEventListener";
import { signedApiV4Request, signedRequest } from "api/api";
import GhostFeedItem from "components/Feed/GhostFeedItem/GhostFeedItem";
import MetaTags from "components/MetaTags/MetaTags";
import classes from "./galleryModal.module.css";
import { PaddingComponent } from "components/UI/Components/Components";
import { NFTItem } from "components/V2/Profile/ProfileDetails/NFTHoldings/NftItem";
import { ReactComponent as IndicatorIcon } from "assets/svg/Landing/indicator.svg";
import { getNftIndices } from "components/GroupFeedItem/GroupFeedItem.components";
import { produce } from "immer";
import ContentRenderer from "components/FeedItemV2/ContentRenderer";
import hackPostToActivitiesSchema from "components/FeedItemV2/hackPostToActivitiesSchema";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";

const GroupedActivityGallery = () => {
  const {
    handleActiveMediaInfo,
    setGroupedActivity,
    setActivityId,
    nftIndices,
    groupedActivity,
    index,
    urlFallbackRef,
  } = useContext(GalleryContext);
  let location = useLocation();

  const { type, id, activity_idx = 0, media_idx, activity_id } = useParams();
  const history = useHistory();
  const [mediaDescription, setMediaDescription] = useState("");
  let pageTitle = "";

  // keyboard shortcuts <- for prev , -> for next
  const handleArrowKeys = (e) => {
    if (nftIndices) {
      if (e.keyCode === 39 && index + 1 !== nftIndices.length) {
        handleActiveMediaInfo(index + 1);
      }
      if (e.keyCode === 37 && index !== 0) {
        handleActiveMediaInfo(index - 1);
      }
    }
  };
  useEventListener("keydown", handleArrowKeys, document);

  useEffect(() => {
    if (activity_id) {
      setActivityId(activity_id);
    }

    const fetchAndFill = async () => {
      let res;
      if (activity_id) {
        res = await signedRequest({
          method: "post",
          path: "/api/v4/get_activity_by_id",
          bodyText: JSON.stringify({ id: activity_id }),
        });
      } else {
        res = await signedApiV4Request({
          method: "post",
          path: "get_feed_item",
          bodyText: JSON.stringify({ type, id }),
        });
      }
      let grouped = res?.data?.data;
      if (grouped) {
        if (type === "post") {
          grouped = hackPostToActivitiesSchema(
            grouped,
            grouped.identities,
            grouped.profiles
          );
        }
        const myNftIndices = getNftIndices(grouped, activity_id);
        // find indexOf {activityIdx: activity_idx, mediaIdx: media_idx} in myNftIndices
        const myNftIndicesIndex = myNftIndices.findIndex(
          ({ activityIdx, mediaIdx }) =>
            activityIdx === parseInt(activity_idx) &&
            mediaIdx === parseInt(media_idx)
        );
        setGroupedActivity(grouped);
        handleActiveMediaInfo(myNftIndicesIndex);
        setMediaDescription(
          grouped?.activities?.[activity_idx]?.media?.[media_idx].description
        );
      }
    };

    const galleryGroupActivity =
      location.state && location.state.groupedActivity;
    if (galleryGroupActivity) {
      const myGalleryGroupActivity = produce(galleryGroupActivity, (draft) => {
        // Filter out the relevant activity
        if (activity_id == null) {
          return;
        }
        const relevantActivity = draft.activities.find(
          ({ id: activityId }) => activityId === activity_id
        );
        if (relevantActivity) {
          draft.activities = [relevantActivity];
        }
      });
      setGroupedActivity(myGalleryGroupActivity);
      const myNftIndices = getNftIndices(myGalleryGroupActivity, activity_id);
      // find indexOf {activityIdx: activity_idx, mediaIdx: media_idx} in myNftIndices
      const myNftIndicesIndex = myNftIndices.findIndex(
        ({ activityIdx, mediaIdx }) =>
          activityIdx === parseInt(activity_idx) &&
          mediaIdx === parseInt(media_idx)
      );
      setGroupedActivity(galleryGroupActivity);
      handleActiveMediaInfo(myNftIndicesIndex);
    } else fetchAndFill();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  pageTitle = `Media Preview · `;
  const handleClose = () => {
    if (location.state) {
      history.goBack();
    } else if (urlFallbackRef.current) {
      history.replace(urlFallbackRef.current);
    } else {
      history.replace("/home");
    }
  };

  return (
    <BaseWrapper mixpanelEvent="GroupedActivityGallery">
      <Modal
        open
        onClose={() => handleClose()}
        BackdropComponent={(props) => (
          <Backdrop {...props} style={{ background: "none" }} />
        )}
        className={classes.galleryContainer}>
        <div className={classes.galleryModal}>
          <MetaTags title={pageTitle} />
          <div className={classes.modalContent}>
            {groupedActivity ? (
              <>
                <Media onClose={() => handleClose()} />
                <ActionButtons feed={groupedActivity} isGroupedActivity />
              </>
            ) : null}
          </div>
          <div className={classes.galleryRightPanel}>
            <ZxFlex justify="space-between" align="center">
              <div className={classes.galleryTitle}>Image Gallery</div>
              <button
                className={classes.closeButton}
                onClick={() => handleClose()}>
                <CloseIcon />
              </button>
            </ZxFlex>
            <PaddingComponent height="12px" />
            {groupedActivity ? (
              <>
                <ContentRenderer
                  groupedActivity={groupedActivity}
                  showSettings={false}
                  show
                  smallThumbnail
                  noMargin
                  hideNftTransfers
                  showComments
                  noPadding
                />
                <PaddingComponent height="24px" />
                <div className={classes.nftTitle}>
                  {["lens", "farcaster", "post"].includes(type)
                    ? "Images"
                    : "NFTs"}
                  <span className={classes.nftCount}>
                    ({nftIndices.length})
                  </span>
                </div>
                <PaddingComponent height="12px" />
                <div className={classes.nftItemsContainer}>
                  {nftIndices.map(
                    ({ activityIdx, mediaIdx }, feedItemIndex) => {
                      const feedMediaItem =
                        groupedActivity?.activities[activityIdx]?.media[
                          mediaIdx
                        ];
                      return (
                        <Fragment
                          key={`sidebar-nft-item-${feedMediaItem?.alt}-${feedItemIndex}`}>
                          <div
                            className={classes.nftItemWrapper}
                            onClick={() =>
                              handleActiveMediaInfo(feedItemIndex)
                            }>
                            {index === feedItemIndex && (
                              <IndicatorIcon
                                className={classes.indicatorIcon}
                              />
                            )}
                            <NFTItem
                              key={`nft-item-${feedMediaItem?.alt}-${feedItemIndex}`}
                              // TODO(shaheer): remove the following when the API changes of a format similar to NFT holdings is made
                              nftItem={{
                                ...feedMediaItem,
                                display_image: feedMediaItem?.src,
                                name: feedMediaItem?.alt,
                              }}
                              rightPane
                              isGalleryThumbnail
                              hideSubtitle
                            />
                          </div>
                          <PaddingComponent height="12px" />
                        </Fragment>
                      );
                    }
                  )}
                </div>

                {mediaDescription?.length ? (
                  <div
                    className="nft-caption"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(mediaDescription),
                    }}></div>
                ) : null}
              </>
            ) : (
              <GhostFeedItem type="gallery-modal" />
            )}
          </div>
        </div>
      </Modal>
    </BaseWrapper>
  );
};

export default GroupedActivityGallery;
