import {
  CustomRow,
  CustomTextSpan,
  PaddingComponent,
} from "components/UI/Components/Components";
import Image from "components/UI/Image";
import classes from "./Joined0xPPL.module.css";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import { TimeLabel } from "components/FeedItemV2/ActivityHeader";
import { getFeedDescriptionWithLengthLimit } from "utils/string_helper";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "contextStates/AuthContext";

const JoinedCommentTile = ({ comment, groupedActivity }) => {
  const { identityDetails } = useContext(AuthContext);

  // if current user creates a new reply, the actor profile can't be found in groupedActivity?.identities
  const commentActor =
    groupedActivity?.identities?.[comment?.post?.creator_identity] ??
    identityDetails?.current?.identity;
  let shortDesc = comment?.post?.contents;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (shortDesc.includes("{{break_line}}")) {
    //remove this
    shortDesc = shortDesc.replace("{{break_line}}", "");
  }
  if (windowWidth < 425 && shortDesc.length > 15) {
    shortDesc =
      getFeedDescriptionWithLengthLimit(comment?.post?.contents, 15) + "...";
  } else if (windowWidth < 768 && shortDesc.length > 20) {
    shortDesc =
      getFeedDescriptionWithLengthLimit(comment?.post?.contents, 20) + "...";
  }
  if (shortDesc.length > 40) {
    shortDesc =
      getFeedDescriptionWithLengthLimit(comment?.post?.contents, 40) + "...";
  }
  let shortName = commentActor?.display_name;
  if (shortName.length > 15) {
    shortName = shortName.slice(0, 15) + "...";
  }

  return (
    <PaddingComponent padding="5px 0px">
      <CustomRow gap="8px" className={classes.comment}>
        <Image
          src={commentActor?.display_picture}
          alt={commentActor?.display_name}
          className={classes.commenterProfilePic}
        />
        <CustomTextSpan fontWeight="500" className={classes.actorName}>
          {shortName}
        </CustomTextSpan>
        <div className={classes.descriptionWrapper}>
          <FeedDescription
            templateString={shortDesc}
            profiles={groupedActivity?.profiles}
            identities={groupedActivity?.identities}
          />
        </div>
        <TimeLabel
          timestamp={comment?.post?.timestamp}
          className={classes.timeLabel}
        />
      </CustomRow>
    </PaddingComponent>
  );
};

export default JoinedCommentTile;
