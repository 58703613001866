import { ClickAwayListener } from "@mui/material";
import { useContext, useState } from "react";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import buttonClasses from "components/UI/Buttons/buttons.module.css";
import {
  CustomColumn,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import CustomTextField from "components/FollowEditButton/CustomTextField";
import { CustomText } from "components/UI/Components/Components";
import { CircularProgress } from "@mui/material";
import { editWalletGroup, createWalletGroup } from "api/profile.api";
import { GlobalContext } from "contextStates/Global";
import {
  ArrowLeft,
  PencilSimple,
  Plus,
} from "@phosphor-icons/react";

const AddEditWalletBundle = ({ group, refetch, onlyIcon }) => {
  const [showPopUpCard, setShowPopUpCard] = useState(false);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowPopUpCard(false);
      }}>
      <div>
        <PPLxHoverCard
          open={showPopUpCard}
          content={
            <WalletBundleForm
              group={group}
              onSuccess={refetch}
              onClose={() => {
                setShowPopUpCard(false);
              }}
            />
          }
          placement="bottom">
          <button
            onClick={() => {
              setShowPopUpCard(true);
            }}
            className={
              onlyIcon
                ? buttonClasses.transparentButton
                : buttonClasses.primaryButton
            }>
            {onlyIcon ? (
              group?.id ? (
                <PencilSimple width={16} height={16} color="var(--text-2)" />
              ) : (
                <Plus width={16} height={16} color="var(--text-2)" />
              )
            ) : group?.id ? (
              "Edit Group"
            ) : (
              "Add Group"
            )}
          </button>
        </PPLxHoverCard>
      </div>
    </ClickAwayListener>
  );
};

export const WalletBundleForm = ({
  group,
  onSuccess,
  onClose,
  showBackButton = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(group?.group_name || "");
  const { handleErrorSnackbar } = useContext(GlobalContext);

  const onAddClick = () => {
    setIsLoading(true);
    if (group?.id) {
      editWalletGroup({ groupId: group.id, newGroupName: name })
        .then((res) => {
          onSuccess();
        })
        .catch((e) => {
          handleErrorSnackbar(e);
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
          onClose();
        });
    } else {
      createWalletGroup({ name })
        .then((res) => {
          onSuccess();
        })
        .catch((e) => {
          handleErrorSnackbar(e);
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
          onClose();
        });
    }
  };

  return (
    <PaddingComponent padding="20px" width="300px">
      <PaddingComponent padding="0 0 8px 0">
        <CustomText
          text={`${group?.id ? "Edit" : "Add"} Group`}
          color="var(--text-1)"
          fontSize="13px"
          fontWeight="600"
        />
      </PaddingComponent>
      <Divider />
      <CustomColumn gap="16px" padding="8px 0 8px 0">
        <CustomTextField
          autoFocus
          size="small"
          placeholder="Group Name"
          fullWidth
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <button
          onClick={onAddClick}
          disabled={isLoading || name.trim() === ""}
          className={buttonClasses.popupButton}>
          {isLoading ? (
            <div>
              <CircularProgress color="inherit" size={12} />
            </div>
          ) : group?.id ? (
            <div>Save</div>
          ) : (
            <div>Add</div>
          )}
        </button>
        {showBackButton && (
          <button
            onClick={onClose}
            className={buttonClasses.transparentLinkButton}>
            <ArrowLeft weight="bold" width={16} height={16} />
            <span>Back to Add Wallets</span>
          </button>
        )}
      </CustomColumn>
    </PaddingComponent>
  );
};

export default AddEditWalletBundle;
