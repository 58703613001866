import React, { useCallback } from "react";
import classes from "./deployedContracts.module.css";
import {
  Table,
  TableHeader,
  TableRow,
  TableCell,
} from "components/UI/Table/Table";
import { AutoSizer, List } from "react-virtualized";
import {
  CustomColumn,
  PaddingComponent,
} from "components/UI/Components/Components";
import { getDeployedContracts } from "api/profile.api";
import withLoader from "components/core/withLoader";
import { epochToReadable } from "utils/misc";
import { getTimeString } from "utils/misc";
import StaticSearchBar from "components/UI/Components/StaticSearchBar";
import { useState, useEffect } from "react";

import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const renderRow = ({ contract, key, style, deployedContracts }) => {
  const profile = deployedContracts.profiles[contract.created_address];
  return (
    <TableRow noPadding alignToFlexStart key={key} style={style}>
      <TableCell alignLeft>
        <FeedDescription
          templateString={"{{contract||" + profile.address + "}}"}
          profiles={deployedContracts?.profiles}
        />
      </TableCell>
      <TableCell alignCenter>
        <FeedDescription
          templateString={"{{chain||" + contract.chain_id + "}}"}
        />
      </TableCell>
      <TableCell alignRight style={{ flexBasis: "22%" }}>
        <div className={classes.createdAt}>
          {`${getTimeString(contract.created_at)} · ${epochToReadable(
            contract.created_at
          )}`}
        </div>
      </TableCell>
    </TableRow>
  );
};

const DeployedContracts = ({ queryData: deployedContracts, refetch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredContracts, setFilteredContracts] = useState(
    deployedContracts?.contracts_deployed ?? []
  );
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  useEffect(() => {
    if (searchTerm) {
      const modifiedList = getContractsAfterSearch({
        data: deployedContracts,
        deployedContracts: deployedContracts?.contracts_deployed ?? [],
        searchTerm,
      });
      setFilteredContracts(modifiedList);
    } else {
      setFilteredContracts(deployedContracts?.contracts_deployed ?? []);
    }
  }, [searchTerm, deployedContracts]);

  const renderRowInner = useCallback(
    (props) =>
      renderRow({
        ...props,
        deployedContracts,
        contract: filteredContracts[props.index],
      }),
    [filteredContracts, deployedContracts]
  );
  return (
    <BaseWrapper mixpanelEvent="ProfileDeployedContractsTab">
      <CustomColumn padding="0 16px">
        <PaddingComponent height="16px" />
        <StaticSearchBar
          setSearchTerm={setSearchTerm}
          isSearchInputFocused={isSearchInputFocused}
          setIsSearchInputFocused={setIsSearchInputFocused}
          title="Search"
        />
        <div className={classes.deployedContracts} style={{ height: "100%" }}>
          <Table>
            <TableHeader noPadding>
              <TableCell alignLeft>Contract Address</TableCell>
              <TableCell alignCenter>Chain</TableCell>
              <TableCell alignRight style={{ flexBasis: "15%" }}>
                Created On
              </TableCell>
            </TableHeader>
            <PaddingComponent height="12px" />
            <div style={{ height: "500px" }}>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    width={width}
                    rowCount={filteredContracts?.length}
                    rowHeight={40}
                    rowRenderer={renderRowInner}
                  />
                )}
              </AutoSizer>
            </div>
          </Table>
        </div>
      </CustomColumn>
    </BaseWrapper>
  );
};

const getContractsAfterSearch = ({ data, deployedContracts, searchTerm }) => {
  if (!searchTerm) return deployedContracts;

  const searchLowercase = searchTerm.toLowerCase();
  const filteredList = deployedContracts?.filter((deployedContract) => {
    const profile = data.profiles[deployedContract?.created_address];
    if (
      deployedContract?.chain_id.toLowerCase().includes(searchLowercase) ||
      deployedContract?.created_address
        .toLowerCase()
        .includes(searchLowercase) ||
      profile?.display_name?.toLowerCase().includes(searchLowercase)
    ) {
      return true;
    }
    return false;
  });
  return filteredList;
};

export default withLoader(DeployedContracts, getDeployedContracts, {
  queryKey: (props) => ["deployed-contracts", props.identifier],
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  renderNotFound: false,
});
