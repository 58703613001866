import {
  CursorDiv,
  CustomRow,
  FlexGrow,
  InfoBox,
  PaddingComponent,
} from "components/UI/Components/Components";
import ColumnSearchField from "components/Pages/Screener/ScreenerRightPanel/components/ColumnSearchField";
import classes from "./ImpersonatorBox.module.css";
import { MagnifyingGlass, X, Detective } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import React from "react";
import { ClickAwayListener } from "@mui/material";
import UserWallets from "./UserWallets";
import { shortStartingAddress } from "utils/misc";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import { killSession } from "./ImpersonateWalletConnect";
import Spinner from "shared/buttonloadingSpinner";
import {
  saveImpersonatorIdentityAndAddress,
  getImpersonatorIdentityAndAddress,
  saveImpersonateSession,
} from "./ImpersonatorUtils";

const ImpersonatorBox = ({
  isDevToolsPopUp = false,
  onWalletSelected = () => {},
  onRemoveUser = () => {},
}) => {
  const [user, setUser] = useState(null);
  const [showWalletList, setShowWalletList] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(null);

  useEffect(() => {
    //get user and address from local storage
    const { identity, address } = getImpersonatorIdentityAndAddress();
    setUser(identity);
    setSelectedWallet(address);
    onWalletSelected(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSetSelectedWallet = (wallet, { newUser } = {}) => {
    setSelectedWallet(wallet);
    setShowWalletList(false);
    saveImpersonatorIdentityAndAddress({
      identity: newUser ?? user,
      address: wallet,
    });
    onWalletSelected(wallet);
  };
  const handleSetUser = (user) => {
    setUser(user);
    if (user == null) {
      //delete saved user
      saveImpersonatorIdentityAndAddress({
        identity: null,
        address: null,
      });
      saveImpersonateSession({ session: null });

      //dont show wallet list
      setShowWalletList(false);
      //remove selected wallet address
      handleSetSelectedWallet(null);
      //callback for parent
      onWalletSelected(null);
      onRemoveUser();
      //kill session
      killSession();
    }
  };

  return (
    <div className={classes.container}>
      {!isDevToolsPopUp && <span className={classes.title}>Simulate As</span>}
      <div className={classes.searchContainer}>
        {user != null ? (
          <ImpersonatorSelected
            isDevToolsPopUp={isDevToolsPopUp}
            selectedWallet={selectedWallet}
            setSelectedWallet={handleSetSelectedWallet}
            user={user}
            setShowWalletList={setShowWalletList}
            showWalletList={showWalletList}
            setUser={setUser}
            removeUser={() => {
              handleSetUser(null);
            }}
          />
        ) : (
          <ImpersonatorSearch
            handleSetSelectedWallet={handleSetSelectedWallet}
            setUser={setUser}
            setShowWalletList={setShowWalletList}
          />
        )}
      </div>
    </div>
  );
};

const ImpersonatorSearch = ({
  setUser,
  setShowWalletList,
  handleSetSelectedWallet,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <InfoBox padding="4px 8px" width="320px" height="28px">
      <CustomRow alignItems="center">
        <MagnifyingGlass size={16} color="var(--text-3)" />
        <ColumnSearchField
          minWidth={200}
          maxWidth={280}
          placeholder="Search users, addresses or ens"
          source="impersonator"
          height="20px"
          customWidth={240}
          isShowingLoading={false}
          setCustomLoadingState={(isLoading) => {
            setIsLoading(isLoading);
          }}
          onAddValue={(_, newValue) => {
            if (newValue.type === "address") {
              setUser(newValue);
              handleSetSelectedWallet(newValue?.data?.address, {
                newUser: newValue,
              });
              return;
            }
            setUser(newValue);
            setShowWalletList(true);
          }}
        />
        <FlexGrow />
        {isLoading && (
          <Spinner
            style={{
              stroke: "var(--primary-color)",
              background: "transparent",
              width: "20px",
              height: "20px",
              alignSelf: "center",
            }}
          />
        )}
      </CustomRow>
    </InfoBox>
  );
};

const ImpersonatorSelected = React.forwardRef(
  (
    {
      user,
      removeUser,
      showWalletList,
      setShowWalletList,
      selectedWallet,
      setSelectedWallet,
      isDevToolsPopUp,
    },
    ref
  ) => {
    const profile = user?.data;
    let walletAppend = "";
    if (selectedWallet) {
      const shortAdd = shortStartingAddress(selectedWallet, {
        digitsInAddress: 4,
        isRemove0X: true,
      });
      walletAppend = `: ${shortAdd}`;
    }
    let firstName = profile?.display_name?.split(" ")[0];
    return (
      <div ref={ref}>
        <InfoBox padding="4px 8px" width="320px">
          <CustomRow alignItems="center">
            <Image
              style={{
                width: isDevToolsPopUp ? "20px" : "16px",
                height: isDevToolsPopUp ? "20px" : "16px",
              }}
              imageType={IMAGE_TYPES.AVATAR}
              className={classes.avatar}
              src={profile?.display_picture}
              alt={profile?.display_name}
              name={profile?.display_name}
            />
            <PaddingComponent width="4px" />
            <span
              style={{
                fontSize: isDevToolsPopUp ? "15px" : "13px",
              }}
              className={classes.name}>{`${firstName}${walletAppend}`}</span>
            <FlexGrow />
            <div className={classes.crossPointer} onClick={removeUser}>
              <X size={14} color="var(--text-3)" />
            </div>
            {(!isDevToolsPopUp || !selectedWallet) && (
              <div className={classes.verticalDivider} />
            )}
            {!isDevToolsPopUp && selectedWallet && <ImpersonatorText />}
            {!selectedWallet && (
              <SelectWalletHover
                setSelectedWallet={setSelectedWallet}
                user={user}
                showWalletList={showWalletList}
                setShowWalletList={setShowWalletList}
              />
            )}
          </CustomRow>
        </InfoBox>
      </div>
    );
  }
);

const ImpersonatorText = () => {
  const { isDevToolsPopUpOpen, setIsDevToolsPopUpOpen } = React.useContext(
    LeftSideHeaderContext
  );

  return (
    <CursorDiv>
      <CustomRow
        onClick={() => {
          if (!isDevToolsPopUpOpen) {
            setIsDevToolsPopUpOpen(true);
          }
        }}>
        <Detective size={16} color="var(--primary-color" />
        <PaddingComponent width="6px" />
        <span className={classes.impersonate}>Impersonate</span>
      </CustomRow>
    </CursorDiv>
  );
};

const SelectWalletHover = ({
  user,
  showWalletList,
  setShowWalletList,
  setSelectedWallet,
}) => {
  const profile = user?.data;
  if (showWalletList) {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setShowWalletList(false);
        }}>
        <div onClick={() => {}}>
          <PPLxHoverCard
            open={showWalletList}
            placement="bottom-start"
            content={
              <UserWallets
                onSelectAddress={(address) => {
                  setSelectedWallet(address);
                }}
                identifier={profile.id}
              />
            }>
            <SelectWalletComp ref={React.createRef()} />
          </PPLxHoverCard>
        </div>
      </ClickAwayListener>
    );
  }
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setShowWalletList(true);
      }}>
      <SelectWalletComp />
    </div>
  );
};

const SelectWalletComp = React.forwardRef(({ props }, ref) => {
  return (
    <div className={classes.selectWalletPointer} ref={ref}>
      <CustomRow alignItems="center">
        <PaddingComponent width="6px" />
        <span className={classes.selectWallet}>Select Wallet</span>
      </CustomRow>
    </div>
  );
});

export default ImpersonatorBox;
