import {
  acceptPrivateWalletsSharingRequest,
  cancelPrivateWalletsSharingRequest,
  sendPrivateWalletsSharingRequest,
  useGetProfileData,
  useGetWalletConnectionsWithProfile,
} from "api/profile.api";
import { useBundleInfo } from "components/Pages/AppModule/RightSideBar/apis/useBundleInfo";
import { AuthContext } from "contextStates/AuthContext";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const useWalletAccess = () => {
  const params = useParams();
  const identifier = params?.profile_id ?? params?.identifier;
  const {
    data: response,
    isLoading: isProfileLoading,
    isError: isProfileError,
  } = useGetProfileData({ identifier });

  const profile = response?.data?.data?.details;
  const { identityDetails } = useContext(AuthContext);
  const self = identityDetails?.current?.identity;
  const [selectedWallets, setSelectedWallets] = useState([]);
  const [sharedWallets, setSharedWallets] = useState([]);
  const queryClient = useQueryClient();
  const refetchRequests = () => {
    queryClient.invalidateQueries(["activeConnections"]);
    queryClient.invalidateQueries(["sentConnectionRequests"]);
  };
  const {
    data: connectionData,
    refetch: refetchConnectionData,
    isLoading: isConnectLoading,
    isFetching: isConnectFetching,
  } = useGetWalletConnectionsWithProfile({
    self: self?.id,
    profile: profile?.id,
    enabled:
      !!self?.id &&
      profile?.id &&
      profile?.is_verified &&
      self?.id !== profile?.id,
  });

  const {
    data: bundleResponse,
    isLoading: isBundleLoading,
    isError: isBundleError,
  } = useBundleInfo({ identifier: self?.id });
  const bundle = bundleResponse?.data?.data?.bundle;
  const privateWallets =
    bundle?.wallets?.filter((wallet) => wallet.is_private) ?? [];
  const addOrRemoveSingleWallet = (wallet) => {
    setSelectedWallets((old) => {
      if (old.includes(wallet)) {
        return old.filter((w) => w !== wallet);
      }
      return [...old, wallet];
    });
  };
  const updateSelectedWallets = (wallets) => {
    setSelectedWallets(wallets);
  };
  useEffect(() => {
    if (connectionData?.private_wallets_suggestions) {
      const shared = connectionData.private_wallets_suggestions
        .filter((w) => w.is_shared)
        .map((w) => w.address);
      setSharedWallets(shared);
      setSelectedWallets(shared);
    }
  }, [connectionData?.private_wallets_suggestions]);
  const { mutate: sendOrUpdateRequest, isPending: isUpdatePending } =
    useMutation({
      mutationFn: () =>
        sendPrivateWalletsSharingRequest({
          toIdentityId: profile?.id,
          walletAddresses: selectedWallets,
        }),
      onSuccess: () => {
        refetchConnectionData();
        refetchRequests();
      },
    });
  const { mutate: cancelOrRevokeAccess, isPending: isRevokePending } =
    useMutation({
      mutationFn: () =>
        cancelPrivateWalletsSharingRequest({ toIdentityId: profile?.id }),
      onSuccess: () => {
        refetchConnectionData();
        refetchRequests();
      },
    });
  const { mutate: acceptRequest, isPending: isAcceptPending } = useMutation({
    mutationFn: () =>
      acceptPrivateWalletsSharingRequest({
        toIdentityId: profile?.id,
        walletAddresses: selectedWallets,
      }),
    onSuccess: () => {
      refetchConnectionData();
      refetchRequests();
    },
  });
  const { mutate: cancelRequest, isPending: isCancelPending } = useMutation({
    mutationFn: () =>
      cancelPrivateWalletsSharingRequest({ toIdentityId: profile?.id }),
    onSuccess: () => {
      refetchConnectionData();
      refetchRequests();
    },
  });

  const checkForChanges = () => {
    if (selectedWallets.length !== sharedWallets.length) return true;
    return selectedWallets.some((wallet) => !sharedWallets.includes(wallet));
  };
  return {
    // profiles
    profile,
    self,
    isProfileLoading,
    isError: isProfileError,

    // bundles
    bundle,
    privateWallets,
    isBundleLoading,
    isBundleError,

    // wallet selection
    selectedWallets,
    addOrRemoveSingleWallet,
    updateSelectedWallets,
    checkForChanges,

    // connection
    connectionData,
    refetchConnectionData,
    sharedWallets,
    cancelOrRevokeAccess,
    acceptRequest,
    cancelRequest,

    // meta
    sendOrUpdateRequest,
    isUpdatePending,
    isRevokePending,
    isAcceptPending,
    isCancelPending,
    isConnectionBeingFetched: isConnectFetching,
    isConnectionLoading: isConnectLoading,
  };
};

export default useWalletAccess;
