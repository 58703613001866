import { getNetworthData } from "api/profile.api";
import { useQuery } from "@tanstack/react-query";
import useIsLoggedInUser from "customHooks/useIsLoggedInUser";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "contextStates/AuthContext";

export const useNetworth = ({ identifier }) => {
  const queryKey = ["networth", identifier?.toString()];
  const queryClient = useQueryClient();
  const isLoggedInUser = useIsLoggedInUser({ username: identifier });
  const { identityDetails } = useAuthContext();
  const { data, isError, isLoading, isSuccess, refetch, isFetching } = useQuery(
    {
      queryKey: queryKey,
      queryFn: () => getNetworthData({ identifier }),
      staleTime: 0,
      enabled: !!identifier,
      retry: 2,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  useEffect(() => {
    if (isLoggedInUser && data) {
      queryClient.setQueryData(
        ["networth", identityDetails.current?.identity?.username],
        data
      );
    }
  }, [isLoggedInUser, data, identityDetails, queryClient]);
  return { data, isError, isLoading, refetch, isSuccess, isFetching };
};
