import { Divider } from "components/UI/Components/Components";
import TradingViewGraph from "../TradingViewGraph/TradingViewGraph";
import classes from "./TokenTab.module.css";
import TopUsers from "./TopUsers/TopUsers";
import TrendingTokenAdditionalDetails from "components/Trending/TrendingTokenAdditionalDetails";
// import PopularTransfers from "components/Trending/PopularTransfers";

const TokenTab = ({
  address,
  chainId,
  showAllDetails = true,
  // tokenProfile
}) => {
  return (
    <div className={classes.token_tab}>
      <Divider margin="0 0 16px 0" />
      <TradingViewGraph
        address={address}
        chain={chainId}
        showPriceHeader
        isTokenPage={true}
        headerPadding="0"
        isNativeToken={true}
      />
      {showAllDetails && (
        <>
          <TrendingTokenAdditionalDetails
            chainId={chainId}
            identifier={address}
          />
          <TopUsers address={address} chainId={chainId} />
          {/* <PopularTransfers chainId={chainId} identifier={address} tokenProfile={tokenProfile} /> */}
        </>
      )}
    </div>
  );
};

export default TokenTab;
