import classes from "./LoginPopup.module.css";
import { AuthContext } from "contextStates/AuthContext";
import { useContext, useEffect, useState } from "react";
import pplLogo from "assets/svg/Landing/logo.svg";
import { useSpring, animated } from "react-spring";
import {
  CustomRow,
  FlexGrow,
  PaddingComponent,
} from "components/UI/Components/Components";
import DynamicWalletButton from "components/DynamicWalletButton/DynamicWalletButton";
import DynamicWalletProvider from "components/DynamicWalletProvider/DynamicWalletProvider";

const LoginPopup = () => {
  const { showLoginPopup, updateSetShowLoginPopup } = useContext(AuthContext);
  const [, setShowPopUp] = useState(false);

  useEffect(() => {
    updateSetShowLoginPopup(setShowPopUp);
  }, [updateSetShowLoginPopup, setShowPopUp]);

  const popupProps = useSpring({
    top: showLoginPopup() ? "80px" : "80px",
    right: showLoginPopup() ? "20px" : "-100px",
    display: showLoginPopup() ? "flex" : "none",
  });

  if (!showLoginPopup()) return null;

  return (
    <animated.div className={classes.login_popup} style={popupProps}>
      <div className={classes.top}>
        <CustomRow alignItems="center">
          <FlexGrow>
            <div className={classes.title}>Sign in</div>
          </FlexGrow>
          <img src={pplLogo} alt="logo" className={classes.logo} />
        </CustomRow>

        <ul className={classes.list}>
          <li>Explore wallets, contracts and profiles</li>
          <li>Follow your favorite crypto natives across web3</li>
          <li>Get in-depth news on whats trending in web3</li>
        </ul>
      </div>
      <PaddingComponent height="24px" />
      <DynamicWalletButton isLogin={true} isOnboarding />
    </animated.div>
  );
};

const Wrapper = (props) => {
  return (
    <DynamicWalletProvider>
      <LoginPopup {...props} />
    </DynamicWalletProvider>
  );
};
export default Wrapper;
