import ChainDetails from "./ChainDetails";
import { PaddingComponent } from "components/UI/Components/Components";
import ChainHeader from "./ChainHeader";
import { useState, useContext, useEffect } from "react";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const ChainPage = ({ chainId, chainType }) => {
  const [metadata, setMetadata] = useState(null);
  const { setTitle } = useContext(LeftSideHeaderContext);

  useEffect(() => {
    if (metadata) setTitle(`${metadata?.chain_title}`);
  }, [metadata, setTitle]);

  return (
    <BaseWrapper mixpanelEvent="ChainPage">
      <div>
        <ChainHeader
          setMetadata={setMetadata}
          chainId={chainId}
          chainType={chainType}
        />
        <PaddingComponent height="40px" />
        <ChainDetails
          metadata={metadata}
          chainId={chainId}
          chainType={chainType}
        />
      </div>
    </BaseWrapper>
  );
};

export default ChainPage;
