import React, { useContext, useState } from "react";
import FeedMediaType from "components/FeedMediaType/FeedMediaType";
import { GalleryContext } from "contextStates/Gallery";
import { getTypeFromUrl } from "utils/misc";
import classes from "components/GalleryModal/galleryModal.module.css";
import Loader from "components/UI/Loader/Loader";

export const Media = ({ onClose = () => {} }) => {
  const { activeMediaInfo } = useContext(GalleryContext);
  const [type, setType] = useState(
    (activeMediaInfo?.content_type ?? getTypeFromUrl(activeMediaInfo?.src)) ||
      "image"
  );
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className={classes.mediaPreview} onClick={onClose}>
      {isLoading && <Loader />}
      {activeMediaInfo?.src?.length ? (
        <FeedMediaType
          onClick={(e) => e.stopPropagation()}
          renderedFrom="GalleryModal"
          type={type}
          className={classes.mediaImage}
          mediaItem={activeMediaInfo}
          setType={setType}
          setIsLoading={setIsLoading}
          key={`image-${activeMediaInfo?.src}`}
        />
      ) : null}
    </div>
  );
};
