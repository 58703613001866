import { CustomRow } from "components/UI/Components/Components";
import React from "react";
import { Checkbox } from "@mui/material";
import ZxText from "../ZxText/ZxText";
import styles from "./ZxCheckBox.module.scss";
const ZxCheckBox = ({ isChecked, onChange, label, value, extraStyles }) => {
  return (
    <CustomRow
      alignItems="center"
      gap="4px"
      onClick={() => onChange(value)}
      style={{ ...extraStyles }}
      className={`${styles.container} ${isChecked ? styles.checked : ``}`}>
      <Checkbox
        size="small"
        className={`${styles.checkbox} ${isChecked ? styles.checked : ``}`}
        checked={isChecked}
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: 18,
            marginRight: "2px",
            padding: "0px",
          },
          "& .MuiTypography-root": { fontSize: "14px" },
          "& .Mui-checked": { color: "#0047ff !important" },
        }}
      />
      {label && (
        <ZxText color={isChecked ? "text-1" : "text-2"} fontSize="12px">
          {label}
        </ZxText>
      )}
    </CustomRow>
  );
};

export default ZxCheckBox;
