import { useEffect, useMemo, useRef, useState } from "react";

import classes from "./TimelineGraphWrapper.module.css";

import Graph from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/Graph";
import graphClasses from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/ProfileGraphs.module.css";
import CustomLinkTabBar, {
  SIZE,
} from "components/UI/Components/CustomLinkTabBar";

const TABKEYS = {
  TVL: "tvl",
  Transactions: "volume",
  "Unique Users": "num_unique_users",
};

const TABLIST = Object.keys(TABKEYS);

const TimelineGraphWrapper = ({ protocolStats }) => {
  const [tab, setTab] = useState(0);

  const data = useMemo(
    () =>
      protocolStats?.map(({ ts, ...args }) => ({
        x: ts,
        y: args[TABKEYS[TABLIST[tab]]],
      })),
    [protocolStats, tab]
  );
  const graphData = useMemo(
    () => ({
      data: data,
    }),
    [data]
  );

  const containerRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setParentWidth(containerRef.current.getBoundingClientRect().width);
    }

    const handleResize = () => {
      if (containerRef.current) {
        setParentWidth(containerRef.current.getBoundingClientRect().width);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup after component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    data != null &&
    data.length > 0 && (
      <div
        className={`${graphClasses.profile_graphs} ${classes.graph_wrapper}`}>
        <div className={graphClasses.header}>
          <div className={graphClasses.left_panel}>
            <CustomLinkTabBar
              activeTab={tab}
              tabsList={TABLIST}
              isLinkTabBar={false}
              setActiveTab={(index) => {
                setTab(index);
              }}
              isBorderBottom={false}
              position="top"
              size={SIZE.LARGE}
            />
          </div>
        </div>
        <div className={graphClasses.graph_component} ref={containerRef}>
          {graphData && (
            <Graph graphData={graphData} parentWidth={parentWidth} />
          )}
        </div>
      </div>
    )
  );
};

export default TimelineGraphWrapper;
