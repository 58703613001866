import {
  PaddingComponent,
  TrendingTokenPriceInfo,
} from "components/UI/Components/Components";
import React from "react";
import PopularTransfers from "./PopularTransfers";

import TrendingTokenAdditionalDetails from "./TrendingTokenAdditionalDetails";
import TrendingLiveChart from "./TrendingLiveChart";

const TrendingTokenDetails = ({
  details,
  identifier,
  chainId,
  isLoading = false,
  isError = false,
  refetch = false,
}) => {
  return (
    <div>
      {!!details?.prices?.length && (
        <>
          <TrendingLiveChart
            chartData={details?.prices}
            isLoading={isLoading}
            isError={isError}
            refetch={refetch}
            tokenName={details?.profile?.display_name}
            headerContent={
              <TrendingTokenPriceInfo
                priceChange={details?.price_percentage_change}
                tokenPrice={details?.curr_price}
                isRowLayout
                priceFontSize="22px"
                priceChangeFontSize="15px"
              />
            }
          />
          <PaddingComponent height="16px" />
        </>
      )}
      <PopularTransfers
        identifier={identifier}
        chainId={chainId}
        tokenProfile={details?.profile}
      />
      <PaddingComponent height="16px" />
      <TrendingTokenAdditionalDetails
        identifier={identifier}
        chainId={chainId}
      />
    </div>
  );
};

export default TrendingTokenDetails;
