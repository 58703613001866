import { useContext } from "react";
import classes from "./BundleAddressTab.module.css";
import classesTable from "components/V2/Profile/ProfileDetails/wallets/components/walletTableHeader.module.css";
import { ProfileContext } from "../../../../../contextStates/ProfileContext";
import WalletsTableHeader from "../wallets/components/walletsTableHeader";
import WalletRowComponent, {
  TableRowItem,
} from "../wallets/components/WalletRowComponent";
import { SourcedBy } from "../wallets/components/WalletComponents";
import VerifiedTag from "components/UI/Components/VerifiedTag";
import { TableRowTextComponent } from "../wallets/components/WalletComponents";
import ProfileComponent from "../../../../UI/ProfileComponent/ProfileComponent";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import EmptyStateUI from "components/ErrorBoundary/EmptyStateUI";

const BundleAddressTab = ({ addressInfos, bundleData }) => {
  const { profileData } = useContext(ProfileContext);
  const info = profileData.current?.details;
  const profiles = profileData.current?.details?.related_identities?.profiles;

  if (profileData && !info) {
    return <EmptyStateUI text="No bundle data found" />;
  }

  return (
    <BaseWrapper mixpanelEvent="ProfileBundleAddressTab">
      <div className={classes.container}>
        <div className={classes.header}>
          Bundled with {info?.related_identities?.identities?.length}{" "}
          {info?.related_identities?.identities?.length > 1
            ? "Profiles"
            : "Profile"}
        </div>
        <div className={classesTable.table}>
          <WalletsTableHeader
            items={[
              { name: "Name", flex: 3 },
              { name: "SOURCED BY", flex: 2 },
              { name: "REASON", flex: 2 },
            ]}></WalletsTableHeader>

          {info?.related_identities?.identities?.map((profile, index) => (
            <div
              key={index}
              className={`${classesTable.tableRow} ${classesTable.tableList}`}>
              <WalletRowComponent>
                <TableRowItem flex={3}>
                  <ProfileComponent info={profiles[profile.id]} />
                </TableRowItem>

                <TableRowItem flex={2}>
                  <SourcedBy
                    wallet={{
                      sourced_by_count: profile.sourced_by?.length,
                      sourced_by: profile.sourced_by?.reduce((obj, num) => {
                        obj[num] = num;
                        return obj;
                      }, {}),
                    }}
                    identities={profiles}
                  />
                </TableRowItem>
                <TableRowItem flex={2}>
                  {profile?.is_verified ? (
                    <VerifiedTag></VerifiedTag>
                  ) : (
                    <TableRowTextComponent
                      isTooltip={true}
                      text={profile?.reason ? profile?.reason : "-"}
                    />
                  )}
                </TableRowItem>
              </WalletRowComponent>
            </div>
          ))}
        </div>
      </div>
    </BaseWrapper>
  );
};

export default BundleAddressTab;
