import overlayArb from "assets/svg/token-overlay-arb.svg";
import overlayAurora from "assets/svg/token-overlay-aurora.svg";
import overlayAvax from "assets/svg/token-overlay-avax.svg";
import overlayBsc from "assets/svg/token-overlay-bsc.svg";
import overlayCro from "assets/svg/token-overlay-cro.svg";
import overlayEth from "assets/svg/token-overlay-eth.svg";
import overlayEvmos from "assets/svg/token-overlay-evmos.svg";
import overlayFtm from "assets/svg/token-overlay-ftm.svg";
import overlayGnosis from "assets/svg/token-overlay-gnosis.svg";
import overlayHeco from "assets/svg/token-overlay-heco.svg";
import overlayMovr from "assets/svg/token-overlay-movr.svg";
import overlayOp from "assets/svg/token-overlay-op.svg";
import overlayPoly from "assets/svg/token-overlay-poly.svg";
import overlayBoba from "assets/svg/token-overlay-boba.svg";
import overlayMoonbeam from "assets/svg/token-overlay-moonbeam.svg";
import {
  ethereumIcon,
  polygonIcon,
  bscIcon,
  avalancheIcon,
  gnosisIcon,
  hecoIcon,
  fantomIcon,
  arbitrumIcon,
  optimismIcon,
  moonriverIcon,
  cronosIcon,
  auroraIcon,
  evmosIcon,
  bobaIcon,
  moonbeamIcon,
  zkSyncIcon,
  polyZk,
  baseIcon,
  hyperlaneTest,
  mantaTest,
  panGuTest,
  zoraChainIcon,
  lineaIcon,
  mantaIcon,
  metisIcon,
  solanaIcon,
  blastIcon,
  degenChainIcon,
  bitcoinIcon,
  toncoinIcon,
  abstractIcon,
  beraIcon,
} from "utils/assets";

export const chainMetaData = [
  {
    name: "Ethereum",
    icon: ethereumIcon,
    overlayIcon: overlayEth,
    website: "Etherscan",
  },
  {
    name: "Polygon",
    icon: polygonIcon,
    overlayIcon: overlayPoly,
    website: "Polygonscan",
  },
  {
    name: "BSC",
    icon: bscIcon,
    overlayIcon: overlayBsc,
    website: "Bscscan",
  },
  {
    name: "Avalanche",
    icon: avalancheIcon,
    overlayIcon: overlayAvax,
    website: "Snowtrace",
  },
  {
    name: "Gnosis",
    icon: gnosisIcon,
    overlayIcon: overlayGnosis,
    website: "Blockscout",
  },
  {
    name: "Heco",
    icon: hecoIcon,
    overlayIcon: overlayHeco,
    website: "Hecoinfo",
  },
  {
    name: "Fantom",
    icon: fantomIcon,
    overlayIcon: overlayFtm,
    website: "FtmScan",
  },
  {
    name: "Arbitrum",
    icon: arbitrumIcon,
    overlayIcon: overlayArb,
    website: "Arbiscan",
  },
  {
    name: "Optimism",
    icon: optimismIcon,
    overlayIcon: overlayOp,
    website: "Optimistic",
  },
  {
    name: "Moonriver",
    icon: moonriverIcon,
    overlayIcon: overlayMovr,
    website: "Moonriver Moonscan",
  },
  {
    name: "Cronos",
    icon: cronosIcon,
    overlayIcon: overlayCro,
    website: "Cronoscan",
  },
  {
    name: "Aurora",
    icon: auroraIcon,
    overlayIcon: overlayAurora,
    website: "Aurorascan",
  },
  {
    name: "EVMOS",
    icon: evmosIcon,
    overlayIcon: overlayEvmos,
    website: "EScan",
  },
  {
    name: "Boba",
    icon: bobaIcon,
    overlayIcon: overlayBoba,
    website: "Bobascan",
  },
  {
    name: "Moonbeam",
    icon: moonbeamIcon,
    overlayIcon: overlayMoonbeam,
    website: "Moonbeam Moonscan",
  },
  {
    name: "zkSync",
    icon: zkSyncIcon,
    overlayIcon: zkSyncIcon,
    website: "zkSync",
  },
  {
    name: "PolygonzkEVM",
    icon: polyZk,
    overlayIcon: overlayPoly,
    website: "Polygonscan",
  },
  {
    name: "Base",
    icon: baseIcon,
    overlayIcon: baseIcon,
    website: "Basescan",
  },
  {
    name: "HyperlaneTest",
    icon: hyperlaneTest,
    overlayIcon: hyperlaneTest,
    website: "Hyperlane Explorer",
  },
  {
    name: "MantaTest",
    icon: mantaTest,
    overlayIcon: mantaTest,
    website: "Manta Explorer",
  },
  {
    name: "PanGu",
    icon: panGuTest,
    overlayIcon: panGuTest,
    website: "PanGu Explorer",
  },
  {
    name: "ZoraChain",
    icon: zoraChainIcon,
    overlayIcon: zoraChainIcon,
    website: "Zora Chain Explorer",
  },
  {
    name: "Linea",
    icon: lineaIcon,
    overlayIcon: lineaIcon,
    website: "Lineascan",
  },
  {
    name: "Manta",
    icon: mantaIcon,
    overlayIcon: mantaIcon,
    website: "Manta explorer",
  },
  {
    name: "Metis",
    icon: metisIcon,
    overlayIcon: metisIcon,
    website: "Metis explorer",
  },
  {
    name: "Blast",
    icon: blastIcon,
    overlayIcon: blastIcon,
    website: "Blast explorer",
  },
  {
    name: "DegenChain",
    icon: degenChainIcon,
    overlayIcon: degenChainIcon,
    website: "Degen Chain Explorer",
  },
  {
    name: "Solana",
    icon: solanaIcon,
    overlayIcon: solanaIcon,
    website: "Solana explorer",
  },
  {
    name: "Bitcoin",
    icon: bitcoinIcon,
    overlayIcon: bitcoinIcon,
    website: "Bitcoin explorer",
  },
  {
    name: "Ton",
    icon: toncoinIcon,
    overlayIcon: toncoinIcon,
    website: "Ton explorer",
  },
  {
    name: "Abstract",
    icon: abstractIcon,
    overlayIcon: abstractIcon,
    website: "Abstract Explorer",
  },
  {
    name: "Bera",
    icon: beraIcon,
    overlayIcon: beraIcon,
    website: "Bera Explorer",
  },
];

export const topUsersTableData = [
  {
    address: "0x8e28...Af25",
    balance: "3,432,452",
    tokenChange: { value: "50,000", percentage: "-2%" },
    tokenBalance: {
      value: "2,731,341",
      change: "4%",
    },
    totalTokenSupply: "4.2e+8",
    boughtUsing: "BTC",
    buyingPotential: "$12m",
    netWorth: "$48m",
  },
  {
    address: "0x3999...4f69",
    balance: "3,432,452",
    tokenChange: { value: "60,134", percentage: "-2%" },
    tokenBalance: {
      value: "2,731,341",
      change: "4%",
    },
    totalTokenSupply: "4.2e+8",
    boughtUsing: "BTC",
    buyingPotential: "$12m",
    netWorth: "$48m",
  },
  {
    address: "defidad.eth",
    balance: "2,731,341",
    tokenChange: { value: "47,000", percentage: "-2%" },
    tokenBalance: {
      value: "2,731,341",
      change: "4%",
    },
    totalTokenSupply: "4.2e+8",
    boughtUsing: "BTC",
    buyingPotential: "$12m",
    netWorth: "$48m",
  },
  {
    address: "myprasanna.eth",
    balance: "1,000,452",
    tokenChange: { value: "40,000", percentage: "-2%" },
    tokenBalance: {
      value: "2,731,341",
      change: "4%",
    },
    totalTokenSupply: "4.2e+8",
    boughtUsing: "BTC",
    buyingPotential: "$12m",
    netWorth: "$48m",
  },
  {
    address: "0x0eDb...14A1",
    balance: "3,432,452",
    tokenChange: { value: "60,134", percentage: "-2%" },
    tokenBalance: {
      value: "2,731,341",
      change: "4%",
    },
    totalTokenSupply: "4.2e+8",
    boughtUsing: "BTC",
    buyingPotential: "$12m",
    netWorth: "$48m",
  },
];

export const topUsersChartSeries = [
  {
    name: "0x8e28...Af25",
    data: [502, 635, 809, 947, 1402, 3634, 5268],
  },
  {
    name: "0x3999...4f69",
    data: [300, 400, 200, 50, 500, 600, 700],
  },
  {
    name: "defidad.eth",
    data: [900, 800, 600, 500, 400, 600, 600],
  },
  {
    name: "myprasanna.eth",
    data: [163, 203, 276, 408, 547, 729, 628],
  },
  {
    name: "0x0eDb...14A1",
    data: [103, 153, 176, 208, 347, 429, 528],
  },
];
