import { GlobalContext } from "contextStates/Global";
import { useState, useContext, useCallback } from "react";
import CustomTextField from "components/FollowEditButton/CustomTextField";
import { GlobeHemisphereWest, TwitterLogo } from "@phosphor-icons/react";
import {
  PaddingComponent,
  CustomRow,
  CustomButton,
  FlexGrow,
  CustomText,
} from "components/UI/Components/Components";
import classes from "./EditContractPopup.module.css";
import { updateContract } from "api/contract.api";
import { useQuery } from "@tanstack/react-query";
import { getForContractHeader } from "api/contract.api";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "../../../../../node_modules/@mui/material/index";
import { X } from "../../../../../node_modules/@phosphor-icons/react/dist/index";
import Loader from "components/UI/Loader/Loader";

export default function EditContractPopup() {
  const {
    editContractPopupDetails,
    isEditContractPopupShown,
    setIsEditContractPopupShown,
    handleErrorSnackbar,
    handleSuccessSnackbar,
  } = useContext(GlobalContext);

  const [nameState, setNameState] = useState("");
  const [websiteState, setWebsiteState] = useState("");
  const [twitterState, setTwitterState] = useState("");

  const {
    address_chain,
    address,
    forNative = false,
  } = editContractPopupDetails.current || {};

  const { data: contractData, isLoading } = useQuery({
    queryKey: ["contract", address_chain, address, forNative],
    queryFn: () =>
      getForContractHeader({ chain_id: address_chain, address, forNative }),
    enabled: !!address_chain && !!address,
    onSuccess: (data) => {
      setNameState(data?.profile?.display_name);
      setWebsiteState(data?.profile?.website);
      return data;
    },
  });

  const onUpdate = useCallback(() => {
    return updateContract({
      chainId: contractData?.profile?.address_chain,
      address: contractData?.profile?.address,
      website: websiteState,
      name: nameState,
      twitter: twitterState,
    })
      .then((data) => {
        setIsEditContractPopupShown(false);
        handleSuccessSnackbar("Contract Details Updated");
      })
      .catch((err) => {
        handleErrorSnackbar(err);
      });
  }, [
    nameState,
    websiteState,
    twitterState,
    contractData,
    handleSuccessSnackbar,
    handleErrorSnackbar,
    setIsEditContractPopupShown,
  ]);

  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth
      open={isEditContractPopupShown}
      onClose={() => setIsEditContractPopupShown(false)}>
      <CustomRow
        justifyContent="center"
        alignItems="center"
        padding="16px 10px 16px 20px">
        <FlexGrow>
          <CustomText
            text="Edit Contract Details"
            fontSize="15px"
            color="var(--text-1)"></CustomText>
        </FlexGrow>
        <IconButton
          size="sm"
          onClick={() => setIsEditContractPopupShown(false)}>
          <X size={16} color="var(--text-2)" />
        </IconButton>
      </CustomRow>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PaddingComponent padding="8px 20px">
            <CustomTextField
              placeholder="Name"
              size="small"
              fullWidth
              value={nameState}
              onChange={(e) => {
                setNameState(e.target.value);
              }}
            />
          </PaddingComponent>
          <PaddingComponent padding="8px 20px">
            <CustomTextField
              placeholder="Website"
              size="small"
              fullWidth
              value={websiteState}
              onChange={(e) => {
                setWebsiteState(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <div className={classes.icon_wrapper}>
                    <GlobeHemisphereWest
                      size={16}
                      weight="fill"
                      color="var(--text-2)"
                    />
                  </div>
                ),
              }}
            />
          </PaddingComponent>
          <PaddingComponent padding="8px 20px">
            <CustomTextField
              placeholder="Twitter"
              size="small"
              fullWidth
              value={twitterState}
              onChange={(e) => {
                setTwitterState(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <div className={classes.icon_wrapper}>
                    <TwitterLogo
                      size={16}
                      weight="fill"
                      color="var(--text-2)"
                    />
                  </div>
                ),
              }}
            />
          </PaddingComponent>
        </>
      )}
      <CustomRow justifyContent="flex-end" padding="8px 20px 20px">
        <CustomButton onClick={onUpdate} text="Update"></CustomButton>
      </CustomRow>
    </Dialog>
  );
}
