import ConditionalLink from "shared/ConditionalLink";
import Image from "../Image";
import classes from "./ProfileComponent.module.css";

const ProfileComponent = ({ info }) => {
  return (
    <ConditionalLink to={info?.link} className={classes.profile_component}>
      <Image
        src={info?.display_picture}
        className={classes.image}
        alt={info?.display_name || info?.title}
      />
      <div className={classes.name}>{info?.display_name || info?.title}</div>
    </ConditionalLink>
  );
};

export default ProfileComponent;
