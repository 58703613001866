import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "components/UI/Table/Table";
import { getChainActivities } from "api/contract.api";
import withLoader from "components/core/withLoader";
import { getTrimedAddress, timeAgo } from "utils/misc";
import { ActivityAvatar } from "components/FeedItemV2/GroupedActivityWrapper";
import { ActorInfo } from "components/FeedItemV2/ActivityHeader";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import { TransactionsShimmer } from "./Shimmers/TransactionsShimmer";
import Copyable from "components/ValueType/Copyable/Copyable";
import ConditionalLink from "shared/ConditionalLink";
const RecentTransactions = ({ queryData, chainId }) => {
  const [chainActivities, setChainActivities] = useState(queryData);
  useEffect(() => {
    const getActivities = async () => {
      try {
        const response = await getChainActivities({ chainId });
        setChainActivities(response);
      } catch (error) {}
    };
    const getNewActivities = setTimeout(() => {
      getActivities();
    }, 10000);
    return () => {
      clearTimeout(getNewActivities);
    };
  }, [chainActivities, chainId]);

  return (
    <>
      <CustomColumn
        margin="16px 0"
        background="var(--base)"
        borderRadius="10px"
        border="1px solid var(--border-dark)">
        <CustomRow
          alignItems="center"
          justifyContent="space-between"
          padding="16px 20px">
          <CustomText
            text="Recent Transaction"
            color="var(--text-1)"
            fontSize="15px"
            fontWeight="600"
          />
        </CustomRow>
        <Divider />
        <Table>
          <TableHeader paddingTop background>
            <TableCell longRow alignLeft>
              Details
            </TableCell>
            <TableCell smallRow alignCenter>
              Hash
            </TableCell>
            <TableCell smallRow alignCenter>
              Fiat
            </TableCell>
            <TableCell smallRow alignCenter>
              Time
            </TableCell>
          </TableHeader>
          <RecentTransactionsList data={chainActivities} />
        </Table>
      </CustomColumn>
      <PaddingComponent height="36px" />
    </>
  );
};

const RecentTransactionsList = ({ data }) => {
  return (
    <>
      {data?.slice(0, 20).map((item) => {
        return <RecentTransactionsRow key={`${item?.id}`} item={item} />;
      })}
    </>
  );
};

function gettransactionPageUrl(chain_id, txn) {
  if (chain_id && txn?.tx_id) {
    return `/${chain_id}/tx/${txn.tx_id}`;
  } else if (txn?.tx_id) {
    return `/tx/${txn.tx_id}`;
  } else {
    return null;
  }
}

export const RecentTransactionsRow = ({ item }) => {
  const match = useRouteMatch();
  const txn = item?.transactions[0];
  const txn_page_url = gettransactionPageUrl(match.params.chain_id, txn);
  return (
    <TableRow>
      <TableCell longRow alignLeft>
        <CustomRow alignItems="flex-start" gap="5px">
          <ActivityAvatar mini actor={item?.actor} />
          <ActorInfo trimWidth actor={item?.actor} />
          <FeedDescription
            actor={item?.actor}
            chainProfiles={item?.chain_profiles}
            profiles={item?.profiles}
            templateString={item?.description_template}
            methodIds={item?.method_ids}
          />
        </CustomRow>
      </TableCell>
      <ConditionalLink to={txn_page_url}>
        <HashCopyableComponent item={item} />
      </ConditionalLink>
      <TableCell smallRow alignCenter>
        <CustomText text={`${item?.usd_value?.display_value || "-"}`} />
      </TableCell>
      <TxnTimeComponent item={item} />
    </TableRow>
  );
};

const TxnTimeComponent = ({ item }) => {
  const [timestamp, setTimestamp] = useState(timeAgo(item?.timestamp * 1000));
  useEffect(() => {
    //update this component every 5 seconds needed to update the time
    const interval = setInterval(() => {
      setTimestamp(timeAgo(item?.timestamp * 1000));
    }, 5000);
    return () => clearInterval(interval);
  }, [item?.timestamp]);
  return (
    <TableCell smallRow alignCenter>
      <CustomText text={`${timestamp}`} />
    </TableCell>
  );
};

const HashCopyableComponent = ({ item }) => {
  return (
    <TableCell smallRow alignCenter>
      <CustomRow>
        <Copyable showOnHover={true} content={item?.transactions[0]?.tx_id}>
          <CustomText text={getTrimedAddress(item?.transactions[0]?.tx_id)} />
        </Copyable>
      </CustomRow>
    </TableCell>
  );
};

export default withLoader(RecentTransactions, getChainActivities, {
  queryKey: ({ chainId }) => ["chainActivities", chainId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 2,
  renderNotFound: false,
  CustomLoader: TransactionsShimmer,
});
