import { Code } from "@phosphor-icons/react";
import ImpersonatePopUp from "./ImpersonatePopUp";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import { ClickAwayListener } from "@mui/material";
import { useEffect, useState } from "react";
import classes from "./DevTools.module.css";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";

import React from "react";
import {
  CursorDiv,
  PaddingComponent,
} from "components/UI/Components/Components";
import {
  getImpersonatorIdentityAndAddress,
  getImpersonateSession,
  isValidSession,
} from "./ImpersonatorUtils";
import { trackEvent } from "utils/event_tracking";

const DevToolIcon = ({ isConnected }) => {
  if (isConnected) {
    return <DevToolsIconConnected />;
  }
  return (
    <CursorDiv>
      <PaddingComponent paddingTop="1px">
        <Code size={20} color="var(--text-2)" />
      </PaddingComponent>
    </CursorDiv>
  );
};

const DevToolsIconConnected = () => {
  const { identity } = getImpersonatorIdentityAndAddress();
  const profilePicture = identity?.data?.display_picture;
  return (
    <div className={classes.devIconContainer}>
      <div className={classes.devIcon}>
        <Code size={16} color="var(--text-2)" />
      </div>
      <div className={classes.devImpersonatorIcon}>
        <Image
          className={classes.icon}
          src={profilePicture}
          imageType={IMAGE_TYPES.AVATAR}
          name={identity?.data?.display_name}
          alt={identity?.data?.display_name}
        />
      </div>
    </div>
  );
};

const DevToolsButton = () => {
  const { isDevToolsPopUpOpen, setIsDevToolsPopUpOpen } = React.useContext(
    LeftSideHeaderContext
  );
  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    const { identity, address } = getImpersonatorIdentityAndAddress();
    const session = getImpersonateSession();
    if (session && identity && address && isValidSession(session)) {
      setIsConnected(true);
    }
  }, []);
  const handleShowCard = (e) => {
    trackEvent("impersonate_clicked");
    e.stopPropagation();
    setIsDevToolsPopUpOpen(true);
  };
  if (isDevToolsPopUpOpen) {
    return (
      <ClickAwayListener onClickAway={() => setIsDevToolsPopUpOpen(false)}>
        <div>
          <PPLxHoverCard
            minWidth={348}
            open={isDevToolsPopUpOpen}
            content={
              <ImpersonatePopUp setIsDevToolsConnected={setIsConnected} />
            }
            placement="bottom-start">
            <div
              style={{
                cursor: "pointer",
                marginTop: "2px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setIsDevToolsPopUpOpen(false);
              }}>
              <DevToolIcon isConnected={isConnected} />
            </div>
          </PPLxHoverCard>
        </div>
      </ClickAwayListener>
    );
  }
  return (
    <div
      onClick={handleShowCard}
      style={{
        cursor: "pointer",
      }}>
      <DevToolIcon isConnected={isConnected} />
    </div>
  );
};

export default DevToolsButton;
