import Image, { IMAGE_TYPES } from "components/UI/Image";
import React, { useContext } from "react";
import styles from "./NftPageAvatar.module.scss";
import { ThemeContext } from "contextStates/Theme.context";
const NftPageAvatar = ({ src }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={`${styles.avatar} ${theme === "dark" ? styles.dark : ``}`}>
      <Image src={src} className={styles.image} imageType={IMAGE_TYPES.NFT} />
    </div>
  );
};

export default NftPageAvatar;
