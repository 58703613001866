import {
  CustomColumn,
  CustomRow,
  Divider,
  PaddingComponent,
  PriceChange,
} from "components/UI/Components/Components";
import React, { useContext } from "react";
import styles from "./ZxGmxItem.module.scss";
import { IMAGE_TYPES } from "components/UI/Image";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import ZxBadge from "zxComponents/common/ZxBadge";
import withNullableError from "zxComponents/common/withNullableError/index";
import { ThemeContext } from "contextStates/Theme.context";

const GmxItemRow = ({
  trade,
  isOpen,
  size,
  pnl,
  pnlPercent,
  profile,
  isGroupedActivityDetails,
  onLinkClick,
}) => {
  const { display_name, display_picture } = profile;
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";
  if (!trade) return null;
  if (!size || size?.value === 0) return null;
  const renderBottomRow = () => {
    const BOTTOM_ROW_KEYS = [
      {
        label: "SIZE",
        key: isOpen ? "open_size_delta" : "closed_size_delta",
      },
      {
        label: "ENTRY",
        key: "entry_price",
      },
      {
        label: "PRICE",
        key: "market_price",
      },
    ];
    return (
      <CustomRow
        alignItems="flex-end"
        justifyContent="space-between"
        overflowY="visible"
        overflowX="visible">
        <CustomRow
          justifyContent="space-between"
          width="60%"
          overflowY="visible"
          overflowX="visible">
          {BOTTOM_ROW_KEYS.map(({ label, key }) => {
            return (
              <CustomColumn
                key={key}
                alignItems="center"
                gap="4px"
                overflowY="visible"
                overflowX="visible">
                <ZxText style={TSTYLES.secondaryLabel}>{label}</ZxText>
                <ZxText style={TSTYLES.title2} fontSize={13}>
                  {trade[key]?.display_value ?? trade[key] ?? "-"}
                </ZxText>
              </CustomColumn>
            );
          })}
        </CustomRow>
        {!isGroupedActivityDetails && (
          <div onClick={onLinkClick}>
            <ZxText
              color="primary"
              style={TSTYLES.bodyLabel}
              fontSize="13px"
              cursor="pointer">
              View details
            </ZxText>
          </div>
        )}
      </CustomRow>
    );
  };
  const isProfit = (pnl.value ?? 0) > 0;
  return (
    <div
      className={`${styles.container} ${isProfit ? (isDark ? styles.profit_dark : styles.profit_light) : isDark ? styles.loss_light : styles.loss_dark}`}
      style={{ marginBottom: isOpen ? 8 : 0 }}>
      <CustomRow gap="16px" overflowY="visible" overflowX="visible">
        {/* TOP ROW */}
        <CustomColumn width="100%" overflowY="visible" overflowX="visible">
          <CustomRow justifyContent="space-between">
            <CustomRow alignItems="center" gap="4px">
              <IconWithChain
                imageType={IMAGE_TYPES.TOKEN}
                src={display_picture}
                iconHeight="20px"
                iconWidth="20px"
                showImageFullHeightWidth
              />
              <ZxText style={TSTYLES.title2}>
                {profile.token_details?.symbol ?? display_name ?? "-"}
              </ZxText>
              <CustomRow alignItems="flex-end" gap="4px">
                {isOpen && trade.leverage && (
                  <ZxText style={TSTYLES.title2} color="text-2">
                    {trade.leverage}x
                  </ZxText>
                )}
                {trade.is_long ? (
                  <ZxText style={TSTYLES.secondaryLabel} color="success">
                    LONG
                  </ZxText>
                ) : (
                  <ZxText style={TSTYLES.secondaryLabel} color="error">
                    SHORT
                  </ZxText>
                )}
              </CustomRow>
            </CustomRow>
            <CustomRow alignItems="center" gap="8px">
              <ZxBadge variant={isOpen ? "primary" : "text"}>
                {isOpen ? "OPEN" : "CLOSED"}
              </ZxBadge>
              <CustomRow alignItems="center" gap="4px">
                <ZxText style={TSTYLES.title2} size={13}>
                  {pnl?.display_value}
                </ZxText>
                {pnlPercent?.value && (
                  <PriceChange
                    value={pnlPercent.value}
                    displayValue={pnlPercent.display_value}
                    fontSize="13px"
                    fontWeight="500"
                  />
                )}
              </CustomRow>
            </CustomRow>
          </CustomRow>
          <div className={`${styles.expanded}`}>
            <CustomColumn overflowY="visible" overflowX="visible">
              <Divider margin="8px 0" />
              {renderBottomRow()}
            </CustomColumn>
          </div>
        </CustomColumn>
      </CustomRow>
    </div>
  );
};

const ZxGmxItem = ({
  trade,
  profiles,
  onLinkClick,
  isGroupedActivityDetails = false,
  isExpandable = true,
}) => {
  if (!trade || !trade.displayed_token) return null;

  const tokenAddress =
    trade.displayed_token?.slice(2, -2).split("||")?.[1] ?? null;

  const profile = profiles[tokenAddress] ?? {
    display_name: "-",
    display_picture: null,
    chain: null,
  };
  return (
    <CustomColumn gap="8px">
      {trade.open_size_delta && (
        <GmxItemRow
          trade={trade}
          isOpen={true}
          profile={profile}
          isExpandable={isExpandable}
          pnl={trade.open_pnl}
          pnlPercent={trade.open_pnl_percent}
          isGroupedActivityDetails={isGroupedActivityDetails}
          onLinkClick={onLinkClick}
          size={trade.open_size_delta}
        />
      )}
      {trade.closed_size_delta && (
        <GmxItemRow
          trade={trade}
          isOpen={false}
          profile={profile}
          isExpandable={isExpandable}
          pnl={trade.closed_pnl}
          pnlPercent={trade.closed_pnl_percent}
          isGroupedActivityDetails={isGroupedActivityDetails}
          onLinkClick={onLinkClick}
          size={trade.closed_size_delta}
        />
      )}
      <PaddingComponent height="8px" />
    </CustomColumn>
  );
};

export default withNullableError(ZxGmxItem);
