import { CustomRow } from "components/UI/Components/Components";
import React from "react";
import AdditionalInfo from "./AdditionalInfo";
import withLoader from "components/core/withLoader";
import { getTrendingAdditionalInfo } from "./trendingUtils";
import DeployedBy from "./DeployedBy";
import classes from "./trendingTokenDetails.module.css";
import AuditFlags from "./AuditFlags";
const TrendingTokenAdditionalDetails = ({ queryData }) => {
  return (
    <div>
      {!!queryData?.audit?.negative?.length && (
        <AuditFlags
          data={queryData?.audit?.negative}
          type="red"
          profiles={queryData?.audit?.profiles}
        />
      )}
      <CustomRow
        gap="16px"
        alignItems="stretch"
        className={classes.additionalInfoWrapper}>
        <AdditionalInfo data={queryData?.additional_details} />
        <DeployedBy
          data={queryData?.deployment}
          externalLinks={queryData?.external_links}
        />
      </CustomRow>
    </div>
  );
};

const TrendingTokenAdditionalDetailsLoader = () => {
  return (
    <div>
      <CustomRow
        gap="16px"
        alignItems="stretch"
        className={classes.additionalInfoWrapper}>
        <AdditionalInfo isShimmer={true} />
        <DeployedBy isShimmer={true} />
      </CustomRow>
    </div>
  );
};

export default withLoader(
  TrendingTokenAdditionalDetails,
  getTrendingAdditionalInfo,
  {
    queryKey: (props) => [
      "trending-item-additional-info",
      props?.identifier,
      props?.chainId,
    ],
    queryOptions: () => ({
      retry: 3,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }),
    CustomLoader: TrendingTokenAdditionalDetailsLoader,
  }
);
