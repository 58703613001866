import { CustomRow, PriceChange } from "components/UI/Components/Components";
import classes from "./PnlTokenList.module.css";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import ZxBadge from "zxComponents/common/ZxBadge";
import { getTrimmedString } from "utils/misc";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import { useContext } from "react";
import { ThemeContext } from "contextStates/Theme.context";
import { getDefaultImage, IMAGE_TYPES } from "components/UI/Image";
const PnlToken = ({
  pnl,
  pnlPercent,
  tokenData,
  profile,
  isOpen,
  showFullLengthName = false,
}) => {
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";
  const image =
    tokenData?.token_details?.logo ??
    profile?.display_picture ??
    getDefaultImage({ imageType: IMAGE_TYPES.TOKEN }) ??
    null;
  const name =
    tokenData?.symbol ??
    profile?.token_details?.symbol ??
    profile?.symbol ??
    profile?.display_name ??
    "-";
  if (!pnl || !pnl.value) return null;
  const isProfit = pnl.value > 0;
  const className = isProfit
    ? isDark
      ? classes.profit_dark
      : classes.profit_light
    : isDark
      ? classes.loss_dark
      : classes.loss_light;
  return (
    <CustomRow
      alignItems="center"
      justifyContent="space-between"
      className={`${classes.tokenContainer} ${className}`}>
      <CustomRow
        gap="8px"
        alignItems="center"
        overflowY="visible"
        overflowX="visible">
        <IconWithChain
          src={image}
          iconWidth="24px"
          iconHeight="24px"
          chain={profile?.address_chain}
          showImageFullHeightWidth
          chainHeight="12px"
          chainWidth="12px"
        />

        <ZxText style={TSTYLES.bodyLabel}>
          {getTrimmedString(name, showFullLengthName ? 20 : 6)}
        </ZxText>
      </CustomRow>
      <CustomRow
        gap="8px"
        overflowX="visible"
        overflowY="visible"
        alignItems="center">
        <ZxBadge variant={isOpen ? "primary" : "text"}>
          {isOpen ? "OPEN" : "CLOSED"}
        </ZxBadge>
        <CustomRow
          gap="4px"
          overflowX="visible"
          overflowY="visible"
          alignItems="center">
          <ZxText
            style={TSTYLES.secondaryLabel}
            color="var(--text-2)"
            fontSize={11}>
            PNL:
          </ZxText>
          <ZxText style={TSTYLES.bodyLabel}>{pnl.display_value}</ZxText>
          {pnlPercent && pnlPercent?.value && (
            <PriceChange
              value={pnlPercent.value}
              displayValue={pnlPercent.display_value}
              showCaret={false}
              fontWeight="500"
              fontSize="11px"
              lineHeight="16px"
            />
          )}
        </CustomRow>
      </CustomRow>
    </CustomRow>
  );
};

export default PnlToken;
