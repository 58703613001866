import React from "react";
import styles from "./ZxTransactionSummaries.module.scss";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import {
  CustomColumn,
  CustomRow,
  Divider,
} from "components/UI/Components/Components";
import ZxText from "../ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { getUniqueId } from "utils/event_tracking";
import { CaretRight } from "@phosphor-icons/react";
const ZxTransactionSummaries = ({
  summaries,
  displayCount = 3,
  totalCount = 0,
  extraRender = null,
  isUserTokenGroup = false,
  groupedActivity,
  ...props
}) => {
  const restCount = Math.max(totalCount, summaries.length) - displayCount;
  const renderList = [
    ...summaries
      .slice(0, displayCount)
      .filter((sm) => Object.keys(sm).length > 0),
    restCount > 0 ? { type: "rest", count: restCount } : {},
  ];
  return (
    <div className={styles.container}>
      {renderList.map((summary, index) => {
        return (
          <CustomColumn key={getUniqueId()}>
            {summary.type !== "rest" ? (
              <div className={styles.summary}>
                <div style={{ width: "85%" }}>
                  <FeedDescription
                    templateString={summary.summary}
                    {...props}
                    maxTokenLength={28}
                  />
                </div>
                <ZxText style={TSTYLES.bodyLabel}>
                  {summary?.usd_value?.display_value}
                </ZxText>
              </div>
            ) : (
              <CustomColumn
                onClick={() => {
                  if (props.onTransactionClick) {
                    props.onTransactionClick();
                  }
                }}>
                {!isUserTokenGroup && (
                  <Divider margin="8px 0" borderColor="var(--elevation-2)" />
                )}
                <CustomRow gap="4px" alignItems="center">
                  <ZxText color="text-2" cursor={"pointer"}>
                    {isUserTokenGroup
                      ? null
                      : `+${restCount} more transaction${restCount === 1 ? `` : `s`}`}
                  </ZxText>
                  {!isUserTokenGroup && (
                    <CaretRight color="var(--text-2)" size={12} />
                  )}
                </CustomRow>
              </CustomColumn>
            )}
            {index < summaries.length - 1 && (
              <Divider margin="8px 0" borderColor="var(--elevation-2)" />
            )}
          </CustomColumn>
        );
      })}
      {extraRender ? extraRender() : null}
    </div>
  );
};

export default ZxTransactionSummaries;
