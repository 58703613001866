import TransactionInternalCallsTile from "./TransactionInternalCallsTile";
import {
  Divider,
  CustomColumn,
  PaddingComponent,
} from "components/UI/Components/Components";
import classes from "../TransactionsRightPanel.module.css";
import withLoader from "components/core/withLoader";
import { QueryKeyConstants } from "utils/constants";
import { getTxnTraces } from "api/feed.api";
import TransactionCallsTree from "./TransactionCallsList";
import TransactionInternalCallsGhost from "./InternalCallsGhost";
import EmptyStateUI from "components/ErrorBoundary/EmptyStateUI";

const TransactionInternalCalls = ({ queryData }) => {
  const txnData = queryData?.data?.data;
  //if txnData is null, it means that the txn is not a contract txn
  if (
    (txnData?.internalTxns?.children?.length ?? []).length === 0 &&
    txnData?.internalTxns?.action == null
  ) {
    return (
      <PaddingComponent padding="16px">
        <EmptyStateUI
          text="No transaction internal calls found."
          fullPage={true}></EmptyStateUI>
      </PaddingComponent>
    );
  }
  return (
    <div className={classes.container}>
      {txnData?.internalTxns?.action && (
        <TransactionInternalCallsTile
          action={txnData?.internalTxns?.action}
          txnData={txnData}
          internalTxn={txnData?.internalTxns}
        />
      )}

      {txnData?.internalTxns?.children.map((nestedInternalTxn, index) => {
        const isLast = index === txnData?.internalTxns?.children.length - 1;
        return (
          <CustomColumn>
            <TransactionCallsTree
              key={`internal-txn-${index}`}
              index={index}
              internalTxn={nestedInternalTxn}
              txnData={txnData}
            />
            {!isLast && (
              <PaddingComponent height="6px">
                <Divider />
              </PaddingComponent>
            )}
          </CustomColumn>
        );
      })}
    </div>
  );
};

export default withLoader(
  TransactionInternalCalls,
  (props) => getTxnTraces({ txnHash: props.txnHash }),
  {
    queryKey: (props) => [QueryKeyConstants.TXN_TRACES, props.txnHash],
    queryOptions: (props) => {
      return { staleTime: 1000 * 60 * 5, enabled: props.txnHash?.length > 0 };
    },
    renderNotFound: false,
    CustomLoader: TransactionInternalCallsGhost,
  }
);
