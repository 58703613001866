export const getPolyMarketConditionIds = (feedItemArr) => {
  return feedItemArr?.flatMap((feedItem) => {
    const polyMarketActivities = feedItem?.activities?.filter(
      (item) =>
        item?.activity_contexts?.polymarket?.polymarket_trade_orders?.length > 0
    );
    return polyMarketActivities
      ?.flatMap(
        (item) => item?.activity_contexts?.polymarket?.polymarket_trade_orders
      )
      .map((item) => item.condition_id)
      .filter((w) => w);
  });
};
