import { Modal, Backdrop } from "@mui/material";
import React from "react";
import {
  PaddingComponent,
  CustomRow,
  CustomText,
  InfoBox,
} from "components/UI/Components/Components";
import { DeviceMobile, X } from "@phosphor-icons/react";
import classes from "./AppDownloadButton.module.css";
import mobileStepClasses from "./MobileDownloadStep/MobileDownloadStep.module.css";
import MobileDownloadStep1 from "./MobileDownloadStep/MobileDownloadStep1";
import { CustomButton } from "components/UI/Components/Components";
import { ClickAwayListener } from "../../../../../node_modules/@mui/material/index";
import { trackEvent } from "utils/event_tracking";

const MobileDownloadSteps = () => {
  return (
    <>
      <div className={mobileStepClasses.stepsContainer}>
        <MobileDownloadStep1 showNumber={false} />
      </div>
      <InfoBox
        margin="8px 0px"
        padding="8px 16px"
        alignItems="center"
        justifyContent="center"
        background="var(--warning)"
        style={{ fontSize: 13, fontWeight: "500", color: "white" }}>
        This is your personal login code. Do not share it with anyone.
      </InfoBox>
    </>
  );
};

export const DownloadAppModal = ({ isDownloadAppPopUpOpen, onClose }) => {
  return isDownloadAppPopUpOpen ? (
    <Modal
      open
      onClose={onClose}
      aria-labelledby="Download App"
      aria-describedby="Get the 0xPPL app on you mobile"
      BackdropComponent={(props) => <Backdrop {...props} open />}
      className={classes.galleryContainer}>
      <div className={classes.galleryModal}>
        <div className={classes.modalContent}>
          <ClickAwayListener onClickAway={onClose}>
            <CustomRow
              alignItems="center"
              width="100%"
              className={classes.titleRow}>
              <h3 className={classes.title}>Scan and get 0xppl mobile app</h3>
              <CustomButton
                color="var(--text-2)"
                background="var(--elevation-0)"
                alignItems="center"
                onClick={onClose}
                text={
                  <PaddingComponent paddingRight="4px" paddingTop="4px">
                    <X size={24} className color="var(--text-1)" />
                  </PaddingComponent>
                }
              />
            </CustomRow>
          </ClickAwayListener>
          <PaddingComponent padding="20px">
            <MobileDownloadSteps />
          </PaddingComponent>
        </div>
      </div>
    </Modal>
  ) : (
    ""
  );
};

const AppDownloadButton = ({ showText = false }) => {
  const [isDownloadAppPopUpOpen, setIsDownloadAppPopUpOpen] =
    React.useState(false);

  const handleShowPupUp = (event) => {
    trackEvent("topbar_download_app_clicked");
    event.stopPropagation();
    setIsDownloadAppPopUpOpen(true);
  };

  const onClose = () => {
    setIsDownloadAppPopUpOpen(false);
  };

  return (
    <>
      <div className={classes.downloadAppButton} onClick={handleShowPupUp}>
        <DeviceMobile size={20} />
        {showText && (
          <PaddingComponent padding="0px 4px">
            <CustomText
              fontWeight="600"
              color="var(--text-2)"
              width="140px"
              text={"Download App"}></CustomText>
          </PaddingComponent>
        )}
      </div>
      <DownloadAppModal
        onClose={onClose}
        isDownloadAppPopUpOpen={isDownloadAppPopUpOpen}
      />
    </>
  );
};

export default AppDownloadButton;
