import ZxTriggerNextPage from "zxComponents/common/ZxTriggerNextPage/ZxTriggerNextPage";
import { SkeletonLoader } from "./MessagingChannelList/SkeletonLoader";

const LoadMoreChannels = (props) => {
  const {
    children,
    hasNextPage,
    isLoading,
    loadNextPage,
    refreshing,
    reverse,
  } = props;
  const loadingState =
    typeof isLoading !== "undefined" ? isLoading : refreshing;

  return (
    <>
      <div style={{ position: "relative" }}>
        {!reverse && children}
        {isLoading && <SkeletonLoader />}
        <ZxTriggerNextPage
          height={400}
          shouldFetch={hasNextPage && !loadingState}
          fetchNextPage={loadNextPage}
        />
        {reverse && children}
      </div>
    </>
  );
};

export default LoadMoreChannels;
