import React from "react";
import styles from "./TokenInteractionsBanner.module.css";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import { getTrimmedString } from "utils/misc";
import ZxImageStack from "zxComponents/common/ZxImageStack/ZxImageStack";

const TokenInteractionsBanner = ({
  symbol,
  interactions,
  onBannerClick,
  isTokenPage,
  contractName,
}) => {
  if (!interactions) return null;
  const { count, profiles } = interactions;
  if (count === 0 || profiles?.length === 0) return null;
  const pictures = profiles.map((profile) => {
    return {
      src: profile?.display_picture,
      alt: profile?.display_name,
      name: profile?.display_name,
    };
  });
  const names = profiles
    .map((profile) => getTrimmedString(profile?.display_name, 15) ?? null)
    .filter((name) => name);
  const restCount = count - profiles.length;
  return (
    <PaddingComponent padding="16px 16px 16px 16px">
      <PaddingComponent
        padding="12px 16px"
        className={styles.bannerContainer}
        onClick={() => {
          if (isTokenPage) onBannerClick();
        }}>
        <CustomRow gap="16px" alignItems="flex-end">
          <CustomColumn gap="8px">
            <CustomRow alignItems="center" gap="8px">
              <ZxImageStack pictures={pictures} />
              <CustomRow alignItems="flex-end">
                {names.map((name, index) => (
                  <CustomText
                    key={index}
                    text={
                      <span>
                        {getTrimmedString(name, 10)}
                        {index !== names.length - 1 && `,`}&nbsp;
                      </span>
                    }
                    fontSize="16px"
                    fontWeight="500"
                    lineHeight="19px"
                    color="var(--text-1)"
                    letterSpacing="-0.26px"
                  />
                ))}
              </CustomRow>

              <CustomText
                text={`${
                  restCount > 0 ? `+ ${restCount}` : ``
                } traded ${isTokenPage ? symbol : contractName}`}
                fontSize="13px"
                fontWeight="400"
                lineHeight="20px"
                color="var(--text-2)"
              />
            </CustomRow>
          </CustomColumn>
        </CustomRow>
      </PaddingComponent>
    </PaddingComponent>
  );
};

export default TokenInteractionsBanner;
