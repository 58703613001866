import { useSpring, animated } from "react-spring";
import classes from "./RightPanel.module.css";
import { useHistory } from "react-router-dom";

const RightPanel = ({
  children,
  onClose,
  contentClass = "",
  depth = 0,
  overrideWidth = null,
}) => {
  const history = useHistory();
  const width = overrideWidth ? { minWidth: overrideWidth } : {};
  const popupProps = useSpring({
    top: "0px",
    display: children ? "flex" : "none",
    zIndex: 7 + depth,
  });
  return (
    <animated.div className={classes.right_panel} style={popupProps}>
      <div
        onClick={(e) => {
          if (onClose) {
            onClose();
          } else {
            history.goBack();
          }
          e.stopPropagation();
          return;
        }}
        className={classes.rightPanelGlass}></div>
      <div
        className={`${classes.rightPanelContent} ${contentClass}`}
        style={{ ...width }}>
        {children}
      </div>
    </animated.div>
  );
};

export default RightPanel;
