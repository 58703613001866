import classes from "./ScreenerTable.module.css";

const ScreenerTableRowComponent = ({ index, children, bottomBorderRadius }) => {
  return (
    <div
      className={`${classes.tableRow} ${
        index % 2 === 0 ? classes.backgroundWhite : classes.backgroundColor
      }`}
      style={{
        borderRadius: `0px 0px ${bottomBorderRadius} ${bottomBorderRadius}`,
      }}>
      {children}
    </div>
  );
};

export default ScreenerTableRowComponent;
