import { AuthContext } from "contextStates/AuthContext";
import { useContext, useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styles from "./BioDetails.module.css";

import { updateProfileDetails, useGetProfileData } from "api/profile.api";
import { getOnboardingData, useGenerateBioMutation } from "api/waitlist.api";
import useNavigateNewOnboarding from "../NavigateOnboarding";
import OnboardingBg from "../OnboardingBg";
import { PaddingComponent } from "components/UI/Components/Components";
import { GlobalContext } from "contextStates/Global";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { trackEvent } from "utils/event_tracking";
import { useForm } from "react-hook-form";
import BioForm from "./BioForm";
import BioCompletedForm from "./BioCompletedForm";

const BioDetails = () => {
  const [onboardingData, setOnboardingData] = useState(null);
  const { data: profile } = useGetProfileData({
    identifier: onboardingData?.identity_id ?? null,
  });
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const { getUserAddress } = useContext(AuthContext);
  const identifier = getUserAddress();
  const [navigateOnboarding, setNavigateOnboarding] = useState(false);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [isFormDone, setIsFormDone] = useState(false);
  const [generatedBio, setGeneratedBio] = useState(null);
  const [retries, setRetries] = useState(0);
  const {
    watch,
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm({
    initialValues: {
      title: "",
      workplace: "",
      interests: "",
    },
  });
  const loadingNavigation = useNavigateNewOnboarding(
    navigateOnboarding,
    setNavigateOnboarding
  );

  const {
    mutate: generateBio,
    isPending: isGeneratingBio,
    isError,
  } = useGenerateBioMutation({
    onSuccess: (data) => {
      const isBioGood = !!data?.data?.data?.are_interests_good;
      if (!isBioGood) {
        setError("interests", {
          type: "custom",
          message:
            data?.data?.data?.bio ??
            "Please provide interests related to crypto, this will help you gain followers who share similar interests.",
        });
        return;
      } else {
        setIsFormDone(true);
        setGeneratedBio(data?.data?.data?.bio);
      }
    },
    onError: (error) => {
      const message =
        error?.response?.data?.message ?? "Failed to generate bio";
      handleErrorSnackbar(null, message);
    },
  });
  const fetchInitialBio = async () => {
    try {
      const onboardingData = await getOnboardingData({ identifier: null });
      const user = onboardingData?.data?.data?.user_details;
      if (user) setOnboardingData(user);
    } catch (err) {
      return;
    }
  };
  useEffect(() => {
    fetchInitialBio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [isError]);

  const handleContinue = async ({ regenerate = false, ...params }) => {
    trackEvent("bio_details_continue_clicked");
    const values = watch();
    generateBio({
      ...values,
      bio: regenerate ? params.bio ?? "" : onboardingData?.bio ?? "",
      regenerate,
    });
    if (regenerate) setRetries((r) => r + 1);
    return;
  };

  const moveToNextStep = async ({ bio }) => {
    setIsUpdatingProfile(true);
    try {
      const response = await updateProfileDetails({
        identifier: identifier,
        profile_bio: bio,
      });

      if (response.status === 200) {
        setNavigateOnboarding(true);
      } else {
        console.error("Failed to update profile");
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ?? "Failed to update profile";
      handleErrorSnackbar(null, message);
    } finally {
      setIsUpdatingProfile(false);
    }
  };

  return (
    <BaseWrapper mixpanelEvent="OnboardingBioDetails">
      <OnboardingBg>
        <div className={styles.bioContent}>
          <div className={styles.header}>
            <h1 className={styles.mainTitle}>
              {isFormDone
                ? "Your Bio"
                : "Introduce yourself to the 0xPPL community"}
            </h1>
          </div>

          <PaddingComponent
            padding="16px"
            backgroundColor="var(--base)"
            borderRadius="12px"
            width="100%">
            {isFormDone && onboardingData && generatedBio ? (
              <BioCompletedForm
                actor={profile?.data?.data?.details ?? onboardingData}
                generatedBio={generatedBio}
                regenerateBio={handleContinue}
                goBack={() => setIsFormDone(false)}
                isGeneratingBio={isGeneratingBio}
                retries={retries}
                onSubmit={moveToNextStep}
                isUpdatingProfile={isUpdatingProfile || loadingNavigation}
              />
            ) : (
              <BioForm
                onSubmit={handleSubmit(handleContinue)}
                register={register}
                errors={errors}
                watch={watch}
                isGenerating={isGeneratingBio}
              />
            )}
          </PaddingComponent>
        </div>
      </OnboardingBg>
    </BaseWrapper>
  );
};

export default BioDetails;
