import {
  CustomRow,
  CustomText,
  PaddingComponent,
  PriceChange,
  CustomColumn,
  Divider,
  CursorDiv,
  SpinnerComponent,
} from "components/UI/Components/Components";
import { DotsSixVertical, Star, Trophy } from "@phosphor-icons/react";
import classes from "../RightBar.module.css";
import { IMAGE_TYPES } from "components/UI/Image";
import { DotsThreeVertical } from "@phosphor-icons/react";
import React, { useState, useContext, useCallback } from "react";
import TrendingItemChartPreview from "components/Trending/TrendingItemChartPreview";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import { ClickAwayListener } from "@mui/material";
import { GlobalContext } from "contextStates/Global";
import { removeFromWatchlist } from "api/watchlist.api";
import { WATCHLIST_CATEGORIES, WATCHLIST_SOURCES } from "utils/constants";
import {
  abbreviateNumber,
  epochToReadable,
  getTrimmedString,
} from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";
import { trackEvent } from "utils/event_tracking";
import { getRemoveWatchlistParams } from "./Watchlist.utils";
import ZxText from "zxComponents/common/ZxText";
import { TSTYLES } from "zxStyles/constants";
import IconWithChain from "../../../Screener/IconWithChain";

const WatchlistTile = React.forwardRef(
  (
    {
      watchlistItem,
      draggableProps,
      dragHandleProps,
      index,
      refetch,
      isFetching,
      onRemove,
    },
    ref
  ) => (
    <CustomColumn ref={ref} width="100%" {...draggableProps}>
      <PaddingComponent padding="12px 0">
        <Content
          watchlistItem={watchlistItem}
          dragHandleProps={dragHandleProps}
          index={index}
          refetch={refetch}
          isFetching={isFetching}
          onRemove={onRemove}
        />
        <PaddingComponent height="8px" />
        <PriceWhenAddedBanner watchlistItem={watchlistItem} />
      </PaddingComponent>
      <Divider borderColor="var(--border-light)" />
    </CustomColumn>
  )
);

const Content = ({
  watchlistItem,
  dragHandleProps,
  index,
  refetch,
  isFetching,
  onRemove,
}) => {
  const isNft = watchlistItem?.category === WATCHLIST_CATEGORIES.NFT;
  const priceData = watchlistItem?.price_chart?.map((v) => [
    v.timestamp,
    v.price,
  ]);
  const priceChange = watchlistItem?.price_change_24h;

  const getFirstRowValue = () => {
    const tokenPrice = watchlistItem?.price_usd?.value;
    return !isNft
      ? tokenPrice
        ? `$${abbreviateNumber(tokenPrice)}`
        : `-`
      : watchlistItem?.floor_price?.display_value ?? "-";
  };
  return (
    <CustomRow gap="6px" justifyContent="space-between">
      <CustomRow gap="4px">
        <div {...dragHandleProps}>
          <CustomColumn alignItems="flex-start">
            <DotsSixVertical size={18} color="var(--text-2)" />
          </CustomColumn>
        </div>
        <NameImageComp watchlistItem={watchlistItem} />
      </CustomRow>
      <CustomColumn>
        {priceData && (
          <PaddingComponent paddingLeft="8px">
            <GraphComp
              prices={priceData}
              index={index}
              priceChange={priceChange}
            />
          </PaddingComponent>
        )}
      </CustomColumn>
      <CustomRow alignItems="start">
        <CustomColumn alignItems="end">
          <ZxText style={TSTYLES.body3} cursor="pointer">
            {getFirstRowValue()}
          </ZxText>
          <PriceChange
            value={priceChange?.value > 0}
            displayValue={priceChange?.display_value}
            fontSize="13px"
            fontWeight="500"
          />
        </CustomColumn>
        <ThreeDotsButton
          refetch={refetch}
          watchlistItem={watchlistItem}
          isFetching={isFetching}
          onRemove={onRemove}
          index={index}
        />
      </CustomRow>
    </CustomRow>
  );
};

const NameImageComp = ({ watchlistItem }) => {
  const isNft = watchlistItem?.category === WATCHLIST_CATEGORIES.NFT;
  const imageSrc = isNft
    ? watchlistItem?.nft_metadata?.collection_image ?? null
    : watchlistItem?.token_metadata?.logo ?? null;
  const displayName = isNft
    ? watchlistItem?.nft_metadata?.collection_name
    : watchlistItem?.token_metadata?.symbol;
  const link = watchlistItem?.token_page_url;
  const getSecondRowValue = () => {
    return !isNft
      ? watchlistItem?.market_cap_usd?.display_value ?? "No data"
      : watchlistItem?.top_offer_price?.display_value ?? "No data";
  };
  const tokenMetadata = watchlistItem.token_metadata;
  return (
    <CustomRow gap="4px" alignItems="center" minWidth="80px">
      <IconWithChain
        src={imageSrc}
        alt={tokenMetadata?.name}
        chain={watchlistItem?.chain_id}
        imageType={isNft ? IMAGE_TYPES.SMALL_NFT : IMAGE_TYPES.SMALL_TOKEN}
        showImageFullHeightWidth
        iconHeight="24px"
        iconWidth="24px"
        chainHeight="10px"
        chainWidth="10px"
      />
      <CustomColumn>
        <ConditionalLink to={link}>
          <ZxText fontSize="13px" pointer={link ? "cursor" : "auto"}>
            {getTrimmedString(displayName, 7)}
          </ZxText>
        </ConditionalLink>
        <ZxText style={TSTYLES.secondary2}>{getSecondRowValue()}</ZxText>
      </CustomColumn>
    </CustomRow>
  );
};

const GraphComp = ({ prices, index, priceChange }) => {
  return (
    <TrendingItemChartPreview
      id={`watchlist-graph-${index}`}
      width={60}
      height={24}
      graphData={prices}
      color={priceChange?.value >= 0 ? "var(--success)" : "var(--error)"}
      stopColor1={
        priceChange?.value >= 0 ? "var(--success)" : "var(--error)"
      }
      stopColor2={
        priceChange?.value >= 0 ? "var(--success)" : "var(--error)"
      }
      stopOpacity1={0.2}
      stopOpacity2={0}
    />
  );
};

const ThreeDotsButton = ({
  refetch,
  watchlistItem,
  isFetching,
  onRemove,
  index,
}) => {
  const [showPopUpCard, setShowPopUpCard] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);

  const handleRemove = useCallback(async () => {
    try {
      setShowPopUpCard(false);
      setIsRemoveLoading(true);

      const response = await removeFromWatchlist(
        getRemoveWatchlistParams(watchlistItem)
      );

      if (response?.status === "ok") {
        refetch();
        setIsRemoveLoading(false);
        handleSuccessSnackbar("Removed from watchlist");
        onRemove(index);
        trackEvent("remove_from_watchlist", {
          address: watchlistItem?.token_address,
          chainId: watchlistItem?.chain_id,
          tokenSlug: watchlistItem?.token_slug,
          collectionId: watchlistItem?.identifier,
        });
        return true;
      }
      handleErrorSnackbar(
        response,
        "Failed to remove from watchlist at the moment!"
      );
      return false;
    } catch (e) {
      handleErrorSnackbar(e, "Failed to remove from watchlist at the moment!");
      setIsRemoveLoading(false);
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchlistItem?.address, refetch]);

  if (showPopUpCard) {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setShowPopUpCard(false);
        }}>
        <PaddingComponent height="16px">
          <PPLxHoverCard
            open={showPopUpCard}
            content={
              <CursorDiv onClick={handleRemove}>
                <PaddingComponent padding="8px 16px">
                  <CustomText text="Remove" color="var(--text-1)" />
                </PaddingComponent>
              </CursorDiv>
            }
            placement="top">
            {isRemoveLoading || isFetching ? (
              <SpinnerComponent />
            ) : (
              <DotsThreeVertical size={16} color="var(--text-1)" />
            )}
          </PPLxHoverCard>
        </PaddingComponent>
      </ClickAwayListener>
    );
  }
  if (isRemoveLoading) return <SpinnerComponent />;
  return (
    <CursorDiv
      onClick={() => {
        setShowPopUpCard(true);
      }}>
      <PaddingComponent paddingTop="3px">
        <DotsThreeVertical size={16} color="var(--text-1)" />
      </PaddingComponent>
    </CursorDiv>
  );
};

export const PriceWhenAddedBanner = ({ watchlistItem }) => {
  const isNft = watchlistItem?.category === WATCHLIST_CATEGORIES.NFT;
  const source = watchlistItem?.source;
  const isTopToken = source === WATCHLIST_SOURCES.TOP_TOKENS;

  if (isTopToken) {
    return (
      <div className={classes.watchlistBanner}>
        <CustomRow gap="4px" alignItems="center">
          <Trophy weight="duotone" color="var(--primary-color" size={14} />
          <CustomText
            color="var(--text-2)"
            fontWeight="400"
            fontSize="11px"
            lineHeight="14px"
            fontStyle="italic"
            text={`Top ranking ${isNft ? "collection" : "token"}`}
          />
        </CustomRow>
      </div>
    );
  }
  const {
    price_when_added: price,
    floor_price_when_added: fPrice,
    timestamp_when_added: timestamp,
  } = watchlistItem;
  if (!price && !fPrice) return null;

  const displayValue = !isNft
    ? watchlistItem?.market_cap_when_added?.display_value ?? "-"
    : fPrice?.display_value ?? "-";
  const formattedDate = epochToReadable(timestamp);
  const text = `Watchlisted at ${
    isNft ? `Floor Price` : `M.Cap`
  }: ${displayValue} on ${formattedDate}`;
  return (
    <div className={classes.watchlistBanner}>
      <Star weight="duotone" color={"#FFA800"} />
      <CustomText
        color="var(--text-2)"
        fontWeight="400"
        fontSize="11px"
        lineHeight="14px"
        fontStyle="italic"
        text={text}
      />
    </div>
  );
};
export default WatchlistTile;
