import React from "react";
import ZxText from "./ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
const backgroundStyles = {
  primary: {
    background: "var(--primary-color10)",
    color: "primary",
  },
  secondary: {
    background: "var(--secondary-color20)",
    color: "secondary",
  },
  text: {
    background: "var(--elevation-2)",
    color: "text-2",
  },
  closed: {
    background: "var(--closed-bg)",
    color: "closed",
  },
  burn: {
    background: "var(--error-light)",
    color: "error",
  },
  sale: {
    background: "var(--success-light)",
    color: "success",
  },
  success: {
    background: "var(--success-light)",
    color: "success",
  },
  transfer: {
    background: "var(--purple-light)",
    color: "purple",
  },
  mint: {
    background: "var(--primary-color10)",
    color: "primary",
  },
  warning: {
    background: "var(--warning-light)",
    color: "warning",
  },
};
const ZxBadge = ({ children, variant = "secondary" }) => {
  const colors = backgroundStyles[variant];
  const { background, color } = colors;
  return (
    <div
      style={{
        background,
        padding: "4px",
        borderRadius: "4px",
        width: "fit-content",
        height: "fit-content",
      }}>
      <ZxText
        style={TSTYLES.secondaryLabel}
        color={color}
        textTransform="uppercase">
        {children}
      </ZxText>
    </div>
  );
};

export default ZxBadge;
