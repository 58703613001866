import {
  PaddingComponent,
  CustomRow,
  CustomColumn,
  CustomText,
} from "components/UI/Components/Components";
import React, { useState } from "react";
import { PriceCard } from "./PriceCard";
import { TopProtocolCard } from "./TopProtocolCard";
import { Info } from "@phosphor-icons/react";
import { useFetchPriceChartData } from "components/V2/Token/TokenChart/ChartDataPriceUtils";
import { abbreviateNumber } from "utils/misc";
import classes from "./styles.module.css";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { Modal } from "../../../node_modules/@mui/material/index";
import Blocks from "./Blocks";
import { TopDapps } from "./TopDapps";
import RecentTransactions from "./RecentTransactions";
import { Chart } from "utils/constants";
import TradingViewGraph from "components/V2/TradingViewGraph/TradingViewGraph";

export const Overview = ({ metadata, chain_id, chainType }) => {
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const { data: tokenChartData, isLoading } = useFetchPriceChartData(
    metadata?.native_token?.coingecko_slug,
    [undefined, undefined, undefined],
    Chart.DURATION_DAYS["1D"],
    true
  );

  let priceDollarValue = null;
  const latestPrice =
    tokenChartData?.prices?.[tokenChartData?.prices?.length - 1][1];
  if (latestPrice != null) {
    priceDollarValue = `${abbreviateNumber(latestPrice)}`;
  }
  const firstPrice = tokenChartData?.prices?.[0][1];

  const pricePercentageChange = ((latestPrice - firstPrice) / firstPrice) * 100;
  const nativeTokenChange = {
    display_value: `${Math.abs(pricePercentageChange).toFixed(2)}%`,
    direction: pricePercentageChange >= 0 ? 1 : -1,
    value: pricePercentageChange,
  };

  if (metadata == null) {
    return null;
  }
  return (
    <PaddingComponent paddingTop={"16px"}>
      <CustomRow gap="16px">
        {metadata?.native_token && (
          <PriceCard
            id="native_token"
            isLoading={isLoading}
            pricePercentageChange={nativeTokenChange}
            prices={tokenChartData?.prices}
            setOpenPriceModal={setOpenPriceModal}
            topChild={
              <CustomColumn gap="6px">
                <CustomRow gap="5px">
                  <CustomText
                    lineHeight="130%"
                    fontWeight="600"
                    text={metadata?.native_token?.symbol}
                  />
                  <CustomText
                    lineHeight="130%"
                    text="Native Token"
                    color="var(--text-3)"
                  />
                </CustomRow>
                <CustomRow gap="8px" alignItems="center">
                  <Image
                    className={classes.nativeTokenLogo}
                    src={metadata?.native_token?.logo}
                    imageType={IMAGE_TYPES.TOKEN}
                    alt={metadata?.native_token?.symbol}
                  />
                  <CustomText
                    text={
                      `$${priceDollarValue}` ??
                      `$${metadata?.native_token?.latest_price?.display_value}`
                    }
                    fontWeight="600"
                    color="var(--text-1)"
                    fontSize="20px"
                  />
                </CustomRow>
              </CustomColumn>
            }
          />
        )}
        {metadata?.weekly_active_addresses && (
          <PriceCard
            id="weekly_active_addresses"
            cursor={null}
            isLoading={isLoading}
            prices={metadata?.weekly_active_addresses?.active_addresses_chart}
            pricePercentageChange={
              metadata?.weekly_active_addresses?.active_addresses_change
            }
            // tokenChartData={tokenChartData}
            showbar={false}
            topChild={
              <CustomColumn gap="6px">
                <CustomText
                  lineHeight="130%"
                  text="Weekly Active Addresses"
                  textAlign="left"
                />
                <CustomText
                  fontWeight="600"
                  fontSize="24px"
                  lineHeight="130%"
                  text={
                    metadata?.weekly_active_addresses?.active_addresses
                      ?.display_value
                  }
                  color="var(--text-1)"
                  textAlign="left"
                />
                {metadata?.weekly_active_addresses?.unique_users
                  ?.display_value && (
                  <CustomRow gap="6px" alignItems="center">
                    <CustomText
                      fontWeight="600"
                      fontSize="12px"
                      lineHeight="150%"
                      text={`${metadata?.weekly_active_addresses?.unique_users?.display_value} Unique Users`}
                      color="var(--text-3)"
                      textAlign="left"
                    />
                    <Info size={14} color="var(--text-3)" weight="fill" />
                  </CustomRow>
                )}
              </CustomColumn>
            }
          />
        )}
        {metadata?.top_protocols && (
          <TopProtocolCard topProtocols={metadata?.top_protocols} />
        )}
      </CustomRow>
      <PaddingComponent height={"16px"} />
      {/* for opening graph on clicking the native token card */}
      <Modal open={openPriceModal} onClose={() => setOpenPriceModal(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.modalContentWrapper}>
            <TradingViewGraph
              address={metadata?.native_token?.symbol}
              chain={metadata?.chain_title}
              showPriceHeader={true}
              showSymbolInHeader={true}
            />
          </div>
        </div>
      </Modal>
      <Blocks
        chainLogo={metadata?.logo}
        chain_id={chain_id}
        chainType={chainType}
      />
      {metadata?.type === "COSMOS" && metadata?.dapps?.length > 0 && (
        <TopDapps dapps={metadata?.dapps} />
      )}

      <RecentTransactions chainId={chain_id} />
    </PaddingComponent>
  );
};
