import classes from "./FilterRowTile.module.css";
import formClasses from "components/UI/PopUpForm/PopUpForm.module.css";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { getChainMetaData } from "utils/misc";
import { useCallback } from "react";

const TokenRowTile = ({
  token,
  setTokensSelected,
  tokensSelected,
  index,
  isExplorer = false,
  tokenTuples,
  setTokenTuples,
}) => {
  const chainMetaData = getChainMetaData(token.chain_id);
  const isActive = isExplorer
    ? tokenTuples.some(
        (tuple) => tuple[1] === token.address && tuple[0] === token.chain_id
      )
    : tokensSelected.includes(token);
  const tokenActiveClass = isActive ? classes.active : "";

  const onToggle = useCallback(() => {
    if (isActive) {
      const updatedTokens = tokensSelected.filter(function (chainVal) {
        if (isExplorer) {
          return chainVal !== token.address;
        } else {
          return chainVal !== token;
        }
      });
      setTokensSelected(updatedTokens);
      setTokenTuples((old) =>
        old.filter((tuple) => tuple[1] !== token.address)
      );
      return;
    } else {
      if (isExplorer) {
        const alreadyExists = tokenTuples.find((tuple) => {
          return tuple[1] === token.address && tuple[0] === token.chain_id;
        });
        if (alreadyExists) return;
        setTokenTuples((old) => [...old, [token.chain_id, token.address]]);
      } else {
        setTokensSelected((old) => [...old, token]);
      }
    }
  }, [
    isActive,
    token,
    tokensSelected,
    setTokensSelected,
    isExplorer,
    setTokenTuples,
    tokenTuples,
  ]);
  return (
    <div
      className={`${classes.box} ${classes.pointer} ${classes.noselect} ${tokenActiveClass}`}
      onClick={onToggle}>
      <div className={classes.spacingRowTile}>
        <div className={classes.rowTile}>
          <input
            type="checkbox"
            checked={isActive}
            onChange={() => {}}
            readOnly></input>
          <div className={classes.tokenIconWrapper}>
            <Image
              className={classes.tokenIcon}
              src={isExplorer ? token?.logo : token?.token_metadata?.logo}
              imageType={IMAGE_TYPES.TOKEN}
              alt={`${token?.token_metadata?.symbol}-icon`}
            />
            {token.chain_id && token.chain_id !== "Ethereum" && (
              <img
                className={classes.tokenChainIcon}
                src={chainMetaData?.overlayIcon}
                alt={`${token?.chain_id}-overlay-icon`}
              />
            )}
          </div>
          <span className={classes.title}>
            {isExplorer ? token.symbol : token.token_metadata?.symbol}
          </span>
        </div>
        <div className={classes.subtitle}>{token.chain_id}</div>
      </div>
      <div className={formClasses.divider}></div>
    </div>
  );
};

export default TokenRowTile;
