import { getGlobalNewsForToken } from "api/feed.api";
import withLoader from "components/core/withLoader";
import React, { useContext, useEffect, useMemo } from "react";
import { QueryKeyConstants } from "utils/constants";
import {
  CustomRow,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import { ActivityAvatar } from "components/FeedItemV2/GroupedActivityWrapper";
import ActivityHeader from "components/FeedItemV2/ActivityHeader";
import {
  removeQuotesFromText,
  transformGroupedActivityQuery,
} from "utils/misc";
import GroupedActivityDetailsEngagements from "components/Feed/GroupedActivityDetails/GroupedActivityDetailsEngagements";
import { useParams, useRouteMatch, Route, Switch } from "react-router-dom";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";
import Comments from "components/Feed/GroupedActivityDetails/Comments";
import FeedItemV2 from "components/FeedItemV2/FeedItemV2";
import TwitterThreads from "components/TrendingActivities/TwitterThreads";
import PaginatedActivities from "components/PaginatedActivities/PaginatedActivities";
import { NewsDetailsGhost } from "./NewsDetailsGhost";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const NewsDetails = ({ tokenAddress, chainId, queryData }) => {
  return (
    <BaseWrapper mixpanelEvent="NewsDetails">
      <InnerNewsDetails
        tokenAddress={tokenAddress}
        chainId={chainId}
        data={queryData}
      />
    </BaseWrapper>
  );
};

let InnerNewsDetails = ({ tokenAddress, chainId, queryData }) => {
  const { setTitle } = useContext(LeftSideHeaderContext);
  useEffect(() => {
    setTitle("News Details");
  }, [setTitle]);
  const params = useParams();
  const tabsList = useMemo(() => {
    return [
      {
        title: `Transactions`,
        link: "transactions",
      },
      {
        title: `Twitter (${queryData?.tweet_ids.length})`,
        link: "twitter",
      },
      // {
      //   title: `Comments (${queryData?.engagements?.replies?.count})`,
      //   link: "comments",
      // },
    ];
  }, [queryData?.tweet_ids.length]);
  let match = useRouteMatch();
  return (
    <div>
      <CustomRow gap="20px" alignItems="center" padding="20px 0 0 0">
        <ActivityAvatar
          actor={queryData?.actor}
          onActorUpdate={() => {}}
          type={queryData?.type}
        />
        <ActivityHeader
          actor={queryData?.actor}
          timestamp={queryData?.timestamp}
          showFullDateTime={true}
          type={queryData?.type}
          category={queryData?.category}
          showSettings={false}
          isGroupedActivityDetails={true}
        />
      </CustomRow>
      <PaddingComponent height="15px" />
      <CustomText
        text={removeQuotesFromText(queryData?.twitter_news)}
        color="var(--text-1)"
        fontSize="15px"
      />
      <GroupedActivityDetailsEngagements
        engagements={queryData?.engagements}
        contentId={queryData?.id}
        contentTimestamp={queryData?.timestamp}
        type={queryData?.type}
        identities={queryData?.identities}
        queryKey={[
          ...transformGroupedActivityQuery(queryData.id, queryData.type),
        ]}
      />
      <CustomLinkTabBar
        zIndex={TABBAR_ZINDEX.HIGH}
        currentStep={params.active_tab ?? "transactions"}
        tabsList={tabsList}
      />
      <PaddingComponent paddingTop="16px">
        <Switch>
          <Route path={`${match.path}/transactions`}>
            <>
              {queryData?.groups.map((item, index) => {
                return <FeedItemV2 key={index} groupedActivity={item} />;
              })}
              <PaginatedActivities
                identifier={tokenAddress}
                activeChainId={chainId}
                activitiesForContract
                type="contract_details"
                exclude_activity_groups_with_hex_actor={true}
              />
            </>
          </Route>
          <Route path={`${match.path}/twitter`}>
            <TwitterThreads referenceThreads={queryData?.tweet_ids} />
          </Route>

          <Route path={`${match.path}/comments`}>
            <Comments
              comments={queryData?.engagements?.replies?.threads}
              contentId={queryData?.id}
              type={queryData?.type}
              identities={queryData?.identities}
              profiles={queryData?.profiles}
              parentId={queryData?.id}
              // for likes and bookmarks
              queryKey={[
                ...transformGroupedActivityQuery(
                  queryData?.id,
                  queryData?.type
                ),
              ]}
              // since grouped activity doesn't have ancestors, just pass the current query key
              queryKeys={[
                transformGroupedActivityQuery(queryData.id, queryData?.type),
              ]}
            />
          </Route>
        </Switch>
      </PaddingComponent>
    </div>
  );
};

InnerNewsDetails = withLoader(InnerNewsDetails, ({ data }) => data, {
  queryKey: ({ data }) => [
    QueryKeyConstants.GROUPED_ACTIVITY,
    data?.id?.toString(),
    data?.type,
  ],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
});

export default withLoader(
  NewsDetails,
  (props) => getGlobalNewsForToken(props),
  {
    queryKey: (props) => [
      QueryKeyConstants.NEWS_DETAILS,
      props.tokenAddress,
      props.chainId,
    ],
    queryOptions: () => {
      return {
        staleTime: 1000 * 60 * 5,
      };
    },
    renderNotFound: false,
    CustomLoader: NewsDetailsGhost,
  }
);
