import ActivityDetailsHeader from "./ActivityDetailsHeader";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";
import { useParams } from "react-router-dom";
import { PaddingComponent } from "components/UI/Components/Components";

import {
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom";
import TransactionDetails from "./Transactions/TransactionDetails";
import TransactionLogs from "./Transactions/Logs/TransactionLogs";
import TransactionInternalCalls from "./Transactions/InternalCalls/TransactionInternalCalls";
import classes from "./ActivityDetails.module.css";

import { useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useMemo, useState } from "react";
import { fetchTxnTraces, getActivityById } from "api/feed.api";
import { GlobalContext } from "contextStates/Global";
import {
  CustomRow,
  CustomText,
  Divider,
} from "components/UI/Components/Components";
import { Token } from "components/GroupFeedItem/components/FeedDescription";
import { CaretRight } from "@phosphor-icons/react";
import GroupedActivityDetailsEngagements from "components/Feed/GroupedActivityDetails/GroupedActivityDetailsEngagements";
import {
  handleFeedItemClick,
  removeQuotesFromText,
  transformGroupedActivityQuery,
} from "utils/misc";
import Comments from "components/Feed/GroupedActivityDetails/Comments";
// import classes from "./groupActivityDetailsV2.module.css";
import { ActivityDetailsGhost } from "./ActivityDetailsGhost";
import { useHistory } from "react-router-dom";
import withLoader from "components/core/withLoader";
import { activityGroupHash } from "components/FeedItemV2/group_feed_utils";
import PnlTokenExpandedList from "components/FeedItemV2/PnlTokenList/PnlTokenExpandedList";
import useQueryParams from "customHooks/useQueryParams";

const ActivityDetailsComponent = ({
  queryData: activityData,
  activityId,
  queryKey,
  contentId,
  txnHash,
  chainId,
  chainType,
  refetch,
  hideEngagements = false,
}) => {
  const { setRibbonData } = useContext(GlobalContext);
  let match = useRouteMatch();
  const queryClient = useQueryClient();
  const { active_tab } = useParams();
  const params = useQueryParams();
  //to call refetch function after 5 seconds... this will trigger the useEffect
  const [forceUpdate, setForceUpdate] = useState(0);
  const transactionData = useMemo(
    () => activityData?.activities?.[0]?.transactions?.[0],
    [activityData?.activities]
  );

  useEffect(() => {
    if (activityData?.is_simulated) {
      setRibbonData("SIMULATED TRANSACTION");
      return () => {
        setRibbonData(null);
      };
    }
  }, [activityData?.is_simulated, setRibbonData]);

  useEffect(() => {
    //if activityData.confirmations_count < 10 and timestamp < 10 minutes ago then call refetch function with delay of 5 seconds
    if (
      !activityData?.is_simulated &&
      activityData?.confirmations_count < 10 &&
      activityData?.timestamp &&
      activityData?.timestamp + 600 > Date.now() / 1000
    ) {
      setTimeout(() => {
        refetch();
        setForceUpdate(forceUpdate + 1);
      }, 5000);
    }
  }, [activityData, refetch, forceUpdate]);

  useEffect(() => {
    if (chainType !== "COSMOS") {
      fetchTxnTraces({
        queryClient,
        activityData,
        txnHash,
      });
    }
  }, [activityData, queryClient, txnHash, chainType]);
  const logsCount =
    activityData?.activities?.[0]?.transactions?.[0]?.data?.logs?.length;
  const isSolana =
    activityData?.activities?.transactions?.[0]?.chain_id === "Solana";
  const commentCount = activityData?.engagements?.replies?.count;

  const tabsList = useMemo(() => {
    const addToLink = (link) => {
      return `${link}${params.toString()?.length > 0 ? `?${params.toString()}` : ""}`;
    };

    if (chainType === "COSMOS") {
      return [
        {
          title: "Details",
          // convert params to string add existing params
          link: addToLink("details"),
        },
        {
          title: `Comments ${commentCount ? `(${commentCount})` : ""}`,
          link: addToLink("comments"),
        },
      ];
    }
    if (activityData?.page_type === "transaction") {
      return [
        {
          title: "Details",
          link: addToLink("details"),
        },
      ];
    }

    return [
      {
        title: "Details",
        link: addToLink("details"),
      },
      {
        title: "Internal Calls",
        link: addToLink("internal-calls"),
      },
      {
        title: `Logs ${logsCount ? `(${logsCount})` : ""}`,
        link: addToLink("logs"),
      },
      {
        title: `Comments ${commentCount ? `(${commentCount})` : ""}`,
        link: addToLink("comments"),
      },
    ];
  }, [activityData?.page_type, chainType, commentCount, logsCount, params]);
  const history = useHistory();
  const handleClick = (event) => {
    handleFeedItemClick({
      contentId: activityData.id,
      contentType: "news",
      link: activityData.link,
      history,
      event,
      clickable: true,
      chain_id: activityData.chain_id,
      token_address: activityData.token_address,
    });
  };

  const groupHash = useMemo(
    () => activityGroupHash(activityData),
    [activityData]
  );
  const groupId = contentId;
  const groupType = activityData?.type;
  return (
    <div>
      <ActivityDetailsHeader
        contentId={contentId}
        activityData={activityData}
        isSolana={isSolana}
      />
      {activityData?.type === "transaction" && activityData?.token_address && (
        <>
          <PaddingComponent height="12px" />
          <div onClick={handleClick} className={classes.newsContainer}>
            <CustomRow gap="10px" alignItems="Center">
              <CustomText text="NEWS" fontWeight="500" />
              <Divider borderColor="var(--primary-color)" opacity="0.06" />
            </CustomRow>
            <CustomRow alignItems="center" justifyContent="space-between">
              <CustomRow alignItems="center" gap="12px">
                <Token
                  key={activityData?.token_address}
                  args={activityData?.token_address}
                  profiles={activityData?.profiles}
                  nativeTokensInfo={
                    activityData?.summary?.native_tokens ||
                    activityData?.native_tokens_dict
                  }
                  nftPrices={activityData?.summary?.nft_prices}
                  type={activityData?.type}
                />
                <CustomText
                  text={removeQuotesFromText(activityData?.twitter_news)}
                  color="var(--text-1)"
                  fontSize="15px"
                  textAlign="left"
                />
              </CustomRow>
              <CaretRight size={20} color="var(--text-3)" />
            </CustomRow>
          </div>
        </>
      )}
      <PnlTokenExpandedList
        groupedActivity={activityData}
        showRightPanel={false}
      />
      <GroupedActivityDetailsEngagements
        engagements={activityData?.engagements}
        contentId={contentId}
        contentTimestamp={groupHash}
        hideEngagements={hideEngagements}
        type={groupType}
        identities={activityData?.identities}
        queryKey={queryKey}
      />
      <CustomLinkTabBar
        zIndex={TABBAR_ZINDEX.HIGH}
        currentStep={`${active_tab}${params.toString()?.length > 0 ? `?${params.toString()}` : ""}`}
        tabsList={tabsList}
      />

      <PaddingComponent paddingTop="16px">
        <Switch>
          <Route path={`${match.path}/details`}>
            <TransactionDetails activityData={activityData} />
          </Route>
          <Route path={`${match.path}/internal-calls`}>
            <TransactionInternalCalls
              activityData={activityData}
              activityId={activityId}
              txnHash={transactionData?.tx_id}
            />
          </Route>
          <Route path={`${match.path}/logs`}>
            <TransactionLogs isFullPage={true} activityData={activityData} />
          </Route>
          <Route path={`${match.path}/comments`}>
            <Comments
              contentId={groupId}
              type={groupType}
              showNewComment
              postDetails={activityData}
            />
          </Route>
        </Switch>
      </PaddingComponent>
    </div>
  );
};

export default withLoader(ActivityDetailsComponent, getActivityById, {
  queryKey: (props) =>
    transformGroupedActivityQuery(props?.activityId, "transaction"),
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  renderNotFound: false,
  CustomLoader: ActivityDetailsGhost,
});
