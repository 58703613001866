import { useParams } from "react-router-dom";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";

const TopBar = ({ url }) => {
  let { active_tab } = useParams();

  const currentStep = active_tab || "details";

  const tabsList = [
    {
      title: "Details",
      link: `details`,
    },
    // {
    //   title: "Holdings",
    //   link: `/${url}/holdings`,
    // },
    {
      title: "Leaderboard",
      link: `leaderboard`,
    },
    {
      title: "Liquidity",
      link: `liquidity`,
    },
    {
      title: "Discussion",
      link: `discussion`,
    },
  ];

  return (
    <CustomLinkTabBar
      zIndex={TABBAR_ZINDEX.HIGH}
      currentStep={currentStep}
      tabsList={tabsList}></CustomLinkTabBar>
  );
};

export default TopBar;
