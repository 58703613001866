import { useQuery } from "@tanstack/react-query";
import { signedRequest } from "../../../../api/api";
export const fetchTopBuyersAndSellers = async ({
  address,
  chain_id,
  currentTimeframe,
}) => {
  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/get_top_buyers_sellers_of_token",
    bodyText: JSON.stringify({
      token_address: address,
      chain_id: chain_id,
      period_days: currentTimeframe,
    }),
  });

  return resp?.data?.data;
};

const useTopBuyersAndSellers = (address, chain_id, currentTimeframe) => {
  const topHoldersQuery = useQuery({
    queryKey: ["top_token_buyers_sellers", address, chain_id, currentTimeframe],
    queryFn: () =>
      fetchTopBuyersAndSellers({ address, chain_id, currentTimeframe }),

    enabled: !!address && !!chain_id && !!currentTimeframe,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
  });

  return {
    ...topHoldersQuery,
    topBuyers: topHoldersQuery.data?.top_buyers,
    topSellers: topHoldersQuery.data?.top_sellers,
  };
};

export default useTopBuyersAndSellers;
