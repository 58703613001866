import {
  CustomColumn,
  CustomRow,
  CustomText,
  InfoBox,
} from "components/UI/Components/Components";
import { useState } from "react";
import { createPortal } from "react-dom";
import { Drawer } from "@mui/material";
import SendTokenPanel from "components/V2/Profile/ProfileDetails/Portfolio/SendTokenPanel/SendTokenPanel";
import { abbreviateNumber } from "utils/misc";
import { ArrowDownRight } from "@phosphor-icons/react";
import classes from "./MyHoldingsRightPanel.module.css";
import withNullableError from "zxComponents/common/withNullableError/index";

const PnlValue = ({
  selectedToken,
  showSendButton = false,
  padding = "16px 20px",
  tableVariant = false,
  background = "var(--elevation-1)",
}) => {
  const current_value =
    selectedToken?.total_usd_value?.value ?? selectedToken?.value_usd;
  const buy_value_usd =
    selectedToken?.usd_value_invested ?? selectedToken?.buy_value_usd;

  const [isSendPanelOpen, setIsSendPanelOpen] = useState(false);
  const renderPnl = ({ pnl, pnlPercent, isOpen = false }) => {
    if (!pnl?.value) return null;
    const dv = pnl?.display_value;
    const isValueCloseToZero = Math.abs(pnl?.value) < 1;
    const isADollarAmount = dv?.includes("$");
    return (
      pnl?.value && (
        <CustomColumn gap="4px" alignItems="flex-start">
          <CustomRow gap="4px" alignItems="center" width="100%">
            <CustomText
              text="PNL"
              fontSize="11px"
              fontWeight="500"></CustomText>
            <CustomText
              text={isOpen ? "OPEN" : "CLOSED"}
              fontSize="11px"
              fontWeight="500"
              color={
                isOpen ? "var(--primary-color)" : "var(--text-2)"
              }></CustomText>
          </CustomRow>
          <CustomRow gap="4px" alignItems="flex-end" width="100%">
            <CustomText
              text={
                isADollarAmount && isValueCloseToZero
                  ? "~$0"
                  : pnl?.display_value
              }
              color="var(--text-1)"
              fontSize="13px"
              fontWeight="500"></CustomText>
            {pnlPercent?.value && !isValueCloseToZero && !isADollarAmount && (
              <CustomText
                text={pnlPercent?.display_value}
                fontWeight="500"
                fontSize="11px"
                color={
                  pnlPercent?.value > 0 ? "var(--success)" : "var(--error)"
                }
              />
            )}
          </CustomRow>
        </CustomColumn>
      )
    );
  };
  const isOnlyClosed =
    selectedToken?.open_pnl?.value === 0 ||
    (!selectedToken?.open_pnl && selectedToken?.closed_pnl?.value !== 0);
  if (
    !selectedToken ||
    (!selectedToken?.open_pnl && !selectedToken?.closed_pnl)
  )
    return null;
  return (
    <>
      <InfoBox
        background={background}
        borderWidth="0px"
        radius="8px"
        padding={tableVariant ? "8px 16px" : padding}>
        <CustomRow
          width="100%"
          gap={isOnlyClosed ? "24px" : "0"}
          justifyContent={isOnlyClosed ? "flex-start" : "space-between"}
          alignItems="center">
          {!isOnlyClosed && (
            <CustomColumn gap="4px" alignItems="flex-start">
              <CustomText
                text="CURRENT BALANCE"
                fontSize="12px"
                fontWeight="500"></CustomText>
              <CustomText
                text={`${
                  selectedToken?.amount
                    ? abbreviateNumber(selectedToken?.amount)
                    : selectedToken?.token_balance?.display_value ??
                      selectedToken?.current_balance?.display_value ??
                      "-"
                }`}
                color="var(--text-1)"
                fontSize="13px"
                fontWeight="500"></CustomText>
            </CustomColumn>
          )}
          {buy_value_usd && !isOnlyClosed && (
            <CustomColumn gap="4px" alignItems="flex-start">
              <CustomText
                text="INVESTED"
                fontSize="11px"
                fontWeight="500"></CustomText>
              <CustomText
                text={buy_value_usd?.display_value}
                color="var(--text-1)"
                fontSize="13px"
                fontWeight="500"></CustomText>
            </CustomColumn>
          )}
          {!isOnlyClosed && (
            <CustomColumn gap="4px" alignItems="flex-start">
              <CustomText
                text="CURRENT VALUE / PNL"
                fontSize="11px"
                fontWeight="500"></CustomText>
              <CustomText
                text={`$${abbreviateNumber(current_value)}`}
                color="var(--text-1)"
                fontSize="13px"
                fontWeight="500"></CustomText>
            </CustomColumn>
          )}
          {renderPnl({
            pnl: selectedToken?.open_pnl,
            pnlPercent: selectedToken?.open_pnl_percent,
            isOpen: true,
          })}
          {renderPnl({
            pnl: selectedToken?.closed_pnl,
            pnlPercent: selectedToken?.closed_pnl_percent,
            isOpen: false,
          })}
          {renderPnl({
            pnl: selectedToken?.realised_pnl,
            pnlPercent: selectedToken?.realised_pnl_percent,
            isOpen: false,
          })}
          {renderPnl({
            pnl: selectedToken?.unrealised_pnl,
            pnlPercent: selectedToken?.unrealised_pnl_percent,
            isOpen: true,
          })}
          {showSendButton && (
            <CustomRow
              alignItems="center"
              justifyContent="flex-end"
              flexGrow={1}>
              <button
                className={`${classes.sendButton}`}
                onClick={() => {
                  setIsSendPanelOpen(true);
                }}>
                <ArrowDownRight
                  size="16"
                  weight="bold"
                  style={{ transform: "rotate(-90deg)" }}
                />
                <span>Send</span>
              </button>
            </CustomRow>
          )}
        </CustomRow>
      </InfoBox>
      {createPortal(
        <Drawer
          anchor="right"
          open={isSendPanelOpen}
          onClose={() => setIsSendPanelOpen(false)}>
          <SendTokenPanel
            defaultToken={selectedToken}
            onClose={() => setIsSendPanelOpen(false)}
          />
        </Drawer>,
        document.getElementById("rightPanel")
      )}
    </>
  );
};

export default withNullableError(PnlValue);
