import ContentPagesRenderer from "./ContentPagesRenderer";
import StatusRenderer from "./NewPost/Components/StatusRenderer";
import React, { createContext, useContext } from "react";
import { createPortal } from "react-dom";
import { Drawer } from "@mui/material";
import TokenHoldingsRightPanel from "components/V2/Profile/ProfileDetails/Portfolio/TokenHoldings/TokenHoldingsRightPanel";
import { GlobalContext } from "contextStates/Global";
import { TriggerNextPage } from "./TriggerNextPage";
import { getPolyMarketConditionIds } from "./util";
import { useFetchFeed } from "api/feed.api";

export const PolyMarketFeedContext = createContext({
  conditionIds: [],
});

const FeedItemsList = ({
  activeTab,
  path,
  isHomePageFeedItem = false,
  filter,
  isProfilePage = false,
  passPreferredActor = false,
}) => {
  const {
    data: feedData,
    flattenedFeedData,
    isLoading,
    isSuccess,
    isError,
    refetch,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useFetchFeed({ activeTab, path, filter });
  const { userTokenTransferDetails, setUserTokenTransferDetails } =
    useContext(GlobalContext);
  const conditionIds = getPolyMarketConditionIds(flattenedFeedData);
  return (
    <div>
      <PolyMarketFeedContext.Provider value={{ conditionIds }}>
        <ContentPagesRenderer
          pages={feedData?.pages}
          isTrending={activeTab === "trending"}
          isGlobalFeed={activeTab === "global_feed" || activeTab === "new_feed"}
          isTrendingTimeline={activeTab === "trending_timeline"}
          isHomePageFeedItem={isHomePageFeedItem}
          isProfilePage={isProfilePage}
          passPreferredActor={passPreferredActor}
        />
      </PolyMarketFeedContext.Provider>
      <StatusRenderer
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        refetch={refetch}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        activities={flattenedFeedData}
        showShimmer={isFetchingNextPage}
        isHomePageFeedItem={isHomePageFeedItem}
      />
      {createPortal(
        <Drawer
          anchor="right"
          open={userTokenTransferDetails?.tokenData}
          onClose={() => setUserTokenTransferDetails({})}>
          <TokenHoldingsRightPanel
            currentTokenData={userTokenTransferDetails?.tokenData}
            actorData={userTokenTransferDetails?.actor}
            identifier={userTokenTransferDetails?.actor?.id}
            onClose={() => setUserTokenTransferDetails({})}
          />
        </Drawer>,
        document.getElementById("rightPanel")
      )}
      {!isFetching && hasNextPage && (
        <TriggerNextPage fetchNextPage={fetchNextPage} />
      )}
    </div>
  );
};

export default FeedItemsList;
