import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePostMutation } from "api/feed.api";
import { useQueryClient } from "@tanstack/react-query";
import ContentRenderer from "components/FeedItemV2/ContentRenderer";
import PostCommentRenderer from "../PostCommentRenderer.js";

const NewRepost = ({
  contentId,
  contentType,
  type,
  queryKeys,
  setIsNewPostLoading = () => {},
  isEditorFocused,
  isModal = false,
  onSuccess = () => {},
  onDiscard = () => {},
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const { mutate: newRepostMutation, isPending: isLoading } = usePostMutation({
    setIsSuccess,
    setIsError,
  });

  useEffect(() => {
    setIsNewPostLoading(isLoading);
  }, [isLoading, setIsNewPostLoading]);
  const onSubmit = useCallback(
    ({ threads, selectedPlatforms, channelId }) => {
      newRepostMutation({
        threads: [
          {
            urls: threads[0].urls,
            contents: threads[0].contents,
            id: contentId,
            images: threads[0].images,
          },
        ],
        selectedPlatforms,
        channelId,
        isRepost: true,
        repostType: contentType,
        repostId: contentId,
      });
    },
    [contentId, newRepostMutation, contentType]
  );

  const queryClient = useQueryClient();

  const feedItem = queryClient.getQueryData({
    queryKey: queryKeys?.[0],
  });

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  // if identities dictionary not found in feedItem, try getting it from ancestors i.e.queryKeys[1 onwards]
  const identities = useMemo(() => {
    let identities = feedItem?.identities;
    if (!identities) {
      queryKeys.slice(1).some((queryKey) => {
        //Starts from index 1
        const items = queryClient.getQueriesData({ queryKey });
        items.some(([, item]) => {
          if (item?.identities) {
            identities = item.identities;
            return true;
          }
          return false;
        });
        return !!identities;
      });
    }
    return identities;
  }, [feedItem?.identities, queryClient, queryKeys]);

  const renderedFeedItem = useMemo(
    () =>
      !!feedItem && (
        <ContentRenderer
          onClick={(e) => e.stopPropagation()}
          groupedActivity={feedItem}
          identities={identities}
          profiles={feedItem?.profiles}
          showReplies={false}
          showSettings={false}
          trimDescription
          smallThumbnail
          hideEngagements
          hideRepost
          blockInteraction
          enableCrossPost={true}
        />
      ),
    [feedItem, identities]
  );

  return (
    <PostCommentRenderer
      isLoading={isLoading}
      onSubmit={onSubmit}
      isEditorFocused={isEditorFocused}
      isSuccess={isSuccess}
      setIsSuccess={setIsSuccess}
      isError={isError}
      setIsError={setIsError}
      postDisabled={!contentId}
      placeholder="Comment on this post..."
      isModal={isModal}
      contentId={contentId}
      contentType={contentType}
      renderContent={renderedFeedItem}
      onDiscard={onDiscard}
      enableCrossPost={true}
      source="repost"
    />
  );
};

export default NewRepost;
