import React from "react";
import {
  CustomSkeleton,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import ProfileItem, {
  ProfileItemTypes,
} from "components/V2/Community/ProfileItem/ProfileItem";
import { getSearchResults } from "api/search.api";
import { TransactionShimmer } from "components/Feed/GroupedActivityDetails/GroupActivityDetailsShimmer";
import withLoader from "components/core/withLoader";
import {
  getChainData,
  inferImageType,
  resultType,
} from "components/Pages/AppModule/Header/components/searchbar_utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const SeeAllResults = ({ queryData }) => {
  const history = useHistory();
  if (queryData?.action === "redirect" && queryData?.link) {
    history.replace(queryData.link);
  }
  const searchResults = queryData?.results;
  return (
    <BaseWrapper mixpanelEvent="SeeAllResults">
      <>
        <PaddingComponent height="16px" />
        <CustomText text={`${searchResults?.length} Results`} />
        <PaddingComponent height="16px" />
        {searchResults?.map((resultItem, index) => {
          const type = resultType(resultItem);
          return (
            <ProfileItem
              key={`search-result-${index}`}
              profile={resultItem?.data}
              details={{
                reasons: [
                  {
                    type: "matched_from",
                    search_hints: resultItem?.search_hints,
                  },
                ],
              }}
              profileItemType={ProfileItemTypes.searchResult}
              resultType={resultItem?.type}
              chainData={getChainData(resultItem?.data)}
              imageType={inferImageType(resultItem)}
              hideNetWorth={type !== null}
            />
          );
        })}
        <PaddingComponent height="16px" />
      </>
    </BaseWrapper>
  );
};

const SearchResultsLoading = () => {
  return (
    <>
      <PaddingComponent height="16px" />
      <CustomText
        text={<CustomSkeleton width="90px" variant="text" height="16px" />}
      />
      <PaddingComponent height="16px" />
      {[...Array(5).keys()].map((item) => (
        <div key={`detailed-search-shimmer-${item}`}>
          <TransactionShimmer />
          <PaddingComponent height="16px" />
        </div>
      ))}
    </>
  );
};

export default withLoader(
  SeeAllResults,
  (props) =>
    getSearchResults({
      queryKey: [
        "search_with_redirect",
        props?.searchTerm,
        props?.filters ?? [],
      ],
      endpoint: "api/search/search_with_redirect",
    }),
  {
    queryKey: (props) => [
      "search_with_redirect",
      props?.searchTerm,
      props?.filters ?? [],
    ],
    renderNotFound: false,
    CustomLoader: SearchResultsLoading,
  }
);
