import classes from "./walletTableHeader.module.css";

const WalletsTableHeader = ({ items }) => {
  return (
    <div className={classes.table}>
      <div className={`${classes.tableHeader}`}>
        {[...items].map((item) => (
          <div
            key={item.name}
            style={{
              flex: item.flex,
              textAlign: item.alignment ?? "left",
            }}>
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WalletsTableHeader;
