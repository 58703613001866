import { useQuery } from "@tanstack/react-query";
import { getMutualHoldings } from "api/profile.api";
import { ONE_DAY } from "utils/constants";
import { QUERY_KEYS } from "utils/query_utils";

const useMutualHoldings = ({ identifier }) => {
  const query = useQuery({
    queryKey: QUERY_KEYS.MUTUAL_HOLDINGS({ identifier }),
    queryFn: () => getMutualHoldings({ identifier }),
    staleTime: ONE_DAY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return query;
};
export default useMutualHoldings;
