import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import { getPercentileValues } from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/Graph";
import {
  XYPlot,
  LineSeries,
  GradientDefs,
  AreaSeries,
  YAxis,
  XAxis,
} from "react-vis";
import { abbreviateNumber, epochToReadable } from "utils/misc";

const TrendingTokenChart = ({
  graphData,
  width = 100,
  height = 24,
  customFormat = (d) => d,
  showAxes = false,
}) => {
  if (!graphData || !graphData.length) {
    return null;
  }
  const timeSeries = graphData.map((data) => {
    return {
      x: data[0],
      y: data[1],
    };
  });

  const last = timeSeries[timeSeries.length - 1];
  const first = timeSeries[0];
  const isNegative = last.y < first.y;
  const color = isNegative ? "var(--error)" : "var(--success)";
  const randomGradientId = "grad-" + Math.random() * 100;
  return (
    <div>
      <XYPlot
        width={width}
        height={height}
        margin={{ left: 0, top: 5, bottom: 5 }}>
        <LineSeries
          data={timeSeries}
          curve={"curveMonotoneX"}
          color={color}
          strokeWidth={1.5}
          style={{
            stroke: color,
            fill: "none",
          }}
        />
        <GradientDefs>
          <linearGradient id={randomGradientId} x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor={color} stopOpacity={0.2} />
            <stop offset="100%" stopColor={color} stopOpacity={0.01} />
          </linearGradient>
        </GradientDefs>
        <AreaSeries
          data={timeSeries}
          color={true ? `url(#${randomGradientId})` : "transparent"}
        />
        {showAxes && (
          <>
            <XAxis
              tickFormat={(d) => {
                return epochToReadable(d / 1000);
              }}
              tickValues={getPercentileValues(timeSeries.map((data) => data.x))}
              tickSizeInner={0}
              tickSizeOuter={5}
              style={{
                ticks: {
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "130%",
                  textAlign: "center",
                  letterSpacing: " -0.02em",
                  fill: "var(--text-3)",
                  marginLeft: "20px",
                  marginBottom: "30px",
                },
                line: { stroke: "var(--border-dark)" },
              }}
            />

            <YAxis
              tickFormat={(d) => {
                return customFormat
                  ? customFormat(d)
                  : `$${abbreviateNumber(d)}`;
              }}
              tickTotal={4}
              tickSizeInner={0}
              tickSizeOuter={0}
              orientation="right"
              color="var(--text-2)"
              style={{
                ticks: {
                  fill: "var(--text-3)",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "130%",
                  textAlign: "center",
                  letterSpacing: " -0.02em",
                  marginLeft: "20px",
                  marginBottom: "30px",
                },
                line: { stroke: "var(--border-dark)" },
              }}
            />
          </>
        )}
      </XYPlot>
    </div>
  );
};

export default withErrorBoundary(TrendingTokenChart);
