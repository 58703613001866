import withLoader from "components/core/withLoader";
import { getActivityDetailsByTokenTransferId } from "api/feed.api";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

const TokenTransferId = ({ queryData, chainId, blockNumber, txIdx, actor }) => {
  return (
    <Redirect
      to={`/group_activity_details/${queryData?.group_id}/${queryData?.activity_id}`}
    />
  );
};

export default withLoader(
  TokenTransferId,
  getActivityDetailsByTokenTransferId,
  {
    queryKey: (props) => ["activity_details", props.txIdx],
  }
);
