import React, { useState } from "react";
import DiscardConfirmationDialog from "./DiscardConfirmationDialog";
import { trackEvent } from "utils/event_tracking";

const DiscardButton = ({
  className,
  discardCallback,
  noConfirmation,
  text = "Discard",
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = () => {
    if (noConfirmation) {
      discardCallback();
      return;
    }
    trackEvent("post_discarded");
    handleDialogToggle();
  };

  const handleDialogToggle = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <div onClick={(e) => e.stopPropagation()} className={className}>
      <button type="button" className={className} onClick={handleClick}>
        {text}
      </button>

      <DiscardConfirmationDialog
        handleDialogToggle={handleDialogToggle}
        openDialog={openDialog}
        discardCallback={discardCallback}
      />
    </div>
  );
};

export default DiscardButton;
