import Dialog from "@mui/material/Dialog";
import EditGroupPopup from "../EditGroupPopup/EditGroupPopup";
import { useContext, useState } from "react";
import classes from "./CreateGroupButton.module.css";
import { createPortal } from "react-dom";
import RightPanel from "../../../../Pages/AppModule/RightPanel/RightPanel";
import { CreateGroupPanel } from "../CreateGroupPanel/CreateGroupPanel";
import { MessagingContext } from "../../../../../contextStates/MessagingContext";
import { Plus } from "@phosphor-icons/react";
import { CustomRow } from "../../../Profile/ProfileDetails/wallets/AddWallet/AddWalletComponent";
import { GlobalContext } from "../../../../../contextStates/Global";

const CreateGroupButton = () => {
  const [isRightPlanelOpen, setIsRightPanelOpen] = useState(false);
  const { setShowAddGroupPanel } = useContext(MessagingContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { featureFlags } = useContext(GlobalContext);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onClick = () => {
    if (featureFlags?.show_streaming_groups) {
      setShowAddGroupPanel(true);
    } else {
      openModal();
    }
  };

  return (
    <>
      <div className={classes.createGroupButtonContainer}>
        <button className={classes.createGroupButton} onClick={onClick}>
          <CustomRow gap="4px" justifyContent="center">
            <Plus size={13} weight="bold" />
            <span>New Group</span>
          </CustomRow>
        </button>
      </div>
      {isRightPlanelOpen &&
        createPortal(
          <RightPanel
            onClose={() => {
              setIsRightPanelOpen(false);
            }}>
            <CreateGroupPanel onClose={() => setIsRightPanelOpen(false)} />
          </RightPanel>,
          document.getElementById("rightPanel")
        )}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "var(--base)",
          },
        }}
        maxWidth={"xs"}
        fullWidth
        open={isModalOpen}
        onClose={closeModal}>
        <EditGroupPopup onClose={closeModal} />
      </Dialog>
    </>
  );
};

export default CreateGroupButton;
