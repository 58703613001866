import React from "react";
import { useHistory } from "react-router-dom";
import classes from "./feedItemV2.module.css";
import commentIcon from "assets/svg/comment.svg";
import likeIcon from "assets/svg/heart.svg";
import shareIcon from "assets/svg/share-icon.svg";
import GroupedActivityWrapper from "./GroupedActivityWrapper";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import { useQuery } from "@tanstack/react-query";
import { getGroupedActivityFromBlob } from "api/feed.api";
import { ONE_DAY, QueryKeyConstants } from "utils/constants";
import { PostAttribution } from "./FeedPost";
import { handleFeedItemClick } from "utils/misc";
const FeedItemV2 = ({
  groupedActivity,
  isAdminView,
  adminOptions = { showParsers: false },
  nested = false,
  profiles,
  identities,
  hideStats,
  hideEngagements,
  clickable = true,
  showFullDateTime = false,
  refetchActivityDetailsPage,
  showReplies = false,
  showSettings = true,
  //dont use this variable directly, use showRepliesExceptWelcomePost instead
  showViewPost = false,
  heroProfiles = {},
  children,
  noMarginTop,
  noMargin,
  noRedirection = false,
  hideNftTransfers = false,
  solidBg = false,
  isGroupedActivityDetails = false,
  smallThumbnail = false,
  showThreadLine = false,
  maxNftsCount = 4,
  trimDescription = false,
  trimLimit = 300,
  hideExtraRows = false,
  blockInteraction = false,
  isHomePageFeedItem = false,
  isProfilePage = false,
  noPadding = false,
  isRepost,
  isParent,
  hasParent,
}) => {
  //dont show replied for welcome post
  const history = useHistory();
  const isWelcomePost = groupedActivity?.type === "welcomePosts";
  const isWelcomeAllPost =
    groupedActivity?.type === "welcomePosts" &&
    groupedActivity?.welcome_posts?.length > 0;
  const showRepliesExceptWelcomePost = isWelcomePost ? false : showReplies;
  const { data: groupedActivityData, refetch: refetchGroupedActivity } =
    useQuery({
      queryFn: () => {
        console.log("feedItemV2.js: getGroupedActivityFromBlob");
        return getGroupedActivityFromBlob({
          id: groupedActivity?.id,
          type: groupedActivity?.type,
        });
      },
      queryKey: [
        QueryKeyConstants.GROUPED_ACTIVITY_FROM_BLOB,
        groupedActivity?.id?.toString(),
        groupedActivity?.type,
      ],
      initialData: groupedActivity,
      staleTime: ONE_DAY,
    });

  profiles = profiles ?? groupedActivityData?.profiles;
  identities = groupedActivityData?.identities ?? identities;
  let actor = null;
  if (identities && identities[groupedActivityData?.actor?.id] != null) {
    actor = identities[groupedActivityData.actor?.id];
  } else if (
    identities &&
    identities[groupedActivityData?.actor?.identity_id] != null
  ) {
    actor = identities[groupedActivityData?.actor?.identity_id];
  } else if (
    profiles &&
    profiles[groupedActivityData?.actor?.address] != null
  ) {
    actor = profiles[groupedActivityData?.actor?.address];
  } else {
    actor = groupedActivityData?.actor;
  }
  if (actor === undefined) {
    actor = {};
  }
  if (actor && !actor?.id && groupedActivityData?.actor?.id) {
    actor.id = groupedActivityData?.actor?.id;
  }
  const activityReplies = groupedActivityData?.engagements?.replies;

  const slicedThreads = activityReplies?.threads?.slice(0, 1);
  if (!groupedActivityData) {
    return null;
  }
  return (
    <div
      className={`${classes.groupedActivity}   ${
        (!!nested && classes.nested) || ""
      } ${!!noMarginTop ? classes.noMarginTop : ""} ${
        !groupedActivityData?.id ? classes.nonClickable : ""
      } ${(!!showViewPost && classes.reposted) || ""} 
      ${(!!noPadding && classes.noPadding) || ""}
      ${(!!noMargin && classes.noMargin) || ""}
      ${(!!isParent && classes.isParent) || ""}
      ${!!isWelcomePost && classes.welcomePost}
      ${(!!hasParent && isHomePageFeedItem && classes.hasParent) || ""}
      ${(!!blockInteraction && classes.blockInteraction) || ""}
      ${groupedActivity?.type === "news" && classes.news} 
      ${isWelcomeAllPost && classes.welcomePost} 
      ${(!!isHomePageFeedItem && classes.homePageGroupedActivity) || ""}
      ${isProfilePage ? classes.profilePageItem : ""}
      `}
      onClick={(e) => {
        if (isRepost) {
          e.stopPropagation();
          handleFeedItemClick({
            contentId: groupedActivity.id,
            contentType: groupedActivity.type,
            link: groupedActivity.link,
            history,
            event: e,
            clickable: true,
            noRedirection: false,
            repliesCount: groupedActivity?.engagements?.replies?.count,
          });
        }
      }}>
      <ActivityInteractionContext
        actorName={groupedActivityData?.interaction?.actor?.display_name}
        type={groupedActivityData?.interaction?.type}
      />
      {!isGroupedActivityDetails && !isProfilePage && (
        <PostAttribution
          postData={groupedActivityData}
          identities={identities}
        />
      )}
      <GroupedActivityWrapper
        hasReplies={
          showRepliesExceptWelcomePost &&
          slicedThreads?.length > 0 &&
          activityReplies?.count > 0 &&
          activityReplies?.threads?.length
        }
        refetchActivityDetailsPage={refetchActivityDetailsPage}
        isAdminView={isAdminView}
        groupedActivity={groupedActivityData}
        identities={identities}
        profiles={profiles}
        actor={actor}
        adminOptions={adminOptions}
        hideStats={hideStats || groupedActivityData?.type !== "activity_group"}
        hideEngagements={hideEngagements}
        showFullDateTime={showFullDateTime}
        children={children}
        hideNftTransfers={hideNftTransfers}
        showSettings={isGroupedActivityDetails}
        showViewPost={showViewPost}
        refetchGroupedActivity={refetchGroupedActivity}
        isGroupedActivityDetails={isGroupedActivityDetails}
        smallThumbnail={smallThumbnail}
        showThreadLine={showThreadLine}
        maxNftsCount={maxNftsCount}
        trimDescription={trimDescription}
        trimLimit={trimLimit}
        hideExtraRows={hideExtraRows}
        isRepost={isRepost}
        heroProfiles={heroProfiles}
      />
    </div>
  );
};

const ActivityInteractionContext = ({ type, actorName }) => {
  if (!type || !actorName) {
    return null;
  }

  let label = "",
    icon = "";
  switch (type) {
    case "comment":
      label = `${actorName} commented on this`;
      icon = commentIcon;
      break;
    case "share":
      label = `Re-posted by  ${actorName}`;
      icon = shareIcon;
      break;
    case "like":
      label = `Liked by ${actorName}`;
      icon = likeIcon;
      break;
    default:
      break;
  }
  return (
    <div className={classes.interactionContext}>
      <div className={classes.icon}>
        <img src={icon} alt="interaction icon" />
      </div>
      <div className={classes.label}>{label}</div>
    </div>
  );
};

export default withErrorBoundary(FeedItemV2, classes.groupedActivity);
