import styles from "./metamaskConnected.module.css";
import { getTrimedAddress } from "utils/misc";
import metamask from "assets/webp/metamask.webp";
import solanaLogo from "assets/svg/solana_logo.svg";

const WalletConnected = () => {
  const userShortAddress = getTrimedAddress(localStorage.userAddress);
  const walletType = localStorage.walletType;

  return (
    <div className={styles.metamask}>
      {walletType === "SOLANA" ? (
        <img
          className={styles.matamask_logo_img}
          src={solanaLogo}
          alt="solana logo"
        />
      ) : (
        <img
          className={styles.matamask_logo_img}
          src={metamask}
          alt="metamask logo"
        />
      )}
      <div className={styles.metamask_address}>
        <div className={styles.metamask_connected}>Connected</div>
        <div className={styles.address}>{userShortAddress}</div>
      </div>
      <div className={styles.metamask_connected_round}></div>
    </div>
  );
};

export default WalletConnected;
