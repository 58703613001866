import classes from "./Filters.module.css";
import buttonsClasses from "components/UI/Buttons/buttons.module.css";
import SelectedTag from "components/UI/Buttons/SelectedTag";
import { getChainMetaData } from "utils/misc";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import tokenClasses from "./FilterRowTile.module.css";
import useFetchProtocolHoldings from "customHooks/fetch/useFetchProtocolHoldings";

const AppliedFiltersList = ({
  chains,
  removeChain,
  tokens,
  removeToken,
  removeAll,
  protocols,
  removeProtocol,
  identifier,
  extraStylingClassName,
}) => {
  if (
    (!chains || chains.length === 0) &&
    (!tokens || tokens.length === 0) &&
    (!protocols || protocols.length === 0)
  )
    return null;

  return (
    <>
      <AppliedFiltersListContent
        chains={chains}
        removeChain={removeChain}
        tokens={tokens}
        removeToken={removeToken}
        removeAll={removeAll}
        protocols={protocols}
        removeProtocol={removeProtocol}
        identifier={identifier}
        extraStylingClassName={extraStylingClassName}
      />
    </>
  );
};

const AppliedFiltersListContent = ({
  chains,
  removeChain,
  tokens,
  removeToken,
  removeAll,
  protocols,
  removeProtocol,
  identifier,
  extraStylingClassName,
}) => {
  const { data: protocolHoldingsData } = useFetchProtocolHoldings({
    identifier,
  });
  const protocolsMetaData = protocolHoldingsData?.tokens_metadata;

  // prevent displaying empty tags when params don't contain any value
  if (
    (!chains || chains?.length === 0) &&
    (!tokens || tokens?.length === 0) &&
    (!protocols || protocols?.length === 0)
  )
    return null;
  return (
    <div className={`${classes.appliedFiltersList} ${extraStylingClassName}`}>
      {/* chains List */}
      {chains.map((chain, index) => {
        if (chain?.length === 0) return null;
        const chainIcon = getChainMetaData(chain);
        return (
          <div key={`selected-tag-token-${index}-${chain}`}>
            <SelectedTag
              text={chain}
              icon={chainIcon?.icon}
              onRemove={() => {
                removeChain(chain);
              }}></SelectedTag>
          </div>
        );
      })}
      {/* tokens holdings List */}
      {tokens?.map((tokenElement, index) => {
        const chainIcon = getChainMetaData(tokenElement.chain_id);
        return (
          <div
            key={`selected-tag-token-${index}-${tokenElement?.token_metadata?.symbol}`}>
            <SelectedTag
              iconComponent={
                <div
                  style={{ paddingLeft: "0px" }}
                  className={tokenClasses.tokenIconWrapper}>
                  <Image
                    className={tokenClasses.tokenIcon}
                    src={tokenElement?.token_metadata?.logo}
                    imageType={IMAGE_TYPES.TOKEN}
                    alt={`${tokenElement?.token_metadata?.symbol}-icon`}
                  />
                  {tokenElement.chain_id !== "Ethereum" && (
                    <img
                      className={tokenClasses.tokenChainIcon}
                      src={chainIcon?.overlayIcon}
                      alt={`${tokenElement?.chain_id}-overlay-icon`}
                    />
                  )}
                </div>
              }
              text={tokenElement.token_name}
              icon={chainIcon?.icon}
              onRemove={() => {
                removeToken(tokenElement);
              }}></SelectedTag>
          </div>
        );
      })}
      {/* protocol tokens List */}
      {protocols?.map((protocolElement, index) => {
        const metadata = protocolsMetaData
          ? protocolsMetaData[protocolElement.contract_address]
          : null;
        const chainIcon = getChainMetaData(protocolElement.chain_id);
        return (
          <div key={`selected-tag-token-${index}-${metadata?.symbol}`}>
            <SelectedTag
              iconComponent={
                <div
                  style={{ paddingLeft: "0px" }}
                  className={tokenClasses.tokenIconWrapper}>
                  <Image
                    className={tokenClasses.tokenIcon}
                    src={metadata?.logo}
                    imageType={IMAGE_TYPES.TOKEN}
                    alt={`${metadata?.symbol}-icon`}
                  />
                  {protocolElement.chain_id !== "Ethereum" && (
                    <img
                      className={tokenClasses.tokenChainIcon}
                      src={chainIcon?.overlayIcon}
                      alt={`${protocolElement?.chain_id}-overlay-icon`}
                    />
                  )}
                </div>
              }
              text={metadata?.name}
              icon={chainIcon.icon}
              onRemove={() => {
                removeProtocol(protocolElement);
              }}></SelectedTag>
          </div>
        );
      })}
      <div onClick={removeAll} className={buttonsClasses.removeAllButton}>
        <span className={buttonsClasses.removeAllText}>Remove All</span>
      </div>
    </div>
  );
};

export default AppliedFiltersList;
