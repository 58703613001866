import { getIdentity } from "api/profile.api";
import withLoader from "components/core/withLoader";
import { QueryKeyConstants } from "utils/constants";
import BasicInfo from "./components/BasicInfo/BasicInfo";
import Socials from "./components/Socials/Socials";
import MyWallets from "./components/MyWallets/MyWallets";
import classes from "./Settings.module.css";
import { useContext, useEffect } from "react";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const SettingsPage = ({ queryData, refetch }) => {
  const userData = queryData?.data?.data;
  const { setTitle } = useContext(LeftSideHeaderContext);
  useEffect(() => {
    setTitle("Settings");
  }, [setTitle]);

  return (
    <BaseWrapper mixpanelEvent="Settings">
      <div className={classes.container}>
        <BasicInfo userData={userData}></BasicInfo>
        <Socials userData={userData} />
        <MyWallets
          identifier={userData?.identity?.id}
          userData={userData}
          refetchIdentity={refetch}
        />
      </div>
    </BaseWrapper>
  );
};
const Wrapper = (props) => {
  return <SettingsPage {...props} />;
};
export default withLoader(Wrapper, getIdentity, {
  queryKey: (props) => [QueryKeyConstants.MY_IDENTITY],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  renderNotFound: true,
});
