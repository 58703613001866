import {
  XYPlot,
  XAxis,
  YAxis,
  LineSeries,
  Hint,
  GradientDefs,
  AreaSeries,
} from "react-vis";
import {
  epochToReadable,
  abbreviateNumber,
  formatUnixTimestamp,
} from "utils/misc";
import classes from "./Graph.module.css";
import { memo, useMemo, useState } from "react";
import { getPercentileValues } from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/Graph";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";

const Graph = memo(
  ({
    graphData = [],
    isSeconds = false,
    isRightPane = false,
    parentWidth = 800,
    parentHeight = 350,
    hideFinalVal = false,
    customFormat = null,
    gradientFill = false,
  }) => {
    const [currentPoint, setCurrentPoint] = useState(null);
    const randomGradientId = "grad-" + Math.random() * 100;
    const timeSeries = useMemo(
      () =>
        graphData.map((data) => {
          return {
            x: isSeconds ? data[0] * 1000 : data[0],
            y: data[1],
          };
        }),
      [graphData, isSeconds]
    );

    const last = timeSeries[timeSeries.length - 1];
    const first = timeSeries[0];
    const isNegative = last.y < first.y;
    const color = isNegative ? "var(--error)" : "var(--success)";

    const minValue = useMemo(
      () =>
        timeSeries?.length > 0
          ? timeSeries.reduce((min, data) => Math.min(min, data.y), Infinity)
          : null,
      [timeSeries]
    );

    const maxValue = useMemo(
      () =>
        timeSeries?.length > 0
          ? timeSeries.reduce((max, data) => Math.max(max, data.y), -Infinity)
          : null,
      [timeSeries]
    );
    const diff = maxValue - minValue;
    const top = (maxValue - last.y) / diff;
    const topOffsetMultiplier = isRightPane ? 1 : 0.95;
    const topOffset = top * topOffsetMultiplier * (parentHeight - 45);

    return (
      <div
        className={`${classes.container} ${
          isRightPane ? classes.right_pane : ""
        }`}>
        <XYPlot
          height={parentHeight}
          width={parentWidth}
          // width={"100%"}
          // width={isRightPane ? 560 : 800}
          padding={0}
          margin={{
            left: 0,
            right: isRightPane ? 40 : 64,
            bottom: isRightPane ? 25 : 34,
            top: 0,
          }}
          onMouseLeave={() => {
            setCurrentPoint(null);
          }}>
          {/* <HorizontalGridLines
            tickTotal={8}
            style={{ stroke: "var(--border-light)" }}
          /> */}
          <GradientDefs>
            <linearGradient id={randomGradientId} x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%" stopColor={color} stopOpacity={0.2} />
              <stop offset="100%" stopColor={color} stopOpacity={0.01} />
            </linearGradient>
          </GradientDefs>

          <AreaSeries
            data={timeSeries}
            color={gradientFill ? `url(#${randomGradientId})` : "transparent"}
            style={{ clipPath: "url(#clip)" }}
          />
          <LineSeries
            data={timeSeries}
            onNearestX={(datapoint, event) => {
              setCurrentPoint({ datapoint, event });
            }}
            curve={"curveMonotoneX"}
            color={color}
            strokeWidth={1.5}
            style={{
              stroke: color,
              fill: "none",
            }}
          />
          <XAxis
            tickFormat={(d) => {
              return epochToReadable(d / 1000);
            }}
            tickValues={getPercentileValues(timeSeries.map((data) => data.x))}
            tickSizeInner={0}
            tickSizeOuter={5}
            style={{
              ticks: {
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "130%",
                textAlign: "center",
                letterSpacing: " -0.02em",
                fill: "var(--text-3)",
                marginLeft: "20px",
                marginBottom: "30px",
              },
              line: { stroke: "var(--border-dark)" },
            }}
          />

          <YAxis
            tickFormat={(d) => {
              return customFormat ? customFormat(d) : `$${abbreviateNumber(d)}`;
            }}
            tickTotal={4}
            tickSizeInner={0}
            tickSizeOuter={0}
            orientation="right"
            color="var(--text-2)"
            style={{
              ticks: {
                fill: "var(--text-3)",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "130%",
                textAlign: "center",
                letterSpacing: " -0.02em",
                marginLeft: "20px",
                marginBottom: "30px",
              },
              line: { stroke: "var(--border-dark)" },
            }}
          />
          {currentPoint && (
            <Hint value={currentPoint.datapoint}>
              <div className={classes.hover_card}>
                <div className={classes.value}>
                  {customFormat
                    ? customFormat(currentPoint.datapoint.y)
                    : abbreviateNumber(currentPoint.datapoint.y)}
                </div>
                <div className={classes.time}>
                  {formatUnixTimestamp(currentPoint.datapoint.x / 1000)}
                </div>
              </div>
            </Hint>
          )}
        </XYPlot>

        {!hideFinalVal && (
          <div
            className={classes.net_worth}
            style={{
              color: "white",
              background: color,
              top: `${topOffset}px`,
            }}>
            {abbreviateNumber(last.y)}
          </div>
        )}
      </div>
    );
  }
);

export default withErrorBoundary(Graph);
