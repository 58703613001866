import classes from "./WalletsPage.module.css";
import WalletHeader from "./components/walletsHeader";
import WalletsTableHeader from "./components/walletsTableHeader";
import classesTable from "./components/walletTableHeader.module.css";
import AddWalletButton from "./AddWallet/AddWallet";
import ProfileWalletRowTile from "./ProfileWalletRowTile";
import { CustomRow, Divider } from "components/UI/Components/Components";
import ManageAccess from "./ManageAccess/ManangeAccess";
import BundlesTabList from "./WalletBundles/BundlesTabList";
import ManageBundles from "./WalletBundles/ManageBundles";
import { useLocation } from "react-router-dom";
import { intersection } from "lodash";
import { useEffect, useState, useContext } from "react";
import { ProfileContext } from "contextStates/ProfileContext";
import { GlobalContext } from "contextStates/Global";
import { Stack } from "@phosphor-icons/react";

const ProfileWallets = ({ bundleData, refetch, pendingRequestsCount }) => {
  const { isLoggedInUserProfile } = useContext(ProfileContext);
  const { featureFlags } = useContext(GlobalContext);
  const location = useLocation();
  const [wallets, setWallets] = useState([]);
  const walletsCount = wallets?.length ?? 0;

  useEffect(() => {
    const selectedBundleIds = new URLSearchParams(location.search)
      .getAll("groups")
      ?.map((id) => parseInt(id));

    if (selectedBundleIds.length !== 0) {
      setWallets(
        bundleData?.bundle?.wallets?.filter(
          (w) => intersection(w.group_ids, selectedBundleIds).length > 0
        )
      );
    } else {
      setWallets(bundleData?.bundle?.wallets);
    }
  }, [location.search, bundleData]);

  return (
    <div className={classes.walletsBox}>
      <CustomRow gap="16px" alignItems="center">
        <CustomRow flexGrow={1}>
          <WalletHeader
            title="Wallets"
            iconRender={<Stack size={20} color="var(--text-1)" />}
            count={walletsCount}
          />
        </CustomRow>
        <CustomRow gap="16px" padding="0 20px">
          <ManageAccess pendingRequestsCount={pendingRequestsCount} />
          <AddWalletButton
            refetch={refetch}
            identityId={bundleData.id}
            groups={bundleData.groups}
          />
        </CustomRow>
      </CustomRow>
      <Divider />
      {isLoggedInUserProfile && featureFlags?.is_showing_private_wallets && (
        <>
          <CustomRow gap="16px" padding="0 20px">
            <CustomRow flexGrow={1}>
              <BundlesTabList />
            </CustomRow>
            <CustomRow flexShrink={0}>
              <ManageBundles bundleData={bundleData} refetch={refetch} />
            </CustomRow>
          </CustomRow>
          <Divider style={{ marginBottom: -10 }} />
        </>
      )}
      <div className={classesTable.table}>
        <WalletsTableHeader
          items={[
            { name: "WALLET", flex: 3 },
            { name: "NAME", flex: 2 },
            { name: "NETWORTH", flex: 2 },
            {
              name: isLoggedInUserProfile ? "GROUPS" : "SOURCED BY",
              flex: 2,
            },
            { name: "ACTIONS", flex: "1 0 0%", alignment: "right" },
          ]}></WalletsTableHeader>

        {wallets.map((addressInfo, index) => (
          <ProfileWalletRowTile
            key={index}
            identity_id={bundleData.id}
            bundleData={bundleData}
            identities={bundleData.identity_details}
            wallet={addressInfo}
            index={index}
            refetch={refetch}
          />
        ))}
      </div>
    </div>
  );
};

export default ProfileWallets;
