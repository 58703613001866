/* src/components/V2/NewOnboarding/ConnectWallet/ConnectWallet.js */
import {
  CustomRow,
  CustomText,
  PaddingComponent,
  CustomColumn,
} from "components/UI/Components/Components";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./ConnectWallet.module.css";
import CustomDivider from "components/UI/CustomComponents/CustomDivider";
import { ListChecks } from "@phosphor-icons/react";
// Importing all the assets
import { getOnboardingStepDetails } from "api/waitlist.api";
import publicWalletIcon from "assets/svg/group-43322.svg";
import DynamicWalletButton from "components/DynamicWalletButton/DynamicWalletButton";
import { AuthContext } from "contextStates/AuthContext";
import { GlobalContext } from "contextStates/Global";
import { useContext } from "react";
import Shimmer from "../../../../shared/GhostLoader/Shimmer/Shimmer";
import { BoldContentParser, ONBOARDING_STATES } from "../NewOnboarding.utils";
import { useQuery } from "@tanstack/react-query";
import OnboardingBg from "../OnboardingBg";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { trackEvent } from "utils/event_tracking";
import { logSentryEvent } from "utils/sentry";
import { QUERY_KEYS } from "utils/query_utils";
import useScreenRes from "customHooks/useMediaQuery";

const ConnectWallet = () => {
  const { isMobile } = useScreenRes();
  const { isUserAuthenticated } = useContext(AuthContext);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const history = useHistory();
  const [isOnboardingLoading, setIsOnboardingLoading] = useState(false);
  const { data, isError, isLoading } = useQuery({
    queryKey: QUERY_KEYS.ONBOARDING_CONNECT_WALLET_STEP,
    queryFn: getOnboardingStepDetails,
    enabled: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchInterval: false,
    retry: 2,
  });
  if (isError) {
    handleErrorSnackbar("Failed to fetch wallet checklist");
  }
  const walletChecklist =
    data?.data?.data?.connect_wallet?.wallet_checklist ?? null;

  const onSign = async (session) => {
    try {
      trackEvent("connect_wallet_btn_success");
      setIsOnboardingLoading(true);
      await isUserAuthenticated({
        returnData: true,
        isNavigating: true,
        navigateCallback: (route) => {
          if (route === null) return;
          setIsOnboardingLoading(false);
          const screen = route?.split("/")[2];
          if (screen === "connect_socials") {
            history.push("/onboarding/connected_wallets");
          }
          if (route && ONBOARDING_STATES.includes(screen)) {
            history.push(`/onboarding/${screen}`);
          } else {
            history.push("/onboarding/connected_wallets");
          }
        },
      });
    } catch (err) {
      setIsOnboardingLoading(false);
      logSentryEvent(err, {
        extra: {
          message: "Failed to redirect after wallet sign",
          session,
        },
      });
    }
  };

  return (
    <BaseWrapper mixpanelEvent="OnboardingConnectWallet">
      <OnboardingBg>
        <div className={styles.mainContent}>
          <div className={styles.header}>
            <h1 className={styles.subtitle}>Let’s get started!</h1>
          </div>
          <CustomColumn
            background="rgba(255, 255, 255, 1)"
            borderRadius="8px"
            padding={isMobile ? "16px 12px" : "20px"}>
            <Header />
            <PaddingComponent height={isMobile ? "12px" : "20px"} />
            <div className={styles.requirements}>
              <ul className={styles.points}>
                {!isLoading && walletChecklist ? (
                  walletChecklist.map((item, index) => (
                    <li key={index} className={styles.restrictionItem}>
                      <PaddingComponent className={styles.restrictionItemNum}>
                        <div>{index + 1}</div>
                      </PaddingComponent>
                      <div className={styles.contentText}>
                        <BoldContentParser text={item} />
                      </div>
                    </li>
                  ))
                ) : (
                  <div className={styles.shimmerCont}>
                    <Shimmer />
                  </div>
                )}
              </ul>
            </div>
            <CustomDivider reverse={true} />
            <PaddingComponent height="20px" />
            <WalletInfo />
            <DynamicWalletButton
              isOnboarding
              setLocalStorageKeys={true}
              setPusherChannel={true}
              setOpenReplayTagOnConnect={true}
              customClass={styles.continueButton}
              customRender={null}
              customLoadingText="Verifying"
              customLoading={isOnboardingLoading}
              onSuccess={(session) => {
                if (session) {
                  onSign(session);
                }
              }}
            />
          </CustomColumn>
        </div>
      </OnboardingBg>
    </BaseWrapper>
  );
};

const Header = () => {
  return (
    <CustomRow gap="8px">
      <ListChecks size={24} color="rgba(27, 31, 34, 1)" />
      <CustomText
        text="Wallet checklist"
        color="rgba(27, 31, 34, 1)"
        fontWeight="600"
        fontSize="17px"
        linHeight="160%"
        letterSpacing="-0.34px"
      />
    </CustomRow>
  );
};

const WalletInfo = () => {
  return (
    <CustomRow gap="16px">
      <img className="typeIcon" src={publicWalletIcon} alt="Public Wallet" />
      <CustomColumn alignItems="flex-start">
        <CustomText
          fontSize="15px"
          fontFamily="Roboto"
          fontWeight="600"
          linHeight="17px"
          letterSpacing="-0.34"
          color="rgba(27, 31, 34, 1)"
          text="Connect your wallet"
        />
        <CustomText
          fontSize="15px"
          textAlign="flex-start"
          fontFamily="Roboto"
          fontWeight="400"
          linHeight="18px"
          letterSpacing="-0.3"
          color="rgba(104, 118, 132, 1)"
          text="The wallet you are connecting will be visible to the people on 0xPPL"
        />
      </CustomColumn>
    </CustomRow>
  );
};

export default ConnectWallet;
