import { Link } from "react-router-dom";
import classes from "./FollowedByFollowers.module.css";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import { getTrimmedString } from "utils/misc";
import { PaddingComponent } from "components/UI/Components/Components";

const RenderText = ({
  text,
  identity_details,
  trimItem = false,
  maxLengthPerItem = 12,
}) => {
  const regex = /{{[^{]*}}+/g;
  let match;
  let lastIndex = 0; // Keep track of the last index we parsed
  const elements = [];
  let matchIndex = 0;
  do {
    match = regex.exec(text);
    if (match !== null) {
      const match_string = match[0];
      const index = match.index;

      // Adding condition to prevent the empty span before the description
      const substr = text?.substring(lastIndex, index);
      if (substr?.length) {
        let marginLeft = 0;
        if (substr.trim() === "and") {
          marginLeft = 4;
        }
        elements.push(
          <span
            key={`last-${lastIndex}`}
            style={{ marginLeft: `${marginLeft}px` }}>
            {substr}
          </span>
        );
      }

      const identityId = match_string.slice(2, -2).split("||")[1];
      const identity = identity_details?.[identityId];

      elements.push(
        <ProfileHoverCard key={`match-${matchIndex}`} actor={identity}>
          <Link
            key={`match-${matchIndex}`}
            to={identity?.link}
            className={classes.link}
            target="_blank">
            {trimItem && maxLengthPerItem
              ? getTrimmedString(identity?.display_name, maxLengthPerItem)
              : identity?.display_name}
          </Link>
        </ProfileHoverCard>
      );
      matchIndex = matchIndex + 1;
      lastIndex = index + match_string.length;
    }
  } while (match);

  elements.push(
    <span key={lastIndex} className={classes.last_index}>
      {text?.substring(lastIndex, text?.length)}
    </span>
  );

  return <>{elements}</>;
};

const FollowedByFollowers = ({
  reason,
  identity_details,
  wrapLines = false,
  trimItem = false,
  maxLengthPerItem = 12,
  minHeight = null,
}) => {
  if (!reason?.follower)
    return minHeight ? <PaddingComponent height={minHeight} /> : null;
  return (
    <div className={`${classes.container} ${wrapLines ? classes.wrap : ""}`}>
      <div className={`${classes.followers}`}>
        <RenderText
          text={reason?.follower}
          identity_details={identity_details}
          maxLengthPerItem={maxLengthPerItem}
          trimItem={trimItem}
        />
      </div>
    </div>
  );
};

export default FollowedByFollowers;
