import ScreenTableRowTile from "./ScreenerTableRowTile";
import classesScreener from "./ScreenerTable.module.css";
import ScreenerTableHeader from "./ScreenerTableHeader";
import { PaddingComponent } from "components/UI/Components/Components";

const ScreenerTable = ({
  data,
  noRightPadding = false,
  rightPadding = "60px",
  paddingBottom = "40px",
  allLeftAlign = false,
  bottomBorderRadius = "0px",
}) => {
  const headers = data?.metadata?.headers;
  const columnsList = Object.values(headers);
  var columns = columnsList.map((header, index) => {
    return {
      name: header,
      alignment:
        index === columnsList.length - 1 &&
        header !== "ADDRESS" &&
        !allLeftAlign
          ? "right"
          : "left",
      flex: index === 0 || index === columnsList.length - 1 ? 3 : 2,
    };
  });
  if (columnsList.length === 1)
    columns.push({
      name: "",
      alignment: "right",
      flex: 2,
    });

  const firstElement = data?.data?.[0];
  if (!firstElement) {
    return (
      <div style={{ paddingBottom }}>
        <span className={classesScreener.text}>
          No result found for the given criteria.
        </span>
      </div>
    );
  }

  return (
    <PaddingComponent paddingBottom={paddingBottom}>
      <div className={classesScreener.table}>
        <div className={classesScreener.header}>
          <ScreenerTableHeader items={columns} rightPadding={rightPadding} />
        </div>

        {data?.data.map((item, index) => (
          <div
            key={`Item + ${item["address"].value} + ${index}`}
            className={`${classesScreener.tableRow} ${classesScreener.tableList}`}>
            <ScreenTableRowTile
              data={data}
              item={item}
              bottomBorderRadius={
                index === data?.data?.length - 1 ? bottomBorderRadius : "0px"
              }
              parentIndex={index}
              noRightPadding={noRightPadding}
              rightPadding={rightPadding}
              allLeftAlign={allLeftAlign}
            />
          </div>
        ))}
      </div>
    </PaddingComponent>
  );
};

export default ScreenerTable;
