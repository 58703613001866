import {
  PaddingComponent,
  CustomColumn,
  CustomRow,
  Divider,
  FlexBox,
  RedirectLink,
} from "components/UI/Components/Components";
import classes from "./TransactionInternalCalls.module.css";
import Copyable from "components/ValueType/Copyable/Copyable";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import ProfileRenderer from "components/UI/Components/ProfileRenderer";
import { cutStringBeyondLength } from "utils/misc";

const InputDataRowTile = ({ name, type, value, profiles }) => {
  const profile = type === "address" ? profiles?.[value] : null;
  return (
    <PaddingComponent paddingBottom="10px">
      <CustomRow>
        <FlexBox flex={"1"}>
          <span className={classes.tableItemText}>{name}</span>
        </FlexBox>
        <FlexBox flex={"1"}>
          <span className={classes.tableItemText}>{type}</span>
        </FlexBox>
        <FlexBox flex={"3"}>
          <CustomRow>
            {type === "address" && profile ? (
              <ProfileRenderer profile={profile} copyable="address" />
            ) : (
              <Copyable content={value} showOnHover>
                <span className={classes.tableItemText}>
                  {cutStringBeyondLength({ text: value, length: 50 })}
                </span>
              </Copyable>
            )}
          </CustomRow>
        </FlexBox>
      </CustomRow>
    </PaddingComponent>
  );
};

const TransactionInternalCallAdditionalDetails = ({
  action,
  profile,
  profiles,
}) => {
  return (
    <CustomColumn>
      <PaddingComponent height="10px" />
      <Divider />
      <PaddingComponent height="12px" />
      <CustomRow>
        {/* contract name */}
        <FlexBox flex={"1"}>
          <span className={classes.title}>Contract Name</span>
        </FlexBox>
        {/* contract method */}
        <FlexBox flex={"3"}>
          <ProfileHoverCard actor={profile}>
            <RedirectLink link={profile?.address_link}>
              <CustomRow gap={"6px"}>
                <Image
                  imageType={IMAGE_TYPES.TOKEN}
                  className={classes.contractIcon}
                  src={profile?.display_picture}
                  fallbackClassName={classes.fallbackImage}
                />
                <span className={classes.contractName}>
                  {profile?.display_name}
                </span>
              </CustomRow>
            </RedirectLink>
          </ProfileHoverCard>
        </FlexBox>
      </CustomRow>
      <PaddingComponent height="10px" />
      {action?.invoked_fn_name && (
        <CustomRow>
          <FlexBox flex="1">
            <span className={classes.title}>Contract Method</span>
          </FlexBox>
          <FlexBox flex="3">
            <span className={classes.contractMethodValue}>
              {action?.invoked_fn_name}
            </span>
          </FlexBox>
        </CustomRow>
      )}
      {(action?.method_signature || action?.arg_types) && (
        <PaddingComponent paddingTop="12px" paddingBottom="12px">
          <Divider />
        </PaddingComponent>
      )}
      {action?.method_signature && (
        <CustomColumn>
          <span className={classes.heading}>Input Data</span>
          <PaddingComponent height="8px" />
          <span className={classes.body}>{action?.method_signature}</span>
          <PaddingComponent height="16px" />
        </CustomColumn>
      )}
      {action?.arg_types && (
        <PaddingComponent paddingBottom="10px">
          <CustomRow>
            <FlexBox flex="1">
              <span className={classes.tableTitle}>NAME</span>
            </FlexBox>
            <FlexBox flex="1">
              <span className={classes.tableTitle}>TYPE</span>
            </FlexBox>
            <FlexBox flex="3">
              <span className={classes.tableTitle}>VALUE</span>
            </FlexBox>
          </CustomRow>
        </PaddingComponent>
      )}
      {action?.arg_types &&
        action?.arg_types?.map((arg, index) => {
          return (
            <InputDataRowTile
              name={action?.arg_names[index]}
              type={arg}
              value={action?.invoked_fn_args[action?.arg_names[index]]}
              profiles={profiles}
            />
          );
        })}
    </CustomColumn>
  );
};

export default TransactionInternalCallAdditionalDetails;
