import { TableCell, TableRow } from "../../../../../UI/Table/Table";
import ProtocolInfo from "./ProtocolInfo";
import { PositionCategoryWrapper } from "./PositionCategories";
import {
  CustomRow,
  CustomText,
  PaddingComponent,
  PriceChange,
} from "../../../../../UI/Components/Components";

export const PolyMarketHoldingRow = ({
  holdingItem,
  rightPane = false,
  chainProfiles,
}) => {
  return (
    <TableRow noPadding>
      <TableCell alignLeft style={rightPane ? { flexBasis: "40%" } : {}}>
        <ProtocolInfo protocol={holdingItem} chainProfiles={chainProfiles} />
      </TableCell>
      <TableCell style={{ flexBasis: "40%" }}>
        <PositionCategoryWrapper
          label="Open"
          content={holdingItem.polymarket_holding.open_trades.length}
        />
      </TableCell>
      <TableCell alignRight shrink style={{ flexBasis: "10%" }}>
        <CustomRow gap="8px">
          {holdingItem.total_usd_value?.display_value}
          {holdingItem.pnl && holdingItem.pnl?.display_value ? (
            <PriceChange
              value={holdingItem.pnl?.value}
              displayValue={holdingItem.pnl?.display_value}
              fontWeight="500"
              showCaret={false}
            />
          ) : (
            <PaddingComponent width="20px">
              <CustomText text="-" color="var(--text-1)" />
            </PaddingComponent>
          )}
        </CustomRow>
      </TableCell>
    </TableRow>
  );
};
