import React, { useContext, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import classes from "./groupedActivityWrapper.module.css";
import ActivityHeader from "./ActivityHeader";
import FeedDescription from "../GroupFeedItem/components/FeedDescription";
import RenderFallbackActivities from "./RenderFallbackActivities";
import ActivityStats from "./ActivityStats";
import ActivityEngagements from "components/ActivityEngagements/ActivityEngagements";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { FeedDescriptionWrapper } from "components/GroupFeedItem/GroupFeedItem.styled";
import SummaryEditComponent from "./SummaryEditComponent";
import DescriptionBox from "components/GroupFeedItem/components/DescriptionBox";

import {
  FeedBundleSuggestion,
  FeedExtraBorderItems,
  isShowingBundleSuggestion,
  NftTransfers,
  TokenTransfers,
} from "../GroupFeedItem/GroupFeedItem.components";
import {
  combinedGnosisSigners,
  handleFeedItemClick,
  removeQuotesFromText,
} from "utils/misc";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import GnosisSigners from "components/GnosisSigners/GnosisSigners";
import { IconTextButton } from "components/UI/Components/IconTextButton";
import TrendingPnlList from "components/TrendingActivities/TrendingPnlList";
import { ReactComponent as FarcasterIcon } from "assets/svg/feed/farcaster.svg";
import { ReactComponent as LensIcon } from "assets/svg/feed/lens.svg";
import { ReactComponent as SnapshotIcon } from "assets/svg/feed/snapshot.svg";
import { conditionallyTrimDescription } from "utils/string_helper";
import ScreenerTable from "components/Pages/Screener/ScreenerTable/ScreenerTable";
import ConditionalLink from "shared/ConditionalLink";
import { ScreenerIcon } from "assets/svg/left_sidebar/CustomSvgIcons";
import {
  CommonButton,
  CustomColumn,
  CustomRow,
  CustomText,
  CustomTextSpan,
  FlexGrow,
  InfoBox,
  PaddingComponent,
} from "components/UI/Components/Components";
import FeedDescriptionSumamryItemsList from "components/GroupFeedItem/components/FeedDescriptionSummaryItemsList";
import ProposalPreview from "./GovernanceProposal/ProposalPreview";
import { TransactionActivities } from "./TransactionActivities";
import { activityGroupHash } from "./group_feed_utils";
import Joined0xPPLItem from "./Joined0xPPl/Joined0xPPLItem";
import PerpGraphDetails, { getPerpTradesList } from "./PerpGraphDetails";
import PnlTokenList from "./PnlTokenList/PnlTokenList";
import PnlTokenExpandedList from "./PnlTokenList/PnlTokenExpandedList";
import { GlobalContext } from "contextStates/Global";
import WelcomePosts from "zxComponents/feed/WelcomePosts/WelcomePosts";
import { getEngagementsQueryKey } from "components/ActivityEngagements/utils";
import ZxTransactionSummaries from "zxComponents/common/ZxTransactionSummaries/ZxTransactionSummaries";
import SelectableDivWithDelay from "components/SelectableDivWithDelay/SelectableDivWithDelay";
import { PolyMarketActivities } from "./PolyMarketActivities";
import { useHistory } from "react-router-dom";
import FollowEditButton from "components/FollowEditButton/FollowEditButton";
import ZxGmxItemWrapper from "zxComponents/feed/ZxGmxItem/ZxGmxItemWrapper";
import { AuthContext } from "contextStates/AuthContext";
import ZxUserTokenGroup from "zxComponents/feed/ZxUserTokenGroup/ZxUserTokenGroup";
import ZxUserTokenAttribution from "zxComponents/feed/ZxUserTokenGroup/ZxUserTokenAttribution";

const GroupedActivityWrapper = ({
  actor,
  description,
  hideStats = false,
  hideEngagements = false,
  hasReplies,
  groupedActivity,
  showOpenInNewTab,
  showFullDateTime,
  refetchActivityDetailsPage,
  identities,
  profiles,
  showSettings = true,
  showViewPost = false,
  children,
  hideNftTransfers = false,
  refetchGroupedActivity,
  isGroupedActivityDetails = false,
  smallThumbnail,
  showThreadLine,
  trimDescription,
  trimLimit,
  hideExtraRows = false,
  maxNftsCount,
  isRepost = false,
  heroProfiles = {},
}) => {
  const isUserTokenGroup =
    groupedActivity?.type === "user_token_group" ||
    groupedActivity?.type === "sol_user_token_group";
  const chains = groupedActivity?.chain_profiles
    ? Object.keys(groupedActivity?.chain_profiles)
    : groupedActivity?.chain_profiles_keys;
  const [summaryPromptId, setSummaryPromptId] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const history = useHistory();
  const [combinedPnlPercentage, setCombinedPnlPercentage] = useState(0);
  const [showPnlComments, setShowPnlComments] = useState(false);
  var { type } = groupedActivity ?? {};
  const { featureFlags } = useContext(GlobalContext);
  const isHomePage = window.location.pathname.includes("/home");
  const groupHash = useMemo(
    () => activityGroupHash(groupedActivity),
    [groupedActivity]
  );

  if (groupedActivity?.type === "welcomePosts") {
    return <WelcomePosts />;
  }
  const onItemClicked = (e) => {
    handleFeedItemClick({
      contentId: groupedActivity.id,
      contentType: groupedActivity?.type,
      history,
      event: e,
      link: groupedActivity.link,
      clickable: true,
      noRedirection: false,
    });
  };
  const hasGmx = (groupedActivity) => {
    if (!groupedActivity) return false;
    return (
      Object.values(getPerpTradesList({ groupedActivity }) ?? {})?.length > 0
    );
  };
  return (
    <div
      className={`${classes.groupedActivityWrapper} 
      ${(!!hasReplies && classes.hasReplies) || ""} ${
        type === "news" && classes.noPaddingTop
      }`}>
      {isUserTokenGroup &&
        !isGroupedActivityDetails &&
        !groupedActivity.attribution_category && (
          <ZxUserTokenAttribution
            groupedActivity={groupedActivity}
            profiles={profiles}
          />
        )}
      <div className={type === "news" ? classes.newsWrapper : ""}>
        <div className={`${classes.actor}`}>
          <CustomRow alignItems="center" width="100%" gap="12px">
            <ActivityAvatar
              actor={actor}
              onActorUpdate={refetchGroupedActivity}
              type={groupedActivity?.type}
              showFollowButton={actor?.followed === false && isHomePage}
            />
            <ActivityHeader
              actor={actor}
              timestamp={groupedActivity?.timestamp}
              involvedChains={chains}
              chainProfiles={groupedActivity?.chain_profiles}
              showFullDateTime={showFullDateTime}
              type={groupedActivity?.type}
              category={groupedActivity?.category}
              showSettings={showSettings}
              onActorUpdate={refetchGroupedActivity}
              sourceUrl={groupedActivity?.source_url}
              isGroupedActivityDetails={isGroupedActivityDetails}
              isHoveringForGptEdit={isHovering}
              setIsHoveringForGptEdit={setIsHovering}
              trendingPriceChartData={groupedActivity?.price_chart_data}
              groupedActivity={groupedActivity}
            />
          </CustomRow>
        </div>
        {type === "news" && (
          <div className={classes.twitter_news}>
            {removeQuotesFromText(groupedActivity.twitter_news)}
          </div>
        )}
      </div>

      <div
        className={`${classes.content}
        ${(!!hasReplies && classes.hasReplies) || " "} ${
          (!!showThreadLine &&
            `${classes.hasReplies} ${classes.showThreadLine}`) ||
          ""
        } ${type === "welcome" && classes.center}`}>
        {!["news", "welcome"].includes(type) && (
          <FeedContent
            groupedActivity={groupedActivity}
            isUserTokenGroup={isUserTokenGroup}
            description={description}
            isHovering={isHovering}
            setSummaryPromptId={setSummaryPromptId}
            profiles={profiles}
            identities={identities}
            isGroupedActivityDetails={isGroupedActivityDetails}
            refetchGroupedActivity={refetchGroupedActivity}
            hideNftTransfers={hideNftTransfers}
            smallThumbnail={smallThumbnail}
            showOpenInNewTab={showOpenInNewTab}
            trimDescription={trimDescription}
            trimLimit={trimLimit}
            isRepost={isRepost}
            heroProfiles={heroProfiles}
            setShowPnlComments={setShowPnlComments}
            setCombinedPnlPercentage={setCombinedPnlPercentage}
          />
        )}
        {type === "welcome" && (
          <Joined0xPPLItem groupedActivity={groupedActivity} />
        )}
        {type === "news" && groupedActivity?.transactions && (
          <TransactionActivities
            refetchGroupedActivity={refetchGroupedActivity}
            transactions={groupedActivity.transactions}
            isHovering={isHovering}
            activityId={groupedActivity.activity_id}
          />
        )}
        {type === "snapshot" && (
          <ProposalPreview
            proposalContent={groupedActivity?.markdown}
            topChoices={groupedActivity?.top_choices}
            state={groupedActivity?.state}
            totalVotes={groupedActivity?.total_votes}
            title={groupedActivity?.title}
            endDate={groupedActivity?.end_date}
            quorum={groupedActivity?.quorum}
            url={groupedActivity?.source_url}
          />
        )}
        {summaryPromptId && (
          <SummaryEditComponent
            refetchActivityDetailsPage={refetchActivityDetailsPage}
            setSummaryPromptId={setSummaryPromptId}
            summaryPromptId={summaryPromptId}
          />
        )}
        {showViewPost && <IconTextButton text="View Post" linkLike />}
        {isGroupedActivityDetails &&
          groupedActivity?.type === "activity_group" && (
            <PerpGraphDetails
              isShowing={
                isGroupedActivityDetails &&
                groupedActivity?.type === "activity_group"
              }
              groupedActivity={groupedActivity}
            />
          )}

        {!hideExtraRows && (
          <FeedExtraBorderItems
            items={[
              groupedActivity?.type === "activity_group" &&
                !isGroupedActivityDetails &&
                hasGmx(groupedActivity) && (
                  <ZxGmxItemWrapper
                    groupedActivity={groupedActivity}
                    profiles={{
                      ...profiles,
                      ...groupedActivity?.native_tokens_dict,
                    }}
                    onItemClicked={onItemClicked}
                    setShowPnlComments={setShowPnlComments}
                    setCombinedPnlPercentage={setCombinedPnlPercentage}
                  />
                ),
              groupedActivity &&
                groupedActivity.type === "zora_activity" &&
                groupedActivity.zora_url && (
                  <ZoraActivityDetails groupedActivity={groupedActivity} />
                ),
              groupedActivity &&
                groupedActivity.type === "zora_activity" &&
                groupedActivity.zora_description && (
                  <ZoraNftDescriptionDetails
                    groupedActivity={groupedActivity}
                  />
                ),
              groupedActivity &&
                groupedActivity.address_pairwise_summary &&
                isShowingBundleSuggestion({
                  groupedActivity,
                  refetchGroupedActivity,
                }) && (
                  <FeedBundleSuggestion
                    groupedActivity={groupedActivity}
                    noPadding
                    refetchGroupedActivity={refetchGroupedActivity}
                  />
                ),
              children != null && children,
              groupedActivity?.pnl_list?.length > 0 && (
                <TrendingPnlList
                  isUpperBorder={false}
                  profiles={profiles}
                  pnlList={groupedActivity?.pnl_list}
                />
              ),
              isGroupedActivityDetails &&
                combinedGnosisSigners(groupedActivity)?.length > 0 &&
                profiles != null && (
                  <GnosisSigners
                    isUpperBorder={false}
                    isBottomBorder={false}
                    signers={combinedGnosisSigners(groupedActivity)}
                    profiles={profiles}
                  />
                ),
              groupedActivity?.type === "screener_activity" && (
                <ScreenerBorderItem groupedActivity={groupedActivity} />
              ),
              groupedActivity?.type === "whale_alert" && (
                <WhaleTokenTransfersBorderItem
                  groupedActivity={groupedActivity}
                  profiles={profiles}
                />
              ),
            ]}
          />
        )}
        {!showViewPost && groupedActivity?.type === "screener_activity" && (
          <>
            <PaddingComponent height="16px" />
            <ScreenerMiniTable groupedActivity={groupedActivity} />
          </>
        )}
        {!hideStats && isGroupedActivityDetails && (
          <ActivityStats
            totalWallets={groupedActivity?.num_wallets}
            totalProtocols={groupedActivity?.num_protocols}
            activitiesCount={
              groupedActivity?.activities?.length || groupedActivity?.num_txns
            }
            hasSummary={!!groupedActivity?.summary}
          />
        )}
        {featureFlags.show_pnl_in_feed &&
          groupedActivity?.type !== "welcome" &&
          (isGroupedActivityDetails ? (
            <PnlTokenExpandedList
              id={groupedActivity?.id}
              type={groupedActivity?.type}
            />
          ) : isUserTokenGroup ? null : (
            <PnlTokenList
              link={groupedActivity?.link}
              profiles={groupedActivity?.profiles}
              groupedActivity={groupedActivity}
              setCombinedPnlPercentage={setCombinedPnlPercentage}
              setShowPnlComments={setShowPnlComments}
              isHomePage={isHomePage}
              showFullLengthName
            />
          ))}
        <PolyMarketActivities
          groupedActivity={groupedActivity}
          activities={groupedActivity?.activities}
          showSingleActivity={!isGroupedActivityDetails}
        />
        {!hideEngagements &&
          !!groupedActivity?.id &&
          groupedActivity?.engagements && (
            <CustomColumn overflowX="visible" overflowY="visible">
              <ActivityEngagements
                engagements={groupedActivity?.engagements}
                hideCount
                postDetails={groupedActivity}
                contentId={groupedActivity.id}
                contentTimestamp={groupHash}
                type={groupedActivity?.type}
                combinedPnlPercentage={combinedPnlPercentage}
                showPnlComments={showPnlComments}
                showComments
                groupedActivity={groupedActivity}
                queryKey={getEngagementsQueryKey(type, groupedActivity.id)}
              />
            </CustomColumn>
          )}
      </div>
    </div>
  );
};

const WhaleTokenTransfersBorderItem = ({ groupedActivity, profiles }) => {
  const firstActivity = groupedActivity?.activities?.[0];
  const firstTransaction = firstActivity?.transactions?.[0];
  const txnDetailsUrl =
    firstActivity?.chain_id && firstTransaction?.tx_id
      ? `/${firstActivity?.chain_id}/tx/${firstTransaction?.tx_id}`
      : "";
  return (
    <PaddingComponent paddingTop="8px" paddingBottom="8px">
      {firstActivity?.token_transfers.length > 0 && (
        <TokenTransfers
          tokenTransfers={firstActivity?.token_transfers}
          profiles={profiles}
          usdValue={firstActivity?.usd_value?.display_value}
          chainId={firstActivity?.chain_id}
          chainSymbol={firstActivity?.chain_symbol}
        />
      )}
      <ConditionalLink to={txnDetailsUrl}>
        <CustomTextSpan color="var(--text-1)">
          {`View Transaction >`}
        </CustomTextSpan>
      </ConditionalLink>
    </PaddingComponent>
  );
};

const ScreenerBorderItem = ({ groupedActivity }) => {
  return (
    <CustomRow alignItems="center" style={{ minHeight: "36px" }}>
      <ScreenerIcon />
      <PaddingComponent width="16px" />
      <CustomTextSpan color="var(--text-1)">
        {!isEmpty(groupedActivity?.replicated_by) && (
          <CustomRow>
            <CustomRow gap="4px">
              {"Replicated by "}
              <FeedDescriptionSumamryItemsList
                identifiersList={Object.keys(groupedActivity?.replicated_by)}
                profiles={groupedActivity?.replicated_by}
              />
            </CustomRow>
            {". "}
          </CustomRow>
        )}
        <ConditionalLink to={groupedActivity?.source_url}>
          {"Run Query >"}
        </ConditionalLink>
      </CustomTextSpan>
    </CustomRow>
  );
};

const ScreenerMiniTable = ({ groupedActivity }) => {
  const data = groupedActivity?.data_summary;
  const firstElement = data?.data?.[0];
  if (!firstElement) {
    return (
      <CustomTextSpan color="var(--text-2)">
        No result found for the given criteria.
      </CustomTextSpan>
    );
  }

  return (
    <ConditionalLink to={groupedActivity?.source_url}>
      <InfoBox padding="0">
        <CustomRow padding="10px 16px" alignItems="center">
          {groupedActivity?.data_summary?.num_results && (
            <CustomTextSpan fontWeight="600" color="var(--text-2)">
              {groupedActivity?.data_summary?.num_results}
              {groupedActivity?.data_summary?.num_results !== 1
                ? " Results"
                : " Result"}
            </CustomTextSpan>
          )}
          <PaddingComponent width="16px" />
        </CustomRow>
        <ScreenerTable
          bottomBorderRadius="6px"
          data={groupedActivity?.data_summary}
          rightPadding="20px"
          paddingBottom="0px"
          allLeftAlign
        />
      </InfoBox>
    </ConditionalLink>
  );
};

export const ZoraActivityDetails = ({ groupedActivity }) => {
  return (
    <PaddingComponent paddingTop="8px" paddingBottom="8px">
      <CustomRow>
        {(groupedActivity.floor_price || groupedActivity.owners) && (
          <FlexGrow>
            <CustomRow>
              {groupedActivity?.floor_price && (
                <CustomRow>
                  <CustomText text="Floor Price: " color="var(--text-2)" />
                  <PaddingComponent width="8px" />
                  <CustomText
                    text={groupedActivity.floor_price}
                    fontWeight="500"
                    color="var(--text-1)"
                  />
                  <PaddingComponent width="16px" />
                </CustomRow>
              )}
              {groupedActivity?.owners && (
                <CustomRow>
                  <CustomText text="Owners: " color="var(--text-2)" />
                  <PaddingComponent width="8px" />
                  <CustomText
                    text={groupedActivity.owners}
                    fontWeight="500"
                    color="var(--text-1)"
                  />
                  <PaddingComponent width="16px" />
                </CustomRow>
              )}
            </CustomRow>
          </FlexGrow>
        )}

        {groupedActivity?.zora_url && (
          <ConditionalLink
            sameTab={false}
            to={{ pathname: groupedActivity.zora_url }}>
            <CommonButton
              padding="4px 18px"
              background="var(--accent-1)"
              color="var(--text-white)"
              fontSize="12px"
              borderRadius="4px">
              Mint
            </CommonButton>
          </ConditionalLink>
        )}
      </CustomRow>
    </PaddingComponent>
  );
};

export const ZoraNftDescriptionDetails = ({ groupedActivity }) => {
  return (
    <PaddingComponent paddingTop="8px" paddingBottom="8px">
      <CustomRow>
        <CustomText
          text={groupedActivity.zora_description}
          color="var(--text-2)"
          textAlign="left"
        />
      </CustomRow>
    </PaddingComponent>
  );
};

export const FeedContent = ({
  groupedActivity,
  description,
  isHovering,
  setSummaryPromptId,
  profiles,
  identities,
  refetchGroupedActivity,
  hideNftTransfers,
  smallThumbnail,
  showOpenInNewTab,
  trimDescription,
  trimLimit,
  maxNftsCount,
  isRepost,
  isGroupedActivityDetails,
  heroProfiles = {},
  isUserTokenGroup = false,
  setShowPnlComments = () => {},
  setCombinedPnlPercentage = () => {},
}) => {
  const history = useHistory();
  const renderTransactions = () => {
    if (!!description) {
      return (
        <FeedDescriptionWrapper>
          {conditionallyTrimDescription(
            description,
            trimDescription,
            trimLimit
          )}
        </FeedDescriptionWrapper>
      );
    }
    if (
      groupedActivity?.transaction_summaries &&
      groupedActivity?.transaction_summaries.length > 0
    ) {
      const { transaction_summaries: summaries } = groupedActivity;

      return (
        <div className={classes.newTransactions}>
          <ZxTransactionSummaries
            isUserTokenGroup={isUserTokenGroup}
            groupedActivity={groupedActivity}
            totalCount={groupedActivity?.num_txns}
            summaries={summaries}
            mainProtocolData={groupedActivity?.protocols}
            isHovering={isHovering}
            heroNames={groupedActivity?.hero_names ?? heroProfiles}
            setSummaryPromptId={setSummaryPromptId}
            isGroupedActivityDetails={isGroupedActivityDetails}
            profiles={profiles}
            identities={identities}
            methodIds={groupedActivity?.method_ids}
            actor={groupedActivity?.actor}
            nativeTokensInfo={
              groupedActivity?.summary?.native_tokens ||
              groupedActivity?.native_tokens_dict
            }
            addressPairWiseSummary={groupedActivity.address_pairwise_summary}
            nftPrices={groupedActivity?.summary?.nft_prices}
            refetchGroupedActivity={refetchGroupedActivity}
            chainProfiles={groupedActivity?.chain_profiles}
            heroProfiles={heroProfiles}
            onTransactionClick={() => {
              if (isGroupedActivityDetails) {
                const transactions = document.getElementById(
                  "details_transactions"
                );
                // scroll to the transactions tab, 80vh down
                transactions?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
          />
          {isUserTokenGroup ? (
            <ZxUserTokenGroup
              groupedActivity={groupedActivity}
              identities={identities}
              profiles={profiles}
              setCombinedPnlPercentage={setCombinedPnlPercentage}
              setShowPnlComments={setShowPnlComments}
            />
          ) : null}
          {!hideNftTransfers && (
            <NftTransfers
              groupedActivity={groupedActivity}
              onClick={(e) => e.stopPropagation()}
              showGallery={!smallThumbnail}
              maxNftsCount={maxNftsCount}
              isRepost={isRepost}
            />
          )}
        </div>
      );
    }
    if (groupedActivity?.summary?.summary) {
      return (
        <div className={classes.activitySummary}>
          <FeedDescription
            mainProtocolData={groupedActivity?.protocols}
            isHovering={isHovering}
            heroNames={groupedActivity?.hero_names ?? heroProfiles}
            setSummaryPromptId={setSummaryPromptId}
            isGroupedActivityDetails={isGroupedActivityDetails}
            templateString={conditionallyTrimDescription(
              groupedActivity?.summary?.summary,
              trimDescription,
              trimLimit
            )}
            profiles={profiles}
            identities={identities}
            methodIds={groupedActivity?.method_ids}
            actor={groupedActivity?.actor}
            nativeTokensInfo={
              groupedActivity?.summary.native_tokens ||
              groupedActivity?.native_tokens_dict
            }
            addressPairWiseSummary={groupedActivity.address_pairwise_summary}
            nftPrices={groupedActivity?.summary.nft_prices}
            refetchGroupedActivity={refetchGroupedActivity}
            chainProfiles={groupedActivity?.chain_profiles}
          />
          {groupedActivity?.mint_price && (
            <CustomRow gap="20px" alignItems="center">
              <CustomText
                fontSize="14px"
                fontWeight="500"
                text="Mint Price :"
              />
              <CustomText
                fontWeight="500"
                color="var(--text-1)"
                fontSize="14px"
                text={groupedActivity.mint_price}
              />
            </CustomRow>
          )}
          {!hideNftTransfers && (
            <NftTransfers
              groupedActivity={groupedActivity}
              onClick={(e) => e.stopPropagation()}
              showGallery={!smallThumbnail}
              maxNftsCount={maxNftsCount}
              isRepost={isRepost}
            />
          )}
        </div>
      );
    }
    if (groupedActivity?.markdown) {
      return <DescriptionBox longText={groupedActivity.markdown} />;
    } else {
      return (
        <RenderFallbackActivities
          groupedActivity={groupedActivity}
          showOpenInNewTab={showOpenInNewTab}
          type={groupedActivity?.type}
          showGallery={!smallThumbnail}
          hideBundleSuggestions
          maxNftsCount={maxNftsCount}
          isRepost={isRepost}
        />
      );
    }
  };
  const onItemClicked = (e) => {
    handleFeedItemClick({
      contentId: groupedActivity.id,
      contentType: groupedActivity.type,
      history,
      event: e,
      link: groupedActivity.link,
      clickable: true,
      noRedirection: false,
    });
  };
  return (
    <SelectableDivWithDelay
      clickable={isGroupedActivityDetails ? false : true}
      className={classes.newTransactions}
      onClick={onItemClicked}>
      {renderTransactions()}
    </SelectableDivWithDelay>
  );
};

export const ActivityAvatar = ({
  actor,
  onActorUpdate,
  type,
  mini = false,
  showFollowButton = false,
}) => {
  const { identityDetails, isUserLoggedIn } = useContext(AuthContext);
  const isLoggedInUser =
    identityDetails?.current?.identity?.id === actor?.id ||
    identityDetails?.current?.identity?.id === actor?.identity_id;
  return (
    <div style={{ position: "relative" }}>
      <ProfileHoverCard actor={actor} onActorUpdate={onActorUpdate}>
        <ConditionalLink
          className={classes.avatarWrapper}
          to={actor?.identity_link || actor?.link}
          onClick={(e) => e.stopPropagation()}>
          <Image
            className={`${classes.avatar} ${mini && classes.mini}`}
            src={actor?.display_picture}
            alt={actor?.display_name}
            imageType={IMAGE_TYPES.AVATAR}
            name={actor?.display_name}
          />

          {type === "farcaster" && (
            <div className={classes.avatarBadge}>
              <FarcasterIcon className={classes.repliedBadge} />
            </div>
          )}
          {type === "lens" && (
            <div className={classes.avatarBadge}>
              <LensIcon className={classes.repliedBadge} />
            </div>
          )}
          {type === "snapshot" && (
            <div className={classes.avatarBadge}>
              <SnapshotIcon />
            </div>
          )}
        </ConditionalLink>
      </ProfileHoverCard>
      {isUserLoggedIn && (
        <div className={classes.followFeedButton}>
          {showFollowButton && (
            <FollowEditButton
              actor={actor}
              onActorUpdate={onActorUpdate}
              showEdit={false}
              followed={actor?.followed}
              identityId={actor?.identity_id ?? actor?.id}
              profileType={actor?.profile_type}
              // onUpdateCallback={onActorUpdate}
              disabled={actor?.disable_follow}
              followDirectly={!!(actor?.identity_id ?? actor?.id)}
              name={actor?.display_name}
              twitter={actor?.socials?.twitter}
              socials={actor?.socials}
              bio={actor?.bio}
              feedFollow={!isLoggedInUser && true}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GroupedActivityWrapper;
