import { signedRequest } from "./api";

export const getNodeData = async ({ node }) => {
  const response = await signedRequest({
    method: "get",
    path: `api/v4/get_node_data_api?node_address=${node.data?.address}`,
  });
  return {
    holdings: response.data.node_data.holdings_data,
    metadata: response.data.node_data.node_metadata,
    netWorth: response.data.node_data.profile_data.net_worth_data.display_value,
  };
};

export const getLinkData = async ({
  link,
  profile,
  startTime,
  endTime,
  tokenTuples = [],
  filterChains = [],
}) => {
  const chainParams =
    filterChains.length > 0 ? `&chain_ids=${filterChains.join(",")}` : "";
  const startParams = startTime ? `&start_ts=${startTime}` : "";
  const endParams = endTime ? `&end_ts=${endTime}` : "";
  const response = await signedRequest({
    method: "post",
    path: `api/v4/get_link_data_api?identifier_a=${link.source.id}&identifier_b=${link.target.id}${chainParams}${startParams}${endParams}`,
    bodyText: JSON.stringify({
      chain_id_addr_or_symbol_tuples: tokenTuples.length > 0 ? tokenTuples : [],
    }),
  });

  return response.data.data;
};

export const getNodeTransfers = async (
  {
    identifier,
    startTime = "",
    endTime = "",
    selectedActiveChains = null,
    selectedActiveTokens = null,
    isGraph = false,
    tokenTuples = [],
    filterChains = [],
  },
  params
) => {
  let chainTokenTuples = undefined;
  if (!isGraph && selectedActiveChains && selectedActiveTokens) {
    chainTokenTuples = [];
    for (const chain of selectedActiveChains) {
      for (const token of selectedActiveTokens) {
        chainTokenTuples.push([chain, token]);
      }
    }
  }
  if (isGraph) {
    chainTokenTuples = tokenTuples;
  }
  let _endTime = params?.endTime;
  if (params?.pageParam) {
    _endTime = params.pageParam;
  }
  const response = await signedRequest({
    method: "post",
    path: `api/v4/get_recent_transfers`,

    bodyText: JSON.stringify({
      identifier: identifier,
      chain_ids: isGraph ? filterChains : selectedActiveChains ?? [],
      chain_id_addr_or_symbol_tuples: chainTokenTuples,
      ...(startTime ? { start_ts: startTime } : {}),
      ...(_endTime ? { end_ts: _endTime } : {}),
    }),
  });
  return response.data;
};

const getTimeline = (timelineResp) => {
  if (timelineResp == null) {
    return [null, null];
  }
  const obj_keys = Object.keys(timelineResp);
  return [+obj_keys[0], +obj_keys[obj_keys.length - 1]];
};

export const fetchTimelineResponse = async ({ identifiers }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/v4/get_activity_timeline?identifiers=${identifiers}`,
  });
  const timelineResp = response.data.data;
  const [sliderStart, sliderEnd] = getTimeline(timelineResp);
  const timelines = Object.entries(timelineResp).map(([x, y]) => ({
    x,
    y: y + 1,
  }));

  return {
    timelines,
    sliderStart,
    sliderEnd,
  };
};

export const fetchGraphData = async (
  frontendShareablePath,
  signal,
  tokenTuples,
  filterChains
) => {
  const params = new URLSearchParams(frontendShareablePath);

  const tokens = params.get("chain_id_addr_or_symbol_tuples")
    ? JSON.parse(params.get("chain_id_addr_or_symbol_tuples"))
    : [];
  // delete params["chain_id_addr_or_symbol_tuples"];
  params.delete("chain_id_addr_or_symbol_tuples");
  const chains = params.get("filter_chains")
    ? params.get("filter_chains").split(",")
    : [];
  const identifiers = params.get("identifiers")
    ? params.get("identifiers")
    : "";
  params.delete("identifiers");
  params.delete("filter_chains");
  // make new path without the above params
  const newParams = "?" + params.toString();
  const response = await signedRequest({
    method: "post",
    path: `/api/v4/get_graph_explorer_unified${newParams}`,
    signal,
    bodyText: JSON.stringify({
      chain_id_addr_or_symbol_tuples: tokens,
      chain_ids: chains,
      identifiers: identifiers,
    }),
  });
  return response.data;
};
