import React from "react";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  PaddingComponent,
  TransparentButton,
} from "components/UI/Components/Components";
import { X } from "@phosphor-icons/react";
import TrendingTokenTransfers from "./TrendingTokenTransfers";

const ViewAllTransfersRightPanel = ({
  tokenProfile,
  transfers,
  profiles,
  onClose = () => {},
}) => {
  const transfersCount = transfers?.length;
  return (
    <PaddingComponent padding="20px 16px">
      <TransparentButton onClick={onClose}>
        <X size={20} color="var(--text-1)" />
      </TransparentButton>
      <PaddingComponent height="16px" />
      <CustomColumn>
        <CustomRow alignItems="center" gap="4px">
          <CustomText
            textAlign="left"
            text="Transactions"
            color="var(--text-1)"
            fontSize="18px"
            fontWeight="600"
          />
          <CustomText
            textAlign="left"
            text={`for ${tokenProfile?.token_details?.symbol}`}
            color="var(--text-2)"
            fontSize="18px"
          />
        </CustomRow>
        <PaddingComponent height="24px" />
        <CustomColumn>
          <CustomText
            textAlign="left"
            text="MOST RECENT TRANSACTIONS"
            color="var(--text-1)"
            fontSize="13px"
            fontWeight="600"
          />
          {!!transfersCount && (
            <CustomText
              textAlign="left"
              fontSize="11px"
              text={`Showing ${transfersCount} transactions`}
            />
          )}
        </CustomColumn>
      </CustomColumn>
      <PaddingComponent height="12px" />
      <TrendingTokenTransfers transfers={transfers} profiles={profiles} />
      <PaddingComponent height="12px" />
    </PaddingComponent>
  );
};

export default ViewAllTransfersRightPanel;
