import { useGetSimilarNfts } from "api/nft.api";
import GenericFallBackUI from "components/ErrorBoundary/GenericFallBackUI";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import { EMPTY_STATES } from "zxStyles/constants";
import { NftListLoader } from "../ZxNftLoaders/ZxNftLoaders";
import { NFTItem } from "components/V2/Profile/ProfileDetails/NFTHoldings/NftItem";
import styles from "./ZxNftDetails.module.scss";
const SimilarNFTs = ({
  contractAddress,
  chainId,
  tokenId,
  isModal,
  onNftClick,
}) => {
  const params = useParams();
  const history = useHistory();
  const { data, isLoading, isError } = useGetSimilarNfts({
    contractAddress,
    chainId,
    tokenId,
  });
  if (isError) {
    return <GenericFallBackUI />;
  }
  if (data?.nfts?.length === 0) {
    return (
      <ZxEmptyState state={EMPTY_STATES.NFT} text="No similar NFTs found" />
    );
  }
  if (isLoading) {
    return <NftListLoader />;
  }
  return (
    <div className={styles.similarNftsContainer}>
      {data?.nfts?.map((nft) => {
        const tokenId = nft.token_id ? `/${nft.token_id}` : "";
        const link = isModal
          ? `/collection/${params.collection_id}/${params.active_tab}/${params.chain_id}/${nft.contract_address}${tokenId}`
          : `/nft/${nft.chain_id}/${nft.contract_address}/${nft.token_id}`;
        return (
          <div
            key={nft.token_id}
            onClick={() => {
              onNftClick();
              history.push(link);
            }}>
            <NFTItem
              nftItem={nft}
              hasGalleryModal={true}
              displayValueOverride={nft?.price?.display_value}
              chainId={nft.chain_id}
              style={{
                borderRadius: "8px",
                cursor: "pointer",
                maxWidth: isModal ? "150px" : "170px",
                minWidth: isModal ? "150px" : "170px",
                minHeight: "196px",
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SimilarNFTs;
