const hackPostToActivitiesSchema = (post, identities, profiles) => {
  const activity = {
    ...post,
    identities,
    profiles,
    id: post?.post?.id,
    activities: [{ media: post?.post?.images, id: 0 }],
  };
  return activity;
};

export default hackPostToActivitiesSchema;
