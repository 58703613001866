import React, { useCallback, useContext, useEffect } from "react";
import "@farcaster/auth-kit/styles.css";

import FarcasterContextProvider, {
  FarcasterContext,
} from "contextStates/FarcasterContext";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  SpinnerComponent,
} from "components/UI/Components/Components";
import classes from "./FarcasterSignInButton.module.scss";
import { CheckCircle, DeviceMobile, QrCode } from "@phosphor-icons/react";
import QRCodeLogin from "components/UI/QrCode/QrCode";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { GlobalContext } from "contextStates/Global";
import { trackEvent } from "utils/event_tracking";
import ZxModal from "zxComponents/common/ZxModal/ZxModal";
import { ArrowSquareOut, SealCheck } from "@phosphor-icons/react";
import { SocialFollowers } from "components/V2/NewOnboarding/SocialLogins/SocialLogins";
import useScreenRes from "customHooks/useMediaQuery";
import { MOBILE_ONBOARDING_THRESHOLD } from "utils/constants";

const LOGIN_STEPS = [
  {
    Icon: QrCode,
    text: "Open camera and scan QR code",
  },
  {
    Icon: DeviceMobile,
    text: "Sign in on your farcaster app",
  },
  {
    Icon: CheckCircle,
    text: "Signup successful",
  },
];

const FarcasterSignInButton = ({
  onFinish = null,
  isDisabled,
  isFetching,
  isSocialConnected,
  customRender = null,
  onSuccess = () => {},
  onBeforeClick = () => {},
  follows = [],
  identities = {},
  updateLoading = null,
  isOnboarding = true,
}) => {
  const trackingPrefix = isOnboarding ? "ob_" : "profile_edit_";
  const {
    channel,
    createChannel,
    isLoading,
    isConnected,
    stopPolling,
    setUserDetails,
    isError,
    status,
    userDetails,
    isPopupOpen,
    setIsPopupOpen,
  } = useContext(FarcasterContext);
  const { width } = useScreenRes();
  const { featureFlags } = useContext(GlobalContext);
  const closePopup = useCallback(() => {
    stopPolling();
    setIsPopupOpen(false);
    setUserDetails(null);
    if (onFinish) onFinish();
  }, [setUserDetails, stopPolling, onFinish, setIsPopupOpen]);
  const openPopup = () => {
    trackEvent(trackingPrefix + "connect_socials_farcaster_btn_clicked");
    createChannel();
    setIsPopupOpen(true);
  };
  useEffect(() => {
    if (isError && status === "ERROR") {
      closePopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, status]);
  useEffect(() => {
    if (isConnected && userDetails) {
      closePopup();
      stopPolling();
      onSuccess(userDetails);
    }
  }, [isConnected, closePopup, stopPolling, onSuccess, userDetails]);
  useEffect(() => {
    if (updateLoading) updateLoading(isLoading);
  }, [isLoading, updateLoading]);
  if (!featureFlags?.farcaster_login_enabled) {
    return null;
  }
  const handleClick = (e) => {
    if (
      !isDisabled &&
      !isLoading &&
      !isConnected &&
      !isFetching &&
      !isSocialConnected
    ) {
      openPopup();
    }
  };
  const isMobile = width < MOBILE_ONBOARDING_THRESHOLD;
  return (
    <>
      {isPopupOpen && (
        <ZxModal
          isOpen={isPopupOpen}
          position="absolute"
          title={isOnboarding ? "Signup with Farcaster" : "Connect Farcaster"}
          titleFontSize="16px"
          onClose={closePopup}
          onCloseEvent="ob_connect_socials_farcaster_modal_dismiss"
          isDivider
          width={isMobile ? "90%" : 480}
          verticalPadding="8px"
          titleAlign="left">
          <CustomRow
            gap="16px"
            justifyContent="space-between"
            padding="8px 0"
            alignItems="center">
            <CustomRow
              alignItems="center"
              justifyContent="center"
              width="200px">
              {!channel ? (
                <SpinnerComponent size={48} color="var(--primary-color)" />
              ) : (
                <QRCodeLogin
                  deeplinkUrl={channel.url}
                  profileImage={"/svg/farcaster.svg"}
                  size={isMobile ? 150 : 200}
                />
              )}
            </CustomRow>
            <CustomColumn gap="16px">
              <ZxText style={TSTYLES.bodyLabel}>Steps to follow</ZxText>
              {LOGIN_STEPS.map((step, index) => (
                <CustomRow gap="8px" key={index}>
                  <step.Icon size={24} color="var(--text-2)" weight="duotone" />
                  <ZxText fontSize={isMobile ? "12px" : "13px"}>
                    {step.text}
                  </ZxText>
                </CustomRow>
              ))}
            </CustomColumn>
          </CustomRow>
        </ZxModal>
      )}
      {customRender ? (
        <div
          onClick={() => {
            onBeforeClick();
            handleClick();
          }}>
          {customRender()}
        </div>
      ) : (
        <CustomColumn
          className={`${classes.container} ${isDisabled || isLoading || isConnected || isSocialConnected || isFetching ? classes.disabled : ""} ${isConnected || isSocialConnected ? classes.connected : ``}`}>
          <CustomRow
            justifyContent="space-between"
            onClick={() => {
              if (
                !isDisabled &&
                !isLoading &&
                !isConnected &&
                !isFetching &&
                !isSocialConnected
              ) {
                openPopup();
              }
            }}
            alignItems="center">
            <CustomRow gap="16px" alignItems="center">
              <img
                src="/svg/farcaster.svg"
                alt="Farcaster"
                className={classes.farcasterLogo}
              />
              <CustomText
                text={
                  !isConnected && !isSocialConnected
                    ? "Connect Farcaster"
                    : "Farcaster Connected"
                }
                color={
                  !isConnected && !isSocialConnected
                    ? "var(--base)"
                    : "var(--text-1)"
                }
                fontWeight="500"
                fontSize="15px"
                lineHeight="22px"
              />
            </CustomRow>
            <CustomRow alignItems="flex-end" gap="8px">
              {!(isConnected || isSocialConnected) && follows?.length > 0 && (
                <ZxText color="white">
                  {follows.length} connections found
                </ZxText>
              )}
              {(!isLoading && !(isConnected || isSocialConnected)) ||
              isError ? (
                <ArrowSquareOut size={20} color="var(--base)" />
              ) : isConnected || isSocialConnected ? (
                <SealCheck color="var(--success)" weight="fill" size={16} />
              ) : (
                <SpinnerComponent color="var(--base)" />
              )}
            </CustomRow>
          </CustomRow>
          {follows.length > 0 &&
            (isConnected || isSocialConnected) &&
            !isLoading && (
              <SocialFollowers
                profileList={follows}
                identities={identities}
                title="Farcaster"
              />
            )}
        </CustomColumn>
      )}
    </>
  );
};

const Wrapper = (props) => {
  return (
    <FarcasterContextProvider {...props}>
      <FarcasterSignInButton {...props} />
    </FarcasterContextProvider>
  );
};

export default withErrorBoundary(Wrapper);
