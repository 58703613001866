import React from "react";
import classes from "./groupedActivityWrapper.module.css";
import ActivityHeader from "./ActivityHeader";
import ActivityEngagements from "components/ActivityEngagements/ActivityEngagements";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import ContentRenderer from "./ContentRenderer";
import { IconTextButton } from "components/UI/Components/IconTextButton";
import LinkPreviews from "components/Feed/LinkPreviews";
import { NftTransfers } from "components/GroupFeedItem/GroupFeedItem.components";
import hackPostToActivitiesSchema from "./hackPostToActivitiesSchema";
import { ancestorsToQueryKeys, stopEventPropagation } from "utils/misc";

import { conditionallyTrimDescription } from "utils/string_helper";
import { CustomColumn, CustomRow } from "components/UI/Components/Components";
import { getEngagementsQueryKey } from "components/ActivityEngagements/utils";
import { getUniqueId } from "utils/event_tracking";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { ThreadConnectorIcon } from "assets/svg/left_sidebar/CustomSvgIcons";
import { ActivityAvatar } from "./GroupedActivityWrapper";

const PostContentWrapper = ({
  actor,
  identities,
  profiles,
  hideEngagements = false,
  hasReplies,
  post,
  showFullDateTime,
  showSettings = true,
  showViewPost = false,
  hideNftTransfers,
  showThreadLine,
  trimDescription,
  trimLimit,
  hideRepost = false,
  smallThumbnail = false,
  isRepost = false,
  replyType,
  showComments = false,
  isSecondaryThread = false,
  isPostDetails = false,
}) => {
  const contents = post?.post?.contents ?? post?.description_text ?? null;
  const hasThreads = post?.threads?.length > 1;
  const threads = hasThreads ? post.threads : [];
  const [showingAllThreads, setShowingAllThreads] = React.useState(false);
  const defaultThreadLimit = isPostDetails ? 3 : 2;
  const showAllThreads = () => setShowingAllThreads(true);
  const isHomePage = window.location.pathname.includes("/home");
  return (
    <div
      className={`${classes.groupedActivityWrapper} 
      ${(!!hasReplies && classes.hasReplies) || ""}
      ${(showThreadLine && classes.showThreadLine) || ""} ${
        replyType === "news-reply" && classes.newsReply
      } `}>
      {!isSecondaryThread && (
        <div className={classes.actor}>
          <CustomRow alignItems="center" width="100%" gap="12px">
            <ActivityAvatar
              type={"post"}
              actor={actor}
              showFollowButton={actor?.followed === false && isHomePage}
            />
            <ActivityHeader
              post={post}
              actor={actor}
              timestamp={post?.timestamp}
              showFullDateTime={showFullDateTime}
              type={post?.type}
              postId={post?.id}
              creatorIdentity={post?.post?.creator_identity}
              involvedChains={post?.chain_profile_keys}
              showSettings={showSettings}
              queryKeys={ancestorsToQueryKeys(post?.ancestors)}
            />
          </CustomRow>
        </div>
      )}
      <div
        className={`${classes.content}
        ${(!!hasReplies && classes.hasReplies) || ""} `}>
        <>
          <FeedDescription
            templateString={conditionallyTrimDescription(
              contents,
              trimDescription,
              trimLimit
            )}
            profiles={profiles}
            identities={identities}
            nativeTokensInfo={post?.native_tokens_dict}
            enableSeeMore={true}
          />
          {!hideNftTransfers && (
            <NftTransfers
              groupedActivity={hackPostToActivitiesSchema(
                post,
                identities,
                profiles
              )}
              onClick={(e) => e.stopPropagation()}
              showGallery={!smallThumbnail}
              isRepost={isRepost}
            />
          )}
        </>
        {!hideRepost && post.reposted != null && (
          <div className={classes.reposted} onClick={stopEventPropagation}>
            <ContentRenderer
              groupedActivity={post.reposted}
              profiles={profiles}
              identities={identities}
              hideEngagements
              showSettings={false}
              isRepost
            />
          </div>
        )}
        {hasThreads && threads?.length > 0 && (
          <CustomColumn gap="8px">
            {threads
              .slice(1, showingAllThreads ? threads.length : defaultThreadLimit)
              .map((thread, idx) => {
                const isLastThread = idx === threads.length - 1;
                return (
                  <CustomColumn key={getUniqueId()} gap="12px">
                    {!isLastThread && <ThreadConnectorIcon />}
                    <PostContentWrapper
                      hasReplies={false}
                      isSecondaryThread
                      description={thread?.post?.contents}
                      identities={identities}
                      profiles={profiles}
                      post={thread}
                      actor={actor}
                      hideStats={true}
                      hideEngagements={hideEngagements}
                      showFullDateTime={showFullDateTime}
                      hideNftTransfers={false}
                      showSettings={showSettings}
                      showViewPost={showViewPost}
                      showThreadLine={showThreadLine}
                      trimDescription={trimDescription}
                      trimLimit={trimLimit}
                      hideRepost={hideRepost}
                      smallThumbnail={false}
                      isRepost={isRepost}
                      showComments={false}
                    />
                  </CustomColumn>
                );
              })}
            {threads.length > 3 && !showingAllThreads && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  showAllThreads();
                }}>
                <ZxText
                  style={TSTYLES.bodyLabel}
                  cursor="pointer"
                  color="primary">
                  View full thread
                </ZxText>
              </div>
            )}
          </CustomColumn>
        )}
        {showViewPost && <IconTextButton text="View Post" linkLike />}
        {post?.post?.url_previews?.length > 0 && (
          <LinkPreviews previews={post?.post?.url_previews} />
        )}
        {!hideEngagements && !isSecondaryThread && (
          <ActivityEngagements
            engagements={post?.engagements}
            contentId={post?.id}
            hideCount
            contentTimestamp={post?.timestamp}
            type={post?.type}
            postDetails={post}
            postAncestors={post?.ancestors}
            queryKey={getEngagementsQueryKey(post?.type, post?.id)}
            showComments={showComments}
            groupedActivity={post}
          />
        )}
      </div>
    </div>
  );
};

export default PostContentWrapper;
