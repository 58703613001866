import classes from "./protocolInfo.module.css";

import { getChainMetaDataWithProfile } from "utils/misc";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import ConditionalLink from "shared/ConditionalLink";

const ProtocolInfo = ({
  protocol,
  hideLabel = false,
  isRightPane = false,
  height,
  width,
  chainProfiles,
}) => {
  const { protocol_website, protocol_icon_url, protocol_name, chain_id } =
    protocol;
  const chainMetaData = getChainMetaDataWithProfile({
    chainId: chain_id,
    chainProfiles,
  });

  return (
    <ConditionalLink
      to={{
        pathname: protocol_website,
      }}
      sameTab={false}
      className={classes.protocolInfo}>
      <div className={classes.protocolIconWrapper}>
        <Image
          className={classes.protocolIcon}
          src={protocol_icon_url}
          imageType={IMAGE_TYPES.TOKEN}
          alt={`${protocol_name}-icon`}
          style={{ height, width }}
        />
        {chain_id !== "Ethereum" && (
          <img
            className={classes.chainIcon}
            src={chainMetaData?.overlayIcon}
            alt={`${chain_id}-overlay-icon`}
          />
        )}
      </div>
      {!hideLabel && (
        <PPLxTooltip title={protocol_name} placement="top-start" arrow>
          <span
            className={`${classes.protocolName} ${
              isRightPane ? classes.rightPane : ""
            }`}>
            {protocol_name}
          </span>
        </PPLxTooltip>
      )}
    </ConditionalLink>
  );
};

export default ProtocolInfo;
