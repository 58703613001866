import {
  CustomColumn,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import CustomLinkTabBar from "components/UI/Components/CustomLinkTabBar";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getGroupedActivity } from "api/feed.api";
import { ONE_DAY, QueryKeyConstants } from "utils/constants";
import ZxGmxItem from "zxComponents/feed/ZxGmxItem/ZxGmxItem";
import TradingViewGraph from "components/V2/TradingViewGraph/TradingViewGraph";
import { useTradingViewMarkers } from "utils/trading_view_utils";

const PerpGraphDetails = ({
  groupedActivity,
  isShowing,
  paddingTop = "8px",
  overrideType = null,
  overrideId = null,
  overrideActivity = null,
}) => {
  const id = overrideId ?? groupedActivity?.id;
  const type = overrideType ?? groupedActivity?.type;
  const { data: groupedActivityData } = useQuery({
    queryFn: () =>
      getGroupedActivity({
        id,
        type,
      }),
    queryKey: [
      QueryKeyConstants.GROUPED_ACTIVITY,
      groupedActivity?.id?.toString(),
      groupedActivity?.type,
    ],
    staleTime: ONE_DAY,
    enabled: !!id && !!type && !overrideActivity,
    initialData: overrideActivity,
  });
  const { getChartMarkers, updateMarkers } = useTradingViewMarkers();
  const list = Object.values(
    getPerpTradesList({ groupedActivity: groupedActivityData }) ?? {}
  );
  const tokenTabs = useMemo(() => {
    return (
      list
        ?.map((item) => {
          return item?.[0]?.perp_trade?.displayed_token ?? null;
        })
        .filter((item) => item !== null) ?? []
    );
  }, [list]);

  const [activeTab, setActiveTab] = useState(0 ?? "");
  useEffect(() => {
    if (tokenTabs.length > 0 && activeTab === "") {
      setActiveTab(tokenTabs[0]);
    }
  }, [tokenTabs, activeTab]);

  const renderTabContent = () => {
    const perpItem = list?.[activeTab];
    if (!perpItem) return null;

    const chainId = perpItem?.[0]?.perp_trade?.chain_id ?? null;
    const tokenAddress = perpItem?.[0]?.perp_trade?.displayed_token
      ?.slice(2, -2)
      ?.split("||")?.[1];
    const allBuyPoints = [];
    const allSellPoints = [];
    perpItem?.forEach((item) => {
      //add all buy points
      item.perp_trade?.buy_timestamps?.forEach((timestamp) => {
        allBuyPoints.push({
          timestamp,
          label: "B",
          text: false,
          id: `${timestamp}_buy`,
          color: "green",
        });
      });
      item.perp_trade?.sell_timestamps?.forEach((timestamp) => {
        allSellPoints.push({
          timestamp,
          label: "S",
          text: false,
          id: `${timestamp}_sell`,
          color: "red",
          action: "sell",
        });
      });
    });
    const markers = [...allBuyPoints, ...allSellPoints];
    updateMarkers(markers);
    if (!isShowing) return null;
    if (groupedActivityData.isLoading) {
      return null;
    }
    const profile =
      groupedActivityData?.profiles?.[tokenAddress] ??
      groupedActivityData?.native_tokens_dict?.[tokenAddress];
    const chain =
      profile?.address_chain ?? chainId ?? profile?.token_details?.chain_id;
    return (
      <PaddingComponent padding="12px 0">
        <PaddingComponent padding="0 0 12px 0">
          {perpItem?.slice(0, 1).map((item, index) => {
            const perpTrade = item?.perp_trade;
            if (!perpTrade) return null;
            return (
              <>
                <ZxGmxItem
                  isOpen={true}
                  isExpandable={false}
                  trade={perpTrade}
                  profiles={{
                    ...groupedActivityData.profiles,
                    ...groupedActivityData?.native_tokens_dict,
                  }}
                  isGroupedActivityDetails
                />
              </>
            );
          })}
        </PaddingComponent>
        <CustomColumn>
          <Divider margin="0 0 12px 0" />
          <TradingViewGraph
            address={tokenAddress}
            chain={chain}
            showPriceHeader
            getMarkers={getChartMarkers}
          />
        </CustomColumn>
      </PaddingComponent>
    );
  };
  if (list.length === 0 || tokenTabs.length === 0) return null;
  return (
    <PaddingComponent paddingTop={paddingTop}>
      <CustomLinkTabBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsList={tokenTabs}
        size="REGULAR"
        isLinkTabBar={false}
        customRender={(tab) => {
          return (
            <div>
              <FeedDescription
                templateString={tab}
                profiles={{
                  ...groupedActivityData.profiles,
                  ...groupedActivityData?.native_tokens_dict,
                }}
                isPlainText={true}
                isNavigating={false}
                allowBubble={true}
              />
            </div>
          );
        }}
      />
      {renderTabContent()}
    </PaddingComponent>
  );
};

export const getPerpTradesList = ({ groupedActivity }) => {
  //if activity.activity_context.perp_trades is not empty then return it
  return groupedActivity?.group_activity_contexts;
};

export default PerpGraphDetails;
