import styled from "styled-components/macro";

export const BarsWrapper = styled.div`
  ${(props) => `
 width:100%;
 display:flex;
 align-items:center;
 height:8px;
 border-radius:8px;
 ${props.padding != null && `padding: ${props.padding}`};
  `}
`;
export const IndividualBar = styled.div`
  ${(props) => `
 width:${props.width};
 background-color:${props.bgColor} ;
 height:8px;
 flex-shrink:0;
 border-top-left-radius:${props.borderLeftRadius ? "8px" : "0px"};
 border-bottom-left-radius:${props.borderLeftRadius ? "8px" : "0px"};
 border-top-right-radius:${props.borderRightRadius ? "8px" : "0px"};
 border-bottom-right-radius:${props.borderRightRadius ? "8px" : "0px"};
  `}
`;

export const PercentageBar = ({ percentageArray, othersPercent, ...props }) => {
  return (
    <BarsWrapper {...props}>
      {percentageArray.map((item, index) => {
        return (
          <IndividualBar
            borderLeftRadius={index === 0}
            bgColor={`var(--bar-${index})`}
            width={item.percentage}
            key={index}
            borderRightRadius={
              othersPercent === 0 && index === percentageArray.length - 1
            }
          />
        );
      })}

      {othersPercent > 0 && (
        <IndividualBar
          borderRightRadius
          bgColor={"var(--text-3)"}
          width={`${othersPercent}%`}
        />
      )}
    </BarsWrapper>
  );
};
