import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
} from "components/UI/Components/Components";
import React from "react";

const GenericTableLoader = ({ showHeader = true }) => {
  return (
    <CustomColumn gap="4px">
      {showHeader && (
        <CustomRow gap="8px">
          {[1, 2, 3, 4].map((_, index) => (
            <CustomSkeleton key={index} height="40px" width="20%" />
          ))}
        </CustomRow>
      )}
      <CustomColumn>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
          <CustomSkeleton key={index} height="40px" width="100%" />
        ))}
      </CustomColumn>
    </CustomColumn>
  );
};

export default GenericTableLoader;
