import { InfoBox } from "components/UI/Components/Components";
import { useEffect, useState } from "react";
import {
  Users,
  DeviceMobile,
  Wallet,
  NotePencil,
  Note,
  TelegramLogo,
  Handshake,
} from "@phosphor-icons/react";
import classes from "./HomeCarousel.module.css";
import Carousel from "./Carousel";
import carouselImage1 from "assets/png/onboarding/carousel_image_1.png";
import carouselImage2 from "assets/png/onboarding/carousel_image_2.png";
import carouselImage3 from "assets/png/onboarding/carousel_image_3.png";
import carouselImageDark3 from "assets/png/onboarding/carousel_image_dark_3.png";
import carouselImage4 from "assets/png/onboarding/carousel_image_4.png";
import carouselImageDark4 from "assets/png/onboarding/carousel_image_dark_4.png";
import carouselImage5 from "assets/png/onboarding/carousel_image_5.png";
import carouselImage6 from "assets/png/onboarding/carousel_image_6.png";
import carouselImageDark6 from "assets/png/onboarding/carousel_image_dark_6.png";
import carouselImage7 from "assets/png/onboarding/carousel_image_7.png";
import carouselImage8 from "assets/png/onboarding/carousel_image_8.png";
import carouselImageDark8 from "assets/png/onboarding/carousel_image_dark_8.png";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "contextStates/AuthContext";
import { DownloadAppModal } from "../../../../V2/Onboarding/components/AppDownloadButton";
import { useQuery } from "@tanstack/react-query";
import { getUserProfileOboardingStatus } from "api/profile.api";
import { trackEvent } from "utils/event_tracking";
import { ThemeContext } from "contextStates/Theme.context";
import { getRecommendedProfiles } from "../../../../../api/community.api";
import { ONE_DAY } from "../../../../../utils/constants";

const HomeCarousel = () => {
  const history = useHistory();
  const { identityDetails } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);

  const identifier = identityDetails?.current?.identity?.id;

  const { data: userProfileOnboardingStatus } = useQuery({
    queryKey: ["user_profile_onboarding_status", identifier],
    queryFn: () => getUserProfileOboardingStatus(),
    enabled: identifier !== undefined,
    retry: 1,
    retryOnMount: false,
    staleTime: 1000 * 60 * 5,
  });

  const [slides, setSlides] = useState([]);

  useEffect(() => {
    setSlides([
      ...(!userProfileOnboardingStatus?.is_invite_code_used
        ? [
            {
              image: carouselImage1,
              icon: Users,
              title: "Get your friends on 0xPPL",
              message: "Discover Alpha, Ape, Shill, FUD together",
              cta: "Use your unique invite code",
              onTap: () => {
                history.push(`/refer-friends`);
                trackEvent("Onboarding Steps Carousel", {
                  cta: "Use your unique invite code",
                });
              },
              overlayComponent: <OverlayComponent />,
            },
          ]
        : []),
      ...(!userProfileOnboardingStatus?.has_downloaded_mobile_app
        ? [
            {
              image: carouselImage2,
              icon: DeviceMobile,
              title: "Download 0xPPL Mobile App",
              message: "Get instant notifications on the go!",
              cta: "Download app",
              onTap: () => {
                handleShowPopUp();
                trackEvent("Onboarding Steps Carousel", {
                  cta: "Download app",
                });
              },
            },
          ]
        : []),
      ...(!userProfileOnboardingStatus?.has_linked_wallets
        ? [
            {
              image: theme === "dark" ? carouselImageDark3 : carouselImage3,
              icon: Wallet,
              title: "Add more wallets on 0xPPL",
              message:
                "Track all your EVM, Solana and Cosmos portfolio in one place",
              cta: "Link your wallets",
              onTap: () => {
                history.push(
                  `/${identityDetails?.current?.identity?.id}/wallets?openAddWallet`
                );
                trackEvent("Onboarding Steps Carousel", {
                  cta: "Link your wallets",
                });
              },
            },
          ]
        : []),
      ...(!userProfileOnboardingStatus?.has_cross_posted
        ? [
            {
              image: theme === "dark" ? carouselImageDark4 : carouselImage4,
              icon: NotePencil,
              title: "Create a cross post on OxPPL",
              message: "Post on twitter, farcaster and lens using 0xPPL",
              cta: "Create a cross post",
              onTap: () => {
                history.push(`/home?newPost`);
                trackEvent("Onboarding Steps Carousel", {
                  cta: "Create a cross post",
                });
              },
            },
          ]
        : []),
      ...(userProfileOnboardingStatus?.num_profiles_followed < 10
        ? [
            {
              image: carouselImage5,
              icon: Wallet,
              title: `Follow your favourite creators (${userProfileOnboardingStatus?.num_profiles_followed}/10)`,
              message: "See all their posts & onchain activities",
              cta: "Follow people",
              onTap: () => {
                history.push(`/community/network`);
                trackEvent("Onboarding Steps Carousel", {
                  cta: "Follow people",
                });
              },
            },
          ]
        : []),
      ...(!userProfileOnboardingStatus?.has_added_bio
        ? [
            {
              image: theme === "dark" ? carouselImageDark6 : carouselImage6,
              icon: Note,
              title: "Add a bio for your profile",
              message: "Bio helps people to know you better",
              cta: "Add your bio",
              onTap: () => {
                history.push(
                  `/${identityDetails?.current?.identity?.id}/activities?openEditProfile`
                );
                trackEvent("Onboarding Steps Carousel", {
                  cta: "Add your bio",
                });
              },
            },
          ]
        : []),
      ...(!userProfileOnboardingStatus?.is_telegram_connected
        ? [
            {
              image: carouselImage7,
              icon: TelegramLogo,
              title: "Connect your telegram handle",
              message: "Streams 0xPPL notifications on telegram",
              cta: "Connect your handle",
              onTap: () => {
                history.push(`/settings`);
                trackEvent("Onboarding Steps Carousel", {
                  cta: "Connect your handle",
                });
              },
            },
          ]
        : []),
      ...(!userProfileOnboardingStatus?.has_shared_private_wallets
        ? [
            {
              image: theme === "dark" ? carouselImageDark8 : carouselImage8,
              icon: Handshake,
              title: "Share private wallet with friends",
              message: "Connect with friends to share private wallets mutually",
              cta: "Connect with friends",
              onTap: () => {
                history.push(
                  `/${identityDetails?.current?.identity?.id}/wallets/manage-access`
                );
                trackEvent("Onboarding Steps Carousel", {
                  cta: "Connect with friends",
                });
              },
            },
          ]
        : []),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileOnboardingStatus, theme]);

  const [isDownloadAppPopUpOpen, setIsDownloadAppPopUpOpen] = useState(false);

  const handleShowPopUp = () => {
    setIsDownloadAppPopUpOpen(true);
  };

  const onClose = () => {
    setIsDownloadAppPopUpOpen(false);
  };

  if (slides.length === 0) return "";

  return (
    <InfoBox padding="16px" radius="8px" borderColor="var(--elevation-1)">
      <Carousel slides={slides} showArrows />
      <DownloadAppModal
        onClose={onClose}
        isDownloadAppPopUpOpen={isDownloadAppPopUpOpen}
      />
    </InfoBox>
  );
};

const OverlayComponent = () => {
  const { data: recommendedProfilesData } = useQuery({
    queryKey: ["recommendedProfiles"],
    queryFn: () => getRecommendedProfiles(),
    staleTime: ONE_DAY,
  });
  const imgData = recommendedProfilesData?.data
    ?.map((item) => item?.display_picture)
    ?.filter((item) => item)
    ?.slice(0, 3);

  return (
    <div className={classes.overlayComponent}>
      {imgData?.[0] && (
        <img className={classes.img1} src={imgData?.[0]} alt="overlayImg1" />
      )}
      {imgData?.[1] && (
        <img className={classes.img2} src={imgData?.[1]} alt="overlayImg2" />
      )}
      {imgData?.[2] && (
        <img className={classes.img3} src={imgData?.[2]} alt="overlayImg3" />
      )}
    </div>
  );
};

export default HomeCarousel;
