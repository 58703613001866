import {
  AdditionalInfoItem,
  CustomRow,
  CustomSkeleton,
  CustomText,
  InfoBox,
  PaddingComponent,
} from "components/UI/Components/Components";
import Copyable from "components/ValueType/Copyable/Copyable";
import { handleUrls } from "utils/misc";

const AdditionalInfo = ({ data, isShimmer = false }) => {
  return (
    <InfoBox>
      <CustomText
        text="Additional Info"
        textAlign="left"
        fontSize="15px"
        fontWeight="600"
        color="var(--text-1)"
      />
      <PaddingComponent height="16px" />
      {data?.map((info, index) => {
        const value = info?.display_value;
        return (
          <AdditionalInfoItem
            valueFlexGrow={1}
            key={`data-${info.key}-${index}`}
            title={`${info.key}: `}
            value={
              <CustomRow alignItems="center" gap="6px">
                <div
                  className="nft-caption"
                  dangerouslySetInnerHTML={{
                    __html: handleUrls(value),
                  }}></div>
                {info?.copiable && <Copyable content={info?.value} />}
              </CustomRow>
            }
          />
        );
      })}
      {isShimmer &&
        Array.from({ length: 3 }).map((_, index) => (
          <AdditionalInfoItem
            valueFlexGrow={1}
            key={`shimmer-${index}`}
            title={<CustomSkeleton width="100px" />}
            value={<CustomSkeleton />}
          />
        ))}
    </InfoBox>
  );
};

export default AdditionalInfo;
