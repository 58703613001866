import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const searchTextItems = [
  "Search a Transaction hash",
  "Search an Address",
  'Search "USDC on Base"',
  'Search "Uniswap protocol"',
  'Search "Pepe token"',
  'Search "myprasanna.eth"',
  'Search "Vitalik"',
];

const DELAY_MS = 1500;
const HOLD_MS = 1500;
const numElements = searchTextItems.length;
const animationDuration = numElements * (DELAY_MS + HOLD_MS);
const visiblePercent = parseInt((DELAY_MS / animationDuration) * 100);
const postHoldPercent =
  visiblePercent + parseInt((HOLD_MS / animationDuration) * 100);
const hiddenPercent =
  postHoldPercent + parseInt((DELAY_MS / animationDuration) * 100);

const searchAnimator = keyframes`
  0% {
    top: 100%;
    visibility: visible;
  }
  ${visiblePercent}%, ${postHoldPercent}% {
    top: 0px;
  }
  ${hiddenPercent}% {
    top: -100%;
    visibility: hidden;
  }
  100% {
    top: 100%;
    visibility: hidden;
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: var(--text-3);
  margin-top: 4.5px;
`;

const AnimatedText = styled(StyledDiv)`
  top: 100%;
  animation: ${animationDuration}ms ease ${({ delay }) => `${delay ?? 0}ms`}
    infinite normal forwards running ${searchAnimator};
`;

const AnimationWrapper = styled.div`
  height: 32px;
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

const AnimatedLoader = ({ textItems }) => {
  return (
    <AnimationWrapper>
      {textItems.map((text, index) => (
        <AnimatedText key={index} delay={index * (DELAY_MS + HOLD_MS)}>
          {text}
        </AnimatedText>
      ))}
    </AnimationWrapper>
  );
};

const SearchPlaceholder = () => {
  const [pauseAnimation, setPauseAnimation] = useState(false);
  useEffect(() => {
    // pause after three cycles
    const timeoutid = setTimeout(() => {
      setPauseAnimation(true);
    }, animationDuration * 3);
    return () => {
      clearTimeout(timeoutid);
    };
  }, []);
  if (pauseAnimation) {
    return (
      <AnimationWrapper>
        <StyledDiv>Search</StyledDiv>
      </AnimationWrapper>
    );
  }

  return <AnimatedLoader textItems={searchTextItems} />;
};

export default SearchPlaceholder;
