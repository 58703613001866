import React, { useContext } from "react";
import classes from "./CreateGroupPanel.module.css";
import {
  CustomColumn,
  CustomRow,
} from "../../../Profile/ProfileDetails/wallets/AddWallet/AddWalletComponent";
import {
  CustomButton,
  CustomText,
  PaddingComponent,
} from "../../../../UI/Components/Components";
import Image, { IMAGE_TYPES } from "../../../../UI/Image";
import withLoader from "../../../../core/withLoader";
import { getFollowersFollowingList } from "../../../../../api/profile.api";
import { QueryKeyConstants } from "../../../../../utils/constants";
import { AuthContext } from "../../../../../contextStates/AuthContext";
import { CreateGroupContext } from "./useGroupCreate";
import { Checkbox } from "@mui/material";
import { NonShrinkingTextField } from "../../../Profile/ProfileDetails/wallets/AddWallet/ConnectWallet";
import { MagnifyingGlass } from "@phosphor-icons/react";

const FollowersListRow = ({ followers }) => {
  return (
    <div>
      {followers.map((follower) => {
        return <ProfileRow key={follower.id} profile={follower} />;
      })}
    </div>
  );
};

const FollowersList = ({ queryData }) => {
  const [query, setQuery] = React.useState("");
  const followers = queryData?.data?.data || [];
  const filteredFollowers = followers
    .filter((follower) =>
      follower.display_name.toLowerCase().includes(query.toLowerCase())
    )
    .sort((a, b) => a.display_name.localeCompare(b.display_name));
  return (
    <>
      <NonShrinkingTextField
        placeholder="Search a name here"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <div style={{ marginRight: 5 }}>
              <MagnifyingGlass size={20} color="var(--text-3)" />{" "}
            </div>
          ),
        }}
      />
      <PaddingComponent minHeight="16px" />
      <CustomText
        text="People you are Following"
        textTransform={"uppercase"}
        fontSize="12px"
        fontWeight="500"
        textAlign="left"
      />
      <PaddingComponent minHeight="8px" />
      <div>
        <FollowersListRow followers={filteredFollowers} />
      </div>
    </>
  );
};

const ProfileRow = ({ profile }) => {
  const imageType = IMAGE_TYPES.AVATAR;
  const { selectedUsers, setSelectedUsers } = useContext(CreateGroupContext);
  const checked =
    selectedUsers.find((user) => user === profile.stream_chat_details?.id) !==
    undefined;
  return (
    <label>
      <CustomRow gap="8px" justifyContent="space-between">
        <CustomRow gap="8px" alignItems="center">
          <Image
            src={profile?.display_picture}
            alt={profile?.display_name}
            className={classes.profileImage}
            imageType={imageType}
            name={profile?.display_name}
          />
          <CustomText text={profile?.display_name} color="var(--text-1)" />
        </CustomRow>
        <div>
          <Checkbox
            style={{
              color: checked ? "var(--primary-color)" : "var(--elevation-3)",
            }}
            checked={checked}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedUsers([
                  ...selectedUsers,
                  profile.stream_chat_details?.id,
                ]);
              } else {
                setSelectedUsers(
                  selectedUsers.filter(
                    (item) => item !== profile.stream_chat_details?.id
                  )
                );
              }
            }}
          />
        </div>
      </CustomRow>
      <PaddingComponent minHeight="16px" />
    </label>
  );
};

const WithLoaderFollowerList = withLoader(
  FollowersList,
  getFollowersFollowingList,
  {
    queryKey: (props) => [
      QueryKeyConstants.FOLLOWER_FOLLOWING_LIST,
      props.isFollowers,
      props.identityId,
    ],
    queryOptions: () => {
      return { staleTime: 1000 * 60 * 5 };
    },
    renderNotFound: false,
  }
);

export const AddPeople = ({ setActiveTab }) => {
  const { isStep1Valid } = useContext(CreateGroupContext);
  const { identityDetails } = useContext(AuthContext);
  const identityId = identityDetails.current?.identity?.id;
  return (
    <div className={classes.formWrapper}>
      <div className={classes.form}>
        <CustomColumn>
          <CustomText
            text="Add people to your group"
            fontSize="17px"
            fontWeight="600"
            color="var(--text-1)"
            textAlign="left"
          />
          <CustomText
            text="Select people whom you wish to invite"
            fontSize="14px"
            fontWeight="400"
            textAlign="left"
          />
        </CustomColumn>
        <PaddingComponent minHeight="16px" />
        <WithLoaderFollowerList isFollowers={false} identityId={identityId} />
      </div>
      <div className={classes.footer}>
        <CustomRow>
          <CustomButton
            className={classes.nextButton}
            disabled={!isStep1Valid}
            text="Next"
            height="58px"
            fontSize="17px"
            radius="8px"
            onClick={() => {
              setActiveTab(2);
            }}
          />
        </CustomRow>
      </div>
    </div>
  );
};
