import ScreenerTable from "../ScreenerTable/ScreenerTable";
import classes from "./ScreenerResults.module.css";
import {
  PaddingComponent,
  CustomColumn,
  CustomRow,
  FlexGrow,
} from "components/UI/Components/Components";
import SelectedTag from "components/UI/Buttons/SelectedTag";
import EditButton from "components/UI/Buttons/EditButton";
import { useState } from "react";
import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import { createPortal } from "react-dom";
import ScreenerRightPanel from "../ScreenerRightPanel/ScreenerRightPanel";
import { getFilteredTags } from "utils/screener_utils";
import ScreenerQuery from "../ScreenerQuery";
import { useRef } from "react";
import { Link } from "react-router-dom";
import IconWithChain from "../IconWithChain";
import ScreenerFilter from "./ScreenerFIlter";

const ScreenerResultsPage = ({
  data,
  setData = () => {},
  queryTokens,
  filterOnlyWallets,
  setFilterOnlyWallets = () => {},
  filterKnownNames,
  setFilterKnownNames = () => {},
}) => {
  const [isOpenEditColumn, setIsOpenEditColumn] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const additionalColumnsData = useRef(data?.additional_columns ?? []);
  const { author_profile, permission } = data;

  return (
    <>
      <div className={classes.container}>
        <PaddingComponent paddingLeft="60px" paddingRight="60px">
          <CustomColumn>
            <div
              className={classes.title}>{`${data?.data?.length} Results`}</div>
            <PaddingComponent height="4px" />
            <div className={classes.subtitle}>
              Showing results generated from the following query,
            </div>
            {author_profile && permission === "READ" && (
              <div className={classes.author_profile}>
                <span className={classes.created_by}>Created By</span>
                <Link
                  to={author_profile.link}
                  target="_blank"
                  className={classes.author_link}>
                  <IconWithChain
                    src={author_profile.display_picture}
                    alt={author_profile.display_name}
                  />
                  <span>{data.author_profile.display_name}</span>
                </Link>
              </div>
            )}
            <PaddingComponent height="8px" />
            {isEditMode && (
              <div>
                <ScreenerQuery
                  source="resultsPage"
                  additionalColumnsData={additionalColumnsData.current}
                  isEditingMode={isEditMode}
                  setIsEditingMode={setIsEditMode}
                  setData={setData}
                  queryTokens={queryTokens}
                />
              </div>
            )}
            <CustomRow alignItems="flex-end" gap="8px">
              <CustomRow
                alignItems="flex-end"
                gap="8px"
                maxWidth="755px"
                flexWrap="wrap">
                {!isEditMode && (
                  <CustomRow gap="8px">
                    {getFilteredTags({
                      queryTokens: data?.query_tokens ?? [],
                    }).map((tag, index) => (
                      <div key={index}>
                        <SelectedTag isCross={false} text={tag} />
                      </div>
                    ))}
                    <EditButton
                      onEdit={() => {
                        setIsEditMode(true);
                      }}
                    />
                  </CustomRow>
                )}
              </CustomRow>
              <PaddingComponent width="8px"></PaddingComponent>
              <PaddingComponent paddingBottom="4px"></PaddingComponent>

              <PaddingComponent width="16px"></PaddingComponent>
              <FlexGrow>
                <div className={classes.headerQueryRow}>
                  <PaddingComponent paddingBottom="8px">
                    <CustomRow
                      alignItems="center"
                      justifyContent="flex-end"
                      gap="16px">
                      <ScreenerFilter
                        {...{
                          filterOnlyWallets,
                          setFilterKnownNames,
                          filterKnownNames,
                          setFilterOnlyWallets,
                        }}
                      />
                    </CustomRow>
                  </PaddingComponent>
                </div>
              </FlexGrow>
            </CustomRow>
            <PaddingComponent height="24px" />
          </CustomColumn>
        </PaddingComponent>
        {data?.data && <ScreenerTable data={data} />}
      </div>

      {createPortal(
        <RightPanel onClose={() => setIsOpenEditColumn(false)}>
          {isOpenEditColumn && (
            <ScreenerRightPanel
              setData={setData}
              additionalColumnsData={additionalColumnsData}
              setIsEditing={setIsOpenEditColumn}
              data={data}
            />
          )}
        </RightPanel>,
        document.getElementById("rightPanel")
      )}
    </>
  );
};

export default ScreenerResultsPage;
