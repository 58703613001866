import { TokenPageHeaderMini } from "../TokenPage/TokenPageHeader/TokenPageHeader";
import { Divider, PaddingComponent } from "../../UI/Components/Components";
import React, { useContext } from "react";
import withLoader from "../../core/withLoader";
import { getTrendingTokenInNetworkDetails } from "api/feed.api";
import { ONE_DAY } from "utils/constants";
import FeedItemV2 from "../../FeedItemV2/FeedItemV2";
import {
  CustomColumn,
  CustomRow,
} from "components/V2/Profile/ProfileDetails/wallets/AddWallet/AddWalletComponent";
import GhostFeedItem from "components/Feed/GhostFeedItem/GhostFeedItem";
import { CaretRight } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import ZxText from "zxComponents/common/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { ThemeContext } from "../../../contextStates/Theme.context";
import styles from "./TrendingContractDetailsPage.module.css";
import SelectableDivWithDelay from "../../SelectableDivWithDelay/SelectableDivWithDelay";
import { handleFeedItemClick } from "../../../utils/misc";

const TrendingContractsFeed = ({ queryData, chain }) => {
  const history = useHistory();
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";
  const tokenProfile = queryData.profile;
  const isToken = tokenProfile.is_token;
  let usdPrice = "";
  let changePercentage = "";
  if (isToken) {
    usdPrice = queryData.token_details?.usd_price;
    changePercentage = queryData.token_details["24h_percent_change"];
  } else {
    usdPrice = queryData.nft_details?.floor_price;
    changePercentage = queryData.nft_details["24h_percent_change"];
  }
  return (
    <div>
      <div
        style={{
          backgroundColor: "var(--base)",
          borderRadius: "8px 8px 0 0",
          marginTop: 8,
          padding: "4px 16px 16px 16px",
        }}>
        <ZxText style={TSTYLES.title2}>Interaction Details</ZxText>
        <Divider height="14px" />
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            history.push(tokenProfile.address_link);
          }}>
          <CustomRow
            margin="17px 0 0 0"
            padding="12px"
            background="var(--elevation-1)"
            borderRadius="12px"
            justifyContent="space-between"
            alignItems="center">
            <TokenPageHeaderMini
              displayImage={tokenProfile.display_picture}
              displayName={tokenProfile.display_name}
              chainId={chain}
              amount={usdPrice}
              change={changePercentage}
            />
            <CaretRight />
          </CustomRow>
        </div>
      </div>
      <PaddingComponent height="4px" />
      <CustomColumn gap="10px" className={styles.container}>
        {queryData.items.map((interaction, idx) => {
          return (
            <div
              style={{ backgroundColor: isDark ? "var(--base)" : "" }}
              key={idx}>
              <SelectableDivWithDelay
                onClick={() => {
                  handleFeedItemClick({
                    link: interaction.link,
                    history: history,
                    contentId: interaction.id,
                  });
                }}>
                <FeedItemV2
                  groupedActivity={interaction}
                  profiles={{
                    ...queryData.profiles,
                    ...(queryData.native_tokens_dict ?? {}),
                  }}
                  identities={interaction.identities}
                  actor={interaction.actor}
                  hideEngagements={true}
                  isHomePageFeedItem
                  heroProfiles={queryData.hero_profiles}
                />
              </SelectableDivWithDelay>
            </div>
          );
        })}
      </CustomColumn>
    </div>
  );
};

const TrendingContractsFeedWithLoader = withLoader(
  TrendingContractsFeed,
  (props) => {
    return getTrendingTokenInNetworkDetails(props);
  },
  {
    queryKey: (props) => [props.type, props.chain, props.address],
    queryOptions: () => {
      return {
        staleTime: ONE_DAY,
      };
    },
    showRefetch: true,
    CustomLoader: (props) => (
      <div style={{ width: 600, marginTop: 17 }}>
        <GhostFeedItem isHomePageFeedItem={true} />
        {[...Array(4).keys()].map((i) => (
          <GhostFeedItem
            isHomePageFeedItem={true}
            key={i}
            index={i}></GhostFeedItem>
        ))}
      </div>
    ),
  }
);
export default TrendingContractsFeedWithLoader;
