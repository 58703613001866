export const saveImpersonatorIdentityAndAddress = ({ identity, address }) => {
  if (!identity || !address) {
    localStorage.removeItem("impersonatorIdentity");
    localStorage.removeItem("impersonatorAddress");
    return;
  }

  localStorage.setItem("impersonatorIdentity", JSON.stringify(identity));
  localStorage.setItem("impersonatorAddress", address);
};

export const getImpersonatorIdentityAndAddress = () => {
  const identityStr = localStorage.getItem("impersonatorIdentity");
  const address = localStorage.getItem("impersonatorAddress");
  //convert json string to json and then retrun
  return {
    identity: JSON.parse(identityStr),
    address,
  };
};

export const saveImpersonateSession = ({ session }) => {
  if (!session) {
    localStorage.removeItem("impersonateSession");
    return;
  }
  localStorage.removeItem("impersonateSession");
  if (session) {
    localStorage.setItem("impersonateSession", JSON.stringify(session));
  }
};

export const getImpersonateSession = () => {
  const session = localStorage.getItem("impersonateSession");
  if (!session) return null;
  return JSON.parse(session);
};

export const getChainNamesAndIdsListFromSession = ({ chainsData }) => {
  const chainIdsList = _getChainIdsListFromSession();
  const allAvailableChainIdsAndNames = getChainIdAndNameDict({ chainsData });

  const chainNamesList = [];
  chainIdsList.map((chainId) => {
    const chainName = allAvailableChainIdsAndNames[chainId];
    if (chainName) {
      chainNamesList.push(chainName);
    }
    return null;
  });
  return chainNamesList;
};

export const _getChainIdsListFromSession = () => {
  const session = getImpersonateSession();
  if (!session) return [];
  const namespaceKey = "eip155";
  const requiredNamespace = session.requiredNamespaces[namespaceKey];
  const optionalNamespace = session.optionalNamespaces
    ? session.optionalNamespaces[namespaceKey]
    : undefined;

  let chains =
    requiredNamespace === undefined ? undefined : requiredNamespace.chains;
  if (optionalNamespace && optionalNamespace.chains) {
    if (chains) {
      // merge chains from requiredNamespace & optionalNamespace, while avoiding duplicates
      chains = Array.from(new Set(chains.concat(optionalNamespace.chains)));
    } else {
      chains = optionalNamespace.chains;
    }
  }

  //every chain item has eip155:chainId format
  //remove eip155: from every item
  const chainIdsList = [];
  chains?.map((chain) => {
    const chainId = chain.split(":")[1];
    chainIdsList.push(parseInt(chainId));
    return null;
  });

  return chainIdsList;
};

export const getChainNameAndIdDict = ({ chainsData }) => {
  const chainIdAndNameDict = {};
  Object.values(chainsData).map((item) => {
    chainIdAndNameDict[item.add_to_wallet.chainName] = item.eth_chain_id;
    return null;
  });
  return chainIdAndNameDict;
};

export const getChainIdAndNameDict = ({ chainsData }) => {
  const chainIdAndNameDict = {};
  Object.values(chainsData).map((item) => {
    chainIdAndNameDict[item.eth_chain_id] = item.add_to_wallet.chainName;
    return null;
  });
  return chainIdAndNameDict;
};

export const isValidSession = (session) => {
  try {
    if (!session) return false;
    if (
      !session.peer ||
      !session.requiredNamespaces ||
      !session.controller ||
      !session.topic
    )
      return false;
    return true;
  } catch (e) {
    return false;
  }
};
