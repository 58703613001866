import React from "react";
import { useParams } from "react-router-dom";
import { ProfileTypes } from "../../../../V2/Profile/ProfileConstants";
import ExplorerCard from "../../../../V2/Profile/ProfileHeader/ExplorerCard";
import {
  CustomRow,
  Divider,
  InfoBoxContainer,
} from "../../../../UI/Components/Components";
import { ArrowRight } from "@phosphor-icons/react";
import ZxText from "../../../../../zxComponents/common/ZxText";
import { TSTYLES } from "../../../../../zxStyles/constants";
import { CustomColumn } from "../../../../V2/Profile/ProfileDetails/wallets/AddWallet/AddWalletComponent";
import { Grid } from "../../../../V2/Profile/ProfileDetails/ProfileDetails";
import { getInviteDetails } from "../../../../../api/profile.api";
import { useHistory } from "react-router-dom";
import useMutualHoldings from "components/V2/Profile/ProfileDetails/hooks/useMutualHoldings";
import { getDefaultImage, IMAGE_TYPES } from "components/UI/Image";
import styles from "./ProfileRightCards.module.css";
import ConditionalLink from "shared/ConditionalLink";
import { Trophy } from "../../../../../../node_modules/@phosphor-icons/react/dist/index";
import { getTrimmedString } from "utils/misc";
import { useQuery } from "@tanstack/react-query";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";

export const RightBarCardContainer = ({
  title = "Title",
  titleRender = null,
  footer = "Footer",
  footerLink = null,
  containerPadding = "12px 16px",
  childrenPadding = "0 0",
  children,
  footerCallback = () => {},
}) => {
  const history = useHistory();
  return (
    <CustomColumn
      background="var(--base)"
      borderRadius="8px"
      padding={containerPadding}>
      <CustomColumn padding="0 0" justifyContent="center">
        <ZxFlex justify="space-between" align="center">
          <ZxText style={TSTYLES.title1} fontSize={15} fontWeight="600">
            {title}
          </ZxText>
          {titleRender ? titleRender() : null}
        </ZxFlex>
        <Divider margin="12px 0" />
      </CustomColumn>

      <CustomRow
        alignItems="center"
        justifyContent="center"
        padding={0}
        overflowY="visible"
        overflowX="visible">
        {children}
      </CustomRow>
      {footer && <Divider margin="0" />}
      <div
        onClick={() => {
          if (!footerLink || !footer) return;
          history.push(footerLink);
          if (footerCallback) {
            footerCallback();
          }
        }}>
        {footer && (
          <CustomRow
            padding="12px 0 0 0"
            gap="8px"
            justifyContent="center"
            alignItems="center">
            <ZxText
              style={TSTYLES.title2}
              fontSize={14}
              color="primary"
              cursor="pointer">
              {footer}
            </ZxText>{" "}
            <ArrowRight weight="bold" size={16} color="var(--primary-color)" />
          </CustomRow>
        )}
      </div>
    </CustomColumn>
  );
};
export const ExplorerRightBarCard = ({ profileType, identifier }) => {
  const isIdentityPage = profileType === "identity";
  const graphQueryKey = `graph-data-${identifier}`;
  const history = useHistory();

  return (
    <ConditionalLink to={`/explorer?identifiers=${identifier}`}>
      <RightBarCardContainer
        title="Graph Explorer"
        footer="View graph explorer"
        footerOnClick={() => {
          history.push(`/explorer?identifiers=${identifier}`);
        }}>
        <ExplorerCard
          isIdentityPage={isIdentityPage}
          graphQueryKey={graphQueryKey}
          identifier={identifier}
          showSummary={
            profileType !== ProfileTypes.COSMOS &&
            profileType !== ProfileTypes.SOLANA
          }
          title={
            profileType === ProfileTypes.COSMOS
              ? "EXPLORE VISUALLY"
              : "TOTAL VALUE TRANSFERRED"
          }
        />
      </RightBarCardContainer>
    </ConditionalLink>
  );
};

export const MutualHoldingsCard = ({ identifier, footerLink = null }) => {
  const { active_tab } = useParams();
  const { data, isLoading, isError } = useMutualHoldings({ identifier });
  if (isError || (!isLoading && (!data || data?.length === 0)) || isLoading) {
    return null;
  }
  const holdings = data?.mutual_holdings?.combined_holdings || [];
  const nfts = data?.mutual_nft_holdings?.nft_collections || [];
  const holdingsCount = holdings?.length || 0;
  const nftCount = nfts?.length || 0;
  const totalCount = holdingsCount + nftCount;
  if (totalCount === 0) return null;
  const getDisplayItems = () => {
    const sliceLength = totalCount > 4 ? 4 : totalCount;
    const allHoldings = [...holdings, ...nfts];
    return [
      ...allHoldings.slice(0, sliceLength),
      holdingsCount > 4 ? { type: "rest", count: 0 } : null,
    ];
  };
  return (
    <ConditionalLink to={footerLink}>
      <RightBarCardContainer
        title="Mutual Holdings"
        footer={null}
        footerCallback={() => {
          const isPortfolioTab = active_tab === "portfolio";
          const timer = isPortfolioTab ? 1 : 500;
          setTimeout(() => {
            window.scrollTo({
              top: 540,
              behavior: "smooth",
            });
          }, timer);
        }}
        footerLink={footerLink}>
        <CustomRow
          alignItems="center"
          justifyContent="space-between"
          overflowY="visible"
          overflowX="visible"
          padding="0 0 8px 0"
          width="100%">
          <CustomRow gap="16px" overflowY="visible" overflowX="visible">
            {getDisplayItems().map((item, index) => {
              if (!item) {
                return null;
              }
              if (item.type === "rest") {
                if (item.count === 0) return null;
                return (
                  <div className={styles.mutualHolding} key={index}>
                    <div className={styles.mutualHoldingAvatar}>
                      <ZxText color="text-2" fontSize={10}>
                        {item.count}
                      </ZxText>
                    </div>
                    <ZxText color="text-2" fontSize={10} cursor="pointer">
                      more
                    </ZxText>
                  </div>
                );
              }
              return (
                <div className={styles.mutualHolding}>
                  <IconWithChain
                    src={
                      item?.token_metadata?.logo ??
                      item?.collection_details?.image_url ??
                      getDefaultImage({
                        imageType:
                          item.type === "nft"
                            ? IMAGE_TYPES.NFT
                            : IMAGE_TYPES.TOKEN,
                      })
                    }
                    chain={item?.chain_id}
                    iconHeight="36px"
                    iconWidth="36px"
                    showImageFullHeightWidth
                    chainHeight="12px"
                    chainWidth="12px"
                  />

                  <ZxText color="text-2" fontSize={11}>
                    {getTrimmedString(
                      item.token_metadata?.symbol ??
                        item?.collection_details?.name,
                      5
                    )}
                  </ZxText>
                </div>
              );
            })}
          </CustomRow>
        </CustomRow>
      </RightBarCardContainer>
    </ConditionalLink>
  );
};

export const ReferralCard = ({ identifier }) => {
  const {
    data: queryData,
    isLoading,
    isError,
  } = useQuery({
    queryFn: () => getInviteDetails({ identifier }),
    queryKey: ["invite-details", identifier],
    enabled: !!identifier,
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const referralRank = queryData?.referral_rank || "-";
  const invitedUsersCount = queryData?.invited_users_cnt || "0";
  const networthOfInvitedUsers =
    queryData?.networth_of_invited_users?.display_value || "0";
  if (isLoading || isError) return null;
  if (!invitedUsersCount || invitedUsersCount === "0") {
    return null;
  }
  const renderTitle = (text) => {
    return (
      <div style={{ fontSize: 18, fontWeight: 500, color: "var(--text-1)" }}>
        {text}
      </div>
    );
  };
  const renderValue = (text) => {
    return (
      <div
        style={{
          fontSize: 12,
          marginTop: 4,
          fontWeight: 400,
          color: "var(--text-2)",
        }}>
        {text}
      </div>
    );
  };
  return (
    <ConditionalLink to={`/refer-friends/${identifier}`}>
      <RightBarCardContainer
        title="Referral"
        footer="View referral chart"
        footerLink={`/refer-friends/${identifier}`}>
        <Grid
          columns={["auto", "auto"]}
          rows={["auto", "auto"]}
          gap={8}
          style={{ textAlign: "center", width: "100%", paddingBottom: "16px" }}>
          <div
            style={{
              gridArea: "1/1/2/3",
              backgroundColor: "var(--elevation-2)",
              borderRadius: 12,
              padding: 24,
            }}>
            <CustomRow gap="4px" justifyContent="center" alignItems="center">
              <Trophy color="var(--text-2)" weight="duotone" size={18} />{" "}
              {renderTitle(referralRank)}
            </CustomRow>
            {renderValue("Referral Rank")}
          </div>
          <div
            style={{
              justifySelf: "stretch",
              alignSelf: "stretch",
              backgroundColor: "var(--elevation-2)",
              borderRadius: 12,
              padding: "24px 12px",
            }}>
            {renderTitle(invitedUsersCount)}
            {renderValue("People Invited")}
          </div>
          <div
            style={{
              justifySelf: "stretch",
              alignSelf: "stretch",
              backgroundColor: "var(--elevation-2)",
              borderRadius: 12,
              padding: "24px 12px",
            }}>
            {renderTitle(networthOfInvitedUsers)}
            {renderValue("Invited Networth")}
          </div>
        </Grid>
      </RightBarCardContainer>
    </ConditionalLink>
  );
};

export const SimilarProfilesCard = () => {
  return (
    <RightBarCardContainer title="Similar Profiles" footer="View more profiles">
      <InfoBoxContainer>
        <ZxText style={TSTYLES.secondaryLabel}>No similar profiles</ZxText>
      </InfoBoxContainer>
    </RightBarCardContainer>
  );
};
