import React, { Fragment } from "react";
import classes from "./galleryModal.module.css";
import { ReactComponent as IndicatorIcon } from "assets/svg/Landing/indicator.svg";
import { PaddingComponent } from "components/UI/Components/Components";
import { NFTItem } from "components/V2/Profile/ProfileDetails/NFTHoldings/NftItem";

const Thumbnails = ({ images, setCurrentImageIndex, currentImageIndex }) => {
  return (
    <div className={classes.nftItemsContainer}>
      {images.map((item, feedItemIndex) => (
        <Fragment key={`sidebar-nft-item-${item?.alt}-${feedItemIndex}`}>
          <div
            className={classes.nftItemWrapper}
            onClick={() => setCurrentImageIndex(feedItemIndex)}>
            {currentImageIndex === feedItemIndex && (
              <IndicatorIcon className={classes.indicatorIcon} />
            )}
            {/* // Hack: as fallback, get the display_image and name (NFT holdings response) */}
            <NFTItem
              key={`nft-item-${item?.alt}-${feedItemIndex}`}
              nftItem={{
                ...item,
                display_image: item?.src ?? item?.display_image,
                name: item?.alt ?? item?.name,
              }}
              isGalleryThumbnail
              rightPane
              hideSubtitle
            />
          </div>
          <PaddingComponent height="12px" />
        </Fragment>
      ))}
    </div>
  );
};

export default Thumbnails;
