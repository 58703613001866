import { WATCHLIST_CATEGORIES } from "utils/constants";

export const getRemoveWatchlistParams = (watchlistItem) => {
  const isNft = watchlistItem?.category === WATCHLIST_CATEGORIES.NFT;
  if (isNft) {
    return {
      collection_id: watchlistItem?.identifier,
    };
  }
  const params = {};
  if (watchlistItem?.token_slug) {
    params.token_slug = watchlistItem?.token_slug;
  }
  if (watchlistItem?.chain_id && watchlistItem?.token_address) {
    params.chain_id = watchlistItem?.chain_id;
    params.token_address = watchlistItem?.token_address;
  }
  return params;
};
