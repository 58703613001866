import classes from "../TransactionsRightPanel.module.css";
import { InfoBoxContainer } from "components/UI/Components/Components";
import TransactionTokenTransferTile from "./TransactionTokenTransferTile";
import withLoader from "components/core/withLoader";
import { QueryKeyConstants } from "utils/constants";
import { getTxnTokenTransfers } from "api/feed.api";
import TokenTransferGhostItems from "./TokenTransferGhostItem";
import { TransactionTokenTransferHeader } from "./TransactionTokenTransferHeader";
import EmptyStateUI from "components/ErrorBoundary/EmptyStateUI";
import { Table } from "components/UI/Table/Table";
import { ArrowsLeftRight } from "@phosphor-icons/react";

const TransactionTokenTransfers = ({ queryData, isRightPane }) => {
  const txnData = queryData?.data?.data;
  if ((txnData?.token_transfers ?? []).length === 0)
    return (
      <InfoBoxContainer paddingBottom={0}>
        <EmptyStateUI
          text="No transaction token transfers found."
          icon={
            <ArrowsLeftRight size={20} color="var(--text-2)" />
          }></EmptyStateUI>
      </InfoBoxContainer>
    );
  return (
    <div className={classes.container}>
      <Table noPaddingBottom>
        <InfoBoxContainer
          paddingBottom="0"
          header={
            <TransactionTokenTransferHeader
              tokenTransfersLength={txnData?.token_transfers?.length}
            />
          }>
          {txnData?.token_transfers?.map((tranfer, index) => (
            <TransactionTokenTransferTile
              key={`transaction-token-transfer-${index}`}
              txnData={txnData}
              index={index}
              isRightPane={isRightPane}
            />
          ))}
        </InfoBoxContainer>
      </Table>
    </div>
  );
};

export default withLoader(TransactionTokenTransfers, getTxnTokenTransfers, {
  queryKey: (props) => [
    QueryKeyConstants.TXN_TOKEN_TRANSFERS,
    props.activityId,
  ],
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  CustomLoader: TokenTransferGhostItems,
  renderNotFound: false,
});
