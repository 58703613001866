import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getTopNFTHoldings } from "api/profile.api";
import { ONE_DAY } from "utils/constants";
import { QUERY_KEYS } from "utils/query_utils";
import { useState, useCallback, useEffect } from "react";
import { getNetworthData, getNetworthGraphData } from "api/profile.api";
import { Chart } from "utils/constants";

const useTopNFTHoldings = ({ identifier }) => {
  const queryClient = useQueryClient();

  const [isNetworthCalled, setIsNetworthCalled] = useState(false);
  const query = useQuery({
    queryKey: QUERY_KEYS.TOP_NFT_HOLDINGS({ identifier }),
    queryFn: () => getTopNFTHoldings({ identifier }),
    staleTime: ONE_DAY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { isSuccess, data } = query;

  const onSuccess = useCallback(async () => {
    setIsNetworthCalled(true);
    await queryClient.fetchQuery({
      queryKey: ["networth", identifier],
      queryFn: () => getNetworthData({ identifier }),
    });
    await queryClient.fetchQuery({
      queryKey: QUERY_KEYS.NETWORTH_GRAPH({
        identity: identifier,
        duration: Chart.DURATION["7D"],
      }),
      queryFn: () =>
        getNetworthGraphData({
          identifier,
          duration: Chart.DURATION_DAYS[Chart.DURATION["7D"]],
        }),
    });
  }, [queryClient, identifier]);

  useEffect(() => {
    if (isSuccess && !isNetworthCalled) {
      onSuccess(data);
    }
  }, [isSuccess, data, onSuccess, isNetworthCalled]);
  return query;
};

export default useTopNFTHoldings;
