import Comments from "components/Feed/GroupedActivityDetails/Comments";
import React from "react";
import { transformGroupedActivityQuery } from "utils/misc";

const Discussion = ({ id, type, engagements, profiles, identities }) => {
  return (
    <Comments
      comments={engagements}
      contentId={id}
      type={type}
      identities={identities}
      profiles={profiles}
      queryKeys={[transformGroupedActivityQuery(id, type, "", false)]}
    />
  );
};

export default Discussion;
