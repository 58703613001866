import {
  CustomRow as OldCustomRow,
  CustomColumn as OldCustomColumn,
} from "../../../../../UI/Components/Components";
import styled from "styled-components/macro";

export const CustomRow = styled(OldCustomRow)`
    overflow:visible;
`

export const CustomColumn = styled(OldCustomColumn)`
    overflow:visible;
`

export const FlexGrow = styled.div`
    flex-grow: 1;
    min-height: 0;
`;