import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../../../../../contextStates/Global";
import { ProfileContext } from "../../../../../../contextStates/ProfileContext";

export const useWalletCreate = ({
  bundleData,
  refetch,
  loggedInUserDetails,
  walletAddress,
  editMode = false,
}) => {
  const nameRef = useRef(null);
  const addressRef = useRef(null);
  const defaultValues = {
    walletType: null,
    connectionType: null,
    address: "",
    name: "",
    selectedBundles: [],
    session: null,
  };
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const { isLoggedInUserProfile } = useContext(ProfileContext);
  const wallet = bundleData?.bundle?.wallets?.find(
    (item) => item.address === walletAddress
  );
  const publicVerifiedWallets =
    bundleData?.bundle?.wallets?.filter(
      (item) => !item.is_private && item.is_verified
    ) || [];
  const isWalletAlreadyInBundle = (address) => {
    return bundleData?.bundle?.wallets?.some(
      (item) => item.address === address
    );
  };
  if (wallet) {
    defaultValues.walletType = wallet.is_private ? "private" : "public";
    defaultValues.address = wallet.address;
    defaultValues.name = wallet.reason;
    defaultValues.connectionType = "connected";
    defaultValues.selectedBundles = wallet.group_ids
      ? [...wallet.group_ids]
      : [];
  } else if (!isLoggedInUserProfile) {
    defaultValues.walletType = "public";
    defaultValues.connectionType = "manual";
  }
  const [walletType, setWalletType] = useState(defaultValues.walletType);
  const [connectionType, setConnectionType] = useState(
    defaultValues.connectionType
  );
  const [address, setAddress] = useState(defaultValues.address);
  const [name, setName] = useState(defaultValues.name);
  const [session, setSession] = useState(defaultValues.session);
  const [selectedBundles, setSelectedBundles] = useState(
    defaultValues.selectedBundles
  );

  const isStep1Valid = walletType !== null;
  let isStep2Valid = isStep1Valid && connectionType !== null;
  if (isStep2Valid) {
    isStep2Valid = address !== "" && name !== "";
  }
  const isStep3Valid = isStep2Valid;
  const setWalletDetails = (wallet) => {
    setWalletType(wallet.type);
    setAddress(wallet.address);
    setName(wallet.reason);
  };
  useEffect(() => {
    if (!bundleData || !bundleData.groups || editMode) return;
    let bundleId = null;
    let latestTime = -1;
    Object.keys(bundleData?.groups || {}).forEach((key) => {
      const group = bundleData?.groups?.[key];
      const date = new Date(group.created_at).getTime();
      if (date > latestTime && group.group_name !== "0xPPL Wallet") {
        latestTime = date;
        bundleId = group.id;
      }
    });
    if (bundleId) {
      setSelectedBundles([bundleId]);
    }
  }, [bundleData, editMode]);
  return {
    nameRef,
    addressRef,
    isWalletAlreadyInBundle,
    walletType,
    setWalletType: (type) => {
      const isEditingOnlyPublicWalletToPrivate =
        wallet &&
        publicVerifiedWallets.length === 1 &&
        publicVerifiedWallets[0].address === wallet.address &&
        type === "private";
      if (isEditingOnlyPublicWalletToPrivate) {
        handleErrorSnackbar(
          new Error("At least one public verified wallet is required.")
        );
      } else {
        setWalletType(type);
      }
    },
    connectionType,
    setConnectionType,
    address,
    setAddress,
    name,
    setName,
    bundleData,
    refetch,
    loggedInUserDetails,
    selectedBundles,
    setSelectedBundles,
    isStep1Valid,
    isStep2Valid,
    isStep3Valid,
    session,
    setSession,
    wallet,
    setWalletDetails,
    isLoggedInUserProfile,
  };
};
