import React, { useState } from "react";
import { CalendarDots as CalendarIcon, XCircle } from "@phosphor-icons/react";
import Month from "./Month";
import { ClickAwayListener } from "@mui/material";

const Container = ({ children, cursor = "default" }) => {
  return (
    <div
      style={{
        backgroundColor: "var(--background)",
        display: "flex",
        margin: "12px 0",
        padding: 8,
        borderRadius: 8,
        fontSize: 14,
        gap: 4,
        alignItems: "center",
        cursor,
      }}>
      {children}
    </div>
  );
};

const formatDate = (date) => {
  // Format date as Aug 12;
  return `${date.toLocaleString("default", { month: "short" })} ${date.getDate()}`;
};
export const DateRangeSelector = ({ onDateRangeChange }) => {
  const [dateRange, setDateRange] = useState();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const onRangeSelect = (dateRange) => {
    setDateRange(dateRange);
    setIsCalendarOpen(false);
    onDateRangeChange(dateRange);
  };
  if (!isCalendarOpen) {
    return (
      <Container cursor="pointer">
        <div
          style={{
            display: "flex",
            gap: 4,
            alignItems: "center",
            color: "var(--text-2)",
          }}
          onClick={() => {
            setIsCalendarOpen(true);
          }}>
          <CalendarIcon size={18} color="var(--text-2)" />
          {dateRange && dateRange.start && (
            <>
              {formatDate(dateRange.start)} -{" "}
              {formatDate(dateRange.end || dateRange.start)}
            </>
          )}
        </div>

        {dateRange && (
          <XCircle
            weight="fill"
            color="var(--text-2)"
            size={18}
            onClick={() => {
              onRangeSelect(null);
            }}
          />
        )}
      </Container>
    );
  }

  return (
    <ClickAwayListener
      style={{ backgroundColor: "red" }}
      onClickAway={() => {
        setIsCalendarOpen(false);
      }}>
      <div style={{ position: "relative", zIndex: 10 }}>
        <>
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              cursor: "pointer",
            }}>
            {isCalendarOpen && (
              <Month
                onChange={onRangeSelect}
                initialDateRange={dateRange}
                color="var(--text-2)"
              />
            )}
          </div>
        </>
      </div>
    </ClickAwayListener>
  );
};
