import React, { useState } from "react";
import classes from "./Reactions.module.css";
import { InteractionButton } from "./ActivityEngagements";
import { reactionData } from "./ReactionData";
import { useContext } from "react";
import { AuthContext } from "contextStates/AuthContext";

const Reactions = ({ handleReactionClick }) => {
  return (
    <div className={classes.reactionContainer}>
      {reactionData
        .sort((a, b) => a.order - b.order)
        .map((reaction) => (
          <div
            onClick={(e) => handleReactionClick(e, reaction.id)}
            key={reaction.id}
            className={classes.reactionItem}>
            <div className={classes.reactionIcon}>
              <div className={classes.normal}>{reaction.icon}</div>
              <div className={classes.animated}>{reaction.animatedIcon}</div>
            </div>
            <div className={classes.tooltip}>{reaction.title}</div>
          </div>
        ))}
    </div>
  );
};

export const ShowReactions = ({ count, emojiIds, size = "medium" }) => {
  const reactionFreq = {};
  emojiIds?.forEach((item) => {
    const reaction =
      reactionData.find((r) => parseInt(r.id) === parseInt(item?.emoji_id)) ||
      reactionData[0];
    reactionFreq[reaction?.id] = (reactionFreq[reaction?.id] ?? 0) + 1;
  });

  return (
    <div
      className={`${classes.postedReactionContainer} ${size === "small" && classes.smallReactions}`}>
      {Object.keys(reactionFreq).map((reactionId, index) => (
        <div key={reactionId} className={classes.postedReactionIcon}>
          {reactionData.find((r) => parseInt(r.id) === parseInt(reactionId))
            ?.icon || reactionData[0].icon}
        </div>
      ))}
      {count > 0 && (
        <div
          style={{
            marginLeft: "4px",
            color: "var(--text-2)",
            fontWeight: "500",
          }}>
          {count}
        </div>
      )}
    </div>
  );
};

const ReactionButton = ({
  className,
  handleClick,
  hideCount = false,
  icon,
  type,
  count,
  needsLogin,
  hasRoundedButtons,
  setEmojiId,
  emojiId,
  hideLikeLabel,
  hideReactions = false,
}) => {
  const [showReactionsPopUp, setShowReactionsPopUp] = useState(false);
  const { isUserLoggedIn, setShowLoginPopup } = useContext(AuthContext);
  const handleMouseEnter = () => {
    setShowReactionsPopUp(true);
  };

  const handleMouseLeave = () => {
    setShowReactionsPopUp(false);
  };

  const handleReactionClick = (e, id) => {
    if (needsLogin && !isUserLoggedIn) {
      setShowLoginPopup(true);
      e.stopPropagation();
      return;
    }
    setEmojiId(id);
    setShowReactionsPopUp(false);
    handleClick(e, id);
  };

  const handleLikeClick = (e) => {
    if (emojiId === null) {
      setEmojiId(1);
    } else {
      setEmojiId(null);
    }
    handleClick(e, emojiId === null ? "1" : null);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {showReactionsPopUp && !hideReactions && (
        <div className={classes.reactionWrapper} onClick={handleLikeClick}>
          <Reactions handleReactionClick={handleReactionClick} />
        </div>
      )}
      <div className={classes.btnWrapper}>
        <InteractionButton
          className={className}
          handleClick={handleLikeClick}
          hideCount={hideCount}
          icon={
            emojiId === null
              ? icon
              : reactionData.find((r) => parseInt(r.id) === parseInt(emojiId))
                  ?.icon || reactionData[0].icon
          }
          text={
            hideLikeLabel
              ? null
              : emojiId === null
                ? "Like"
                : reactionData.find((r) => parseInt(r.id) === parseInt(emojiId))
                    ?.title || "Like"
          }
          activeText={!!emojiId}
          count={count}
          type={type}
          needsLogin={needsLogin}
          hasRoundedButtons={hasRoundedButtons}
        />
      </div>
    </div>
  );
};

export default ReactionButton;
