import { useCallback, useRef, useContext } from "react";
import { X } from "@phosphor-icons/react";
import PostTextEditor from "./Components/PostTextEditor";
import LinkPreviews from "../LinkPreviews";
import classes from "./PostCommentRenderer.module.css";
import {
  CursorDiv,
  CustomRow,
  FlexGrow,
  PaddingComponent,
} from "components/UI/Components/Components";
import { AuthContext } from "contextStates/AuthContext";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import {
  SUPPORTED_IMAGE_FORMATS,
  getFileTypeFromDataString,
} from "./NewPost.utils";

const PostCommentTextEditor = ({
  index,
  fieldName,
  watch,
  errors,
  field,
  setEmojiPlugins,
  setCurrentThreadIndex,
  previewImages,
  setPreviewImages,
  isFormExpanded,
  remove,
  fields,
  isSuccess,
  placeholder,
  isSubmitDisabled,
  showNewPostIcon,
  setRawLinks,
  urls,
  trigger,
  setEditorRawContent,
  handleOnFocus = () => {},
  emojiPlugin,
  onSubmit,
  initialRawContent,
  nodeRef,
}) => {
  // states
  const editorRef = useRef(null);
  const postTextEditorRef = useRef();

  const removePreviewImage = useCallback(
    (indexToRemove) => {
      const newImages = previewImages[index].filter(
        (_, index) => index !== indexToRemove
      );
      const newPreviewImages = [...previewImages];
      newPreviewImages[index] = newImages;
      setPreviewImages(newPreviewImages);
    },
    [previewImages, setPreviewImages, index]
  );

  const postContent = watch?.(fieldName);

  const handleEditorFocus = useCallback(() => {
    if (
      editorRef.current != null &&
      document.activeElement !== editorRef.current
    ) {
      editorRef.current.focus();
      handleOnFocus?.();
      //set the index for the list on which focus is there
      setCurrentThreadIndex(index);
    }
  }, [handleOnFocus, setCurrentThreadIndex, index]);

  return (
    <>
      <div onClick={handleEditorFocus} ref={nodeRef}>
        <div className={classes.textBoxWrapper}>
          <CustomRow gap="16px" alignItems="flex-start">
            {isFormExpanded && showNewPostIcon && <UserAvatar />}
            <FlexGrow>
              <PostTextEditor
                onFocus={() => {
                  setCurrentThreadIndex(index);
                }}
                isFormExpanded={isFormExpanded}
                setPostContent={(content, rawContent) => {
                  field.onChange(content);
                  setEditorRawContent((prev) => {
                    let newEditorRawContent = [...prev];
                    newEditorRawContent[index] = rawContent;
                    return newEditorRawContent;
                  });
                  // trigger validation after change
                  trigger(`threads.${index}.name`);
                }}
                setRawLinks={setRawLinks}
                index={index}
                postContent={postContent}
                ref={postTextEditorRef}
                editorRef={editorRef}
                placeholder={placeholder}
                initialContent={field.value}
                setEditorRawContent={setEditorRawContent}
                isSubmitDisabled={isSubmitDisabled}
                emojiPlugin={emojiPlugin}
                onSubmit={onSubmit}
                initialRawContent={initialRawContent}
                updatePreviewImages={(imageData) => {
                  setPreviewImages((prev) => {
                    const newPreviewImages = [...prev];
                    const currentItemImages = newPreviewImages[index] ?? [];
                    const newCurrentImages = [...currentItemImages, imageData];
                    newPreviewImages[index] = newCurrentImages;
                    return newPreviewImages;
                  });
                }}
                isSuccess={isSuccess}
              />
            </FlexGrow>
            {isFormExpanded && fields.length > 1 && (
              <RemoveThread
                index={index}
                remove={remove}
                setRawLinks={setRawLinks}
                setEmojiPlugins={setEmojiPlugins}
                setPreviewImages={setPreviewImages}
                setEditorRawContent={setEditorRawContent}
                setCurrentThreadIndex={setCurrentThreadIndex}
              />
            )}
          </CustomRow>
        </div>
        {isFormExpanded && (
          <PreviewsImagesErrors
            index={index}
            errors={errors}
            previewImages={previewImages[index]}
            removePreviewImage={removePreviewImage}
            urls={urls[index]}
          />
        )}
      </div>
    </>
  );
};

const RemoveThread = ({
  index,
  remove,
  setPreviewImages,
  setRawLinks,
  setEditorRawContent,
  setEmojiPlugins,
  setCurrentThreadIndex,
}) => {
  const removeThread = useCallback(() => {
    remove(index);
    setCurrentThreadIndex((prev) => {
      if (prev === 0) return 0;
      return prev - 1;
    });
    setPreviewImages((prev) => {
      const newPreviewImages = [...prev];
      newPreviewImages.splice(index, 1);
      return newPreviewImages;
    });
    setRawLinks((prev) => {
      const newRawLinks = [...prev];
      newRawLinks.splice(index, 1);
      return newRawLinks;
    });
    setEditorRawContent((prev) => {
      const newEditorRawContent = [...prev];
      newEditorRawContent.splice(index, 1);
      return newEditorRawContent;
    });
    setEmojiPlugins((prev) => {
      const newEmojiPlugins = [...prev];
      newEmojiPlugins.splice(index, 1);
      return newEmojiPlugins;
    });
  }, [
    index,
    remove,
    setPreviewImages,
    setRawLinks,
    setEditorRawContent,
    setEmojiPlugins,
    setCurrentThreadIndex,
  ]);
  return (
    <CursorDiv
      onClick={(e) => {
        removeThread(e);
        e.stopPropagation();
      }}>
      <X size={20} color="var(--text-2)" />
    </CursorDiv>
  );
};

const UserAvatar = () => {
  const { identityDetails } = useContext(AuthContext);
  return (
    <Image
      className={classes.userAvatar}
      imageType={IMAGE_TYPES.AVATAR}
      name={identityDetails?.current?.identity?.display_name}
      src={identityDetails?.current?.identity?.display_picture}
      alt={identityDetails?.current?.identity?.display_name}
    />
  );
};

const PreviewsImagesErrors = ({
  errors,
  previewImages,
  removePreviewImage,
  urls,
  index,
}) => {
  return (
    <PaddingComponent paddingTop="8px">
      {errors.threads && errors.threads[index]?.name && (
        <p className={classes.errorText}>
          {errors.threads[index].name.message}
        </p>
      )}
      {errors.threads && errors.threads[index]?.farcaster && (
        <p className={classes.errorText}>
          {errors.threads[index].farcaster.message}
        </p>
      )}
      {errors.threads && errors.threads[index]?.unsupported_format && (
        <p className={classes.errorText}>
          {errors.threads[index].unsupported_format.message}
        </p>
      )}
      <LinkPreviews urls={urls} />
      {previewImages?.length > 0 && (
        <div className={classes.imagePreviewWrapperParent}>
          {previewImages.map(({ result, file }, index) => {
            const type = file?.type ?? getFileTypeFromDataString(result);
            const supportedFormat = SUPPORTED_IMAGE_FORMATS.includes(type);
            return (
              <div
                key={index}
                className={`${classes.imagePreviewWrapper} ${
                  !supportedFormat ? classes.errorImage : ``
                }`}>
                <img
                  src={result}
                  alt="Preview"
                  className={classes.imagePreview}
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    removePreviewImage(index);
                  }}
                  className={classes.removeButton}>
                  <X size={11} />
                </button>
              </div>
            );
          })}
        </div>
      )}
    </PaddingComponent>
  );
};

export default PostCommentTextEditor;
