import { useState, useRef } from "react";
import { MenuItem, Menu } from "@mui/material";
import { CustomRow, CustomText } from "components/UI/Components/Components";
import { IconButton } from "@mui/material";
import {
  DotsThreeVertical,
  BellSlash,
  Bell,
  Info,
  XCircle,
  Circle,
  SignOut,
} from "@phosphor-icons/react";
import { blockUser, unBlockUser } from "api/messaging.api";
import { useChatContext } from "stream-chat-react";
import { useHistory } from "react-router-dom";
import { trackEvent } from "utils/event_tracking";
import { ChannelTypes } from "../../helpers";

const ChannelMenu = ({ channel, openGroupInfoModal }) => {
  const { client } = useChatContext();
  const history = useHistory();
  const anchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(null);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const type = channel?.data?.channel_type;
  const isMuted = channel.muteStatus()?.muted;
  const dmUser = Object.values(channel.state?.members || {}).find(
    (member) => member.user?.id !== client.user?.id
  );
  const isBlocked = dmUser?.banned;

  const menuColor = "var(--text-1)";
  const items = [
    ...(type === ChannelTypes.GROUP
      ? [
          {
            label: <CustomText text="Group Info" color={menuColor} />,
            icon: <Info color={menuColor} />,
            onClick: () => {
              openGroupInfoModal();
              handleMenuClose();
            },
          },
        ]
      : []),
    {
      label: (
        <CustomText
          text={isMuted ? "Unmute Notifications" : "Mute Notifications"}
          color={menuColor}
        />
      ),
      icon: isMuted ? (
        <Bell color={menuColor} />
      ) : (
        <BellSlash color={menuColor} />
      ),
      onClick: async () => {
        handleMenuClose();
        if (isMuted) {
          await channel.unmute();
        } else {
          await channel.mute();
        }
      },
    },
    ...(type === ChannelTypes.DM
      ? [
          {
            label: (
              <CustomText
                text={isBlocked ? "Unblock User" : "Block User"}
                color={menuColor}
              />
            ),
            icon: isBlocked ? (
              <Circle color={menuColor} />
            ) : (
              <XCircle color={menuColor} />
            ),
            onClick: async () => {
              handleMenuClose();
              if (isBlocked) {
                trackEvent("unblock_user");
                await unBlockUser({
                  groupId: channel.id,
                  userId: dmUser?.user_id.replace("sc-identity-", ""),
                });
                await channel.show();
                history.push("/messages/all/" + channel.id);
              } else {
                trackEvent("block_user");
                await blockUser({
                  groupId: channel.id,
                  userId: dmUser?.user_id.replace("sc-identity-", ""),
                });
                await channel.markRead();
                await channel.hide();
              }
            },
          },
        ]
      : []),
    ...(type === ChannelTypes.GROUP
      ? [
          {
            label: <CustomText text="Exit Group" color={menuColor} />,
            icon: <SignOut color={menuColor} />,
            onClick: () => {
              handleMenuClose();
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <IconButton ref={anchorRef} size="small" onClick={handleMenuOpen}>
        <DotsThreeVertical size={20} color="var(--text-1)" />
      </IconButton>
      <Menu
        sx={{
          "& .MuiMenu-paper": {
            backgroundColor: "var(--navBg)",
          },
          "& .MuiMenuItem-root:hover": {
            backgroundColor: "var(--primary-color10)",
          },
        }}
        anchorEl={anchorRef?.current}
        open={menuOpen}
        onClose={handleMenuClose}
        disableScrollLock={true}
        dense>
        {items.map((item, index) => (
          <MenuItem
            sx={{}}
            key={index}
            onClick={item.onClick}
            disabled={item.disabled}>
            <CustomRow gap="8px" alignItems="center">
              {item.icon}
              {item.label}
            </CustomRow>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ChannelMenu;
