import React, { useContext, useEffect } from "react";
import styles from "./ZxUserTokenGroup.module.scss";
import { useQuery } from "@tanstack/react-query";
import { QueryKeyConstants } from "utils/constants";
import {
  CustomColumn,
  CustomRow,
  PriceChange,
} from "components/UI/Components/Components";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { ThemeContext } from "contextStates/Theme.context";
import withNullableError from "zxComponents/common/withNullableError/index";
import ZxUserTokenAttribution from "./ZxUserTokenAttribution";
import { getPnlPercentForVal } from "components/FeedItemV2/PnlTokenList/PnlTokenList";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
const KEY_LIST = [
  {
    key: "usd_value_total_buy",
    label: "Total bought",
    render: ({ symbol }) => (symbol ? `BOUGHT ${symbol}` : "Total bought"),
  },
  {
    key: "usd_value_total_sold",
    label: "Total sold",
    render: ({ symbol }) => (symbol ? `Sold ${symbol}` : "Total sold"),
  },
  {
    type: "pnl",
    key: "closed_pnl",
    pnlPercentKey: "closed_pnl_percent",
    label: "Closed PNL",
  },
  {
    key: "total_usd_value",
    label: "Current value / PNL",
    pnlPercentKey: "open_pnl",
    type: "pnl",
  },
];
const ZxUserTokenGroup = ({
  groupedActivity,
  profiles,
  setShowPnlComments = () => {},
  setCombinedPnlPercentage = () => {},
  selectedToken = null,
  showAttribution = true,
  overrideLogo = null,
}) => {
  const { data: slowItem } = useQuery({
    queryKey: [QueryKeyConstants.SLOW_FEED_ITEM, groupedActivity?.blob_id],
    queryFn: () => {},
    enabled: false,
  });

  console.log("ZxUserTokenGroup.js: ZxUserTokenGroup");
  const pnlItem = slowItem ?? groupedActivity;
  const { user_token_pnl } = pnlItem ?? {};
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    const tokenPnl = selectedToken ?? user_token_pnl?.[0] ?? null;
    if (!tokenPnl) return;
    const totalPnl =
      (tokenPnl.open_pnl?.value || 0) + (tokenPnl.closed_pnl?.value || 0);
    if (totalPnl !== 0) {
      setShowPnlComments(true);
      setCombinedPnlPercentage(getPnlPercentForVal(totalPnl));
    }
  }, [
    setShowPnlComments,
    setCombinedPnlPercentage,
    user_token_pnl,
    selectedToken,
  ]);
  if (!user_token_pnl && !selectedToken) return null;
  if (user_token_pnl?.length === 0 && !selectedToken) return null;
  const tokenPnl = selectedToken ?? user_token_pnl[0];

  const isLoss =
    (tokenPnl.open_pnl?.value ?? 0) + (tokenPnl.closed_pnl?.value ?? 0) < 0;
  const isDark = theme === "dark";
  const classTheme = isLoss
    ? isDark
      ? styles["loss_dark"]
      : styles.loss_light
    : isDark
      ? styles.profit_dark
      : styles.profit_light;
  const hasValue = tokenPnl?.total_usd_value?.value;
  const hasClosedPnl = tokenPnl?.closed_pnl?.value;
  const hasOpenPnl = tokenPnl?.open_pnl?.value;
  const tokenProfile =
    profiles?.[tokenPnl?.token_address ?? tokenPnl?.contract_address];
  if (!hasValue && !hasClosedPnl && !hasOpenPnl) return null;
  const renderList = () => {
    return KEY_LIST.map((item, idx) => {
      const isFirst = idx === 0;
      const dv = tokenPnl[item.key]?.display_value;
      const val = tokenPnl[item.key]?.value;
      const isValueCloseToZero = Math.abs(val) < 1;
      const isDollarValue = dv?.includes("$");
      if (!dv || val === 0) return null;
      const hasPercent =
        item.type === "pnl" &&
        tokenPnl[item.pnlPercentKey] &&
        tokenPnl[item.pnlPercentKey].display_value;
      const perc = tokenPnl[item.pnlPercentKey];
      const symbol = tokenProfile?.symbol ?? null;
      return (
        <ZxFlex gap="12px">
          {isFirst && (
            <Image
              src={
                tokenProfile?.token_details?.logo ??
                tokenProfile?.display_picture ??
                selectedToken?.token_metadata?.display_picture ??
                overrideLogo
              }
              imageType={IMAGE_TYPES.TOKEN}
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "8px",
              }}
            />
          )}
          <CustomColumn
            overflowY="visible"
            overflowX="visible"
            gap="2px"
            key={idx}>
            <ZxText style={TSTYLES.secondaryLabel}>
              {item?.render
                ? item?.render({ symbol })?.toUpperCase()
                : item.label.toUpperCase()}
            </ZxText>
            <CustomRow overflowX="visible" overflowY="visible" gap="4px">
              <ZxText style={TSTYLES.bodyLabel}>
                {isValueCloseToZero && isDollarValue ? "~$0" : dv}
              </ZxText>
              {hasPercent &&
                perc &&
                perc?.display_value &&
                Math.abs(perc?.value) > 1 && (
                  <PriceChange
                    value={perc?.value}
                    displayValue={perc?.display_value}
                    fontWeight="500"
                  />
                )}
            </CustomRow>
          </CustomColumn>
        </ZxFlex>
      );
    });
  };
  return (
    <CustomColumn gap="8px" width="100%">
      <div className={`${styles.container} ${classTheme}`}>{renderList()}</div>
      {showAttribution && pnlItem.attribution_category && (
        <ZxUserTokenAttribution
          profiles={profiles}
          groupedActivity={pnlItem}
          showDivider={false}
        />
      )}
    </CustomColumn>
  );
};

export default withNullableError(ZxUserTokenGroup);
