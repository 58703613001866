import { getNodeTransfers } from "api/graph.api";
import classes from "./SelectedNodeTransfers.module.css";
import {
  TableProfile,
  Value,
  ValueStyle,
  HeaderStyle,
  RowStyle,
  NFT,
} from "./SelectedEdge";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect, useMemo, useState } from "react";
import {
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import ExplorerRowsFilter from "./Filters/ExplorerRowsFilter";
import TokenTransferGhostItems, {
  TokenTransferPageGhostItems,
} from "../ActivityDetails/Transactions/TokenTransfers/TokenTransferGhostItem";
import { useInfiniteQuery } from "@tanstack/react-query";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import { useInView } from "react-intersection-observer";

const SelectedNodeTransfers = (props) => {
  const [ref, inView] = useInView();
  const {
    identifier,
    setHoveredEdge = () => {},
    isRightPane = false,
    setTokenTransfersCount = () => {},
    setInitialTokenTransfer = () => {},
    setAllTokenTransfers = () => {},
    maxHeight = "auto",
    onNewData = () => {},
    isGraph = false,
  } = props;
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        "nodeTransfers",
        props.identifier,
        props.startTime === props.sliderStart ? null : props.startTime,
        props.endTime === props.sliderEnd ? null : props.endTime,
        isGraph ? props?.tokenTuples : props?.selectedActiveChains?.join(","),
        isGraph ? props.filterChains : props?.selectedActiveTokens?.join(","),
      ],
      queryFn: (pageParam) => getNodeTransfers(props, pageParam),
      initialPageParam: 0,
      getNextPageParam: (lastPage, pages) => {
        if (!pages || !lastPage) return null;
        return lastPage?.data?.timestamp_cursor
          ? lastPage?.data?.timestamp_cursor - 1
          : null;
      },
      enabled: !!props.identifier,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });
  let profiles_by_addr = {};
  let token_metadata = {};
  const allRows =
    data?.pages
      ?.flatMap((rp) => {
        const page = rp.data;
        if (page) {
          profiles_by_addr = { ...profiles_by_addr, ...page.profiles_by_addr };
          token_metadata = { ...token_metadata, ...page.token_metadata };
          return page.transfers;
        }
        return null;
      })
      .filter((r) => r) || [];

  const transfers = allRows;

  const queryData = data?.pages[0] || {};
  const [selectedFromAddresses, setSelectedFromAddresses] = useState([]);
  const [selectedToAddresses, setSelectedToAddresses] = useState([]);
  const actor = identifier;

  useEffect(() => {
    if (!!transfers) {
      setTokenTransfersCount(transfers?.length);
      setInitialTokenTransfer(transfers?.[transfers?.length - 1]);
      setAllTokenTransfers?.(queryData?.data);
      onNewData(queryData?.data);
    }
  }, [
    setInitialTokenTransfer,
    setTokenTransfersCount,
    setAllTokenTransfers,
    transfers,
    queryData?.data,
    onNewData,
  ]);

  const distinctFromAddresses = useMemo(
    () =>
      new Set(
        transfers
          ?.map((transfer) => transfer?.from_address)
          .filter((nullOrAddress) => nullOrAddress !== null) ?? []
      ),
    [transfers]
  );
  const distinctToAddresses = useMemo(
    () =>
      new Set(
        transfers
          ?.map((transfer) => transfer?.to_address)
          .filter((nullOrAddress) => nullOrAddress !== null)
      ),
    [transfers]
  );
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage && transfers?.length > 0) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, transfers, fetchNextPage]);
  if (!data) {
    return <TokenTransferGhostItems />;
  }
  return (
    <div
      className={`${classes.selected_node_transfers} ${
        isRightPane ? classes.rightPane : ""
      }`}>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          paddingBottom: "12px",
          paddingRight: "4px",
          backgroundColor: "var(--base)",
          maxHeight,
          position: "relative",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          " &::-webkit-scrollbar-thumb": {
            background: "#dbdee1",
            borderRadius: "100px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "var(--text-3)",
          },
        }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "1px solid var(--text-2-10)",
              }}>
              <TableCell sx={{ ...HeaderStyle }}>
                <CustomRow alignItems="flex-start">
                  <span>From</span>
                  <PaddingComponent width="8px" />
                  <ExplorerRowsFilter
                    addresses={distinctFromAddresses}
                    selected={selectedFromAddresses}
                    setSelected={setSelectedFromAddresses}
                    profiles={profiles_by_addr}
                  />
                </CustomRow>
              </TableCell>
              <TableCell align="left" sx={{ ...HeaderStyle }}>
                <CustomRow alignItems="flex-start">
                  <span>To</span>
                  <PaddingComponent width="8px" />
                  <ExplorerRowsFilter
                    addresses={distinctToAddresses}
                    selected={selectedToAddresses}
                    setSelected={setSelectedToAddresses}
                    profiles={profiles_by_addr}
                  />
                </CustomRow>
              </TableCell>
              <TableCell align="right" sx={{ ...HeaderStyle }}>
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transfers?.map((row, index) => {
              return (
                <TransferRow
                  key={index}
                  row={row}
                  selectedFromAddresses={selectedFromAddresses}
                  selectedToAddresses={selectedToAddresses}
                  profiles_by_addr={profiles_by_addr}
                  setHoveredEdge={setHoveredEdge}
                  token_metadata={token_metadata}
                  actor={actor}
                />
              );
            })}
            {!isFetching && transfers?.length > 0 && hasNextPage && (
              <div
                ref={ref}
                style={{
                  height: "300px",
                  width: "0px",
                  background: "rgba(0,0,0,0)",
                  position: "absolute",
                  bottom: 0,
                  zIndex: 2,
                }}
              />
            )}
          </TableBody>
        </Table>

        {isFetchingNextPage && <TokenTransferPageGhostItems />}
      </TableContainer>
    </div>
  );
};

const TransferRow = React.memo(
  ({
    selectedFromAddresses,
    selectedToAddresses,
    row,
    profiles_by_addr,
    setHoveredEdge,
    token_metadata,
    actor,
  }) => {
    if (!row) return null;
    if (
      selectedFromAddresses?.length > 0 &&
      !selectedFromAddresses?.includes(row?.from_address)
    ) {
      return null;
    }
    if (
      selectedToAddresses?.length > 0 &&
      !selectedToAddresses?.includes(row?.to_address)
    ) {
      return null;
    }
    return (
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          border: 0,
          ...RowStyle,
          cursor: "pointer",
        }}
        onClick={() => {
          const isSolana = row.chain_str?.toLowerCase() === "solana";
          if (isSolana) {
            window.open(`/tx/${row.tx_signature}`);
            return;
          }
          window.open(
            `/tx_id?chain_id=${row.chain_str}&tx_idx=${row.tx_idx}&actor=${actor}&block_number=${row.block_number}`,
            "_blank"
          );
        }}
        onMouseEnter={() => {
          setHoveredEdge(row);
        }}
        onMouseLeave={() => {
          setHoveredEdge(null);
        }}>
        <TableCell component="th" scope="row" sx={{ ...ValueStyle }}>
          <TableProfile
            profile={profiles_by_addr[row?.from_address]}
            address={row?.from_address}
          />
        </TableCell>
        <TableCell align="left" sx={{ ...ValueStyle }}>
          <TableProfile
            profile={profiles_by_addr[row.to_address]}
            address={row.to_address}
          />
        </TableCell>
        <TableCell align="right" sx={{ ...ValueStyle }}>
          {row.token_id ? (
            <NFT transaction={row} metadata={token_metadata} />
          ) : (
            <Value transaction={row} metadata={token_metadata} />
          )}
        </TableCell>
      </TableRow>
    );
  }
);

export default withErrorBoundary(SelectedNodeTransfers);
