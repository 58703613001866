import React, { useRef, useState } from "react";
import TokenDescription from "../TokenDescription/TokenDescription";
import TokenDetails from "../TokenDetails/TokenDetails";
import TokenFlags from "../TokenFlags/TokenFlags";
import {
  CustomColumn,
  PaddingComponent,
} from "components/UI/Components/Components";
import EmptyStateUI from "components/ErrorBoundary/EmptyStateUI";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import TradingViewAttribution from "zxComponents/common/TradingViewAttribution/TradingViewAttribution";

const TokenPageRightBar = ({
  contractDetails,
  tokenDetails,
  chainId,
  contractAddress,
  metadata,
}) => {
  const [isRedFlagOpen, setIsRedFlagOpen] = useState(true);
  const [isGreenFlagOpen, setIsGreenFlagOpen] = useState(false);
  const flagRef = useRef(null);
  if (tokenDetails.isError || contractDetails.isError || metadata.isError) {
    return (
      <EmptyStateUI text="Could not fetch details, please try again later" />
    );
  }
  const { profile } = contractDetails.data ?? {};
  const { symbol, coingecko_slug: slug } = metadata.data;

  const descProps = {
    tokenBanner: profile?.token_details?.header_image_uri ?? null,
    description: profile?.bio ?? "",
    onRedFlagClick: () => {},
    onGreenFlagClick: () => {},
    symbol,
  };
  const { audit, visible_token_details: visibleTokenDetails } =
    tokenDetails.data ?? {};
  const greenFlags = audit?.positive || [];
  const profiles = audit?.profiles ?? {};
  const redFlags = audit?.negative || [];
  const redFlagsCount = redFlags.length;
  const greenFlagsCount = greenFlags.length;
  // const twitterLink = infoList.find((item) => item.key === "Search on Twitter");
  const handleFlagClick = (type = "green") => {
    if (type === "green") {
      setIsGreenFlagOpen(true);
    } else {
      setIsRedFlagOpen(true);
    }
    if (flagRef.current) {
      flagRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };
  return (
    <CustomColumn gap="20px">
      <TokenDescription
        {...descProps}
        twitterLink={
          tokenDetails.data?.token_metadata?.twitter_search_link ?? null
        }
        redFlagCount={redFlagsCount}
        greenFlagCount={greenFlagsCount}
        onGreenFlagClick={() => handleFlagClick("green")}
        onRedFlagClick={() => handleFlagClick("red")}
      />
      <TokenDetails
        slug={slug}
        visibleTokenDetails={visibleTokenDetails}
        chainId={chainId}
        contractAddress={contractAddress}
        deployer={contractDetails?.data?.creation_details?.creator}
        tokenDetails={tokenDetails}
      />
      {(redFlagsCount > 0 || greenFlagsCount > 0) && (
        <>
          <TokenFlags
            type="green"
            list={greenFlags}
            profiles={profiles}
            isOpen={isGreenFlagOpen}
            toggle={() => setIsGreenFlagOpen(!isGreenFlagOpen)}
          />
          <TokenFlags
            type="red"
            list={redFlags}
            profiles={profiles}
            isOpen={isRedFlagOpen}
            toggle={() => setIsRedFlagOpen(!isRedFlagOpen)}
          />
        </>
      )}
      <div ref={flagRef}></div>
      <TradingViewAttribution />
      <PaddingComponent height="24px" />
    </CustomColumn>
  );
};

export default withErrorBoundary(TokenPageRightBar);
