import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { getCollectionDetails } from "components/V2/CollectionDetails/collectionsUtils";
import { getQueryKeyOptions, QUERY_KEYS } from "utils/query_utils";
import { signedRequest } from "./api";

export const useGetCollectionDetails = (collectionId) => {
  return useQuery({
    queryKey: QUERY_KEYS.NFT_COLLECTION_PAGE(collectionId),
    queryFn: () => getCollectionDetails({ collectionId }),
    ...getQueryKeyOptions({}),
    enabled: !!collectionId,
  });
};

export const getNftCollectionActivities = async ({
  collectionId,
  pageParam = null,
}) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/v4/get_collection_transfers?collection_id=${collectionId}${pageParam ? `&cursor=${pageParam}` : ""}`,
  });

  return response.data.data;
};

export const getNftCollectionFloorPriceChart = async ({
  collectionId,
  duration,
}) => {
  const priceFrom = Math.ceil(
    (Date.now() - duration * 24 * 60 * 60 * 1000) / 1000
  );
  const response = await signedRequest({
    method: "get",
    path:
      "/api/v4/get_collection_pricing?collection_id=" +
      collectionId +
      "&price_from=" +
      priceFrom,
  });
  return {
    price_history:
      response?.data?.data?.["price_history"] ??
      response?.data?.data?.["24h_price_history"] ??
      [],
    price_change: response?.data?.data?.price_change ??
      response?.data?.data?.["24h_price_change"] ?? {
        value: null,
        display_value: null,
        direction: 0,
      },
  };
};
export const useGetNftCollectionActivities = ({ collectionId }) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS.NFT_COLLECTION_ACTIVITIES(collectionId),
    queryFn: ({ pageParam }) =>
      getNftCollectionActivities({ collectionId, pageParam }),
    ...getQueryKeyOptions({}),
    enabled: !!collectionId,
    getNextPageParam: (lastPage) => {
      return lastPage?.next_cursor;
    },
  });
};

const getNftTokenTransfers = async ({ chainId, contractAddress, tokenId }) => {
  const tokenParam = tokenId ? `&token_id=${tokenId}` : "";
  const response = await signedRequest({
    method: "post",
    path: `/api/v4/get_nft_transfers?contract_address=${contractAddress}&chain_id=${chainId}${tokenParam}`,
  });
  return response?.data?.data;
};

const getSimilarNfts = async ({ contractAddress, chainId, tokenId }) => {
  const response = await signedRequest({
    method: "post",
    path: `/api/v4/get_similar_nfts`,
    bodyText: JSON.stringify({
      contract_address: contractAddress,
      chain_id: chainId,
      token_id: tokenId,
    }),
  });
  return response?.data?.data;
};

export const useGetNftTokenTransfers = ({
  chainId,
  contractAddress,
  tokenId,
  enabled,
}) => {
  return useQuery({
    queryKey: QUERY_KEYS.NFT_TOKEN_TRANSFERS({
      chainId,
      contractAddress,
      tokenId,
    }),
    queryFn: () => getNftTokenTransfers({ chainId, contractAddress, tokenId }),
    ...getQueryKeyOptions({}),
    enabled: enabled && !!chainId && !!contractAddress,
  });
};

export const useGetCollectionFloorPriceChart = ({
  collectionId,
  duration,
  initialData = null,
}) => {
  return useQuery({
    queryKey: QUERY_KEYS.NFT_COLLECTION_FLOOR_PRICE_CHART(
      collectionId,
      duration
    ),
    queryFn: () => getNftCollectionFloorPriceChart({ collectionId, duration }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    initialData,
    enabled: !!collectionId,
  });
};

export const useGetSimilarNfts = ({ contractAddress, chainId, tokenId }) => {
  return useQuery({
    queryKey: QUERY_KEYS.SIMILAR_NFTS({ contractAddress, chainId, tokenId }),
    queryFn: () => getSimilarNfts({ contractAddress, chainId, tokenId }),
    ...getQueryKeyOptions({}),
    enabled: !!contractAddress && !!chainId,
  });
};
