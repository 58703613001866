import { useContext, useEffect } from "react";
import ActivityDetailsComponent from "./ActivityDetailsComponent";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const ActivityDetails = ({ activity_id, group_id }) => {
  const { setTitle } = useContext(LeftSideHeaderContext);
  useEffect(() => {
    setTitle("Activity Details");
  }, [setTitle]);

  return (
    <BaseWrapper mixpanelEvent="ActivityDetails">
      <ActivityDetailsComponent activityId={activity_id} contentId={group_id} />
    </BaseWrapper>
  );
};

export default ActivityDetails;
