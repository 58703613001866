import styles from "./confidence.module.css";
import { titleCase } from "utils/string_helper";
export const CONFIDENCE_LEVELS = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
};

const getClassName = (index, confidence) => {
  if (confidence === CONFIDENCE_LEVELS.LOW) {
    return index === 0 ? styles.low : styles.default;
  }
  if (confidence === CONFIDENCE_LEVELS.MEDIUM) {
    return index <= 1 ? styles.medium : styles.default;
  }
  if (confidence === CONFIDENCE_LEVELS.HIGH) {
    return index <= 2 ? styles.high : styles.default;
  }
};

const Confidence = ({
  confidence = CONFIDENCE_LEVELS.LOW,
  withText = false,
}) => {
  return (
    <div className={styles.confidences}>
      <div className={styles.circles}>
        <div className={getClassName(0, confidence)}></div>
        <div className={getClassName(1, confidence)}></div>
        <div className={getClassName(2, confidence)}></div>
      </div>
      {withText ? (
        <div className={styles.confidence_text}>{confidence} Confidence</div>
      ) : null}
    </div>
  );
};

const ConfidenceTableStyle = ({ confidenceText, withText = false }) => {
  const confidence = confidenceText.toUpperCase();
  return (
    <div className={styles.confidences}>
      <div className={styles.circles}>
        <div className={getClassName(0, confidence)}></div>
        <div className={getClassName(1, confidence)}></div>
        <div className={getClassName(2, confidence)}></div>
      </div>
      {withText ? (
        <div className={styles.table_style_font}>{titleCase(confidence)}</div>
      ) : null}
    </div>
  );
};

export { ConfidenceTableStyle, Confidence };
