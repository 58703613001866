import { padNumber } from "./Scheduler";

export const SUPPORTED_IMAGE_FORMATS = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/webp",
];

export const getFileTypeFromDataString = (dataString) => {
  if (!dataString) return "unsupported";
  try {
    return dataString.split(":")[1].split(";")[0];
  } catch (err) {
    return SUPPORTED_IMAGE_FORMATS[0];
  }
};

export const getDateInFormat = (date) => {
  const newDate = new Date(date);
  const month = newDate.toLocaleString("default", { month: "short" });
  const day = newDate.getDate();
  let period = "AM";
  let hours = newDate.getHours();
  if (hours >= 12) {
    period = "PM";
  }
  if (hours > 12) {
    hours = hours % 12;
  }
  const minutes = newDate.getMinutes();
  return `${day} ${month} at ${padNumber(hours)}:${padNumber(minutes)} ${period}`;
};
