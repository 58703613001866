import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "components/UI/Table/Table";
import ProfileRenderer from "components/UI/Components/ProfileRenderer";
import {
  InfoBoxContainer,
  PaddingComponent,
} from "components/UI/Components/Components";
import classes from "components/V2/Profile/ProfileDetails/Portfolio/TokenHoldings/header.module.css";

const GovernanceVote = ({ vote, profiles }) => {
  const actor = profiles[vote.actor.address];
  return (
    <TableRow alignToFlexStart>
      <TableCell alignLeft>
        <ProfileRenderer profile={actor} copyable="address" />
      </TableCell>
      <TableCell alignLeft>{vote?.choice}</TableCell>
      <TableCell alignRight>{vote.voting_power?.display_value}</TableCell>
    </TableRow>
  );
};

const GovernanceVotes = ({ votes, profiles }) => {
  return (
    <div>
      <PaddingComponent height="16px" />
      <InfoBoxContainer
        header={
          <div className={classes.header}>
            <div className={classes.labelContainer}>
              <div className={classes.label}>Votes</div>
              <div className={classes.totalUsdValue}>{votes?.length}</div>
            </div>
          </div>
        }>
        <Table>
          <TableHeader noBorder>
            <TableCell alignLeft>VOTER</TableCell>
            <TableCell alignLeft>CHOICE</TableCell>
            <TableCell alignRight>VOTE POWER</TableCell>
          </TableHeader>
          {votes.map((vote, index) => (
            <GovernanceVote
              key={`governance-vote-${index}`}
              vote={vote}
              profiles={profiles}
            />
          ))}
        </Table>
      </InfoBoxContainer>
    </div>
  );
};

export default GovernanceVotes;
