import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { Eye, EyeClosed } from "@phosphor-icons/react";

const WalletPrivacy = ({ wallet, size = 14, showTooltip = true }) => {
  const Icon = wallet?.is_private ? EyeClosed : Eye;
  if (showTooltip) {
    return (
      <PPLxTooltip title={wallet?.is_private ? "Private Wallet" : "Public Wallet"} arrow delay={500}>
        <Icon size={size} color="var(--text-2)" />
      </PPLxTooltip>
    );
  } else return <EyeClosed size={size} color="var(--text-2)" />;
};

export default WalletPrivacy;
