import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import styles from "./TokenPage.module.css";
import {
  CustomColumn,
  CustomSkeleton,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import TokenPageHeader from "./TokenPageHeader/TokenPageHeader";
import CustomLinkTabBar from "components/UI/Components/CustomLinkTabBar";
import {
  ContractCollectionUI,
  PAGE_TYPES,
  TOKEN_PAGE_SWAP_TABS,
  TokenPageLoader,
  TokenPageRightBarLoader,
  fetchInitialPageData,
} from "./token_page_utils";
import ContractCode from "../Contract/ContractDetails/ContractCode";
import ReadWriteContract from "../Contract/ContractDetails/ReadWriteContract";
import { useFetchChainIds } from "api/contract_details.api";
import PaginatedActivities from "components/PaginatedActivities/PaginatedActivities";
import TimelineGraphWrapper from "../ChartGraph/TimelineGraphWrapper";
import TokenPageSwapTabs from "./TokenPageSwapTabs/TokenPageSwapTabs";
import TokenLiquidity from "components/Trending/TokenLiquidity";
import TokenPageRightBar from "./TokenPageRightBar/TokenPageRightBar";
import RightBar from "components/Pages/AppModule/RightSideBar/RightBar";
import ExploreCollectionsTab from "../CollectionDetails/AllNftsTab/ExploreCollectionsTab";
import { useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "contextStates/AuthContext";
import TradingViewGraph from "../TradingViewGraph/TradingViewGraph";
import useTokenPageDetails from "./useTokenPageDetails";
import GraphLoader from "../Profile/ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import EmptyStateUI from "components/ErrorBoundary/EmptyStateUI";
import TokenPageContextProvider, { TokenPageContext } from "./TokenPageContext";
import { removeTradingView } from "utils/trading_view_utils";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
const TOKEN_TABS = [
  {
    title: "Price Chart",
    link: "token",
  },
  {
    title: "Activities",
    link: "activities",
  },
  {
    title: "TVL Info",
    link: "tvlinfo",
  },
  {
    title: "Source Code",
    link: "source-code",
  },
  {
    title: "ABI",
    link: "abi",
  },
  {
    title: "Read Contract",
    link: "read-contract",
  },
  {
    title: "Write Contract",
    link: "write-contract",
  },
  {
    title: "Liquidity",
    link: "liquidity",
  },
];

const FOLLOWER_SWAPS_TAB = TOKEN_PAGE_SWAP_TABS.findIndex(
  (tab) => tab.key === "follower_swaps"
);
const RECENT_SWAPS_TAB = TOKEN_PAGE_SWAP_TABS.findIndex(
  (tab) => tab.key === "recent_swaps"
);

const TokenPage = ({ chainId, contractAddress, queryData: data, refetch }) => {
  const { metadata, contractDetails, tokenDetails, coreDetails, isAllLoading } =
    useTokenPageDetails({ chainId, contractAddress });
  const {
    getMarkers,
    tableType,
    updateTableType,
    updateChartMarkers,
    chartPrice,
    setChartPrice,
  } = useContext(TokenPageContext);
  const { profile } = contractDetails.data ?? {};
  const pageType = metadata.data?.contract_type;
  const isTokenPage =
    pageType === PAGE_TYPES.TOKEN ||
    pageType === PAGE_TYPES.LPTOKEN ||
    pageType === "Unknown";
  const isNftPage = pageType === PAGE_TYPES.NFT;
  const isContractPage =
    pageType === PAGE_TYPES.CONTRACT ||
    pageType === PAGE_TYPES.HYBRIDTOKEN ||
    pageType === PAGE_TYPES.MULTITOKEN;
  const isSolana = chainId?.toLowerCase() === "solana";
  const isBitcoin = chainId?.toLowerCase() === "bitcoin";
  const { isUserLoggedIn } = useContext(AuthContext);

  const slug = metadata.data?.coingecko_slug ?? null;
  const symbol = metadata.data?.symbol ?? null;
  const description = profile?.bio ?? "";
  const excludeTabs = () => {
    const tabs = [];
    if (isNftPage || isContractPage) {
      tabs.push("token");
      tabs.push("liquidity");
      tabs.push("tvlinfo");
    } else if (isSolana || isBitcoin) {
      tabs.push("tvlinfo");
      tabs.push("liquidity");
      tabs.push("read-contract");
      tabs.push("write-contract");
      tabs.push("abi");
      tabs.push("source-code");
      tabs.push("activities");
    }
    return tabs;
  };
  const addTabs =
    pageType === PAGE_TYPES.NFT
      ? [{ link: "collections", title: "Collection" }]
      : [];
  const { chainIds } = useFetchChainIds();
  const params = useParams();
  const ethChainId = useMemo(
    () => chainIds?.[chainId]?.eth_chain_id,
    [chainIds, chainId]
  );
  const queryClient = useQueryClient();
  const initialFetchDoneRef = useRef(false);
  // fetch initial data
  useEffect(() => {
    if (metadata.data && !initialFetchDoneRef.current) {
      fetchInitialPageData({
        chainId,
        contractAddress,
        pageType,
        queryClient,
        isUserLoggedIn,
        isSolana,
        isTokenPage,
      });
      initialFetchDoneRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUserLoggedIn,
    chainId,
    contractAddress,
    pageType,
    queryClient,
    metadata,
    isTokenPage,
  ]);

  const activeTab = params?.active_tab;
  const readWriteRequestParams = useMemo(
    () => ({
      chainId,
      contractAddress,
      abi: data?.abi,
      ethChainId,
    }),
    [contractAddress, chainId, data?.abi, ethChainId]
  );
  const history = useHistory();

  useEffect(() => {
    if (activeTab === "contract") {
    }
    if (activeTab === "token" && !isTokenPage && metadata.data) {
      history.replace(
        `/c/${chainId}/${contractAddress}/${
          pageType === PAGE_TYPES.NFT ? "collections" : "activities"
        }`
      );
      return;
    }
    if (!activeTab) {
      const tab = isNftPage
        ? "collections"
        : isContractPage
          ? "activities"
          : isBitcoin
            ? "activities"
            : "token";
      history.replace(`/c/${chainId}/${contractAddress}/${tab}`);
      return;
    }
  }, [
    pageType,
    activeTab,
    history,
    chainId,
    contractAddress,
    isContractPage,
    isNftPage,
    isTokenPage,
    isSolana,
    isBitcoin,
    metadata,
  ]);
  const [activeTableTab, setActiveTableTab] = useState(0);
  useEffect(() => {
    const isRecent = activeTableTab === RECENT_SWAPS_TAB;
    const isFollower = activeTableTab === FOLLOWER_SWAPS_TAB;
    if (activeTableTab !== tableType && (isRecent || isFollower)) {
      updateTableType(isRecent ? "recent" : "follower");
      updateChartMarkers("clear");
      updateChartMarkers("refresh");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTableTab, tableType]);

  useEffect(() => {
    return () => {
      removeTradingView();
    };
  }, []);

  const onBannerClick = () => {
    setActiveTableTab(FOLLOWER_SWAPS_TAB);
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };
  const pageTypeProps = {
    pageType,
    isTokenPage,
    isContractPage,
    isNftPage,
  };
  const headerProps = {
    metadata,
    tokenDetails,
    contractDetails,
    ...pageTypeProps,
    chainId,
    contractAddress,
    onBannerClick,
    refetch: contractDetails.refetch,
  };
  const renderTabContent = () => {
    if (!["token", "activities"].includes(activeTab) && coreDetails.isLoading) {
      return (
        <PaddingComponent padding="16px">
          <ZxFlex dir="column" gap="12px">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((t, i) => {
              return (
                <CustomSkeleton
                  key={i}
                  height="48px"
                  borderRadius="4px"
                  width="100%"
                  variant="rectangular"
                />
              );
            })}
          </ZxFlex>
        </PaddingComponent>
      );
    }
    switch (activeTab) {
      case "token": {
        return (
          <PaddingComponent padding="0">
            <CustomColumn gap="0">
              <div className={styles.priceChartContainer}>
                {metadata.isLoading ? (
                  <GraphLoader height={600} />
                ) : (
                  <TradingViewGraph
                    chain={chainId}
                    address={contractAddress}
                    slug={slug}
                    showPriceHeader={true}
                    getMarkers={getMarkers}
                    symbol={symbol}
                    metadata={metadata.data}
                    chartPrice={chartPrice}
                    setChartPrice={setChartPrice}
                    isTokenPage={true}
                  />
                )}
              </div>
              <Divider />
              <PaddingComponent paddingTop="20px">
                {!isBitcoin && (
                  <TokenPageSwapTabs
                    isSolana={isSolana}
                    chainId={chainId}
                    contractAddress={contractAddress}
                    activeTab={activeTableTab}
                    setActiveTab={setActiveTableTab}
                  />
                )}
              </PaddingComponent>
            </CustomColumn>
          </PaddingComponent>
        );
      }
      case "collections": {
        if (pageType !== PAGE_TYPES.NFT) {
          return null;
        }
        return (
          <PaddingComponent padding="16px">
            <ExploreCollectionsTab
              collections={contractDetails.data?.collections}
            />
          </PaddingComponent>
        );
      }
      case "tvlinfo":
        return (
          <PaddingComponent padding="0 16px 16px 16px">
            <TimelineGraphWrapper
              protocolStats={coreDetails.data?.statistics?.timeline ?? []}
            />
          </PaddingComponent>
        );
      case "source-code":
        return (
          <PaddingComponent padding="0 16px">
            <ContractCode
              type="source-code"
              contractDetails={coreDetails.data}
              isDetailsLoading={false}
            />
          </PaddingComponent>
        );
      case "abi":
      case "idl": {
        return (
          <PaddingComponent padding="0 16px">
            <ContractCode
              type="abi"
              contractDetails={coreDetails.data}
              isDetailsLoading={false}
            />
          </PaddingComponent>
        );
      }
      case "read-contract": {
        return (
          <PaddingComponent padding="0 16px">
            <ReadWriteContract
              isRead={true}
              data={coreDetails.data}
              requestParams={readWriteRequestParams}
            />
          </PaddingComponent>
        );
      }
      case "write-contract": {
        return (
          <PaddingComponent padding="0 16px">
            <ReadWriteContract
              isRead={false}
              data={coreDetails.data}
              requestParams={readWriteRequestParams}
            />
          </PaddingComponent>
        );
      }
      case "liquidity":
        return (
          <PaddingComponent padding="0">
            <TokenLiquidity identifier={contractAddress} chainId={chainId} />
          </PaddingComponent>
        );
      case "activities":
        return (
          <PaddingComponent padding="12px 16px">
            <PaginatedActivities
              identifier={contractAddress}
              activeChainId={chainId}
              activitiesForContract
              type="contract_details"
              isProfilePage={true}
            />
          </PaddingComponent>
        );
      default:
        return null;
    }
  };

  const PAGE_TABS = [...TOKEN_TABS, ...addTabs].filter(
    (tab) => !excludeTabs().includes(tab.link)
  );

  if (isAllLoading || metadata.isLoading) {
    return <TokenPageLoader />;
  }
  return (
    <div className={styles.container}>
      <PaddingComponent className={styles.pageContent}>
        {/* Header */}
        <TokenPageHeader {...headerProps} />
        <Divider />
        {/* update after data */}
        {/* {isTokenPage && <TokenPnlBanner data={data} />} */}
        {contractDetails.data && (
          <ContractCollectionUI
            profile={profile}
            description={description}
            {...pageTypeProps}
          />
        )}

        {/* Tab bar */}
        <PaddingComponent padding="16px 16px 4px 16px">
          <CustomLinkTabBar
            tabsList={PAGE_TABS}
            currentStep={activeTab}
            isLinkTabBar={true}
          />
        </PaddingComponent>
        {/* Tab content */}
        <PaddingComponent>{renderTabContent()}</PaddingComponent>
        <Divider />
      </PaddingComponent>
      <PaddingComponent
        padding={isTokenPage && !isBitcoin ? "16px 20px" : 0}
        className={`${styles.rightBar} ${
          isTokenPage && !isBitcoin ? styles.whiteBg : ""
        }`}>
        {isTokenPage && !isBitcoin ? (
          tokenDetails.isLoading ? (
            <TokenPageRightBarLoader />
          ) : (
            <TokenPageRightBar
              metadata={metadata}
              contractDetails={contractDetails}
              tokenDetails={tokenDetails}
              chainId={chainId}
              contractAddress={contractAddress}
            />
          )
        ) : (
          <RightBar />
        )}
      </PaddingComponent>
    </div>
  );
};

const TokenPageWrapper = (props) => {
  return (
    <ErrorBoundary
      fallback={
        <EmptyStateUI text="Could not fetch details, please try again." />
      }>
      <TokenPageContextProvider {...props}>
        <TokenPage {...props} />
      </TokenPageContextProvider>
    </ErrorBoundary>
  );
};

export default TokenPageWrapper;
