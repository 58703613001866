import { useHistory, useParams } from "react-router-dom";
import React, { useState } from "react";
import successAnimation from "../../../../../assets/animations/success.json";
import classes from "./CreateGroupPanel.module.css";
import {
  CustomColumn,
  CustomRow,
  FlexGrow,
} from "../../../Profile/ProfileDetails/wallets/AddWallet/AddWalletComponent";
import {
  CustomText,
  RightPanelCrossIcon,
} from "../../../../UI/Components/Components";
import Lottie from "react-lottie";
import CustomLinkTabBar, {
  SIZE,
} from "../../../../UI/Components/CustomLinkTabBar";
import { GroupType } from "./GroupType";
import { AddPeople } from "./AddPeople";
import { GroupName } from "./GroupName";
import { CreateGroupContext, useGroupCreate } from "./useGroupCreate";

export const CreateGroupPanel = ({ onClose }) => {
  const history = useHistory();
  const params = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const value = useGroupCreate();

  if (showSuccessAnimation) {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: successAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div className={classes.rightPanel}>
        <div className={classes.header}>
          <CustomRow gap="40px" justifyContent="space-between">
            <span className={classes.title}>New Group</span>
            <RightPanelCrossIcon
              onClick={() => {
                history.replace(`/${params.identifier}/wallets`);
              }}></RightPanelCrossIcon>
          </CustomRow>
        </div>
        <div className={classes.animationContainer}>
          <CustomColumn justifyContent="center">
            <Lottie options={defaultOptions} height={280} width={280} />
            <CustomText
              text="Group created successfully"
              fontSize="16px"
              fontWeight="600"
            />
          </CustomColumn>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <CustomRow gap="40px" justifyContent="space-between">
          <span className={classes.title}>Add Group</span>
          <RightPanelCrossIcon onClick={onClose}></RightPanelCrossIcon>
        </CustomRow>
      </div>
      <div className={classes.tabBar}>
        <CustomLinkTabBar
          fullWidth={true}
          isBorderBottom={false}
          size={SIZE.REGULAR}
          validateChange={(index) => {
            if (index === 0) {
              return true;
            } else if (index === 1) {
              return value.isStep1Valid;
            } else if (index === 2) {
              return value.isStep1Valid;
            }
          }}
          tabsList={[
            <CustomRow gap={"4px"} alignItems="center" margin="0 4px 8px 4px">
              <span
                className={
                  activeTab === 0 ? classes.tabActiveNumber : classes.tabNumber
                }>
                1
              </span>
              Group type
            </CustomRow>,
            <CustomRow gap={"4px"} alignItems="center" margin="0 4px 8px 4px">
              <span
                className={
                  activeTab === 1 ? classes.tabActiveNumber : classes.tabNumber
                }>
                2
              </span>
              Add people
            </CustomRow>,
            <CustomRow gap={"4px"} alignItems="center" margin="0 4px 8px 4px">
              <span
                className={
                  activeTab === 2 ? classes.tabActiveNumber : classes.tabNumber
                }>
                3
              </span>
              Group name
            </CustomRow>,
          ]}
          isLinkTabBar={false}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
      <CreateGroupContext.Provider value={value}>
        <FlexGrow>
          {activeTab === 0 && <GroupType setActiveTab={setActiveTab} />}
          {activeTab === 1 && <AddPeople setActiveTab={setActiveTab} />}
          {activeTab === 2 && (
            <GroupName
              setActiveTab={setActiveTab}
              setShowSuccessAnimation={setShowSuccessAnimation}
              onClose={onClose}
            />
          )}
        </FlexGrow>
      </CreateGroupContext.Provider>
    </div>
  );
};
