import checkWalletAnimation from "assets/animations/check_wallet.json";
import { PaddingComponent } from "components/UI/Components/Components";
import { useEffect } from "react";
import Lottie from "react-lottie";
import OnboardingBg from "../OnboardingBg";
import styles from "./WalletLoader.module.css";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const WalletLoader = () => {
  useEffect(() => {
    document.title = "Checking your wallet...";
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: checkWalletAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <BaseWrapper mixpanelEvent="OnboardingWalletLoader">
      <OnboardingBg showTalkToUs={false} showStepper={false}>
        <div className={styles.walletLoaderContainer}>
          <div className={styles.walletLoaderContent}>
            <div className={styles.walletLoaderSpinner}>
              <Lottie options={defaultOptions} height={280} width={280} />
            </div>
            <div className={styles.walletLoaderText}>
              <h2>Checking your wallet...</h2>
              <PaddingComponent height={1} />
              <div>This will only take a couple of seconds.</div>
              <div> Please wait</div>
            </div>
          </div>
        </div>
      </OnboardingBg>
    </BaseWrapper>
  );
};

export default WalletLoader;
