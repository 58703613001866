import classes from "./AddWallet.module.css";
import {
  CustomButton,
  CustomText,
  PaddingComponent,
} from "../../../../../UI/Components/Components";
import { CustomColumn, CustomRow, FlexGrow } from "./AddWalletComponent";
import { useContext } from "react";
import { AddWalletContext } from "./AddWallet";
import { Eye, EyeClosed } from "@phosphor-icons/react";
import { trackEvent } from "../../../../../../utils/event_tracking";
import { getCdnUrl } from "../../../../../../utils/assets";

export function HelpSection({
  icon,
  title,
  subTitle,
  bgColor = "var(--help-background)",
}) {
  return (
    <>
      <CustomRow className={classes.help} style={{ background: bgColor }}>
        <img src={icon} className={classes.helpIcon} alt="help" />
        <CustomColumn>
          <CustomText
            text={title}
            fontSize="15px"
            fontWeight="600"
            textAlign="left"
            color="var(--text-1)"
          />
          <CustomText
            text={subTitle}
            fontSize="13px"
            fontWeight="400"
            textAlign="left"
          />
        </CustomColumn>
      </CustomRow>
    </>
  );
}

const IconWrapper = ({ children }) => {
  return (
    <CustomRow
      padding="10px"
      borderRadius="50%"
      aspectRatio={1}
      background="var(--gray-gradient-background)">
      {children}
    </CustomRow>
  );
};
export const WalletTypeOptions = ({ walletType, setWalletType, size }) => {
  const optionClass = size === "small" ? classes.optionSmall : classes.option;
  return (
    <CustomRow gap={"20px"} justifyContent="stretch">
      <CustomColumn
        width="50%"
        justifyContent="center"
        className={`${optionClass} ${walletType === "public" ? classes.active : ""}`}
        onClick={() => {
          trackEvent("add_wallet_selected_public_wallet");
          setWalletType("public");
        }}>
        <IconWrapper>
          <Eye size={24} weight="duotone" />
        </IconWrapper>
        <CustomText
          text="Public Wallet"
          fontSize="15px"
          fontWeight="inherit"
          color="inherit"
        />
      </CustomColumn>
      <CustomColumn
        width="50%"
        justifyContent="center"
        className={`${optionClass} ${walletType === "private" ? classes.active : ""}`}
        onClick={() => {
          trackEvent("add_wallet_selected_private_wallet");
          setWalletType("private");
        }}>
        <IconWrapper>
          <EyeClosed size={24} weight="duotone" />
        </IconWrapper>
        <CustomText
          text="Private Wallet"
          fontSize="15px"
          fontWeight="inherit"
          color="inherit"
        />
      </CustomColumn>
    </CustomRow>
  );
};
const WalletType = ({ setActiveTab }) => {
  const { setWalletType, walletType, isStep1Valid } =
    useContext(AddWalletContext);
  return (
    <div className={classes.formWrapper}>
      <div className={classes.form}>
        <div
          className={classes.graphic}
          style={{
            backgroundImage: `url(${getCdnUrl("assets/onboarding/add-wallet/add_wallert_type_graphic.png")})`,
          }}>
          <CustomColumn
            minHeight={"150px"}
            width={"100%"}
            justifyContent="flex-start"
            padding="34px 185px 26px 26px">
            <CustomText
              text="Multi chain platform"
              fontSize="17px"
              fontWeight="600"
              color="var(--graphic-text)"
              textAlign="left"
            />
            <CustomText
              text="One app for EVM, Solana, Cosmos, Ton, and Bitcoin wallets. You can add more wallets once you’re in."
              fontSize="15px"
              fontWeight="400"
              color="var(--graphic-text)"
              textAlign="left"
            />
          </CustomColumn>
        </div>
        <PaddingComponent minHeight="24px" />
        <WalletTypeOptions
          walletType={walletType}
          setWalletType={setWalletType}
        />
        <FlexGrow />
        <PaddingComponent minHeight="24px" />
        <HelpSection
          icon={getCdnUrl("assets/onboarding/add-wallet/lock-front-color.png")}
          title="What are private wallets?"
          subTitle="Keep your wallets private. You can give view access to your friends later."
        />
        <PaddingComponent minHeight="24px" />
      </div>
      <div className={classes.footer}>
        <CustomRow>
          <CustomButton
            className={classes.nextButton}
            disabled={!isStep1Valid}
            text="Next"
            radius="8px"
            onClick={() => {
              trackEvent("complete_choose_wallet_type_step", {
                is_edit: false,
                is_public: walletType === "public",
              });
              setActiveTab(1);
            }}
          />
        </CustomRow>
      </div>
    </div>
  );
};

export default WalletType;
