import { ClickAwayListener } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getSearchResults, getSearchFilters } from "api/search.api";
import React, { useRef, useState, useCallback, useEffect } from "react";
import styles from "./searchBar.module.css";
import SearchInput from "./SearchInput";
import SearchResults from "./SearchResults";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import { trackEvent } from "utils/event_tracking";
import { useHistory } from "react-router-dom";
const NEW_FEED_ROUTES = [
  "/home",
  "/post",
  "/activity_group",
  "/sol_activity_group",
];
const SearchBar = ({ noPlaceholderAnimation = false }) => {
  const history = useHistory();
  const [filters, setFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);
  const [isQueryTriggeredDummyLoading, setIsQueryTriggeredDummyLoading] =
    useState(false);
  const { isSearchInputFocused, setIsSearchInputFocused } = React.useContext(
    LeftSideHeaderContext
  );

  const isNewFeedRoute = NEW_FEED_ROUTES.some((nf) =>
    history.location.pathname.includes(nf)
  );

  useEffect(() => {
    //run timeout for 300ms and make this from true to false
    setIsQueryTriggeredDummyLoading(true);
    const timeout = setTimeout(() => {
      setIsQueryTriggeredDummyLoading(false);
    }, 300);
    return () => clearTimeout(timeout);
  }, [searchTerm, filters]);
  const { data: searchResults, isLoading: isSearchLoading } =
    useFetchSearchResults({ searchTerm, filters });
  const { data: searchFilters } = useFetchSearchFilters();

  useEffect(() => {
    if (searchFilters?.data?.data) {
      const data = Object.keys(searchFilters.data.data);
      //build list
      const updatedFilters = data.map((filter) => {
        return {
          name: filter,
          isSelected: false,
        };
      });
      updatedFilters.unshift({
        name: "All",
        isSelected: true,
      });
      setFilters(updatedFilters);
    }
  }, [searchFilters]);

  const handleSearchBlur = useCallback(() => {
    setIsSearchInputFocused(false);
    inputRef.current.blur();
  }, [setIsSearchInputFocused]);

  const searchInputProps = {
    searchTerm,
    setSearchTerm,
    setFilters,
    isSearchLoading,
    isSearchInputFocused,
    setIsSearchInputFocused,
    inputRef,
    noPlaceholderAnimation,
  };

  const searchLoadingCheck = isSearchLoading || isQueryTriggeredDummyLoading;
  useEffect(() => {
    if (isSearchLoading) {
      setIsSearchInputFocused(true);
    }
  }, [isSearchLoading, setIsSearchInputFocused]);
  useEffect(() => {
    // Capture enter key while search input is focused and search is loading
    // Redirect to /search?query=searchTerm
    if (isSearchInputFocused && searchLoadingCheck) {
      const handleKeyDown = (e) => {
        if (e.key === "Enter") {
          history.push(`/search?query=${searchTerm}`);
          handleSearchBlur();
          trackEvent("search_query", {
            search_term: searchTerm,
          });
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }
  }, [
    searchLoadingCheck,
    searchTerm,
    isSearchInputFocused,
    history,
    handleSearchBlur,
  ]);

  return (
    <ClickAwayListener onClickAway={() => setIsSearchInputFocused(false)}>
      <div
        className={`${styles.searchBar} ${isNewFeedRoute ? styles.newFeed : ``}`}>
        <SearchInput {...searchInputProps} />

        {isSearchInputFocused && (
          <SearchResults
            isSearchLoading={searchLoadingCheck}
            searchResults={searchResults ?? []}
            isSearchInputFocused={isSearchInputFocused}
            setIsSearchInputFocused={setIsSearchInputFocused}
            handleSearchBlur={handleSearchBlur}
            searchTerm={searchTerm}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

const useFetchSearchResults = ({ searchTerm, filters }) => {
  return useQuery({
    queryKey: ["search_result", searchTerm, filters],
    queryFn: getSearchResults,
    staleTime: 0,
    retry: 3,
    retryOnMount: false,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    enabled: searchTerm !== "",
  });
};

const useFetchSearchFilters = () => {
  return useQuery({
    queryKey: ["search_filters"],
    queryFn: getSearchFilters,
    staleTime: 1000 * 60 * 5,
    retry: 3,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
export default SearchBar;
