import React from "react";
import Image from "components/UI/Image";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import classes from "./personProfile.module.css";
import ConditionalLink from "shared/ConditionalLink";
import VerifiedBadge from "../Components/VerifiedBadge";
import { getTrimmedString } from "utils/misc";

const PersonProfile = ({ profile, onlyAvatar = false, onlyName = false }) => {
  if (!profile) {
    return null;
  }

  if (onlyAvatar) {
    return <PersonAvatar profile={profile} />;
  }
  if (onlyName) {
    return <PersonName profile={profile} />;
  }
  return (
    <ProfileHoverCard actor={profile}>
      <ConditionalLink to={profile.link} className={classes.actorImageWithName}>
        <Image
          className={classes.actorAvatar}
          src={profile.display_picture}
          alt={profile.display_name}
        />
        {getTrimmedString(profile.display_name, 10)}
        <VerifiedBadge profile={profile} />
      </ConditionalLink>
    </ProfileHoverCard>
  );
};

const PersonAvatar = ({ profile }) => {
  return (
    <ProfileHoverCard actor={profile}>
      <ConditionalLink to={profile.link} className={classes.avatarWrapper}>
        <Image
          className={classes.actorAvatar}
          src={profile.display_picture}
          alt={profile.display_name}
        />
        <VerifiedBadge profile={profile} />
      </ConditionalLink>
    </ProfileHoverCard>
  );
};
const PersonName = ({ profile }) => {
  return (
    <ProfileHoverCard actor={profile} queryProfile>
      <ConditionalLink to={profile.link} className={classes.actorName}>
        {profile.display_name}
        <VerifiedBadge profile={profile} />
      </ConditionalLink>
    </ProfileHoverCard>
  );
};

export default PersonProfile;
