import DotsMobileStepper from "./ConnectWallet/DotsMobileStepper";
import styles from "./OnboardingBg.module.css";

import liveChatAnimation from "assets/animations/live_chat_animation.json";
import logo from "assets/svg/elevated-logo.svg";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import AddressConnectedInfoOnb from "components/V2/Onboarding/components/AddressConnectedInfoOnb";
import {
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import ZxText from "zxComponents/common/ZxText/ZxText";
import Lottie from "react-lottie";
import ExternalLinks from "../Profile/ProfileHeader/ExternalLinks";
import { logSentryEvent } from "utils/sentry";
import useScreenRes from "customHooks/useMediaQuery";

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: liveChatAnimation,
};
const OnboardingBg = ({
  children,
  showTalkToUs = true,
  stepperColor,
  showStepper = true,
}) => {
  const [currStep, setCurrentStep] = useState(1);
  const location = useLocation();
  const { pathname } = location;
  const showOnboardingHeader = !pathname.includes("connect_wallet");
  const { isMobile } = useScreenRes();
  useEffect(() => {
    const currentStep = () => {
      switch (pathname) {
        case "/onboarding/connect_wallet":
        case "/onboarding/connected_wallets":
          return 0;
        case "/onboarding/connect_socials":
        case "/onboarding/complete_profile":
          return 1;
        case "/onboarding/add_invite_code":
          return 2;
        case "/onboarding/follow_notables":
        case "/onboarding/follow_friends":
          return 3;
        case "/onboarding/tweet_abt_0xppl":
          return 4;
        case "/onboarding/download_app":
        case "/onboarding/wallet_checklist":
          return 5;
        case "/onboarding/complete":
          return "complete";
        default:
          return "unknown_route";
      }
    };
    setCurrentStep(currentStep());
  }, [pathname]);
  const openIntercom = () => {
    try {
      if (window.Intercom) {
        window.Intercom("showNewMessage");
      }
    } catch (err) {
      console.log("Error in opening intercom", err);
      logSentryEvent("Error in opening intercom", err);
    }
  };
  return (
    <div className={styles.container}>
      {!isMobile && (
        <div className={styles.sidebar}>
          <img className={styles.logo} src={logo} alt="Logo" />
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.contentHeaderActions}>
          {showStepper && (
            <DotsMobileStepper
              currStep={currStep}
              stepperColor={stepperColor}
            />
          )}
          <div className={styles.logoutContainer}>
            <PaddingComponent paddingTop="8px">
              <AddressConnectedInfoOnb visible={showOnboardingHeader} />
            </PaddingComponent>
            {showTalkToUs && (
              <div className={styles.linksContainer}>
                <PaddingComponent
                  padding={isMobile ? "4px 8px" : "8px 12px"}
                  className={styles.liveChat}
                  onClick={openIntercom}>
                  <CustomRow alignItems="center" gap={isMobile ? "4px" : "8px"}>
                    <Lottie
                      options={animationOptions}
                      height={isMobile ? 12 : 20}
                      width={isMobile ? 12 : 20}
                    />
                    {
                      <ZxText
                        cursor="pointer"
                        fontSize={isMobile ? "10px" : "13px"}>
                        Live Chat
                      </ZxText>
                    }
                  </CustomRow>
                </PaddingComponent>
                <ExternalLinks
                  iconWrapperColor="var(--social-bg)"
                  links={{
                    telegram: "https://t.me/BT06Fh0aqjpjMDk8",
                    twitter: "https://x.com/0xppl_",
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default OnboardingBg;
