import { IconWrapper } from "components/UI/Components/Components";
import classes from "./GenericFallBackUI.module.css";
import { useContext } from "react";
import { ThemeContext } from "contextStates/Theme.context";

const EmptyStateUI = ({
  containerClass,
  text = "",
  icon,
  newState = false,
  emptyTransactions = false,
}) => {
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";
  return (
    <div className={`${containerClass} ${classes.emptyStateBox}`}>
      <div className={classes.header}>
        {icon ? (
          <IconWrapper
            height="50px"
            width="50px"
            color="var(--elevation-2)"
            cursor="auto"
            noBorder>
            {icon}
          </IconWrapper>
        ) : !newState && !emptyTransactions ? (
          "-(•...•)-"
        ) : (
          <img
            src={
              newState
                ? "/empty_state.svg"
                : !isDark
                  ? "/png/empty_transactions_new.png"
                  : "/png/empty_transactions_new_dark.png"
            }
            alt="Empty State"
          />
        )}
      </div>
      <div className={classes.text}>{`${
        text ?? "There seems to be an issue."
      }`}</div>
    </div>
  );
};

export default EmptyStateUI;
