export const NFT_PAGE_LISTING_FILTERS = [
  {
    label: "Price low to high",
    key: "price_asc",
  },
  {
    label: "Price high to low",
    key: "price_desc",
  },
];

export const NFT_PAGE_ALL_FILTERS = [
  {
    label: "Price low to high",
    key: "last_sale_price_asc",
  },
  {
    label: "Price high to low",
    key: "last_sale_price_desc",
  },
];

export const DEFAULT_NFT_LISTING_FILTER = NFT_PAGE_LISTING_FILTERS[0].key;
export const DEFAULT_NFT_ALL_FILTER = NFT_PAGE_ALL_FILTERS[0].key;
