import {
  AdditionalInfoItem,
  CustomProfileMiniItem,
  CustomRow,
  CustomSkeleton,
  CustomText,
  InfoBox,
  PaddingComponent,
} from "components/UI/Components/Components";
import React from "react";
import { formatUnixTimestamp, timeAgo } from "utils/misc";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";

const DeployedBy = ({ data, externalLinks, isShimmer = false }) => {
  if (data?.creator == null && !isShimmer) {
    return null;
  }
  return (
    <InfoBox>
      <CustomText
        text="Deployed By"
        textAlign="left"
        fontSize="15px"
        fontWeight="600"
        color="var(--text-1)"
      />
      <PaddingComponent height="16px" />

      <CustomRow justifyContent="space-between" alignItems="center">
        {isShimmer ? (
          <CustomRow gap="16px" width="100%">
            <CustomRow flexShrink={0}>
              <CustomSkeleton
                width="40px"
                height="40px"
                variant="circular"></CustomSkeleton>
            </CustomRow>
            <CustomSkeleton width="100%"></CustomSkeleton>
          </CustomRow>
        ) : (
          <CustomProfileMiniItem
            profile={data?.creator}
            image={data?.creator?.display_picture}
            title={data?.creator.display_name}
            circleAvatar
            subTitle={
              <CustomText
                text={`${data?.deployment_count} contracts deployed`}
                fontSize="15px"
              />
            }
          />
        )}
      </CustomRow>
      <PaddingComponent height="16px" />

      {isShimmer ? (
        <AdditionalInfoItem
          title={<CustomSkeleton width="100px" />}
          value={<CustomSkeleton width="100%" />}
          titleFlexGrow={2}
        />
      ) : (
        <AdditionalInfoItem
          title="Deployed on: "
          titleFlexGrow={2}
          value={
            <CustomRow alignItems="center" gap="6px">
              <div>
                {formatUnixTimestamp(data?.created_at)}{" "}
                <CustomText text={timeAgo(data?.created_at * 1000)} />
              </div>
            </CustomRow>
          }
        />
      )}

      {!!Object.keys(externalLinks || {})?.length && (
        <AdditionalInfoItem
          title="Links: "
          value={<ExternalLinks links={externalLinks} />}
          titleFlexGrow={2}
        />
      )}
    </InfoBox>
  );
};

export default DeployedBy;
