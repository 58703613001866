import classes from "./QueryExamples.module.css";
import { ListDashes } from "@phosphor-icons/react";
import { getExampleQueries } from "api/screener.api";
import withLoader from "components/core/withLoader";
import TrendingQueryCard from "../TrendingQueries/TrendingQueryCard";
import { CustomSkeleton } from "components/UI/Components/Components";

const QueryExamples = ({ queryData }) => {
  return (
    <div className={classes.query_example}>
      <div className={classes.heading}>
        <span>Examples</span>
        <div>
          <ListDashes size={22} weight="fill" />
        </div>
      </div>
      <div className={classes.body}>
        {queryData?.data?.data.slice(0, 3).map((query) => {
          return <TrendingQueryCard query={query} isExample />;
        })}
      </div>
    </div>
  );
};

export const ExampleQueryGhost = () => {
  return (
    <div className={classes.query_example}>
      <div className={classes.heading}>
        <span>Examples</span>
        <ListDashes size={22} weight="fill" />
      </div>
      <CustomSkeleton height={44} />
      <CustomSkeleton height={44} />
      <CustomSkeleton height={44} />
      <CustomSkeleton height={44} />
      <CustomSkeleton height={44} />
    </div>
  );
};

export default withLoader(QueryExamples, getExampleQueries, {
  queryKey: () => ["exampleQueries"],
  containerClass: classes.query_example,
  CustomLoader: ExampleQueryGhost,
  staleTime: 1000 * 60 * 30,
});
