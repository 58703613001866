import React, { useContext, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { ProtectedRoute } from "shared/protected.route";
import { MEDIA_POST_TYPES } from "utils/constants";
import useDuplicateHistoryBlocker from "customHooks/useDuplicateHistoryBlocker";
import { AuthContext } from "contextStates/AuthContext";
import { isAllAuthValidKeyAvailable } from "contextStates/auth_utils";
import { isTestingOnboarding } from "utils/constants";
import HelloPage from "components/Pages/AppModule/HelloPage";
import useQueryParams from "customHooks/useQueryParams";
import { trackEvent } from "utils/event_tracking";
import ZxTradingViewPage from "zxComponents/ZxTradingViewPage/ZxTradingViewPage";
import GenericFallBackUI from "components/ErrorBoundary/GenericFallBackUI";

import GroupedActivityGallery from "components/GalleryModal/GroupedActivityGallery";
import GalleryContextProvider from "contextStates/Gallery";
import AppModule from "components/Pages/AppModule/AppModule";
import LandingPage from "components/V2/Landing/LandingPage";
import Onboarding from "components/V2/NewOnboarding/NewOnboarding";
import PageNotFound from "pages/PageNotFound/PageNotFound/PageNotFound";
import TwitterCallback from "components/Onboarding/TwitterCallback";
import CrossPostTwitterCallback from "components/Onboarding/CrossPostTwitterCallback";
import AuthorizeForm from "components/Admin/AuthorizeForm";

const Routes = () => {
  useDuplicateHistoryBlocker();
  const { isUserAuthenticated, identityDetails } = useContext(AuthContext);
  let location = useLocation();
  let history = useHistory();
  const queryParams = useQueryParams();
  const source = queryParams.get("source");

  let background = location.state && location.state.background;

  useEffect(() => {
    const currentPath = window.location.pathname;
    const checkAuthentication = async ({ navigateCallback }) => {
      if (currentPath === "/redirect-to-app") return;
      await isUserAuthenticated({
        navigateCallback,
        forceWaitlist: true,
        isNavigating: true,
      });
    };

    if (
      currentPath?.includes("/twitter/callback") ||
      currentPath?.includes("/charts/")
    ) {
      return;
    }

    checkAuthentication({
      navigateCallback: (path) => {
        if (
          path === "/" ||
          path === "/home" ||
          path === null ||
          path === "/redirect-to-app" ||
          path.includes("/charts/") ||
          isTestingOnboarding
        ) {
          return;
        }
        history.push(path);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (source) {
      trackEvent("redirect_link_source", {
        source,
        link: window.location.href,
      });
    }
  }, [source]);
  const createRedirectWithQuery = (path) => {
    return `${path}${location.search}`;
  };

  return (
    <>
      <Switch location={background || location}>
        <Route path="/charts/:chain/:address">
          <ZxTradingViewPage />
        </Route>
        <Route path="/redirect-to-app">
          <HelloPage />
        </Route>
        <Route exact path="/twitter/callback">
          <TwitterCallback />
        </Route>
        <Route exact path="/twitter/cross_post_callback">
          <CrossPostTwitterCallback />
        </Route>
        <Route exact path="/twitter/cross_post_callback">
          <CrossPostTwitterCallback></CrossPostTwitterCallback>
        </Route>

        <Route
          exact
          path="/"
          render={({ match }) => {
            if (isAllAuthValidKeyAvailable()) {
              return <Redirect to="/home" />;
            }
            return <LandingPage />;
          }}></Route>
        <Route exact path="/join-waitlist">
          <Redirect to="/onboarding/intro" />
        </Route>
        <Route exact path="/connect-wallet">
          <Redirect to="/onboarding/intro" />
        </Route>
        <Route exact path="/gm/:subroute?">
          {({ match }) => (
            <Redirect
              to={createRedirectWithQuery(
                `/onboarding/intro/${match.params.subroute || ""}`
              )}
            />
          )}
        </Route>
        <ProtectedRoute path="/activity_details/:activity_id/media/:media_idx">
          <GalleryContextProvider>
            <GroupedActivityGallery />
          </GalleryContextProvider>
        </ProtectedRoute>
        <ProtectedRoute
          path={`/:type${MEDIA_POST_TYPES}/:id/media/:activity_idx/:media_idx`}>
          <GalleryContextProvider>
            <GroupedActivityGallery />
          </GalleryContextProvider>
        </ProtectedRoute>
        <Route exact path="/admin/authorize" component={AuthorizeForm} />
        <Route path="/onboarding" component={Onboarding} />
        <Route
          path="/manage-wallets-access/:tab?"
          render={({ match }) => {
            const id = identityDetails?.current?.identity?.id;

            if (id) {
              return (
                <Redirect
                  to={
                    "/" +
                    id +
                    "/wallets/manage-access/" +
                    (match.params.tab || "active")
                  }
                />
              );
            }
            return null;
          }}
        />
        <Route
          exact
          path="/oops"
          component={() => (
            <GenericFallBackUI
              height="300px"
              width="300px"
              fullPage
              textSize="20px"
              onReload={() => history.push("/home")}
              text="Something went wrong, please try again later."
            />
          )}
        />
        <Route path="/" component={AppModule} />
        <Route path="*" component={PageNotFound} />
      </Switch>
      {/* Show the modal when a background page is set */}
      {background && (
        <>
          <Route path="/activity_details/:activity_id/media/:media_idx">
            <GalleryContextProvider>
              <GroupedActivityGallery />
            </GalleryContextProvider>
          </Route>
          <Route
            path={`/:type${MEDIA_POST_TYPES}/:id/media/:activity_idx/:media_idx`}>
            <GalleryContextProvider>
              <GroupedActivityGallery />
            </GalleryContextProvider>
          </Route>
        </>
      )}
    </>
  );
};

export default Routes;
