import React from "react";
import { Modal } from "@mui/material";
import {
  CustomRow,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import styles from "./ZxModal.module.scss";
import ZxText from "../ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { X } from "@phosphor-icons/react";
import { trackEvent } from "utils/event_tracking";

const ZxModal = ({
  isOpen,
  onClose,
  title,
  titlePadding,
  children,
  onCloseEvent = "",
  width = "540px",
}) => {
  const closeModal = (e) => {
    if (onCloseEvent) {
      trackEvent(onCloseEvent);
    }
    onClose();
  };
  return (
    <Modal open={isOpen} onClose={closeModal}>
      <PaddingComponent
        padding="16px"
        className={styles.container}
        style={{ width }}>
        <PaddingComponent padding={titlePadding}>
          <CustomRow justifyContent="space-between" alignItems="center">
            <ZxText style={TSTYLES.title2} fontSize={"18px"}>
              {title}
            </ZxText>
            <PaddingComponent
              onClick={closeModal}
              // padding="4px"
              className={styles.closeBtn}
              backgroundColor="var(--elevation-2)">
              <X color="var(--text-2)" size={16} onClick={onClose} />
            </PaddingComponent>
          </CustomRow>
          <Divider margin="12px 0" />
        </PaddingComponent>
        <PaddingComponent>{children}</PaddingComponent>
      </PaddingComponent>
    </Modal>
  );
};

export default ZxModal;
