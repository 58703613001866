import React, { useContext } from "react";
import ContentRenderer from "components/FeedItemV2/ContentRenderer";

import { processSeenFeedItems } from "api/feed.api";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { AuthContext } from "contextStates/AuthContext";
import TopPnl from "zxComponents/feed/TopPnl/TopPnl";
import FollowSuggestions from "zxComponents/feed/FollowSuggestions/FollowSuggestions";
import TrendingCards from "zxComponents/feed/TrendingCards/TrendingCards";
import { getUniqueId } from "utils/event_tracking";
import TrendingContracts from "zxComponents/feed/TrendingContracts/TrendingContracts";
import SelectableDivWithDelay from "components/SelectableDivWithDelay/SelectableDivWithDelay";
import { handleFeedItemClick } from "utils/misc";
import useVisibilityTracker from "./useVisibilityTracker";
import { logSentryEvent } from "../../utils/sentry";
import { signedRequest } from "api/api";
import LocalTrendingFeedItem from "zxComponents/feed/LocalTrendingFeedItem/LocalTrendingFeedItem";

const batchRecordVisibility = async (itemIds) => {
  try {
    await processSeenFeedItems(itemIds);
  } catch (error) {
    logSentryEvent(error, {
      extra: {
        message: "Error calling process seen feed items!",
        itemIds,
      },
    });
  }
};

const makeTrackingClickCall = (typeIds) => {
  signedRequest({
    method: "post",
    path: "/api/v4/process_seen_feed_items",
    bodyText: JSON.stringify({ type_ids: typeIds }),
  });
};

const ContentPagesRenderer = ({
  pages,
  isTrending = false,
  isGlobalFeed = false,
  isTrendingTimeline = false,
  isHomePageFeedItem = false,
  isProfilePage = false,
  passPreferredActor = false,
}) => {
  useVisibilityTracker(batchRecordVisibility);

  return (
    <>
      {pages?.map((page, pageIndex) => (
        <RenderPage
          page={page}
          key={`page-${pageIndex}`}
          isTrending={isTrending}
          isGlobalFeed={isGlobalFeed}
          isTrendingTimeline={isTrendingTimeline}
          isHomePageFeedItem={isHomePageFeedItem}
          isProfilePage={isProfilePage}
          passPreferredActor={passPreferredActor}
        />
      ))}
    </>
  );
};

const RenderPage = ({
  page,
  isTrending,
  isHomePageFeedItem = false,
  isProfilePage = false,
  passPreferredActor = false,
}) => {
  if (isTrending) {
    return <TrendingCards isDataPassed={true} data={page?.data} />;
  }
  return (
    <RenderFeedPage
      page={page}
      isHomePageFeedItem={isHomePageFeedItem}
      isProfilePage={isProfilePage}
      passPreferredActor={passPreferredActor}
    />
  );
};

const FeedItemWrapper = styled.div`
  ${(props) => `
 ${props.isHomePageFeedItem ? "margin-bottom: 8px" : "margin-bottom: 12px"};
 ${props.isHomePageFeedItem ? "border: none" : "border: 1px solid var(--border-dark)"};
 ${!props.isHomePageFeedItem ? "border-radius: 8px" : "border-radius: 0"};
   background-color: var(--base); 
  `}
`;

const RenderFeedPage = React.memo(
  ({
    page,
    isHomePageFeedItem = false,
    isProfilePage,
    passPreferredActor = false,
  }) => {
    const displayedActivities = page?.data;
    const history = useHistory();
    const auth = useContext(AuthContext);
    const { isUserLoggedIn, isSuperuser } = auth;
    const trackClickedItem = (e, groupedActivity) => {
      if (!groupedActivity?.type || !groupedActivity?.blob_id) return;
      try {
        const data = [
          { id: groupedActivity.blob_id, type: groupedActivity.type },
        ];
        makeTrackingClickCall(data);
      } catch (err) {
        logSentryEvent(err, {
          extra: {
            message: "Error tracking clicked item!",
          },
        });
      } finally {
        return;
      }
    };
    return (
      <div key={getUniqueId()}>
        {displayedActivities?.map((groupedActivity, activityIndex) => {
          const isTrendingItem = groupedActivity.type === "trending_feed_index";
          const isTopPnlItem = groupedActivity.type === "top_pnl_index";
          const isLocalTrendingItem =
            groupedActivity.type === "local_trending_group";
          if (isLocalTrendingItem) {
            return (
              <LocalTrendingFeedItem
                isFirst={activityIndex === 0}
                key={
                  groupedActivity?.id && groupedActivity?.type
                    ? `${groupedActivity.type}-${groupedActivity.id}-${activityIndex}`
                    : activityIndex
                }
                groupedActivity={groupedActivity}
              />
            );
          }
          const isFollowSuggestionsItem =
            groupedActivity.type === "follow_suggestion_index";
          const isTrendingShown = isUserLoggedIn && isSuperuser;
          const isTrendingContractItem =
            groupedActivity.type === "trending_contracts_index";
          if (isTrendingContractItem) {
            return <TrendingContracts key={groupedActivity.type} />;
          }
          if (isTrendingItem) {
            return isTrendingShown ? (
              <TrendingCards key={groupedActivity.type} isDataPassed={false} />
            ) : null;
          }
          if (isFollowSuggestionsItem) {
            return <FollowSuggestions key={groupedActivity.type} />;
          }
          if (isTopPnlItem) {
            return <TopPnl key={groupedActivity.type} />;
          }
          return (
            <SelectableDivWithDelay
              clickable={true}
              key={
                groupedActivity?.id && groupedActivity?.type
                  ? `${groupedActivity.type}-${groupedActivity.id}-${activityIndex}`
                  : activityIndex
              }
              onClick={(e) => {
                trackClickedItem(e, groupedActivity);
                handleFeedItemClick({
                  contentId: groupedActivity.id,
                  contentType: groupedActivity.type,
                  link: groupedActivity.link,
                  history,
                  event: e,
                  clickable: true,
                  noRedirection: false,
                  repliesCount: groupedActivity?.engagements?.replies?.count,
                  extraParams:
                    passPreferredActor && groupedActivity?.actor
                      ? `?preferred_actor=${groupedActivity?.actor?.address}`
                      : ``,
                });
              }}>
              <div>
                <FeedItemWrapper
                  isProfilePage={isProfilePage}
                  isHomePageFeedItem={isHomePageFeedItem}
                  className="feed-activity"
                  key={`grouped-activity-${groupedActivity.id ?? activityIndex}`}
                  data-id={`${groupedActivity.blob_id}-${groupedActivity.type}`}>
                  <ContentRenderer
                    isHomePageFeedItem={isHomePageFeedItem}
                    groupedActivity={groupedActivity}
                    profiles={groupedActivity.profiles}
                    identities={groupedActivity.identities}
                    isProfilePage={isProfilePage}
                    showReplies
                    solidBg
                  />
                </FeedItemWrapper>
              </div>
            </SelectableDivWithDelay>
          );
        })}
      </div>
    );
  }
);

export default ContentPagesRenderer;
