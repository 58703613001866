import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { logSentryEvent } from "utils/sentry";
import { GlobalContext } from "./Global";
import { signedRequest } from "api/api";
import { trackEvent } from "utils/event_tracking";

export const FarcasterContext = createContext({});

const STATUS = {
  INIT: "INIT",
  POLLING: "POLLING",
  CREATING: "CREATING",
  CONNECTED: "CONNECTED",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const faxios = axios.create({
  baseURL: "https://relay.farcaster.xyz/v1",
});

const config = {
  domain: "0xppl.com",
  siweUri: "https://0xppl.com/",
};

const createFarcasterChannel = async () => {
  const res = await faxios.post("/channel", {
    ...config,
  });
  const data = res.data;
  if (!data?.channelToken)
    throw new Error("Failed to connect to farcaster, please try again later");
  return res.data;
};

const pollFarcasterChannel = async (channel) => {
  const res = await faxios.get(`/channel/status`, {
    headers: {
      Authorization: `Bearer ${channel}`,
    },
  });
  return res.data;
};

const verifyFarcaster = async ({ data, isSocialConnect }) => {
  const endpoint = !isSocialConnect
    ? "/api/farcaster/connect"
    : "/api/farcaster/verify";
  await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify(data),
  });
};
const FarcasterContextProvider = ({
  onConnect = null,
  onFinish = () => {},
  onSuccess,
  isSocialConnect = true,
  isOnboarding = true,
  ...props
}) => {
  const trackingPrefix = isOnboarding ? "ob_" : "profile_edit_";
  const [channel, setChannel] = useState(null);
  const [status, setStatus] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const {
    mutate: createChannelMutation,
    isPending: isCreatingChannel,
    isError: isCreateChannelError,
  } = useMutation({
    mutationFn: createFarcasterChannel,
    onSuccess: (data) => {
      if (isPopupOpen) {
        setChannel(data);
        setIsPolling(true);
        setStatus(STATUS.POLLING);
      }
    },
    onError: (error) => {
      handleErrorSnackbar(
        false,
        "Failed to connect to farcaster, please try again later"
      );
      setIsPolling(false);
      setStatus(STATUS.ERROR);
      setError(error);
    },
  });
  const createChannel = () => {
    setStatus(STATUS.CREATING);
    createChannelMutation();
  };
  const {
    mutate: verifyFarcasterSign,
    isPending: isVerifyLoading,
    isError: isVerifyError,
  } = useMutation({
    mutationFn: () => verifyFarcaster({ data: pollingData, isSocialConnect }),
    onError: (error) => {
      setIsPolling(false);
      handleErrorSnackbar(
        false,
        "Failed to connect farcaster, please try again later"
      );
      logSentryEvent("Farcaster Channel Verification Error", error);
      setStatus(STATUS.ERROR);
      onFinish("farcaster");
      trackEvent(trackingPrefix + "connect_socials_farcaster_status_failed");
    },
    onSuccess: () => {
      setStatus(STATUS.SUCCESS);
      setChannel(null);
      setIsPolling(false);
      onSuccess();
      handleSuccessSnackbar("Successfully connected to Farcaster");
      trackEvent(trackingPrefix + "connect_socials_farcaster_status_verified");
    },
  });
  const {
    data: pollingData,
    isLoading: isStatusLoading,
    isError: isStatusError,
  } = useQuery({
    queryKey: ["farcasterChannelPolling", channel?.channelToken],
    queryFn: () => pollFarcasterChannel(channel?.channelToken),
    retry: 1,
    enabled: isPolling,
    refetchInterval: 1500,
    staleTime: 0,
  });
  const isLoading = isCreatingChannel || isPolling;
  const isError = isCreateChannelError || isStatusError || isVerifyError;
  const isConnected = status === STATUS.SUCCESS && !isVerifyLoading;
  const stopPolling = () => {
    setIsPolling(false);
    setChannel(null);
  };
  useEffect(() => {
    if (pollingData?.state === "completed") {
      trackEvent(trackingPrefix + "connect_socials_farcaster_status_completed");
      setStatus(STATUS.CONNECTED);
      setIsPolling(false);
      setUserDetails(pollingData);
      if (onConnect) onConnect("farcaster");
      verifyFarcasterSign();
    }
  }, [pollingData, verifyFarcasterSign, onConnect, trackingPrefix]);

  return (
    <FarcasterContext.Provider
      value={{
        channel,
        setChannel,
        status,
        setStatus,
        userDetails,
        setUserDetails,
        error,
        setError,
        isPolling,
        setIsPolling,
        createChannel,
        isCreatingChannel,
        isCreateChannelError,
        pollingData,
        isStatusLoading,
        isStatusError,
        isLoading,
        isConnected,
        stopPolling,
        isError,
        isPopupOpen,
        setIsPopupOpen,
      }}>
      {props.children}
    </FarcasterContext.Provider>
  );
};
export default FarcasterContextProvider;
