import { CustomSkeleton } from "components/UI/Components/Components";
import React from "react";

const GhostTokenHoldings = ({ rightPane = false }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gridGap: "16px",
        width: "100%",
      }}>
      {Array(3)
        .fill()
        .map((_, index) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                padding: "0 20px",
              }}
              key={index}>
              <CustomSkeleton width={100} />
              <CustomSkeleton width={100} />
              <CustomSkeleton width={100} />
              {!rightPane && (
                <>
                  <CustomSkeleton width={100} />
                  <CustomSkeleton width={100} />
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default GhostTokenHoldings;
