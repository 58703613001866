import React, { useEffect, useRef } from "react";
import { trackEvent, MIXPANEL_EVENTS } from "utils/event_tracking";
import { useLocation } from "react-router-dom";

const BaseWrapper = ({ children, mixpanelEvent, mixpanelproperties }) => {
  const startTimeStamp = useRef();
  const timeSpentWhenFocused = useRef(0);
  const lastFocusTime = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    startTimeStamp.current = Date.now();
    lastFocusTime.current = startTimeStamp.current;

    const onFocus = () => {
      lastFocusTime.current = Date.now();
    };

    const onBlur = () => {
      timeSpentWhenFocused.current += Date.now() - lastFocusTime.current;
    };

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    trackEvent(`Web-Entered_${MIXPANEL_EVENTS[mixpanelEvent]}_screen`, {
      ...mixpanelproperties,
      pathname,
    });

    return () => {
      const endTimeStamp = Date.now();

      if (document.hasFocus()) {
        timeSpentWhenFocused.current += endTimeStamp - lastFocusTime.current;
      }

      if (MIXPANEL_EVENTS[mixpanelEvent]) {
        trackEvent(`Web-Exited_${MIXPANEL_EVENTS[mixpanelEvent]}_screen`, {
          ...mixpanelproperties,
          duration: timeSpentWhenFocused.current / 1000 + "s",
          pathname,
        });
      }
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, [mixpanelEvent, mixpanelproperties, pathname]);

  return <>{children}</>;
};

export default BaseWrapper;
