import {
  CustomColumn,
  CustomRow,
  CustomTextSpan,
  FlexBox,
  PaddingComponent,
  Divider,
  InfoBox,
  CustomCircularProgress,
} from "components/UI/Components/Components";
import { getShortMonthDayYearDate } from "utils/misc";
import { signedRequest } from "api/api";
import { getTwitterSearchResults } from "api/search.api";
import CustomTextFieldWaitlist from "components/UI/CustomComponents/CustomTextFieldWaitlist.styled";
import { useState, useCallback, useContext, useEffect } from "react";
import classes from "./UserInteractedTxnDetails.module.css";
import { InputAdornment } from "@mui/material";
import Bio from "components/V2/Profile/ProfileHeader/Bio";
import Image from "components/UI/Image";
import { GlobalContext } from "contextStates/Global";
import { followAddress } from "api/profile.api";

const UserInteractedTxnDetails = ({ profile, onFollow }) => {
  const [txnData, setTxnData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetch = useCallback(() => {
    setIsLoading(true);
    signedRequest({
      method: "get",
      path: `api/0xppl/get_top_interacted_accounts_token_transfers?identifier=${
        profile?.address || profile?.id
      }`,
    })
      .then((data) => {
        setIsLoading(false);
        setTxnData(data.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [profile]);

  useEffect(() => {
    if (profile) {
      fetch();
    }
  }, [fetch, profile]);

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CustomCircularProgress />
      </div>
    );
  }
  return (
    <CustomColumn>
      {profile?.id == null && (
        <NameAndTwitterDetails profile={profile} onFollow={onFollow} />
      )}
      {txnData?.total_transfer_count?.value > 0 && (
        <div className={classes.txnListBox}>
          <CustomTextSpan
            color="#687684"
            fontSize="14px"
            fontWeight="500"
            letterSpacing="0.01em"
            fontFamily="Outfit">
            {`You have ${txnData?.total_transfer_count?.display_value} transactions with each other totalling ${txnData?.total_usd_value_transfer?.display_value}`}
          </CustomTextSpan>
          <PaddingComponent height="20px" />
          <Divider />
          {txnData?.transfers.map((transfer, index) => (
            <TxnTile
              key={`users-interacted-tranfers${index}`}
              transfer={transfer}
            />
          ))}
        </div>
      )}
    </CustomColumn>
  );
};

const TxnTile = ({ transfer }) => {
  const isSent = transfer?.direction === "Sent";
  return (
    <CustomColumn>
      <PaddingComponent padding="16px 0px">
        <CustomRow alignItems="center">
          <FlexBox flex="1">
            <CustomTextSpan
              fontSize="14px"
              fontWeight="500"
              letterSpacing="0.01em"
              fontFamily="Outfit">
              {transfer?.direction}
            </CustomTextSpan>
          </FlexBox>
          <FlexBox flex="1">
            <CustomTextSpan
              fontSize="14px"
              fontWeight="400"
              letterSpacing="0.01em"
              fontFamily="Outfit">
              {getShortMonthDayYearDate(transfer?.timestamp)}
            </CustomTextSpan>
          </FlexBox>
          <FlexBox flex="1">
            <CustomRow justifyContent="flex-end">
              <CustomTextSpan
                color={isSent ? "rgba(226, 76, 76, 1)" : "#079D39"}
                fontSize="14px"
                fontWeight="500"
                letterSpacing="0.01em"
                fontFamily="Outfit">
                {`${isSent ? "-" : "+"} ${transfer?.quantity?.display_value} ${
                  transfer?.symbol
                }`}
              </CustomTextSpan>
            </CustomRow>
          </FlexBox>
          <FlexBox flex="1">
            <CustomRow justifyContent="flex-end">
              <CustomTextSpan
                fontSize="14px"
                fontWeight="400"
                letterSpacing="0.01em"
                fontFamily="Outfit">
                {transfer?.value_usd?.display_value}
              </CustomTextSpan>
            </CustomRow>
          </FlexBox>
        </CustomRow>
      </PaddingComponent>
      <Divider />
    </CustomColumn>
  );
};

const NameAndTwitterDetails = ({ profile, onFollow }) => {
  const [name, setName] = useState("");
  const [submittedName, setSubmittedName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { handleErrorSnackbar } = useContext(GlobalContext);

  const searchName = useCallback(() => {
    let timeOutId;
    const controller = new AbortController();

    if (name.length > 0) {
      setSearchResults([]);
      setIsLoading(true);
      timeOutId = setTimeout(() => {
        setSubmittedName("");
        getTwitterSearchResults({
          endpoint: "api/search/search_twitter",
          queryKey: ["follow_search", name],
          signal: controller.signal,
        })
          .then((data) => {
            if (data?.exact) {
              setSearchResults([data?.exact]);
            } else {
              setSearchResults(data?.search);
            }
            setSubmittedName(name);
            setIsLoading(false);
            if (data.length === 0) {
              handleErrorSnackbar(null, "No results found!");
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }, 500);
    }

    return () => {
      clearTimeout(timeOutId);
      if (!controller.signal.aborted) {
        controller.abort();
      }
    };
  }, [name, handleErrorSnackbar]);

  return (
    <div className={classes.nameAndTwitterBox}>
      <div className={classes.nameBox}>
        <CustomTextSpan
          fontSize="14px"
          fontWeight="600"
          letterSpacing="0.01em"
          fontFamily="Outfit">
          Do you know this wallet address?
        </CustomTextSpan>
        <div className={classes.form_group}>
          <CustomTextFieldWaitlist
            variant="outlined"
            type="text"
            label="Name"
            className="textField"
            onChange={(event) => {
              setName(event.target.value.trim());
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    className={`${classes.submitButton} ${
                      name ? "" : classes.disabled
                    }`}>
                    {isLoading ? (
                      <div>
                        <CustomCircularProgress isColorWhite={true} />
                      </div>
                    ) : (
                      <div onClick={searchName}>
                        <CustomTextSpan
                          color="white"
                          fontSize="14px"
                          fontWeight="600"
                          letterSpacing="0.01em"
                          fontFamily="Outfit">
                          Submit
                        </CustomTextSpan>
                      </div>
                    )}
                  </div>
                </InputAdornment>
              ),
              classes: {
                adornedEnd: classes.endInput,
              },
            }}
            value={name}
          />
        </div>
      </div>
      {!searchResults.length && !!submittedName.length && (
        <TwitterNotFound
          userProfile={profile}
          onFollow={onFollow}
          name={submittedName}
        />
      )}
      {searchResults.length === 1 && (
        <CustomTextBold text="Is this their twitter handle?" />
      )}
      {searchResults.length > 1 && (
        <CustomTextBold text="Is one of these their twitter handle?" />
      )}
      {getTwitterFirst3results({ searchResults }).map((item, index) => (
        <TwitterDetails
          key={`twitter-details-${index}`}
          onDiscard={() => {
            setSearchResults((prev) => {
              //delete this item from the array
              const newSearchResults = [...prev];
              newSearchResults.splice(index, 1);
              return newSearchResults;
            });
          }}
          userProfile={profile}
          twitterProfile={item?.data}
          onFollow={onFollow}
        />
      ))}
    </div>
  );
};

const getTwitterFirst3results = ({ searchResults }) => {
  if (searchResults.length > 3) {
    return searchResults.slice(0, 3);
  }
  return searchResults;
};

const TwitterNotFound = ({ userProfile, onFollow, name }) => {
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const [isFollowLoading, setIsFollowLoading] = useState(false);

  const onAcceptFollow = async () => {
    setIsFollowLoading(true);
    return followAddress({
      address: userProfile?.address,
      nickname: name,
    })
      .then(() => {
        setIsFollowLoading(false);
        onFollow();
        handleSuccessSnackbar("Followed successfully!");
      })
      .catch((err) => {
        handleErrorSnackbar(err);
        setIsFollowLoading(false);
      });
  };
  return (
    <div className={classes.twitterBox}>
      <FlexBox flex="1">
        <CustomTextBold
          text={`Could not find ${name} on twitter. Try another query or `}
        />
        <span onClick={onAcceptFollow} className={classes.cursorPointer}>
          {isFollowLoading ? (
            <CustomCircularProgress />
          ) : (
            <CustomTextBold color="#0648D7" text="Continue without twitter" />
          )}
        </span>
      </FlexBox>
    </div>
  );
};

const TwitterDetails = ({
  userProfile,
  onFollow,
  twitterProfile,
  onDiscard,
}) => {
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const [isFollowLoading, setIsFollowLoading] = useState(false);

  const onAcceptFollow = async () => {
    setIsFollowLoading(true);
    const payload = {
      address: userProfile?.address,
      nickname: twitterProfile?.display_name,
      bio: twitterProfile?.description,
      twitter: twitterProfile?.username,
    };
    return signedRequest({
      method: "post",
      path: "api/0xppl/onboarding_follow_via_twitter",
      bodyText: JSON.stringify(payload),
    })
      .then((data) => {
        setIsFollowLoading(false);
        onFollow();
        handleSuccessSnackbar("Followed successfully!");
      })
      .catch((err) => {
        handleErrorSnackbar(err);
        setIsFollowLoading(false);
      });
  };
  return (
    <div className={classes.twitterBox}>
      <InfoBox padding="14px 20px">
        <CustomColumn gap="10px">
          <CustomRow gap="16px">
            <Image
              src={twitterProfile?.display_picture}
              alt={twitterProfile?.display_name}
              className={classes.profile_image}
            />
            <FlexBox flex="1">
              <CustomColumn>
                <CustomTextBold text={twitterProfile?.display_name} />
                {twitterProfile?.username && (
                  <CustomTextLight text={`@${twitterProfile?.username}`} />
                )}
              </CustomColumn>
            </FlexBox>
            <CustomRow gap="16px">
              <div onClick={onAcceptFollow} className={classes.cursorPointer}>
                {isFollowLoading ? (
                  <CustomCircularProgress />
                ) : (
                  <CustomTextBold color="#0648D7" text="Yes" />
                )}
              </div>
              <div className={classes.verticalDivider} />
              <div onClick={onDiscard} className={classes.cursorPointer}>
                <CustomTextBold text="No" color="#687684" />
              </div>
            </CustomRow>
          </CustomRow>
          {twitterProfile?.description && (
            <Bio
              bio={twitterProfile?.description}
              className={classes.twitterBio}
              maxCharacters={200}
            />
          )}
        </CustomColumn>
      </InfoBox>
    </div>
  );
};

const CustomTextBold = ({ text, color }) => {
  return (
    <CustomTextSpan
      color={color}
      fontSize="14px"
      fontWeight="600"
      letterSpacing="0.01em"
      fontFamily="Outfit">
      {text}
    </CustomTextSpan>
  );
};

const CustomTextLight = ({ text, color }) => {
  return (
    <CustomTextSpan
      color={color ?? "#687684"}
      fontSize="14px"
      fontWeight="400"
      letterSpacing="0.01em"
      fontFamily="Outfit">
      {text}
    </CustomTextSpan>
  );
};

export default UserInteractedTxnDetails;
