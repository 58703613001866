import { AuthContext } from "contextStates/AuthContext";
import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { isTestingOnboarding } from "utils/constants";

export const ProtectedRoute = ({ component: Component, ...otherProps }) => {
  const { isUserLoggedIn } = useContext(AuthContext);

  if (!isUserLoggedIn && !isTestingOnboarding) {
    return (
      <Route>
        <Redirect to="/" />
      </Route>
    );
  }

  return (
    <Route
      render={(props) => {
        return <Component {...props} />;
      }}
      {...otherProps}
    />
  );
};
