import React from "react";
import styles from "./ContractLinkGlobe.module.css";
import { Globe } from "@phosphor-icons/react";
import ConditionalLink from "shared/ConditionalLink";
const ContractLinkGlobe = ({ link }) => {
  return (
    <ConditionalLink to={link}>
      <div className={styles["container"]}>
        <Globe size={20} color="var(--text-2)" />
      </div>
    </ConditionalLink>
  );
};

export default ContractLinkGlobe;
