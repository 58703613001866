import React, { useState, memo } from "react";
import PPLxTooltip from "../PPLxTooltip/PPLxTooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  CustomRow,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import classes from "./PPLxAccordion.module.css";

const PPLxAccordion = ({ title, helpTooltip, children, expanded }) => {
  const [isBodyExpanded, setIsBodyExpanded] = useState(() => expanded);

  return (
    <div className={classes.accordionWrapper}>
      <div
        className={classes.accordionHeader}
        onClick={() => setIsBodyExpanded((prev) => !prev)}>
        <CustomRow gap="5px" alignItems="center">
          {title}
          {helpTooltip?.length && (
            <PPLxTooltip title={helpTooltip} placement="top">
              <div className={classes.helpIcon}>?</div>
            </PPLxTooltip>
          )}
        </CustomRow>
        {isBodyExpanded ? (
          <ExpandLessIcon className={classes.collapseExpandIcon} />
        ) : (
          <ExpandMoreIcon className={classes.collapseExpandIcon} />
        )}
      </div>
      {isBodyExpanded && (
        <PaddingComponent padding="0px 20px">
          <Divider />
        </PaddingComponent>
      )}
      <div
        className={`${classes.body} ${isBodyExpanded ? classes.expanded : ""}`}>
        {!!isBodyExpanded && children}
      </div>
    </div>
  );
};

export default memo(PPLxAccordion);
