import React, { useContext, useEffect, useState } from "react";
import classes from "./SocialLogins.module.css";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  SpinnerComponent,
} from "components/UI/Components/Components";
import DynamicWalletButton from "components/DynamicWalletButton/DynamicWalletButton";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { getLensConnectChallenge, lensCallback } from "api/waitlist.api";
import { signDynamicWallet } from "contextStates/auth_utils";
import { GlobalContext } from "contextStates/Global";
import { trackEvent } from "utils/event_tracking";
import styles from "./SocialLogins.module.css";
import { SealCheck } from "@phosphor-icons/react";
import { ArrowSquareOut } from "../../../../../node_modules/@phosphor-icons/react/dist/index";
import { SocialFollowers } from "./SocialLogins";
import ZxText from "zxComponents/common/ZxText/ZxText";
const ConnectLens = ({
  onSuccess,
  disabled,
  isConnected,
  isFetching,
  follows = [],
  identities = {},
  customRender = null,
  isOnboarding = true,
  updateLoading = null,
}) => {
  const trackingPrefix = isOnboarding ? "ob_" : "profile_edit_";
  const { handleErrorSnackbar, handleLogOut, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const [isSignLoading, setIsSignLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(isConnected);
  useEffect(() => {
    if (isConnected) {
      setIsSuccess(true);
    }
    if (!isFetching && isSuccess && !isConnected) {
      setIsSuccess(false);
    }
  }, [isConnected, isFetching, isSuccess]);
  const onConnect = async (wallet) => {
    setIsSignLoading(true);
    if (updateLoading) updateLoading(true);
    try {
      const challenge = await getLensConnectChallenge({
        address: wallet.address,
      });
      trackEvent(trackingPrefix + "connect_socials_lens_challenge_success");
      const signature = await signDynamicWallet({
        message: challenge,
        wallet,
        logout: handleLogOut,
      });
      trackEvent(trackingPrefix + "connect_socials_lens_signature_success");
      const res = await lensCallback({
        address: wallet.address,
        signature,
      });
      handleSuccessSnackbar?.("Successfully connected to Lens");
      setIsSuccess(true);
      if (onSuccess) {
        onSuccess(res);
        trackEvent(trackingPrefix + "connect_socials_lens_success");
      }
    } catch (error) {
      console.log({ error });
      const message =
        error?.response?.data?.message ?? "Failed to connect to Lens";
      handleErrorSnackbar(null, message);
    } finally {
      setIsSignLoading(false);
      if (updateLoading) updateLoading(false);
    }
  };
  const { showAuthFlow } = useDynamicContext();
  const isLoading =
    isSignLoading || (isSuccess && !isConnected) || showAuthFlow;
  const isDisabled = disabled || isLoading || isConnected;
  const isFlowComplete = isSuccess && !isFetching && isConnected;
  const CustomRender = () => {
    if (customRender) {
      return customRender();
    }
    return (
      <div
        className={`${styles.row} ${styles.lensRow} ${isFlowComplete ? styles.connected : ``}`}>
        <CustomColumn>
          <CustomRow
            className={` ${isDisabled ? classes.disabled : ""}`}
            justifyContent="space-between"
            alignItems="center">
            <CustomRow gap="16px" alignItems="center">
              <img
                src="/svg/lens.svg"
                alt="Lens"
                className={classes.lensLogo}
              />
              <CustomText
                text={!isFlowComplete ? "Connect Lens" : "Lens Connected"}
                color={!isFlowComplete ? "var(--base)" : "var(--text-1)"}
                fontWeight="500"
                fontSize="15px"
                lineHeight="24px"
              />
            </CustomRow>
            <CustomRow alignItems="flex-end" gap="8px">
              {!isFlowComplete && follows?.length > 0 && (
                <ZxText color="white">
                  {follows.length} connections found
                </ZxText>
              )}
              {!isLoading && !isFlowComplete ? (
                <ArrowSquareOut color="var(--base)" size={20} />
              ) : isSuccess ? (
                <SealCheck color="var(--success)" weight="fill" size={20} />
              ) : (
                <SpinnerComponent color="var(--base)" />
              )}
            </CustomRow>
          </CustomRow>
        </CustomColumn>
      </div>
    );
  };
  return (
    <>
      <DynamicWalletButton
        isOnboarding={isOnboarding}
        customRender={CustomRender}
        connectOnly
        disabled={isSignLoading || isSuccess || disabled}
        onSuccess={onConnect}
        onClickEventName="ob_connect_socials_lens_click"
        isLogin={false}
        redirect={false}
        setLocalStorageKeys={false}
        setOpenReplayTagOnConnect={true}
      />
      {isFlowComplete && follows.length > 0 && !isLoading && (
        <SocialFollowers
          profileList={follows}
          identities={identities}
          title="Lens"
        />
      )}
    </>
  );
};

export default ConnectLens;
