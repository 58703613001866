import React, { useContext, useEffect, useState } from "react";
import NewComment from "../NewPost/Components/NewComment";
import GhostFeedItem from "../GhostFeedItem/GhostFeedItem";
import { AuthContext } from "contextStates/AuthContext";
import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
} from "components/UI/Components/Components";
import { getUniqueId } from "utils/event_tracking";
import ZxComment from "zxComponents/common/ZxComment/ZxComment";
import NewCommentModal from "components/ActivityEngagements/NewCommentModal";
import { useGetEngagements } from "api/feed.api";

const Comments = ({
  contentId,
  type,
  queryKeys,
  postDetails,
  groupedActivity,
  showNewComment = false,
  identities,
}) => {
  const [isNewPostLoading, setIsNewPostLoading] = useState(false);
  const { isUserLoggedIn, setShowLoginPopup, identityDetails } =
    useContext(AuthContext);
  const handleUnauthenticatedUser = () => {
    if (!isUserLoggedIn) {
      setShowLoginPopup(true);
    }
  };
  const userId = identityDetails?.current?.identity?.id;
  const postType = type ?? postDetails?.type ?? groupedActivity?.type;
  const postId = contentId ?? postDetails?.id ?? groupedActivity?.id;
  const engagementsQuery = useGetEngagements({
    id: postId,
    type: postType,
  });
  const query = engagementsQuery;
  const { data: engagements, refetch, isFetching, isLoading } = query;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [replyComment, setReplyComment] = useState(null);
  const comments = engagements?.replies?.threads ?? [];
  const [firstEngagements, setFirstEngagements] = useState(null);
  useEffect(() => {
    if (!firstEngagements && engagements && !showNewComment) {
      setFirstEngagements(engagements);
    }
  }, [firstEngagements, engagements, showNewComment]);
  const closeCommentModal = () => {
    setIsModalOpen(false);
    setReplyComment(null);
  };
  const threadIds = [];
  postDetails?.threads?.forEach((thread) => {
    threadIds.push(thread.id?.toString());
    threadIds.push(thread.blob_id?.toString());
  });
  const filteredComments =
    comments.filter((cm) => {
      const checkforId = threadIds.includes(cm.id?.toString());
      const checkforBlobId = threadIds.includes(cm.blob_id);
      return !(checkforId && checkforBlobId);
    }) ?? [];
  const shownComments = filteredComments
    ?.reverse()
    ?.slice(0, showNewComment ? filteredComments?.length : 1);
  const firstEngagementsComments =
    firstEngagements?.replies?.threads?.map((cm) => cm.id?.toString()) ?? [];
  const homePageComments =
    comments?.filter((cm) => {
      return firstEngagementsComments?.includes(cm.id?.toString());
    }) ?? [];
  useEffect(() => {
    if (showNewComment) {
      refetch();
    }
  }, [refetch, showNewComment]);
  return (
    <div onClick={(e) => e.stopPropagation()}>
      {showNewComment && (
        <NewComment
          queryKeys={queryKeys}
          contentId={contentId}
          type={type}
          postDetails={postDetails}
          isEditorFocused={false}
          setIsNewPostLoading={setIsNewPostLoading}
          handleOnFocus={handleUnauthenticatedUser}
          isUserLoggedIn={isUserLoggedIn}
        />
      )}

      {!isLoading ? (
        <CustomColumn gap="12px">
          {(showNewComment ? shownComments : homePageComments)
            ?.reverse()
            .map((comment, idx) => {
              const isLast = idx === shownComments?.length - 1;
              return (
                <ZxComment
                  key={`comment-${comment?.id ?? getUniqueId()}`}
                  comment={comment}
                  link={
                    showNewComment ? null : `/${postType}/${postId}/comments`
                  }
                  post={groupedActivity ?? postDetails}
                  openCommentModal={(comment) => {
                    setReplyComment(comment);
                    setIsModalOpen(true);
                  }}
                  refetchPostEngagements={refetch}
                  engagements={comment?.engagements}
                  currentIdentityId={userId}
                  identities={{
                    ...identities,
                    ...postDetails?.identities,
                    ...engagements?.identities,
                  }}
                  profiles={{
                    ...postDetails?.profiles,
                    ...engagements?.profiles,
                  }}
                  isLastComment={showNewComment && isLast}
                  nftMentions={{
                    ...(groupedActivity?.nft_collection_mentions ?? {}),

                    ...(engagements?.nft_collection_mentions ?? {}),
                  }}
                />
              );
            })}
          {isFetching && !showNewComment && (
            <CustomColumn gap="8px">
              {[1, 2, 3].map((i) => (
                <CustomRow gap="16px" key={i}>
                  <CustomSkeleton
                    variant="circular"
                    height="24px"
                    width="28px"
                  />
                  <CustomSkeleton
                    variant="rectangle"
                    width="100%"
                    height="20px"
                  />
                </CustomRow>
              ))}
            </CustomColumn>
          )}
        </CustomColumn>
      ) : (
        <CustomColumn gap="8px">
          {[1, 2, 3].map((i) => (
            <CustomRow gap="16px" key={i}>
              <CustomSkeleton variant="circular" height="24px" width="28px" />
              <CustomSkeleton variant="rectangle" width="100%" height="20px" />
            </CustomRow>
          ))}
        </CustomColumn>
      )}
      <NewCommentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={closeCommentModal}
        contentId={replyComment?.id}
        postDetails={replyComment}
        type={replyComment?.type}
        onSuccess={refetch}
        prefixContent={replyComment}
      />
      {isNewPostLoading && <GhostFeedItem />}
    </div>
  );
};
export default Comments;
