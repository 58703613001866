import panelClasses from "components/Pages/AppModule/RightPanel/RightPanel.module.css";
import classes from "./PersonSearch.module.css";
import { AuthContext } from "contextStates/AuthContext";
import { useContext, useEffect, useState, useRef } from "react";
import {
  InfoBox,
  CustomText,
  CustomRow,
  RightPanelCrossIcon,
  Divider,
  CustomColumn,
  PaddingComponent,
  FlexGrow,
  BootstrapInput,
  SpinnerComponent,
} from "components/UI/Components/Components";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import useTokenHoldings from "../TokenHoldings/useTokenHoldings";
import PersonSearch from "./PersonSearch";
import WalletPrivacy from "components/V2/Profile/ProfileDetails/wallets/components/WalletPrivacy";
import WalletVerifiedBadge from "components/V2/Profile/ProfileDetails/wallets/components/WalletVerifiedBadge";
import AddressComponent from "components/UI/Components/AddressComponent";
import { useBundleInfo } from "components/Pages/AppModule/RightSideBar/apis/useBundleInfo";
import {
  handleNetworkSwitching,
  connectWalletGetProviderAddress,
  TXN_TIMEOUT_ERROR,
} from "contextStates/auth_utils";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { CircularProgress, InputAdornment } from "@mui/material";
import { GlobalContext } from "contextStates/Global";
import contractABI from "./ERC20Abi";
import CustomTextField from "./CustomTextField";
import { initOnboard } from "contextStates/auth_utils";
import Web3 from "web3";
import { addWalletChain, WALLET_CHAIN_MISCONF } from "contextStates/auth_utils";
import { getChainIds } from "api/contract.api";
import Popup from "components/UI/Popup/Popup";
import { getTrimedAddress } from "utils/misc";
import { MenuItem, Select, IconButton } from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import { logSentryEvent } from "utils/sentry";
import { SendTokenContext } from "contextStates/SendTokenContext";
import Refetch from "components/UI/Refetch/Refetch";

export const computeDefaultIdentity = ({
  isAddress,
  identity,
  relatedIdentity,
  isLoggedInUserProfile,
}) => {
  if (isLoggedInUserProfile) {
    return null;
  }
  if (isAddress) {
    if (relatedIdentity) {
      return {
        type: "identity",
        data: relatedIdentity,
      };
    } else {
      return {
        type: "address",
        data: identity,
      };
    }
  }
  return {
    type: "identity",
    data: identity,
  };
};
const SendTokenPanel = ({ defaultToken, onClose }) => {
  const { identityDetails } = useContext(AuthContext);
  const { isAddress, identity, relatedIdentity, isLoggedInUserProfile } =
    useContext(SendTokenContext);
  const {
    handleErrorSnackbar,
    handleSuccessSnackbar,
    activeNotifications,
    currentActiveTransaction,
    listenToTransaction,
  } = useContext(GlobalContext);
  const loggedInUserDetails = identityDetails?.current?.identity;
  const isEVMAddress = identity?.address?.startsWith("0x");
  const [toAddress, setToAddress] = useState(
    isEVMAddress ? identity?.address : ""
  );
  const [fromAddress, setFromAddress] = useState("");
  const [amount, setAmount] = useState(null);
  const [tokenWallets, setTokenWallets] = useState([]);
  const [isSendingTokens, setIsSendingTokens] = useState(false);
  const [showAddToWallet, setShowAddToWallet] = useState(false);
  const [isAmountInUSD, setIsAmountInUSD] = useState(false);
  const [tokenData, setTokenData] = useState(defaultToken);
  const [shouldSwitchAccount, setShouldSwitchAccount] = useState(false);
  const [isMaxAmount, setIsMaxAmount] = useState(false);
  const amountInputRef = useRef();
  const {
    data: tokenHoldingsData,
    refetch,
    isError,
    isLoading,
    isFetching,
  } = useTokenHoldings({
    identifier: loggedInUserDetails?.id,
  });
  const chainProfiles = tokenHoldingsData?.chain_profiles;

  const { data: bundleInfoData } = useBundleInfo({
    identifier: loggedInUserDetails?.id,
  });

  const checkAndGetFromAddressConnected = async () => {
    const { wallets } = await connectWalletGetProviderAddress({
      skipReconnect: !shouldSwitchAccount,
    });
    if (!wallets || wallets.length === 0) {
      setShouldSwitchAccount(true);
      throw new Error({
        cause: {
          code: "WALLET_NOT_CONNECTED",
          message: "Please connect wallet " + fromAddress + "to continue",
        },
      });
    }
    const selectedWalletConnected = wallets.find((w) => {
      return w?.accounts.find(
        (a) =>
          Web3.utils.toChecksumAddress(a.address) ===
          Web3.utils.toChecksumAddress(fromAddress)
      );
    });
    if (selectedWalletConnected) {
      const web3 = new Web3(selectedWalletConnected?.provider);
      setShouldSwitchAccount(false);
      return {
        connectedAddress: fromAddress,
        web3,
        provider: selectedWalletConnected?.provider,
      };
    } else {
      setShouldSwitchAccount(true);
      // eslint-disable-next-line no-throw-literal
      throw {
        cause: {
          code: "WALLET_NOT_CONNECTED",
          message: "Please connect wallet " + fromAddress + " to continue",
        },
      };
    }
  };

  const onTransactionHash = (transactionHash) => {
    currentActiveTransaction.current = transactionHash;
    const link = `/${tokenData?.chain_id}/tx/${transactionHash}/`;
    const onboard = initOnboard();
    const notif = onboard.state.actions.customNotification({
      eventCode: "txPool",
      type: "pending",
      message:
        "Sending " +
        amount +
        " " +
        tokenData?.token_metadata?.symbol +
        " to " +
        getTrimedAddress(toAddress),
      autoDismiss: 0,
      link,
      onClick: () => {
        window.open(link, "_blank");
      },
    });
    activeNotifications.current = {
      ...activeNotifications?.current,
      [transactionHash]: notif,
    };
    const message =
      "Successfully sent " +
      amount +
      " " +
      tokenData?.token_metadata?.symbol +
      " to " +
      getTrimedAddress(toAddress);
    listenToTransaction({
      hash: transactionHash,
      chain: tokenData?.chain_id,
      successMessage: message,
      errorMessage: "Transaction failed!",
    });
    onClose();
  };

  const initTransaction = async () => {
    setIsSendingTokens(true);
    listenToTransaction({
      hash: null,
      chain: null,
      successMessage: null,
      errorMessage: null,
    });
    try {
      // check if connected address is same as from address
      const { provider, web3, connectedAddress } =
        await checkAndGetFromAddressConnected();
      // switch to correct network
      const currentNetworkChainId = await web3.eth.net.getId();
      const ethChainId = chainProfiles?.[tokenData?.chain_id]?.eth_chain_id;
      if (currentNetworkChainId !== ethChainId) {
        await handleNetworkSwitching({ provider, ethChainId });
      }
      // eslint-disable-next-line no-undef
      let finalAmount = BigInt(
        (isAmountInUSD
          ? (amount / tokenData?.usd_price?.value).toFixed(6)
          : amount) *
          10 ** tokenData?.token_metadata?.decimals
      );

      // check is token is native token of the chain
      if (
        tokenData?.token_metadata?.symbol ===
        chainProfiles?.[tokenData?.chain_id]?.native_token_details?.symbol
      ) {
        // Convert to BigInt
        const gasLimitWithBuffer = 100000n; // Adding a 10% buffer
        const tx = {
          from: connectedAddress, // Sender's address
          to: toAddress, // Recipient's address
          value: web3.utils.toHex(finalAmount),
          data: "0x",
          // eslint-disable-next-line no-undef
          gas: web3.utils.toHex(gasLimitWithBuffer),
          maxPriorityFeePerGas: web3.utils.toHex(gasLimitWithBuffer),
          // Gas price in wei
          // gas: web3.utils.toHex(gasPrice), // Gas price in wei
          // Convert ETH amount to wei
        };
        await web3.eth.sendTransaction(tx).on("transactionHash", (hash) => {
          onTransactionHash(hash);
        });
      } else {
        let contract = new web3.eth.Contract(
          contractABI,
          tokenData?.contract_address,
          {
            from: connectedAddress,
          }
        );
        const balance = await contract.methods
          .balanceOf(connectedAddress)
          .call();
        // Calculate token amount  with correct precision
        const finalTokens = isMaxAmount ? balance : finalAmount;
        // Estimate gas for the transaction
        const gasLimitWithBuffer = 200000n;
        // Get current gas price
        const gasPrice = (await web3.eth.getGasPrice()) * 10n;
        // eslint-disable-next-line no-undef
        const maxPriorityFeePerGas = BigInt(Math.ceil(Number(gasPrice) * 2)); // 20% of gas price as priority fee

        // Construct transaction object
        const tx = {
          from: connectedAddress,
          gas: web3.utils.toHex(gasLimitWithBuffer),
          maxPriorityFeePerGas: web3.utils.toHex(maxPriorityFeePerGas),
        };
        // Send transaction
        await contract.methods
          .transfer(toAddress, web3.utils.toHex(finalTokens))
          .send(tx)
          .on("transactionHash", onTransactionHash);
      }
    } catch (error) {
      console.error({ error });
      setIsSendingTokens(false);
      console.log({ error });
      logSentryEvent(error, {
        message: "Could not send transaction",
        fromAddress,
        toAddress,
        amount,
        tokenData,
        error,
      });
      let notification =
        activeNotifications.current?.[currentActiveTransaction?.current];
      if (notification) return;
      const code = error?.cause?.code ?? error?.code;
      if (code === "WALLET_NOT_CONNECTED") {
        handleErrorSnackbar(
          null,
          error?.cause?.message,
          false,
          { vertical: "bottom", horizontal: "left" },
          6000
        );
      } else if (error?.message === WALLET_CHAIN_MISCONF) {
        setShowAddToWallet(true);
      } else if (code === 4100) {
        const message =
          "The requested account and/or method has not been authorized by the user.";
        handleErrorSnackbar(
          null,
          message,
          false,
          { vertical: "bottom", horizontal: "left" },
          6000
        );
      } else if (code === 4001) {
        handleErrorSnackbar(
          null,
          "Transaction rejected",
          false,
          { vertical: "bottom", horizontal: "left" },
          6000
        );
      } else if (error?.name === TXN_TIMEOUT_ERROR) {
        setIsSendingTokens(false);
        logSentryEvent(error, {
          message: "Transaction timed out",
          fromAddress,
          toAddress,
        });
        return;
      } else {
        if (code === -32603) {
          handleErrorSnackbar(
            null,
            "Could not create transaction, insufficient gas",
            false,
            { vertical: "bottom", horizontal: "left" },
            6000
          );
        } else if (code === 1005) {
          handleErrorSnackbar(
            null,
            "Invalid address, could not send transaction",
            false,
            { vertical: "bottom", horizontal: "left" },
            6000
          );
        } else {
          handleErrorSnackbar(
            null,
            "Could not create transaction, please try again",
            false,
            { vertical: "bottom", horizontal: "left" },
            6000
          );
        }
      }
    }
    setIsSendingTokens(false);
  };
  useEffect(() => {
    const holdingsByAddress = tokenHoldingsData?.holdings_by_address || {};
    let wallets = [];
    for (let address in holdingsByAddress) {
      if (
        holdingsByAddress?.[address]?.holdings?.filter(
          (h) =>
            h?.contract_address === tokenData?.contract_address &&
            h?.chain_id === tokenData?.chain_id
        ).length > 0
      ) {
        wallets.push(address);
      }
    }
    wallets = wallets.sort((a, b) => {
      const aHoldings = tokenHoldingsData?.holdings_by_address[
        a
      ]?.holdings?.find(
        (h) =>
          h.contract_address === tokenData?.contract_address &&
          h.chain_id === tokenData?.chain_id
      );
      const bHoldings = tokenHoldingsData?.holdings_by_address[
        b
      ]?.holdings?.find(
        (h) =>
          h.contract_address === tokenData?.contract_address &&
          h.chain_id === tokenData?.chain_id
      );
      return aHoldings?.token_balance?.value > bHoldings?.token_balance?.value
        ? -1
        : 1;
    });
    if (fromAddress.trim().length === 0 && wallets.length > 0) {
      setFromAddress(wallets[0]);
    }
    setTokenWallets(wallets);
  }, [tokenHoldingsData, tokenData, fromAddress]);
  useEffect(() => {
    if (tokenData) {
      localStorage.setItem("sendDefaultToken", JSON.stringify(tokenData));
    }
    if (fromAddress) {
      localStorage.setItem("sendDefaultFromAddress", fromAddress);
    }
  }, [tokenData, fromAddress]);
  useEffect(() => {
    if (!tokenData && tokenHoldingsData?.holdings?.length > 0) {
      const oldToken = JSON.parse(localStorage.getItem("sendDefaultToken"));
      const isTokenPresent = tokenHoldingsData?.holdings?.find(
        (t) =>
          t.contract_address === oldToken?.contract_address &&
          t.chain_id === oldToken?.chain_id
      );
      if (isTokenPresent) {
        setTokenData(isTokenPresent);
      }
    }
  }, [tokenData, tokenHoldingsData]);
  useEffect(() => {
    if (defaultToken) {
      const foundToken = tokenHoldingsData?.holdings?.find(
        (token) =>
          token.contract_address === defaultToken?.token_address &&
          token.chain_id === defaultToken?.str_chain_id
      );
      if (foundToken) {
        setTokenData(foundToken);
      }
    }
  }, [tokenHoldingsData, defaultToken]);

  const fromAddressTokenHolding = tokenHoldingsData?.holdings_by_address[
    fromAddress
  ]?.holdings?.find(
    (h) =>
      h.contract_address === tokenData?.contract_address &&
      h.chain_id === tokenData?.chain_id
  );

  const validFromAddress = fromAddress.trim().length > 0;
  const validToAddress = toAddress.trim().length > 0;
  const isAmountEntered = amount && parseFloat(amount) !== 0;
  const validAmount =
    amount &&
    parseFloat(amount) > 0 &&
    (isAmountInUSD
      ? (amount / tokenData?.usd_price?.value).toFixed(6)
      : amount) <= fromAddressTokenHolding?.token_balance?.value;

  const sendButtonDisabled =
    !validFromAddress || !validToAddress || !validAmount || isSendingTokens;

  useEffect(() => {
    if (validFromAddress && validToAddress && amountInputRef?.current) {
      setTimeout(() => amountInputRef.current.focus(), 0);
    }
  }, [validFromAddress, validToAddress]);

  const valueToUSD = (val) => {
    if (!tokenData) {
      return "0";
    }
    return (val / tokenData?.usd_price?.value)
      .toFixed(6)
      .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
  };

  const valueToToken = (val) => {
    if (!tokenData) {
      return "";
    }
    return (val * tokenData?.usd_price?.value)
      .toFixed(2)
      .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
  };

  useEffect(() => {
    if (!isAmountInUSD) {
      if (amount) setAmount(valueToUSD(amount));
    } else {
      if (amount) setAmount(valueToToken(amount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAmountInUSD, tokenData]);

  const defaultIdentity = computeDefaultIdentity({
    isAddress,
    identity,
    relatedIdentity,
    isLoggedInUserProfile,
  });
  return (
    <div className={panelClasses.panel}>
      <div className={panelClasses.header}>
        <RightPanelCrossIcon onClick={onClose}></RightPanelCrossIcon>
        <CustomRow
          className={panelClasses.title}
          gap="8px"
          padding="2px 0 16px 0">
          {`Send`}
          {defaultToken ? (
            <>
              <IconWithChain
                src={tokenData?.token_metadata?.logo}
                alt={tokenData?.token_name}
                chain={tokenData?.chain_id}
                iconHeight="24px"
                iconWidth="24px"
                chainHeight="12px"
                chainWidth="12px"
                chainRightDistance="-4px"
                showImageFullHeightWidth
              />
              {`${tokenData?.token_name} on ${tokenData?.chain_id}`}
            </>
          ) : defaultIdentity ? (
            ` to ${defaultIdentity?.data?.display_name}`
          ) : null}
        </CustomRow>
        <span className={panelClasses.subtitle}>
          Send tokens to your friends on 0xPPL or to any wallet address
        </span>
      </div>
      <div className={classes.sendFormWrapper}>
        <InfoBox
          margin="16px 20px"
          background={"var(--base)"}
          width="calc(100% - 40px)"
          alignItems="flex-start"
          style={{
            borderColor: !validToAddress ? "var(--primary-color" : null,
          }}>
          <PaddingComponent padding="0 0 16px">
            <CustomText
              text="Name / Wallet Address"
              color="var(--text-1)"
              fontSize="13px"
              fontWeight="600"
            />
          </PaddingComponent>
          <PersonSearch
            toAddress={toAddress}
            setToAddress={setToAddress}
            hideChains={["Solana", "Cosmos", "Bitcoin"]}
            onUpdateCallback={() => {
              handleSuccessSnackbar("User updated successfully");
            }}
          />
        </InfoBox>
        <InfoBox
          margin="16px 20px"
          background={"var(--base)"}
          width="calc(100% - 40px)"
          alignItems="flex-start"
          style={{
            borderColor:
              validToAddress && !validFromAddress
                ? "var(--primary-color"
                : null,
          }}>
          <CustomRow gap="8px" padding="0 0 16px" alignItems="center">
            <CustomText
              text={
                defaultToken
                  ? "Choose your wallet with"
                  : "Select Token and your wallet"
              }
              color="var(--text-1)"
              fontSize="13px"
              fontWeight="600"
            />
            {defaultToken ? (
              <>
                <IconWithChain
                  src={tokenData?.token_metadata?.logo}
                  alt={tokenData?.token_name}
                  chain={tokenData?.chain_id}
                />
                <CustomText
                  text={tokenData?.token_name}
                  color="var(--text-1)"
                  fontSize="13px"
                  fontWeight="600"
                />
              </>
            ) : (
              ""
            )}
          </CustomRow>
          {!defaultToken && (
            <CustomRow width="100%">
              <Select
                size="small"
                sx={{
                  "& .MuiSelect-select": {
                    height: "20px",
                  },
                }}
                MenuProps={{
                  sx: {
                    ".MuiMenu-paper": {
                      backgroundColor: "var(--navBg)",
                    },
                  },
                }}
                fullWidth
                input={<BootstrapInput />}
                displayEmpty
                IconComponent={(props) => {
                  return (
                    <div
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 12,
                        color: "var(--text-2)",
                      }}>
                      <IconButton size="small" className={props?.className}>
                        <CaretDown size={16} />
                      </IconButton>
                    </div>
                  );
                }}
                value={
                  `${tokenData?.chain_id}_${tokenData?.contract_address}` ?? ""
                }>
                <MenuItem disabled value={undefined}>
                  <CustomRow padding="0 4px 0 4px">
                    {!isError && !isLoading && !isFetching ? (
                      <CustomText text="Select Token" color="var(--text-3)" />
                    ) : isFetching || isLoading ? (
                      <CustomRow gap="8px">
                        <CustomText
                          text="Loading tokens"
                          color="var(--text-3)"
                        />{" "}
                        <SpinnerComponent />
                      </CustomRow>
                    ) : null}
                  </CustomRow>
                </MenuItem>
                {!isError ? (
                  tokenHoldingsData?.holdings?.length !== 0 ? (
                    tokenHoldingsData?.holdings?.map((token) => {
                      if (!token?.total_usd_value?.value) return null;
                      if (
                        tokenHoldingsData?.chain_profiles?.[token?.chain_id]
                          ?.eth_chain_id
                      ) {
                        return (
                          <MenuItem
                            value={`${token?.chain_id}_${token?.contract_address}`}
                            onClick={() => {
                              setIsMaxAmount(false);
                              setFromAddress("");
                              setTokenData(token);
                              localStorage.setItem("defaultSendToken", token);
                            }}>
                            <CustomRow
                              alignItems="center"
                              gap="4px"
                              padding="0 4px 0 0"
                              width="100%">
                              <IconWithChain
                                src={token?.token_metadata?.logo}
                                alt={token?.token_name}
                                chain={token?.chain_id}
                              />
                              <CustomText text={token?.token_metadata.name} />
                              <FlexGrow />
                              <CustomText
                                text={token?.chain_id}
                                color="var(--text-3)"
                              />
                            </CustomRow>
                          </MenuItem>
                        );
                      }
                      return null;
                    })
                  ) : tokenHoldingsData?.holdings?.length === 0 ? (
                    <CustomText text="No tokens found" />
                  ) : null
                ) : (
                  <CustomColumn alignItems="center" justifyContent="center">
                    <CustomText text="Error fetching tokens" />
                    <Refetch
                      refetch={refetch}
                      showHeader={false}
                      showText={false}
                    />
                  </CustomColumn>
                )}
              </Select>
            </CustomRow>
          )}
          {tokenData ? (
            <>
              <CustomRow width="100%" padding="16px 0 8px">
                <CustomRow flexGrow={10}>
                  <CustomText
                    text="WALLET"
                    fontSize="11px"
                    fontWeight="500"></CustomText>
                </CustomRow>
                <CustomRow
                  flexShrink={0}
                  justifyContent="flex-start"
                  width={"35%"}>
                  <CustomText
                    text="NAME"
                    fontSize="11px"
                    fontWeight="500"></CustomText>
                </CustomRow>
                <CustomRow
                  flexShrink={0}
                  justifyContent="flex-end"
                  width={"15%"}>
                  <CustomText
                    text="AMOUNT"
                    fontSize="11px"
                    fontWeight="500"></CustomText>
                </CustomRow>
                <CustomRow
                  flexShrink={0}
                  justifyContent="flex-end"
                  width={"15%"}>
                  <CustomText
                    text="VALUE"
                    fontSize="11px"
                    fontWeight="500"></CustomText>
                </CustomRow>
              </CustomRow>
              <Divider />
              <PaddingComponent height="8px" />
            </>
          ) : (
            ""
          )}
          {tokenWallets.map((address, index) => {
            const wallet =
              bundleInfoData?.data?.data?.bundle?.wallets?.find(
                (w) => w.address === address
              ) || {};
            const holding = tokenHoldingsData?.holdings_by_address[
              address
            ]?.holdings?.find(
              (h) =>
                h.contract_address === tokenData?.contract_address &&
                h.chain_id === tokenData?.chain_id
            );
            return (
              <CustomRow
                width="100%"
                padding="8px 0"
                className={classes.walletRow}
                onClick={() => {
                  setFromAddress(address);
                }}>
                <CustomRow flexGrow={10} gap="4px" alignItems="center">
                  <input
                    type="radio"
                    name="wallet-selection"
                    value={address}
                    defaultChecked={fromAddress === address}
                  />
                  <WalletPrivacy wallet={wallet} size={16} />
                  <AddressComponent
                    textClassName={classes.addressText}
                    index={index + 1}
                    address={wallet?.address || address}
                    showTrimmed
                  />
                  <WalletVerifiedBadge wallet={wallet} size={16} />
                </CustomRow>
                <CustomRow
                  flexShrink={0}
                  justifyContent="flex-start"
                  width={"35%"}>
                  <CustomText
                    text={wallet?.reason}
                    color="var(--text-1)"></CustomText>
                </CustomRow>
                <CustomRow
                  flexShrink={0}
                  justifyContent="flex-end"
                  width={"15%"}>
                  <CustomText
                    text={holding?.token_balance?.display_value || "0"}
                    color="var(--text-1)"></CustomText>
                </CustomRow>
                <CustomRow
                  flexShrink={0}
                  justifyContent="flex-end"
                  width={"15%"}>
                  <CustomText
                    text={holding?.total_usd_value?.display_value || "0"}
                    color="var(--text-1)"></CustomText>
                </CustomRow>
              </CustomRow>
            );
          })}
        </InfoBox>
        <InfoBox
          margin="16px 20px"
          background={"var(--base)"}
          width="calc(100% - 40px)"
          alignItems="flex-start"
          style={{
            borderColor:
              validToAddress && validFromAddress && !isAmountEntered
                ? "var(--primary-color"
                : null,
          }}>
          <PaddingComponent padding="0 0 16px">
            <CustomText
              text="Add Amount"
              color="var(--text-1)"
              fontSize="13px"
              fontWeight="600"
            />
          </PaddingComponent>
          <Divider />
          <PaddingComponent height="16px" />
          <CustomRow gap="8px" padding="0 0 16px" maxWidth="400px">
            <CustomColumn alignItems="flex-start" width="100%">
              <CustomRow gap="4px" alignItems="center" width="100%">
                <div
                  className={`${classes.filterPill} ${
                    !isAmountInUSD ? classes.isActive : ""
                  }`}
                  onClick={() => {
                    setIsAmountInUSD(false);
                  }}>
                  Token Amount
                </div>
                <div
                  className={`${classes.filterPill} ${
                    isAmountInUSD ? classes.isActive : ""
                  }`}
                  onClick={() => {
                    setIsAmountInUSD(true);
                  }}>
                  USD Value
                </div>
              </CustomRow>
              <CustomRow gap="4px" alignItems="center" width="100%">
                <CustomRow flexGrow={1}>
                  <CustomTextField
                    variant="standard"
                    size="small"
                    type="number"
                    fullWidth
                    disabled={!tokenData}
                    inputProps={{
                      style: {
                        fontSize: "30px",
                        fontWeight: "700",
                      },
                    }}
                    InputProps={{
                      onWheel: (e) => e.target.blur(),
                      startAdornment: isAmountInUSD ? (
                        <InputAdornment position="start">
                          <PaddingComponent paddingBottom="4px">
                            <CustomText
                              text="$"
                              fontSize="30px"
                              fontWeight="700"
                            />
                          </PaddingComponent>
                        </InputAdornment>
                      ) : null,
                    }}
                    value={amount}
                    inputRef={amountInputRef}
                    placeholder="0.0"
                    onChange={(e) => {
                      setIsMaxAmount(false);
                      setAmount(e.target.value);
                    }}
                  />
                </CustomRow>
                <CustomRow
                  gap="4px"
                  flexGrow={1}
                  flexShrink={0}
                  alignItems="center"
                  justifyContent="flex-end">
                  <Image
                    src={tokenData?.token_metadata?.logo}
                    alt={tokenData?.token_name}
                    imageType={IMAGE_TYPES.TOKEN}
                    className={classes.tokenIcon}
                  />
                  <CustomText
                    text={tokenData?.token_name}
                    color="var(--text-1)"
                    fontSize="16px"
                    fontWeight="600"
                  />
                </CustomRow>
              </CustomRow>
              <CustomRow gap="4px" alignItems="center" width="100%">
                <CustomRow gap="4px" alignItems="center" flexGrow={1}>
                  <CustomText
                    className={classes.balanceText}
                    text={
                      isAmountInUSD
                        ? valueToUSD(amount) +
                          " " +
                          (tokenData?.token_metadata?.symbol
                            ? tokenData?.token_metadata?.symbol
                            : "")
                        : "$" + (amount ? valueToToken(amount) : "0")
                    }
                    onClick={() => {
                      setIsAmountInUSD((prev) => !prev);
                    }}
                    color="var(--text-2)"
                    fontSize="0.75rem"
                    lineHeight="1rem"
                    fontWeight="500"
                  />
                </CustomRow>
                <CustomRow
                  gap="8px"
                  alignItems="center"
                  justifyContent="flex-end">
                  <CustomText
                    lineHeight="1rem"
                    fontSize="0.75rem"
                    className={classes.balanceText}
                    onClick={() => {
                      setAmount(
                        isAmountInUSD
                          ? valueToToken(
                              fromAddressTokenHolding?.token_balance?.value
                            )
                          : fromAddressTokenHolding?.token_balance?.value
                      );
                    }}
                    text={
                      "Bal: " +
                      (fromAddressTokenHolding?.token_balance?.display_value ||
                        "0")
                    }
                  />
                  <button
                    className={classes.maxButton}
                    onClick={() => {
                      setIsMaxAmount(true);
                      setAmount(
                        isAmountInUSD
                          ? valueToToken(
                              fromAddressTokenHolding?.token_balance?.value
                            )
                          : fromAddressTokenHolding?.token_balance?.value
                      );
                    }}>
                    MAX
                  </button>
                </CustomRow>
              </CustomRow>
            </CustomColumn>
          </CustomRow>
        </InfoBox>
      </div>
      <button
        className={classes.sendButton}
        onClick={initTransaction}
        disabled={sendButtonDisabled}>
        {isSendingTokens && <CircularProgress color="inherit" size={16} />}
        Send
      </button>
      {showAddToWallet && (
        <Popup
          onClose={() => setShowAddToWallet(false)}
          title={`${tokenData?.chain_id} not configured`}
          width="400px">
          <p className={classes.popup_note}>
            Please add {tokenData?.chain_id} (chainid:
            {chainProfiles?.[tokenData?.chain_id]?.eth_chain_id}) to your wallet
            to submit the transaction.
          </p>
          <button
            onClick={async () => {
              try {
                const data = await getChainIds();
                const addToWalletPayload =
                  data?.[tokenData?.chain_id]?.add_to_wallet;
                const res = await addWalletChain(addToWalletPayload);
                if (res) {
                  handleSuccessSnackbar?.(res);
                  setShowAddToWallet(false);
                }
              } catch (err) {
                handleErrorSnackbar?.(
                  null,
                  `Failed to add chain to wallet with error: ${err?.message}`
                );
              }
            }}
            className={classes.add_to_wallet_button}>
            Add to wallet
          </button>
        </Popup>
      )}
    </div>
  );
};

export default SendTokenPanel;
