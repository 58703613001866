import PropTypes from "prop-types";
import styled from "styled-components";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";

export const Root = styled("div")(
  ({ theme }) => `
      color: ${false ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"};
      font-size: 15px;
      font-family: "ui-monospace";
      height: 100%;
    `
);

export const InputWrapper = styled("div")(
  ({ theme }) => `
      width: 100%;
      border-top: none;
      border-left: none;
      border-right: none;
      background-color: var(--inherit);
      padding: 1px;
      display: flex;
      flex-wrap: wrap;
      padding: 16px 20px;
      min-height: 256px;
      height: 100%;
    
      &.focused {    
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid var(--light-primary-color);
      }
    
      & input {
        background-color: var(--inherit);
        color: var(--text-1);
        height: 30px;
        box-sizing: border-box;
        padding: 4px 6px;
        width: 0;
        min-width: 100px;
        flex-grow: 1;
        border: 0;
        margin: 0;
        outline: 0;
      }
    `
);

export function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export const Listbox = styled("ul")(
  ({ theme }) => `
      min-width: 100px;
      margin: 2px 0 0;
      padding: 0;
      position: absolute;
      list-style: none;
      background-color: var(--background);
      overflow: auto;
      max-height: 250px;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      z-index: 1;
    
      & li {
        padding: 5px 12px;
        display: flex;
    
      
    
        & svg {
          color: transparent;
        }
      }
    
      & li[aria-selected='true'] {
        background-color: ${false ? "#2b2b2b" : "#fafafa"};
        font-weight: 600;
    
        & svg {
          color: #1890ff;
        }
      }
    
      & li.${autocompleteClasses.focused} {
        background-color: var(--elevation-1);
        cursor: pointer;
    
        & svg {
          color: currentColor;
        }
      }
    `
);
