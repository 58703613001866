import { getBundleData } from "api/profile.api";
import { useQuery } from "@tanstack/react-query";

export const useBundleInfo = ({
  identifier,
  enabled = true,
  bundleSuggestions = false,
}) => {
  const queryKey = ["bundle", identifier];
  const { data, isError, isLoading, isSuccess, refetch, isFetching } = useQuery(
    {
      queryKey: queryKey,
      queryFn: () => getBundleData({ identifier, bundleSuggestions }),
      staleTime: 1000 * 60 * 5,
      enabled: enabled && identifier != null,
      retry: 2,
    }
  );

  return { data, isError, isLoading, refetch, isSuccess, isFetching };
};
