import { useNewCommentMutation } from "api/feed.api";
import { GlobalContext } from "contextStates/Global";
import { useCallback, useContext, useEffect, useState } from "react";
import PostCommentRenderer from "../PostCommentRenderer";
import styles from "./NewPost.module.css";
const NewComment = ({
  contentId,
  type,
  queryKeys,
  setIsNewPostLoading = () => {},
  isEditorFocused,
  isModal = false,
  prefillCommentContent,
  onSuccess = () => {},
  onDiscard = () => {},
  handleOnFocus,
  isUserLoggedIn,
  postDetails,
  onCrossCommentClose = () => {},
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [commentSuccessResponse, setCommentSuccessResponse] = useState();
  const isLensFeatureEnabled =
    useContext(GlobalContext).featureFlags["is_lens_cross_post_enabled"];

  const localSuccess = (data) => {
    setCommentSuccessResponse(data);
    setIsSuccess(true);
    if (!isModal) {
      onSuccess(data);
    }
  };

  const {
    mutate: newCommentMutation,
    isPending: isLoading,
    isSuccess: commentPosted,
  } = useNewCommentMutation({
    onSuccess: localSuccess,
    setIsSuccess,
    setIsError,
    contentId,
    type,
    queryKeys,
  });

  useEffect(() => {
    if (!commentPosted) setIsSuccess(false);
    else setIsSuccess(true);
  }, [commentPosted]);

  useEffect(() => {
    setIsNewPostLoading(isLoading);
  }, [isLoading, setIsNewPostLoading]);

  const onSubmit = useCallback(
    ({ threads, selectedPlatforms, channelId }) => {
      newCommentMutation({
        urls: threads[0].urls,
        contents: threads[0].contents,
        id: contentId,
        images: threads[0].images,
        channelId: channelId,
        selectedPlatforms: isLensFeatureEnabled
          ? selectedPlatforms
          : selectedPlatforms.filter((platform) => platform !== "lens"),
      });
    },
    [contentId, newCommentMutation, isLensFeatureEnabled]
  );

  const placeHolder = isUserLoggedIn
    ? "Comment on this post..."
    : "Sign in to comment on this post";

  return (
    <div className={`${!isModal ? styles.container : ``}`}>
      <PostCommentRenderer
        prefillCommentContent={prefillCommentContent}
        isLoading={isLoading}
        onSubmit={onSubmit}
        isEditorFocused={isEditorFocused}
        isSuccess={isSuccess}
        setIsSuccess={setIsSuccess}
        isError={isError}
        setIsError={setIsError}
        postDisabled={!contentId || !isUserLoggedIn}
        placeholder={placeHolder}
        isModal={isModal}
        onDiscard={onDiscard}
        handleOnFocus={handleOnFocus}
        showNewPostIcon
        enableCrossPost={true}
        source="comment"
        isCrossPostDefaultSelected={false}
        postDetails={postDetails}
        commentSuccessResponse={commentSuccessResponse?.data ?? null}
        resetCommentSuccessResponse={() => setCommentSuccessResponse(null)}
        onCrossCommentClose={() => {
          onSuccess(commentSuccessResponse);
          onCrossCommentClose();
        }}
      />
    </div>
  );
};

export default NewComment;
