import React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import { styled } from "@mui/system";
import styles from "./ConnectWallet.module.css";

const StyledStepper = styled(({ stepperColor, currStep, ...res }) => (
  <MobileStepper {...res} />
))(({ theme, stepperColor, currStep }) => ({
  maxWidth: 400,
  flexGrow: 1,
  backgroundColor: "transparent",
  ".MuiMobileStepper-dot": {
    width: 6,
    height: 6,
    margin: "0 8px",
    backgroundColor: "rgba(104, 118, 132, 0.6)",
  },
  ".MuiMobileStepper-dotActive": {
    backgroundColor: stepperColor || "#1B1F22",
    width: 20,
    height: 6,
    borderRadius: 10,
  },
  // Custom style for dots before the current step
  [`& .MuiMobileStepper-dot:nth-of-type(-n + ${currStep})`]: {
    backgroundColor: "rgba(27, 31, 34, 1)",
  },
}));

const CustomMobileStepper = ({ currStep = 1, stepperColor, steps = 8 }) => {
  return (
    <div className={styles.paginationContainer}>
      <StyledStepper
        variant="dots"
        steps={steps}
        position="static"
        activeStep={currStep}
        stepperColor={stepperColor}
        currStep={currStep}
      />
    </div>
  );
};

export default CustomMobileStepper;
