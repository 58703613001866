import { useEffect, useRef, useState, useCallback } from "react";
import { useAudioPlayer } from "react-use-audio-player";
import classes from "./NftAudioPlayer.module.css";
import buttonClasses from "components/UI/Buttons/buttons.module.css";
import { Play, Pause } from "@phosphor-icons/react";
import NftCollectionInfo from "components/V2/NftItemDetails/NftCollectionInfo";
import { CircleAvatar } from "components/UI/Components/Components";
import { IMAGE_TYPES } from "components/UI/Image";
import { CircularProgress } from "@mui/material";
import audioPlayingAnimation from "assets/animations/audio-playing.json";
import Lottie from "lottie-react";

const NftAudioPlayer = ({ nftDetails, className }) => {
  const {
    playing,
    getPosition,
    duration,
    seek,
    src,
    togglePlayPause,
    load,
    play,
    stop,
  } = useAudioPlayer();
  const [pos, setPos] = useState(0);
  const frameRef = useRef();
  const seekBarElem = useRef(null);
  const [isLoading, setIsLoading] = useState(0);
  const lottieRef = useRef();

  useEffect(() => {
    const animate = () => {
      setPos(getPosition());
      frameRef.current = requestAnimationFrame(animate);
    };

    frameRef.current = window.requestAnimationFrame(animate);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goTo = useCallback(
    (event) => {
      const { pageX: eventOffsetX } = event;

      if (seekBarElem.current) {
        const elementOffsetX = seekBarElem.current.offsetLeft;
        const elementWidth = seekBarElem.current.clientWidth;
        const percent = (eventOffsetX - elementOffsetX) / elementWidth;
        seek(percent * duration);
        if (!playing) play();
      }
    },
    [duration, playing, seek, play]
  );

  useEffect(() => {
    if (playing) lottieRef.current?.play();
    else lottieRef.current?.pause();
    return () => {
      if (playing) stop();
    };
  }, [playing, stop]);

  if (duration === Infinity) return null;

  return (
    <div className={classes.audioPlayer}>
      <div className={classes.artwork}>
        <CircleAvatar
          borderRadius="8px"
          height="120px"
          width="120px"
          src={
            nftDetails?.image_url || nftDetails?.scaled_src || nftDetails?.src
          }
          imageType={IMAGE_TYPES.NFT}
          flexShrink="0"
          objectFit="cover"
        />
      </div>
      <div className={classes.nftInfo}>
        <div className={classes.audioControlsTop}>
          <div className={classes.info}>
            <NftCollectionInfo
              collectionInfo={
                nftDetails?.collection || {
                  name: nftDetails?.collection_name,
                  image_url: nftDetails?.collection_image_url,
                }
              }
              showTag={false}
            />
            <div className={classes.title}>
              <span>{nftDetails?.name || nftDetails?.alt}</span>
              <Lottie
                lottieRef={lottieRef}
                style={{ height: 24, width: 24 }}
                autoplay={false}
                animationData={audioPlayingAnimation}
              />
            </div>
          </div>
        </div>
        <div
          className={classes.audioControlsBottom}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div className={classes.audioControls}>
            <button
              className={buttonClasses.secondaryButton}
              onClick={() => {
                if (src !== nftDetails?.audio_url) {
                  setIsLoading(1);
                  load(nftDetails?.audio_url, {
                    autoplay: true,
                    onload: () => {
                      setIsLoading(2);
                    },
                  });
                } else {
                  togglePlayPause();
                }
              }}>
              {playing ? (
                <Pause size={16} weight="fill" />
              ) : isLoading === 1 ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                <Play size={16} weight="fill" />
              )}
            </button>
          </div>
          <div
            className={classes.audioSeekBar}
            ref={seekBarElem}
            onClick={goTo}>
            <div
              style={{ width: `${(pos / duration) * 100}%` }}
              className={classes.audioSeekBarTick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftAudioPlayer;
