import React, { useContext } from "react";
import Modal from "@mui/material/Modal";
import { ReactComponent as CloseModalIcon } from "assets/svg/feed/close_modal_icon.svg";
import classes from "./newCommentModal.module.css";
import NewComment from "components/Feed/NewPost/Components/NewComment";
import {
  ancestorsToQueryKeys,
  transformGroupedActivityQuery,
} from "utils/misc";
import { AuthContext } from "contextStates/AuthContext";

const NewCommentModal = ({
  isModalOpen = false,
  setIsModalOpen = () => {},
  contentId,
  type,
  postAncestors,
  prefillCommentContent,
  onSuccess,
  postDetails,
  prefixContent = null,
}) => {
  const handleClose = () => setIsModalOpen?.(false);
  const { isUserLoggedIn, setShowLoginPopup } = useContext(AuthContext);
  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <div className={classes.modalContainer}>
        <div className={classes.modalTitleWrapper} id="modal-modal-title">
          <button className={classes.close} onClick={handleClose}>
            <CloseModalIcon />
          </button>
          <div className={classes.title}>Reply</div>
        </div>
        <div className={classes.modalContentWrapper}>
          <NewComment
            prefillCommentContent={prefillCommentContent}
            contentId={contentId}
            type={type}
            isEditorFocused={true}
            onSuccess={(data) => {
              onSuccess?.(data);
              handleClose();
            }}
            postDetails={postDetails}
            onDiscard={handleClose}
            handleOnFocus={setShowLoginPopup}
            isUserLoggedIn={isUserLoggedIn}
            onCrossCommentClose={() => {
              handleClose();
            }} // this is to show the modal and reset state when cross comment is closed
            isModal
            // since grouped activity doesn't have ancestors, just pass the current query key
            queryKeys={[
              ...ancestorsToQueryKeys(postAncestors),
              transformGroupedActivityQuery(contentId, type, "", true),
            ]}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewCommentModal;
