import classes from "./ContractItem.module.css";
import ChainIcon from "components/UI/ChainIcon";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

const ContractItem = ({ contractProfile, label, tvl }) => {
  const { address_chain, link, display_name, address } = contractProfile;
  return (
    <Link to={link} className={classes.contract_item} key={address}>
      <div className={classes.contract_item_left}>
        <div className={classes.contract_item_left_top}>{display_name}</div>
        <div className={classes.contract_item_left_bottom}>
          {address.slice(0, 10)}
          {tvl && ` · TVL ${tvl.display_value}`}
        </div>
      </div>
      <div className={classes.contract_item_right}>
        {label && <div className={classes.label}>{label}</div>}
        {address_chain && address_chain !== "_Any" && (
          <ChainIcon chainId={address_chain} className={classes.chain_icon} />
        )}
        {address_chain && address_chain === "_Any" && (
          <ChainIcon className={classes.chain_icon} />
        )}
        <Link to={link} className={classes.link} target="_blank">
          <ChevronRight className={classes.chevron_right} />
        </Link>
      </div>
    </Link>
  );
};

export default ContractItem;
