import ProtocolHoldings from "components/V2/Profile/ProfileDetails/Portfolio/ProtocolHoldings/ProtocolHoldings";
import TokenHoldings from "components/V2/Profile/ProfileDetails/Portfolio/TokenHoldings/TokenHoldings";
import TimelineGraphWrapper from "components/V2/ChartGraph/TimelineGraphWrapper";
import { CustomColumn } from "components/UI/Components/Components";

// TODO: Add support for both TVL and Treasury tab
const ProtocolPortfolio = ({
  queryData,
  protocol,
  protocolStats,
  tvl,
  scrollableRef,
}) => {
  return (
    <CustomColumn gap="16px">
      <TimelineGraphWrapper protocolStats={protocolStats} />
      <TokenHoldings forProtocolTreasury identifier={protocol} />
      <ProtocolHoldings forProtocolTreasury identifier={protocol} />
    </CustomColumn>
  );
};

export default ProtocolPortfolio;
