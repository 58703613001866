import classes from "./NotificationsPage.module.css";
import {
  CustomColumn,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import NotificationsList from "./NotificationList";
import LoginCheckWrapper from "components/core/LoginCheckWrapper";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const NotificationsPage = () => {
  return (
    <BaseWrapper mixpanelEvent="NotificationsPage">
      <LoginCheckWrapper
        title="Sign in to view your Notifications"
        subtitle="Stay notified on interactions and activities from your network and notables">
        <div className={classes.notifications}>
          <CustomColumn>
            <PaddingComponent height="24px" />
            <span className={classes.title}>Your Notifications</span>
            <PaddingComponent height="16px" />
            <Divider />
            <NotificationsList />
          </CustomColumn>
        </div>
      </LoginCheckWrapper>
    </BaseWrapper>
  );
};

export default NotificationsPage;
