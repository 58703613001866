import { trackEvent } from "utils/event_tracking";
import {
  CustomRow,
  CustomText,
  Divider,
  FlexGrow,
  PaddingComponent,
} from "../Components/Components";
import classes from "./Popup.module.css";
import { X } from "@phosphor-icons/react";

const Popup = ({
  children,
  onClose,
  bgColor = "var(--navBg)",
  title,
  crossSize = 20,
  isDivider = false,
  titleFontSize = "24px",
  horizontalPadding = "20px",
  verticalPadding = "0px",
  width = "560px",
  titleFontWeight = "500",
  titleAlign = "center",
  titleLetterSpacing = "auto",
  header = null,
  onCloseEvent = null,
  position = "fixed",
}) => {
  return (
    <div className={classes.popup} style={{ position }}>
      <div
        className={classes.glass}
        onClick={(e) => {
          e.stopPropagation();
          onClose(false);
          if (onCloseEvent) {
            trackEvent(onCloseEvent);
          }
        }}>
        <div
          className={classes.popup_content}
          style={{
            backgroundColor: bgColor,
            width: width,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <PaddingComponent padding={`${verticalPadding} ${horizontalPadding}`}>
            <CustomRow alignItems="center">
              <FlexGrow style={{ justifyContent: titleAlign }}>
                <CustomRow justifyContent={titleAlign}>
                  {title && (
                    <CustomText
                      text={title}
                      fontWeight={titleFontWeight}
                      fontSize={titleFontSize}
                      color="var(--text-1)"
                      letterSpacing={titleLetterSpacing}
                      textAlign={titleAlign}
                    />
                  )}
                </CustomRow>
              </FlexGrow>
              <CrossIcon
                onClose={() => {
                  onClose();
                  if (onCloseEvent) {
                    trackEvent(onCloseEvent);
                  }
                }}
                size={crossSize}
              />
            </CustomRow>
          </PaddingComponent>
          {isDivider && <Divider borderColor="var(--border-light)" />}
          <PaddingComponent padding={`0px ${horizontalPadding}`}>
            {children}
          </PaddingComponent>
        </div>
      </div>
    </div>
  );
};

export const CrossIcon = ({ onClose, size }) => {
  return (
    <X
      size={size}
      color="var(--text-1)"
      onClick={(e) => {
        e.stopPropagation();
        onClose(false);
      }}
      style={{
        marginBottom: "2px",
        cursor: "pointer",
      }}
    />
  );
};

export default Popup;
