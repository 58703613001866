const queries = [
  {
    title: "Holdings of {{token}}",
    type: "dynamic",
    description: "Returns all addresses that hold the specified token.",
  },
  {
    title: "Holdings in {{protocol}}",
    type: "dynamic",
    description:
      "Returns all addresses that have holdings in the specified protocl.",
  },

  {
    title: "Withdrew from {{protocol}}",
    type: "dynamic",
    description:
      "Returns all addresses that withdrew from the specified protocol.",
  },
  {
    title: "Withdrew {{token}} from {{protocol}}",
    type: "dynamic",
    description:
      "Returns all addresses that withdrew the specified token from the specified protocol.",
  },
  {
    title: "Deposits into {{protocol}}",
    type: "dynamic",
    description:
      "Returns all addresses that deposited into the specified protocol.",
  },
  {
    title: "Deposits of {{token}} in {{protocol}}",
    type: "dynamic",
    description:
      "Returns all addresses that deposited the specified token into the specified protocol.",
  },
  {
    title: "Interacted with {{protocol}}",
    type: "dynamic",
    description:
      "Returns all addresses that interacted with the specified protocol.",
  },
  {
    title: "Net worth",
    type: "static",
    editable: "No",
    description:
      "Returns all addresses that have a net worth greater than or less than. For example Net Worth > $100m.",
  },
  {
    title: "Followers",
    type: "static",
    editable: "No",
    description: "Profiles which have followers > 100",
  },
  {
    title: "is following {{profile}}",
    type: "dynamic",
    description: "Profiles which are following the specified profile.",
  },
  {
    title: "on {{chain}}",
    type: "dynamic",
    description: "Filters the query by chain. For example, on Ethereum.",
  },
  {
    title: "on {{protocol}}",
    type: "dynamic",
    description: "Filters the query by protocol. For example, on Uniswap.",
  },
  {
    title: "on {{date}}",
    type: "dynamic",
    description: "Filters the query by date. For example, on 2021-10-01.",
  },
  {
    title: "AND",
    type: "static",
    editable: "No",
    description: "Used to join two queries",
  },
  {
    title: "OR",
    type: "static",
    editable: "No",
    description: "Used to join two queries",
  },
];

export default queries;
