import { CustomColumn, CustomRow } from "components/UI/Components/Components";
import Graph from "components/V2/Token/TokenChart/Graph";
import React from "react";
import { Chart } from "utils/constants";
import GraphLoader from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";
import GenericFallBackUI from "components/ErrorBoundary/GenericFallBackUI";
import styles from "./ZxCommonChart.module.scss";
import { EMPTY_STATES } from "zxStyles/constants";
import ZxEmptyState from "../ZxEmptyState/ZxEmptyState";
const ZxCommonChart = ({
  graphData,
  width = 700,
  height = 400,
  formatter = (d) => d,
  leftHeader,
  currentDuration,
  setDuration,
  durations = Chart,
  isLoading,
  isError,
}) => {
  if (graphData.length === 0) {
    return <ZxEmptyState state={EMPTY_STATES.CHART} text="No data found" />;
  }
  return (
    <CustomColumn gap="16px">
      <CustomRow justifyContent="space-between">
        {leftHeader()}
        <CustomRow gap="16px">
          {Object.keys(durations.DURATION).map((key) => {
            const isSelected = durations.DURATION_DAYS[key] === currentDuration;
            return (
              <CustomColumn
                key={key}
                gap="4px"
                onClick={() => setDuration(durations.DURATION_DAYS[key])}>
                <span
                  className={`${styles.duration} ${isSelected ? styles.selected : ""}`}>
                  {key}
                </span>
              </CustomColumn>
            );
          })}
        </CustomRow>
      </CustomRow>
      {isLoading || isError ? (
        isLoading ? (
          <GraphLoader height={250} />
        ) : (
          <GenericFallBackUI />
        )
      ) : (
        <Graph
          graphData={graphData}
          margin={{ left: 0, right: 0, bottom: 0, top: 0 }}
          parentWidth={width}
          parentHeight={height}
          isXAxisVisible={true}
          isYAxisVisible={true}
          horizontalGridLines={0}
          verticalGridLines={0}
          gradientFill={true}
          hideHover={true}
          hideFinalVal={true}
          width={width}
          height={height}
          customFormat={formatter}
        />
      )}
    </CustomColumn>
  );
};

export default ZxCommonChart;
