import { useCallback, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { signedRequest } from "api/api";
import { GlobalContext } from "contextStates/Global";
import { QUERY_KEYS } from "utils/query_utils";
import { Chart } from "utils/constants";
import { getNetworthData, getNetworthGraphData } from "api/profile.api";
import { isProfilePage } from "utils/misc";

const useFetchProtocolHoldings = ({
  identifier,
  forProtocolTreasury = false,
  enabled = true,
}) => {
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const queryClient = useQueryClient();
  const [isErrorSnackbarDisplayed, setIsErrorSnackbarDisplayed] =
    useState(false);

  const endpoint = forProtocolTreasury
    ? "/api/v4/get_protocol_protocol_deposits"
    : "/api/0xppl/get_protocol_token_holdings";
  const getProtocolHoldings = async ({ slowPath }) => {
    if (forProtocolTreasury == null) {
      return {};
    }
    const resp = await signedRequest({
      method: "post",
      path: `${endpoint}?identifier=${identifier}&slow_path=${slowPath}`,
    });
    return resp?.data?.data;
  };
  const queryKey = useCallback(
    ({ slowPath }) => {
      return ["protocol-holdings", identifier, forProtocolTreasury, slowPath];
    },
    [identifier, forProtocolTreasury]
  );
  const history = useHistory();
  const location = history.location;
  const isHome = location.pathname?.includes("/home");
  const isProfile = isProfilePage(location.pathname);
  const onSuccess = useCallback(async () => {
    if (isHome || isProfile) {
      await queryClient.fetchQuery({
        queryKey: ["networth", identifier],
        queryFn: () => getNetworthData({ identifier }),
      });
      await queryClient.fetchQuery({
        queryKey: QUERY_KEYS.NETWORTH_GRAPH({
          identity: identifier,
          duration: Chart.DURATION["7D"],
        }),
        queryFn: () =>
          getNetworthGraphData({
            identifier,
            duration: Chart.DURATION_DAYS[Chart.DURATION["7D"]],
          }),
      });
    }
  }, [queryClient, identifier, isHome, isProfile]);

  const onError = (err) => {
    if (!isErrorSnackbarDisplayed) {
      handleErrorSnackbar(err, "Failed to get protocol holdings.");
      setIsErrorSnackbarDisplayed(true);
    }
  };

  const queryFastPath = useQuery({
    queryKey: queryKey({ slowPath: false }),
    queryFn: () => getProtocolHoldings({ slowPath: false }),
    enabled: enabled && identifier?.length > 0,
    retry: 1,
    retryOnMount: false,
    select: (resp) => ({
      ...resp,
      holdings: resp?.holdings,
    }),
    onError,
    staleTime: 1000 * 60,
  });
  const querySlowPath = useQuery({
    queryKey: queryKey({ slowPath: true }),
    queryFn: () => getProtocolHoldings({ slowPath: true }),
    enabled: enabled && identifier?.length > 0,
    retry: 1,
    retryOnMount: false,
    select: (resp) => ({
      ...resp,
      holdings: resp?.holdings,
    }),
    onError,
    staleTime: 1000 * 60,
  });
  const { isSuccess, data } = querySlowPath;
  useEffect(() => {
    if (isSuccess) {
      onSuccess(data);
    }
  }, [isSuccess, data, onSuccess]);
  if (querySlowPath.data) {
    return { ...querySlowPath };
  }
  return { ...queryFastPath };
};

export default useFetchProtocolHoldings;
