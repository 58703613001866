import React from "react";
import FeedItemV2 from "components/FeedItemV2/FeedItemV2";
import { PaddingComponent } from "components/UI/Components/Components";

const TrendingActivityTabDetails = ({ referenceActivities, activeTab }) => {
  if (activeTab === "reference_activities") {
    return (
      <div>
        <PaddingComponent height="16px" />
        {referenceActivities?.map((referenceActivity, index) => (
          <FeedItemV2
            groupedActivity={referenceActivity}
            key={`reference-activity-${referenceActivity?.group_id}-${index}`}
            noMarginTop
            showReplies
          />
        ))}
      </div>
    );
  }
};

export default TrendingActivityTabDetails;
