import classes from "./NotificationsPage.module.css";
import {
  CustomColumn,
  PaddingComponent,
} from "components/UI/Components/Components";
import NotificationTile from "./NotificationTile";
import GhostFeedItem from "components/Feed/GhostFeedItem/GhostFeedItem";
import React, { useContext, useEffect, useCallback, useState } from "react";
import StatusRenderer from "components/Feed/NewPost/Components/StatusRenderer";
import useTriggerNextPaginatedActivitiesPage from "customHooks/useTriggerNextPaginatedActivitiesPage";
import { useHistory } from "react-router-dom";
import { signedRequest } from "api/api";
import { discardAppDownloadNotification } from "./api";
import MobilePushNotificationTile from "./MobilePush";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import { AuthContext } from "contextStates/AuthContext";
import { useFetchFeed } from "api/feed.api";
import usePageTitle from "customHooks/usePageTitle";

const NotificationsList = () => {
  const { isUserLoggedIn } = useContext(AuthContext);
  usePageTitle({ title: "0xPPL - Notifications" });
  const {
    isLoading,
    isSuccess,
    isError,
    flattenedFeedData,
    isFetching,
    data,
    refetch,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useFetchFeed({ isNotifications: true, enabled: isUserLoggedIn });

  const { pages: queryData } = data || {};

  useTriggerNextPaginatedActivitiesPage({
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  });

  const [showmobilePushNotificationTile, setShowmobilePushNotificationTile] =
    useState(queryData?.[0]?.data?.push_mobile);

  const notificationActivities =
    queryData
      ?.map((page) => {
        return page?.data?.activities;
      })
      .flat() || [];

  const { setTitle } = useContext(LeftSideHeaderContext);
  const history = useHistory();
  useEffect(() => {
    setTitle("Notifications");
  }, [setTitle]);

  const handleTileClick = useCallback(
    (link) => {
      history.push(link);
    },
    [history]
  );

  useEffect(() => {
    return () => {
      refetch();
    };
  }, [refetch]);

  useEffect(() => {
    // make an API call to mark unread notifications as read
    const unreadNotificationActivities = notificationActivities.filter(
      (activity) => !activity.is_read
    );
    const unreadActivityIdEngagementTypeTuples = unreadNotificationActivities
      .filter(
        (activity) =>
          ![
            "follow",
            "bundle_change",
            "new_user",
            "followee_post",
            "shared_wallet",
            "group_invite",
          ].includes(activity.notification_type)
      )
      .map((activity) => [activity.activity_id, activity.notification_type]);

    const followerIdentityIds = unreadNotificationActivities
      .filter((activity) => activity.notification_type === "follow")
      .map((activity) => activity.followers)
      .flat();

    const unreadFolloweePostIds = unreadNotificationActivities
      .filter((activity) => activity.notification_type === "followee_post")
      .map((activity) => activity.activity_id)
      .flat();

    const privateWalletShareRequestIds = unreadNotificationActivities
      .filter((activity) => activity.notification_type === "shared_wallet")
      .map((activity) => activity.private_wallet_share_request_id)
      .flat();

    const readBundleChangeIdentityIds = unreadNotificationActivities
      .filter((activity) => activity.notification_type === "bundle_change")
      .map((activity) => activity.images_for_profiles)
      .flat();

    const readNewUserIdentityIds = unreadNotificationActivities
      .filter((activity) => activity.notification_type === "new_user")
      .map((activity) => activity.images_for_profiles)
      .flat();

    // call the API only if `unreadActivityIdEngagementTypeTuples` is not empty
    if (
      unreadActivityIdEngagementTypeTuples.length !== 0 ||
      followerIdentityIds.length !== 0 ||
      readBundleChangeIdentityIds.length !== 0 ||
      readNewUserIdentityIds.length !== 0 ||
      unreadFolloweePostIds.length !== 0 ||
      privateWalletShareRequestIds.length !== 0
    ) {
      markNotificationsAsRead({
        ActivityIdEngagementTypeTuples: unreadActivityIdEngagementTypeTuples,
        followerIdentityIds: followerIdentityIds,
        readBundleChangeIdentityIds: readBundleChangeIdentityIds,
        readNewUserIdentityIds: readNewUserIdentityIds,
        unreadFolloweePostIds: unreadFolloweePostIds,
        privateWalletShareRequestIds: privateWalletShareRequestIds,
      });
    }
  });

  return (
    <div className={classes.notifications}>
      {showmobilePushNotificationTile && (
        <MobilePushNotificationTile
          handleClick={() => {
            discardAppDownloadNotification();
            setShowmobilePushNotificationTile(false);
          }}
        />
      )}
      <CustomColumn>
        <PaddingComponent height="14px" />
        {notificationActivities.map((activity) => {
          return (
            <NotificationTile
              activity={activity}
              key={activity.timestamp}
              onTileClick={(event) => {
                if (event?.metaKey || event?.ctrlKey) {
                  window.open(activity.link, "_blank");
                } else {
                  handleTileClick(activity.link);
                }
              }}
            />
          );
        })}
      </CustomColumn>
      <StatusRenderer
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        refetch={refetch}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
        activities={flattenedFeedData}
        showShimmer={isFetchingNextPage}
        message="No notifications found."
      />
    </div>
  );
};

export const markNotificationsAsRead = async ({
  ActivityIdEngagementTypeTuples,
  followerIdentityIds,
  readBundleChangeIdentityIds,
  readNewUserIdentityIds,
  unreadFolloweePostIds,
  privateWalletShareRequestIds,
}) => {
  return await signedRequest({
    method: "post",
    path: "/api/fcm/mark_notifications_as_read",
    bodyText: JSON.stringify({
      activity_id_notification_type_tuples: ActivityIdEngagementTypeTuples,
      follower_identity_ids: followerIdentityIds,
      bundle_change_identity_ids: readBundleChangeIdentityIds,
      new_user_identity_ids: readNewUserIdentityIds,
      unread_followee_post_ids: unreadFolloweePostIds,
      private_wallet_share_request_ids: privateWalletShareRequestIds,
    }),
  });
};

export const NotificationLoader = () => {
  return (
    <div>
      {[...Array(5).keys()].map((i) => (
        <GhostFeedItem key={i} index={i}></GhostFeedItem>
      ))}
    </div>
  );
};

export default NotificationsList;
