import IconWithChain from "components/Pages/Screener/IconWithChain";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import React from "react";
import ConditionalLink from "shared/ConditionalLink";

export const TopProtocolCard = ({ topProtocols }) => {
  return (
    <CustomColumn
      width="100%"
      borderRadius="10px"
      border="1px solid var(--border-dark)"
      background="var(--base)"
      padding="14px "
      height="140px">
      <CustomRow justifyContent="space-between" alignItems="center">
        <CustomText lineHeight="130%" text="Top Protocols" />
        {/* <CustomText
          lineHeight="130%"
          text="View All"
          fontSize="12px"
          fontWeight="600"
        /> */}
      </CustomRow>
      <PaddingComponent height="8px" />
      <CustomRow gap="16px">
        {topProtocols?.map((item, index) => {
          return (
            <ConditionalLink key={item?.name} to={item.link}>
              <CustomColumn
                gap="6px"
                justifyContent="space-between"
                key={`${index}-${item?.name}`}>
                <IconWithChain
                  src={item?.icon}
                  alt={item.name}
                  iconHeight="32px"
                  iconWidth="32px"
                  chainHeight="14px"
                  chainWidth="14px"
                  showImageFullHeightWidth={true}
                  number={index + 1}
                />
                <CustomText
                  text={item?.name}
                  color="var(--text-1)"
                  fontWeight="600"
                  lineHeight="130%"
                  textAlign="left"
                />
                <CustomColumn>
                  <CustomText
                    text={item?.type}
                    fontSize="11px"
                    lineHeight="130%"
                    textAlign="left"
                  />
                  <CustomText
                    text={`TVL: ${item?.tvl?.display_value}`}
                    fontSize="11px"
                    lineHeight="130%"
                    textAlign="left"
                  />
                </CustomColumn>
              </CustomColumn>
            </ConditionalLink>
          );
        })}
      </CustomRow>
    </CustomColumn>
  );
};
