import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
} from "components/UI/Components/Components";
import React from "react";
import TrendingItemChartPreview from "components/Trending/TrendingItemChartPreview";
import { CaretUp, CaretDown } from "@phosphor-icons/react";
import ProfileCardShimmer from "components/V2/Profile/ProfileHeader/ProfileCardShimmer";
export const PriceCard = ({
  isLoading,
  pricePercentageChange,
  prices,
  topChild,
  cursor = "pointer",
  showbar = true,
  setOpenPriceModal = () => {},
  id,
}) => {
  if (isLoading) {
    return <ProfileCardShimmer />;
  }
  return (
    <CustomRow
      onClick={() => setOpenPriceModal(true)}
      flexShrink="0"
      width="188px"
      borderRadius="10px"
      border="1px solid var(--border-dark)"
      background="var(--base)"
      padding="14px 0px 14px 14px"
      height="140px"
      cursor={cursor}>
      <CustomColumn width="100%" justifyContent="space-between">
        {topChild}
        {showbar && (
          <Divider
            width="16px"
            background="var(--text-2)"
            borderRadius="20px"
            opacity="0.2"
            height="3px"
          />
        )}
        <CustomRow
          width="100%"
          justifyContent="space-between"
          alignItems="center">
          {pricePercentageChange && (
            <CustomRow alignItems="center" gap="6px">
              {pricePercentageChange?.direction >= 0 ? (
                <CaretUp size={9} color="var(--success)" weight="fill" />
              ) : (
                <CaretDown size={9} color=" var(--error)" weight="fill" />
              )}

              <CustomText
                color={
                  pricePercentageChange?.direction >= 0
                    ? "var(--success)"
                    : "var(--error)"
                }
                text={pricePercentageChange?.display_value}
                fontWeight="700"
              />
            </CustomRow>
          )}
          {prices && (
            <TrendingItemChartPreview
              id={id}
              width={80}
              height={40}
              graphData={prices}
              color={
                pricePercentageChange?.direction >= 0
                  ? "var(--success)"
                  : "var(--error)"
              }
              stopColor1={
                pricePercentageChange?.direction >= 0
                  ? "var(--success)"
                  : "var(--error)"
              }
              stopColor2={
                pricePercentageChange?.direction >= 0
                  ? "var(--success)"
                  : "var(--error)"
              }
              stopOpacity1={0.2}
              stopOpacity2={0}
            />
          )}
        </CustomRow>
      </CustomColumn>
    </CustomRow>
  );
};
