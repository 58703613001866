import classes from "./TransactionTokenTransfer.module.css";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import AddressComponent from "components/UI/Components/AddressComponent";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import {
  CustomRow,
  CustomTextSpan,
  PaddingComponent,
  RedirectLink,
} from "components/UI/Components/Components";
import { ArrowRight, Fire } from "@phosphor-icons/react";
import { HammerIcon } from "assets/svg/left_sidebar/CustomSvgIcons";
import { TableCell, TableRow } from "components/UI/Table/Table";
import OverflowText from "components/UI/OverflowText";

const AddressDetails = ({
  actor,
  displayName,
  address,
  image,
  isFromAddress,
}) => {
  if (
    actor == null ||
    (actor.display_name === "Null Address" && actor.disable_follow)
  ) {
    return (
      <div className={classes.addressRowTile}>
        {isFromAddress ? <MintedAddress /> : <BurntAddress />}
      </div>
    );
  }
  return (
    <ProfileHoverCard actor={actor}>
      <div className={classes.addressRowTile}>
        <RedirectLink link={actor?.address_link}>
          <Image
            className={classes.profileImage}
            src={image}
            imageType={IMAGE_TYPES.AVATAR}
            alt={"profile-image"}
            name={displayName}
          />
        </RedirectLink>
        <RedirectLink link={actor?.address_link}>
          <div className={classes.addressColumnTile}>
            <span className={classes.title}>{displayName}</span>
            <AddressComponent
              textClassName={classes.subtitle}
              address={address}></AddressComponent>
          </div>
        </RedirectLink>
      </div>
    </ProfileHoverCard>
  );
};

const AmountDetails = ({
  tokenValue,
  tokenSymbol,
  tokenImage,
  usdDisplayValue,
  tokenProfile,
  isRightPane = false,
}) => {
  return (
    <ProfileHoverCard actor={tokenProfile}>
      <div className={classes.amountTileColumn}>
        <div className={classes.amountRowTile}>
          <span className={classes.valueTitle}>{tokenValue}</span>
          <RedirectLink link={tokenProfile?.address_link}>
            <Image
              style={{
                marginTop: "0px",
              }}
              className={classes.amountTokenImage}
              src={tokenImage}
              imageType={IMAGE_TYPES.TOKEN}
              alt={"profile-image"}
            />
          </RedirectLink>
          <RedirectLink link={tokenProfile?.address_link}>
            <OverflowText
              className={classes.title}
              text={tokenSymbol}
              limit={isRightPane ? 10 : 129}
            />
          </RedirectLink>
        </div>
        <span className={classes.subtitle}>{usdDisplayValue}</span>
      </div>
    </ProfileHoverCard>
  );
};

const TransactionTokenTransferTile = ({ txnData, index, isRightPane }) => {
  const tokenTranfers = txnData?.token_transfers[index];
  const fromAddressData = txnData.profiles[tokenTranfers?.from_address];
  const toAddressData = txnData.profiles[tokenTranfers?.to_address];
  const tokenProfile = txnData.profiles[tokenTranfers?.token?.address];
  return (
    <TableRow noPadding>
      <TableCell style={{ flexBasis: "33%" }}>
        <AddressDetails
          isFromAddress={true}
          actor={fromAddressData}
          isRightAligned={true}
          image={fromAddressData?.display_picture}
          displayName={fromAddressData?.display_name}
          address={tokenTranfers?.from_address}
        />
      </TableCell>
      <TableCell style={{ flexBasis: "33%" }}>
        <AddressDetails
          isFromAddress={false}
          actor={toAddressData}
          image={toAddressData?.display_picture}
          displayName={toAddressData?.display_name}
          address={tokenTranfers?.to_address}
        />
      </TableCell>
      <TableCell alignRight style={{ flexBasis: "33%" }}>
        <AmountDetails
          tokenProfile={tokenProfile}
          tokenValue={tokenTranfers?.value?.display_value}
          tokenSymbol={tokenTranfers?.token?.symbol}
          tokenImage={tokenTranfers?.token?.logo}
          usdDisplayValue={tokenTranfers?.value_usd?.display_value}
          isRightPane={isRightPane}
        />
      </TableCell>
    </TableRow>
  );
};

export const MintedAddress = ({ showArrow = true }) => {
  return (
    <CustomRow gap="4px" alignItems="center">
      <div className={classes.iconWrapper}>
        <HammerIcon size={14} color="var(--text-2)" />
      </div>
      <PaddingComponent width="4px" />
      <CustomTextSpan fontWeight="500" color="var(--text-2)">
        Minted
      </CustomTextSpan>
      {showArrow && <ArrowRight size={12} color="var(--text-2)" />}
    </CustomRow>
  );
};
export const BurntAddress = () => {
  return (
    <CustomRow gap="4px" alignItems="center">
      <div className={classes.iconWrapper}>
        <Fire size={14} color="var(--text-2)" />
      </div>
      <PaddingComponent width="4px" />
      <CustomTextSpan fontWeight="500" color="var(--text-2)">
        Burned
      </CustomTextSpan>
    </CustomRow>
  );
};

export default TransactionTokenTransferTile;
