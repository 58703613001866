import { useQuery } from "@tanstack/react-query";
import { signedRequest } from "./api";
import { getTokenMetadata } from "./contract.api";
import { getTrendingAdditionalInfo } from "components/Trending/trendingUtils";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "utils/query_utils";
import { CODEX_NETWORKS, ONE_DAY } from "utils/constants";
import axios from "axios";

export const getTokenContractDetails = async ({ chainId, contractAddress }) => {
  const response = await signedRequest({
    method: "post",
    path: `/api/v4/get_contract_details`,
    bodyText: JSON.stringify({
      chain_id: chainId,
      contract_address: contractAddress,
    }),
  });
  return response.data.data;
};

export const getTokenSwapDetails = async ({
  chainId,
  contractAddress,
  pageParam,
  filterUser,
}) => {
  const filter = filterUser
    ? {
        filter_by_identifiers: [
          filterUser?.id ??
            filterUser?.identity_id ??
            filterUser?.username ??
            filterUser?.address,
        ],
      }
    : {};
  const cursor =
    pageParam?.end_cursor || pageParam?.start_cursor ? pageParam : {};
  const response = await signedRequest({
    method: "post",
    path: `/api/0xppl/get_recent_token_transfers`,
    bodyText: JSON.stringify({
      chain_id: chainId,
      token_address: contractAddress,
      ...filter,
      ...cursor,
    }),
  });
  return response.data.data;
};

export const getFollowerSwapDetails = async ({
  chainId,
  contractAddress,
  pageParam,
  filterUser,
  signal,
}) => {
  const cursor =
    pageParam?.end_cursor || pageParam?.start_cursor ? pageParam : {};
  const filter = filterUser
    ? {
        filter_by_identifiers: [
          filterUser?.id ??
            filterUser?.identity_id ??
            filterUser?.username ??
            filterUser?.address ??
            null,
        ],
      }
    : {};
  const response = await signedRequest({
    method: "post",
    path: `/api/0xppl/get_followee_token_transfers`,
    bodyText: JSON.stringify({
      chain_id: chainId,
      token_address: contractAddress,
      ...cursor,
      ...filter,
    }),
    signal,
  });
  return response.data.data;
};

export const useFetchTokenAdditionalData = ({ chainId, contractAddress }) => {
  return useQuery({
    queryKey: ["tokenAdditionalData", chainId, contractAddress],
    queryFn: () =>
      getTrendingAdditionalInfo({
        chainId,
        identifier: contractAddress,
      }),
    retry: 1,
    refetchOnWindowFocus: false,
  });
};

export const useFetchTokenMetadata = ({ chainId, contractAddress }) => {
  return useQuery({
    queryKey: ["tokenMetadata", chainId, contractAddress],
    queryFn: () => getTokenMetadata({ chainId, address: contractAddress }),
    retry: 1,
    refetchOnWindowFocus: false,
  });
};

export const getTransfersData = async ({
  retryCount = 0,
  params,
  prevData,
  type,
  stopLoop = false,
  pageParam,
}) => {
  if (retryCount >= 5) {
    return prevData;
  }
  retryCount += 1;
  const data =
    type === "recent"
      ? await getTokenSwapDetails({ ...params, pageParam })
      : await getFollowerSwapDetails({ ...params, pageParam });
  const transfers = data?.transfer_data ?? data?.recent_transfer_data ?? [];
  if (transfers.length === 0) {
    let count = 0;
    let returnData = data;
    let cursor = data?.end_cursor;
    if (stopLoop || !cursor || pageParam?.end_cursor) return data;
    for (let i = 0; i < 5; i++) {
      if (count > 5 || !cursor) {
        break;
      }
      count++;
      const newParams = { ...params, pageParam: { start_cursor: cursor } };
      try {
        const tryData =
          type === "recent"
            ? await getTokenSwapDetails(newParams)
            : await getFollowerSwapDetails(newParams);
        if (
          tryData?.transfer_data?.length > 0 ||
          tryData?.recent_transfer_data?.length > 0
        ) {
          returnData = tryData;
          break;
        }
        cursor = tryData?.end_cursor;
      } catch (err) {
        console.error({ err });
      }
    }
    return returnData;
  } else {
    return data;
  }
};

export const useFetchTokenSwapTableData = ({
  queryKey,
  chainId,
  contractAddress,
  filter,
  type,
  init = false,
  enabled = true,
}) => {
  return useInfiniteQuery({
    queryKey: [
      queryKey,
      chainId,
      contractAddress,
      filter?.id ?? filter?.username ?? filter?.address ?? null,
    ],
    queryFn: async ({ pageParam }) => {
      const params = {
        chainId,
        contractAddress,
        filterUser: filter ?? null,
      };
      const data = await getTransfersData({
        params,
        type,
        pageParam,
      });
      return data;
    },
    retry: init ? 3 : 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000,
    enabled,
    getPreviousPageParam: (firstPage) => {
      if (firstPage?.start_cursor) {
        return { end_cursor: firstPage?.start_cursor };
      }
      return undefined;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage?.end_cursor) {
        return { start_cursor: lastPage?.end_cursor };
      }
      return undefined;
    },
  });
};

export const getTokenPageData = async ({ chainId, contractAddress }) => {
  const res = await signedRequest({
    method: "post",
    path: `/api/v4/get_new_token_page_details?contract_address=${contractAddress}&chain_id=${chainId}&new_api=true`,
  });
  return res.data.data;
};

export const getTokenPageUserData = async ({ chainId, contractAddress }) => {
  const res = await signedRequest({
    method: "post",
    path: "/api/v4/get_user_specific_token_page_details",
    bodyText: JSON.stringify({
      chain_id: chainId,
      contract_address: contractAddress,
    }),
  });
  return res.data.data;
};

export const getTokenPageChartData = async ({ chainId, contractAddress }) => {
  const res = await signedRequest({
    method: "post",
    path: "/api/v4/get_token_chart_details",
    bodyText: JSON.stringify({
      chain_id: chainId,
      contract_address: contractAddress,
    }),
  });
  return res.data.data;
};

export const getTokenPageBasicDetails = async ({
  chainId,
  contractAddress,
}) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_basic_token_data",
    bodyText: JSON.stringify({
      chain_id: chainId,
      contract_address: contractAddress,
    }),
  });
  return response.data.data;
};

export const getTokenPageFolloweePNLs = async ({
  chainId,
  contractAddress,
}) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_followees_with_pnl",
    bodyText: JSON.stringify({
      chain_id: chainId,
      contract_address: contractAddress,
    }),
  });
  return response.data.data;
};

export const getContractInfo = async ({ chainId, contractAddress }) => {
  const res = await signedRequest({
    method: "post",
    path: "/api/v4/get_contract_info",
    bodyText: JSON.stringify({
      chain_id: chainId,
      contract_address: contractAddress,
    }),
  });
  return res.data.data;
};

export const getUserPnl = async ({ identifier, contractAddress, chainId }) => {
  const identity =
    identifier?.id ?? identifier?.username ?? identifier?.address ?? identifier;
  const res = await signedRequest({
    method: "post",
    path: "/api/v4/get_user_token_holding",
    bodyText: JSON.stringify({
      identity: identity,
      token_address: contractAddress,
      chain_id: chainId,
    }),
  });
  return res.data.data;
};

export const getTokenStats = async ({
  chainId,
  contractAddress,
  tokenType,
}) => {
  const codexChainId = CODEX_NETWORKS.find(
    (n) =>
      n.name === chainId || n.altName?.toLowerCase() === chainId?.toLowerCase()
  )?.id;
  const res = await axios.post(
    "https://square-dew-e2a0.subscriptions-95d.workers.dev/",
    {
      query: `query GetDetailedStats($pairId: String!, $tokenOfInterest: TokenOfInterest, $timestamp: Int, $windowSizes: [DetailedStatsWindowSize], $bucketCount: Int, $statsType: TokenPairStatisticsType) {
  getDetailedStats(
    pairId: $pairId
    tokenOfInterest: $tokenOfInterest
    timestamp: $timestamp
    windowSizes: $windowSizes
    bucketCount: $bucketCount
    statsType: $statsType
  ) {
    pairId
    tokenOfInterest
    statsType
    stats_min5 {
      ...WindowedDetailedStatsFields
      __typename
    }
    stats_hour1 {
      ...WindowedDetailedStatsFields
      __typename
    }
    stats_hour4 {
      ...WindowedDetailedStatsFields
      __typename
    }
    stats_hour12 {
      ...WindowedDetailedStatsFields
      __typename
    }
    stats_day1 {
      ...WindowedDetailedStatsFields
      __typename
    }
    __typename
  }
}

fragment WindowedDetailedStatsFields on WindowedDetailedStats {
  windowSize
  timestamp
  endTimestamp
  buckets {
    start
    end
    __typename
  }
  transactions {
    ...DetailedStatsNumberMetricsFields
    __typename
  }
  volume {
    ...DetailedStatsStringMetricsFields
    __typename
  }
  buys {
    ...DetailedStatsNumberMetricsFields
    __typename
  }
  sells {
    ...DetailedStatsNumberMetricsFields
    __typename
  }
  buyers {
    ...DetailedStatsNumberMetricsFields
    __typename
  }
  sellers {
    ...DetailedStatsNumberMetricsFields
    __typename
  }
  traders {
    ...DetailedStatsNumberMetricsFields
    __typename
  }
  buyVolume {
    ...DetailedStatsStringMetricsFields
    __typename
  }
  sellVolume {
    ...DetailedStatsStringMetricsFields
    __typename
  }
  __typename
}

fragment DetailedStatsNumberMetricsFields on DetailedStatsNumberMetrics {
  change
  currentValue
  previousValue
  buckets
  __typename
}

fragment DetailedStatsStringMetricsFields on DetailedStatsStringMetrics {
  change
  currentValue
  previousValue
  buckets
  __typename
}`,
      variables: {
        pairId: `${contractAddress}:${codexChainId}`,
        tokenOfInterest: tokenType,
        statsType: "FILTERED",
      },
    }
  );
  const isError = res?.data?.errors?.length > 0;
  if (isError) throw new Error("Error fetching token stats");
  return res.data.data.getDetailedStats;
};

export const getTokenPriceChange = async ({ chainId, contractAddress }) => {
  const codexChainId = CODEX_NETWORKS.find(
    (n) =>
      n.name === chainId || n.altName?.toLowerCase() === chainId?.toLowerCase()
  )?.id;
  const res = await axios.post(
    "https://square-dew-e2a0.subscriptions-95d.workers.dev/",
    {
      query: `query GetPairMetadata($pairId: String!, $quoteToken: QuoteToken, $statsType: TokenPairStatisticsType) {
  pairMetadata(pairId: $pairId, quoteToken: $quoteToken, statsType: $statsType) {
    createdAt
    price
    exchangeId
    fee
    id
    liquidity
    liquidityToken
    nonLiquidityToken
    pairAddress
    priceChange: priceChange24
    priceChange5m
    priceChange1
    priceChange12
    priceChange24
    priceChange4
    tickSpacing
    volume: volume24
    volume1
    volume12
    volume24
    volume4
    quoteToken
    statsType
    token0 {
      address
      decimals
      name
      networkId
      pooled
      price
      symbol
      labels {
        type
        subType
        createdAt
        __typename
      }
      __typename
    }
    token1 {
      address
      decimals
      name
      networkId
      pooled
      price
      symbol
      labels {
        type
        subType
        createdAt
        __typename
      }
      __typename
    }
    enhancedToken0 {
      id
      address
      decimals
      name
      networkId
      creatorAddress
      symbol
      __typename
    }
    enhancedToken1 {
      id
      address
      decimals
      name
      networkId
      creatorAddress
      symbol
      __typename
    }
    __typename
  }
}`,
      variables: {
        pairId: `${contractAddress}:${codexChainId}`,
        statsType: "FILTERED",
        operationName: "GetPairMetadata",
      },
    }
  );
  const isError = res?.data?.errors?.length > 0;
  if (isError) throw new Error("Error fetching token stats");
  return res.data.data.pairMetadata;
};

export const useTokenPagePnl = ({ chainId, contractAddress, identifier }) => {
  return useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_PNL({
      identifier,
      chainId,
      contractAddress,
    }),
    queryFn: () => getUserPnl({ identifier, contractAddress, chainId }),
    retry: 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: ONE_DAY,
    enabled: !!identifier,
  });
};

export const useGetTokenPageDetails = ({ chain, address }) => {
  return useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_TOKEN_DETAILS({
      chainId: chain,
      contractAddress: address,
    }),
    queryFn: () =>
      getTokenPageData({ chainId: chain, contractAddress: address }),
    retry: 1,
    refetchOnWindowFocus: false,
    staleTime: ONE_DAY,
  });
};

export const useGetTokenChartData = ({ chain, address }) => {
  return useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_CHART_DATA({
      chainId: chain,
      contractAddress: address,
    }),
    queryFn: () =>
      getContractInfo({ chainId: chain, contractAddress: address }),
    retry: 1,
    refetchOnWindowFocus: false,
    staleTime: ONE_DAY,
  });
};

export const useGetTokenPageBasicDetails = ({ chain, address }) => {
  return useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_BASIC_DETAILS({
      chainId: chain,
      contractAddress: address,
    }),
    queryFn: () =>
      getTokenPageBasicDetails({ chainId: chain, contractAddress: address }),
    retry: 1,
    refetchOnWindowFocus: false,
    staleTime: ONE_DAY,
  });
};

export const useGetTokenFolloweePNLs = ({ chain, address, signal }) => {
  return useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_FOLLOWEE_PNL({
      chainId: chain,
      contractAddress: address,
    }),
    queryFn: () =>
      getTokenPageFolloweePNLs({ chainId: chain, contractAddress: address }),
    retry: 1,
    refetchOnWindowFocus: false,
    staleTime: ONE_DAY,
  });
};

export const useGetTokenStats = ({ chain, address, tokenType = null }) => {
  return useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_STATS({
      chainId: chain,
      contractAddress: address,
    }),
    queryFn: () => getTokenStats({ chainId: chain, contractAddress: address }),
    retry: 1,
    refetchOnWindowFocus: false,
    staleTime: 5 * 1000,
    enabled: !!address,
    refetchInterval: 5 * 1000,
  });
};

export const useGetTokenPriceChange = ({ chain, address }) => {
  return useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_PRICE_CHANGE({
      chainId: chain,
      contractAddress: address,
    }),
    queryFn: () =>
      getTokenPriceChange({
        chainId: chain,
        contractAddress: address,
      }),
    retry: 1,
    refetchOnWindowFocus: false,
    staleTime: 5 * 1000,
    enabled: !!address,
    refetchInterval: 5 * 1000,
  });
};
