import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import { ClickAwayListener } from "../../../../node_modules/@mui/material/index";
import {
  CursorDiv,
  CustomColumn,
  CustomRow,
  CustomText,
  CustomTextSpan,
  FlexGrow,
  InfoBox,
  PaddingComponent,
} from "./Components";
import { CaretDown } from "@phosphor-icons/react";
import classes from "./CustomDropDown.module.css";
import { useState } from "react";

const CustomDropdownButton = ({
  icon,
  title,
  items,
  iconsList,
  onSelectItem,
  customClass = "",
  fontSize,
  customClassList = "",
  defaultOpen,
  hideDropDown,
  size = "small",
  color = "var(--text-1)",
  showSearch = false,
  listPositionClass = "",
}) => {
  const [open, setOpen] = useState(defaultOpen || false);
  const handleItemSelect = (item, index) => {
    onSelectItem(item, index);
    setOpen(false);
  };

  if (open) {
    return (
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div>
          <PPLxHoverCard
            open={open}
            placement="bottom-start"
            content={
              <DropdownItemsList
                items={items}
                onSelectItem={handleItemSelect}
                customClass={customClassList}
                icons={iconsList}
                color={color}
                fontSize={fontSize}
                showSearch={showSearch}
              />
            }>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}>
              {!hideDropDown && (
                <DropdownBox
                  fontSize={fontSize}
                  customClass={customClass}
                  color={color}
                  size={size}
                  title={title}
                  icon={icon}
                  open={open}
                />
              )}
            </div>
          </PPLxHoverCard>
        </div>
      </ClickAwayListener>
    );
  }
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setOpen(true);
      }}>
      <DropdownBox
        fontSize={fontSize}
        customClass={customClass}
        title={title}
        icon={icon}
        color={color}
        size="small"
      />
    </div>
  );
};

const DropdownBox = ({
  title,
  customClass,
  fontSize,
  icon,
  open,
  size = "medium",
  color,
}) => {
  return (
    <CursorDiv>
      <InfoBox
        padding="4px 10px"
        className={`${(open && classes.openedDropdown) ?? ""} ${customClass} ${
          size === "small" && classes.small
        } `}>
        <CustomRow alignItems="center" gap="2px">
          {icon && (
            <img
              style={{ marginRight: "6px" }}
              width="14px"
              src={icon}
              alt={`icon${title}`}
            />
          )}
          <CustomTextSpan fontSize={fontSize} color={color}>
            {title}
          </CustomTextSpan>
          <FlexGrow />
          <CaretDown size={14} color="var(--text-1)" />
        </CustomRow>
      </InfoBox>
    </CursorDiv>
  );
};

const DropdownItemsList = ({
  items,
  icons,
  onSelectItem,
  customClass,
  color,
  showSearch = false,
  fontSize = "13px",
}) => {
  const [search, setSearch] = useState("");
  const getFilteredItems = () => {
    try {
      return showSearch
        ? items.filter((item) =>
            item.toLowerCase().includes(search.toLowerCase())
          )
        : items;
    } catch (err) {
      return items;
    }
  };
  const filteredItems = getFilteredItems();
  return (
    <div className={`${classes.dropdownBox} ${customClass}`}>
      <CustomColumn>
        {showSearch && (
          <PaddingComponent
            padding="2px 8px 4px 8px"
            className={classes.inputContainer}>
            <input
              className={classes.searchInput}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </PaddingComponent>
        )}
        {filteredItems.length > 0 ? (
          filteredItems.map((item, index) => {
            return (
              <div
                key={item}
                className={classes.dropdownTile}
                onClick={(e) => {
                  onSelectItem(item, index, e);
                }}>
                {icons && icons?.[index] && (
                  <img
                    width="12px"
                    height="12px"
                    style={{ marginRight: "4px" }}
                    src={icons[index]}
                    alt={`icon${item}`}
                  />
                )}
                <CustomTextSpan color={color} fontSize={fontSize}>
                  {item}
                </CustomTextSpan>
              </div>
            );
          })
        ) : (
          <PaddingComponent padding="8px">
            <CustomRow alignItems="center" justifyContent="center">
              <CustomText
                color="var(--text-2)"
                fontSize="13px"
                text="No results"
                textAlign="center"
              />
            </CustomRow>
          </PaddingComponent>
        )}
      </CustomColumn>
    </div>
  );
};

export default CustomDropdownButton;
