import { ShowReactions } from "../Reactions";
import { PostGeneratedComment } from "components/FeedItemV2/WelcomePostsList/WelcomePostActions";
import { CustomRow, FlexGrow } from "components/UI/Components/Components";
import classes from "./CurrentEngagements.module.css";
import { useHistory } from "react-router-dom";
import { useGetEngagements, useGetGroupActivity } from "../../../api/feed.api";
import { createPortal } from "react-dom";
import RightPanel from "../../Pages/AppModule/RightPanel/RightPanel";
import UsersListRightPanel from "../../Feed/GroupedActivityDetails/UsersListRightPanel/UsersListRightPanel";
import { useState } from "react";
import { getCommentCount, getPlurals, stopEventPropagation } from "utils/misc";

const CurrentEngagements = ({
  bookmarkLink,
  repostLink,
  pnlCommentsVisible,
  pnlCommentSuggestions,
  pnlCommentSuggestionsBucket,
  contentId,
  type,
  setPnlCommentsVisible,
  size = "medium",
  hideComments = false,
  minHeight = 0,
  hideRightSection = false,
  isActivityDetails = false,
}) => {
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [rightPanelType, setRightPanelType] = useState(null);
  const { data: engagements } = useGetEngagements({
    id: contentId?.toString(),
    type,
  });

  const { data: groupedActivity } = useGetGroupActivity({
    id: contentId?.toString(),
    type: type,
    enabled: isActivityDetails,
  });
  const history = useHistory();
  const commentLink = `/${type}/${contentId}/comments`;
  const commentCount = getCommentCount(groupedActivity, engagements, true);
  const reactions = engagements?.likes;
  const repostCount = engagements?.reposts?.count;
  const bookmarkCount = engagements?.bookmarks?.count;
  const rightSectionData = [
    {
      count: commentCount,
      title: getPlurals(commentCount, "comment"),
      hide: hideComments,
      key: "comments",
    },
    {
      count: repostCount,
      title: getPlurals(repostCount, "repost"),
      link: repostLink,
      key: "reposts",
    },
    {
      count: bookmarkCount,
      title: getPlurals(bookmarkCount, "bookmark"),
      link: bookmarkLink,
      key: "bookmarks",
    },
  ];
  const hideRightPanel = () => {
    setShowRightPanel(false);
    setRightPanelType(null);
  };
  return (
    <div
      className={`${classes.currentEngagementWrapper} ${size === "small" && classes.smallWrapper}`}
      style={{ minHeight, columnGap: hideRightSection ? "0" : "8px" }}>
      <div className={classes.leftSection}>
        {reactions?.likes?.length > 0 && (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              stopEventPropagation(e);
              setRightPanelType("likes");
              setShowRightPanel(true);
            }}>
            <ShowReactions
              count={reactions?.likes?.length}
              emojiIds={reactions?.likes}
            />
          </div>
        )}
      </div>
      {pnlCommentsVisible ? (
        <CustomRow className={classes.generatedCommentsRow}>
          {pnlCommentSuggestions?.[pnlCommentSuggestionsBucket]?.map(
            (comment, index) => (
              <PostGeneratedComment
                key={index}
                comment={comment}
                contentId={contentId}
                contentType={type}
                gotoNextPost={() => {
                  setPnlCommentsVisible(false);
                }}
              />
            )
          )}
        </CustomRow>
      ) : (
        <FlexGrow />
      )}

      {!hideRightSection && (
        <div className={classes.rightSection}>
          {rightSectionData
            .filter((item) => item.count)
            .map((item, index) =>
              !item.hide ? (
                <div
                  className={classes.rightSectionItem}
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    const type = item.key;
                    if (type === "comments" && !isActivityDetails) {
                      if (!isActivityDetails) {
                        history.push(commentLink);
                      }
                      return;
                    }
                    setRightPanelType(type);
                    setShowRightPanel(true);
                  }}>
                  {index !== 0 && <div className={classes.divider}>•</div>}
                  {item.count} {item.title}
                </div>
              ) : null
            )}
        </div>
      )}
      {showRightPanel &&
        rightPanelType &&
        createPortal(
          <RightPanel onClose={hideRightPanel}>
            <UsersListRightPanel
              contentId={contentId}
              type={type}
              onClose={hideRightPanel}
              engagementType={rightPanelType}
            />
          </RightPanel>,
          document.getElementById("rightPanel")
        )}
    </div>
  );
};

export default CurrentEngagements;
