import { useContext } from "react";
import { transformFilterParams, updateQueryParams } from "utils/misc";
import { useLocation, useHistory } from "react-router-dom";
import { ProfileContext } from "contextStates/ProfileContext";
import AppliedFiltersList from "./AppliedFiltersList";

const AppliedFiltersWrapper = ({ identifier }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    selectedActiveChains,
    setSelectedChains,
    selectedActiveTokens,
    setSelectedTokens,
    selectedActiveProtocols,
  } = useContext(ProfileContext);
  return (
    <AppliedFiltersList
      identifier={identifier}
      removeAll={() => {
        setSelectedChains([]);
        setSelectedTokens([], []);
        history.push({ search: "" });
      }}
      removeToken={(token) => {
        const newSelectedTokens = selectedActiveTokens.current?.filter(
          (selectedToken) =>
            !(
              selectedToken?.contract_address === token?.contract_address &&
              selectedToken?.chain_id === token?.chain_id
            )
        );
        setSelectedTokens(newSelectedTokens, selectedActiveProtocols.current);
        const queryValues = {
          tokens: transformFilterParams(newSelectedTokens),
          protocols: transformFilterParams(selectedActiveProtocols.current),
        };
        updateQueryParams({ updates: queryValues, location, history });
      }}
      removeProtocol={(protocol) => {
        const newSelectedProtocols = selectedActiveProtocols.current.filter(
          (selectedProtocol) =>
            !(
              selectedProtocol.chain_id === protocol.chain_id &&
              selectedProtocol.contract_address === protocol.contract_address
            )
        );
        setSelectedTokens(selectedActiveTokens.current, newSelectedProtocols);
        const queryValues = {
          tokens: transformFilterParams(selectedActiveTokens.current),
          protocols: transformFilterParams(newSelectedProtocols),
        };
        updateQueryParams({ updates: queryValues, location, history });
      }}
      removeChain={(chain) => {
        const newSelectedChains = selectedActiveChains.current.filter(
          (selectedChain) => selectedChain !== chain
        );
        setSelectedChains(newSelectedChains);

        updateQueryParams({
          updates: {
            chains: newSelectedChains?.join(","),
          },
          location,
          history,
        });
      }}
      tokens={selectedActiveTokens.current}
      protocols={selectedActiveProtocols.current}
      chains={selectedActiveChains.current}
    />
  );
};

export default AppliedFiltersWrapper;
