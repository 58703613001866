import { RightPanelCrossIcon } from "components/UI/Components/Components";
import classes from "./UsersListRightPanel.module.css";
import ProfileItem, {
  ProfileItemGhost,
  ProfileItemTypes,
} from "components/V2/Community/ProfileItem/ProfileItem";
import { useState, useContext } from "react";
import { AuthContext } from "contextStates/AuthContext";
import { reactionData } from "components/ActivityEngagements/ReactionData";
import { uniqueId } from "lodash";
import { getEngagements } from "api/feed.api";
import { ONE_DAY } from "utils/constants";
import { stopEventPropagation } from "utils/misc";
import { QUERY_KEYS } from "utils/query_utils";
import { useQuery } from "@tanstack/react-query";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";

//if is followers true then followers list else following list
const UsersListRightPanel = ({
  contentId,
  type,
  identities,
  onClose = () => {},
  engagementType,
}) => {
  const { data: engagements, isLoading } = useQuery({
    queryKey: QUERY_KEYS.ENGAGEMENTS_RIGHT_PANEL({
      type,
      contentId: contentId?.toString(),
    }),
    queryFn: () => getEngagements({ id: contentId?.toString(), type }),
    enabled: !!contentId && !!type,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: ONE_DAY,
  });
  const [selectedEmojiId, setSelectedEmojiId] = useState(null);
  const authContext = useContext(AuthContext);
  const identityDetails = authContext.identityDetails;
  const currentDetails = identityDetails?.current;
  const identity = currentDetails?.identity;

  const reactionFreq = {};
  engagements?.[engagementType]?.["likes"]?.forEach((item) => {
    const reaction =
      reactionData.find((r) => parseInt(r.id) === parseInt(item?.emoji_id)) ||
      reactionData[0];
    reactionFreq[reaction?.id] = [...(reactionFreq[reaction?.id] ?? []), item];
  });
  const count = engagements?.[engagementType]?.count;
  const Header = () => {
    return (
      <div className={classes.header}>
        <span
          className={classes.title}>{`${count ?? ``} ${engagementType}`}</span>
        <RightPanelCrossIcon onClick={onClose}></RightPanelCrossIcon>
      </div>
    );
  };
  if (isLoading || !engagements) {
    return (
      <div className={classes.rightPanel}>
        <Header />
        <div className={classes.listContainer}>
          {Array.from({ length: 5 }).map((_, idx) => (
            <ProfileItemGhost key={idx} />
          ))}
        </div>
      </div>
    );
  }
  const innerKey = engagementType !== "reposts" ? engagementType : "threads";
  const engagementsList =
    engagements[engagementType]?.[innerKey]?.filter(
      ({ emoji_id = 1 }) =>
        selectedEmojiId === null ||
        (emoji_id ?? 1) === parseInt(selectedEmojiId)
    ) ?? [];

  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <span className={classes.title}>{`${count} ${engagementType}`}</span>
        <RightPanelCrossIcon
          onClick={(e) => {
            stopEventPropagation(e);
            onClose();
          }}
        />
      </div>
      {engagementType === "likes" && (
        <div className={classes.rectionContainer}>
          <div
            style={{
              borderBottom:
                selectedEmojiId === null
                  ? "2px solid var(--text-1"
                  : "2px solid transparent",
            }}
            className={classes.reactionBox}
            onClick={(e) => {
              stopEventPropagation(e);
              setSelectedEmojiId(null);
            }}>
            All {engagements?.[engagementType]?.["likes"].length}
          </div>
          {Object.keys(reactionFreq).map((reactionId) => (
            <div
              style={{
                borderBottom:
                  selectedEmojiId === reactionId
                    ? "2px solid var(--text-1"
                    : "2px solid transparent",
              }}
              className={classes.reactionBox}
              onClick={(e) => {
                stopEventPropagation(e);
                setSelectedEmojiId(reactionId);
              }}>
              {reactionData.find((r) => parseInt(r.id) === parseInt(reactionId))
                ?.icon || reactionData[0].icon}
              {reactionFreq[reactionId].length}
            </div>
          ))}
        </div>
      )}
      <div className={classes.listContainer}>
        {engagementsList.map((engagement, idx) => {
          const idObject = identity?.id ? { [identity.id]: identity } : {};
          const creatorIdentity =
            engagementType !== "reposts"
              ? engagement.creator_identity
              : engagement.post.creator_identity;
          const profile = {
            ...identities,
            ...engagements?.identities,
            ...idObject,
          }?.[creatorIdentity];
          return (
            <ProfileItem
              key={uniqueId()}
              profile={profile}
              profileItemType={ProfileItemTypes?.["0xppl"]}
              icon={
                engagementType === "likes" &&
                reactionData.find(
                  (r) => parseInt(r.id) === parseInt(engagement?.emoji_id)
                )?.icon
              }
            />
          );
        })}
      </div>
    </div>
  );
};
export default withErrorBoundary(UsersListRightPanel);
