import { useContext } from "react";
import classes from "./Avatar.module.css";
import { AuthContext } from "contextStates/AuthContext";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { useState } from "react";
import { ClickAwayListener } from "@mui/material";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import WalletConnected from "components/shared/WalletConnected/WalletConnected";
import { Link } from "react-router-dom";
import { User, SignOut, Gear } from "@phosphor-icons/react";
import { trackEvent } from "utils/event_tracking";
// import { useDynamicContext } from "../../../../../node_modules/@dynamic-labs/sdk-react-core/src/index";

const Avatar = () => {
  const { identityDetails, isUserLoggedIn, setShowLoginPopup } =
    useContext(AuthContext);
  const [showCard, setShowCard] = useState(false);

  const handleShowCard = (e) => {
    trackEvent("profile_avatar_click");
    e.stopPropagation();
    if (!isUserLoggedIn) {
      setShowLoginPopup(true);
      return;
    }
    setShowCard(true);
  };

  const isUserFullyLoggedIn =
    identityDetails.current?.pending_onboarding === false &&
    identityDetails.current?.onboarding_state?.current === "complete" &&
    isUserLoggedIn;
  const imageSrc = !isUserFullyLoggedIn
    ? null
    : identityDetails?.current?.identity?.display_picture;

  if (showCard) {
    return (
      <ClickAwayListener onClickAway={() => setShowCard(false)}>
        <div>
          <PPLxHoverCard
            open={showCard}
            content={
              <ProfileDropDown
                link={identityDetails.current?.identity?.link}
                setShowCard={setShowCard}
              />
            }
            placement="bottom">
            <div
              className={classes.avatar}
              style={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowCard(false);
              }}>
              <Image
                className={classes.avatar}
                imageType={IMAGE_TYPES.AVATAR}
                src={imageSrc}
                name={identityDetails?.current?.identity?.display_name}
                alt={identityDetails?.current?.identity?.display_name}
              />
            </div>
          </PPLxHoverCard>
        </div>
      </ClickAwayListener>
    );
  }

  return (
    <div
      className={classes.avatar}
      onClick={handleShowCard}
      style={{
        cursor: "pointer",
      }}>
      <Image
        className={classes.avatar}
        imageType={IMAGE_TYPES.AVATAR}
        name={identityDetails?.current?.identity?.display_name}
        src={imageSrc}
        alt={identityDetails?.current?.identity?.display_name}
      />
    </div>
  );
};

const ProfileDropDown = ({ link, setShowCard }) => {
  const { logout } = useContext(AuthContext);
  // const { handleLogOut } = useDynamicContext();
  return (
    <div className={classes.profile_dropdown}>
      <WalletConnected />
      <div className={classes.divider}></div>
      <Link
        className={classes.dropdown_item}
        to={link}
        onClick={() => {
          trackEvent("profile_dropdown_view_profile");
          setShowCard(false);
        }}>
        <User size={18} color="var(--text-2)" style={{ marginRight: "12px" }} />
        View Profile
      </Link>
      <Link
        to="/settings"
        className={classes.dropdown_item}
        onClick={() => {
          trackEvent("profile_dropdown_settings");
          setShowCard(false);
        }}>
        <Gear size={18} color="var(--text-2)" style={{ marginRight: "12px" }} />
        Settings
      </Link>
      <div className={classes.divider}></div>
      <div
        className={classes.logout}
        onClick={() => {
          trackEvent("profile_dropdown_logout");
          // handleLogOut();
          logout();
        }}>
        <SignOut
          size={18}
          color="var(--error)"
          style={{ marginRight: "12px" }}
        />
        Logout
      </div>
    </div>
  );
};

export default Avatar;
