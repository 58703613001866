import { useFetchTokenSwapTableData } from "api/token_page.api";
import useQueryParams from "customHooks/useQueryParams";
import React, { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { formatUnixTimestamp } from "utils/misc";
export const TokenPageContext = React.createContext({});
let recentTimer;
let followTimer;
const TokenPageContextProvider = ({ chainId, contractAddress, children }) => {
  const history = useHistory();
  const location = history.location;
  const params = useQueryParams();
  const [chartPrice, setChartPrice] = React.useState(null);
  const [recentFilter, setRecentFilter] = React.useState(null);
  const [followerFilter, setFollowerFilter] = React.useState(null);
  const tableTypeRef = useRef("recent");
  const tableType = tableTypeRef.current;
  const updateTableType = (type) => {
    tableTypeRef.current = type;
  };
  const recentChartMarkers = React.useRef([]);
  const followerChartMarkers = React.useRef([]);
  const recentTransfers = useFetchTokenSwapTableData({
    queryKey: "tokenRecentTransfers",
    chainId,
    contractAddress,
    filter: recentFilter,
    type: "recent",
  });
  const updateChartMarkers = (type = "clear") => {
    try {
      const widget = window.tvWidget;

      const chart = widget?.chart();
      if (chart) {
        if (type === "clear") {
          chart.clearMarks();
        } else {
          chart.refreshMarks();
        }
      }
    } catch (err) {
      console.error({ err });
      return;
    }
  };
  const followerTransfers = useFetchTokenSwapTableData({
    queryKey: "tokenFollowerTransfers",
    chainId,
    contractAddress,
    filter: followerFilter,
    type: "follow",
  });
  useEffect(() => {
    if (params.has("maker") && !recentFilter && !followerFilter) {
      const maker = params.get("maker");
      setRecentFilter({
        address: maker,
        display_name: maker,
        display_picture: null,
        searchForProfile: true,
      });
    } else if (!params.has("maker") && (recentFilter || followerFilter)) {
      setRecentFilter(null);
      setFollowerFilter(null);
    }
  }, [params, recentFilter, followerFilter, setRecentFilter]);
  const updateFilter = (filter, type) => {
    type === "recent" ? setRecentFilter(filter) : setFollowerFilter(filter);
    if (filter) {
      params.set(
        "maker",
        filter?.id ??
          filter?.identity_id ??
          filter?.username ??
          filter?.address ??
          ""
      );
    } else {
      params.delete("maker");
    }
    history.push(location.pathname + filter ? `?${params.toString()}` : "");
    clearTimeout(type === "recent" ? recentTimer : followTimer);
    if (!filter || filter.length === 0) {
      updateChartMarkers("clear");
    }
  };
  const getData = (type) => {
    return type === "recent" ? recentTransfers : followerTransfers;
  };
  const getFilter = (type) => {
    return type === "recent" ? recentFilter : followerFilter;
  };
  const updateMarkers = useCallback((data, ref) => {
    try {
      const totalRows = data?.data?.pages?.reduce((acc, pg) => {
        return acc + (pg.transfer_data ?? pg.recent_transfer_data ?? []).length;
      }, 0);
      if (totalRows === 0) return;
      const markers = data?.data?.pages?.flatMap((pg) => {
        const transfers = pg.transfer_data ?? pg.recent_transfer_data ?? [];
        const val = [];
        const existingMarkers = {};
        ref.current.forEach((m) => {
          existingMarkers[`${m.timestamp}_${m.action}`] = true;
        });

        transfers.forEach((t) => {
          const type = t.type.toLowerCase();
          if (
            type === "buy" ||
            type === "sell" ||
            type === "received" ||
            type === "sent"
          ) {
            const isBuy = type === "buy" || type === "received";
            const price = t.price_bought_at?.value;
            val.push({
              timestamp: t.time_stamp,
              action: type,
              color: isBuy ? "green" : "red",
              type: type,
              price: price,
              amount: t.amount.value,
              text: [`$${price} \n`, formatUnixTimestamp(t.time_stamp)],
              label: isBuy ? "B" : "S",
              id: `${t.time_stamp}_${type}`,
            });
          }
        });
        return val;
      });
      ref.current = markers;

      const widget = window.tvWidget;
      const chart = widget?.activeChart();
      if (chart && markers && markers.length > 0) {
        updateChartMarkers("refresh");
      }
    } catch (error) {
      console.log("Error updating markers", error);
    }
  }, []);
  const getMarkers = () => {
    return tableTypeRef.current === "recent"
      ? recentChartMarkers
      : followerChartMarkers;
  };

  useEffect(() => {
    if (recentFilter && recentTransfers.data) {
      updateMarkers(recentTransfers, recentChartMarkers);
    }
    if (!recentFilter && recentChartMarkers.current.length > 0) {
      recentChartMarkers.current = [];
    }
  }, [recentTransfers.data, recentFilter, updateMarkers, recentTransfers]);

  useEffect(() => {
    if (followerFilter && followerTransfers.data) {
      updateMarkers(followerTransfers, followerChartMarkers);
    }
    if (!followerFilter && followerChartMarkers.current.length > 0) {
      followerChartMarkers.current = [];
    }
  }, [
    followerFilter,
    followerTransfers.data,
    updateMarkers,
    followerTransfers,
  ]);
  const resetFilter = useCallback(() => {
    setRecentFilter(null);
    setFollowerFilter(null);
    if (params.has("maker")) {
      params.delete("maker");
      history.push(location.pathname, `?${params.toString()}`);
    }
  }, [params, history, location.pathname]);

  useEffect(() => {
    // resetFilter();
    setChartPrice(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, contractAddress]);
  return (
    <TokenPageContext.Provider
      value={{
        getData,
        updateFilter,
        getFilter,
        recentFilter,
        resetFilter,
        followerFilter,
        recentChartMarkers,
        followerChartMarkers,
        updateMarkers,
        getMarkers,
        tableType,
        updateTableType,
        updateChartMarkers,
        chartPrice,
        setChartPrice,
      }}>
      {children}
    </TokenPageContext.Provider>
  );
};

export default TokenPageContextProvider;
