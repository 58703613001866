import React from "react";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";
const StickyTabs = ({
  activeTab,
  transactionsCount,
  commentsCount,
  referenceActivitiesCount,
  voteCount,
  resultsCount,
  hideReferenceActivities = false,
  hideTransactions = false,
  showVotes = false,
  showResults = false,
}) => {
  const tabsList = [
    {
      title: `Comments (${commentsCount ?? 0})`,
      link: { pathname: "comments" },
      isActiveTab: "comments" === activeTab,
    },
  ];
  if (!hideReferenceActivities) {
    tabsList.push({
      title: `References (${referenceActivitiesCount ?? 0})`,
      link: { pathname: "reference_activities" },
      isActiveTab: "reference_activities" === activeTab,
    });
  }

  if (!hideTransactions) {
    tabsList.push({
      title: `Transactions ${transactionsCount ? `(${transactionsCount})` : ""}`,
      link: { pathname: "transactions" },
      isActiveTab: "transactions" === activeTab,
    });
  }

  if (showVotes) {
    tabsList.push({
      title: `Votes (${voteCount ?? 0})`,
      link: { pathname: "votes" },
      isActiveTab: "votes" === activeTab,
    });
  }
  if (showResults) {
    tabsList.push({
      title: `Results (${resultsCount ?? 0})`,
      link: { pathname: "results" },
      isActiveTab: "results" === activeTab,
    });
  }
  return (
    <CustomLinkTabBar
      currentStep={{ pathname: activeTab }}
      tabsList={tabsList}
      zIndex={TABBAR_ZINDEX.HIGH}
    />
  );
};

export default StickyTabs;
