import { getTrendingCards } from "api/trending.api";
import {
  CustomSkeleton,
  CustomText,
  Divider,
  PaddingComponent,
  PriceChange,
} from "components/UI/Components/Components";
import withLoader from "components/core/withLoader";
import { Link } from "react-router-dom";
import styles from "./TrendingTokensListSmall.module.css";
import React, { Fragment } from "react";
import Image from "components/UI/Image";
import TrendingTokenChart from "components/TrendingActivities/TrendingTokenChart";

const TokenDetailsRow = ({ details }) => {
  const metadata = details.trending_metadata;
  const profile = metadata.token_profile;
  const displayPicture = profile?.display_picture ?? null;
  const displayName = profile?.display_name ?? "N/A";
  const tokenPrice = metadata?.token_details?.price?.display_value ?? null;

  const priceChangeValue =
    metadata?.token_details?.["24h_percent_change"]?.value ?? null;

  const priceData = metadata?.token_details?.["24h_price_history"] ?? [];
  const priceChangeDisplay =
    metadata?.token_details?.["24h_percent_change"]?.display_value ?? null;
  const floorPrice = metadata?.nft_details?.floor_price?.display_value ?? null;
  const nativeChainDetails = metadata.chain_profiles ?? null;
  const chainId = profile.address_chain;
  const chainProfile = nativeChainDetails[chainId];
  const nativeChainSymbol = chainProfile?.native_token_details?.symbol ?? null;
  const contractAddress = profile.address;
  const isNft = !profile.is_token;
  const totalHolders =
    metadata?.mcap_hodlers_details?.num_holders?.display_value ?? "";
  const marketCap =
    metadata?.mcap_hodlers_details?.market_cap?.display_value ?? "-";
  return (
    <Link to={`/trending_token/${chainId}/${contractAddress}`}>
      <PaddingComponent padding="16px 0" className={styles["token-container"]}>
        <div className={styles["token-details"]}>
          <div className={styles["token-details-left"]}>
            <Image
              src={displayPicture}
              alt={displayName}
              className={styles["token-image"]}
              imageType={isNft ? "small_nft" : "small_token"}
            />
            <CustomText
              text={displayName}
              fontSize="15px"
              fontWeight="400"
              lineHeight="24px"
              color="var(--text-1)"
              className={styles["token-name"]}
            />
          </div>
          <div className={styles["token-details-right"]}>
            {isNft ? (
              <CustomText
                text="Floor Price"
                fontSize="12px"
                fontWeight="500"
                lineHeight="18px"
                color="var(--text-2)"
              />
            ) : (
              <CustomText
                text="Price"
                fontSize="12px"
                fontWeight="500"
                lineHeight="18px"
                color="var(--text-2)"
              />
            )}
            {isNft ? (
              <CustomText
                text={floorPrice ? `${floorPrice} ${nativeChainSymbol}` : "-"}
                fontSize="13px"
                lineHeight="19.5px"
                color="var(--text-1)"
              />
            ) : !isNft ? (
              <Fragment>
                <CustomText
                  text={tokenPrice}
                  fontSize="13px"
                  lineHeight="19.5px"
                  color="var(--text-1)"
                />
                <PriceChange
                  value={priceChangeValue}
                  displayValue={priceChangeDisplay}
                />
              </Fragment>
            ) : null}
          </div>
        </div>
        {/* token price */}
        <div className={styles["token-price-chart-container"]}>
          {priceData.length > 0 ? (
            <TrendingTokenChart graphData={priceData} width={60} height={24} />
          ) : (
            <span></span>
          )}
          <div className={styles["token-price-details"]}>
            <CustomText
              text={isNft ? `Holders` : `M.Cap`}
              fontWeight="400"
              fontSize="12px"
              lineHeight="14.3px"
              color="var(--text-2)"
            />
            <CustomText
              text={isNft ? totalHolders : marketCap}
              fontWeight="400"
              fontSize="12px"
              lineHeight="17px"
              color="var(--text-1)"
            />
          </div>
        </div>
      </PaddingComponent>
    </Link>
  );
};

const TrendingTokensListSmall = ({ queryData }) => {
  if (!queryData) return null;
  return (
    <PaddingComponent padding="24px 0">
      <CustomText
        text="Trending Tokens & NFTs"
        fontSize="14px"
        fontWeight="600"
        lineHeight="21px"
        color="var(--text-1)"
      />
      <PaddingComponent paddingTop="16px" className={styles["list-container"]}>
        {queryData.map((item, idx) => {
          return (
            <Fragment>
              <TokenDetailsRow key={idx} details={item} />
              <Divider />
            </Fragment>
          );
        })}
      </PaddingComponent>
    </PaddingComponent>
  );
};

const TrendingTokensListSmallLoader = () => {
  return (
    <PaddingComponent className={styles["page-right-bar"]} paddingTop="24px">
      <CustomText
        text={"Trending Tokens & NFTs"}
        fontSize="14px"
        fontWeight="600"
        color="var(--text-1)"
        lineHeight="22px"
      />
      {[1, 2, 3, 4, 5, 6].map((_, index) => (
        <CustomSkeleton key={index} width="100%" height="40px" />
      ))}
    </PaddingComponent>
  );
};

export default withLoader(TrendingTokensListSmall, getTrendingCards, {
  queryKey: ({ chainId, contractAddress }) => [
    "trending_token_list_small",
    chainId,
    contractAddress,
  ],
  retry: 1,
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 2,
    };
  },
  CustomLoader: TrendingTokensListSmallLoader,
});
