import { ArrowRight } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { getOnboardingData } from "api/waitlist.api";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  SpinnerComponent,
} from "components/UI/Components/Components";
import QRCodeLogin, { getQRCode } from "components/UI/QrCode/QrCode";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import OnboardingBg from "../OnboardingBg";
import classes from "./DownloadApp.module.css";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { isTestingOnboarding } from "utils/constants";
import { AuthContext } from "contextStates/AuthContext";
import Loader from "components/UI/Loader/Loader";
import { logSentryEvent } from "utils/sentry";
import { getIdentity } from "api/profile.api";
import appleStoreButtonImage from "../../../../assets/png/onboarding/download_apple_store.png";
import googlePlayStoreButtonImage from "../../../../assets/png/onboarding/download_google_play.png";
import { trackEvent } from "utils/event_tracking";
import useScreenRes from "customHooks/useMediaQuery";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import CustomDivider from "components/UI/CustomComponents/CustomDivider";
import Image from "components/UI/Image";

const STEPS = ["Scan QR", "Download app", "Scan QR with app to login"];

const QrCodeComponent = ({ profileImage, size = 200 }) => {
  const [deeplinkUrl, setDeepLinkUrl] = useState(null);

  useEffect(() => {
    getQRCode().then((res) => {
      setDeepLinkUrl(res?.data?.data?.dynamic_url);
    });
  }, []);

  return !deeplinkUrl ? (
    <div className={classes.loadingSection}>
      <SpinnerComponent size={25} />
      <div className={classes.loadingText}>{"Loading QR code..."}</div>
    </div>
  ) : (
    <QRCodeLogin
      deeplinkUrl={deeplinkUrl}
      size={size}
      profileImage={profileImage}
      isOnboarding={true}
    />
  );
};

const DownloadApp = () => {
  const history = useHistory();
  const { isUserAuthenticated } = useContext(AuthContext);
  const { data: userData, isError } = useQuery({
    queryKey: ["user-data-onboarding"],
    queryFn: getOnboardingData,
    enabled: true,
    refetchInterval: 2500, // Polling every 2.5 seconds
    refetchIntervalInBackground: false,
  });
  useEffect(() => {
    if (isError) {
      logSentryEvent(null, {
        message: "Error in onboarding download app",
        isError: isError,
        onboardingData: userData,
      });
    }
  }, [isError, userData]);
  const { identityDetails } = useContext(AuthContext);
  const profile = identityDetails.current?.identity ?? null;
  const { isMobile, width } = useScreenRes();
  const { data: userProfile, isError: userError } = useQuery({
    queryKey: [profile?.id, "onboarding-my-identity"],
    queryFn: getIdentity,
    enabled: true,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  useEffect(() => {
    if (isTestingOnboarding) return;
    const curStep = userData?.data?.data?.current_step;
    if (curStep == null) {
      return;
    }
    if (curStep === "complete") {
      trackEvent("User logged in", null);
      isUserAuthenticated({
        isNavigating: true,
        navigateCallback: (path) => {
          if (path === null) return;
          trackEvent("onboarding_completed_redirecting_home");
          history.push(`/home`);
        },
      });
      return;
    }
    const currRoute = `/onboarding/${curStep}`;
    history.push(currRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, history]);

  const freshProfile = userProfile?.data?.data?.identity ?? null;
  // fetching fresh identity in case identity hasnt been fetched after bio completion
  const { display_picture: profileImage } =
    freshProfile && !userError ? freshProfile : profile ?? {};

  if (!profile && !freshProfile) {
    return <Loader />;
  }
  const isSmallScreen = isMobile || width < 1500;
  return (
    <BaseWrapper mixpanelEvent="OnboardingDownloadApp">
      <OnboardingBg>
        <div className={classes.mainContent}>
          <div className={classes.headerCont}>
            <div className={classes.header}>
              <CustomRow gap="16px" alignItems="center">
                <h1 className={classes.mainTitle}>
                  {"Download 0xPPL mobile app"}
                </h1>
              </CustomRow>
            </div>
          </div>
          <PaddingComponent backgroundColor="#fff" borderRadius="12px">
            <div className={classes.contentWrapper}>
              <div className={classes.qrCodeCont}>
                <PaddingComponent padding={isSmallScreen ? "12px" : "32px"}>
                  <div className={classes.qrCodeSection}>
                    <QrCodeComponent
                      profileImage={profileImage}
                      size={isSmallScreen ? 250 : 326}
                    />
                    {/* <div className={classes.steps}>
                      <div className={classes.step}>
                        <CustomText
                          text="Steps to download app"
                          fontWeight="700"
                          fontSize="20px"
                          color="var(--text-1)"
                        />
                      </div>
                      <div className={classes.step}>
                        <Camera
                          size={24}
                          color="var(--primary-color)"
                          weight="duotone"
                        />
                        <div className={classes.stepContent}>
                          {"Open your phone camera"}
                        </div>
                      </div>
                      <div className={classes.step}>
                        <QrCode
                          size={24}
                          color="var(--primary-color)"
                          weight="duotone"
                        />
                        <div className={classes.stepContent}>
                          {"Scan the above QR code"}
                        </div>
                      </div>
                      <div className={classes.step}>
                        <DownloadSimple
                          size={24}
                          color="var(--primary-color)"
                          weight="duotone"
                        />
                        <div className={classes.stepContent}>
                          {"Download the iOS/Android app"}
                        </div>
                      </div>
                      <div className={classes.step}>
                        <DeviceMobile
                          size={24}
                          color="var(--primary-color)"
                          weight="duotone"
                        />
                        <div className={classes.stepContent}>
                          {"Scan this QR code to login"}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </PaddingComponent>
                <PaddingComponent height="20px" />
                <CustomColumn
                  gap="10px"
                  width="100%"
                  justifyContent="center"
                  style={{ position: "relative" }}>
                  <hr
                    style={{
                      width: "100%",
                      border: "1px solid var(--border-dark)",
                      position: "absolute",
                    }}
                  />
                  <CustomRow justifyContent="center">
                    <div
                      style={{
                        position: "relative",
                        display: "inline-flex",
                        gap: 10,
                        padding: "0 5px",
                        alignItems: "center",
                        background: "var(--base)",
                      }}>
                      {/* <img src={AvailableLeftDesignIcon} alt="" />
                      <CustomText
                        text="Available on"
                        color="var(--text-1)"
                        fontSize="20px"
                        fontWeight="700"
                      />
                      <img src={AvailableRightDesignIcon} alt="" /> */}
                    </div>
                  </CustomRow>
                </CustomColumn>
                <CustomRow gap="10px" width="100%" justifyContent="center">
                  <div>
                    <img
                      src={appleStoreButtonImage}
                      style={{ height: 36 }}
                      alt="Download on the App Store"
                    />
                  </div>
                  <div>
                    <img
                      style={{ height: 36 }}
                      src={googlePlayStoreButtonImage}
                      alt="Get it on Google Play store"
                    />
                  </div>
                </CustomRow>
                <PaddingComponent height="20px" />
              </div>
              <div className={classes.profileContent}></div>
            </div>
            <PaddingComponent padding="16px 16px 32px 16px">
              <ZxFlex dir="column" gap="20px" align="flex-start" width="100%">
                <ZxFlex width="100%" gap="16px">
                  <ZxText style={TSTYLES.title1}>Steps to download</ZxText>
                  <ZxFlex flex="1">
                    <CustomDivider reverse />
                  </ZxFlex>
                </ZxFlex>
                <ZxFlex gap="4px">
                  {STEPS.map((s, idx) => {
                    const isLast = idx === STEPS.length - 1;
                    return (
                      <ZxFlex key={idx} justify="space-between" align="center">
                        <div className={classes.stepNumber}>
                          <ZxText color="white" style={TSTYLES.bodyLabel}>
                            {idx + 1}
                          </ZxText>
                        </div>
                        <ZxText key={idx} fontSize="15px">
                          {s}
                        </ZxText>
                        {!isLast && (
                          <PaddingComponent padding="0 12px">
                            <ZxFlex>
                              <ArrowRight color="var(--text-1)" size={20} />
                            </ZxFlex>
                          </PaddingComponent>
                        )}
                      </ZxFlex>
                    );
                  })}
                </ZxFlex>
              </ZxFlex>
            </PaddingComponent>
          </PaddingComponent>
          <div className={classes.gifWrapper}>
            <Image
              src="/gif/mobile_app_gif.gif"
              alt="Mobile App Gif"
              style={{ borderRadius: "8px" }}
              className={classes.gifImage}
            />
          </div>
        </div>
      </OnboardingBg>
    </BaseWrapper>
  );
};

export default DownloadApp;
