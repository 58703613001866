import React from "react";
import classes from "./perpetualPositionBody.module.css";
import { TableRow, TableCell } from "components/UI/Table/Table";
import { TokenInfoWrapper, TokenSymbol } from "./TokenInfoWrapper";
import upArrowIcon from "assets/svg/profile/up-arrow.svg";
import downArrowIcon from "assets/svg/profile/down-arrow.svg";

const PerpetualPositionBody = ({ holdings }) => {
  return holdings.map((holdingsItem, index) => (
    <TableRow key={`perpetual-protocols-${index}`} alignToFlexStart noPadding>
      <TableCell alignLeft>
        <div className={classes.pairWrapper}>
          <TokenInfoWrapper
            token_url={holdingsItem?.index?.token_url}
            token_icon={holdingsItem?.index?.token_icon}
            info={<TokenSymbol symbol={holdingsItem?.index?.token_symbol} />}
          />
          <PositionType
            isLong={holdingsItem?.is_long}
            leverage={holdingsItem?.leverage}
          />
        </div>
      </TableCell>
      <TableCell alignLeft>
        {holdingsItem?.position_size?.display_value}
      </TableCell>
      <TableCell alignLeft style={{ flexBasis: "30%" }}>
        {holdingsItem?.entry_price?.display_value}
        {/* TODO(shaheer): get Market and Liquidate values from API whenever ready*/}
        <PriceItem
          label="Market: "
          price={holdingsItem?.market?.display_value}
        />
        <PriceItem
          label="Liquidate: "
          price={holdingsItem?.liquidate?.display_value}
        />
      </TableCell>
      <TableCell alignLeft>
        {/* TODO(shaheer): get the direction and pnl_change_percentage from API whenever ready */}
        <PNL
          direction={holdingsItem?.pnl_change_direction}
          pnl={holdingsItem?.pnl?.display_value}
          changePercentage={holdingsItem?.pnl_change_percentage?.display_value}
        />
      </TableCell>
      <TableCell alignRight>{holdingsItem?.usd_value?.display_value}</TableCell>
    </TableRow>
  ));
};

const PositionType = ({ isLong, leverage }) => {
  if (isLong) {
    return (
      <div className={`${classes.positionType} ${classes.long}`}>
        <div>Long</div>
        <div className={classes.leverage}>{`${leverage}x`}</div>
      </div>
    );
  }

  return (
    <div className={`${classes.positionType} ${classes.short}`}>
      <div>Short</div>
      <div className={classes.leverage}>{`${leverage}x`}</div>
    </div>
  );
};

const PriceItem = ({ label, price }) => {
  if (!label || !price) return null;

  return (
    <div className={classes.priceItem}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{price}</div>
    </div>
  );
};
const PNL = ({ direction, pnl, changePercentage }) => {
  const directionClass = direction === 1 ? classes.up : classes.down;
  return (
    <div className={`${classes.pnl} ${direction ? directionClass : ""}`}>
      <div className={classes.pnlValue}>{pnl}</div>
      {!!direction && !!changePercentage && (
        <div className={classes.pnlChangePercentage}>
          <img
            className={classes.priceChangeIcon}
            src={direction === 1 ? upArrowIcon : downArrowIcon}
            alt="price-change-icon"
          />
          <div className={directionClass}>{changePercentage}%</div>
        </div>
      )}
    </div>
  );
};

export default PerpetualPositionBody;
