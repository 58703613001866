import { TimeLabel } from "components/FeedItemV2/ActivityHeader";
import ConditionalLink from "shared/ConditionalLink";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import ZxBadge from "zxComponents/common/ZxBadge";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { ArrowRight01Icon } from "hugeicons-react";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import { stopEventPropagation } from "utils/misc";
import { Funnel } from "../../../../node_modules/@phosphor-icons/react/dist/index";

export const getZxTokenRecentTransfersColumns = ({
  filter,
  showFilter,
  updateFilter,
  toggleSearchModal,
  isFolloweeSwaps,
}) => {
  return [
    {
      title: "TIME",
      key: "timestamp",
      width: "15%",
      render: (row, { index }) => {
        return (
          <TimeLabel
            timestamp={row.time_stamp}
            color="var(--text-1)"
            showSecondsCount={false}
          />
        );
      },
    },
    {
      title: "TYPE",
      key: "type",
      width: "15%",
      render: (row) => {
        const type = row.type?.toLowerCase();
        const isBuy = type === "buy" || type === "received";
        return (
          <ZxBadge variant={isBuy ? "success" : "burn"}>
            {isBuy ? "BUY" : "SELL"}
          </ZxBadge>
        );
      },
    },

    {
      title: "USD",
      key: "usd_value",
      width: "20%",
      align: "flex-end",
      render: (row, { profiles }) => {
        const type = row.type?.toLowerCase();
        const isBuy = type === "buy" || type === "received";
        return (
          <ZxText
            style={TSTYLES.title1}
            color={isBuy ? "success" : "error"}
            fontSize="14px">
            {row.usd_value?.display_value}
          </ZxText>
        );
      },
    },
    {
      title: "AMOUNT",
      key: "amount",
      align: "flex-end",
      width: "20%",
      render: (row, { profiles }) => {
        const type = row.type?.toLowerCase();
        const isBuy = type === "buy" || type === "received";
        return (
          <ZxText
            style={TSTYLES.title1}
            color={isBuy ? "success" : "error"}
            fontSize="14px">
            {row.amount?.display_value}
          </ZxText>
        );
      },
    },
    {
      title: "PRICE",
      key: "price_bought_at",
      align: "flex-end",
      width: "20%",
      render: (row, { profiles }) => {
        const type = row.type?.toLowerCase();
        const isBuy = type === "buy" || type === "received";
        return (
          <ZxText
            style={TSTYLES.title1}
            color={isBuy ? "success" : "error"}
            fontSize="14px">
            {row.price_bought_at?.display_value}
          </ZxText>
        );
      },
    },
    {
      title: (
        <ZxFlex justify="space-between" width="100%">
          <ZxFlex width="90%">USER/WALLET</ZxFlex>
          {showFilter ? (
            <ZxFlex
              cursor="pointer"
              width="10%"
              onClick={(e) => {
                stopEventPropagation(e);
                toggleSearchModal(true);
              }}>
              <Funnel
                size={14}
                color="var(--text-3)"
                weight={filter && !isFolloweeSwaps ? "fill" : "regular"}
              />
            </ZxFlex>
          ) : null}{" "}
        </ZxFlex>
      ),
      key: "address",
      width: "30%",
      render: (row, { profiles }) => {
        const profile = profiles[row.user_address];
        return (
          <ZxFlex
            width="100%"
            cursor="pointer"
            onClick={(e) => {
              stopEventPropagation(e);
              filter
                ? updateFilter(null)
                : updateFilter(profiles?.[row.user_address]);
            }}>
            <ZxFlex width="90%">
              <ZxActor
                actor={profile}
                showFollowButton={false}
                size="20px"
                showNetworth={false}
                dpFontSize="13px"
                nameLength={12}
              />
            </ZxFlex>
            <Funnel
              size={16}
              color="var(--text-3)"
              weight={filter && !isFolloweeSwaps ? "fill" : "regular"}
            />
          </ZxFlex>
        );
      },
    },
    {
      title: null,
      key: "link",
      width: "60px",
      align: "center",
      render: (row, { profiles }) => {
        return (
          <ZxFlex>
            <ConditionalLink to={row?.transaction_link} sameTab={false}>
              <ArrowRight01Icon color="var(--text-2)" size={16} />
            </ConditionalLink>
          </ZxFlex>
        );
      },
    },
  ];
};
