import React from "react";
import {
  AlertComponent,
  CircleAvatar,
  CustomColumn,
  CustomRow,
  CustomSkeleton,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "components/UI/Table/Table";
import classes from "./tokenLiquidity.module.css";
import withLoader from "components/core/withLoader";
import { getTrendingItemLiquidity } from "./trendingUtils";
import { IMAGE_TYPES } from "components/UI/Image";
import ConditionalLink from "shared/ConditionalLink";
import GenericTableLoader from "components/V2/GenericTableLoader/GenericTableLoader";

const TokenLiquidity = ({ queryData: data, paddingTop = "0px" }) => {
  return (
    <PaddingComponent padding="16px">
      <PaddingComponent height={paddingTop} />
      {data?.warnings?.map((warning, index) => (
        <>
          <AlertComponent
            type="error"
            title={warning?.title}
            key={`warning-${index}`}>
            <CustomText
              text={warning?.description}
              fontSize="15px"
              textAlign="left"
              color="var(--text-1)"
            />
          </AlertComponent>
          <PaddingComponent height="16px" />
        </>
      ))}

      <div className={classes.tokenLiquidity}>
        <Table>
          <LiquidityHeader
            totalLiquidity={data?.total_liquidity?.display_value}
            totalPools={data?.pools?.length}
          />
          <PaddingComponent height="16px" fontWeight="500" />
          <TableHeader noPadding>
            <TableCell alignLeft style={{ flexBasis: "50%" }}>
              POOL
            </TableCell>
            <TableCell>LIQUIDITY</TableCell>
            <TableCell alignRight>24H VOLUME</TableCell>
            <TableCell alignRight># OF PROVIDERS</TableCell>
          </TableHeader>
          <PaddingComponent height="12px" />
          {data?.pools?.map((item, index) => (
            <LiquidityRow pool={item} key={`liquidity-pool-${index}`} />
          ))}
        </Table>
      </div>
    </PaddingComponent>
  );
};

const LiquidityHeader = ({ totalLiquidity, totalPools }) => {
  return (
    <CustomRow justifyContent="space-between" alignItems="flex-end">
      <CustomColumn>
        <CustomText
          text="Total Liquidity"
          fontSize="15px"
          color="var(--text-2)"
          fontWeight="600"
          textAlign="left"
        />
        <CustomText
          text={totalLiquidity}
          fontSize="26px"
          color="var(--text-1)"
          fontWeight="600"
          textAlign="left"
        />
      </CustomColumn>
      <CustomText
        text={`${totalPools} Pools`}
        fontSize="15px"
        color="var(--text-2)"
        textAlign="right"
      />
    </CustomRow>
  );
};
const LiquidityRow = ({ pool }) => {
  return (
    <TableRow noPadding alignToFlexStart>
      <TableCell alignLeft style={{ flexBasis: "50%" }}>
        <ConditionalLink sameTab={false} to={{ pathname: pool?.url }}>
          <CustomRow gap="8px">
            <CustomRow>
              {pool?.image_urls?.map((poolImage, index) => (
                <CircleAvatar
                  src={poolImage}
                  alt={`pool-${index}`}
                  imageType={IMAGE_TYPES.SMALL_TOKEN}
                  className={index === 0 ? classes.overlap : ""}
                />
              ))}
            </CustomRow>
            <CustomRow gap="4px" alignItems="center">
              <CustomText
                text={pool?.name?.base}
                fontSize="15px"
                fontWeight="600"
                color="var(--text-1)"
              />
              <CustomText
                text={`/${pool?.name?.quote}`}
                fontSize="15px"
                color="var(--text-2)"
              />
              {pool?.dex_name && (
                <CustomText
                  text={`(${pool?.dex_name})`}
                  fontSize="13px"
                  color="var(--text-2)"
                  whiteSpace="pre"
                />
              )}
            </CustomRow>
          </CustomRow>
        </ConditionalLink>
      </TableCell>
      <TableCell alignLeft>
        <CustomText
          text={pool?.total_liquidity?.display_value}
          fontSize="15px"
          fontWeight="600"
          color="var(--text-1)"
        />
      </TableCell>
      <TableCell alignRight>
        <CustomText
          text={pool?.["24h_volume_in_usd"]?.display_value}
          fontSize="15px"
          color="var(--text-1)"
        />
      </TableCell>
      <TableCell alignRight>
        <CustomText
          text={pool?.num_providers}
          fontSize="15px"
          color="var(--text-1)"
        />
      </TableCell>
    </TableRow>
  );
};

const TokenLiquidityLoader = () => {
  return (
    <PaddingComponent padding="16px">
      <CustomColumn gap="4px">
        <CustomSkeleton height="40px" width="250px" />
        <CustomSkeleton height="60px" width="400px" />
        <GenericTableLoader />
      </CustomColumn>
    </PaddingComponent>
  );
};
export default withLoader(TokenLiquidity, getTrendingItemLiquidity, {
  queryKey: (props) => [
    "trending-item-liquidity",
    props.chainId,
    props.identifier,
  ],

  queryOptions: (props) => ({
    enabled: !!props?.identifier && !!props?.chainId,
    retry: 3,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: 10 * 1000,
  }),
  CustomLoader: TokenLiquidityLoader,
});
