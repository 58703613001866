export const getRootNodes = (nodes) => {
  if (!nodes) {
    return [];
  }
  return nodes?.filter((node) => node.ancestor === null);
};

export const nullParseInt = (val) => (val == null ? null : parseInt(val));

export const TIMELINE_HEIGHT = 44;

/**
 * Computes website as well as api query params from the given explorer params
 */
export const computeUrl = ({
  identifiers,
  selectedActiveTokens,
  selectedActiveChains,
  timeRange,
  isContractGraph = false,
  buildIdentityNodes,
  sliderTimeRange = [null, null],
  graph_node_count_threshold,
  graph_depth_threshold,
  tokenTuples,
  filterChains,
}) => {
  const additionalParams = computeAdditionalParams({
    timeRange,
    sliderTimeRange,
    selectedActiveChains,
    selectedActiveTokens,
    tokenTuples,
    filterChains,
  });
  if (graph_depth_threshold && graph_node_count_threshold)
    return `?identifiers=${identifiers}&show_contracts=${isContractGraph}&build_identity_nodes=${buildIdentityNodes}${additionalParams}&graph_node_count_threshold=${graph_node_count_threshold}&graph_depth_threshold=${graph_depth_threshold}`;
  else
    return `?identifiers=${identifiers}&show_contracts=${isContractGraph}&build_identity_nodes=${buildIdentityNodes}${additionalParams}`;
};

const computeAdditionalParams = ({
  timeRange,
  sliderTimeRange = [null, null],
  selectedActiveChains,
  selectedActiveTokens,
  tokenTuples,
  filterChains,
}) => {
  let result = "";
  if (timeRange?.length > 0) {
    const [start, end] = timeRange;
    const [sliderStart, sliderEnd] = sliderTimeRange;

    if (start && start !== sliderStart) {
      result += `&start_ts=${start}`;
    }
    if (end && end !== sliderEnd) {
      result += `&end_ts=${end}`;
    }
  }
  if (tokenTuples?.length > 0) {
    result += `&chain_id_addr_or_symbol_tuples=${JSON.stringify(tokenTuples)}`;
  }
  if (filterChains?.length > 0) {
    result += `&filter_chains=${filterChains.join(",")}`;
  }
  return result;
};
