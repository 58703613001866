import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  CustomSkeleton,
} from "components/UI/Components/Components";
import React from "react";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";

export const TrendingDetailsGhost = () => {
  return (
    <div>
      <CustomColumn>
        <PaddingComponent height="24px" />
        <CustomRow alignItems="center" justifyContent="space-between">
          <CustomRow>
            <CustomSkeleton
              animation="wave"
              variant="rectangular"
              height={50}
              width={50}
            />
            <PaddingComponent width="20px" />
            <CustomColumn>
              <CustomRow gap="20px">
                <CustomSkeleton
                  width={60}
                  height={30}
                  animation="wave"
                  variant="text"
                />
                <CustomSkeleton
                  height={30}
                  width={30}
                  animation="wave"
                  variant="circular"
                />
              </CustomRow>
              <CustomSkeleton
                width={50}
                height={20}
                animation="wave"
                variant="text"
              />
            </CustomColumn>
          </CustomRow>
          <CustomSkeleton
            height={30}
            width={150}
            animation="wave"
            variant="rectangular"
          />
        </CustomRow>
        <PaddingComponent height="20px" />
        <CustomRow alignItems="center" justifyContent="space-between">
          <CustomSkeleton
            height={40}
            width={60}
            animation="wave"
            variant="text"
          />
          <CustomRow gap="10px">
            <CustomSkeleton
              height={40}
              width={60}
              animation="wave"
              variant="text"
            />
            <CustomSkeleton
              height={40}
              width={60}
              animation="wave"
              variant="text"
            />
          </CustomRow>
        </CustomRow>
        <PaddingComponent height="40px" />
      </CustomColumn>
      <CustomLinkTabBar
        zIndex={TABBAR_ZINDEX.HIGH}
        isEnabled={false}
        tabsList={[
          {
            title: "Details",
            link: "details",
          },
          {
            title: "Leaderboard",
            link: "leaderboard",
          },
          {
            title: "Liquidity",
            link: "liquidity",
          },
          {
            title: "Discussion",
            link: "discussion",
          },
        ]}
      />
    </div>
  );
};
