import {
  CircleAvatar,
  CustomRow,
  CustomText,
  TypeTag,
} from "components/UI/Components/Components";
import ConditionalLink from "shared/ConditionalLink";
import ExternalLinks from "../Profile/ProfileHeader/ExternalLinks";

const NftCollectionInfo = ({ collectionInfo, showTag = true }) => {
  return (
    <CustomRow alignItems="center" justifyContent="space-between">
      <CustomRow alignItems="center" gap="4px">
        <ConditionalLink
          to={`/collection/${collectionInfo?.collection_id}/nfts`}>
          <CustomRow alignItems="center" gap="4px">
            <CircleAvatar
              src={collectionInfo?.image_url}
              height="16px"
              width="16px"
            />

            <CustomText
              text={collectionInfo?.name}
              fontSize="15px"
              fontWeight="500"
              color="var(--text-1)"
            />
          </CustomRow>
        </ConditionalLink>
        {showTag && <TypeTag text="NFT" />}
      </CustomRow>
      <ExternalLinks links={collectionInfo?.external_links} />
    </CustomRow>
  );
};

export default NftCollectionInfo;
