import React from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeyConstants } from "utils/constants";
import { getGroupedActivityFromBlob } from "api/feed.api";
import ZxImageStack from "zxComponents/common/ZxImageStack/ZxImageStack";
import { getTrimmedString } from "utils/misc";
import {
  CustomColumn,
  CustomRow,
  Divider,
} from "components/UI/Components/Components";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import ConditionalLink from "shared/ConditionalLink";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import TokenHoverCard from "components/FeedItemV2/TokenHover/TokenHoverCard";
const ZxUserTokenAttribution = ({
  groupedActivity,
  profiles,
  showDivider = true,
}) => {
  const { data: pnlItem } = useQuery({
    queryKey: [QueryKeyConstants.SLOW_FEED_ITEM, groupedActivity?.blob_id],
    queryFn: () =>
      getGroupedActivityFromBlob({
        id: groupedActivity?.id,
        type: groupedActivity?.type,
      }),
    enabled: false,
  });
  const { followers_interacting_with_token: followers } = pnlItem ?? {};
  if (!followers || followers?.count === 0) return null;
  const { count, profiles: actors } = followers;
  const images = actors.map((p) => ({
    ...p,
    src: p.display_picture ?? null,
    name: p.display_name ?? null,
  }));
  const postfix =
    count > actors.length ? ` +${count - actors.length} also` : "";
  const tokenAddress = followers?.token_address ?? null;
  const tokenProfile = profiles?.[tokenAddress] ?? null;
  const tokenName = tokenProfile?.symbol
    ? getTrimmedString(tokenProfile?.symbol, 20)
    : null;
  const link = tokenProfile?.link ?? null;
  const linkSuffix = tokenProfile?.is_token && link ? `/followee_pnls` : ``;
  return (
    <ConditionalLink to={`${link}${linkSuffix}`}>
      <CustomColumn>
        <CustomRow overflowX="visible" overflowY="visible" gap="4px">
          <ZxImageStack pictures={images} />
          <CustomRow alignItems="center" gap="4px">
            {actors?.length > 0 &&
              actors.map((ac, idx) => {
                const isLast = idx === actors.length - 1;
                return (
                  <ProfileHoverCard actor={ac} key={idx}>
                    <ConditionalLink to={ac.link ?? ac.address_link ?? null}>
                      <ZxText style={TSTYLES.body1} fontSize="13px">
                        {getTrimmedString(ac.display_name, 15)}
                        {isLast ? "" : ", "}
                      </ZxText>
                    </ConditionalLink>
                  </ProfileHoverCard>
                );
              })}
            <ZxText style={TSTYLES.body1} color="text-2">
              {postfix} traded
            </ZxText>
            <TokenHoverCard tokenProfile={tokenProfile}>
              <ZxText style={TSTYLES.body1} fontSize="13px">
                {tokenName}
              </ZxText>
            </TokenHoverCard>
          </CustomRow>
        </CustomRow>
        {showDivider && <Divider margin="8px 0" />}
      </CustomColumn>
    </ConditionalLink>
  );
};

export default ZxUserTokenAttribution;
