import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import {
  CustomColumn,
  PaddingComponent,
  IconWrapper,
  CustomRow,
} from "components/UI/Components/Components";
import { GlobalContext } from "contextStates/Global";
import { ProfileContext } from "contextStates/ProfileContext";
import classes from "./NotificationPopUp.module.css";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { useState, useCallback, useContext } from "react";
import { ClickAwayListener } from "@mui/material";
import { updateNotificationStatus } from "api/profile.api";
import {
  BellSimpleRinging,
  BellSimple,
  BellSimpleSlash,
} from "@phosphor-icons/react";

const PreferenceEnum = {
  Never: "Never",
  EveryTransaction: "EveryTransaction",
  OnlyIfInteresting: "OnlyIfInteresting",
};

const NotificationPopUp = ({ profile }) => {
  const [showPopUpCard, setShowPopUpCard] = useState(false);
  const preference = profile?.notification_preference;

  const onNotificationClick = useCallback(() => {
    setShowPopUpCard(!showPopUpCard);
    return;
  }, [setShowPopUpCard, showPopUpCard]);

  if (!preference || !profile.followed) return null;

  const notificationButton = (
    <PaddingComponent>
      <IconWrapper
        onClick={onNotificationClick}
        color={"var(--elevation-2)"}
        noBorder>
        {preference === PreferenceEnum.OnlyIfInteresting.toString() ? (
          <BellSimple size={18} color="var(--text-2)" />
        ) : preference === PreferenceEnum.Never.toString() ? (
          <BellSimpleSlash size={18} color="var(--text-2)" />
        ) : (
          <BellSimpleRinging size={18} color="var(--text-2)" />
        )}
      </IconWrapper>
    </PaddingComponent>
  );

  if (showPopUpCard) {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setShowPopUpCard(false);
        }}>
        <div>
          <PPLxHoverCard
            open={showPopUpCard}
            content={
              <NotificationPopUpComponent
                profile={profile}
                setShowPopUpCard={setShowPopUpCard}
              />
            }
            placement="bottom">
            {notificationButton}
          </PPLxHoverCard>
        </div>
      </ClickAwayListener>
    );
  }
  return notificationButton;
};

const NotificationPopUpComponent = ({ profile, setShowPopUpCard }) => {
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const { invalidateProfileDetails } = useContext(ProfileContext);

  const updatePreference = useCallback(
    (preference) => {
      updateNotificationStatus({
        preference: preference,
        identityId: profile?.identity_id || profile?.id,
      })
        .then((resp) => {
          if (resp?.data?.status === "ok") {
            handleSuccessSnackbar(
              "Notification preference updated successfully!"
            );
            invalidateProfileDetails();
            return;
          }
          handleErrorSnackbar(
            null,
            "Failed to update notification preference!"
          );
        })
        .catch((err) => {
          handleErrorSnackbar(
            null,
            "Failed to update notification preference!"
          );
        });
      setShowPopUpCard(false);
    },
    [
      profile?.identity_id,
      setShowPopUpCard,
      handleErrorSnackbar,
      profile?.id,
      handleSuccessSnackbar,
      invalidateProfileDetails,
    ]
  );
  const NotificationTypeTile = ({
    text,
    Icon,
    isActive,
    preference,
    children,
  }) => {
    return (
      <div
        onClick={() => updatePreference(preference)}
        className={`${classes.notificationType} ${
          isActive ? classes.isActive : ""
        }`}>
        <CustomRow alignItems="center">
          <PaddingComponent width="16px"></PaddingComponent>
          {children}
          <PaddingComponent width="14px"></PaddingComponent>
          <span
            className={`${classes.typeText} ${
              isActive ? classes.isActiveText : ""
            }`}>
            {text}
          </span>
        </CustomRow>
      </div>
    );
  };
  const preference = profile?.notification_preference;

  return (
    <div className={classes.container}>
      <CustomColumn>
        <PaddingComponent height="12px"></PaddingComponent>
        <PaddingComponent paddingLeft="16px">
          <span className={classes.title}>NOTIFICATIONS</span>
        </PaddingComponent>
        <PaddingComponent height="10px"></PaddingComponent>
        <NotificationTypeTile
          preference="EveryTransaction"
          text="All"
          isActive={preference === PreferenceEnum.EveryTransaction.toString()}>
          <BellSimpleRinging
            size={18}
            color={
              preference === PreferenceEnum.EveryTransaction.toString()
                ? "var(--text-1)"
                : "var(--text-2)"
            }
          />
        </NotificationTypeTile>
        <NotificationTypeTile
          preference="OnlyIfInteresting"
          text="Important"
          isActive={preference === PreferenceEnum.OnlyIfInteresting.toString()}>
          <BellSimple
            size={18}
            color={
              preference === PreferenceEnum.OnlyIfInteresting.toString()
                ? "var(--text-1)"
                : "var(--text-2)"
            }
          />
        </NotificationTypeTile>
        <NotificationTypeTile
          preference="Never"
          text="None"
          isActive={preference === PreferenceEnum.Never.toString()}
          iconActive={NotificationsOffIcon}>
          <BellSimpleSlash
            size={18}
            color={
              preference === PreferenceEnum.Never.toString()
                ? "var(--text-1)"
                : "var(--text-2)"
            }
          />
        </NotificationTypeTile>
        <PaddingComponent height="12px"></PaddingComponent>
      </CustomColumn>
    </div>
  );
};

export default NotificationPopUp;
