import React, { useCallback, useContext, useMemo, useState } from "react";
import classes from "./NFTHoldings.module.css";
import { NFTItem } from "./NftItem";
import { ExtraNFTs } from "./ExtraNfts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { CustomColumn, CustomRow } from "components/UI/Components/Components";
import { AccordionDetails } from "@mui/material";
import { NFTList } from "./NFTHoldings";
import { GhostNftItem } from "./GhostNftHoldings";
import { GlobalContext } from "contextStates/Global";
import { PaddingComponent } from "components/UI/Components/Components";

const accordionStyles = {
  background: "none",
  boxShadow: "none",
  "&.MuiAccordion-root:before": {
    // Remove the default divider line
    height: "0px",
  },
  ".MuiAccordionSummary-root": { padding: 0 },
  ".MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },

  ".MuiAccordionDetails-root": {
    padding: "16px 0 0 0",
  },
};

const FlattenedNFTCollections = ({
  nftCollections,
  chainProfiles,
  isLoading,
}) => {
  const { windowWidth } = useContext(GlobalContext);
  const [openAccordions, setOpenAccordions] = useState({});

  if (!nftCollections || !chainProfiles) {
    return null;
  }

  const nftCollectionChunks = chunk(nftCollections, 4);

  return (
    <PaddingComponent padding="8px 20px 0px">
      {nftCollectionChunks?.map((collectionChunk, index) => {
        const panelId = `accordion-${index}`;
        return (
          <div className={classes.accordionWrapper}>
            <Accordion
              key={panelId}
              expanded={!!openAccordions[panelId]?.length}
              sx={accordionStyles}>
              <AccordionHeader
                {...{
                  collectionChunk,
                  openAccordions,
                  panelId,
                  setOpenAccordions,
                  isLoading,
                  chainProfiles,
                  windowWidth,
                }}
              />

              {windowWidth > 768 && (
                <AccordionBody
                  {...{
                    openAccordions,
                    panelId,
                    collectionChunk,
                    chainProfiles,
                  }}
                />
              )}
            </Accordion>
          </div>
        );
      })}
    </PaddingComponent>
  );
};
const AccordionHeader = ({
  collectionChunk,
  openAccordions,
  panelId,
  setOpenAccordions,
  isLoading,
  chainProfiles,
  windowWidth,
}) => {
  const handleAccordionChange = useCallback(
    (panelId, collectionId) => {
      setOpenAccordions((prevState) => {
        const currentPanelState = prevState[panelId] || []; // Array of open collections for this panel
        const isCollectionOpen = currentPanelState.includes(collectionId); // Is the collection currently open?

        const newPanelState = isCollectionOpen
          ? currentPanelState.filter((id) => id !== collectionId) // If collection is open, close it
          : [...currentPanelState, collectionId]; // If collection is closed, open it

        return {
          ...prevState,
          [panelId]: newPanelState,
        };
      });
    },
    [setOpenAccordions]
  );

  return (
    <AccordionSummary
      sx={{
        ".MuiAccordionSummary-content": {
          width: "100%",
        },
      }}>
      <CustomRow gap="20px" className={classes.nftItemsWrapper}>
        {collectionChunk?.map((collection, index) => {
          if (collection.tokens.length === 1) {
            const nftItem = collection?.tokens?.[0];
            return (
              <NFTItem
                key={`${nftItem.name}-${index}-item`}
                nftItem={nftItem}
                overrideLink={
                  nftItem?.token_id
                    ? null
                    : `/nft/${collection.chain_id}/${nftItem.contract_address}`
                }
                chainId={collection?.chain_id}
                collectionDetails={collection?.collection_details}
                hasGalleryModal={false}
                style={{
                  width: "172px",
                }}
              />
            );
          }

          const key = `${collection?.collection_details?.collection_id}-${index}-item`;

          const onClick = () =>
            handleAccordionChange(
              panelId,
              collection?.collection_details?.collection_id
            );

          const isExpanded = openAccordions?.[panelId]?.includes(
            collection?.collection_details?.collection_id
          );
          return (
            <>
              <ExtraNFTs
                key={key}
                remainingItems={collection.tokens}
                remainingItemsCount={collection.tokens.length}
                customTitle={collection?.collection_details?.name}
                isExpanded={isExpanded}
                onClick={onClick}
                hideImageForSmallScreens={false}
                customSubTitle={
                  <CustomColumn
                    gap="4px"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexGrow={1}>
                    {collection?.collection_details?.[
                      collection?.collection_details?.token_value_source
                    ]?.token_value && (
                      <div>{`${
                        collection?.collection_details?.token_value_source ===
                        "floor_price"
                          ? "Floor"
                          : "Top Bid"
                      } ${
                        collection?.collection_details?.[
                          collection?.collection_details?.token_value_source
                        ]?.token_value?.display_value
                      }`}</div>
                    )}
                    {collection?.collection_details?.total_token_value && (
                      <div>
                        {`Total ${collection?.collection_details?.total_token_value?.display_value}
                  `}
                      </div>
                    )}
                    {!collection?.collection_details?.[
                      collection?.collection_details?.token_value_source
                    ]?.token_value &&
                      !collection?.collection_details?.total_token_value &&
                      "View All"}
                  </CustomColumn>
                }
              />

              {windowWidth <= 768 && isExpanded && (
                <NFTList
                  nftCollections={[collection]}
                  chainProfiles={chainProfiles}
                  collectionItemMargin="0px"
                  collectionItemHasBorderBottom
                />
              )}
            </>
          );
        })}
        {isLoading &&
          collectionChunk?.length < 4 &&
          Array(4 - collectionChunk?.length)
            .fill()
            .map((_, index) => <GhostNftItem key={"ghost-" + index} />)}
      </CustomRow>
    </AccordionSummary>
  );
};
const AccordionBody = ({
  openAccordions,
  panelId,
  collectionChunk,
  chainProfiles,
}) => {
  const collections = useMemo(
    () =>
      collectionChunk?.filter((collectionItem) => {
        return openAccordions?.[panelId]?.includes(
          collectionItem?.collection_details?.collection_id
        );
      }),
    [collectionChunk, openAccordions, panelId]
  );

  if (!openAccordions?.[panelId]?.length === 0) {
    return null;
  }
  return (
    <AccordionDetails>
      <CustomColumn gap="20px">
        <NFTList
          nftCollections={collections}
          chainProfiles={chainProfiles}
          collectionItemMargin="0px"
          collectionItemHasBorderBottom
        />
      </CustomColumn>
    </AccordionDetails>
  );
};

const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

export default FlattenedNFTCollections;
