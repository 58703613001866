import classes from "./Community.module.css";
import {
  Route,
  Switch,
  useLocation,
  useRouteMatch,
  Redirect,
} from "react-router-dom";

import TopProfileList from "components/V2/Community/TopProfileList/TopProfileList";
import NetworkProfileList from "components/V2/Community/NetworkProfileList/NetworkProfileList";
import { PaddingComponent } from "components/UI/Components/Components";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";
import { useRef } from "react";
import LoginCheckWrapper from "components/core/LoginCheckWrapper";
import { COMMUNITY_TABS } from "utils/constants";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import usePageTitle from "customHooks/usePageTitle";

const Community = () => {
  usePageTitle({ title: "People - 0xPPL" });

  let match = useRouteMatch();
  let location = useLocation();
  const currentStep = location.pathname
    .replace(match.path, "")
    .replace("/", "");

  const refetchRef = useRef(null);

  return (
    <BaseWrapper mixpanelEvent="Community">
      <div className={classes.community}>
        <div className={classes.title}>Your Web3 Digest</div>
        <div className={classes.sub_title}>
          Find people from your network, get recommendations, discover top
          profiles, and much more!
        </div>
        <PaddingComponent height="28px" />
        <CustomLinkTabBar
          zIndex={TABBAR_ZINDEX.HIGH}
          tabsList={[
            {
              link: "/community/recommended",
              title: "Recommended",
              isActiveTab: currentStep === "recommended",
            },
            {
              link: "/community/new",
              title: "New on 0xPPL",
              isActiveTab: currentStep === "new",
            },
            {
              link: "/community/network",
              title: "From Your Network",
              isActiveTab: currentStep === "network",
            },
            {
              link: "/community/profiles",
              title: "Profiles",
              isActiveTab: currentStep === "profiles",
            },
          ]}
          isBorderBottom={true}></CustomLinkTabBar>

        <div className={classes.tabs_container}>
          <Switch>
            <Route exact path="/community/profiles">
              <TopProfileList
                tab={COMMUNITY_TABS.profiles}
                refetchFeedOnFollowOrUnfollow
                refetchRef={refetchRef}
                resetOnRefetch
              />
            </Route>
            <Route exact path="/community/new">
              <LoginCheckWrapper
                title="Sign in to see what's new on 0xPPL"
                subtitle="Stay notified on interactions and activities from your network and notables">
                <TopProfileList
                  tab={COMMUNITY_TABS.newOn0xPPL}
                  refetchFeedOnFollowOrUnfollow
                  refetchRef={refetchRef}
                  resetOnRefetch
                />
              </LoginCheckWrapper>
            </Route>
            <Route exact path="/community/network">
              <LoginCheckWrapper
                title="Sign in to view your network Profiles"
                subtitle="Stay notified on interactions and activities from your network and notables">
                <NetworkProfileList
                  refetchFeedOnFollowOrUnfollow
                  refetchRef={refetchRef}
                  resetOnRefetch
                />
              </LoginCheckWrapper>
            </Route>
            <Route exact path="/community/recommended">
              <TopProfileList
                tab={COMMUNITY_TABS.recommended}
                refetchFeedOnFollowOrUnfollow
                refetchRef={refetchRef}
                resetOnRefetch
              />
            </Route>
            <Route exact path="/community">
              <Redirect to={`/community/${Object.keys(COMMUNITY_TABS)[0]}`} />
            </Route>
          </Switch>
        </div>
      </div>
    </BaseWrapper>
  );
};

export default Community;
