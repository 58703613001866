import classes from "./header.module.css";
import { CircularProgress } from "@mui/material";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import TimeAgo from "react-timeago";
import englishStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

const Header = ({ totalUsdValue, lastUpdatedAt, refetch, isFetching }) => {
  const formatter = buildFormatter(englishStrings);
  return (
    <div className={classes.header}>
      <div className={classes.labelContainer}>
        <div className={classes.label}>Token Holdings</div>
        <div className={classes.totalUsdValue}>{totalUsdValue}</div>
      </div>
      {lastUpdatedAt > 0 && (
        <PPLxTooltip title="Recompute the holdings" arrow placement="top">
          <button
            className={classes.updatedAt}
            disabled={isFetching}
            style={{ cursor: "cursor" }}
            onClick={refetch}>
            {isFetching && (
              <CircularProgress
                style={{ color: "var(--text-3)", marginTop: "2px" }}
                size={10}
              />
            )}
            <TimeAgo date={lastUpdatedAt * 1000} formatter={formatter} />
          </button>
        </PPLxTooltip>
      )}
    </div>
  );
};
export default Header;
