import filtersIcon from "assets/svg/profile/filters.svg";
import classes from "./Filters.module.css";
import { useState } from "react";
import { ClickAwayListener } from "@mui/material";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import HoldingsFilterPopUp from "./HoldingsFilterPopUp";
const FiltersButton = ({ identifier }) => {
  const [showPopUpCard, setShowPopUpCard] = useState(false);
  const Filter = () => (
    <div
      onClick={() => {
        setShowPopUpCard((prev) => !prev);
      }}
      className={classes.box}>
      <img className={classes.icon} src={filtersIcon} alt="filters" />
      <span className={classes.text}>Filters</span>
    </div>
  );
  if (showPopUpCard) {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setShowPopUpCard(false);
        }}>
        <div>
          <PPLxHoverCard
            open={showPopUpCard}
            content={
              <HoldingsFilterPopUp
                setShowPopUpCard={setShowPopUpCard}
                identifier={identifier}
              />
            }
            placement="bottom">
            <div className={classes.spinner_container}>
              <Filter />
            </div>
          </PPLxHoverCard>
        </div>
      </ClickAwayListener>
    );
  }
  return <Filter />;
};
export default FiltersButton;
