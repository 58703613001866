import React, { Fragment, useEffect, useMemo, useState } from "react";
import classes from "./galleryModal.module.css";
import { Modal, Backdrop } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { PaddingComponent } from "components/UI/Components/Components";
import GalleryModalPreview from "./GalleryModalPreview";
import NavigationButtons from "./NavigationButtons";
import { useBindKeys } from "./useBindKeys";
import { useNavigation } from "./useNavigation";
import Thumbnails from "./Thumbnails";

const GalleryModalV2 = ({
  images,
  onClose,
  thumbnailsTitle = "NFT's",
  sidebarContent = null,
  imageIndex = 0,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const totalImages = useMemo(() => images?.length, [images]);

  const currentImage = useMemo(
    () => images[currentImageIndex],
    [currentImageIndex, images]
  );

  const { handleNextImage, handlePrevImage } = useNavigation({
    currentImageIndex,
    totalImages,
    setCurrentImageIndex,
  });

  // On mounting, set the current index to the image that was clicked
  useEffect(() => {
    setCurrentImageIndex(imageIndex);
  }, [imageIndex]);

  useBindKeys({ handleNextImage, handlePrevImage, onClose });

  if (!images) {
    return null;
  }

  return (
    <Modal
      open
      onClose={onClose}
      BackdropComponent={(props) => (
        <Backdrop {...props} style={{ background: "none" }} />
      )}
      className={classes.galleryContainer}>
      <div className={classes.galleryModal}>
        <div className={classes.modalContent}>
          <GalleryModalPreview
            onClose={onClose}
            // Hack: transform nft object so that we can display the image. remove when API sends media objects
            mediaItem={{
              src: currentImage?.display_image,
              scaled_src: currentImage?.display_image,
              alt: currentImage?.name,
              ...currentImage,
            }}
          />
          <NavigationButtons
            handleNextImage={handleNextImage}
            handlePrevImage={handlePrevImage}
            currentImageIndex={currentImageIndex}
            totalImages={totalImages}
          />
        </div>
        <div className={classes.galleryRightPanel}>
          <button className={classes.closeButton} onClick={onClose}>
            <X size={16} color="var(--text-1)" />
          </button>
          <div className={classes.galleryTitle}>Image Gallery for</div>
          <PaddingComponent height="16px" />
          {sidebarContent}
          <>
            <PaddingComponent height="24px" />
            <div className={classes.nftTitle}>
              {/* TODO(shaheer): display Images for lens, farcaster, and post types */}
              {thumbnailsTitle}
              <span className={classes.nftCount}>({images.length})</span>
            </div>
            <PaddingComponent height="12px" />
            <Thumbnails
              images={images}
              setCurrentImageIndex={setCurrentImageIndex}
              currentImageIndex={currentImageIndex}
            />
          </>
        </div>
      </div>
    </Modal>
  );
};

export default GalleryModalV2;
