import { TableRowItem } from "components/V2/Profile/ProfileDetails/wallets/components/WalletRowComponent";
import { TableRowTextComponent } from "components/V2/Profile/ProfileDetails/wallets/components/WalletComponents";
import ScreenerTableRowComponent from "./ScreenerTableRowComponent";
import { Link } from "react-router-dom";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import React from "react";
import classes from "./ScreenerTable.module.css";
import profilePic from "assets/png/profile_pic.png";
import {
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import { shortStartingAddress } from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";
const ScreenerTableRowTile = ({
  data,
  item,
  parentIndex,
  rightPadding = "60px",
  allLeftAlign = false,
  bottomBorderRadius = "0px",
}) => {
  const headers = data?.metadata?.headers;
  const columnsKeysList = Object.keys(headers);
  const profiles = data?.metadata?.minimal_profiles;
  const profile = profiles[item["address"].value];

  return (
    <ScreenerTableRowComponent
      bottomBorderRadius={bottomBorderRadius}
      index={parentIndex}>
      {columnsKeysList.map((key, index) => (
        <React.Fragment key={`Column_${item["address"].value}_${index}`}>
          <TableRowItem
            alignment={
              columnsKeysList.length - 1 === index &&
              key !== "address" &&
              !allLeftAlign
                ? "right"
                : "left"
            }
            flex={index === 0 || columnsKeysList.length - 1 === index ? 3 : 2}>
            {key === "address" ? (
              <CustomRow>
                <TableRowTextComponent
                  textClass={`${classes.textRow} ${classes.index}`}
                  text={`${parentIndex + 1}.`}
                />
                <ProfileHoverCard actor={profile}>
                  <div className={classes.profileRow}>
                    <img
                      className={classes.avatar}
                      src={
                        profile.display_picture === "" ||
                        !profile.display_picture
                          ? profilePic
                          : profile.display_picture
                      }
                      alt={"Profile Pic"}
                    />
                    <Link to={item[key]?.link} target="_blank">
                      <TableRowTextComponent
                        textClass={classes.textRow}
                        text={`${
                          item[key]?.display_value ?? ""
                        } (${shortStartingAddress(profile.address, {
                          digitsInAddress: 6,
                        })})`}
                      />
                    </Link>
                  </div>
                </ProfileHoverCard>
              </CustomRow>
            ) : (
              <PaddingComponent
                paddingRight={
                  index === columnsKeysList.length - 1 ? rightPadding : "0px"
                }>
                <ConditionalLink
                  to={{ pathname: item[key]?.link }}
                  sameTab={false}>
                  <TableRowTextComponent
                    textClass={classes.textRow}
                    text={item[key]?.display_value ?? ""}
                  />
                </ConditionalLink>
              </PaddingComponent>
            )}
          </TableRowItem>
        </React.Fragment>
      ))}
      {columnsKeysList.length === 1 && (
        <TableRowItem alignment={"right"} flex={2}></TableRowItem>
      )}
    </ScreenerTableRowComponent>
  );
};

export default ScreenerTableRowTile;
