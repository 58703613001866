import React from "react";
import styles from "./ZxCard.module.scss";
const ZxCard = ({
  children,
  padding = "16px",
  minWidth = "100%",
  cursor = "default",
  borderRadius = "12px",
  minHeight = "100%",
  background = "var(--base)",
  border = "none",
}) => {
  return (
    <div
      style={{
        padding: `${padding}`,
        minWidth,
        minHeight,
        cursor,
        borderRadius,
        backgroundColor: background,
        border: border,
      }}
      className={styles.container}>
      {children}
    </div>
  );
};

export default ZxCard;
