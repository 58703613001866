import {
  PaddingComponent,
  CustomColumn,
  CustomRow,
  TypeTag,
  FlexGrow,
  CustomSkeleton,
} from "components/UI/Components/Components";
import classes from "./ActivityDetails.module.css";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";
import { TransactionDetailsGhost } from "./Transactions/TransactionDetailsGhost";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import TransactionInternalCallsGhost from "./Transactions/InternalCalls/InternalCallsGhost";
export const ActivityDetailsGhost = ({ tabsList }) => {
  let match = useRouteMatch();
  return (
    <div>
      <CustomColumn>
        <PaddingComponent height="24px" />
        <CustomRow>
          <CustomSkeleton
            animation="wave"
            variant="rectangular"
            height={40}
            width={40}
          />
          <PaddingComponent width="20px" />
          <FlexGrow>
            <CustomColumn>
              <CustomSkeleton width={100} animation="wave" variant="text" />
              <PaddingComponent height="4px" />
              <CustomRow gap="8px">
                <CustomSkeleton animation="wave" width={200} variant="text" />
                <TypeTag text={"Transaction"}></TypeTag>
              </CustomRow>
            </CustomColumn>
          </FlexGrow>
          <CustomSkeleton
            className={classes.avatar}
            animation="wave"
            variant="rectangular"
            height={24}
            width={24}
          />
        </CustomRow>
        <PaddingComponent height="20px" />
        <CustomSkeleton height={60} animation="wave" variant="text" />
        <CustomSkeleton height={40} animation="wave" variant="text" />
        <PaddingComponent height="40px" />
      </CustomColumn>
      <CustomLinkTabBar
        zIndex={TABBAR_ZINDEX.HIGH}
        isEnabled={false}
        tabsList={
          tabsList ?? [
            {
              title: "Details",
              link: "details",
            },
            {
              title: "Internal Calls",
              link: "internal-calls",
            },
            {
              title: "Logs",
              link: "logs",
            },
          ]
        }
      />
      <PaddingComponent paddingTop="16px">
        <Switch>
          <Route path={`${match.path}/details`}>
            <TransactionDetailsGhost></TransactionDetailsGhost>
          </Route>
          <Route path={`${match.path}/internal-calls`}>
            <TransactionInternalCallsGhost />
          </Route>
          <Route path={`${match.path}/logs`}>
            <TransactionInternalCallsGhost />
          </Route>
        </Switch>
      </PaddingComponent>
    </div>
  );
};
