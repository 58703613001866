import WalletDetailsHeader from "./WalletDetailsHeader";
import BundleDetails from "./BundleDetails";
import classes from "./WalletDetails.module.css";
import { useState } from "react";
import { getBundleData } from "api/profile.api";
import withLoader from "components/core/withLoader";
import RightPanelPortfolio from "../../Portfolio/RightPanelPortfolio";

const WalletDetails = ({ queryData, address }) => {
  const bundleData = queryData.data.data;
  var wallet = bundleData.bundle.wallets.find(
    (item) => item?.address === address
  );
  if (!wallet) {
    wallet = bundleData.removed_by_you.find(
      (item) => item?.address === address
    );
  }
  if (!wallet) {
    wallet = bundleData.suggestions.find((item) => item?.address === address);
  }
  const [activeTab, setActiveTab] = useState(0);
  if (!wallet) return null;
  return (
    <div className={classes.rightPanel}>
      <WalletDetailsHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        bundleData={bundleData}
        wallet={wallet}
      />
      {activeTab === 1 && (
        <BundleDetails bundleData={bundleData} wallet={wallet} />
      )}
      {activeTab === 0 && (
        <div className={classes.portfolioWrapper}>
          <RightPanelPortfolio identifier={wallet.address} hideGraph />
        </div>
      )}
    </div>
  );
};
export default withLoader(WalletDetails, getBundleData, {
  queryKey: (props) => ["bundle", props.identifier],
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  renderNotFound: false,
});
