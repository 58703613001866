import classes from "components/V2/ActivityDetails/Transactions/TransactionsRightPanel.module.css";
import {
  CircleAvatar,
  CustomRow,
  PaddingComponent,
  RedirectLink,
} from "components/UI/Components/Components";
import { X, StackSimple } from "@phosphor-icons/react";
import TokenInfo from "./TokenInfo";
import { DayPriceChangePercent } from "./TokenPrice";
import { ReactComponent as CoinGeckoIcon } from "assets/svg/feed/coingecko_icon.svg";
import { getProfileImageType, getShortMonthDayYearDate } from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";
import WalletPrivacy from "../../wallets/components/WalletPrivacy";
import WalletVerifiedBadge from "../../wallets/components/WalletVerifiedBadge";
import AddressComponent from "components/UI/Components/AddressComponent";
import { IconButton } from "@mui/material";
import { IMAGE_TYPES } from "components/UI/Image";

export const RightPaneTitle = ({
  actorInfo,
  currentTokenData,
  onClose,
  initialTokenTransfer,
}) => {
  const name = actorInfo?.details?.display_name ?? actorInfo?.display_name;
  const src = actorInfo?.details?.display_picture ?? actorInfo?.display_picture;
  return (
    <>
      <div className={classes.title}>
        <CustomRow alignItems="center" gap="10px">
          <CustomRow alignItems="center">
            <CircleAvatar
              src={src}
              alt={name}
              name={name}
              imageType={getProfileImageType(actorInfo) ?? IMAGE_TYPES.AVATAR}
              style={{ marginRight: "-5px", zIndex: 1 }}
            />
            <TokenInfo
              token={currentTokenData}
              iconClassName={classes.rightPaneTokenIcon}
              wrapperClassName={classes.rightPaneTokenWrapper}
              iconHeight="24px"
              iconWidth="24px"
              hideName
            />
          </CustomRow>
          <CustomRow flexGrow={1} gap="8px">
            <RedirectLink link={actorInfo?.details?.link ?? actorInfo?.link}>
              {name}
            </RedirectLink>
            {` x `}
            <RedirectLink
              link={
                currentTokenData?.token_link ?? currentTokenData?.link ?? null
              }>
              {currentTokenData?.token_metadata?.symbol +
                (currentTokenData?.token_metadata?.symbol === "ETH" &&
                currentTokenData?.chain_id === "Ethereum"
                  ? ""
                  : " on " + currentTokenData?.chain_id)}
            </RedirectLink>
          </CustomRow>
          <IconButton onClick={onClose}>
            <X size={16} color="var(--text-1)" />
          </IconButton>
        </CustomRow>
        <PaddingComponent height="8px" />
        {!!initialTokenTransfer && (
          <div className={classes.subText}>
            {`First ${currentTokenData?.token_metadata?.symbol} transaction on 
        ${getShortMonthDayYearDate(initialTokenTransfer.block_timestamp)}`}
          </div>
        )}
      </div>
    </>
  );
};

export const TokenInfoBoxHeader = ({
  currentTokenData,
  chainId,
  dayPriceChangePercent,
}) => {
  return (
    <CustomRow justifyContent="space-between" alignItems="center">
      <CustomRow alignItems="center" gap="8px">
        <TokenInfo
          token={currentTokenData}
          iconClassName={classes.rightPaneTokenIcon}
          nameClassName={classes.rightPaneTokenName}
          wrapperClassName={classes.rightPaneTokenWrapper}
          showImageFullHeightWidth
          iconHeight="20px"
          iconWidth="20px"
        />
        <div className={classes.subText}>{`on ${chainId}`}</div>
      </CustomRow>
      <CustomRow gap="8px" alignItems="center">
        {currentTokenData?.usd_price?.value > 0 &&
          dayPriceChangePercent !== null && (
            <DayPriceChangePercent
              dayPriceChangePercent={dayPriceChangePercent}
            />
          )}
        {currentTokenData?.usd_price?.display_value}
      </CustomRow>
    </CustomRow>
  );
};

export const TokenInfoBoxContent = ({ tokenLink, coingeckoLink }) => {
  return (
    <CustomRow alignItems="center">
      <span className={classes.externalLinks}>Links: </span>
      <PaddingComponent paddingRight="16px"></PaddingComponent>
      <CustomRow alignItems="center">
        <InfoBoxLinkItem
          link={tokenLink}
          icon={<StackSimple size={16} color="var(--text-2)" />}
          title="Token Page"
        />
        <PaddingComponent paddingRight="16px"></PaddingComponent>

        {!!coingeckoLink && (
          <InfoBoxLinkItem
            link={coingeckoLink}
            icon={<CoinGeckoIcon />}
            title="CoinGecko"
          />
        )}
      </CustomRow>
    </CustomRow>
  );
};

export const TokenWalletMap = ({ wallets }) => {
  return (
    <CustomRow alignItems="center" padding="8px 0 0">
      <span className={classes.externalLinks}>In Your Wallets: </span>
      <PaddingComponent paddingRight="8px"></PaddingComponent>
      <CustomRow alignItems="center">
        {wallets.map((wallet, index) => (
          <CustomRow
            alignItems="center"
            gap="4px"
            padding="0px 8px"
            key={"token-wallet-" + index}>
            <WalletPrivacy wallet={wallet} size={16} />
            <AddressComponent index={index + 1} address={wallet?.address} />
            <WalletVerifiedBadge wallet={wallet} size={16} />
          </CustomRow>
        ))}
      </CustomRow>
    </CustomRow>
  );
};

const InfoBoxLinkItem = ({ link, title, icon }) => {
  return (
    <ConditionalLink to={link}>
      <CustomRow gap="4px" alignItems="center">
        {icon}
        <div className={classes.view_protocol_text}>{title}</div>
      </CustomRow>
    </ConditionalLink>
  );
};
