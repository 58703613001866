import Image, { IMAGE_TYPES } from "components/UI/Image";
import ConditionalLink from "shared/ConditionalLink";
import classes from "./tokenInfoWrapper.module.css";

export const TokenIcon = ({
  icon,
  token_url,
  overlay,
  height = "16px",
  width = "16px",
}) => {
  return (
    <ConditionalLink
      to={{
        pathname: token_url,
      }}
      className={`${classes.protocolIcon} ${!!overlay ? classes.overlay : ""}`}
      style={{ height, width }}>
      <Image
        className={`${classes.protocolIcon} ${
          !!overlay ? classes.overlay : ""
        }`}
        src={icon}
        imageType={IMAGE_TYPES.TOKEN}
        style={{ height, width }}
      />
    </ConditionalLink>
  );
};

export const TokenSymbol = ({ symbol, className }) => {
  return <div className={`${classes.tokenSymbol} ${className}`}>{symbol}</div>;
};
export const TokenBalance = ({ balance, className }) => {
  return (
    <div className={`${classes.tokenBalance} ${className}`}>
      {balance?.display_value ? balance?.display_value : balance?.symbol}{" "}
    </div>
  );
};

export const TokenInfoWrapper = ({
  token_url,
  token_icon,
  info,
  isTrending,
}) => {
  return (
    <ConditionalLink
      to={{
        pathname: token_url,
      }}
      className={`${classes.tokenInfo} ${
        isTrending ? classes.isTrending : ""
      }`}>
      <TokenIcon icon={token_icon} />
      <div className={classes.infoWrapper}>{info}</div>
    </ConditionalLink>
  );
};
