import classes from "./SocialLogins.module.css";
import { useHistory } from "react-router-dom";
import TwitterButton from "./TwitterButton";

import socialsBanner from "assets/svg/connect_socials.svg";

import OnboardingBg from "../OnboardingBg";
import { useCallback, useContext, useEffect, useState } from "react";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { trackEvent } from "utils/event_tracking";
import LoaderButton from "components/UI/LoaderButton/LoaderButton";
import {
  CustomColumn,
  CustomRow,
  Divider,
  SpinnerComponent,
} from "components/UI/Components/Components";
import ConnectLens from "./ConnectLens";
import useNavigateNewOnboarding from "../NavigateOnboarding";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "utils/query_utils";
import { AuthContext } from "contextStates/AuthContext";
import {
  getOnboardingData,
  moveOnboardingState,
  updateCookiesForIdentity,
} from "api/waitlist.api";
import { GlobalContext } from "contextStates/Global";
import FarcasterSignInButton from "zxComponents/FarcasterSignInButton/FarcasterSignInButton";
import { isTestingOnboarding } from "utils/constants";
import { useGetSocialCircle } from "api/community.api";
import ZxImageStack from "zxComponents/common/ZxImageStack/ZxImageStack";
import { TSTYLES } from "zxStyles/constants";
import { CaretRight } from "../../../../../node_modules/@phosphor-icons/react/dist/index";
import ZxText from "zxComponents/common/ZxText/ZxText";
import ZxModal from "zxComponents/common/ZxModal/ZxModal";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import { getTrimmedString } from "utils/misc";
import useScreenRes from "customHooks/useMediaQuery";
import { logSentryEvent } from "utils/sentry";
let timer;
const Overlay = ({ enabled }) => {
  return enabled ? (
    <div className={classes.overlay}>
      <SpinnerComponent color="var(--primary-color" size={50} />
    </div>
  ) : null;
};
export const SocialFollowers = ({ profileList, identities, title }) => {
  const hasFollows = profileList?.length > 1;
  const { isMobile } = useScreenRes();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const profilePictures = profileList?.map((fl) => {
    const profile = identities[fl];
    if (!profile) return null;
    return {
      src: profile?.display_picture ?? null,
      name: profile?.name ?? null,
      alt: profile?.name ?? null,
    };
  });
  const names = profileList?.slice(0, 4)?.map((fl) => {
    const profile = identities[fl];
    return getTrimmedString(profile?.display_name, 8);
  });
  if (!hasFollows || profileList?.length === 0) return null;
  return (
    <>
      <CustomColumn onClick={() => setIsModalOpen(true)}>
        <Divider margin="12px 0" />
        <CustomRow justifyContent="space-between" alignItems="center">
          <CustomRow alignItems="center" gap="8px">
            <ZxImageStack
              pictures={profilePictures?.slice(0, 3)}
              width="24px"
              tooltip
              height="24px"
            />
            <CustomRow
              gap="4px"
              alignItems="center"
              justifyContent="space-between">
              <ZxText
                style={TSTYLES.secondary1}
                fontSize={isMobile ? "11px" : "13px"}>
                {names?.join(", ")}{" "}
                {profileList?.length > 4 ? `+${profileList?.length - 4}` : ``}{" "}
                friends on 0xPPL
              </ZxText>
              <CaretRight size={12} color="var(--text-2)" weight="bold" />
            </CustomRow>
          </CustomRow>
          <ZxText
            style={TSTYLES.bodyLabel}
            color="primary"
            fontSize={isMobile ? "11px" : "13px"}>
            View to follow
          </ZxText>
        </CustomRow>
      </CustomColumn>
      <ZxModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={`${title} friends on 0xPPL`}>
        <CustomColumn height="450px" overflowX="scroll" overflowY="scroll">
          {profileList?.map((id, index) => {
            const actor = identities[id];
            return (
              <>
                <ZxActor
                  actor={actor}
                  key={index}
                  showNetworth
                  displayNameStyle="body"
                  nameLength={35}
                  ignoreLoginCheck
                />
                <Divider margin="8px 0" />
              </>
            );
          })}
        </CustomColumn>
      </ZxModal>
    </>
  );
};
const SocialLogins = () => {
  const { getUserAddress, logout } = useContext(AuthContext);
  const { data: circle, refetch } = useGetSocialCircle();
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: QUERY_KEYS.ONBOARING_INTERACTION({
      wallet: getUserAddress(),
    }),
    queryFn: () =>
      getOnboardingData({ identifier: getUserAddress(), withError: true }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const [isNavigating, setIsNavigating] = useState(false);
  const loadingNavigation = useNavigateNewOnboarding(
    isNavigating,
    setIsNavigating
  );

  const [isNextStepLoading, setIsNextStepLoading] = useState(false);
  const onboardingState = data?.data?.data ?? null;

  const [connectedSocials, setConnectedSocials] = useState([]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const isLensConnected = connectedSocials.includes("lens");
  const isTwitterConnected = connectedSocials.includes("twitter");
  const isFarcasterConnected = connectedSocials.includes("farcaster");
  const moveToNextStep = async () => {
    setIsNextStepLoading(true);
    try {
      trackEvent("connect_socials_continue_btn_clicked");
      const res = await moveOnboardingState({
        currentStep: "connect_socials",
      });
      try {
        getOnboardingData({ identifier: getUserAddress() });
      } catch (err) {
        logSentryEvent(err, {
          msg: "Error in fetching onboarding data after moving to next step",
        });
      }
      if (res?.onboarding_state?.current) {
        if (!isTestingOnboarding) {
          const existingUid = localStorage.getItem(`user_cookies_uid`);
          if (!existingUid) {
            try {
              await updateCookiesForIdentity();
            } catch (err) {
              logSentryEvent(err, {
                msg: "Error in getting UID for QR code",
              });
            }
          }
          history.push(`/onboarding/${res.onboarding_state.current}`);
        }
      } else setIsNavigating(true);
    } catch (err) {
      console.error(err);
    } finally {
      setIsNextStepLoading(false);
    }
  };
  useEffect(() => {
    if (!isLoading && data?.is_error) {
      if (data?.response?.status === 500) {
        logout();
        history.push("/onboarding/intro");
        return;
      } else {
        setIsError(true);
      }
    }
  }, [data, isLoading, history, logout, handleErrorSnackbar]);

  useEffect(() => {
    if (isError) {
      handleErrorSnackbar?.(null, "An error occurred. Please try again later.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const isButtonDisabled =
    connectedSocials?.length === 0 || !onboardingState?.current_step;
  const addToConnectedSocials = useCallback(
    (social) => {
      setConnectedSocials((prev) => [...prev, social]);
    },
    [setConnectedSocials]
  );
  const onFarcasterSuccess = () => {
    addToConnectedSocials("farcaster");
    refetch();
  };
  const onLensSuccess = () => {
    addToConnectedSocials("lens");
    refetch();
  };
  useEffect(() => {
    if (onboardingState && onboardingState?.connected_socials) {
      setConnectedSocials(onboardingState?.connected_socials);
    }
  }, [onboardingState]);
  return (
    <BaseWrapper mixpanelEvent="OnboardingConnectSocials">
      <OnboardingBg>
        <div className={classes.mainContent}>
          <Overlay enabled={isLoading} />
          <div className={classes.header}>
            <h1 className={classes.mainTitle}>Connect your socials</h1>
            <p className={classes.subtitle}>
              Connect your social accounts for a tailored feed and onchain
              updates from friends
            </p>
          </div>
          <div className={classes.socialsContainer}>
            <FarcasterSignInButton
              isSocialLogin
              isSocialConnected={isFarcasterConnected}
              onSuccess={onFarcasterSuccess}
              isFetching={isFetching}
              follows={circle?.farcaster}
              identities={circle?.identities}
            />
            <TwitterButton
              connectTwitterText="Twitter"
              disabled={isTwitterConnected || isLoading}
              isConnected={isTwitterConnected}
              isOnboardingStateLoading={isLoading}
              follows={circle?.twitter}
              identities={circle?.identities}
            />
            <ConnectLens
              isConnected={isLensConnected}
              onSuccess={onLensSuccess}
              disabled={isLensConnected}
              isFetching={isFetching}
              follows={circle?.lens}
              identities={circle?.identities}
            />
          </div>
          <CustomColumn gap="24px" style={{ marginTop: "20px" }}>
            <img src={socialsBanner} alt="Socials Banner" />
            <LoaderButton
              disabled={isButtonDisabled}
              checkLogin={false}
              onClick={moveToNextStep}
              loading={isNextStepLoading || loadingNavigation}
              className={`${classes.continueButton} ${isButtonDisabled ? classes.disabled : ``}`}>
              Continue
            </LoaderButton>
          </CustomColumn>
        </div>
      </OnboardingBg>
    </BaseWrapper>
  );
};

export default SocialLogins;
