import React from "react";
import styles from "./ZxTokenInfo.module.scss";
import {
  CustomColumn,
  CustomRow,
  PriceChange,
} from "components/UI/Components/Components";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import { IMAGE_TYPES } from "components/UI/Image";
import ZxText from "../ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import WatchlistTokenButton from "components/V2/Watchlist/WatchlistTokenButton";
import ConditionalLink from "shared/ConditionalLink";
import TokenHoverCard from "components/FeedItemV2/TokenHover/TokenHoverCard";
import { getTrimmedString } from "utils/misc";
const ZxTokenInfo = ({
  token,
  price,
  percentChange,
  isWatchlisted,
  refetch,
}) => {
  const {
    display_name: name,
    display_picture: logo,
    address_chain: chain,
    address,
    link,
  } = token;
  return (
    <div className={styles.container}>
      <CustomRow gap="8px">
        <ConditionalLink to={link}>
          <IconWithChain
            chain={chain}
            imageType={IMAGE_TYPES.TOKEN}
            src={logo}
            iconHeight="40px"
            iconWidth="40px"
            chainHeight="12px"
            chainWidth="12px"
            showImageFullHeightWidth
          />
        </ConditionalLink>
        <CustomColumn gap="2px">
          <CustomRow alignItems="center" gap="4px">
            <TokenHoverCard tokenProfile={token}>
              <ZxText style={TSTYLES.title2} cursor="pointer">
                {getTrimmedString(name, 12)}
              </ZxText>
            </TokenHoverCard>
            <ZxText style={TSTYLES.secondary2}>{chain}</ZxText>
          </CustomRow>
          <CustomRow gap="8px" alignItems="center">
            {price && price.value && (
              <ZxText style={TSTYLES.bodyLabel}>{price?.display_value}</ZxText>
            )}
            {percentChange && (
              <PriceChange
                fontWeight="500"
                fontSize="12px"
                value={percentChange?.value}
                displayValue={percentChange?.display_value}
                showCaret={false}
              />
            )}
          </CustomRow>
        </CustomColumn>
      </CustomRow>
      <WatchlistTokenButton
        isWatchlisted={isWatchlisted}
        chainId={chain}
        tokenAddress={address}
        refetch={refetch}
      />
    </div>
  );
};

export default ZxTokenInfo;
