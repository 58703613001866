import { useContext } from "react";
import { CHAIN_TAB_ROUTES } from "utils/constants";
import { GlobalContext } from "contextStates/Global";
import { Redirect } from "react-router-dom";

import { FeedContainer } from "components/UI/Components/ResizeFeedComponent";
import { Route } from "react-router-dom/cjs/react-router-dom";
import useQueryParams from "customHooks/useQueryParams";
import AllBlocksPage from "components/ChainPage/AllBlocksPage";
import BlockDetailsPage from "components/ChainPage/BlockDetailsPage";
import ChainPage from "components/ChainPage/ChainPage";
import NativeTokenDetails from "components/NativeTokenDetails/NativeTokenDetails";
import TransactionActivityDetails from "components/V2/ActivityDetails/TransactionActivityDetails";
import AddressToDetails from "components/AddressToDetails/AddressToDetails";
const ChainRoutes = ({ supportedChains }) => {
  const { supportedChainsLowerCaseDict } = useContext(GlobalContext);
  const params = useQueryParams();
  return (
    <>
      <Route exact path={`/:chain_id${supportedChains}/native_token/:address`}>
        <FeedContainer>
          <NativeTokenDetails />
        </FeedContainer>
      </Route>
      <Route
        exact
        path={`/:chain_id${supportedChains}/tx/:tx_hash/:active_tab?/:engagement_type?`}
        render={({ match }) => {
          if (!match.params.active_tab) {
            const preferredActor = params.get("preferred_actor");
            return (
              <Redirect
                to={`/${match.params.chain_id}/tx/${match.params.tx_hash}/details${params?.toString()?.length > 0 ? `?preferred_actor=${preferredActor?.split("/")?.[0]}` : ""}`}
              />
            );
          }
          return (
            <FeedContainer>
              <TransactionActivityDetails
                tx_hash={match.params.tx_hash}
                chain_type={getChainType({
                  match,
                  supportedChainsLowerCaseDict,
                })}
                chain_id={getChainId({ match, supportedChainsLowerCaseDict })}
              />
            </FeedContainer>
          );
        }}
      />
      <Route
        exact
        path={`/:chain_id${supportedChains}/blocks`}
        render={({ match }) => {
          return (
            <FeedContainer>
              <AllBlocksPage
                chainType={getChainType({
                  match,
                  supportedChainsLowerCaseDict,
                })}
                chainId={getChainId({ match, supportedChainsLowerCaseDict })}
              />
            </FeedContainer>
          );
        }}
      />
      <Route
        exact
        path={`/:chain_id${supportedChains}/block/:blockNumber(\\d+)/:active_tab?`}
        render={({ match }) => {
          return (
            <FeedContainer>
              <BlockDetailsPage
                chainType={getChainType({
                  match,
                  supportedChainsLowerCaseDict,
                })}
                chainId={getChainId({ match, supportedChainsLowerCaseDict })}
                blockNumber={match.params.blockNumber}
              />
            </FeedContainer>
          );
        }}
      />
      <Route
        exact
        path={`/:chain_id${supportedChains}/:active_tab${CHAIN_TAB_ROUTES}?`}
        render={({ match }) => {
          if (!match.params.active_tab) {
            return <Redirect to={`/${match.params.chain_id}/overview`} />;
          }

          return (
            <FeedContainer>
              <ChainPage
                chainType={getChainType({
                  match,
                  supportedChainsLowerCaseDict,
                })}
                chainId={getChainId({ match, supportedChainsLowerCaseDict })}
                activeTab={match.params.active_tab}
              />
            </FeedContainer>
          );
        }}
      />

      {/* Redirects */}
      <Route
        exact
        path={`/:chain_id${supportedChains}/address/:address`}
        component={AddressToDetails}
      />
      <Route
        exact
        path={`/:chain_id${supportedChains}/token/:address`}
        component={AddressToDetails}
      />
      <Route
        exact
        path={`/:chain_id${supportedChains}/contract_details/:address/:active_tab?`}
        render={({ match }) => {
          return (
            <Redirect
              to={`/c/${match.params.chain_id}/${match.params.address}/${
                match.params.active_tab || "home"
              }`}
            />
          );
        }}
      />
      <Route
        exact
        path={`/:chain_id${supportedChains}/chain_home/:active_tab?`}
        render={({ match }) => {
          return (
            <Redirect
              to={`/${match.params.chain_id}/${match.params.active_tab ?? ""}`}
            />
          );
        }}
      />
    </>
  );
};

const getChainId = ({ match, supportedChainsLowerCaseDict }) => {
  return supportedChainsLowerCaseDict
    ? supportedChainsLowerCaseDict[match.params.chain_id.toLowerCase()].chain_id
    : match.params.chain_id;
};

const getChainType = ({ match, supportedChainsLowerCaseDict }) => {
  return supportedChainsLowerCaseDict
    ? supportedChainsLowerCaseDict[match.params.chain_id.toLowerCase()]
        .chain_type
    : null;
};

export default ChainRoutes;
