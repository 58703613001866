import { useCallback } from "react";

export const useNavigation = ({
  currentImageIndex,
  totalImages,
  setCurrentImageIndex,
}) => {
  const handleNextImage = useCallback(() => {
    setCurrentImageIndex((currentImageIndex + 1) % totalImages);
  }, [currentImageIndex, setCurrentImageIndex, totalImages]);

  const handlePrevImage = useCallback(() => {
    setCurrentImageIndex((currentImageIndex - 1 + totalImages) % totalImages);
  }, [currentImageIndex, setCurrentImageIndex, totalImages]);

  return { handleNextImage, handlePrevImage };
};
