import axios from "axios";
import Web3 from "web3";
import { v4 as uuidv4 } from "uuid";
import { tracker } from "../index";
import { getTrackerDeviceId, getTrackerSessionId } from "utils/event_tracking";
// import cwLog from "./cloudwatch";

export const backendURL = "https://0xppl.com";

// export const backendURL = "http://34.74.200.217"; //Abhishek
// export const backendURL = "http://35.229.17.227"; // Vaibhav
// export const backendURL = "http://34.139.171.122/"; // Ansh
// export const backendURL = "http://20.122.166.149"; // Pras
// export const backendURL = "http://3.142.209.138";
// export const backendURL = "http://127.0.0.1";
// export const backendURL = "http://34.75.206.69/"; // bharath
// export const backendURL = "http://34.75.116.12"; // Venkat
// export const backendURL = "http://20.65.56.222"; // Karan
// export const backendURL = "http://34.138.84.128"; // Abhinav
// export const backendURL = "http://18.117.163.62"; // Tushar
// export const backendURL = "http://20.1.207.199"; // Parth
// export const backendURL = "http://3.16.21.33"; // Extra dev server
// export const backendURL = "http://35.196.152.98"; // Saharsh
// export const backendURL = "http://13.58.89.72"; // Vivek
// export const backendURL = "http://35.196.65.187/"; // Sobhagya
// export const backendURL = "http://34.23.124.87"; // Rajeev
// export const backendURL = "http://35.185.24.96"; // Dipankar
// export const backendURL = "http://104.196.143.240"; // Jatinder
// export const backendURL = "http://35.185.42.16"; // Dhruval
// export const backendURL = "http://34.138.72.23"; // Rajeev
// export const backendURL = "http://35.227.53.174/"; // Akshat
// export const backendURL = "http://35.227.111.30"; // Shivam
// export const backendURL = "http://34.75.219.106"; // Gaurav
// export const backendURL = "http://35.190.160.26/"; // Anshu

export const backend = axios.create({
  baseURL: backendURL,
});

export const baseURL = axios.create({
  baseURL: `${backend.defaults.baseURL}/api/v2`,
});

export const requestApi = (config) => {
  return baseURL.request(config);
};

export const updateWaitlistProfile = async (formData) => {
  const resp = await fetch(
    `${backend.defaults.baseURL}/api/0xppl/update_waitlist_profile`,
    {
      method: "POST",
      body: JSON.stringify({
        visitor_id: localStorage.getItem("visitorId"),
        ...formData,
      }),
    }
  );
  return resp;
};

export const getPageData = async (page, params) => {
  const {
    data: { status, data },
  } = await baseURL.get(page, {
    params,
  });
  if (status !== "ok") {
    throw new Error(`Error fetching data for page ${page}`);
  }

  return data;
};

function isEncoded(uri) {
  uri = uri || "";

  return uri !== decodeURI(uri);
}

// Authenticated requests.

export const signedRequest = async ({
  method,
  path,
  headers,
  bodyText,
  signal,
  isMetricsApiRequest,
}) => {
  // if the user of the function didn't add a slash in the beginning of the path
  const firstChar = path.slice(0, 1);
  if (firstChar !== "/") {
    path = `/${path}`;
  }

  // sending pusher channel id
  let pusherChannelId = localStorage.getItem("pusherChannelSessionAddressId");
  const requestId = uuidv4().toString().replace(/-/g, "");

  if (!path.includes("pusher_channel_id")) {
    if (!path.includes("?")) {
      path = `${path}?pusher_channel_id=${pusherChannelId}`;
    } else {
      path = `${path}&pusher_channel_id=${pusherChannelId}`;
    }
  }
  if (!path.includes("request_id")) {
    if (!path.includes("?")) {
      path = `${path}?request_id=${requestId}`;
    } else {
      path = `${path}&request_id=${requestId}`;
    }
  }

  if (!isEncoded(path)) {
    path = encodeURI(path);
  }

  headers = headers || {};

  const whitelistedMethods = ["get", "post", "delete"];

  if (!whitelistedMethods.includes(method)) {
    throw new Error(`Method ${method} not supported`);
  }

  let authHeaders;

  if (isAuthenticated()) {
    authHeaders = calculateAuthHeaders(method, path, bodyText || "");
  }

  if (tracker?.getSessionToken()) {
    headers["X-0xppl-Client-Session-Id"] = getTrackerSessionId();
    headers["X-0xppl-Device-Id"] = getTrackerDeviceId();
    headers["X-OpenReplay-SessionToken"] = tracker.getSessionToken(); // Inject openReplaySessionToken
  }

  if (tracker?.getSessionURL()) {
    headers["X-OpenReplay-SessionUrl"] = tracker.getSessionURL(); // Inject openReplaySessionURL
  }
  let tz;
  try {
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) {
    tz = "Unknown";
  }
  // const before = new Date().getTime();
  const response = await backend.request({
    url: path,
    method,
    headers: {
      ...(headers || {}),
      ...authHeaders,
      ...{
        "X-0XPPL-REQUEST-ID": requestId,
        "X-0XPPL-PLATFORM-TIME-ZONE": tz,
      },
      // ...{
      //   "X-0XPPL-AUTH-LOGIN-AS": "0xA790d081A78182D9D3d217232AcB352b4D98409F",
      // },
    },
    data: bodyText,
    signal,
  });
  // const after = new Date().getTime();
  // const latency = after - before;
  // const message = `API CALL - path - ${path} \n method - ${method} \n latency - ${latency}ms \n status - ${response.status} \n payload - ${bodyText}`;
  // cwLog({ message });
  return response;
};

export const signedApiRequest = async (config) => {
  config.path = `/api/0xppl/${config.path || ""}`;
  return signedRequest(config);
};

export const signedApiOtherRequest = async (config) => {
  config.path = `/api/${config.path || ""}`;
  return signedRequest(config);
};

export const signedApiV4Request = async (config) => {
  config.path = `/api/v4/${config.path || ""}`;
  return signedRequest(config);
};

// Note: Signing just the body text is convenient and sufficient
// for now. Maybe we can also sign over method, url and any relevant headers
// as well if needed later.

const isAuthenticated = () => {
  const sessionPrivateKey = localStorage.getItem("sessionPrivateKey");
  const tokenSerialized = localStorage.getItem("tokenSerialized");
  const tokenSignature = localStorage.getItem("tokenSignature");
  return sessionPrivateKey && tokenSerialized && tokenSignature;
};

export const calculateAuthHeaders = (method, path, bodyText) => {
  if (!isAuthenticated()) {
    throw new Error("Not authenticated. Missing session state");
  }

  const sessionPrivateKey = localStorage.getItem("sessionPrivateKey");
  const tokenSerialized = localStorage.getItem("tokenSerialized");
  const tokenSignature = localStorage.getItem("tokenSignature");
  let walletType = localStorage.getItem("walletType");
  if (walletType == null) {
    localStorage.setItem("walletType", "EVM");
    walletType = "EVM";
  }

  const web3 = new Web3(window?.ethereum ?? null);
  const tsNow = () => parseInt(new Date().getTime() / 1000);

  const ts = tsNow();
  const nonce = Math.random().toString(36).substring(2);

  const signaturePayload = JSON.stringify([
    ["ts", String(ts)],
    ["nonce", String(nonce)],
    ["method", method.toLowerCase()],
    ["path", path],
    ["body", bodyText],
  ]);

  const signature = web3.eth.accounts.sign(signaturePayload, sessionPrivateKey);

  return {
    "X-0XPPL-AUTH-TS": ts,
    "X-0XPPL-PLATFORM": "web",
    "X-0XPPL-AUTH-NONCE": nonce,
    "X-0XPPL-AUTH-REQUEST-SIGNATURE": signature.signature,
    "X-0XPPL-AUTH-SESSION-TOKEN": btoa(tokenSerialized),
    "X-0XPPL-AUTH-SESSION-TOKEN-SIGNATURE": tokenSignature,
    "X-0XPPL-AUTH-WALLET-TYPE": walletType,
  };
};

export const getPaginatedActivitiesPath = (
  type,
  identifier,
  activeChainId,
  activitiesForContract,
  selectedActiveChains,
  selectedActiveTokens,
  selectedActiveProtocols,
  selectedFeedType,
  selectedDateRange,
  chainType,
  exclude_activity_groups_with_hex_actor
) => {
  const paramsDict = {
    page_size: 20,
  };
  if (selectedDateRange) {
    paramsDict["last_cursor"] = selectedDateRange.start.getTime() / 1000;
    if (selectedDateRange.end) {
      selectedDateRange.end.setHours(23);
      selectedDateRange.end.setMinutes(59);
      selectedDateRange.end.setSeconds(59);
      paramsDict["cursor"] = selectedDateRange.end.getTime() / 1000;
    }
  }
  if (type !== "protocol") {
    if (activeChainId) {
      paramsDict["chain_id"] = activeChainId;
    }
    paramsDict["activities_for_contract"] = activitiesForContract;
  }
  if (type === "chain") {
    paramsDict["chain_type"] = chainType;
  }

  const params = new URLSearchParams(paramsDict);

  let path;

  switch (type) {
    case "person_details":
    case "contract_details":
      path = `/api/v4/get_activities_by_address?viewee_address=${identifier}&exclude_activity_groups_with_hex_actor=${exclude_activity_groups_with_hex_actor}`;
      break;
    case "identity_details":
      path = `/api/0xppl/get_activities_by_identity_id?identity_id=${identifier}`;
      break;
    case "protocol":
      path = `/api/v4/get_protocol_activities?identifier=${identifier}`;
      break;
    case "chain":
      path = `/api/v4/get_activities_for_chain?identifier=${identifier}`; // chain_id filter filled above
      break;
    default:
      path = `/api/0xppl/get_activities_v1?identifier=${identifier}`;
      break;
  }
  if (
    selectedActiveChains?.length > 0 &&
    selectedActiveChains?.filter((c) => c?.length > 0)
  ) {
    //make string of chain ids seperated with commas by join
    let chainIdsString = selectedActiveChains?.join(",");

    path += `&chain_ids=${chainIdsString}`;
  }
  //list of tokens comma separated
  // chain selectedActiveTokens with selectedActiveProtocols
  const tokenAddressesListString =
    selectedActiveTokens
      ?.filter((token) => !!token?.contract_address)
      .map(({ contract_address }) => contract_address)
      .join(",") ?? "";
  const protocolTokensList =
    selectedActiveProtocols
      ?.filter((token) => !!token?.contract_address)
      .map(({ contract_address }) => contract_address)
      .join(",") ?? "";
  const result = [];
  if (tokenAddressesListString) {
    result.push(tokenAddressesListString);
  }
  if (protocolTokensList) {
    result.push(protocolTokensList);
  }
  if (result.length > 0) {
    path += `&token_addrs=${result.join(",")}`;
  }

  if (selectedFeedType?.length > 0) {
    const feedTypeParams = {
      is_onchain: false,
      is_offchain: false,
      is_replies: false,
    };

    selectedFeedType.forEach((type) => {
      if (type === "posts") {
        feedTypeParams.is_offchain = true;
      } else if (type === "transactions") {
        feedTypeParams.is_onchain = true;
      } else if (type === "replies") {
        feedTypeParams.is_replies = true;
      }
    });

    path += `&is_onchain=${feedTypeParams.is_onchain}&is_offchain=${feedTypeParams.is_offchain}&is_replies=${feedTypeParams.is_replies}`;
  }

  return `${path}&${params.toString()}`;
};
