import { Avatar } from "stream-chat-react";
import classes from "./AvatarWithPresence.module.css";
import { UsersFour } from "../../../../../../node_modules/@phosphor-icons/react/dist/index";

const AvatarWithPresence = (props) => {
  return (
    <div className={classes.avatarWrapper}>
      <Avatar {...props} />
      {props?.isGroup ? (
        <div className={classes.groupIcon}>
          <UsersFour size={12} color="var(--text-2)" />
        </div>
      ) : (
        props?.user?.online && <div className={classes.presence} />
      )}
    </div>
  );
};

export default AvatarWithPresence;
