import React from "react";
import classes from "./ghostNftHoldings.module.css";
import { Skeleton } from "@mui/material";
import { conditionalClass } from "utils/misc";

const GhostNftHoldings = ({ rightPane }) => {
  return (
    <div
      className={`${classes.nftHoldings} ${conditionalClass(
        rightPane,
        classes.rightPane
      )}`}>
      {Array(2)
        .fill(null)
        .map((item, index) => (
          <GhostNftCollectionItem key={`ghost-collection-item-${index}`} />
        ))}
    </div>
  );
};

const GhostNftCollectionItem = (props) => {
  return (
    <div className={classes.nftCollectionItem}>
      <div className={classes.categoryItems}>
        {Array(4)
          .fill(null)
          .map((item, index) => (
            <GhostNftItem key={`ghost-nft-item-${index}`} />
          ))}
      </div>
    </div>
  );
};
export const GhostNftItem = () => {
  return (
    <div className={classes.nftItem}>
      <div className={classes.imageWrapper}>
        <Skeleton
          height={145}
          width={174}
          variant="rectangular"
          animation="wave"
        />
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.title}>
          <Skeleton variant="text" animation="wave" />
        </div>

        <div className={classes.subTitle}>
          <Skeleton variant="text" animation="wave" />
        </div>
      </div>
    </div>
  );
};

export default GhostNftHoldings;
