import React, { useEffect, useRef, useState } from "react";
import classes from "./selectableDivWithDelay.module.css";
const SelectableDivWithDelay = ({
  onClick = () => {},
  children,
  className,
  clickable = true,
}) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleMouseDown = () => {
    setIsMouseDown(false);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsMouseDown(true);
    }, 500);
  };

  const handleClick = (e) => {
    if (!isMouseDown) {
      onClick(e);
      e.stopPropagation();
    }
  };
  return (
    <div
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      className={`${className} ${(clickable && classes.selectableDiv) || ""}`}>
      {children}
    </div>
  );
};

export default SelectableDivWithDelay;
