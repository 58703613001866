import { X } from "@phosphor-icons/react";
import { crossPostComment } from "api/feed.api";
import Modal from "@mui/material/Modal";
import farcaster from "assets/svg/farcaster.svg";
import lens from "assets/svg/lens.svg";
import twitter_x_icon from "assets/svg/twitter_x_icon.svg";
import {
  CircleAvatar,
  CustomButton,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import { useContext, useEffect, useState } from "react";
import classes from "./CrossPostModal.module.css";
import { GlobalContext } from "contextStates/Global";
import { logSentryEvent } from "utils/sentry";

const CrossPostModal = ({
  avatarUrl,
  name,
  showModal,
  setShowModal,
  currentComment,
  commentSuccessResponse,
  connectedPlatforms,
  actor,
  onSuccess,
  onClose,
  resetCommentSuccessResponse,
}) => {
  const [commonSocials, setCommonSocials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const toggleModal = () => {
    if (showModal && onClose) {
      onClose();
      if (resetCommentSuccessResponse) resetCommentSuccessResponse();
    }
    setShowModal(!showModal);
  };

  const handleCrossPost = async () => {
    setIsLoading(true);
    const platforms = [];
    if (
      connectedPlatforms.includes("twitter") &&
      actor?.external_links?.twitter
    ) {
      platforms.push("twitter");
    }
    if (connectedPlatforms.includes("lens") && actor?.external_links?.lens) {
      platforms.push("lens");
    }
    const postData = {
      post_id: commentSuccessResponse?.id,
      type: commentSuccessResponse?.type,
      contents: commentSuccessResponse?.post?.contents,
      urls: commentSuccessResponse?.post?.url_previews || [],
      images:
        commentSuccessResponse?.post?.images?.map((i) => ({
          ...i,
          src: i.downsampled_image,
          scaled_src: i.image,
        })) || [],
      selectedPlatforms: platforms,
    };

    try {
      await crossPostComment(postData);
      handleSuccessSnackbar("Cross posted successfully!");
      toggleModal();
      onSuccess();
      resetCommentSuccessResponse();
    } catch (error) {
      logSentryEvent(error, {
        extra: {
          message: "Error while cross posting!",
          postData,
        },
      });
      handleErrorSnackbar(error, "Error while cross posting!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleNoComment = () => {
    toggleModal();
  };
  useEffect(() => {
    const getCommonSocials = () => {
      const socials = [];
      if (
        connectedPlatforms.includes("twitter") &&
        actor?.external_links?.twitter
      ) {
        socials.push("twitter");
      }
      if (connectedPlatforms.includes("lens") && actor?.external_links?.lens) {
        socials.push("lens");
      }
      return socials;
    };
    setCommonSocials(getCommonSocials());
  }, [connectedPlatforms, actor]);
  const iconMap = {
    lens: lens,
    twitter: twitter_x_icon,
    farcaster: farcaster,
  };
  if (!commonSocials || commonSocials.length === 0) {
    setShowModal(false);
    return;
  }
  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className={classes.modalOverlay}>
          <div className={classes.modal}>
            <div className={classes.closeButton} onClick={toggleModal}>
              <X size={24} color="var(--text-1)" weight="bold" />
            </div>
            <CircleAvatar
              src={avatarUrl}
              alt={name}
              height="72px"
              width="72px"
            />
            <PaddingComponent height="20px" />
            <CustomText
              text={`${name} is not on 0xPPL yet!`}
              fontSize="20px"
              fontWeight="600"
              textAlign="center"
              letterSpacing="-0.4px"
              lineHeight="150%"
              color="var(--text-1)"
            />
            <PaddingComponent height="10px" />
            <CustomText
              text="Would you also like to cross post your comment by tagging them on social?"
              fontSize="14px"
              fontWeight="400"
              textAlign="center"
              letterSpacing="-0.28px"
              lineHeight="160%"
              color="var(--text-2)"
              width="346px"
              maxWidth="100%"
            />
            <PaddingComponent height="20px" />

            <div className={classes.pillsContainer}>
              {commonSocials.map((platform) => (
                <div key={platform} className={classes.pill}>
                  <div className={classes.iconContainer}>
                    <img
                      src={iconMap[platform]}
                      alt={platform}
                      className={classes.icon}
                    />
                  </div>
                  <span className={classes.pillText}>{platform}</span>
                </div>
              ))}
            </div>

            <PaddingComponent height="20px" />
            <CustomButton
              text={isLoading ? null : "Yes, I’ll try cross post"}
              onClick={handleCrossPost}
              isLoading={isLoading}
              background="#0648d7"
              color="#ffffff"
              fontSize="15px"
              padding="8px 16px"
              borderRadius="36px"
              fontWeight="600"
              letterSpacing="-0.3px"
              lineHeight="150%"
              className={classes.primaryButton}
              style={{ width: "100%" }}
            />
            <PaddingComponent height="20px" />
            <button
              className={classes.secondaryButton}
              onClick={handleNoComment}>
              No, comment only on 0xPPL
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CrossPostModal;
