import { abbreviateNumber } from "utils/misc";
import { isTokenIncludedForSelectedTokens } from "utils/profile_utils";
import { useMemo } from "react";
import { find, sortBy } from "lodash";

const useTokenHoldingsFilter = ({
  data,
  selectedActiveChains,
  selectedActiveTokens,
  selectedActiveProtocols,
  selectedWalletAddresses,
}) => {
  const filteredData = useMemo(() => {
    let holdingsData = [...(data?.holdings || [])];
    const holdingsByAddressData = data?.holdings_by_address || {};

    // create new combined holdings data
    if (selectedWalletAddresses) {
      const newHoldingsData = [];
      for (let i = 0; i < selectedWalletAddresses.length; i++) {
        const address = selectedWalletAddresses[i];
        const currentHoldings = holdingsByAddressData[address]?.holdings;
        for (let j = 0; j < currentHoldings?.length; j++) {
          const holding = currentHoldings[j];
          let found = find(newHoldingsData, {
            chain_id: holding.chain_id,
            contract_address: holding.contract_address,
          });
          if (found) {
            if (found.total_usd_value) {
              found.total_usd_value.value =
                (found.total_usd_value?.value || 0) +
                (holding.total_usd_value?.value || 0);
              found.total_usd_value.display_value =
                "$" + abbreviateNumber(found.total_usd_value.value);
            } else {
              found.total_usd_value = holding.total_usd_value;
            }
            if (found.token_balance) {
              found.token_balance.value =
                (found.token_balance?.value || 0) +
                (holding.token_balance?.value || 0);
              found.token_balance.display_value = abbreviateNumber(
                found.token_balance.value
              );
            } else {
              found.token_balance = holding.token_balance;
            }
            found.address_ids.push(address);
          } else {
            let modifiedHolding = structuredClone(holding);
            modifiedHolding.address_ids = [address];
            newHoldingsData.push(modifiedHolding);
          }
        }
      }
      holdingsData = [...newHoldingsData];
    }

    const chainFilteredHoldings = holdingsData?.filter((holding) =>
      selectedActiveChains?.length > 0
        ? selectedActiveChains.includes(holding.chain_id)
        : true
    );
    const chainAndTokenFilteredHoldings = chainFilteredHoldings.filter(
      (holding) =>
        selectedActiveTokens?.length > 0 || selectedActiveProtocols?.length > 0
          ? isTokenIncludedForSelectedTokens({
              selectedActiveTokens,
              selectedActiveProtocols,
              holding,
            })
          : true
    );
    const filteredUsdValue = chainAndTokenFilteredHoldings.reduce(
      (acc, holding) => acc + (holding?.total_usd_value?.value || 0),
      0
    );

    const percentHoldings = chainAndTokenFilteredHoldings.map((holding) => {
      const relativePercentage = {
        value: (holding.total_usd_value?.value * 100) / filteredUsdValue,
        display_value:
          ((holding.total_usd_value?.value * 100) / filteredUsdValue).toFixed(
            2
          ) + "%",
      };
      return {
        ...holding,
        relative_percentage_across_chains: relativePercentage,
      };
    });

    const abbreviatedFilteredUsdValue = abbreviateNumber(filteredUsdValue);

    return {
      ...data,
      holdings: sortBy(
        percentHoldings,
        (holding) => -holding.total_usd_value?.value
      ),
      filteredUsdValue: `$${abbreviatedFilteredUsdValue}`,
    };
  }, [
    selectedActiveChains,
    selectedActiveTokens,
    data,
    selectedActiveProtocols,
    selectedWalletAddresses,
  ]);

  return filteredData;
};

export default useTokenHoldingsFilter;
