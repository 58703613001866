import { ReactComponent as CrossIcon } from "assets/svg/profile/cross.svg";
import classes from "./TransactionsRightPanel.module.css";
import { RenderActivity } from "components/GroupFeedItem/GroupFeedItem.components";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import { getChainMetaData } from "utils/misc";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";

import {
  PaddingComponent,
  CustomColumn,
  Divider,
  InfoBox,
  CustomRow,
} from "components/UI/Components/Components";
import { useEffect, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { fetchTxnTraces } from "api/feed.api";

const TransctionRightPanelHeader = ({
  groupedActivity,
  activity,
  index,
  activeTab,
  setActiveTab,
  onClose,
  isSimulated,
}) => {
  const queryClient = useQueryClient();
  const txnHash = useMemo(
    () => activity.transactions[0]?.tx_id,
    [activity.transactions]
  );
  useEffect(() => {
    fetchTxnTraces({
      queryClient,
      activityData: groupedActivity,
      txnHash,
    });
  }, [groupedActivity, queryClient, txnHash]);

  const logsCount = activity.transactions[0]?.data?.logs?.length;

  const tabsList = useMemo(() => {
    return [
      "Details",
      "Internal Calls",
      `Logs ${logsCount ? `(${logsCount})` : ""}`,
    ];
  }, [logsCount]);

  return (
    <>
      {isSimulated && (
        <div className={classes.simulatedRibbon}>SIMULATED TRANSACTION</div>
      )}
      <div className={classes.header}>
        <div onClick={onClose}>
          <CrossIcon className={classes.crossIcon} />
        </div>

        <span className={classes.title}>Transaction Details</span>
        <TransactionPreview
          activity={activity}
          groupedActivity={groupedActivity}
          index={index}
          isSimulated={isSimulated}
        />
        <PaddingComponent height="40px"></PaddingComponent>
        <CustomLinkTabBar
          zIndex={TABBAR_ZINDEX.HIGH}
          isEnabled={true}
          isLinkTabBar={false}
          isBorderBottom={false}
          tabsList={tabsList}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </>
  );
};

export const TransactionPreview = ({
  activity,
  groupedActivity,
  index,
  isSimulated,
}) => {
  const chainMetaData = getChainMetaData(activity.chain_id);
  const routeToRedirect = isSimulated
    ? `/activity_details/${activity.id}`
    : `/group_activity_details/${groupedActivity.id}/${activity.id}`;
  return (
    <InfoBox padding="10px 16px">
      <CustomColumn overflowY="scroll" overflowX="scroll">
        <RenderActivity
          padding={"0px"}
          marginBottom={"12px"}
          key={`activity-details-transaction-${activity.id}`}
          activity={activity}
          profiles={groupedActivity.profiles}
          methodIds={groupedActivity.method_ids}
          index={index}
          isBorder={false}
          groupedActivity={{
            ...groupedActivity,
            activities: [activity],
          }}
          isActivityDetails
        />
        <Divider />
        <PaddingComponent paddingBottom="10px" />
        <CustomRow alignItems="center">
          <Link to={routeToRedirect} target="_blank">
            <CustomRow alignItems="center">
              <OpenInNewIcon
                fontSize="12px"
                className={classes.open_in_new_tab}
              />
              <span className={classes.openInNewTab}>Open in New Tab</span>
            </CustomRow>
          </Link>
          {!isSimulated &&
            (!!activity?.transactions[0]?.explorer_url ||
              !!activity?.transactions[0]?.tenderly_url) && (
              <>
                <PaddingComponent paddingRight="24px"></PaddingComponent>
                <span className={classes.externalLinks}>External Links: </span>
                <PaddingComponent paddingRight="8px"></PaddingComponent>
                <CustomRow>
                  {!!activity?.transactions[0]?.explorer_url && (
                    <a
                      href={activity?.transactions[0]?.explorer_url}
                      target="_blank"
                      rel="noreferrer">
                      <span className={classes.websiteLink}>
                        {chainMetaData.website}
                      </span>
                    </a>
                  )}
                  <PaddingComponent width="4px"></PaddingComponent>
                  {activity?.transactions[0]?.tenderly_url && (
                    <a
                      href={activity?.transactions[0]?.tenderly_url}
                      target="_blank"
                      rel="noreferrer">
                      <span className={classes.websiteLink}>Tenderly</span>
                    </a>
                  )}
                </CustomRow>
              </>
            )}
        </CustomRow>
      </CustomColumn>
    </InfoBox>
  );
};
export default TransctionRightPanelHeader;
