import { useContext } from "react";
import { GlobalContext } from "contextStates/Global";
import {
  WALLET_AUTORESPONSE_CUTOFF_MS,
  addWalletChain,
} from "contextStates/auth_utils";
import buttonClass from "../UI/Buttons/buttons.module.css";

export const AddToWalletButton = ({ payload }) => {
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);

  if (payload?.type === "Keplr") {
    return <KeplrAddToWalletButton data={payload.data} />;
  }

  return (
    <div
      onClick={() => {
        addWalletChain(payload)
          .then((value) => {
            handleSuccessSnackbar?.(value);
          })
          .catch((err) => {
            handleErrorSnackbar?.(
              null,
              `Failed to add chain to wallet with error: ${err?.message}`
            );
          });
      }}>
      <button className={buttonClass.primaryButton}>Add to Metamask</button>
    </div>
  );
};

const KeplrAddToWalletButton = ({ data }) => {
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);

  return (
    <div
      onClick={() =>
        addToKeplr({ data, handleErrorSnackbar, handleSuccessSnackbar })
      }>
      <button className={buttonClass.primaryButton}>Add to Keplr</button>
    </div>
  );
};

const addToKeplr = async ({
  data,
  handleErrorSnackbar,
  handleSuccessSnackbar,
}) => {
  if (!window.keplr) {
    handleErrorSnackbar?.(
      null,
      `Keplr extension is not installed. Please install it from https://wallet.keplr.app`
    );
    return;
  }
  const startTime = Date.now();
  try {
    await window.keplr.experimentalSuggestChain(data);
  } catch (err) {
    handleErrorSnackbar?.(
      null,
      `Failed to add chain to wallet with error: ${err?.message}`
    );
    return;
  }
  const runTimeMS = Date.now() - startTime;
  if (runTimeMS < WALLET_AUTORESPONSE_CUTOFF_MS) {
    handleSuccessSnackbar?.("Looks like the chain already exists in Keplr");
  }
};
