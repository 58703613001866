import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
  CustomText,
  Divider,
  FlexBox,
  PaddingComponent,
} from "components/UI/Components/Components";
import ConditionalLink from "shared/ConditionalLink";

export const BlocksShimmer = () => {
  return (
    <CustomColumn
      margin="16px 0"
      background="var(--base)"
      borderRadius="10px"
      border="1px solid var(--border-dark)">
      <CustomRow
        alignItems="center"
        justifyContent="space-between"
        padding="16px 20px">
        <CustomText
          text="Recent Blocks"
          color="var(--text-1)"
          fontSize="15px"
          fontWeight="600"
        />
        <ConditionalLink to={`blocks`}>
          <CustomText text="View All" fontWeight="600" />
        </ConditionalLink>
      </CustomRow>
      <PaddingComponent padding="0px 20px">
        <Divider />
      </PaddingComponent>
      <PaddingComponent height="20px" />
      <CustomRow
        overflowX="auto"
        gap="10px"
        padding="0 0 16px 0"
        margin="0px 0px 20px 20px">
        <BlockCardShimmer />
        <BlockCardShimmer />
        <BlockCardShimmer />
        <BlockCardShimmer />
      </CustomRow>
    </CustomColumn>
  );
};

export const BlockCardShimmer = () => {
  return (
    <CustomColumn
      flexShrink="0"
      borderRadius="10px"
      padding="10px 12px"
      width="180px"
      height="165px"
      border="1px solid var(--border-dark)"
      gap="12px"
      justifyContent="space-between">
      <CustomRow alignItems="center" gap="8px">
        <CustomSkeleton variant="circular" width="20px" height="20px" />
        <CustomSkeleton variant="text" width="60%" />
      </CustomRow>
      <CustomColumn gap="4px">
        <CustomRow justifyContent={"space-between"} alignItems="center">
          <FlexBox flex="4">
            <CustomSkeleton variant="text" />
          </FlexBox>
          <PaddingComponent width="12px" />
          <FlexBox flex="4">
            <CustomSkeleton variant="text" />
          </FlexBox>
        </CustomRow>
        <CustomRow justifyContent={"space-between"} alignItems="center">
          <FlexBox flex="4">
            <CustomSkeleton variant="text" />
          </FlexBox>
          <PaddingComponent width="12px" />
          <FlexBox flex="4">
            <CustomSkeleton variant="text" />
          </FlexBox>
        </CustomRow>
        <CustomRow justifyContent={"space-between"} alignItems="center">
          <FlexBox flex="4">
            <CustomSkeleton variant="text" />
          </FlexBox>
          <PaddingComponent width="12px" />
          <FlexBox flex="4">
            <CustomSkeleton variant="text" />
          </FlexBox>
        </CustomRow>
      </CustomColumn>
      <CustomRow alignItems="center" justifyContent="space-between">
        <CustomRow width="100%" alignItems="center" gap="4px">
          <CustomSkeleton variant="circular" width="20px" height="20px" />
          <CustomSkeleton variant="text" width="40%" />
        </CustomRow>
        <CustomSkeleton width="50%" variant="text" />
      </CustomRow>
    </CustomColumn>
  );
};
