import DescriptionBox from "components/GroupFeedItem/components/DescriptionBox";
import {
  BulletPoint,
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  InfoBoxContainer,
  PaddingComponent,
  RoundedChip,
} from "components/UI/Components/Components";
import React, { useState } from "react";
import { removePercentSign } from "utils/misc";
import { PercentageBar } from "./PercentageBar";
import { ArrowSquareOut, CaretDown, CaretUp } from "@phosphor-icons/react";
import ConditionalLink from "shared/ConditionalLink";

const ProposalPreview = ({
  proposalContent,
  topChoices,
  state,
  totalVotes,
  title,
  endDate,
  quorum,
  url,
}) => {
  const [showOtherChoices, setShowOtherChoices] = useState(false);
  const sortedTopChoices = topChoices?.sort((a, b) => {
    let aPercent = Number(a.percentage.substring(0, a.percentage.length - 1));
    let bPercent = Number(b.percentage.substring(0, b.percentage.length - 1));
    return bPercent - aPercent;
  });
  // this code is to find the remaining percentage
  let othersPercent = 0;
  if (sortedTopChoices.length > 5) {
    for (let i = 0; i < 5; i++) {
      const element = sortedTopChoices[i];
      othersPercent += Number(removePercentSign(element.percentage));
    }
    othersPercent = 100 - othersPercent;
  }

  return (
    <>
      <InfoBoxContainer padding="16px 0">
        <CustomRow
          padding="0px 20px"
          justifyContent="space-between"
          alignItems="center">
          <div>
            <CustomText
              text={title}
              color="var(--text-1)"
              fontSize="15px"
              fontWeight="700"
            />
            <div>
              <CustomText text={endDate} />
            </div>
          </div>

          <RoundedChip
            bgColor={
              (state = "closed" ? "var(--text-3)" : "var(--primary-color)")
            }
            noBorder
            borderRadius="30px">
            <CustomText
              text={state}
              fontWeight="600"
              fontSize="12px"
              color="white"
              textTransform="uppercase"
            />
          </RoundedChip>
        </CustomRow>
        <PaddingComponent height="12px" />
        <PercentageBar
          percentageArray={
            sortedTopChoices.length > 5
              ? sortedTopChoices.slice(0, 5)
              : sortedTopChoices
          }
          othersPercent={othersPercent}
          padding="0px 20px"
        />

        <CustomRow
          padding="12px 20px"
          justifyContent="space-between"
          alignItems="center">
          <ConditionalLink sameTab={false} to={{ pathname: url }}>
            <CustomRow alignItems="center" gap="4px">
              <ArrowSquareOut color="var(--text-2)" size={14} />
              <CustomText color="var(--text-2)" text={"Snapshot"} />
            </CustomRow>
          </ConditionalLink>
          <CustomRow alignItems="center" gap="4px">
            <CustomText
              color="var(--text-3)"
              text={`Quorum:${quorum}/${totalVotes}`}
            />
          </CustomRow>
        </CustomRow>

        <Divider />

        {sortedTopChoices
          .slice(0, showOtherChoices ? sortedTopChoices.length : 5)
          .map((item, index) => {
            return (
              <>
                <CustomRow gap="60px" alignItems="center" padding="8px 20px">
                  <CustomRow gap="10px" flexBasis="30%" alignItems="center">
                    <BulletPoint
                      width="10px"
                      height="10px"
                      bgColor={
                        index > 4 ? "var(--text-3)" : `var(--bar-${index})`
                      }
                    />

                    <CustomText
                      color="var(--text-1)"
                      fontSize="15px"
                      fontWeight="600"
                      text={item.label}
                    />
                  </CustomRow>

                  <CustomText
                    color="var(--text-1)"
                    fontSize="15px"
                    text={`${item.voting_power} (${item.percentage})`}
                  />
                </CustomRow>
                <Divider />
              </>
            );
          })}
        {sortedTopChoices.length > 5 && (
          <>
            {" "}
            <CustomRow gap="60px" alignItems="center" padding="8px 20px">
              {!showOtherChoices && (
                <>
                  {" "}
                  <CustomRow gap="10px" alignItems="center">
                    <BulletPoint
                      width="10px"
                      height="10px"
                      bgColor="var(--text-3)"
                    />

                    <CustomText
                      color="var(--text-1)"
                      fontSize="15px"
                      fontWeight="600"
                      text="Others"
                    />
                  </CustomRow>
                  <CustomText
                    color="var(--text-1)"
                    fontSize="15px"
                    text={`${othersPercent.toFixed(2)}%`}
                  />
                </>
              )}

              {sortedTopChoices.length > 5 && (
                <CustomRow
                  onClick={() => setShowOtherChoices(!showOtherChoices)}
                  gap="10px"
                  alignItems="center"
                  cursor="pointer">
                  {showOtherChoices ? (
                    <CaretUp size={20} weight="bold" color="var(--text-2)" />
                  ) : (
                    <CaretDown size={20} weight="bold" color="var(--text-2)" />
                  )}
                  <CustomText
                    text={showOtherChoices ? "See Less" : "See More"}
                    color="var(--text-2)"
                    fontWeight="600"
                  />
                </CustomRow>
              )}
            </CustomRow>
            <Divider />
          </>
        )}

        <CustomColumn background="var(--background-2)" padding="16px 20px">
          <CustomRow alignItems="center" gap="12px">
            <CustomText text="DETAILS" color="var(--text-2)" />
            <Divider />
          </CustomRow>
          {proposalContent && (
            <DescriptionBox
              topChoices={topChoices}
              state={state}
              longText={proposalContent}
            />
          )}
        </CustomColumn>
      </InfoBoxContainer>
    </>
  );
};

export default ProposalPreview;
