import React from "react";
import styles from "./searchResultItem.module.css";
import matchedIcon from "assets/svg/header/matched_icon.svg";
import { sanitize } from "dompurify";

const ResultItemHints = ({ searchHints, marginLeft = "50px" }) => {
  if (!searchHints) {
    return null;
  }
  return (
    <div>
      {Object.entries(searchHints)?.map(([matchedKey, matchedValue], index) => (
        <div
          className={styles.matchedWrapper}
          style={{ marginLeft }}
          key={`search-hint-${matchedKey}-${index}`}>
          <img
            className={styles.matchedIcon}
            src={matchedIcon}
            alt="Matched icon"
          />
          <div className={styles.matchedDetails}>
            Matched from {matchedKey}:
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(matchedValue),
              }}></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResultItemHints;
