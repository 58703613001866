import {
  abbreviateNumber,
  formatUnixTimestamp,
  sleepFunction,
} from "utils/misc";
import { CHARTS_BACKEND_URLS, CODEX_BARS_QUERY } from "./TradingView.constants";
import { CODEX_NETWORKS, getResolutionFromString } from "utils/constants";
import axios from "axios";
import { getGeckoTerminalGraphData, getPriceGraphData } from "api/price.api";

export const fetchBirdeyeBars = async ({
  address,
  type,
  from,
  to,
  chain,
  isChartInUSD,
  mcapMultiplier,
}) => {
  try {
    await sleepFunction(200);
    const response = await fetch(
      `${CHARTS_BACKEND_URLS.BIRDEYE}?address=${address}&type=${type}&time_from=${from}&time_to=${to}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "x-chain": chain.toLowerCase(),
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    if (!data.success) {
      throw new Error("Failed to retrieve data");
    }
    const multiplier = isChartInUSD ? 1 : mcapMultiplier;
    return data.data.items.map((item) => ({
      time: item.unixTime * 1000,
      open: item.o * multiplier,
      high: item.h * multiplier,
      low: item.l * multiplier,
      close: item.c * multiplier,
      volume: item.v,
    }));
  } catch (error) {
    console.error("Error fetching historical data:", error);
    throw error;
  }
};

export const fetchCodexBars = async ({
  resolution,
  from,
  to,
  chain,
  countBack,
  poolAddress,
  tokenType,
  isChartInUSD = true,
  mcapMultiplier = 1,
  isFirstRequest = false,
}) => {
  try {
    if (!chain || !poolAddress) throw new Error("Invalid parameters");
    const chainId = CODEX_NETWORKS.find(
      (n) =>
        n.name === chain || n.altName?.toLowerCase() === chain?.toLowerCase()
    )?.id;
    const quoteToken = tokenType ? { quoteToken: tokenType } : {};
    const res = await axios.post(
      "https://square-dew-e2a0.subscriptions-95d.workers.dev/",
      {
        query: CODEX_BARS_QUERY,
        variables: {
          countback: countBack,
          resolution,
          from,
          to,
          ...quoteToken,
          statsType: "FILTERED",
          removeEmptyBars: false,
          removeLeadingNullValues: true,
          symbol: `${poolAddress}:${chainId}`,
        },
      }
    );
    const bars = res.data.data.getBars ?? {};
    const { o, h, l, c, t, volume, s } = bars;
    if (s === "no_data" && isFirstRequest)
      throw new Error("No data from CODeX");
    if (!t || !o || !h || !l || !c) {
      throw new Error("Invalid data");
    }
    const finalBars = t
      .map((time, index) => {
        const multiplier = isChartInUSD ? 1 : mcapMultiplier;
        const now = Date.now();
        if (time * 1000 > now) return null;
        return {
          time: time * 1000,
          open: o[index] * multiplier,
          high: h[index] * multiplier,
          low: l[index] * multiplier,
          close: c[index] * multiplier,
          volume: volume[index] / c[index],
        };
      })
      .filter((b) => b);
    return finalBars;
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const fetchBackendBars = async ({
  terminalKeys,
  onHistoryCallback,
  firstDataRequest,
  setEmpty,
  slug,
  chain,
  address,
  to,
  from,
  resolution,
  countBack,
  isChartInUSD = false,
  mcapMultiplier = 1,
}) => {
  const isTerminalChart = terminalKeys.every((key) => key);
  const returnWithNoData = () => {
    onHistoryCallback([], {
      noData: true,
    });
    if (firstDataRequest && setEmpty) {
      setEmpty(true);
    }
  };
  if (!isTerminalChart && !slug && !chain && !address) {
    return returnWithNoData;
  }
  const prices = !isTerminalChart
    ? await getPriceGraphData({
        chain,
        address,
        slug,
        to,
        from,
        resolution: getResolutionFromString(resolution),
      })
    : await getGeckoTerminalGraphData({
        terminalKeys,
        limit: countBack,
        resolution,
        before: to,
      });
  if (!prices || prices.length === 0) {
    return returnWithNoData();
  }
  setEmpty(false);
  let bars = [];
  const multiplier = isChartInUSD ? 1 : mcapMultiplier;
  prices.forEach((bar) => {
    let [time, open, high, low, close] = bar;
    const greaterThanFrom = time >= from;
    const lessThanTo = time < to;
    if (greaterThanFrom && lessThanTo) {
      bars = [
        ...bars,
        {
          time: time * 1000,
          open: open * multiplier,
          high: high * multiplier,
          low: low * multiplier,
          close: close * multiplier,
        },
      ];
    }
  });
  return bars;
};

export const constructMarkers = ({ markers, startDate, resolution }) => {
  const firstTime = startDate * 1000;
  const seconds = getResolutionFromString(resolution);
  const milli = seconds * 1000;
  const finalTimestamps = {};
  markers.forEach((m) => {
    const multiplier = Math.floor((m.timestamp * 1000 - firstTime) / milli);
    if (multiplier >= 0) {
      try {
        const time = (firstTime + milli * multiplier) / 1000;
        const key = `${time}_${m.action}`;
        if (!finalTimestamps[key]) {
          const val = m.value ? m.value.value : m.amount * m.price;
          const displayVal = isNaN(val) ? "-" : abbreviateNumber(val);
          const getLabel = () => {
            if (m.label) {
              return m.label;
            }
            return m.action === "buy" ? "B" : "S";
          };
          finalTimestamps[key] = {
            count: 1,
            amount: m.amount,
            value: m.value?.value ?? null,
            id: `${key}`,
            time,
            color: m.color,
            timestamp: m.timestamp,
            text:
              m.text === false
                ? false
                : [
                    `${m.type === "buy" || m.type === "received" ? (m.type === "buy" ? "Bought" : "Received") : m.type === "sent" ? "Sent" : "Sold"} $${displayVal} on ${formatUnixTimestamp(m.timestamp, true)}`,
                  ],
            label: getLabel(),
            minSize: 25,
            labelFontColor: "white",
            labelFontSize: 12,
            price: m.price,
            borderWidth: 0,
            hoveredBorderWidth: 0,
          };
        } else {
          finalTimestamps[key].count++;
          finalTimestamps[key].amount += m.amount;
          finalTimestamps[key].value += m.value?.value ?? 0;
          const { count, price, value } = finalTimestamps[key];
          const amount = finalTimestamps[key].amount;
          finalTimestamps[key].label =
            count > 1 && count < 10 ? `${count}` : "9+";
          const val = value ? value : amount * price;
          const displayVal = isNaN(val) ? "-" : abbreviateNumber(val);
          finalTimestamps[key].text =
            finalTimestamps[key].text === false
              ? false
              : [
                  `${m.type === "buy" || m.type === "received" ? (m.type === "buy" ? "Bought" : "Received") : m.type === "sent" ? "Sent" : "Sold"} $${displayVal} on ${formatUnixTimestamp(m.timestamp, true)}`,
                ];
        }
      } catch (err) {
        console.error({ err }, "ERROR");
      }
    }
  });

  const finalMarkers = Object.values(finalTimestamps);
  return finalMarkers;
};
