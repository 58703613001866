import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import classes from "./NFTHoldings.module.css";
import CollectionHeader from "./CollectionHeader";
import CollectionNfts from "./CollectionNfts";
import GhostNftHoldings from "./GhostNftHoldings";
import { Divider, PaddingComponent } from "components/UI/Components/Components";
import FlattenedNFTCollections from "./FlattenedNFTCollections";
import useProfileNftHoldings from "./useProfileNftHoldings";
import Refetch from "components/UI/Refetch/Refetch";
import { ProfileContext } from "contextStates/ProfileContext";
import { GlobalContext } from "contextStates/Global";
import Header from "./Header";
import { abbreviateNumber } from "utils/misc";
import { throttle } from "lodash";
import { OVERVIEW_CARDS } from "../../../../../zxComponents/feed/ZxFeed.utils";
import ZxHorizontalScrollWrapper from "../../../../../zxComponents/common/ZxHorizontalScrollWrapper";
import { HorizontalScrollButtons } from "../../../../Feed/HorizontalScrollButtons";
import { NFTItem } from "./NftItem";
import useTopNFTHoldings from "./useTopNFTHoldings";
import { getUniqueId } from "utils/event_tracking";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import { EMPTY_STATES } from "zxStyles/constants";
import { RightBarCardContainer } from "components/Pages/AppModule/RightSideBar/components/ProfileRightCards";

const NFT_COLLECTIONS_PER_PAGE = 16;

const NFTHoldings = ({
  identifier,
  selectedActiveChains,
  selectedGroupIds,
  rightPane = false,
}) => {
  const { windowWidth } = useContext(GlobalContext);
  const { setHoldingsTotalUsdValue } = useContext(ProfileContext);
  const { data, refetch, isLoading, isError } = useProfileNftHoldings({
    identifier,
    selectedActiveChains,
    selectedGroupIds,
  });
  const [shownNftCollectionsUpto, setShownNftCollectionsUpto] = useState(
    NFT_COLLECTIONS_PER_PAGE
  );
  const nftCollections = useMemo(
    () =>
      data?.nft_collections
        ?.filter((c) =>
          selectedActiveChains?.length
            ? selectedActiveChains.includes(c.chain_id)
            : true
        )
        .slice(0, shownNftCollectionsUpto),
    [data, shownNftCollectionsUpto, selectedActiveChains]
  );
  const chainProfiles = data?.chain_profiles ?? [];
  const totalUsdValue = useMemo(() => {
    const value =
      data?.nft_collections
        ?.filter((c) =>
          selectedActiveChains?.length
            ? selectedActiveChains.includes(c.chain_id)
            : true
        )
        .reduce(
          (collectionAcc, c) =>
            collectionAcc + (c?.collection_details?.total_usd_value || 0),
          0
        ) || 0;

    const display_value = "$" + abbreviateNumber(value);
    setHoldingsTotalUsdValue?.({ nft: { value, display_value } });
    return display_value;
  }, [data, setHoldingsTotalUsdValue, selectedActiveChains]);

  useEffect(() => {
    setShownNftCollectionsUpto(NFT_COLLECTIONS_PER_PAGE);
    refetch();
  }, [selectedActiveChains, selectedGroupIds, refetch]);

  const triggerNextPage = useCallback(() => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const endPosition = document.body.scrollHeight;
    if (endPosition - scrollPosition <= 300) {
      setShownNftCollectionsUpto((prev) => {
        if (
          prev <
          data?.nft_collections?.filter((c) =>
            selectedActiveChains?.length
              ? selectedActiveChains.includes(c.chain_id)
              : true
          ).length
        ) {
          return prev + NFT_COLLECTIONS_PER_PAGE;
        }
        return prev;
      });
    }
  }, [data, setShownNftCollectionsUpto, selectedActiveChains]);

  useEffect(() => {
    const throttledTriggerNextPage = throttle(triggerNextPage, 50);
    window.addEventListener("scroll", throttledTriggerNextPage);

    return () => {
      window.removeEventListener("scroll", throttledTriggerNextPage);
    };
  }, [triggerNextPage]);

  return (
    <div className={classes.nftHoldings}>
      <Header
        totalUsdValue={totalUsdValue}
        lastUpdatedAt={!rightPane ? nftCollections?.last_updated_at : ""}
        isFullyComputed={true}
        refetch={() => {}}
        isFetching={false}
      />
      {isLoading ? (
        <PaddingComponent padding="8px 0 0">
          <GhostNftHoldings rightPane={rightPane || windowWidth <= 768} />
        </PaddingComponent>
      ) : nftCollections?.length === 0 ? (
        <ZxEmptyState state={EMPTY_STATES.NFT} text="No NFTs" size={200} />
      ) : isError ? (
        <Refetch
          onClick={() => {
            refetch?.();
          }}
        />
      ) : !rightPane ? (
        <FlattenedNFTCollections
          nftCollections={nftCollections}
          chainProfiles={chainProfiles}
          isLoading={isLoading}
        />
      ) : (
        <NFTList
          nftCollections={nftCollections}
          chainProfiles={chainProfiles}
          rightPane={rightPane}
        />
      )}
    </div>
  );
};

export const NFTList = React.memo(
  ({
    nftCollections,
    chainProfiles,
    rightPane,
    collectionItemMargin = "16px 20px 16px 20px ",
    collectionItemHasBorderBottom = false,
  }) => {
    return (
      <>
        {nftCollections.map((nftCollection, index) => (
          <div
            className={classes.nftCollectionItem}
            style={{ margin: collectionItemMargin }}
            key={getUniqueId()}>
            <CollectionHeader
              collection={nftCollection.collection_details}
              chainProfiles={chainProfiles}
              totalNfts={nftCollection.tokens?.length}
              rightPane={rightPane}
            />
            <CollectionNfts
              nftItems={nftCollection.tokens}
              chainId={nftCollection.chain_id}
              rightPane={rightPane}
              collectionDetails={nftCollection.collection_details}
              totalCollectionTokens={nftCollection.tokens?.length}
              hasGalleryModal={false}
              filterNfts={false}
            />
            {collectionItemHasBorderBottom && (
              <PaddingComponent padding="16px 0 0">
                <Divider />
              </PaddingComponent>
            )}
          </div>
        ))}
      </>
    );
  }
);
export const TopNFTHoldings = ({ identifier }) => {
  const elRef = React.useRef();
  const { data, isLoading, isError } = useTopNFTHoldings({ identifier });
  const isReady = !isLoading && data;
  const nftCollections = data ?? [];
  if (nftCollections.length === 0 || isError) {
    return null;
  }
  return (
    <RightBarCardContainer
      containerPadding="12px"
      title="Top NFTs"
      footer="View all NFTs"
      footerLink={`/${identifier}/portfolio`}>
      <ZxHorizontalScrollWrapper
        isLoading={isLoading}
        scrollRef={elRef}
        containerPadding={"8px 0 12px 0 "}
        itemCount={nftCollections.length ?? 0}
        meta={OVERVIEW_CARDS.NFT}>
        <div
          ref={elRef}
          className={classes.scrollContainer}
          style={{
            display: "flex",
            overflow: "auto",
            width: "276px",
            padding: "0",
            gap: "12px",
          }}>
          {isReady &&
            nftCollections.map((nftCollection, idx) => {
              const nftItem = nftCollection.tokens[0];
              const chainId = nftCollection.chain_id;
              const collectionDetails = nftCollection.collection_details;
              return (
                <NFTItem
                  key={`${nftItem.name}-${idx}-item`}
                  nftItem={nftItem}
                  chainId={chainId}
                  collectionDetails={collectionDetails}
                  totalCollectionLength={1}
                  hasGalleryModal={false}
                  style={{
                    borderRadius: 8,
                    width: "150px",
                  }}
                />
              );
            })}
        </div>
        {isReady && nftCollections?.length > 1 && (
          <HorizontalScrollButtons
            wrapperEl={elRef}
            postWidth={OVERVIEW_CARDS.NFT.width}
            size={20}
          />
        )}
      </ZxHorizontalScrollWrapper>
    </RightBarCardContainer>
  );
};

export default withErrorBoundary(NFTHoldings);
