import React, { useContext, useState } from "react";
import styles from "./TokenPageHeader.module.css";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  IconWrapper,
  PaddingComponent,
  PriceChange,
  TypeTag,
} from "components/UI/Components/Components";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import WatchlistTokenButton from "components/V2/Watchlist/WatchlistTokenButton";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";
import BuySellButton from "components/V2/BuySellButton/BuySellButton";
import { EditContractPopup, TokenPageHeaderLoader } from "../token_page_utils";
import { PencilSimple } from "@phosphor-icons/react";
import Copyable from "components/ValueType/Copyable/Copyable";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "utils/query_utils";
import { getTokenPageUserData } from "api/token_page.api";
import TokenInteractionsBanner from "../TokenInteractionsBanner/TokenInteractionsBanner";
import { getTrimmedString } from "utils/misc";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import withNullableError from "zxComponents/common/withNullableError/index";
import { AuthContext } from "contextStates/AuthContext";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import ZxUserTokenGroup from "zxComponents/feed/ZxUserTokenGroup/ZxUserTokenGroup";
const TokenPageHeader = ({
  buyLink,
  chainId,
  slug,
  data,
  isTokenPage,
  isContractPage,
  isNftPage,
  contractAddress,
  refetch,
  onBannerClick,
  metadata,
  contractDetails,
  tokenDetails,
}) => {
  const { data: userData, isLoading } = useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_USER_DETAILS({ chainId, contractAddress }),
    queryFn: () => getTokenPageUserData({ chainId, contractAddress }),
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });
  const [showPopup, setShowPopup] = useState(false);
  const { isUserLoggedIn } = useContext(AuthContext);
  if (metadata.isLoading || !metadata.data) {
    return <TokenPageHeaderLoader />;
  }
  const { logo, name, symbol } = metadata.data;
  const watchlistProps =
    tokenDetails?.data && isTokenPage
      ? {
          isWatchlisted: tokenDetails?.data?.is_watchlisted ?? false,
          chainId,
          tokenAddress: contractAddress,
          tokenSlug: slug,
        }
      : null;
  const followerBannerProps =
    !isLoading && userData
      ? {
          ...userData?.followers_interacting_with_token,
        }
      : null;
  const pnlBannerProps =
    !isLoading && userData && isTokenPage
      ? {
          pnlData: { ...userData?.pnl_of_viewer },
          slug,
          contractAddress,
          chainId,
          symbol,
        }
      : null;
  const externalLinks = contractDetails.data?.external_links ?? [];
  const displayName = name ?? contractDetails?.data?.profile?.display_name;
  const displayImage = logo ?? contractDetails?.data?.profile?.display_picture;
  return (
    <CustomColumn gap="0">
      <PaddingComponent padding="8px 16px">
        <CustomRow justifyContent="space-between">
          <CustomRow gap="12px" alignItems="center">
            <div className={styles.displayPictureContainer}>
              <Image
                src={displayImage}
                alt={displayName}
                imageType={IMAGE_TYPES.TOKEN}
                className={styles.displayPicture}
              />
            </div>
            <CustomRow gap="8px">
              <CustomText
                className={styles.name}
                color="var(--text-1)"
                text={getTrimmedString(displayName, 20)}
                fontSize="17px"
                lineHeight="22px"
                fontWeight="600"
              />

              {isTokenPage && (
                <CustomRow gap="4px" alignItems="flex-end">
                  <CustomText
                    className={styles.symbol}
                    text={`${symbol} `}
                    color="var(--text-2)"
                    fontSize="12px"
                    lineHeight="18px"
                    fontWeight="600"
                  />

                  <CustomText
                    className={styles.chain}
                    color="var(--text-2)"
                    text={`on ${chainId}`}
                    fontSize="12px"
                    lineHeight="18px"
                    fontWeight="400"
                  />
                </CustomRow>
              )}
              {isContractPage && <TypeTag text="Contract" />}
              {isNftPage && <TypeTag text="NFT" />}
            </CustomRow>
            <IconWrapper color="var(--elevation-1)">
              <CustomRow alignItems="center" justifyContent="center">
                <Copyable
                  content={contractAddress}
                  title="Copy address"
                  defaultOpacity="1"
                />
                <PaddingComponent padding="0 2px" />
              </CustomRow>
            </IconWrapper>
            {isTokenPage && watchlistProps && (
              <WatchlistTokenButton {...watchlistProps} />
            )}
          </CustomRow>
          <CustomRow gap="12px" alignItems="center">
            <ExternalLinks links={externalLinks} />
            {buyLink && <BuySellButton link={buyLink} action="buy" />}

            {isUserLoggedIn && (
              <PPLxTooltip title="Edit contract details" arrow placement="top">
                <IconWrapper
                  className={styles.edit_icon}
                  noBorder
                  color="var(--elevation-2)">
                  <PencilSimple
                    onClick={(e) => {
                      setShowPopup(true);
                    }}
                    fontSize="18px"
                    color="var(--text-2)"
                  />
                </IconWrapper>
              </PPLxTooltip>
            )}
          </CustomRow>
        </CustomRow>

        {showPopup && (
          <EditContractPopup
            onClose={() => setShowPopup(false)}
            chainId={chainId}
            contractAddress={contractAddress}
            refetch={refetch}
            links={externalLinks}
          />
        )}
      </PaddingComponent>
      <Divider />
      {followerBannerProps && (
        <TokenInteractionsBanner
          symbol={symbol}
          interactions={followerBannerProps}
          onBannerClick={onBannerClick}
          isTokenPage={isTokenPage}
          contractName={metadata.data?.name ?? null}
        />
      )}

      {pnlBannerProps &&
        Object.keys(userData?.pnl_of_viewer)?.filter(
          (k) => !!userData?.pnl_of_viewer[k]
        )?.length > 0 && (
          <PaddingComponent padding="12px 16px">
            <ZxUserTokenGroup
              selectedToken={userData?.pnl_of_viewer}
              showAttribution={false}
            />
          </PaddingComponent>
        )}
    </CustomColumn>
  );
};

export default withNullableError(TokenPageHeader);

export const TokenPageHeaderMini = ({
  displayName,
  displayImage,
  amount,
  change,
  chainId,
}) => {
  return (
    <CustomColumn gap="0">
      <PaddingComponent padding="0">
        <CustomRow justifyContent="space-between">
          <CustomRow gap="12px" alignItems="center">
            <div className={styles.displayPictureContainer}>
              <IconWithChain
                src={displayImage}
                alt={displayImage}
                chain={chainId}
                iconHeight="48px"
                iconWidth="48px"
                chainHeight="16px"
                chainWidth="16px"
                showImageFullHeightWidth={true}
                imageType={IMAGE_TYPES.TOKEN}
              />
            </div>
            <CustomColumn gap="2px">
              <CustomText
                className={styles.name}
                color="var(--text-1)"
                text={getTrimmedString(displayName, 30)}
                fontSize="17px"
                lineHeight="22px"
                fontWeight="600"
              />
              <CustomRow gap="8px">
                {amount && <CustomText text={amount.display_value} />}
                {change && (
                  <PriceChange
                    value={change.value}
                    displayValue={change.display_value}
                    fontWeight="400"
                  />
                )}
              </CustomRow>
            </CustomColumn>
          </CustomRow>
        </CustomRow>
      </PaddingComponent>
    </CustomColumn>
  );
};
