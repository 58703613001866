import classes from "./NetworkProfileList.module.css";
import { TopProfileGhostList } from "../TopProfileList/TopProfileList";
import { getNetworkProfiles } from "api/community.api";
import withLoader from "components/core/withLoader";
import ProfileItem, { ProfileItemTypes } from "../ProfileItem/ProfileItem";
const NetworkProfileList = ({
  queryData,
  refetchFeedOnFollowOrUnfollow = false,
}) => {
  const profiles =
    queryData?.data?.map((item) => {
      let profile = {};
      if (item.type === "identity") {
        profile = queryData.identity_details[item.identifier];
      } else if (item.type === "address") {
        profile = queryData.address_profiles[item.identifier];
      } else {
        // Complain
        console.error("Unknown profile type", item);
      }
      return {
        profile,
        details: item.details,
      };
    }) ?? [];

  return (
    <div className={classes.network}>
      {profiles.map(({ profile, details }) => {
        return (
          <ProfileItem
            key={`profile ${profile.address || profile.id}`}
            profile={profile}
            profiles={queryData.address_profiles}
            identities={queryData.identity_details}
            nativeTokensInfo={queryData.native_tokens_dict}
            resultType="identity"
            profileItemType={
              details.from_twitter
                ? ProfileItemTypes.twitter
                : details.from_lens
                  ? ProfileItemTypes.lens
                  : null
            }
            details={details}
            address_profiles={queryData.address_profiles}
            identity_details={queryData.identity_details}
            refetchFeedOnFollowOrUnfollow={refetchFeedOnFollowOrUnfollow}
          />
        );
      })}
    </div>
  );
};

export default withLoader(NetworkProfileList, getNetworkProfiles, {
  queryKey: () => ["NetworkProfileList"],
  containerClass: classes.network,
  CustomLoader: TopProfileGhostList,
  staleTime: 1000 * 60 * 30,
});
