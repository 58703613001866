import { useMutation } from "@tanstack/react-query";
import { postLensAuth } from "api/post.api";
import { connectWalletGetProviderAddress } from "contextStates/auth_utils";
import { logSentryEvent } from "utils/sentry";

const LENS_BASE_URL = "https://api-v2.lens.dev/";

export const signWithWalletForLens = async () => {
  try {
    const { web3, fromAddr } = await connectWalletGetProviderAddress({
      skipReconnect: false,
    });
    const profileQuery = `
    query ProfilesManaged($profilesManagedRequest: ProfilesManagedRequest!, $lastLoggedInProfileRequest: LastLoggedInProfileRequest!) {
      profilesManaged(request: $profilesManagedRequest) {
        items {
          ...ListProfileFields
          __typename
        }
        pageInfo {
          next
          __typename
        }
        __typename
      }
      lastLoggedInProfile(request: $lastLoggedInProfileRequest) {
        ...ListProfileFields
        __typename
      }
    }
    
    fragment ListProfileFields on Profile {
      id
      handle {
        ...HandleInfoFields
        __typename
      }
      ownedBy {
        ...NetworkAddressFields
        __typename
      }
      operations {
        ...ProfileOperationsFields
        __typename
      }
      metadata {
        ...ProfileMetadataFields
        __typename
      }
      followModule {
        ...FollowModuleFields
        __typename
      }
      peerToPeerRecommendedByMe
      __typename
    }
    
    fragment HandleInfoFields on HandleInfo {
      fullHandle
      localName
      suggestedFormatted {
        localName
        __typename
      }
      linkedTo {
        nftTokenId
        __typename
      }
      __typename
    }
    
    fragment NetworkAddressFields on NetworkAddress {
      address
      chainId
      __typename
    }
    
    fragment ProfileOperationsFields on ProfileOperations {
      id
      isBlockedByMe {
        value
        __typename
      }
      isFollowedByMe {
        value
        __typename
      }
      isFollowingMe {
        value
        __typename
      }
      __typename
    }
    
    fragment ProfileMetadataFields on ProfileMetadata {
      displayName
      bio
      rawURI
      picture {
        ... on ImageSet {
          ...ImageSetFields
          __typename
        }
        ... on NftImage {
          image {
            ...ImageSetFields
            __typename
          }
          __typename
        }
        __typename
      }
      coverPicture {
        ...ImageSetFields
        __typename
      }
      attributes {
        ...MetadataAttributeFields
        __typename
      }
      __typename
    }
    
    fragment ImageSetFields on ImageSet {
      optimized {
        uri
        __typename
      }
      raw {
        uri
        __typename
      }
      __typename
    }
    
    fragment MetadataAttributeFields on MetadataAttribute {
      type
      key
      value
      __typename
    }
    
    fragment FollowModuleFields on FollowModule {
      ... on FeeFollowModuleSettings {
        type
        amount {
          ...AmountFields
          __typename
        }
        recipient
        __typename
      }
      ... on RevertFollowModuleSettings {
        type
        __typename
      }
      ... on UnknownFollowModuleSettings {
        type
        __typename
      }
      __typename
    }
    
    fragment AmountFields on Amount {
      asset {
        ...Erc20Fields
        __typename
      }
      value
      __typename
    }
    
    fragment Erc20Fields on Asset {
      ... on Erc20 {
        name
        symbol
        decimals
        contract {
          ...NetworkAddressFields
          __typename
        }
        __typename
      }
      __typename
    }
  `;
    const profileResponse = await (
      await fetch(LENS_BASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: profileQuery,
          variables: {
            profilesManagedRequest: {
              for: fromAddr,
            },
            lastLoggedInProfileRequest: {
              for: fromAddr,
            },
          },
        }),
      })
    ).json();
    const profileId =
      profileResponse.data?.lastLoggedInProfile?.id ??
      profileResponse.data?.profilesManaged?.items[0]?.id ??
      null;

    if (!profileId) {
      throw new Error("No profile found for the connected wallet");
    }

    const authChallengeQuery = {
      query:
        "query AuthChallenge($request: ChallengeRequest!) {result: challenge(request: $request) {...AuthChallenge}}fragment AuthChallenge on AuthChallengeResult {__typename id text}",
      variables: {
        request: {
          signedBy: fromAddr,
          for: profileId,
        },
      },
      operationName: "AuthChallenge",
    };
    const authResponse = await (
      await fetch(LENS_BASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(authChallengeQuery),
      })
    ).json();
    const { id, text } = authResponse.data.result;
    const signature = await web3.eth.personal.sign(
      text,
      fromAddr,
      "Lens connect"
    );
    const tokenMutation = {
      query:
        "mutation AuthAuthenticate($request: SignedAuthChallenge!) {\n  result: authenticate(request: $request) {\n    accessToken\n    refreshToken\n  }\n}",
      variables: {
        request: {
          id,
          signature,
        },
      },
      operationName: "AuthAuthenticate",
    };
    const tokenResponse = await (
      await fetch(LENS_BASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tokenMutation),
      })
    ).json();

    const { accessToken, refreshToken } = tokenResponse.data.result;

    return {
      error: null,
      data: {
        accessToken,
        refreshToken,
      },
    };
  } catch (e) {
    logSentryEvent(e, {
      extra: {
        message: "Failed to sign with wallet for Lens",
      },
    });
    return {
      data: null,
      error: true,
    };
  }
};

const usePostLensAuthMutation = ({ onSuccess, onError, onFinish }) => {
  const mutation = useMutation({
    mutationFn: postLensAuth,
    onSuccess,
    onError,
    onSettled: onFinish,
  });

  return mutation;
};

export default usePostLensAuthMutation;
