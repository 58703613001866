/* src/components/Feed/FeedFilters/FeedFilters.js */
import { Checkbox, FormControlLabel } from "@mui/material";
import classes from "./FeedFilters.module.css";
import { DateRangeSelector } from "./Calendar";
import { useContext } from "react";
import { AuthContext } from "contextStates/AuthContext";

const FilterPills = [
  { title: "Transactions", param: "transactions" },
  { title: "Posts", param: "posts" },
  { title: "Replies", param: "replies" },
];

const FeedFilters = ({ onChange, onDateRangeChange, selectedFeedType }) => {
  const { isSuperuser } = useContext(AuthContext);
  return (
    <div className={classes.filterWrapper}>
      <div className={classes.filtersContainer}>
        {FilterPills.map((f) => (
          <FormControlLabel
            key={"filter-" + f.title}
            control={
              <Checkbox
                checked={selectedFeedType.includes(f.param)}
                classes={{
                  root: classes.filterCheckbox,
                }}
                onChange={() => onChange(f.param)}
              />
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
                marginRight: "2px",
                padding: "0px",
              },
              "& .MuiTypography-root": { fontSize: "14px" },
              "& .Mui-checked": { color: "#0047ff !important" },
            }}
            label={f.title}
            className={`${classes.filterLabel} ${
              selectedFeedType.includes(f.param) ? classes.checkedLabel : ""
            }`}
          />
        ))}
      </div>
      {isSuperuser && (
        <DateRangeSelector onDateRangeChange={onDateRangeChange} />
      )}
    </div>
  );
};

export default FeedFilters;
