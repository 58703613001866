import { getSearchResults, getCoingeckoSearchResults } from "api/search.api";
import CustomTextField from "components/FollowEditButton/CustomTextField.js";
import { useEffect, useState, useContext } from "react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { ClickAwayListener } from "@mui/material";
import React from "react";
import classes from "./SearchBarMini.module.css";
import { CustomColumn, CustomSkeleton, SpinnerComponent } from "./Components";
import { CustomText, PaddingComponent, CustomRow } from "./Components";
import { GlobalContext } from "contextStates/Global";

const shallowCompare = (obj1, obj2) => {
  if (!obj1 || !obj2) return false;
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  for (const key in obj1) {
    if (typeof obj1[key] !== "object") {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  return true;
};

const SearchBarMini = ({
  placeholder,
  source,
  searchQueryKey,
  SearchItemComponent,
  refetch,
  watchlistItems,
  useCoingecko = false,
  hideIcon,
  handleSelect = () => {},
  defaultSearchTerm,
  selectedResult = null,
  displaySelectionText = "",
  isSearchInputFocusedProp,
  trackingFunction = null,
  prefix = null,
  disabled = false,
  showLoaderBottom = false,
  customBorderRadius = "4px",
}) => {
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm || "");
  const searchRef = React.useRef(defaultSearchTerm || "");
  const [searchResults, setSearchResults] = useState(null);
  const [prevSelectedResult, setPrevSelectedResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(
    isSearchInputFocusedProp || false
  );
  const { handleErrorSnackbar } = useContext(GlobalContext);
  useEffect(() => {
    let timeOutId;
    const controller = new AbortController();
    if (searchTerm?.trim().length > 0 && searchRef.current !== searchTerm) {
      setSearchResults(null);
      setIsLoading(true);
      searchRef.current = searchTerm;
      if (useCoingecko) {
        timeOutId = setTimeout(() => {
          getCoingeckoSearchResults({
            queryKey: [searchQueryKey, searchTerm],
            signal: controller.signal,
          })
            .then((data) => {
              setSearchResults(data);
              trackingFunction?.(data, searchTerm);
              setIsLoading(false);
            })
            .catch((err) => {
              if (err.message === "Too many requests")
                handleErrorSnackbar(null, err?.message);
              setIsLoading(false);
            });
        }, 400);
      } else {
        timeOutId = setTimeout(() => {
          getSearchResults({
            queryKey: [searchQueryKey, `${searchTerm} source:${source} `],
            signal: controller.signal,
          })
            .then((data) => {
              setSearchResults(data);
              setIsLoading(false);
            })
            .catch((err) => {
              if (err.message === "canceled") return;
              setIsLoading(false);
            });
        }, 200);
      }
    } else {
      setIsLoading(false);
      setSearchResults(null);
    }

    return () => {
      clearTimeout(timeOutId);
      if (!controller.signal.aborted) {
        controller.abort();
      }
    };
  }, [
    searchTerm,
    searchQueryKey,
    source,
    useCoingecko,
    handleErrorSnackbar,
    trackingFunction,
    setSearchResults,
  ]);
  const resetInput = () => {
    setSearchTerm("");
  };
  if (selectedResult) {
    return (
      <div
        className={classes.searchBar}
        onClick={() => {
          setPrevSelectedResult(selectedResult);
          handleSelect({ source, result: null });
          setIsSearchInputFocused(true);
        }}>
        <div
          style={{
            background: "var(--base)",
            width: "100%",
            padding: "6px 19px",
            border: "1px solid var(--border-dark)",
            borderRadius: "4px",
            marginBottom: 2,
          }}>
          <CustomText text={displaySelectionText} />
        </div>
      </div>
    );
  }
  const isEmptyResults =
    searchTerm?.trim().length > 0 && searchResults?.length === 0 && !isLoading;
  return (
    <ClickAwayListener
      onClickAway={() => {
        handleSelect({ source, result: prevSelectedResult });
        setIsSearchInputFocused(false);
      }}>
      <div
        className={classes.searchBar}
        style={{ borderRadius: customBorderRadius }}>
        <CustomTextField
          size="small"
          placeholder={placeholder}
          sx={{
            input: {
              cursor: disabled ? "not-allowed" : "auto",
              borderRadius: customBorderRadius,
            },
            "& .MuiInputBase-root": {
              borderRadius: customBorderRadius,
            },
          }}
          fullWidth
          disabled={disabled}
          value={searchTerm}
          onFocus={() => setIsSearchInputFocused(true)}
          onChange={(e) => {
            if (e.target?.value?.trim()?.length > 0) setIsLoading(true);
            setSearchTerm(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <div className={classes.searchBarLoading}>
                {isLoading && (
                  <SpinnerComponent size={16} color="var(--primary-color)" />
                )}
              </div>
            ),
            startAdornment: (
              <div className={classes.searchBarPlaceholder}>
                {!hideIcon && (
                  <MagnifyingGlass size={16} color="var(--text-3)" />
                )}
                {prefix}
              </div>
            ),
          }}
        />
        {isSearchInputFocused && !isLoading && !isEmptyResults && (
          <div className={classes.searchResultsContainer}>
            {(searchResults ?? []).map((result) => (
              <SearchItemComponent
                key={result.id}
                selected={shallowCompare(
                  result?.data,
                  prevSelectedResult?.data
                )}
                result={result}
                refetch={refetch}
                watchlistItems={watchlistItems}
                setIsSearchInputFocused={setIsSearchInputFocused}
                useCoingecko={useCoingecko}
                source={source}
                setSearchTerm={setSearchTerm}
                handleSelect={handleSelect}
                resetInput={resetInput}
              />
            ))}
          </div>
        )}
        {isEmptyResults && !isLoading && (
          <div className={classes.searchResultsContainer}>
            <div className={classes.searchResultItem}>
              <PaddingComponent height="8px" />
              <CustomRow
                gap="4px"
                height="24px"
                alignItems="center"
                width="100%">
                <CustomText
                  text={"No results"}
                  fontSize="13px"
                  color="var(--text-1)"
                  lineHeight="130%"
                  letterSpacing="-0.22px"
                />
              </CustomRow>

              <PaddingComponent height="8px" />
            </div>
          </div>
        )}
        {showLoaderBottom && isLoading && isSearchInputFocused && (
          <CustomColumn
            gap="0px"
            padding="8px 0"
            style={{
              position: "absolute",
              top: "28px",
              width: "100%",
              backgroundColor: "var(--base)",
              zIndex: "8",
              border: "1px solid var(--border-dark)",
            }}>
            {[1, 2, 3, 4].map((key) => {
              return <CustomSkeleton key={key} height="40px" width="100%" />;
            })}
          </CustomColumn>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default React.memo(SearchBarMini);
