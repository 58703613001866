import { signedApiOtherRequest } from "api/api";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { GlobalContext } from "contextStates/Global";
import styled from "styled-components";
import Loader from "components/UI/Loader/Loader";

const CrossPostTwitterCallback = () => {
  const history = useHistory();
  const [queryParams] = useState(
    new URLSearchParams(history?.location?.search)
  );

  const oauth_token = queryParams.get("oauth_token");
  const oauth_verifier = queryParams.get("oauth_verifier");
  const denied = queryParams.get("denied");

  const { handleErrorSnackbar } = useContext(GlobalContext);

  useEffect(() => {
    if (denied) {
      handleErrorSnackbar(null, "Please authorize twitter to proceed");
      window.opener?.postMessage(
        { status: "error", message: "Please authorize Twitter to proceed" },
        window.location.origin
      );
      window.close();
    }
    if (oauth_token && oauth_verifier) {
      signedApiOtherRequest({
        method: "post",
        path: "twitter/cross_post_callback",
        bodyText: JSON.stringify({
          oauth_token,
          oauth_verifier,
        }),
      })
        .then((data) => {
          window.opener?.postMessage(
            { status: "success" },
            window.location.origin
          );
          window.close();
        })
        .catch((err) => {
          handleErrorSnackbar(err, "Failed to connect Twitter.");
          window.opener?.postMessage(
            { status: "error", message: "Failed to connect Twitter." },
            window.location.origin
          );
          window.close();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [denied, oauth_token, oauth_verifier]);

  return (
    <CenterFullWidthDiv>
      <Loader />
    </CenterFullWidthDiv>
  );
};

export default CrossPostTwitterCallback;

const CenterFullWidthDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
