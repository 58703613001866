import {
  CustomRow,
  CustomText,
  DirectedTokenPrice,
  InfoBoxContainer,
} from "components/UI/Components/Components";
import CustomLinkTabBar, {
  SIZE,
} from "components/UI/Components/CustomLinkTabBar";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "components/UI/Table/Table";
import withLoader from "components/core/withLoader";
import React, { useState } from "react";
import { getTrendingItemLeaderboard } from "./trendingUtils";
import { TableProfile } from "components/V2/Explorer/SelectedEdge";

const TABKEYS = {
  "Top Holders": "top_holders",
  "Top Gainers": "top_gainers",
  "Top Losers": "top_losers",
};

const TABLIST = Object.keys(TABKEYS);

const Leaderboard = ({ queryData: data }) => {
  const [tab, setTab] = useState(0);
  return (
    <div>
      <InfoBoxContainer
        headerPaddingBottom="0px"
        padding="0px 20px 16px"
        hasHeaderBorder={false}
        contentPaddingTop="4px"
        header={
          <CustomLinkTabBar
            activeTab={tab}
            tabsList={TABLIST}
            isLinkTabBar={false}
            setActiveTab={(index) => {
              setTab(index);
            }}
            isBorderBottom={false}
            position="top"
            size={SIZE.LARGE}
          />
        }>
        {tab === 0 && (
          <TopRankingUsers
            type="top_holders"
            data={data?.top_holders}
            profiles={data?.profiles_by_addr}
          />
        )}
        {tab === 1 && (
          <TopRankingUsers
            type="top_gainers"
            data={data?.top_gainers}
            profiles={data?.profiles_by_addr}
          />
        )}
        {tab === 2 && (
          <TopRankingUsers
            type="top_losers"
            data={data?.top_losers}
            profiles={data?.profiles_by_addr}
          />
        )}
      </InfoBoxContainer>
    </div>
  );
};

const TopRankingUsers = ({ type, data, profiles }) => {
  if (!type || !data) {
    return null;
  }

  return (
    <Table>
      <TableHeader noPadding>
        <TableCell alignLeft style={{ flexBasis: "45%" }}>
          RANK / NAME
        </TableCell>
        <TableCell alignCenter>OWNS</TableCell>
        <TableCell alignRight>PNL</TableCell>
        <TableCell alignRight>VALUE</TableCell>
      </TableHeader>
      {data?.map((rankItem, index) => (
        <RankRow
          key={`${type}-rank-${index}`}
          index={index + 1}
          rankItem={rankItem}
          profile={profiles?.[rankItem?.user_address]}
        />
      ))}
    </Table>
  );
};
const RankRow = ({ rankItem, profile, index }) => {
  return (
    <TableRow noPadding alignToFlexStart>
      <TableCell alignLeft style={{ flexBasis: "45%" }}>
        <CustomRow gap="24px">
          <CustomText fontSize="15px" text={index} color="var(--text-1)" />
          <TableProfile
            profile={profile}
            abbreviateLength={20}
            link={`${profile?.link}/activities?tokens=${rankItem?.token_address},${rankItem?.chain_id}`}
          />
        </CustomRow>
      </TableCell>
      <TableCell alignCenter>
        <CustomRow gap="24px">
          <CustomText
            fontSize="15px"
            color="var(--text-1)"
            text={rankItem?.balance?.display_value}
            textAlign="right"
          />
          <CustomText
            fontSize="15px"
            color="var(--text-2)"
            text={rankItem?.per_token_supply?.display_value}
            textAlign="left"
          />
        </CustomRow>
      </TableCell>
      <TableCell alignRight>
        {rankItem?.pnl_percentage?.value !== 0 ? (
          <DirectedTokenPrice
            isChevron
            price={`${rankItem?.pnl_usd_value?.display_value} ${
              rankItem?.pnl_percentage
                ? `(${rankItem?.pnl_percentage?.display_value})`
                : ``
            }`}
            direction={rankItem?.pnl_percentage?.value > 0 ? "bought" : "sold"}
          />
        ) : (
          " - "
        )}
      </TableCell>
      <TableCell alignRight>
        <CustomText
          fontSize="15px"
          color="var(--text-1)"
          text={rankItem?.holding_usd_value?.display_value}
        />
      </TableCell>
    </TableRow>
  );
};

export default withLoader(Leaderboard, getTrendingItemLeaderboard, {
  queryKey: (props) => [
    "trending-item-leaderboard",
    props.chainId,
    props.identifier,
  ],

  queryOptions: (props) => ({
    enabled: !!props?.identifier && !!props?.chainId,
    retry: 3,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  }),
});
