import React, { useContext, useMemo } from "react";
import { MenuItem, Menu, IconButton } from "@mui/material";
import {
  PencilSimple,
  Trash,
  SealCheck,
  DotsThreeVertical,
} from "@phosphor-icons/react";
import { useState, useRef } from "react";
import { CustomRow, CustomText } from "components/UI/Components/Components";
import { ProfileContext } from "contextStates/ProfileContext";
import AddWalletForm from "components/V2/Profile/ProfileDetails/wallets/components/AddWalletForm";
import DeleteWalletPopUp from "components/V2/Profile/ProfileDetails/wallets/components/DeleteWalletPopUp";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { AuthContext } from "contextStates/AuthContext";
import DeleteWalletPopUpForm from "components/V2/Profile/ProfileDetails/wallets/components/DeleteWalletPopUpForm";
import DynamicWalletButton from "components/DynamicWalletButton/DynamicWalletButton";
import { useHistory, useParams } from "react-router-dom";

const WalletMenu = ({ wallet, bundleData, refetch }) => {
  const params = useParams();
  const history = useHistory();
  const anchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const [isEditPopupShown, setIsEditPopupShown] = useState(false);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const { profileData, isLoggedInUserProfile } = useContext(ProfileContext);
  const verifiedWallets = bundleData?.bundle?.wallets?.filter(
    (w) => w.is_verified
  );
  const canDelete = !!(verifiedWallets?.length > 1);
  const { verifyWallet } = useContext(AuthContext);
  const menuColor = "var(--text-1)";
  const items = [
    {
      label: <CustomText text="Delete" color={menuColor} />,
      icon: <Trash color={menuColor} />,
      disabled: !canDelete && wallet?.is_verified,
      onClick: () => {
        setIsDeletePopupShown(true);
        handleMenuClose();
      },
    },
  ];

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const walletItem = useMemo(() => {
    return (
      <CustomRow gap="8px" alignItems="center">
        <SealCheck color={menuColor} />
        <CustomText text="Verify" color={menuColor} />
      </CustomRow>
    );
  }, []);
  const verifyWithWalletItem = useMemo(() => {
    return (
      <MenuItem disabled={wallet?.is_verified}>
        <DynamicWalletButton
          onSuccess={(session) =>
            verifyWallet({
              address: wallet.address,
              session,
              onSuccess: refetch,
            })
          }
          customRender={() => {
            return walletItem;
          }}
          disabled={wallet?.is_verified}
        />
      </MenuItem>
    );
  }, [verifyWallet, wallet.address, walletItem, refetch, wallet?.is_verified]);
  if (isLoggedInUserProfile) {
    return (
      <>
        <ClickAwayListener
          onClickAway={() => {
            setIsEditPopupShown(false);
          }}>
          <div style={{ position: "relative", top: -10, right: -30 }}>
            <PPLxHoverCard
              open={isEditPopupShown}
              content={
                <AddWalletForm
                  wallet={wallet}
                  isEditing={true}
                  refetch={refetch}
                  bundleData={bundleData}
                  setShowPopUpCard={() => {
                    setIsEditPopupShown(false);
                  }}
                  identityId={profileData?.current?.details?.id}
                  groups={bundleData?.groups}
                  selectedGroupIds={wallet?.group_ids}
                  isLoggedInUserProfile={isLoggedInUserProfile}
                />
              }
              placement="bottom">
              <div></div>
            </PPLxHoverCard>
          </div>
        </ClickAwayListener>
        <ClickAwayListener
          onClickAway={() => {
            setIsDeletePopupShown(false);
          }}>
          <div style={{ position: "relative", top: -10, right: -50 }}>
            <PPLxHoverCard
              open={isDeletePopupShown}
              content={
                <DeleteWalletPopUpForm
                  bundleData={bundleData}
                  wallet={wallet}
                  refetch={refetch}
                  setShowPopUpCard={setIsDeletePopupShown}
                  isUserProfile={false}
                />
              }
              placement="bottom">
              <div></div>
            </PPLxHoverCard>
          </div>
        </ClickAwayListener>
        <CustomRow ref={anchorRef}>
          <IconButton
            size="small"
            onClick={() => {
              history.push(
                `/${params.identifier}/wallets/edit-wallet/${wallet.address}`
              );
            }}>
            <PencilSimple width={16} height={16} color="var(--text-1)" />
          </IconButton>

          <IconButton size="small" onClick={handleMenuOpen}>
            <DotsThreeVertical width={16} height={16} color="var(--text-1)" />
          </IconButton>
          <Menu
            sx={{
              "& .MuiMenu-paper": {
                backgroundColor: "var(--navBg)",
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "var(--primary-color10)",
              },
            }}
            anchorEl={anchorRef?.current}
            open={menuOpen}
            onClose={handleMenuClose}
            disableScrollLock={true}
            dense>
            {/* Connect Wallet */}
            {verifyWithWalletItem}
            {items.map((item, index) => (
              <MenuItem
                sx={{}}
                key={index}
                onClick={item.onClick}
                disabled={item.disabled}>
                <CustomRow gap="8px" alignItems="center">
                  {item.icon}
                  {item.label}
                </CustomRow>
              </MenuItem>
            ))}
          </Menu>
        </CustomRow>
      </>
    );
  } else {
    return (
      <DeleteWalletPopUp
        isDisabled={wallet?.is_verified}
        wallet={wallet}
        refetch={refetch}
        bundleData={bundleData}
      />
    );
  }
};

export default WalletMenu;
