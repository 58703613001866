import { stopEventPropagation } from "utils/misc";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { Funnel } from "../../../../node_modules/@phosphor-icons/react/dist/index";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";

export const getTokenFolloweePNLTableColumns = ({
  filter,
  updateFilter,
  toggleSearchModal,
}) => {
  return [
    {
      title: "BUY",
      key: "buy",
      width: "15%",
      align: "flex-end",
      render: (row) => {
        const buy = row.usd_value_total_buy?.value;
        const displayValue = row.usd_value_total_buy?.display_value;
        const isSmall = buy && buy < 1;
        return (
          <ZxText
            style={TSTYLES.title1}
            fontSize="14px"
            color={buy && buy > 0 ? "success" : "text-1"}>
            {buy && buy > 1 ? displayValue : isSmall ? "~$0" : "-"}
          </ZxText>
        );
      },
    },
    {
      title: "SELL",
      key: "sell",
      width: "15%",
      align: "flex-end",
      render: (row) => {
        const sell = row.usd_value_total_sold?.value;
        const displayValue = row.usd_value_total_sold?.display_value;
        const isSmall = sell < 1;
        return (
          <ZxText style={TSTYLES.title1} fontSize="13px" color={"error"}>
            {sell && sell > 1 ? displayValue : isSmall ? "~$0" : "-"}
          </ZxText>
        );
      },
    },
    {
      title: "CLOSED PNL",
      key: "closed_pnl",
      width: "20%",
      align: "flex-end",
      render: (row) => {
        const pnl = row.closed_pnl;
        const pnlValue = pnl?.value;
        const color =
          pnlValue !== undefined
            ? pnlValue > 0
              ? "success"
              : "error"
            : "text-1";
        const isSmall = Math.abs(pnlValue) < 1;
        return (
          <ZxText style={TSTYLES.title1} fontSize="14px" color={color}>
            {isSmall ? "~$0" : row.closed_pnl?.display_value ?? "-"}
          </ZxText>
        );
      },
    },
    {
      title: "CURRENT VALUE",
      key: "total_value",
      width: "20%",
      align: "flex-end",
      render: (row) => {
        const usd = row.total_usd_value;
        const isSmall = usd?.value && usd?.value < 1;

        const openPnl = row.open_pnl;
        const openPnlValue = openPnl?.value;
        const isOpenPnlSmall = openPnlValue && openPnlValue < 1;
        return (
          <ZxFlex gap="8px">
            <ZxText style={TSTYLES.title1} fontSize="14px" color={"text-1"}>
              {isSmall ? "~$0" : usd?.display_value ?? "-"}
            </ZxText>
            {openPnlValue !== undefined &&
              openPnlValue !== 0 &&
              !isOpenPnlSmall && (
                <ZxText
                  style={TSTYLES.title1}
                  fontSize="11px"
                  color={openPnlValue > 0 ? "success" : "error"}>
                  {openPnlValue > 0 ? "+" : ""}
                  {openPnl?.display_value ?? "-"}
                </ZxText>
              )}
          </ZxFlex>
        );
      },
    },
    {
      title: (
        <ZxFlex justify="space-between" width="100%">
          <ZxFlex width="90%">USER/WALLET</ZxFlex>
          {
            <ZxFlex
              cursor="pointer"
              width="10%"
              onClick={(e) => {
                stopEventPropagation(e);
                toggleSearchModal(true);
              }}>
              <Funnel size={14} color="var(--text-3)" weight={"regular"} />
            </ZxFlex>
          }{" "}
        </ZxFlex>
      ),
      key: "address",
      width: "35%",
      render: (row, { profiles }) => {
        const profile = profiles[row.id];
        return (
          <ZxFlex
            width="100%"
            cursor="pointer"
            onClick={(e) => {
              stopEventPropagation(e);
              updateFilter(profiles?.[row.id]);
            }}>
            <ZxFlex width="90%">
              <ZxActor
                actor={profile}
                showFollowButton={false}
                size="20px"
                showNetworth={false}
                dpFontSize="13px"
                nameLength={20}
              />
            </ZxFlex>
            <Funnel
              size={16}
              color="var(--text-3)"
              weight={filter?.address === profile?.address ? "fill" : "regular"}
            />
          </ZxFlex>
        );
      },
    },
  ];
};
