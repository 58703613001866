import React, { useContext, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import styles from "./WalletAccessPage.module.scss";
import {
  WalletAccessPageHeader,
  WalletSharedRow,
} from "./WalletAccessPage.utils";
import ZxTabs from "zxComponents/common/ZxTabs/ZxTabs";
import {
  useGetActiveWalletConnections,
  useGetWalletRequests,
} from "api/profile.api";
import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import { EMPTY_STATES } from "zxStyles/constants";
import { AuthContext } from "contextStates/AuthContext";
import { CustomRow, CustomSkeleton } from "components/UI/Components/Components";

const WalletRowLoader = () => {
  return [1, 2, 3, 4, 5].map((row, idx) => {
    return (
      <CustomRow gap="16px" key={row}>
        <CustomSkeleton
          width="40px"
          height="40px"
          variant="rectangular"
          borderRadius="4px"
        />
        <CustomSkeleton
          width="100%"
          height="40px"
          variant="rectangular"
          borderRadius="4px"
        />
      </CustomRow>
    );
  });
};
const WalletManagePage = () => {
  const [activeTab, setActiveTab] = React.useState("shared");
  const {
    data: activeConnections,
    isLoading: isActiveLoading,
    isFetching: isActiveFetching,
  } = useGetActiveWalletConnections();
  const {
    data: requestConnections,
    isLoading: isRequestLoading,
    isFetching: isFetchingRequest,
  } = useGetWalletRequests();
  const params = useParams();
  const [isRequestsClicked, setIsRequestsClicked] = React.useState(false);
  const TABS = useMemo(() => {
    return [
      {
        label: "Shared",
        key: "shared",
      },
      {
        key: "requests",
        label: "Requests",
        postfix: () => {
          const requests =
            requestConnections?.received?.details?.length +
            requestConnections?.sent?.details?.length;
          if (!requests || requests === 0 || isRequestsClicked) return null;
          return (
            <span
              className={`${styles.count} ${activeTab === "requests" ? styles.black : styles.red}`}>
              {requests > 0 ? requests : null}
            </span>
          );
        },
      },
    ];
  }, [isRequestsClicked, activeTab, requestConnections]);
  const { identifier } = params;
  const { identityDetails } = useContext(AuthContext);
  const history = useHistory();

  const idCheck =
    identityDetails?.current?.identity && identifier
      ? identityDetails?.current?.identity?.id?.toString() === identifier ||
        identityDetails?.current?.identity?.username === identifier
      : true;
  if (!idCheck) {
    history.push(`/${identifier}/wallets`);
    return null;
  }
  const renderTabContent = () => {
    if (activeTab === "shared") {
      if (isActiveLoading || isActiveFetching) {
        return <WalletRowLoader />;
      }
      if (activeConnections?.details?.length === 0) {
        return (
          <ZxEmptyState
            state={EMPTY_STATES.TOKEN}
            size={200}
            text="No active connections"
          />
        );
      }
      return activeConnections?.details?.map((row, idx) => {
        return (
          <WalletSharedRow
            profiles={activeConnections.profiles}
            row={row}
            key={idx}
            isLast={idx === activeConnections?.details?.length - 1}
            identifier={identifier}
            hideBadge
          />
        );
      });
    } else {
      const sent = requestConnections?.sent || {};
      const received = requestConnections?.received || {};
      if (isRequestLoading || isFetchingRequest) {
        return <WalletRowLoader />;
      }
      if (!sent?.details?.length && !received?.details?.length) {
        return (
          <ZxEmptyState
            state={EMPTY_STATES.TOKEN}
            size={200}
            text="No pending requests"
          />
        );
      }
      return (
        <div className={styles.requestsContainer}>
          {received?.details?.map((rec, idx) => {
            return (
              <WalletSharedRow
                profiles={received.profiles}
                row={rec}
                identifier={identifier}
              />
            );
          })}
          {sent?.details?.map((sn, idx) => {
            return (
              <WalletSharedRow
                profiles={sent.profiles}
                row={sn}
                isLast={false}
                identifier={identifier}
              />
            );
          })}
        </div>
      );
    }
  };
  return (
    <div className={styles.container}>
      <WalletAccessPageHeader
        title="Share private wallets"
        hideBadge
        showNewRequest
      />
      <ZxTabs
        activeTab={activeTab}
        onChange={(tab) => {
          setActiveTab(tab);
          if (tab === "requests") setIsRequestsClicked(true);
        }}
        tabs={TABS}
      />
      {renderTabContent()}
    </div>
  );
};

export default WalletManagePage;
