import classes from "./ProtocolHeader.module.css";
import { getProtocolDetails } from "api/contract.api";
import withLoader from "components/core/withLoader";
import ProtocolInfo from "./ProtocolInfo/ProtocolInfo";
import TVLCard from "../../../UI/Cards/TVLCard";
import TokenCard from "../../../UI/Cards/TokenCard";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  CustomSkeleton,
} from "components/UI/Components/Components";
import ProfileCardShimmer from "components/V2/Profile/ProfileHeader/ProfileCardShimmer";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import { useContext, useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";

export const ProtocolHeaderGhost = () => {
  return (
    <div className={classes.protocol_header}>
      <CustomColumn>
        <CustomRow>
          <PaddingComponent paddingRight="24px">
            <CustomSkeleton variant="rounded" height={64} width={64} />
          </PaddingComponent>
          <CustomColumn
            justifyContent="center"
            style={{
              width: "100%",
            }}>
            <CustomSkeleton width="120px" height={32} variant="text" />
            <CustomSkeleton width="320px" height={24} variant="text" />
          </CustomColumn>
        </CustomRow>
        <PaddingComponent height="24px" />
        <CustomRow gap="20px">
          <ProfileCardShimmer />
          <ProfileCardShimmer />
        </CustomRow>
        <PaddingComponent height="40px" />
      </CustomColumn>
    </div>
  );
};

const ProtocolHeader = ({ queryData, protocol }) => {
  const { tvl, profiles, token } = queryData;
  const tokenData = profiles[`${token?.chain_id}_${token?.address}`];
  const display_name =
    tokenData?.token_details?.symbol ?? tokenData?.display_name;

  const { setTitle } = useContext(LeftSideHeaderContext);
  useEffect(() => {
    setTitle(queryData.name);
  }, [queryData, setTitle]);

  return (
    <div className={classes.protocol_header}>
      <section className={classes.info}>
        <ProtocolInfo info={queryData} />
      </section>
      {queryData.description && (
        <>
          <PaddingComponent paddingTop="16px">
            <FeedDescription
              templateString={queryData.description}
              mainProtocolData={{
                [queryData?.main_protocol_details?.identifier]:
                  queryData?.main_protocol_details,
              }}
            />
          </PaddingComponent>
        </>
      )}

      <section>
        <ScrollContainer className={classes.cards}>
          {tokenData && (
            <TokenCard
              title={`${display_name} Token`}
              marketCap={token.fully_diluted_valuation.display_value}
              value={token.price?.display_value}
              percentChange={token.price_percentage_change}
              // link={profiles[`${token.chain_id}_${token.address}`].link}
              link="token"
              logo={token.logo}
            />
          )}
          <TVLCard title="TVL" value={tvl.display_value} />
        </ScrollContainer>
      </section>
      <PaddingComponent height="40px" />
    </div>
  );
};

export default withLoader(ProtocolHeader, getProtocolDetails, {
  queryKey: (props) => ["Protocol", props.protocol],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  renderNotFound: true,
  refetchContainerClass: classes.refetch_container,
  CustomLoader: ProtocolHeaderGhost,
});
