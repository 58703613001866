import classes from "../../../../../../components/FollowEditButton/FollowForm/FollowForm.module.css";
import classesComponents from "./WalletsComponents.module.css";
import CustomTextField from "../../../../../../components/FollowEditButton/CustomTextField";
import { addRemoveAddressFromBundle } from "api/profile.api";
import { GlobalContext } from "contextStates/Global";
import { useState, useContext, useEffect, useMemo } from "react";
import { CircularProgress } from "@mui/material";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import {
  CustomCheckBox,
  CustomRow,
  CustomText,
} from "components/UI/Components/Components";
import { Info, Plus } from "@phosphor-icons/react";
import CustomSwitchButton from "components/UI/Components/CustomSwitch";
import { WalletBundleForm } from "components/V2/Profile/ProfileDetails/wallets/WalletBundles/AddEditWalletBundle";
import { trackEvent } from "utils/event_tracking";

const AddEditWalletForm = ({
  identityId,
  setShowPopUpCard,
  refetch,
  isLoggedInUserProfile,
  groups,
  selectedGroupIds,
  wallet,
  isEditing = false,
  bundleData,
}) => {
  const [addressState, setAddressState] = useState(wallet?.address || "");
  const [reasonState, setReasonState] = useState(wallet?.reason || "");
  const [walletGroups, setWalletGroups] = useState([]);
  const [isPrivateWallet, setIsPrivateWallet] = useState(
    wallet?.is_private ?? false
  );
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [isAddingBundle, setIsAddingBundle] = useState(false);
  const { handleErrorSnackbar, setSnackBarState, featureFlags } =
    useContext(GlobalContext);

  const disabled = !addressState || !reasonState;

  useEffect(() => {
    if (isEditing) {
      setWalletGroups(
        Object.keys(groups || {}).map((id) => ({
          ...groups[id],
          isSelected: wallet?.group_ids?.includes(parseInt(id)) ?? false,
        }))
      );
    }
    setWalletGroups(
      Object.keys(groups || {}).map((id) => ({
        ...groups[id],
        isSelected: selectedGroupIds?.includes(parseInt(id)) ?? false,
      }))
    );
  }, [groups, wallet, selectedGroupIds, isEditing]);

  const canMakeWalletPrivate = useMemo(() => {
    if (wallet === null || !isEditing) {
      return true;
    }
    let count = 0;
    var verifiedWalletAddress = "";
    bundleData?.bundle?.wallets.forEach((w) => {
      if (w.is_verified && !w.is_private) {
        count++;
        verifiedWalletAddress = w.address;
      }
    });
    if (count <= 1 && verifiedWalletAddress === wallet?.address) {
      return false;
    }
    return true;
  }, [bundleData, wallet, isEditing]);

  const addWalletToBundle = () => {
    setIsAddingAddress(true);
    addRemoveAddressFromBundle({
      address: addressState,
      reason: reasonState,
      identity_id: identityId,
      is_private: isPrivateWallet,
      group_ids: walletGroups.filter((g) => g.isSelected).map((g) => g.id),
    })
      .then((resp) => {
        setShowPopUpCard(false);
        refetch();
        setSnackBarState({
          open: true,
          message: isEditing ? "Saved changes" : "Added wallet to bundle",
          type: "success",
        });
        trackEvent("wallet_added_success", {
          address: addressState,
          reason: reasonState,
          identity_id: identityId,
          is_private: isPrivateWallet,
          group_ids: walletGroups.filter((g) => g.isSelected).map((g) => g.id),
        });
      })
      .catch((e) => {
        trackEvent("wallet_added_failure", {
          address: addressState,
          reason: reasonState,
          identity_id: identityId,
          is_private: isPrivateWallet,
          group_ids: walletGroups.filter((g) => g.isSelected).map((g) => g.id),
        });
        setShowPopUpCard(false);

        if (e.response.data?.errors?.errors[0]?.msg) {
          handleErrorSnackbar(null, e.response.data?.errors?.errors[0].msg);
        } else if (typeof e.response.data?.errors?.errors === "string") {
          handleErrorSnackbar(null, e.response.data?.errors?.errors);
        } else {
          handleErrorSnackbar(
            e,
            isEditing
              ? "Failed to save changes"
              : "Failed to add wallet to bundle"
          );
        }
      });
  };

  if (isAddingBundle) {
    return (
      <WalletBundleForm
        showBackButton={true}
        onSuccess={() => {
          refetch();
        }}
        onClose={() => {
          setIsAddingBundle(false);
        }}
      />
    );
  }

  return (
    <div className={classes.follow_form} style={{ gridGap: "8px" }}>
      <h3 className={classes.title}>
        {isEditing
          ? "Edit Details"
          : isLoggedInUserProfile
            ? "Add Wallet"
            : "Add Wallet to Bundle"}
      </h3>
      <div className={classesComponents.divider}></div>
      {/* This div is used for extra padding */}
      <div />
      <div className={classes.form_group}>
        <CustomTextField
          size="small"
          placeholder="Wallet Address"
          fullWidth
          autoFocus={!isEditing}
          disabled={isEditing}
          value={addressState}
          onChange={(e) => {
            setAddressState(e.target.value);
          }}
        />
        {isLoggedInUserProfile ? (
          <CustomTextField
            size="small"
            placeholder="Wallet Name"
            autoFocus={isEditing}
            fullWidth
            value={reasonState}
            optional
            onChange={(e) => {
              setReasonState(e.target.value);
            }}
          />
        ) : (
          <CustomTextField
            size="small"
            placeholder="Reason"
            fullWidth
            value={reasonState}
            optional
            onChange={(e) => {
              setReasonState(e.target.value);
            }}
          />
        )}
      </div>

      {isLoggedInUserProfile && featureFlags?.is_showing_private_wallets && (
        <>
          <CustomRow flexGrow={1} padding="8px 0 0 0">
            <h3 className={classes.title}>
              {isEditing ? "Groups" : "Add to Groups"}
            </h3>
            <PPLxTooltip title="Groups help you organise your wallets" arrow>
              <span className={classes.info_icon}>
                <Info width={16} height={16} />
              </span>
            </PPLxTooltip>
          </CustomRow>
          <CustomRow padding="8px 0px" gap="8px" flexWrap="wrap">
            <button
              className={classes.new_bundle_button}
              onClick={() => {
                setIsAddingBundle(true);
              }}>
              <Plus
                width={12}
                height={12}
                stroke="currentColor"
                strokeWidth="16px"
              />
              <span>New</span>
            </button>
            {walletGroups.map((group) => {
              const onClick = () => {
                setWalletGroups((prev) => {
                  const newGroups = prev.map((g) => {
                    if (g.id === group.id) {
                      return { ...g, isSelected: !g.isSelected };
                    }
                    return g;
                  });
                  return newGroups;
                });
              };
              return (
                <CustomCheckBox
                  className={classes.bundle_checkbox}
                  isActive={group.isSelected}
                  setIsActive={onClick}>
                  <CustomText text={group.group_name} color="var(--text-1)" />
                </CustomCheckBox>
              );
            })}
          </CustomRow>
          <div className={classesComponents.divider}></div>
          {canMakeWalletPrivate && (
            <CustomRow padding="8px 0px">
              <CustomRow flexGrow={1}>
                <h3 className={classes.title}>
                  {isEditing ? "Private Wallet" : "Make Wallet Private"}
                </h3>
                <PPLxTooltip
                  title="Private wallet is visible only to you"
                  arrow>
                  <span className={classes.info_icon}>
                    <Info width={16} height={16} />
                  </span>
                </PPLxTooltip>
              </CustomRow>
              <CustomSwitchButton
                isPrimaryColored={true}
                toggle={isPrivateWallet}
                setToggle={(value) => setIsPrivateWallet(value)}
                activeSliderBackground="var(--primary-color)"
                activeSliderCircle="var(--text-white)"
              />
            </CustomRow>
          )}
        </>
      )}
      <div />
      <button
        className={`${classes.done} ${disabled ? classes.disabled : ""}`}
        onClick={addWalletToBundle}
        disabled={disabled}>
        {isAddingAddress ? (
          <div className={classes.spinner_container}>
            <CircularProgress color="inherit" size={12} />
          </div>
        ) : (
          <div>Apply</div>
        )}
      </button>
    </div>
  );
};

export default AddEditWalletForm;
