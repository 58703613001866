import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
} from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { ClickAwayListener } from "@mui/material";
import { ImageSquare } from "@phosphor-icons/react";
import Spinner from "shared/buttonloadingSpinner";
import DiscardButton from "../DiscardButton";
import classes from "./PostCommentRenderer.module.css";
import NewPostHeader from "./Components/NewPostHeader";
import { useFetchConnectPlatformsCrossPost } from "./CrossPost/cross_post_utils";
import { IconButton } from "@mui/material";
import { Gif, PlusCircle, Smiley, X } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { getFarcasterChannels } from "api/feed.api";
import farcasterIcon from "assets/svg/farcaster.svg";
import {
  CustomRow,
  VerticalDivider,
} from "components/UI/Components/Components";
import CustomDropdownButton from "components/UI/Components/CustomDropDown";
import { AuthContext } from "contextStates/AuthContext";
import { GlobalContext } from "contextStates/Global";
import createEmojiPlugin from "draft-js-emoji-plugin";
import { Validation } from "utils/constants";
import { trackEvent } from "utils/event_tracking";
import CrossPostModal from "./Components/CrossPostModal";
import GifSearchExperience from "./Components/GifPopUp";
import PostProgressCircle from "./Components/PostProgressCircle";
import { getFormattedPostContentFromEditorForBackend } from "./CrossPost/cross_post_utils";
import {
  SUPPORTED_IMAGE_FORMATS,
  getFileTypeFromDataString,
} from "./NewPost.utils";
import PostCommentTextEditor from "./PostCommentTextEditor";
import { Spinner_Blob } from "./spinner";
const PostCommentRenderer = ({
  isLoading,
  isDummy = false,
  onSubmit,
  isSuccess,
  showNewPostIcon = false,
  postDisabled,
  isEditorFocused,
  placeholder,
  isModal,
  renderContent = null,
  prefillCommentContent,
  onDiscard,
  isNewPost = false,
  isHomePage,
  handleOnFocus = () => {},
  source,
  enableCrossPost = false,
  isCrossPostDefaultSelected = true,
  postDetails,
  commentSuccessResponse,
  resetCommentSuccessResponse,
  onCrossCommentClose = () => {},
  prefixContent = null,
}) => {
  const { identityDetails } = useContext(AuthContext);
  const { featureFlags } = useContext(GlobalContext);

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    clearErrors,
    reset,
    formState: { errors },
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      threads: [{ name: prefillCommentContent ?? "" }],
    },
  });

  const emojiPluginOptions = useMemo(
    () => ({
      useNativeArt: true,
      selectButtonContent: <Smiley size={20} />,
    }),
    []
  );
  const { isUserLoggedIn } = useContext(AuthContext);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const emojiPlugin = createEmojiPlugin(emojiPluginOptions);
  const [isFormExpanded, setIsFormExpanded] = useState(false);
  //this stores raw content for users text
  const [editorRawContent, setEditorRawContent] = useState([]);
  const [rawLinks, setRawLinks] = useState([]);
  const [urls, setUrls] = useState([]);
  const [currentThreadIndex, setCurrentThreadIndex] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [isCrossPostModalOpen, setIsCrossPostModalOpen] = useState(false);
  const fileInputRef = useRef();
  const [emojiPlugins, setEmojiPlugins] = useState([emojiPlugin]);
  const [showGifPopUp, setShowGifPopUp] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [selectedChannelName, setSelectedChannelName] =
    useState("Select Channel");

  const {
    connectedPlatforms,
    refetch,
    isFetching: isConnectedPlatformsLoading,
  } = useFetchConnectPlatformsCrossPost();

  const isFarcasterConnected = selectedPlatforms?.includes("farcaster");
  const isTwitterConnected = selectedPlatforms?.includes("twitter");
  const isLensConnected = selectedPlatforms?.includes("lens");

  const gifRef = useRef();

  const { data: { farcaster: farcasterChannels = [] } = {} } = useQuery({
    queryKey: ["get-farcaster-channels"],
    queryFn: getFarcasterChannels,
    retry: 2,
    retryOnMount: false,
    staleTime: 1000 * 60 * 60,
    enabled: isUserLoggedIn,
  });
  const [isCrossCommentDone, setIsCrossCommentDone] = useState(false);
  const [disableCrossComment, setDisableCrossComment] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "threads",
  });

  const currentComment = watch(`threads.${currentThreadIndex}.name`, "");

  let EmojiSuggestions, EmojiSelect;
  if (emojiPlugins && emojiPlugins[currentThreadIndex]) {
    EmojiSuggestions = emojiPlugins[currentThreadIndex].EmojiSuggestions;
    EmojiSelect = emojiPlugins[currentThreadIndex].EmojiSelect;
  }

  const ignoreWordLimit =
    featureFlags?.ignore_cross_post_word_count_limit ?? false;

  const addThread = useCallback(async () => {
    append({ name: "" });
    setCurrentThreadIndex(fields.length);
    setPreviewImages((prevImages) => [...prevImages, []]);
    setRawLinks((prevLinks) => [...prevLinks, []]);
    setUrls((prevUrls) => [...prevUrls, []]);
    setEditorRawContent((prev) => [...prev, null]);
    const newEmojiPlugin = createEmojiPlugin(emojiPluginOptions);

    setEmojiPlugins((prev) => [...prev, newEmojiPlugin]);
  }, [
    append,
    setPreviewImages,
    setCurrentThreadIndex,
    fields,
    emojiPluginOptions,
  ]);

  const handleWrapperClick = () => {
    if (!isDummy) {
      setIsFormExpanded(true);
    }
  };

  const handleEditorBlur = () => {
    setIsFormExpanded(false);
  };

  useEffect(() => {
    if (isEditorFocused) {
      setIsFormExpanded(true);
    }
  }, [isEditorFocused, setIsFormExpanded]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setUrls(rawLinks);
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [rawLinks]);

  useEffect(() => {
    if (
      commentSuccessResponse &&
      commentSuccessResponse?.metadata?.error_message
    ) {
      handleErrorSnackbar(
        new Error(commentSuccessResponse.metadata.error_message)
      );
      refetch();
      resetCommentSuccessResponse();
    }
  }, [
    commentSuccessResponse,
    refetch,
    handleErrorSnackbar,
    resetCommentSuccessResponse,
  ]);

  const handleClearContent = useCallback(() => {
    reset({
      threads: [{ name: prefillCommentContent ?? "" }],
    });
    setRawLinks([]);
    setUrls([]);
    setPreviewImages([]);
  }, [reset, setUrls, setPreviewImages, prefillCommentContent, setRawLinks]);

  const discardPost = () => {
    if (isModal) {
      onDiscard?.();
      return;
    }
    handleClearContent();
    setIsFormExpanded(false);
  };
  useEffect(() => {
    if (isSuccess) {
      setIsFormExpanded(false);
      handleClearContent();
    }
  }, [isSuccess, handleClearContent]);
  useEffect(() => {
    if (!isSuccess) return;
    if (!isCrossCommentDone && commentSuccessResponse && !disableCrossComment) {
      const actor = postDetails?.actor;
      const isAuthorOn0xppl =
        actor?.is_user_account ||
        actor?.user_since != null ||
        actor?.is_verified;
      const authPlatforms = [];
      if (actor?.external_links?.twitter) authPlatforms.push("twitter");
      if (actor?.external_links?.lens) authPlatforms.push("lens");
      // If no platform is selected and user is not verified then show cross post modal
      if (
        selectedPlatforms.length === 0 &&
        source === "comment" &&
        !isAuthorOn0xppl &&
        !isCrossCommentDone &&
        authPlatforms.length > 0 &&
        featureFlags?.show_cross_post_comment_nudge
      ) {
        handleClearContent();
        setIsCrossPostModalOpen(true);
        return;
      } else {
        onCrossCommentClose();
      }
    } else {
      onCrossCommentClose();
    }
  }, [
    handleClearContent,
    isSuccess,
    postDetails,
    selectedPlatforms,
    source,
    commentSuccessResponse,
    isCrossCommentDone,
    identityDetails,
    featureFlags.show_cross_post_comment_nudge,
    disableCrossComment,
    onCrossCommentClose,
  ]);

  let isAddThreadsDisabled = postDisabled || isLoading > 0;

  //if any item is is emoty then make it disabled
  if (!isAddThreadsDisabled) {
    const emptyIndex = fields.find((_, idx) => {
      const thread = watch(`threads.${idx}.name`, "");
      const progres = thread.length ?? 0;
      return progres === 0;
    });
    isAddThreadsDisabled |= emptyIndex !== undefined;
  }
  const postHasImages = previewImages.some((img) => img.length > 0);
  let isSubmitDisabled =
    source !== "repost" &&
    ((isAddThreadsDisabled && !postHasImages) ||
      Object.keys(errors).length > 0);

  const myOnSubmit = useCallback(() => {
    if (isSubmitDisabled) {
      return;
    }

    const threads = [];
    // create a array where each item contain name and images
    for (let i = 0; i < fields.length; i++) {
      const contentForBackend = getFormattedPostContentFromEditorForBackend({
        content: editorRawContent[i],
      });
      const thread = {
        contents: contentForBackend,
        images:
          previewImages[i]
            ?.filter(({ dontPost }) => !dontPost)
            ?.map(({ result }) => result) ?? [],
        urls: urls[i] ?? [],
      };
      threads.push(thread);
    }
    if (isCrossCommentDone) setIsCrossCommentDone(false);
    trackEvent("post_btn_click");
    setDisableCrossComment(selectedPlatforms.length > 0);
    onSubmit({ threads, selectedPlatforms, channelId: selectedChannelId });
  }, [
    onSubmit,
    isCrossCommentDone,
    urls,
    fields,
    previewImages,
    editorRawContent,
    selectedPlatforms,
    selectedChannelId,
    isSubmitDisabled,
  ]);

  const currentThreadValue = watch(`threads.${currentThreadIndex}.name`, "");
  const currentItemProgress = currentThreadValue.length ?? 0;

  const maxLength = ignoreWordLimit
    ? 999999
    : Math.min(
        isTwitterConnected
          ? Validation.TWITTER_MAX_LENGTH
          : Validation.POST_MAX_LENGTH,
        isFarcasterConnected
          ? Validation.FARCASTER_MAX_LENGTH
          : Validation.POST_MAX_LENGTH,
        isLensConnected
          ? Validation.LENS_MAX_LENGTH
          : Validation.POST_MAX_LENGTH,
        Validation.POST_MAX_LENGTH
      );

  const validationConfig = {
    maxLength: {
      value: maxLength,
      message: `Max ${maxLength} characters. Add a new thread!`,
    },
    minLength: {
      value: Validation.MIN_LENGTH,
      message: "Post cannot be less than the min length",
    },
  };

  const changeUrlToBlob = async (url) => {
    return await fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({ blob, result: reader.result });
          };
          reader.readAsDataURL(blob);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleClickOutside = (event) => {
    if (gifRef.current && !gifRef.current.contains(event.target)) {
      setShowGifPopUp(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onGifSelect = (gif) => {
    const url = gif?.images?.original?.url;
    const placeholderImage = Spinner_Blob;
    const currentImages = previewImages[currentThreadIndex] ?? [];
    let newImages = [...currentImages, placeholderImage];
    let newPreviewImages = [...previewImages];
    newPreviewImages[currentThreadIndex] = newImages;
    setPreviewImages(newPreviewImages);

    changeUrlToBlob(url).then((result) => {
      newImages = newImages.map((image) => {
        if (image === placeholderImage) {
          return result;
        }
        return image;
      });
      newPreviewImages[currentThreadIndex] = newImages;
      setPreviewImages([...newPreviewImages]);
    });
  };

  // Check if thread has more than 2 images when farcaster is selected
  useEffect(() => {
    const threads = watch("threads");

    threads.forEach((_, index) => {
      // check if thread has more than 2 images
      if (isFarcasterConnected && previewImages[index]?.length > 2) {
        setError(`threads.${index}.farcaster`, {
          message: "Farcaster supports only 2 images per thread",
        });
      } else {
        clearErrors(`threads.${index}.farcaster`);
      }
      const postHasSupportedFiles = previewImages[index]?.every(
        ({ file, result }) => {
          const type = file?.type ?? getFileTypeFromDataString(result);
          return SUPPORTED_IMAGE_FORMATS.includes(type);
        }
      );
      if (!postHasSupportedFiles && previewImages[index]?.length > 0) {
        setError(`threads.${index}.unsupported_format`, {
          message:
            "One or more image formats not supported. Use only .jpg, .png, .gif or .webp files.",
        });
      } else {
        clearErrors(`threads.${index}.unsupported_format`);
      }
    });
  }, [
    previewImages,
    connectedPlatforms,
    isFarcasterConnected,
    setError,
    clearErrors,
    watch,
  ]);
  const triggerSubmit = (e) => {
    e.preventDefault();
    if (!showGifPopUp) {
      handleSubmit(myOnSubmit)();
      return;
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleEditorBlur}>
        <form onSubmit={triggerSubmit}>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            multiple
            onChange={(e) => {
              const files = Array.from(e.target.files);
              if (files.length > 0) {
                const readerPromises = files.map((file) => {
                  return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                      resolve({ file, result: reader.result });
                    };
                    reader.readAsDataURL(file);
                  });
                });

                Promise.all(readerPromises).then((results) => {
                  const currentImages = previewImages[currentThreadIndex] ?? [];
                  const newImages = [...currentImages, ...results];
                  const newPreviewImages = [...previewImages];
                  newPreviewImages[currentThreadIndex] = newImages;
                  setPreviewImages(newPreviewImages);
                });
                e.target.value = null;
              }
            }}
          />
          <div
            onClick={handleWrapperClick}
            className={`${classes.newPostWrapper} ${
              isFormExpanded ? classes.expanded : classes.default
            } ${isHomePage ? classes.homePage : ""} ${
              isModal ? classes.isModal : ""
            }`}
            style={{
              // pointerEvents: isDummy ? "none" : "default",
              cursor: "text",
              padding: isHomePage ? "14px 16px" : isModal ? "0" : "4px 12px",
            }}>
            {isFormExpanded && (isNewPost || enableCrossPost) && (
              <NewPostHeader
                isVisible={isFormExpanded && (isNewPost || enableCrossPost)}
                connectedPlatforms={connectedPlatforms}
                setSelectedPlatforms={setSelectedPlatforms}
                isCrossPostDefaultSelected={isCrossPostDefaultSelected}
                refetch={refetch}
                isLoading={isConnectedPlatformsLoading}
              />
            )}
            {/* {prefixContent && (
              <ZxComment comment={prefixContent} readOnly post={postDetails} />
            )} */}
            <div className={classes.contentWrapper}>
              <div className={classes.withImageAndLinks}>
                {(isFormExpanded ? fields : fields.slice(0, 1)).map(
                  (item, index) => {
                    return (
                      <Controller
                        key={item.id}
                        name={`threads.${index}.name`}
                        control={control}
                        rules={validationConfig}
                        render={({ field }) => (
                          <PostCommentTextEditor
                            trigger={trigger}
                            field={field}
                            index={index}
                            emojiPlugin={emojiPlugins[index]}
                            setEmojiPlugins={setEmojiPlugins}
                            setEditorRawContent={setEditorRawContent}
                            fieldName={`threads.${index}.name`}
                            watch={watch}
                            clearErrors={clearErrors}
                            errors={errors}
                            setValue={setValue}
                            setError={setError}
                            setCurrentThreadIndex={setCurrentThreadIndex}
                            previewImages={previewImages}
                            setPreviewImages={setPreviewImages}
                            isFormExpanded={isFormExpanded}
                            remove={remove}
                            fields={fields}
                            isSuccess={isSuccess}
                            onSubmit={handleSubmit(myOnSubmit)}
                            placeholder={
                              index === 0 ? placeholder : "Add another post"
                            }
                            showNewPostIcon={showNewPostIcon}
                            handleOnFocus={handleOnFocus}
                            urls={urls}
                            setRawLinks={setRawLinks}
                          />
                        )}
                      />
                    );
                  }
                )}
              </div>
            </div>
            {isFormExpanded && renderContent}
            <div className={classes.buttonsWrapper}>
              <div className={classes.optionsIcons}>
                <IconButton
                  type="button"
                  onClick={() => fileInputRef.current.click()}>
                  <ImageSquare size={20} style={{ color: "var(--text-2)" }} />
                </IconButton>
                <div
                  onClick={() => setShowGifPopUp(true)}
                  className={classes.gifBtn}>
                  <Gif size={20} style={{ color: "var(--text-2)" }} />
                  {showGifPopUp && (
                    <div ref={gifRef}>
                      <GifSearchExperience onSelect={onGifSelect} />
                    </div>
                  )}
                </div>
                <div className={classes.chooseEmojiWrapper}>
                  {EmojiSelect && <EmojiSelect />}
                  <div style={{ position: "relative" }}>
                    {!isFormExpanded && (
                      <div
                        onClick={() => setIsFormExpanded(true)}
                        className={classes.emojiSelectOverlay}
                      />
                    )}
                  </div>
                </div>
                {isFormExpanded && !ignoreWordLimit && (
                  <PostProgressCircle
                    wordsLength={currentItemProgress}
                    total={maxLength}
                  />
                )}
                {isNewPost && isFormExpanded && !isAddThreadsDisabled && (
                  <>
                    <VerticalDivider height="20px" />
                    <IconButton onClick={addThread}>
                      <PlusCircle
                        size={20}
                        weight="fill"
                        color="var(--primary-color)"
                      />
                    </IconButton>
                  </>
                )}
                {!isFormExpanded ? (
                  <div className={classes.actionButtons}>
                    <button
                      type="submit"
                      className={`${classes.postButton}  ${classes.disabled}`}
                      onClick={() => setIsFormExpanded(true)}>
                      Post
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {isFormExpanded && (
                <div className={classes.actionButtons}>
                  <CustomRow alignItems="flex-end" gap="4px">
                    {isFarcasterConnected && farcasterChannels.length > 0 && (
                      <CustomDropdownButton
                        fontSize="13px"
                        customClass={classes.farcasterDropdown}
                        customClassList={classes.farcasterList}
                        icon={farcasterIcon}
                        title={selectedChannelName}
                        items={farcasterChannels.map(
                          (channel) => channel.channel_name
                        )}
                        showSearch={true}
                        onSelectItem={(item, index) => {
                          const channel = farcasterChannels.find(
                            (channel) => channel.channel_name === item
                          );
                          if (channel) {
                            setSelectedChannelId(channel.channel_id);
                            setSelectedChannelName(item);
                          }
                        }}
                      />
                    )}
                    {selectedChannelId && isFarcasterConnected && (
                      <DiscardButton
                        text={<X size={16} />}
                        discardCallback={() => {
                          setSelectedChannelId(null);
                          setSelectedChannelName("Select Channel");
                        }}
                        noConfirmation={true}
                        className={classes.clearButton}
                      />
                    )}
                  </CustomRow>
                  <DiscardButton
                    className={classes.discardButton}
                    discardCallback={discardPost}
                    noConfirmation={
                      previewImages.length === 0 && !currentThreadValue
                    }
                  />
                  <button
                    type="submit"
                    className={classes.postButton}
                    disabled={isSubmitDisabled}>
                    {!isLoading ? (
                      "Post"
                    ) : (
                      <Spinner
                        style={{
                          stroke: "var(--text-white)",
                          background: "transparent",
                          width: "20px",
                          height: "20px",
                          alignSelf: "center",
                        }}
                      />
                    )}
                  </button>
                </div>
              )}
              {EmojiSuggestions && <EmojiSuggestions />}
            </div>
          </div>
        </form>
      </ClickAwayListener>

      {!isNewPost && isCrossPostModalOpen && (
        <CrossPostModal
          avatarUrl={postDetails?.actor?.display_picture}
          name={postDetails?.actor?.display_name}
          postDetails={postDetails}
          showModal={isCrossPostModalOpen}
          selectedPlatforms={selectedPlatforms ?? []}
          connectedPlatforms={connectedPlatforms ?? []}
          currentComment={currentComment}
          setShowModal={setIsCrossPostModalOpen}
          commentSuccessResponse={commentSuccessResponse}
          onSuccess={() => {
            onCrossCommentClose();
            setIsCrossCommentDone(true);
          }}
          onClose={() => {
            onCrossCommentClose();
            setIsCrossCommentDone(true);
          }}
          actor={postDetails?.actor}
          resetCommentSuccessResponse={resetCommentSuccessResponse}
        />
      )}
    </>
  );
};

export default PostCommentRenderer;
