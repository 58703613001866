import { getSearchResults } from "api/search.api";
import CustomTextField from "./CustomTextField.js";
import { useEffect, useState, useContext } from "react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { ClickAwayListener } from "@mui/material";
import React from "react";
import classes from "./SearchBarMini.module.css";
import { SpinnerComponent } from "../../../../UI/Components/Components.js";
import {
  CustomText,
  PaddingComponent,
  CustomRow,
} from "../../../../UI/Components/Components.js";
import { GlobalContext } from "contextStates/Global";
import { useChatContext } from "stream-chat-react";

import { ChannelTypes } from "../../helpers";

const SearchBarMini = ({
  placeholder,
  source,
  searchQueryKey,
  SearchItemComponent,
  refetch,
  watchlistItems,
  useChatClient = false,
  hideIcon,
  handleSelect,
  defaultSearchTerm,
  isSearchInputFocusedProp,
  hideOutline = true,
  size = "medium",
}) => {
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm || "");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(
    isSearchInputFocusedProp || false
  );
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const { client } = useChatContext();

  useEffect(() => {
    let timeOutId;
    const controller = new AbortController();

    if (searchTerm.trim().length > 0) {
      setSearchResults([]);
      setIsLoading(true);
      if (useChatClient) {
        timeOutId = setTimeout(() => {
          const filter = {
            type: "messaging",
            members: { $in: [client.userID] },
            // name: { $autocomplete: searchTerm },
            // "member.user.name": { $autocomplete: searchTerm },
            channel_type: { $in: [ChannelTypes.GROUP] },
          };
          const sort = [{ last_message_at: -1 }];

          client
            .queryChannels(filter, sort, {
              watch: true, // this is the default
              state: true,
            })
            .then((channels) => {
              setSearchResults(
                channels.filter((channel) =>
                  channel.data.name
                    ?.toLowerCase()
                    .includes(searchTerm?.toLowerCase())
                )
              );
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }, 500);
      } else {
        timeOutId = setTimeout(() => {
          getSearchResults({
            queryKey: [searchQueryKey, `${searchTerm} source:${source} `],
            signal: controller.signal,
          })
            .then((data) => {
              setSearchResults(data);
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }, 500);
      }
    } else {
      setIsLoading(false);
      setSearchResults([]);
    }

    return () => {
      clearTimeout(timeOutId);
      if (!controller.signal.aborted) {
        controller.abort();
      }
    };
  }, [
    searchTerm,
    searchQueryKey,
    source,
    useChatClient,
    client,
    handleErrorSnackbar,
  ]);

  return (
    <ClickAwayListener onClickAway={() => setIsSearchInputFocused(false)}>
      <div className={classes.searchBar}>
        <CustomTextField
          size={size}
          placeholder={placeholder}
          fullWidth
          value={searchTerm}
          autoComplete="off"
          hideOutline={hideOutline}
          onFocus={() => setIsSearchInputFocused(true)}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <>
                {isLoading ? (
                  <div className={classes.searchBarLoading}>
                    <SpinnerComponent size={20} />
                  </div>
                ) : null}
              </>
            ),
            startAdornment: (
              <div className={classes.searchBarPlaceholder}>
                {!hideIcon && (
                  <MagnifyingGlass size={20} color="var(--text-3)" />
                )}
              </div>
            ),
          }}
        />
        {isSearchInputFocused &&
          (searchResults?.length > 0 ? (
            <div className={classes.searchResultsContainer}>
              {searchResults.map((result) => (
                <SearchItemComponent
                  key={result.id}
                  result={result}
                  refetch={refetch}
                  watchlistItems={watchlistItems}
                  setIsSearchInputFocused={setIsSearchInputFocused}
                  useChatClient={useChatClient}
                  source={source}
                  setSearchTerm={setSearchTerm}
                  handleSelect={handleSelect}
                />
              ))}
            </div>
          ) : (
            searchTerm.trim().length > 0 &&
            !isLoading && (
              <div className={classes.searchResultsContainer}>
                <div className={classes.searchResultItem}>
                  <PaddingComponent height="8px" />
                  <CustomRow
                    gap="4px"
                    height="24px"
                    alignItems="center"
                    padding="0 24px"
                    width="100%">
                    <CustomText
                      text={"No results"}
                      fontSize="13px"
                      color="var(--text-2)"
                      lineHeight="130%"
                      letterSpacing="-0.22px"
                    />
                  </CustomRow>

                  <PaddingComponent height="8px" />
                </div>
              </div>
            )
          ))}
      </div>
    </ClickAwayListener>
  );
};

export default SearchBarMini;
