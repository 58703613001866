import React, { useMemo } from "react";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";
import brokenImageIcon from "assets/svg/feed/nft_fallback_no_bg.svg";
import { getTypeFromUrl, transformedIPFSLink } from "utils/misc";
import Image, { IMAGE_TYPES } from "components/UI/Image";
React.lazy(() => import("@google/model-viewer/dist/model-viewer"));

const FeedMediaType = ({
  renderedFrom,
  className,
  onClick = () => {},
  mediaItem,
  customStyle,
  setType,
  setIsLoading,
}) => {
  const type = useMemo(
    () =>
      (mediaItem?.content_type ?? getTypeFromUrl(mediaItem?.src)) || "image",
    [mediaItem?.content_type, mediaItem?.src]
  );
  const mediaSrc = transformedIPFSLink(mediaItem?.src) || mediaItem?.src;

  // Use model/video tags if they are substrings of `type` else fallback to image tag
  const tagToUse = type?.includes("model")
    ? "model"
    : type?.includes("video")
      ? "video"
      : "image";

  return (
    <>
      {tagToUse === "image" && renderedFrom === "GalleryModal" && (
        <Image
          className={className ?? ""}
          src={mediaItem?.scaled_src ?? mediaItem?.src ?? mediaSrc}
          alt={mediaItem?.alt}
          style={{ ...customStyle, ...mediaItem?.css_styles }}
          onLoad={() => setIsLoading(false)}
          imageType={IMAGE_TYPES.NFT}
          onClick={onClick}
        />
      )}
      {tagToUse === "model" && renderedFrom === "GalleryModal" && (
        // display 3d object on gallery modal
        <model-viewer
          camera-controls
          reveal="interaction"
          poster={mediaItem?.poster}
          src={mediaSrc}
          alt={mediaItem?.alt}
          style={{ width: "100%", height: "100%" }}></model-viewer>
      )}
      {tagToUse === "video" && renderedFrom === "GalleryModal" && (
        // display video on gallery modal
        <video
          controls
          className={`media-type--video ${className}`}
          onError={() => {
            setType?.("image broken");
          }}
          onClick={onClick}>
          <source src={mediaSrc} type="video/mp4" />
        </video>
      )}
      {tagToUse === "image" && renderedFrom === "MediaPreview" && (
        <img
          className={`media-type--image ${className ?? ""}`}
          src={mediaSrc}
          alt={mediaItem?.alt}
          style={mediaItem?.css_styles}
          onError={(e) => {
            e.target.src = brokenImageIcon;
            e.target.className += " fallback-image";
          }}
        />
      )}
      {tagToUse === "model" && renderedFrom === "MediaPreview" && (
        // display 3d object poster on feed item
        <div className="media-placeholder">
          <div className="media-placeholder__label">
            <ThreeDRotationIcon fontSize="large" />
          </div>
          {mediaItem?.poster ? (
            <img
              className="media-type--image media-placeholder__image"
              src={mediaItem.poster}
              alt={mediaItem.alt}
            />
          ) : (
            <model-viewer
              camera-controls
              reveal="interaction"
              poster={mediaItem?.poster}
              src={mediaSrc}
              alt={mediaItem?.alt}
              style={{ width: "100%", height: "100%" }}></model-viewer>
          )}
        </div>
      )}
      {tagToUse === "video" && renderedFrom === "MediaPreview" && (
        // display video poster on feed item
        <div className="media-placeholder">
          <div className="media-placeholder__label">
            <PlayCircleFilledIcon fontSize="large" />
          </div>
          {mediaItem?.poster ? (
            <img
              className="media-type--image media-placeholder__image"
              src={mediaItem.poster}
              alt={mediaItem.alt}
              onClick={onClick}
            />
          ) : (
            <video
              className={`media-type--video ${className}`}
              onError={() => {
                setType?.("image broken");
              }}
              onClick={onClick}>
              <source src={mediaSrc} type="video/mp4" />
            </video>
          )}
        </div>
      )}
    </>
  );
};

export default FeedMediaType;
