import searchLoader from "assets/svg/header/search_loader.svg";
import { useSpring, animated } from "react-spring";

const AnimatedSearchLoader = () => {
  const { transform } = useSpring({
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
    config: { duration: 500 },
    loop: true,
  });

  return <animated.img src={searchLoader} style={{ transform }} />;
};

export default AnimatedSearchLoader;
