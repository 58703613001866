import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import classes from "components/FeedItemV2/deletePostDialog.module.css";

const DiscardOrDraftConfirmationDialog = ({
  handleDialogToggle,
  openDialog,
  saveDraft,
  discardCallback = () => {},
}) => {
  const handleDiscardConfirmation = () => {
    handleDialogToggle();
    discardCallback();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogToggle}
      classes={{ paper: classes.deleteDialog }}
      //
      sx={{
        ".MuiPaper-root": {
          background: "var(--base)",
        },
      }}>
      <DialogTitle>
        <div className={classes.dialogTitle}>Save post to draft</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className={classes.dialogContent}>
            Save this post as a draft to access it later?
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <button
          onClick={handleDiscardConfirmation}
          className={`${classes.dialogButton} ${classes.confirmButton}`}>
          Discard
        </button>
        <button
          onClick={() => {
            handleDialogToggle();
            saveDraft();
          }}
          autoFocus
          className={`${classes.dialogButton} ${classes.saveButton}`}>
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscardOrDraftConfirmationDialog;
