import React from "react";
import styles from "./TokenDescription.module.css";
import { CustomColumn } from "components/UI/Components/Components";
import TokenTwitterPill from "components/UI/TokenTwitterPill/TokenTwitterPill";
import ToggleShowMore from "components/ToggleShowMore/ToggleShowMore";
const TokenDescription = ({
  tokenBanner,
  description = "",
  twitterLink = null,
  redFlagCount,
  greenFlagCount,
  symbol,
  type = "token",
  onRedFlagClick,
  onGreenFlagClick,
  isError,
}) => {
  if (type !== "token")
    return (
      <ToggleShowMore
        text={description}
        limit={450}
        type="text"
        className={styles.text}
      />
    );
  return (
    <CustomColumn gap="16px">
      {tokenBanner && (
        <div className={styles.imageContainer}>
          <img
            src={tokenBanner}
            name="tokenBanner"
            className={styles.image}
            alt="tokenBanner"
          />
        </div>
      )}
      <ToggleShowMore
        text={description}
        limit={200}
        type="text"
        className={styles.showMoreText}
        textClassName={styles.text}
      />
      <TokenTwitterPill link={twitterLink} symbol={symbol} />
    </CustomColumn>
  );
};

export default TokenDescription;
