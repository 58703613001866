import { useMemo } from "react";
import { useOwnersInfiniteQuery } from "../collectionsUtils";
import { merge } from "lodash";
import Refetch from "components/UI/Refetch/Refetch";
import { Table, TableCell, TableHeader } from "components/UI/Table/Table";
import OwnerTableRow from "./OwnerTableRow";
import GenericTableLoader from "components/V2/GenericTableLoader/GenericTableLoader";

const OwnersTable = ({ collectionId }) => {
  const { status, refetch, data, isFetchingNextPage, isLoading } =
    useOwnersInfiniteQuery(collectionId);

  const owners = useMemo(
    () => data?.pages.flatMap((page) => page?.owners) ?? [],
    [data]
  );
  const profiles = useMemo(
    () => merge({}, ...(data?.pages.flatMap((page) => page?.profiles) ?? [])),
    [data]
  );

  if (isLoading) return <GenericTableLoader showHeader={false} />;
  if (status === "error")
    return (
      <Refetch
        onClick={() => {
          refetch?.();
        }}
      />
    );
  return (
    <Table>
      <TableHeader noPadding>
        <TableCell alignLeft>Owner</TableCell>
        <TableCell alignRight>Owned</TableCell>
        <TableCell alignRight>Status</TableCell>
      </TableHeader>

      {owners?.map((item, index) => (
        <OwnerTableRow
          owner={item}
          profiles={profiles}
          key={`owner-${index}`}
        />
      ))}
      {isFetchingNextPage && <GenericTableLoader showHeader={false} />}
    </Table>
  );
};

export default OwnersTable;
