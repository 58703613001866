export const titleCase = (str) => {
  if (!str || typeof str !== "string") return "";

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getFeedDescriptionWithLengthLimit = (description, limit) => {
  // limit is the number of characters to be displayed
  if (!description || typeof description !== "string") return "";
  //get initial characters upto limit
  //if it has }} missing and has {{ then extend limit upto these curly braces;
  //there can be multiple {{}} pairs so look for ending curly braces only

  let descriptionWithLimit = description.substring(0, limit);
  if (!descriptionWithLimit.includes("{{")) return descriptionWithLimit;
  let lastIndexOpen = descriptionWithLimit.lastIndexOf("{{");
  let lastIndexClose = descriptionWithLimit.lastIndexOf("}}");
  if (lastIndexClose > lastIndexOpen) {
    return descriptionWithLimit;
  }
  //}} are missing extend limit upto next }}
  //get substring after lastIndexOpen till next }}
  let nextIndexClose = description.indexOf("}}", lastIndexOpen);

  const result = description.substring(0, nextIndexClose + 2);

  if (description.length > limit) {
    return result.trim() + "...";
  }
  //return substring upto this nextIndexClose
  return result;
};

export const conditionallyTrimDescription = (
  description,
  condition,
  trimLimit
) => {
  if (condition && trimLimit) {
    return getFeedDescriptionWithLengthLimit(description, trimLimit);
  }
  return description;
};
