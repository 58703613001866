import classes from "./ProtocolHoldings.module.css";
import Header from "./Header";
import Footer from "./Footer";

import useFetchProtocolHoldings from "customHooks/fetch/useFetchProtocolHoldings";
import useFilterSmallProtocolHoldings from "./useFilterSmallProtocolHoldings";
import ProtocolHoldingsRenderer from "./ProtocolHoldingsRenderer";

import useProtocolHoldingsFilter from "./useProtocolHoldingsFilter";
import { PaddingComponent } from "components/UI/Components/Components";
import { useEffect } from "react";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import { useGetProfileData } from "api/profile.api";

const ProtocolHoldings = ({
  identifier,
  forProtocolTreasury,
  activeChainId,
  selectedActiveChains,
  selectedActiveProtocols,
  selectedActiveTokens,
  selectedWalletAddresses,
  setHoldingsTotalUsdValue,
  rightPane = false,
}) => {
  const { data: protocolHoldings, ...protocolHoldingsQuery } =
    useFetchProtocolHoldings({ identifier, forProtocolTreasury });
  const filteredData = useProtocolHoldingsFilter({
    selectedActiveChains,
    protocolHoldings,
    selectedActiveProtocols,
    selectedActiveTokens,
    selectedWalletAddresses,
  });
  const { data: profile } = useGetProfileData({ identifier });
  const isAddressPage = profile?.data?.data?.type !== "identity";

  const {
    filteredProtocolHoldings,
    containsSmallProtocols,
    isSmallProtocolsVisible,
    setIsSmallProtocolsVisible,
    smallTokensCount,
  } = useFilterSmallProtocolHoldings({
    protocolHoldings: filteredData,
    activeChainId,
  });
  const emptyCondition =
    identifier?.length &&
    filteredProtocolHoldings?.length === 0 &&
    !protocolHoldingsQuery.isLoading;
  useEffect(() => {
    setHoldingsTotalUsdValue?.({
      protocol: protocolHoldings?.total_usd_value || {},
    });
  }, [protocolHoldings, setHoldingsTotalUsdValue]);
  return (
    <div className={classes.protocolHoldings}>
      <Header
        totalUsdValue={filteredData?.filteredUsdValue}
        lastUpdatedAt={!rightPane ? protocolHoldings?.last_updated_at : ""}
        isFullyComputed={filteredData?.is_fully_computed}
        refetch={protocolHoldingsQuery.refetch}
        isFetching={protocolHoldingsQuery.isFetching}
      />
      <ProtocolHoldingsRenderer
        filteredProtocolHoldings={filteredProtocolHoldings}
        protocolHoldingsQuery={protocolHoldingsQuery}
        rightPane={rightPane}
        emptyCondition={emptyCondition}
        chainProfiles={protocolHoldings?.chain_profiles}
        isAddressPage={isAddressPage}
      />
      <PaddingComponent height="16px" />
      <Footer
        isSmallTokensVisible={isSmallProtocolsVisible}
        containsSmallTokens={containsSmallProtocols}
        setIsSmallTokensVisible={setIsSmallProtocolsVisible}
        smallTokensCount={smallTokensCount}
        rightPane={rightPane}
      />
    </div>
  );
};

export default withErrorBoundary(ProtocolHoldings);
