import React, { useContext } from "react";
import { getTrendingContractInteractions } from "api/feed.api";
import {
  CustomColumn,
  CustomRow,
  Divider,
} from "components/UI/Components/Components";
import { CaretRight } from "@phosphor-icons/react";
import styles from "./TrendingContracts.module.scss";
import TokenHoverCard from "components/FeedItemV2/TokenHover/TokenHoverCard";
import ConditionalLink from "shared/ConditionalLink";
import ZxHorizontalScrollWrapper from "zxComponents/common/ZxHorizontalScrollWrapper/ZxHorizontalScrollWrapper";
import ZxCard from "zxComponents/common/ZxCard/ZxCard";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import ZxImageStack from "zxComponents/common/ZxImageStack/ZxImageStack";
import { getTrimmedString } from "utils/misc";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "utils/query_utils";
import { AuthContext } from "contextStates/AuthContext";
import { getUniqueId } from "utils/event_tracking";
import {
  HorizontalScrollButtons,
  horizontalScrollContainerClassName,
} from "components/Feed/HorizontalScrollButtons";
import { FEED_CARDS } from "../ZxFeed.utils";
import withNullableError from "zxComponents/common/withNullableError/index";
import { ThemeContext } from "contextStates/Theme.context";
import { getDefaultImage, IMAGE_TYPES } from "components/UI/Image";

const GRADIENT_STRING = "linear-gradient(180deg, #D9EBFF 0%, #92BFF5 100%)";
const HEADER_IMAGE = "/feed/contract_header.svg";
const CARD_WIDTH = 280;
const CARD_HEIGHT = 115;
const IMAGE_COUNT = 3;

export const TrendingContracts = () => {
  const { identityDetails } = useContext(AuthContext);
  const identifier = identityDetails?.current?.identity?.id;
  const { data, isLoading, isError } = useQuery({
    queryKey: QUERY_KEYS.FEED_TRENDING_CONTRACTS({ identifier }),
    queryFn: getTrendingContractInteractions,
    staleTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const elRef = React.useRef();
  const { theme } = useContext(ThemeContext);
  if (isError || (data && data.items && data.items.length === 0)) {
    return null;
  }
  const isReady = !isLoading && data && data.items;
  return (
    <ZxHorizontalScrollWrapper
      meta={FEED_CARDS.CONTRACTS}
      isLoading={isLoading}
      gradientString={GRADIENT_STRING}
      itemCount={data?.items?.length ?? 0}
      headerImage={HEADER_IMAGE}
      itemWidth={CARD_WIDTH}
      itemHeight={CARD_HEIGHT}
      title="Trending in your network">
      <div
        className={`${styles.container} ${horizontalScrollContainerClassName}`}
        ref={elRef}>
        {isReady &&
          data.items.map((contract) => {
            const { display_name, display_picture, address_chain, link } =
              contract.profile;
            const pictures = contract.interactions
              .slice(0, IMAGE_COUNT)
              .map((u) => {
                return {
                  src: u.actor.display_picture,
                  name: u.actor.display_name,
                  alt: u.actor.display_name,
                };
              });
            return (
              <ZxCard
                className={styles.card}
                background={
                  theme === "dark"
                    ? FEED_CARDS.CONTRACTS.darkModebackground
                    : "var(--base)"
                }
                key={getUniqueId()}
                minWidth={`${CARD_WIDTH}px`}
                cursor="pointer">
                <ConditionalLink to={link.replace("/c/", "/t/")}>
                  <CustomColumn gap="12px">
                    <CustomRow
                      gap="8px"
                      alignItems="center"
                      className={styles.header}
                      justifyContent="space-between">
                      <CustomRow
                        alignItems="center"
                        gap="8px"
                        className={styles.tokenInfo}>
                        <IconWithChain
                          src={
                            display_picture ??
                            getDefaultImage({ imageType: IMAGE_TYPES.TOKEN })
                          }
                          name={display_name}
                          iconWidth="32px"
                          iconHeight="32px"
                          showImageFullHeightWidth
                          chain={address_chain}
                          chainHeight="14px"
                          chainWidth="14px"
                          chainBorderWidth="2px"
                        />
                        <TokenHoverCard tokenProfile={contract.profile}>
                          <ZxText style={TSTYLES.title2} cursor="pointer">
                            {getTrimmedString(display_name, 15)}
                          </ZxText>
                        </TokenHoverCard>
                      </CustomRow>
                      <CaretRight size={16} color="var(--text-2)" />
                    </CustomRow>
                    <Divider />
                    <CustomRow gap="8px" alignItems="center">
                      <ZxImageStack pictures={pictures} />
                      <ZxText style={TSTYLES.secondary1}>
                        {contract.summary}
                      </ZxText>
                    </CustomRow>
                  </CustomColumn>
                </ConditionalLink>
              </ZxCard>
            );
          })}
      </div>
      {isReady && data?.items?.length > 2 && (
        <HorizontalScrollButtons wrapperEl={elRef} postWidth={CARD_WIDTH} />
      )}
    </ZxHorizontalScrollWrapper>
  );
};

export default withNullableError(TrendingContracts);
