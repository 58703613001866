import Image, { IMAGE_TYPES } from "components/UI/Image";
import { CustomTextSpan, CustomRow } from "components/UI/Components/Components";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import classes from "./ProfileRenderer.module.css";
import ConditionalLink from "shared/ConditionalLink";
import Copyable from "components/ValueType/Copyable/Copyable";

const ProfileRenderer = ({ profile, copyable, showCopyableOnHover = true }) => {
  const baseComponent = (
    <ProfileHoverCard actor={profile}>
      <ConditionalLink to={profile?.link}>
        <CustomRow gap="6px">
          <Image
            imageType={
              profile?.is_token ? IMAGE_TYPES.TOKEN : IMAGE_TYPES.AVATAR
            }
            className={classes.profileIcon}
            src={profile?.display_picture}
            name={profile?.display_name}
          />
          <CustomTextSpan fontWeight="500" fontSize="13px">
            {profile?.display_name}
          </CustomTextSpan>
        </CustomRow>
      </ConditionalLink>
    </ProfileHoverCard>
  );
  switch (copyable) {
    case "address":
      return (
        <Copyable
          content={profile?.address}
          title="Copy Address"
          showOnHover={showCopyableOnHover}>
          {baseComponent}
        </Copyable>
      );
    case "link":
      return (
        <Copyable
          content={profile?.link}
          title="Copy Profile link"
          showOnHover={showCopyableOnHover}>
          {baseComponent}
        </Copyable>
      );
    default:
      return baseComponent;
  }
};

export default ProfileRenderer;
