import { signedApiRequest } from "api/api";
import { GlobalContext } from "contextStates/Global";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./BundleSuggestion.module.css";
import { AuthContext } from "contextStates/AuthContext";
import { CustomCircularProgress } from "components/UI/Components/Components";
const BundleButton = ({
  identityId,
  address,
  onSuccess,
  className = "",
  setIsUpdate,
  triggerBundle,
}) => {
  const [bundling, setBundling] = useState(false);
  const { handleErrorSnackbar, setSnackBarState } = useContext(GlobalContext);
  const { isUserLoggedIn, setShowLoginPopup } = useContext(AuthContext);
  const isTriggered = useRef(false);

  const onBundle = useCallback(() => {
    if (!isUserLoggedIn) {
      setShowLoginPopup(true);
      return;
    }
    if (setIsUpdate) {
      setIsUpdate(true);
    }
    setBundling(true);
    const data = {
      identity_id: identityId,
      add_addresses: [
        {
          reason: "",
          address,
        },
      ],
    };

    signedApiRequest({
      method: "post",
      path: "add_remove_addresses",
      bodyText: JSON.stringify(data),
    })
      .then((data) => {
        onSuccess(data);

        setSnackBarState({
          open: true,
          message: "Successfully Bundled",
          type: "success",
        });
      })
      .catch((error) => {
        setBundling(false);
        handleErrorSnackbar(error, "Failed to Bundle");
      });
  }, [
    address,
    handleErrorSnackbar,
    identityId,
    isUserLoggedIn,
    onSuccess,
    setIsUpdate,
    setShowLoginPopup,
    setSnackBarState,
  ]);

  useEffect(() => {
    if (triggerBundle && !isTriggered.current) {
      onBundle();
      isTriggered.current = true;
    }
  }, [onBundle, triggerBundle]);

  if (identityId == null) {
    // Cannot bundle addresses with addresses today
    return null;
  }

  if (bundling) return <CustomCircularProgress />;
  return (
    <button
      className={`${styles.bundle} ${className}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onBundle();
      }}
      disabled={bundling}>
      Bundle
    </button>
  );
};

export default BundleButton;
