import {
  PaddingComponent,
  Divider,
  CustomColumn,
  CustomRow,
  CustomText,
  FlexGrow,
  CursorDiv,
  SpinnerComponent,
  LoadingHoverCard,
} from "components/UI/Components/Components";
import React, { useContext, useMemo, useCallback } from "react";
import CustomSwitch from "components/UI/Components/CustomSwitch";
import pplIcon from "assets/svg/Landing/logo.svg";
import twitterIcon from "assets/svg/twitter.svg";
import farcasterIcon from "assets/svg/farcaster.svg";
import lensIcon from "assets/svg/lens.svg";
import classes from "../Components/NewPost.module.css";
import { connectTwitter } from "./connect_twitter_utils";
import { GlobalContext } from "contextStates/Global";
import { signWithWalletForLens } from "./connect_lens_utils";

const CrossPostDialog = ({
  connectedPlatforms,
  onConnectFarcaster,
  isFarcasterConnectLoading,
  isLoading,
  setIsTwitterConnectLoading,
  isTwitterConnectLoading,
  refetch,
  isTwitterEnabled,
  isFarcasterEnabled,
  setIsTwitterEnabled,
  setIsFarcasterEnabled,
  isLensEnabled,
  setIsLensEnabled,
  isLensConnectLoading,
  setIsLensConnectLoading,
  onConnectLens,
  setAllPlatforms,
}) => {
  const isTwitterConnected = connectedPlatforms.includes("twitter");
  const isFarcasterConnected = connectedPlatforms.includes("farcaster");
  const isLensConnected = connectedPlatforms.includes("lens");
  const { featureFlags } = useContext(GlobalContext);
  const isLensFeatureEnabled = featureFlags["is_lens_cross_post_enabled"];

  const isAllConnected = useMemo(() => {
    return (
      (!isTwitterConnected || isTwitterEnabled) &&
      (!isFarcasterConnected || isFarcasterEnabled) &&
      (isLensFeatureEnabled ? !isLensConnected || isLensEnabled : true)
    );
  }, [
    isTwitterConnected,
    isTwitterEnabled,
    isFarcasterConnected,
    isFarcasterEnabled,
    isLensConnected,
    isLensEnabled,
    isLensFeatureEnabled,
  ]);
  const setIsAllConnected = useCallback(
    (value) => {
      // if value is function, then it is a callback
      // (imitate useState)
      if (typeof value === "function") {
        value = value(isAllConnected);
      }
      const platforms = {
        farcaster: isFarcasterEnabled,
        twitter: isTwitterEnabled,
        lens: isLensEnabled,
      };
      if (isTwitterConnected) {
        platforms.twitter = value;
      }
      if (isFarcasterConnected) {
        platforms.farcaster = value;
      }
      if (isLensConnected) {
        platforms.lens = value;
      }
      setAllPlatforms(platforms);
    },
    [
      isAllConnected,
      setAllPlatforms,
      isTwitterConnected,
      isLensEnabled,
      isTwitterEnabled,
      isFarcasterEnabled,
      isFarcasterConnected,
      isLensConnected,
    ]
  );

  if (isLoading) {
    return <LoadingHoverCard minWidth={240} />;
  }
  return (
    <PaddingComponent padding="12px">
      <CustomColumn gap="12px">
        <AllConnectedPlatforms
          onChange={setIsAllConnected}
          isEnabled={isAllConnected}
        />
        <Divider />
        <ConnectedPlatformsList
          isTwitterConnected={isTwitterConnected}
          isFarcasterConnected={isFarcasterConnected}
          isLensConnected={isLensConnected}
          isTwitterEnabled={isTwitterEnabled}
          isFarcasterEnabled={isFarcasterEnabled}
          isLensEnabled={isLensEnabled}
          setIsTwitterEnabled={setIsTwitterEnabled}
          setIsFarcasterEnabled={setIsFarcasterEnabled}
          setIsLensEnabled={setIsLensEnabled}
          isLensFeatureEnabled={isLensFeatureEnabled}
        />
        {(!isTwitterConnected || !isFarcasterConnected) && <Divider />}
        {(!isTwitterConnected || !isFarcasterConnected || !isLensConnected) && (
          <ConnectPlatformsList
            refetch={refetch}
            onConnectFarcaster={onConnectFarcaster}
            isFarcasterConnectLoading={isFarcasterConnectLoading}
            isTwitterConnected={isTwitterConnected}
            isFarcasterConnected={isFarcasterConnected}
            isTwitterConnectLoading={isTwitterConnectLoading}
            setIsTwitterConnectLoading={setIsTwitterConnectLoading}
            isLensConnected={isLensConnected}
            isLensConnectLoading={isLensConnectLoading}
            setIsLensConnectLoading={setIsLensConnectLoading}
            onConnectLens={onConnectLens}
            isLensFeatureEnabled={isLensFeatureEnabled}
          />
        )}
      </CustomColumn>
    </PaddingComponent>
  );
};

const ConnectedPlatformsList = ({
  isTwitterConnected,
  isFarcasterConnected,
  isLensConnected,
  isTwitterEnabled,
  isFarcasterEnabled,
  isLensEnabled,
  setIsTwitterEnabled,
  setIsFarcasterEnabled,
  setIsLensEnabled,
  isLensFeatureEnabled,
}) => {
  return (
    <CustomColumn gap="12px">
      <ConnectedTile
        icon={pplIcon}
        toggle={true}
        text="0xPPL"
        isSwitchDisabled={true}
      />
      {isTwitterConnected && (
        <ConnectedTile
          key="twitter"
          icon={twitterIcon}
          text="Twitter"
          toggle={isTwitterEnabled}
          setToggle={setIsTwitterEnabled}
        />
      )}
      {isFarcasterConnected && (
        <ConnectedTile
          key="farcaster"
          icon={farcasterIcon}
          text="Farcaster"
          toggle={isFarcasterEnabled}
          setToggle={setIsFarcasterEnabled}
        />
      )}
      {isLensConnected && isLensFeatureEnabled && (
        <ConnectedTile
          key="lens"
          icon={lensIcon}
          text="Lens"
          toggle={isLensEnabled}
          setToggle={setIsLensEnabled}
        />
      )}
    </CustomColumn>
  );
};

const ConnectPlatformsList = ({
  isTwitterConnected,
  isFarcasterConnected,
  isLensConnected,
  onConnectFarcaster,
  onConnectLens,
  isFarcasterConnectLoading,
  isLensConnectLoading,
  setIsLensConnectLoading,
  setIsTwitterConnectLoading,
  isTwitterConnectLoading,
  isLensFeatureEnabled,
  refetch,
}) => {
  const { handleErrorSnackbar } = useContext(GlobalContext);
  return (
    <CustomColumn gap="12px">
      {!isTwitterConnected && (
        <ConnectPlatformTile
          key="twitter"
          icon={twitterIcon}
          text="Twitter"
          isLoading={isTwitterConnectLoading}
          onClick={() => {
            connectTwitter({
              setIsTwitterConnectLoading: setIsTwitterConnectLoading,
              onSuccess: () => {
                setIsTwitterConnectLoading(false);
                refetch();
              },
              onError: () => {
                handleErrorSnackbar?.(null, "Failed to connect to Twitter");
                setIsTwitterConnectLoading(false);
              },
            });
          }}
        />
      )}
      {!isFarcasterConnected && (
        <ConnectPlatformTile
          key="farcaster"
          isLoading={isFarcasterConnectLoading}
          icon={farcasterIcon}
          text="Farcaster"
          onClick={() => {
            onConnectFarcaster();
          }}
        />
      )}
      {!isLensConnected && isLensFeatureEnabled && (
        <ConnectPlatformTile
          key="lens"
          isLoading={isLensConnectLoading}
          icon={lensIcon}
          text="Lens"
          onClick={async () => {
            setIsLensConnectLoading(true);
            const { data, error } = await signWithWalletForLens();
            if (error) {
              handleErrorSnackbar?.(null, "Failed to connect to Lens");
              setIsLensConnectLoading(false);
              return;
            }
            const { accessToken, refreshToken } = data;
            onConnectLens({
              accessToken,
              refreshToken,
            });
            setIsLensConnectLoading(false);
          }}
        />
      )}
    </CustomColumn>
  );
};

const AllConnectedPlatforms = ({ onChange, isEnabled }) => {
  return (
    <CustomRow>
      <CustomText
        text="All connected platforms"
        fontSize="13px"
        color="var(--text-1)"
        lineHeight="160%"
        letterSpacing="-0.24px"
      />
      <FlexGrow />
      <CustomSwitch
        toggle={isEnabled}
        setToggle={onChange}
        isPrimaryColored={true}
      />
    </CustomRow>
  );
};

const ConnectedTile = ({ icon, text, setToggle, isSwitchDisabled, toggle }) => {
  return (
    <CustomRow>
      <img src={icon} className={classes.postOnImage} alt="ppl" />
      <PaddingComponent width="8px" />
      <CustomText
        text={text}
        fontSize="13px"
        color="var(--text-1)"
        lineHeight="160%"
        letterSpacing="-0.24px"
      />
      <FlexGrow />
      <CustomSwitch
        key={text}
        isPrimaryColored={true}
        toggle={toggle}
        setToggle={setToggle}
        isDisabled={isSwitchDisabled}
      />
    </CustomRow>
  );
};

const ConnectPlatformTile = ({ icon, text, onClick, isLoading }) => {
  return (
    <CustomRow>
      <img src={icon} className={classes.postOnImage} alt="ppl" />
      <PaddingComponent width="8px" />
      <CustomText
        text={text}
        fontSize="13px"
        color="var(--text-1)"
        lineHeight="160%"
        letterSpacing="-0.24px"
      />
      <FlexGrow />
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <CursorDiv>
          <CustomText
            onClick={onClick}
            text="Connect"
            fontWeight="600"
            color="var(--primary-color)"
            fontSize="13px"
          />
        </CursorDiv>
      )}
    </CustomRow>
  );
};

export default CrossPostDialog;
