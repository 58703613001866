import React, { useMemo } from "react";
import classes from "./feedItemV2.module.css";
import commentIcon from "assets/svg/comment.svg";
import likeIcon from "assets/svg/heart.svg";
import shareIcon from "assets/svg/share-icon.svg";
import PostContentWrapper from "./PostContentWrapper";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";

import { useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { getGroupedActivityFromBlob } from "api/feed.api";
import { ONE_DAY, QueryKeyConstants } from "utils/constants";
import { Lightning, XLogo, Users } from "@phosphor-icons/react";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import ConditionalLink from "shared/ConditionalLink";
import { CustomColumn, Divider } from "components/UI/Components/Components";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { handleFeedItemClick } from "utils/misc";

const attributions = {
  FEATURED: "featured",
  INVITED: "invited",
  LIKE: "like",
  TWITTER: "twitter",
  REPLY: "reply",
};

const FeedPost = ({
  post,
  profiles,
  identities,
  nested = false,
  hideEngagements,
  clickable = false,
  showFullDateTime = false,
  solidBg = false,
  showSettings = true,
  showReplies = false,
  showViewPost = false,
  noRedirection,
  hideNftTransfers = false,
  noMargin = false,
  showThreadLine = false,
  trimDescription,
  trimLimit,
  hideRepost = false,
  blockInteraction = false,
  smallThumbnail = false,
  isHomePageFeedItem = false,
  isRepost = false,
  isParent = false,
  hasParent = false,
  showComments = undefined,
  isProfilePage = false,
  noPadding = false,
}) => {
  const { data: postData } = useQuery({
    queryFn: () => {
      console.log("Feedpost group activity");
      return getGroupedActivityFromBlob({
        id: post.id,
        type: post.type,
      });
    },
    queryKey: [
      QueryKeyConstants.GROUPED_ACTIVITY_FROM_BLOB,
      post.id.toString(),
      post.type,
    ],
    retry: 2,
    enabled: false,
    initialData: post,
    staleTime: ONE_DAY,
  });
  profiles = profiles ?? postData.profiles;
  identities = postData.identities ?? identities;
  const history = useHistory();
  const actor = useMemo(() => {
    if (identities && identities[postData.post?.creator_identity] != null) {
      return identities[postData.post?.creator_identity];
    } else if (identities && identities[postData.actor?.id] != null) {
      return identities[postData.actor?.id];
    } else if (profiles && profiles[postData.actor?.address] != null) {
      return profiles[postData.actor?.address];
    } else {
      return postData.actor;
    }
  }, [identities, profiles, postData?.post?.creator_identity, postData.actor]);
  const postReplies = postData?.engagements?.replies;
  const slicedThreads = postReplies?.threads?.slice(0, 1);
  return (
    <div
      className={`${classes.groupedActivity} ${
        (!!isHomePageFeedItem && classes.homePageGroupedActivity) || ""
      }  ${(!!nested && classes.nested) || ""}
       ${(!!isParent && classes.isParent) || ""}
       ${(!!noPadding && classes.noPadding) || ""}
       ${(!!hasParent && isHomePageFeedItem && classes.hasParent) || ""}  ${(!!noMargin && classes.noMargin) || ""}
        ${(!!blockInteraction && classes.blockInteraction) || ""}
        ${isProfilePage ? classes.profilePageItem : ""}
      `}
      style={{ cursor: clickable ? "pointer" : "default" }}
      onClick={(e) => {
        if (clickable) {
          handleFeedItemClick({
            contentId: postData.id,
            contentType: postData.type,
            link: postData.link,
            history,
            event: e,
            clickable: true,
            noRedirection,
          });
        }
      }}>
      <ActivityInteractionContext
        actorName={actor?.display_name}
        type={post?.interaction?.type}
      />
      {!!isHomePageFeedItem && (
        <PostAttribution postData={postData} identities={identities} />
      )}
      <PostContentWrapper
        hasReplies={
          showReplies && slicedThreads?.length > 0 && postReplies?.count
        }
        description={postData?.post?.contents}
        identities={identities}
        profiles={profiles}
        post={postData}
        actor={actor}
        hideStats={true}
        hideEngagements={hideEngagements}
        showFullDateTime={showFullDateTime}
        hideNftTransfers={hideNftTransfers}
        showSettings={showSettings}
        showViewPost={showViewPost}
        showThreadLine={showThreadLine}
        trimDescription={trimDescription}
        trimLimit={trimLimit}
        hideRepost={hideRepost}
        smallThumbnail={smallThumbnail}
        isRepost={isRepost}
        showComments={true}
        isPostDetails={!isHomePageFeedItem}
      />
    </div>
  );
};

const getIdentityDetails = (inputString, identities) => {
  const identityPattern = /\{\{identity\|\|(\d+)\}\}/;
  const matchResult = inputString.match(identityPattern);
  if (matchResult && matchResult[1]) {
    const identityId = matchResult[1];
    const identityDetails = identities[identityId];
    return identityDetails;
  } else {
    return null;
  }
};

export const PostAttribution = ({ postData, identities }) => {
  const isAttributionAvailable =
    postData?.attribution_category && postData?.attribution_category !== "";
  const RenderAttribution = ({ category, attributionIdentity }) => {
    switch (category) {
      case attributions.FEATURED:
        return (
          <div className={classes.attribution_featured}>
            <Lightning size={16} color="#079d39" weight="duotone" />{" "}
            <span className={classes.attribution_text}>Featured</span>
          </div>
        );
      case attributions.LIKE:
      case attributions.REPLY:
        return (
          <div className={classes.attribution_liked}>
            <ProfileHoverCard actor={attributionIdentity}>
              <ConditionalLink
                className={classes.actorInfoLink}
                to={
                  attributionIdentity?.identity_link ||
                  attributionIdentity?.link
                }
                target="_blank"
                rel="noreferrer">
                <Image
                  src={attributionIdentity?.display_picture}
                  imageType={IMAGE_TYPES.AVATAR}
                  name={attributionIdentity?.display_name}
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "24px",
                  }}
                />
                {attributionIdentity?.display_name}
              </ConditionalLink>
            </ProfileHoverCard>
            <ZxText style={TSTYLES.secondary1}>
              &nbsp;
              {category === attributions.LIKE
                ? "liked this"
                : "commented on this"}{" "}
            </ZxText>
          </div>
        );
      case attributions.TWITTER:
        return (
          <div className={classes.attribution_twitter}>
            <XLogo size={16} color={"var(--text-1)"} weight="duotone" />{" "}
            <span className={classes.attribution_text}>
              From your twitter network
            </span>
          </div>
        );
      case attributions.INVITED:
        return (
          <div className={classes.attribution_invited}>
            {" "}
            <Users size={16} color="#0648D7" weight="duotone" />{" "}
            <span className={classes.attribution_text}>Invited by</span>
            <ProfileHoverCard actor={attributionIdentity}>
              <ConditionalLink
                className={classes.actorInfoLink}
                to={
                  attributionIdentity?.identity_link ||
                  attributionIdentity?.link
                }
                target="_blank"
                rel="noreferrer">
                {attributionIdentity?.display_name}
              </ConditionalLink>
            </ProfileHoverCard>
          </div>
        );
      default:
        return <></>;
    }
  };

  if (isAttributionAvailable) {
    const attributionIdentity = getIdentityDetails(
      postData?.attribution,
      identities
    );
    return (
      <CustomColumn>
        <RenderAttribution
          category={postData?.attribution_category}
          attributionIdentity={attributionIdentity}
        />
        <Divider margin="12px 0" />
      </CustomColumn>
    );
  }

  return null;
};

const ActivityInteractionContext = ({ type, actorName }) => {
  if (!type || !actorName) {
    return null;
  }

  let label = "",
    icon = "";
  switch (type) {
    case "comment":
      label = `${actorName} commented on this`;
      icon = commentIcon;
      break;
    case "share":
      label = `Re-posted by  ${actorName}`;
      icon = shareIcon;
      break;
    case "like":
      label = `Liked by ${actorName}`;
      icon = likeIcon;
      break;
    default:
      break;
  }
  return (
    <div className={classes.interactionContext}>
      <div className={classes.icon}>
        <img src={icon} alt="interaction icon" />
      </div>
      <div className={classes.label}>{label}</div>
    </div>
  );
};

export default withErrorBoundary(FeedPost, classes.groupedActivity);
