import buttonClasses from "components/UI/Buttons/buttons.module.css";
import formClasses from "components/UI/PopUpForm/PopUpForm.module.css";
import { useState, useContext, useMemo } from "react";
import classes from "./Filters.module.css";
import CustomLinkTabBar, {
  SIZE,
} from "components/UI/Components/CustomLinkTabBar";
import ChainFilterPopUp from "./ChainFilterPopUp";
import HoldingsTokenFiltersList from "./HoldingsTokenFiltersList";
import { ProfileContext } from "contextStates/ProfileContext";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { transformFilterParams, updateQueryParams } from "utils/misc";
import { produce } from "../../../../../../node_modules/immer/dist/immer";
import { getHoldingsWithoutNativeTokens } from "utils/profile_utils";
import useTokenHoldings from "../Portfolio/TokenHoldings/useTokenHoldings";

const HoldingsFilterPopUp = ({ identifier, setShowPopUpCard }) => {
  const location = useLocation();
  const history = useHistory();
  const { active_tab: activerTab } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const {
    setSelectedChains,
    selectedActiveChains,
    selectedActiveTokens,
    setSelectedTokens,
    selectedActiveProtocols,
  } = useContext(ProfileContext);
  const [chainsSelected, setChainsSelected] = useState(
    selectedActiveChains.current
  );
  const [protocolsSelected, setProtocolsSelected] = useState(
    selectedActiveProtocols.current
  );
  const [tokensSelected, setTokensSelected] = useState(
    selectedActiveTokens.current
  );
  var { data: tokenHoldingsData } = useTokenHoldings({
    identifier,
    forProtocolTreasury: false,
  });

  const modifiedHoldings = useMemo(
    () =>
      produce(tokenHoldingsData, (draft) => {
        if (activerTab === "activities" && draft?.holdings) {
          getHoldingsWithoutNativeTokens(draft);
        }
      }),
    [activerTab, tokenHoldingsData]
  );
  const hasNonSolanaTokens = (modifiedHoldings?.holdings ?? [])?.some(
    (t) => t.chain_id !== "Solana"
  );
  const onApply = () => {
    const activeTokenChains = tokensSelected.map((t) => t.chain_id);
    const finalChains = Array.from(
      new Set([...activeTokenChains, ...chainsSelected])
    );
    setSelectedChains(finalChains);
    setSelectedTokens(tokensSelected, protocolsSelected);

    updateQueryParams({
      updates: {
        chains: finalChains,
        tokens: transformFilterParams(tokensSelected),
        protocols: transformFilterParams(protocolsSelected),
      },
      location,
      history,
    });
    setShowPopUpCard(false);
  };

  return (
    <div className={formClasses.popup_form}>
      <div className={classes.formPadding}>
        <div className={classes.headerRow}>
          <span className={classes.headerByText}> By: </span>
          <CustomLinkTabBar
            isLinkTabBar={false}
            isEnabled={true}
            size={SIZE.SMALL}
            tabsList={hasNonSolanaTokens ? ["Chains", "Tokens"] : ["Chains"]}
            activeTab={activeTab}
            isBackdropFilter={false}
            setActiveTab={setActiveTab}
          />
        </div>
        {activeTab === 0 && (
          <ChainFilterPopUp
            identifier={identifier}
            setChainsSelected={setChainsSelected}
            chainsSelected={chainsSelected}
          />
        )}
        {activeTab === 1 && (
          <HoldingsTokenFiltersList
            identifier={identifier}
            protocolsSelected={protocolsSelected}
            setProtocolsSelected={setProtocolsSelected}
            setTokensSelected={setTokensSelected}
            tokensSelected={tokensSelected}
          />
        )}
      </div>
      <div className={formClasses.divider}></div>
      <div className={classes.buttonPadding}>
        <button className={buttonClasses.popupButton} onClick={onApply}>
          <div>Apply</div>
        </button>
      </div>
    </div>
  );
};

export default HoldingsFilterPopUp;
