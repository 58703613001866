import React, { useCallback, useState } from "react";
import { InteractionButton } from "./ActivityEngagements";
import NewRepostModal from "./NewRepostModal";

const RepostButton = ({
  className,
  icon,
  contentId,
  count,
  contentType,
  contentTimestamp,
  needsLogin,
  postAncestors,
  hideCount = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <InteractionButton
        className={className}
        handleClick={handleClick}
        icon={icon}
        count={count}
        needsLogin={needsLogin}
        hideCount={hideCount}
      />

      <NewRepostModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        contentId={contentId}
        contentType={contentType}
        contentTimestamp={contentTimestamp}
        postAncestors={postAncestors}
      />
    </div>
  );
};

export default RepostButton;
