import classes from "../ScreenerRightPanel.module.css";
import {
  PaddingComponent,
  CustomColumn,
  CustomRow,
  FlexGrow,
} from "components/UI/Components/Components";
import InputColumnTile from "./InputColumnTile";

const InputColumns = ({
  inputColumnsList,
  setInputColumnsList,
  updateInputColumnsState,
}) => {
  return (
    <CustomColumn>
      <PaddingComponent paddingBottom="16px">
        <CustomRow alignItems="center" gap="16px">
          <span className={classes.header}>ADVANCED</span>
          <FlexGrow>
            <div className={classes.centerDivider} />
          </FlexGrow>
        </CustomRow>
      </PaddingComponent>
      {inputColumnsList.map((inputColumn, index) => {
        return (
          <InputColumnTile
            updateInputColumnsState={updateInputColumnsState}
            key={`filters-${index}`}
            index={index}
            inputColumnsList={inputColumnsList}
            setInputColumnsList={(val) => {
              setInputColumnsList(val);
              updateInputColumnsState();
            }}
            inputColumn={inputColumn}
          />
        );
      })}
      <PaddingComponent height="42px" />
    </CustomColumn>
  );
};

export default InputColumns;
