import {
  CursorDiv,
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  FlexGrow,
  InfoBox,
  PaddingComponent,
} from "components/UI/Components/Components";
import { CaretRight, RocketLaunch } from "@phosphor-icons/react";
import ProgressCircle from "components/UI/Components/ProgressCircle";
import classes from "./leftSideBar.module.css";
import GettingStartedPopUp from "./UserJourney/GettingStartedPopUp";
import { useState, useContext, useMemo } from "react";
import { AuthContext } from "contextStates/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { getUserProfileOboardingStatus } from "api/profile.api";
import { DownloadAppModal } from "components/V2/Onboarding/components/AppDownloadButton";
import { trackEvent } from "utils/event_tracking";

const UserJourney = ({ className }) => {
  const { identityDetails } = useContext(AuthContext);
  const [showGettingStartedPopUp, setShowGettingStartedPopUp] = useState(false);
  const identifier = identityDetails?.current?.identity?.id;
  const [isDownloadAppPopUpOpen, setIsDownloadAppPopUpOpen] = useState(false);

  const showDownloadAppPopUp = () => {
    setIsDownloadAppPopUpOpen(true);
  };
  const onDownloadAppPopUpClose = () => {
    setIsDownloadAppPopUpOpen(false);
  };

  const userProfileOnboardingStatus = useQuery({
    queryKey: ["user_profile_onboarding_status", identifier],
    queryFn: getUserProfileOboardingStatus,
    enabled: identifier !== undefined,
    retry: 1,
    retryOnMount: false,
    staleTime: 1000 * 60 * 5,
  });

  const { completedSteps, totalSteps } = useMemo(() => {
    let count = 0,
      total = 0;
    for (let step in userProfileOnboardingStatus) {
      if (
        [
          "has_added_bio",
          "has_cross_posted",
          "has_downloaded_mobile_app",
          "has_linked_wallets",
          "is_telegram_connected",
          "num_profiles_followed",
          "is_invite_code_used",
          "has_shared_private_wallets",
        ].includes(step)
      ) {
        total++;
        if (userProfileOnboardingStatus[step] === true) count++;
        if (
          step === "num_profiles_followed" &&
          userProfileOnboardingStatus[step] >= 10
        )
          count++;
      }
    }
    return { completedSteps: count, totalSteps: total };
  }, [userProfileOnboardingStatus]);

  if (completedSteps === totalSteps) return null;

  return (
    <>
      <CursorDiv
        onClick={() => {
          trackEvent("kickstart_journey_card");
          setShowGettingStartedPopUp(true);
        }}>
        <CustomColumn gap="12px" className={className}>
          <Divider />
          <Header
            userProfileOnboardingStatus={userProfileOnboardingStatus}
            showGettingStartedPopUp={showGettingStartedPopUp}
            setShowGettingStartedPopUp={setShowGettingStartedPopUp}
            completedSteps={completedSteps}
            totalSteps={totalSteps}
          />
          <Divider />
          <Content
            identity={identityDetails?.current?.identity}
            completedSteps={completedSteps}
            totalSteps={totalSteps}
          />
        </CustomColumn>
      </CursorDiv>
      {showGettingStartedPopUp && (
        <GettingStartedPopUp
          setShowGettingStartedPopUp={setShowGettingStartedPopUp}
          userProfileOnboardingStatus={userProfileOnboardingStatus}
          completedSteps={completedSteps}
          totalSteps={totalSteps}
          showDownloadAppPopUp={showDownloadAppPopUp}
        />
      )}
      <DownloadAppModal
        onClose={onDownloadAppPopUpClose}
        isDownloadAppPopUpOpen={isDownloadAppPopUpOpen}
      />
    </>
  );
};

const Header = () => {
  return (
    <PaddingComponent padding="0">
      <CustomRow gap="4px" alignItems="center">
        <RocketLaunch size={18} color="var(--primary-color)" weight="duotone" />
        <CustomText
          text="Kickstart your journey"
          fontSize="16px"
          fontWeight="600"
          color="var(--text-1)"
        />
        <FlexGrow />
        <CaretRight size={16} color="var(--text-2)" />
      </CustomRow>
    </PaddingComponent>
  );
};

const Content = ({ identity, completedSteps, totalSteps }) => {
  return (
    <CustomColumn>
      <CustomRow gap="12px" alignItems="flex-start">
        <FlexGrow>
          <CustomColumn alignItems="flex-start" gap="4px">
            <CustomText
              text={
                "gm " + (identity?.display_name?.split(" ")?.[0] || "") + " 👋"
              }
              fontSize="13px"
              fontWeight="600"
              letterSpacing="-0.26px"
              color="var(--text-1)"
            />
            <CustomText
              textAlign="flex-start"
              text="Let's build the best web3-social experience for you!"
              fontSize="10px"
              letterSpacing="-0.2px"
            />
          </CustomColumn>
        </FlexGrow>
        <ProgressBar completedSteps={completedSteps} totalSteps={totalSteps} />
      </CustomRow>
      <PaddingComponent height="4px" />
      <CompletedSteps completedSteps={completedSteps} totalSteps={totalSteps} />
    </CustomColumn>
  );
};

const CompletedSteps = ({ completedSteps, totalSteps }) => {
  return (
    <InfoBox radius="30px" padding="4px 8px" width="116px">
      <CustomRow alignItems="center" gap="4px" justifyContent="center">
        <CustomText
          text={completedSteps || "0"}
          fontSize="15px"
          letterSpacing="-0.26px"
          fontWeight="600"
          color="var(--text-1)"
        />
        <CustomText
          text={`/ ${totalSteps} completed`}
          fontSize="12px"
          letterSpacing="-0.2px"
        />
      </CustomRow>
    </InfoBox>
  );
};

export const ProgressBar = ({ completedSteps, totalSteps }) => {
  if (totalSteps === 0) totalSteps = 1;
  return (
    <div className={classes.progressCircle}>
      <ProgressCircle
        radius={25}
        progress={completedSteps / totalSteps}
        strokeWidth={5}
      />
      <div style={{ position: "absolute" }}>
        <CustomText
          text={parseInt((completedSteps / totalSteps) * 100) + "%"}
          fontSize="15px"
          letterSpacing="-0.26px"
        />
      </div>
    </div>
  );
};
export default UserJourney;
