import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import classes from "./TrendingQueryCard.module.css";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import Image from "components/UI/Image";
import { CaretRight } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

const TrendingQueryCard = ({ query, isExample = false }) => {
  return (
    <div className={classes.trending_query_card}>
      <Link to={`/screener?session_id=${query.session_id}`} target="_blank">
        <div className={classes.header}>
          {isExample ? (
            <div className={classes.headline}>{query.headline}</div>
          ) : (
            <ProfileHoverCard actor={query.author_profile}>
              <div className={classes.profile}>
                <Image
                  src={query.author_profile.display_picture}
                  alt={query.author_profile.display_name}
                  className={classes.profile_image}
                />
                <div className={classes.profile_name}>
                  {query.author_profile.display_name}
                </div>
              </div>
            </ProfileHoverCard>
          )}
          <CaretRight size={22} weight="thin" color={"var(--text-2)"} />
        </div>
        <div className={classes.body}>
          <FeedDescription
            templateString={query.query_template}
            actor={query.author_profile}
            chainProfiles={query.chain_profiles}
            nativeTokensInfo={query.native_tokens_info}
            nonEvmProfiles={query.non_evm_profiles}
            profiles={query.profiles}
            identities={query.identities}
            mainProtocolData={query.main_protocol_details}
          />
        </div>
      </Link>
    </div>
  );
};

export default TrendingQueryCard;
