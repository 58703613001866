import { ChartBar, Flag, MoneyWavy, Ranking } from "@phosphor-icons/react";
import {
  CustomColumn,
  CustomRow,
} from "../../wallets/AddWallet/AddWalletComponent";
import ZxText from "../../../../../../zxComponents/common/ZxText";
import { TSTYLES } from "../../../../../../zxStyles/constants";
import { PriceChange } from "../../../../../UI/Components/Components";
import styles from "./PolyMarketHoldings.module.css";
import { Grid } from "../../../../../UI/Components/Grid";

const getOutComeClassName = (outcome) => {
  switch (outcome.toLowerCase()) {
    case "yes":
      return styles.yesValue;
    case "no":
      return styles.noValue;
    default:
      return styles.otherValue;
  }
};
export const PolyMarketHoldingDetails = (props) => {
  const { protocolHoldingItem } = props;

  const { polymarket_holding } = protocolHoldingItem;
  const highLights = [
    {
      icon: <Ranking size={24} color="var(--text-2)" />,
      label: "Position Value",
      value: polymarket_holding.positions_value,
    },
    {
      icon: <Flag size={24} color="var(--text-2)" />,
      label: "Markets Traded",
      value: {
        display_value: polymarket_holding.markets_traded,
      },
    },
    {
      icon: <ChartBar size={24} color="var(--text-2)" />,
      label: "Volume Traded",
      value: polymarket_holding.volume_traded,
    },
    {
      icon: <MoneyWavy size={24} color="var(--text-2)" />,
      label: "PNL",
      value: polymarket_holding.PNL,
    },
  ];

  return (
    <>
      <Grid gap={20} partitions={4} style={{ marginBottom: 16, marginTop: 16 }}>
        {highLights.map(({ label, icon, value }) => {
          return (
            <CustomRow
              key={label}
              padding="16px"
              gap="12px"
              alignItems="center"
              style={{ background: "var(--elevation-1)", borderRadius: 8 }}>
              {icon}
              <CustomColumn gap="4px">
                <ZxText
                  style={TSTYLES.secondary2}
                  fontSize={11}
                  color="var(--text-2)">
                  {label}
                </ZxText>
                <ZxText
                  style={TSTYLES.title1}
                  color="var(--text-1)"
                  fontSize={13}>
                  {label === "PNL" ? (
                    <PriceChange
                      value={value.value}
                      displayValue={value.display_value}
                    />
                  ) : (
                    value.display_value
                  )}
                </ZxText>
              </CustomColumn>
            </CustomRow>
          );
        })}
      </Grid>
      <Grid direction="vertical" className={styles.table}>
        <Grid gap={20} partitions={4} className={styles.innerRow}>
          <div className={styles.textLeft} style={{ gridColumn: "1/3" }}>
            <ZxText fontSize={13} color="var(--text-2)">
              MARKET
            </ZxText>
          </div>
          <div className={styles.textRight}>
            <ZxText fontSize={13} color="var(--text-2)">
              VOTE
            </ZxText>
          </div>
          <div className={styles.textRight}>
            <ZxText fontSize={13} color="var(--text-2)">
              SHARED HOLDING
            </ZxText>
          </div>
        </Grid>
        {polymarket_holding.open_trades.map(
          ({ icon, title, usdc_value, share_size, outcome }, index) => (
            <Grid
              key={index}
              gap={20}
              partitions={4}
              className={styles.innerRow}>
              <div style={{ gridColumn: "1/3" }}>
                <CustomRow gap="4px" alignItems="center">
                  <img
                    alt={title}
                    src={icon}
                    style={{ width: 24, height: 24, borderRadius: 12 }}
                  />
                  <ZxText fontSize={13}>{title}</ZxText>
                </CustomRow>
              </div>
              <div className={styles.textRight}>
                <div className={getOutComeClassName(outcome)}>{outcome}</div>
              </div>
              <div className={styles.textRight}>
                <ZxText fontSize={13} align="right" color="var(--text-1)">
                  {share_size.display_value} shares
                </ZxText>
              </div>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
};
