import queries from "./QueryObject";
import { v4 as uuidv4 } from "uuid";

export const defaultQuery = (firstQuery) => ({
  id: uuidv4(),
  operator: queries[13],
  query: {
    query: queries[0],
    compOperator: {
      operator: ">",
      value: firstQuery ? "$100M" : null,
    },
    filters: {
      chain: { data: null, query: queries[10] },
      protocol: { data: null, query: queries[11] },
    },
    params: {
      token: firstQuery ? ethData : null,
      protocol: null,
      profile: null,
    },
  },
});

const ethData = {
  type: "native_token",
  data: {
    address_chain: "Ethereum",
    display_name: "Ethereum",
    display_picture:
      "https://d43skd0e52j71.cloudfront.net/static/chain_eth.svg",
    symbol: "ETH",
    link: "/Ethereum/native_token/ETH",
    token_details: {
      logo: "https://d43skd0e52j71.cloudfront.net/static/chain_eth.svg",
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      market_cap_rank: null,
      market_cap: null,
    },
  },
  search_hints: {
    Symbol: "<em>ETH</em>",
  },
};
