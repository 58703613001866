import React, { useState } from "react";
import classes from "./linkPreviews.module.css";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { signedRequest } from "api/api";
import { useQuery } from "@tanstack/react-query";
import { getTrimmedString } from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";

const LinkPreviews = ({ urls, previews }) => {
  if (urls?.length === 0 || previews?.length === 0) {
    return null;
  }

  const firstThreeUrls = urls?.slice(0, 3);
  const firstThreePreviews = previews?.slice(0, 3);

  return (
    <div
      className={`${classes.linkPreviewsWrapper} ${
        firstThreePreviews?.length === 1 ? classes.singleLink : ""
      }`}>
      {!!firstThreeUrls &&
        firstThreeUrls?.map((url, index) => (
          <LinkPreviewItem url={url} key={`${url}-${index}`} />
        ))}
      {!!firstThreePreviews &&
        firstThreePreviews.map((previewItem, index) => (
          <LinkPreviewUI
            previewItem={previewItem}
            key={`${previewItem?.url}-${index}`}
            singlePreview={previews.length === 1}
          />
        ))}
    </div>
  );
};

const LinkPreviewItem = ({ url }) => {
  const fetchLinkPreview = async (url) => {
    const { data } = await signedRequest({
      method: "get",
      path: `/preview/external?url=${url}`,
    });
    return data.data;
  };

  const { data: linkPreview } = useQuery({
    queryKey: ["linkPreview", url],
    queryFn: () => fetchLinkPreview(url),
    refetchOnMount: false,
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!url,
  });

  if (!linkPreview) return null;

  return <LinkPreviewUI previewItem={linkPreview} />;
};

const LinkPreviewUI = ({
  previewItem,
  descriptionLength = 20,
  titleLength = 20,
  singlePreview,
}) => {
  const { image_url: image, description, title, url: siteUrl } = previewItem;

  let singlePreviewDescriptionLength = 0,
    singlePreviewTitleLength = 0;
  if (singlePreview) {
    singlePreviewDescriptionLength = 200;
    singlePreviewTitleLength = 90;
  }

  const [isImageVisible, setIsImageVisible] = useState(true);
  return (
    <ConditionalLink
      sameTab={false}
      to={{ pathname: siteUrl }}
      className={`${classes.container}`}>
      {!!previewItem?.url && isImageVisible && (
        <div className={classes.imageWrapper}>
          <Image
            className={classes.image}
            src={image}
            alt={`${title}-icon`}
            imageType={IMAGE_TYPES.NFT}
            onErrorCallback={() =>
              singlePreview ? setIsImageVisible(false) : null
            }
          />
        </div>
      )}
      <div className={classes.lowerContainer}>
        {!!singlePreview && (
          <div className={`${classes.secondary} ${classes.siteDetails}`}>
            <span>{siteUrl}</span>
          </div>
        )}
        <div className={classes.title}>
          {getTrimmedString(
            title,
            singlePreviewDescriptionLength || titleLength
          )}
        </div>
        {description && (
          <span className={`${classes.description} ${classes.secondary}`}>
            {getTrimmedString(
              description,
              singlePreviewTitleLength || descriptionLength
            )}
          </span>
        )}
      </div>
    </ConditionalLink>
  );
};

export default LinkPreviews;
