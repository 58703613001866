import { RenderActivity } from "components/GroupFeedItem/GroupFeedItem.components";
import { PaddingComponent } from "components/UI/Components/Components";
import { Link } from "react-router-dom";
import ProtocolHoldingsRightPanelHeader from "./ProtocolHoldingsRightPanelHeader";
import { TransactionShimmer } from "components/Feed/GroupedActivityDetails/GroupActivityDetailsShimmer";
import { protocolTypeToLabel } from "utils/misc";

const ProtocolHoldingsRightPanel = ({
  currentProtocolItem,
  currentPositionItem,
  currentHoldingsLabel,
  txnsCount,
  onClose,
  isLoading,
  groupedActivities,
}) => {
  const firstTransactionTimestamp =
    groupedActivities?.[0]?.activities?.[0]?.timestamp;
  const label = protocolTypeToLabel(currentHoldingsLabel);

  return (
    <div>
      <ProtocolHoldingsRightPanelHeader
        currentProtocolItem={currentProtocolItem}
        currentPositionItem={currentPositionItem}
        firstTransactionTimestamp={firstTransactionTimestamp}
        txnsCount={txnsCount}
        currentPositionLabel={label}
        onClose={onClose}
      />
      <PaddingComponent padding="16px 20px">
        <Transactions
          groupedActivities={groupedActivities}
          isLoading={isLoading}
        />
      </PaddingComponent>
    </div>
  );
};

const Transactions = ({ groupedActivities, isLoading }) => {
  if (isLoading) {
    return [...Array(3).keys()].map((item, index) => (
      <>
        <TransactionShimmer key={`txn-shimmer-${index}`} />
        <PaddingComponent height="16px" />
      </>
    ));
  }

  if (!groupedActivities) {
    return null;
  }
  return (
    <>
      {groupedActivities.map((groupedActivity, activityIndex) => (
        <div key={`transaction-activity-${activityIndex}`}>
          {groupedActivity.activities.map((activity, index) => {
            const link = `/group_activity_details/${groupedActivity.group_id}/${activity.id}`;
            const key = `activity-details-transaction-${activity.id}-${activityIndex}-${index}`;

            return (
              <Link key={key} to={link}>
                <RenderActivity
                  key={`activity-details-transaction-${activity.id}`}
                  activity={activity}
                  profiles={groupedActivity.profiles}
                  methodIds={groupedActivity.method_ids}
                  index={index}
                  groupedActivity={{
                    ...groupedActivity,
                    activities: [activity],
                  }}
                  isActivityDetails
                />
              </Link>
            );
          })}
        </div>
      ))}
    </>
  );
};

export default ProtocolHoldingsRightPanel;
