import styles from "./appLayout.module.css";
import Header from "./Header/Header";
import LeftSideBar from "./LeftSideBar/LeftSideBar";
import { Route, Switch, useLocation } from "react-router-dom";
import MessagingApp from "components/V2/Messaging/MessagingApp";

import { ProtectedRoute } from "shared/protected.route";
import { useContext, useMemo, useRef, useEffect } from "react";
import { AuthContext } from "contextStates/AuthContext";
import { createPortal } from "react-dom";
import { Redirect } from "react-router-dom";

import { MobileOSTypes } from "utils/constants";
import {
  getBrowserNameAndVersion,
  getMobileOperatingSystem,
  isJoinWaitlist,
} from "utils/misc";
import { POST_TYPES, TRENDING_ROUTES } from "utils/constants";
import useQueryParams from "customHooks/useQueryParams";
import { GlobalContext } from "contextStates/Global";

import {
  FeedContainer,
  RightBarContainer,
} from "components/UI/Components/ResizeFeedComponent";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import { ThemeContext } from "contextStates/Theme.context";
import DownloadAppBanner from "../../V2/DownloadAppBanner/DownloadAppBanner";
import {
  ProfileRightBar,
  WalletAccessRightBar,
} from "components/Pages/AppModule/RightSideBar/RightBar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ZxNftDetails from "zxComponents/nft/ZxNftDetails/ZxNftDetails";
import WalletAccessPage from "zxComponents/profile/WalletAccessPage/WalletAccessPage";
import { signedRequest } from "api/api";
import { logSentryEvent } from "utils/sentry";
import ProfileEditPage from "zxComponents/profile/ProfileEditPage/ProfileEditPage";
import { TOKEN_TABS } from "zxComponents/token/ZxTokenPage/ZxTokenPage.utils";

import Profile from "components/V2/Profile/Profile";
import Feed from "components/Feed/Feed";
import PageNotFound from "pages/PageNotFound/PageNotFound/PageNotFound";
import NftCollectionsPage from "zxComponents/nft/CollectionsPage/CollectionsPage";
import ProtocolPage from "components/V2/Protocol/ProtocolPage";
import LoginPopup from "components/LoginPopup/LoginPopup";
import EditContractPopup from "components/V2/Contract/EditContractDetails/EditContractPopup";
import Screener from "../Screener/Screener";
import GroupedActivityDetails from "components/Feed/GroupedActivityDetails/GroupedActivityDetails";
import PostDetails from "components/Feed/GroupedActivityDetails/PostDetails";
import ActivityDetails from "components/V2/ActivityDetails/ActivityDetails";
import Community from "components/V2/Community/Community";
import Bookmarks from "components/V2/Bookmarks/Bookmarks";
import Explorer from "components/V2/Explorer/NavigatableExplorer";
import TokenTransferId from "components/V2/RedirectComponents/TokenTransferId";
import NotificationsPage from "components/V2/Notifications/NotificationsPage";
import TransactionActivityDetails from "components/V2/ActivityDetails/TransactionActivityDetails";
import SeeAllResults from "components/SeeAllResults/SeeAllResults";
import TrendingDetailsWrapper from "components/Trending/TrendingDetailsWrapper";
import MobileDownloadFooter from "components/UI/Components/MobileDownload";
import NewsDetails from "components/V2/News/NewsDetails";
import RightBar from "components/Pages/AppModule/RightSideBar/RightBar";
import DevSummaryDetails from "components/V2/DevSummaryDetails/DevSummaryDetails";
import ReferFriends from "components/V2/ReferFriends/ReferFriends";
import ChainRoutes from "./ChainRoutes";
import TrendingDetailsPage from "components/V2/TrendingDetails/TrendingDetailsPage";
import TrendingContractDetailsPage from "components/V2/TrendingContractDetails/TrendingContractDetailsPage";
import OldTokenPage from "components/V2/TokenPage/TokenPage";
import TokenPage from "zxComponents/token/ZxTokenPage/ZxTokenPage";
import RedirectPage from "components/V2/RedirectPage/RedirectPage";
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
let userMetadataTimer;
const AppModule = () => {
  const { isUserLoggedIn, identityDetails } = useContext(AuthContext);
  const { isSearchInputFocused, isLeftSideBarOpen } = useContext(
    LeftSideHeaderContext
  );
  const { theme } = useContext(ThemeContext);
  const { featureFlags, ribbonData, getSupportedChainsListForRoutes } =
    useContext(GlobalContext);
  let query = useQuery();
  const location = useLocation();
  const routeString = getSupportedChainsListForRoutes();
  const isMetadataRequestDone = useRef(false);

  const routes =
    routeString?.substring(1, routeString.length - 1).split("|") ?? [];
  const pageRouteIdentifiers = [
    ...routes,
    "home",
    "charts",
    "r",
    "c",
    "p",
    "t",
    "tx",
    "group_activity_details",
    "activity_group",
    "activity_details",
    "profile_created",
    "news_details",
    "summary-details",
    "profile",
    "person_details",
    "refer-friends",
    "notifications",
    "bookmarks",
    "search",
    "me",
    "feed",
    "identity",
    "link",
    "screener",
    "messages",
    "404",
    "settings",
    "community",
    "trending_details",
    "trending_token",
    "post",
    "activity_group",
    "sol_activity_group",
    "lens",
    "farcaster",
    "trending",
    "welcome",
    "mirror",
    "snapshot",
    "proposal",
    "whale_alert",
    "screener_activity",
    "nft",
    "collection",
    "user_token_group",
    "sol_user_token_group",
  ];
  // Check if match is not null to determine if we are on the profile page
  const isProfilePage = location?.pathname
    ? !pageRouteIdentifiers.includes(location.pathname.split("/")[1]) &&
      !location.pathname.includes("/tx/")
    : true;
  const supportedChains = getSupportedChainsListForRoutes();
  const path = location.pathname;
  const isExplorer = path.startsWith("/explorer");
  const isScreenerResults =
    path.startsWith("/screener") && location.search?.startsWith("?session_id=");
  const isTrendingToken = path.includes("/trending_token");
  const isTokenPage = path.includes("/c/");
  const isMessages = path.startsWith("/messages");
  const isRedirectPage = path.startsWith("/r/");
  // const isRenderingProfilePage = isProfilePage(path);
  const isRightBarVisible =
    !isRedirectPage &&
    !isProfilePage &&
    !isExplorer &&
    !isScreenerResults &&
    !isMessages &&
    !isTrendingToken &&
    !isTokenPage;
  const isLeftBarVisible = !isRedirectPage;
  const rightBarRef = useRef(null);
  const handleScroll = (e) => {
    if (rightBarRef.current == null) return;
    const { scrollTop, clientHeight } = document.documentElement;

    const scrollRatio = scrollTop / clientHeight;
    rightBarRef.current.scrollTop =
      scrollRatio * rightBarRef.current.clientHeight * 0.8;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const sendMetadata = async () => {
      try {
        const isBrave = (await navigator?.brave?.isBrave()) ?? false;
        const browser = getBrowserNameAndVersion();
        await signedRequest({
          path: "/api/0xppl/get_user_metadata",
          method: "post",
          bodyText: JSON.stringify({
            platform: "web",
            os_version: isBrave ? `Brave ${browser?.split(" ")[1]}` : browser,
          }),
        });
      } catch (err) {
        logSentryEvent(err, {
          msg: "Error in sending user metadata",
        });
      }
    };
    if (!isMetadataRequestDone.current) {
      userMetadataTimer = setTimeout(() => {
        sendMetadata();
      }, 5000);
      isMetadataRequestDone.current = true;
    }
    return () => {
      clearTimeout(userMetadataTimer);
    };
  }, []);
  return (
    <div className={styles.app_layout}>
      <div className={styles.main}>
        <Header isUserLoggedIn={isUserLoggedIn} />
        {theme === "dark" && <div className={styles.bgGradient} />}
        {isLeftBarVisible && <LeftSideBar />}
        <div className={styles.container}>
          <div
            className={styles.flexBox}
            style={{
              marginTop: ribbonData == null ? "48px" : "70px",
              height:
                ribbonData == null
                  ? "calc(100vh - 48px)"
                  : "calc(100vh - 70px)",
              marginLeft: isLeftSideBarOpen ? "250px" : "86px",
            }}>
            <Switch>
              <Route path="/explorer">
                <Explorer />
              </Route>
              <Route
                key={`${supportedChains}`}
                path={`/:chain_id${supportedChains}`}>
                <ChainRoutes supportedChains={supportedChains} />
              </Route>
              <Route
                exact
                path={`/collection/:collection_id/:active_tab?/:chain_id?/:contract_address?/:nft_id?`}
                render={({ match }) => {
                  if (!match.params.active_tab) {
                    return (
                      <Redirect
                        to={`/collection/${match.params.collection_id}/listings`}
                      />
                    );
                  }
                  return (
                    <FeedContainer className={styles.noPadding}>
                      <NftCollectionsPage
                        collectionId={match.params.collection_id}
                      />
                    </FeedContainer>
                  );
                }}
              />
              <Route
                exact
                path={`/nft/:chain_id${supportedChains}/:address/:token_id?`}
                render={({ match }) => {
                  return (
                    <FeedContainer>
                      <ZxNftDetails
                        chainId={match.params.chain_id}
                        contractAddress={match.params.address}
                        nftId={match.params.token_id}
                        isModal={false}
                      />
                    </FeedContainer>
                  );
                }}
              />
              <Route
                exact
                path={`/c/:chain_id${supportedChains}/:address/:active_tab?`}
                render={({ match }) => {
                  if (!match.params.active_tab) {
                    return (
                      <Redirect
                        to={`/c/${match.params.chain_id}/${match.params.address}/${TOKEN_TABS.RECENT_TXNS}`}
                      />
                    );
                  }
                  const oldTabs = [
                    "contract",
                    "activities",
                    "collections",
                    "tvl-info",
                    "source-code",
                    "abi",
                    "liquidity",
                    "idl",
                    "read-contract",
                    "write-contract",
                  ];
                  if (oldTabs.includes(match.params.active_tab)) {
                    if (match.params.active_tab === "contract") {
                      return (
                        <Redirect
                          to={`/c/${match.params.chain_id}/${match.params.address}/activities`}
                        />
                      );
                    }
                    return (
                      <OldTokenPage
                        chainId={match?.params?.chain_id}
                        contractAddress={match?.params?.address}
                      />
                    );
                  }
                  const tokenTabs = Object.entries(TOKEN_TABS).map(
                    (k, v) => k[1]
                  );
                  if (!tokenTabs.includes(match.params.active_tab)) {
                    return (
                      <Redirect
                        to={`/c/${match.params.chain_id}/${match.params.address}/${TOKEN_TABS.RECENT_TXNS}`}
                      />
                    );
                  }
                  return (
                    <TokenPage
                      chain={match?.params?.chain_id}
                      address={match?.params?.address}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`/ct/:chain_id${supportedChains}/:address/:active_tab?`}
                render={({ match }) => {
                  if (!match.params.active_tab) {
                    return (
                      <Redirect
                        to={`/ct/${match.params.chain_id}/${match.params.address}/token`}
                      />
                    );
                  }
                  return (
                    <OldTokenPage
                      chainId={match?.params?.chain_id}
                      contractAddress={match?.params?.address}
                    />
                  );
                }}
              />
              <Route
                exact
                path={`/t/:chain_id${supportedChains}/:address/:active_tab?`}
                render={({ match }) => {
                  return (
                    <FeedContainer padding="16px 0">
                      <TrendingContractDetailsPage
                        type="trending_in_network"
                        chain={match?.params?.chain_id}
                        address={match?.params?.address}
                      />
                    </FeedContainer>
                  );
                }}
              />
              <Route
                exact
                path="/p/:protocol/:active_tab?"
                render={({ match }) => {
                  return !match.params.active_tab ? (
                    <Redirect to={`/p/${match.params.protocol}/contracts`} />
                  ) : (
                    <FeedContainer>
                      <ProtocolPage />
                    </FeedContainer>
                  );
                }}></Route>
              <Route
                exact
                path="/r/:link"
                render={({ match }) => {
                  return !match.params.link ? (
                    <Redirect to={`/home`} />
                  ) : (
                    <RedirectPage link={match.params.link} />
                  );
                }}></Route>

              <Route
                exact
                path="/tx/:tx_hash/:active_tab?/:engagement_type?"
                render={({ match }) => {
                  if (!match.params.active_tab) {
                    return (
                      <Redirect to={`/tx/${match.params.tx_hash}/details`} />
                    );
                  }
                  return (
                    <FeedContainer>
                      <TransactionActivityDetails
                        tx_hash={match.params.tx_hash}
                      />
                    </FeedContainer>
                  );
                }}
              />
              <Route exact path="/tx_id">
                <FeedContainer>
                  <TokenTransferId
                    chainId={query.get("chain_id")}
                    blockNumber={query.get("block_number")}
                    txIdx={query.get("tx_idx")}
                    actor={query.get("actor")}
                  />
                </FeedContainer>
              </Route>
              <Route
                exact
                path="/group_activity_details/:group_id/:activity_id/:active_tab?"
                render={({ match }) => {
                  if (!match.params.active_tab) {
                    return (
                      <Redirect
                        to={`/group_activity_details/${match.params.group_id}/${match.params.activity_id}/details`}
                      />
                    );
                  }
                  return (
                    <FeedContainer>
                      <ActivityDetails
                        group_id={match.params.group_id}
                        activity_id={match.params.activity_id}
                      />
                    </FeedContainer>
                  );
                }}
              />
              <Route
                exact
                path="/activity_details/:activity_id/:active_tab?/:engagement_type?"
                render={({ match }) => {
                  if (!match.params.active_tab) {
                    return (
                      <Redirect
                        to={`/activity_details/${match.params.activity_id}/details`}
                      />
                    );
                  }
                  return (
                    <FeedContainer>
                      <ActivityDetails activity_id={match.params.activity_id} />
                    </FeedContainer>
                  );
                }}
              />
              <Route
                exact
                path="/news_details/:chain_id/:token_address/:active_tab?/:engagement_type?"
                render={({ match }) => {
                  if (!match.params.active_tab) {
                    return (
                      <Redirect
                        to={`/news_details/${match.params.token_address}/${match.params.chain_id}/transactions`}
                      />
                    );
                  }

                  return (
                    <FeedContainer>
                      <NewsDetails
                        tokenAddress={match.params.token_address}
                        chainId={match.params.chain_id}
                      />
                    </FeedContainer>
                  );
                }}
              />

              <ProtectedRoute path={`/settings`}>
                <Redirect
                  to={`/${identityDetails?.current?.identity?.id}/settings`}
                />
              </ProtectedRoute>
              <Route path={`/community`}>
                <FeedContainer>
                  <Community />
                </FeedContainer>
              </Route>
              <Route
                path="/search"
                component={SeeAllResultsRedirection}></Route>

              {/* <Route path={`/explorer`}>Explorer</Route> */}
              <ProtectedRoute path="/me">
                <Redirect
                  to={identityDetails?.current?.identity?.link ?? `/onboarding`}
                />
              </ProtectedRoute>

              <ProtectedRoute
                path={`/feed/:active_tab?`}
                render={({ match }) => (
                  <Redirect to={`/home/${match.params.active_tab}`} />
                )}></ProtectedRoute>

              <Route
                path={`/trending_details/:chainId/:identifier/:active_tab${TRENDING_ROUTES}?`}
                render={({ match }) => {
                  if (!match.params.active_tab) {
                    const activeTab = "details";
                    return (
                      <Redirect
                        to={`/trending_details/${match.params.chainId}/${match.params.identifier}/${activeTab}`}
                      />
                    );
                  }
                  return (
                    <FeedContainer>
                      <TrendingDetailsWrapper
                        identifier={match?.params?.identifier}
                        chainId={match?.params?.chainId}
                      />
                    </FeedContainer>
                  );
                }}></Route>
              {/* TRENDING DETAILS - V2 */}
              <Route
                exact
                path={`/trending_token/:chainId/:contractAddress`}
                render={({ match }) => {
                  return (
                    <TrendingDetailsPage
                      chainId={match?.params?.chainId}
                      contractAddress={match?.params?.contractAddress}
                    />
                  );
                }}
              />
              <Route
                path={`/home`}
                render={() => {
                  return (
                    <div>
                      {identityDetails.current?.has_downloaded_app ===
                        false && <DownloadAppBanner variant="feed" />}
                      <FeedContainer className={styles.feedContainer}>
                        <Feed />
                      </FeedContainer>
                    </div>
                  );
                }}></Route>
              <Route
                path={`/summary-details`}
                render={() => {
                  return (
                    <FeedContainer>
                      <DevSummaryDetails />
                    </FeedContainer>
                  );
                }}></Route>
              <Route
                path={`/:type${POST_TYPES}/:id/:active_tab?/:engagement_type?`}
                render={({ match }) => {
                  if (!match.params.active_tab) {
                    const activeTab =
                      match.params.type === "activity_group"
                        ? "transactions"
                        : "comments";
                    return (
                      <Redirect
                        to={`/${match.params.type}/${match.params.id}/${activeTab}`}
                      />
                    );
                  }
                  if (match.params.type === "post") {
                    return (
                      <FeedContainer style={{ maxWidth: "800px" }}>
                        <PostDetails
                          postId={match.params.id}
                          key={`activity-${match.params.id}`}
                        />
                      </FeedContainer>
                    );
                  }
                  return (
                    <FeedContainer style={{ maxWidth: "800px" }}>
                      <GroupedActivityDetails
                        id={match.params.id}
                        type={match.params.type}
                      />
                    </FeedContainer>
                  );
                }}></Route>
              <Route
                path="/identity/:identityId/:active_tab?"
                render={({ match }) => {
                  return (
                    <Redirect
                      to={`/${match.params.identityId}/${
                        match.params.active_tab || "activities"
                      }`}
                    />
                  );
                }}
              />

              <Route
                path="/person_details/:identifier/:active_tab?"
                render={({ match }) => {
                  return (
                    <Redirect
                      to={`/${match.params.identifier}/${
                        match.params.active_tab || "activities"
                      }`}
                    />
                  );
                }}
              />

              <Route
                path={"/refer-friends/:identifier?"}
                render={({ match }) => {
                  return (
                    <FeedContainer>
                      <ReferFriends identifier={match.params.identifier} />
                    </FeedContainer>
                  );
                }}
              />
              <Route path={"/notifications"}>
                <FeedContainer>
                  <NotificationsPage />
                </FeedContainer>
              </Route>
              <Route path={"/bookmarks"}>
                <FeedContainer>
                  <Bookmarks />
                </FeedContainer>
              </Route>
              <Route path="/link">
                <Redirect to={`/home`} />
              </Route>
              <Route path={"/screener"}>
                <Screener />
              </Route>
              {featureFlags.is_messaging_enabled && (
                <Route path={"/messages/:active_tab?/:channel_id?"}>
                  <MessagingApp />
                </Route>
              )}
              <Route path={"/404"} component={PageNotFound} />
              <Route
                path={`/:identifier/edit`}
                render={({ match }) => {
                  return (
                    <>
                      <FeedContainer>
                        <ProfileEditPage identifier={match.params.identifier} />
                      </FeedContainer>
                      <RightBarContainer>
                        <ProfileRightBar rightBarRef={rightBarRef} />
                      </RightBarContainer>
                    </>
                  );
                }}
              />
              <Route
                path={`/:identifier/:active_tab?/:address?/:bundle_identity_id?`}>
                <Switch>
                  <Route
                    path={`/:identifier/wallets/manage-access/:profile_id?`}>
                    <Switch>
                      <Route
                        path={`/:identifier/wallets/manage-access/:profile_id`}>
                        <>
                          <FeedContainer
                            className={`${styles.noPadding} ${styles.borderContainer} ${styles.autoHeight}`}>
                            <WalletAccessPage />
                          </FeedContainer>
                          <RightBarContainer>
                            <WalletAccessRightBar rightBarRef={rightBarRef} />
                          </RightBarContainer>
                        </>
                      </Route>
                      <Route path={`/:identifier/wallets/manage-access`}>
                        <>
                          <FeedContainer
                            className={`${styles.noPadding} ${styles.borderContainer} ${styles.autoHeight}`}>
                            <WalletAccessPage manage={true} />
                          </FeedContainer>
                          <RightBarContainer>
                            <WalletAccessRightBar rightBarRef={rightBarRef} />
                          </RightBarContainer>
                        </>
                      </Route>
                    </Switch>
                  </Route>
                  <Route path={`/:identifier/wallets/request/:profile_id?`}>
                    <>
                      <FeedContainer
                        className={`${styles.noPadding} ${styles.borderContainer} ${styles.autoHeight}`}>
                        <WalletAccessPage />
                      </FeedContainer>
                      <RightBarContainer>
                        <WalletAccessRightBar rightBarRef={rightBarRef} />
                      </RightBarContainer>
                    </>
                  </Route>
                  <Route
                    path={`/:identifier/:active_tab?/:address?/:bundle_identity_id?`}>
                    <>
                      <FeedContainer
                        className={`${styles.noPadding} ${styles.borderContainer} ${styles.autoHeight}`}>
                        <Profile />
                      </FeedContainer>
                      <RightBarContainer>
                        <ProfileRightBar rightBarRef={rightBarRef} />
                      </RightBarContainer>
                    </>
                  </Route>
                </Switch>
              </Route>
            </Switch>
          </div>
          {isRightBarVisible && (
            <RightBarContainer>
              <RightBar rightBarRef={rightBarRef} />
            </RightBarContainer>
          )}
        </div>
        {isSearchInputFocused && <div className={styles.bgGradient2} />}
        {createPortal(
          getMobileOperatingSystem() !== MobileOSTypes.UNKNOWN &&
            !isJoinWaitlist(location) ? (
            <MobileDownloadFooter />
          ) : null,
          document.getElementById("mobileDownloadFooter")
        )}
        {createPortal(<LoginPopup />, document.getElementById("loginPopup"))}
        {createPortal(
          <EditContractPopup />,
          document.getElementById("editContractPopup")
        )}
      </div>
    </div>
  );
};

const SeeAllResultsRedirection = () => {
  const query = useQueryParams();
  const filtersStr = query.get("filters");
  //from list of filters separated with , make a list
  const filters = filtersStr?.split(",").map((filter) => {
    return { name: filter, isSelected: true };
  });
  return (
    <FeedContainer>
      <SeeAllResults searchTerm={query.get("query")} filters={filters} />
    </FeedContainer>
  );
};

export default AppModule;
