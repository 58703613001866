import React from "react";
import styles from "./ZxFilterButton.module.scss";
import { CaretDown, Funnel, X } from "@phosphor-icons/react";
import ZxText from "../ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { Menu } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
  CustomColumn,
  CustomRow,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import ZxCheckBox from "../ZxCheckbox/ZxCheckBox";
import GenericButton from "components/V2/GenericButton/GenericButton";
const ZxFilterButton = ({
  options,
  isMultiLevel,
  selectedOptions = {},
  onApply,
  isActive,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tempValues, setTempValues] = React.useState(selectedOptions);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <div className={`${styles.container} `} onClick={handleClick}>
        <Funnel
          size={16}
          color={"var(--text-2)"}
          weight={isActive ? "fill" : "bold"}
        />
        <ZxText style={TSTYLES.body1} color={"text-2"}>
          Filter
        </ZxText>
      </div>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        sx={{
          ".MuiPaper-root": {
            backgroundColor: "var(--background)",
            width: "220px",
            // boxShadow: "none",
            marginTop: "4px",
          },
          ".MuiMenu-list": {
            paddingTop: "0",
          },
        }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <PaddingComponent padding="8px 16px">
          <CustomRow justifyContent="space-between">
            <CustomRow alignItems="flex-end" gap="8px">
              <ZxText style={TSTYLES.title2} fontSize="14px">
                Filter by
              </ZxText>
              <span className={styles.clearbtn} onClick={() => onApply({})}>
                Clear
              </span>
            </CustomRow>
            <PaddingComponent
              className={styles.closebtn}
              onClick={() => {
                setTempValues(selectedOptions);
                handleClose();
              }}>
              <X size={12} color="var(--text-2)" weight="bold" />
            </PaddingComponent>
          </CustomRow>
        </PaddingComponent>
        <Divider margin="0px 0 8px 0" />

        <div className={styles.items}>
          {Object.keys(options).map((title, idx) => {
            return (
              <Accordion
                key={`${title}_${idx}`}
                sx={{
                  boxShadow: "none",
                  margin: "0",
                  ".MuiAccordionSummary-root": {
                    padding: "0 16px",
                    minHeight: "0",
                  },
                  ".MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "0",
                  },
                  ".MuiAccordionSummary-content": {
                    margin: "4px 0",
                  },
                  "&.MuiAccordion-root:before": {
                    // Remove the default divider line
                    height: "0px",
                    margin: "0px",
                  },

                  ".MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded":
                    {
                      margin: 0,
                    },

                  ".MuiAccordionDetails-root": {
                    padding: "0 0 0 0",
                  },
                  ".MuiAccordion-root.Mui-expanded": {
                    margin: "0 !important",
                  },
                }}>
                <AccordionSummary>
                  <CustomRow
                    justifyContent="space-between"
                    width="100%"
                    style={{
                      borderBottom: "1px solid var(--border-light)",
                      padding: "8px 0",
                    }}>
                    <ZxText style={TSTYLES.body1}>{title}</ZxText>
                    <CustomRow gap="8px">
                      <ZxText style={TSTYLES.body1} color="text-2">
                        {options[title].length}
                      </ZxText>
                      <CaretDown size={16} color="var(--text-1)" fill="bold" />
                    </CustomRow>
                  </CustomRow>
                </AccordionSummary>
                <AccordionDetails>
                  <CustomColumn>
                    {options[title].map((option, index) => {
                      const isSelected = tempValues[title]?.includes(option);
                      return (
                        <CustomRow
                          justifyContent="space-between"
                          key={`${title}_${option}_${index}`}
                          style={{ width: "100%", padding: "0 8px" }}>
                          <ZxCheckBox
                            isChecked={isSelected ?? false}
                            label={option}
                            extraStyles={{ width: "100%" }}
                            color="text-2"
                            onChange={() => {
                              setTempValues((prev) => {
                                const temp = { ...prev };
                                if (!temp[title]) {
                                  temp[title] = [option];
                                } else {
                                  if (temp[title].includes(option)) {
                                    temp[title] = temp[title].filter(
                                      (item) => item !== option
                                    );
                                  } else {
                                    temp[title].push(option);
                                  }
                                }
                                return temp;
                              });
                            }}
                          />
                        </CustomRow>
                      );
                    })}
                  </CustomColumn>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>

        <PaddingComponent padding="12px 16px">
          <GenericButton
            color="var(--base)"
            customClass={styles.submit}
            onClick={() => {
              onApply(tempValues);
              handleClose();
            }}>
            Apply
          </GenericButton>
        </PaddingComponent>
      </Menu>
    </div>
  );
};

export default ZxFilterButton;
