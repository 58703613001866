import React from "react";
import NoChatHistory from "assets/svg/no-chat-history.svg";
const UnMemoizedEmptyStateIndicator = (props) => {
  const { listType } = props;

  if (listType === "thread") return null;

  if (listType === "channel" || listType === "message") {
    return (
      <div className="str-chat__channel-list-empty" >
        <img
          className="str-chat__channel-list-empty-image"
          src={NoChatHistory}
          alt="No messages"
        />
        <p className="str-chat__channel-list-empty-v1" role="listitem">
          No messages yet
        </p>
        <p className="str-chat__channel-list-empty-v2" role="listitem">
          Search for people from above to start a conversation
        </p>
      </div>
    );
  }


  return <p>No items exist</p>;
};

export default React.memo(UnMemoizedEmptyStateIndicator);
