import { ArrowDownRight } from "@phosphor-icons/react";
import { useState } from "react";
import { createPortal } from "react-dom";
import { Drawer } from "@mui/material";
import classes from "./SendTokenButton.module.css";
import SendTokenPanel from "components/V2/Profile/ProfileDetails/Portfolio/SendTokenPanel/SendTokenPanel";
import { trackEvent } from "utils/event_tracking";

const SendTokenButton = () => {
  const [isSendPanelOpen, setIsSendPanelOpen] = useState(false);
  return (
    <>
      <button
        className={`${classes.connectButton}`}
        onClick={() => {
          trackEvent("send_token_btn_clicked");
          setIsSendPanelOpen(true);
        }}>
        <ArrowDownRight
          size="16"
          weight="bold"
          style={{ transform: "rotate(-90deg)" }}
        />
        <span>Send Token</span>
      </button>
      {createPortal(
        <Drawer
          anchor="right"
          open={isSendPanelOpen}
          onClose={() => setIsSendPanelOpen(false)}>
          <SendTokenPanel onClose={() => setIsSendPanelOpen(false)} />
        </Drawer>,
        document.getElementById("rightPanel")
      )}
    </>
  );
};

export default SendTokenButton;
