import classes from "./styles.module.css";
import TopBar from "./TopBar";
import {
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom";
import PaginatedActivities from "components/PaginatedActivities/PaginatedActivities";
import { Overview } from "./Overview";

const ChainDetails = ({ metadata, chainType, chainId }) => {
  let match = useRouteMatch();
  return (
    <div className={classes.protocol_details}>
      <TopBar />
      <div>
        <Switch>
          {/* update src/utils/constants.js if you add new routes */}
          <Route path={`${match.path}/activities`}>
            <PaginatedActivities
              activeChainId={chainId}
              type="chain"
              identifier={chainId}
              chainType={chainType}
            />
          </Route>

          <Route path={`${match.path}/overview`}>
            <Overview
              metadata={metadata}
              chain_id={chainId}
              chainType={chainType}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default ChainDetails;
