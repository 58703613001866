import classes from "../WalletsPage.module.css";
import WalletHeader from "../components/walletsHeader";
import WalletsTableHeader from "../components/walletsTableHeader";
import classesTable from "../components/walletTableHeader.module.css";
import CommunitySourceWalletRowTile from "./CommunitySourceWalletRowTile";
const CommunitySourcesWallet = ({ bundleData, wallet }) => {
  return (
    <div className={classes.walletsBox}>
      <WalletHeader title={"Community Sources for this Wallet"} />

      <div className={classesTable.table}>
        <WalletsTableHeader
          items={[
            { name: "NAME", flex: 4 },
            { name: "REASON", flex: 4 },
            { name: "ADDED ON", alignment: "right", flex: 3 },
          ]}
        />

        {Object.keys(wallet.sourced_by).map((key, index) => (
          <div
            key={`${key}_${index}`}
            className={`${classesTable.tableRow} ${classesTable.tableList}`}>
            <CommunitySourceWalletRowTile
              bundleData={bundleData}
              wallet={wallet}
              sourcedByKey={key}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommunitySourcesWallet;
