import {
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import React from "react";

const ZxBanner = ({ icon, background, textColor, children }) => {
  return (
    <PaddingComponent
      backgroundColor={background}
      borderRadius="8px"
      padding="12px">
      <CustomRow gap="8px">
        {icon}
        <p style={{ color: textColor, fontSize: "13px", lineHeight: "18px" }}>
          {children}
        </p>
      </CustomRow>
    </PaddingComponent>
  );
};

export default ZxBanner;
