const RenderQueryTitle = ({ title }) => {
  if (!title) return null;
  const regex = /{{[^{]*}}+/g;
  let match;
  let lastIndex = 0; // Keep track of the last index we parsed
  const elements = [];
  do {
    match = regex.exec(title);
    if (match !== null) {
      const match_string = match[0];
      const index = match.index;

      // Adding condition to prevent the empty span before the description
      const substr = title.substring(lastIndex, index);
      if (substr?.length) {
        elements.push(
          <span
            key={lastIndex}
            style={{
              color: "var(--text-1)",
              fontSize: "13px",
              fontFamily: "Roboto",
              fontWeight: "600",
              lineHeight: "150%",
              letterSpacing: "-0.26px",
            }}>
            {substr}
          </span>
        );
      }

      elements.push(
        <span
          key={index}
          style={{
            color: "var(--text-2)",
            fontSize: "13px",
            fontFamily: "Roboto",
            fontWeight: 600,
            lineHeight: "150%",
            letterSpacing: "-0.26px",
            textTransform: "Capitalize",
            borderRadius: "2px",
            border: "1px solid var(--border-dark, rgba(104, 118, 132, 0.10))",
            background: "var(--elevation-1, rgba(104, 118, 132, 0.04))",
            padding: "1px 4px",
            marginLeft: "4px",
            marginRight: "4px",
          }}>
          {match_string.slice(2, -2)}
        </span>
      );

      lastIndex = index + match_string.length;
    }
  } while (match);

  elements.push(
    <span
      key={lastIndex}
      style={{
        color: "var(--text-1)",
        fontSize: "13px",
        fontFamily: "Roboto",
        fontWeight: "600",
        lineHeight: "150%",
        letterSpacing: "-0.26px",
      }}>
      {title.substring(lastIndex, title.length)}
    </span>
  );

  return <>{elements}</>;
};

export default RenderQueryTitle;
