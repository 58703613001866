import { ClickAwayListener } from "@mui/material";
import { useCallback, useState, useContext } from "react";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import { AuthContext } from "contextStates/AuthContext";
import { ActionsComponent } from "./WalletComponents";
import deleteIcon from "assets/svg/delete.svg";
import DeleteWalletPopUpForm from "./DeleteWalletPopUpForm";

const DeleteBundleButton = ({
  wallet,
  refetch,
  bundleData,
  isUserProfile = true,
  isDisabled = false,
}) => {
  const [showPopUpCard, setShowPopUpCard] = useState(false);
  const { isUserLoggedIn, setShowLoginPopup } = useContext(AuthContext);

  const onDeleteClick = useCallback(() => {
    if (isDisabled) return;
    if (!isUserLoggedIn) {
      setShowLoginPopup(true);
      return;
    }
    setShowPopUpCard(true);
  }, [isUserLoggedIn, setShowLoginPopup, isDisabled]);
  if (showPopUpCard) {
    return (
      <div>
        <ClickAwayListener
          onClickAway={() => {
            setShowPopUpCard(false);
          }}>
          <div>
            <PPLxHoverCard
              open={showPopUpCard}
              content={
                <DeleteWalletPopUpForm
                  bundleData={bundleData}
                  wallet={wallet}
                  refetch={refetch}
                  setShowPopUpCard={setShowPopUpCard}
                  isUserProfile={isUserProfile}
                />
              }
              placement="bottom">
              <div>
                <ActionsComponent
                  actionIconOnClick={onDeleteClick}
                  actionIcon={deleteIcon}
                  actionIconAlt="Remove Wallet"
                />
              </div>
            </PPLxHoverCard>
          </div>
        </ClickAwayListener>
      </div>
    );
  }

  return (
    <ActionsComponent
      actionIcon1Disabled={isDisabled}
      actionIconOnClick={onDeleteClick}
      actionIcon={deleteIcon}
      actionIconAlt="Remove Wallet"></ActionsComponent>
  );
};

export default DeleteBundleButton;
