import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CustomTextFieldWaitlist = styled(TextField)(({ width, height }) => ({
  width: width,
  "& .MuiInputBase-root": {
    height: height,
  },
  "& label": {
    paddingLeft: "6px",
  },
  "& .MuiInputLabel-root": {
    color: "rgba(104, 118, 132, 0.6)",
    fontFamily: "Outfit",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "150%",
    letterSpacing: "0.01em",
    "&.Mui-focused": {
      color: "rgba(104, 118, 132, 1)",
      fontFamily: "Outfit",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "130%",
    },
    "&.Mui-error": {
      color: "rgba(104, 118, 132, 1)",
      fontFamily: "Outfit",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "130%",
    },
  },
  "& input": {
    backgroundColor: "transparent",
    color: "rgba(27, 31, 34, 1)",
    fontFamily: "Outfit",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "150%",
    paddingLeft: "21px",
  },
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(104, 118, 132, 0.1)",
      borderSize: "1px",
      paddingLeft: "14px",
    },
    "&:hover fieldset": {
      borderColor: "rgba(104, 118, 132, 0.3)",
      borderSize: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(6, 72, 215, 0.4)",
      borderSize: "1px",
    },
    "&.Mui-error fieldset": {
      borderColor: "rgba(226, 76, 76, 0.4)",
      borderSize: "1px",
    },
    "& MuiInputBase-input": {
      fontWeight: "500",
    },
  },
}));

export default CustomTextFieldWaitlist;
