import React from "react";
import withLoader from "components/core/withLoader";
import { getTrendingItem } from "./trendingUtils";
import { PaddingComponent } from "components/UI/Components/Components";
import classes from "components/V2/Profile/ProfileDetails/ProfileDetails.module.css";
import TopBar from "./TopBar";
import {
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom";
import TrendingTokenDetails from "./TrendingTokenDetails";
import TrendingDetailsHeader from "./TrendingDetailsHeader";
import TokenLiquidity from "./TokenLiquidity";
import Leaderboard from "./Leaderboard";
import Discussion from "./Discussion";
import { TrendingDetailsGhost } from "./TrendingDetailsGhost";

const TrendingDetails = ({
  queryData: details,
  chainId,
  identifier,
  isLoading,
  isError,
  refetch,
  currentUserIdentifier,
}) => {
  let match = useRouteMatch();

  const { profile, ...remainingDetails } = details;

  return (
    <div>
      <PaddingComponent height="20px" />
      <TrendingDetailsHeader
        isTrendingToken
        isActivityDetails
        profile={profile}
        details={remainingDetails}
        identifier={identifier}
        currentUserIdentifier={currentUserIdentifier}
        chainId={chainId}
      />

      <div className={classes.profile_body}>
        <TopBar url={`/trending_details/${chainId}/${identifier}`} />
        <PaddingComponent height="20px" />
        <Switch>
          <Route path={`${match.path}/details`}>
            <TrendingTokenDetails
              identifier={identifier}
              chainId={chainId}
              details={details}
              isLoading={isLoading}
              isError={isError}
              refetch={refetch}
            />
          </Route>
          <Route path={`${match.path}/liquidity`}>
            <TokenLiquidity identifier={identifier} chainId={chainId} />
          </Route>
          <Route path={`${match.path}/leaderboard`}>
            <Leaderboard identifier={identifier} chainId={chainId} />
          </Route>
          <Route path={`${match.path}/discussion`}>
            <Discussion
              id={details?.id}
              type={details?.type}
              engagements={details?.engagements?.replies?.threads}
              profiles={details?.profiles}
              identities={details?.identities}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default withLoader(TrendingDetails, getTrendingItem, {
  queryKey: (props) => ["trending-item", props.chainId, props.identifier],

  queryOptions: (props) => ({
    enabled: !!props?.identifier,
    retry: 3,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: 10 * 1000,
  }),
  CustomLoader: TrendingDetailsGhost,
});
