import classes from "./AddWallet.module.css";
import {
  CustomText,
  RightPanelCrossIcon,
} from "../../../../../UI/Components/Components";
import { CustomColumn, CustomRow, FlexGrow } from "./AddWalletComponent";
import { useHistory, useParams } from "react-router-dom";
import React, { createContext, useCallback, useContext, useState } from "react";
import CustomLinkTabBar, {
  SIZE,
} from "../../../../../UI/Components/CustomLinkTabBar";
import WalletType from "./WalletType";
import ConnectWallet, { ManualWalletAddressForm } from "./ConnectWallet";
import AddToBundle from "./AddToBundle";
import { ProfileContext } from "../../../../../../contextStates/ProfileContext";
import { useWalletCreate } from "./useWalletCreate";
import buttonClasses from "../../../../../UI/Buttons/buttons.module.css";
import { trackEvent } from "../../../../../../utils/event_tracking";
import Lottie from "react-lottie";
import successAnimation from "../../../../../../assets/animations/success.json";
import { EditWalletForm, SaveWalletButton } from "./EditWalletForm";

export const AddWalletContext = createContext({});

export const EditWalletPanel = ({
  bundleData,
  refetch,
  loggedInUserDetails,
}) => {
  const history = useHistory();
  const params = useParams();

  const value = useWalletCreate({
    bundleData,
    refetch,
    loggedInUserDetails,
    walletAddress: params.address,
    editMode: true,
  });

  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <CustomRow gap="40px" justifyContent="space-between">
          <span className={classes.title}>Edit Wallet</span>
          <RightPanelCrossIcon
            onClick={() => {
              history.replace(`/${params.identifier}/wallets`);
            }}></RightPanelCrossIcon>
        </CustomRow>
      </div>
      <AddWalletContext.Provider value={value}>
        <EditWalletForm />
        <SaveWalletButton editMode={false} />
      </AddWalletContext.Provider>
    </div>
  );
};

export const AddWalletPanel = (props) => {
  const { isLoggedInUserProfile } = useContext(ProfileContext);
  if (isLoggedInUserProfile) {
    return <AddWalletPanelSelf {...props} />;
  } else {
    return <AddWalletPanelOthers {...props} />;
  }
};

const AddWalletPanelOthers = ({ bundleData, refetch, loggedInUserDetails }) => {
  const history = useHistory();
  const params = useParams();
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const value = useWalletCreate({
    bundleData,
    refetch,
    loggedInUserDetails,
  });
  if (showSuccessAnimation) {
    return <SuccessAnimation />;
  }
  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <CustomRow gap="40px" justifyContent="space-between">
          <span className={classes.title}>Add Wallet</span>
          <RightPanelCrossIcon
            onClick={() => {
              history.replace(`/${params.identifier}/wallets`);
            }}></RightPanelCrossIcon>
        </CustomRow>
      </div>
      <AddWalletContext.Provider value={value}>
        <>
          <div className={classes.form}>
            <ManualWalletAddressForm bundleData={bundleData} />
          </div>
          <SaveWalletButton
            isLoggedInUserProfile={value.isLoggedInUserProfile}
            setShowSuccessAnimation={setShowSuccessAnimation}
          />
        </>
      </AddWalletContext.Provider>
    </div>
  );
};

const SuccessAnimation = () => {
  const history = useHistory();
  const params = useParams();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <CustomRow gap="40px" justifyContent="space-between">
          <span className={classes.title}>Add Wallet</span>
          <RightPanelCrossIcon
            onClick={() => {
              history.replace(`/${params.identifier}/wallets`);
            }}></RightPanelCrossIcon>
        </CustomRow>
      </div>
      <div className={classes.animationContainer}>
        <CustomColumn justifyContent="center">
          <Lottie options={defaultOptions} height={280} width={280} />
          <CustomText
            text="Wallet added successfully"
            fontSize="16px"
            fontWeight="600"
          />
        </CustomColumn>
      </div>
    </div>
  );
};
export const AddWalletPanelSelf = ({
  bundleData,
  refetch,
  loggedInUserDetails,
}) => {
  const history = useHistory();
  const params = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const value = useWalletCreate({ bundleData, refetch, loggedInUserDetails });
  if (showSuccessAnimation) {
    return <SuccessAnimation />;
  }
  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <CustomRow gap="40px" justifyContent="space-between">
          <span className={classes.title}>Add Wallet</span>
          <RightPanelCrossIcon
            onClick={() => {
              history.replace(`/${params.identifier}/wallets`);
            }}></RightPanelCrossIcon>
        </CustomRow>
      </div>
      <div className={classes.tabBar}>
        <CustomLinkTabBar
          fullWidth={true}
          isBorderBottom={false}
          size={SIZE.REGULAR}
          validateChange={(index) => {
            if (index === 0) {
              return true;
            } else if (index === 1) {
              return value.isStep1Valid;
            } else if (index === 2) {
              return value.isStep2Valid;
            }
          }}
          shouldHighlightTab={(activeTab, tabIndex) => {
            if (activeTab === tabIndex) return true;
            if (tabIndex === 0) {
              return value.isStep1Valid;
            } else if (tabIndex === 1) {
              return value.isStep2Valid;
            } else if (tabIndex === 2) {
              return value.isStep3Valid;
            }
          }}
          tabsList={[
            <CustomRow gap={"4px"} alignItems="center" margin="0 4px 8px 4px">
              <span
                className={
                  activeTab === 0 ? classes.tabActiveNumber : classes.tabNumber
                }>
                1
              </span>
              Wallet type
            </CustomRow>,
            <CustomRow gap={"4px"} alignItems="center" margin="0 4px 8px 4px">
              <span
                className={
                  activeTab === 1 ? classes.tabActiveNumber : classes.tabNumber
                }>
                2
              </span>
              Connect wallet
            </CustomRow>,
            <CustomRow gap={"4px"} alignItems="center" margin="0 4px 8px 4px">
              <span
                className={
                  activeTab === 2 ? classes.tabActiveNumber : classes.tabNumber
                }>
                3
              </span>
              Add to bundle
            </CustomRow>,
          ]}
          isLinkTabBar={false}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
      <AddWalletContext.Provider value={value}>
        <FlexGrow>
          {activeTab === 0 && <WalletType setActiveTab={setActiveTab} />}
          {activeTab === 1 && <ConnectWallet setActiveTab={setActiveTab} />}
          {activeTab === 2 && (
            <AddToBundle
              setActiveTab={setActiveTab}
              setShowSuccessAnimation={setShowSuccessAnimation}
            />
          )}
        </FlexGrow>
      </AddWalletContext.Provider>
    </div>
  );
};

const AddWalletButton = () => {
  const params = useParams();
  const history = useHistory();
  const onClick = useCallback(() => {
    let link = "/" + params.identifier + "/wallets/add-wallet";
    history.replace(link);
    trackEvent("initiate_add_wallet_flow", { is_edit: false });
  }, [params.identifier, history]);
  return (
    <CustomRow gap="4px" alignItems="center" cursor="pointer" onClick={onClick}>
      <button onClick={onClick} className={buttonClasses.primaryButton}>
        Add Wallet
      </button>
    </CustomRow>
  );
};

export default AddWalletButton;
