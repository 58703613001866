import React from "react";
import PPLxTooltip from "../PPLxTooltip/PPLxTooltip";

const PPLxNameValuePair = ({
  name,
  helpTooltip,
  children,
  spaceBetween,
  noFlex,
}) => {
  return (
    <div
      className={`name-value-pair ${
        noFlex !== undefined ? "no-flex" : "flex"
      } ${spaceBetween ? "space-between" : ""}`}
    >
      <div className="name-value-pair__name">
        {helpTooltip?.length ? (
          <PPLxTooltip title={helpTooltip} placement="top">
            <div className="name-value-pair__name--help-icon">?</div>
          </PPLxTooltip>
        ) : null}
        {name}
      </div>
      <div className="name-value-pair__value">{children}</div>
    </div>
  );
};

export default PPLxNameValuePair;
