import classes from "./TokenChart.module.css";
import withLoader from "../../../core/withLoader";
import GraphLoader from "../../Profile/ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";
import { getTokenMetadata } from "../../../../api/contract.api";
import { useState, useMemo, useEffect, Fragment } from "react";
import Refetch from "components/UI/Refetch/Refetch";
import { useFetchPriceChartData } from "./ChartDataPriceUtils";
import CustomLinkTabBar, {
  SIZE,
} from "components/UI/Components/CustomLinkTabBar";
import { abbreviateNumber } from "utils/misc";
import { Chart } from "utils/constants";
import {
  CustomRow,
  CustomText,
  PaddingComponent,
  PriceChange,
} from "components/UI/Components/Components";
import GraphV2 from "./GraphV2";
import EmptyStateUI from "components/ErrorBoundary/EmptyStateUI";
import Graph from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/Graph";
import { sortGraphDataByTime } from "./chartUtils";

const TABKEYS = {
  Price: "price",
};

const TABLIST = Object.keys(TABKEYS);

const PriceHeader = ({ isLineChart, chartData }) => {
  const lastPrice = isLineChart
    ? chartData?.[chartData.length - 1]?.[1]
    : chartData?.[chartData.length - 1]?.[4];
  if (!lastPrice) return null;
  const firstPrice = isLineChart ? chartData?.[0]?.[1] : chartData?.[0]?.[4];
  const priceDiff = lastPrice - firstPrice;
  const priceDiffPercentage = (priceDiff / firstPrice) * 100;
  const priceChange = {
    value: priceDiff >= 0 ? 1 : -1,
    displayValue: `${abbreviateNumber(priceDiffPercentage)}%`,
  };
  return (
    <CustomRow
      alignItems="center"
      gap="8px"
      overflowY="visible"
      overflowX="visible">
      <CustomText
        text={`$${abbreviateNumber(lastPrice)}`}
        fontSize="24px"
        color="var(--text-1)"
        fontWeight="600"
        lineHeight="28px"
      />
      <PriceChange
        value={priceChange.value}
        displayValue={priceChange.displayValue}
      />
    </CustomRow>
  );
};

export const TokenChartRenderer = ({
  slug,
  showStats,
  chartStats,
  modalCloseButton,
  headerContent,
  setChartStats,
  isRightPane,
  isLive,
  geckoTerminalDataAvailable,
  geckoTerminalKey,
  tokenName,
  markers,
  headerComponent,
  paddingBottomToFilters,
  mini = false,
  chainId,
  tokenAddress,
  chartDuration = "7D",
  border = true,
  showPriceHeader = true,
  hideHeaderTab = false,
}) => {
  const [tab, setTab] = useState(0);
  const [duration, setDuration] = useState(Chart.DURATION[chartDuration]);
  const [isLineChart, setIsLineChart] = useState(false);
  const {
    data: tokenChartData,
    isLoading,
    isSuccess,
    isError,
    refetch,
    isFetching,
  } = useFetchPriceChartData(
    slug,
    geckoTerminalKey,
    Chart.DURATION_DAYS[duration],
    isLineChart,
    chainId,
    tokenAddress
  );
  const chartData = useMemo(
    () => (isLineChart ? tokenChartData?.prices : tokenChartData),
    [isLineChart, tokenChartData]
  );
  const hasPriceChart =
    slug ||
    (chainId && tokenAddress) ||
    geckoTerminalDataAvailable ||
    (isSuccess && !chartData?.length);
  useEffect(() => {
    if (!!chartStats) {
      return;
    }
    if (!hasPriceChart) {
      setChartStats?.({ available: false });
    } else if (!isFetching && chartData?.length) {
      // sorted in time
      const [latestTs, latestPrice] = chartData[chartData.length - 1];
      const dayAgoTs = latestTs - 24 * 60 * 60 * 1000;
      // find the price at the start of the day
      const dayAgoPrice = chartData.reduce(
        (prevPrice, [ts, price]) => {
          if (ts <= dayAgoTs) {
            return price;
          }
          return prevPrice;
        },
        chartData[0][1] // oldest price from this chart
      );

      const pricePercentageChange = Math.abs(
        ((latestPrice - dayAgoPrice) / dayAgoPrice) * 100
      );
      const changeDisplayValue = `${abbreviateNumber(pricePercentageChange)}%`;
      const changeDict = {
        value: pricePercentageChange,
        display_value: changeDisplayValue,
        direction: latestPrice >= dayAgoPrice ? 1 : -1,
      };
      const priceDollarValue = `$${abbreviateNumber(latestPrice)}`;
      setChartStats?.({
        available: true,
        price: priceDollarValue,
        change: changeDict,
      });
    } else if (!isFetching) {
      setChartStats?.({ available: false });
    }
    return () => {
      setChartStats?.({ available: false });
    };
  }, [chartStats, hasPriceChart, chartData, setChartStats, isFetching]);
  if (!hasPriceChart) return null;
  return (
    <div
      className={classes.profile_graphs}
      style={{
        backgroundColor: mini ? "transparent" : "var(--bg-2)",
        border: mini || !border ? "none" : "1px solid var(--border-dark)",
        borderRadius: mini || !border ? "non" : "6px",
      }}>
      {showStats && !mini && (
        <CustomRow
          padding="16px 20px 0px 20px"
          alignItems="center"
          overflowY="visible"
          justifyContent="space-between">
          <PaddingComponent padding="2px 0">
            <CustomText
              color="var(--text-1)"
              textAlign="left"
              fontSize="24px"
              fontWeight="600"
              text={chartStats?.price}
            />
          </PaddingComponent>
          {modalCloseButton}
        </CustomRow>
      )}
      {!mini && headerComponent}
      {!mini && (
        <div
          className={`${classes.header} ${showStats && classes.paddingTop}`}
          style={{
            paddingBottom: paddingBottomToFilters,
          }}>
          <div className={classes.left_panel}>
            {headerContent ?? !hideHeaderTab ? (
              <CustomLinkTabBar
                activeTab={tab}
                tabsList={TABLIST}
                isLinkTabBar={false}
                setActiveTab={(index) => {
                  setTab(index);
                }}
                isBorderBottom={false}
                position="top"
                size={SIZE.LARGE}
              />
            ) : null}
            {showPriceHeader && (
              <PriceHeader isLineChart={isLineChart} chartData={chartData} />
            )}
          </div>
          <div className={classes.right_panel}>
            {Object.keys(Chart.DURATION).map((key) => {
              return (
                <span
                  className={`${classes.duration} ${
                    Chart.DURATION[key] === duration
                      ? classes.duration_active
                      : ""
                  }`}
                  onClick={() => setDuration(Chart.DURATION[key])}
                  key={key}>
                  {Chart.DURATION[key]}
                </span>
              );
            })}
          </div>
        </div>
      )}

      <div className={classes.graphContainer}>
        <TokenChartStatusRenderer
          {...{
            chartData,
            isError,
            isLoading,
            isFetching,
            tokenName,
            markers,
            mini,
            setIsLineChart,
            isLineChart,
            refetch,
          }}
        />
      </div>
    </div>
  );
};

const TokenChartStatusRenderer = ({
  chartData,
  isError,
  isLoading,
  isFetching,
  tokenName,
  markers,
  mini,
  setIsLineChart,
  isLineChart,
  refetch,
}) => {
  if (isLoading || isFetching) {
    return <GraphLoader />;
  }

  if (isError) {
    return (
      <>
        <PaddingComponent height="16px" />
        <Refetch
          onClick={() => {
            refetch();
          }}
          isLoading={isFetching}
          containerClass={classes.graph_component}
        />
      </>
    );
  }

  if (!chartData || !Object.keys(chartData).length) {
    return (
      <>
        <PaddingComponent height="16px" />
        <EmptyStateUI
          text="Token Chart not available"
          fullPage={true}></EmptyStateUI>
      </>
    );
  }

  if (mini) {
    //modify the graph data to be a mini graph
    const miniGraphData = chartData.map((data) => {
      return {
        x: data[0] / 1000,
        y: {
          value: data[1],
        },
      };
    });

    return (
      <Graph
        graphData={{
          data: sortGraphDataByTime(miniGraphData, "desc"),
        }}
        margin={{ left: 0, right: 0, bottom: 0, top: 0 }}
        parentWidth={266}
        parentHeight={80}
        isXAxisVisible={false}
        isYAxisVisible={false}
        horizontalGridLines={0}
        verticalGridLines={0}
        gradientFill={true}
      />
    );
  }
  return (
    <GraphV2
      data={chartData}
      name={tokenName}
      markers={markers}
      mini={mini}
      setIsLineChart={setIsLineChart}
      isLineChart={isLineChart}
    />
  );
};

const getGeckoTerminalKey = (tokenMetaData) => {
  return [
    tokenMetaData?.gecko_terminal_network,
    tokenMetaData?.gecko_terminal_pool,
    tokenMetaData?.gecko_terminal_base_or_quote,
  ];
};

///gets token slugs from token_metadata api and calls TokenChartRenderer
const TokenChart = ({
  queryData,
  chartStats,
  setChartStats,
  headerContent,
  isLive = false,
  isRightPane = false,
  showStats = false,
  modalCloseButton,
  disableLineCandleToggle = false,
  onlyUseExistingData = false,
  existingGraphData,
  customDisplayFunction,
  overrideDuration = false,
  overrideDurationValue = null,
  setOverrideDurationValue = null,
  overrideLoading = false,
  excludeDurations = [],
  chainId,
  address,
  border = true,
  showPriceHeader = false,
  hideHeaderTab = false,
}) => {
  const geckoTerminalKey = useMemo(
    () => getGeckoTerminalKey(queryData),
    [queryData]
  );
  const geckoTerminalDataAvailable = geckoTerminalKey.every(
    (key) => key != null
  );
  const tokenSlug = queryData?.coingecko_slug;
  if (onlyUseExistingData) {
    if (overrideLoading) {
      return <GraphLoader />;
    }
    return existingGraphData?.length > 0 ? (
      <div className={classes["existing-data-chart-container"]}>
        {overrideDuration && overrideDurationValue && (
          <div className={classes.duration_panel}>
            {Object.keys(Chart.DURATION)
              .filter((k) => {
                return !excludeDurations.includes(Chart.DURATION[k]);
              })
              .map((key) => {
                return (
                  <span
                    className={`${classes.duration} ${
                      Chart.DURATION[key] === overrideDurationValue
                        ? classes.duration_active
                        : ""
                    }`}
                    onClick={() =>
                      setOverrideDurationValue(Chart.DURATION[key])
                    }
                    key={key}>
                    {Chart.DURATION[key]}
                  </span>
                );
              })}
          </div>
        )}
        <GraphV2
          data={existingGraphData}
          isLineChart={disableLineCandleToggle}
          disableLineCandleToggle={disableLineCandleToggle}
          customDisplayFunction={customDisplayFunction}
          gradientFill={true}
        />
      </div>
    ) : null;
  }

  return (
    <TokenChartRenderer
      slug={tokenSlug}
      tokenName={queryData?.name}
      geckoTerminalDataAvailable={geckoTerminalDataAvailable}
      geckoTerminalKey={geckoTerminalKey}
      showStats={showStats}
      chartStats={chartStats}
      modalCloseButton={modalCloseButton}
      headerContent={headerContent}
      setChartStats={setChartStats}
      isRightPane={isRightPane}
      metadata={queryData}
      isLive={isLive}
      chainId={chainId}
      tokenAddress={address}
      border={border}
      showPriceHeader={showPriceHeader}
      hideHeaderTab={hideHeaderTab}
    />
  );
};

export default withLoader(TokenChart, getTokenMetadata, {
  queryKey: (props) => ["token", props.chainId, props.address],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  CustomLoader: GraphLoader,
});
