import Image, { IMAGE_TYPES } from "components/UI/Image";
import classes from "components/V2/Profile/ProfileHeader/ProfileInfo.module.css";
import Bio from "components/V2/Profile/ProfileHeader/Bio";
import ActivityEngagements from "components/ActivityEngagements/ActivityEngagements";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import ChainIcon from "components/UI/ChainIcon";
import ConditionalLink from "shared/ConditionalLink";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import {
  CustomButton,
  CustomRow,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import { Wallet } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { getTrendingAdditionalInfo } from "./trendingUtils";
import { getNetworthData } from "api/profile.api";
import { getEngagementsQueryKey } from "components/ActivityEngagements/utils";

const TrendingDetailsHeader = ({
  identifier,
  chainId,
  profile,
  details,
  currentUserIdentifier,
}) => {
  let match = useRouteMatch();

  const { data: additionalInfo } = useQuery({
    queryKey: ["trending-item-additional-info", identifier, chainId],
    queryFn: () => getTrendingAdditionalInfo({ identifier, chainId }),
    retry: 1,
  });

  const { data: netWorth } = useQuery({
    queryKey: ["networth", currentUserIdentifier],
    queryFn: () => getNetworthData({ identifier: currentUserIdentifier }),
    retry: 1,
  });

  const netWorthUsdValue =
    netWorth?.data?.data?._Any?.total_usd_value.display_value;

  return (
    <div className={classes.profile_info}>
      <div className={classes.top_panel}>
        <ConditionalLink to={profile.link} className={classes.top_panel}>
          <Image
            src={profile.display_picture}
            className={classes.profile_pic}
            imageType={IMAGE_TYPES.TOKEN}
            alt={profile.display_name || profile.title || profile.address}
          />
          <div className={classes.left_panel}>
            <div className={classes.title_panel}>
              <div className={classes.title_row}>
                <span className={classes.title}>
                  {profile?.token_details?.symbol}
                </span>
                {profile?.address_chain && (
                  <ChainIcon
                    chainId={profile?.address_chain}
                    className={classes.chain_icon}
                  />
                )}
                <VerifiedBadge profile={profile} />
              </div>
              <div className={classes.aliases}>
                <span className={classes.alias}>
                  {profile.token_details?.name}
                </span>
              </div>
            </div>
          </div>
        </ConditionalLink>
        <div className={classes.verticalDivider} />
        <div className={classes.right_panel}>
          {!!details?.engagements && (
            <ActivityEngagements
              marginTop="0"
              className={classes.engagementsWrapper}
              contentId={details?.id}
              type={details?.type}
              contentTimestamp={details?.timestamp}
              engagements={details?.engagements}
              queryKey={getEngagementsQueryKey(details.type, details.id)}
              hideItems={{
                comments: true,
                reposts: true,
              }}
              customShareLink={match.url}
              shouldDirectlyCopy
              hideCount
              isTrendingActivity
              hasRoundedButtons
            />
          )}
        </div>
      </div>
      <div className={classes.bio}>
        <Bio bio={profile.bio} />
      </div>
      {(additionalInfo?.buy_url || additionalInfo?.sell_url) && (
        <>
          <PaddingComponent height="10px" />
          <CustomRow justifyContent="space-between" gap="16px">
            <CustomRow gap="16px" alignItems="center" flexGrow="1">
              {/* TODO(shaheer): build wallet page once design is ready */}
              <CustomButton
                fontSize="15px"
                color="var(--text-2)"
                background="var(--elevation-1)"
                fontWeight="400"
                style={{ cursor: "auto" }}
                text={
                  <CustomRow gap="4px" alignItems="center">
                    <Wallet size={22} color="var(--text-2)" />
                    {netWorthUsdValue}
                  </CustomRow>
                }
              />
              <Divider />
            </CustomRow>
            <CustomRow gap="8px" alignItems="center">
              {!!additionalInfo?.buy_url?.length && (
                <ConditionalLink
                  sameTab={false}
                  to={{ pathname: additionalInfo?.buy_url }}>
                  <CustomButton
                    fontSize="15px"
                    color="var(--text-white)"
                    fontWeight="600"
                    text="BUY"
                  />
                </ConditionalLink>
              )}

              {!!additionalInfo?.sell_url?.length && (
                <ConditionalLink
                  sameTab={false}
                  to={{ pathname: additionalInfo?.sell_url }}>
                  <CustomButton
                    fontSize="15px"
                    color="var(--text-white)"
                    fontWeight="600"
                    text="SELL"
                  />
                </ConditionalLink>
              )}
            </CustomRow>
          </CustomRow>
        </>
      )}
    </div>
  );
};

export default TrendingDetailsHeader;
