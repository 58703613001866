import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
  CustomText,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import {
  Table,
  TableCell,
  TableRow,
  TableHeader,
} from "components/UI/Table/Table";

export const TransactionsShimmer = () => {
  return (
    <>
      <CustomColumn
        margin="16px 0"
        background="var(--base)"
        borderRadius="10px"
        border="1px solid var(--border-dark)">
        <CustomRow
          alignItems="center"
          justifyContent="space-between"
          padding="16px 20px">
          <CustomText
            text="Recent Transaction"
            color="var(--text-1)"
            fontSize="15px"
            fontWeight="600"
          />
        </CustomRow>
        <Divider />
        <Table>
          <TableHeader paddingTop background>
            <TableCell longRow alignLeft>
              Details
            </TableCell>
            <TableCell smallRow alignCenter>
              Hash
            </TableCell>
            <TableCell smallRow alignCenter>
              Fiat
            </TableCell>
            <TableCell smallRow alignCenter>
              Time
            </TableCell>
          </TableHeader>
          <TransactionRowShimmer />
          <TransactionRowShimmer />
          <TransactionRowShimmer />
          <TransactionRowShimmer />
          <TransactionRowShimmer />
        </Table>
      </CustomColumn>
      <PaddingComponent height="36px" />
    </>
  );
};

export const TransactionRowShimmer = () => {
  return (
    <TableRow>
      <TableCell longRow>
        <CustomRow width="100%" alignItems="center" gap="5px">
          <CustomSkeleton variant="circular" width="30px" height="30px" />
          <CustomSkeleton variant="text" width="25%" />
          <CustomSkeleton variant="text" width="45%" />
        </CustomRow>
      </TableCell>

      <TableCell smallRow alignCenter>
        <CustomSkeleton variant="text" width="60px" />
      </TableCell>
      <TableCell smallRow alignCenter>
        <CustomSkeleton variant="text" width="60px" />
      </TableCell>
      <TableCell smallRow alignCenter>
        <CustomSkeleton variant="text" width="60px" />
      </TableCell>
    </TableRow>
  );
};
