import {
  TableRowItem,
  TableRowTile,
} from "components/UI/Components/Components";

import { getNetWorthV2 } from "utils/profile_utils";
import { TableRowTextComponent } from "components/V2/Profile/ProfileDetails/wallets/components/WalletComponents";
import AddressComponent from "components/UI/Components/AddressComponent";

import VerifiedTag from "components/UI/Components/VerifiedTag";
import WalletMenu from "components/V2/Profile/ProfileDetails/wallets/components/WalletMenu";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";

const MyWalletRowTile = ({ addressInfo, index, refetch, bundleData }) => {
  const networth = getNetWorthV2(addressInfo) ?? "";
  return (
    <TableRowTile>
      <TableRowItem flex={2}>
        <AddressComponent index={index + 1} address={addressInfo?.address} />
      </TableRowItem>
      <TableRowItem flex={2}>
        <TableRowTextComponent text={networth}></TableRowTextComponent>
      </TableRowItem>
      <TableRowItem flex={2}>
        {addressInfo?.is_verified ? (
          <VerifiedTag></VerifiedTag>
        ) : (
          <TableRowTextComponent text={"-"}></TableRowTextComponent>
        )}
      </TableRowItem>
      <TableRowItem
        alignment={"right"}
        flex={2}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <ZxFlex justify="flex-end">
          <WalletMenu
            wallet={addressInfo}
            bundleData={bundleData}
            refetch={refetch}
          />
        </ZxFlex>
        {/* <div className={classes.walletDeleteRow}>
          <Wall
        </div> */}
      </TableRowItem>
    </TableRowTile>
  );
};

export default MyWalletRowTile;
