import React, { useEffect, useRef } from "react";
import styles from "./searchResults.module.css";
import SearchResultItem from "./SearchResultItem";
import RecentSearches from "./RecentSearches";
import {
  useKeyboardNavigation,
  useRecentSearchResults,
} from "./searchbar_utils";
import SearchFilters from "./SearchFilters";
import SearchGhostItem from "./SearchGhostItem";
import { Divider, PaddingComponent } from "components/UI/Components/Components";
import SearchNoResult from "./SearchNoResult";
import { trackEvent } from "utils/event_tracking";
import { v4 as uuidv4 } from "uuid";

const SearchResults = ({
  searchResults,
  isSearchInputFocused,
  setIsSearchInputFocused,
  handleSearchBlur,
  searchTerm,
  isSearchLoading = false,
  filters,
  setFilters,
}) => {
  const searchUuid = useRef(null);
  const resultsCount = searchResults.length;
  const filteredSearchResult =
    resultsCount > 5 ? searchResults.slice(0, 5) : searchResults;

  if (resultsCount > 5) {
    //get selected filters
    const selectedFilters = filters.filter((filter) => filter.isSelected);
    //join this list in string with ,
    const selectedFiltersString = selectedFilters
      .map((filter) => filter.name)
      .join(",");
    filteredSearchResult.push({
      type: "see_all",
      label: `See all results (${resultsCount})`,
      data: {
        link: `/search?query=${searchTerm}&filters=${selectedFiltersString}`,
      },
      searchTerm,
    });
  }

  useEffect(() => {
    if (!isSearchLoading && searchResults?.length > 0) {
      const uuid = uuidv4();
      searchUuid.current = uuid;
      trackEvent("search_query", {
        search_term: searchTerm,
        search_results: filteredSearchResult.map((result) => {
          return {
            type: result.type,
            data: {
              id: result.data.id,
              title: result.data.title,
              address: result.data.address,
              display_name: result.data.display_name,
            },
          };
        }),
        uuid: uuid,
      });
    }
  }, [isSearchLoading, searchResults, filteredSearchResult, searchTerm]);

  const { recentSearchResults } = useRecentSearchResults();

  const { selectedResultIndex } = useKeyboardNavigation({
    filteredSearchResult: searchTerm?.length
      ? filteredSearchResult
      : recentSearchResults,
    isSearchInputFocused,
    handleSearchBlur,
    searchUuid,
  });

  if (
    !!searchTerm &&
    searchResults?.length === 0 &&
    !isSearchLoading &&
    isSearchInputFocused
  ) {
    return (
      <div className={styles.searchResultsContainer}>
        <SearchFilters filters={filters} setFilters={setFilters} />
        <Divider />
        <PaddingComponent height="16px" />
        <SearchNoResult />
      </div>
    );
  }

  if (searchResults?.length === 0 && !isSearchLoading) {
    return (
      <RecentSearches
        selectedResultIndex={selectedResultIndex}
        setIsSearchInputFocused={setIsSearchInputFocused}
        recentSearchResults={recentSearchResults}
      />
    );
  }
  return (
    <div className={styles.searchResultsContainer}>
      <SearchFilters filters={filters} setFilters={setFilters} />
      <Divider />

      {isSearchLoading &&
        [...Array(5).keys()].map((index) => {
          return <SearchGhostItem key={index} />;
        })}
      {!isSearchLoading &&
        filteredSearchResult.map((result, index) => (
          <SearchResultItem
            selected={selectedResultIndex === index}
            index={index}
            searchUuid={searchUuid.current}
            key={`search-result-item-${index}`}
            result={result}
            setIsSearchInputFocused={setIsSearchInputFocused}
          />
        ))}
    </div>
  );
};

export default SearchResults;
