import {
  CustomRow,
  CustomText,
  RedirectLink,
} from "components/UI/Components/Components";

import classes from "./trendingTokenDetails.module.css";
import { useMemo } from "react";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { timeAgo } from "utils/misc";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";

const TransferItem = ({ transfer, profiles }) => {
  const profile = useMemo(
    () => profiles?.[transfer?.actor_addr],
    [profiles, transfer?.actor_addr]
  );

  if (!transfer || !profile) {
    return null;
  }

  const txnLink = `/tx_id?chain_id=${transfer?.chain_id}&block_number=${transfer?.block_number}&tx_idx=${transfer?.tx_idx}&actor=${transfer?.actor_addr}`;
  return (
    <CustomRow alignItems="center" justifyContent="space-between">
      <CustomRow alignItems="center" gap="8px">
        <RedirectLink link={profile?.link}>
          <ProfileHoverCard actor={profile}>
            <CustomRow alignItems="center" gap="4px">
              <Image
                src={profile?.display_picture}
                imageType={IMAGE_TYPES.SMALL_AVATAR}
                className={`${classes.transferAvatar} ${
                  transfer?.direction === "sold" ? classes.sold : ""
                }`}
                alt=""
              />
              <div className={`${classes.actorName} ${classes.textOverflow}`}>
                {profile?.display_name}
              </div>
              <VerifiedBadge profile={profile} />
            </CustomRow>
          </ProfileHoverCard>
        </RedirectLink>

        <RedirectLink link={txnLink}>
          <CustomText
            className={classes.textOverflow}
            text={transfer?.description}
            textAlign="left"
            fontSize="15px"
            color="var(--text-2)"
          />
        </RedirectLink>
      </CustomRow>
      <CustomText
        fontSize="15px"
        text={timeAgo(transfer?.timestamp * 1000)}
        whiteSpace="nowrap"
      />
    </CustomRow>
  );
};

export default TransferItem;
