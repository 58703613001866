import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CustomTextField = styled(TextField)((props) => ({
  "& .MuiInputBase-root": {
    outline: "none",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  "& .MuiInputLabel-outlined": {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    letterSpacing: "-0.02em",
    fontSize: "15px",
    paddingLeft: "16px",
    outline: "none",
    paddingTop: "16px",
    paddingBottom: "16px",
    borderColor: "transparent",
    borderRadius: 0,
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(5px, -9px) scale(0.9)",
  },
  "& ": {
    outline: "none",
    border: "0",
  },
  "& input": {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: props.fontSize ?? "15px",
    lineHeight: "150%",
    paddingLeft: props.paddingLeft ?? "12px",
    color: "var(--text-1)",
    border: "0px solid transparent",
    outline: "none",
  },
  "& .MuiInputBase-root:before, .MuiInputBase-root:after": {
    content: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(104, 118, 132, 0.1)",
      borderSize: "1px",
      paddingLeft: "14px",
    },
    "& fieldset,  &.MuiOutlinedInput-root.Mui-focused fieldset, .MuiOutlinedInput-root.Mui-focused:hover fieldset ":
      {
        ...(props.errorState
          ? { borderColor: "var(--error)", outline: "none" }
          : {}),
      },
    "&:hover fieldset": {
      borderColor: "rgba(104, 118, 132, 0.3)",
      borderSize: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(6, 72, 215, 0.24)",
      borderSize: "1px",
    },
    "& MuiInputBase-input": {
      fontWeight: "500",
    },
    "& .MuiIconButton-root": {
      color: "var(--text-2)",
    },
    "& .Mui-disabled": {
      color: "var(--text-2)",
      WebKitTextFillColor: "var(--text-2)",
    },
    "& .Mui-disabled.MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(104, 118, 132, 0.1)",
    },
  },

  ...(props.hideOutline
    ? {
        "& .MuiOutlinedInput-notchedOutline, & .Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: "0px solid transparent !important",
          },
      }
    : {}),

  backgroundColor: "var(--base)",
  borderRadius: "4px",
}));

export default CustomTextField;
