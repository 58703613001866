export const CODEX_BARS_QUERY = `query GetBars($symbol: String!, $countback: Int, $from: Int!, $to: Int!, $resolution: String!, $currencyCode: String, $quoteToken: QuoteToken, $statsType: TokenPairStatisticsType, $removeLeadingNullValues: Boolean, $removeEmptyBars: Boolean) {
    getBars(
      symbol: $symbol
      countback: $countback
      from: $from
      to: $to
      resolution: $resolution
      currencyCode: $currencyCode
      quoteToken: $quoteToken
      statsType: $statsType
      removeLeadingNullValues: $removeLeadingNullValues
      removeEmptyBars: $removeEmptyBars
    ) {
      s
      o
      h
      l
      c
      t
      volume
      __typename
    }
  }`;

export const CODEX_SOCKET_QUERY = `subscription UpdateAggregateBatch($pairId: String, $quoteToken: QuoteToken, $statsType: TokenPairStatisticsType) {
    onBarsUpdated(pairId: $pairId, quoteToken: $quoteToken, statsType: $statsType) {
      eventSortKey
      networkId
      pairAddress
      pairId
      timestamp
      quoteToken
      aggregates {
        ...ResolutionBarFields
        __typename
      }
      __typename
    }
  }
  
  fragment ResolutionBarFields on ResolutionBarData {
    r1S {
      ...CurrencyBarFields
      __typename
    }
    r5S {
      ...CurrencyBarFields
      __typename
    }
    r15S {
      ...CurrencyBarFields
      __typename
    }
    r30S {
      ...CurrencyBarFields
      __typename
    }
    r1 {
      ...CurrencyBarFields
      __typename
    }
    r5 {
      ...CurrencyBarFields
      __typename
    }
    r15 {
      ...CurrencyBarFields
      __typename
    }
    r30 {
      ...CurrencyBarFields
      __typename
    }
    r60 {
      ...CurrencyBarFields
      __typename
    }
    r240 {
      ...CurrencyBarFields
      __typename
    }
    r720 {
      ...CurrencyBarFields
      __typename
    }
    r1D {
      ...CurrencyBarFields
      __typename
    }
    r7D {
      ...CurrencyBarFields
      __typename
    }
    __typename
  }
  
  fragment CurrencyBarFields on CurrencyBarData {
    t
    usd {
      ...IndividualBarFields
      __typename
    }
    token {
      ...IndividualBarFields
      __typename
    }
    __typename
  }
  
  fragment IndividualBarFields on IndividualBarData {
    t
    o
    h
    l
    c
    volume
    volumeNativeToken
    buys
    buyers
    buyVolume
    sells
    sellers
    sellVolume
    liquidity
    traders
    transactions
    __typename
  }`;

export const CHARTS_BACKEND_URLS = {
  GECKO_TERMINAL: "",
  BIRDEYE: "https://odd-mountain-7abe.subscriptions-95d.workers.dev/defi/ohlcv",
  CODEX: "https://square-dew-e2a0.subscriptions-95d.workers.dev/",
  BIRDEYE_SOCKET: `wss://odd-mountain-7abe.subscriptions-95d.workers.dev`,
  CODEX_SOCKET: `wss://square-dew-e2a0.subscriptions-95d.workers.dev/`,
};

export const CHART_INIT_SETTINGS = {
  session: "24x7",
  minmov: 0.000001,
  pricescale: 1000,
  has_intraday: true,
  supported_resolutions: ["1", "5", "15", "30", "60", "240", "720", "1D"],
  volume_precision: 2,
};
