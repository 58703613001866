import Loader from "components/UI/Loader/Loader";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getBacklink } from "api/feed.api";

const RedirectPage = ({ link }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["redirect", { link }],
    queryFn: () => getBacklink({ id: link }),
    retry: 2,
  });
  const history = useHistory();
  useEffect(() => {
    if (data && !isLoading && !isError) {
      history.push(data);
    }
    if (isError) {
      history.push("/home");
    }
  }, [data, isLoading, isError, history]);
  return <Loader />;
};

export default RedirectPage;
