import classes from "../ScreenerRightPanel.module.css";
import {
  PaddingComponent,
  CustomColumn,
  CustomRow,
  FlexGrow,
} from "components/UI/Components/Components";
import CheckboxText from "./CheckboxText";

const EditBasicColumns = ({
  updateFollowersState,
  updateFollowingState,
  isFollowersSelected,
  setIsFollowersSelected,
  isFollowingSelected,
  setIsFollowingSelected,
}) => {
  return (
    <CustomColumn>
      <PaddingComponent paddingBottom="16px">
        <CustomRow alignItems="center" gap="16px">
          <span className={classes.header}>BASIC</span>
          <FlexGrow>
            <div className={classes.centerDivider} />
          </FlexGrow>
        </CustomRow>
      </PaddingComponent>
      <CheckboxText
        isActive={isFollowersSelected}
        setIsActive={(val) => {
          setIsFollowersSelected(val);
          updateFollowersState(val);
        }}
        text="Followers"
      />
      <PaddingComponent height="20px" />
      <CheckboxText
        isActive={isFollowingSelected}
        setIsActive={(val) => {
          setIsFollowingSelected(val);
          updateFollowingState(val);
        }}
        text="Following"
      />
      <PaddingComponent height="42px" />
    </CustomColumn>
  );
};

export default EditBasicColumns;
