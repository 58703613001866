import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  FlexBox,
  PaddingComponent,
} from "components/UI/Components/Components";

import withLoader from "components/core/withLoader";
import React, { useState, useEffect } from "react";
import { getLatestBlocks } from "./api";
import { referenceTimeAgo } from "utils/misc";
import Draggable from "components/Draggable";
import { Cube, CheckCircle, Hourglass, Spinner } from "@phosphor-icons/react";
import ConditionalLink from "shared/ConditionalLink";
import classes from "./styles.module.css";
import { BlocksShimmer } from "./Shimmers/BlockShimmer";
import { useHistory } from "react-router-dom";
const Blocks = ({ chainLogo, chain_id, queryData, chainType }) => {
  const [blocks, setBlocks] = useState(queryData);
  const [blocksCount, setBlocksCount] = useState(queryData.length);
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    const getLatestData = async (chain_id, start_block_number, chainType) => {
      try {
        const data = await getLatestBlocks(
          chain_id,
          start_block_number,
          chainType
        );
        setAnimate(true);
        setBlocksCount(data.length);
        setBlocks((prev) => {
          return [...data, ...prev];
        });
      } catch (error) {
        setBlocks((prev) => {
          return [...prev];
        });
      }
    };
    const addBocks = setTimeout(() => {
      getLatestData({
        chain_id,
        start_block_number: blocks[0]?.number?.value + 1,
        chainType,
      });
    }, 1000);
    return () => {
      clearTimeout(addBocks);
    };
  }, [blocks, chainType, chain_id]);

  return (
    <>
      <CustomColumn
        margin="16px 0"
        background="var(--base)"
        borderRadius="10px"
        border="1px solid var(--border-dark)">
        <CustomRow
          alignItems="center"
          justifyContent="space-between"
          padding="16px 20px">
          <CustomText
            text="Recent Blocks"
            color="var(--text-1)"
            fontSize="15px"
            fontWeight="600"
          />
          <ConditionalLink to={`blocks`}>
            <CustomText text="View All" fontWeight="600" />
          </ConditionalLink>
        </CustomRow>
        <PaddingComponent padding="0px 20px">
          <Divider />
        </PaddingComponent>
        <PaddingComponent height="20px" />
        <BlocksContainer
          blocksCount={blocksCount}
          chainType={chainType}
          blocks={blocks}
          animate={animate}
          chainId={chain_id}
          chainLogo={chainLogo}
        />
      </CustomColumn>
    </>
  );
};

export const BlocksContainer = ({
  blocksCount,
  blocks,
  chainType,
  animate,
  chainId,
  chainLogo,
}) => {
  const [now, setNow] = useState(new Date());
  const [wasScrolled, setWasScrolled] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  useEffect(() => {
    const resetNow = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(resetNow);
  });

  return (
    <Draggable setWasScrolled={setWasScrolled} setIsScrolling={setIsScrolling}>
      <CustomRow
        overflowX="auto"
        gap="10px"
        padding="0 0 16px 0"
        margin="0px 0px 20px 20px">
        <BlockCard
          chainType={chainType}
          generating
          lastBlock={blocks[0]?.number?.value + 1}
          blocksCount={blocksCount}
        />
        {blocks?.slice(0, 20).map((item, index) => {
          return (
            <BlockCard
              key={item?.number?.value}
              block={item}
              index={index}
              now={now}
              chainType={chainType}
              blocksCount={blocksCount}
              animate={animate}
              chainId={chainId}
              chainLogo={chainLogo}
              wasScrolled={wasScrolled}
              isScrolling={isScrolling}
            />
          );
        })}
        <PaddingComponent padding="0 16px 0 0" />
      </CustomRow>
    </Draggable>
  );
};

export const BlockCard = ({
  block,
  now,
  index,
  generating = false,
  lastBlock,
  chainType,
  blocksCount,
  animate,
  chainLogo,
  chainId,
  wasScrolled,
  isScrolling,
}) => {
  const [isRowOpen, setIsRowOpen] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (index <= blocksCount) setIsRowOpen(true);
    }, 20);

    return () => clearTimeout(timer);
  }, [blocksCount, index]);
  const history = useHistory();
  const handelBlockClick = () => {
    if (!wasScrolled) history.push(`block/${block.number.value}/details`);
  };

  return (
    <div
      onClick={handelBlockClick}
      className={
        index > blocksCount || generating || !animate || isRowOpen
          ? `${classes.row} ${classes.open}`
          : classes.row
      }>
      <CustomColumn
        flexShrink="0"
        borderRadius="10px"
        padding="10px 12px"
        width="180px"
        height="165px"
        border="1px solid var(--border-dark)"
        opacity={generating ? "0.5" : "1"}
        gap="12px"
        justifyContent="space-between"
        cursor={isScrolling ? undefined : "pointer"}>
        <CustomRow alignItems="center" gap="8px">
          <Cube size={20} color="var(--text-1)" />
          <CustomText
            color="var(--text-1)"
            text={generating ? lastBlock : block?.number?.value}
            fontSize="15px"
            fontWeight="500"
          />
        </CustomRow>
        <CustomColumn gap="4px">
          <BlockInfoRow
            generating={generating}
            leftText="Gas"
            rightText={
              chainType === "COSMOS"
                ? block?.gas?.value?.display_value ?? "-"
                : block?.gas?.native_token_value?.display_value ?? "-"
            }
          />
          <BlockInfoRow
            generating={generating}
            leftText={chainType === "COSMOS" ? "Gas Target" : "Base Fee"}
            rightText={
              chainType === "COSMOS"
                ? block?.gas?.wanted?.display_value ?? "-"
                : block?.base_fee_per_gas?.value?.display_value ?? "-"
            }
          />
          <BlockInfoRow
            generating={generating}
            leftText="Txns"
            rightText={block?.tx_count ?? "-"}
          />
        </CustomColumn>
        <BlockStatusRow block={block} generating={generating} now={now} />
      </CustomColumn>
    </div>
  );
};

const BlockInfoRow = ({ generating, leftText, rightText }) => {
  return (
    <CustomRow
      justifyContent={generating ? "space-between" : ""}
      alignItems="center">
      <FlexBox flex="4">
        <CustomText text={leftText} />
      </FlexBox>
      <PaddingComponent width="12px" />
      <FlexBox flex="5">
        <CustomText
          maxLines={1}
          color={generating ? "var(--text-2)" : "var(--text-1)"}
          text={!generating ? rightText : "-"}
          textAlign="right"
        />
      </FlexBox>
    </CustomRow>
  );
};

export const BlockStatusRow = ({
  block,
  generating,
  now,
  showTimeAgo = true,
  fontSize = "12px",
  fontWeight = "600",
}) => {
  const status = block?.status;
  const timeAgo = referenceTimeAgo({
    date: block?.timestamp * 1000,
    referenceTime: now,
  });
  return (
    <CustomRow alignItems="center" justifyContent="space-between">
      {generating && (
        <BlockStatus
          status={"Generating"}
          color="var(--text-3)"
          icon={<Spinner size={14} weight="bold" color="var(--text-3)" />}
          fontSize={fontSize}
          fontWeight={fontWeight}
        />
      )}
      {status === "confirmed" && (
        <BlockStatus
          status={status}
          color="var(--success)"
          icon={<CheckCircle size={14} weight="bold" color="var(--success)" />}
          fontSize={fontSize}
          fontWeight={fontWeight}
        />
      )}
      {status === "safe" && (
        <BlockStatus
          status={status}
          color="var(--warning)"
          icon={<Hourglass size={14} weight="bold" color="var(--warning)" />}
          fontSize={fontSize}
          fontWeight={fontWeight}
        />
      )}
      {status === "unfinalized" && (
        <BlockStatus
          status={status}
          color="var(--text-2)"
          icon={<Hourglass size={14} weight="bold" color="var(--text-2)" />}
          fontSize={fontSize}
          fontWeight={fontWeight}
        />
      )}

      {showTimeAgo && (
        <CustomText
          color="var(--text-3)"
          fontSize="12px"
          text={
            !generating
              ? `${timeAgo} ${timeAgo === "just now" ? "" : "ago"}`
              : ""
          }
        />
      )}
    </CustomRow>
  );
};

export const BlockStatus = ({ status, color, icon, fontSize, fontWeight }) => {
  return (
    <CustomRow alignItems="center" gap="4px">
      {icon}
      <CustomText
        color={color}
        text={status}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textTransform="capitalize"
      />
    </CustomRow>
  );
};

export default withLoader(Blocks, getLatestBlocks, {
  queryKey: ({ chain_id, chainType }) => [
    "LatestBlock",
    chain_id,
    chainType,
    { chain_id },
  ],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 2,
  renderNotFound: false,
  CustomLoader: BlocksShimmer,
});
