import { CustomRow } from "components/UI/Components/Components";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";

export const NftKeyValue = ({ label, value, showSeparator = false }) => {
  return (
    <CustomRow gap="8px" alignItems="center">
      <ZxText style={TSTYLES.body1} fontSize={14} color="text-2">
        {label}
      </ZxText>
      <ZxText style={TSTYLES.title2} fontSize={15}>
        {value}
      </ZxText>
      {showSeparator && (
        <div
          style={{
            width: "4px",
            height: "4px",
            borderRadius: "50%",
            backgroundColor: "var(--text-3)",
          }}
        />
      )}
    </CustomRow>
  );
};
