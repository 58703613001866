import { signedRequest } from "api/api";

export const getTrendingTransactionsInfo = async ({ identifier, chainId }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/v4/get_recent_whale_and_trending_transfers?token_address=${identifier}&chain_id=${chainId}&refresh=true&num_txns=100`,
  });
  return response?.data?.data;
};

export const getTrendingItem = async ({ identifier, chainId }) => {
  const response = await signedRequest({
    method: "post",
    path: `/api/v4/get_trending_token_data?token_addr=${identifier}&chain_id=${chainId}&refresh_data=true`,
  });
  return response?.data?.data;
};

export const getTrendingAdditionalInfo = async ({ identifier, chainId }) => {
  if (!identifier || !chainId) return null;
  const response = await signedRequest({
    method: "post",
    path: `/api/v4/trending_token_additional_details?contract_address=${identifier}&chain_id=${chainId}`,
  });
  return response?.data?.data;
};

export const getTrendingItemLiquidity = async ({ identifier, chainId }) => {
  const response = await signedRequest({
    method: "post",
    path: `/api/v4/token_liquidity_report?token_address=${identifier}&chain_id=${chainId}`,
  });
  return response?.data?.data;
};

export const getTrendingItemLeaderboard = async ({ identifier, chainId }) => {
  const response = await signedRequest({
    method: "post",
    path: `/api/v4/get_token_leaderboard?token_address=${identifier}&chain_id=${chainId}`,
  });
  return response?.data?.data;
};

export const prefetchTrendingAdditionalInfo = async ({
  identifier,
  chainId,
  queryClient,
}) => {
  await queryClient.prefetchQuery({
    queryKey: ["trending-item-additional-info", identifier, chainId],
    queryFn: () => getTrendingAdditionalInfo({ identifier, chainId }),
    staleTime: 1000 * 60 * 5,
  });
};

const fetchTrendingTimeline = async () => {
  const response = await signedRequest({
    method: "get",
    path: `/api/v4/get_trending_items?page_size=20`,
  });
  return response?.data;
};
export const prefetchTrendingTimeline = async ({ queryClient }) => {
  await queryClient.prefetchInfiniteQuery({
    queryKey: ["my_feed", "trending_timeline"],
    queryFn: () => fetchTrendingTimeline(),
    staleTime: 1000 * 60 * 5,
  });
};
