import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Web3 from "web3";
import { useLocation } from "react-router-dom";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import {
  CustomColumn,
  CustomRow,
  SpinnerComponent,
} from "components/UI/Components/Components";
import { saveSessionDetails, tsNow } from "contextStates/auth_utils";
import { AuthContext } from "contextStates/AuthContext";
import styles from "./DynamicWalletButton.module.css";
import base58 from "bs58";
import { tracker } from "index";
import { logSentryEvent } from "utils/sentry";
import {
  TRACKING_EVENTS,
  mixpanelSetAddress,
  trackEvent,
} from "utils/event_tracking";
import { sendWalletConnectedEvent } from "api/waitlist.api";

const WALLET_STATES = {
  INIT: "INIT",
  SIGNED: "SIGNED",
  CONNECTING: "CONNECTING",
  SIGNING: "SIGNING",
  ERROR: "ERROR",
};

const DynamicWalletButton = ({
  onSuccess,
  isLogin = false,
  customLoading = false,
  customLoadingText = null,
  redirect = true,
  customText = null,
  disabled,
  size = "medium",
  customRender = null,
  setOpenReplayTagOnConnect = false,
  setLocalStorageKeys = isLogin,
  onError = null,
  className = "",
  customClass = "",
  connectOnly = false,
  setPusherChannel = false,
  onConnect = () => {},
  onFinish = () => {},
  onClickEventName = "",
  isOnboarding = false,
}) => {
  const { primaryWallet, setShowAuthFlow, showAuthFlow, handleLogOut } =
    useDynamicContext();
  const { isUserLoggedIn } = useContext(AuthContext);
  // has 5 states from above
  const [status, setStatus] = useState(WALLET_STATES.INIT);
  const location = useLocation();
  const isSigning = useRef(false);
  const { handleErrorSnackbar, isUserAuthenticated, setShowLoginPopup } =
    useContext(AuthContext);
  const [helpMessage, setHelpMessage] = useState(null);
  const signMessage = useCallback(async () => {
    isSigning.current = false;
    if (!primaryWallet) {
      throw new Error("Primary wallet not found");
    }
    const web3 = new Web3(window.ethereum ?? null);
    const { address: sessionAddress, privateKey: sessionPrivateKey } =
      web3.eth.accounts.create();
    const fromAddr = primaryWallet.address;
    const message = {
      app: "0xppl",
      address: fromAddr,
      sessionAddress,
      ts: tsNow(),
    };
    const isSolana = primaryWallet.chain === "SOL";
    const connector = isSolana
      ? primaryWallet.connector
      : primaryWallet.connector.ethers;
    const signer = await connector.getSigner();
    const jsonMessage = JSON.stringify(message);
    const tokenSerialized = isSolana
      ? new TextEncoder().encode(jsonMessage)
      : jsonMessage;
    if (!signer) {
      handleErrorSnackbar("Error in signing message");
      setStatus(WALLET_STATES.ERROR);
      setHelpMessage("Couldn't verify wallet. Please try again");
      return;
    }
    setHelpMessage("Verifying your wallet");
    try {
      const signature = await signer.signMessage(tokenSerialized);
      if (!isSolana) {
        const newSign = web3.eth.accounts.recover(tokenSerialized, signature);
        if (
          web3.utils.toChecksumAddress(newSign) !==
          web3.utils.toChecksumAddress(fromAddr)
        ) {
          setStatus(WALLET_STATES.ERROR);
          handleLogOut();
          setHelpMessage(
            "Signer doesn't match connected wallet, please try again."
          );
          throw new Error(
            "Error signing session. Signer doesn't match connected wallet"
          );
        }
      }
      // @TODO - verify signature for solana
      // if (isSolana) {
      //   const walletIsSigner = nacl.sign.detached.verify(
      //     tokenSerialized,
      //     base58.decode(signature.signature),
      //     base58.decode(signature.publicKey)
      //   );
      //   if (!walletIsSigner) {
      //     throw new Error(
      //       "Error signing session. Signer doesn't match connected wallet"
      //     );
      //   }
      // }
      setHelpMessage("Wallet verified! Waiting for response...");
      const sessionDetails = {
        tokenSerialized: JSON.stringify(message),
        tokenSignature: isSolana
          ? base58.encode(signature.signature)
          : signature,
        userAddress: fromAddr,
        sessionAddress,
        sessionPrivateKey,
        walletType: isSolana ? "SOLANA" : "EVM",
      };
      setStatus(WALLET_STATES.SIGNED);
      try {
        if (setOpenReplayTagOnConnect && tracker?.setUserAnonymousID) {
          tracker?.setUserAnonymousID(`${fromAddr}`);
        }
      } catch (err) {
        logSentryEvent(err, {
          message: "Error setting user anonymous ID",
          setOpenReplayTagOnConnect,
        });
      }
      trackEvent(TRACKING_EVENTS.WALLET_CONNECT.WALLET_CONNECT_STATUS, {
        status: "success",
        selected_wallet_address: primaryWallet?.address,
        selected_wallet_type: primaryWallet?.connector?.name,
        trigger_page: location.pathname,
      });
      trackEvent("wallet_address_connected", {
        isSolana: false,
        address: primaryWallet?.address,
        isOnboarding: true,
      });
      mixpanelSetAddress({ address: primaryWallet?.address });
      // remove wallet from being connected
      handleLogOut();
      //return session details here
      if (setLocalStorageKeys) {
        await saveSessionDetails(sessionDetails);
      }
      if (setPusherChannel) {
        localStorage.setItem("pusherChannelSessionAddressId", fromAddr);
      }
      if (isOnboarding) {
        sendWalletConnectedEvent({ address: fromAddr });
      }
      if (isLogin) {
        await isUserAuthenticated({
          isNavigating: redirect,
        });
        setShowLoginPopup(false);
      }
      if (onSuccess) {
        onSuccess(sessionDetails);
      }
    } catch (err) {
      setHelpMessage("Couldn't verify wallet. Please try again");
      trackEvent(TRACKING_EVENTS.WALLET_CONNECT.WALLET_CONNECT_STATUS, {
        status: "failure",
        selected_wallet_address: primaryWallet?.address,
        selected_wallet_type: primaryWallet?.connector?.name,
        trigger_page: location.pathname,
      });
      handleLogOut();
      onError && onError();
      setStatus(WALLET_STATES.ERROR);
    } finally {
      onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    primaryWallet,
    onFinish,
    setPusherChannel,
    handleLogOut,
    isUserAuthenticated,
    setShowLoginPopup,
    isLogin,
    setOpenReplayTagOnConnect,
    onSuccess,
    setLocalStorageKeys,
    redirect,
    onError,
    location.pathname,
  ]);

  useEffect(() => {
    if (
      primaryWallet &&
      status === WALLET_STATES.CONNECTING &&
      isSigning.current
    ) {
      if (connectOnly) {
        setStatus(WALLET_STATES.SIGNED);
        onSuccess(primaryWallet);
        handleLogOut();
      } else {
        setStatus(WALLET_STATES.SIGNING);
        onConnect();
        signMessage();
      }
    }
  }, [
    primaryWallet,
    onConnect,
    status,
    signMessage,
    isSigning,
    isUserLoggedIn,
    connectOnly,
    onSuccess,
    handleLogOut,
  ]);

  useEffect(() => {
    if (
      customLoading &&
      customText &&
      (status === WALLET_STATES.SIGNED || status === WALLET_STATES.ERROR)
    ) {
      setHelpMessage(customText);
    }
  }, [customLoading, customText, helpMessage, status, connectOnly]);
  const getText = () => {
    if (customLoading && customLoadingText) {
      return customLoadingText;
    }
    switch (status) {
      case WALLET_STATES.SIGNED:
        return primaryWallet ? "Connected" : "Connect Wallet";
      case WALLET_STATES.CONNECTING:
        return !primaryWallet && !showAuthFlow
          ? "Connect Wallet"
          : "Connecting";
      case WALLET_STATES.SIGNING:
        return "Signing";
      case WALLET_STATES.ERROR:
      case WALLET_STATES.INIT:
      default:
        return "Connect Wallet";
    }
  };
  useEffect(() => {
    if (showAuthFlow) {
      setStatus(WALLET_STATES.CONNECTING);
    }
  }, [showAuthFlow]);
  const handleClick = () => {
    if (disabled) {
      return;
    }
    if (onClickEventName) {
      trackEvent(onClickEventName);
    }
    handleLogOut();
    isSigning.current = true;
    setShowAuthFlow(true);
    setHelpMessage(null);
  };
  const isDisabled =
    disabled || status === WALLET_STATES.SIGNING || customLoading;
  return (
    <CustomColumn className={className}>
      {!customRender ? (
        <button
          onClick={handleClick}
          disabled={isDisabled}
          className={`${styles.btn} ${isDisabled ? styles.disabled : ``} ${
            size === "large" ? styles.large : ``
          } ${customClass}`}>
          <CustomRow gap="2px" justifyContent="center">
            {(status === WALLET_STATES.CONNECTING ||
              status === WALLET_STATES.SIGNING ||
              customLoading) && <SpinnerComponent color="#fff" />}
            {getText()}
          </CustomRow>
        </button>
      ) : (
        <div role="button" onClick={handleClick}>
          {customRender()}
        </div>
      )}

      {/* {helpMessage && !customRender && (
        <div className={styles.helpMessage}>{helpMessage}</div>
      )} */}
    </CustomColumn>
  );
};

export default DynamicWalletButton;
