import classes from "components/V2/Profile/ProfileDetails/Filters/Filters.module.css";
import { useState, useEffect } from "react";
import TokenRowTile from "components/V2/Profile/ProfileDetails/Filters/TokenRowTile";
import { getFiltersData } from "api/profile.api";
import withLoader from "components/core/withLoader";
import { getTokensAfterSearch } from "utils/profile_utils";
import FiltersSearchBar from "components/V2/Profile/ProfileDetails/Filters/FiltersSearchBar";

const ExplorerTokensFilterList = ({
  queryData,
  tokensSelected,
  setTokensSelected,
  chainsSelected,
  setChainsSelected,
  tokenTuples,
  setTokenTuples,
  filterChains,
  setFilterChains,
}) => {
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const tokensData = queryData.data.data;
  const [tokensList, setTokensList] = useState(tokensData.metadata);

  useEffect(() => {
    if (searchTerm) {
      const modifiedList = getTokensAfterSearch({
        tokensList: tokensData.metadata,
        searchTerm,
      });
      setTokensList(modifiedList);
    } else {
      setTokensList(tokensData.metadata);
    }
  }, [searchTerm, tokensData.metadata]);

  return (
    <div className={classes.chainFiltersList}>
      <div className={classes.horizontalPadding}>
        <FiltersSearchBar
          setSearchTerm={setSearchTerm}
          isSearchInputFocused={isSearchInputFocused}
          setIsSearchInputFocused={setIsSearchInputFocused}
        />
      </div>
      {tokensList &&
        Object.values(tokensList)
          .sort((t) => {
            const tuple = [t.chain_id, t.address];
            return tokenTuples.includes(tuple) ? 1 : -1;
          })
          .map((entry, index) => {
            return (
              <div key={`token-filter-${entry}-${index}`}>
                <TokenRowTile
                  isExplorer={true}
                  tokensSelected={tokensSelected}
                  chainsSelected={chainsSelected}
                  setChainsSelected={setChainsSelected}
                  setTokensSelected={setTokensSelected}
                  tokenTuples={tokenTuples}
                  setTokenTuples={setTokenTuples}
                  filterChains={filterChains}
                  setFilterChains={setFilterChains}
                  token={entry}
                  index={index}
                />
              </div>
            );
          })}
    </div>
  );
};

export default withLoader(ExplorerTokensFilterList, getFiltersData, {
  queryKey: (props) => ["explorer-chains", props.identifiers],
  CustomLoader: () => null,
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  showRefetch: false,
  renderNotFound: false,
});
