import React, { useContext } from "react";
import classes from "./downloadButton.module.css";
import { DownloadSimple } from "@phosphor-icons/react";
import { TransparentButton } from "../Components";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { saveAs } from "file-saver";
import { GlobalContext } from "contextStates/Global";

const DownloadButton = ({
  mimeType = "application/json",
  fileName = "download.txt",
  content,
}) => {
  const { handleErrorSnackbar } = useContext(GlobalContext);

  const handleDownload = () => {
    if (!content) {
      const err = new Error("Failed to download. content not provided");
      handleErrorSnackbar(err, "");
      return;
    }

    const blob = new Blob(
      [content],
      !!mimeType ? { type: `${mimeType};charset=utf-8` } : {}
    );

    saveAs(blob, fileName);
  };

  return (
    <TransparentButton
      className={classes.downloadButton}
      onClick={handleDownload}>
      <PPLxTooltip placement="top" title="Download" arrow>
        <DownloadSimple size={16} />
      </PPLxTooltip>
    </TransparentButton>
  );
};

export default DownloadButton;
