import Copyable from "components/ValueType/Copyable/Copyable";
import classes from "./TransactionLogs.module.css";
import {
  PaddingComponent,
  CustomColumn,
  CustomRow,
  Divider,
  FlexBox,
} from "components/UI/Components/Components";
import ProfileRenderer from "components/UI/Components/ProfileRenderer";
import LogsUnparsedAdditionalDetails from "./LogUnparsedAdditionalDetails";

const LogsArgumentTile = ({ index, argumentKey, argumentValue }) => {
  return (
    <CustomRow>
      <FlexBox flex="1">
        <span className={classes.argTableText}>{index}</span>
      </FlexBox>
      <FlexBox flex="2">
        <span className={classes.argTableText}>{argumentKey}</span>
      </FlexBox>
      <FlexBox overflow="auto" flex="3">
        <CustomRow>
          {typeof argumentValue === "object" ? (
            <ProfileRenderer
              profile={argumentValue}
              copyable="address"
              showCopyableOnHover={false}
            />
          ) : (
            <CustomRow alignItems="flex-start">
              <pre className={classes.argTableText}>{`${argumentValue}`}</pre>
              <Copyable content={argumentValue} />
            </CustomRow>
          )}
        </CustomRow>
      </FlexBox>
    </CustomRow>
  );
};

const LogsParsedAdditionalDetails = ({ log }) => {
  if (!log?.args) return <LogsUnparsedAdditionalDetails log={log} />;
  return (
    <CustomColumn gap="10px">
      <PaddingComponent padding="2px 0px 2px 0px">
        <Divider />
      </PaddingComponent>
      <span className={classes.heading}>Arguments</span>
      {Object.entries(log?.args)?.map(([argumentKey, argumentValue], index) => {
        return (
          <LogsArgumentTile
            index={index}
            argumentKey={argumentKey}
            argumentValue={argumentValue}
          />
        );
      })}
    </CustomColumn>
  );
};

export default LogsParsedAdditionalDetails;
