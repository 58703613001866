import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  useZxTokenPageContext,
  ZxTokenPageContextProvider,
} from "./ZxTokenPageContext";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import styles from "./ZxTokenPage.module.scss";
import ZxTokenHeader from "../ZxTokenHeader/ZxTokenHeader";
import { Divider, PaddingComponent } from "components/UI/Components/Components";
import TokenFolloweePNL from "./TokenFolloweePNL/TokenFolloweePNL";
import { useGetTokenFolloweePNLs } from "api/token_page.api";
import TradingViewGraph from "components/V2/TradingViewGraph/TradingViewGraph";
import TokenPageRightBar from "./TokenPageRightBar/TokenPageRightBar";
import ZxTabs from "zxComponents/common/ZxTabs/ZxTabs";
import {
  TOKEN_PAGE_TABS_LIST,
  TOKEN_TABS,
  ZxTokenPageHeaderLoader,
} from "./ZxTokenPage.utils";
import ZxTokenTopHolders from "../ZxTokenTopHolders/ZxTokenTopHolders";
import ZxTokenRecentTransfers from "../ZxTokenRecentTransfers/ZxTokenRecentTransfers";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxUserTokenGroup from "zxComponents/feed/ZxUserTokenGroup/ZxUserTokenGroup";
import ZxTokenFolloweePNLTable from "../ZxTokenFolloweePNLTable/ZxTokenFolloweePNLTable";
import usePageTitle from "customHooks/usePageTitle";
import { abbreviateNumber } from "utils/misc";
const ZxTokenPage = ({ chain, address }) => {
  const { setIsLeftSideBarOpen } = useContext(LeftSideHeaderContext);
  const {
    tokenPageDetails,
    chartDetails,
    tokenProfile,
    swapFilter,
    updateSwapFilter,
    tokenPrice,
    updateTokenPrice,
    filteredUserPnl,
    getMarkers,
  } = useZxTokenPageContext();
  const { data: followeePnls, isLoading: isFolloweePnlsLoading } =
    useGetTokenFolloweePNLs({ chain, address });
  const history = useHistory();
  const { active_tab: activeTab } = useParams();
  useEffect(() => {
    setIsLeftSideBarOpen(true);
  }, [chain, address, setIsLeftSideBarOpen]);
  const title = tokenPrice
    ? `${tokenProfile?.data?.symbol} $${abbreviateNumber(tokenPrice, false, true)} - 0xPPL`
    : "0xPPL";
  usePageTitle({ title });
  const updateRecentTxnsFilter = (f) => {
    const hasMaker = f
      ? `?maker=${f?.id ?? f?.identity_id ?? f?.address ?? f.username}`
      : "";
    history.push(`/c/${chain}/${address}/${TOKEN_TABS.RECENT_TXNS}${hasMaker}`);
    updateSwapFilter(f);
  };
  const renderTabs = () => {
    switch (activeTab) {
      case TOKEN_TABS.RECENT_TXNS:
        const showPnl =
          swapFilter &&
          filteredUserPnl &&
          (!!filteredUserPnl?.open_pnl?.value ||
            !!filteredUserPnl?.closed_pnl?.value ||
            !!filteredUserPnl?.current_balance?.value ||
            !!filteredUserPnl?.total_usd_value);
        return (
          <PaddingComponent padding={showPnl ? "8px 0" : "0"}>
            <ZxFlex
              dir="column"
              width="100%"
              align="flex-start"
              flex="1"
              gap="8px">
              {showPnl && (
                <ZxFlex width="100%" flex="1">
                  <ZxUserTokenGroup
                    selectedToken={{
                      ...filteredUserPnl,
                      display_picture:
                        tokenProfile?.data?.profile?.display_picture,
                    }}
                    showAttribution={false}
                    overrideLogo={tokenProfile?.data?.profile?.display_picture}
                  />
                </ZxFlex>
              )}
              <ZxFlex flex="1" width="100%">
                <ZxTokenRecentTransfers
                  chain={chain}
                  address={address}
                  showFilter
                  filter={swapFilter}
                  updateFilter={updateRecentTxnsFilter}
                />
              </ZxFlex>
            </ZxFlex>
          </PaddingComponent>
        );
      case TOKEN_TABS.FOLLOWEE_SWAPS:
        return (
          <ZxTokenFolloweePNLTable
            chain={chain}
            address={address}
            filter={swapFilter}
            updateFilter={updateRecentTxnsFilter}
          />
        );
      case TOKEN_TABS.TOP_HOLDERS:
        return (
          <ZxTokenTopHolders
            chain={chain}
            address={address}
            showFilter
            filter={swapFilter}
            updateFilter={updateRecentTxnsFilter}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {tokenProfile?.isLoading ? (
          <ZxTokenPageHeaderLoader />
        ) : (
          <ZxTokenHeader
            token={tokenProfile?.data ?? null}
            deployedAt={chartDetails?.data?.deployed_at}
            tokenPageDetails={tokenPageDetails?.data}
          />
        )}

        <Divider margin="8px 0" />
        <TokenFolloweePNL
          symbol={tokenProfile?.data?.symbol}
          data={followeePnls}
          isLoading={isFolloweePnlsLoading}
          onFilter={(p) => {
            updateSwapFilter(p);
            history.push(`/c/${chain}/${address}/${TOKEN_TABS.RECENT_TXNS}`);
          }}
          onView={() => {
            history.push(`/c/${chain}/${address}/${TOKEN_TABS.FOLLOWEE_SWAPS}`);
            // scroll down smoothly
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            });
          }}
        />
        <TradingViewGraph
          chain={chain}
          address={address}
          metadata={chartDetails?.data}
          showPriceHeader
          isTokenPage
          headerPadding="0"
          chartHeight={450}
          chartPrice={tokenPrice}
          setChartPrice={updateTokenPrice}
          getMarkers={getMarkers}
        />
        <Divider margin="8px 0" />
        <ZxTabs
          isPrimaryTabs
          tabs={TOKEN_PAGE_TABS_LIST}
          activeTab={activeTab}
          onChange={(e, v) => {
            history.push(`/c/${chain}/${address}/${v}`);
          }}
        />
        {renderTabs()}
      </div>
      <div className={styles.rightBar}>
        <TokenPageRightBar
          token={tokenProfile?.data}
          tokenPageDetails={tokenPageDetails?.data}
          poolAddress={chartDetails?.data?.gecko_terminal_pool}
          deployedAt={chartDetails?.data?.deployed_at}
          chain={chain}
          address={address}
          tokenType={chartDetails?.data?.token_type}
          tokenPrice={tokenPrice}
          chartDetails={chartDetails?.data}
        />
      </div>
    </div>
  );
};

const Wrapper = (props) => {
  return (
    <ZxTokenPageContextProvider {...props}>
      <ZxTokenPage {...props} />
    </ZxTokenPageContextProvider>
  );
};
export default Wrapper;
