import {
  CustomColumn,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import LoaderButton from "components/UI/LoaderButton/LoaderButton";
import { AuthContext } from "contextStates/AuthContext";
import { useContext } from "react";
import classes from "../ErrorBoundary/GenericFallBackUI.module.css";

const LoginCheckWrapper = ({ title = "", subtitle = "", children }) => {
  const { isUserLoggedIn } = useContext(AuthContext);
  if (isUserLoggedIn) {
    return children;
  }

  return (
    <CustomColumn alignItems="center">
      <PaddingComponent height="48px" />
      <div className={classes.header}>-(•...•)-</div>
      <PaddingComponent height="16px" />
      <CustomText fontWeight="500" text={title} fontSize="17px"></CustomText>
      <PaddingComponent height="16px" />
      <CustomText fontSize="14px" text={subtitle}></CustomText>
      <PaddingComponent height="36px" />
      <LoaderButton height="36px" width="120px">
        <CustomText color="0x000000" text="Sign In"></CustomText>
      </LoaderButton>
    </CustomColumn>
  );
};

export default LoginCheckWrapper;
