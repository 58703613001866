import { useCallback, useEffect, useState } from "react";
import { PaddingComponent } from "components/UI/Components/Components";
import { SortAscending, SortDescending } from "@phosphor-icons/react";
import { orderBy, throttle } from "lodash";
import { signedRequest } from "api/api";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { ONE_DAY } from "utils/constants";
import { DEFAULT_NFT_LISTING_FILTER } from "zxComponents/nft/ZxNft.utils";
import { QUERY_KEYS } from "utils/query_utils";

export const getNftDetails = async ({
  tokenId,
  contractAddress,
  chainId,
  signal,
}) => {
  const requestBody = {
    contract_address: contractAddress,
    chain_id: chainId,
    version: "v2",
  };

  if (tokenId !== null) {
    requestBody.token_id = tokenId;
  }

  const response = await signedRequest({
    method: "post",
    path: `/api/v4/get_nft_details`,
    bodyText: JSON.stringify(requestBody),
    signal,
  });
  return response?.data?.data;
};

/**
 * handles NFT search in Collection
 */
export const useSearchNftsInCollection = ({
  tokenId,
  contractAddress,
  chainId,
  listingsOnly,
  enabled = true,
}) => {
  return useQuery({
    queryKey: [
      "search_nft_in_collection",
      tokenId,
      chainId,
      contractAddress,
      listingsOnly,
    ],
    queryFn: ({ signal }) =>
      getNftDetails({ tokenId, contractAddress, chainId, signal }),
    enabled: enabled && !!(contractAddress && chainId),
    retry: 1,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

/**
 * Handles sorting collections based on columns
 */
export const useSort = ({ filteredNfts, setFilteredNfts }) => {
  const [sortConfig, setSortConfig] = useState();

  const sortByKey = useCallback(
    (key) => {
      const direction =
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
          ? "descending"
          : "ascending";

      const sortedItems = orderBy(
        filteredNfts,
        [
          (item) => {
            switch (key) {
              case "name":
                return item?.[key];
              case "rank":
                return item?.rarity?.[key];

              case "last_sale":
                return item?.[key]?.token_value?.value;
              case "listed_time":
                return new Date(item?.first_created?.timestamp);
              default:
                return item;
            }
          },
        ],
        [direction === "ascending" ? "asc" : "desc"]
      );

      setSortConfig({ key, direction });
      setFilteredNfts(sortedItems);
    },
    [filteredNfts, setFilteredNfts, sortConfig]
  );

  const getArrow = (key) => {
    if (!sortConfig || sortConfig.key !== key) {
      return <PaddingComponent width="16px" />;
    }
    return sortConfig.direction === "ascending" ? (
      <SortAscending size={16} color="var(--text-2)" /> // ascending arrow
    ) : (
      <SortDescending size={16} color="var(--text-2)" />
    ); // descending arrow
  };

  return { getArrow, sortByKey };
};

export const prefetchNftInfiniteQuery = async (queryClient, collectionId) => {
  await queryClient.prefetchInfiniteQuery(
    ["get_nfts_in_collection", collectionId],
    () => fetchNfts({ pageParam: "None", collectionId }),
    {
      getNextPageParam: (lastPage) => lastPage?.next_cursor,
    }
  );
};
export const prefetchOwnersInfiniteQuery = async (
  queryClient,
  collectionId
) => {
  await queryClient.prefetchInfiniteQuery(
    ["get_collection_owners", collectionId],
    () => fetchOwners({ pageParam: "None", collectionId }),
    {
      getNextPageParam: (lastPage) => lastPage?.next_cursor,
    }
  );
};

export const useNftInfiniteQuery = ({
  collectionId,
  listingsOnly,
  sort = DEFAULT_NFT_LISTING_FILTER,
  filters,
}) => {
  const query = useInfiniteQuery({
    queryKey: QUERY_KEYS.NFT_COLLECTION_PAGE_NFTS({
      collectionId,
      listingsOnly: false,
      sort,
      filters,
    }),
    queryFn: (props) =>
      fetchNfts({ ...props, collectionId, listingsOnly, sort, filters }),
    getNextPageParam: (lastPage) => lastPage?.next_cursor,
    enabled: !!collectionId,
    retry: 3,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: ONE_DAY,
  });

  return query;
};
export const useOwnersInfiniteQuery = (collectionId) => {
  const query = useInfiniteQuery({
    queryKey: ["get_collection_owners", collectionId],
    queryFn: (props) => fetchOwners({ ...props, collectionId }),
    getNextPageParam: (lastPage) => lastPage?.next_cursor,
    enabled: !!collectionId,
    retry: 3,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = query;

  const myTriggerNextPage = useCallback(
    () => triggerNextPage(fetchNextPage, hasNextPage, isFetchingNextPage),
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  useEffect(() => {
    const throttledTriggerNextPage = throttle(myTriggerNextPage, 50);
    window.addEventListener("scroll", throttledTriggerNextPage);

    return () => {
      window.removeEventListener("scroll", throttledTriggerNextPage);
    };
  }, [myTriggerNextPage]);

  return query;
};

const triggerNextPage = (fetchNextPage, hasNextPage, isFetchingNextPage) => {
  const scrollPosition = window.innerHeight + window.pageYOffset;
  const endPosition = document.body.scrollHeight;

  if (endPosition - scrollPosition <= 1500) {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }
};

export const fetchNfts = async ({
  collectionId,
  pageParam = null,
  listingsOnly,
  sort = null,
  filters = {},
}) => {
  const endpoint = listingsOnly
    ? "get_listed_nfts_in_collection"
    : "get_nfts_in_collection";
  const res = await signedRequest({
    method: "post",
    path: `/api/v4/${endpoint}?collection_id=${collectionId}${pageParam ? `&cursor=${pageParam}` : ""}${sort ? `&order_by=${sort}` : ""}${!listingsOnly ? "&version=v2" : ""}`,
    bodyText: !listingsOnly
      ? JSON.stringify({
          filter_by_trait: filters,
        })
      : null,
  });

  return res?.data?.data;
};

const fetchOwners = async ({ collectionId, pageParam = null }) => {
  const res = await signedRequest({
    method: "get",
    path: `/api/v4/get_owners_for_collection?collection_id=${collectionId}${pageParam ? `&cursor=${pageParam}` : ""}`,
  });

  return res?.data?.data;
};

export const getCollectionDetails = async ({ collectionId, ...props }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/v4/get_collection_details?collection_id=${collectionId}`,
  });

  return response.data.data;
};
