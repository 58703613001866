import { useHistory } from "react-router-dom";
import classes from "./FollowerFollowingRightPanel.module.css";
import { ONE_DAY, QueryKeyConstants } from "utils/constants";
import { getFollowersFollowingList } from "api/profile.api";
import ProfileItem, {
  ProfileItemGhost,
  ProfileItemTypes,
} from "../../Community/ProfileItem/ProfileItem";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import { X } from "@phosphor-icons/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import ZxTriggerNextPage from "zxComponents/common/ZxTriggerNextPage/ZxTriggerNextPage";

const FollowerFollowingRightPanelGhost = ({ isFollowers }) => {
  return (
    <div className={classes.rightPanel}>
      <div className={classes.listContainer}>
        {[...Array(8).keys()].map((index) => {
          return <ProfileItemGhost key={`profile-item-ghost-${index}`} />;
        })}
      </div>
    </div>
  );
};

//if is followers true then followers list else following list
const FollowerFollowingRightPanel = ({
  isFollowers,
  onClose,
  count,
  identityId,
}) => {
  const {
    data: queryData,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [
      QueryKeyConstants.FOLLOWER_FOLLOWING_LIST,
      isFollowers,
      identityId,
    ],
    queryFn: ({ pageParam }) =>
      getFollowersFollowingList({ isFollowers, identityId, pageParam }),
    staleTime: ONE_DAY,
    enabled: !!identityId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => {
      return lastPage?.data?.cursor?.toString() ?? null;
    },
  });
  const history = useHistory();
  const list = queryData?.pages?.flatMap((p) => p?.data?.data ?? []) ?? [];
  const requestConnection =
    history.location.search?.includes("requestConnection");
  return (
    <div className={classes.rightPanel}>
      <ZxFlex justify="space-between" className={classes.header} align="center">
        <span className={classes.title}>
          {isFollowers
            ? `${count ?? list.length} Followers`
            : `${count ?? list.length} Following`}
        </span>
        <X onClick={onClose} />
      </ZxFlex>

      {isLoading ? (
        <FollowerFollowingRightPanelGhost isFollowers={isFollowers} />
      ) : (
        <div className={classes.listContainer}>
          {list.map((profile) => {
            return (
              <ProfileItem
                resultType="identity"
                key={profile.id}
                profile={profile}
                requestConnection={requestConnection}
                profileItemType={
                  profile.followed_on_twitter
                    ? ProfileItemTypes.twitter
                    : ProfileItemTypes["0xppl"]
                }
              />
            );
          })}
        </div>
      )}
      {isFetchingNextPage && (
        <FollowerFollowingRightPanelGhost isFollowers={isFollowers} />
      )}
      <ZxTriggerNextPage
        fetchNextPage={fetchNextPage}
        height="1200px"
        width="20px"
        shouldFetch={hasNextPage && !isFetchingNextPage && !isLoading}
      />
    </div>
  );
};

export default withErrorBoundary(FollowerFollowingRightPanel);
