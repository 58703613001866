import Image, { IMAGE_TYPES } from "components/UI/Image";
import classes from "./ContractInfo.module.css";
import ChainIcon from "components/UI/ChainIcon";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LayersIcon from "@mui/icons-material/Layers";
import {
  IconWrapper,
  PaddingComponent,
  CustomRow,
  CustomButton,
} from "components/UI/Components/Components";
import Copyable from "components/ValueType/Copyable/Copyable";
import { getTrimedAddress } from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";
import useFetchTokenMetaData from "customHooks/useFetchTokenMetaData";
import TokenCard from "components/UI/Cards/TokenCard";
import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext } from "contextStates/Global";
import Popup from "components/UI/Popup/Popup";
import CustomTextField from "components/FollowEditButton/CustomTextField";
import LoaderButton from "components/UI/LoaderButton/LoaderButton";
import { updateContract } from "api/contract.api";
import { AuthContext } from "contextStates/AuthContext";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";

import {
  PencilSimple,
  TwitterLogo,
  GlobeHemisphereWest,
} from "@phosphor-icons/react";
import TVLCard from "components/UI/Cards/TVLCard";
import { getFormattedMonthNameDate } from "utils/profile_utils";
import ScrollContainer from "react-indiana-drag-scroll";
import Bio from "components/V2/Profile/ProfileHeader/Bio";
import { getTrendingAdditionalInfo } from "components/Trending/trendingUtils";
import { useQuery } from "@tanstack/react-query";

/* TODO: Should probably be sent by the backend */
const figureLabel = (info) => {
  const { profile, multisig_owners } = info;
  if (profile?.is_token === true) {
    return "Token";
  }
  if (!!multisig_owners?.length) {
    return "Multisig";
  }
  if (["NFT", "MultiToken"].includes(profile?.address_type)) {
    return "NFT";
  }
  return "Contract";
};

const nativeTokenParams = (info) => {
  const {
    chain_id: address_chain,
    name: altName,
    symbol: display_name,
    logo: display_picture,
  } = info;

  return {
    address_chain,
    altName,
    display_name,
    display_picture,
    label: "Native Token",
  };
};

const contractParams = (info) => {
  let altName;
  const profile = info?.profile;
  const creation_details = info?.creation_details;
  const statistics = info?.statistics;
  const external_link = info?.external_link;
  const explorer_link = info?.explorer_link;
  let display_name = profile?.display_name;
  const is_token = profile?.is_token;
  const tvl = statistics?.tvl;
  const { address, display_picture, address_chain, protocol_details, bio } =
    profile;
  const {
    creator: {
      link: creator_link,
      display_name: creator_display_name,
      display_picture: creator_display_picture,
    } = {},
    created_at: contract_created_at,
    creation_link,
  } = creation_details || {};

  const label = figureLabel(info);
  if (label === "Token" && profile?.token_details.symbol) {
    altName = display_name;
    display_name = profile?.token_details.symbol;
  }

  return {
    address,
    address_chain,
    altName,
    contract_created_at,
    creator_display_name,
    creator_display_picture,
    creator_link,
    creation_link,
    display_name,
    display_picture,
    label,
    protocol_details,
    external_link,
    explorer_link,
    is_token,
    tvl,
    bio,
  };
};

const parseProps = ({ info, forNative }) => {
  if (forNative) {
    return nativeTokenParams(info);
  } else {
    return contractParams(info);
  }
};

const computeTokenDetails = ({ tokenChartStats, is_token, tokenData }) => {
  let showTokenCard = tokenData && is_token;
  if (tokenChartStats == null) {
    showTokenCard = false;
  }

  const marketCap = tokenData?.market_cap?.display_value;
  let value = tokenData?.price?.display_value;
  let percentChange = tokenData?.price_percentage_change;

  if (tokenChartStats?.available === true) {
    value = tokenChartStats.price;
    percentChange = tokenChartStats.change;
  }

  return {
    showTokenCard,
    marketCap,
    value,
    percentChange,
  };
};

const ContractInfo = ({ info, forNative, tokenChartStats, refetch }) => {
  const {
    address,
    address_chain,
    altName,
    contract_created_at,
    creator_display_name,
    creator_display_picture,
    creator_link,
    creation_link,
    display_name,
    display_picture,
    label,
    protocol_details,
    tvl,
    is_token,
    bio,
  } = parseProps({ info, forNative });

  const { data: tokenData } = useFetchTokenMetaData(
    address,
    address_chain,
    is_token
  );

  const { data: additionalInfo } = useQuery({
    queryKey: ["trending-item-additional-info", address, address_chain],
    queryFn: () =>
      getTrendingAdditionalInfo({
        identifier: address,
        chainId: address_chain,
      }),
    enabled: is_token && !forNative,
  });

  const { setTitle } = useContext(LeftSideHeaderContext);
  const [showPopup, setShowPopup] = useState(false);
  const [nameState, setNameState] = useState("");
  const [websiteState, setWebsiteState] = useState(
    info.external_links?.website
  );
  const [twitterState, setTwitterState] = useState(
    info.external_links?.twitter
  );
  const { handleErrorSnackbar, setSnackBarState } = useContext(GlobalContext);
  const { isSuperuser } = useContext(AuthContext);
  useEffect(() => {
    setTitle(display_name);
  }, [setTitle, display_name]);

  const onUpdate = useCallback(() => {
    return updateContract({
      chainId: address_chain,
      address: address,
      website: websiteState,
      name: nameState,
      twitter: twitterState,
    })
      .then((data) => {
        refetch();
        setShowPopup(false);
        setSnackBarState({
          open: true,
          message: "Contract Details Updated",
          type: "success",
        });
      })
      .catch((err) => {
        handleErrorSnackbar(err);
      });
  }, [
    nameState,
    websiteState,
    twitterState,
    address,
    address_chain,

    refetch,
    setSnackBarState,
    handleErrorSnackbar,
  ]);

  const { showTokenCard, marketCap, percentChange, value } =
    computeTokenDetails({
      tokenChartStats,
      is_token,
      tokenData,
    });
  const showTVLCard = tvl?.display_value && tvl?.value > 0 && !is_token;

  return (
    <div className={classes.container}>
      <div className={classes.contract_info}>
        <Image
          src={display_picture}
          alt={display_name}
          imageType={IMAGE_TYPES.CONTRACT}
          className={classes.image}
        />

        <div className={classes.details}>
          <div className={classes.top}>
            <div className={classes.name}>{display_name}</div>
            <div className={classes.contract_tag}>{label}</div>
            <ChainIcon chainId={address_chain} className={classes.chain_icon} />
          </div>
          {altName && <div className={classes.aliases}>{altName}</div>}
        </div>

        <div className={classes.socials}>
          <ExternalLinks
            isVerticalDivider={
              additionalInfo?.buy_url || additionalInfo?.sell_url
            }
            links={info.external_links}
          />
          {isSuperuser && (
            <IconWrapper className={classes.edit_icon}>
              <PencilSimple
                onClick={(event) => {
                  setShowPopup(true);
                }}
                fontSize="18px"
                color="var(--text-2)"
              />
            </IconWrapper>
          )}
        </div>
        {(additionalInfo?.buy_url || additionalInfo?.sell_url) && (
          <CustomRow gap="8px" alignItems="center" margin="0 0 0 8px">
            {!!additionalInfo?.buy_url?.length && (
              <ConditionalLink
                sameTab={false}
                to={{ pathname: additionalInfo?.buy_url }}>
                <CustomButton
                  fontSize="15px"
                  color="var(--text-white)"
                  fontWeight="600"
                  text="BUY"
                />
              </ConditionalLink>
            )}

            {!!additionalInfo?.sell_url?.length && (
              <ConditionalLink
                sameTab={false}
                to={{ pathname: additionalInfo?.sell_url }}>
                <CustomButton
                  fontSize="15px"
                  color="var(--text-white)"
                  fontWeight="600"
                  text="SELL"
                />
              </ConditionalLink>
            )}
          </CustomRow>
        )}
      </div>
      {bio ? (
        <PaddingComponent paddingTop="8px">
          <Bio className={classes.bio} bio={bio} />
        </PaddingComponent>
      ) : (
        ""
      )}
      {(creator_display_name || creator_display_picture || address) && (
        <div className={classes.creator_details_container}>
          {address && (
            <div className={classes.address}>
              {" "}
              <Copyable content={address}>{getTrimedAddress(address)}</Copyable>
            </div>
          )}
          {(creator_display_name || creator_display_picture) && (
            <div className={classes.dot}>{" · "}</div>
          )}
          {(creator_display_name || creator_display_picture) && (
            <>
              <div className={classes.deployed_by_text}>Deployed By</div>
              <ConditionalLink
                to={creator_link}
                className={classes.deployed_by_link}
                target="_blank">
                <Image
                  src={creator_display_picture}
                  alt={creator_display_name}
                  imageType={IMAGE_TYPES.AVATAR}
                  className={classes.creator_image}
                  name={creator_display_name}
                />
                <div className={classes.deployed_by_name}>
                  {creator_display_name}
                </div>
              </ConditionalLink>
              {contract_created_at && (
                <>
                  <div className={classes.deployed_by_name}>on</div>
                  <ConditionalLink
                    to={creation_link}
                    className={classes.deployed_by_name}
                    title="Link to transaction">
                    {getFormattedMonthNameDate({
                      date: contract_created_at * 1000,
                      monthFormat: "short",
                    })}
                  </ConditionalLink>
                </>
              )}
            </>
          )}
        </div>
      )}
      {(showTokenCard || showTVLCard) && (
        <section>
          <ScrollContainer className={classes.cards}>
            {showTokenCard && (
              <TokenCard
                title={`${display_name} Token`}
                marketCap={marketCap}
                value={value}
                percentChange={percentChange}
                // link={profiles[`${token.chain_id}_${token.address}`].link}
                link="token"
                logo={display_picture}
              />
            )}
            {showTVLCard && <TVLCard title="TVL" value={tvl.display_value} />}
          </ScrollContainer>
        </section>
      )}
      {protocol_details?.link ? (
        <div className={classes.protocol_card_container}>
          <Link to={protocol_details.link} target="_blank">
            <OpenInNewIcon
              height={40}
              width={40}
              className={classes.open_in_new_tab}
              color={"var(--text-2)"}
            />
          </Link>
          <div className={classes.protocol_details}>
            <div className={classes.protocol_text}>
              This contract is a part of
            </div>
            <div className={classes.protocol}>
              <Image
                src={protocol_details.icon}
                alt={protocol_details.name}
                imageType={IMAGE_TYPES.PROTOCOL}
                className={classes.protocol_image}
              />
              <span className={classes.protocol_name}>
                {protocol_details.name}
              </span>
            </div>
          </div>
          <Link
            to={protocol_details.link}
            target="_blank"
            className={classes.view_protocol}>
            <LayersIcon className={classes.layers_icon} />
            <div className={classes.view_protocol_text}>View Protocol Page</div>
          </Link>
        </div>
      ) : null}
      <PaddingComponent height="24px"></PaddingComponent>
      {showPopup && (
        <Popup onClose={setShowPopup}>
          <h1 className={classes.edit_form_heading}>Edit Contract Details</h1>
          <PaddingComponent height="8px"></PaddingComponent>
          <CustomTextField
            label="Name"
            value={nameState}
            onChange={(e) => {
              setNameState(e.target.value);
            }}
          />
          <PaddingComponent height="8px"></PaddingComponent>
          <CustomTextField
            label="Website"
            value={websiteState}
            onChange={(e) => {
              setWebsiteState(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <div className={classes.icon_wrapper}>
                  <GlobeHemisphereWest
                    size={16}
                    weight="fill"
                    color="var(--text-2)"
                  />
                </div>
              ),
            }}
          />
          <PaddingComponent height="8px"></PaddingComponent>
          <CustomTextField
            label="Twitter"
            value={twitterState}
            onChange={(e) => {
              setTwitterState(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <div className={classes.icon_wrapper}>
                  <TwitterLogo size={16} weight="fill" color="var(--text-2)" />
                </div>
              ),
            }}
          />
          <PaddingComponent height="8px"></PaddingComponent>
          <LoaderButton
            onClick={onUpdate}
            isLoading={false}
            className={classes.loader_button}>
            Update
          </LoaderButton>
        </Popup>
      )}
    </div>
  );
};

export default ContractInfo;
