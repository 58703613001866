import classes from "./GhostFeedItem.module.css";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  CustomSkeleton,
  InfoBox,
} from "components/UI/Components/Components";

const GhostFeedItem = ({ isGroupActivityShimmer, isHomePageFeedItem }) => {
  return (
    <div
      className={classes.container}
      style={{
        margin: isHomePageFeedItem ? "0px" : "16px 0px",
      }}>
      <InfoBox
        radius={isHomePageFeedItem ? "0px" : "6px"}
        borderWidth={
          isGroupActivityShimmer || isHomePageFeedItem ? "0px" : "1px"
        }
        background={isGroupActivityShimmer ? "transparent" : "var(--base)"}
        padding={isGroupActivityShimmer ? "0px" : "20px"}>
        <CustomRow>
          <PaddingComponent paddingRight="20px">
            <CustomSkeleton variant="circular" height={40} width={40} />
          </PaddingComponent>
          <CustomColumn
            justifyContent="stretch"
            style={{
              width: "100%",
            }}>
            <CustomSkeleton width="164px" variant="text" />
            <CustomSkeleton variant="text" />
            <PaddingComponent height="16px" />
            <CustomSkeleton width="40px" variant="text" />
          </CustomColumn>
          <PaddingComponent width="32px" />
        </CustomRow>
      </InfoBox>
    </div>
  );
};

export default GhostFeedItem;
