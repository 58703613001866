import {
  CustomColumn,
  PaddingComponent,
  InfoBox,
  CustomRow,
  CustomButton,
} from "components/UI/Components/Components";
import classes from "./NotificationsPage.module.css";
import ChatIcon from "@mui/icons-material/Chat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import WorkspacesRoundedIcon from "@mui/icons-material/WorkspacesRounded";
import { Box } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import {
  PlayStoreButton,
  AppStoreButton,
} from "components/UI/Components/AppDownloadButtons";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import ProfileHoverCard from "../Profile/ProfileHoverCard/ProfileHoverCard";
import { TimeLabel } from "../../FeedItemV2/ActivityHeader";

const NotificationType = {
  COMMENT: "comment",
  REPLY: "reply",
  LIKE: "like",
  FOLLOW: "follow",
  BUNDLE_CHANGE: "bundle_change",
  ACTION: "action",
  SYSTEM: "system",
};
const NotificationTile = ({ activity, onTileClick }) => {
  const {
    notification_type,
    identities,
    profiles,
    title,
    content,
    images_for_profiles = [],
    is_read,
  } = activity;
  const profilesForImages = images_for_profiles.map(
    (identity_id) => identities[identity_id]
  );
  if (notification_type === "group_invite") {
    profilesForImages.push(...Object.values(identities));
  }

  return (
    <div onClick={onTileClick} className={classes.notificationTile}>
      <InfoBox
        margin="6px 0px"
        padding="16px 24px 16px 32px"
        background={is_read ? null : "var(--elevation-1)"}>
        <CustomRow justifyContent="space-between">
          <CustomRow gap="28px">
            <PaddingComponent paddingTop="6px">
              <Box
                component={getIconFromNotificationType(notification_type)}
                sx={{
                  fontSize: "18px",
                  color:
                    notification_type === NotificationType.LIKE.toString()
                      ? "var(--error)"
                      : notification_type === NotificationType.SYSTEM.toString()
                        ? "var(--primary-color)"
                        : "var(--text-2)",
                }}
              />
            </PaddingComponent>
            <CustomColumn>
              {profilesForImages && (
                <PaddingComponent paddingBottom="8px">
                  <CustomColumn justifyContent="center">
                    {profilesForImages?.map((profile, index) => {
                      return (
                        <ProfileHoverCard
                          key={`avatar-${profile?.display_name}-${index}`}
                          actor={profile}
                          onActorUpdate={() => {}}>
                          <div
                            className={`${
                              index === 0
                                ? classes.profileInitial
                                : classes.profileSeries
                            }`}
                            style={{
                              zIndex: index + 1,
                              marginLeft:
                                index === 0
                                  ? "0px"
                                  : `${(index === 1 ? 23 : 24) * index}px`,
                              position: index === 0 ? "relative" : "absolute",
                            }}>
                            <Image
                              imageType={IMAGE_TYPES.AVATAR}
                              alt={`profile-${index}`}
                              src={profile?.display_picture}
                              name={profile?.display_name}
                            />
                          </div>
                        </ProfileHoverCard>
                      );
                    })}
                  </CustomColumn>
                </PaddingComponent>
              )}
              {notification_type === NotificationType.SYSTEM.toString() ? (
                <span className={`${classes.tileTitle} ${classes.highlighted}`}>
                  {title}
                </span>
              ) : (
                <FeedDescription
                  templateString={title}
                  identities={identities}
                  profiles={profiles}
                  isPlainText={true}
                />
              )}

              {content && (
                <PaddingComponent paddingTop="8px">
                  <pre className={classes.tileSubtitle}>{activity.content}</pre>
                </PaddingComponent>
              )}
              {notification_type === NotificationType.ACTION.toString() && (
                <PaddingComponent paddingTop="12px">
                  <CustomRow gap="10px">
                    <CustomButton text="Accept"></CustomButton>
                    <CustomButton
                      background="var(--elevation-2)"
                      color="var(--text-2)"
                      text="Discard"></CustomButton>
                  </CustomRow>
                </PaddingComponent>
              )}
              {notification_type === NotificationType.SYSTEM.toString() && (
                <PaddingComponent paddingTop="12px">
                  <CustomRow gap="10px">
                    <PlayStoreButton />
                    <AppStoreButton />
                  </CustomRow>
                </PaddingComponent>
              )}
            </CustomColumn>
          </CustomRow>
          <TimeLabel timestamp={activity.timestamp} />
        </CustomRow>
      </InfoBox>
    </div>
  );
};

// function to get icon from notification type
const getIconFromNotificationType = (notification_type) => {
  switch (notification_type) {
    case NotificationType.COMMENT.toString():
      return ChatIcon;
    case NotificationType.REPLY.toString():
      return ChatIcon;
    case NotificationType.LIKE.toString():
      return FavoriteIcon;
    case NotificationType.FOLLOW.toString():
      return PersonRoundedIcon;
    case NotificationType.ACTION.toString():
      return WorkspacesRoundedIcon;
    case NotificationType.BUNDLE_CHANGE.toString():
      return WorkspacesRoundedIcon;
    default:
      return AutoAwesomeIcon;
  }
};

export default NotificationTile;
