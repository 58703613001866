import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DEFAULT_NFT_ALL_FILTER,
  DEFAULT_NFT_LISTING_FILTER,
} from "../ZxNft.utils";

export const CollectionPageContext = React.createContext({});

const CollectionPageContextProvider = ({ children }) => {
  const { active_tab: activeTab } = useParams();
  const [searchIds, setSearchIds] = React.useState({
    all: "",
    listings: "",
  });
  const [sortOrders, setSortOrders] = React.useState({
    all: DEFAULT_NFT_ALL_FILTER,
    listings: DEFAULT_NFT_LISTING_FILTER,
  });
  const [filters, setFilters] = useState({});
  const updateSearch = (key, value) => {
    setSearchIds((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const updateSort = (key, value) => {
    setSortOrders((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const addToFilters = (val) => {
    setFilters((prev) => ({
      ...prev,
      ...val,
    }));
  };
  const removeFromFilters = (title, val) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      const index = newFilters[title].indexOf(val);
      if (index > -1) {
        newFilters[title].splice(index, 1);
      }
      if (newFilters[title].length === 0) {
        delete newFilters[title];
      }
      return newFilters;
    });
  };
  const updateFilters = (val) => {
    setFilters(val);
  };
  const getSearchAndSort = (key) => {
    return { search: searchIds[key], sort: sortOrders[key] };
  };

  useEffect(() => {
    if (activeTab === "listings") {
      updateSort("listings", DEFAULT_NFT_LISTING_FILTER);
    }
    if (activeTab === "all") {
      updateSort("all", DEFAULT_NFT_ALL_FILTER);
    }
  }, [activeTab]);
  return (
    <CollectionPageContext.Provider
      value={{
        getSearchAndSort,
        updateSearch,
        updateSort,
        activeTab,
        filters,
        addToFilters,
        removeFromFilters,
        updateFilters,
      }}>
      {children}
    </CollectionPageContext.Provider>
  );
};

export default CollectionPageContextProvider;
