import React from "react";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "components/UI/Table/Table";
import { TableProfile } from "components/V2/Explorer/SelectedEdge";
import { timeAgo } from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";
import {
  CustomText,
  DirectedTokenPrice,
} from "components/UI/Components/Components";

const TrendingTokenTransfers = ({ transfers, profiles }) => {
  return (
    <Table>
      <TableHeader noPadding>
        <TableCell alignLeft style={{ flexBasis: "45%" }}>
          TO/FROM
        </TableCell>
        <TableCell alignRight>TIME</TableCell>
        <TableCell alignRight>AMOUNT</TableCell>
        <TableCell alignRight>VALUE</TableCell>
      </TableHeader>

      {transfers?.map((transferItem, index) => (
        <TransferRow
          key={`token-transfer-${index}`}
          transferItem={transferItem}
          profile={profiles?.[transferItem?.actor_addr]}
        />
      ))}
    </Table>
  );
};

export default TrendingTokenTransfers;

const TransferRow = ({ transferItem, profile }) => {
  const txnLink = `/tx_id?chain_id=${transferItem?.chain_id}&tx_idx=${transferItem?.tx_idx}&actor=${transferItem?.actor_addr}&block_number=${transferItem?.block_number}`;
  return (
    <ConditionalLink to={txnLink} style={{ padding: "inherit" }}>
      <TableRow noPadding alignToFlexStart>
        <TableCell alignLeft style={{ flexBasis: "45%" }}>
          <TableProfile profile={profile} abbreviateLength={20} />
        </TableCell>
        <TableCell alignRight>
          <CustomText
            fontSize="14px"
            color="var(--text-1)"
            text={timeAgo(transferItem?.timestamp * 1000)}
          />
        </TableCell>
        <TableCell alignRight>
          <DirectedTokenPrice
            price={transferItem?.value?.display_value}
            direction={transferItem?.direction}
          />
        </TableCell>
        <TableCell alignRight>
          <CustomText
            fontSize="14px"
            color="var(--text-1)"
            text={transferItem?.usd_value?.display_value}
          />
        </TableCell>
      </TableRow>
    </ConditionalLink>
  );
};
