import { useQuery } from "@tanstack/react-query";
import { signedRequest } from "../../../../api/api";

export const fetchTopHolders = async ({ address, chain_id }) => {
  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/get_top_holders_of_token",
    bodyText: JSON.stringify({
      token_address: address,
      chain_id: chain_id,
    }),
  });
  return resp?.data?.data;
};

const useTopHolders = (address, chain_id) => {
  const topHoldersQuery = useQuery({
    queryKey: ["top_token_holders", address, chain_id],
    queryFn: () => fetchTopHolders({ address, chain_id }),
    enabled: !!address && !!chain_id,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
  });

  return topHoldersQuery;
};

export default useTopHolders;
