import classes from "./SearchFilters.module.css";

const SearchFilters = ({ filters, setFilters }) => {
  return (
    <div className={classes.container}>
      {filters.map((filter, index) => {
        return (
          <SearchFilterTile
            key={index}
            handleClick={() => {
              setFilters((prevFilters) => {
                const filtersListUpdated = prevFilters.map((prevFilter, i) => {
                  //for All
                  if (index === 0) {
                    if (i === 0) {
                      return {
                        ...prevFilter,
                        isSelected: true,
                      };
                    }
                    return {
                      ...prevFilter,
                      isSelected: false,
                    };
                  }
                  //for others
                  if (i === index) {
                    return {
                      ...prevFilter,
                      isSelected: !prevFilter.isSelected,
                    };
                  }
                  //set All to false if any other is selected
                  if (i === 0) {
                    return {
                      ...prevFilter,
                      isSelected: false,
                    };
                  }
                  return prevFilter;
                });
                //set All to true if none other is selected
                const isAnySelected = filtersListUpdated.some((prevFilter) => {
                  return prevFilter.isSelected;
                });
                if (!isAnySelected) {
                  filtersListUpdated[0].isSelected = true;
                }
                return filtersListUpdated;
              });
            }}
            text={filter.name}
            setFilters={setFilters}
            isSelected={filter.isSelected}
          />
        );
      })}
    </div>
  );
};

const SearchFilterTile = ({ text, isSelected, handleClick }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
      className={`${classes.filter_tag} ${
        isSelected ? `${classes.selected_filter_tag}` : ""
      }`}>
      {text}
    </div>
  );
};

export default SearchFilters;
