import {
  CustomColumn,
  PaddingComponent,
  CustomText,
} from "components/UI/Components/Components";
import Image from "components/UI/Image";
import classes from "./WelcomePostsList.module.css";
import AppLogo from "assets/svg/Landing/logo.svg";
import { useContext } from "react";
import { ThemeContext } from "contextStates/Theme.context";
import { FEED_CARDS } from "zxComponents/feed/ZxFeed.utils";

const AvatarColumn = ({ avatars = [] }) => {
  return (
    <div
      className={
        avatars.length === 4 ? classes.avatarColumnOne : classes.avatarColumnTwo
      }>
      {avatars?.map((a, index) => (
        <Image
          className={classes.miniAvatar}
          src={a}
          key={a + index}
          alt="0xppl user"
        />
      ))}
    </div>
  );
};
const MemberCount = ({ count, avatars = [] }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={classes.memberCountContainer}
      style={{
        background:
          theme === "dark"
            ? FEED_CARDS.WELCOME.darkModebackground
            : "var(--base)",
      }}>
      <AvatarColumn avatars={avatars?.slice(0, 4)} />
      <AvatarColumn avatars={avatars?.slice(4, 7)} />
      <CustomColumn justifyContent="center" flexGrow={1}>
        <CustomColumn alignItems="center">
          <PaddingComponent padding="24px">
            <img src={AppLogo} className={classes.appLogo} alt="0xppl" />
          </PaddingComponent>
          <div
            className={classes.memberCount}
            style={{
              "--item-count": count,
            }}></div>
          <CustomText text="MEMBERS ON 0xPPL" color="var(--text-2)" />
          <PaddingComponent height="24px" />
          <CustomText text="And growing strong 💪" color="var(--text-2)" />
        </CustomColumn>
      </CustomColumn>
      <AvatarColumn avatars={avatars?.slice(8, 11)} />
      <AvatarColumn avatars={avatars?.slice(12, 16)} />
    </div>
  );
};

export default MemberCount;
