import React, { useContext } from "react";
import ZxHorizontalScrollWrapper from "zxComponents/common/ZxHorizontalScrollWrapper/ZxHorizontalScrollWrapper";
import styles from "./FollowSuggestions.module.scss";
import twitterIcon from "assets/svg/twitter.svg";
import farcasterIcon from "assets/svg/farcaster.svg";
import lensIcon from "assets/svg/lens.svg";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "contextStates/AuthContext";
import { QUERY_KEYS } from "utils/query_utils";
import { getRecommendedProfiles } from "api/community.api";
import ZxCard from "zxComponents/common/ZxCard/ZxCard";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import { CustomColumn, Divider } from "components/UI/Components/Components";
import FollowedByFollowers from "components/V2/Community/ProfileItem/FollowedByFollowers";
import { reasonsFromProfile } from "utils/profile_utils";
import { ProfileItemTypes } from "components/V2/Community/ProfileItem/ProfileItem";
import FollowEditButton from "components/FollowEditButton/FollowEditButton";
import {
  HorizontalScrollButtons,
  horizontalScrollContainerClassName,
} from "components/Feed/HorizontalScrollButtons";
import { FEED_CARDS } from "../ZxFeed.utils";
import withNullableError from "zxComponents/common/withNullableError/index";
import { ThemeContext } from "contextStates/Theme.context";
import ZxImageStack from "zxComponents/common/ZxImageStack/ZxImageStack";

const CARD_WIDTH = 300;
const CARD_HEIGHT = 220;

const FollowAttribution = ({ socials }) => {
  const icons = [];
  if (socials.includes("twitter")) {
    icons.push({
      src: twitterIcon,
      alt: "Twitter",
    });
  }
  if (socials.includes("farcaster")) {
    icons.push({
      src: farcasterIcon,
      alt: "Farcaster",
    });
  }
  if (socials.includes("lens")) {
    icons.push({
      src: lensIcon,
      alt: "Lens",
    });
  }
  return (
    <>
      <Divider margin="8px 0 8px 0" />
      <div className={styles.attribution}>
        <span>You are following this profile on</span>
        <ZxImageStack pictures={icons} width="16px" height="16px" />
      </div>
    </>
  );
};

const FollowSuggestions = () => {
  const { identityDetails } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const identifier = identityDetails?.current?.identity?.id;
  const { isLoading, data, isError } = useQuery({
    queryKey: QUERY_KEYS.FEED_SUGGESTIONS({ identifier }),
    queryFn: getRecommendedProfiles,
    staleTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
  });
  const elRef = React.useRef();
  const cards = data?.data?.slice(0, 10) ?? null;
  const followers = data?.followers ?? null;
  if ((cards && cards.length === 0) || isError) return null;
  const moveToNext = () => {
    elRef.current?.scrollBy({ left: CARD_WIDTH, behavior: "smooth" });
  };
  return (
    <ZxHorizontalScrollWrapper
      isLoading={isLoading}
      itemCount={data?.items?.length ?? 0}
      meta={FEED_CARDS.FOLLOW}
      title="People to follow">
      <div
        className={`${styles.container} ${horizontalScrollContainerClassName}`}
        ref={elRef}>
        {cards &&
          cards.map((card, idx) => {
            const { reasons } = reasonsFromProfile(card);
            const socialFollows = card.user_follows_identity;
            let socials = [];
            Object.keys(socialFollows).forEach((key) => {
              if (socialFollows[key]) socials.push(key);
            });
            return (
              <ZxCard
                key={idx}
                card={card}
                minWidth={CARD_WIDTH}
                background={
                  theme === "dark"
                    ? FEED_CARDS.FOLLOW.darkModebackground
                    : "var(--base)"
                }
                minHeight={CARD_HEIGHT}>
                <CustomColumn gap="8px">
                  <ZxActor
                    actor={card}
                    showFollowButton={false}
                    nameLength={15}
                    showBio
                  />
                  {socials?.length ? (
                    <FollowAttribution socials={socials} />
                  ) : (
                    <FollowedByFollowers
                      reason={reasons[0]}
                      minHeight={"64px"}
                      refetchFeedOnFollowOrUnfollow
                      identity_details={followers}
                      key={`followed-by-followers-${idx}`}
                      wrapLines={true}
                      trimItem={true}
                      maxLengthPerItem={12}
                      profileItemType={
                        card.followed_on_twitter
                          ? ProfileItemTypes.twitter
                          : ProfileItemTypes["0xppl"]
                      }
                    />
                  )}
                  <FollowEditButton
                    refetchFeedOnFollowOrUnfollow={false}
                    actor={card}
                    showEdit={false}
                    name={card?.display_name}
                    twitter={card?.socials?.twitter}
                    socials={card?.socials}
                    bio={card?.bio}
                    followed={card?.followed}
                    address={card?.address}
                    identityId={card?.identity_id || card?.id}
                    profileType={card?.profile_type}
                    onUpdateCallback={moveToNext}
                    disabled={card.disable_follow}
                    followDirectly={card.follow_directly}
                    fullWidth={true}
                  />
                </CustomColumn>
              </ZxCard>
            );
          })}
      </div>
      {!isLoading && data && cards?.length > 2 && (
        <HorizontalScrollButtons wrapperEl={elRef} postWidth={CARD_WIDTH} />
      )}
    </ZxHorizontalScrollWrapper>
  );
};

export default withNullableError(FollowSuggestions);
