import React, { useContext } from "react";
import { getNetworth } from "../../../../../utils/profile_utils";
import classes from "./CreateGroupPanel.module.css";
import {
  CustomColumn,
  CustomRow,
  FlexGrow,
} from "../../../Profile/ProfileDetails/wallets/AddWallet/AddWalletComponent";
import {
  CustomBackButton,
  CustomButton,
  CustomText,
  PaddingComponent,
  SpinnerComponent,
} from "../../../../UI/Components/Components";
import WalletPrivacy from "../../../Profile/ProfileDetails/wallets/components/WalletPrivacy";
import AddressComponent from "../../../../UI/Components/AddressComponent";
import WalletVerifiedBadge from "../../../Profile/ProfileDetails/wallets/components/WalletVerifiedBadge";
import { Checkbox } from "@mui/material";
import { getCdnUrl } from "../../../../../utils/assets";
import { AuthContext } from "../../../../../contextStates/AuthContext";
import { HelpSection } from "../../../Profile/ProfileDetails/wallets/AddWallet/WalletType";
import { useBundleInfo } from "../../../../Pages/AppModule/RightSideBar/apis/useBundleInfo";

import { CreateGroupContext } from "./useGroupCreate";

import { ChannelTypes } from "../../helpers";

const GroupTypeOptions = ({ setActiveTab }) => {
  const { groupType, setGroupType, setShowWalletList } =
    useContext(CreateGroupContext);
  const optionClass = classes.option;
  return (
    <CustomRow gap={"20px"} justifyContent="stretch">
      <CustomColumn width="50%" gap="16px" alignItems="center">
        <CustomColumn
          justifyContent="center"
          className={`${optionClass} ${groupType === ChannelTypes.GROUP ? classes.active : ""}`}
          onClick={() => {
            setGroupType(ChannelTypes.GROUP);
            setActiveTab(1);
          }}>
          <img
            src={getCdnUrl("assets/messaging/chat-bubble-front-color.png")}
            alt="chat"
          />
          <CustomText text="Group chat" fontSize="15px" fontWeight="500" />
        </CustomColumn>
        <CustomColumn width="70%">
          <CustomText
            text="Chat with your friends on 0xPPL"
            fontSize="13px"
            fontWeight="400"
          />
        </CustomColumn>
      </CustomColumn>
      <CustomColumn width="50%" gap="16px" alignItems="center">
        <CustomColumn
          justifyContent="center"
          className={`${optionClass} ${groupType === ChannelTypes.STREAMING_GROUP ? classes.active : ""}`}
          onClick={() => {
            setGroupType(ChannelTypes.STREAMING_GROUP);
            setShowWalletList(true);
          }}>
          <img
            src={getCdnUrl("assets/messaging/dollar-front-color.png")}
            alt="Shot Talk"
          />
          <CustomText text="Trade talk" fontSize="15px" fontWeight="500" />
        </CustomColumn>
        <CustomColumn width="70%">
          <CustomText
            text="Chat + stream txns of your friends"
            fontSize="13px"
            fontWeight="400"
          />
        </CustomColumn>
      </CustomColumn>
    </CustomRow>
  );
};
const WalletRow = ({ wallet }) => {
  const { selectedWallets, setSelectedWallets } =
    useContext(CreateGroupContext);
  const networth = getNetworth(wallet) ?? "";
  const checked =
    selectedWallets.includes(wallet.address) || !wallet.is_private;
  const disabled = !wallet.is_private;
  const walletRowClass = disabled
    ? classes.walletRowDisabled
    : classes.walletRow;

  return (
    <label>
      <CustomRow
        gap="8px"
        className={walletRowClass}
        justifyContent="space-between">
        <CustomColumn>
          <CustomRow gap="6px">
            <CustomText
              text={wallet?.reason ? wallet?.reason : "-"}
              fontSize="15px"
              fontWeight="500"
              color="var(--text-1)"
            />
            <CustomText text={"·"} fontSize="15px" fontWeight="500" />
            <CustomText
              text={networth}
              fontSize="15px"
              fontWeight="500"
              color="var(--text-1)"
            />
          </CustomRow>
          <CustomRow alignItems="center" gap="4px">
            <WalletPrivacy wallet={wallet} size={16} />
            <AddressComponent index={1} address={wallet?.address} />
            <WalletVerifiedBadge wallet={wallet} size={16} />
            <CustomText text={"·"} fontSize="15px" fontWeight="500" />
            <CustomText
              text={wallet?.is_private ? "Private" : "Public"}
              fontSize="15px"
              fontWeight="500"
            />
          </CustomRow>
        </CustomColumn>
        <CustomRow alignItems="center" gap="4px">
          <Checkbox
            style={{
              color: checked ? "var(--primary-color)" : "var(--elevation-3)",
            }}
            disabled={!wallet.is_private}
            checked={checked}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedWallets([...selectedWallets, wallet.address]);
              } else {
                setSelectedWallets(
                  selectedWallets.filter((item) => item !== wallet.address)
                );
              }
            }}
          />
        </CustomRow>
      </CustomRow>
    </label>
  );
};
const WalletGroup = ({ wallets, enabled = false, title = "" }) => {
  if (!wallets || wallets.length === 0) {
    return null;
  }
  return (
    <>
      <CustomText
        text={title}
        fontSize="17px"
        fontWeight="600"
        color="var(--text-1)"
        textAlign="left"
      />
      <div
        className={classes.walletListContainer}
        style={
          enabled
            ? {
                borderColor: "var(--primary-color60)",
                boxShadow: "0px 4px 8px 0px --primary-color20",
              }
            : {}
        }>
        {wallets.map((wallet) => {
          return <WalletRow wallet={wallet} key={wallet.address} />;
        })}
      </div>
      <PaddingComponent minHeight="16px" />
    </>
  );
};

export const PickWalletForGroups = ({ identifier, setActiveTab }) => {
  const { data } = useBundleInfo({ identifier });
  if (!data) {
    return <SpinnerComponent size={40} />;
  }
  const wallets = data.data.data.bundle.wallets;
  const publicWallets = wallets.filter((wallet) => !wallet.is_private);
  const privateWallets = wallets.filter((wallet) => wallet.is_private);
  if (privateWallets.length === 0) {
    setActiveTab?.(1);
  }
  return (
    <>
      <WalletGroup wallets={publicWallets} title={"Public Wallets"} />
      <WalletGroup
        wallets={privateWallets}
        enabled={true}
        title={"Private Wallets"}
      />
    </>
  );
};
export const GroupType = ({ setActiveTab }) => {
  const { isStep1Valid, showWalletList, setShowWalletList } =
    useContext(CreateGroupContext);
  const { identityDetails } = useContext(AuthContext);
  if (showWalletList) {
    return (
      <div className={classes.formWrapper}>
        <div className={classes.form}>
          <CustomColumn gap="8px">
            <CustomRow gap="8px">
              <CustomBackButton
                onClick={() => {
                  setShowWalletList(false);
                }}
                isShow={true}
              />
              <CustomText
                text="Show your wallets to the group"
                fontSize="17px"
                fontWeight="600"
                color="var(--text-1)"
                textAlign="left"
              />
            </CustomRow>
            <CustomText
              text="Exchange view access of your wallets with the group"
              fontSize="13px"
              fontWeight="400"
              color="var(--text-2)"
              textAlign="left"
            />
            <PaddingComponent minHeight="8px" />
            <PickWalletForGroups
              setActiveTab={setActiveTab}
              identifier={identityDetails?.current?.identity?.id}
            />
          </CustomColumn>
        </div>
        <div className={classes.footer}>
          <CustomButton
            className={classes.nextButton}
            disabled={!isStep1Valid}
            text="Next"
            height="58px"
            fontSize="17px"
            radius="8px"
            onClick={() => {
              setActiveTab(1);
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div className={classes.formWrapper}>
      <div className={classes.form}>
        <CustomColumn>
          <CustomText
            margin="0 0 8px 0"
            text="Choose group type"
            fontSize="17px"
            fontWeight="600"
            color="var(--text-1)"
            textAlign="left"
          />
          <CustomText
            text="Choose group chat or trade talk to stream txns"
            fontSize="14px"
            fontWeight="400"
            textAlign="left"
          />
        </CustomColumn>
        <PaddingComponent minHeight="16px" />

        <GroupTypeOptions setActiveTab={setActiveTab} />
        <FlexGrow />
        <HelpSection
          icon={getCdnUrl("assets/messaging/dollar-front-color.png")}
          title={"What is Trade Talk?"}
          subTitle={
            "Go beyond the usual messaging and share wallets to stream live transactions from everyone in the group"
          }
          bgColor="var(--info-background)"
        />
        <PaddingComponent minHeight="24px" />
      </div>
    </div>
  );
};
