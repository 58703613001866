import { CustomRow, CustomSkeleton } from "components/UI/Components/Components";
import React, { useContext } from "react";
import styles from "./ZxHorizontalScrollWrapper.module.scss";
import ZxText from "../ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { horizontalScrollContainerClassName } from "components/Feed/HorizontalScrollButtons";
import { ThemeContext } from "contextStates/Theme.context";
const ZxHorizontalScrollWrapper = ({
  isLoading,
  children,
  meta,
  containerPadding = "16px 0",
}) => {
  const { theme } = useContext(ThemeContext);
  if (!meta) return null;
  return (
    <div
      className={`${styles.container}`}
      style={{
        background: theme === "light" ? meta.gradientLight : meta.gradientDark,
        padding: containerPadding,
      }}>
      {meta.title && (
        <CustomRow
          style={{ paddingLeft: "16px" }}
          alignItems="center"
          gap="8px">
          {meta.icon && (
            <img
              src={meta.icon}
              alt={meta.title}
              className={styles.headerImage}
            />
          )}
          <ZxText style={TSTYLES.title1}>{meta.title}</ZxText>
        </CustomRow>
      )}
      <div
        className={`${styles.scrollContainer} ${horizontalScrollContainerClassName} `}>
        {isLoading ? (
          <div className={styles.loaders}>
            {[1, 2, 3].map((i) => {
              return (
                <CustomSkeleton
                  key={i}
                  variant="rounded"
                  width={`${meta.width}px`}
                  height={`${meta.height}px`}
                  borderRadius="12px"
                />
              );
            })}
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default ZxHorizontalScrollWrapper;
