import OtherUserBundleHeader from "./OtherUserBundleHeader";
import classes from "../WalletDetails.module.css";
import OtherUserBundleWallets from "./OtherUserBundleWallets";
import withLoader from "components/core/withLoader";
import { getBundleData } from "api/profile.api";

const OtherUserBundleDetails = ({
  queryData,
  refetch,
  viewerPerspectiveId,
  identifier,
}) => {
  const bundleData = queryData.data.data;
  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <div className={classes.wrapper}>
          <OtherUserBundleHeader
            identifier={identifier}
            viewerPerspectiveId={viewerPerspectiveId}
            bundleData={bundleData}
            refetchBundle={refetch}
          />
        </div>
      </div>
      <div className={classes.panelDetailsPadding}>
        <OtherUserBundleWallets bundleData={bundleData} />
      </div>
    </div>
  );
};
export default withLoader(OtherUserBundleDetails, getBundleData, {
  queryKey: (props) => [
    "get_bundle_info",
    props.identifier,
    props.viewerPerspectiveId,
  ],
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  renderNotFound: false,
});
