import React, { useContext, useState } from "react";

import { useAddRemoveFromWatchlistMutation } from "api/trending.api";
import { GlobalContext } from "contextStates/Global";
import styles from "./WatchlistTokenButton.module.css";
import {
  PaddingComponent,
  SpinnerComponent,
} from "components/UI/Components/Components";
import { Star } from "@phosphor-icons/react";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";

const WatchlistTokenButton = ({
  isWatchlisted = false,
  chainId = null,
  tokenAddress = null,
  tokenSlug = null,
  refetch,
  iconWrapper,
  isNft = false,
  collectionId = null,
  newStyle = false,
}) => {
  const [isAdded, setIsAdded] = useState(isWatchlisted);
  // useEffect(() => {
  //   setIsAdded(isWatchlisted);
  // }, [isWatchlisted]);
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);

  const { mutate, isPending: isLoading } = useAddRemoveFromWatchlistMutation({
    action: isAdded ? "remove" : "add",
    chainId,
    contractAddress: tokenAddress,
    slug: tokenSlug,
    collectionId,
    isNft,
    // this is to save state since theres no backend flag right now, remove when its ready
    onSuccess: () => {
      handleSuccessSnackbar?.(
        `${isAdded ? "Removed from" : "Added to"} watchlist`
      );
      setIsAdded((prev) => !prev);
      if (refetch) refetch();
    },
    onError: (err) => {
      console.error(err);
      handleErrorSnackbar?.(
        null,
        `Failed to ${isAdded ? "remove" : "add"} to watchlist`
      );
    },
  });
  if (newStyle) {
    return (
      <PPLxTooltip title="Watchlist" arrow placement="top">
        <PaddingComponent
          backgroundColor="var(--warning-light)"
          color="var(--warning)"
          padding="8px"
          borderRadius="8px"
          style={{ cursor: "pointer" }}
          fontSize="12px"
          fontWeight="bold"
          onClick={(e) => {
            e.stopPropagation();
            if (!isLoading) {
              mutate();
            }
          }}>
          <ZxFlex gap="8px" align="center">
            {isLoading ? (
              <SpinnerComponent size={16} />
            ) : (
              <Star
                color="var(--warning)"
                size={16}
                weight={isAdded ? "fill" : "regular"}
              />
            )}

            {!isAdded && (
              <ZxText color="warning" fontSize="12px" style={TSTYLES.bodyLabel}>
                Add to Watchlist
              </ZxText>
            )}
          </ZxFlex>
        </PaddingComponent>
      </PPLxTooltip>
    );
  }
  return (
    <PPLxTooltip title="Watchlist" placement="top" arrow>
      <div
        className={`${styles.container} ${iconWrapper ? styles.iconWrapper : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          if (!isLoading) {
            mutate();
          }
        }}>
        {isLoading ? (
          <SpinnerComponent size={20} />
        ) : (
          <Star
            size={20}
            color={isAdded ? "#FFA800" : "var(--text-2)"}
            weight={isAdded ? "fill" : "regular"}
          />
        )}
      </div>
    </PPLxTooltip>
  );
};

export default WatchlistTokenButton;
