import classes from "../WalletDetails.module.css";
import backIcon from "assets/svg/profile/back_arrow.svg";
import { GlobalContext } from "contextStates/Global";
import { useContext, useState } from "react";
import classesBundle from "./OtherUserBundle.module.css";
import InfoTile, { INFO_TILE_TYPE } from "../InfoTile";
import { ProfileContext } from "contextStates/ProfileContext";
import { getNetWorthV2 } from "utils/profile_utils";
import { copyBundle } from "api/profile.api";
import withLoader from "components/core/withLoader";
import { getBundleData } from "api/profile.api";
import { useParams, useHistory } from "react-router-dom";

const OtherUserBundleHeader = ({
  queryData,
  viewerPerspectiveId,
  bundleData,
  refetchBundle,
}) => {
  const history = useHistory();
  let { identifier, address } = useParams();

  const { profileData, updateProfile } = useContext(ProfileContext);
  const [isCopyingBundle, setIsCopyingBundle] = useState(false);
  const { handleErrorSnackbar, setSnackBarState } = useContext(GlobalContext);
  const viewerPerspectiveProfile =
    queryData.data.data?.identity_details[viewerPerspectiveId];

  const [isBundleSimilar, setIsBundleSimilar] = useState(
    bundleData.is_bundle_similar
  );

  const onCopyBundle = () => {
    setIsCopyingBundle(true);

    copyBundle({
      user: viewerPerspectiveProfile.id,
      identifier: bundleData.id,
    })
      .then((data) => {
        setIsCopyingBundle(false);
        setIsBundleSimilar(true);
        refetchBundle();
        updateProfile();
        setSnackBarState({
          open: true,
          message: "Bundle copied",
          type: "success",
        });
      })
      .catch((error) => {
        setIsCopyingBundle(false);
        handleErrorSnackbar(error, "Failed to copy bundle");
      });
  };

  return (
    <div className={classes.header}>
      <img
        onClick={() => {
          history.replace(`/${identifier}/wallets/${address}`);
        }}
        className={classes.crossIcon}
        src={backIcon}
        alt={"Back"}></img>
      <span
        className={
          classes.title
        }>{`${viewerPerspectiveProfile.display_name}'s Bundle for`}</span>
      <div className={classesBundle.iconRow}>
        <img
          className={classesBundle.avatar}
          src={profileData?.current?.details?.display_picture}
          alt={"User Profile"}></img>
        <div className={classesBundle.userDetails}>
          <span className={classes.title}>
            {profileData?.current?.details?.display_name}
          </span>
          <span className={classes.subtitle}>{`${getNetWorthV2(
            profileData?.current.details
          )}  •  ${
            profileData?.current?.details?.bundle?.address_infos?.length
          } wallets`}</span>
        </div>
      </div>
      {isBundleSimilar !== undefined && (
        <InfoTile
          isButtonLoading={isCopyingBundle}
          buttonText={isBundleSimilar ? "" : "Copy Bundle"}
          buttonOnClick={() => {
            onCopyBundle();
          }}
          infoTileType={
            isBundleSimilar ? INFO_TILE_TYPE.NEUTRAL : INFO_TILE_TYPE.INFO
          }
          text={
            isBundleSimilar
              ? "You have the same bundle"
              : "This bundle is different from your bundle"
          }
        />
      )}
      {isBundleSimilar !== undefined && (
        <div style={{ paddingBottom: "24px" }} />
      )}
    </div>
  );
};
export default withLoader(OtherUserBundleHeader, getBundleData, {
  queryKey: (props) => ["bundle", props.identifier],
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  renderNotFound: false,
});
