import classes from "../../WalletsPage.module.css";
import classesTable from "../../components/walletTableHeader.module.css";
import WalletsTableHeader from "../../components/walletsTableHeader";
import WalletHeader from "../../components/walletsHeader";
import OtherUserBundleWalletTile from "./OtherUserBundleWalletTile";
import { getNetWorthV2 } from "utils/profile_utils";

const OtherUserBundleWallets = ({ bundleData }) => {
  return (
    <div className={classes.walletsBox}>
      <WalletHeader
        title={`${bundleData?.bundle?.wallets?.length} Wallets`}
        totalUsdValue={`${getNetWorthV2(bundleData?.bundle)}`}
      />

      <div className={classesTable.table}>
        <WalletsTableHeader
          items={[
            { name: "ENS/WALLET", flex: 3 },
            { name: "REASON", flex: 3 },
            { name: "NETWORTH", alignment: "right", flex: 2 },
          ]}></WalletsTableHeader>

        {bundleData?.bundle?.wallets.map((wallet, index) => (
          <div
            key={index}
            className={`${classesTable.tableRow} ${classesTable.tableList}`}>
            <OtherUserBundleWalletTile
              index={index}
              bundleData={bundleData}
              wallet={wallet}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherUserBundleWallets;
