import classes from "./TransactionsRightPanel.module.css";
import TransctionRightPanelHeader from "./TransactionsRightPanelHeader";
import { useState, useMemo } from "react";
import TransactionDetails from "./TransactionDetails";
import { getTransactionIndexFromId } from "./transaction_utils";
import { QueryKeyConstants } from "utils/constants";
import withLoader from "components/core/withLoader";
import { getActivityById } from "api/feed.api";
import TransactionInternalCalls from "./InternalCalls/TransactionInternalCalls";
import TransactionLogs from "./Logs/TransactionLogs";
import { PaddingComponent } from "components/UI/Components/Components";

const TransactionsRightPanel = ({
  queryData: activityData,
  groupedActivity,
  activityId,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  // Handle the case where caller does not have / pass groupedActivity
  groupedActivity = groupedActivity ?? activityData;
  const index = getTransactionIndexFromId(groupedActivity, activityId);
  const currentActivity = useMemo(
    () => activityData.activities[0],
    [activityData.activities]
  );
  const currentTxnHash = useMemo(
    () => currentActivity.transactions[0].tx_id,
    [currentActivity.transactions]
  );

  return (
    <div className={classes.rightPanelContainer}>
      <TransctionRightPanelHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        index={index}
        activity={activityData.activities[0]}
        groupedActivity={groupedActivity}
        onClose={onClose}
        isSimulated={activityData.is_simulated}
      />
      {activeTab === 0 && (
        <PaddingComponent padding="16px 20px">
          <TransactionDetails
            activityData={activityData}
            groupedActivity={groupedActivity}
            activity={groupedActivity.activities[index]}
            index={index}
            activityId={activityId}
            isRightPane
          />
        </PaddingComponent>
      )}
      {activeTab === 1 && (
        <PaddingComponent padding="16px 20px">
          <TransactionInternalCalls
            activityData={activityData}
            activityId={activityId}
            txnHash={currentTxnHash}
          />
        </PaddingComponent>
      )}
      {activeTab === 2 && (
        <TransactionLogs activityData={activityData} activityId={activityId} />
      )}
    </div>
  );
};

export default withLoader(TransactionsRightPanel, getActivityById, {
  queryKey: (props) => [QueryKeyConstants.ACTIVITY_BY_ID, props.activityId],
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  renderNotFound: false,
});
