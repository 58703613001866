import withLoader from "components/core/withLoader";
import Loader from "components/UI/Loader/Loader";
import ChainInfo from "./ChainInfo";
import { getChainInfo } from "./api";
import { useEffect } from "react";
import { PaddingComponent } from "components/UI/Components/Components";

const ChainHeader = ({ queryData: metadata, setMetadata }) => {
  useEffect(() => {
    if (metadata) setMetadata(metadata);
  }, [metadata, setMetadata]);

  return <ChainInfo metadata={metadata} />;
};

export default withLoader(ChainHeader, getChainInfo, {
  queryKey: ({ chainId, chainType }) => ["chainInfo", { chainId, chainType }],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 2,
  renderNotFound: false,
  CustomLoader: () => (
    <PaddingComponent paddingTop="20px">
      <Loader />
    </PaddingComponent>
  ),
});
