import { PaddingComponent } from "components/UI/Components/Components";
import tokenClasses from "./TransactionTokenTransfer.module.css";
import { TableCell, TableRow } from "components/UI/Table/Table";

export const TransactionTokenTransferHeader = ({
  tokenTransfersLength = 0,
  isShimmer = false,
}) => {
  return (
    <>
      {!isShimmer && (
        <span className={tokenClasses.infoText}>
          {`${
            tokenTransfersLength > 0 ? tokenTransfersLength : "No"
          } Token Transfers`}
        </span>
      )}
      <PaddingComponent height="12px"></PaddingComponent>
      <TableRow noPadding>
        <TableCell style={{ flexBasis: "33%" }}>
          <div className={tokenClasses.headerText}>FROM</div>
        </TableCell>
        <TableCell style={{ flexBasis: "33%" }}>
          <div className={tokenClasses.headerText}>TO</div>
        </TableCell>
        <TableCell alignRight style={{ flexBasis: "33%" }}>
          <div className={tokenClasses.headerText}>AMOUNT</div>
        </TableCell>
      </TableRow>
    </>
  );
};
