import classes from "components/V2/Profile/ProfileDetails/Filters/Filters.module.css";
import { useState } from "react";
import { ClickAwayListener } from "@mui/material";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import ExplorerFiltersPopUp from "./ExplorerFiltersPopUp";
import SortIcon from "@mui/icons-material/Sort";
import { Box } from "@mui/material";

const ExplorerFiltersButton = ({
  identifiers,
  setSelectedActiveChains,
  selectedActiveChains,
  selectedActiveTokens,
  setSelectedActiveTokens,
  onSuccess,
  tokenTuples,
  setTokenTuples,
  filterChains,
  setFilterChains,
}) => {
  const [showPopUpCard, setShowPopUpCard] = useState(false);
  const Filter = () => (
    <div
      onClick={() => {
        setShowPopUpCard((prevShowPopupCard) => !prevShowPopupCard);
      }}
      className={classes.box}>
      <Box
        component={SortIcon}
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "14px",
          color: "var(--text-2)",
        }}
      />
      <span className={classes.text}>Filters</span>
    </div>
  );
  if (showPopUpCard) {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setShowPopUpCard(false);
        }}>
        <div>
          <PPLxHoverCard
            open={showPopUpCard}
            content={
              <ExplorerFiltersPopUp
                onSuccess={onSuccess}
                setShowPopUpCard={setShowPopUpCard}
                identifiers={identifiers}
                setSelectedChains={setSelectedActiveChains}
                selectedActiveChains={selectedActiveChains}
                selectedActiveTokens={selectedActiveTokens}
                setSelectedTokens={setSelectedActiveTokens}
                tokenTuples={tokenTuples}
                setTokenTuples={setTokenTuples}
                filterChains={filterChains}
                setFilterChains={setFilterChains}
              />
            }
            placement="bottom">
            <div className={classes.spinner_container}>
              <Filter />
            </div>
          </PPLxHoverCard>
        </div>
      </ClickAwayListener>
    );
  }
  return <Filter />;
};
export default ExplorerFiltersButton;
