import { useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getNodeTransfers } from "api/graph.api";
import { getMarkers } from "utils/chart_utils";
import TradingViewGraph from "components/V2/TradingViewGraph/TradingViewGraph";
import { useTradingViewMarkers } from "utils/trading_view_utils";
import { ONE_DAY } from "utils/constants";

const fetchNodeTransfers = async (identifier, selectedToken) => {
  const res = await getNodeTransfers({
    identifier: identifier,
    selectedActiveChains: [
      selectedToken?.profile?.address_chain ?? selectedToken?.chain_id,
    ],
    selectedActiveTokens: [
      selectedToken?.token_address ?? selectedToken?.address,
    ],
  });
  return res.data;
};

const PnlChart = ({ identifier, selectedToken, identityId = null }) => {
  const { getChartMarkers, updateMarkers, clearMarkers } =
    useTradingViewMarkers();
  useEffect(() => {
    return () => {
      clearMarkers();
    };
  }, [identifier, selectedToken, clearMarkers]);
  const { data: recentTokenTransfers } = useInfiniteQuery({
    queryKey: [
      "pnlNodeTransfers",
      identifier,
      null,
      null,
      selectedToken?.profile?.address_chain ?? selectedToken?.chain_id,
      selectedToken?.token_address ?? selectedToken?.address,
    ],
    queryFn: () => fetchNodeTransfers(identifier, selectedToken),
    staleTime: ONE_DAY,
    enabled:
      !!identifier &&
      !!(selectedToken?.profile?.address_chain ?? selectedToken?.chain_id) &&
      !!(selectedToken?.token_address ?? selectedToken?.address),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!pages || !lastPage) return null;
      return lastPage?.data?.timestamp_cursor
        ? lastPage?.data?.timestamp_cursor - 1
        : null;
    },
  });
  useEffect(() => {
    if (recentTokenTransfers && identifier) {
      updateMarkers(
        getMarkers({
          recentTokenTransfers: recentTokenTransfers,
          identifier,
          identityId,
        })
      );
    }
  }, [recentTokenTransfers, identifier, updateMarkers, identityId]);
  // return null;
  return (
    <TradingViewGraph
      address={selectedToken?.address}
      chain={
        selectedToken?.address_chain ??
        selectedToken?.profile?.address_chain ??
        selectedToken?.chain_id
      }
      showPriceHeader
      getMarkers={getChartMarkers}
      isNativeToken={
        selectedToken?.is_native_token ??
        selectedToken?.link?.includes("/native_token/") ??
        false
      }
    />
  );
};

export default PnlChart;
