import withLoader from "components/core/withLoader";
import { getTrendingTransactionsInfo } from "./trendingUtils";
import classes from "./trendingTokenDetails.module.css";
import { CustomColumn, CustomRow } from "components/UI/Components/Components";
import { useState } from "react";
import { createPortal } from "react-dom";
import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import ViewAllTransfersRightPanel from "./ViewAllTransfersRightPanel";
import TrendingTile from "./TrendingTile";

const PopularTransfers = ({ queryData: transfersInfo, tokenProfile }) => {
  const [isRightPaneOpen, setIsRightPaneOpen] = useState(false);

  if (!transfersInfo) {
    return null;
  }
  const whaleTransfersLength = transfersInfo?.whale_transfers?.length;
  const celebrityTransfersLength = transfersInfo?.celebrity_transfers?.length;

  return (
    <>
      <CustomRow gap="16px" alignItems="stretch">
        {(!!whaleTransfersLength || !!celebrityTransfersLength) && (
          <CustomColumn flexGrow={1}>
            {/* temporarily hide whale transactions */}
            {/* {!!whaleTransfersLength && (
              <TrendingTile
                profiles={transfersInfo?.profiles}
                transfers={transfersInfo?.whale_transfers}
                type="whale"
                {...(!!celebrityTransfersLength
                  ? { borderRadius: "6px 6px 0 0" }
                  : {})}
              />
            )} */}
            {!!celebrityTransfersLength && (
              <TrendingTile
                profiles={transfersInfo?.profiles}
                transfers={transfersInfo?.celebrity_transfers}
                visibleTransfersCount={6}
                type="celebrity"
                {...(!!transfersInfo?.whale_transfers?.length
                  ? { borderRadius: "0 0 6px 6px", borderTop: "none" }
                  : {})}
              />
            )}
          </CustomColumn>
        )}

        {!!transfersInfo?.transfers?.length && (
          <CustomColumn flexGrow={1}>
            <TrendingTile
              profiles={transfersInfo?.profiles}
              transfers={transfersInfo?.transfers}
              type="latest"
              visibleTransfersCount={6}
              handleViewAll={() => setIsRightPaneOpen(true)}
            />
          </CustomColumn>
        )}
      </CustomRow>

      {createPortal(
        <RightPanel onClose={() => setIsRightPaneOpen(false)}>
          {isRightPaneOpen && (
            <ViewAllTransfersRightPanel
              tokenProfile={tokenProfile}
              transfers={transfersInfo?.transfers}
              profiles={transfersInfo?.profiles}
              onClose={() => setIsRightPaneOpen(false)}
            />
          )}
        </RightPanel>,
        document.getElementById("rightPanel")
      )}
    </>
  );
};

export default withLoader(PopularTransfers, getTrendingTransactionsInfo, {
  queryKey: (props) => [
    "trending-transactions-info",
    props?.identifier,
    props?.chainId,
  ],
  containerClass: classes.popularTransfers,
  queryOptions: () => ({
    retry: 3,
    refetchInterval: 10 * 1000,
  }),
});
