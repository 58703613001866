import {
  InfoBox,
  CustomRow,
  CustomText,
  PaddingComponent,
  RightPanelCrossIcon,
  Divider,
} from "components/UI/Components/Components";
import classes from "./ManageBundles.module.css";
import { useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "contextStates/Global";
import { ProfileContext } from "contextStates/ProfileContext";
import { Faders, CaretRight } from "@phosphor-icons/react";
import AddEditWalletBundle from "./AddEditWalletBundle";
import WalletPrivacy from "components/V2/Profile/ProfileDetails/wallets/components/WalletPrivacy";
import AddressComponent from "components/UI/Components/AddressComponent";
import WalletVerifiedBadge from "components/V2/Profile/ProfileDetails/wallets/components/WalletVerifiedBadge";
import AddWallet from "../components/AddWallet";
import { TableRowItem } from "../components/WalletRowComponent";
import DeleteBundle from "./DeleteBundle";

const ManageBundles = ({ bundleData, refetch }) => {
  const { featureFlags } = useContext(GlobalContext);
  const { isLoggedInUserProfile } = useContext(ProfileContext);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  // check current user
  if (!isLoggedInUserProfile) return null;

  // check flag
  if (!featureFlags.is_showing_private_wallets) return null;

  return (
    <>
      <CustomRow
        gap="4px"
        alignItems="center"
        cursor="pointer"
        className={classes.manageBundles}
        onClick={() => {
          history.push(
            "/" + params.identifier + "/wallets/manage-groups" + location.search
          );
        }}>
        <Faders
          width={14}
          height={14}
          color="var(--text-2)"
          style={{ transform: "rotate(90deg)", flexShrink: 0 }}
        />
        <CustomText
          text="Manage Groups"
          fontSize="13px"
          fontWeight="600"
          color="var(--text-2)"
          whiteSpace="nowrap"
        />
      </CustomRow>
    </>
  );
};

export const ManageBundlesPanel = ({ bundleData, refetch }) => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const bundleIds = Object.keys(bundleData?.groups ?? {});
  const walletsCount = bundleIds.reduce(
    (sum, b) =>
      sum +
      (bundleData?.groups[b]?.num_private_wallets || 0) +
      (bundleData?.groups[b]?.num_public_wallets || 0),
    0
  );
  return (
    <>
      <div className={classes.rightPanel}>
        <div className={classes.header}>
          <RightPanelCrossIcon
            onClick={() => {
              history.goBack();
            }}></RightPanelCrossIcon>
          <span className={classes.title}>Manage Groups</span>
          <CustomRow gap="8px" alignItems="center">
            <CustomText
              text={`${bundleIds.length} Groups`}
              fontSize="13px"
              color="var(--text-2)"
            />
            <div className={classes.spanDot}></div>
            <CustomText
              text={`${walletsCount} Wallets`}
              fontSize="13px"
              color="var(--text-2)"
            />
          </CustomRow>
          <PaddingComponent height="16px" />
        </div>
        <PaddingComponent padding="8px 16px">
          <InfoBox width="100%">
            <CustomRow width="100%" alignItems="center" margin="0 0 16px 0">
              <CustomRow flexGrow={1}>
                <CustomText
                  text="All Groups"
                  fontSize="15px"
                  fontWeight="600"
                  color="var(--text-1)"
                />
              </CustomRow>
              <CustomRow flexShrink={0}>
                <AddEditWalletBundle refetch={refetch} />
              </CustomRow>
            </CustomRow>
            <CustomRow width="100%" alignItems="flex-start" margin="0 0 8px 0">
              <TableRowItem flex={1}>
                <CustomText
                  text="NAME"
                  fontSize="11px"
                  fontWeight="500"
                  color="var(--text-2)"></CustomText>
              </TableRowItem>
              <TableRowItem flex={1} alignment="right">
                <CustomText
                  text="NO OF WALLETS"
                  fontSize="11px"
                  fontWeight="500"
                  color="var(--text-2)"></CustomText>
              </TableRowItem>
              <TableRowItem flex={1} alignment="right">
                <CustomText
                  text="NETWORTH"
                  fontSize="11px"
                  fontWeight="500"
                  color="var(--text-2)"></CustomText>
              </TableRowItem>
              <CustomRow flexShrink={0} width="22px"></CustomRow>
            </CustomRow>
            <Divider />
            {bundleIds.map((id) => {
              const group = bundleData?.groups[id];
              return (
                <CustomRow
                  width="100%"
                  alignItems="center"
                  padding="16px 0 0"
                  cursor="pointer"
                  onClick={() => {
                    history.push(
                      `/${params.identifier}/wallets/manage-groups/` +
                        id +
                        location.search
                    );
                  }}>
                  <TableRowItem flex={1}>
                    <CustomText
                      text={group?.group_name}
                      fontSize="15px"
                      color="var(--text-1)"></CustomText>
                  </TableRowItem>
                  <TableRowItem flex={1} alignment="right">
                    <CustomText
                      text={
                        (group?.num_private_wallets || 0) +
                        (group?.num_public_wallets || 0)
                      }
                      fontSize="15px"
                      color="var(--text-1)"></CustomText>
                  </TableRowItem>
                  <TableRowItem flex={1} alignment="right">
                    <CustomText
                      text={group?.net_worth?.total_usd_value?.display_value}
                      fontSize="15px"
                      color="var(--text-1)"></CustomText>
                  </TableRowItem>
                  <CustomRow flexShrink={0} padding="0 4px">
                    <CaretRight width={16} height={16} color="var(--text-2)" />
                  </CustomRow>
                </CustomRow>
              );
            })}
          </InfoBox>
        </PaddingComponent>
      </div>
    </>
  );
};

export const BundleDetailsPanel = ({ bundleData, refetch, onClose }) => {
  const params = useParams();
  const history = useHistory();
  const groupId = parseInt(params.selectedGroupId);
  const groupData = bundleData?.groups[groupId];
  const walletsInGroup = bundleData?.bundle?.wallets?.filter((w) =>
    w.group_ids?.includes(parseInt(groupId))
  );
  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <RightPanelCrossIcon onClick={onClose}></RightPanelCrossIcon>
        <CustomRow>
          <span className={classes.title}>{groupData?.group_name}</span>
          <AddEditWalletBundle
            refetch={refetch}
            group={groupData}
            onlyIcon={true}
          />
          <DeleteBundle
            group={groupData}
            onSuccess={() => {
              refetch();
              onClose?.();
            }}
            onlyIcon={true}
          />
        </CustomRow>
        <CustomRow gap="8px" alignItems="center">
          <CustomText
            text={`${
              (groupData?.num_private_wallets || 0) +
              (groupData?.num_public_wallets || 0)
            } Wallets`}
            fontSize="13px"
            color="var(--text-2)"
          />
        </CustomRow>
        <PaddingComponent height="16px" />
      </div>
      <PaddingComponent padding="8px 16px">
        <InfoBox width="100%">
          <CustomRow width="100%" alignItems="center" margin="0 0 16px 0">
            <CustomRow flexGrow={1}>
              <CustomText
                text="Group Wallets"
                fontSize="15px"
                fontWeight="600"
                color="var(--text-1)"
              />
            </CustomRow>
            <CustomRow flexShrink={0}>
              <AddWallet
                refetch={refetch}
                identityId={bundleData?.id}
                groups={bundleData?.groups}
                selectedGroupIds={[parseInt(groupId)]}
              />
            </CustomRow>
          </CustomRow>
          <CustomRow width="100%" alignItems="flex-start" margin="0 0 8px 0">
            <TableRowItem flex={2}>
              <CustomText
                text="WALLET"
                fontSize="11px"
                fontWeight="500"
                color="var(--text-2)"></CustomText>
            </TableRowItem>
            <TableRowItem flex={1}>
              <CustomText
                text="NAME"
                fontSize="11px"
                fontWeight="500"
                color="var(--text-2)"></CustomText>
            </TableRowItem>
            <TableRowItem flex={1} alignment="right">
              <CustomText
                text="NETWORTH"
                fontSize="11px"
                fontWeight="500"
                color="var(--text-2)"></CustomText>
            </TableRowItem>
            {/* <CustomRow flexShrink={0} width="22px"></CustomRow> */}
          </CustomRow>
          <Divider />
          {walletsInGroup.map((wallet, index) => {
            return (
              <CustomRow
                width="100%"
                alignItems="center"
                padding="16px 0 0"
                cursor="pointer"
                onClick={() => {
                  history.push(
                    `/${params.identifier}/wallets/${wallet.address}`
                  );
                }}>
                <TableRowItem flex={2}>
                  <CustomRow alignItems="center" gap="4px">
                    <WalletPrivacy wallet={wallet} size={16} />
                    <AddressComponent
                      index={index + 1}
                      address={wallet?.address}
                    />
                    <WalletVerifiedBadge wallet={wallet} size={16} />
                  </CustomRow>
                </TableRowItem>
                <TableRowItem flex={1}>
                  <CustomText
                    text={wallet?.reason}
                    fontSize="15px"
                    color="var(--text-1)"></CustomText>
                </TableRowItem>
                <TableRowItem flex={1} alignment="right">
                  <CustomText
                    text={
                      wallet?.net_worth?.["_Any"]?.total_usd_value
                        ?.display_value
                    }
                    fontSize="15px"
                    color="var(--text-1)"></CustomText>
                </TableRowItem>
                {/* <CustomRow flexShrink={0} padding="0 4px">
                  <WalletMenu wallet={wallet} bundleData={bundleData} refetch={refetch} />
                </CustomRow> */}
              </CustomRow>
            );
          })}
        </InfoBox>
        <PaddingComponent height="16px" />
      </PaddingComponent>
    </div>
  );
};

export default ManageBundles;
