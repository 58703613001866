import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "components/UI/Table/Table";

import { PositionCategories } from "./PositionCategories";
import ProtocolAccordion from "./ProtocolAccordion";
import ProtocolInfo from "./ProtocolInfo";
import HoldingDetails from "./HoldingDetails";
import { createPortal } from "react-dom";
import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import ProtocolHoldingsRightPanel from "./ProtocolHoldingsRightPanel";
import { useContext, useLayoutEffect, useMemo, useRef, useState } from "react";
import { getActivitiesByIdentifiers } from "./GenericHoldingDetails";
import { useQuery } from "@tanstack/react-query";
import { ProfileContext } from "contextStates/ProfileContext";
import { reduce, size } from "lodash";
import {
  CustomRow,
  CustomText,
  PaddingComponent,
  PriceChange,
} from "components/UI/Components/Components";
import { PolyMarketHoldingRow } from "./PolyMarketHoldingRow";

const HoldingRow = ({
  holdingItem,
  rightPane = false,
  chainProfiles,
  categoryColumnWidth,
}) => {
  if (holdingItem.polymarket_holding) {
    return (
      <PolyMarketHoldingRow
        holdingItem={holdingItem}
        rightPane={rightPane}
        chainProfiles={chainProfiles}
      />
    );
  }
  return (
    <TableRow noPadding>
      <TableCell alignLeft style={rightPane ? { flexBasis: "40%" } : {}}>
        <ProtocolInfo protocol={holdingItem} chainProfiles={chainProfiles} />
      </TableCell>
      <TableCell style={{ flexBasis: "40%" }}>
        <PositionCategories
          positions={holdingItem.holdings_by_label}
          wrap={false}
          maxWidth="380px"
        />
      </TableCell>
      <TableCell alignRight shrink style={{ flexBasis: "10%" }}>
        <CustomRow gap="8px">
          {holdingItem.total_usd_value?.display_value}
          {holdingItem.pnl && holdingItem.pnl?.display_value ? (
            <PriceChange
              value={holdingItem.pnl?.value}
              displayValue={holdingItem.pnl?.display_value}
              fontWeight="500"
              showCaret={false}
            />
          ) : (
            <PaddingComponent width="20px">
              <CustomText text="-" color="var(--text-1)" />
            </PaddingComponent>
          )}
        </CustomRow>
      </TableCell>
    </TableRow>
  );
};

const Content = ({ holdings, rightPane, chainProfiles }) => {
  const [isRightPaneOpen, setIsRightPaneOpen] = useState(false);
  const [currentTxnIdentifiers, setCurrentTxnIdentifiers] = useState(null);
  const [currentPoolAddress, setCurrentPoolAddress] = useState(null);
  const [currentProtocolIndex, setCurrentProtocolIndex] = useState(null);
  const [currentPositionIndex, setCurrentPositionIndex] = useState(null);
  const [currentHoldingsLabel, setCurrentHoldingsLabel] = useState(null);

  const currentProtocolItem = holdings?.[currentProtocolIndex];
  const { identifier } = useContext(ProfileContext);
  const currentPositionItem =
    holdings?.[currentProtocolIndex]?.holdings_by_label?.[currentHoldingsLabel]
      ?.holdings?.[currentPositionIndex];
  const { data: groupedActivities, isLoading } = useQuery({
    queryKey: [
      "transaction_activity_details",
      currentTxnIdentifiers,
      currentPoolAddress,
    ],
    queryFn: () =>
      getActivitiesByIdentifiers({
        txnIdentifiers: currentTxnIdentifiers,
        poolId: currentPoolAddress,
        chainId: holdings?.[currentProtocolIndex]?.chain_id,
        identifier: identifier.current,
      }),
    enabled: !!currentTxnIdentifiers || !!currentPoolAddress,
    retry: 3,
    retryOnMount: false,
    staleTime: 1000 * 60 * 5,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const txnsCount = useMemo(
    () => countActivityGroupTransactions(groupedActivities),
    [groupedActivities]
  );

  const categoryColumnRef = useRef(null);
  const [categoryColumnWidth, setCategoryColumnWidth] = useState(null);
  useLayoutEffect(() => {
    if (categoryColumnRef.current) {
      setCategoryColumnWidth(categoryColumnRef.current.offsetWidth);
    }
  }, []);

  return (
    <Table noPaddingBottom>
      <TableHeader
        style={{
          width: "calc(100% - 40px)",
          margin: "0 auto 8px auto",
          padding: 0,
        }}>
        <TableCell alignLeft style={rightPane ? { flexBasis: "40%" } : {}}>
          Name
        </TableCell>
        <TableCell
          style={{ flexBasis: "50%", overflowX: "auto", flexWrap: "nowrap" }}
          ref={categoryColumnRef}>
          Category
        </TableCell>
        <TableCell alignRight style={{ marginRight: "20px", flexBasis: "10%" }}>
          Value
        </TableCell>
      </TableHeader>
      {holdings.map((item, index) => (
        <ProtocolAccordion
          key={`protocol-table-row-${index}`}
          summary={
            <HoldingRow
              holdingItem={item}
              rightPane={rightPane}
              chainProfiles={chainProfiles}
              categoryColumnWidth={categoryColumnWidth}
            />
          }
          details={
            <HoldingDetails
              setCurrentPositionIndex={setCurrentPositionIndex}
              setCurrentProtocolIndex={setCurrentProtocolIndex}
              setCurrentHoldingsLabel={setCurrentHoldingsLabel}
              protocolIndex={index}
              holdings={item.holdings_by_label}
              invested={item.usd_value_invested}
              protocolHoldingItem={item}
              setIsRightPaneOpen={setIsRightPaneOpen}
              setCurrentTxnIdentifiers={setCurrentTxnIdentifiers}
              setCurrentPoolAddress={setCurrentPoolAddress}
            />
          }
        />
      ))}
      {createPortal(
        <RightPanel onClose={() => setIsRightPaneOpen(false)}>
          {isRightPaneOpen && (
            <ProtocolHoldingsRightPanel
              isLoading={isLoading}
              groupedActivities={groupedActivities}
              currentProtocolItem={currentProtocolItem}
              currentPositionItem={currentPositionItem}
              txnsCount={txnsCount}
              currentHoldingsLabel={currentHoldingsLabel}
              onClose={() => setIsRightPaneOpen(false)}
            />
          )}
        </RightPanel>,
        document.getElementById("rightPanel")
      )}
    </Table>
  );
};

export default Content;

const countActivityGroupTransactions = (objects) => {
  return reduce(
    objects,
    (total, obj) => {
      return total + size(obj.activities);
    },
    0
  );
};
