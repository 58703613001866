import { QrCodeBorder } from "assets/svg/left_sidebar/CustomSvgIcons";
import classes from "./QrCode.module.css";
import { QRCodeFloating } from "./QrCode";

const BroderedQRCode = () => {
  return (
    <div>
      <div className={classes.parentImage}>
        <QrCodeBorder width={112} height={112} />
      </div>
      <div className={classes.childImage}>
        <QRCodeFloating
          color="var(--text-1)"
          isBorderEnabled={false}
          size={96}
        />
      </div>
    </div>
  );
};

export default BroderedQRCode;
