import { useMemo, useContext } from "react";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import classes from "./ProfileInfo.module.css";
import FollowEditButton from "components/FollowEditButton/FollowEditButton";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Bio from "./Bio";
import { ProfileTypes } from "../ProfileConstants";
import { useParams } from "react-router-dom";
import NotificationPopUp from "../Notifications/NotificationPopUp";
import PartOfProfile, {
  PartOfProfileTypes,
} from "../../../UI/PartOfProfile/PartOfProfile";
import ExternalLinks from "./ExternalLinks";
import {
  CustomColumn,
  CustomRow,
  Divider,
  FlexGrow,
  PaddingComponent,
  TypeTag,
} from "components/UI/Components/Components";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import ConnectionButton from "components/V2/Profile/ProfileHeader/ConnectionButton";
import { UsersFour } from "@phosphor-icons/react";
import SendTokenButton from "./SendTokenButton";
import ConditionalLink from "shared/ConditionalLink";
import PersonProfile from "components/UI/PersonProfile/PersonProfile";
import ExplorerCard from "./ExplorerCard";
import NetWorthCard from "./NetWorthCard";
import ScrollContainer from "react-indiana-drag-scroll";
import AddressComponent from "components/UI/Components/AddressComponent";
import SendMessageButton from "./SendMessageButton";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import { ProfileContext } from "contextStates/ProfileContext";
import { SendTokenContext } from "contextStates/SendTokenContext";
import { isProfileVerified } from "utils/misc";
import useIsLoggedInUser from "customHooks/useIsLoggedInUser";
import GenericButton from "components/V2/GenericButton/GenericButton";
import { useAuthContext } from "contextStates/AuthContext";

export const LinkWrapper = ({ children, link }) => {
  return link ? <Link to={link}>{children}</Link> : children;
};

const ProfileInfo = ({
  info,
  refetch,
  profileType,
  isIdentityPage,
  graphQueryKey,
}) => {
  let { identifier, active_tab } = useParams();
  const { isSuperuser } = useAuthContext();
  const { isLoggedInUserProfile, profileData } = useContext(ProfileContext);
  const isMyProfile = useIsLoggedInUser(info);
  const history = useHistory();
  const isAddress = [
    ProfileTypes.ADDRESS,
    ProfileTypes.COSMOS,
    ProfileTypes.SOLANA,
    ProfileTypes.BITCOIN,
    ProfileTypes.TON,
  ].includes(profileType);
  const onFollowed = (data) => {
    if (profileType === ProfileTypes.ADDRESS) {
      const navigatePath = data?.link;
      history.push({ pathname: navigatePath });
    } else {
      refetch();
    }
  };

  const relatedIdentity = useMemo(() => {
    const relatedIdentities = info?.related_identities?.identities ?? [];
    if (relatedIdentities?.length > 0) {
      // [{id: , ...otherDetails}], extract first id from object
      const relatedIdentityId = relatedIdentities[0].id;
      return info.related_identities.profiles[relatedIdentityId];
    }
    return null;
  }, [info]);

  const aliasesList = useMemo(() => {
    return Object.keys(info?.aliases ?? {}).slice(0, 3);
  }, [info?.aliases]);

  const borderedRowItems = [];

  if (info?.invite_details?.invitor_details) {
    borderedRowItems.push(
      <div className={classes.invite_details}>
        <span>Invited by</span>
        <PersonProfile profile={info?.invite_details.invitor_details} />
      </div>
    );
  }

  if (info?.invite_details?.invited_users_count) {
    if (borderedRowItems.length)
      borderedRowItems.push(<span className={classes.dotLight}></span>);
    borderedRowItems.push(
      <ConditionalLink
        to={`/refer-friends/${info?.id}`}
        className={classes.invited_users_count}>
        <UsersFour size={16} />
        <span>{info.invite_details?.invited_users_count} Invited</span>
      </ConditionalLink>
    );
  }

  if (
    info?.active_since &&
    profileType !== ProfileTypes.COSMOS &&
    profileType !== ProfileTypes.SOLANA
  ) {
    if (borderedRowItems.length)
      borderedRowItems.push(<span className={classes.dotLight}></span>);
    borderedRowItems.push(
      <div className={classes.active_since}>
        Onchain since {info.active_since}
      </div>
    );
  }
  const isPPLUser = () => {
    return (
      (info?.type === "identity" && info?.onboarding_state === "complete") ||
      (info?.type === "address" && info?.user_since)
    );
  };
  if (isPPLUser()) {
    const created =
      info?.type === "identity"
        ? new Date(info.onboarding_completed_at)
        : new Date(info.user_since);

    if (borderedRowItems.length)
      borderedRowItems.push(<span className={classes.dotLight}></span>);
    borderedRowItems.push(
      <div className={classes.active_since}>
        0xPPL user since {created.toLocaleString("default", { month: "short" })}{" "}
        {created.getDate()} {created.getFullYear()}
      </div>
    );
  }
  const isGraphDisabled = info?.disable_graph ?? false;
  if (!info) return null;
  return (
    <div className={classes.profile_info}>
      <CustomRow className={classes.avatar_panel}>
        <Image
          src={info.display_picture}
          name={info.display_name}
          imageType={IMAGE_TYPES.AVATAR}
          className={classes.profile_pic}
          alt={info.display_name || info.title || info.address}
        />
        <CustomColumn
          width="100%"
          padding="8px 0 0"
          className={`${
            isAddress || info.username?.length === 0 || aliasesList.length === 0
              ? classes.no_actor_info
              : ""
          }`}>
          <div className={classes.title_panel}>
            <div className={classes.title_row}>
              <PPLxTooltip title={info.display_name || info.title}>
                <span
                  className={classes.title}
                  onClick={(e) => {
                    if (isSuperuser) {
                      // copy identity id to clipboard
                      navigator.clipboard.writeText(
                        info.id ?? info.identity_id
                      );
                    }
                  }}>
                  {info.display_name || info.title}
                </span>
              </PPLxTooltip>
              <VerifiedBadge profile={info} showTooltip />
              {isAddress && <TypeTag text="Address" />}
            </div>
            <div className={classes.aliases}>
              {!isAddress && info.username?.length > 0 && (
                <span className={classes.alias}>
                  {"@"}
                  {info.username}
                </span>
              )}
              {aliasesList.length > 0 && (
                <>
                  {info.username && <span className={classes.dot}></span>}
                  <span key={`AKA-item`} className={classes.alias}>
                    AKA
                  </span>
                  <span style={{ whiteSpace: "pre-wrap", width: "3px" }}>
                    {" "}
                  </span>
                  {aliasesList.map((alias, index) => {
                    return (
                      <span key={index} className={classes.alias}>
                        {alias}
                        {index !== aliasesList.length - 1 && (
                          <span>,&nbsp;</span>
                        )}
                      </span>
                    );
                  })}
                </>
              )}
            </div>
            <CustomRow>
              {isAddress ? (
                <AddressComponent
                  address={info.address}
                  showTrimmed={true}
                  digitsInAddress={100}
                  displayOnHover={false}
                />
              ) : (
                <div className={classes.follower_info}>
                  <LinkWrapper
                    link={
                      (info.following_count?.value ?? 0) === 0
                        ? null
                        : `/${identifier}/${active_tab}/following`
                    }>
                    <div className={classes.follower_info_item}>
                      <span className={classes.count}>
                        {info.following_count?.value}
                      </span>
                      <span className={classes.text}>following</span>
                    </div>
                  </LinkWrapper>
                  <span className={classes.dot}></span>
                  <LinkWrapper
                    link={
                      (info.follower_count?.value ?? 0) === 0
                        ? null
                        : `/${identifier}/${active_tab}/followers`
                    }>
                    <div className={classes.follower_info_item}>
                      <span className={classes.count}>
                        {info.follower_count?.value}
                      </span>
                      <span className={classes.text}>followers</span>
                    </div>
                  </LinkWrapper>
                </div>
              )}
            </CustomRow>
          </div>
        </CustomColumn>
      </CustomRow>
      <div className={classes.mutual_contacts_cont}>
        {info?.mutual_followers_info && !isLoggedInUserProfile && (
          <>
            <Divider />
            <div className={classes.mutual_contacts}>
              <FeedDescription
                templateString={info?.mutual_followers_info}
                identities={info.identities}
                isPlainText={true}
                isPairwiseRelationship={true}
              />
            </div>
          </>
        )}
      </div>

      <div className={classes.padded_items}>
        <FlexGrow>
          <Divider />
          <CustomRow gap="16px" width="100%" padding="12px 0">
            <FlexGrow>
              <ExternalLinks
                isVerticalDivider={false}
                links={info.external_links}
              />
            </FlexGrow>

            <div className={classes.follow_panel}>
              <NotificationPopUp profile={info} />
              {!isMyProfile ? (
                <FollowEditButton
                  name={info?.display_name || info?.title}
                  twitter={info?.socials?.twitter}
                  socials={info?.socials}
                  bio={info?.bio}
                  followed={info?.followed}
                  address={info.address}
                  identityId={info.identity_id || info.id}
                  profileType={profileType}
                  onUpdateCallback={onFollowed}
                  disabled={info.disable_follow}
                  showEdit={!isAddress && !isProfileVerified(info)}
                  followDirectly={info.follow_directly}
                />
              ) : (
                <ConditionalLink to={`/${identifier}/edit`}>
                  <GenericButton
                    color="var(--text-2)"
                    background="var(--elevation-2)"
                    customClass={classes.edit_profile_button}
                    borderRadius="8px">
                    Edit Profile
                  </GenericButton>
                </ConditionalLink>
              )}
            </div>
          </CustomRow>
        </FlexGrow>

        <CustomColumn whiteSpace="nowrap">
          <Divider />
          <CustomRow alignItems="center">{borderedRowItems}</CustomRow>
          <Divider />
        </CustomColumn>
        {info.bio && (
          <>
            <PaddingComponent height="8px" />
            <div className={classes.bio}>
              <Bio bio={info.bio} />
            </div>
          </>
        )}
        {isAddress && (
          <CustomRow
            gap="8px"
            alignItems="center"
            justifyContent="flex-start"
            padding="8px 0"
            width="100%">
            <SendTokenContext.Provider
              value={{
                identity: info,
                relatedIdentity,
                isAddress,
                isLoggedInUserProfile,
              }}>
              <SendTokenButton />
            </SendTokenContext.Provider>
          </CustomRow>
        )}
        {isAddress && relatedIdentity != null && (
          <div>
            <PartOfProfile
              profileType={PartOfProfileTypes.PROFILE}
              link={relatedIdentity.link}
              name={relatedIdentity.display_name}
              icon={relatedIdentity.display_picture}
            />
          </div>
        )}
      </div>
      {!isAddress && (
        <CustomRow
          gap="8px"
          alignItems="center"
          justifyContent="flex-start"
          padding="8px 20px 16px"
          width="100%">
          <SendTokenContext.Provider
            value={{
              identity: info,
              relatedIdentity,
              isAddress,
              isLoggedInUserProfile,
            }}>
            <SendTokenButton />
          </SendTokenContext.Provider>
          <ConnectionButton identity={info} />
          <SendMessageButton identity={info} />
        </CustomRow>
      )}

      <ScrollContainer className={classes.cards}>
        <NetWorthCard identifier={identifier} />
        {!isGraphDisabled && (
          <ExplorerCard
            isIdentityPage={isIdentityPage}
            graphQueryKey={graphQueryKey}
            username={profileData?.current?.details?.username}
            identifier={profileData?.current?.details?.id ?? identifier}
            showSummary={
              profileType !== ProfileTypes.COSMOS &&
              profileType !== ProfileTypes.SOLANA
            }
            title={
              profileType === ProfileTypes.COSMOS
                ? "EXPLORE VISUALLY"
                : "TOTAL VALUE TRANSFERRED"
            }
          />
        )}
      </ScrollContainer>
    </div>
  );
};

export default ProfileInfo;
