import classes from "./PartOfProfile.module.css";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LayersIcon from "@mui/icons-material/Layers";
import Image, { IMAGE_TYPES } from "components/UI/Image";

export const PartOfProfileTypes = {
  CONTRACT: "CONTRACT",
  PROFILE: "PROFILE",
};

const ProfileText = {
  [PartOfProfileTypes.CONTRACT]: "This contract is a part of",
  [PartOfProfileTypes.PROFILE]: "This address is bundled with",
};

const ViewText = {
  [PartOfProfileTypes.CONTRACT]: "View Protocol Page",
  [PartOfProfileTypes.PROFILE]: "View Profile Page",
};

const PartOfProfile = ({
  link,
  profileType = PartOfProfileTypes.PROFILE,
  name,
  icon,
}) => {
  return (
    <Link to={link} target="_blank" className={classes.part_of_profile}>
      <div className={classes.protocol_card_container}>
        <Link to={link} target="_blank">
          <OpenInNewIcon
            height={40}
            width={40}
            className={classes.open_in_new_tab}
            color={"var(--text-2)"}
          />
        </Link>
        <div className={classes.protocol_details}>
          <div className={classes.protocol_text}>
            {ProfileText[profileType]}
          </div>
          <div className={classes.protocol}>
            <Image
              src={icon}
              alt={name}
              imageType={IMAGE_TYPES.PROTOCOL}
              className={classes.protocol_image}
            />
            <span className={classes.protocol_name}>{name}</span>
          </div>
        </div>

        <div className={classes.view_protocol}>
          <LayersIcon className={classes.layers_icon} />
          <div className={classes.view_protocol_text}>
            {ViewText[profileType]}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PartOfProfile;
