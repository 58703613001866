import React, { useContext, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "utils/query_utils";
import { getWelcomePosts, useFetchFeed } from "api/feed.api";
import ZxHorizontalScrollWrapper from "zxComponents/common/ZxHorizontalScrollWrapper/index";
import WelcomePostCard from "./WelcomePostCard";
import ZxCard from "zxComponents/common/ZxCard/ZxCard";
import styles from "./WelcomePosts.module.scss";
import {
  HorizontalScrollButtons,
  horizontalScrollContainerClassName,
} from "components/Feed/HorizontalScrollButtons";
import { FEED_CARDS } from "../ZxFeed.utils";
import withNullableError from "zxComponents/common/withNullableError/index";
import { ThemeContext } from "contextStates/Theme.context";
import MemberCount from "components/FeedItemV2/WelcomePostsList/MemberCount";
import { uniq } from "lodash";
const CARD_WIDTH = 340;
const CARD_HEIGHT = 374;

const WelcomePosts = () => {
  const elRef = React.useRef();
  const { isLoading, data, isError } = useQuery({
    queryKey: QUERY_KEYS.WELCOME_POSTS(),
    queryFn: getWelcomePosts,
    staleTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
  });
  const posts = useMemo(() => data?.welcome_posts ?? [], [data]);
  const { theme } = useContext(ThemeContext);
  const usersCount = data?.num_users_onboarded;
  const { flattenedFeedData } = useFetchFeed({
    activeTab: "new_feed",
    filter: "",
  });
  const avatars = useMemo(() => {
    if (flattenedFeedData?.length > 0) {
      let links = uniq(
        flattenedFeedData
          ?.map((f) => {
            const { identities } = f;
            if (identities) {
              return uniq(
                Object.values(identities).map((i) => i?.display_picture)
              );
            } else {
              return null;
            }
          })
          ?.filter((v) => v)
          .flat()
      );
      const newFeedProfiles = flattenedFeedData[0]?.profiles;
      Object.keys(newFeedProfiles || {}).forEach((key) => {
        if (newFeedProfiles[key].is_verified)
          links.push(newFeedProfiles[key]?.display_picture);
      });
      if (links.length > 0 && links.length < 14) {
        while (links.length < 20) {
          links = links.concat(links);
        }
      }
      const welcomeAvatars = posts
        ? posts.map((p) => p?.actor?.display_picture)
        : [];
      const unique = uniq(links);
      return unique?.length > 12 ? unique : [...unique, ...welcomeAvatars];
    }
    return [];
  }, [flattenedFeedData, posts]);
  if (isError || posts?.length === 0) {
    return null;
  }
  const moveToNext = () => {
    elRef.current?.scrollBy({ left: CARD_WIDTH, behavior: "smooth" });
  };

  return (
    <ZxHorizontalScrollWrapper
      isLoading={isLoading}
      itemCount={data?.items?.length ?? 0}
      meta={FEED_CARDS.WELCOME}
      title="Welcome to our new members">
      <div
        className={`${styles.container} ${horizontalScrollContainerClassName}`}
        ref={elRef}>
        {posts &&
          posts.map((p) => {
            return (
              <ZxCard
                key={p.id}
                minWidth={CARD_WIDTH}
                minHeight={CARD_HEIGHT}
                background={
                  theme === "dark"
                    ? FEED_CARDS.WELCOME.darkModebackground
                    : "var(--base)"
                }>
                <WelcomePostCard post={p} moveToNext={moveToNext} />
              </ZxCard>
            );
          })}
        {posts && posts.length > 0 && (
          <MemberCount count={usersCount} avatars={avatars} />
        )}
      </div>
      {posts && posts.length > 2 && (
        <HorizontalScrollButtons wrapperEl={elRef} postWidth={CARD_WIDTH} />
      )}
    </ZxHorizontalScrollWrapper>
  );
};

export default withNullableError(WelcomePosts);
