import {
  BuySellActionText,
  CustomText,
  InfoBoxContainer,
  PaddingComponent,
} from "components/UI/Components/Components";
import styles from "./NftSaleCard.module.css";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { getTrimmedString, referenceTimeAgo } from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";
import { trackEvent } from "utils/event_tracking";
const NftSaleCard = ({
  displayPicture,
  nativeChainLogo,
  displayValue,
  swapSymbol,
  action,
  actorAvatar,
  txLink,
  className,
  timestamp,
}) => {
  const timeDisplay = referenceTimeAgo({
    date: timestamp * 1000,
    referenceTime: new Date(),
  });
  return (
    <ConditionalLink
      to={txLink}
      onClick={() =>
        trackEvent("trending_card_recent_swaps_click", {
          tx_link: txLink ?? "",
        })
      }>
      <InfoBoxContainer
        className={`${styles["trending-card-sale"]} ${className}`}
        background="var(--elevation-1)">
        <Image
          src={displayPicture}
          alt={"nft-image"}
          imageType={IMAGE_TYPES.NFT}
          className={styles["sale-image"]}
        />
        <PaddingComponent
          padding="8px 12px"
          className={styles["sale-info-container"]}>
          <div className={styles["sale-action-container"]}>
            <Image
              src={actorAvatar}
              imageType={IMAGE_TYPES.AVATAR}
              className={styles["sale-avatar"]}
            />
            <BuySellActionText action={action} />
            <CustomText
              text={`${timeDisplay} ${timeDisplay === "just now" ? "" : "ago"}`}
              color="var(--text-3)"
              fontWeight="400"
              fontSize="11px"
              lineHeight="16.5px"
              className={styles["sale-time"]}
            />
          </div>
          {displayValue ? (
            <PaddingComponent className={styles["sale-price-container"]}>
              {/* <Image
                src={nativeChainLogo}
                imageType={IMAGE_TYPES.SMALL_CONTRACT}
                alt={swapSymbol}
                className={styles["sale-chain-logo"]}
              /> */}
              {swapSymbol === "ETH" ? (
                <Image
                  src={nativeChainLogo}
                  imageType={IMAGE_TYPES.SMALL_CONTRACT}
                  alt={swapSymbol}
                  className={styles["sale-chain-logo"]}
                />
              ) : (
                <PaddingComponent width="16px" height="16px" />
              )}

              <CustomText
                text={`${displayValue} ${getTrimmedString(swapSymbol, 10)}`}
                lineHeight="20px"
                color="var(--text-1)"
                className={styles["sale-value"]}
              />
            </PaddingComponent>
          ) : (
            <PaddingComponent height="20px" />
          )}
        </PaddingComponent>
      </InfoBoxContainer>
    </ConditionalLink>
  );
};

export default NftSaleCard;
