import {
  Divider,
  CustomColumn,
  PaddingComponent,
  CustomRow,
  InfoBox,
  FlexGrow,
} from "components/UI/Components/Components";
import { useState, useRef } from "react";
import LogsTile from "./LogsTile";
import CustomSwitchButton from "components/UI/Components/CustomSwitch";
import classes from "./TransactionLogs.module.css";
import LogsSearchBar from "./LogsSearchBar";
import EmptyStateUI from "components/ErrorBoundary/EmptyStateUI";

const TransactionLogs = ({ activityData, isFullPage = false }) => {
  const [isParsed, setIsParsed] = useState(true);
  const logs = activityData?.activities[0].transactions[0]?.data?.logs;
  const expandedIndexesList = useRef([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  if (!(logs?.length ?? []))
    return (
      <PaddingComponent padding="16px">
        <EmptyStateUI
          text="No transaction logs found."
          fullPage={true}></EmptyStateUI>
      </PaddingComponent>
    );
  return (
    <CustomColumn overflowY="scroll">
      <PaddingComponent padding={isFullPage ? "0px" : "8px 20px 8px 20px"}>
        <CustomRow gap="10px">
          <FlexGrow>
            {logs?.length ? (
              <LogsSearchBar
                filterColumns={[
                  "logIndex",
                  "event",
                  "data",
                  "address",
                  "topics",
                  "args",
                ]}
                setIsSearchInputFocused={setIsSearchInputFocused}
                isSearchInputFocused={isSearchInputFocused}
                items={logs}
                searchResult={searchResult}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setSearchResult={setSearchResult}
              />
            ) : null}
          </FlexGrow>
          <InfoBox width={"180px"} padding="6px 16px">
            <CustomRow gap="6px" justifyContent="center" alignItems="center">
              <span
                className={
                  isParsed
                    ? classes.switchSelectedText
                    : classes.switchUnselectedText
                }>
                Parsed
              </span>
              <CustomSwitchButton
                toggle={!isParsed}
                setToggle={() => {
                  setIsParsed(!isParsed);
                }}></CustomSwitchButton>
              <span
                className={
                  !isParsed
                    ? classes.switchSelectedText
                    : classes.switchUnselectedText
                }>
                Unparsed
              </span>
            </CustomRow>
          </InfoBox>
        </CustomRow>
      </PaddingComponent>
      {!isFullPage && <Divider />}
      <PaddingComponent
        padding={isFullPage ? "10px 0px" : "16px 20px 16px 20px"}>
        {(searchTerm?.length ? searchResult : logs)?.map((log, index) => {
          return (
            <LogsTile
              expandedIndexesList={expandedIndexesList}
              isParsed={isParsed}
              log={log}></LogsTile>
          );
        })}
      </PaddingComponent>
    </CustomColumn>
  );
};

export default TransactionLogs;
