import React, { useState } from "react";
import {
  CustomColumn,
  CustomRow,
  CustomTextSpan,
  PaddingComponent,
  Divider,
  InfoBox,
  FlexGrow,
  CursorDiv,
} from "components/UI/Components/Components";
import ParseToggleSwitch, { ToggleSwitch } from "./ParseToggleSwitch";
import {
  Table,
  TableHeader,
  TableCell,
  TableRow,
} from "components/UI/Table/Table";
import OverflowText from "components/UI/OverflowText";
import classes from "./inputData.module.css";
import { CaretUp, CaretDown } from "@phosphor-icons/react";
import SyntaxHighlighter from "react-syntax-highlighter";
import ProfileRenderer from "components/UI/Components/ProfileRenderer";
import { hexToUtf8 } from "utils/misc";
import isValidUTF8 from "utf-8-validate";

const InputData = ({ data, profiles }) => {
  const [showParsed, setShowParsed] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showUtf8, setShowUtf8] = useState(false);
  if (!data) {
    return null;
  }

  const parsedData = data?.parsed;
  const unparsedData = data?.unparsed?.raw;
  const utf8 = hexToUtf8(data?.unparsed?.raw);

  const displayUtf8Toggle =
    !parsedData && utf8?.length > 0 && isValidUTF8(utf8);

  return (
    <InfoBox padding="12px 0px 0px 16px">
      <CustomColumn gap="16px">
        <PaddingComponent paddingRight="16px">
          <CustomColumn gap="16px">
            <HeaderInfo data={data} />
            <Divider />
            <ToggleTile
              showParsed={showParsed}
              setShowParsed={setShowParsed}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              setShowUtf8={setShowUtf8}
              showUtf8={showUtf8}
              parsedData={parsedData}
              unparsedData={unparsedData}
              displayUtf8Toggle={displayUtf8Toggle}
            />
          </CustomColumn>
        </PaddingComponent>
        {isExpanded ? (
          <InputDataRenderer
            unparsedData={unparsedData}
            parsedData={parsedData}
            jsonData={data?.json}
            utf8={utf8}
            showParsed={showParsed}
            showUtf8={showUtf8}
            profiles={profiles}
          />
        ) : (
          //this is required for giving padding coming because of gap
          <div />
        )}
      </CustomColumn>
    </InfoBox>
  );
};

const InputDataRenderer = ({
  unparsedData,
  parsedData,
  jsonData,
  utf8,
  showParsed,
  showUtf8,
  profiles,
}) => {
  if (parsedData && showParsed) {
    return (
      <ParsedDataRenderer
        parsedData={parsedData}
        jsonData={jsonData}
        profiles={profiles}
      />
    );
  }

  return (
    <div className={`${classes.code} ${classes.codeText}`}>
      {showUtf8 ? utf8 : unparsedData}
    </div>
  );
};

const ParsedDataRenderer = ({ profiles, parsedData, jsonData }) => {
  if (isJson1LevelNested(jsonData)) {
    return <ParsedInputData data={parsedData} profiles={profiles} />;
  }
  return (
    <div className={classes.code}>
      <SyntaxHighlighter
        className={classes.jsonSyntax}
        language="javascript"
        style={{}}
        showLineNumbers={false}
        customStyle={{
          fontSize: "13px",
          color: "var(--text-1)",
          fontFamily: "Roboto",
          backgroundColor: "transparent",
          letterSpacing: "0.26px",
          lineHeight: "180%",
        }}>
        {JSON.stringify(jsonData, null, 2)}
      </SyntaxHighlighter>
    </div>
  );
};

const ToggleTile = ({
  showParsed,
  setShowParsed,
  isExpanded,
  setIsExpanded,
  showUtf8,
  setShowUtf8,
  parsedData,
  unparsedData,
  displayUtf8Toggle,
}) => {
  return (
    <CustomRow>
      <CursorDiv onClick={() => setIsExpanded((prev) => !prev)}>
        <CustomRow alignItems="center">
          <CustomTextSpan
            fontSize="13px"
            fontWeight="600"
            color="var(--text-2)">
            Show Input Data
          </CustomTextSpan>
          <PaddingComponent width="4px" />
          {isExpanded ? (
            <CaretUp color="var(--text-2)" size={16} />
          ) : (
            <CaretDown color="var(--text-2)" size={16} />
          )}
        </CustomRow>
      </CursorDiv>
      <FlexGrow />
      {displayUtf8Toggle ? (
        <ToggleSwitch
          isDisabled={!isExpanded}
          enabled={showUtf8}
          setIsEnabled={setShowUtf8}
          leftText="Unparsed"
          rightText="UTF-8"
        />
      ) : (
        <ParseToggleSwitch
          isDisabled={!isExpanded}
          showParsed={showParsed}
          setShowParsed={setShowParsed}
          leftText={parsedData && "Parsed"}
          rightText={unparsedData && "Unparsed"}
          enableSwitch={parsedData && unparsedData}
        />
      )}
    </CustomRow>
  );
};

const HeaderInfo = ({ data }) => {
  if (isFunctionAvailable(data)) {
    return <FunctionInfo data={data} />;
  }
  return <MethodInfo methodId={data?.methodId} />;
};

const FunctionInfo = ({ data }) => {
  return (
    <CustomColumn>
      <CustomTextSpan fontSize="13px" fontWeight="600" color="var(--text-2)">
        Function
      </CustomTextSpan>
      <PaddingComponent height="4px" />
      <FunctionTextItems data={data} />
    </CustomColumn>
  );
};

const FunctionTextItems = ({ data }) => {
  const functionElements = [];
  functionElements.push(
    <CustomTextSpan color="var(--accent-1)">{data?.method_name}</CustomTextSpan>
  );
  functionElements.push(
    <CustomTextSpan whitespace="pre-wrap">{" ("}</CustomTextSpan>
  );
  data?.arg_types?.map((type, index) => {
    const name = data?.arg_names[index];
    if (type) {
      functionElements.push(
        <CustomTextSpan whitespace="pre-wrap" color="var(--warning)">
          {type}
        </CustomTextSpan>
      );
      const isLast = index === data?.arg_types?.length - 1;
      let trailingText = "";
      if (name) {
        if (isLast) {
          trailingText = ` ${name}`;
        } else {
          trailingText = ` ${name}, `;
        }
      } else if (!isLast) {
        trailingText = `, `;
      }
      functionElements.push(
        <CustomTextSpan whitespace="pre-wrap">{trailingText}</CustomTextSpan>
      );
    }
    return type;
  });
  functionElements.push(<CustomTextSpan>{")"}</CustomTextSpan>);
  return <CustomRow flexWrap="wrap">{functionElements}</CustomRow>;
};

const MethodInfo = ({ methodId }) => {
  return (
    <CustomColumn>
      <CustomTextSpan fontSize="13px" fontWeight="600" color="var(--text-2)">
        Method ID
      </CustomTextSpan>
      <PaddingComponent height="4px" />
      <CustomTextSpan>{methodId}</CustomTextSpan>
    </CustomColumn>
  );
};

const ParsedInputData = ({ data, profiles }) => {
  return (
    <div>
      <Table>
        <TableHeader noPadding noBorder>
          <TableCell alignLeft shrink>
            #
          </TableCell>
          <TableCell alignLeft style={{ flexGrow: 0 }}>
            NAME
          </TableCell>
          <TableCell alignLeft>TYPE</TableCell>
          <TableCell alignLeft style={{ flexGrow: 1 }}>
            DATA
          </TableCell>
        </TableHeader>

        {data?.map((argument, index) => (
          <TableRow
            noPadding
            alignToFlexStart
            key={`argument-${argument.name}-${index}`}>
            <TableCell alignLeft shrink>
              {index}
            </TableCell>
            <TableCell alignLeft style={{ flexGrow: 0 }}>
              <OverflowText limit={12} text={argument.name} />
            </TableCell>
            <TableCell alignLeft>
              <OverflowText limit={12} text={argument.type} />
            </TableCell>
            <TableCell alignLeft style={{ flexGrow: 1 }}>
              {argument.type === "address" &&
              profiles?.[argument.data.display_value] ? (
                <ProfileRenderer
                  profile={profiles[argument.data.display_value]}
                  copyable="address"
                />
              ) : (
                <OverflowText limit={12} text={argument.data.display_value} />
              )}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </div>
  );
};

const isJson1LevelNested = (json) => {
  if (json) {
    for (const key in json) {
      if (typeof json[key] === "object" && json[key] !== null) {
        return false;
      }
    }
  }
  return true;
};

const isFunctionAvailable = (data) => {
  if (data?.arg_types?.length > 0) {
    return true;
  }
  return false;
};

export default InputData;
