import { signedRequest } from "api/api";

export const getChainInfo = async ({ chainId, chainType }) => {
  const resp = await signedRequest({
    method: "get",
    path: `/api/v4/get_chain_details?chain_id=${chainId}&chain_type=${chainType}`,
  });

  return resp.data.data;
};
export const getLatestBlocks = async ({
  chain_id,
  end_block_number = null,
  start_block_number = null,
  chainType,
}) => {
  let path = ``;
  if (end_block_number)
    path = `/api/v4/get_blocks_range?chain_type=${chainType}&chain_id=${chain_id}&end_block_number=${end_block_number}`;
  else if (start_block_number)
    path = `/api/v4/get_blocks_range?chain_type=${chainType}&chain_id=${chain_id}&start_block_number=${start_block_number}`;
  else
    path = `/api/v4/get_blocks_range?chain_type=${chainType}&chain_id=${chain_id}`;

  const resp = await signedRequest({
    method: "get",
    path: path,
  });

  return resp.data.data;
};

export const getBlockDetails = async ({ chainType, chainId, blockNumber }) => {
  const path = `/api/v4/get_block_details?chain_type=${chainType}&chain_id=${chainId}&last_block_number=${blockNumber}`;
  const resp = await signedRequest({
    method: "get",
    path: path,
  });

  return resp.data.data;
};
