import classes from "./FilterRowTile.module.css";
import formClasses from "components/UI/PopUpForm/PopUpForm.module.css";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { getChainMetaData } from "utils/misc";
import { useCallback } from "react";

const ChainRowTile = ({
  chain,
  chainDetails,
  setChainsSelected,
  chainsSelected,
}) => {
  const chainIcon = getChainMetaData(chain);
  const isActive = chainsSelected.includes(chain);
  const chainActiveClass = isActive ? classes.active : "";

  const toggle = useCallback(() => {
    if (chainsSelected.includes(chain)) {
      const updatedChains = chainsSelected.filter(function (chainVal) {
        return chainVal !== chain;
      });
      setChainsSelected([...updatedChains]);
      return;
    }

    setChainsSelected([...chainsSelected, chain]);
  }, [chain, chainsSelected, setChainsSelected]);

  return (
    <div className={`${classes.box} ${chainActiveClass}`} onClick={toggle}>
      <div className={classes.spacingRowTile}>
        <div className={classes.rowTile}>
          <input
            type="checkbox"
            checked={chainsSelected.includes(chain)}
            className={classes.box}
            readOnly
          />
          <Image
            className={classes.chainIcon}
            src={chainIcon?.icon}
            alt={chainIcon?.name}
            imageType={IMAGE_TYPES.CHAIN}
          />
          <span className={classes.title}>{chain}</span>
        </div>
        {chainDetails && (
          <div className={classes.subtitle}>
            {chainDetails.total_usd_value.display_value}
          </div>
        )}
      </div>
      <div className={formClasses.divider}></div>
    </div>
  );
};

export default ChainRowTile;
