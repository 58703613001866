import { useContext, useState, useEffect } from "react";
import styles from "./leftSideBar.module.css";
import { AuthContext } from "contextStates/AuthContext";
import { useLocation, useHistory } from "react-router-dom";
import { ScreenerIcon } from "assets/svg/left_sidebar/CustomSvgIcons";
import AppLogo from "assets/svg/Landing/logo.svg";
import {
  CursorDiv,
  Divider,
  FlexGrow,
  PaddingComponent,
} from "components/UI/Components/Components";
import { useQueryClient } from "@tanstack/react-query";
import { prefetchExplorerQueries } from "api/explorer.api";
import { prefetchMyProfile } from "api/community.api";
import {
  ChatText,
  House,
  Graph,
  Users,
  UserCircle,
  TrendUp,
  Bookmark,
  Gift,
} from "@phosphor-icons/react";
import NotificationIcon from "components/V2/Notifications/NotificationIcon";
import LeftSideBarTile from "./LeftSideBarTile";
import UserJourney from "./UserJourney";
import { GlobalContext } from "contextStates/Global";
import useQueryParams from "customHooks/useQueryParams";
import { useQuery } from "@tanstack/react-query";
import { getInviteDetails } from "api/profile.api";
import { getGraphKey, isProfilePage } from "utils/misc";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import useScreenRes from "customHooks/useMediaQuery";

let timer1;
let timer2;
const PREFETCH_DELAY = 5 * 1000;

const LeftSideBar = () => {
  let location = useLocation();
  let history = useHistory();
  const params = useQueryParams();
  const { identityDetails, isUserLoggedIn } = useContext(AuthContext);
  const { featureFlags } = useContext(GlobalContext);
  const queryClient = useQueryClient();
  const [userGraphQueryKey, setUserGraphQueryKey] = useState("");
  const { isLeftSideBarOpen } = useContext(LeftSideHeaderContext);
  const { width } = useScreenRes();
  useEffect(() => {
    if (!isUserLoggedIn) {
      return;
    }
    timer1 = setTimeout(() => {
      prefetchMyProfile({
        queryClient,
        identifier: identityDetails.current?.identity?.username,
      });
    }, PREFETCH_DELAY);
    return () => {
      clearTimeout(timer1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  useEffect(() => {
    const isProfile = isProfilePage(location.pathname);
    if (
      identityDetails.current?.identity?.id === null ||
      !isUserLoggedIn ||
      location.pathname.split("/")[1] === "explorer" ||
      isProfile
    ) {
      return;
    }
    timer2 = setTimeout(() => {
      const identifier = identityDetails.current?.identity?.id;

      const queryKey = getGraphKey({
        identifiers:
          identityDetails?.current?.identity?.id ??
          identityDetails?.current?.identity?.username ??
          identifier,
        isIdentityNodes: true,
        isContractsGraph: false,
      });
      setUserGraphQueryKey(queryKey);
      prefetchExplorerQueries({
        fullQueryKey: queryKey,
        identifier,
        queryClient,
        username: identityDetails.current?.identity?.username,
      });
    }, PREFETCH_DELAY);
    return () => {
      clearTimeout(timer2);
    };
  }, [identityDetails, queryClient, isUserLoggedIn, location.pathname]);

  const currentStep = location.pathname.split("/")[1];
  return (
    <div
      className={`${styles.left_side_bar} ${!isLeftSideBarOpen || width < 1400 ? styles.minimize : ""}`}>
      <div className={styles.links}>
        <div className={styles.mobileHidden}>
          <CursorDiv
            onClick={() => {
              //scroll to top and reset feed
              if (location.pathname === "/home") {
                window.scrollTo(0, 0);
                const queryKey = [
                  "my_feed",
                  "new_feed",
                  null,
                  params?.get("feed") || "",
                ];
                queryClient.resetQueries({
                  queryKey,
                  exact: true,
                });
                return;
              }
              history.replace("/home");
            }}>
            <img src={AppLogo} className={styles.appIcon} alt="0xppl" />
          </CursorDiv>
        </div>
        <DividerWrapper height="16px" />
        <LeftSideBarTile
          key="home"
          link="/home"
          Icon={House}
          isSelected={currentStep === "home"}
          title="Home"
        />
        <DividerWrapper />
        {isUserLoggedIn && (
          <>
            <UserExploreCategory
              currentStep={currentStep}
              userGraphQueryKey={userGraphQueryKey}
              identityDetails={identityDetails}
            />
            <DividerWrapper />
          </>
        )}
        <FeaturesCategory
          currentStep={currentStep}
          featureFlags={featureFlags}
        />
        <DividerWrapper />
        <UserInteractions
          currentStep={currentStep}
          featureFlags={featureFlags}
        />
        {featureFlags.is_refer_friend_enabled && isUserLoggedIn && (
          <ReferFriendsItem currentStep={currentStep} location={location} />
        )}
      </div>
      <FlexGrow />
      <UserJourney className={styles.smallScreenHidden} />
    </div>
  );
};

const ReferFriendsItem = ({ currentStep, location }) => {
  const hasClickedReferFriends = localStorage.getItem("hasClickedReferFriends")
    ? true
    : false;
  const { identityDetails } = useContext(AuthContext);
  const identityId = identityDetails?.current?.identity?.id;
  const { data } = useQuery({
    queryFn: () => getInviteDetails({ identifier: identityId }),
    queryKey: ["invite-details", identityId],
    enabled: !!identityId,
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const [secondaryCount, setSecondaryCount] = useState(
    data?.unclaimed_invite_codes_count ?? 0
  );
  useEffect(() => {
    if (data) {
      setSecondaryCount(data?.unclaimed_invite_codes_count ?? 0);
    }
  }, [data]);
  const [showSecondaryCount, setShowSecondaryCount] = useState(
    !hasClickedReferFriends
  );
  return (
    <>
      <DividerWrapper />
      <LeftSideBarTile
        key="refer-friends"
        link="/refer-friends"
        Icon={Gift}
        onClick={() => {
          localStorage.setItem("hasClickedReferFriends", "true");
          setShowSecondaryCount(false);
        }}
        isSelected={
          currentStep === "refer-friends" && !location.pathname.split("/")[2]
        }
        title="Refer Friends"
        secondaryCount={showSecondaryCount ? secondaryCount : null}
      />
    </>
  );
};

const UserExploreCategory = ({
  currentStep,
  identityDetails,
  userGraphQueryKey,
}) => {
  let location = useLocation();
  let paths = location.pathname.split("/");
  const isPortfolio =
    parseInt(paths[1]) === identityDetails?.current?.identity?.id &&
    paths[2] === "portfolio";
  const { isUserLoggedIn } = useContext(AuthContext);

  return (
    <>
      {isUserLoggedIn && (
        <LeftSideBarTile
          key="portfolio"
          link={`/${identityDetails?.current?.identity?.username}/portfolio`}
          Icon={UserCircle}
          isSelected={isPortfolio}
          title="My Profile"
          className={styles.mobileHidden}
        />
      )}
      {isUserLoggedIn && (
        <LeftSideBarTile
          key="explorer"
          link={{
            pathname: `/explorer`,
            search: `?identifiers=${
              identityDetails?.current?.identity?.id || "21"
            }`,
            state: {
              queryKey: userGraphQueryKey,
              initial: true,
            },
          }}
          Icon={Graph}
          isSelected={currentStep === "explorer"}
          title="Explorer"
          className={styles.mobileHidden}
        />
      )}
    </>
  );
};

const FeaturesCategory = ({ currentStep, featureFlags }) => {
  const { isSuperuser } = useContext(AuthContext);
  return (
    <>
      {featureFlags.show_trending_tokens && (
        <LeftSideBarTile
          key="trending_timeline"
          link="/trending_timeline"
          Icon={TrendUp}
          isSelected={currentStep === "trending_timeline"}
          title="Trending Tokens"
        />
      )}
      <LeftSideBarTile
        key="community"
        link="/community"
        Icon={Users}
        isSelected={currentStep.startsWith("community")}
        title="People"
      />
      {isSuperuser && (
        <LeftSideBarTile
          key="screener"
          link="/screener"
          Icon={ScreenerIcon}
          isSelected={currentStep === "screener"}
          title="Screener"
          className={styles.mobileHidden}
        />
      )}
    </>
  );
};

const UserInteractions = ({ currentStep, featureFlags }) => {
  const { isUserLoggedIn } = useContext(AuthContext);
  return (
    <>
      {featureFlags.is_messaging_enabled && isUserLoggedIn && (
        <LeftSideBarTile
          key="messages"
          link="/messages/all"
          Icon={ChatText}
          isSelected={currentStep === "messages"}
          title="Messages"
          showUnreadMessagesCount={true}
        />
      )}
      <LeftSideBarTile
        key="notifications"
        link="/notifications"
        isSelected={currentStep === "notifications"}
        title="Notifications"
        showNotificationCount={true}
        onNotificationPage={currentStep === "notifications"}>
        <NotificationIcon />
      </LeftSideBarTile>
      <LeftSideBarTile
        key="bookmarks"
        link="/bookmarks"
        Icon={Bookmark}
        isSelected={currentStep === "bookmarks"}
        title="Bookmarks"
      />
    </>
  );
};

const DividerWrapper = ({ height = "12px" } = {}) => {
  return (
    <div className={styles.mobileHidden}>
      <PaddingComponent height={height} />
      <Divider />
      <PaddingComponent height={height} />
    </div>
  );
};

export default LeftSideBar;
