import classes from "./CustomCheckBox.module.css";

export const CustomCheckBox = ({
  isActive,
  isToggleable = true,
  setIsActive,
  children,
  className,
}) => {
  return (
    <label className={`${classes.checkboxWrapper} ${className}`}>
      <input
        className={classes.checkbox}
        type="checkbox"
        disabled={!isToggleable}
        checked={isActive}
        onChange={() => {
          if (!isToggleable) return;
          setIsActive(!isActive);
        }}
      />
      {children}
      <span
        className={`${classes.checkmark} ${
          isActive ? classes.active : ""
        }`}></span>
    </label>
  );
};
