import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
} from "components/UI/Components/Components";
import "./Carousel.css";
import { SecondaryButton } from "components/UI/Components/SecondaryButton";
import { Users } from "@phosphor-icons/react";

import React, { useState, useEffect, useCallback } from "react";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

const Carousel = ({
  slides = [],
  itemRenderer = null,
  buttonColor = "var(--primary-color)",
  autoPlayDuration = 10000,
  paginationMargin = "16px 0 0 0",
  disableAutoPlay = false,
  showArrows = false,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handlePrev = useCallback(() => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);
  const handleNext = useCallback(() => {
    setActiveIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowRight") {
      handleNext();
    } else if (event.key === "ArrowLeft") {
      handlePrev();
    }
  };
  let timer;
  const startTimer = useCallback(() => {
    stopTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setInterval(() => {
      if (!disableAutoPlay) {
        handleNext();
      }
    }, autoPlayDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, slides]);
  const stopTimer = useCallback(() => {
    clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, slides]);

  useEffect(() => {
    if (slides.length > 1) startTimer();
    return stopTimer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  const showLeft = showArrows && slides.length > 1 && activeIndex !== 0;
  const showRight =
    showArrows && slides.length > 1 && activeIndex !== slides.length - 1;
  return (
    <div
      className="carousel-wrapper slide"
      onKeyDown={handleKeyDown}
      onMouseEnter={stopTimer}
      onMouseLeave={startTimer}
      tabIndex="0"
      aria-live="polite"
      background="red"
      height="200px"
      aria-roledescription="carousel">
      <div className="carousel-items">
        {slides.map((slide, index) => {
          const Icon = slide.icon || Users;
          return (
            <div
              key={index}
              className={`carousel-item ${
                index === activeIndex ? "active" : ""
              }`}
              style={{
                transform: `translateX(-${activeIndex * 100}%)`,
                transition: `transform 0.2s ease`,
              }}>
              {!itemRenderer ? (
                <CustomColumn gap="12px" width="100%">
                  <CustomRow gap="8px" alignItems="center">
                    <Icon
                      size={18}
                      color="var(--primary-color)"
                      weight="duotone"
                    />
                    <CustomText
                      text={slide.title}
                      fontSize="14px"
                      fontWeight="600"
                      color="var(--text-1)"
                    />
                  </CustomRow>
                  <Divider />
                  <CustomColumn gap="8px" width="100%" height="300px">
                    <CustomColumn flexShrink={0} padding="8px 0px 0px">
                      <CustomText
                        text={slide.message}
                        color="var(--text-1)"
                        fontSize="13px"
                        letterSpacing="-0.26px"
                      />
                    </CustomColumn>
                    <CustomColumn
                      alignItems="center"
                      justifyContent="center"
                      flexGrow={1}>
                      <img
                        src={slide.image}
                        alt={slide.title}
                        className="image"
                      />
                      {slide.overlayComponent}
                    </CustomColumn>
                    <CustomColumn flexShrink={0}>
                      <SecondaryButton text={slide.cta} onClick={slide.onTap} />
                    </CustomColumn>
                  </CustomColumn>
                </CustomColumn>
              ) : (
                itemRenderer(slide, index, activeIndex)
              )}
            </div>
          );
        })}
        {showLeft && (
          <div className="carousel-arrows carousel-arrows-left">
            <CaretLeft
              size={20}
              color="var(--text-3)"
              weight="fill"
              className={`carousel-arrow `}
              onClick={handlePrev}
            />
          </div>
        )}
        {showRight && (
          <div className="carousel-arrows carousel-arrows-right">
            <CaretRight
              size={20}
              color="var(--text-3)"
              weight="fill"
              className={`carousel-arrow right-arrow`}
              onClick={handleNext}
            />
          </div>
        )}
      </div>
      {slides?.length > 1 && (
        <div
          className="carousel-pagination"
          style={{ margin: paginationMargin }}>
          {slides.map((_, index) => (
            <button
              key={index}
              className={`pagination-indicator ${
                index === activeIndex ? "active" : ""
              }`}
              onClick={() => setActiveIndex(index)}
              style={{
                backgroundColor:
                  index === activeIndex ? buttonColor : "var(--text-3)",
              }}
              aria-label={`Go to slide ${index + 1}`}></button>
          ))}
        </div>
      )}
    </div>
  );
};
export default Carousel;
