export const Grid = ({
  children,
  gap,
  padding,
  direction = "horizontal",
  style = {},
  partitions = 0,
  className,
}) => {
  let divisions = "1fr";
  divisions = Array.from({ length: partitions || children.length || 1 }).map(
    () => "1fr"
  );
  return (
    <div
      className={className}
      style={{
        padding,
        display: "grid",
        [direction === "horizontal"
          ? "gridTemplateColumns"
          : "gridTemplateRows"]: divisions.join(" "),
        gap,
        ...style,
      }}>
      {children}
    </div>
  );
};
