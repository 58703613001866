import React, { useContext, useState } from "react";
import classes from "./TokenHoldingsRightPanel.module.css";

import { Divider, PaddingComponent } from "components/UI/Components/Components";
import { RightPaneTitle } from "./RightPanelComponents";
import SelectedNodeTransfers from "components/V2/Explorer/SelectedNodeTransfers";
import { ProfileContext } from "contextStates/ProfileContext";
import PnlChart from "components/FeedItemV2/PnlTokenList/PnlChart";
import {
  CustomColumn,
  InfoBox,
  CustomText,
} from "components/UI/Components/Components";
import useFetchTokenMetaData from "customHooks/useFetchTokenMetaData";
import GraphLoader from "../ProfileGraphs/GraphLoader";
import TopUsers from "components/V2/Token/TopUsers/TopUsers";
import ZxUserTokenGroup from "zxComponents/feed/ZxUserTokenGroup/ZxUserTokenGroup";

const TokenHoldingsRightPanel = ({
  currentTokenData,
  identifier,
  actorData,
  onClose = () => {},
}) => {
  const [initialTokenTransfer, setInitialTokenTransfer] = useState(null);
  const [tokenTransfersCount, setTokenTransfersCount] = useState(0);

  const { profileData } = useContext(ProfileContext);
  const actorInfo = actorData ?? profileData?.current ?? {};
  const { contract_address: tokenAddress, chain_id: chainId } =
    currentTokenData || {};
  const isSolana = chainId?.toLowerCase() === "solana";
  const isBitcoin = chainId?.toLowerCase() === "bitcoin";
  const { data: tokenMetadata, isLoading: isMetadataLoading } =
    useFetchTokenMetaData(tokenAddress, chainId);
  return (
    <div className={classes.header} style={{ width: "800px" }}>
      <RightPaneTitle
        actorInfo={actorInfo}
        currentTokenData={currentTokenData}
        initialTokenTransfer={initialTokenTransfer}
        onClose={onClose}
      />

      <ZxUserTokenGroup
        selectedToken={{
          address: tokenAddress,
          chain_id: chainId,
          ...currentTokenData,
          realised_pnl: currentTokenData?.realised_pnl ?? null,
          unrealised_pnl: currentTokenData?.unrealised_pnl ?? null,
        }}
        showAttribution={false}
      />

      <PaddingComponent height="16px" />

      {!isMetadataLoading ? (
        <CustomColumn>
          <Divider margin="0 0 12px 0" />
          <PnlChart
            identifier={
              actorInfo?.details?.identity_id ??
              identifier ??
              actorInfo?.details?.id
            }
            identityId={
              actorInfo?.details?.identity_id ?? actorInfo?.details?.id
            }
            selectedToken={{
              address: tokenAddress,
              chain_id: chainId,
              ...tokenMetadata,
              ...currentTokenData,
            }}
          />
        </CustomColumn>
      ) : (
        <GraphLoader height={350} />
      )}

      <PaddingComponent height="16px" />

      {!isBitcoin && (
        <CustomColumn gap="8px">
          <InfoBox padding="16px" alignItems="flex-start">
            <CustomText
              text={`Token Transfers ${tokenTransfersCount > 0 ? `(${tokenTransfersCount})` : ``}`}
              color="var(--text-1)"
              fontWeight="600"
              fontSize="15px"
            />
            <SelectedNodeTransfers
              identifier={identifier}
              selectedActiveTokens={[tokenAddress]}
              selectedActiveChains={[chainId]}
              setInitialTokenTransfer={setInitialTokenTransfer}
              setTokenTransfersCount={setTokenTransfersCount}
              isRightPane
              maxHeight="250px"
            />
          </InfoBox>
          {!isSolana && !isBitcoin && !tokenMetadata?.is_native_token && (
            <TopUsers
              address={tokenAddress}
              chainId={chainId}
              borderPosition="bottom"
              giveTopPadding
              noPadding={false}
            />
          )}
        </CustomColumn>
      )}
      <PaddingComponent height="12px" />
    </div>
  );
};

export default TokenHoldingsRightPanel;
