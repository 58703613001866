import classes from "./CollectionNfts.module.css";
import { useState } from "react";
import { ExtraNFTs } from "./ExtraNfts";
import { NFTItem } from "./NftItem";
import useFilterNfts from "./useFilterNfts";
import viewLess from "assets/svg/profile/view_less_icon.svg";
import { conditionalClass } from "utils/misc";
import React from "react";
import CollectionHeader from "./CollectionHeader";
import { useGalleryModal } from "components/GalleryModalV2/useGalleryModal";
import GalleryModalV2 from "components/GalleryModalV2/GalleryModalV2";

const CollectionNfts = ({
  nftItems,
  chainId,
  rightPane = false,
  hasGalleryModal = true,
  collectionDetails,
  totalCollectionTokens,
  filterNfts = true,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { filteredNfts, remainingItems, remainingItemsCount } = useFilterNfts(
    nftItems,
    isExpanded,
    filterNfts
  );

  const {
    handleGalleryModal,
    isGalleryModalOpen,
    setIsGalleryModalOpen,
    currentNftIndex,
  } = useGalleryModal();

  return (
    <div className={classes.categoryItemsWrapper}>
      <div
        className={`${classes.categoryItems} ${conditionalClass(
          rightPane,
          classes.rightPane
        )}`}>
        <FilteredNftItems
          filteredNfts={filteredNfts}
          rightPane={rightPane}
          chainId={chainId}
          collectionDetails={collectionDetails}
          hasGalleryModal={hasGalleryModal}
          handleGalleryModal={handleGalleryModal}
        />
        {remainingItemsCount > 0 && (
          <ExtraNFTs
            remainingItems={remainingItems}
            remainingItemsCount={remainingItemsCount}
            setIsExpanded={setIsExpanded}
            rightPane={rightPane}
          />
        )}
        {hasGalleryModal && isGalleryModalOpen && (
          <GalleryModalV2
            images={nftItems}
            imageIndex={currentNftIndex}
            sidebarContent={
              <CollectionHeader
                collection={collectionDetails}
                totalNfts={totalCollectionTokens}
                rightPane={rightPane}
              />
            }
            onClose={() => setIsGalleryModalOpen(false)}
          />
        )}
      </div>
      {isExpanded ? (
        <div className={classes.footerWrapper}>
          <div className={classes.footer}>
            <div className={classes.label}>Showing all NFT’s</div>
            <button
              className={classes.buttonLabel}
              onClick={() => setIsExpanded(false)}>
              View Less
              <span className={classes.arrow}>
                <img src={viewLess} alt="view less icon" />
              </span>
            </button>
          </div>
          <div className={classes.divider}></div>
        </div>
      ) : null}
    </div>
  );
};

export const FilteredNftItems = React.memo(
  ({
    filteredNfts,
    chainId,
    collectionDetails,
    rightPane,
    handleGalleryModal,
    hasGalleryModal,
  }) => {
    return (
      <>
        {filteredNfts.map((nftItem, index) => (
          <NFTItem
            key={`${nftItem.name}-${index}-item`}
            nftItem={nftItem}
            chainId={chainId}
            collectionDetails={collectionDetails}
            totalCollectionLength={filteredNfts.length}
            rightPane={rightPane}
            onClick={() => handleGalleryModal(index)}
            hasGalleryModal={hasGalleryModal}
            style={{ width: "172px" }}
          />
        ))}
      </>
    );
  }
);

export default CollectionNfts;
