export const getFilteredTags = ({ queryTokens }) => {
  const filteredTags = [];
  var tag = "";
  queryTokens.forEach((queryToken) => {
    if (
      queryToken?.query?.title?.toLowerCase() === "and" ||
      queryToken?.query?.title?.toLowerCase() === "or"
    ) {
      filteredTags.push(tag.trim());
      tag = "";
    } else {
      if (queryToken?.query?.type === "static") {
        tag += `${queryToken?.query?.title} `;
      } else {
        let tempTag = queryToken?.query?.title;
        const str = queryToken?.query?.title;
        const regex = /{{(.*?)}}/g;
        const matches = str.match(regex);
        const values = matches.map((match) => match.replace(/[{}]/g, ""));
        values.forEach((value) => {
          const modifiedValue = getDynamicTitleData({
            dynamicTitle: value,
            data: queryToken?.data?.[value],
          });
          tempTag = tempTag.replace(`{{${value}}}`, modifiedValue);
        });
        tag += `${tempTag} `;
      }
    }
  });
  if (tag) {
    filteredTags.push(tag.trim());
  }
  return filteredTags;
};

export const getDynamicTitleData = ({ dynamicTitle, data }) => {
  if (data == null) {
    return dynamicTitle;
  }
  if (dynamicTitle === "token") {
    return data?.data?.token_details?.name;
  }
  if (dynamicTitle === "date") {
    return data.split("T")[0];
  }
  return data?.data?.title || data?.data?.display_name;
};
