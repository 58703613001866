import StaticSearchBar from "components/UI/Components/StaticSearchBar";
import React from "react";
import ZxModal from "zxComponents/common/ZxModal/ZxModal";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { useQuery } from "@tanstack/react-query";
import { getSearchResults } from "api/search.api";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import styles from "./ZxTokenFilterSearch.module.css";
import {
  CustomRow,
  CustomSkeleton,
  CustomText,
  Divider,
} from "components/UI/Components/Components";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import { getTrimmedString } from "utils/misc";
import { X } from "@phosphor-icons/react";

const ZxTokenFilterSearch = ({ isOpen, onClose, onApply, filter = null }) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const updateSearchTerm = (term) => setSearchTerm(term);
  const { data, isFetching, isLoading } = useQuery({
    queryKey: ["tokenPageSearch", searchTerm],
    queryFn: ({ signal }) =>
      getSearchResults({
        queryKey: [searchTerm, `${searchTerm} source:Profile`],
        signal,
      }),
    staleTime: 0,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: searchTerm.length > 0,
  });
  const renderPrefix = () => {
    if (!filter) return null;
    const { display_name: name, display_picture: dp } = filter;
    return (
      <ZxFlex
        gap="4px"
        onClick={() => {
          onApply(null);
          setSearchTerm("");
        }}
        cursor="pointer"
        className={styles.searchpill}>
        <Image
          src={dp ?? null}
          imageType={IMAGE_TYPES.AVATAR}
          name={name}
          className={styles.actorImage}
          alt={name}
        />
        <CustomText
          text={getTrimmedString(name, 10)}
          className={styles.actorName}
          color="var(--text-2)"
          fontSize="12px"
        />
        <X size={16} />
      </ZxFlex>
    );
  };
  const renderResults = () => {
    if (data?.length === 0) {
      return (
        <div className={styles.noResults}>
          <ZxText>No results</ZxText>
        </div>
      );
    }
    return (
      data?.length > 0 &&
      searchTerm.length > 0 &&
      !isFetching &&
      !isLoading && (
        <div className={styles.searchResults}>
          {data.map((d, idx) => {
            const profile = d?.data;
            const isLast = idx === data.length - 1;
            return (
              <ZxFlex dir="column" align="flex-start" key={profile?.address}>
                <CustomRow
                  gap="6px"
                  padding="8px 12px"
                  cursor="pointer"
                  style={{ cursor: "pointer" }}
                  width="100%"
                  alignItems="center"
                  onClick={() => {
                    onApply(profile);
                    setSearchTerm("");
                    onClose();
                  }}>
                  <Image
                    className="actorAvatar"
                    src={profile?.display_picture}
                    alt={profile?.display_name}
                    name={profile?.display_name}
                    imageType={IMAGE_TYPES.AVATAR}
                  />
                  <CustomText fontSize="13px" text={profile?.display_name} />
                  <VerifiedBadge profile={profile} />
                </CustomRow>
                {!isLast && <Divider margin="0px" />}
              </ZxFlex>
            );
          })}
        </div>
      )
    );
  };
  const renderLoading = () => {
    return [...Array(5)].map((_, idx) => (
      <CustomSkeleton
        key={idx}
        height="40px"
        borderRadius="4px"
        width="100%"
        variant="rectangular"
      />
    ));
  };
  return (
    <ZxModal
      isOpen={isOpen}
      onClose={onClose}
      title="Filter by profile or an address">
      <ZxFlex
        dir="column"
        gap="8px"
        className={styles.searchContainer}
        width="100%"
        align="flex-start">
        <StaticSearchBar
          title="Search for profiles or addresses"
          margin="0"
          borderRadius="4px"
          setSearchTerm={updateSearchTerm}
          searchTerm={searchTerm}
          resultsPosition="relative"
          background="var(--base)"
          border="1px solid var(--border-dark)"
          disabled={!!filter}
          prefix={renderPrefix()}
          resetOnDisable
          delay={10}
          isLoading={false}
          hasResults={data?.length > 0}
          renderResults={false}
        />
        {isLoading || isFetching ? renderLoading() : renderResults()}
      </ZxFlex>
    </ZxModal>
  );
};

export default ZxTokenFilterSearch;
