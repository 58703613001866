import React, { useContext, useRef } from "react";
import ZxHorizontalScrollWrapper from "zxComponents/common/ZxHorizontalScrollWrapper/index";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "utils/query_utils";
import { getTrendingCards } from "api/trending.api";
import ZxCard from "zxComponents/common/ZxCard/ZxCard";
import styles from "./TrendingCards.module.scss";
import TrendingCard from "./TrendingCard";
import { getUniqueId } from "utils/event_tracking";
import StatusRenderer from "components/Feed/NewPost/Components/StatusRenderer";
import { CustomColumn, Divider } from "components/UI/Components/Components";
import {
  HorizontalScrollButtons,
  horizontalScrollContainerClassName,
} from "components/Feed/HorizontalScrollButtons";
import { FEED_CARDS } from "../ZxFeed.utils";
import withNullableError from "zxComponents/common/withNullableError/index";
import { ThemeContext } from "contextStates/Theme.context";

const CARD_WIDTH = 310;
const CARD_HEIGHT = 390;

const TrendingCards = ({ isDataPassed = false, queryData }) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: QUERY_KEYS.FEED_GLOBAL_TRENDING(),
    queryFn: getTrendingCards,
    staleTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !isDataPassed,
  });
  const elRef = useRef();
  const { theme } = useContext(ThemeContext);
  if (isDataPassed && !queryData) {
    return <StatusRenderer isLoading={true} />;
  }
  const cards = (isDataPassed ? queryData : data) ?? [];

  if (cards && cards.length === 0) return null;
  const Wrapper = ({ children }) => {
    if (!isDataPassed) {
      return (
        <ZxHorizontalScrollWrapper
          isLoading={isLoading}
          itemCount={cards?.items?.length ?? 0}
          meta={FEED_CARDS.TRENDING}
          title="Trending today">
          {children}
        </ZxHorizontalScrollWrapper>
      );
    }
    return children;
  };

  return (
    <Wrapper>
      <div
        className={`${isDataPassed ? styles.verticalContainer : styles.cardsContainer}  ${horizontalScrollContainerClassName}`}
        ref={elRef}>
        {cards &&
          cards.map((card) => {
            return (
              <CustomColumn
                key={getUniqueId()}
                overflowX="visible"
                overflowY="visible">
                <ZxCard
                  minWidth={`${CARD_WIDTH}px`}
                  minHeight={`${CARD_HEIGHT}px`}
                  background={
                    theme === "dark"
                      ? FEED_CARDS.TRENDING.darkModebackground
                      : "var(--base)"
                  }
                  borderRadius={isDataPassed ? "0" : "12px"}>
                  <TrendingCard
                    card={card}
                    key={getUniqueId()}
                    fullWidth={isDataPassed}
                    refetch={refetch}
                  />
                </ZxCard>
                {isDataPassed && <Divider margin="12px 0" />}
              </CustomColumn>
            );
          })}
      </div>
      <HorizontalScrollButtons wrapperEl={elRef} postWidth={CARD_WIDTH} />
    </Wrapper>
  );
};

const Wrapper = ({ isDataPassed, data }) => {
  if (isDataPassed && data) {
    return <TrendingCards isDataPassed={isDataPassed} queryData={data} />;
  }
  if (!isDataPassed) {
    return <TrendingCards />;
  }
  return <StatusRenderer isLoading={true} />;
};
export default withNullableError(Wrapper);
