import React from "react";
import styles from "./searchResultItem.module.css";
import SearchResultItem from "./SearchResultItem";
import {
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";

const RecentSearches = ({
  setIsSearchInputFocused,
  selectedResultIndex,
  recentSearchResults,
}) => {
  if (recentSearchResults?.length === 0) {
    return null;
  }
  return (
    <div className={styles.searchResultsContainer}>
      <PaddingComponent
        paddingLeft="16px"
        paddingTop="10px"
        paddingBottom="20px">
        <CustomText
          fontSize="10px"
          fontWeight="600"
          color="var(--text-2)"
          text="RECENT SEARCHES"
        />
      </PaddingComponent>
      {recentSearchResults?.map((result, index) => (
        <SearchResultItem
          selected={selectedResultIndex === index}
          key={`search-result-item-${index}`}
          result={result}
          index={index}
          setIsSearchInputFocused={setIsSearchInputFocused}
          isRecentSearchItem
        />
      ))}
    </div>
  );
};

export default RecentSearches;
