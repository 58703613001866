import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { Funnel } from "../../../../node_modules/@phosphor-icons/react/dist/index";

export const getZxTokenTopHolderColumns = ({
  filter,
  showFilter,
  updateFilter,
}) => {
  return [
    {
      title: "Rank",
      key: "rank",
      width: "10%",
      render: (row, { index }) => {
        return (
          <ZxText style={TSTYLES.title1} color="text-2" fontSize="11px">
            #{index + 1}
          </ZxText>
        );
      },
    },
    {
      title: "% HOLDINGS",
      key: "holdings_percent",
      width: "20%",
      render: (row) => {
        return (
          <ZxText fontSize="14px">
            {row?.percent_of_supply?.display_value}%
          </ZxText>
        );
      },
    },
    {
      title: "AMOUNT",
      key: "balance",
      width: "20%",
      render: (row) => {
        return <ZxText fontSize="14px">{row?.balance?.display_value}</ZxText>;
      },
    },
    {
      title: "USD VALUE",
      key: "usd_value",
      width: "25%",
      render: (row) => {
        return (
          <ZxText fontSize="14px" style={TSTYLES.title1}>
            {row?.usd_value?.display_value}
          </ZxText>
        );
      },
    },
    {
      title: "Wallet / Address",
      key: "wallet",
      width: "40%",
      render: (row) => {
        return (
          <ZxFlex
            width="100%"
            onClick={() => updateFilter(row?.actor_details)}
            cursor="pointer">
            <ZxFlex width="90%">
              <ZxActor
                actor={row?.actor_details}
                showFollowButton={false}
                size="20px"
                showNetworth={false}
                dpFontSize="13px"
                nameLength={28}
              />
            </ZxFlex>
            {showFilter && <Funnel size={16} color="var(--text-3)" />}
          </ZxFlex>
        );
      },
    },
  ];
};
