import { signedApiV4Request } from "api/api";
import React, { useCallback, useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import FullPageLoader from "shared/FullPageLoader/FullPageLoader";
import emptyStateIcon from "assets/webp/empty_state.webp";
import {
  CustomTextSpan,
  PaddingComponent,
} from "components/UI/Components/Components";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const AddressToDetails = () => {
  const history = useHistory();
  const { chain_id, address } = useParams();
  const [isDetailsPageLoading, setIsDetailsPageLoading] = useState(true);

  const fetchDetailsPage = useCallback(() => {
    signedApiV4Request({
      method: "post",
      path: "is_address_contract",
      bodyText: JSON.stringify({
        address: address,
        chain_id: chain_id,
      }),
    })
      .then((res) => {
        if (res?.data?.data?.is_contract) {
          history.replace(`/${chain_id}/contract_details/${address}`);
        } else {
          history.replace(`/${address}`);
        }
      })
      .catch((err) => {
        setIsDetailsPageLoading(false);
      });
  }, [address, chain_id, history]);

  useEffect(() => {
    fetchDetailsPage();
  }, [fetchDetailsPage]);

  return (
    <BaseWrapper mixpanelEvent="AddressToDetails">
      <div>
        {isDetailsPageLoading ? (
          <FullPageLoader />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "100px",
            }}>
            <img
              src={emptyStateIcon}
              alt="activity-not-found"
              loading="lazy"
              style={{ height: "80px", marginBottom: "20px" }}
            />
            <CustomTextSpan fontWeight="500">
              Contract or person not found
            </CustomTextSpan>
            <PaddingComponent height="8px" />
            <CustomTextSpan fontSize="13px" color="var(--text-2)">
              Sorry, we couldn't find any contract or person associated with the
              given address. Please re-check the address and try again.
            </CustomTextSpan>
          </div>
        )}
      </div>
    </BaseWrapper>
  );
};

export default AddressToDetails;
