import classes from "./selectedTag.module.css";
import { ReactComponent as CrossIcon } from "assets/svg/profile/cross.svg";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { PaddingComponent } from "components/UI/Components/Components";

const SelectedTag = ({
  text,
  iconComponent,
  icon,
  onRemove,
  isCross = true,
}) => {
  return (
    <div onClick={onRemove} className={classes.box}>
      {iconComponent && iconComponent}
      {!iconComponent && icon && (
        <Image
          className={classes.chainIcon}
          src={icon}
          alt="Chain Icon"
          imageType={IMAGE_TYPES.CHAIN}
        />
      )}
      <span className={classes.text}>{text}</span>
      {isCross ? (
        <CrossIcon className={classes.crossIcon} />
      ) : (
        <PaddingComponent width="10px" />
      )}
    </div>
  );
};

export default SelectedTag;
