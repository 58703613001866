import { Link } from "react-router-dom";
import NetWorthCardToken from "../../V2/Profile/ProfileHeader/NetWorthCardToken";
import classes from "./TVLCard.module.css";

const TVLCard = ({ title, value, tokens = null }) => {
  return (
    <Link to="tvlinfo" className={`${classes.net_worth_card}`}>
      <div className={classes.top}>
        <div className={classes.title}>{title}</div>
        <div className={classes.value}>
          <span className={classes.value_number}>{value}</span>
          <div className={classes.value_change}></div>
        </div>
      </div>
      <div className={classes.middle}></div>
      <div className={classes.bottom}>
        {tokens &&
          tokens.length > 0 &&
          tokens.slice(0, 3).map((token, index) => {
            return (
              <div key={`networth-key-${index}`}>
                <NetWorthCardToken token={token} />
              </div>
            );
          })}
      </div>
    </Link>
  );
};

export default TVLCard;
