import React, { useContext } from "react";
import styles from "./LocalTrendingFeedItem.module.scss";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import { IMAGE_TYPES } from "components/UI/Image";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import ZxBadge from "zxComponents/common/ZxBadge";
import ConditionalLink from "shared/ConditionalLink";
import TokenHoverCard from "components/FeedItemV2/TokenHover/TokenHoverCard";
import {
  PaddingComponent,
  PriceChange,
} from "components/UI/Components/Components";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import { TimeLabel } from "components/FeedItemV2/ActivityHeader";
import ZxImageStack from "zxComponents/common/ZxImageStack/ZxImageStack";
import withNullableError from "zxComponents/common/withNullableError/index";
import { useQuery } from "@tanstack/react-query";
import { QueryKeyConstants } from "utils/constants";
import { ChampionIcon } from "hugeicons-react";
import { CaretRight } from "../../../../node_modules/@phosphor-icons/react/dist/index";
import { ThemeContext } from "contextStates/Theme.context";
import LocalTrendingFeedChart from "./LocalTrendingFeedChart";
import { useInView } from "react-intersection-observer";
import LocalTrendingTransfers from "./LocalTrendingTransfers";
const LocalTrendingFeedItem = ({ groupedActivity, isFirst = false }) => {
  const { actor, local_trending_items } = groupedActivity;
  const [ref, inView] = useInView();
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";
  const { data: slowItem } = useQuery({
    queryKey: [QueryKeyConstants.SLOW_FEED_ITEM, groupedActivity?.blob_id],
    queryFn: () => {},
    enabled: false,
  });
  const itemList = slowItem?.local_trending_items ?? local_trending_items;
  const tokenProfile = groupedActivity?.profiles?.[actor?.address] ?? null;
  const {
    display_picture: dp,
    display_name: dn,
    is_token: isToken,
    chain_id: chain,
    address,
    link,
  } = tokenProfile ?? {};

  let profiles = {};
  if (!tokenProfile || itemList?.length === 0) return null;
  const actors = groupedActivity?.users;
  const hasMoreThan4 = actors?.length > 4;
  const pictures =
    actors?.slice(0, 4)?.map((a) => {
      const profile = groupedActivity?.identities?.[a];
      return {
        src: profile?.display_picture,
        name: profile?.display_picture,
        ...profile,
      };
    }) ?? [];
  return (
    <div ref={ref} className={styles.container}>
      <div className={`${styles.background} ${isDark ? styles.dark : ""}`} />
      <PaddingComponent padding="8px 0" />
      <ZxFlex
        gap="8px"
        dir="column"
        align="flex-start"
        className={styles.content}>
        <ZxFlex justify="space-between" align="flex-start" width="100%">
          <ZxFlex gap="8px">
            <ConditionalLink to={link}>
              <IconWithChain
                src={dp}
                chain={chain}
                iconWidth="40px"
                iconHeight="40px"
                chainHeight="16px"
                chainWidth="16px"
                showImageFullHeightWidth
                iconClassName={styles.actorIcon}
                imageType={isToken ? IMAGE_TYPES.TOKEN : IMAGE_TYPES.NFT}
              />
            </ConditionalLink>
            <TokenHoverCard tokenProfile={tokenProfile}>
              <ConditionalLink to={link}>
                <ZxText style={TSTYLES.title1} fontSize="15px" underlineOnHover>
                  {dn}
                </ZxText>
              </ConditionalLink>
            </TokenHoverCard>
            <ZxBadge variant="warning">
              <ZxFlex>
                TRENDING
                <ChampionIcon size={16} color="var(--warning)" />
              </ZxFlex>
            </ZxBadge>
          </ZxFlex>

          <LocalTrendingTransfers
            inView={inView}
            isDark={isDark}
            profiles={profiles}
            chain={chain}
            address={address}
          />
        </ZxFlex>

        <div className={styles.row}>
          {itemList?.length > 0 && (
            <PaddingComponent width="100%" borderRadius="16px">
              <ZxFlex dir="column" align="flex-start">
                <div className={styles.grid}>
                  {itemList.slice(0, 2).map((item, idx) => {
                    const { actor } = item;
                    const profile = groupedActivity?.identities?.[actor?.id];
                    const type = item?.blob_id?.split(":")[1];
                    const id = item?.blob_id?.split(":")[2];
                    const itemLink = `/${type}/${id}`;
                    const summaryUsd =
                      item?.transaction_summaries?.[0]?.usd_value;
                    const verb =
                      item?.transaction_summaries?.[0]?.summary?.toLowerCase();
                    const timestamp =
                      item?.transaction_summaries?.[0]?.timestamp;
                    const buy = [
                      "bought",
                      "minted",
                      "claimed",
                      "added liquidity",
                    ];
                    const isBuy = buy.includes(verb?.toLowerCase());

                    if (!profile) return null;
                    const pnl = item?.user_token_pnl?.[0];
                    const openPnl = pnl?.open_pnl?.value ?? 0;
                    const openPnlDv = pnl?.open_pnl?.display_value ?? "$0";
                    const openPnlPercent = pnl?.open_pnl_percent?.value ?? 0;
                    const closePnl = pnl?.closed_pnl?.value ?? 0;

                    const showUsd = !openPnl && !closePnl;

                    return (
                      <ZxFlex
                        width="100%"
                        key={idx}
                        className={styles.item}
                        dir="column">
                        <ConditionalLink
                          to={itemLink}
                          style={{ width: "100%" }}>
                          <ZxFlex
                            dir="column"
                            key={idx}
                            flex="1"
                            gap="8px"
                            width="100%"
                            align="flex-start">
                            <ZxFlex
                              justify="space-between"
                              align="flex-start"
                              width="100%"
                              flex="1">
                              <ZxFlex align="flex-start" gap="8px">
                                <ZxActor
                                  actor={profile}
                                  showFollowButton={false}
                                  nameLength={15}
                                  size="32px"
                                  key={idx}
                                  dpFontSize="13px"
                                  infoRowGap="2px"
                                  columnJustify="center"
                                  secondLineRender={() => {
                                    return (
                                      <TimeLabel
                                        timestamp={timestamp}
                                        showFullDateTime={false}
                                        fontSize="11px"
                                      />
                                    );
                                  }}
                                />
                              </ZxFlex>
                              <ZxBadge variant={isBuy ? "success" : "burn"}>
                                {verb?.toUpperCase()}
                              </ZxBadge>
                            </ZxFlex>
                            <ZxFlex
                              dir="column"
                              align="flex-start"
                              gap="4px"
                              width="100%">
                              {!showUsd ? (
                                <ZxFlex justify="space-between" width="100%">
                                  <ZxFlex align="flex-start" dir="column">
                                    <ZxText color="text-2" fontSize="11px">
                                      Open PNL
                                    </ZxText>
                                    <ZxFlex>
                                      <ZxText
                                        style={TSTYLES.title1}
                                        color={openPnl ? "text-1" : "text-2"}
                                        fontSize="13px">
                                        {openPnl ? openPnlDv : "$0"}
                                      </ZxText>
                                      {!!openPnlPercent && (
                                        <PriceChange
                                          value={openPnlPercent}
                                          displayValue={
                                            pnl?.open_pnl_percent?.display_value
                                          }
                                          fontWeight="500"
                                          showCaret={false}
                                          fontSize="11px"
                                        />
                                      )}
                                    </ZxFlex>
                                  </ZxFlex>
                                  {!!closePnl && (
                                    <ZxFlex align="flex-end" dir="column">
                                      <ZxText color="text-2" fontSize="11px">
                                        Closed PNL
                                      </ZxText>
                                      <ZxFlex>
                                        <ZxText
                                          style={TSTYLES.title1}
                                          color={
                                            closePnl < 0 ? "error" : "success"
                                          }
                                          fontSize="13px">
                                          {pnl?.closed_pnl?.display_value}
                                        </ZxText>
                                      </ZxFlex>
                                    </ZxFlex>
                                  )}
                                </ZxFlex>
                              ) : pnl?.total_usd_value?.value ||
                                summaryUsd?.value ? (
                                <ZxFlex align="flex-start" dir="column">
                                  <ZxText color="text-2" fontSize="11px">
                                    USD Value
                                  </ZxText>
                                  <ZxFlex>
                                    <ZxText
                                      style={TSTYLES.title1}
                                      fontSize="14px">
                                      {pnl?.total_usd_value?.display_value ??
                                        summaryUsd?.display_value}
                                    </ZxText>
                                  </ZxFlex>
                                </ZxFlex>
                              ) : null}
                            </ZxFlex>
                          </ZxFlex>
                        </ConditionalLink>
                      </ZxFlex>
                    );
                  })}
                  {/* {pictures && pictures.length > 0 && (
                  <ConditionalLink
                    to={`${tokenProfile?.link}${isToken ? "/token?table_tab=follower_swaps" : ""}`}
                    style={{ width: "100%" }}>
                    <div className={styles.extraItem}>
                      <ZxFlex width="100%" height="100%">
                        <ZxImageStack
                          pictures={pictures}
                          showHover
                          width="40px"
                          height="40px"
                        />
                        <ZxFlex justify="space-between" flex="1">
                          <ZxText color="text-2">
                            {hasMoreThan4
                              ? `+${actors?.length} more interactions`
                              : `also traded ${tokenProfile?.token_details?.symbol}`}
                          </ZxText>
                          <CaretRight size={16} color="var(--text-2)" />
                        </ZxFlex>
                      </ZxFlex>
                    </div>
                  </ConditionalLink>
                )} */}
                </div>
                {pictures && pictures.length > 0 && (
                  <ConditionalLink
                    to={`${tokenProfile?.link}${isToken ? "/token?table_tab=follower_swaps" : ""}`}
                    style={{ width: "100%" }}>
                    <ZxFlex
                      dir="column"
                      width="100%"
                      align="flex-start"
                      className={styles.extraItem}>
                      {/* <Divider margin="8px 0" /> */}

                      <ZxFlex width="100%" gap="8px" justify="space-between">
                        <ZxImageStack pictures={pictures} showHover />

                        <ZxFlex justify="space-between" flex="1">
                          <ZxText color="text-2">
                            {hasMoreThan4
                              ? `+${actors?.length} also traded ${tokenProfile?.token_details?.symbol}`
                              : `also traded ${tokenProfile?.token_details?.symbol}`}
                          </ZxText>
                          <CaretRight size={16} color="var(--text-2)" />
                        </ZxFlex>
                      </ZxFlex>
                    </ZxFlex>
                  </ConditionalLink>
                )}
              </ZxFlex>
            </PaddingComponent>
          )}
          <LocalTrendingFeedChart
            groupedActivity={groupedActivity}
            chain={tokenProfile?.chain}
            address={tokenProfile?.address}
            hasOtherActors={pictures?.length > 0}
          />
        </div>
      </ZxFlex>
    </div>
  );
};

export default withNullableError(LocalTrendingFeedItem);
