import classes from "components/V2/Profile/ProfileDetails/Filters/Filters.module.css";
import { useState, useEffect } from "react";
import { getTokensAfterSearch } from "utils/profile_utils";
import FiltersSearchBar from "components/V2/Profile/ProfileDetails/Filters/FiltersSearchBar";
import ExplorerRowTile from "./ExplorerRowTile";

const ExplorerRowsFilterList = ({ selected, setSelected, profiles }) => {
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState(profiles);

  useEffect(() => {
    if (searchTerm) {
      const modifiedList = getTokensAfterSearch({
        tokensList: profiles,
        searchTerm,
        keys: ["display_name", "address"],
      });
      setFilteredProfiles(modifiedList);
    } else {
      setFilteredProfiles(profiles);
    }
  }, [searchTerm, profiles]);

  return (
    <div>
      <div className={classes.horizontalPadding}>
        <FiltersSearchBar
          setSearchTerm={setSearchTerm}
          isSearchInputFocused={isSearchInputFocused}
          setIsSearchInputFocused={setIsSearchInputFocused}
          title="Search"
        />
      </div>
      <div className={classes.scrollList}>
        {filteredProfiles &&
          filteredProfiles.map((profile) => {
            return (
              <ExplorerRowTile
                addressesSelected={selected}
                setAddressesSelected={setSelected}
                profile={profile}
                key={`profile-filter-${profile.address}`}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ExplorerRowsFilterList;
