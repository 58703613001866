import {
  CustomRow,
  CustomText,
  SpinnerComponent,
  Divider,
  PaddingComponent,
  FlexGrow,
  CursorDiv,
} from "components/UI/Components/Components";
import { useCallback, useState, useContext } from "react";
import { removeFromWatchlist, addToWatchlist } from "api/watchlist.api";
import classes from "./Watchlist.module.css";
import { GlobalContext } from "contextStates/Global";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { getRemoveWatchlistParams } from "./Watchlist.utils";
import { trackEvent } from "utils/event_tracking";

const WatchlistCoingeckoSearchItem = ({
  result,
  refetch,
  watchlistItems,
  setIsSearchInputFocused,
  resetInput,
}) => {
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const data = result.data;
  const isNft = result.type === "nft_collection" || data.address_type === "NFT";
  const displayName = data?.display_name;
  const image = data?.display_picture;
  const [isAddLoading, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const isAdded = watchlistItems.some((wt) => {
    return isNft
      ? wt.identifier === data?.collection_id
      : result.type === "coingecko"
        ? wt.token_slug === data?.slug
        : wt.token_address === data?.address &&
          wt.chain_id === data?.address_chain;
  });
  const add = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        const response = await addToWatchlist({
          isNft,
          address: data?.address,
          chainId: data?.address_chain ?? data?.chain,
          tokenSlug: data?.slug,
          collectionId: data?.collection_id,
        });

        setIsLoading(false);
        if (response?.status === "ok") {
          refetch();
          setIsSearchInputFocused(false);
          resetInput();
          handleSuccessSnackbar("Added to watchlist");
          trackEvent("added_to_watchlist_from_search");
          return true;
        }
        handleErrorSnackbar(
          response,
          "Failed to add to watchlist at the moment!"
        );
        return false;
      } catch (e) {
        handleErrorSnackbar(e, "Failed to add to watchlist at the moment!");
        setIsLoading(false);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.api_symbol, refetch]
  );

  const remove = useCallback(
    async (data) => {
      try {
        const isNft = result.type === "nft_collection";
        setIsRemoveLoading(true);
        const item = watchlistItems.find((wt) => {
          return isNft
            ? wt.identifier === data?.collection_id
            : result.type === "coingecko"
              ? wt.token_slug === data?.slug
              : wt.token_address === data?.address &&
                wt.chain_id === data?.address_chain;
        });
        const response = await removeFromWatchlist(
          getRemoveWatchlistParams(item)
        );

        setIsSearchInputFocused(false);
        if (response?.status === "ok") {
          handleSuccessSnackbar("Removed from watchlist");
          refetch();
          resetInput();
          trackEvent("removed_from_watchlist_from_search");
          return true;
        }
        setIsRemoveLoading(false);
        handleErrorSnackbar(
          response,
          "Failed to remove from watchlist at the moment!"
        );
        return false;
      } catch (e) {
        handleErrorSnackbar(
          e,
          "Failed to remove from watchlist at the moment!"
        );
        setIsRemoveLoading(false);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.api_symbol, refetch]
  );
  return (
    <div className={classes.searchResultItem}>
      <PaddingComponent height="8px" />
      <CustomRow gap="4px" alignItems="flex-start" width="100%">
        <Image
          src={image}
          alt={displayName + " icon"}
          width={20}
          height={20}
          imageType={isNft ? IMAGE_TYPES.SMALL_NFT : IMAGE_TYPES.SMALL_TOKEN}
          style={{ marginTop: 2 }}
        />
        <FlexGrow>
          <CustomText
            text={displayName}
            fontSize="13px"
            color="var(--text-1)"
            lineHeight="130%"
            letterSpacing="-0.22px"
          />
        </FlexGrow>
        <CursorDiv
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isAdded) {
              remove(data);
              return;
            }
            add(data);
          }}>
          <PaddingComponent padding="8px 0 8px 8px 8px">
            {isAddLoading || isRemoveLoading ? (
              <SpinnerComponent />
            ) : isAdded ? (
              <RemoveFromWatchlist />
            ) : (
              <AddToWatchlist />
            )}
          </PaddingComponent>
        </CursorDiv>
      </CustomRow>
      <PaddingComponent height="8px" />
      <Divider />
    </div>
  );
};

const AddToWatchlist = () => {
  return (
    <CustomText
      text="Add"
      color="var(--primary-color)"
      fontSize="13px"
      fontWeight="600"
      lineHeight="130%"
      letterSpacing="-0.22px"
    />
  );
};

const RemoveFromWatchlist = () => {
  return (
    <CustomText
      text="Remove"
      color="var(--error)"
      fontSize="13px"
      fontWeight="600"
      lineHeight="130%"
      letterSpacing="-0.22px"
    />
  );
};

export default WatchlistCoingeckoSearchItem;
