import classes from "./WalletsComponents.module.css";
import { useCallback, useContext } from "react";
import Spinner from "shared/buttonloadingSpinner";
import { AuthContext } from "contextStates/AuthContext";
import React from "react";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";

const TableRowTextComponent = ({
  text,
  textClass,
  isUnderline,
  isTooltip = false,
}) => {
  if (!isTooltip || text?.length < 10) {
    return (
      <div
        style={{ textDecoration: isUnderline ? "underline" : null }}
        className={textClass ?? classes.text}>
        {text}
      </div>
    );
  }
  return (
    <PPLxTooltip title={text} placement="top-start" arrow>
      <div
        style={{ textDecoration: isUnderline ? "underline" : null }}
        className={textClass ?? classes.text}>
        {text}
      </div>
    </PPLxTooltip>
  );
};

const SourcedBy = ({ wallet, identities }) => {
  const count = wallet.sourced_by_count;
  const sourced_by_identities = Object.keys(wallet.sourced_by);
  const identitiesList = sourced_by_identities
    .map((id) => identities[id])
    .filter((identity) => identity?.display_picture != null);

  const profilePic1 = identitiesList[0]?.display_picture;
  const profilePic2 = identitiesList[1]?.display_picture;

  const countDisplay =
    profilePic2 && profilePic1
      ? count - 2
      : profilePic1 || profilePic2
        ? count - 1
        : count;
  if (count === 0) return <span className={classes.text}>-</span>;

  return (
    <div className={classes.sourcedBy}>
      {profilePic1 && profilePic2 ? (
        <div className={classes.sourcedByProfiles}>
          {profilePic1 && (
            <ProfileHoverCard actor={identitiesList[0]}>
              <img
                className={classes.sourcedByAvatar1}
                src={profilePic1}
                alt="User profile"
              />
            </ProfileHoverCard>
          )}
          {profilePic2 && (
            <ProfileHoverCard actor={identitiesList[1]}>
              <img
                className={classes.sourcedByAvatar2}
                src={profilePic2}
                alt="User profile"
              />
            </ProfileHoverCard>
          )}
        </div>
      ) : (
        (profilePic1 || profilePic2) && (
          <ProfileHoverCard actor={identitiesList[0]}>
            <img
              className={classes.sourcedByAvatar}
              src={profilePic1}
              alt="User profile"
            />
          </ProfileHoverCard>
        )
      )}
      {countDisplay !== 0 && (
        <span className={classes.countText}>{`+${countDisplay}`}</span>
      )}
    </div>
  );
};

const ActionsComponent = ({
  actionIcon1IsLoading,
  actionIcon,
  actionIconAlt,
  actionIconOnClick,
  actionIcon2IsLoading,
  actionIcon2,
  actionIconAlt2,
  actionIconOnClick2,
  actionIcon1Disabled = false,
  actionIcon2Disabled = false,
}) => {
  const { isUserLoggedIn, setShowLoginPopup } = useContext(AuthContext);
  const onClickAction = useCallback(() => {
    if (!isUserLoggedIn) {
      setShowLoginPopup(true);
      return;
    }
    actionIconOnClick();
  }, [isUserLoggedIn, setShowLoginPopup, actionIconOnClick]);
  const onClickAction2 = useCallback(() => {
    if (!isUserLoggedIn) {
      setShowLoginPopup(true);
      return;
    }
    actionIconOnClick2();
  }, [isUserLoggedIn, setShowLoginPopup, actionIconOnClick2]);
  return (
    <div className={classes.actionIcons}>
      {actionIcon1IsLoading ? (
        <Spinner
          style={{
            stroke: "#0648d7",
            background: "transparent",
            width: "20px",
            height: "20px",
            alignSelf: "center",
          }}
        />
      ) : (
        <button
          style={{
            opacity: actionIcon1Disabled ? 0.5 : 1,
          }}
          className={classes.custom_button}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickAction();
          }}>
          <img src={actionIcon} alt={actionIconAlt}></img>
        </button>
      )}
      {!actionIcon2 ? null : actionIcon2IsLoading ? (
        <div className={classes.icon}>
          <Spinner
            style={{
              stroke: "#0648d7",
              background: "transparent",
              width: "20px",
              height: "20px",
              alignSelf: "center",
            }}
          />
        </div>
      ) : (
        <button
          style={{
            opacity: actionIcon1Disabled ? 0.5 : 1,
          }}
          className={classes.custom_button}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickAction2();
          }}>
          <img
            className={classes.icon}
            src={actionIcon2}
            alt={actionIconAlt2}></img>
        </button>
      )}
    </div>
  );
};

export { TableRowTextComponent, ActionsComponent, SourcedBy };
