import {
  getNftCollectionActivities,
  useGetCollectionDetails,
} from "api/nft.api";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams, useHistory } from "react-router-dom";
import ZxNftHeader from "../ZxNftHeader/ZxNftHeader";
import {
  CustomColumn,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import { safeDestructure } from "utils/misc";
import ZxNftCollectionList from "../ZxNftCollectionList/ZxNftCollectionList";
import {
  CollectionPageHeaderLoader,
  NftListLoader,
} from "../ZxNftLoaders/ZxNftLoaders";
import CustomLinkTabBar from "components/UI/Components/CustomLinkTabBar";
import CollectionPageContextProvider, {
  CollectionPageContext,
} from "../CollectionPageContext/CollectionPageContext";
import EmptyStateUI from "components/ErrorBoundary/EmptyStateUI";
import { fetchNfts } from "components/V2/CollectionDetails/collectionsUtils";
import { ONE_DAY } from "utils/constants";
import { createPortal } from "react-dom";
import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import ZxNftDetails from "../ZxNftDetails/ZxNftDetails";
import ZxNftActivities from "../ZxNftActivities/ZxNftActivities";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import { getQueryKeyOptions, QUERY_KEYS } from "utils/query_utils";
import { DEFAULT_NFT_ALL_FILTER } from "../ZxNft.utils";
import OwnersTable from "components/V2/CollectionDetails/OwnersTab/OwnersTable";
import useQueryParams from "customHooks/useQueryParams";
const PAGE_TABS = [
  {
    title: "Listings",
    link: "listings",
  },
  {
    title: "All NFTs",
    link: "all",
  },
  {
    title: "Activities",
    link: "activities",
  },
  {
    title: "Owners",
    link: "owners",
  },
];
const CollectionsPage = ({ collectionId }) => {
  const {
    active_tab: activeTab,
    nft_id: nftId,
    chain_id: nftChainId,
    contract_address: nftContractAddress,
  } = useParams();
  const params = useQueryParams();
  const traitName = params.get("trait");
  const traitValue = params.get("trait_value");
  const isPrefetchDone = useRef(false);
  const {
    data: collection,
    isLoading,
    isError,
  } = useGetCollectionDetails(collectionId);

  const queryClient = useQueryClient();
  const history = useHistory();
  const { updateFilters } = useContext(CollectionPageContext);

  useEffect(() => {
    if (traitName && traitValue) {
      updateFilters({
        [traitName]: [traitValue],
      });
      history.push(`/collection/${collectionId}/all`);
    }
  }, [traitName, traitValue, collectionId, history, updateFilters]);
  const getHeaderProps = useCallback(() => {
    if (collection) {
      const {
        image_url,
        name,
        description,
        external_links,
        market_place_pages,
      } = safeDestructure(collection, [
        "image_url",
        "name",
        "description",
        "external_links",
        "market_place_pages",
      ]);
      return {
        display_picture: image_url,
        name,
        description,
        external_links: {
          ...(external_links ?? {}),
          ...(market_place_pages ?? {}),
        },
      };
    }
    return null;
  }, [collection]);

  useEffect(() => {
    if (!PAGE_TABS.find((t) => t.link === activeTab)) {
      history.push(`/collection/${collectionId}/listings`);
    }
  }, [activeTab, collectionId, history]);
  useEffect(() => {
    // prefetch all tab
    if (!isPrefetchDone.current) {
      queryClient.prefetchInfiniteQuery({
        queryKey: QUERY_KEYS.NFT_COLLECTION_PAGE_NFTS({
          collectionId,
          listingsOnly: false,
          sort: DEFAULT_NFT_ALL_FILTER,
        }),
        queryFn: (props) =>
          fetchNfts({ ...props, collectionId, listingsOnly: false }),
        getNextPageParam: (lastPage) => lastPage?.next_cursor,
        enabled: !!collectionId,
        retry: 3,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: ONE_DAY,
      });
      queryClient.prefetchInfiniteQuery({
        queryKey: QUERY_KEYS.NFT_COLLECTION_ACTIVITIES(collectionId),
        queryFn: ({ pageParam }) =>
          getNftCollectionActivities({ collectionId, pageParam }),
        ...getQueryKeyOptions({}),
        enabled: !!collectionId,
        getNextPageParam: (lastPage) => {
          return lastPage?.next_cursor;
        },
      });
      isPrefetchDone.current = true;
    }
  }, [queryClient, collectionId]);
  if (isError) {
    return <EmptyStateUI text="Something went wrong, please try again" />;
  }

  if (isLoading || !collection)
    return (
      <CustomColumn>
        <CollectionPageHeaderLoader />
        <PaddingComponent padding="16px">
          <NftListLoader />
        </PaddingComponent>
      </CustomColumn>
    );
  const renderTabContent = () => {
    switch (activeTab) {
      case "listings":
        return (
          <ZxNftCollectionList
            listingsOnly
            collectionId={collectionId}
            collection={collection}
            activeTab={activeTab}
          />
        );
      case "all":
        return (
          <ZxNftCollectionList
            listingsOnly={false}
            collectionId={collectionId}
            collection={collection}
            activeTab={activeTab}
          />
        );
      case "activities":
        return (
          <ZxNftActivities collectionId={collectionId} activeTab={activeTab} />
        );
      case "owners":
        return <OwnersTable collectionId={collectionId} />;
      default:
        return <ZxNftCollectionList listingsOnly={false} />;
    }
  };
  return (
    <div>
      <PaddingComponent padding="16px">
        {" "}
        <ZxNftHeader
          details={getHeaderProps()}
          collectionId={collectionId}
          collection={collection}
        />
        <Divider margin="16px 0 16px 0" />
        <CustomColumn padding="4px 0 0 0" gap="12px">
          <CustomLinkTabBar
            tabsList={PAGE_TABS}
            isBorderBottom={true}
            currentStep={
              PAGE_TABS.find((t) => t.link === activeTab)?.link ??
              PAGE_TABS[0].link
            }
          />
          <PaddingComponent padding="0px 0 0 0">
            {renderTabContent()}
          </PaddingComponent>
        </CustomColumn>
      </PaddingComponent>
      {createPortal(
        <RightPanel
          overrideWidth="738px"
          onClose={() => {
            history.push(`/collection/${collectionId}/${activeTab}`);
          }}>
          {nftChainId && nftContractAddress && (
            <ZxNftDetails
              nftId={nftId}
              contractAddress={nftContractAddress}
              chainId={nftChainId}
              collection={collection}
              activePageTab={activeTab}
              onClose={() => {
                history.push(`/collection/${collectionId}/${activeTab}`);
              }}
            />
          )}
        </RightPanel>,
        document.getElementById("rightPanel")
      )}
    </div>
  );
};
const Wrapper = ({ ...props }) => {
  return (
    <CollectionPageContextProvider {...props}>
      <CollectionsPage {...props} />
    </CollectionPageContextProvider>
  );
};
export default withErrorBoundary(Wrapper);
