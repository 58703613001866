import classes from "./HorizontalScrollButtons.module.css";
import { ArrowCircleLeft, ArrowCircleRight } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import _ from "lodash";

export const horizontalScrollContainerClassName = classes.container;

const canElementScrollRight = (element) => {
  return element.scrollWidth > element.offsetWidth + element.scrollLeft;
};
const canElementScrollLeft = (element) => {
  return element.scrollLeft > 0;
};

export function HorizontalScrollButtons({ wrapperEl, postWidth }) {
  const [showLeft, setShowLeft] = React.useState(false);
  const [showRight, setShowRight] = React.useState(true);
  useEffect(() => {
    const ref = wrapperEl.current;
    const onScroll = () => {
      if (wrapperEl.current) {
        setShowLeft(canElementScrollLeft(wrapperEl.current));
        setShowRight(canElementScrollRight(wrapperEl.current));
      }
    };
    const debouncedOnScroll = _.debounce(onScroll, 100);
    wrapperEl.current?.addEventListener("scroll", debouncedOnScroll);
    return () => {
      ref?.removeEventListener("scroll", debouncedOnScroll);
    };
  }, [wrapperEl]);
  const gotoNext = () => {
    wrapperEl.current?.scrollBy({ left: postWidth, behavior: "smooth" });
  };

  const gotoPrev = () => {
    wrapperEl.current?.scrollBy({ left: -postWidth, behavior: "smooth" });
  };
  return (
    <div className={classes.stickyButtons}>
      {showLeft && (
        <ArrowCircleLeft
          size={28}
          color="var(--text-2)"
          weight="thin"
          className={`${classes.arrowButton} ${classes.leftArrow}`}
          onClick={gotoPrev}
        />
      )}
      {showRight && (
        <ArrowCircleRight
          size={28}
          color="var(--text-2)"
          weight="thin"
          className={`${classes.arrowButton} ${classes.rightArrow}`}
          onClick={gotoNext}
        />
      )}
    </div>
  );
}
