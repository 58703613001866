import { getChainMetaDataWithProfile } from "utils/misc";
import Image, { IMAGE_TYPES } from "./Image";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";

const ChainIcon = ({ chainId, chainProfiles, className = "" }) => {
  if (!chainId) return null;
  const chain = getChainMetaDataWithProfile({ chainId, chainProfiles });
  if (!chain) {
    return null;
  }
  const chainName = chain.name ?? chainId;
  return (
    <PPLxTooltip title={chainName} arrow placement="top">
      <Image
        className={className}
        src={chain.icon}
        alt={chainName}
        key={chainName}
        imageType={IMAGE_TYPES.CHAIN}
      />
    </PPLxTooltip>
  );
};

export default ChainIcon;
