import React from "react";
import Refetch from "components/UI/Refetch/Refetch";
import Content from "./Content";
import GhostTokenHoldings from "./GhostTokenHoldings";
import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import { EMPTY_STATES } from "zxStyles/constants";

const TokenHoldingsRenderer = ({
  chainTokenHoldings,
  activeChainId,
  data,
  refetch,
  isError,
  isLoading,
  emptyCondition,
  rightPane = false,
  identifier,
  chainProfiles,
  isCosmos,
  openTradingDesk,
  tradableTokensList,
}) => {
  if (isError) {
    return (
      <Refetch
        onClick={() => {
          refetch();
        }}
      />
    );
  }
  if (isLoading || data?.last_updated_at === -1) {
    return (
      <div className="token-holdings">
        <div className="table">
          <div className="table__tbody">
            <GhostTokenHoldings rightPane={rightPane} />
          </div>
        </div>
      </div>
    );
  }
  if (emptyCondition) {
    return (
      <ZxEmptyState state={EMPTY_STATES.TOKEN} text="No tokens" size={150} />
    );
  }

  return (
    <Content
      holdings={chainTokenHoldings}
      activeChainId={activeChainId}
      rightPane={rightPane}
      identifier={identifier}
      chainProfiles={chainProfiles}
      isCosmos={isCosmos}
      openTradingDesk={openTradingDesk}
      tradableTokensList={tradableTokensList}
      refetch={refetch}
    />
  );
};

export default TokenHoldingsRenderer;
