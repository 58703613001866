const ProgressCircle = ({ progress, radius = 10, strokeWidth = 2 }) => {
  const diameter = radius * 2;
  const svgSize = diameter + strokeWidth * 3;
  const circumference = 2 * Math.PI * radius;
  const progressCalc = (progress * 100 * circumference) / 100;
  return (
    <svg
      height={svgSize}
      width={svgSize}
      style={{ transform: "rotate(-90deg)" }}>
      <circle
        stroke="var(--border-light)"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={svgSize / 2}
        cy={svgSize / 2}
      />
      <circle
        stroke="var(--primary-color)"
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset: circumference - progressCalc }}
        r={radius}
        cx={svgSize / 2}
        cy={svgSize / 2}
      />
    </svg>
  );
};

export default ProgressCircle;
