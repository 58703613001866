import { CustomRow, PriceChange } from "components/UI/Components/Components";
import ZxText from "zxComponents/common/ZxText";
import { TSTYLES } from "zxStyles/constants";

export const LabeledValue = ({
  label,
  value,
  change,
  isMoneyChange,
  ...rowProps
}) => {
  return (
    <CustomRow gap="4px" {...rowProps} alignItems="center">
      <ZxText style={TSTYLES.secondary2} fontSize={11}>
        {label}
      </ZxText>
      <ZxText style={TSTYLES.body3}>{value || "-"}</ZxText>
      {change && (
        <PriceChange
          value={change.value}
          displayValue={change.display_value}
          fontWeight="400"
        />
      )}
    </CustomRow>
  );
};
