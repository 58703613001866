import { CaretDown, CaretUp } from "@phosphor-icons/react";
import {
  PaddingComponent,
  Divider,
  CustomColumn,
  CustomRow,
  CustomText,
  CursorDiv,
  InfoBox,
} from "components/UI/Components/Components";
import { useCallback, useEffect, useState, useContext } from "react";
import pplIcon from "assets/svg/Landing/logo.svg";
import twitterIcon from "assets/svg/twitter.svg";
import farcasterIcon from "assets/svg/farcaster.svg";
import lensIcon from "assets/svg/lens.svg";
import classes from "./NewPost.module.css";
import CrossPostDialog from "../CrossPost/CrossPostDialog";
import { ClickAwayListener } from "@mui/material";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import CrossPostFarcasterPopUp from "../CrossPost/CrossPostFarcasterPopUp";
import { getConnectFarcaster, pollFarcasterConnectStatus } from "api/feed.api";
import { useQuery } from "@tanstack/react-query";
import { GlobalContext } from "contextStates/Global";
import { USER_PREFENCES } from "utils/constants";
import usePostLensAuthMutation from "../CrossPost/connect_lens_utils";

const NewPostHeader = ({
  connectedPlatforms = [],
  isLoading,
  refetch,
  setSelectedPlatforms,
  selectedPlatforms,
  isVisible,
  isCrossPostDefaultSelected = true,
}) => {
  const [isShowingConnect, setIsShowingConnect] = useState(false);
  const isTwitter =
    isCrossPostDefaultSelected && connectedPlatforms.includes("twitter");
  const isFarcaster =
    isCrossPostDefaultSelected && connectedPlatforms.includes("farcaster");
  const isLens =
    isCrossPostDefaultSelected && connectedPlatforms.includes("lens");
  const [isShowFarcasterPopUp, setIsShowFarcasterPopUp] = useState(false);
  const [isFarcasterConnectLoading, setIsFarcasterConnectLoading] =
    useState(null);
  const [isTwitterConnectLoading, setIsTwitterConnectLoading] = useState(false);
  const [farcasterRedirectUrl, setFarcasterRedirectUrl] = useState(null);
  const [isFarcasterPolling, setIsFarcasterPolling] = useState(false);
  const [isTwitterEnabled, setIsTwitterEnabled] = useState(
    isTwitter && selectedPlatforms?.includes("twitter")
  );
  const [isFarcasterEnabled, setIsFarcasterEnabled] = useState(
    isFarcaster && selectedPlatforms?.includes("farcaster")
  );
  const [isLensEnabled, setIsLensEnabled] = useState(
    isLens && selectedPlatforms?.includes("lens")
  );
  const [isLensConnectLoading, setIsLensConnectLoading] = useState(false);
  const isLensFeatureEnabled =
    useContext(GlobalContext).featureFlags["is_lens_cross_post_enabled"];
  const {
    getUserPreference,
    setUserPreference,
    handleErrorSnackbar,
    handleSuccessSnackbar,
  } = useContext(GlobalContext);

  const postLensAuthMutation = usePostLensAuthMutation({
    onSuccess: () => {
      handleSuccessSnackbar?.("Connected to Lens");
      refetch();
    },
    onError: (e) => {
      handleErrorSnackbar?.(null, "Failed to connect to Lens");
    },
    onFinish: () => {
      setIsLensConnectLoading(false);
    },
  });

  useEffect(() => {
    const prefs = getUserPreference(
      USER_PREFENCES.CROSS_POST_SELECTED_PLATFORMS
    ) || ["twitter", "farcaster", "lens"];
    const platformsStrList = [];

    if (connectedPlatforms.length === 0) {
      return;
    }
    if (
      connectedPlatforms?.includes("twitter") &&
      selectedPlatforms?.includes("twitter") &&
      prefs?.includes("twitter") &&
      isCrossPostDefaultSelected
    ) {
      setIsTwitterEnabled(true);
      platformsStrList.push("twitter");
    } else {
      setIsTwitterEnabled(false);
    }
    if (
      connectedPlatforms?.includes("farcaster") &&
      selectedPlatforms?.includes("farcaster") &&
      prefs?.includes("farcaster") &&
      isCrossPostDefaultSelected
    ) {
      setIsFarcasterEnabled(true);
      platformsStrList.push("farcaster");
    } else {
      setIsFarcasterEnabled(false);
    }
    if (
      connectedPlatforms?.includes("lens") &&
      selectedPlatforms?.includes("lens") &&
      prefs?.includes("lens") &&
      isCrossPostDefaultSelected
    ) {
      setIsLensEnabled(true);
      platformsStrList.push("lens");
    } else {
      setIsLensEnabled(false);
    }
  }, [
    connectedPlatforms,
    setIsTwitterEnabled,
    setIsFarcasterEnabled,
    getUserPreference,
    selectedPlatforms,
    isCrossPostDefaultSelected,
  ]);

  const { data: farcasterPollingData } = useQuery({
    queryKey: ["cross-post-farcaster-polling"],
    queryFn: pollFarcasterConnectStatus,
    enabled: isFarcasterPolling,
    refetchInterval: 2000,
  });

  useEffect(() => {
    if (farcasterPollingData) {
      if (farcasterPollingData.connection_state === "approved") {
        setIsShowFarcasterPopUp(false);
        setIsFarcasterPolling(false);
        refetch();
      }
    }
  }, [
    farcasterPollingData,
    setIsShowFarcasterPopUp,
    setIsFarcasterPolling,
    refetch,
  ]);

  const onConnectFarcaster = useCallback(() => {
    if (!!farcasterRedirectUrl) {
      setIsShowFarcasterPopUp(true);
      return;
    }
    setIsFarcasterConnectLoading(true);
    getConnectFarcaster()
      .then((res) => {
        setIsShowFarcasterPopUp(true);
        setIsFarcasterConnectLoading(false);
        setFarcasterRedirectUrl(res.url);
      })
      .catch((err) => {
        setIsFarcasterConnectLoading(false);
      });
  }, [
    farcasterRedirectUrl,
    setIsFarcasterConnectLoading,
    setFarcasterRedirectUrl,
  ]);

  const onConnectLens = useCallback(
    async ({ accessToken, refreshToken }) => {
      try {
        await postLensAuthMutation.mutateAsync({
          accessToken,
          refreshToken,
        });
      } catch (e) {
        handleErrorSnackbar?.(null, "Failed to connect to Lens");
      }
    },
    [postLensAuthMutation, handleErrorSnackbar]
  );

  useEffect(() => {
    if (isFarcasterConnectLoading != null) {
      setIsShowingConnect(!isShowFarcasterPopUp);
      setIsFarcasterPolling(isShowFarcasterPopUp);
    }
  }, [
    isShowFarcasterPopUp,
    setIsShowFarcasterPopUp,
    isFarcasterConnectLoading,
  ]);

  const selectAndSavePlatforms = ({ twitter, farcaster, lens }) => {
    const platformsStrList = [];
    if (twitter) {
      platformsStrList.push("twitter");
    }
    if (farcaster) {
      platformsStrList.push("farcaster");
    }
    if (lens) {
      platformsStrList.push("lens");
    }
    setIsLensEnabled(lens);
    setIsFarcasterEnabled(farcaster);
    setIsTwitterEnabled(twitter);
    setSelectedPlatforms(platformsStrList);
    setUserPreference(
      USER_PREFENCES.CROSS_POST_SELECTED_PLATFORMS,
      platformsStrList
    );
  };

  if (!isVisible) {
    return null;
  }

  return (
    <CustomColumn>
      <CustomRow alignItems="center">
        <CustomText text="Post on:" />
        <PaddingComponent width="4px" />
        <PaddingComponent paddingRight="8px">
          <img src={pplIcon} className={classes.postOnImage} alt="ppl" />
        </PaddingComponent>
        {isTwitterEnabled && (
          <PaddingComponent paddingRight="8px">
            <img
              src={twitterIcon}
              className={classes.postOnImage}
              alt="twitter"
            />
          </PaddingComponent>
        )}
        {isFarcasterEnabled && (
          <PaddingComponent paddingRight="8px">
            <img
              src={farcasterIcon}
              className={classes.postOnImage}
              alt="farcaster"
            />
          </PaddingComponent>
        )}
        {isLensEnabled && isLensFeatureEnabled && (
          <PaddingComponent paddingRight="8px">
            <img
              src={lensIcon}
              className={classes.postOnImage}
              alt="farcaster"
            />
          </PaddingComponent>
        )}
        <ClickAwayListener onClickAway={() => setIsShowingConnect(false)}>
          <CursorDiv>
            <ArrowComp
              refetch={refetch}
              isLoading={isLoading}
              isFarcasterConnectLoading={isFarcasterConnectLoading}
              onConnectFarcaster={onConnectFarcaster}
              connectedPlatforms={connectedPlatforms}
              isShowingConnect={isShowingConnect}
              setIsShowingConnect={setIsShowingConnect}
              isTwitterConnectLoading={isTwitterConnectLoading}
              setIsTwitterConnectLoading={setIsTwitterConnectLoading}
              setIsTwitterEnabled={(val) => {
                setIsTwitterEnabled(val);
                selectAndSavePlatforms({
                  twitter: val,
                  farcaster: isFarcasterEnabled,
                  lens: isLensEnabled,
                });
              }}
              setIsFarcasterEnabled={(val) => {
                setIsFarcasterEnabled(val);
                selectAndSavePlatforms({
                  twitter: isTwitterEnabled,
                  farcaster: val,
                  lens: isLensEnabled,
                });
              }}
              setIsLensEnabled={(val) => {
                setIsLensEnabled(val);
                selectAndSavePlatforms({
                  twitter: isTwitterEnabled,
                  farcaster: isFarcasterEnabled,
                  lens: val,
                });
              }}
              setAllPlatforms={selectAndSavePlatforms}
              setIsLensConnectLoading={setIsLensConnectLoading}
              isLensConnectLoading={isLensConnectLoading}
              isFarcasterEnabled={isFarcasterEnabled}
              isTwitterEnabled={isTwitterEnabled}
              isLensEnabled={isLensEnabled}
              onConnectLens={onConnectLens}
            />
          </CursorDiv>
        </ClickAwayListener>
      </CustomRow>
      <PaddingComponent height="8px" />
      <Divider />
      {isShowFarcasterPopUp && (
        <CrossPostFarcasterPopUp
          farcasterRedirectUrl={farcasterRedirectUrl}
          setIsShowFarcasterPopUp={setIsShowFarcasterPopUp}
        />
      )}
    </CustomColumn>
  );
};

const ArrowComp = ({
  isShowingConnect,
  setIsShowingConnect,
  connectedPlatforms,
  onConnectFarcaster,
  isFarcasterConnectLoading,
  isLoading,
  setIsTwitterConnectLoading,
  isTwitterConnectLoading,
  refetch,
  isTwitterEnabled,
  isFarcasterEnabled,
  onConnectLens,
  setIsTwitterEnabled,
  setIsFarcasterEnabled,
  isLensEnabled,
  setIsLensEnabled,
  setIsLensConnectLoading,
  isLensConnectLoading,
  setAllPlatforms,
}) => {
  if (isShowingConnect) {
    return (
      <ClickAwayListener onClickAway={() => setIsShowingConnect(false)}>
        <div>
          <PPLxHoverCard
            minWidth={240}
            open={isShowingConnect}
            content={
              <CrossPostDialog
                setAllPlatforms={setAllPlatforms}
                refetch={refetch}
                setIsTwitterConnectLoading={setIsTwitterConnectLoading}
                isTwitterConnectLoading={isTwitterConnectLoading}
                isLoading={isLoading}
                isFarcasterConnectLoading={isFarcasterConnectLoading}
                onConnectFarcaster={onConnectFarcaster}
                onConnectLens={onConnectLens}
                connectedPlatforms={connectedPlatforms}
                setIsTwitterEnabled={setIsTwitterEnabled}
                setIsFarcasterEnabled={setIsFarcasterEnabled}
                isFarcasterEnabled={isFarcasterEnabled}
                isTwitterEnabled={isTwitterEnabled}
                isLensEnabled={isLensEnabled}
                setIsLensEnabled={setIsLensEnabled}
                isLensConnectLoading={isLensConnectLoading}
                setIsLensConnectLoading={setIsLensConnectLoading}
              />
            }
            placement="bottom-start">
            <CursorDiv
              onClick={(e) => {
                e.stopPropagation();
                setIsShowingConnect(!isShowingConnect);
              }}>
              <AddPlatformButton
                key="expanded"
                isExpanded={isShowingConnect}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShowingConnect(!isShowingConnect);
                }}
              />
            </CursorDiv>
          </PPLxHoverCard>
        </div>
      </ClickAwayListener>
    );
  }
  return (
    <CursorDiv
      onClick={(e) => {
        e.stopPropagation();
        setIsShowingConnect(!isShowingConnect);
      }}>
      <AddPlatformButton key="collapsed" isExpanded={isShowingConnect} />
    </CursorDiv>
  );
};

const AddPlatformButton = ({ isExpanded, onClick }) => {
  return (
    <div>
      <InfoBox
        onClick={onClick}
        padding="4px 12px"
        radius="30px"
        background={isExpanded ? "var(--base)" : "var(--elevation-1)"}>
        <CustomRow gap="4px">
          <CustomText
            text="Add Platform"
            fontSize="13px"
            fontWeight="500"
            color="var(--text-2)"
          />
          <PaddingComponent height="16px">
            {isExpanded ? (
              <CaretUp size={16} color="var(--text-2)" />
            ) : (
              <CaretDown size={16} color="var(--text-2)" />
            )}
          </PaddingComponent>
        </CustomRow>
      </InfoBox>
    </div>
  );
};

export default NewPostHeader;
