import { Divider } from "components/UI/Components/Components";
import React, { useCallback, useContext, useState } from "react";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxInput from "zxComponents/common/ZxInput/ZxInput";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import {
  ProfileEditTwitterConnect,
  ProfileSocialActions,
} from "./ProfileEditPage.utils";
import ConnectLens from "components/V2/NewOnboarding/SocialLogins/ConnectLens";
import FarcasterSignInButton from "zxComponents/FarcasterSignInButton/FarcasterSignInButton";
import { useIsTelegramConnected } from "api/profile.api";
import TelegramLoginButton from "components/External/TelegramLoginButton";
import { GlobalContext } from "contextStates/Global";

const ProfileEditForm = ({
  form,
  isLoading,
  profile,
  identifier,
  refetchProfile,
}) => {
  const { handleSuccessSnackbar } = useContext(GlobalContext);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = form;
  const { verified_socials } = profile ?? { verified_socials: [] };
  const { data: telegram, refetch: refetchTelegramStatus } =
    useIsTelegramConnected();
  const [socialLoaders, setSocialLoaders] = useState({
    lens: false,
    twitter: false,
    farcaster: false,
  });
  const updateSocialLoader = (key, value) => {
    setSocialLoaders((prev) => ({ ...prev, [key]: value }));
  };
  const getInputProps = useCallback(
    (name, label) => {
      return {
        register,
        name,
        width: "380px",
        containerWidth: "100%",
        labelWidth: "100px",
        flexProps: {
          dir: "row",
          justify: "space-between",
          align: "center",
        },
        label,
        placeholder: `Enter ${label}`,
        disabled: isLoading,
        value: watch(name),
      };
    },
    [register, isLoading, watch]
  );
  const isTwitterVerified = verified_socials.includes("twitter");
  const isFarcasterVerified = verified_socials.includes("farcaster");
  const isLensVerified = verified_socials.includes("lens");
  const isTelegramConnected = telegram?.data?.data;

  const handleSocialSuccess = (social) => {
    handleSuccessSnackbar?.(social + " connected!");
    refetchProfile();
  };
  const isAnySocialLoading = Object.values(socialLoaders).some((v) => v);
  return (
    <form onSubmit={handleSubmit}>
      <ZxFlex dir="column" gap="12px" align="flex-start">
        <ZxText style={TSTYLES.secondaryLabel}>PROFILE</ZxText>
        <ZxInput
          {...getInputProps("display_name", "Name")}
          validation={{
            message: "Name is required",
            validate: (v) => {
              if (!v) return "Name is required";
              if (v.length > 24)
                return "Name should be less than 25 characters";
              return true;
            },
          }}
          disabled={isAnySocialLoading}
          error={errors?.display_name}
        />
        <ZxInput
          isTextArea
          error={errors?.bio}
          {...getInputProps("bio", "Bio")}
          validation={{
            validate: (v) => {
              if (!v) return "Bio is required";
              if (v.length > 160)
                return "Bio should be less than 160 characters";
              const regex = [
                "^.{10,}$",
                "^.*(?:[A-Za-z0-9]+[^A-Za-z0-9]+){3,}[A-Za-z0-9]+.*$",
              ];
              if (regex.every((r) => new RegExp(r, "s").test(v))) return true;
              else
                return "Bio should be more than 10 characters and contain at least 4 words";
            },
          }}
          disabled={isAnySocialLoading}
        />
      </ZxFlex>
      <Divider margin="20px 0" />
      <ZxFlex dir="column" gap="12px" align="flex-start">
        <ZxText style={TSTYLES.secondaryLabel}>SOCIALS</ZxText>
        <ZxInput
          {...getInputProps("socials.twitter", "Twitter", "Twitter ID")}
          disabled={isTwitterVerified || isAnySocialLoading}
          inputSuffix={
            <ProfileEditTwitterConnect>
              <ProfileSocialActions isVerified={isTwitterVerified} />
            </ProfileEditTwitterConnect>
          }
        />
        <ZxInput
          inputSuffix={
            <FarcasterSignInButton
              isOnboarding={false}
              isSocialConnect={true}
              onSuccess={() => handleSocialSuccess("Farcaster")}
              customRender={() => (
                <ProfileSocialActions
                  isVerified={isFarcasterVerified}
                  isLoading={socialLoaders?.farcaster}
                />
              )}
            />
          }
          {...getInputProps("socials.farcaster", "Farcaster", "Farcaster ID")}
          disabled={isFarcasterVerified || isAnySocialLoading}
        />
        <ZxInput
          {...getInputProps("socials.lens", "Lens", "Lens ID")}
          disabled={isLensVerified || socialLoaders?.lens}
          inputSuffix={
            <ConnectLens
              updateLoading={(v) => updateSocialLoader("lens", v)}
              customRender={() => (
                // <div onClick={stopEventPropagation}>
                <ProfileSocialActions
                  isVerified={isLensVerified}
                  isLoading={socialLoaders?.lens}
                />
                // </div>
              )}
              disabled={false}
              onSuccess={refetchProfile}
            />
          }
        />
        <ZxInput
          {...getInputProps("socials.telegram", "Telegram", "Telegram ID")}
          disabled={isTelegramConnected || isAnySocialLoading}
          inputSuffix={
            <TelegramLoginButton
              customRender={() => (
                <ProfileSocialActions isVerified={isTelegramConnected} />
              )}
              onSuccess={refetchTelegramStatus}
              isTelegramConnected={false}
            />
          }
        />
        <ZxInput
          disabled={isAnySocialLoading}
          {...getInputProps("socials.github", "Github", "Github ID")}
        />
        <ZxInput
          disabled={isAnySocialLoading}
          {...getInputProps("socials.linkedin", "LinkedIn", "LinkedIn ID")}
        />
      </ZxFlex>
    </form>
  );
};

export default ProfileEditForm;
