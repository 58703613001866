import { signedRequest } from "api/api";

export const getSummaryPromptData = ({ promptId }) => {
  return signedRequest({
    method: "post",
    path: "/api/v4/get_gpt_prompt_data",
    bodyText: JSON.stringify({
      prompt_id: promptId,
    }),
  });
};

export const updateSummaryResponseText = ({
  promptId,
  curatedResponseText,
}) => {
  return signedRequest({
    method: "post",
    path: "/api/v4/update_summary_response_text",
    bodyText: JSON.stringify({
      prompt_id: promptId,
      curated_response_text: curatedResponseText,
    }),
  });
};

export const activityGroupHash = (groupedActivity) => {
  if (groupedActivity?.group_uid) {
    return groupedActivity.group_uid;
  }
  if (groupedActivity?.activities) {
    return groupedActivity.activities.reduce(
      (acc, activity) =>
        activity.description_template
          ? acc + activity.description_template
          : acc,
      ""
    );
  }
  return groupedActivity?.timestamp;
};
