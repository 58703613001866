import {
  CustomButton,
  CustomRow,
  CustomTextSpan,
  RedirectLink,
} from "components/UI/Components/Components";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import OnboardingBg from "../OnboardingBg";
import { CustomCheckBox } from "./CustomCheckBox";
import classes from "./TalkToUs.module.css";
import ethIcon from "./assets/eth-icon.png";
import kIcon from "./assets/k-icon.png";
import solIcon from "./assets/sol-icon.png";
import { CustomText } from "components/UI/Components/Components";
import CustomDivider from "components/UI/CustomComponents/CustomDivider";
import { AuthContext } from "contextStates/AuthContext";
import { getOnboardingStepDetails } from "api/waitlist.api";
import { useQuery } from "@tanstack/react-query";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { CaretLeft } from "@phosphor-icons/react";

const FALLBACKS = {
  telegram: "https://t.me/BT06Fh0aqjpjMDk8",
  calendly: "https://calendly.com/d/ck6b-8ht-v9s/0xppl-onboarding-support",
};

const TalkToUs = () => {
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const { getUserAddress } = useContext(AuthContext);
  const identifier = getUserAddress();
  const { data } = useQuery({
    queryKey: ["getOnboardingStepDetails", identifier],
    queryFn: getOnboardingStepDetails,
  });
  const links = data?.data?.data?.contact_us ?? null;
  const telegramLink = links?.telegram ?? FALLBACKS.telegram;
  const calendlyLink = links?.calendly ?? FALLBACKS.calendly;

  const toggleSelection = (option) => {
    setSelectedOption((prevOption) => (prevOption === option ? null : option));
  };

  return (
    <BaseWrapper mixpanelEvent="OnboardingTalkToUs">
      <OnboardingBg showStepper={false}>
        <CustomRow alignItems="center" gap="8px">
          <CaretLeft
            color="var(--text-1)"
            weight="bold"
            size="20px"
            className={classes.backBtn}
            onClick={() => history.goBack()}
          />
          <CustomText
            text="Talk to Us 👋"
            fontSize="28px"
            fontWeight="600"
            fontFamily="Roboto"
            color="#1b1f22"
            textAlign="left"
          />
        </CustomRow>
        <div className={classes.introCard}>
          <div className={classes.introText}>
            <CustomTextSpan fontWeight="500" fontSize="17px" color="#48406c">
              We are excited!
            </CustomTextSpan>
            <CustomTextSpan fontSize="13px" color="#6c638e">
              Let's chat! Schedule a call with a 0xPPL member or send a Telegram
              message.
            </CustomTextSpan>
          </div>
          <div className={classes.icons}>
            <img
              className={`${classes.icon} ${classes.eth}`}
              src={ethIcon}
              alt="Ethereum Icon"
            />
            <img
              className={`${classes.icon} ${classes.k}`}
              src={kIcon}
              alt="K Icon"
            />
            <img
              className={`${classes.icon} ${classes.sol}`}
              src={solIcon}
              alt="Solana Icon"
            />
          </div>
        </div>

        <CustomDivider />

        <div className={classes.optionsSection}>
          <CustomCheckBox
            isActive={selectedOption === "coffeeChat"}
            setIsActive={() => toggleSelection("coffeeChat")}>
            <div className={classes.option}>
              <img
                src={"/svg/tea-cup-font-color.svg"}
                alt="Coffee Chat Icon"
                className={classes.icon}
              />
              <div className={classes.optionText}>
                <CustomTextSpan
                  fontWeight="600"
                  fontSize="17px"
                  color="#1b1f22">
                  Coffee chat with us
                </CustomTextSpan>
                <CustomTextSpan fontSize="15px" color="#687684">
                  Meet a 0xPPL team member (30m)
                </CustomTextSpan>
              </div>
            </div>
          </CustomCheckBox>
          <CustomCheckBox
            isActive={selectedOption === "telegramChat"}
            setIsActive={() => toggleSelection("telegramChat")}>
            <div className={classes.option}>
              <img
                src={"/svg/chat-bubble-front-color.svg"}
                alt="Telegram Chat Icon"
                className={classes.icon}
              />
              <div className={classes.optionText}>
                <CustomTextSpan
                  fontWeight="600"
                  fontSize="17px"
                  color="#1b1f22">
                  Chat on Telegram
                </CustomTextSpan>
                <CustomTextSpan fontSize="15px" color="#687684">
                  A 0xPPL member will assist you
                </CustomTextSpan>
              </div>
            </div>
          </CustomCheckBox>
          <RedirectLink
            link={
              selectedOption === "telegramChat" ? telegramLink : calendlyLink
            }>
            <CustomButton
              text="Proceed"
              disabled={!selectedOption}
              height="58px"
              borderRadius="12px"
              background="var(--primary-color)"
              className={classes.proceedButton}
              onClick={() => {}}
            />
          </RedirectLink>
        </div>
      </OnboardingBg>
    </BaseWrapper>
  );
};

export default TalkToUs;
