import { Autocomplete } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState, useMemo, useContext } from "react";
import { getSearchResults } from "api/search.api";
import { debounce } from "lodash";
import CustomTextField from "components/FollowEditButton/CustomTextField";
import { Select, MenuItem } from "@mui/material";
import { useBundleInfo } from "components/Pages/AppModule/RightSideBar/apis/useBundleInfo";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  FlexGrow,
  PaddingComponent,
  BootstrapInput,
  CustomButton,
} from "components/UI/Components/Components";
import WalletPrivacy from "components/V2/Profile/ProfileDetails/wallets/components/WalletPrivacy";
import WalletVerifiedBadge from "components/V2/Profile/ProfileDetails/wallets/components/WalletVerifiedBadge";
import AddressComponent from "components/UI/Components/AddressComponent";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import { CaretDown } from "@phosphor-icons/react";
import { IconButton } from "@mui/material";
import classes from "./PersonSearch.module.css";
import { useRecentSearchResults } from "components/Pages/AppModule/Header/components/searchbar_utils";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { isEVMAddress } from "contextStates/auth_utils";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import FollowEditButton from "components/FollowEditButton/FollowEditButton";
import { SendTokenContext } from "contextStates/SendTokenContext";
import { computeDefaultIdentity } from "./SendTokenPanel";

const PersonSearch = ({
  toAddress,
  setToAddress,
  onUpdateCallback,
  hideChains = [],
}) => {
  const { isAddress, identity, relatedIdentity, isLoggedInUserProfile } =
    useContext(SendTokenContext);
  const [isAddressIsInBundle, setIsAddressIsInBundle] = useState(true);
  const defaultIdentity = computeDefaultIdentity({
    isAddress,
    identity,
    relatedIdentity,
    isLoggedInUserProfile,
  });

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(defaultIdentity);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAddressSelectorOpen, setIsAddressSelectorOpen] = useState(false);
  const [isExtraDetailsOpen, setIsExtraDetailsOpen] = useState(false);
  const [newAddedAddress, setNewAddedAddress] = useState(null);
  const loading = open && isLoading;
  const { recentSearchResults, updateRecentSearches } =
    useRecentSearchResults();

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        getSearchResults({
          queryKey: ["send-search", `${request.searchTerm} source:identity `],
          signal: request.controller.signal,
        })
          .then(callback)
          .catch((e) => {
            //do nothing no need to handle cancel case
          });
      }, 400),
    []
  );

  const { data: bundleDetails, isLoading: isLoadingBundleDetails } =
    useBundleInfo({
      identifier: isAddressSelectorOpen ? value?.data?.id : null,
    });

  useEffect(() => {
    let active = true;
    const controller = new AbortController();

    if (inputValue === "") {
      setOptions([...recentSearchResults]);
      return undefined;
    }

    setIsLoading(true);
    fetch({ searchTerm: inputValue, controller }, (results) => {
      if (active) {
        let newOptions = [];
        const isIdentifiedAddress =
          results.length === 1 &&
          results[0].type === "address" &&
          results[0].data.identity_id !== null;
        if (isIdentifiedAddress) {
          setValue({
            type: "identity",
            data: {
              display_name: results[0].data.display_name,
              display_picture: results[0].data.display_picture,
              id: results[0].data.identity_id,
            },
          });
          setOptions([]);
          setIsLoading(false);
          return;
        }

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...results];
        }

        setOptions(newOptions);
        setIsLoading(false);
      }
    });

    return () => {
      active = false;
      if (!controller.signal.aborted) {
        controller.abort();
      }
    };
  }, [inputValue, fetch, value, recentSearchResults, setToAddress]);
  useEffect(() => {
    if (value?.type === "identity" && value?.data) {
      setIsAddressSelectorOpen(true);
      setIsExtraDetailsOpen(false);
    } else if (value?.type === "address") {
      setIsAddressSelectorOpen(false);
      if (isEVMAddress(value?.data?.address)) {
        setToAddress(value?.data?.address);
        setIsExtraDetailsOpen(true);
      } else {
        setToAddress("");
        setIsExtraDetailsOpen(false);
      }
    } else {
      setIsAddressSelectorOpen(false);
      setIsExtraDetailsOpen(false);
      setToAddress("");
    }
  }, [value, setToAddress]);
  useEffect(() => {
    if (bundleDetails?.data?.data?.bundle?.wallets?.length > 0) {
      if (newAddedAddress) {
        setToAddress(newAddedAddress);
      } else {
        const wallets = bundleDetails?.data?.data?.bundle?.wallets;
        if (toAddress && isEVMAddress(toAddress)) {
          if (!wallets.find((w) => w.address === toAddress)) {
            setIsAddressIsInBundle(false);
            setIsAddressSelectorOpen(false);
          } else {
            // If the selected address is in the bundle, set the selected address i.e toAddress not changed
            return;
          }
        } else {
          const nonSolanaWallets = (
            bundleDetails?.data?.data?.bundle?.wallets ?? []
          ).filter((w) => w.chain_id !== "Solana");
          setToAddress(nonSolanaWallets?.[0]?.address ?? "");
        }
      }
    }
  }, [bundleDetails, newAddedAddress, setToAddress, toAddress]);

  useEffect(() => {
    if (!open) {
      setOptions([...recentSearchResults]);
    }
  }, [open, recentSearchResults]);

  return (
    <CustomColumn gap="16px" width="100%">
      <Autocomplete
        freeSolo
        sx={{
          width: "100%",
          "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
            color: "var(--text-2)",
          },
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) =>
          option.data?.id === value.data?.id
        }
        readOnly={!!defaultIdentity}
        getOptionLabel={(option) => option?.data?.display_name || option || ""}
        renderOption={(props, option) => {
          return (
            <CustomRow
              gap="4px"
              alignItems="center"
              {...props}
              key={option.data.id}>
              <Image
                src={option?.data?.display_picture}
                className={classes.userAvatar}
                imageType={IMAGE_TYPES.SMALL_AVATAR}
              />
              <CustomText text={option?.data?.display_name} />
              <VerifiedBadge profile={option?.data} />
            </CustomRow>
          );
        }}
        options={options}
        loading={loading}
        loadingText={<CustomText text="Loading..." />}
        value={value}
        onChange={(event, newValue, reason) => {
          if (typeof newValue === "string") {
            setValue({
              type: "address",
              data: {
                display_name: newValue,
              },
            });
          } else {
            setValue(newValue);
            updateRecentSearches(newValue);
          }
          if (reason === "clear") {
            setNewAddedAddress(null);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          if (isEVMAddress(newInputValue)) {
            setToAddress(newInputValue);
            setIsExtraDetailsOpen(true);
          } else if (value?.type === "identity") {
            // dont change the address if the user is selected, the above useeffect will handle this
            setIsExtraDetailsOpen(false);
          } else {
            setToAddress("");
            setIsExtraDetailsOpen(false);
          }
        }}
        renderInput={(params) => {
          return (
            <>
              {value?.type === "identity" ? (
                <PaddingComponent className={classes.selectedUserInput}>
                  <PaddingComponent className={classes.selectedUserInputInner}>
                    <ProfileHoverCard actor={value?.data}>
                      <CustomRow gap="4px" alignItems="center">
                        <Image
                          src={value?.data?.display_picture}
                          className={classes.userAvatar}
                          imageType={IMAGE_TYPES.SMALL_AVATAR}
                        />
                        <CustomText text={value?.data?.display_name} />
                        <VerifiedBadge profile={value?.data} />
                      </CustomRow>
                    </ProfileHoverCard>
                    {params.InputProps.endAdornment}
                  </PaddingComponent>
                </PaddingComponent>
              ) : (
                ""
              )}
              {value?.type === "address" ? (
                <PaddingComponent className={classes.selectedUserInput}>
                  <PaddingComponent className={classes.selectedUserInputInner}>
                    <CustomRow gap="4px" alignItems="center">
                      <CustomText text={value?.data?.address} />
                    </CustomRow>
                    {params.InputProps.endAdornment}
                  </PaddingComponent>
                </PaddingComponent>
              ) : (
                ""
              )}
              <CustomTextField
                {...params}
                style={{
                  display:
                    value?.type === "identity" || value?.type === "address"
                      ? "none"
                      : "",
                }}
                size="small"
                fullWidth
                autoFocus
                placeholder="Name / Wallet Address"
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    "& .MuiIconButton-root": {
                      color: "var(--text-2)",
                    },
                  },
                  endAdornment: (
                    <CustomRow gap="8px" style={{ color: "var(--text-2)" }}>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </CustomRow>
                  ),
                }}
              />
            </>
          );
        }}
      />
      {!isAddressIsInBundle && (
        <PaddingComponent className={classes.selectedUserInput}>
          <PaddingComponent className={classes.selectedUserInputInner}>
            <CustomRow gap="4px" alignItems="center">
              <CustomText text={toAddress} />
            </CustomRow>
          </PaddingComponent>
        </PaddingComponent>
      )}
      {isAddressSelectorOpen && (
        <Select
          value={toAddress}
          size="small"
          sx={{
            "& .MuiSelect-select": {
              height: "20px",
            },
          }}
          MenuProps={{
            sx: {
              ".MuiMenu-paper": {
                backgroundColor: "var(--navBg)",
              },
            },
          }}
          input={<BootstrapInput />}
          onChange={(e) => {
            setToAddress(e.target.value);
          }}
          renderValue={(value) => {
            const wallet = bundleDetails?.data?.data?.bundle?.wallets?.find(
              (w) => w.address === value
            );
            return (
              <CustomRow gap="4px" alignItems="center">
                <WalletPrivacy wallet={wallet} />
                <AddressComponent
                  textClassName={classes.addressText}
                  address={wallet?.address}
                  showTrimmed
                />
                <WalletVerifiedBadge wallet={wallet} />
              </CustomRow>
            );
          }}
          IconComponent={(props) => {
            return (
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 12,
                  color: "var(--text-2)",
                }}>
                {isLoadingBundleDetails ? (
                  <div
                    className={props?.className}
                    style={{ marginTop: 4, marginRight: 8 }}>
                    <CircularProgress color="inherit" size={16} />
                  </div>
                ) : (
                  <IconButton size="small" className={props?.className}>
                    <CaretDown size={16} />
                  </IconButton>
                )}
              </div>
            );
          }}>
          {bundleDetails?.data?.data?.bundle?.wallets?.map((wallet, index) => {
            if (hideChains?.includes(wallet.chain_id)) {
              return null;
            }
            return (
              <MenuItem value={wallet.address} key={wallet.address}>
                <CustomRow
                  alignItems="center"
                  gap="4px"
                  padding="0 4px 0 0"
                  width="100%">
                  <WalletPrivacy wallet={wallet} size={16} />
                  <AddressComponent
                    textClassName={classes.addressText}
                    index={index + 1}
                    address={wallet?.address}
                    showTrimmed
                  />
                  <WalletVerifiedBadge wallet={wallet} size={16} />
                  <FlexGrow />
                  <CustomText
                    text={
                      wallet?.net_worth?._Any?.total_usd_value?.display_value
                    }
                  />
                </CustomRow>
              </MenuItem>
            );
          })}
        </Select>
      )}
      {isExtraDetailsOpen && (
        <CustomRow gap="16px">
          <FollowEditButton
            minHeight="42px"
            onUpdateCallback={(data) => {
              if (data) {
                setValue({ type: data.type, data: data });
                setNewAddedAddress(toAddress);
              }
              onUpdateCallback?.(data);
            }}
            address={toAddress}>
            <CustomButton
              text="Add User Details"
              className={classes.customBtn}></CustomButton>
          </FollowEditButton>
        </CustomRow>
      )}
    </CustomColumn>
  );
};

export default PersonSearch;
