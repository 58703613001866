import CustomLinkTabBar from "components/UI/Components/CustomLinkTabBar";
import React, { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TOKEN_PAGE_SWAP_TABS } from "../token_page_utils";
import { PaddingComponent } from "components/UI/Components/Components";
import TopUsers from "components/V2/Token/TopUsers/TopUsers";
import TokenPageTransfersTable from "../TokenPageTransfersTable/TokenPageTransfersTable";
import LoginCheckWrapper from "components/core/LoginCheckWrapper";
import { TokenPageContext } from "../TokenPageContext";
import useQueryParams from "customHooks/useQueryParams";

const TokenPageSwapTabs = ({
  contractAddress,
  chainId,
  activeTab,
  setActiveTab,
  isSolana,
}) => {
  const { recentFilter, updateFilter } = useContext(TokenPageContext);
  const excludeTabs = isSolana ? ["top-buyers", "top-sellers"] : [];
  const finalTabs = TOKEN_PAGE_SWAP_TABS.filter(
    (t) => !excludeTabs.includes(t.key)
  );
  const queryParams = useQueryParams();
  const moveToTab = queryParams.get("table_tab");
  const history = useHistory();
  const renderTabContent = useCallback(() => {
    const tabKey = finalTabs[activeTab]?.key;
    const tabTitle = finalTabs[activeTab]?.title;
    if (!tabKey) return null;
    switch (tabKey) {
      case "recent_swaps":
        return (
          <TokenPageTransfersTable
            contractAddress={contractAddress}
            chainId={chainId}
            type="recent"
            key="recent_swaps"
          />
        );
      case "follower_swaps":
        return (
          <LoginCheckWrapper
            title="Login to see this content"
            subtitle="See what your favorite degens are doing onchain">
            <TokenPageTransfersTable
              contractAddress={contractAddress}
              chainId={chainId}
              type="follower"
              key="follower_swaps"
            />
          </LoginCheckWrapper>
        );
      case "top-holders":
      case "top-buyers":
      case "top-sellers":
        return (
          <TopUsers
            address={contractAddress}
            chainId={chainId}
            borderPosition="bottom"
            showTabBar={false}
            customTab={tabTitle}
            giveTopPadding={true}
            size={"REGULAR"}
            noPadding={true}
            showFilter={true}
            filter={recentFilter}
            updateFilter={(row) => {
              updateFilter(row, "recent");
              setActiveTab(0);
            }}
          />
        );
      default:
        return null;
    }
  }, [
    chainId,
    contractAddress,
    activeTab,
    recentFilter,
    updateFilter,
    setActiveTab,
    finalTabs,
  ]);
  useEffect(() => {
    if (moveToTab === "follower_swaps" && activeTab !== 1) {
      const stringParams = new URLSearchParams(window.location.search);
      // remove the table_tab query param
      stringParams.delete("table_tab");
      history.replace(
        `${history.location.pathname}${stringParams.toString()?.length ? `?${stringParams.toString()}` : ""}`
      );
      setActiveTab(1);
      // move the scroll down after the tab is changed and after 300ms
      setTimeout(() => {
        window.scrollTo({
          top: window.scrollY + 700,
          behavior: "smooth",
        });
      }, 800);
    }
  }, [moveToTab, setActiveTab, activeTab, history]);
  return (
    <PaddingComponent padding="0 16px">
      <CustomLinkTabBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsList={finalTabs.map((t) => t.title)}
        isLinkTabBar={false}
      />
      <PaddingComponent padding="16px 0">{renderTabContent()}</PaddingComponent>
    </PaddingComponent>
  );
};

export default TokenPageSwapTabs;
