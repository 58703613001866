import classes from "./SocialLogins.module.css";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  SpinnerComponent,
} from "components/UI/Components/Components";
import { XLogo } from "@phosphor-icons/react";
import { useContext, useState } from "react";
import { trackEvent } from "utils/event_tracking";
import { signedApiOtherRequest } from "api/api";
import { GlobalContext } from "contextStates/Global";
import styles from "./SocialLogins.module.css";
import { SealCheck } from "@phosphor-icons/react";
import { ArrowSquareOut } from "@phosphor-icons/react";
import { SocialFollowers } from "./SocialLogins";
import ZxText from "zxComponents/common/ZxText/ZxText";
// eslint-disable-next-line no-unused-vars
let timer;
const TwitterButton = ({
  isConnected,
  disabled,
  follows = [],
  identities = {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const handleTwitterAuth = () => {
    setIsLoading(true);
    return signedApiOtherRequest({
      method: "get",
      path: "twitter/request_login",
    })
      .then((data) => {
        trackEvent("connect_twitter_btn_redirected");
        window.location.href = data.data.redirect_url;
      })
      .catch((err) => {
        trackEvent("ob_connect_socials_twitter_request_login_failed");
        handleErrorSnackbar(err, "Failed to Authorize.");
      })
      .finally(() => {
        timer = setTimeout(() => {
          setIsLoading(false);
        }, 10000);
      });
  };
  const isDisabled = disabled || isLoading || isConnected;

  return (
    <CustomColumn
      className={`${styles.row} ${styles.twitterRow} ${isConnected && !isLoading ? styles.connected : ``}`}>
      <CustomRow
        className={`${classes.container} ${isDisabled ? classes.disabled : ""}`}
        onClick={() => {
          if (!isDisabled) {
            trackEvent("ob_connect_socials_twitter_click");
            handleTwitterAuth();
          }
        }}
        disabled={isDisabled}
        justifyContent="space-between"
        alignItems="center">
        <CustomRow gap="16px" alignItems="center">
          <XLogo
            size={20}
            color={!isConnected ? "var(--base)" : "var(--text-1)"}
          />
          <CustomText
            text={!isConnected ? "Connect Twitter" : "Twitter Connected"}
            color={!isConnected ? "var(--base)" : "var(--text-1)"}
            fontWeight="500"
            fontSize="15px"
            lineHeight="22px"
          />
        </CustomRow>
        <CustomRow alignItems="flex-end" gap="8px">
          {!isConnected && follows?.length > 0 && (
            <ZxText color="white">{follows.length} connections found</ZxText>
          )}
          {!isConnected ? (
            isLoading ? (
              <SpinnerComponent color={"var(--base)"} size={20} />
            ) : (
              <ArrowSquareOut size={20} color="var(--base)" />
            )
          ) : (
            <SealCheck color="var(--success)" weight="fill" size={20} />
          )}
        </CustomRow>
      </CustomRow>
      {isConnected && !isLoading && (
        <SocialFollowers
          profileList={follows}
          identities={identities}
          title="Twitter"
        />
      )}
    </CustomColumn>
  );
};

export default TwitterButton;
