import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CustomTextField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    outline: "none",
    border: "none",
  },
  "& .MuiInputLabel-outlined": {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    letterSpacing: "-0.02em",
    fontSize: "13px",
    paddingLeft: "10px",
    outline: "none",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(5px, -9px) scale(0.9)",
  },
  "& ": {
    outline: "none",
    border: "0",
  },
  "& label": {
    color: "#687684",
    paddingLeft: "10px",
    "& Mui-focused": {
      color: "#687684",
    },
  },
  "& input": {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "150%",
    paddingLeft: 0,
    color: "var(--text-1)",
    border: "0px solid transparent",
    outline: "none",
  },
  "& .MuiInputBase-root:before, .MuiInputBase-root:after": {
    content: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "0px solid transparent",
  },
  "& .Mui-focused.MuiOutlinedInput-notchedOutline": {
    border: "0px solid transparent",
  },
  backgroundColor: "var(--base)",
  borderRadius: "4px",
}));

export default CustomTextField;
