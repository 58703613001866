import React from "react";
import styles from "./TokenFlags.module.css";
import { CaretDown, CaretUp, FlagPennant } from "@phosphor-icons/react";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import { CheckCircle, XCircle } from "@phosphor-icons/react";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
const TokenFlags = ({ type = "green", isOpen, toggle, list, profiles }) => {
  const iconColor = type === "green" ? "var(--success)" : "var(--error)";
  return (
    <div className={styles.container}>
      <div
        className={`${styles.header} ${styles[type]} ${
          isOpen ? styles.open : ""
        }`}
        onClick={toggle}>
        <CustomRow justifyContent="space-between" alignItems="center">
          <CustomRow gap="8px" alignItems="center">
            <FlagPennant size="16" color={iconColor} weight="fill" />
            <span>
              {" "}
              {list.length} {type === "green" ? "Green" : "Red"} Flag
              {list.length > 1 ? "s" : ""}
            </span>
          </CustomRow>
          {isOpen ? (
            <CaretUp size="16" color="var(--text-1)" />
          ) : (
            <CaretDown size="16" color="var(--text-1)" />
          )}
        </CustomRow>
      </div>
      {/* content */}
      {isOpen && (
        <PaddingComponent className={styles.content} padding="12px 16px">
          <CustomColumn gap="12px">
            {isOpen &&
              list.length > 0 &&
              list.map((flag, index) => (
                <CustomRow key={index} gap="4px" alignItems="flex-start">
                  <PaddingComponent width={24} paddingTop="4px">
                    {type === "green" ? (
                      <CheckCircle
                        size="16"
                        color="var(--success)"
                        weight="fill"
                      />
                    ) : (
                      <XCircle size="16" color="var(--error)" weight="fill" />
                    )}
                  </PaddingComponent>
                  <FeedDescription
                    templateString={flag}
                    profiles={profiles}
                    fontSize="14px"
                  />
                </CustomRow>
              ))}
          </CustomColumn>
        </PaddingComponent>
      )}
    </div>
  );
};

export default TokenFlags;
