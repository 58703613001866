import React from "react";
import {
  CustomText,
  Divider,
  PaddingComponent,
} from "../Components/Components";

import styles from "./TokenDetailsSideBar.module.css";
import Copyable from "components/ValueType/Copyable/Copyable";
import TokenTwitterPill from "../TokenTwitterPill/TokenTwitterPill";

const TokenDetailsSideBar = ({
  list,
  title = "Token Details",
  symbol,
  isNft,
}) => {
  const twitterItem = list.find((item) => item.key === "Search on Twitter");
  return (
    <PaddingComponent className={styles["container"]}>
      <CustomText
        text={title}
        fontSize="14px"
        fontWeight="600"
        lineHeight="21px"
        color="var(--text-1)"
        className={styles["title"]}
        letterSpacing="-0.02em"
      />

      <div className={styles["list-container"]}>
        <Divider />
        {list.map((item, index) => {
          const { key, value, display_value: displayValue, copiable } = item;
          const isTwitter = key === "Search on Twitter";
          if (isTwitter || (isNft && key === "Total supply")) {
            return null;
          }
          return (
            <PaddingComponent
              key={index}
              padding="16px 0"
              className={styles["list-item"]}>
              <CustomText
                text={key}
                lineHeight="18px"
                fontSize="12px"
                fontWeight="500"
                color="var(--text-2)"
              />
              {!copiable ? (
                <CustomText
                  text={displayValue}
                  lineHeight="19.5px"
                  fontSize="13px"
                  color="var(--text-1)"
                />
              ) : (
                <Copyable content={value}>
                  <CustomText
                    text={displayValue}
                    lineHeight="19.5px"
                    fontSize="13px"
                    color="var(--text-1)"
                  />
                </Copyable>
              )}
            </PaddingComponent>
          );
        })}
        {twitterItem && (
          <PaddingComponent padding="16px 0">
            <TokenTwitterPill symbol={symbol} link={twitterItem?.value} />
          </PaddingComponent>
        )}
      </div>
    </PaddingComponent>
  );
};

export default TokenDetailsSideBar;
