import {
  CustomColumn,
  CustomRow,
  SpinnerComponent,
} from "components/UI/Components/Components";
import React, { useCallback, useContext, useState } from "react";
import styles from "./WalletLoginPopup.module.css";
import { Wallet, CaretRight } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import ZxText from "zxComponents/common/ZxText/ZxText";
import Image from "components/UI/Image";
import FarcasterSignInButton from "zxComponents/FarcasterSignInButton/FarcasterSignInButton";
import { signWithFarcaster } from "contextStates/auth_utils";
import { AuthContext } from "contextStates/AuthContext";
import DynamicWalletButton from "components/DynamicWalletButton/DynamicWalletButton";
import { TSTYLES } from "zxStyles/constants";
import { trackEvent } from "utils/event_tracking";
const STATUS = {
  CONNECTED: "CONNECTED",
  SIGNED: "SIGNED",
};
// types = login, signup
const WalletLoginPopup = ({ type = "login", onClose }) => {
  const history = useHistory();
  const { isUserAuthenticated } = useContext(AuthContext);
  const [status, setStatus] = useState({
    wallet: null,
    farcaster: null,
  });

  const onConnect = useCallback((type) => {
    setStatus((prev) => ({ ...prev, [type]: STATUS.CONNECTED }));
  }, []);

  const onFinish = useCallback((type) => {
    setStatus((prev) => ({ ...prev, [type]: null }));
  }, []);

  const WalletPopupRow = () => {
    return (
      <CustomRow
        alignItems="center"
        className={styles.popupOption}
        justifyContent="space-between">
        <CustomRow gap="8px" alignItems="center">
          <Wallet color="var(--text-2)" size={20} weight="fill" />
          <ZxText cursor="pointer">
            {type === "login" ? "Login" : "Signup"} with your wallet
          </ZxText>
        </CustomRow>
        {status.wallet === null ? (
          <CaretRight color="var(--text-3)" size={16} />
        ) : (
          <SpinnerComponent size={16} />
        )}
      </CustomRow>
    );
  };

  return (
    <CustomColumn gap="16px" padding="0 0 8px 0">
      <ZxText style={TSTYLES.title1}>
        {type === "login" ? "Welcome Back!" : "Get started now!"}
      </ZxText>
      <CustomColumn gap="12px">
        {type === "login" ? (
          <DynamicWalletButton
            isOnboarding
            isLogin
            redirect
            customRender={WalletPopupRow}
            onClickEventName="login_with_wallet_button_click"
          />
        ) : (
          <div
            onClick={() => {
              trackEvent("sign_up_with_wallet_button_click");
              history.push("/onboarding/connect_wallet");
            }}>
            <WalletPopupRow />
          </div>
        )}
        <FarcasterSignInButton
          isSocialConnect={false}
          isDisabled={false}
          onBeforeClick={() => {
            trackEvent(
              (type === "login" ? "login" : "sign_up") +
                "_with_farcaster_button_clicked"
            );
          }}
          isSocialConnected={false}
          onConnect={onConnect}
          onFinish={onFinish}
          onSuccess={async (data) => {
            if (!data) return;
            try {
              await signWithFarcaster({
                signature: data.signature,
                message: data.message,
                fromAddr: data.custody,
              });
              await isUserAuthenticated({
                isNavigating: true,
              });
            } catch (err) {
              console.error(err);
            } finally {
              onFinish("farcaster");
            }
          }}
          customRender={() => {
            return (
              <CustomRow
                className={styles.popupOption}
                justifyContent="space-between">
                <CustomRow gap="8px" alignItems="center">
                  <Image
                    src={"/svg/farcaster_bare.svg"}
                    alt="farcaster-icon"
                    className={styles.popupOptionIcon}
                  />
                  <ZxText cursor="pointer">
                    {type === "login" ? "Login" : "Signup"} with your farcaster
                  </ZxText>
                </CustomRow>
                {status.farcaster === null ? (
                  <CaretRight color="var(--text-3)" size={16} />
                ) : (
                  <SpinnerComponent size={16} />
                )}
              </CustomRow>
            );
          }}
        />
      </CustomColumn>
    </CustomColumn>
  );
};

export default WalletLoginPopup;
