import { useCallback, useState } from "react";
import { InteractionButton } from "./ActivityEngagements";
import NewCommentModal from "./NewCommentModal";

const CommentButton = ({
  className,
  icon,
  contentId,
  count,
  contentType,
  needsLogin,
  postAncestors,
  children,
  prefillCommentContent,
  onSuccess,
  hideCount = false,
  postDetails,
  showCommentCount,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  return (
    <div onClick={(e) => e.stopPropagation()}>
      {children != null ? (
        <div onClick={handleClick}>{children}</div>
      ) : (
        <InteractionButton
          className={className}
          handleClick={handleClick}
          icon={icon}
          count={count}
          needsLogin={needsLogin}
          hideCount={hideCount}
          text={`${showCommentCount && count > 0 ? `${count} ${count === 1 ? `comment` : `comments`}` : `Comment`} `}
        />
      )}

      <NewCommentModal
        prefillCommentContent={prefillCommentContent}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        contentId={contentId}
        postDetails={postDetails}
        type={contentType}
        postAncestors={postAncestors}
        onSuccess={onSuccess}
      />
    </div>
  );
};

export default CommentButton;
