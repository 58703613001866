import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  CustomSkeleton,
  FlexGrow,
} from "components/UI/Components/Components";
import classes from "./ProfileHeader.module.css";
import { Divider } from "components/UI/Components/Components";
import ProfileCardShimmer from "./ProfileCardShimmer";
import GraphLoader from "../ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";

const ProfileHeaderShimmer = () => {
  return (
    <div className={classes.profile_header}>
      <PaddingComponent padding="12px">
        <section className={classes.main}>
          <CustomColumn className={classes.profileInfoShimmer}>
            <CustomRow>
              <PaddingComponent paddingRight="24px">
                <CustomSkeleton variant="rounded" height={72} width={72} />
              </PaddingComponent>
              <FlexGrow />
              <CustomColumn
                justifyContent="center"
                style={{
                  width: "100%",
                }}>
                <CustomSkeleton width="120px" height={32} variant="text" />
                <CustomSkeleton width="320px" height={24} variant="text" />
              </CustomColumn>
            </CustomRow>
            <PaddingComponent height="20px" />
            <Divider />
            <PaddingComponent height="8px" />
            <CustomRow gap="8px" alignItems="center">
              <CustomSkeleton variant="circular" height={32} width={32} />
              <CustomSkeleton variant="circular" height={32} width={32} />
              <CustomSkeleton variant="circular" height={32} width={32} />
              <FlexGrow />
              <CustomSkeleton width={80} height={52} />
            </CustomRow>
            <PaddingComponent height="8px" />
            <Divider />
            <PaddingComponent height="8px" />
            <CustomSkeleton variant="text" />
            <PaddingComponent height="8px" />
            <Divider />
            <PaddingComponent height="24px" />
            <CustomRow gap="8px" alignItems="center">
              <CustomSkeleton width={80} height={52} />
              <CustomSkeleton width={80} height={52} />
            </CustomRow>
            <div className={classes.cards}>
              <ProfileCardShimmer />
              <GraphLoader height={150} />
            </div>
          </CustomColumn>
        </section>
      </PaddingComponent>
    </div>
  );
};

export default ProfileHeaderShimmer;
