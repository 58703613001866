import {
  XYPlot,
  XAxis,
  HorizontalGridLines,
  YAxis,
  LineSeries,
  Hint,
  VerticalGridLines,
  AreaSeries,
  GradientDefs,
  MarkSeries,
} from "react-vis";
import { epochToReadable } from "utils/misc";
import classes from "./Graph.module.css";
import { useMemo, useRef, useState } from "react";

import { abbreviateNumber } from "utils/misc";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";

export const getPercentileValues = (array) => {
  var n = array.length;
  var index_15 = Math.floor(n * 0.07);
  var index_50 = Math.floor(n * 0.5);
  var index_85 = Math.floor(n * 0.93);

  var value_15 = array[index_15];
  var value_50 = array[index_50];
  var value_85 = array[index_85];
  return [value_15, value_50, value_85];
};

const Graph = ({
  graphData,
  tab,
  parentWidth = 800,
  parentHeight = 350,
  isXAxisVisible = true,
  isYAxisVisible = true,
  margin = { left: 0, right: 64, bottom: 34, top: 0 },
  horizontalGridLines = 8,
  verticalGridLines = 0,
  gradientFill = false,
  hideHover = false,
  xTickFormatter = (d) => epochToReadable(d),
  showRightPrice = false,
  showCrossHair = false,
  showRightCircle = false,
  isReverse = true,
}) => {
  const [currentPoint, setCurrentPoint] = useState(null);
  const chartRef = useRef(null);
  const yValueToDisplayValue = useMemo(
    () =>
      graphData.data?.reduce((acc, curr) => {
        acc[curr.y.value] =
          curr.y.display_value ?? `$${abbreviateNumber(curr.y.value)}`;
        return acc;
      }, {}),
    [graphData.data]
  );

  if (graphData.data?.length === 0) return null;
  //if first point timestamp is smaller then reverse data
  const firstPoint = graphData.data?.[0];
  const lastPoint = graphData.data?.[graphData.data.length - 1];
  const latestPoint = (isReverse ? firstPoint.y.value : lastPoint.y.value) ?? 0;
  const oldestPoint = (isReverse ? lastPoint.y.value : firstPoint.y.value) ?? 0;
  const isNegative = latestPoint < oldestPoint;
  const color = isNegative ? "var(--error)" : "var(--success)";

  const timeSeries = graphData.data.map((data) => {
    return {
      x: data.x,
      y: data.y.value,
    };
  });
  if (!timeSeries?.length) return null;

  let minValue = Math.min(...timeSeries.map((data) => data.y));
  let maxValue = Math.max(...timeSeries.map((data) => data.y));
  // TODO(Abhinav): Equality check might because of floats. Fix when we see this.
  if (maxValue === minValue) {
    const center = (maxValue + minValue) / 2;
    minValue = center - 50;
    maxValue = center + 50;
  }
  const diff = maxValue - minValue;
  const top = (maxValue - timeSeries?.[timeSeries?.length - 1]?.y) / diff;
  const topOffsetMultiplier = 0.95;
  const topOffset = top * topOffsetMultiplier * (parentHeight - 45);
  const randomGradientId = "grad-" + Math.random() * 100;
  const lastXY = timeSeries?.[timeSeries?.length - 1];
  return (
    <div
      className={classes.container}
      ref={chartRef}
      style={{ width: parentWidth, height: parentHeight }}>
      <XYPlot
        height={parentHeight}
        width={parentWidth}
        padding={0}
        margin={{
          ...margin,
          left: isYAxisVisible ? 60 : 0,
          bottom: isXAxisVisible ? 36 : 0,
        }}
        onMouseLeave={() => {
          setTimeout(() => setCurrentPoint(null), 200);
        }}>
        {isXAxisVisible && (
          <XAxis
            hideLine={false}
            hideTicks={false}
            tickFormat={(d) => {
              return xTickFormatter(d);
            }}
            tickTotal={timeSeries?.length > 5 ? 7 : 2}
            tickSizeInner={0}
            tickSizeOuter={0}
            style={{
              ticks: {
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "10px",
                lineHeight: "130%",
                textAlign: "center",
                letterSpacing: " -0.02em",
                fill: "var(--text-3)",
                marginLeft: "50px",
                marginBottom: "30px",
              },
              line: { stroke: "var(--border-dark)" },
            }}
          />
        )}
        {isYAxisVisible && (
          <YAxis
            tickFormat={(d) => `$${abbreviateNumber(d)}`}
            tickTotal={timeSeries?.length < 3 ? 3 : 6}
            orientation="left"
            color="var(--text-2)"
            style={{
              ticks: {
                fill: "var(--text-2)",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "10px",
              },
              line: { stroke: "var(--border-dark)" },
            }}
          />
        )}
        <HorizontalGridLines
          tickTotal={horizontalGridLines}
          style={{ stroke: "var(--border-light)" }}
        />
        <VerticalGridLines
          tickTotal={verticalGridLines}
          style={{ stroke: "var(--border-light)" }}
        />

        <GradientDefs>
          <linearGradient id={randomGradientId} x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor={color} stopOpacity={0.2} />
            <stop offset="100%" stopColor={color} stopOpacity={0.01} />
          </linearGradient>
        </GradientDefs>

        <AreaSeries
          data={timeSeries}
          curve="curveBasis"
          color={gradientFill ? `url(#${randomGradientId})` : "transparent"}
        />
        <LineSeries
          data={timeSeries}
          curve="curveBasis"
          color={color}
          style={{
            stroke: color,
            fill: "none",
            strokeWidth: 2,
          }}
          onNearestX={(datapoint, event) => {
            setCurrentPoint({ datapoint, event });
          }}
        />

        {currentPoint && !hideHover && (
          <Hint value={currentPoint.datapoint}>
            <div className={classes.hover_card}>
              <div className={classes.value}>
                {yValueToDisplayValue[currentPoint.datapoint.y]}
              </div>
              <div className={classes.time}>
                {xTickFormatter(currentPoint.datapoint.x)}
              </div>
            </div>
          </Hint>
        )}
        {currentPoint && showCrossHair && (
          <MarkSeries
            data={[currentPoint.datapoint]}
            size={5}
            color={color}
            opacity={0.8}
          />
        )}
        {showRightCircle && (
          <MarkSeries data={[lastXY]} size={5} color={color} opacity={0.8} />
        )}
        {showRightPrice && (
          <div
            className="marker"
            style={{
              position: "absolute",
              left: parentWidth - 8, // Position right of the axis
              top: topOffset, // Center vertically
              backgroundColor: color,
              color: "var(--text-white)",
              padding: "4px 8px",
              borderRadius: "4px",
              fontSize: "10px",
              fontWeight: "bold",
            }}>
            {graphData?.data?.[
              graphData?.data?.length - 1
            ].y.display_value?.slice(0, 8)}
            {/* Show the last price */}
          </div>
        )}
      </XYPlot>
    </div>
  );
};

export default withErrorBoundary(Graph);
