import { getTrendingQueries } from "api/screener.api";
import classes from "./TrendingQueries.module.css";
import withLoader from "components/core/withLoader";
import { SortAscending } from "@phosphor-icons/react";
import { CustomSkeleton } from "components/UI/Components/Components";
import TrendingQueryCard from "./TrendingQueryCard";

export const TrendingQueryGhost = () => {
  return (
    <div className={classes.trending_query}>
      <div className={classes.heading}>
        <span>Recent Queries</span>
        <SortAscending size={22} weight="fill" />
      </div>
      <CustomSkeleton height={44} />
      <CustomSkeleton height={44} />
      <CustomSkeleton height={44} />
      <CustomSkeleton height={44} />
      <CustomSkeleton height={44} />
    </div>
  );
};

const TrendingQueries = ({ queryData }) => {
  return (
    <div className={classes.trending_query}>
      <div className={classes.heading}>
        <span>Recent Queries</span>
        <SortAscending size={22} weight="fill" />
      </div>
      <div className={classes.body}>
        {queryData?.data?.data.slice(0, 3).map((query) => {
          return <TrendingQueryCard query={query} />;
        })}
      </div>
    </div>
  );
};

export default withLoader(TrendingQueries, getTrendingQueries, {
  queryKey: () => ["trendingQueries"],
  containerClass: classes.trending_query,
  CustomLoader: TrendingQueryGhost,
  staleTime: 1000 * 60 * 30,
});
