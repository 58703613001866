import {
  CustomRow,
  PaddingComponent,
  CustomTextSpan,
  CustomColumn,
} from "components/UI/Components/Components";
import classes from "./TrendingPnlList.module.css";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { ChartBar } from "@phosphor-icons/react";

export const PnlItem = ({ pnl, profile }) => {
  return (
    <CustomRow alignItems="center">
      <Image
        src={profile?.display_picture}
        alt={profile?.display_name}
        className={classes.image}
        imageType={
          profile?.isToken
            ? IMAGE_TYPES.TOKEN
            : profile?.address_type === "contract"
              ? IMAGE_TYPES.CONTRACT
              : profile?.address_type === "NFT"
                ? IMAGE_TYPES.NFT
                : IMAGE_TYPES.AVATAR
        }
        name={profile?.display_name}
      />
      <PaddingComponent width="4px" />
      <CustomTextSpan fontSize="13px" fontWeight="500">
        {profile?.display_name}
      </CustomTextSpan>
      <PaddingComponent width="4px" />
      <CustomTextSpan
        fontSize="13px"
        fontWeight="500"
        color={pnl.pnl >= 0 ? "var(--success)" : "var(--error)"}>
        {pnl.pnl_display}
      </CustomTextSpan>
      <PaddingComponent width="2px" />
      <CustomTextSpan
        fontSize="13px"
        fontWeight="500"
        color={pnl.pnl >= 0 ? "var(--success)" : "var(--error)"}>
        {pnl.pnl_percent_display}
      </CustomTextSpan>
    </CustomRow>
  );
};

const TrendingPnlList = ({ profiles, pnlList }) => {
  return (
    <CustomColumn key={`trending-pnl-list`}>
      <PaddingComponent height="8px" />
      <CustomRow flexWrap="wrap" alignItems="center" gap="16px">
        <CustomRow>
          <ChartBar size={16} color="var(--text-2)" />
          <PaddingComponent width="16px" />
          <CustomTextSpan fontSize="13px">PnL:</CustomTextSpan>
        </CustomRow>
        {pnlList.map((pnl, index) => {
          const profile = profiles[pnl?.address];
          return <PnlItem pnl={pnl} profile={profile} />;
        })}
      </CustomRow>
      <PaddingComponent height="8px" />
    </CustomColumn>
  );
};

export default TrendingPnlList;
