import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { SealCheck } from "@phosphor-icons/react";

const WalletVerifiedBadge = ({ wallet, size = 14, showTooltip = true }) => {
  if (wallet?.is_verified) {
    if (showTooltip) {
      return (
        <PPLxTooltip title="Verified Wallet" arrow delay={500}>
          <SealCheck size={size} weight="fill" fill="var(--success)" />
        </PPLxTooltip>
      );
    } else return <SealCheck size={size} weight="fill" fill="var(--success)" />;
  }

  return null;
};

export default WalletVerifiedBadge;
