import React, { useEffect } from "react";
import ZxFlex from "../ZxFlex/ZxFlex";
import styles from "./ZxInput.module.scss";
import ZxText from "../ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
const ZxInput = ({
  label,
  name,
  flexProps,
  width,
  register = null,
  placeholder = `Enter ${label}`,
  isTextArea,
  disabled,
  inputSuffix = null,
  inputPrefix = null,
  value = "",
  validation = {},
  error = {},
  textAreaRows = 4,
  textAreaResize = "vertical",
  noBorderOnFocus = false,
  focusOnMount = false,
  errorTextClassName = "",
  labelWidth = "auto",
  containerWidth = "auto",
  ...others
}) => {
  const regProps = register ? { ...register(name, { ...validation }) } : {};
  const hasError = error?.message || error?.type;
  const borderProps = noBorderOnFocus ? { border: "none" } : {};
  const inputName = `zx-input-${name.split(".").join("-")}`;
  useEffect(() => {
    if (focusOnMount) {
      const input = document.querySelector(
        `#zx-input-${name.split(".").join("-")}`
      );
      if (input) {
        input.focus();
      }
    }
  }, [focusOnMount, name]);
  return (
    <ZxFlex
      align="center"
      width={containerWidth}
      {...flexProps}
      onClick={(e) => {
        const input = document.querySelector(
          `#zx-input-${name.split(".").join("-")}`
        );
        if (input) {
          input.focus();
        }
      }}>
      {label && (
        <label className={`${styles.label}`} style={{ width: labelWidth }}>
          {label}
        </label>
      )}
      <ZxFlex dir="column" gap="4px" align="flex-start">
        {!isTextArea ? (
          <ZxFlex
            className={`${styles.inputContainer} ${hasError && styles.error}`}
            width={width}
            align="center"
            justify="flex-start">
            {inputPrefix && (
              <ZxFlex
                align="center"
                justify="center"
                width="max-content"
                flex="1">
                {inputPrefix}
              </ZxFlex>
            )}
            <input
              name={name}
              id={inputName}
              placeholder={placeholder}
              className={styles.input}
              disabled={disabled}
              style={{
                minWidth: !value ? "80px" : "50px",
                width,
              }}
              {...regProps}
            />{" "}
            {inputSuffix && (
              <ZxFlex
                align="center"
                justify="center"
                width="max-content"
                flex="1">
                {inputSuffix}
              </ZxFlex>
            )}
          </ZxFlex>
        ) : (
          <textarea
            disabled={disabled}
            name={name}
            className={`${styles.independentTextArea} ${hasError && styles.error}`}
            placeholder={placeholder}
            id={inputName}
            style={{
              maxWidth: width,
              width,
              minWidth: width,
              resize: textAreaResize,
              ...borderProps,
            }}
            rows={textAreaRows}
            {...regProps}
          />
        )}
        {hasError && (
          <div className={errorTextClassName}>
            <ZxText style={TSTYLES.body3} fontSize="11px" color="error">
              {error?.message}
            </ZxText>
          </div>
        )}
      </ZxFlex>
    </ZxFlex>
  );
};

export default ZxInput;
