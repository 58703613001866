import GenericButton from "components/V2/GenericButton/GenericButton";
import React from "react";

const ZxInputTextButton = ({ children, ...rest }) => {
  return (
    <GenericButton
      color="var(--primary-color)"
      padding="0"
      background="transparent"
      borderRadius="0"
      {...rest}>
      {children}
    </GenericButton>
  );
};

export default ZxInputTextButton;
