import React from "react";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import { IMAGE_TYPES } from "components/UI/Image";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { TimeLabel } from "components/FeedItemV2/ActivityHeader";
import ZxDot from "zxComponents/common/ZxDot";
import ZxCopy from "zxComponents/common/ZxCopy/ZxCopy";
import { getTrimedAddress } from "utils/misc";
import WatchlistTokenButton from "components/V2/Watchlist/WatchlistTokenButton";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";
const ZxTokenHeader = ({ token, deployedAt }) => {
  if (!token) return null;
  return (
    <ZxFlex justify="space-between">
      {/* INFO */}
      <ZxFlex gap="4px">
        <IconWithChain
          src={token?.logo}
          imageType={IMAGE_TYPES.TOKEN}
          chain={token?.profile?.address_chain}
          iconWidth="32px"
          iconHeight="32px"
          chainHeight="16px"
          chainWidth="16px"
          showImageFullHeightWidth
        />
        <ZxFlex dir="column" align="flex-start" gap="0">
          <ZxFlex gap="8px">
            <ZxText style={TSTYLES.title1} fontSize="16px">
              {token?.symbol}
            </ZxText>
            <ZxCopy
              isButton={false}
              value={token?.profile?.address}
              title="Copy contract address"
              buttonText={getTrimedAddress(token?.profile?.address)}
            />
            <ZxDot />
            {deployedAt && (
              <TimeLabel
                timestamp={deployedAt}
                fontSize="12px"
                color="var(--text-2)"
              />
            )}
          </ZxFlex>
          <ZxText color="text-2" fontSize="12px">
            {token?.profile?.display_name}
          </ZxText>
        </ZxFlex>
      </ZxFlex>

      {/* Links and copy */}
      <ZxFlex gap="8px">
        {/* <ZxCopy
          isButton
          buttonText={getTrimedAddress(token?.profile?.address)}
          value={token?.profile?.address}
        /> */}
        <WatchlistTokenButton
          isWatchlisted={token?.is_watchlisted}
          token={token}
          newStyle
          chainId={token?.profile?.address_chain}
          tokenAddress={token?.profile?.address}
        />
        <ExternalLinks links={token?.external_links ?? {}} />
      </ZxFlex>
    </ZxFlex>
  );
};

export default ZxTokenHeader;
