import {
  CustomColumn,
  CustomRow,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import React, { useState } from "react";
import styles from "./DownloadAppBanner.module.css";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import GenericButton from "../GenericButton/GenericButton";
import {
  QrCode,
  DownloadSimple,
  Sparkle,
  DeviceMobile,
} from "@phosphor-icons/react";
import Popup from "components/UI/Popup/Popup";
import QRCodeLogin from "components/UI/QrCode/QrCode";
import { DOWNLOAD_APP_DEEPLINK } from "utils/constants";
import ZxText from "../../../zxComponents/common/ZxText";
import { TSTYLES } from "../../../zxStyles/constants";
import appleStoreButtonImage from "assets/png/onboarding/download_apple_store.png";
import googlePlayStoreButtonImage from "assets/png/onboarding/download_google_play.png";
import { trackEvent } from "../../../utils/event_tracking";
import useScreenRes from "customHooks/useMediaQuery";
const DOWNLOAD_STEPS = [
  {
    Icon: DeviceMobile,
    text: "Open your phone camera",
  },
  {
    Icon: QrCode,
    text: "Scan the above QR code",
  },
  {
    Icon: DownloadSimple,
    text: "Download the iOS/Android app",
  },
];
const DownloadAppBanner = ({ variant = "default" }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = () => {
    if (isPopupOpen) {
      trackEvent("download_app_modal_dismissed");
    } else {
      trackEvent("download_app_banner_btn_clicked");
    }

    setIsPopupOpen((old) => !old);
  };
  const { isMobile } = useScreenRes();
  const PopupWrapper = ({ children }) => {
    return isMobile ? (
      <CustomColumn className={styles.modal}>{children}</CustomColumn>
    ) : (
      <CustomRow
        alignItems="center"
        justifyContent="space-between"
        gap="20px"
        className={styles.modal}>
        {children}
      </CustomRow>
    );
  };
  return (
    <PaddingComponent
      className={
        variant === "default" ? styles.container : styles.containerFeed
      }>
      {isPopupOpen && (
        <Popup
          onClose={togglePopup}
          title="Download the 0xPPL mobile app"
          titleAlign="left"
          titleFontSize="15px"
          isDivider
          verticalPadding="2px"
          horizontalPadding="16px"
          width={isMobile ? "80%" : "600px"}>
          <PopupWrapper>
            <CustomColumn gap="10px">
              <ZxText style={TSTYLES.body3}>NOW AVAILABLE ON</ZxText>
              <CustomRow gap="20px">
                <a
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    trackEvent("download_app_modal_app_store_btn_clicked");
                  }}
                  href="https://apps.apple.com/in/app/0xppl/id1658303976">
                  <img
                    src={appleStoreButtonImage}
                    style={{ height: 36 }}
                    alt="Download on the App Store"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    trackEvent("download_app_modal_play_store_btn_clicked");
                  }}
                  href="https://play.google.com/store/apps/details?id=com.ppl.zeroxppl">
                  <img
                    style={{ height: 36 }}
                    src={googlePlayStoreButtonImage}
                    alt="Get it on Google Play store"
                  />
                </a>
              </CustomRow>
              {isMobile && (
                <div className={styles.qrCodeContainer}>
                  <QRCodeLogin size={200} deeplinkUrl={DOWNLOAD_APP_DEEPLINK} />
                </div>
              )}
              <ZxText style={TSTYLES.body3}>STEPS TO DOWNLOAD</ZxText>
              <CustomColumn className={styles.stepContainer} gap="20px">
                {DOWNLOAD_STEPS.map(({ Icon, text }, index) => {
                  return (
                    <CustomRow key={index} gap="8px" alignItems="center">
                      <Icon size={24} color="var(--text-1)" weight="duotone" />
                      <CustomText
                        text={text}
                        textAlign={"left"}
                        fontSize="14px"
                        color="var(--text-1)"
                      />
                    </CustomRow>
                  );
                })}
              </CustomColumn>
            </CustomColumn>
            {!isMobile && (
              <div className={styles.qrCodeContainer}>
                <QRCodeLogin size={200} deeplinkUrl={DOWNLOAD_APP_DEEPLINK} />
              </div>
            )}
          </PopupWrapper>
        </Popup>
      )}
      <PaddingComponent className={styles.content}>
        <div className={styles.textContainer}>
          <CustomRow
            gap="10px"
            alignItems="center"
            className={styles.textWrapper}>
            <Sparkle weight="fill" color="var(--warning)" />
            <p className={styles.text}>
              Download the{" "}
              <span className={styles.textBold}>0xPPL mobile app.</span>{" "}
              {!isMobile &&
                `Now
              available on`}
            </p>
            <Image
              className={styles.appleLogo}
              src="/apple_logo.svg"
              imageType={IMAGE_TYPES.NO_FALLBACK}
              alt="Apple Logo"
            />
            <Image
              className={styles.playStoreLogo}
              src="/play_store_logo.svg"
              imageType={IMAGE_TYPES.NO_FALLBACK}
              alt="Apple Logo"
            />
          </CustomRow>
        </div>
        {variant === "default" ? (
          <GenericButton
            customClass={styles.button}
            borderRadius="12px"
            background="var(--primary-color)"
            onClick={togglePopup}
            disabled={false}>
            <CustomRow gap="8px" alignItems="center">
              <QrCode size={20} color="#fff" />
              <CustomText
                text="Download mobile app"
                color="#fff"
                fontWeight="600"
                fontSize="17px"
                letterSpacing="-0.2px"
                lineHeight="26px"
              />
            </CustomRow>
          </GenericButton>
        ) : (
          <button onClick={togglePopup} className={styles.button}>
            Download App
          </button>
        )}
      </PaddingComponent>
    </PaddingComponent>
  );
};

export default DownloadAppBanner;
