import React, { useContext, useState } from "react";
import { Channel, ChannelList, Chat } from "stream-chat-react";

import "@stream-io/stream-chat-css/dist/css/index.css";
import "./MessagingApp.css";

import MessagingChannelList from "./components/MessagingChannelList/MessagingChannelList";
import MessagingInput from "./components/MessagingInput/MessagingInput";
import MessagingThreadHeader from "./components/MessagingThread/MessagingThread";
import CustomMessage from "./components/CustomMessage/CustomMessage";
import MessagingChannelPreview from "./components/MessagingChannelPreview/MessagingChannelPreview";

import { ChannelInner } from "./components/ChannelInner/ChannelInner";
import { useParams } from "react-router-dom";
import { PaddingComponent } from "components/UI/Components/Components";
import { AuthContext } from "contextStates/AuthContext";
import MessagingTabs from "./components/MessagingTabs/MessagingTabs";
import { MessagingContext } from "contextStates/MessagingContext";
import EmptyStateIndicator from "./components/EmptyStateIndicator/EmptyStateIndicator";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { CreateGroupPanel } from "./components/CreateGroupPanel/CreateGroupPanel";
import { createPortal } from "react-dom";
import RightPanel from "../../Pages/AppModule/RightPanel/RightPanel";
import { GlobalContext } from "../../../contextStates/Global";
import {
  ChannelTypes,
  setGroupChannelTypes,
  tabToChannelTypesMap,
} from "./helpers";
import LoadMoreChannels from "./components/LoadMoreChannels";

const options = { state: true, watch: true, presence: true, limit: 30 };

const sort = {
  last_message_at: -1,
  updated_at: -1,
};

export const GiphyContext = React.createContext({});

const App = () => {
  const { active_tab, channel_id } = useParams();
  const [giphyState, setGiphyState] = useState(false);
  const [isMobileNavVisible, setMobileNav] = useState(false);
  const [offset, setOffset] = useState(30);
  const { chatClient, showAddGroupPanel, setShowAddGroupPanel } =
    useContext(MessagingContext);
  const { identityDetails } = useContext(AuthContext);
  const { featureFlags } = useContext(GlobalContext);
  if (featureFlags?.show_streaming_groups) {
    setGroupChannelTypes([ChannelTypes.GROUP, ChannelTypes.STREAMING_GROUP]);
  }
  const identity = identityDetails.current?.identity;

  const currentStep = active_tab || "all";

  const filters = {
    type: "messaging",
    members: { $in: [identity?.stream_chat_details?.id] },
    channel_type: { $in: tabToChannelTypesMap(currentStep) },
    hidden: { $eq: currentStep === "blocked" },
    ...(currentStep !== "blocked" ? {} : {}),
    joined: { $eq: true },
  };
  if (currentStep === "requests") {
    filters.joined = { $eq: false };
  }

  const toggleMobile = () => setMobileNav(!isMobileNavVisible);

  const giphyContextValue = { giphyState, setGiphyState };

  const onRightPanelClose = () => {
    setShowAddGroupPanel(false);
  };
  const loadMoreChannels = async () => {
    try {
      const channels = await chatClient.queryChannels(filters, sort, {
        offset,
        limit: 30,
      });
      if (channels.length > 0) {
        setOffset((prevOffset) => prevOffset + channels.length);
      } else {
        console.log("No more channels to load.");
      }
    } catch (error) {
      console.error("Error loading more channels:", error);
    }
  };
  if (!chatClient) return null;
  if (!chatClient.user) return null;
  return (
    <BaseWrapper mixpanelEvent="MessagingApp">
      <div className="messagingAppContainer">
        {showAddGroupPanel &&
          createPortal(
            <RightPanel onClose={onRightPanelClose} depth={1000}>
              <Chat client={chatClient}>
                <CreateGroupPanel onClose={onRightPanelClose} />
              </Chat>
            </RightPanel>,
            document.getElementById("rightPanel")
          )}
        <Chat client={chatClient} theme={`messaging`}>
          <PaddingComponent paddingTop="12px">
            <MessagingTabs />
          </PaddingComponent>
          <ChannelList
            filters={filters}
            sort={sort}
            Paginator={LoadMoreChannels}
            loadMore={loadMoreChannels}
            options={options}
            Preview={MessagingChannelPreview}
            EmptyStateIndicator={EmptyStateIndicator}
            customActiveChannel={
              Number.isInteger(parseInt(channel_id)) ? null : channel_id
            }
            // EmptyStateIndicator
            List={(props) => (
              <MessagingChannelList {...props} type={currentStep} />
            )}
          />
          <Channel
            Input={MessagingInput}
            maxNumberOfFiles={10}
            Message={CustomMessage}
            multipleUploads={true}
            ThreadHeader={MessagingThreadHeader}
            TypingIndicator={() => null}>
            <GiphyContext.Provider value={giphyContextValue}>
              <ChannelInner toggleMobile={toggleMobile} />
            </GiphyContext.Provider>
          </Channel>
        </Chat>
      </div>
    </BaseWrapper>
  );
};

export default App;
