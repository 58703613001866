import React from "react";
import { CustomColumn, CustomRow } from "components/UI/Components/Components";
import NFTPreview from "components/V2/Profile/ProfileDetails/NFTHoldings/NFTPreview";
import { NFTInfo } from "components/V2/Profile/ProfileDetails/NFTHoldings/NftItem";

const CollectionCard = ({ imageUrl, name, onClick = () => {} }) => {
  return (
    <CustomRow
      flexShrink="0"
      width="188px"
      borderRadius="10px"
      border="1px solid var(--border-dark)"
      background="var(--base)"
      height="150px"
      style={{ position: "relative", cursor: "pointer" }}
      onClick={onClick}>
      <CustomColumn
        style={{
          position: "absolute",
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
        }}>
        <NFTPreview media={imageUrl} name={name} />
        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            left: "0",
          }}>
          <NFTInfo name={name} />
        </div>
      </CustomColumn>
    </CustomRow>
  );
};

export default CollectionCard;
