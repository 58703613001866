export const ONBOARDING_STATES = [
  "connect_wallet",
  "connect_socials",
  "complete_profile",
  "add_invite_code",
  "follow_notables",
  "follow_friends",
  "tweet_abt_0xppl",
  "download_app",
  "wallet_checklist",
  "complete",
];

export const BoldContentParser = ({ text }) => {
  const regex = /{{\s*bold\s*\|\|([^}]+)}}/g;
  let match;
  const elements = [];
  let lastIndex = 0;

  while ((match = regex.exec(text)) !== null) {
    const [fullMatch, boldText] = match;
    const startIndex = match.index;

    // Add any text before the bold match
    if (startIndex > lastIndex) {
      elements.push(
        <span key={lastIndex}>{text.slice(lastIndex, startIndex)}</span>
      );
    }

    // Add the bold text
    elements.push(<strong key={startIndex}>{boldText.trim()}</strong>);

    lastIndex = startIndex + fullMatch.length;
  }

  // Add any remaining text after the last match
  if (lastIndex < text.length) {
    elements.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>);
  }

  return elements;
};
