import classes from "./RightBar.module.css";
import {
  CustomColumn,
  CustomRow,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import HomeCarousel from "./components/HomeCarousel";
import MyHoldings from "./components/MyHoldings";
import TotalNetworth from "./components/TotalNetworth";
import Watchlist from "./components/Watchlist";
import { useLocation, useParams } from "react-router-dom";
import { AuthContext } from "contextStates/AuthContext";
import React, { useContext } from "react";
import { GlobalContext } from "contextStates/Global";
import {
  MutualHoldingsCard,
  ReferralCard,
} from "./components/ProfileRightCards";
import { isProfilePage } from "../../../../utils/misc";
import withLoader from "../../../core/withLoader";
import { getProfileData } from "../../../../api/profile.api";
import { TopPnlOverview } from "zxComponents/feed/TopPnl/TopPnl";
import { TopNFTHoldings } from "components/V2/Profile/ProfileDetails/NFTHoldings/NFTHoldings";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { ONE_DAY } from "utils/constants";

const RightBar = ({ rightBarRef }) => {
  const location = useLocation();
  const path = location.pathname;
  const isProfile = isProfilePage(path);
  const { isUserLoggedIn } = useContext(AuthContext);
  const { featureFlags } = useContext(GlobalContext);

  return (
    <div className={classes.rightBarContainer} ref={rightBarRef}>
      <PaddingComponent padding="16px 0px" width="300px">
        <CustomColumn gap="12px">
          {!isProfile && isUserLoggedIn && <TotalNetworth />}
          {!isProfile && isUserLoggedIn && <MyHoldings />}
          {!isProfile &&
            isUserLoggedIn &&
            featureFlags.is_watchlist_enabled && <Watchlist />}
          {isUserLoggedIn && <HomeCarousel />}
        </CustomColumn>
      </PaddingComponent>
    </div>
  );
};

export const ProfileRightBar = ({ rightBarRef }) => {
  const params = useParams();
  return (
    <ProfileRightBarWithLoader
      identifier={params.identifier}
      rightBarRef={rightBarRef}
    />
  );
};

export const WalletAccessRightBar = React.memo(({ rightBarRef }) => {
  const STEPS = [
    {
      title: "Select wallets to share",
      description:
        "Choose which private wallets you’d like to share with your friends.",
    },
    {
      title: "Send a request",
      description:
        "Send a request to your friend. Your friend must share at least one private wallet to accept.",
    },
    {
      title: "View each other's wallets",
      description:
        "Upon acceptance, you can see  your friend’s private wallets and vice versa.",
    },
  ];
  return (
    <div
      className={`${classes.rightBarContainer} ${classes.walletBarContainer}`}
      ref={rightBarRef}>
      <ZxText style={TSTYLES.title2}>How it works</ZxText>
      <Divider margin="12px 0" />
      <CustomColumn>
        {STEPS.map((step, index) => {
          const isLast = index === STEPS.length - 1;
          return (
            <CustomColumn key={index}>
              <CustomRow className={classes.walletBarStep}>
                <div className={classes.walletBarStepNumber}>{index + 1}</div>
                <CustomColumn gap="8px" className={classes.walletBarStepText}>
                  <ZxText style={TSTYLES.title2}>{step.title}</ZxText>
                  <ZxText style={TSTYLES.body1} color="text-2">
                    {step.description}
                  </ZxText>
                </CustomColumn>
              </CustomRow>
              {!isLast && <Divider margin="16px 0" />}
            </CustomColumn>
          );
        })}
      </CustomColumn>
    </div>
  );
});

export const ProfileRightBarComponent = ({ rightBarRef, queryData }) => {
  const { isUserLoggedIn, identityDetails } = useContext(AuthContext);
  const params = useParams();
  const identifier = params.identifier;
  const identityId =
    queryData?.data?.data?.details?.id ??
    queryData?.data?.data?.details?.identity_id;
  const viewingProfile = queryData?.data?.data?.details;
  const viewerProfile = identityDetails?.current?.identity;
  const isLoggedInUser = identityDetails?.current?.identity?.id === identityId;
  return (
    <div className={classes.rightBarContainer} ref={rightBarRef}>
      <PaddingComponent padding="16px 0px" width="300px">
        <CustomColumn gap="12px">
          <TopPnlOverview identity={identityId} />
          <TopNFTHoldings identifier={identifier} />
          {isUserLoggedIn && !isLoggedInUser && (
            <MutualHoldingsCard
              identifier={identifier}
              viewerProfile={viewerProfile}
              viewingProfile={viewingProfile}
              footerLink={`/${identifier}/portfolio`}
            />
          )}
          {isUserLoggedIn && <ReferralCard identifier={identityId} />}

          {/* {isUserLoggedIn && <SimilarProfilesCard identifier={identifier} />} */}
          {isUserLoggedIn && <HomeCarousel />}
        </CustomColumn>
      </PaddingComponent>
    </div>
  );
};

export const ProfileRightBarWithLoader = withLoader(
  ProfileRightBarComponent,
  getProfileData,
  {
    queryKey: (props) => ["profile", props.identifier],
    queryOptions: () => {
      return {
        staleTime: ONE_DAY,
      };
    },
    CustomLoader: () => null,
    renderNotFound: false,
    showRefetch: false,
  }
);

export default RightBar;
