import { PaddingComponent } from "components/UI/Components/Components";
import React, { useEffect } from "react";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import styles from "./ZxQuotedMessagePreview.module.css";
import { X } from "../../../../../../node_modules/@phosphor-icons/react/dist/index";
const ZxQuotedMessagePreview = ({ message }) => {
  const [reply, setReply] = React.useState(message);

  const clearMessage = () => {
    setReply(null);
  };
  useEffect(() => {
    setReply(message);
  }, [message]);
  if (!message || !message.html || !reply) {
    return null;
  }
  return (
    <PaddingComponent
      padding="8px"
      borderRadius="4px"
      onClick={clearMessage}
      className={styles.container}
      backgroundColor="var(--elevation-1)">
      <ZxFlex justify="space-between">
        <ZxFlex dir="column" align="flex-start">
          <ZxText style={TSTYLES.bodyLabel} color="text-2">
            {message?.user?.name}
          </ZxText>
          <p
            className={styles.message}
            dangerouslySetInnerHTML={{
              __html: message?.html,
            }}></p>
        </ZxFlex>
        <X color="var(--text-2)" size={16} weight="bold" />
      </ZxFlex>
    </PaddingComponent>
  );
};

export default ZxQuotedMessagePreview;
