import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  CustomSkeleton,
} from "components/UI/Components/Components";

const SearchGhostItem = () => {
  return (
    <PaddingComponent paddingBottom="16px">
      <CustomRow>
        <PaddingComponent
          paddingRight="16px"
          paddingLeft="16px"
          paddingTop="8px">
          <CustomSkeleton variant="circular" height={32} width={32} />
        </PaddingComponent>
        <CustomColumn
          justifyContent="stretch"
          style={{
            width: "100%",
          }}>
          <CustomSkeleton width="80px" variant="text" />
          <CustomSkeleton width="180px" variant="text" />
        </CustomColumn>
        <PaddingComponent width="16px" />
      </CustomRow>
    </PaddingComponent>
  );
};

export default SearchGhostItem;
