import { Core } from "@walletconnect/core";
import { Web3Wallet } from "@walletconnect/web3wallet";
import { wcProjectId, WCMetadata } from "utils/constants";
import { getSdkError } from "@walletconnect/utils";
import {
  getImpersonateSession,
  saveImpersonateSession,
} from "./ImpersonatorUtils";

const core = new Core({
  projectId: wcProjectId,
});

let web3wallet = null;

export const initialiseWeb3Wallet = async () => {
  web3wallet = await Web3Wallet.init({
    core,
    metadata: WCMetadata,
  });
};

export const initWeb3Wallet = async ({
  address,
  uri,
  onDisconnect,
  onConnected,
}) => {
  web3wallet = null;
  await initialiseWeb3Wallet();
  return await subscribeToEvents({
    address,
    uri,
    onDisconnect,
    onConnected,
  });
};

const subscribeToEvents = async ({
  address,
  uri,
  onDisconnect,
  onConnected,
}) => {
  web3wallet.on("session_proposal", async (proposal) => {
    try {
      if (proposal?.params == null) return;
      const { requiredNamespaces, optionalNamespaces } = proposal.params;
      const namespaceKey = "eip155";
      const requiredNamespace = requiredNamespaces[namespaceKey];
      const optionalNamespace = optionalNamespaces
        ? optionalNamespaces[namespaceKey]
        : undefined;
      let chains =
        requiredNamespace === undefined ? undefined : requiredNamespace.chains;
      if (optionalNamespace && optionalNamespace.chains) {
        if (chains) {
          // merge chains from requiredNamespace & optionalNamespace, while avoiding duplicates
          chains = Array.from(new Set(chains.concat(optionalNamespace.chains)));
        } else {
          chains = optionalNamespace.chains;
        }
      }

      const accounts = [];
      chains?.map((chain) => {
        accounts.push(`${chain}:${address}`);
        return null;
      });

      const namespace = {
        accounts,
        chains: chains,
        methods:
          requiredNamespace === undefined ? [] : requiredNamespace.methods,
        events: requiredNamespace === undefined ? [] : requiredNamespace.events,
      };
      const session = await web3wallet.approveSession({
        id: proposal.id,
        namespaces: {
          [namespaceKey]: namespace,
        },
      });
      saveImpersonateSession({ session });
      onConnected(session);
    } catch (e) {
      onConnected(null);
    }
  });
  try {
    await web3wallet.core.pairing.pair({ uri });
  } catch (e) {
    return;
  }

  web3wallet.on("session_delete", (eventData) => {
    onDisconnect({
      topic: eventData.topic,
    });
  });
};

export const updateSession = async ({
  isUpdateChain = true,
  newChainId,
  newAddress,
}) => {
  try {
    const web3WalletSession = getImpersonateSession();

    if (!web3WalletSession) {
      return [false, "Please connect to continue"];
    }
    const eventName = isUpdateChain ? "chainChanged" : "accountsChanged";
    if (web3wallet && web3WalletSession) {
      await web3wallet.emitSessionEvent({
        topic: web3WalletSession.topic,
        event: {
          name: eventName,
          data: [newAddress],
        },
        chainId: `eip155:${newChainId}`,
      });
      return [true, ""];
    }
  } catch (e) {
    return [false, e.message];
  }
};

export const killSession = async ({ topic } = {}) => {
  if (!web3wallet) return;
  const sessionTopic = topic ?? getImpersonateSession()?.topic;
  if (sessionTopic) {
    try {
      await web3wallet.disconnectSession({
        topic: sessionTopic,
        reason: getSdkError("USER_DISCONNECTED"),
      });
      web3wallet = null;
      saveImpersonateSession({ session: null });
      return [true, ""];
    } catch (e) {
      return [false, e.message];
    }
  }
  return [false, "Session doesn't exist"];
};
