import FollowEditButton from "components/FollowEditButton/FollowEditButton";
import {
  CustomProfileMiniItem,
  CustomRow,
  CustomText,
} from "components/UI/Components/Components";
import { TableCell, TableRow } from "components/UI/Table/Table";

const OwnerTableRow = ({ owner, profiles }) => {
  const profile = profiles?.[owner?.owner_address];
  if (!owner || !profile) {
    return null;
  }
  return (
    <TableRow noPadding>
      <TableCell alignLeft>
        <CustomRow gap="8px" alignItems="center">
          <CustomProfileMiniItem
            image={profile?.display_picture}
            title={profile?.display_name}
            profile={profile}
            alignItems="center"
          />
        </CustomRow>
      </TableCell>
      <TableCell alignRight>
        <CustomText
          color="var(--text-1)"
          fontSize="14px"
          text={owner?.quantity}
        />
      </TableCell>
      <TableCell alignRight>
        <FollowEditButton
          showEdit={false}
          name={profile?.display_name}
          twitter={profile?.socials?.twitter}
          socials={profile?.socials}
          bio={profile?.bio}
          followed={profile?.followed}
          address={profile.address}
          identityId={profile?.identity_id || profile?.id}
          profileType={profile?.profile_type}
          onUpdateCallback={() => {}}
          disabled={profile.disable_follow}
          followDirectly={profile.follow_directly}
        />
      </TableCell>
    </TableRow>
  );
};

export default OwnerTableRow;
