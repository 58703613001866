import TokenInfo from "../Profile/ProfileDetails/Portfolio/TokenHoldings/TokenInfo";
import classes from "./NetWorthCardToken.module.css";

export const getTokenName = (token) => {
  return token?.symbol || token?.name || "";
};

const NetWorthCardToken = ({ token, chainProfiles }) => {
  return (
    <div className={classes.net_worth_card_token}>
      <div className={classes.text_container}>
        <TokenInfo
          token={token}
          chainProfiles={chainProfiles}
          iconClassName={classes.icon}
          chainClassName={classes.chain}
          nameClassName={classes.name}
        />
        <div className={classes.values}>
          <span className={classes.value}>
            {token.total_usd_value?.display_value}{" "}
          </span>
          <span className={classes.value}>
            {token.relative_percentage_across_chains?.display_value}
          </span>
        </div>
      </div>
    </div>
  );
};

export default NetWorthCardToken;
