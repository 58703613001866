import React, { useMemo } from "react";
import styles from "./TrendingCards.module.scss";
import ZxTokenInfo from "zxComponents/common/ZxTokenInfo/ZxTokenInfo";
import ZxPriceBar from "zxComponents/common/ZxPriceBar/ZxPriceBar";
import TrendingTokenChart from "components/TrendingActivities/TrendingTokenChart";
import { CustomColumn, Divider } from "components/UI/Components/Components";
import { getUniqueId } from "utils/event_tracking";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import ZxBuySellBar from "zxComponents/common/ZxBuySellBar/ZxBuySellBar";
import SelectableDivWithDelay from "components/SelectableDivWithDelay/SelectableDivWithDelay";
import { handleFeedItemClick } from "utils/misc";
import { useHistory } from "react-router-dom";
const DURATIONS = ["1h", "6h", "12h", "24h"];
const InfoPills = ({ info }) => {
  return (
    <div className={styles.infoContainer}>
      {info.map((it) => {
        return (
          <CustomColumn
            key={getUniqueId()}
            className={styles.info}
            alignItems={it.align ?? "flex-start"}>
            <ZxText
              style={TSTYLES.secondaryLabel}
              textTransform="uppercase"
              fontSize="10px">
              {it.label}
            </ZxText>
            <ZxText style={TSTYLES.bodyLabel} align={it.align ?? "left"}>
              {it.value?.display_value}
            </ZxText>
          </CustomColumn>
        );
      })}
    </div>
  );
};
const TrendingCard = ({ card, fullWidth = false, refetch }) => {
  const history = useHistory();
  const {
    token_profile: profile,
    token_details: priceDetails,
    nft_details: nftDetails,
    mcap_hodlers_details: hodlerDetails,
    buy_sell: buySell,
    is_watchlisted: isWatchlisted,
  } = card.trending_metadata || {};
  const {
    price,
    "24h_percent_change": percentChange,
    "24h_price_history": graphData,
  } = priceDetails || {};
  const changes = DURATIONS.map((dur) => {
    const key = `${dur}_percent_change`;
    return {
      label: dur,
      change: priceDetails[key] ?? null,
    };
  });
  const isToken = profile.is_token;
  const infoPills = useMemo(() => {
    return isToken
      ? [
          {
            label: "24H VOLUME",
            value: hodlerDetails?.["24h_volume"],
            align: "flex-start",
          },
          {
            label: "MARKET CAP",
            value: hodlerDetails?.market_cap,
            align: "center",
          },
          {
            label: "LIQUIDITY",
            value: hodlerDetails?.liquidity,
            align: "flex-end",
          },
        ]
      : [
          {
            label: "24H SALES",
            value: hodlerDetails?.["24h_txns"],
            align: "flex-start",
          },
          {
            label: "24H VOLUME",
            value: hodlerDetails?.["24h_volume"],
            align: "center",
          },
          {
            label: "OWNERS",
            value: hodlerDetails?.["num_holders"],
            align: "flex-end",
          },
        ];
  }, [isToken, hodlerDetails]);
  return (
    <SelectableDivWithDelay
      clickable={true}
      className={styles.link}
      onClick={(e) =>
        handleFeedItemClick({ history, link: profile.address_link, event: e })
      }>
      <div
        className={styles.card}
        style={{ maxWidth: fullWidth ? "auto" : "280px" }}>
        <ZxTokenInfo
          token={profile}
          price={profile.is_token ? price : nftDetails?.floor_price}
          percentChange={percentChange}
          isWatchlisted={isWatchlisted}
          refetch={refetch}
        />
        <div className={styles.priceBar}>
          <ZxPriceBar changes={changes} />
        </div>
        <TrendingTokenChart
          graphData={graphData}
          width={fullWidth ? 578 : 290}
          height={100}
        />
        <InfoPills info={infoPills} />
        <Divider margin="4px 0" />
        <ZxBuySellBar buyCount={buySell?.buys} sellCount={buySell?.sells} />
      </div>
    </SelectableDivWithDelay>
  );
};

export default TrendingCard;
