import React from "react";
import styles from "./ZxPriceBar.module.scss";
import { CustomColumn, PriceChange } from "components/UI/Components/Components";
import ZxText from "../ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { getUniqueId } from "utils/event_tracking";
import withNullableError from "../withNullableError/index";
const ZxPriceBar = ({ changes }) => {
  if (!changes || changes?.length === 0) return null;
  return (
    <div className={styles.container}>
      {changes.map((change) => {
        return (
          <CustomColumn gap="4px" key={getUniqueId()} className={styles.pill}>
            <ZxText style={TSTYLES.secondaryLabel} textTransform="uppercase">
              {change.label}
            </ZxText>
            <PriceChange
              value={change?.change?.value ?? 0}
              fontWeight="500"
              fontSize="12px"
              showCaret={false}
              displayValue={change?.change?.display_value}
            />
          </CustomColumn>
        );
      })}
    </div>
  );
};

export default withNullableError(ZxPriceBar);
