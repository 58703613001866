import { CustomColumn, CustomRow, CustomSkeleton } from 'components/UI/Components/Components';

export const SkeletonLoader = () => {
  return (
    <div>
      {[...Array(6)].map((tranfer, index) => (
        <CustomRow
          gap="16px"
          key={"chat-item-skeleton" + index}
          height="72px"
          justifyContent="center">
          <CustomRow flexShrink={0}>
            <CustomSkeleton width="40px" height="40px" variant="circular" />
          </CustomRow>
          <CustomColumn gap="4px" flexGrow={1}>
            <CustomSkeleton width="200px" height="20px" />
            <CustomSkeleton width="100px" height="10px" />
          </CustomColumn>
        </CustomRow>
      ))}
    </div>
  );
};
