import { useCallback } from "react";
import {
  useChannelActionContext,
  useChannelStateContext,
} from "stream-chat-react";

const STORAGE_KEY = "@chat/drafts";

const getDrafts = () => JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}");

const removeDraft = (key) => {
  const drafts = getDrafts();

  if (drafts[key]) {
    delete drafts[key];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(drafts));
  }
};

const updateDraft = (key, value) => {
  const drafts = getDrafts();
  if (!value) {
    delete drafts[key];
  } else {
    drafts[key] = value;
  }
  localStorage.setItem(STORAGE_KEY, JSON.stringify(drafts));
};

const useDraftAPI = () => {
  const { channel, thread } = useChannelStateContext();
  const { sendMessage } = useChannelActionContext();

  const handleInputChange = useCallback(
    (e) => {
      updateDraft(channel.cid, e.target.value);
    },
    [channel.cid]
  );

  const handleThreadInputChange = useCallback(
    (e) => {
      if (!thread) return;
      updateDraft(`${channel.cid}:${thread.id}`, e.target.value);
    },
    [channel.cid, thread]
  );

  const getMainInputDraft = useCallback(() => {
    const drafts = getDrafts();
    return drafts[channel.cid] || "";
  }, [channel.cid]);

  const getThreadInputDraft = useCallback(() => {
    if (!thread) return;
    const drafts = getDrafts();
    return drafts[`${channel.cid}:${thread.id}`] || "";
  }, [channel.cid, thread]);

  const overrideSubmitHandler = useCallback(
    async (message, channelCid, customMessageData) => {
      await sendMessage(message, customMessageData);
      const key = message.parent
        ? `${channelCid}:${message.parent.id}`
        : channelCid;
      removeDraft(key);
    },
    [sendMessage]
  );

  const removeDraftAfterSubmit = useCallback(async (message, channelCid) => {
    const key = message.parent
      ? `${channelCid}:${message.parent.id}`
      : channelCid;
    removeDraft(key);
  }, []);

  return {
    getMainInputDraft,
    getThreadInputDraft,
    handleInputChange,
    handleThreadInputChange,
    overrideSubmitHandler,
    removeDraftAfterSubmit,
  };
};

export default useDraftAPI;
