import React, { useState } from "react";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

const Month = ({ onChange, initialDateRange }) => {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [selectedRange, setSelectedRange] = useState(
    initialDateRange || {
      start: null,
      end: null,
    }
  );

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const firstDay = new Date(currentYear, currentMonth, 1).getDay();

  const days = [];

  // Fill the days array with blanks for days before the first of the month
  for (let i = 0; i < firstDay; i++) {
    days.push({ day: null, outside: true });
  }

  // Fill the days array with the actual days of the month
  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(currentYear, currentMonth, i);
    days.push({
      day: i,
      outside: date > today,
      month: currentMonth,
      year: currentYear,
    });
  }

  // Handle Previous Month
  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  // Handle Next Month
  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handleDateClick = (day) => {
    if (day.outside) return;
    const selectedDate = new Date(day.year, day.month, day.day);

    if (!selectedRange.start) {
      setSelectedRange({ start: selectedDate, end: null });
    } else if (!selectedRange.end) {
      if (selectedDate < selectedRange.start) {
        setSelectedRange({ start: selectedDate, end: selectedRange.start });
      } else {
        setSelectedRange({ ...selectedRange, end: selectedDate });
      }
    } else {
      setSelectedRange({ start: selectedDate, end: null });
    }
  };

  const isInRange = (day) => {
    if (!selectedRange.start || !day.day) return false;

    const date = new Date(day.year, day.month, day.day);

    if (selectedRange.end) {
      return date >= selectedRange.start && date <= selectedRange.end;
    } else {
      return date.getTime() === selectedRange.start.getTime();
    }
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const isRangeSelected = selectedRange?.start && selectedRange?.end;
  return (
    <div
      style={{
        backgroundColor: "var(--background)",
        padding: 16,
        borderRadius: 4,
        border: "1px solid var(--border-dark)",
      }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 12,
        }}>
        <button onClick={handlePrevMonth}>
          <CaretLeft size={10} />
        </button>
        <h3>
          {monthNames[currentMonth]} {currentYear}
        </h3>
        <button onClick={handleNextMonth}>
          <CaretRight size={10} />
        </button>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: "1px",
          marginTop: "16px",
          textAlign: "center",
          fontSize: 13,
        }}>
        {dayNames.map((dayName) => {
          return <div key={dayName}>{dayName}</div>;
        })}

        {days.map((day, index) => (
          <div
            key={index}
            onClick={() => handleDateClick(day)}
            style={{
              padding: "8px",
              backgroundColor: isInRange(day)
                ? "var(--primary-color20)"
                : day.outside
                  ? "var(--elevation-1)"
                  : "var(--elevation-2)",
              textAlign: "center",
              border: day.outside
                ? "1px solid var(--border-light)"
                : "1px solid var(--border-dark)",
              color: day.outside ? "var(--text-2)" : "var(--text-1)",
              boxSizing: "border-box",
              cursor: day.outside ? "not-allowed" : "pointer",
            }}>
            {day.day}
          </div>
        ))}
      </div>
      <div style={{ paddingTop: 8, display: "flex", justifyContent: "end" }}>
        <button
          style={{
            padding: "8px 16px",
            color: isRangeSelected ? "var(--primary-color)" : "var(--text-2)",
            fontSize: 13,
            fontWeight: 600,
            borderRadius: 4,
            background:
              selectedRange?.start && selectedRange?.end
                ? "var(--primary-color10)"
                : "var(--elevation-1",
          }}
          onClick={() => {
            if (selectedRange.start) {
              onChange(selectedRange);
            } else {
              onChange(null);
            }
          }}>
          Select
        </button>
      </div>
    </div>
  );
};

export default Month;
