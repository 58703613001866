import { Alert } from "@mui/material";
import FeedMediaType from "components/FeedMediaType/FeedMediaType";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getChainMetaData, getTypeFromUrl } from "utils/misc";
import DescriptionBox from "./components/DescriptionBox";
import FeedDescription from "./components/FeedDescription";
import {
  CustomColumn,
  CustomRow,
  FlexBox,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import TokenHoverCard from "components/FeedItemV2/TokenHover/TokenHoverCard";
import { TimeLabel } from "components/FeedItemV2/ActivityHeader";
import { GroupActivityGalleryMediaPreview } from "./NFTImages/GroupActivityMediaPreview";
import NftAudioPlayer from "components/V2/NftAudioPlayer/NftAudioPlayer";

import {
  ActivitiesDivider,
  ActivityContent,
  ActivityItem,
  ActivityItemWrapper,
  Amount,
  TokenSeparator,
  LastNftItem,
  NftCaption,
  NftItem,
  NftTransfersWrapper,
  TokenImage,
  TokenInfoWrapper,
  TokenName,
  TokenTransferItem,
  TokenTransfersUsdValue,
  TokenTransfersWrapper,
  ContentWrapper,
  TokenImageWrapper,
  LastMediaPreviewItem,
  ChainIconWrapper,
  ActivityExtraInfo,
  MediaPreviewHorizontalSeparator,
  MediaPreviewVerticalSeparator,
  MediaPreviewCaption,
} from "./GroupFeedItem.styled";
import { sanitize } from "dompurify";
import useFetchTokenMetaData from "customHooks/useFetchTokenMetaData";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import BundleSuggestionTile from "./components/Bundle/BundleSuggestionTile";
import GnosisSigners from "components/GnosisSigners/GnosisSigners";
import { IMAGE_TYPES } from "components/UI/Image";
import ConditionalLink from "shared/ConditionalLink";
import ChainIcon from "components/UI/ChainIcon";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";

export const TokenTransfers = ({
  tokenTransfers,
  profiles,
  usdValue,
  chainId,
  chainSymbol,
}) => {
  const tokenTransfersLength = tokenTransfers?.length;
  const { data: nativeTokenMetaData } = useFetchTokenMetaData(
    chainSymbol,
    chainId
  );

  return (
    <TokenTransfersWrapper>
      {tokenTransfers.map((transfer, index) => {
        const tokenDetails = profiles[transfer.address];
        const isNativeToken = transfer.symbol === chainSymbol;
        const isNft =
          !tokenDetails?.is_token &&
          ["NFT", "MultiToken"].includes(tokenDetails?.address_type);
        const displayPicture = isNativeToken
          ? nativeTokenMetaData?.logo
          : tokenDetails?.display_picture;

        return (
          <React.Fragment key={`token-transfer-item-${index}`}>
            <TokenTransferItem
              onClick={(e) => e.stopPropagation()}
              to={transfer.token_url}
              target="_blank"
              rel="noreferrer">
              <Amount>
                <span>{transfer.direction === 1 ? "-" : "+"}</span>
                <span>{transfer.value || transfer.amount.display_value}</span>
              </Amount>

              <TokenHoverCard
                isNativeToken={isNativeToken}
                tokenProfile={
                  isNativeToken
                    ? {
                        address_chain: chainId,
                        address: chainSymbol,
                        ...nativeTokenMetaData,
                      }
                    : tokenDetails
                }
                isNft={isNft}
                nftPrice={transfer.value_usd}>
                <TokenInfoWrapper>
                  <TokenImageWrapper>
                    <TokenImage
                      src={displayPicture}
                      alt=""
                      imageType={IMAGE_TYPES.TOKEN}
                    />
                  </TokenImageWrapper>

                  <TokenName>
                    {transfer.symbol?.slice(0, 20)}
                    {transfer.symbol?.length > 20 ? "..." : ""}
                  </TokenName>
                </TokenInfoWrapper>
              </TokenHoverCard>
            </TokenTransferItem>
            {tokenTransfersLength !== index + 1 ? (
              <TokenSeparator>{`, `}</TokenSeparator>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      })}

      <TokenTransfersUsdValue>{usdValue}</TokenTransfersUsdValue>
    </TokenTransfersWrapper>
  );
};

export const getNftIndices = (
  groupedActivity,
  activityId,
  excludeAudioNfts = true
) => {
  const indices = [];
  const uniqueSrcSet = new Set();

  if (groupedActivity?.activities == null) {
    return indices;
  }

  for (
    let activityIdx = 0;
    activityIdx < groupedActivity?.activities.length;
    activityIdx++
  ) {
    if (
      activityId != null &&
      activityId !== groupedActivity?.activities[activityIdx]?.id
    ) {
      continue;
    }

    for (
      let mediaIdx = 0;
      mediaIdx < groupedActivity?.activities[activityIdx]?.media?.length;
      mediaIdx++
    ) {
      const mediaItem =
        groupedActivity?.activities[activityIdx]?.media[mediaIdx];
      if (mediaItem && !uniqueSrcSet.has(mediaItem.src)) {
        if (
          (!excludeAudioNfts && !mediaItem.audio_url) ||
          (excludeAudioNfts && mediaItem.audio_url)
        ) {
          continue;
        }
        indices.push({ activityIdx, mediaIdx });
        uniqueSrcSet.add(mediaItem.src);
      }
    }
  }

  if (indices.length === 0) {
    return (
      groupedActivity.media
        ?.filter((m) => (excludeAudioNfts ? !m.audio_url : true))
        .map((m, idx) => ({
          activityIdx: 0,
          mediaIdx: idx,
        })) || []
    );
  }

  return indices;
};

const Item = ({
  index,
  mediaLength,
  isRepost,
  activityId,
  groupedActivity,
  showGallery,
  nftPreviews,
}) => {
  const { activityIdx, mediaIdx } = nftPreviews[index];
  return (
    <NftRendererGalleryItem
      groupedActivity={groupedActivity}
      activityId={activityId}
      activityIdx={activityIdx}
      mediaIdx={mediaIdx}
      index={index}
      mediaLength={mediaLength}
      showGallery={showGallery}
      isRepost={isRepost}
    />
  );
};

export const NftTransfers = ({
  groupedActivity,
  activityId,
  showGallery = false,
  maxNftsCount = 4,
  isRepost = false,
}) => {
  const location = useLocation();
  const nftPreviews = useMemo(() => {
    const nfts = getNftIndices(groupedActivity, activityId);
    return nfts.length > maxNftsCount ? nfts.slice(0, maxNftsCount) : nfts;
  }, [groupedActivity, activityId, maxNftsCount]);
  const audioNftPreviews = useMemo(() => {
    const nfts = getNftIndices(groupedActivity, activityId, false);
    return nfts.length > maxNftsCount ? nfts.slice(0, maxNftsCount) : nfts;
  }, [groupedActivity, activityId, maxNftsCount]);
  if (!showGallery) {
    const previews = [...nftPreviews, ...audioNftPreviews];
    if (previews.length === 0) return null;
    return (
      <NftTransfersWrapper>
        {[...nftPreviews, ...audioNftPreviews]?.map(
          ({ activityIdx, mediaIdx, ...oldItem }, index) => {
            const item =
              groupedActivity?.activities[activityIdx]?.media[mediaIdx] ||
              oldItem;
            const mediaLink = activityId
              ? `/activity_details/${activityId}/media/${mediaIdx}`
              : groupedActivity?.id
                ? `/${groupedActivity.type}/${groupedActivity.id}/media/${activityIdx}/${mediaIdx}`
                : null;
            return (
              <NftRenderer
                key={`nft-item-${item?.alt}-${index}`}
                item={item}
                index={index}
                mediaLink={mediaLink}
                location={location}
                groupedActivity={groupedActivity}
                mediaLength={nftPreviews.length}
                isFullPreview={false}
                maxNftsCount={maxNftsCount}
                isRepost={isRepost}
              />
            );
          }
        )}
      </NftTransfersWrapper>
    );
  }

  if (nftPreviews.length === 1) {
    return (
      <Item
        isRepost={isRepost}
        index={0}
        mediaLength={1}
        activityId={activityId}
        groupedActivity={groupedActivity}
        showGallery={showGallery}
        nftPreviews={nftPreviews}
      />
    );
  }
  if (nftPreviews.length === 2) {
    return (
      <CustomRow>
        <ZxFlex>
          <PaddingComponent padding="8px 8px 0 0">
            <Item
              isRepost={isRepost}
              mediaLength={2}
              index={0}
              activityId={activityId}
              groupedActivity={groupedActivity}
              showGallery={showGallery}
              nftPreviews={nftPreviews}
            />
          </PaddingComponent>
          <MediaPreviewVerticalSeparator />
          <PaddingComponent padding="8px 0 0 8px">
            <Item
              isRepost={isRepost}
              mediaLength={2}
              index={1}
              activityId={activityId}
              groupedActivity={groupedActivity}
              showGallery={showGallery}
              nftPreviews={nftPreviews}
            />
          </PaddingComponent>
        </ZxFlex>
      </CustomRow>
    );
  }

  if (nftPreviews.length === 3) {
    return (
      <CustomRow>
        <FlexBox flex="1">
          <Item
            isRepost={isRepost}
            mediaLength={3}
            index={0}
            activityId={activityId}
            groupedActivity={groupedActivity}
            showGallery={showGallery}
            nftPreviews={nftPreviews}
          />
        </FlexBox>
        <MediaPreviewVerticalSeparator />
        <FlexBox flex="1">
          <CustomColumn>
            <Item
              isRepost={isRepost}
              mediaLength={3}
              index={1}
              activityId={activityId}
              groupedActivity={groupedActivity}
              showGallery={showGallery}
              nftPreviews={nftPreviews}
            />
            <MediaPreviewHorizontalSeparator />
            <Item
              isRepost={isRepost}
              mediaLength={3}
              index={2}
              activityId={activityId}
              groupedActivity={groupedActivity}
              showGallery={showGallery}
              nftPreviews={nftPreviews}
            />
          </CustomColumn>
        </FlexBox>
      </CustomRow>
    );
  }
  if (nftPreviews.length === 4) {
    return (
      <CustomColumn>
        <FlexBox flex="1">
          <CustomRow>
            <FlexBox flex="1">
              <Item
                isRepost={isRepost}
                mediaLength={4}
                index={0}
                activityId={activityId}
                groupedActivity={groupedActivity}
                showGallery={showGallery}
                nftPreviews={nftPreviews}
              />
            </FlexBox>
            <MediaPreviewVerticalSeparator />
            <FlexBox flex="1">
              <Item
                isRepost={isRepost}
                mediaLength={4}
                index={1}
                activityId={activityId}
                groupedActivity={groupedActivity}
                showGallery={showGallery}
                nftPreviews={nftPreviews}
              />
            </FlexBox>
          </CustomRow>
        </FlexBox>
        <MediaPreviewHorizontalSeparator />
        <FlexBox flex="1">
          <CustomRow>
            <FlexBox flex="1">
              <Item
                isRepost={isRepost}
                mediaLength={4}
                index={2}
                activityId={activityId}
                groupedActivity={groupedActivity}
                showGallery={showGallery}
                nftPreviews={nftPreviews}
              />
            </FlexBox>
            <MediaPreviewVerticalSeparator />
            <FlexBox flex="1">
              <Item
                isRepost={isRepost}
                mediaLength={4}
                index={3}
                activityId={activityId}
                groupedActivity={groupedActivity}
                showGallery={showGallery}
                nftPreviews={nftPreviews}
              />
            </FlexBox>
          </CustomRow>
        </FlexBox>
      </CustomColumn>
    );
  }

  if (audioNftPreviews.length && showGallery) {
    return (
      <CustomColumn gap="8px">
        {audioNftPreviews.map(
          ({ activityIdx, mediaIdx, ...oldItem }, index) => {
            const item =
              groupedActivity?.activities[activityIdx]?.media[mediaIdx] ||
              oldItem;
            const mediaLink = activityId
              ? `/activity_details/${activityId}/media/${mediaIdx}`
              : groupedActivity?.id
                ? `/${groupedActivity.type}/${groupedActivity.id}/media/${activityIdx}/${mediaIdx}`
                : null;
            return (
              <>
                <NftRenderer
                  key={`nft-audio-item-${item?.alt}-${index}`}
                  item={item}
                  index={index}
                  mediaLink={mediaLink}
                  location={location}
                  groupedActivity={groupedActivity}
                  mediaLength={audioNftPreviews.length}
                  isFullPreview={false}
                  maxNftsCount={maxNftsCount}
                  isRepost={isRepost}
                />
              </>
            );
          }
        )}
      </CustomColumn>
    );
  }

  return null;
};

const NftRendererGalleryItem = ({
  groupedActivity,
  activityId,
  activityIdx,
  mediaIdx,
  index,
  mediaLength,
  showGallery,
  isRepost = false,
}) => {
  const location = useLocation();
  const item = groupedActivity?.activities[activityIdx]?.media[mediaIdx];
  const mediaLink = activityId
    ? `/activity_details/${activityId}/media/${mediaIdx}`
    : groupedActivity?.id
      ? `/${groupedActivity.type}/${groupedActivity.id}/media/${activityIdx}/${mediaIdx}`
      : null;
  return (
    <NftRenderer
      key={`nft-item-${item?.alt}-${index}`}
      item={item}
      index={index}
      mediaLink={mediaLink}
      location={location}
      groupedActivity={groupedActivity}
      mediaLength={mediaLength}
      isFullPreview={showGallery}
      isRepost={isRepost}
    />
  );
};

const NftRenderer = ({
  item,
  index,
  mediaLink,
  location,
  groupedActivity,
  mediaLength,
  isFullPreview = false,
  maxNftsCount,
  isRepost = false,
}) => {
  const [type, setType] = useState(
    item.content_type ?? getTypeFromUrl(item.src)
  );
  if (item.audio_url) {
    return <NftAudioPlayer nftDetails={item} />;
  }
  return (
    <NftItem
      style={{
        height: isFullPreview ? "auto" : "120px",
        width: isFullPreview
          ? mediaLength === 1
            ? "fit-content"
            : "auto"
          : "120px",
      }}
      to={{
        pathname: mediaLink,
        state: {
          background: location,
          groupedActivity: groupedActivity,
        },
      }}
      sameTab>
      {index === maxNftsCount - 1 && mediaLength > maxNftsCount ? (
        <>
          {!isFullPreview ? <LastNftItem /> : <LastMediaPreviewItem />}
          {!isFullPreview && (
            <NftCaption>+{mediaLength - maxNftsCount} more</NftCaption>
          )}
          {isFullPreview && (
            <MediaPreviewCaption>
              +{mediaLength - maxNftsCount} more
            </MediaPreviewCaption>
          )}
        </>
      ) : (
        !isFullPreview && (
          <PPLxTooltip title={item?.description || item?.alt} arrow>
            <NftCaption>{item?.description || item?.alt}</NftCaption>
          </PPLxTooltip>
        )
      )}
      {isFullPreview ? (
        <GroupActivityGalleryMediaPreview
          type={type}
          setType={setType}
          mediaItem={item}
          index={index}
          itemsLength={mediaLength}
          isFullPreview={isFullPreview}
          isRepost={isRepost}
        />
      ) : (
        <FeedMediaType
          renderedFrom="MediaPreview"
          type={type}
          setType={setType}
          mediaItem={item}
          isFullPreview={isFullPreview}
        />
      )}
    </NftItem>
  );
};

const ActivityItemAdminView = ({
  isAdminView,
  adminOptions,
  profiles,
  activity,
}) => {
  return isAdminView && adminOptions.showParsers ? (
    <Alert
      variant="outlined"
      severity="info"
      style={{ wordBreak: "break-word", marginTop: 5 }}
      onClick={(e) => {
        if (e?.target?.href?.length) {
          window.open(e?.target?.href, "_newtab");
        }
      }}>
      <div
        dangerouslySetInnerHTML={{
          __html:
            sanitize(activity?.verbose_description) +
            " blockscan_name: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.blockscan_name +
            " blockscan_icon: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.blockscan_icon +
            " blockscan_website: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.blockscan_website +
            " crawler_name: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.crawler_name +
            " crawler_icon: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.crawler_icon +
            " crawler_website: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.crawler_website +
            " crawler_direct_match_score: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.crawler_direct_match_score +
            " crawler_sister_contract_score: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.crawler_sister_contract_score +
            " crawler_search_result_link_score: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.crawler_search_result_link_score +
            " crawler_search_result_title_score: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.crawler_search_result_title_score +
            " user_specified_name: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.user_specified_name +
            " user_specified_icon: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.user_specified_icon +
            " user_specified_website: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.user_specified_website +
            " crawler_instrumentation: " +
            profiles[activity?.addresses_involved.invoked_contract]
              ?.protocol_details?.crawler_instrumentation,
        }}></div>
    </Alert>
  ) : null;
};

export const isShowingBundleSuggestion = ({ groupedActivity }) => {
  if (!groupedActivity || !groupedActivity.address_pairwise_summary) {
    return false;
  }
  for (const [address, summary] of Object.entries(
    groupedActivity.address_pairwise_summary
  )) {
    if (address !== groupedActivity.actor.address && summary?.are_related) {
      return true;
    }
  }
  return false;
};

export const FeedBundleSuggestion = ({
  groupedActivity,
  refetchGroupedActivity,
}) => {
  let listToShowBundleSuggestion = useMemo(() => {
    const result = [];
    for (const [address, summary] of Object.entries(
      groupedActivity?.address_pairwise_summary
    )) {
      if (result.length >= 3) {
        break;
      }
      if (address !== groupedActivity?.actor?.address && summary?.are_related) {
        result.push({
          ...summary,
          address: address,
        });
      }
    }
    return result;
  }, [
    groupedActivity?.address_pairwise_summary,
    groupedActivity?.actor?.address,
  ]);

  if (!groupedActivity || !groupedActivity.address_pairwise_summary) {
    return null;
  }
  if (listToShowBundleSuggestion.length === 0) {
    return null;
  }
  return listToShowBundleSuggestion.map((updatedSummary, bundleIndex) => {
    return updatedSummary.are_related ? (
      <CustomColumn key={`bundle-${updatedSummary.address}-${bundleIndex}`}>
        <BundleSuggestionTile
          isUpperBorder={false}
          isBottomBorder={
            bundleIndex ===
            Object.values(listToShowBundleSuggestion)?.length - 1
              ? false
              : true
          }
          relatedData={updatedSummary}
          profiles={groupedActivity.profiles}
          methodIds={groupedActivity.methodIds}
          actor={groupedActivity.actor}
          nativeTokensInfo={groupedActivity.native_tokens_dict}
          key={updatedSummary.address}
          refetchGroupedActivity={refetchGroupedActivity}
          {...(groupedActivity?.type === "news" ? { paddingLeft: "20px" } : {})}
        />
      </CustomColumn>
    ) : null;
  });
};

export const FeedExtraBorderItems = ({ items }) => {
  if (items?.length === 0) return null;
  const itemsToRender = items.map((item, index) => {
    if (item == null || item === false) return null;
    return (
      <CustomColumn key={index}>
        {item}
        {index !== items.length - 1 && items.length > 1 && <Divider />}
      </CustomColumn>
    );
  });
  //check its items to render has all elements null
  if (itemsToRender.every((item) => item === null)) return null;

  return <CustomColumn>{itemsToRender}</CustomColumn>;
};

export const LinkToExplorer = ({ activity, children }) => {
  const tooltipText = useMemo(
    () => getChainMetaData(activity.chain_id)?.website,
    [activity.chain_id]
  );
  return (
    <ConditionalLink
      sameTab={false}
      to={{ pathname: activity.transactions?.[0]?.explorer_url }}>
      <div title={tooltipText ? `Link to ${tooltipText}` : null}>
        {children}
      </div>
    </ConditionalLink>
  );
};

export const RenderActivity = ({
  activity,
  profiles,
  identities,
  methodIds,
  index,
  showDivider,
  isAdminView,
  showOpenInNewTab,
  adminOptions,
  groupedActivity,
  isActivityDetails = false,
  isBackgroundColor = true,
  isBorder = true,
  marginBottom,
  padding,
  hideBundleSuggestions = false,
  type,
  showGallery = false,
  showNftImages = true,
  mainProtocolData,
}) => {
  return (
    <ActivityItemWrapper
      {...(!!isActivityDetails && {
        padding: padding,
        isBackgroundColor: isBackgroundColor,
        isActivityDetails: true,
        isBorder: isBorder,
        marginBottom: marginBottom,
        to: `/group_activity_details/${groupedActivity.id}/${activity.id}`,
      })}
      className="activityTokenTransfer">
      <ActivityItem>
        {/* TODO(shaheer): display method icon when it's supported by the API */}
        {/* {!!isActivityDetails && (
          <ActivityIcon>
            <img src={rightChevronIcon} alt="activity icon" />
          </ActivityIcon>
        )} */}
        <ActivityContent>
          <ContentWrapper>
            {!!activity?.description_template && (
              <FeedDescription
                templateString={activity?.description_template}
                profiles={profiles}
                identities={identities}
                methodIds={methodIds}
                isAdminView={isAdminView}
                actor={groupedActivity?.actor}
                addressPairWiseSummary={
                  groupedActivity.address_pairwise_summary
                }
                nativeTokensInfo={groupedActivity.native_tokens_dict}
                chainProfiles={activity.chain_profiles}
                nonEvmProfiles={activity.non_evm_profiles}
                fontColor={
                  ["lens", "farcaster"].includes(type)
                    ? "var(--text-2)"
                    : undefined
                }
                mainProtocolData={mainProtocolData}
                heroNames={groupedActivity.hero_names}
              />
            )}
            {!!isActivityDetails && (
              <ActivityExtraInfo>
                <TimeLabel timestamp={activity.timestamp} />
                <LinkToExplorer activity={activity}>
                  <ChainIconWrapper>
                    <ChainIcon
                      chainId={activity.chain_id}
                      chainProfiles={activity.chain_profiles}
                      explorerUrl={activity.transactions?.[0]?.explorer_url}
                    />
                  </ChainIconWrapper>
                </LinkToExplorer>
              </ActivityExtraInfo>
            )}
          </ContentWrapper>
          {activity?.token_transfers?.length > 0 ? (
            <TokenTransfers
              tokenTransfers={activity.token_transfers}
              profiles={profiles}
              usdValue={activity.usd_value?.display_value}
              chainId={activity.chain_id}
              chainSymbol={activity.chain_symbol}
            />
          ) : null}
          {activity.extended_description_template ? (
            <DescriptionBox longText={activity.extended_description_template} />
          ) : null}
          {activity?.media?.length > 0 && showNftImages && (
            <NftTransfers
              groupedActivity={groupedActivity}
              activityId={activity?.id}
              showGallery={showGallery}
            />
          )}
          {!hideBundleSuggestions &&
            !!groupedActivity.address_pairwise_summary &&
            activity.addresses_involved.external_addrs.map((address) => {
              if (
                address !== groupedActivity.actor.address &&
                !!groupedActivity.address_pairwise_summary[address]
              ) {
                const updatedSummary = {
                  ...groupedActivity.address_pairwise_summary[address],
                  address: address,
                };
                return updatedSummary.are_related ? (
                  <PaddingComponent paddingTop="16px" paddingBottom="16px">
                    <BundleSuggestionTile
                      relatedData={updatedSummary}
                      profiles={groupedActivity.profiles}
                      methodIds={groupedActivity.methodIds}
                      isAdminView={isAdminView}
                      actor={groupedActivity.actor}
                      nativeTokensInfo={groupedActivity.native_tokens_dict}
                      key={address}
                    />
                  </PaddingComponent>
                ) : null;
              }
              return null;
            })}
          {isActivityDetails &&
            !!activity?.addresses_involved?.mulitisig_signatories && (
              <>
                <GnosisSigners
                  signers={activity.addresses_involved.mulitisig_signatories}
                  multisigs={activity.addresses_involved.multisig_addrs}
                  profiles={groupedActivity.profiles}
                  isTransaction
                />
              </>
            )}
        </ActivityContent>
      </ActivityItem>
      <ActivityItemAdminView
        isAdminView={isAdminView}
        adminOptions={adminOptions}
        profiles={profiles}
        activity={activity}
      />
      {showDivider ? <ActivitiesDivider /> : null}
    </ActivityItemWrapper>
  );
};

export const RenderActivities = ({
  activities,
  profiles,
  identities,
  methodIds,
  isAdminView,
  showOpenInNewTab,
  adminOptions,
  groupedActivity,
  hideBundleSuggestions = false,
  type,
  showGallery = false,
  showNftImages = true,
  mainProtocolData,
}) => {
  const slicedActivities = activities?.slice(0, 3) ?? [];
  if (slicedActivities.length === 0) return null;
  return slicedActivities?.map((activity, index) => (
    <RenderActivity
      showNftImages={showNftImages}
      showGallery={showGallery}
      key={index}
      activity={activity}
      profiles={profiles}
      identities={identities}
      methodIds={methodIds}
      index={index}
      showDivider={index !== slicedActivities.length - 1}
      isAdminView={isAdminView}
      showOpenInNewTab={showOpenInNewTab}
      adminOptions={adminOptions}
      groupedActivity={{ ...groupedActivity, activities: [activity] }}
      hideBundleSuggestions={hideBundleSuggestions}
      type={type}
      mainProtocolData={mainProtocolData}
    />
  ));
};
