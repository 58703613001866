import classes from "./TopProfileList.module.css";
import withLoader from "components/core/withLoader";
import { getCommunityProfiles } from "api/community.api";
import ProfileItem, {
  ProfileItemGhost,
  ProfileItemTypes,
} from "../ProfileItem/ProfileItem";
import { reasonsFromProfile } from "utils/profile_utils";

export const TopProfileGhostList = () => {
  return (
    <div className={classes.profile}>
      {Array.from({ length: 10 }).map((_, index) => {
        return <ProfileItemGhost key={`profile-item-ghost-${index}`} />;
      })}
    </div>
  );
};

const TopProfileList = ({
  queryData,
  refetchFeedOnFollowOrUnfollow = false,
}) => {
  return (
    <div className={classes.profile}>
      {queryData?.data?.map((profile) => {
        return (
          <ProfileItem
            key={profile.id}
            profile={profile}
            resultType="identity"
            profileItemType={
              profile.followed_on_twitter
                ? ProfileItemTypes.twitter
                : ProfileItemTypes["0xppl"]
            }
            identity_details={queryData.followers}
            details={reasonsFromProfile(profile)}
            refetchFeedOnFollowOrUnfollow={refetchFeedOnFollowOrUnfollow}
          />
        );
      })}
    </div>
  );
};

export default withLoader(TopProfileList, getCommunityProfiles, {
  queryKey: (props) => [props.tab],
  containerClass: classes.profile,
  CustomLoader: TopProfileGhostList,
  staleTime: 1000 * 60 * 30,
});
