import {
  CustomRow,
  CustomText,
  InfoBox,
  PaddingComponent,
  CustomColumn,
  Divider,
} from "components/UI/Components/Components";
import { useContext, useState } from "react";
import HomeComponentsHeader from "../HomeComponentsHeader";
import MyHoldingsItem, { MyHoldingsItemShimmer } from "./MyHoldingsItem";
import classes from "./MyHoldings.module.css";
import useMyHoldings from "./useMyHoldings";
import { createPortal } from "react-dom";
import MyHoldingsRightPanel from "./MyHoldingsRightPanel";
import { AuthContext } from "contextStates/AuthContext";
import Refetch from "components/UI/Refetch/Refetch";
import no_holdings from "assets/png/no_holdings.png";
import { GlobalContext } from "contextStates/Global";
import { USER_PREFENCES } from "utils/constants";
import { Drawer } from "@mui/material";
import { trackEvent } from "utils/event_tracking";
import ZxText from "zxComponents/common/ZxText";
import { TSTYLES } from "zxStyles/constants";

const MyHoldings = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isRightPaneOpen, setIsRightPaneOpen] = useState(false);
  const [selectedHolding, setSelectedHolding] = useState({});
  const { identityDetails } = useContext(AuthContext);
  const { getUserPreference, setUserPreference } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState(
    getUserPreference(USER_PREFENCES.MY_HOLDINGS_ACTIVE_TAB) || 0
  );

  const { data, isLoading, isError, refetch } = useMyHoldings({
    identifier: identityDetails?.current?.identity?.id,
    showRecent: activeTab === 1,
  });

  const handleTabChange = (index) => {
    setActiveTab(index);
    trackEvent("holdings_tab_change", { tab: activeTab });
    setUserPreference(USER_PREFENCES.MY_HOLDINGS_ACTIVE_TAB, index);
  };

  return (
    <InfoBox padding="16px" radius="8px" borderColor="var(--elevation-1)">
      <CustomColumn alignItems="flex-start">
        <HomeComponentsHeader
          text="My Top Holdings"
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
        {isExpanded && (
          <>
            <PaddingComponent height="16px" />
            <Divider
              borderColor="var(--border-dark)"
              style={{ position: "relative", top: "-1px" }}
            />
            <Header />
            <div className={classes.holdingsContainer}>
              {isLoading ? <MyHoldingsItemShimmer /> : ""}
              {data?.holdings?.map((holdingInfo, index) => {
                return (
                  <MyHoldingsItem
                    key={index}
                    data={holdingInfo}
                    chainProfiles={data?.chain_profiles}
                    onClick={(data) => {
                      setSelectedHolding(data);
                      setIsRightPaneOpen(true);
                    }}
                  />
                );
              })}
              {data?.holdings?.length === 0 ? (
                <CustomColumn
                  height="250px"
                  alignItems="center"
                  justifyContent="flex-start"
                  padding="20px 0px"
                  background={`url(${no_holdings}) no-repeat bottom/cover`}>
                  <CustomText
                    text="No holdings found with your connected wallets"
                    color="var(--text-2)"
                    fontSize="12px"></CustomText>
                </CustomColumn>
              ) : (
                ""
              )}
              {isError && (
                <CustomColumn
                  width="100%"
                  height="165px"
                  justifyContent="center">
                  <Refetch onClick={refetch}></Refetch>
                </CustomColumn>
              )}
            </div>
          </>
        )}
      </CustomColumn>
      {createPortal(
        <Drawer
          anchor="right"
          open={isRightPaneOpen}
          onClose={() => setIsRightPaneOpen(false)}>
          <MyHoldingsRightPanel
            onClose={() => {
              setIsRightPaneOpen(false);
            }}
            holdingsData={data}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
            identifier={identityDetails?.current?.identity?.id}
            selectedHolding={selectedHolding}
            setSelectedHolding={setSelectedHolding}
          />
        </Drawer>,
        document.getElementById("rightPanel")
      )}
    </InfoBox>
  );
};

const Header = () => {
  return (
    <PaddingComponent padding="10px 0" width="100%">
      <CustomRow justifyContent="space-between" width="100%">
        <ZxText style={TSTYLES.secondaryLabel} fontSize={10}>
          NAME / AMOUNT
        </ZxText>
        <ZxText style={TSTYLES.secondaryLabel} fontSize={10}>
          VALUE(24H) / INVESTED
        </ZxText>
      </CustomRow>
    </PaddingComponent>
  );
};

export default MyHoldings;
