import React, { useContext } from "react";
import { ChatContext } from "stream-chat-react";
import "./MessagingChannelPreview.css";
import AvatarWithPresence from "../Avatars/AvatarWithPresence";
import {
  ChannelTypes,
  isEnabledChannelType,
  isGroupChannelType,
  replaceMentionedUsers,
} from "../../helpers";

const getTimeStamp = (channel) => {
  let lastHours = channel.state.last_message_at?.getHours();
  let lastMinutes = channel.state.last_message_at?.getMinutes();
  let half = "AM";

  if (lastHours === undefined || lastMinutes === undefined) {
    return "";
  }

  if (lastHours > 12) {
    lastHours = lastHours - 12;
    half = "PM";
  }

  if (lastHours === 0) lastHours = 12;
  if (lastHours === 12) half = "PM";

  if (lastMinutes.toString().length === 1) {
    lastMinutes = `0${lastMinutes}`;
  }

  return `${lastHours}:${lastMinutes} ${half}`;
};

const getChannelName = (members) => {
  const defaultName = "";

  if (!members.length || members.length === 1) {
    return members[0]?.user.name || defaultName;
  }

  return `${members[0]?.user.name || defaultName}, ${
    members[1]?.user.name || defaultName
  }`;
};

const MessagingChannelPreview = (props) => {
  const { channel, latestMessage, lastMessage, setActiveChannel } = props;
  const { channel: activeChannel, client } = useContext(ChatContext);

  const members = Object.values(channel.state.members).filter(
    ({ user }) => user.id !== client.userID
  );

  const channelImage = isGroupChannelType(channel.data.channel_type)
    ? channel.data.image
    : members[0]?.user?.image;

  const myUnreadMessages =
    channel.state.read[client.userID]?.unread_messages || 0;

  if (
    channel.data?.channel_type === ChannelTypes.DM &&
    channel.data.created_by.id !== client.userID &&
    !lastMessage &&
    !channel.state.membership.invited
  )
    return null;

  if (!isEnabledChannelType(channel.data.channel_type)) return null;

  const isPendingMember = channel.state.membership.status === "pending";

  let lastMessageToDisplayInPreview =
    lastMessage && lastMessage?.type !== "system"
      ? lastMessage?.user?.name +
        ": " +
        (lastMessage?.text
          ? replaceMentionedUsers(
              lastMessage?.text,
              lastMessage?.mentioned_users
            )
          : "Sent " + lastMessage?.attachments[0]?.type)
      : latestMessage;

  if (isPendingMember) {
    lastMessageToDisplayInPreview = "Invite pending";
  }

  return (
    <div
      className={
        channel?.id === activeChannel?.id
          ? "channel-preview__container selected"
          : "channel-preview__container"
      }
      onClick={() => {
        setActiveChannel(channel);
      }}>
      <AvatarWithPresence
        image={channelImage}
        name={channel.data.name}
        size={40}
        isGroup={isGroupChannelType(channel.data.channel_type)}
        user={members[0]?.user}
      />
      <div className="channel-preview__content-wrapper">
        <div className="channel-preview__content-top">
          <p className="channel-preview__content-name">
            {channel.data.name || getChannelName(members)}
          </p>
          <p className="channel-preview__content-time">
            {getTimeStamp(channel)}
          </p>
        </div>
        <div className="channel-preview__content-bottom">
          {isGroupChannelType(channel.data.channel_type) ? (
            <p className="channel-preview__content-message">
              {lastMessageToDisplayInPreview}
            </p>
          ) : latestMessage === "Nothing yet..." ? (
            <p className="channel-preview__content-new-message">
              Send a message
            </p>
          ) : (
            <p className="channel-preview__content-message">
              {replaceMentionedUsers(
                lastMessage?.text,
                lastMessage?.mentioned_users
              )}
            </p>
          )}

          {myUnreadMessages > 0 && !isPendingMember && (
            <span className="channel-preview__notification-dot">
              {myUnreadMessages}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessagingChannelPreview;
