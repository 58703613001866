export const FEED_CARDS = {
  CONTRACTS: {
    gradientLight: "linear-gradient(180deg, #D9EBFF 0%, #92BFF5 100%)",
    gradientDark: "linear-gradient(180deg, #2C3B4E 0%, #272D32 36.98%)",
    width: 280,
    height: 115,
    icon: "/feed/contract_header.svg",
    title: "Trending in your network",
    darkModebackground: "#161C23",
  },
  PNL: {
    gradientLight: "linear-gradient(180deg, #C1F3D2 0%, #67C286 100%)",
    gradientDark: "linear-gradient(180deg, #223A29 0%, #1A2B1F 100%)",
    width: 340,
    height: 280,
    icon: "/feed/pnl_header.svg",
    title: "Top PnL trades",
    darkModebackground: "#19221B",
  },
  FOLLOW: {
    gradientLight: "linear-gradient(180deg, #F9E5D0 0%, #EDB67B 100%)",
    gradientDark: "linear-gradient(180deg, #41382E 0%, #2B241E 36.98%)",
    width: 300,
    height: 220,
    icon: "/feed/follow_header.svg",
    title: "People to follow",
    darkModebackground: "#1C1916",
  },
  WELCOME: {
    gradientLight: "linear-gradient(180deg, #FEC8E8 0%, #FC8ECE 94.06%)",
    gradientDark: "linear-gradient(180deg, #462036 0%, #301525 36.98%)",
    width: 330,
    height: 374,
    icon: "/feed/welcome_header.svg",
    title: "Welcome to our new members",
    darkModebackground: "#2A111F",
  },
  TRENDING: {
    gradientLight: "linear-gradient(180deg, #E3D3FF 0%, #B892FC 98.39%)",
    gradientDark: "linear-gradient(180deg, #352357 0%, #261A3B 36.98%)",
    width: 320,
    height: 420,
    title: "Trending today",
    icon: "/feed/trending_header.svg",
    darkModebackground: "#1C1230",
  },
};

export const OVERVIEW_CARDS = {
  PNL: {
    gradientLight: "linear-gradient(180deg, var(--base) 0%, var(--base) 100%)",
    gradientDark: "linear-gradient(180deg, var(--base) 0%, var(--base) 100%)",
    width: 268,
    height: 100,
    title: null,
    darkModebackground: "#19221B",
  },

  NFT: {
    gradientLight: "linear-gradient(180deg, var(--base) 0%, var(--base) 100%)",
    gradientDark: "linear-gradient(180deg, var(--base) 0%, var(--base) 100%)",
    width: 190,
    height: 224,
    title: null,
    darkModebackground: "#2C1E1E",
  },
};
