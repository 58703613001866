import {
  CustomColumn,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import Popup from "components/UI/Popup/Popup";
import { QRCodeSVG } from "qrcode.react";

const CrossPostFarcasterPopUp = ({
  setIsShowFarcasterPopUp,
  farcasterRedirectUrl,
}) => {
  return (
    <Popup
      onClose={setIsShowFarcasterPopUp}
      titleFontSize="20px"
      title="Connect your farcaster account"
      isDivider>
      <PaddingComponent height="12px" />
      <CustomColumn alignItems="center">
        <QRCodeSVG
          value={farcasterRedirectUrl}
          size={200}
          fgColor="var(--text-1)"
          bgColor="var(--base)"
        />
        <PaddingComponent height="16px" />
        <CustomText
          text="Scan QR Code from your mobile and approve to connect"
          color="var(--text-1)"
          fontSize="500"
        />
        <PaddingComponent height="6px" />
        <CustomText
          fontSize="11px"
          text="Note: Don't press back or close this window until you approve the connection"
          color="var(--text-2)"
        />
        <CustomText fontSize="11px" text="" color="var(--text-2)" />
      </CustomColumn>
    </Popup>
  );
};

export default CrossPostFarcasterPopUp;
