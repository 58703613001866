import { useMemo, useLayoutEffect, useRef, useContext } from "react";
import classes from "./CustomLinkTabBar.module.css";
import {
  CustomRow,
  FlexBox,
  LinkReplace,
} from "components/UI/Components/Components";
import { useState, createRef } from "react";
import { GlobalContext } from "contextStates/Global";
import styled from "styled-components";

export const SIZE = {
  SMALL: "SMALL",
  REGULAR: "REGULAR",
  LARGE: "LARGE",
};

const SIZE_CLASSES = {
  [SIZE.SMALL]: classes.tabbarlinkSmall,
  [SIZE.REGULAR]: classes.tabbarlink,
  [SIZE.LARGE]: classes.tabbarlinkLarge,
};

const LinkTabBar = ({
  tab,
  currentStep,
  isEnabled,
  size,
  onClickTrackingEvent = () => {},
}) => {
  let isActive = false;
  if (tab.isActiveTab == null) {
    isActive = tab.link === currentStep;
  } else {
    isActive = tab.isActiveTab;
  }
  return (
    <LinkReplace
      to={tab.link}
      onClickTrackingEvent={() => {
        if (tab && typeof tab === "string") {
          onClickTrackingEvent(tab?.title?.toLowerCase() ?? tab?.toLowerCase());
        }
      }}
      className={`${classes.link} ${
        isActive && isEnabled ? classes.active : ""
      }  ${SIZE_CLASSES[size]}`}>
      {tab.title ?? tab}
    </LinkReplace>
  );
};

const StateTabBar = ({
  title,
  index,
  setActiveTab,
  size,
  activeTab,
  validateChange,
  shouldHighlightTab,
}) => {
  return (
    <div
      onClick={() => {
        validateChange(index) && setActiveTab(index);
      }}
      className={`${classes.link} ${SIZE_CLASSES[size]} ${
        shouldHighlightTab(activeTab, index) ? classes.active : ""
      }`}>
      {title}
    </div>
  );
};

const BAR_SIZE = {
  SMALL: "3px",
  REGULAR: "4px",
  LARGE: "5px",
};

export const TABBAR_ZINDEX = {
  HIGH: 4,
  REGULAR: 3,
};

const TopOffsetDiv = styled.div`
  top: ${({ ribbonData }) => (ribbonData == null ? "0px" : "24px")};
  @media (max-width: 768px) {
    top: 46px;
  }
`;

const CustomLinkTabBar = ({
  activeTab,
  setActiveTab,
  tabsList,
  currentStep,
  children,
  isEnabled = true,
  size = SIZE.REGULAR,
  isLinkTabBar = true,
  isBorderBottom = true,
  position = "bottom",
  zIndex = TABBAR_ZINDEX.REGULAR,
  isBackdropFilter = true,
  customRender = null,
  validateChange = () => true,
  fullWidth = false,
  shouldHighlightTab = (activeTab, tabIndex) => {
    return activeTab === tabIndex;
  },
  isCentered = false,
  tabPadding = "0",
  tabGap = "20px",
  sticky,
  onClickTrackingEvent = () => {},
}) => {
  const { ribbonData } = useContext(GlobalContext);
  const [indicatorPosition, setIndicatorPosition] = useState({});
  const isCalculated = useRef(false);

  const tabBarRefs = useRef([]);

  const isPostionTop = position === "top";

  tabBarRefs.current = tabsList.map(
    (_, i) => tabBarRefs.current[i] || createRef()
  );
  /** added timeout as the ref was not getting the correct position as size was not assigned that time  */
  useLayoutEffect(() => {
    setTimeout(
      () => {
        const positionData = tabBarRefs.current.map((ref, i) => {
          return {
            left:
              ref.current?.getBoundingClientRect()?.left -
              ref.current?.offsetParent?.getBoundingClientRect()?.left,
            width: ref.current?.getBoundingClientRect()?.width,
          };
        });
        setIndicatorPosition(positionData);
        isCalculated.current = true;
      },
      isCalculated.current ? 0 : 200
    );
  }, [currentStep, tabsList, activeTab, isLinkTabBar]);

  const currentIndex = useMemo(() => {
    if (!isLinkTabBar) return activeTab;
    const index = tabsList.findIndex(
      (tab) => tab.link === currentStep || tab.isActiveTab
    );
    return index;
  }, [currentStep, tabsList, activeTab, isLinkTabBar]);
  return (
    <TopOffsetDiv
      className={classes.tabBarPosition}
      ribbonData={ribbonData}
      style={{
        zIndex: zIndex,
        backdropFilter: isBackdropFilter ? "blur(16px)" : "none",
      }}>
      <div
        className={`${classes.indicatorPositionContainer} ${
          position === "top" && classes.indicatorPositionContainerTop
        }`}
        style={{ alignItems: isCentered ? "center" : "flex-end" }}>
        <div
          className={classes.borderContainer}
          style={{
            borderBottom: isBorderBottom
              ? "1px solid var(--border-dark)"
              : "none",
            paddingBottom: isPostionTop ? "16px" : "8px",
            paddingTop: isPostionTop ? "16px" : "0px",
          }}>
          <div className={classes.itemsContainer}>
            <FlexBox flex="1">
              <CustomRow
                gap={tabGap}
                overflowX={isCentered ? "hidden" : "visible"}
                alignItems={isCentered ? "center" : "flex-start"}
                justifyContent={fullWidth ? "space-around" : "flex-start"}
                className={classes.tabsContainer}>
                {tabsList.map((tab, index) => {
                  return (
                    <div
                      ref={tabBarRefs.current[index]}
                      key={`custom-tab-bar-${index}`}
                      style={{ padding: tabPadding }}
                      className={classes.tabItemWrapper}>
                      {tab.render ? (
                        tab.render()
                      ) : isLinkTabBar ? (
                        <LinkTabBar
                          tab={customRender ? customRender(tab) : tab}
                          currentStep={currentStep}
                          isEnabled={isEnabled}
                          size={size}
                          onClickTrackingEvent={onClickTrackingEvent}
                          sticky={sticky}
                        />
                      ) : (
                        <StateTabBar
                          title={customRender ? customRender(tab) : tab}
                          index={index}
                          setActiveTab={setActiveTab}
                          size={size}
                          validateChange={validateChange}
                          shouldHighlightTab={shouldHighlightTab}
                          activeTab={activeTab}
                        />
                      )}
                    </div>
                  );
                })}
              </CustomRow>
            </FlexBox>
            {children}
          </div>
        </div>
        {isEnabled && (
          <div
            style={{
              transition: "width 0.3s, left 0.3s", // adjust the timing as needed
              width: `${indicatorPosition[currentIndex]?.width ?? 0}px`,
              left: `${indicatorPosition[currentIndex]?.left}px`,
              height: BAR_SIZE[size],
            }}
            className={classes.active_bar}
          />
        )}
      </div>
    </TopOffsetDiv>
  );
};

export default CustomLinkTabBar;
