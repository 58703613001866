import classes from "./walletTableHeader.module.css";
import { useHistory } from "react-router-dom";

export const TableRowItem = ({ alignment, flex, children, onClick }) => {
  alignment = alignment || "left";
  return (
    <div
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
        }
      }}
      style={{
        height: "24px",
        flex: flex,
        width: "100%",
        textAlign: alignment,
        display: "flex",
        alignItems: "center",
        justifyContent: alignment === "left" ? "flex-start" : "flex-end",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}>
      {children}
    </div>
  );
};

const WalletRowComponent = ({ onClick, children, link }) => {
  const history = useHistory();
  return (
    <div
      onClick={
        link
          ? () => {
              history.push(link);
            }
          : onClick
      }
      style={{cursor: link|| onClick ? "pointer" : "default"}}
      className={classes.tableRow}>
      {children}
    </div>
  );
};

export default WalletRowComponent;
