import {
  CustomButton,
  CustomColumn,
  CustomRow,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import { useEffect, useState } from "react";
import NoExplorerImage from "assets/svg/no_graph_explorer.svg";
import NoOnboardingImage from "assets/png/no_mobile_onboarding.png";
import PartyingFace from "assets/partying-face.gif";
import { appDownloadLink } from "utils/constants";
import { openInNewTab } from "utils/misc";
import { getDeepLink } from "components/V2/Notifications/api";

const isFeatureGateEnabled = true;

const Features = {
  onboarding: {
    title: "Onboard with our mobile app",
    description:
      "You can’t onboard using the mobile browser. Quickly install the 0xPPL app on your phone to continue",
    image: NoOnboardingImage,
    dividerImage: PartyingFace,
  },
  explorer: {
    title: "Ah, Snap",
    description:
      "You can’t view the graph explorer on mobile browser. For a better user experience, use the mobile app.",
    image: NoExplorerImage,
  },
};

const FeatureGate = ({ featureName, children }) => {
  const feature = Features[featureName];
  const [snap, setSnap] = useState(false);
  const [downloadAppLink, setDownloadAppLink] = useState(null);

  const getDeepLinkForInvite = async () => {
    if (downloadAppLink) return;

    const currentUrl = new URL(window.location.href);
    const code = currentUrl.searchParams.get("code");
    const isOnboardingRoute = currentUrl.pathname === "/onboarding/intro/";

    if (code && isOnboardingRoute) {
      try {
        const linkToRedirect = "https://0xppl.com/gm?code=" + code;
        const response = await getDeepLink({ url: linkToRedirect });
        const dynamicUrl = response?.data?.data?.dynamic_url;

        if (dynamicUrl) {
          setDownloadAppLink(dynamicUrl);
        } else {
          setDownloadAppLink(appDownloadLink); // Fallback to current URL
        }
      } catch (error) {
        setDownloadAppLink(appDownloadLink); // Fallback to current URL
      }
    } else {
      setDownloadAppLink(appDownloadLink);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        if (!snap) {
          //check for userAgent and touchstart event
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            ) &&
            "ontouchstart" in document.documentElement
          ) {
            setSnap(true);
          }
        }
      } else {
        setSnap(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    getDeepLinkForInvite();
    // Cleanup the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (snap && isFeatureGateEnabled) {
    return (
      <CustomColumn
        height={featureName === "explorer" ? "calc(100svh - 100px)" : "100svh"}
        width="100vw"
        justifyContent="flex-end"
        background={feature.dividerImage ? "var(--background)" : "var(--base)"}>
        <CustomRow
          padding="0px 16px"
          justifyContent="center"
          alignItems="flex-end">
          <img
            src={feature.image}
            alt={featureName}
            style={{ width: "250px" }}
          />
        </CustomRow>
        {feature.dividerImage && (
          <div
            style={{
              position: "relative",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              borderTop: "1px solid var(--border-dark)",
              background: "var(--base)",
            }}>
            <img
              src={feature.dividerImage}
              alt={featureName + " emotion"}
              style={{ width: "100px", position: "absolute", top: "-50px" }}
            />
          </div>
        )}
        <CustomColumn
          padding="0px 16px"
          alignItems="center"
          flexShrink={0}
          background="var(--base)">
          <CustomColumn maxWidth="335px">
            <CustomText
              text={feature.title}
              fontSize="24px"
              fontWeight="700"
              color="var(--text-1)"
            />
            <PaddingComponent height="16px" />
            <CustomText text={feature?.description} />
          </CustomColumn>
        </CustomColumn>
        <PaddingComponent
          padding="32px 16px 96px"
          width="100%"
          style={{ textAlign: "center", background: "var(--base)" }}>
          <CustomButton
            text="Download Mobile App"
            disabled={!downloadAppLink}
            style={{
              width: "100%",
              maxWidth: "335px",
              padding: "12px 14px",
              height: "48px",
              color: "white",
            }}
            onClick={() => {
              // Open in same tab
              openInNewTab(downloadAppLink);
            }}></CustomButton>
        </PaddingComponent>
      </CustomColumn>
    );
  }
  return children;
};

export default FeatureGate;
