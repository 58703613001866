import {
  fetchGraphData,
  fetchTimelineResponse,
  getNodeTransfers,
} from "api/graph.api";
import { computeUrl } from "components/V2/Explorer/utils/explorer_utils";
import { fetchTokenHoldings, getActivitiesSummary } from "api/profile.api";
import { QUERY_KEYS } from "utils/query_utils";
import { ONE_DAY } from "utils/constants";
export const prefetchExplorerQueries = async ({
  identifier,
  queryClient,
  graphQueryKey,
  isHoldingPrefetch = true,
  fullQueryKey = null,
  isProfile = false,
  username = null,
}) => {
  if (!identifier) {
    return;
  }
  const frontendShareablePath = computeUrl({
    identifiers: identifier?.toString(),
    buildIdentityNodes: true,
  });

  queryClient.prefetchQuery({
    queryKey: fullQueryKey
      ? fullQueryKey
      : ["graph", graphQueryKey, false, [], []],
    queryFn: ({ signal }) => fetchGraphData(frontendShareablePath, signal),
    staleTime: ONE_DAY,
  });
  queryClient.prefetchQuery({
    queryKey: ["timeline", identifier],
    queryFn: () => fetchTimelineResponse({ identifiers: identifier }),
    staleTime: ONE_DAY,
  });
  queryClient.prefetchQuery({
    queryKey: ["graph-summary", username ?? identifier],
    queryFn: () => getActivitiesSummary({ identifier: username ?? identifier }),
    staleTime: ONE_DAY,
    enabled: !!(username ?? identifier),
  });
  queryClient.prefetchInfiniteQuery({
    queryKey: ["nodeTransfers", identifier, null, null, [], []],
    queryFn: () => getNodeTransfers({ identifier: identifier }),
    staleTime: ONE_DAY,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!pages || !lastPage) return null;
      return lastPage?.data?.timestamp_cursor
        ? lastPage?.data?.timestamp_cursor - 1
        : null;
    },
    enabled: !!identifier,
  });
  if (isHoldingPrefetch) {
    queryClient.prefetchQuery({
      queryKey: QUERY_KEYS.TOKEN_HOLDINGS({
        identifier: username ?? identifier?.toString(),
        forProtocolTreasury: false,
        slowPath: false,
      }),
      queryFn: () => fetchTokenHoldings({ identifier: username ?? identifier }),
      staleTime: 1000 * 60 * 5,
    });
  }
};
