import Refetch from "components/UI/Refetch/Refetch";
import React from "react";
import Content from "./Content";
import GhostTokenHoldings from "components/V2/Profile/ProfileDetails/Portfolio/TokenHoldings/GhostTokenHoldings";
import { PaddingComponent } from "components/UI/Components/Components";
import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import { EMPTY_STATES } from "zxStyles/constants";

const ProtocolHoldingsRenderer = ({
  filteredProtocolHoldings,
  protocolHoldingsQuery,
  chainProfiles,
  emptyCondition,
  rightPane = false,
}) => {
  const { isLoading, isError, refetch } = protocolHoldingsQuery;

  if (isError && !filteredProtocolHoldings?.length)
    return (
      <Refetch
        onClick={() => {
          refetch();
        }}
      />
    );

  if (isLoading || filteredProtocolHoldings?.last_updated_at === -1) {
    return (
      <div className="token-holdings">
        <div className="table">
          <div className="table__tbody">
            <PaddingComponent height="16px" />
            <GhostTokenHoldings rightPane={rightPane} />
          </div>
        </div>
      </div>
    );
  }

  if (emptyCondition) {
    return <ZxEmptyState state={EMPTY_STATES.PROTOCOL} text="No Protocols" />;
  }

  return (
    <>
      <PaddingComponent height="16px" />
      <Content
        holdings={filteredProtocolHoldings}
        rightPane={rightPane}
        chainProfiles={chainProfiles}
      />
    </>
  );
};

export default ProtocolHoldingsRenderer;
