import React from "react";
import Lottie from "react-lottie";
import classes from "./pageNotFound.module.css";
import animationData from "./animationData.json";
import {
  CustomButtonStyle,
  CustomColumn,
  CustomRow,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import { House } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

function PageNotFound() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <BaseWrapper mixpanelEvent="PageNotFound">
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Lottie options={defaultOptions} height={150} width={300} />
          <CustomColumn maxWidth="710px">
            <CustomText
              text="Whoops! Something went wrong"
              fontSize="24px"
              fontWeight="600"
              color="var(--text-1)"
            />
            <PaddingComponent height="16px" />
            <CustomText
              text="Sorry, we can’t find the page that you were looking for. It might have been removed or doesn’t exist anymore."
              fontSize="18px"
            />
            <PaddingComponent height="32px" />
            <CustomRow justifyContent="center">
              <CustomButtonStyle>
                <Link to={"/"}>
                  <CustomRow alignItems="center">
                    <House size="20px" color="var(--text-white)" />
                    <PaddingComponent width="4px" />
                    <CustomText
                      color="var(--text-white)"
                      fontSize="15px"
                      fontWeight="600"
                      text="Back to home"
                    />
                  </CustomRow>
                </Link>
              </CustomButtonStyle>
            </CustomRow>
          </CustomColumn>
        </div>
      </div>
    </BaseWrapper>
  );
}

export default PageNotFound;
