import { CustomRow, PaddingComponent } from "../../../UI/Components/Components";
import classes from "./ScreenerTable.module.css";
const ScreenerTableHeader = ({ items, rightPadding = "60px" }) => {
  return (
    <div className={classes.table}>
      <div className={`${classes.tableRow} ${classes.tableHeader}`}>
        {[...items].map((item, index) => (
          <div
            key={`${item.name}_${index}`}
            className={classes.headerText}
            style={{
              flex: item.flex,
              textAlign: item.alignment ?? "left",
            }}>
            <PaddingComponent
              paddingRight={index === items.length - 1 ? rightPadding : "8px"}>
              <CustomRow>
                {index === 0 && <div className={classes.index}>#</div>}
                <div
                  style={{
                    flex: item.flex,
                    textAlign: item.alignment ?? "left",
                  }}>
                  {item.name}
                </div>
              </CustomRow>
            </PaddingComponent>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScreenerTableHeader;
