import ConditionalLink from "shared/ConditionalLink";
import classes from "./tokenInfo.module.css";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import { TransparentButton } from "components/UI/Components/Components";
import { getTrimmedString } from "utils/misc";
import { IMAGE_TYPES } from "components/UI/Image";

const TokenInfo = ({
  token,
  truncate = true,
  iconClassName = classes.tokenIcon,
  chainClassName = classes.chainIcon,
  nameClassName = classes.tokenName,
  wrapperClassName = classes.tokenInfo,
  showImageFullHeightWidth = false,
  iconHeight = "16px",
  iconWidth = "16px",
  hideName = false,
  noRedirection = false,
  noButton = false,
  chainProfiles,
}) => {
  const { token_link, token_metadata, token_name, chain_id, address } =
    token || {};

  const link =
    token_link ?? token_metadata?.link ?? `/${chain_id}/token/${address}`;

  return (
    <div className={`${wrapperClassName} ${!truncate ? classes.noShrink : ""}`}>
      <TokenInfoWrapper
        tokenLink={link}
        noRedirection={noRedirection}
        noButton={noButton}>
        <IconWithChain
          src={token?.token_metadata?.logo}
          imageType={IMAGE_TYPES.TOKEN}
          chain={token?.chain_id}
          iconClassName={iconClassName}
          alt={token?.token_metadata?.name}
          chainClassName={chainClassName}
          chainProfiles={chainProfiles}
          iconHeight={iconHeight}
          iconWidth={iconWidth}
          showImageFullHeightWidth={showImageFullHeightWidth}
        />
      </TokenInfoWrapper>

      {!hideName && (
        <TokenInfoWrapper
          noRedirection={noRedirection}
          tokenLink={link}
          className={`${truncate ? classes.tokenNameWrapper : ""}`}
          noButton={noButton}>
          <PPLxTooltip
            title={token_name + " on " + token?.chain_id}
            arrow
            placement="top">
            <span className={nameClassName}>
              {getTrimmedString(token_metadata?.symbol, 10)}
            </span>
          </PPLxTooltip>
        </TokenInfoWrapper>
      )}
    </div>
  );
};

const TokenInfoWrapper = ({
  children,
  className,
  noRedirection,
  tokenLink,
  noButton,
  ...props
}) => {
  if (noRedirection) {
    if (noButton) {
      return (
        <div
          className={`${classes.tokenIconWrapper} ${className}`}
          {...props}
          style={{
            cursor: "default",
          }}>
          {children}
        </div>
      );
    }
    return (
      <TransparentButton
        className={`${classes.tokenIconWrapper} ${className}`}
        {...props}>
        {children}
      </TransparentButton>
    );
  }
  return (
    <ConditionalLink
      to={{ pathname: tokenLink }}
      sameTab={false}
      className={`${classes.tokenIconWrapper} ${className}`}
      {...props}>
      {children}
    </ConditionalLink>
  );
};
export default TokenInfo;
