import { InfoBox, CustomText } from "./Components";

export const SecondaryButton = ({ text, onClick }) => {
  return (
    <InfoBox
      onClick={onClick}
      padding="10px 16px"
      radius="30px"
      background="var(--elevation-1)"
      style={{ cursor: "pointer", justifySelf: "flex-end" }}>
      <CustomText
        text={text}
        fontWeight="600"
        fontSize="13px"
        letterSpacing="-0.26px"
      />
    </InfoBox>
  );
};
