import classes from "./Filters.module.css";
import { useState, useEffect, useMemo, useRef } from "react";
import TokenRowTile from "./TokenRowTile";
import ProtocolTokenRowTile from "./ProtocolTokenRowTile";
import useTokenHoldings from "../Portfolio/TokenHoldings/useTokenHoldings";
import useFetchProtocolHoldings from "customHooks/fetch/useFetchProtocolHoldings";
import { useParams } from "react-router-dom";
import { produce } from "immer";

import FiltersSearchBar from "./FiltersSearchBar";
import {
  getHoldingsAfterSearch,
  getProtcolHoldingsTokenAddressList,
  getProtocolHoldingsAfterSearch,
  getHoldingsWithoutNativeTokens,
} from "utils/profile_utils";
const HoldingsTokenFiltersList = ({
  identifier,
  tokensSelected,
  setTokensSelected,
  protocolsSelected,
  setProtocolsSelected,
}) => {
  const { active_tab } = useParams();
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  var { data: tokenHoldingsData } = useTokenHoldings({
    identifier,
    forProtocolTreasury: false,
  });
  const { data: protocolHoldingsData } = useFetchProtocolHoldings({
    identifier,
  });
  const dummyProtocolHoldingsDataForSearch = useRef(null);
  const [protocolHoldings, setProtocolHoldingsData] = useState(null);

  const modifiedHoldings = useMemo(
    () =>
      produce(tokenHoldingsData, (draft) => {
        if (active_tab === "activities" && draft?.holdings) {
          getHoldingsWithoutNativeTokens(draft);
        }
      }),
    [active_tab, tokenHoldingsData]
  );
  const [holdingsData, setHoldings] = useState(modifiedHoldings?.holdings);

  useEffect(() => {
    //get unique tokens from protocol holdings
    const filteredProtocols = getProtcolHoldingsTokenAddressList({
      protocolHoldings: protocolHoldingsData,
      tokenHoldings: modifiedHoldings,
    });
    dummyProtocolHoldingsDataForSearch.current = filteredProtocols;
    setProtocolHoldingsData(filteredProtocols);
  }, [protocolHoldingsData, modifiedHoldings]);

  useEffect(() => {
    if (searchTerm) {
      const holdingsData = getHoldingsAfterSearch({
        tokenHoldingsData: modifiedHoldings,
        searchTerm,
      });
      const protocolsSearchedData = getProtocolHoldingsAfterSearch({
        protocolHoldings: dummyProtocolHoldingsDataForSearch.current,
        protocolsMetadata: protocolHoldingsData?.tokens_metadata,
        searchTerm: searchTerm,
      });
      setHoldings(holdingsData);
      setProtocolHoldingsData(protocolsSearchedData);
    } else {
      setHoldings(modifiedHoldings?.holdings);
      setProtocolHoldingsData(dummyProtocolHoldingsDataForSearch.current);
    }
  }, [searchTerm, modifiedHoldings, protocolHoldingsData]);

  return (
    <div className={classes.chainFiltersList}>
      <div className={classes.horizontalPadding}>
        <FiltersSearchBar
          setSearchTerm={setSearchTerm}
          isSearchInputFocused={isSearchInputFocused}
          setIsSearchInputFocused={setIsSearchInputFocused}
        />
      </div>
      {holdingsData &&
        Object.values(holdingsData).map((entry, index) => {
          return (
            <div key={`token-filter-${entry}-${index}`}>
              <TokenRowTile
                tokensSelected={tokensSelected}
                setTokensSelected={setTokensSelected}
                token={entry}
                index={index}
              />
            </div>
          );
        })}
      {protocolHoldings &&
        Object.values(protocolHoldings).map((entry, index) => {
          return (
            <div key={`protocol-token-filter-${entry}-${index}`}>
              <ProtocolTokenRowTile
                tokensMetaData={protocolHoldingsData?.tokens_metadata}
                protocolsSelected={protocolsSelected}
                setProtocolsSelected={setProtocolsSelected}
                protocol={entry}
                index={index}
              />
            </div>
          );
        })}
    </div>
  );
};

export default HoldingsTokenFiltersList;
