import React from "react";
import { MessageSimple, useMessageContext } from "stream-chat-react";

import "./CustomMessage.css";
import { customRenderText } from "../../helpers";
import { CaretRight } from "@phosphor-icons/react";
import { CustomText } from "../../../../UI/Components/Components";

const TxnBlock = ({ text, link }) => {
  return (
    <div className="streaming-transaction-message">
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}>
        <div className={"streaming-transaction-message__header"}>
          <CustomText
            text="Transaction"
            color="var(--primary-color)"
            fontWeight="600"
          />
          <CaretRight size={16} color="var(--primary-color)" />
        </div>
      </a>
      <div className={"streaming-transaction-message__text"}>{text}</div>
    </div>
  );
};

const CustomMessage = (props) => {
  const { message } = useMessageContext();
  if (message.streaming) {
    return (
      <MessageSimple
        {...props}
        renderText={() => {
          return (
            <div className="custom-message__streaming">
              <div className="custom-message__streaming-icon" />
              <div className="custom-message__streaming-text">
                <TxnBlock text={message.text} link={message.url_custom_link} />
              </div>
            </div>
          );
        }}
      />
    );
  }
  return (
    <>
      <MessageSimple {...props} renderText={customRenderText} />
    </>
  );
};

export default CustomMessage;
