import {
  PaddingComponent,
  InfoBox,
  Divider,
} from "components/UI/Components/Components";
import { Skeleton } from "@mui/material";
import classes from "./TransactionsRightPanel.module.css";
import BasicInfoRowTile from "components/BasicInfoRowTile/BasicInfoRowTile";

export const TransactionDetailsGhost = () => {
  return (
    <div className={classes.container}>
      <InfoBox>
        <span className={classes.infoText}>Info</span>
        <PaddingComponent height={"10px"}></PaddingComponent>
        <Divider />
        <div>
          <PaddingComponent height={"16px"}></PaddingComponent>
          <Skeleton height={24} animation="wave" variant="text" />
          <PaddingComponent height={"16px"}></PaddingComponent>
          <Divider />
        </div>

        <PaddingComponent height={"16px"}></PaddingComponent>
        <div className={classes.detailsInfoContainer}>
          <BasicInfoRowTile title="Hash">
            <div className={classes.detailsTextRow}>
              <Skeleton
                width={180}
                height={24}
                animation="wave"
                variant="text"
              />
            </div>
          </BasicInfoRowTile>
          <BasicInfoRowTile title="Value">
            <div className={classes.detailsTextRow}>
              <Skeleton
                width={180}
                height={24}
                animation="wave"
                variant="text"
              />
            </div>
          </BasicInfoRowTile>
          <BasicInfoRowTile title="Gas Fee">
            <div className={classes.detailsTextRow}>
              <Skeleton
                width={180}
                height={24}
                animation="wave"
                variant="text"
              />
            </div>
          </BasicInfoRowTile>
          <BasicInfoRowTile title="Status">
            <div className={classes.detailsTextRow}>
              <Skeleton
                width={180}
                height={24}
                animation="wave"
                variant="text"
              />
            </div>
          </BasicInfoRowTile>
          <BasicInfoRowTile title="Block No">
            <div className={classes.detailsTextRow}>
              <Skeleton
                width={180}
                height={24}
                animation="wave"
                variant="text"
              />
            </div>
          </BasicInfoRowTile>
          <BasicInfoRowTile title="Txn Index">
            <div className={classes.detailsTextRow}>
              <Skeleton
                width={180}
                height={24}
                animation="wave"
                variant="text"
              />
            </div>
          </BasicInfoRowTile>
          <BasicInfoRowTile title="Nonce">
            <div className={classes.detailsTextRow}>
              <Skeleton
                width={180}
                height={24}
                animation="wave"
                variant="text"
              />
            </div>
          </BasicInfoRowTile>
        </div>
      </InfoBox>
    </div>
  );
};
