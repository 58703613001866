import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import classes from "./NftItem.module.css";
import NFTPreview from "./NFTPreview";
import { abbreviateNumber, conditionalClass } from "utils/misc";
import { CustomRow } from "components/UI/Components/Components";
import ConditionalLink from "shared/ConditionalLink";
import { ArrowSquareOut, CaretDown, CaretUp } from "@phosphor-icons/react";

export const NFTItem = ({
  nftItem,
  chainId,
  collectionDetails,
  rightPane = false,
  hideSubtitle = false,
  onClick = () => {},
  hasGalleryModal = true,
  isGalleryThumbnail = false,
  totalCollectionLength = 1,
  style = {},
  overrideLink = null,
  displayValueOverride = null,
}) => {
  let nftDetailsLink = "";
  if (nftItem.token_id || overrideLink) {
    nftDetailsLink = overrideLink
      ? overrideLink
      : `/nft/${nftItem?.chain_id ?? chainId}/${
          nftItem?.contract_address
        }/${nftItem?.token_id}`;
  }
  const displayValue = displayValueOverride
    ? displayValueOverride
    : collectionDetails?.[collectionDetails?.token_value_source]?.token_value
          ?.display_value ?? collectionDetails?.total_token_value?.value
      ? `${abbreviateNumber(
          collectionDetails?.total_token_value?.value / totalCollectionLength
        )} ${collectionDetails?.total_token_value?.symbol}`
      : null;
  return (
    <ConditionalLink
      {...(hasGalleryModal || isGalleryThumbnail
        ? { onClick }
        : {
            to: nftDetailsLink,
          })}
      className={`${classes.nftItem} ${conditionalClass(
        rightPane,
        classes.rightPane
      )}`}
      style={style}>
      <NFTPreview
        media={
          nftItem?.previews?.image_small_url ??
          nftItem?.display_image ??
          nftItem?.image_url
        }
        audioPath={nftItem?.audio_url}
        name={nftItem?.name}
        rightPane={rightPane}
      />
      <NFTInfo
        name={nftItem?.name}
        totalValue={collectionDetails?.total_token_value?.display_value}
        displayValue={displayValue}
        valueType={
          displayValueOverride
            ? "override"
            : collectionDetails?.token_value_source
        }
        hideSubtitle={hideSubtitle}
      />
      {isGalleryThumbnail && nftDetailsLink && (
        <div className={classes.externalLinkButton}>
          <PPLxTooltip title={"Go to NFT Details Page"}>
            <ConditionalLink to={nftDetailsLink}>
              <div className={classes.iconWrapper}>
                <ArrowSquareOut size={16} color="var(--text-1)" weight="fill" />
              </div>
            </ConditionalLink>
          </PPLxTooltip>
        </div>
      )}
    </ConditionalLink>
  );
};

export const NFTInfo = ({
  name,
  displayValue,
  totalValue,
  valueType,
  customSubTitle,
  isExpanded,
  setIsExpanded,
  extraNfts = false,
  rightPane = false,
  hideSubtitle = false,
  nftsCount,
}) => {
  return (
    <div
      className={`${classes.infoWrapper} ${
        extraNfts ? classes.extraNfts : ""
      } ${conditionalClass(rightPane, classes.rightPane)} ${
        hideSubtitle ? classes.centerAlign : ""
      }`}>
      {customSubTitle ? (
        <button
          className={classes.title}
          onClick={() => setIsExpanded((prev) => !prev)}>
          <CustomRow alignItems="baseline" gap="4px" flexGrow={1}>
            <span className={classes.truncate}>{name}</span>
            {nftsCount ? `(${nftsCount})` : ""}
          </CustomRow>
          <CustomRow alignItems="center" flexShrink={0}>
            {isExpanded ? (
              <CaretUp size={16} color="var(--text-1)" />
            ) : (
              <CaretDown size={16} color="var(--text-1)" />
            )}
          </CustomRow>
        </button>
      ) : (
        <PPLxTooltip title={name}>
          <div className={classes.title}>
            <CustomRow alignItems="baseline" gap="4px">
              <span className={classes.truncate}>{name}</span>
            </CustomRow>
          </div>
        </PPLxTooltip>
      )}
      {!hideSubtitle && (
        <>
          <div className={classes.subTitle}>
            {displayValue
              ? `${
                  valueType === "override"
                    ? ""
                    : valueType === "floor_price"
                      ? "Floor"
                      : "Top Bid"
                } ${displayValue}`
              : totalValue
                ? `Total ${totalValue}`
                : null}
          </div>
          {customSubTitle && (
            <div className={classes.subTitle}>{customSubTitle}</div>
          )}
        </>
      )}
    </div>
  );
};
