import { getBundleData } from "api/profile.api";
import {
  CustomColumn,
  LoadingHoverCard,
} from "components/UI/Components/Components";
import withLoader from "components/core/withLoader";
import classes from "./UserWallets.module.css";
import { getTrimedAddress } from "utils/misc";

const UserWalletsList = ({ queryData, onSelectAddress }) => {
  const wallets = queryData?.data?.data?.bundle?.wallets;
  return (
    <div className={classes.walletBox}>
      <CustomColumn>
        {wallets.map((wallet) => {
          const shortAddress = getTrimedAddress(wallet.address);
          return (
            <div
              key={wallet.address}
              className={classes.walletTile}
              onClick={() => {
                onSelectAddress(wallet.address);
              }}>
              {shortAddress}
            </div>
          );
        })}
      </CustomColumn>
    </div>
  );
};

export default withLoader(UserWalletsList, getBundleData, {
  queryKey: (props) => ["wallets-list", props.identifier],
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  retry: 2,
  CustomLoader: () => <LoadingHoverCard minWidth={200} />,
  renderNotFound: false,
});
