import FeedMediaType from "components/FeedMediaType/FeedMediaType";
import Loader from "components/UI/Loader/Loader";
import React, { useState } from "react";
import { getTypeFromUrl } from "utils/misc";
import classes from "./galleryModal.module.css";

const GalleryModalPreview = ({ mediaItem, onClose = () => {} }) => {
  const [type, setType] = useState(
    (mediaItem?.content_type ?? getTypeFromUrl(mediaItem?.src)) || "image"
  );
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className={classes.mediaPreview} onClick={onClose}>
      {isLoading && <Loader />}
      {mediaItem?.src?.length ? (
        <FeedMediaType
          renderedFrom="GalleryModal"
          type={type}
          onClick={(e) => e.stopPropagation()}
          mediaItem={mediaItem}
          setType={setType}
          className={classes.mediaImage}
          setIsLoading={setIsLoading}
          key={`image-${mediaItem?.src}`}
        />
      ) : null}
    </div>
  );
};

export default GalleryModalPreview;
