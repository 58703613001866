import withLoader from "components/core/withLoader";
import React, { useContext, useEffect, useState } from "react";
import { getBlockDetails } from "./api";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";

import { useParams } from "react-router-dom";
import {
  Container,
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import {
  formatUnixTimestamp,
  getFormattedDate,
  getTrimedAddress,
  referenceTimeAgo,
} from "utils/misc";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";
import {
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom";
import { BlockStatusRow } from "./Blocks";
import Copyable from "components/ValueType/Copyable/Copyable";
import classes from "./styles.module.css";
import Image from "components/UI/Image";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const BlockDetailsPage = ({ queryData }) => {
  const { setTitle } = useContext(LeftSideHeaderContext);
  const { blockNumber, chain_id, active_tab } = useParams();

  useEffect(() => {
    setTitle("Block Details");
  }, [setTitle]);
  const currentStep = active_tab || "Details";
  const tabsList = [
    {
      title: "Details",
      link: "details",
    },
  ];
  let match = useRouteMatch();

  return (
    <BaseWrapper mixpanelEvent="BlockDetailsPage">
      <CustomColumn margin="36px 0 0 0">
        <BlockInfoHeader
          queryData={queryData}
          blockNumber={blockNumber}
          chainId={chain_id}
        />
        <PaddingComponent height="24px" />
        <CustomLinkTabBar
          currentStep={currentStep}
          tabsList={tabsList}
          zIndex={TABBAR_ZINDEX.HIGH}
        />
        <Switch>
          <Route path={`${match.path}/details`}>
            <SingleBlockDetails data={queryData} />
          </Route>

          {/* <Route path={`${match.path}/transactions`}>
          <SingleBlockDetails />
        </Route> */}
        </Switch>
      </CustomColumn>
    </BaseWrapper>
  );
};

export const BlockInfoHeader = ({ queryData, blockNumber, chainId }) => {
  return (
    <CustomRow gap="20px">
      <Image className={classes.blockChainLogo} src={queryData.chain_logo} />
      <CustomColumn>
        <CustomText
          color="var(--text-1)"
          fontSize="15px"
          fontWeight="600"
          text={`Block ${blockNumber}`}
          textAlign="left"
        />
        <CustomText
          textAlign="left"
          text={`${chainId} ${formatUnixTimestamp(queryData?.timestamp)}`}
          textTransform="capitalize"
        />
      </CustomColumn>
    </CustomRow>
  );
};

export const SingleBlockDetails = ({ data }) => {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const resetNow = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(resetNow);
  });
  const timeAgo = referenceTimeAgo({
    date: data?.timestamp * 1000,
    referenceTime: now,
  });
  return (
    <>
      <CustomColumn
        borderRadius="6px"
        padding="16px 20px"
        width="100%"
        border="1px solid var(--border-dark)"
        background="var(--base)"
        margin="16px 0 0 0"
        gap="10px">
        <CustomText fontWeight="600" textAlign="left" text="Info" />
        <CustomColumn gap="16px">
          <Divider />
          {data?.status && (
            <BlockInfoRow
              left="Status"
              right={
                <BlockStatusRow
                  fontSize="15px"
                  fontWeight="500"
                  showTimeAgo={false}
                  block={data}
                />
              }
            />
          )}
          {data?.timestamp && (
            <BlockInfoRow
              left="Timestamp"
              right={`${timeAgo}  ${
                timeAgo === "just now" ? "" : "ago"
              } ( ${getFormattedDate(data?.timestamp)} )`}
            />
          )}
          {(data?.gas_used || data?.gas_limit || data?.base_fee_per_gas) && (
            <Divider />
          )}

          {data?.gas_used && (
            <BlockInfoRow left="Gas Used" right={data.gas_used} />
          )}
          {data?.gas_limit && (
            <BlockInfoRow left="Gas Limit" right={data.gas_limit} />
          )}
          {data?.base_fee_per_gas && (
            <BlockInfoRow
              left="Base Fee Per Gas"
              right={data.base_fee_per_gas}
            />
          )}
          {(data?.total_difficulty || data?.size) && <Divider />}
          {data?.total_difficulty && (
            <BlockInfoRow
              left="Total Difficulty"
              right={data.total_difficulty}
            />
          )}
          {data?.size && <BlockInfoRow left="Size" right={data.size} />}
        </CustomColumn>
      </CustomColumn>
      <AdditionalBlockDetails data={data} />
      <PaddingComponent height="16px" />
    </>
  );
};

export const AdditionalBlockDetails = ({ data }) => {
  return (
    <CustomColumn
      borderRadius="6px"
      padding="16px 20px"
      width="100%"
      border="1px solid var(--border-dark)"
      background="var(--base)"
      margin="16px 0 0 0"
      gap="10px">
      <CustomText fontWeight="600" textAlign="left" text="Addtional Details" />
      <CustomColumn gap="16px">
        <Divider />
        {data?.hash && (
          <BlockInfoRow
            left="Hash"
            right={getTrimedAddress(data.hash)}
            address={data.hash}
          />
        )}
        {data?.parent_hash && (
          <BlockInfoRow
            left="Parent Hash"
            right={getTrimedAddress(data.parent_hash)}
            address={data.parent_hash}
          />
        )}
        {data?.nonce && <BlockInfoRow left="Nonce" right={data.nonce} />}
      </CustomColumn>
    </CustomColumn>
  );
};

export const BlockInfoRow = ({ left, right, address }) => {
  return (
    <CustomRow alignItems="center">
      <Container padding="0" width="150px">
        <CustomText text={left} fontSize="15px" />
      </Container>
      <CustomRow alignItems="center">
        <CustomText color="var(--text-1)" fontSize="15px" text={right} />
        {address && <Copyable content={address} />}
      </CustomRow>
    </CustomRow>
  );
};

export default withLoader(BlockDetailsPage, getBlockDetails, {
  queryKey: ({ chainId, chainType, blockNumber }) => [
    "blockDetails",
    chainId,
    chainType,
    blockNumber,
  ],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 2,
  renderNotFound: false,
});
