import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import classes from "components/FeedItemV2/deletePostDialog.module.css";

const DiscardConfirmationDialog = ({
  handleDialogToggle,
  openDialog,
  discardCallback = () => {},
}) => {
  const handleDiscardConfirmation = () => {
    handleDialogToggle();
    discardCallback();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogToggle}
      classes={{ paper: classes.deleteDialog }}
      //
      sx={{
        ".MuiPaper-root": {
          background: "var(--base)",
        },
      }}>
      <DialogTitle>
        <div className={classes.dialogTitle}>Discard post</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className={classes.dialogContent}>
            Are you sure you want to discard this post?
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <button
          onClick={handleDialogToggle}
          className={`${classes.dialogButton} ${classes.cancelButton}`}>
          No
        </button>
        <button
          onClick={handleDiscardConfirmation}
          autoFocus
          className={`${classes.dialogButton} ${classes.confirmButton}`}>
          Yes
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscardConfirmationDialog;
