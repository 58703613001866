import React, { useContext } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { GalleryContext } from "contextStates/Gallery";

const ActionButtons = () => {
  const { handleActiveMediaInfo, index, nftIndices } =
    useContext(GalleryContext);

  // // media length without the items whose src is null/""/undefined
  // const [filteredMediaLength] = useState(
  //   feed?.media?.filter((item) => item?.src?.length).length
  // );

  return (
    <div className="action-buttons">
      <button
        className="slide-button slide-button__prev action-button"
        disabled={index === 0}
        onClick={() => handleActiveMediaInfo(index - 1)}>
        <NavigateBeforeIcon />
      </button>
      <button
        className="slide-button slide-button__next action-button"
        disabled={index + 1 === nftIndices.length}
        onClick={() => handleActiveMediaInfo(index + 1)}>
        <NavigateNextIcon />
      </button>
    </div>
  );
};

export default ActionButtons;
