import classes from "./PrivateWalletsList.module.css";
import { useState } from "react";
import {
  CustomRow,
  CustomText,
  PaddingComponent,
  InfoBox,
  FlexGrow,
  Divider,
} from "components/UI/Components/Components";
import WalletVerifiedIcon from "assets/svg/wallet-verified-icon.svg";
import WalletPrivateIcon from "assets/svg/wallet-private.svg";
import AddressComponent from "components/UI/Components/AddressComponent";
import { useEffect } from "react";
import { useCallback, useMemo } from "react";
import { CircularProgress } from "@mui/material";

const PrivateWalletsList = ({
  connectionData,
  sendOrUpdateConnection,
  rejectConnection,
  acceptConnection,
  connectionIdentity,
  loggedInUserDetails,
}) => {
  const [wallets, setWallets] = useState(
    connectionData?.private_wallets_suggestions || []
  );
  const [primaryButtonText, setPrimaryButtonText] = useState(null);
  const [secondaryButtonText, setSecondaryButtonText] = useState(null);
  const [primaryButtonLoading, setPrimaryButtonLoading] = useState(false);
  const [secondaryButtonLoading, setSecondaryButtonLoading] = useState(false);

  const isSharedWalletsChanged = useCallback(() => {
    return wallets.filter((w) => w.isSelected !== w.is_shared).length > 0;
  }, [wallets]);

  const isSentRequest = useMemo(
    () => connectionData?.sender_identity_id === loggedInUserDetails?.id,
    [connectionData, loggedInUserDetails]
  );

  useEffect(() => {
    setWallets(
      connectionData?.private_wallets_suggestions?.map((w) => ({
        ...w,
        isSelected: w.is_shared,
      })) || []
    );
    setPrimaryButtonText(
      getPrimaryButtonText({ connectionData, isSentRequest })
    );
    setSecondaryButtonText(
      getSecondaryButtonText({
        connectionData,
        isSentRequest,
      })
    );
  }, [connectionData, isSentRequest]);

  return (
    <div className={classes.walletsBox}>
      {wallets.length > 0 ? (
        <div className={classes.table}>
          <CustomRow padding="16px 20px 4px 20px">
            <CustomText
              text={"Choose private wallets to share"}
              fontSize="15px"
              fontWeight="500"
              color="var(--text-1)"
            />
          </CustomRow>
          <WalletsTableHeader
            selected={
              wallets.filter((w) => w.isSelected).length === wallets.length
            }
            onSelect={(e) => {
              const isAnySelected = wallets
                .map((w) => w.isSelected)
                .includes(true);
              wallets.forEach((w) => (w.isSelected = !isAnySelected));
              setWallets([...wallets]);
            }}></WalletsTableHeader>

          {wallets.map((addressInfo, index) => (
            <WalletsTableRow
              key={index}
              wallet={addressInfo}
              index={index}
              onSelect={(e) => {
                const found = wallets.findIndex((w) => w.address === e.address);
                wallets[found].isSelected = !wallets[found].isSelected;
                setWallets([...wallets]);
              }}
            />
          ))}

            <div className={classes.shareMessage}>
              After mutual sharing, both parties can view tokens, protocols,
              NFTs, and transaction feeds of current and future in the shared
              wallets until access is revoked.
            </div>
        </div>
      ) : (
        <PaddingComponent padding="16px 0px">
          <InfoBox>
            <CustomRow padding="0 0 16px 0">
              <CustomText
                text={`Choose private wallets to share`}
                fontSize="15px"
                fontWeight="500"
                color="var(--text-1)"
              />
            </CustomRow>
            <CustomRow>
              <CustomRow gap="4px" flexGrow={1} alignItems="center">
                <img
                  src={WalletPrivateIcon}
                  alt="private wallet"
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "4px",
                  }}
                />
                <CustomText
                  text={`0 Wallets`}
                  fontSize="13px"
                  color="var(--text-2)"></CustomText>
              </CustomRow>
              <a
                href={`/${loggedInUserDetails?.id}/wallets?openAddWallet`}
                className={classes.button}>
                Add Private Wallets
              </a>
            </CustomRow>
          </InfoBox>
        </PaddingComponent>
      )}
      {!isSentRequest && connectionData?.shared_wallets_by_them_data && (
        <PaddingComponent padding="16px 0">
          <InfoBox>
            <CustomRow padding="0 0 8px 0">
              <CustomText
                text={`${connectionIdentity?.display_name}'s shared wallet(s)`}
                fontSize="15px"
                fontWeight="500"
                color="var(--text-1)"
              />
            </CustomRow>
            <CustomRow padding="0 0 8px 0">
              <FlexGrow>
                <CustomText
                  text="WALLETS"
                  fontSize="11px"
                  fontWeight="500"
                  color="var(--text-2)"></CustomText>
              </FlexGrow>
              <div>
                <CustomText
                  text="NETWORTH"
                  fontSize="11px"
                  fontWeight="500"
                  color="var(--text-2)"></CustomText>
              </div>
            </CustomRow>
            <Divider />
            <CustomRow padding="8px 0" alignItems="center">
              <CustomRow gap="4px" flexGrow={1} alignItems="center">
                <img
                  src={WalletPrivateIcon}
                  alt="private wallet"
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "4px",
                  }}
                />
                <CustomText
                  text={`${connectionData?.shared_wallets_by_them_data?.num_wallets} Wallets`}
                  fontSize="13px"
                  color="var(--text-2)"></CustomText>
              </CustomRow>
              <div>
                <CustomText
                  text={
                    connectionData?.shared_wallets_by_them_data?.net_worth
                      .display_value
                  }
                  fontSize="13px"
                  color="var(--text-1)"></CustomText>
              </div>
            </CustomRow>
          </InfoBox>
        </PaddingComponent>
      )}
      {connectionData?.shared_wallets_by_them_list?.length > 0 && (
        <PaddingComponent padding="16px 0">
          <InfoBox>
            <CustomRow padding="0 0 8px 0">
              <CustomText
                text={`${connectionIdentity?.display_name}'s shared wallet(s)`}
                fontSize="15px"
                fontWeight="500"
                color="var(--text-1)"
              />
            </CustomRow>
            <CustomRow padding="0 0 8px 0">
              <FlexGrow>
                <CustomText
                  text="WALLET"
                  fontSize="11px"
                  fontWeight="500"
                  color="var(--text-2)"></CustomText>
              </FlexGrow>
              <div>
                <CustomText
                  text="NETWORTH"
                  fontSize="11px"
                  fontWeight="500"
                  color="var(--text-2)"></CustomText>
              </div>
            </CustomRow>
            <Divider />
            <PaddingComponent height="8px" />
            {connectionData?.shared_wallets_by_them_list?.map(
              (wallet, index) => (
                <CustomRow padding="8px 0">
                  <CustomRow gap="4px" flexGrow={1} alignItems="center">
                    <img
                      src={WalletPrivateIcon}
                      alt="private wallet"
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "4px",
                      }}
                    />
                    <AddressComponent
                      index={index + 1}
                      address={wallet?.address}
                      textClassName={classes.addressText}
                    />
                  </CustomRow>
                  <CustomText
                    text={`${wallet.net_worth?.display_value}`}
                    fontSize="13px"
                    color="var(--text-1)"
                  />
                </CustomRow>
              )
            )}
          </InfoBox>
        </PaddingComponent>
      )}
      <PaddingComponent padding="16px 0">
        <CustomRow gap="16px" justifyContent="flex-end">
          {secondaryButtonText && (
            <button
              className={`${classes.button} ${classes.secondaryButton}`}
              onClick={async () => {
                setSecondaryButtonLoading(true);
                rejectConnection();
                setSecondaryButtonLoading(false);
              }}>
              <CustomRow gap="4px" alignItems="center">
                {secondaryButtonLoading && (
                  <CircularProgress color="inherit" size={12} />
                )}
                {secondaryButtonText}
              </CustomRow>
            </button>
          )}
          {primaryButtonText && (
            <button
              disabled={
                connectionData?.status === "not_sent"
                  ? wallets.filter((w) => w.isSelected).length === 0
                  : !isSharedWalletsChanged()
              }
              className={classes.button}
              onClick={async () => {
                setPrimaryButtonLoading(true);
                const selectedWallets = wallets
                  .filter((w) => w.isSelected)
                  ?.map((w) => w.address);

                await (connectionData?.status !== "pending"
                  ? sendOrUpdateConnection({
                      selectedWallets,
                    })
                  : isSentRequest
                  ? sendOrUpdateConnection({
                      selectedWallets,
                    })
                  : acceptConnection({ selectedWallets }));
                setPrimaryButtonLoading(false);
              }}>
              <CustomRow gap="4px" alignItems="center">
                {primaryButtonLoading && (
                  <CircularProgress color="inherit" size={12} />
                )}
                {primaryButtonText}
              </CustomRow>
            </button>
          )}
        </CustomRow>
      </PaddingComponent>
    </div>
  );
};

const getPrimaryButtonText = ({ connectionData, isSentRequest }) => {
  if (connectionData?.status === "not_sent") {
    return "Send Request";
  } else if (connectionData?.status === "pending") {
    return isSentRequest ? "Save Changes" : "Share & Accept";
  } else if (connectionData?.status === "accepted") {
    return "Save Changes";
  } else if (connectionData?.status === "rejected") {
    return null;
  }
};

const getSecondaryButtonText = ({ connectionData, isSentRequest }) => {
  if (connectionData?.status === "not_sent") {
    return null;
  } else if (connectionData?.status === "pending") {
    return (isSentRequest ? "Cancel" : "Reject") + " Request";
  } else if (connectionData?.status === "accepted") {
    return "Disconnect Share";
  } else if (connectionData?.status === "rejected") {
    return null;
  }
};

const WalletsTableRow = ({ wallet, index, onSelect }) => {
  return (
    <div className={classes.tableRow} onClick={() => onSelect(wallet)}>
      <div className={classes.checkboxCol}>
        <input type="checkbox" checked={wallet.isSelected} />
      </div>
      <div className={classes.addressCol}>
        <CustomRow alignItems="center">
          <img
            src={WalletPrivateIcon}
            alt="private wallet"
            style={{ width: "16px", height: "16px", marginRight: "4px" }}
          />
          <AddressComponent
            index={index + 1}
            address={wallet?.address}
            textClassName={classes.addressText}
          />
          {wallet?.is_verified ? (
            <img
              src={WalletVerifiedIcon}
              alt="wallet verification"
              style={{ width: "16px", height: "16px", marginLeft: "4px" }}
            />
          ) : (
            ""
          )}
        </CustomRow>
      </div>
      <div className={classes.walletNameCol}>
        {wallet?.name ? (
          <CustomText
            text={wallet?.name}
            fontSize="13px"
            color="var(--text-1)"
          />
        ) : (
          ""
        )}
      </div>
      <div className={classes.networthCol}>
        <CustomText
          text={wallet?.net_worth?.display_value}
          fontSize="13px"
          color="var(--text-1)"></CustomText>
      </div>
    </div>
  );
};

const WalletsTableHeader = ({ onSelect, selected }) => {
  return (
    <div className={classes.tableHeader}>
      <div className={classes.checkboxCol}>
        <input
          type="checkbox"
          onChange={onSelect}
          checked={selected}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={classes.addressCol}>WALLET</div>
      <div className={classes.walletNameCol}>WALLET NAME</div>
      <div className={classes.networthCol}>NETWORTH</div>
    </div>
  );
};

export default PrivateWalletsList;
