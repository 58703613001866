import React from "react";
import classes from "./postSuccess.module.css";
import { ReactComponent as BubblesIcon } from "assets/svg/feed/success_bubbles.svg";
import { ReactComponent as SuccessIcon } from "assets/svg/Landing/circle_tick_success.svg";
const PostSuccess = ({ message }) => {
  return (
    <div className={classes.successWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.successIconWrapper}>
          <SuccessIcon className={classes.successIcon} />
        </div>
        <div className={classes.successMessage}>
          {message ?? "Post published!"}
        </div>
      </div>
      <div className={classes.backgroundWrapper}>
        <BubblesIcon />
      </div>
    </div>
  );
};

export default PostSuccess;
