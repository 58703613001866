import { useState } from "react";
import Image, { IMAGE_TYPES } from "components/UI/Image";

const IPFSMediaPreview = ({ ipfsLink, ...props }) => {
  const [type, setType] = useState("image");

  const handleVideoError = (event) => {
    event.target.onerror = null; // reset the error handler to avoid infinite loop
    setType("broken");
    console.error(`ipfs nft not found`, { ipfsLink });
  };

  if (type === "image") {
    return (
      <img src={ipfsLink} onError={() => setType("video")} alt="" {...props} />
    );
  }

  if (type === "video") {
    return (
      <video controls onError={handleVideoError} {...props}>
        <source src={ipfsLink} type="video/mp4" />
      </video>
    );
  }

  return (
    <Image
      imageType={IMAGE_TYPES.NFT}
      className={props?.nftImageClass}
      src={ipfsLink}
      fallbackClassName={props?.fallbackClassName}
    />
  );
};
export default IPFSMediaPreview;
