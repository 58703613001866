import { useState, useContext, useCallback } from "react";
import { addRemoveAddressFromBundle } from "api/profile.api";
import { GlobalContext } from "contextStates/Global";
import classes from "../../../../../../components/FollowEditButton/FollowForm/FollowForm.module.css";
import CustomTextField from "../../../../../../components/FollowEditButton/CustomTextField";
import classesComponents from "./WalletsComponents.module.css";
import { CircularProgress } from "@mui/material";
import classesButtons from "components/UI/Buttons/buttons.module.css";
import { ProfileContext } from "contextStates/ProfileContext";

const DeleteWalletPopUpForm = ({
  wallet,
  refetch,
  setShowPopUpCard,
  bundleData,
  isUserProfile,
}) => {
  const [reasonState, setReasonState] = useState("");
  const { handleErrorSnackbar, setSnackBarState } = useContext(GlobalContext);
  const [isRemoveAddressLoading, setIsRemoveAddressLoading] = useState(false);
  const { profileData, updateProfile, removeWalletFromProfileCache } =
    useContext(ProfileContext);
  const userData = isUserProfile ? profileData?.current?.details : bundleData;

  const disabled = !reasonState;

  const deleteAddressFromBundle = useCallback(() => {
    setIsRemoveAddressLoading(true);
    addRemoveAddressFromBundle({
      address: wallet?.address,
      reason: reasonState,
      identity_id: userData?.id,
      addOrRemove: "Remove",
    })
      .then((resp) => {
        setIsRemoveAddressLoading(false);
        setShowPopUpCard(false);
        refetch();
        if (updateProfile) {
          updateProfile();
          removeWalletFromProfileCache(wallet?.address);
        }
        setSnackBarState({
          open: true,
          message: "Deleted wallet from bundle",
          type: "success",
        });
      })
      .catch((error) => {
        setIsRemoveAddressLoading(false);
        handleErrorSnackbar(
          error,
          error?.response?.data?.errors?.errors ??
            "Failed to delete wallet from bundle",
          false
        );
      });
  }, [
    handleErrorSnackbar,
    reasonState,
    refetch,
    setShowPopUpCard,
    updateProfile,
    userData?.id,
    wallet?.address,
    setSnackBarState,
    removeWalletFromProfileCache,
  ]);

  return (
    <div className={classes.follow_form}>
      <h3 className={classes.title}>
        Are you sure you want to remove this wallet from{" "}
        {userData?.display_name}?
      </h3>
      {bundleData?.bundle?.is_crowdsourced && (
        <h3 className={classes.subtitle}>
          A private bundle will be created for you. This wallet will get a
          negative vote in the public bundle which will be reviewed.
        </h3>
      )}

      <div className={classes.form_group}>
        <CustomTextField
          size="large"
          placeholder="Reason"
          fullWidth
          multiline
          rows={3}
          value={reasonState}
          style={{ height: "100px" }}
          onChange={(e) => {
            setReasonState(e.target.value);
          }}
        />
      </div>
      <div className={classesComponents.buttons_row}>
        <button
          className={classesButtons.cancelButton}
          onClick={(e) => {
            e.stopPropagation();
            setShowPopUpCard(false);
          }}
          disabled={false}>
          <div>Cancel</div>
        </button>
        <button
          className={`${classesButtons.errorButton} ${
            disabled ? classes.disabled : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            deleteAddressFromBundle();
          }}
          disabled={disabled}>
          {isRemoveAddressLoading ? (
            <div className={classes.spinner_container}>
              <CircularProgress color="inherit" size={12} />
            </div>
          ) : (
            <div>Remove</div>
          )}
        </button>
      </div>
    </div>
  );
};

export default DeleteWalletPopUpForm;
