import { CustomColumn, Divider } from "components/UI/Components/Components";
import { PaddingComponent } from "../../UI/Components/Components";
import classes from "./Bookmarks.module.css";
import { useContext, useEffect, useRef } from "react";
import StatusRenderer from "components/Feed/NewPost/Components/StatusRenderer";
import useTriggerNextPaginatedActivitiesPage from "customHooks/useTriggerNextPaginatedActivitiesPage";
import GalleryContextProvider from "contextStates/Gallery";
import ContentRenderer from "components/FeedItemV2/ContentRenderer";
import LoginCheckWrapper from "components/core/LoginCheckWrapper";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { useFetchFeed } from "api/feed.api";

const Bookmarks = () => {
  const scrollableRef = useRef(null);
  const { setTitle } = useContext(LeftSideHeaderContext);

  useEffect(() => {
    setTitle("Your Bookmarks");
  }, [setTitle]);

  return (
    <BaseWrapper mixpanelEvent="Bookmarks">
      <LoginCheckWrapper
        title="Sign in to view your Bookmarks"
        subtitle="Stay notified on interactions and activities from your network and notables">
        <div id="scrollable-bookmarks" ref={scrollableRef}>
          <CustomColumn>
            <PaddingComponent height="24px" />
            <span className={classes.title}>Your Bookmarks</span>
            <PaddingComponent height="16px" />
            <Divider />
            <PaddingComponent height="4px" />
            <FeedActivitiesList scrollableRef={scrollableRef} />
          </CustomColumn>
        </div>
      </LoginCheckWrapper>
    </BaseWrapper>
  );
};

const FeedActivitiesList = ({ scrollableRef }) => {
  const {
    data: feedData,
    flattenedFeedData,
    isLoading,
    isSuccess,
    isError,
    refetch,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useFetchFeed({ isBookmark: true });

  useTriggerNextPaginatedActivitiesPage({
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  });

  return (
    <GalleryContextProvider>
      <div>
        {feedData?.pages?.map((page, pageIndex) =>
          page?.data?.map((groupedActivity, activityIndex) => (
            <ContentRenderer
              key={`grouped-activity-${groupedActivity.id ?? activityIndex}`}
              groupedActivity={groupedActivity}
              profiles={groupedActivity.profiles}
              identities={groupedActivity.identities}
              showReplies
              solidBg
            />
          ))
        )}
        <StatusRenderer
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          refetch={refetch}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          activities={flattenedFeedData}
          showShimmer={isFetchingNextPage}
          message="No bookmarks found."
        />
      </div>
    </GalleryContextProvider>
  );
};

export default Bookmarks;
