import { useContext, useEffect, useMemo } from "react";
import classes from "./BundlesTabList.module.css";
import { useLocation, useHistory } from "react-router-dom";
import { ProfileContext } from "contextStates/ProfileContext";
import { useBundleInfo } from "components/Pages/AppModule/RightSideBar/apis/useBundleInfo";
import { getUniqueId } from "utils/event_tracking";

const BundlesTabList = () => {
  const location = useLocation();
  const history = useHistory();
  const { setSelectedGroupIds, identifier } = useContext(ProfileContext);
  const { data: bundleData } = useBundleInfo({
    identifier: identifier?.current,
    bundleSuggestions: true,
  });

  const FilterPills = useMemo(() => {
    const groups = bundleData?.data?.data?.groups;
    return [
      { title: "All", param: ["groups", null] },
      ...(Object.keys(groups || {}).map((id) => {
        const group = groups[id];
        return { title: group.group_name, param: ["groups", group.id] };
      }) || []),
    ];
  }, [bundleData]);

  useEffect(() => {
    const group = new URLSearchParams(location.search)
      .getAll("groups")
      .map(parseInt);
    setSelectedGroupIds(group, bundleData?.data?.data?.bundle.wallets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, bundleData]);

  return (
    <div className={classes.filtersContainer}>
      {FilterPills.map((f) => {
        const params = new URLSearchParams(location.search);
        const isActive = f.param[1]
          ? parseInt(params?.get(f.param[0])) === f.param[1]
          : !params?.get(f.param[0]);
        params.delete(f.param[0]);
        if (f.param[1]) params.set(f.param[0], f.param[1]);
        const link = location.pathname + "?" + params.toString();
        return (
          <div
            key={getUniqueId()}
            className={`${classes.filterPill} ${
              isActive ? classes.isActive : ""
            }`}
            onClick={() => {
              history.push(link);
            }}>
            {f.title}
          </div>
        );
      })}
    </div>
  );
};

export default BundlesTabList;
