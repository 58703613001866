import { RightPanelCrossIcon } from "components/UI/Components/Components";
import { useHistory } from "react-router-dom";
import classes from "./MutualFollowersRightPanel.module.css";
import withLoader from "components/core/withLoader";
import { getMutualFollowers } from "api/profile.api";
import ProfileItem, {
  ProfileItemGhost,
  ProfileItemTypes,
} from "../../Community/ProfileItem/ProfileItem";

const MutualFollowersRightPanelGhost = () => {
  const history = useHistory();
  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <RightPanelCrossIcon onClick={history.goBack}></RightPanelCrossIcon>
        <span className={classes.title}>Mutual followers</span>
      </div>
      <div className={classes.listContainer}>
        {[...Array(6).keys()].map((index) => {
          return <ProfileItemGhost key={`profile-item-ghost-${index}`} />;
        })}
      </div>
    </div>
  );
};

//if is followers true then followers list else following list
const MutualFollowersRightPanel = (props) => {
  const { queryData } = props;
  const history = useHistory();
  const list = queryData?.data?.data?.mutual_followers_profile_info || [];
  const requestConnection =
    history.location.search?.includes("requestConnection");

  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <RightPanelCrossIcon onClick={history.goBack}></RightPanelCrossIcon>
        <span className={classes.title}>
          {`${list.length} Mutual followers`}
        </span>
      </div>
      <div className={classes.listContainer}>
        {list.map((profile) => {
          return (
            <ProfileItem
              key={profile.id}
              profile={profile}
              requestConnection={requestConnection}
              profileItemType={
                profile.followed_on_twitter
                  ? ProfileItemTypes.twitter
                  : ProfileItemTypes["0xppl"]
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default withLoader(MutualFollowersRightPanel, getMutualFollowers, {
  queryKey: (props) => {
    return ["mutualFollowers", props.identityId];
  },
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  CustomLoader: (props) => MutualFollowersRightPanelGhost(),
  renderNotFound: true,
});
