import {
  CustomSkeleton,
  CustomText,
  Divider,
  PaddingComponent,
  PriceChange,
} from "components/UI/Components/Components";

import styles from "./TrendingDetailsPage.module.css";
import Image from "components/UI/Image";
import ContractLinkGlobe from "components/UI/ContractLinkGlobe/ContractLinkGlobe";
import WatchlistTokenButton from "../Watchlist/WatchlistTokenButton";
import { useContext, useRef } from "react";
import BuySellButton from "../BuySellButton/BuySellButton";
import { FeedContainer } from "components/UI/Components/ResizeFeedComponent";
import { AuthContext } from "contextStates/AuthContext";
import { GlobalContext } from "contextStates/Global";
import TokenChart from "../Token/TokenChart/TokenChart";
import { abbreviateNumber } from "utils/misc";

export const TrendingDetailsHeaderV2 = ({
  displayPicture,
  displayName,
  tokenSymbol,
  tokenProfileLink,
  isNft,
  chainId,
  contractAddress,
  coinGeckoSlug,
  buyUrl,
  sellUrl,
  isWatchListed,
  isSellEnabled,
  refetch,
}) => {
  const { isUserLoggedIn } = useContext(AuthContext);
  const { featureFlags } = useContext(GlobalContext);
  const isWatchlistEnabled =
    isUserLoggedIn && featureFlags.is_watchlist_enabled;

  return (
    <PaddingComponent
      className={styles["header"]}
      padding="8px 16px"
      paddingTop="20px">
      <div className={styles["header-left"]}>
        <Image
          imageType={isNft ? "nft" : "token"}
          src={displayPicture}
          alt={displayName}
          className={styles["header-image"]}
        />
        <div className={styles["header-text"]}>
          <CustomText
            text={displayName}
            color="var(--text-1)"
            fontWeight="600"
            lineHeight="22px"
            fontSize="17px"
          />
          {!isNft && (
            <CustomText
              text={tokenSymbol}
              color="var(--text-2)"
              fontWeight="400"
              lineHeight="18px"
              fontSize="12px"
            />
          )}
        </div>
      </div>
      {/*  */}
      <div className={styles["header-links"]}>
        <ContractLinkGlobe link={tokenProfileLink} />
        {isWatchlistEnabled && (
          <WatchlistTokenButton
            isNft={isNft}
            chainId={chainId}
            tokenAddress={contractAddress}
            tokenSlug={coinGeckoSlug}
            isWatchListed={isWatchListed}
            refetch={refetch}
          />
        )}
        {buyUrl && <BuySellButton action="Buy" link={buyUrl} />}
        {sellUrl && isSellEnabled && (
          <BuySellButton action="Sell" link={sellUrl} />
        )}
      </div>
    </PaddingComponent>
  );
};

export const TrendingDetailsDescription = ({ description, displayName }) => {
  return (
    <PaddingComponent
      padding="16px 0"
      className={styles["description-container"]}>
      <CustomText
        text={`About ${displayName}`}
        color="var(--text-1)"
        fontSize="14px"
        fontWeight="600"
        lineHeight="21px"
      />
      <CustomText
        text={description}
        color="var(--text-2)"
        fontSize="13px"
        lineHeight="20px"
        fontWeight="400"
        textAlign="left"
      />
    </PaddingComponent>
  );
};

export const TrendingTokenPriceWrapper = ({
  priceDisplay,
  hasPriceData,
  priceChangeDisplay,
  priceChangeValue,
  priceData,
  contractAddress,
  chainId,
  isNft,
  floorPrice,
  nativeChainSymbol,
}) => {
  const containerRef = useRef(null);
  return (
    <div ref={containerRef}>
      <PaddingComponent
        className={styles["price-tab-content"]}
        paddingTop="12px"
        paddingBottom="8px"
        paddingLeft="0">
        <div className={styles["price-info-container"]}>
          <CustomText
            text={isNft ? `${floorPrice} ${nativeChainSymbol}` : priceDisplay}
            color="var(--text-1)"
            fontWeight="600"
            lineHeight="32px"
            fontSize="24px"
            letterSpacing="-0.03em"
          />
          <PriceChange
            displayValue={priceChangeDisplay}
            value={priceChangeValue}
          />
        </div>
        <TokenChart
          address={contractAddress}
          chainId={chainId}
          className={styles["price-graph"]}
          headerContent={false}
          onlyUseExistingData={isNft}
          disableLineCandleToggle={isNft}
          existingGraphData={
            isNft ? priceData.map((pd) => [pd[0] * 1000, pd[1]]) : []
          }
          customDisplayFunction={(value) =>
            `${abbreviateNumber(value)} ${nativeChainSymbol}`
          }
        />
      </PaddingComponent>
    </div>
  );
};

export const getTokenDetails = ({
  isNft,
  tokenDetails,
  floorPrice,
  nativeChainSymbol,
}) => {
  if (!isNft) return tokenDetails;
  else {
    const floorPriceKey = {
      key: "Floor price",
      display_value: `${floorPrice} ${nativeChainSymbol}`,
    };
    const filteredDetails = tokenDetails.filter((dt) => {
      return dt.key !== "Total supply" && dt.key !== "Symbol";
    });
    filteredDetails.splice(2, 0, floorPriceKey);
    return filteredDetails;
  }
};

export const TrendingDetailsLoader = () => {
  return (
    <PaddingComponent className={styles["page-container"]}>
      <FeedContainer>
        <PaddingComponent className={styles["page-content"]} padding="16px">
          <PaddingComponent className={styles["loader-header"]} padding="16px">
            <CustomSkeleton
              width="60px"
              height="60px"
              className={styles["loader-header-avatar"]}
            />
            <div className={styles["loader-header-buttons"]}>
              <CustomSkeleton width="32px" height="50px" />
              <CustomSkeleton width="120px" height="50px" />
              <CustomSkeleton width="50px" height="50px" />
              <CustomSkeleton width="50px" height="50px" />
            </div>
          </PaddingComponent>
          <Divider />
          <PaddingComponent
            className={styles["loader-description"]}
            padding="16px">
            <CustomSkeleton width="30%" height="48px" />
            {[1, 2, 3, 4].map((_, index) => (
              <CustomSkeleton key={index} width="100%" height="32px" />
            ))}
          </PaddingComponent>
          <PaddingComponent className={styles["loader-tabs"]} padding="16px">
            {[1, 2, 3, 4, 5, 6].map((_, index) => (
              <CustomSkeleton key={index} width="100%" height="40px" />
            ))}
          </PaddingComponent>
          <Divider />
          <PaddingComponent className={styles["loader-charts"]} padding="16px">
            <CustomSkeleton
              width="100%"
              height="300px"
              className={styles["loader-chart"]}
            />
          </PaddingComponent>
        </PaddingComponent>
      </FeedContainer>
      <PaddingComponent className={styles["page-right-bar"]} padding="16px">
        <CustomSkeleton width="100%" height="60px" />
        <Divider />
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
          <CustomSkeleton key={index} width="100%" height="40px" />
        ))}
      </PaddingComponent>
    </PaddingComponent>
  );
};
