import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { IconButton } from "@mui/material";
import {
  CalendarPlus,
  Gif,
  ImageSquare,
  PlusCircle,
  Smiley,
  X,
} from "@phosphor-icons/react";
import Spinner from "shared/buttonloadingSpinner";
import classes from "./PostCommentRenderer.module.css";
import NewPostHeader from "./Components/NewPostHeader";
import {
  getFormattedPostContentFromEditorForBackend,
  useFetchConnectPlatformsCrossPost,
} from "./CrossPost/cross_post_utils";
import { useQuery } from "@tanstack/react-query";
import { getFarcasterChannels } from "api/feed.api";
import farcasterIcon from "assets/svg/farcaster.svg";
import {
  CustomRow,
  VerticalDivider,
} from "components/UI/Components/Components";
import CustomDropdownButton from "components/UI/Components/CustomDropDown";
import { AuthContext } from "contextStates/AuthContext";
import { GlobalContext } from "contextStates/Global";
import createEmojiPlugin from "draft-js-emoji-plugin";
import { Validation } from "utils/constants";
import { trackEvent } from "utils/event_tracking";
import CrossPostModal from "./Components/CrossPostModal";
import GifSearchExperience from "./Components/GifPopUp";
import PostProgressCircle from "./Components/PostProgressCircle";
import {
  getDateInFormat,
  getFileTypeFromDataString,
  SUPPORTED_IMAGE_FORMATS,
} from "./NewPost.utils";
import PostCommentTextEditor from "./PostCommentTextEditor";
import { Spinner_Blob } from "./spinner";
import { Scheduler } from "./Scheduler";
import { ScheduledPostList } from "./ScheduledPostList";
import { DraftPostList } from "./DraftPostList";
import DiscardOrDraftButton from "../DiscardOrDraftButton";
import { NewPostContext } from "./NewPostV2";
import { PaperPlaneRight } from "@phosphor-icons/react";

const PostCommentRendererV2 = ({
  isLoading,
  isDummy = false,
  onSubmit,
  isSuccess,
  showNewPostIcon = false,
  postDisabled,
  isEditorFocused,
  placeholder,
  isModal,
  renderContent = null,
  prefillCommentContent,
  onDiscard,
  isNewPost = false,
  isHomePage,
  handleOnFocus = () => {},
  source,
  enableCrossPost = false,
  isCrossPostDefaultSelected = true,
  postDetails,
  commentSuccessResponse,
  resetCommentSuccessResponse,
  onCrossCommentClose = () => {},
  setIsScheduling,
  scheduledDate,
  isScheduling,
  setScheduledDate,
  activeTab,
  setActiveTab,
}) => {
  const { identityDetails } = useContext(AuthContext);
  const { featureFlags } = useContext(GlobalContext);
  const {
    form,
    previewImages,
    setPreviewImages,
    editorRawContent,
    setEditorRawContent,
    selectedPlatforms,
    setSelectedPlatforms,
    selectedChannelId,
    setSelectedChannelId,
  } = useContext(NewPostContext);

  const {
    control,
    handleSubmit,
    setValue: _setValue,
    setError,
    watch,
    clearErrors,
    reset,
    formState: { errors },
    trigger,
  } = form;

  const setValue = (arg) => {
    console.log(arg);
    _setValue(arg);
  };

  const emojiPluginOptions = useMemo(
    () => ({
      useNativeArt: true,
      selectButtonContent: <Smiley size={20} />,
    }),
    []
  );
  const { isUserLoggedIn } = useContext(AuthContext);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const emojiPlugin = createEmojiPlugin(emojiPluginOptions);
  const [rawLinks, setRawLinks] = useState([]);
  const [urls, setUrls] = useState([]);
  const [currentThreadIndex, setCurrentThreadIndex] = useState(0);

  const [isCrossPostModalOpen, setIsCrossPostModalOpen] = useState(false);
  const fileInputRef = useRef();
  const [emojiPlugins, setEmojiPlugins] = useState([emojiPlugin]);
  const [showGifPopUp, setShowGifPopUp] = useState(false);
  const [selectedChannelName, setSelectedChannelName] =
    useState("Select Channel");
  const newThreadRef = useRef(null);

  const {
    connectedPlatforms,
    refetch,
    isFetching: isConnectedPlatformsLoading,
  } = useFetchConnectPlatformsCrossPost();

  const isFarcasterConnected = selectedPlatforms?.includes("farcaster");
  const isTwitterConnected = selectedPlatforms?.includes("twitter");
  const isLensConnected = selectedPlatforms?.includes("lens");

  const gifRef = useRef();

  const { data: { farcaster: farcasterChannels = [] } = {} } = useQuery({
    queryKey: ["get-farcaster-channels"],
    queryFn: getFarcasterChannels,
    retry: 2,
    retryOnMount: false,
    staleTime: 1000 * 60 * 60,
    enabled: isUserLoggedIn,
  });
  useEffect(() => {
    const selectedChannel = farcasterChannels.find(
      (channel) => channel.channel_id === selectedChannelId
    );
    if (selectedChannel) {
      setSelectedChannelName(selectedChannel.channel_name);
    } else {
      setSelectedChannelName("Select Channel");
    }
  }, [selectedChannelId, farcasterChannels]);
  const [isCrossCommentDone, setIsCrossCommentDone] = useState(false);
  const [disableCrossComment, setDisableCrossComment] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "threads",
  });

  const currentComment = watch(`threads.${currentThreadIndex}.name`, "");

  let EmojiSuggestions, EmojiSelect;
  if (emojiPlugins && emojiPlugins[currentThreadIndex]) {
    EmojiSuggestions = emojiPlugins[currentThreadIndex].EmojiSuggestions;
    EmojiSelect = emojiPlugins[currentThreadIndex].EmojiSelect;
  }

  const ignoreWordLimit =
    featureFlags?.ignore_cross_post_word_count_limit ?? false;

  const addThread = useCallback(async () => {
    append({ name: "" });
    setCurrentThreadIndex(fields.length);
    setPreviewImages((prevImages) => [...prevImages, []]);
    setRawLinks((prevLinks) => [...prevLinks, []]);
    setUrls((prevUrls) => [...prevUrls, []]);
    setEditorRawContent((prev) => [...prev, null]);
    const newEmojiPlugin = createEmojiPlugin(emojiPluginOptions);
    setEmojiPlugins((prev) => [...prev, newEmojiPlugin]);
    setTimeout(() => {
      if (newThreadRef.current) {
        newThreadRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }, [
    append,
    setPreviewImages,
    setCurrentThreadIndex,
    fields,
    emojiPluginOptions,
    setEditorRawContent,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setUrls(rawLinks);
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [rawLinks]);

  useEffect(() => {
    if (
      commentSuccessResponse &&
      commentSuccessResponse?.metadata?.error_message
    ) {
      handleErrorSnackbar(
        new Error(commentSuccessResponse.metadata.error_message)
      );
      refetch();
      resetCommentSuccessResponse();
    }
  }, [
    commentSuccessResponse,
    refetch,
    handleErrorSnackbar,
    resetCommentSuccessResponse,
  ]);

  const handleClearContent = useCallback(() => {
    reset({
      threads: [{ name: prefillCommentContent ?? "" }],
    });
    setRawLinks([]);
    setUrls([]);
    setPreviewImages([]);
    setScheduledDate(null);
  }, [
    reset,
    setUrls,
    setPreviewImages,
    prefillCommentContent,
    setRawLinks,
    setScheduledDate,
  ]);

  const discardPost = () => {
    if (isModal) {
      onDiscard?.();
      return;
    }
    handleClearContent();
  };
  useEffect(() => {
    if (isSuccess) {
      handleClearContent();
    }
  }, [isSuccess, handleClearContent]);
  useEffect(() => {
    if (!isSuccess) return;
    if (!isCrossCommentDone && commentSuccessResponse && !disableCrossComment) {
      const actor = postDetails?.actor;
      const isAuthorOn0xppl =
        actor?.is_user_account ||
        actor?.user_since != null ||
        actor?.is_verified;
      const authPlatforms = [];
      if (actor?.external_links?.twitter) authPlatforms.push("twitter");
      if (actor?.external_links?.lens) authPlatforms.push("lens");
      // If no platform is selected and user is not verified then show cross post modal
      if (
        selectedPlatforms.length === 0 &&
        source === "comment" &&
        !isAuthorOn0xppl &&
        !isCrossCommentDone &&
        authPlatforms.length > 0 &&
        featureFlags?.show_cross_post_comment_nudge
      ) {
        handleClearContent();
        setIsCrossPostModalOpen(true);
        return;
      } else {
        onCrossCommentClose();
      }
    } else {
      onCrossCommentClose();
    }
  }, [
    handleClearContent,
    isSuccess,
    postDetails,
    selectedPlatforms,
    source,
    commentSuccessResponse,
    isCrossCommentDone,
    identityDetails,
    featureFlags.show_cross_post_comment_nudge,
    disableCrossComment,
    onCrossCommentClose,
  ]);

  let isAddThreadsDisabled = postDisabled || isLoading > 0;

  //if any item is is emoty then make it disabled
  if (!isAddThreadsDisabled) {
    const emptyIndex = fields.find((_, idx) => {
      const thread = watch(`threads.${idx}.name`, "");
      const progres = thread.length ?? 0;
      return progres === 0;
    });
    isAddThreadsDisabled |= emptyIndex !== undefined;
  }
  const postHasImages = previewImages.some((img) => img.length > 0);
  let isSubmitDisabled =
    (source !== "repost" &&
      isAddThreadsDisabled &&
      (isNewPost ? true : !postHasImages)) ||
    Object.keys(errors).length > 0;

  const getThreadsToSubmit = useCallback(() => {
    const threads = [];
    // create a array where each item contain name and images
    for (let i = 0; i < fields.length; i++) {
      const contentForBackend = getFormattedPostContentFromEditorForBackend({
        content: editorRawContent[i],
      });
      const thread = {
        contents: contentForBackend,
        images:
          previewImages[i]
            ?.filter(({ dontPost }) => !dontPost)
            ?.map(({ result }) => result) ?? [],
        urls: urls[i] ?? [],
      };
      threads.push(thread);
    }
    return threads;
  }, [fields, previewImages, editorRawContent, urls]);

  const draftSubmit = () => {
    const threads = getThreadsToSubmit();
    if (isCrossCommentDone) setIsCrossCommentDone(false);
    trackEvent("draft_btn_click");
    setDisableCrossComment(selectedPlatforms.length > 0);
    onSubmit({
      threads,
      selectedPlatforms,
      channelId: selectedChannelId,
      draft: true,
    });
  };

  const myOnSubmit = useCallback(() => {
    if (isSubmitDisabled) {
      return;
    }

    const threads = getThreadsToSubmit();
    if (isCrossCommentDone) setIsCrossCommentDone(false);
    trackEvent("post_btn_click");
    setDisableCrossComment(selectedPlatforms.length > 0);
    onSubmit({ threads, selectedPlatforms, channelId: selectedChannelId });
  }, [
    onSubmit,
    isCrossCommentDone,
    selectedPlatforms,
    selectedChannelId,
    isSubmitDisabled,
    getThreadsToSubmit,
  ]);

  const currentThreadValue = watch(`threads.${currentThreadIndex}.name`, "");
  const currentItemProgress = currentThreadValue.length ?? 0;

  const maxLength = ignoreWordLimit
    ? 999999
    : Math.min(
        isTwitterConnected
          ? Validation.TWITTER_MAX_LENGTH
          : Validation.POST_MAX_LENGTH,
        isFarcasterConnected
          ? Validation.FARCASTER_MAX_LENGTH
          : Validation.POST_MAX_LENGTH,
        isLensConnected
          ? Validation.LENS_MAX_LENGTH
          : Validation.POST_MAX_LENGTH,
        Validation.POST_MAX_LENGTH
      );

  const validationConfig = {
    maxLength: {
      value: maxLength,
      message: `Max ${maxLength} characters. Add a new thread!`,
    },
    minLength: {
      value: Validation.MIN_LENGTH,
      message: "Post cannot be less than the min length",
    },
  };

  const changeUrlToBlob = async (url) => {
    return await fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({ blob, result: reader.result });
          };
          reader.readAsDataURL(blob);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickOutside = (event) => {
    if (gifRef.current && !gifRef.current.contains(event.target)) {
      setShowGifPopUp(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onGifSelect = (gif) => {
    const url = gif?.images?.original?.url;
    const placeholderImage = Spinner_Blob;
    const currentImages = previewImages[currentThreadIndex] ?? [];
    let newImages = [...currentImages, placeholderImage];
    let newPreviewImages = [...previewImages];
    newPreviewImages[currentThreadIndex] = newImages;
    setPreviewImages(newPreviewImages);

    changeUrlToBlob(url).then((result) => {
      newImages = newImages.map((image) => {
        if (image === placeholderImage) {
          return result;
        }
        return image;
      });
      newPreviewImages[currentThreadIndex] = newImages;
      setPreviewImages([...newPreviewImages]);
    });
  };

  // Check if thread has more than 2 images when farcaster is selected
  useEffect(() => {
    const threads = watch("threads");

    threads.forEach((_, index) => {
      // check if thread has more than 2 images
      if (isFarcasterConnected && previewImages[index]?.length > 2) {
        setError(`threads.${index}.farcaster`, {
          message: "Farcaster supports only 2 images per thread",
        });
      } else {
        clearErrors(`threads.${index}.farcaster`);
      }
      const postHasSupportedFiles = previewImages[index]?.every(
        ({ file, result }) => {
          const type = file?.type ?? getFileTypeFromDataString(result);
          return SUPPORTED_IMAGE_FORMATS.includes(type);
        }
      );
      if (!postHasSupportedFiles && previewImages[index]?.length > 0) {
        setError(`threads.${index}.unsupported_format`, {
          message:
            "One or more image formats not supported. Use only .jpg, .png, .gif or .webp files.",
        });
      } else {
        clearErrors(`threads.${index}.unsupported_format`);
      }
    });
  }, [
    previewImages,
    connectedPlatforms,
    isFarcasterConnected,
    setError,
    clearErrors,
    watch,
  ]);
  const triggerSubmit = (e) => {
    e.preventDefault();
    if (!showGifPopUp) {
      handleSubmit(myOnSubmit)();
      return;
    }
  };

  const saveDraft = () => {
    handleSubmit(draftSubmit)();
  };

  let buttonLabel = "Post";
  if (scheduledDate) {
    buttonLabel = "Schedule";
  }

  return (
    <>
      <div style={{ position: "relative" }}>
        <form onSubmit={triggerSubmit}>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            multiple
            onChange={(e) => {
              const files = Array.from(e.target.files);
              if (files.length > 0) {
                const readerPromises = files.map((file) => {
                  return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                      resolve({ file, result: reader.result });
                    };
                    reader.readAsDataURL(file);
                  });
                });

                Promise.all(readerPromises).then((results) => {
                  const currentImages = previewImages[currentThreadIndex] ?? [];
                  const newImages = [...currentImages, ...results];
                  const newPreviewImages = [...previewImages];
                  newPreviewImages[currentThreadIndex] = newImages;
                  setPreviewImages(newPreviewImages);
                });
                e.target.value = null;
              }
            }}
          />
          <div
            className={`${classes.newPostWrapper} ${
              classes.expanded
            } ${isHomePage ? classes.homePage : ""} ${
              isModal ? classes.isModal : ""
            }`}>
            {(isNewPost || enableCrossPost) && (
              <NewPostHeader
                isVisible={isNewPost || enableCrossPost}
                connectedPlatforms={connectedPlatforms}
                selectedPlatforms={selectedPlatforms}
                setSelectedPlatforms={setSelectedPlatforms}
                isCrossPostDefaultSelected={isCrossPostDefaultSelected}
                refetch={refetch}
                isLoading={isConnectedPlatformsLoading}
              />
            )}
            <div className={classes.contentWrapper}>
              <div className={classes.withImageAndLinks}>
                {fields.map((item, index) => {
                  return (
                    <Controller
                      key={item.id}
                      name={`threads.${index}.name`}
                      control={control}
                      rules={validationConfig}
                      render={({ field }) => (
                        <PostCommentTextEditor
                          trigger={trigger}
                          field={field}
                          index={index}
                          nodeRef={
                            index === fields.length - 1 ? newThreadRef : null
                          }
                          emojiPlugin={emojiPlugins[index]}
                          setEmojiPlugins={setEmojiPlugins}
                          initialRawContent={editorRawContent[index]}
                          setEditorRawContent={setEditorRawContent}
                          fieldName={`threads.${index}.name`}
                          watch={watch}
                          clearErrors={clearErrors}
                          errors={errors}
                          setValue={setValue}
                          setError={setError}
                          setCurrentThreadIndex={setCurrentThreadIndex}
                          previewImages={previewImages}
                          setPreviewImages={setPreviewImages}
                          isFormExpanded={true}
                          remove={remove}
                          fields={fields}
                          isSuccess={isSuccess}
                          onSubmit={handleSubmit(myOnSubmit)}
                          placeholder={
                            index === 0 ? placeholder : "Add another post"
                          }
                          showNewPostIcon={showNewPostIcon}
                          handleOnFocus={handleOnFocus}
                          urls={urls}
                          setRawLinks={setRawLinks}
                        />
                      )}
                    />
                  );
                })}
              </div>
            </div>
            {renderContent}
            {scheduledDate && (
              <div className={classes.scheduledDate}>
                <span>
                  Scheduled to post on {getDateInFormat(scheduledDate)}
                </span>
                <button
                  type="button"
                  className={classes.editButton}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsScheduling(true);
                  }}>
                  Edit
                </button>
              </div>
            )}
            <div className={classes.buttonsWrapper}>
              <div className={classes.optionsIcons}>
                <IconButton
                  type="button"
                  onClick={() => fileInputRef.current.click()}>
                  <ImageSquare size={20} style={{ color: "var(--text-2)" }} />
                </IconButton>
                <div
                  onClick={() => setShowGifPopUp(true)}
                  className={classes.gifBtn}>
                  <Gif size={20} style={{ color: "var(--text-2)" }} />
                  {showGifPopUp && (
                    <div ref={gifRef}>
                      <GifSearchExperience onSelect={onGifSelect} />
                    </div>
                  )}
                </div>
                <div className={classes.chooseEmojiWrapper}>
                  {EmojiSelect && <EmojiSelect />}
                </div>
                <IconButton>
                  <CalendarPlus
                    size={20}
                    weight="light"
                    style={{ color: "var(--text-2)" }}
                    onClick={() => {
                      setIsScheduling(true);
                    }}
                  />
                </IconButton>
                {!ignoreWordLimit && (
                  <PostProgressCircle
                    wordsLength={currentItemProgress}
                    total={maxLength}
                  />
                )}
                {isNewPost && !isAddThreadsDisabled && (
                  <>
                    <VerticalDivider height="20px" />
                    <IconButton onClick={addThread}>
                      <PlusCircle
                        size={20}
                        weight="fill"
                        color="var(--primary-color)"
                      />
                    </IconButton>
                  </>
                )}
              </div>
              {
                <div className={classes.actionButtons}>
                  <CustomRow alignItems="flex-end" gap="4px">
                    {isFarcasterConnected && farcasterChannels.length > 0 && (
                      <CustomDropdownButton
                        fontSize="13px"
                        customClass={classes.farcasterDropdown}
                        customClassList={classes.farcasterList}
                        icon={farcasterIcon}
                        title={selectedChannelName}
                        items={farcasterChannels.map(
                          (channel) => channel.channel_name
                        )}
                        showSearch={true}
                        onSelectItem={(item, index) => {
                          const channel = farcasterChannels.find(
                            (channel) => channel.channel_name === item
                          );
                          if (channel) {
                            setSelectedChannelId(channel.channel_id);
                            setSelectedChannelName(item);
                          }
                        }}
                      />
                    )}
                    {selectedChannelId && isFarcasterConnected && (
                      <DiscardOrDraftButton
                        text={<X size={16} />}
                        discardCallback={() => {
                          setSelectedChannelId(null);
                          setSelectedChannelName("Select Channel");
                        }}
                        saveDraft={saveDraft}
                        noConfirmation={true}
                        className={classes.clearButton}
                      />
                    )}
                  </CustomRow>
                  <DiscardOrDraftButton
                    className={classes.discardButton}
                    discardCallback={discardPost}
                    saveDraft={saveDraft}
                    noConfirmation={
                      previewImages.length === 0 && !currentThreadValue
                    }
                  />
                  <button
                    type="submit"
                    className={classes.postButton}
                    style={{
                      width: "auto",
                    }}
                    disabled={isSubmitDisabled}>
                    {!isLoading ? (
                      <CustomRow alignItems="center" gap="8px">
                        <PaperPlaneRight color="#fff" size={16} weight="fill" />
                        {buttonLabel}
                      </CustomRow>
                    ) : (
                      <Spinner
                        style={{
                          stroke: "var(--text-white)",
                          background: "transparent",
                          width: "20px",
                          height: "20px",
                          alignSelf: "center",
                        }}
                      />
                    )}
                  </button>
                </div>
              }
              {EmojiSuggestions && <EmojiSuggestions />}
            </div>
          </div>
        </form>
        {activeTab === 1 && <ScheduledPostList />}
        {activeTab === 2 && <DraftPostList />}
      </div>

      {!isNewPost && isCrossPostModalOpen && (
        <CrossPostModal
          avatarUrl={postDetails?.actor?.display_picture}
          name={postDetails?.actor?.display_name}
          postDetails={postDetails}
          showModal={isCrossPostModalOpen}
          selectedPlatforms={selectedPlatforms ?? []}
          connectedPlatforms={connectedPlatforms ?? []}
          currentComment={currentComment}
          setShowModal={setIsCrossPostModalOpen}
          commentSuccessResponse={commentSuccessResponse}
          onSuccess={() => {
            onCrossCommentClose();
            setIsCrossCommentDone(true);
          }}
          onClose={() => {
            onCrossCommentClose();
            setIsCrossCommentDone(true);
          }}
          actor={postDetails?.actor}
          resetCommentSuccessResponse={resetCommentSuccessResponse}
        />
      )}
      {isScheduling && (
        <Scheduler
          date={scheduledDate}
          callbacks={{
            onConfirm: (date) => {
              setIsScheduling(false);
              if (date) {
                setScheduledDate(date);
              }
            },
            clearDate: () => {
              setScheduledDate(null);
              setIsScheduling(false);
            },
            showScheduledTab: () => {
              setIsScheduling(false);
              setActiveTab(1);
            },
          }}
        />
      )}
    </>
  );
};

export default PostCommentRendererV2;
