import React, { useState, useEffect, useCallback, useMemo } from "react";

import CustomDropdownButton from "components/UI/Components/CustomDropDown";
import SearchBarMini from "components/UI/Components/SearchBarMini";
import deleteIcon from "assets/svg/delete.svg";
import { CaretDown, CurrencyDollar } from "@phosphor-icons/react";
import { ClickAwayListener } from "@mui/material";
import { getChainMetaData } from "utils/misc";
import queriesArray from "../QueryObject";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import allChainIcon from "assets/svg/all-chains.svg";

import classes from "./QueryLine.module.css";

const operatorArray = ["AND", "OR"];
const comparisonOperators = [">", "<"];

const extractTextFromHTML = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const QueryLine = ({
  index,
  showDeleteBtn,
  setAddedQueries,
  handleDeleteQuery,
  currentQuery,
}) => {
  const [operator, setOperator] = useState(
    currentQuery?.operator?.title || "AND"
  );
  const [chosenQuery, setChosenQuery] = useState(
    currentQuery?.query?.query?.title.replace(/{{|}}/g, "") ||
      "Holdings of token"
  );
  const [queryParams, setQueryParams] = useState(
    currentQuery?.query?.params || {
      token: null,
      protocol: null,
      profile: null,
    }
  );
  const [compOperator, setCompOperator] = useState(
    currentQuery?.query?.compOperator?.operator || ">"
  );
  const [compValue, setCompValue] = useState(
    currentQuery?.query?.compOperator?.value || null
  );
  const [onChain, setOnChain] = useState({
    data: currentQuery?.query?.filters?.chain?.data || null,
    title:
      currentQuery?.query?.filters?.chain?.data?.display_name || "All Chains",
  });
  const [onProtocol, setOnProtocol] = useState({
    data: currentQuery?.query?.filters?.protocol?.data || null,
    title:
      currentQuery?.query?.filters?.protocol?.data?.data?.display_name ||
      "All Protocols",
  });
  const [filterDropdown, setFilterDropdown] = useState(null);
  const [extractedParams, setExtractedParams] = useState([]);

  const queryOptions = queriesArray
    .slice(0, -5)
    .map((query) => query.title.replace(/{{|}}/g, ""));

  // gives out the query object from the queriesArray using the title
  const findQueryFromTitle = useCallback((title) => {
    return queriesArray.find(
      (query) => query.title.replace(/{{|}}/g, "") === title
    );
  }, []);

  const showFilters = useMemo(() => {
    switch (chosenQuery) {
      case "Followers":
        return false;
      case "is following profile":
        return false;
      default:
        return true;
    }
  }, [chosenQuery]);

  const showResetBtn = useMemo(() => {
    if (
      onChain?.title === "All Chains" &&
      onProtocol?.title === "All Protocols"
    ) {
      return false;
    }
    return true;
  }, [onChain, onProtocol]);

  const showCompOperator = useMemo(() => {
    switch (chosenQuery) {
      case "Interacted with protocol":
        return false;
      case "is following profile":
        return false;
      default:
        return true;
    }
  }, [chosenQuery]);

  const showDollarIconWithCompValue = useMemo(() => {
    if (chosenQuery === "Followers") {
      return false;
    }
    return true;
  }, [chosenQuery]);

  const showOnProtocol = useMemo(() => {
    if (chosenQuery.split(" ").includes("protocol")) {
      return false;
    }
    return true;
  }, [chosenQuery]);

  // Stores on {{chain}} and on {{protocol}} queries
  const filterArray = useMemo(() => queriesArray.slice(0, -3).slice(-2), []);

  useEffect(() => {
    const currIndexQuery = {
      id: currentQuery.id,
      operator: findQueryFromTitle(operator),
      query: {
        query: findQueryFromTitle(chosenQuery),
        compOperator: {
          operator: compOperator,
          value: compValue,
        },
        filters: {
          chain: { data: onChain?.data, query: filterArray[0] },
          protocol: { data: onProtocol?.data, query: filterArray[1] },
        },
        params: queryParams,
      },
    };

    const queryTitle = findQueryFromTitle(chosenQuery)?.title;
    const queryParamsArray = queryTitle
      ?.match(/\{\{([^{}]+)\}\}/g)
      ?.map((match) => match.slice(2, -2));
    setExtractedParams(queryParamsArray);

    setAddedQueries((prev) => {
      prev[index] = currIndexQuery;
      return prev;
    });
  }, [
    operator,
    chosenQuery,
    compOperator,
    compValue,
    onChain,
    onProtocol,
    queryParams,
    currentQuery.id,
    index,
    findQueryFromTitle,
    setAddedQueries,
    filterArray,
  ]);

  const handleParamSelection = ({ source, result }) => {
    setQueryParams({ ...queryParams, [source]: result });
  };

  const handleOnChainSelect = ({ chain }) => {
    chain !== "All Chains"
      ? setOnChain({
          data: { display_name: chain },
          title: chain,
        })
      : setOnChain({ data: null, title: "All Chains" });
    setFilterDropdown(null);
  };

  const handleOnProtocolSelect = ({ source, result }) => {
    setOnProtocol({
      data: result,
      title: result?.data?.title || result?.data?.display_name,
    });
    setFilterDropdown(null);
  };

  const chainData = getChainMetaData().map((chain) => ({
    itemName: chain.name,
    icon: chain.icon,
  }));

  return (
    <>
      {index !== 0 && (
        <div style={{ width: "72px" }}>
          <CustomDropdownButton
            fontSize="13px"
            title={operator}
            items={operatorArray}
            customClass={classes.operatorButton}
            customClassList={classes.operatorList}
            onSelectItem={(item) => setOperator(item)}
          />
        </div>
      )}
      <div className={classes.parentContainer}>
        <div className={classes.queryLineContainer}>
          <CustomDropdownButton
            fontSize="13px"
            customClass={classes.queryLineElement}
            customClassList={classes.dropdownList}
            title={chosenQuery}
            onSelectItem={(item) => {
              setChosenQuery(item);
              setQueryParams({
                token: null,
                protocol: null,
                profile: null,
              });
              setCompOperator(">");
              setOnChain({ data: null, title: "All Chains" });
              setOnProtocol({ data: null, title: "All Protocols" });
            }}
            items={queryOptions}
          />
          {extractedParams?.map((param, index) => {
            let searchTerm = "";
            if (queryParams[param]) {
              searchTerm = extractTextFromHTML(
                Object.values(queryParams?.[param]?.search_hints)[0]
              );
            }
            return (
              <SearchBarMini
                key={chosenQuery + index}
                placeholder={`Search ${param}`}
                source={param}
                searchQueryKey={param}
                SearchItemComponent={SearchDropDown}
                handleSelect={handleParamSelection}
                selectedResult={queryParams[param]}
                defaultSearchTerm={searchTerm}
                displaySelectionText={
                  queryParams[param]?.data?.title ||
                  queryParams[param]?.data?.display_name
                }
                hideIcon
              />
            );
          })}
          {showCompOperator && (
            <CustomDropdownButton
              fontSize="13px"
              customClass={classes.compOperator}
              customClassList={classes.compOperatorList}
              title={compOperator}
              onSelectItem={(item) => setCompOperator(item)}
              items={comparisonOperators}
            />
          )}

          {showCompOperator && showDollarIconWithCompValue && (
            <div style={{ position: "relative" }}>
              <input
                onChange={(e) => setCompValue("$" + e.target.value)}
                className={classes.compValue}
                value={(compValue || "").replace("$", "")}
              />
              <CurrencyDollar size={15} className={classes.dollarIcon} />
            </div>
          )}
          {showCompOperator && !showDollarIconWithCompValue && (
            <input
              onChange={(e) => setCompValue(e.target.value)}
              className={classes.compValue}
              value={(compValue || "").replace("$", "")}
            />
          )}
          {showDeleteBtn && (
            <img
              onClick={() => handleDeleteQuery(index)}
              className={classes.deleteIcon}
              src={deleteIcon}
              alt="deleteIcon"
            />
          )}
        </div>
        <div>
          {showFilters && (
            <div className={classes.filterOptions}>
              <>
                <div
                  style={{ display: "flex", gap: "5px" }}
                  onClick={() => setFilterDropdown("chain")}>
                  {onChain?.title}
                  <CaretDown
                    style={{
                      transform: filterDropdown === "chain" && "rotate(180deg)",
                    }}
                    size={14}
                    color="var(--text-2)"
                  />
                </div>
                {showOnProtocol && (
                  <div
                    style={{ display: "flex", gap: "5px" }}
                    onClick={() => setFilterDropdown("protocol")}>
                    {onProtocol?.title}
                    <CaretDown
                      style={{
                        transform:
                          filterDropdown === "protocol" && "rotate(180deg)",
                      }}
                      size={14}
                      color="var(--text-2)"
                    />
                  </div>
                )}
                {showResetBtn && (
                  <div
                    className={classes.resetBtn}
                    onClick={() => {
                      setOnChain({ data: null, title: "All Chains" });
                      setOnProtocol({ data: null, title: "All Protocols" });
                    }}>
                    Reset
                  </div>
                )}
              </>
            </div>
          )}
          {filterDropdown && (
            <div style={{ position: "absolute", marginTop: "5px" }}>
              <ClickAwayListener
                onClickAway={() => {
                  setFilterDropdown(false);
                }}>
                {filterDropdown === "chain" ? (
                  <div>
                    <CustomDropdownButton
                      fontSize="13px"
                      customClass={classes.chainFilter}
                      customClassList={classes.chainFilterList}
                      title={onChain.title}
                      onSelectItem={(item) =>
                        handleOnChainSelect({ chain: item })
                      }
                      items={[
                        "All Chains",
                        ...chainData.map((chain) => chain.itemName),
                      ]}
                      iconsList={[
                        allChainIcon,
                        ...chainData.map((chain) => chain.icon),
                      ]}
                      defaultOpen={true}
                      hideDropDown
                    />
                  </div>
                ) : (
                  <div>
                    <SearchBarMini
                      placeholder={"Search protocol"}
                      source={"protocol"}
                      searchQueryKey={"protocol"}
                      SearchItemComponent={SearchDropDown}
                      handleSelect={handleOnProtocolSelect}
                      defaultSearchTerm={
                        onProtocol?.title === "All Protocols"
                          ? ""
                          : onProtocol?.title
                      }
                      hideIcon
                      isSearchInputFocusedProp={true}
                    />
                  </div>
                )}
              </ClickAwayListener>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const SearchDropDown = ({
  result,
  source,
  handleSelect,
  selected,
  setIsSearchInputFocused,
}) => {
  return (
    <div
      className={classes.searchDropDown}
      style={{
        background: selected ? "var(--elevation-1)" : "var(--base)",
        color: selected ? "var(--text-1)" : "var(--text-2)",
      }}
      onClick={() => {
        setIsSearchInputFocused(false);
        handleSelect({ source, result });
      }}>
      <IconWithChain
        src={result?.data?.display_picture}
        chain={result?.data?.address_chain}
        showImageFullHeightWidth
        iconHeight="12px"
        iconWidth="12px"
        chainHeight="10px"
        chainWidth="10px"
        chainRightDistance="-5px"
      />
      <div>{result.data?.title || result?.data?.display_name}</div>
    </div>
  );
};

export default QueryLine;
