import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import { SpinnerComponent } from "../Components/Components";
import styles from "./refetch.module.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import { EMPTY_STATES } from "zxStyles/constants";

const Refetch = ({
  onClick,
  text,
  containerClass = "",
  margin = "0px",
  showHeader = true,
  buttonText = "Reload",
  showText = true,
  isLoading = false,
  size = "50px",
}) => {
  return (
    <div
      className={`${styles.refetch} ${containerClass}`}
      onClick={onClick}
      style={{ margin }}>
      {showHeader && (
        <ZxEmptyState
          size={size}
          state={EMPTY_STATES.ERROR}
          text={showText ? text || "There seems to be an issue." : null}
        />
      )}

      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <div className={styles.refresh}>
          <RefreshIcon className={styles.refresh_icon}></RefreshIcon>
          {buttonText}
        </div>
      )}
    </div>
  );
};

export default Refetch;
