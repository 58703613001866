import { CustomRow } from "components/UI/Components/Components";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import ConditionalLink from "shared/ConditionalLink";

import styles from "./ZxNftActivities.module.scss";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { getTrimedAddress, getTrimmedString } from "utils/misc";
import { TSTYLES } from "zxStyles/constants";
import { TimeLabel } from "components/FeedItemV2/ActivityHeader";
export const SaleActor = ({ actor }) => {
  return (
    <ProfileHoverCard actor={actor}>
      <ConditionalLink to={actor?.link ?? `/${actor?.address}`}>
        <CustomRow alignItems="center" gap="8px">
          <Image
            src={actor?.display_picture}
            className={styles.avatar}
            imageType={IMAGE_TYPES.AVATAR}
            name={actor?.display_name}
          />
          <ZxText cursor="pointer">
            {getTrimmedString(actor?.display_name, 12)}
          </ZxText>
        </CustomRow>
      </ConditionalLink>
    </ProfileHoverCard>
  );
};
export const getNftActivitiesColumns = (data) => {
  return [
    {
      title: "FROM",
      width: "250px",
      align: "flex-start",
      render: (row) => {
        return (
          <SaleActor
            actor={
              data?.profiles?.[row?.from_address] ?? {
                display_name: getTrimedAddress(row?.from_address),
                display_picture: null,
              }
            }
          />
        );
      },
    },
    {
      title: "TO",
      width: "250px",
      align: "flex-start",
      render: (row) => {
        return (
          <SaleActor
            actor={
              data?.profiles?.[row?.transaction_to_address] ?? {
                display_name: getTrimedAddress(row?.transaction_to_address),
                display_picture: null,
              }
            }
          />
        );
      },
    },
    {
      title: "PRICE",
      width: "250px",
      align: "flex-start",
      render: (row) => {
        const sale = row?.sale_details;
        const logo = sale?.token_details?.logo ?? null;
        const price = sale?.token_value?.display_value ?? "-";
        return (
          <CustomRow
            gap="4px"
            alignItems="center"
            overflowX="visible"
            overflowY="visible">
            <Image
              src={logo}
              className={styles.avatar}
              imageType={IMAGE_TYPES.TOKEN}
            />
            <ZxText style={TSTYLES.title1} fontSize="13px">
              {price}
            </ZxText>
          </CustomRow>
        );
      },
    },
    {
      title: "TIME",
      width: "250px",
      align: "flex-end",
      render: (row) => {
        return <TimeLabel timestamp={Date.parse(row.timestamp) / 1000} />;
      },
    },
  ];
};
