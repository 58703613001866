import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import styles from "./ZxTokenPage.module.scss";
import rightBarStyles from "./TokenPageRightBar/TokenPageRightBar.module.scss";
import ZxText from "zxComponents/common/ZxText/ZxText";
import {
  ArrowReloadHorizontalIcon,
  UserGroupIcon,
  Diamond02Icon,
} from "hugeicons-react";
import { TSTYLES } from "zxStyles/constants";
import { CustomSkeleton, Divider } from "components/UI/Components/Components";
import { useZxTokenPageContext } from "./ZxTokenPageContext";
import { CurrencyCircleDollar } from "../../../../node_modules/@phosphor-icons/react/dist/index";
import { ArrowRight01Icon } from "hugeicons-react";
import { PriceChange } from "components/UI/Components/Components";
import { createPortal } from "react-dom";
import { GlobalContext } from "contextStates/Global";
import { useContext } from "react";
import { Drawer } from "../../../../node_modules/@mui/material/index";
import TokenHoldingsRightPanel from "components/V2/Profile/ProfileDetails/Portfolio/TokenHoldings/TokenHoldingsRightPanel";
import { useAuthContext } from "contextStates/AuthContext";
export const TOKEN_TABS = {
  RECENT_TXNS: "recent_txns",
  FOLLOWEE_SWAPS: "followee_pnls",
  // TOP_TRADERS: "top_traders",
  TOP_HOLDERS: "top_holders",
};
export const TOKEN_PAGE_TABS_LIST = [
  {
    render: ({ isActive }) => {
      return (
        <ZxFlex className={isActive ? styles.activeTab : styles.inactiveTab}>
          <ArrowReloadHorizontalIcon
            color={isActive ? "var(--text-1)" : "var(--text-2)"}
            size={16}
            strokeWidth="2px"
          />
          <ZxText
            color={isActive ? "text-1" : "text-2"}
            style={TSTYLES.body1}
            fontSize="13px">
            Recent Txns
          </ZxText>
        </ZxFlex>
      );
    },
    key: TOKEN_TABS.RECENT_TXNS,
  },
  {
    render: ({ isActive }) => {
      return (
        <ZxFlex className={isActive ? styles.activeTab : styles.inactiveTab}>
          <UserGroupIcon
            color={isActive ? "var(--text-1)" : "var(--text-2)"}
            size={16}
            strokeWidth="2px"
          />
          <ZxText
            color={isActive ? "text-1" : "text-2"}
            style={TSTYLES.body1}
            fontSize="13px">
            Followee PNLs
          </ZxText>
        </ZxFlex>
      );
    },
    key: TOKEN_TABS.FOLLOWEE_SWAPS,
  },
  // {
  //   render: ({ isActive }) => {
  //     return (
  //       <ZxFlex className={isActive ? styles.activeTab : styles.inactiveTab}>
  //         <ChampionIcon
  //           color={isActive ? "var(--text-1)" : "var(--text-2)"}
  //           size={16}
  //           strokeWidth="2px"
  //         />
  //         <ZxText
  //           color={isActive ? "text-1" : "text-2"}
  //           style={TSTYLES.body1}
  //           fontSize="13px">
  //           Top Traders
  //         </ZxText>
  //       </ZxFlex>
  //     );
  //   },
  //   key: TOKEN_TABS.TOP_TRADERS,
  // },
  {
    render: ({ isActive }) => {
      return (
        <ZxFlex className={isActive ? styles.activeTab : styles.inactiveTab}>
          <Diamond02Icon
            color={isActive ? "var(--text-1)" : "var(--text-2)"}
            size={16}
            strokeWidth="2px"
          />
          <ZxText
            color={isActive ? "text-1" : "text-2"}
            style={TSTYLES.body1}
            fontSize="13px">
            Top Holders
          </ZxText>
        </ZxFlex>
      );
    },
    key: TOKEN_TABS.TOP_HOLDERS,
  },
];

export const ZxTokenPageHeaderLoader = () => {
  return (
    <ZxFlex className={styles.headerLoader} justify="space-between">
      <ZxFlex gap="8px">
        <CustomSkeleton variant="circular" height="32px" width="32px" />
        <CustomSkeleton
          variant="rectangular"
          width="180px"
          height="28px"
          borderRadius="4px"
        />
      </ZxFlex>
      <ZxFlex gap="8px">
        <CustomSkeleton
          variant="rectangular"
          width="120px"
          height="28px"
          borderRadius="8px"
        />
        {[1, 2, 3, 4, 5].map((i) => (
          <CustomSkeleton
            key={i}
            variant="rectangular"
            width="32px"
            height="32px"
            borderRadius="8px"
          />
        ))}
      </ZxFlex>
    </ZxFlex>
  );
};

export const ZxTokenPageMyHoldings = ({ token }) => {
  const { myHoldings } = useZxTokenPageContext();
  const { identityDetails } = useAuthContext();
  const identity = identityDetails?.current?.identity;
  const { userTokenTransferDetails, setUserTokenTransferDetails } =
    useContext(GlobalContext);
  const value = myHoldings?.total_usd_value?.value;
  if (!myHoldings || !value) return null;
  const displayValue = myHoldings?.total_usd_value?.display_value;
  const openPnl = myHoldings?.open_pnl?.value;
  return (
    <>
      <div
        className={rightBarStyles.card}
        onClick={() => {
          const params = {
            tokenData: {
              ...token,
              ...token?.profile,
              chain_id: token?.profile?.address_chain,
            },
            actor: {
              id: identity.identity_id ?? identity.id,
              details: identity,
            },
            token_metadata: {
              ...token?.profile,
              ...token,
            },
            chain_id: token?.profile?.address_chain,
            contract_address: token?.profile?.address,
          };
          setUserTokenTransferDetails({
            actor: identity,
            tokenData: { ...params, ...myHoldings },
          });
        }}>
        <ZxFlex dir="column" align="flex-start" cursor="pointer">
          <ZxFlex gap="4px">
            <CurrencyCircleDollar
              color="var(--success)"
              size={24}
              weight="duotone"
            />
            <ZxText style={TSTYLES.secondaryTitle}>Your Holdings</ZxText>
          </ZxFlex>
          <Divider margin="8px 0" />
          <ZxFlex justify="space-between" width="100%">
            <ZxFlex gap="8px">
              <ZxText style={TSTYLES.title1} fontSize="24px">
                {displayValue}
              </ZxText>
              <PriceChange
                value={openPnl}
                displayValue={myHoldings?.open_pnl?.display_value}
                fontWeight="500"
              />
            </ZxFlex>
            <ArrowRight01Icon color="var(--text-2)" size={20} />
          </ZxFlex>
        </ZxFlex>
      </div>
      {createPortal(
        <Drawer
          anchor="right"
          open={userTokenTransferDetails?.tokenData}
          onClose={() => {
            console.log("called");
            setUserTokenTransferDetails({});
          }}>
          <TokenHoldingsRightPanel
            currentTokenData={userTokenTransferDetails?.tokenData}
            actorData={userTokenTransferDetails?.actor}
            identifier={
              userTokenTransferDetails?.actor?.id ??
              userTokenTransferDetails?.actor?.details?.identity_id ??
              userTokenTransferDetails?.actor?.details?.address
            }
            onClose={() => setUserTokenTransferDetails({})}
          />
        </Drawer>,
        document.getElementById("rightPanel")
      )}
    </>
  );
};
