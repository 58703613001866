import {
  CustomRow,
  PaddingComponent,
  VerticalDivider,
} from "components/UI/Components/Components";
import React from "react";
import { ReactComponent as CrossIcon } from "assets/svg/profile/cross.svg";
import { MiniProfile } from "./SelectedEdge";
import ColumnSearchField from "components/Pages/Screener/ScreenerRightPanel/components/ColumnSearchField";
import { CustomCircularProgress } from "components/UI/Components/Components";

export const ExplorerHeaderInput = ({
  profilesList,
  source,
  onAddValue,
  onRemove,
  isIdentityGraph,
  canRemove,
  isGraphLoading,
}) => {
  return (
    <CustomRow alignItems="center" border="1px solid var(--border-dark)">
      <CustomRow
        padding="4px"
        gap="4px"
        height="36px"
        justifyContent="space-between" //added
        overflowX="scroll"
        direction="rtl"
        style={{
          maxWidth: "450px",
        }}
        alignItems="center">
        {profilesList?.map((profile, index) => (
          <IdentifierTile
            key={profile}
            option={profile}
            source={source}
            isIdentityGraph={isIdentityGraph}
            canRemove={canRemove}
            isDivider={index !== profilesList.length - 1}
            onRemove={() => {
              onRemove(profile);
            }}
          />
        ))}
      </CustomRow>
      <PaddingComponent paddingLeft="4px">
        <VerticalDivider height="20px" />
      </PaddingComponent>
      {isGraphLoading && profilesList.length > 0 ? (
        <CustomRow width="80px" justifyContent="center">
          <CustomCircularProgress size={16} />
        </CustomRow>
      ) : (
        <CustomRow width="160px" alignItems="center">
          <ColumnSearchField
            placeholder={"+ Add more"}
            source={source}
            height="20px"
            onAddValue={onAddValue}
          />
        </CustomRow>
      )}
    </CustomRow>
  );
};

export const IdentifierTile = ({
  onRemove,
  option,
  isIdentityGraph,
  canRemove,
  isDivider,
}) => {
  return (
    <div
      style={{
        direction: "ltr",
        padding: "4px 0",
      }}>
      <CustomRow
        padding="4px"
        gap="4px"
        alignItems="center"
        justifyContent="center">
        <MiniProfile profile={option} showAddress={!isIdentityGraph} />
        {canRemove && (
          <CustomRow
            flexShrink="0"
            width="10px"
            height="10px"
            cursor="pointer"
            onClick={onRemove}>
            <CrossIcon />
          </CustomRow>
        )}

        {isDivider && (
          <PaddingComponent paddingLeft="4px">
            <VerticalDivider height="20px" />
          </PaddingComponent>
        )}
      </CustomRow>
    </div>
  );
};
