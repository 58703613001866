import React from "react";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";

import styles from "./BioDetails.module.css";
import Image from "components/UI/Image";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import ZxInput from "zxComponents/common/ZxInput/ZxInput";
import { Divider, SpinnerComponent } from "components/UI/Components/Components";
import GenericButton from "components/V2/GenericButton/GenericButton";
const BioForm = ({ onSubmit, register, errors, watch, isGenerating }) => {
  const interests = watch("interests");
  const hasError =
    interests?.length === 0 ||
    errors?.interests?.message ||
    errors?.interests?.type;
  return (
    <ZxFlex dir="column" gap="16px" align="flex-start">
      <ZxFlex gap="12px">
        <div className={styles.iconContainer}>
          <Image src="/png/briefcase.png" alt="work" />
        </div>
        <ZxFlex dir="column" gap="4px" align="flex-start">
          <ZxText style={TSTYLES.title1}>Share your tile and workplace</ZxText>
          <ZxText color="text-2">
            Build your credibility by sharing who you are and where you work.
          </ZxText>
        </ZxFlex>
      </ZxFlex>
      <form onSubmit={onSubmit} style={{ width: "100%" }}>
        <ZxFlex dir="column" gap="0" align="flex-start">
          <ZxFlex gap="16px">
            <ZxInput
              label={null}
              placeholder="Job Title (Optional)"
              name="title"
              register={register}
              disabled={isGenerating}
              width="280px"
              focusOnMount
            />

            <ZxInput
              label={null}
              placeholder="Workplace (Optional)"
              name="workplace"
              register={register}
              disabled={isGenerating}
              width="272px"
            />
          </ZxFlex>
          <Divider margin="20px 0" />
          <ZxFlex dir="column" gap="16px" align="flex-start">
            <ZxFlex gap="12px">
              <div className={styles.iconContainer}>
                <Image src="/png/dollar_circle.png" alt="crypto" />
              </div>
              <ZxFlex dir="column" gap="4px" align="flex-start">
                <ZxText style={TSTYLES.title1}>
                  What are your interests in crypto?
                </ZxText>
                <ZxText color="text-2">
                  Provide the people a good reason to follow you on our app.
                </ZxText>
              </ZxFlex>
            </ZxFlex>
            <ZxInput
              isTextArea
              register={register}
              textAreaResize="none"
              error={errors.interests}
              disabled={isGenerating}
              validation={{
                validate: (v) =>
                  v && v.length > 0 ? true : "This field is required",
              }}
              name="interests"
              width="568px"
              textAreaRows={8}
              placeholder="Ex. I trade meme coins, collect NFTs and invest in protocols."
            />

            <GenericButton
              type="submit"
              color="var(--text-white)"
              width="100%"
              disabled={hasError || isGenerating}
              borderRadius="8px"
              fontSize="15px"
              padding="12px 16px">
              <ZxFlex width="100%" justify="center">
                <ZxText style={TSTYLES.title1} fontSize="16px" color="white">
                  {isGenerating ? (
                    <ZxFlex>
                      <SpinnerComponent size={20} color="var(--text-white)" />
                      Generating bio...
                    </ZxFlex>
                  ) : (
                    "Generate Bio"
                  )}
                </ZxText>
              </ZxFlex>
            </GenericButton>
          </ZxFlex>
        </ZxFlex>
      </form>
    </ZxFlex>
  );
};

export default BioForm;
