import envelope from "assets/svg/feed/envelope.png";
import envelopeDark from "assets/svg/feed/envelope_dark.png";

import classes from "./Joined0xPPL.module.css";
import {
  CustomRow,
  CustomColumn,
  PaddingComponent,
} from "components/UI/Components/Components";
import JoinedCommentTile from "./JoinedCommentTile";
import JoinedCreateComment from "./JoinedCreateComment";
import { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { ThemeContext } from "contextStates/Theme.context";
import Bio from "components/V2/Profile/ProfileHeader/Bio";

const Joined0xPPLItem = ({ groupedActivity }) => {
  const { type } = useParams();
  const { theme } = useContext(ThemeContext);
  const isGroupedActivity = type === "welcome";
  const envelopeImage = theme === "light" ? envelope : envelopeDark;
  const commentsList = useCallback(() => {
    const threadsList = groupedActivity?.engagements?.replies?.threads || [];
    //reverse the ordering of threads list
    const reversedThreadsList = [...threadsList].reverse();
    if (reversedThreadsList.length > 3) {
      return reversedThreadsList.slice(0, 3);
    }
    return reversedThreadsList;
  }, [groupedActivity?.engagements?.replies?.threads]);
  return (
    <CustomRow width="100%" justifyContent="center">
      <div className={classes.envelopeContainer}>
        <img
          style={{
            background:
              theme === "light"
                ? isGroupedActivity
                  ? "var(--background)"
                  : "var(--base)"
                : "#FFFFFF03",
            boxShadow: `0px -2px 16px 0px ${
              theme === "light" ? "var(--border-light)" : "#FFFFFF03"
            }`,
          }}
          src={envelopeImage}
          alt={"envelope"}
          className={classes.envelope}
        />
        <div className={classes.envelopeItems}>
          <PaddingComponent height="20px" className={classes.paddingTop} />
          {!groupedActivity?.actor?.bio && <PaddingComponent height="30px" />}
          <CustomRow
            alignItems="center"
            gap="10px"
            className={classes.welcomeWrapper}>
            <div className={classes.divider} />
            <span className={classes.welcome}>WELCOME</span>
            <div className={classes.divider} />
          </CustomRow>
          <span className={classes.name}>
            {groupedActivity?.actor?.display_name}
          </span>
          {groupedActivity?.actor?.bio && (
            <Bio
              className={classes.welcomeBio}
              bio={groupedActivity?.actor?.bio}
            />
          )}
          <PaddingComponent height="42px" className={classes.paddingBottom} />
          <JoinedCreateComment groupedActivity={groupedActivity} />
          <PaddingComponent height="10px" />
          <div className={classes.dividerLong} />
          <PaddingComponent height="5px" />
          <CustomColumn className={classes.commentsWrapper}>
            {commentsList()?.map((comment, index) => (
              <JoinedCommentTile
                groupedActivity={groupedActivity}
                comment={comment}
                key={index}
              />
            ))}
          </CustomColumn>
        </div>
      </div>
    </CustomRow>
  );
};

export default Joined0xPPLItem;
