import { useContext, useState } from "react";
import styles from "./leftSideBar.module.css";
import { CustomText } from "components/UI/Components/Components";
import NotificationUnreadIcon from "components/V2/Notifications/NotificationUnreadIcon";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import useQueryParams from "customHooks/useQueryParams";
import { MessagingContext } from "contextStates/MessagingContext";
import { trackEvent } from "utils/event_tracking";

const LeftSideBarTile = ({
  title,
  link,
  Icon,
  isSelected,
  children,
  className = "",
  showNotificationCount = false,
  showUnreadMessagesCount = false,
  onNotificationPage = false,
  onClick = () => {},
  secondaryCount = null,
}) => {
  const params = useQueryParams();
  const [isHovering, setIsHovering] = useState(false);
  const { totalUnreadCount } = useContext(MessagingContext);

  const queryClient = useQueryClient();
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <Link
      to={link}
      className={className}
      onClick={() => {
        trackEvent(`left_sidebar_${title.toLowerCase().replace(/ /g, "_")}`);
        onClick();
        if (link === "/home" && window.location.pathname === "/home") {
          //scroll to top and reset feed
          window.scrollTo(0, 0);
          const queryKey = [
            "my_feed",
            "new_feed",
            null,
            params?.get("feed") || "",
          ];
          queryClient.resetQueries({
            queryKey,
            exact: true,
          });
          return;
        }
      }}>
      <div
        className={styles.left_side_bar_tile}
        style={{
          backgroundColor: isSelected
            ? "var(--primary-color10)"
            : isHovering
              ? "var(--primary-color05)"
              : "var(--base)",
          width: "100%",
        }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}>
        {Icon && (
          <Icon
            size={20}
            weight="duotone"
            style={{ flexShrink: 0 }}
            color={`${isSelected ? "var(--primary-color)" : "var(--text-2)"}`}
          />
        )}
        {children}
        <div className={styles.left_side_bar_tile_text}>
          <CustomText
            text={title}
            color={`${
              isSelected
                ? "var(--primary-color)"
                : isHovering
                  ? "var(--text-1)"
                  : "var(--text-2)"
            }`}
            fontSize="13px"
            fontWeight={`${isHovering || isSelected ? "500" : "400"}`}
            lineHeight="19.5px"
            style={{
              paddingLeft: "12px",
              transition: "transform 0.5s ease-in-out, width 0.5s ease-in-out",
              overflow: "hidden",
              flexGrow: "1",
              justifyContent: "flex-start",
              textAlign: "left",
            }}
          />
          {showNotificationCount && (
            <NotificationUnreadIcon onNotificationPage={onNotificationPage} />
          )}
        </div>
        {showUnreadMessagesCount && totalUnreadCount > 0 && (
          <div className={styles.notificationDot}>{totalUnreadCount}</div>
        )}
        {!!secondaryCount && secondaryCount > 0 && (
          <div className={`${styles.notificationDot} ${styles.secondaryDot}`}>
            {secondaryCount}
          </div>
        )}
      </div>
    </Link>
  );
};

export default LeftSideBarTile;
