import React, { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDeletePostMutation } from "api/feed.api";
import Spinner from "shared/buttonloadingSpinner";
import { GlobalContext } from "contextStates/Global";
import classes from "./deletePostDialog.module.css";
import { useQueryClient } from "@tanstack/react-query";
import { fetchQueryWithPartialKeys } from "utils/misc";
import { useParams, useHistory } from "react-router-dom";

const DeletePostDialog = ({
  postId,
  handleDialogToggle,
  openDialog,
  queryKeys,
  onDelete,
  isComment = false,
}) => {
  const history = useHistory();
  const { id: idParam, type: typeParam } = useParams();
  const { handleErrorSnackbar, setSnackBarState } = useContext(GlobalContext);
  const queryClient = useQueryClient();
  const onSuccess = () => {
    handleDialogToggle();
    setSnackBarState({
      open: true,
      message: `${isComment ? "Comment" : "Post"} deleted`,
      type: "success",
    });
    fetchQueryWithPartialKeys({ queryClient, queryKeys });
    onDelete();
    // if trying to delete a post from post details page
    if (typeParam === "post" && postId === parseInt(idParam)) {
      const [, parentId, parentType] = queryKeys?.[0] || [];
      // if it has parent post/feed item, redirect to that
      if (!!parentId && !!parentType) {
        history.replace(`/${parentType}/${parentId}`);
      } else {
        // else redirect to /home/following
        queryClient.refetchQueries(["my_feed"]);
        history.replace("/home/following");
      }
    }
  };
  const onError = (err) => {
    handleErrorSnackbar(err, "Error deleting" + isComment ? "comment" : "post");
  };

  const { mutate: deletePostMutation, isPending: isLoading } =
    useDeletePostMutation({
      onSuccess,
      onError,
    });

  const handleDeleteConfirmation = () => {
    deletePostMutation({ postId });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogToggle}
      classes={{ paper: classes.deleteDialog }}
      //
      sx={{
        ".MuiPaper-root": {
          background: "var(--base)",
        },
      }}>
      <DialogTitle>
        <div className={classes.dialogTitle}>
          Delete {isComment ? "comment" : "post"}
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className={classes.dialogContent}>
            Are you sure you want to delete this{" "}
            {isComment ? "comment" : "post"} ?
          </div>
        </DialogContentText>
      </DialogContent>

      {isLoading ? (
        <DialogContent>
          <Spinner
            style={{
              stroke: "var(--primary-color)",
              background: "transparent",
              width: "20px",
              height: "20px",
              display: "block",
              margin: "auto",
            }}
          />
        </DialogContent>
      ) : (
        <DialogActions>
          <button
            onClick={handleDialogToggle}
            className={`${classes.dialogButton} ${classes.cancelButton}`}>
            No
          </button>
          <button
            onClick={handleDeleteConfirmation}
            autoFocus
            className={`${classes.dialogButton} ${classes.confirmButton}`}>
            Yes
          </button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DeletePostDialog;
