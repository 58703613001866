import ProtocolHoldings from "./ProtocolHoldings/ProtocolHoldings";
import TokenHoldings from "./TokenHoldings/TokenHoldings";
import classes from "./Portfolio.module.css";
// import ProfileGraphs from "./ProfileGraphs/ProfileGraphs";
import { useEffect, useState, useContext } from "react";
import { ProfileContext } from "contextStates/ProfileContext";
import AppliedFiltersWrapper from "../Filters/AppliedFiltersWrapper";
import BundlesTabList from "components/V2/Profile/ProfileDetails/wallets/WalletBundles/BundlesTabList";
import { GlobalContext } from "contextStates/Global";
import NFTHoldings from "../NFTHoldings/NFTHoldings";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { PaddingComponent } from "../../../../UI/Components/Components";

const Portfolio = ({
  identifier,
  hideGraph = false,
  forProtocolTreasury = false,
  isCosmos = false,
}) => {
  const { isLoggedInUserProfile } = useContext(ProfileContext);
  const { featureFlags } = useContext(GlobalContext);
  const [updatePortfolio, setUpdatePortfolioTab] = useState(false);
  const {
    setUpdatePortfolioTabRenderFunction,
    selectedActiveChains,
    selectedActiveTokens,
    selectedActiveProtocols,
    selectedWalletAddresses,
    selectedGroupIds,
    setHoldingsTotalUsdValue,
  } = useContext(ProfileContext);

  useEffect(() => {
    setUpdatePortfolioTabRenderFunction(setUpdatePortfolioTab, updatePortfolio);
    return () => setUpdatePortfolioTabRenderFunction(null, null);
  }, [setUpdatePortfolioTabRenderFunction, updatePortfolio]);
  return (
    <BaseWrapper mixpanelEvent="Profile/Portfolio">
      <PaddingComponent padding="0 16px">
        <div className={classes.portfolio}>
          {isLoggedInUserProfile && featureFlags.is_showing_private_wallets && (
            <BundlesTabList />
          )}

          <AppliedFiltersWrapper identifier={identifier} />
          {/* temp commenting the graph for use case */}
          {/* {!hideGraph && (
        <ProfileGraphs
          forProtocolTreasury={forProtocolTreasury}
          selectedActiveChains={selectedActiveChains.current}
          selectedActiveTokens={selectedActiveTokens.current}
          selectedActiveProtocols={selectedActiveProtocols.current}
          identifier={identifier}
        />
      )} */}

          <TokenHoldings
            forProtocolTreasury={forProtocolTreasury}
            identifier={identifier}
            selectedActiveTokens={selectedActiveTokens.current}
            selectedActiveChains={selectedActiveChains.current}
            selectedActiveProtocols={selectedActiveProtocols.current}
            selectedWalletAddresses={selectedWalletAddresses.current}
            setHoldingsTotalUsdValue={setHoldingsTotalUsdValue}
            isCosmos={isCosmos}
          />
          <ProtocolHoldings
            forProtocolTreasury={forProtocolTreasury}
            selectedActiveTokens={selectedActiveTokens.current}
            identifier={identifier}
            selectedActiveProtocols={selectedActiveProtocols.current}
            selectedActiveChains={selectedActiveChains.current}
            selectedWalletAddresses={selectedWalletAddresses.current}
            setHoldingsTotalUsdValue={setHoldingsTotalUsdValue}
          />
          <NFTHoldings
            identifier={identifier}
            selectedActiveChains={selectedActiveChains.current}
            selectedGroupIds={selectedGroupIds.current}
          />
        </div>
      </PaddingComponent>
    </BaseWrapper>
  );
};

export default Portfolio;
