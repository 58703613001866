import { signedApiRequest } from "api/api";
import { Confidence } from "components/UI/Confidence/Confidence";
import { GlobalContext } from "contextStates/Global";
import { useContext, useState } from "react";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import BundleButton from "./BundleButton";
import FeedDescription from "../FeedDescription";
import styles from "./BundleSuggestion.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import BundleSuggestionHoverCard from "./BundleSuggestionHoverCard";

import {
  PaddingComponent,
  CustomRow,
  CursorDiv,
  CustomCircularProgress,
  CustomColumn,
  Divider,
} from "components/UI/Components/Components";
import { AuthContext } from "contextStates/AuthContext";

const BundleSuggestionTile = ({
  relatedData,
  profiles,
  methodIds,
  isAdminView,
  actor,
  nativeTokensInfo = {},
  showViewTransfers = true,
  refetchGroupedActivity,
  isBottomBorder = true,
  isUpperBorder = true,
  fetchGraph,
  paddingLeft = "0px",
}) => {
  const [discarding, setDiscarding] = useState(false);
  const [show, setShow] = useState(true);
  const [, setIsUpdate] = useState(false);
  const { handleErrorSnackbar, setSnackBarState } = useContext(GlobalContext);
  const { isUserLoggedIn, setShowLoginPopup } = useContext(AuthContext);

  if (!show) {
    return null;
  }

  const onBundle = () => {
    if (refetchGroupedActivity) {
      refetchGroupedActivity();
    }
    if (fetchGraph) {
      fetchGraph();
    }
    setShow(false);
  };

  const onDiscard = () => {
    if (!isUserLoggedIn) {
      setShowLoginPopup(true);
      return;
    }
    setDiscarding(true);
    const data = {
      address_a: actor.address,
      address_b: relatedData.address,
    };

    signedApiRequest({
      path: "discard_wallet_relationship",
      method: "post",
      bodyText: JSON.stringify(data),
    })
      .then((data) => {
        setShow(false);

        setSnackBarState({
          open: true,
          message: "Discarded",
          type: "success",
        });
      })
      .catch((error) => {
        setDiscarding(false);

        handleErrorSnackbar(error, "Failed to Bundle");
      });
  };

  return (
    <CustomColumn>
      {isUpperBorder && <Divider />}
      <div className={styles.container} style={{ paddingLeft }}>
        <CustomRow alignItems="center">
          <PPLxHoverCard
            content={
              <BundleSuggestionHoverCard
                discarding={discarding}
                relatedData={relatedData}
                profiles={profiles}
                methodIds={methodIds}
                isAdminView={isAdminView}
                actor={actor}
                nativeTokensInfo={nativeTokensInfo}
                onDiscard={onDiscard}
                onBundle={onBundle}
                setIsUpdate={setIsUpdate}
                showViewTransfers={showViewTransfers}
              />
            }>
            <div>
              <PaddingComponent paddingTop="7px" paddingBottom="8px">
                <Confidence confidence={relatedData?.confidence} />
              </PaddingComponent>
            </div>
          </PPLxHoverCard>
          <PaddingComponent width="14px" />
          <div className={styles.description}>
            <FeedDescription
              templateString={
                relatedData.template_string ||
                "{{person||" +
                  relatedData.address +
                  "}} may be a wallet of {{person||" +
                  relatedData.related_with +
                  "}}"
              }
              profiles={profiles}
              methodIds={methodIds}
              isAdminView={isAdminView}
              actor={actor}
              nativeTokensInfo={nativeTokensInfo}
              refetchGroupedActivity={refetchGroupedActivity}
              appendAddressToSameName={false}
            />
          </div>
          {(actor?.identity_id || actor?.id) && (
            <CustomRow gap="16px">
              <PaddingComponent width="8px" />
              <div className={styles.verticalDivider} />
              <BundleButton
                identityId={actor.id || actor.identity_id}
                address={relatedData.address}
                onSuccess={onBundle}
              />
              <div className={styles.verticalDivider} />
            </CustomRow>
          )}
          <PaddingComponent width="16px" />
          {discarding ? (
            <CustomCircularProgress />
          ) : actor?.identity_id || actor?.id ? (
            <CursorDiv
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (discarding) return;
                onDiscard();
              }}>
              <Box
                component={CloseIcon}
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "18px",
                  color: "var(--text-2)",
                }}
              />
            </CursorDiv>
          ) : null}
        </CustomRow>
      </div>
      {isBottomBorder && <Divider />}
    </CustomColumn>
  );
};

export default BundleSuggestionTile;
