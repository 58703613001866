import {
  CustomRow,
  CustomButton,
  CustomText,
} from "components/UI/Components/Components";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import classes from "./MyHoldingsRightPanel.module.css";

const AllHoldings = ({ holdingsData, selectedHolding, setSelectedHolding }) => {
  return (
    <CustomRow
      overflowX="scroll"
      gap="8px"
      padding="16px 20px 10px"
      flexShrink={0}
      height="68px"
      className={classes.allHoldings}>
      {holdingsData?.map((holding) => {
        let isSelected = false;
        if (selectedHolding?.type === "Token") {
          isSelected =
            selectedHolding?.token_address === holding.token_address &&
            selectedHolding?.str_chain_id === holding.str_chain_id;
        } else if (selectedHolding?.type === "Protocol") {
          isSelected = selectedHolding?.tokens === holding.tokens;
        }
        return (
          <CustomButton
            className={classes.holdingsButton}
            background={
              isSelected ? "var(--primary-color10)" : "var(--elevation-1)"
            }
            onClick={() => {
              setSelectedHolding(holding);
            }}
            flexWrap="nowrap"
            text={
              <>
                <IconWithChain
                  src={holding?.display_image}
                  chain={holding?.str_chain_id}
                  showImageFullHeightWidth
                  iconHeight="24px"
                  iconWidth="24px"
                  chainHeight="10px"
                  chainWidth="10px"
                  chainRightDistance="-5px"
                />
                <CustomText
                  text={holding?.token_details?.symbol || holding?.display_name}
                  whiteSpace="nowrap"
                />
                <CustomText
                  text={holding?.total_usd_value?.display_value}
                  color="var(--text-2)"
                />
              </>
            }></CustomButton>
        );
      })}
    </CustomRow>
  );
};

export default AllHoldings;
