import React, { useEffect } from "react";
import { debounceFunction, searchInArray } from "utils/misc";
import searchIcon from "assets/svg/search_icon.svg";
import { ClickAwayListener } from "@mui/material";
import styles from "./LogsSearchBar.module.css";

const LogsSearchBar = ({
  items,
  filterColumns,
  setSearchResult,
  searchTerm,
  searchResult,
  setSearchTerm,
  isSearchInputFocused,
  setIsSearchInputFocused,
}) => {
  const handleSearchDebounce = (e) => {
    debounceFunction(setSearchTerm(e.target.value), 300);
  };

  useEffect(() => {
    const handleLogsSearch = (inputValue) => {
      const term = inputValue?.trim();
      if (term?.length) {
        const res = searchInArray(items, filterColumns, term);
        setSearchResult(res);
      } else {
        setSearchResult([]);
      }
    };
    handleLogsSearch(searchTerm);

    return () => {
      setSearchResult([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, searchTerm]);

  return (
    <ClickAwayListener onClickAway={() => setIsSearchInputFocused(false)}>
      <div
        className={`${styles.searchInputContainer} ${
          isSearchInputFocused ? styles.focus : ""
        }`}>
        <div className={styles.searchInput}>
          <img
            className={styles.searchIcon}
            src={searchIcon}
            alt="search icon"
          />
          <input
            type="text"
            placeholder="Search Logs"
            value={searchTerm}
            // ref={inputRef}
            onChange={handleSearchDebounce}
            onPaste={handleSearchDebounce}
            onFocus={() => setIsSearchInputFocused(true)}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default LogsSearchBar;
