import React from "react";
import { VerticalBarSeries, XAxis, XYPlot } from "react-vis";
import { Slider, Box } from "@mui/material";
import styled from "styled-components";
import { epochToReadable } from "utils/misc";
import classes from "./Explorer.module.css";

const ZeroXpplSlider = styled(Slider)({
  color: "var(--light-primary-color)",
  height: 4,
  padding: "13px 0",
  "& .MuiSlider-track": {
    height: 26,
    color: "var(--light-primary-color)",
    border: 0,
    borderRadius: 0,
  },
  "& .MuiSlider-rail": {
    color: "rgba(0,0,0, 0.1)",
    height: 26,
    borderRadius: 0,
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 8,
    backgroundColor: "var(--primary-color)",
    borderRadius: "4px",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
});

function valuetext(value) {
  return epochToReadable(value);
}

const ExplorerTimeline = React.memo(
  ({
    timelines,
    sliderStart,
    sliderEnd,
    onTimeRangeCommited,
    handleChange,
    timeRange,
    width,
    height,
  }) => {
    const innerWidth = width > 1400 ? width - 270 : width - 90;
    return (
      <div className={classes.timeline_container}>
        <XYPlot
          xType="ordinal"
          width={innerWidth}
          height={height}
          margin={{ left: 5, right: 10, bottom: 12, top: 10 }}>
          <VerticalBarSeries
            data={timelines}
            style={{
              stroke: "var(--explorer-edge)",
              fill: "var(--explorer-edge)",
            }}
          />

          <XAxis
            tickFormat={(d) => {
              return epochToReadable(+d);
            }}
            tickTotal={4}
            tickSizeInner={0}
            tickSizeOuter={5}
            orientation="bottom"
            style={{
              ticks: {
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "10px",
                lineHeight: "130%",
                textAlign: "center",
                letterSpacing: " -0.02em",
                fill: "var(--explorer-edge)",
                marginLeft: "20px",
                marginBottom: "30px",
              },
              line: { stroke: "var(--explorer-edge)" },
            }}
          />
        </XYPlot>
        <div className={classes.timeline_slider}>
          <Box sx={{ width: innerWidth }}>
            <ZeroXpplSlider
              getAriaLabel={() => "All transaction Time Line"}
              value={timeRange}
              onChange={handleChange}
              onChangeCommitted={onTimeRangeCommited}
              valueLabelDisplay="auto"
              min={sliderStart}
              max={sliderEnd}
              valueLabelFormat={valuetext}
            />
          </Box>
        </div>
      </div>
    );
  }
);

export default ExplorerTimeline;
