import { IMAGE_TYPES } from "components/UI/Image";
import { useCallback, useEffect, useState } from "react";
import { getChainMetaData } from "utils/misc";
import { useHistory } from "react-router-dom";
import { trackEvent } from "utils/event_tracking";

export const inferImageType = (result) => {
  if (!result) {
    return IMAGE_TYPES.AVATAR;
  }

  if (result.type === "identity" || result.data.type === "External") {
    return IMAGE_TYPES.AVATAR;
  }
  if (result.data.is_token) {
    return IMAGE_TYPES.TOKEN;
  }
  return IMAGE_TYPES.CONTRACT;
};

export const resultType = (resultItem) => {
  if (!resultItem) {
    return null;
  }
  if (resultItem.type === "identity") {
    return "Profile";
  }
  if (resultItem?.type === "token" || resultItem?.data?.is_token) {
    return "Token";
  }
  if (resultItem?.type === "protocol") {
    return "Protocol";
  }
  if (resultItem?.type === "native_token") {
    return "Native Token";
  }
  if (
    resultItem.type === "address" &&
    resultItem.data.address_type === "External"
  ) {
    return "Wallet";
  }
  return null;
};

export const getChainData = (profile) => {
  if (!profile) {
    return null;
  }

  if (profile?.address_chain) {
    return getChainMetaData().find((chainItem) => {
      if (chainItem.name === profile?.address_chain) {
        return chainItem;
      }
      return null;
    });
  }
};

export const useRecentSearchResults = () => {
  const [recentSearchResults, setRecentSearchResults] = useState([]);

  useEffect(() => {
    const localResults = localStorage.getItem("recentSearchResults");
    if (localResults) {
      setRecentSearchResults(JSON.parse(localResults));
    }
  }, []);

  const updateRecentSearches = (result) => {
    if (!result) {
      return;
    }

    let searchResults =
      JSON.parse(localStorage.getItem("recentSearchResults")) || [];

    const index = searchResults.findIndex(
      (item) => item?.data?.display_name === result?.data?.display_name
    );

    if (index !== -1) {
      if (index !== 0) {
        searchResults.splice(index, 1);
      } else {
        return;
      }
    }

    delete result.data.net_worth;

    searchResults.unshift(result);

    if (searchResults.length > 5) {
      searchResults.pop();
    }

    localStorage.setItem("recentSearchResults", JSON.stringify(searchResults));
    setRecentSearchResults(searchResults);
  };

  return { recentSearchResults, updateRecentSearches };
};

export const useKeyboardNavigation = ({
  filteredSearchResult,
  isSearchInputFocused,
  handleSearchBlur,
  searchUuid,
}) => {
  const history = useHistory();
  const [selectedResultIndex, setSelectedResultIndex] = useState(0);
  const { updateRecentSearches } = useRecentSearchResults();

  const nextSearchResult = useCallback(
    (prevIndex) =>
      prevIndex === filteredSearchResult.length - 1 ? 0 : prevIndex + 1,
    [filteredSearchResult.length]
  );

  const previousSearchResult = useCallback(
    (prevIndex) =>
      prevIndex === 0 ? filteredSearchResult.length - 1 : prevIndex - 1,
    [filteredSearchResult.length]
  );

  useEffect(() => {
    const handleNavigation = (event) => {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setSelectedResultIndex(nextSearchResult);
      }
      if (event.key === "ArrowUp") {
        event.preventDefault();
        setSelectedResultIndex(previousSearchResult);
      }
      if (event.key === "Enter") {
        if (filteredSearchResult.length === 0) return;
        const selectedResult = filteredSearchResult[selectedResultIndex];
        const link = selectedResult.data.link;
        updateRecentSearches(selectedResult);
        history.push(link);
        trackEvent("search_query", {
          uuid: searchUuid,
          selected_index: selectedResultIndex,
        });
        handleSearchBlur();
      }
      if (event.key === "Escape") {
        handleSearchBlur();
      }
    };

    if (isSearchInputFocused) {
      document.addEventListener("keydown", handleNavigation);

      return () => document.removeEventListener("keydown", handleNavigation);
    }
  }, [
    filteredSearchResult,
    handleSearchBlur,
    history,
    nextSearchResult,
    previousSearchResult,
    selectedResultIndex,
    isSearchInputFocused,
    updateRecentSearches,
    searchUuid,
  ]);

  return { selectedResultIndex };
};
