import Image, { IMAGE_TYPES } from "components/UI/Image";
import classes from "./ProtocolInfo.module.css";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";
import { CustomRow } from "components/UI/Components/Components";

const ProtocolInfo = ({ info, chainId }) => {
  const {
    name: display_name,
    display_picture,
    contract_count,
    chain_count,
  } = info;

  return (
    <div className={classes.container}>
      <CustomRow alignItems="center" flexGrow="1">
        <Image
          src={display_picture}
          alt={display_name}
          imageType={IMAGE_TYPES.CONTRACT}
          className={classes.image}
        />
        <div className={classes.details}>
          <div className={classes.top}>
            <div className={classes.name}>{display_name}</div>
            <div className={classes.contract_tag}>Protocol</div>
          </div>
          <div className={classes.bottom}>
            <div className={classes.deployed_by_text}>
              {contract_count} Contracts · {chain_count} Chains
            </div>
          </div>
        </div>
      </CustomRow>
      <div className={classes.socials}>
        <ExternalLinks links={info?.external_links} />
      </div>
    </div>
  );
};

export default ProtocolInfo;
