import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";

const TopBar = ({ hasToken, isEnabled = true }) => {
  const { active_tab } = useParams();
  const currentStep = active_tab || "contracts";
  const tabsList = [
    {
      title: "Contracts",
      link: "contracts",
    },
    {
      title: "TVL Info",
      link: "tvlinfo",
    },
    {
      title: "Activities",
      link: "activities",
    },
  ];
  if (hasToken) {
    tabsList.splice(1, 0, {
      title: "Token",
      link: "token",
    });
  }
  return (
    <CustomLinkTabBar
      isEnabled={isEnabled}
      currentStep={currentStep}
      tabsList={tabsList}
      zIndex={TABBAR_ZINDEX.HIGH}
    />
  );
};

export default TopBar;
