import classes from "./genericHoldingDetails.module.css";
import {
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableTitle,
} from "components/UI/Table/Table";
import { TokenBalance, TokenIcon } from "./TokenInfoWrapper";
import { signedRequest } from "api/api";
import { Rewards } from "./V3LiquidityProviderDetails";
import { getTrimedAddress, getTrimmedString } from "utils/misc";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { getBalanceSymbols } from "../Portfolio.utils";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";

const GenericHoldingDetails = ({
  setCurrentPositionIndex,
  setCurrentProtocolIndex,
  setCurrentHoldingsLabel,
  holdingsLabel,
  protocolIndex,
  label,
  holdings,
  totalUsdValue,
  setIsRightPaneOpen,
  protocolHoldingItem,
  setCurrentTxnIdentifiers,
  setCurrentPoolAddress,
  address,
}) => {
  const handleClick = (e, holdingsItem, index) => {
    setCurrentTxnIdentifiers(null);
    setCurrentPoolAddress(null);
    const txnIdentifiers = holdingsItem?.transactions;
    if (!!txnIdentifiers) {
      setCurrentTxnIdentifiers(txnIdentifiers);
    }
    const poolAddress = holdingsItem?.pool_address;
    if (!!poolAddress) {
      setCurrentPoolAddress(holdingsItem?.pool_address);
    }

    if (
      (txnIdentifiers !== undefined && txnIdentifiers.length > 0) ||
      holdingsItem?.pool_address != null
    ) {
      e.stopPropagation();
      setCurrentHoldingsLabel(holdingsLabel);
      setCurrentProtocolIndex(protocolIndex);
      setCurrentPositionIndex(index);
      setIsRightPaneOpen(true);
    }
  };
  return (
    <Table>
      <TableTitle
        noPadding
        title={label + (address ? " (" + getTrimedAddress(address) + ")" : "")}
        subTitle={
          <div className={classes.totalUsdValue}>
            Total Value: {totalUsdValue}
          </div>
        }
      />
      <TableHeader noPadding>
        <TableCell alignLeft>Pool</TableCell>

        {holdings?.[0]?.rewards && (
          <TableCell alignLeft style={{ flexBasis: "35%" }}>
            Rewards
          </TableCell>
        )}
        {(holdings?.[0]?.usd_value_invested?.display_value ??
          holdings?.[0]?.usd_value_invested?.[0]?.display_value) && (
          <TableCell alignLeft style={{ flexBasis: "15%" }}>
            Invested
          </TableCell>
        )}
        <TableCell alignRight>USD Value</TableCell>
      </TableHeader>
      {holdings?.map((holdingsItem, index) => (
        <TableRow
          noPadding
          onClick={(e) => handleClick(e, holdingsItem, index)}
          key={`generic-protocols-${index}`}
          style={{
            cursor:
              !!holdingsItem?.transactions?.length ||
              !!holdingsItem?.pool_address
                ? "pointer"
                : "auto",
          }}>
          <TableCell alignLeft>
            <div
              className={`${classes.pools} ${
                holdingsItem.balance?.length > 4 ? classes.poolsOverflow : ""
              }`}>
              <PoolIcons
                balances={holdingsItem.balance}
                pools={holdingsItem.pool}
                height="20px"
                width="20px"
              />
              <PPLxTooltip
                title={getBalanceSymbols(holdingsItem.balance)}
                arrow
                placement="top">
                {getTrimmedString(getBalanceSymbols(holdingsItem.balance), 15)}
              </PPLxTooltip>
            </div>
          </TableCell>
          {holdingsItem.rewards && (
            <TableCell alignLeft style={{ flexBasis: "35%" }}>
              <Rewards rewards={holdingsItem.rewards} />
            </TableCell>
          )}
          {(holdings?.[0]?.usd_value_invested?.display_value ??
            holdings?.[0]?.usd_value_invested?.[0]?.display_value) && (
            <TableCell alignLeft style={{ flexBasis: "15%" }}>
              {holdings?.[0]?.usd_value_invested?.display_value ??
                holdings?.[0]?.usd_value_invested?.[0]?.display_value ??
                "-"}
            </TableCell>
          )}

          <TableCell alignRight>
            {holdingsItem.usd_value?.display_value}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
export const PoolIcons = ({ balances, pools, height, width, ...props }) => {
  // pick icon and url from pool if it is not available in balance
  return (
    <div className={classes.poolIcons} {...props}>
      {balances.map((balanceItem, index) => (
        <TokenIcon
          key={`token-icon-${index}`}
          icon={balanceItem?.token_icon || pools[index]?.token_icon}
          token_url={balanceItem?.token_url || pools[index]?.token_url}
          overlay
          height={height}
          width={width}
        />
      ))}
    </div>
  );
};
export const Balances = ({ balances, isRightPane }) => {
  return (
    <div
      className={`${classes.poolSymbols} ${
        isRightPane ? classes.rightPane : ""
      }`}>
      {balances.map((balanceItem, index) => (
        <TokenBalance
          className={classes.poolSymbol}
          balance={balanceItem}
          key={`token-balance-${index}`}
        />
      ))}
    </div>
  );
};

export const getActivitiesByIdentifiers = async ({
  txnIdentifiers,
  poolId,
  chainId,
  identifier,
}) => {
  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/get_activities_by_identifiers",
    bodyText: JSON.stringify({
      tx_identifiers: txnIdentifiers,
      pool_id: poolId,
      chain_id: chainId,
      actor: identifier,
    }),
  });

  return resp?.data?.data;
};

export default withErrorBoundary(GenericHoldingDetails);
