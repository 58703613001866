import logo from "assets/svg/elevated-logo.svg";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import classes from "./InfoSlider.module.css";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { trackEvent } from "utils/event_tracking";
import Popup from "components/UI/Popup/Popup";
import InviteCodeInput from "../InviteCodeInput/InviteCodeInput";
import { GlobalContext } from "contextStates/Global";
import DownloadAppBanner from "components/V2/DownloadAppBanner/DownloadAppBanner";
import WalletLoginPopup from "../WalletLoginPopup/WalletLoginPopup";

import useQueryParams from "customHooks/useQueryParams";
import { isValidInviteCode } from "api/waitlist.api";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "utils/query_utils";
import { CaretRight, Gift } from "@phosphor-icons/react";
import useScreenRes from "customHooks/useMediaQuery";
import { MOBILE_ONBOARDING_THRESHOLD, MobileOSTypes } from "utils/constants";
import { getMobileOperatingSystem } from "utils/misc";
// import { CaretRight, Wallet } from "@phosphor-icons/react";
// import ZxText from "zxComponents/common/ZxText/ZxText";
const CONNECT_TYPES = {
  LOGIN: "login",
  SIGNUP: "signup",
};
const InfoSlider = ({ connectType, setConnectType }) => {
  const { width } = useScreenRes();
  const location = useLocation();
  const params = useQueryParams();
  const icode = params.get("code");
  const [isInviteCodePopupOpen, setIsInviteCodePopupOpen] = useState(false);
  const [validInviteCode, setValidInviteCode] = useState(null);
  const { handleSuccessSnackbar } = useContext(GlobalContext);
  // const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  // const [modalType, setModalType] = useState(null);
  const { data: inviteValidity } = useQuery({
    queryKey: QUERY_KEYS.INVITE_CODE_VALIDATION({ inviteCode: icode }),
    enabled: !!icode,
    queryFn: () => isValidInviteCode({ inviteCode: icode }),
  });
  const onInviteCodeSuccess = useCallback(
    (code) => {
      setValidInviteCode(code);
      handleSuccessSnackbar("Invite code verified successfully", {
        vertical: "top",
        horizontal: "right",
      });
      storeCodeInLocalStorage(code);
    },
    [handleSuccessSnackbar]
  );
  useEffect(() => {
    if (inviteValidity && inviteValidity.is_valid && !validInviteCode) {
      onInviteCodeSuccess(icode);
      setConnectType(CONNECT_TYPES.SIGNUP);
    }
  }, [
    inviteValidity,
    icode,
    handleSuccessSnackbar,
    onInviteCodeSuccess,
    validInviteCode,
    setConnectType,
  ]);
  useEffect(() => {
    const fetchCodeFromURL = () => {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");
      if (code) {
        storeCodeInLocalStorage(code);
      }
    };
    fetchCodeFromURL();
  }, [location]);

  const storeCodeInLocalStorage = (code) => {
    localStorage.setItem("inviteCode", code);
  };

  const toggleInviteCodePopup = () => {
    if (!isInviteCodePopupOpen) {
      trackEvent("invite_code_button_click");
    }
    setIsInviteCodePopupOpen((old) => !old);
  };
  const changeToLogin = () => {
    trackEvent("login_button_click");
    setConnectType(CONNECT_TYPES.LOGIN);
  };

  const changeToSignup = () => {
    trackEvent("signup_button_click");
    setConnectType(CONNECT_TYPES.SIGNUP);
  };

  const isLogin = connectType === CONNECT_TYPES.LOGIN;
  const isSignup = connectType === CONNECT_TYPES.SIGNUP;
  const isMobile = width < MOBILE_ONBOARDING_THRESHOLD;
  const operatingSystem = getMobileOperatingSystem();
  return (
    <BaseWrapper mixpanelEvent="OnboardingInfoSlider">
      {isInviteCodePopupOpen && (
        <Popup
          title="Got an invite code?"
          width={isMobile ? "95%" : "520px"}
          onClose={toggleInviteCodePopup}
          isDivider={true}
          titleFontSize="20px"
          verticalPadding="4px"
          titleAlign="left"
          titleLetterSpacing="-0.4px"
          titleFontWeight="600">
          <InviteCodeInput
            onClose={toggleInviteCodePopup}
            onSuccess={onInviteCodeSuccess}
          />
        </Popup>
      )}
      {/* {isConnectModalOpen && (
        <WalletLoginPopup type={modalType} onClose={closeConnectModal} />
      )} */}
      <div className={classes.container}>
        <div className={classes.rightSide}>
          <div className={classes.iconLogo}>
            <img className={classes.logo} src={logo} alt="Logo" />
          </div>
          <PaddingComponent height="24px" />
          <CustomColumn>
            <CustomText
              className={classes.web3Text}
              text={
                <span>
                  Social network <br /> for crypto natives
                </span>
              }
              fontFamily="Roboto"
              fontWeight="500"
              fontSize="28px"
              color="#1b1f22"
              letterSpacing="-0.8px"
              lineHeight="130%"
            />
            <PaddingComponent height="16px" />
            <CustomText
              className={classes.web3Description}
              text={
                <span>
                  Track your portfolio, trade with friends and connect <br /> to
                  degens onchain
                </span>
              }
              fontFamily="Roboto"
              fontWeight="400"
              fontSize="15px"
              color="#687684"
              letterSpacing="-0.3px"
              lineHeight="160%"
            />
            <PaddingComponent height="24px" />
            <CustomRow
              alignItems="center"
              justifyContent="center"
              className={classes.heroBtnContainer}>
              <button
                onClick={changeToLogin}
                className={`${classes.heroBtn} ${isLogin ? classes.selected : ``} ${classes.login}`}>
                Login
              </button>
              <button
                onClick={changeToSignup}
                className={`${classes.heroBtn} ${isSignup ? classes.selected : ``} ${classes.signup}`}>
                Signup
              </button>
            </CustomRow>
            <PaddingComponent padding="24px 0 0 0">
              <WalletLoginPopup type={connectType} />
            </PaddingComponent>
            {!validInviteCode && connectType === CONNECT_TYPES.SIGNUP && (
              <PaddingComponent paddingTop="4px">
                <button
                  className={classes.inviteButton}
                  onClick={toggleInviteCodePopup}>
                  <CustomRow
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%">
                    <CustomRow alignItems="center" gap="8px">
                      <Gift size={16} color="var(--primary-color)" />
                      <CustomText
                        text="Got an invite code?"
                        fontWeight="400"
                        fontSize="15px"
                        color="var(--primary-color)"
                      />
                    </CustomRow>
                    <CaretRight size={16} color="var(--text-2)" weight="bold" />
                  </CustomRow>
                </button>
              </PaddingComponent>
            )}
          </CustomColumn>
          {(!isMobile || operatingSystem === MobileOSTypes.IOS) && (
            <DownloadAppBanner />
          )}
        </div>
      </div>
    </BaseWrapper>
  );
};

export default InfoSlider;
