import React, { useCallback, useContext, useEffect, useState } from "react";
import { usePostMutation } from "api/feed.api";
import PostSuccess from "./Components/PostSuccess";
import PostError from "./Components/PostError";
import PostCommentRenderer from "./PostCommentRenderer";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "contextStates/Global";
import { AuthContext } from "contextStates/AuthContext";
import { CustomRow } from "components/UI/Components/Components";
import { PenNib } from "@phosphor-icons/react";

const NewPost = ({ isHomePage = false }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const [isEditorFocused, setIsEditorFocused] = useState(
    history?.location?.search?.includes("newPost")
  );
  const [lastPostData, setLastPostData] = useState(null);
  const onPostSuccess = (data) => {
    setLastPostData(data);
  };
  const isLensFeatureEnabled =
    useContext(GlobalContext).featureFlags["is_lens_cross_post_enabled"];
  const { isUserLoggedIn, setShowLoginPopup } = useContext(AuthContext);
  const { mutate: postMutation, isPending: isLoading } = usePostMutation({
    setIsSuccess,
    setIsError,
    onSuccess: onPostSuccess,
  });
  const onSubmit = useCallback(
    ({ threads, selectedPlatforms, channelId }) => {
      if (!isUserLoggedIn) {
        setShowLoginPopup(true);
        return;
      }
      postMutation({
        threads,
        selectedPlatforms: isLensFeatureEnabled
          ? selectedPlatforms
          : selectedPlatforms.filter((platform) => platform !== "lens"),
        channelId,
      });
    },
    [postMutation, isLensFeatureEnabled, isUserLoggedIn, setShowLoginPopup]
  );

  useEffect(() => {
    if (isHomePage) {
      const open = history?.location?.search?.includes("newPost");
      if (open) {
        setIsEditorFocused(false);
        setTimeout(() => {
          setIsEditorFocused(true);
          window.scrollTo({ top: 0, behavior: "smooth" });
          history.replace("/home");
        }, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  useEffect(() => {
    let currentStateTimeout;
    if (isSuccess || isError) {
      // on submit, hide the success/error message after 2.5 seconds
      currentStateTimeout = setTimeout(() => {
        isSuccess && setIsSuccess(false);
        isError && setIsError(false);
      }, 2500);
    }
    return () => {
      clearTimeout(currentStateTimeout);
    };
  }, [isError, isSuccess]);

  if (isSuccess) {
    return <PostSuccess />;
  }

  return (
    <div>
      {isError && <PostError />}
      <PostCommentRenderer
        isHomePage={isHomePage}
        isNewPost={true}
        isLoading={isLoading}
        isEditorFocused={isEditorFocused}
        onSubmit={onSubmit}
        isSuccess={isSuccess}
        setIsSuccess={setIsSuccess}
        isError={isError}
        setIsError={setIsError}
        placeholder={
          <CustomRow alignItems="center" gap="8px">
            <PenNib size={16} color="var(--text-3" />
            What's on your mind today?
          </CustomRow>
        }
        showNewPostIcon
        commentSuccessResponse={lastPostData}
        resetCommentSuccessResponse={() => setLastPostData(null)}
      />
    </div>
  );
};

export default NewPost;
