import classes from "./WalletsPage.module.css";
import WalletHeader from "./components/walletsHeader";
import WalletsTableHeader from "./components/walletsTableHeader";
import classesTable from "./components/walletTableHeader.module.css";
import { ReactComponent as BundleSuggestionIcon } from "assets/svg/bundle_suggestion.svg";
import BundleSuggestionRowTile from "./BundleSuggestionRowTile";
const BundleSuggestions = ({ identityBundleData, refetch }) => {
  const relatedWallets = identityBundleData.suggestions;
  if (!identityBundleData || !relatedWallets || relatedWallets.length === 0) {
    return null;
  }

  return (
    <div className={classes.walletsBox}>
      <WalletHeader
        title={"Bundle Suggestions"}
        count={relatedWallets?.length}
        iconRender={<BundleSuggestionIcon />}
      />
      <div className={classesTable.table}>
        <WalletsTableHeader
          items={[
            { name: "WALLET", flex: 2 },
            { name: "NETWORTH", flex: 2 },
            { name: "SOURCED BY", flex: 2 },
            { name: "CONFIDENCE", flex: 3 },
            { name: "ACTIONS", alignment: "right", flex: 2 },
          ]}></WalletsTableHeader>
        {relatedWallets.length > 0 ? (
          Object.entries(relatedWallets).map(([key, bundle], index) => (
            <BundleSuggestionRowTile
              key={key}
              bundle={bundle}
              index={index}
              refetch={refetch}
              identityBundleData={identityBundleData}
            />
          ))
        ) : (
          <div className={classes.no_wallets}>No related wallets found</div>
        )}
      </div>
    </div>
  );
};

export default BundleSuggestions;
