import React, { useContext } from "react";
import { trackEvent } from "utils/event_tracking";
import DiscardOrDraftConfirmationDialog from "./DiscardOrDraftConfirmationDialog";
import { NewPostContext } from "./NewPost/NewPostV2";

const DiscardOrDraftButton = ({
  className,
  discardCallback,
  noConfirmation,
  saveDraft,
  isLoading,
  text = "Discard",
}) => {
  const { openDiscardModal, setOpenDiscardModal } = useContext(NewPostContext);

  const handleClick = () => {
    if (noConfirmation) {
      discardCallback();
      return;
    }
    trackEvent("post_discarded");
    handleDialogToggle();
  };

  const handleDialogToggle = () => {
    setOpenDiscardModal(!openDiscardModal);
  };

  return (
    <div onClick={(e) => e.stopPropagation()} className={className}>
      <button type="button" className={className} onClick={handleClick}>
        {text}
      </button>

      <DiscardOrDraftConfirmationDialog
        handleDialogToggle={handleDialogToggle}
        saveDraft={saveDraft}
        openDialog={openDiscardModal}
        discardCallback={discardCallback}
      />
    </div>
  );
};

export default DiscardOrDraftButton;
