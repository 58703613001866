import React, { useContext, useState } from "react";
import { Button, Container, FormControlLabel } from "@mui/material";
import { signedApiOtherRequest } from "api/api";
import { GlobalContext } from "contextStates/Global";
import CustomTextField from "components/FollowEditButton/CustomTextField";
import classes from "components/UI/Buttons/buttons.module.css";
import {
  CustomCheckBox,
  CustomRow,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const AuthorizeForm = () => {
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isTestingAddress, setIsTestingAddress] = useState(false);
  const [address, setAddress] = useState("");
  const [reason, setReason] = useState("");
  const [identityId, setIdentityId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { handleErrorSnackbar, setSnackBarState } = useContext(GlobalContext);

  return (
    <BaseWrapper mixpanelEvent="AuthorizeForm">
      <Container>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            marginTop: "12px",
          }}
          justifyContent="space-around"
          justifyItems="center"
          height={360}
          width={332}
          onSubmit={(event) => {
            event.preventDefault();
            const payload = {
              address,
              comments: reason,
              is_superuser: isSuperUser,
              is_address_for_testing: isTestingAddress,
              identity_id: identityId,
            };
            setSubmitting(true);
            signedApiOtherRequest({
              method: "post",
              path: "access_control/add_to_allow_list",
              bodyText: JSON.stringify(payload),
            })
              .then(() => {
                // success prompt
                setSnackBarState({
                  open: true,
                  message: "Successfully authorized address",
                  type: "success",
                });
                setSubmitting(false);
              })
              .catch((err) => {
                // failure prompt
                const err_msg = err.response.data?.errors?.errors;
                if (err_msg) {
                  setSnackBarState({
                    open: true,
                    message: `Error: ${err_msg}`,
                    type: "error",
                  });
                } else {
                  handleErrorSnackbar(err, "You're not a superuser");
                }
                setSubmitting(false);
              });
          }}>
          <CustomTextField
            variant="outlined"
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          <CustomTextField
            variant="outlined"
            label="Reason for addition"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
          />
          <CustomTextField
            variant="outlined"
            label="Identity Id"
            type="number"
            value={identityId ?? ""}
            onChange={(e) => setIdentityId(parseInt(e.target.value) || null)}
          />
          <FormControlLabel
            control={
              <PaddingComponent paddingLeft="24px">
                <CustomRow alignItems="center" gap="12px">
                  <CustomCheckBox
                    isActive={isSuperUser}
                    setIsActive={setIsSuperUser}
                  />
                  <CustomText text="SuperUser" />
                </CustomRow>
              </PaddingComponent>
            }
          />
          <FormControlLabel
            control={
              <PaddingComponent paddingLeft="24px">
                <CustomRow alignItems="center" gap="12px">
                  <CustomCheckBox
                    isActive={isTestingAddress}
                    setIsActive={setIsTestingAddress}
                  />
                  <CustomText text="Address is only for testing purpose" />
                </CustomRow>
              </PaddingComponent>
            }
          />
          <Button
            type="submit"
            variant="contained"
            className={classes.primaryButton}
            disabled={submitting}>
            Submit
          </Button>
          <Button
            onClick={() => {
              setIsSuperUser(false);
              setAddress("");
              setReason("");
              setIdentityId(null);
            }}
            variant="outlined"
            color="error"
            disabled={submitting}>
            Clear
          </Button>
        </form>
      </Container>
    </BaseWrapper>
  );
};

export default AuthorizeForm;
