import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "components/UI/Table/Table";
import React from "react";

export const AllBlocksPageShimmer = () => {
  return (
    <CustomColumn gap="16px" margin="36px 0 0 0">
      <CustomSkeleton variant="text" width="200px" height="30px" />

      <CustomRow justifyContent="space-between" alignItems="center">
        <CustomRow gap="16px">
          <TransactionStatsShimmer />
          <TransactionStatsShimmer />
        </CustomRow>
        <CustomSkeleton variant="circular" width="64px" height="64px" />
      </CustomRow>
    </CustomColumn>
  );
};

export const TransactionStatsShimmer = () => {
  return (
    <CustomColumn
      width="188px"
      height="74px"
      border="1px solid var(--border-dark)"
      background="var(--base)"
      borderRadius="10px"
      padding="14px 16px"
      gap="2px">
      <CustomSkeleton variant="text" width="120px" height="17px" />
      <CustomSkeleton variant="text" width="120px" height="26px" />
    </CustomColumn>
  );
};

export const BlocksTableShimmer = () => {
  return (
    <>
      <CustomColumn
        margin="16px 0"
        background="var(--base)"
        borderRadius="10px"
        border="1px solid var(--border-dark)">
        <CustomRow
          padding="16px 20px"
          alignItems="center"
          justifyContent="space-between">
          <CustomSkeleton variant="text" width="100px" height="22px" />

          <CustomRow gap="10px">
            <CustomSkeleton variant="rectangular" width="58px" height="20px" />
            <CustomSkeleton variant="rectangular" width="58px" height="20px" />
          </CustomRow>
        </CustomRow>
        <Divider />
        <Table>
          <TableHeader paddingTop background>
            <TableCell alignLeft>Block No</TableCell>
            <TableCell alignLeft>Hash</TableCell>

            <TableCell alignLeft>Created At</TableCell>
            <TableCell>Tx count</TableCell>
            <TableCell>Gas Consumed</TableCell>
          </TableHeader>
          <BlockTableRowShimmer />
          <BlockTableRowShimmer />
          <BlockTableRowShimmer />
          <BlockTableRowShimmer />
          <BlockTableRowShimmer />
          <BlockTableRowShimmer />
          <BlockTableRowShimmer />
        </Table>
      </CustomColumn>
      <PaddingComponent height="36px" />
    </>
  );
};

export const BlockTableRowShimmer = () => {
  return (
    <TableRow>
      <TableCell alignLeft>
        <CustomSkeleton variant="text" width="100px" />
      </TableCell>
      <TableCell alignLeft>
        <CustomSkeleton variant="text" width="80px" />
      </TableCell>
      <TableCell alignLeft>
        <CustomSkeleton variant="text" width="120px" />
      </TableCell>
      <TableCell alignLeft>
        <CustomSkeleton variant="text" width="50px" />
      </TableCell>
      <TableCell alignLeft>
        <CustomSkeleton variant="text" width="100px" />
      </TableCell>
    </TableRow>
  );
};
