import React, { useContext, useEffect } from "react";
import classes from "./readContract.module.css";
import PPLxAccordion from "shared/0xUI/PPLxAccordion/PPLxAccordion";
import ReadContractFormItem, { ResponseWrapper } from "./ReadContractFormItem";
import { ToggleSwitch } from "components/V2/ActivityDetails/Transactions/ParseToggleSwitch";
import { useState } from "react";
import {
  CustomRow,
  CustomTextSpan,
  FlexGrow,
  PaddingComponent,
} from "components/UI/Components/Components";
import ImpersonatorBox from "../../DevTools/ImpersonatorBox";
import { ArrowBendDownRight } from "@phosphor-icons/react";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import { AuthContext } from "contextStates/AuthContext";
const ReadWriteContract = ({ data, requestParams, isRead = true }) => {
  const isProxyAvailable = isRead
    ? data?.read_as_proxy != null
    : data?.write_as_proxy != null;
  const [isReadAsProxy, setIsReadAsProxy] = useState(isProxyAvailable);
  const [simulatedWallet, setSimulatedWallet] = useState(null);
  const { isSuperuser } = useContext(AuthContext);
  useEffect(() => {
    if (isRead) {
      setSimulatedWallet(null);
    }
  }, [isRead]);

  if (!data) {
    return null;
  }

  const list = isReadAsProxy
    ? isRead
      ? data?.read_as_proxy
      : data?.write_as_proxy
    : isRead
      ? data?.read_contract
      : data?.write_contract;
  return (
    <div className={classes.container}>
      <CustomRow alignItems="center" justifyContent="space-between">
        <FlexGrow>
          {isRead ? (
            <CustomTextSpan
              fontSize="13px"
              fontWeight="600"
              color="var(--text-2)">{`${
              list?.length ?? 0
            } Functions`}</CustomTextSpan>
          ) : isSuperuser ? (
            <ImpersonatorBox
              onWalletSelected={setSimulatedWallet}
              onRemoveUser={() => setSimulatedWallet(null)}
            />
          ) : null}
        </FlexGrow>
        <div></div>
        {isProxyAvailable && (
          <ToggleSwitch
            enabled={isReadAsProxy}
            setIsEnabled={setIsReadAsProxy}
            rightText={isRead ? "Read as Proxy" : "Write as Proxy"}
          />
        )}
      </CustomRow>
      {list?.map((func, index) => (
        <PPLxAccordion
          key={`${isRead ? "read" : "write"}-contract-${func.name}-${index}`}
          title={`${index + 1}. ${func.name} `}>
          <div className={classes.arguments}>
            <FunctionType
              isRead={isRead}
              func={func}
              requestParams={requestParams}
              simulatedWallet={simulatedWallet}
            />
          </div>
        </PPLxAccordion>
      ))}
    </div>
  );
};

export default ReadWriteContract;

const FunctionType = ({ func, requestParams, isRead, simulatedWallet }) => {
  if (!func) return null;
  // if function doesn't have any inputs, we just display the type and name
  if (func?.inputs?.length === 0 && isRead) {
    // if the default_value exists, display it otherwise display name <type>
    if (func.outputs.some((output) => "default_value" in output)) {
      const outputs = func.outputs
        ?.filter((output) => "default_value" in output)
        ?.map((output) => (
          <div className={classes.responseContent}>
            <FeedDescription
              templateString={output?.default_value?.toString()}
              profiles={output?.profiles}
            />
          </div>
        ));
      return (
        <>
          <CustomRow alignItems="center" gap="4px">
            <ArrowBendDownRight size={16} />
            {`${func.outputs.map((output) => output.type)?.join(",")} `}
          </CustomRow>
          <ResponseWrapper
            {...{
              isSuccess: true,
              response: outputs,
              isRead,
              inputs: func.inputs,
              funcName: func.name,
            }}
          />
          <PaddingComponent height="8px" />
        </>
      );
    } else {
      return `${func.name} <${func.outputs
        .map((output) => output.type)
        .join(", ")}>`;
    }
  }

  return (
    <ReadContractFormItem
      inputs={func?.inputs}
      funcName={func.name}
      requestParams={requestParams}
      outputs={func?.outputs}
      isRead={isRead}
      simulatedWallet={simulatedWallet}
    />
  );
};
