import { useFetchTokenSwapTableData } from "api/token_page.api";
import TradingViewGraph from "components/V2/TradingViewGraph/TradingViewGraph";
import { ThemeContext } from "contextStates/Theme.context";
import useQueryParams from "customHooks/useQueryParams";
import React, { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { MobileOSTypes } from "utils/constants";
import { formatUnixTimestamp, getMobileOperatingSystem } from "utils/misc";
import { useTradingViewMarkers } from "utils/trading_view_utils";
const MINIMUM_ROWS = 20;
const MAX_REQUESTS = 10;
const ZxTradingViewPage = () => {
  const networkCallRef = useRef(0);
  const { chain, address } = useParams();
  const params = useQueryParams();
  const theme = params.get("theme") ?? "light";
  const chartHeight = params.get("chartHeight") ?? null;
  const isFullScreen = params.get("fullScreen") === "true" || !chartHeight;
  const showPriceHeader = params.get("showPriceHeader") === "true";
  const pageParams = params.get("pageParams") ?? "";
  const pageParamsArray = pageParams.split("-");
  const pageType = ["token_page"].includes(pageParamsArray[0])
    ? pageParamsArray[0]
    : null;
  const filterAddress =
    pageType === "token_page" ? pageParamsArray[1] ?? null : null;
  const history = useHistory();
  const { setStoredTheme } = useContext(ThemeContext);
  const {
    data: transfersData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchTokenSwapTableData({
    queryKey: "tokenRecentTransfers",
    chainId: chain,
    contractAddress: address,
    filter: filterAddress ? { address: filterAddress } : null,
    type: "recent",
    enabled: !!filterAddress,
  });
  const transfers = (transfersData?.pages ?? [])?.reduce((acc, page) => {
    acc.push(...(page.transfer_data ?? page.recent_transfer_data ?? []));
    return acc;
  }, []);
  const { getChartMarkers, updateMarkers } = useTradingViewMarkers();
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: window.location.href.includes("/charts/"),
      });
    }
    setStoredTheme(["dark", "light"].includes(theme) ? theme : "light");
    if (!history) return;
    const os = getMobileOperatingSystem();
    if (os !== MobileOSTypes.ANDROID && os !== MobileOSTypes.IOS) {
      // history.replace("/home");
      return;
    }
    if (!chain || !address) {
      // history.replace("/home");
      return;
    }
  }, [history, address, chain, theme, setStoredTheme]);
  useEffect(() => {
    const val = [];
    transfers.forEach((t) => {
      const type = t.type.toLowerCase();
      if (
        type === "buy" ||
        type === "sell" ||
        type === "received" ||
        type === "sent"
      ) {
        const isBuy = type === "buy" || type === "received";
        const price = t.price_bought_at?.value;
        val.push({
          timestamp: t.time_stamp,
          action: type,
          color: isBuy ? "green" : "red",
          type: type,
          price: price,
          amount: t.amount.value,
          text: [`$${price} \n`, formatUnixTimestamp(t.time_stamp)],
          label: isBuy ? "B" : "S",
          id: `${t.time_stamp}_${type}`,
        });
      }
    });
    updateMarkers(val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfers]);
  // fetch minimum rows
  useEffect(() => {
    const rows = transfers ? transfers?.length : null;
    if (
      rows &&
      rows < MINIMUM_ROWS &&
      networkCallRef.current < MAX_REQUESTS &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
      networkCallRef.current = networkCallRef.current + 1;
    }
  }, [transfers, fetchNextPage, hasNextPage, isFetchingNextPage]);
  return (
    <div>
      <TradingViewGraph
        address={address}
        chain={chain}
        overrideTheme={theme}
        showPriceHeader={showPriceHeader}
        chartHeight={chartHeight}
        isFullScreen={isFullScreen}
        getMarkers={getChartMarkers}
        isNativeToken={pageType === "native_token"}
      />
    </div>
  );
};

export default ZxTradingViewPage;
