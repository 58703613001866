import Image, { IMAGE_TYPES } from "components/UI/Image";
import classes from "./Joined0xPPL.module.css";
import { useContext } from "react";
import { AuthContext } from "contextStates/AuthContext";
import {
  CustomRow,
  PaddingComponent,
  RoundedChip,
  CursorDiv,
} from "components/UI/Components/Components";
import { Plus } from "@phosphor-icons/react";
import CommentButton from "components/ActivityEngagements/CommentButton";

const JoinedCreateComment = ({ groupedActivity }) => {
  const { identityDetails } = useContext(AuthContext);
  let suggestions = groupedActivity?.welcome_message_suggestions;
  const showSuggestions = groupedActivity?.show_suggestions;
  if (!showSuggestions) {
    return null;
  }
  if (suggestions.length > 2) {
    suggestions = suggestions.slice(0, 2);
  }
  return (
    <CustomRow
      alignItems="center"
      gap="6px"
      className={classes.newCommentWrapper}>
      <PaddingComponent paddingRight="2px" height="20px">
        <Image
          className={classes.commenterProfilePic}
          imageType={IMAGE_TYPES.AVATAR}
          name={identityDetails?.current?.identity?.display_name}
          src={identityDetails?.current?.identity?.display_picture}
          alt={identityDetails?.current?.identity?.display_name}
        />
      </PaddingComponent>
      <CustomRow gap="6px" className={classes.commentsWrapper}>
        {suggestions?.map((comment, index) => {
          let shorterComment = comment;
          if (shorterComment.length > 32) {
            shorterComment = comment.slice(0, 32) + "...";
          }
          return (
            <CommentButton
              key={index}
              prefillCommentContent={comment}
              contentId={groupedActivity.id}
              contentType={groupedActivity.type}>
              <RoundedChip>{shorterComment}</RoundedChip>
            </CommentButton>
          );
        })}
        <CommentButton
          contentId={groupedActivity.id}
          contentType={groupedActivity.type}>
          <CursorDiv>
            <div className={classes.addCommentButton}>
              <Plus size={12} color="var(--text-3)" />
            </div>
          </CursorDiv>
        </CommentButton>
      </CustomRow>
    </CustomRow>
  );
};

export default JoinedCreateComment;
