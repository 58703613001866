import useTopHolders from "components/V2/Token/TopUsers/useTopHolders";
import React from "react";
import ZxTable from "zxComponents/common/ZxTable/ZxTable";
import { getZxTokenTopHolderColumns } from "./ZxTokenTopHolders.utils";
const ZxTokenTopHolders = ({
  chain,
  address,
  showFilter = false,
  filter = null,
  updateFilter = () => {},
}) => {
  const { data, isLoading, isError } = useTopHolders(address, chain);
  return (
    <div>
      <ZxTable
        data={data}
        columns={getZxTokenTopHolderColumns({
          showFilter,
          filter,
          updateFilter,
        })}
        infiniteScroll={false}
        isLoading={isLoading}
        isError={isError}
        isTokenPageTable
      />
    </div>
  );
};

export default ZxTokenTopHolders;
