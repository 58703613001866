import { Autocomplete } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import IconWithChain from "../../IconWithChain";
import classes from "../../Query.module.css";
import { getSearchResults } from "api/search.api";
import InputColumnTextField from "./InputTextField.styled";
import Popper from "@mui/material/Popper";

const ColumnSearchField = ({
  source,
  onAddValue,
  placeholder,
  minWidth = 120,
  maxWidth = 300,
  customWidth,
  setCustomLoadingState,
  height = "30px",
  isShowingLoading = true,
}) => {
  const [currentHighlighted, setCurrentHighlighted] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchRef = React.useRef(null);
  const [isFocused, setIsFocused] = React.useState(false);

  useEffect(() => {
    if (setCustomLoadingState) {
      setCustomLoadingState(isLoading);
    }
  }, [isLoading, setCustomLoadingState]);

  const handleKeyDown = (event) => {
    if (event.key === "Tab" && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      if (event.target.value.length > 0) {
        const newValue = currentHighlighted;
        setOptions(newValue ? [newValue, ...options] : options);
        onAddValue(source, newValue);
      }
    }
  };

  let searchHelperKey;
  let endpoint;
  if (source === "profile") {
    searchHelperKey = "source:profile";
    endpoint = "api/search/search";
  }
  if (source === "token") {
    searchHelperKey = "source:token";
    endpoint = "api/search/search";
  }
  if (source === "protocol") {
    searchHelperKey = "";
    endpoint = "api/search/search_protocols";
  }
  if (source === "impersonator") {
    searchHelperKey = "source:identity source:lens source:farcaster source:ens";
    endpoint = "api/search/search";
  }

  useEffect(() => {
    let timeOutId = null;
    const controller = new AbortController();
    setOptions([]);
    if (inputValue.trim().length > 0) {
      timeOutId = setTimeout(() => {
        // store reference to the timeout
        setIsLoading(true);
        getSearchResults({
          queryKey: ["", `${inputValue} ${searchHelperKey}`],
          endpoint,
          signal: controller.signal,
        })
          .then((data) => {
            setOptions(data);
            setIsLoading(false);
          })
          .catch((_) => {});
      }, 200);
    }
    return () => {
      clearTimeout(timeOutId);
      if (!controller.signal.aborted) {
        controller.abort();
      }
      setIsLoading(false);
    };
  }, [inputValue, searchHelperKey, endpoint, isFocused]);

  const width = customWidth ?? (isFocused ? 200 : inputValue?.length * 7 + 40);

  return (
    <div
      ref={searchRef}
      onClick={(event) => {
        event.stopPropagation();
        searchRef.current.querySelector("input").focus();
      }}
      className={classes.reusable_search}
      onFocus={(event) => {
        event.stopPropagation();
        setIsFocused(true);
      }}
      onKeyDown={(event) => {
        event.stopPropagation();
      }}
      onBlur={(event) => {
        event.stopPropagation();
        setIsFocused(false);
      }}>
      <Autocomplete
        onHighlightChange={(event, newHighlighted) => {
          setCurrentHighlighted(newHighlighted);
        }}
        PopperComponent={({ children, ...props }) => (
          <Popper {...props} style={{ zIndex: 2000 }}>
            {children}
          </Popper>
        )}
        autoHighlight
        disableClearable
        sx={{
          minWidth: minWidth,
          maxWidth: maxWidth,
          backgroundColor: "inherit",
          width: width,
          height: 20,
          color: "var(--text-1)",
        }}
        id={`${source}`}
        getOptionLabel={(option) => ""}
        isOptionEqualToValue={(option, value) => {
          return (
            (option.data?.display_name &&
              option.data?.display_name === value.data?.display_name) ||
            (option.data?.title && option.data?.title === value.data?.title)
          );
        }}
        value={""}
        freeSolo={!isShowingLoading}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions={false}
        noOptionsText={
          <span style={{ color: "var(--text-2)" }}>Searching</span>
        }
        onChange={(event, newValue) => {
          // setValue(newValue);
          setOptions(newValue ? [newValue, ...options] : options);
          onAddValue(source, newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        openOnFocus
        renderInput={(params) => {
          params.InputProps.endAdornment = null;
          params.InputProps.style = {
            innerHeight: "20px",
            padding: "0px",
            fontSize: "15px",
            fontFamily: "ui-monospace",
            color: "var(--text-1)",
          };

          params.InputProps.disableUnderline = !isFocused;
          params.InputProps.onKeyDown = handleKeyDown;
          return (
            <div
              style={{
                padding: "0px",
                height: height,
                display: "flex",
                alignItems: "center",
                color: "var(--text-1)",
              }}>
              <InputColumnTextField
                {...params}
                variant="outlined"
                type="text"
                placeholder={placeholder}
                className="textField"
              />
            </div>
          );
        }}
        renderOption={(props, option) => {
          return (
            <li
              style={{
                width: `${width}px`,
                color: "var(--text-1)",
              }}
              {...props}
              key={option.data?.link}>
              <IconWithChain
                src={option.data?.display_picture}
                alt={option.data?.display_name || option.data?.display_name}
                chain={option.data?.address_chain}
              />
              {source === "token"
                ? `${option.data?.token_details?.symbol}`
                : option.data?.title || option.data?.display_name}
            </li>
          );
        }}
      />
    </div>
  );
};

export default ColumnSearchField;
