import React, { useContext } from "react";
import styles from "./ProfleEditPage.module.scss";
import {
  CustomRow,
  CustomSkeleton,
  Divider,
  PaddingComponent,
  SpinnerComponent,
} from "components/UI/Components/Components";
import { CaretLeft } from "../../../../node_modules/@phosphor-icons/react/dist/index";
import ZxText from "zxComponents/common/ZxText/ZxText";
import GenericButton from "components/V2/GenericButton/GenericButton";
import { TSTYLES } from "zxStyles/constants";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import ZxInputTextButton from "zxComponents/common/ZxInputTextButton";
import { GlobalContext } from "contextStates/Global";
import { signedApiOtherRequest } from "api/api";
import { trackEvent } from "utils/event_tracking";
import ConditionalLink from "shared/ConditionalLink";
export const ProfileEditPageHeader = ({
  onBack,
  onSave,
  isLoading,
  disabled,
  identifier,
}) => {
  return (
    <CustomRow className={styles.header} justifyContent="space-between">
      <CustomRow alignItems="center" gap="16px">
        <ConditionalLink to={`/${identifier}`}>
          <CaretLeft size={16} color={"var(--text-1)"} />
        </ConditionalLink>
        <ZxText style={TSTYLES.title1} fontSize="16px">
          Edit Profile
        </ZxText>
      </CustomRow>
      {!isLoading ? (
        <GenericButton
          background="var(--primary-color)"
          color="var(--white)"
          padding="8px 12px"
          customClass={styles.saveButton}
          onClick={onSave}
          disabled={disabled}
          borderRadius="4px">
          <ZxText color="white" style={TSTYLES.title2} fontSize="12px">
            Save changes
          </ZxText>
        </GenericButton>
      ) : (
        <SpinnerComponent size={30} color="var(--primary-color)" />
      )}
    </CustomRow>
  );
};

export const ProfileEditPageLoader = () => {
  return (
    <ZxFlex dir="column" gap="16px" width="100%" align="flex-start">
      <ZxFlex gap="16px">
        <CustomSkeleton
          width="64px"
          height="64px"
          borderRadius="16px"
          variant="rectangular"
        />
        <ZxFlex dir="column" gap="8px" align="flex-start">
          <CustomSkeleton
            width="250px"
            height="24px"
            variant="rectangular"
            borderRadius="4px"
          />
          <CustomSkeleton
            width="100px"
            height="24px"
            variant="rectangular"
            borderRadius="4px"
          />
        </ZxFlex>
      </ZxFlex>
      <Divider margin="16px 0" />
      <ZxFlex dir="column" gap="16px" align="flex-start" width="100%">
        <ZxText style={TSTYLES.secondaryLabel}>PROFILE</ZxText>
        <ZxFlex dir="column" gap="12px" align="flex-start" width="100%">
          <ZxFlex justify="space-between" width="100%">
            <ZxText style={TSTYLES.body1}>Name</ZxText>
            <CustomSkeleton
              width="300px"
              height="32px"
              variant="rectangular"
              borderRadius="4px"
            />
          </ZxFlex>
          {/* BIO */}
          <ZxFlex justify="space-between" width="100%" align="flex-start">
            <ZxText style={TSTYLES.body1}>Bio</ZxText>
            <CustomSkeleton
              width="300px"
              height="80px"
              variant="rectangular"
              borderRadius="4px"
            />
          </ZxFlex>
        </ZxFlex>
        <Divider margin="12px 0" />
        <ZxText style={TSTYLES.secondaryLabel}>SOCIALS</ZxText>
        <ZxFlex dir="column" gap="12px" align="flex-start" width="100%">
          <ZxFlex justify="space-between" width="100%">
            <ZxText style={TSTYLES.body1}>Twitter</ZxText>
            <CustomSkeleton
              width="300px"
              height="32px"
              variant="rectangular"
              borderRadius="4px"
            />
          </ZxFlex>
          {/* LENS */}
          <ZxFlex justify="space-between" width="100%">
            <ZxText style={TSTYLES.body1}>Lens</ZxText>
            <CustomSkeleton
              width="300px"
              height="32px"
              variant="rectangular"
              borderRadius="4px"
            />
          </ZxFlex>
          <ZxFlex justify="space-between" width="100%">
            <ZxText style={TSTYLES.body1}>Farcaster</ZxText>
            <CustomSkeleton
              width="300px"
              height="32px"
              variant="rectangular"
              borderRadius="4px"
            />
          </ZxFlex>
        </ZxFlex>
      </ZxFlex>
    </ZxFlex>
  );
};

export const ProfileSocialActions = ({
  isVerified,
  onEdit,
  onVerify,
  isLoading,
}) => {
  return (
    <ZxFlex justify={"flex-end"} width={"100%"}>
      {isVerified ? (
        <VerifiedBadge isRenderOnly color="var(--success)" size={16} />
      ) : (
        <PaddingComponent width="8px" />
      )}

      {isLoading ? (
        <SpinnerComponent color="var(--primary-color)" size={16} />
      ) : !isVerified ? (
        <ZxInputTextButton>Verify</ZxInputTextButton>
      ) : (
        <ZxInputTextButton>Edit</ZxInputTextButton>
      )}
    </ZxFlex>
  );
};

export const ProfileEditTwitterConnect = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const handleRedirect = async () => {
    try {
      trackEvent("profile_edit_twitter_connect_clicked");
      setIsLoading(true);
      const res = await signedApiOtherRequest({
        method: "get",
        path: "twitter/request_login",
      });
      setIsLoading(false);
      const { redirect_url: link } = res.data;
      trackEvent("profile_edit_twitter_connect_redirected");
      window.location.href = link;
      setIsLoading(false);
    } catch (err) {
      trackEvent("profile_edit_twitter_connect_failed");
      handleErrorSnackbar(err, "Could not connect to twitter");
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <SpinnerComponent color="var(--primary-color)" size={16} />;
  }
  return (
    <div className={styles.twitterConnect} onClick={handleRedirect}>
      {children}
    </div>
  );
};

export const ProfileDPUploadButton = ({ form }) => {
  const fileRef = React.useRef(null);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      handleErrorSnackbar(null, "No file selected");
      return;
    }

    // Validate file type
    if (!file.type.startsWith("image/")) {
      handleErrorSnackbar(null, "File type should be an image.");
      return;
    }

    // Validate file size (optional, e.g., max 5MB)
    if (file.size > 10 * 1024 * 1024) {
      handleErrorSnackbar(null, "File size should be less than 10MB.");
      return;
    }
    const { setValue } = form;
    setValue("dp_file", file);
  };
  return (
    <>
      <GenericButton
        color="var(--text-2)"
        onClick={(e) => {
          fileRef.current.click();
        }}
        background="var(--elevation-1)"
        padding="8px"
        borderRadius="8px">
        Edit photo
      </GenericButton>
      <input
        type="file"
        accept="image/*"
        ref={fileRef}
        onChange={handleFileChange}
        style={{ display: "none" }} // Hide the input
      />
    </>
  );
};
