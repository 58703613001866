import classes from "components/V2/Profile/ProfileDetails/Filters/FilterRowTile.module.css";
import formClasses from "components/UI/PopUpForm/PopUpForm.module.css";
import { useCallback } from "react";
import { TableProfile } from "../SelectedEdge";
import { PaddingComponent } from "components/UI/Components/Components";

const ExplorerRowTile = ({
  profile,
  setAddressesSelected,
  addressesSelected,
}) => {
  const isActive = addressesSelected.includes(profile.address);
  const onToggle = useCallback(() => {
    if (isActive) {
      setAddressesSelected((prevAddressesSelected) =>
        prevAddressesSelected.filter((address) => address !== profile.address)
      );
      return;
    }
    setAddressesSelected((prevAddressesSelected) => [
      ...prevAddressesSelected,
      profile.address,
    ]);
  }, [isActive, profile, setAddressesSelected]);

  return (
    <CheckboxRenderer
      content={
        <>
          <PaddingComponent width="20px" />
          <TableProfile
            profile={profile}
            address={profile.address}
            disableHover
            abbreviateLength={20}
          />
        </>
      }
      isActive={isActive}
      onToggle={onToggle}
    />
  );
};

export const CheckboxRenderer = ({ content, isActive, onToggle, subtitle }) => {
  const activeClass = isActive ? classes.active : "";

  return (
    <div
      className={`${classes.box} ${classes.pointer} ${classes.noselect} ${activeClass}`}
      onClick={onToggle}>
      <div className={classes.spacingRowTile}>
        <div className={classes.rowTile}>
          <input type="checkbox" checked={isActive} readOnly></input>
          {content}
        </div>
        {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      </div>
      <div className={formClasses.divider}></div>
    </div>
  );
};

export default ExplorerRowTile;
