import { getCssVariable } from "utils/misc";

export const getMarkers = ({
  recentTokenTransfers,
  identifier,
  maxMarkers = 100,
  identityId = null,
}) => {
  let markers = [];
  let profiles = {};
  const combined = recentTokenTransfers?.pages?.flatMap((page) => {
    const transfers = page?.transfers;
    profiles = { ...profiles, ...page?.profiles_by_addr };
    return transfers?.reduce((combinedTransfers, c) => {
      profiles = { ...profiles, ...page?.profiles };
      combinedTransfers.push(structuredClone(c));
      return combinedTransfers;
    }, []);
  });
  combined?.forEach((item) => {
    if (markers.length > maxMarkers) return; // limit markers
    if (item?.token_id) return; // remove if nft
    if (item?.value_usd < 10e-2 || !item?.usd_value?.display_value) return;
    if (item?.txn_type === "buy") {
      markers.push(generateMaker(item, "buy"));
    } else if (item?.txn_type === "sell") {
      console.log("in else if");
      markers.push(generateMaker(item, "sell"));
    }
  });
  return markers;
};

export const generateMaker = (item, type) => {
  const marker = {
    type,
    action: type,
    timestamp: item.block_timestamp,
    value: item.usd_value,
    color: type === "buy" ? "green" : "red",
    id: `${item.block_timestamp}_${type}`,
  };
  return marker;
};

export const getConsolidatedMarkers = (data, markers) => {
  const candleSize = data[1]?.time - data[0]?.time;
  const consolidatedMarkers = [];

  data.forEach((d, idx) => {
    let [buyMarkers, sellMarkers] = [[], []];
    const nextCandle = data[idx + 1];
    const nextTime =
      idx === data.length - 1
        ? d.time + candleSize
        : nextCandle?.time ?? d.time + candleSize;
    const markersInCandle = markers.filter(
      (m) => m.time >= d.time && m.time < nextTime
    );

    markersInCandle.forEach((m) => {
      if (m.color === getCssVariable("--success")) {
        buyMarkers.push(m);
      } else {
        sellMarkers.push(m);
      }
    });

    if (buyMarkers.length > 0) {
      consolidatedMarkers.push(
        generateMaker(buyMarkers[0].time, `${buyMarkers.length}`, "buy")
      );
    }
    if (sellMarkers.length > 0) {
      consolidatedMarkers.push(
        generateMaker(sellMarkers[0].time, `${sellMarkers.length}`, "sell")
      );
    }
  });

  return consolidatedMarkers;
};
