import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";

const TopBar = ({ isEnabled = true }) => {
  const { active_tab } = useParams();
  const currentStep = active_tab || "activities";
  const tabsList = [
    {
      title: "Overview",
      link: "overview",
    },
    {
      title: "Activities",
      link: "activities",
    },
  ];
  //TODO add when needed
  // if (metadata?.all_protocols)
  //   tabsList.push({
  //     title: "Protocols",
  //     link: "protocols",
  //   });
  return (
    <CustomLinkTabBar
      isEnabled={isEnabled}
      currentStep={currentStep}
      tabsList={tabsList}
      zIndex={TABBAR_ZINDEX.HIGH}
    />
  );
};

export default TopBar;
