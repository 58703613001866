import classes from "./TokenCard.module.css";
import ConditionalLink from "shared/ConditionalLink";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import Image from "../Image";
import { useLocation } from "react-router-dom";
const TokenCard = ({ title, value, marketCap, percentChange, link, logo }) => {
  const upDirection =
    (percentChange?.direction ?? 1) * (percentChange?.value ?? 0) > 0;

  const location = useLocation();
  // if current path is the same as link prop, remove cursor pointer
  const conditionalLink = !location.pathname?.includes(link) ? link : null;
  return (
    <ConditionalLink to={conditionalLink} sameTab>
      <div className={`${classes.net_worth_card}`}>
        <div className={classes.top}>
          <div className={classes.title}>{title}</div>
          <div className={classes.value_container}>
            <span className={classes.value_number}>{value}</span>
            {percentChange && (
              <div className={classes.percent_container}>
                {upDirection ? (
                  <CaretUp size={12} weight="fill" color="var(--success)" />
                ) : (
                  <CaretDown size={12} weight="fill" color="var(--error)" />
                )}

                <div
                  className={`${classes.value_percent} ${
                    upDirection ? classes.up : classes.down
                  }`}>
                  {percentChange?.display_value}
                </div>
              </div>
            )}
            <div className={classes.value_change}></div>
          </div>
        </div>
        <div className={classes.middle}></div>
        <div className={classes.bottom}>Market Cap {marketCap}</div>
        {logo && <Image alt={title} src={logo} className={classes.logo} />}
      </div>
    </ConditionalLink>
  );
};

export default TokenCard;
