import React, { useCallback, useContext, useState } from "react";
import classes from "./activityExtraInfo.module.css";
import PostSettings from "./PostSettings";
import { AuthContext } from "contextStates/AuthContext";

import {
  CursorDiv,
  CustomColumn,
  CustomRow,
  CustomText,
  CustomTextSpan,
  WhaleIcon,
} from "components/UI/Components/Components";
import ChainIcon from "components/UI/ChainIcon";
import { TrendUp, Star, ChartLine, PuzzlePiece } from "@phosphor-icons/react";
import twitterIcon from "assets/svg/twitter.svg";
import farcasterIcon from "assets/svg/farcaster.svg";
import lensIcon from "assets/svg/lens.svg";
import ConditionalLink from "shared/ConditionalLink";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import { ClickAwayListener } from "../../../node_modules/@mui/material/index";
import { DotsThreeVertical } from "../../../node_modules/@phosphor-icons/react/dist/index";

const ActivityExtraInfo = ({
  chains,
  chainProfiles,
  type,
  postId,
  creatorIdentity,
  showSettings = true,
  queryKeys,
  isGroupedActivityDetails,
  isHoveringForGptEdit,
  setIsHoveringForGptEdit,
  category,
  post,
  groupedActivity,
}) => {
  const { identityDetails, isSuperuser } = useContext(AuthContext);
  const currentIdentityId = identityDetails?.current?.identity?.id;
  return (
    <div
      className={classes.activityExtraInfo}
      onClick={(e) => e.stopPropagation()}>
      {type === activityCategory.NEWS && !isGroupedActivityDetails && (
        <ActivityTypeTag
          type={activityCategory.NEWS}
          text={activityCategory.NEWS}
          textColor="var(--accent-1)"
          icon={<TrendUp size={14} weight="bold" color="var(--accent-1)" />}
        />
      )}
      {category === activityCategory.TRENDING && isGroupedActivityDetails && (
        <ActivityTypeTag
          type={activityCategory.TRENDING}
          text={activityCategory.TRENDING}
          textColor="var(--primary-color)"
          icon={
            <TrendUp size={14} weight="bold" color="var(--primary-color)" />
          }
        />
      )}
      {category === activityCategory.CELEBRITY && (
        <ActivityTypeTag
          type={activityCategory.CELEBRITY}
          text={activityCategory.CELEBRITY}
          textColor="var(--primary-color)"
          icon={<Star size={14} color="var(--primary-color)" weight="fill" />}
        />
      )}
      {type === activityCategory.ZORA_ACTIVITY && (
        <ActivityTypeTag
          type={activityCategory.ZORA_ACTIVITY}
          text="Zora activity"
          textColor="var(--accent-1)"
          icon={<PuzzlePiece size={14} color="var(--accent-1)" weight="fill" />}
        />
      )}
      {category === activityCategory.WHALEALERT && (
        <ActivityTypeTag
          type={activityCategory.WHALEALERT}
          text="Whale Alert"
          textColor="var(--accent-1)"
          icon={<WhaleIcon />}
        />
      )}
      {category === activityCategory.TRADING_INSIGHTS && (
        <ActivityTypeTag
          type={activityCategory.TRADING_INSIGHTS}
          text="Trading Insight"
          textColor="var(--primary-color)"
          icon={
            <ChartLine size={14} color="var(--primary-color)" weight="fill" />
          }
        />
      )}
      {post?.post?.cross_post_urls && (
        <CrossPostUrls crossPostUrls={post?.post?.cross_post_urls} />
      )}
      {chains && (
        <div className={classes.chainIconsWrapper}>
          {chains?.map((chainId) => (
            <ChainIcon
              key={`chain-id-${chainId}`}
              chainId={chainId}
              chainProfiles={chainProfiles}
              className={classes.chainIcon}
            />
          ))}
        </div>
      )}

      {((showSettings && type === "post") || isSuperuser) && (
        <PostSettings
          postId={postId}
          queryKeys={queryKeys}
          groupedActivity={groupedActivity}
          isLoggedInUserPost={creatorIdentity === currentIdentityId}
        />
      )}
      {isSuperuser && isGroupedActivityDetails && (
        <ActivityExtraInfoPopUp
          isGroupedActivityDetails={isGroupedActivityDetails}
          isHoveringForGptEdit={isHoveringForGptEdit}
          setIsHoveringForGptEdit={setIsHoveringForGptEdit}
        />
      )}
    </div>
  );
};

const CrossPostUrls = ({ crossPostUrls = [] }) => {
  return (
    <CustomRow gap="4px">
      {crossPostUrls["twitter"] && (
        <ConditionalLink
          sameTab={false}
          to={{ pathname: crossPostUrls["twitter"] }}>
          <img
            src={twitterIcon}
            className={classes.crossPostImage}
            alt="twitter"
          />
        </ConditionalLink>
      )}
      {crossPostUrls["farcaster"] && (
        <ConditionalLink
          sameTab={false}
          to={{ pathname: crossPostUrls["farcaster"] }}>
          <img
            src={farcasterIcon}
            className={classes.crossPostImage}
            alt="farcaster"
          />
        </ConditionalLink>
      )}
      {crossPostUrls["lens"] && (
        <ConditionalLink
          sameTab={false}
          to={{ pathname: crossPostUrls["lens"] }}>
          <img src={lensIcon} className={classes.crossPostImage} alt="lens" />
        </ConditionalLink>
      )}
    </CustomRow>
  );
};

const ActivityExtraInfoPopUp = ({
  isGroupedActivityDetails,
  isHoveringForGptEdit,
  setIsHoveringForGptEdit,
}) => {
  const [showPopUpCard, setShowPopUpCard] = useState(false);
  const { isSuperuser } = useContext(AuthContext);

  const onThreeDotClick = useCallback(() => {
    if (isSuperuser && isGroupedActivityDetails) {
      setShowPopUpCard(true);
    }
  }, [isSuperuser, isGroupedActivityDetails]);

  const showHovering = useCallback(() => {
    if (isSuperuser && isGroupedActivityDetails) {
      setIsHoveringForGptEdit(!isHoveringForGptEdit);
      setShowPopUpCard(false);
    }
  }, [
    isSuperuser,
    isGroupedActivityDetails,
    setIsHoveringForGptEdit,
    isHoveringForGptEdit,
  ]);

  const button = (
    <CursorDiv onClick={onThreeDotClick}>
      <button className={classes.settingsIconWrapper}>
        <DotsThreeVertical size={20} color="var(--text-2)" />
      </button>
    </CursorDiv>
  );

  if (showPopUpCard) {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setShowPopUpCard(false);
        }}>
        <div>
          <PPLxHoverCard
            open={showPopUpCard}
            content={
              <div onClick={showHovering} className={classes.popUpContainer}>
                <CustomColumn>
                  <CustomTextSpan>
                    {isHoveringForGptEdit ? "Unedit" : "Edit"}
                  </CustomTextSpan>
                </CustomColumn>
              </div>
            }
            placement="bottom">
            {button}
          </PPLxHoverCard>
        </div>
      </ClickAwayListener>
    );
  }
  return button;
};

export const ActivityTypeTag = ({ icon, type, textColor, text }) => {
  return (
    <CustomRow gap="4px" alignItems="center">
      {icon}
      <CustomText
        color={textColor}
        fontWeight="600"
        fontSize="12px"
        text={text}
        textTransform="capitalize"
      />
    </CustomRow>
  );
};
export default ActivityExtraInfo;

export const activityCategory = {
  NEWS: "news",
  TRENDING: "trending",
  WHALEALERT: "whale_alert",
  CELEBRITY: "notable",
  TRADING_INSIGHTS: "trading_insight",
  ZORA_ACTIVITY: "zora_activity",
};
