import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useReportPostMutation } from "api/feed.api";
import Spinner from "shared/buttonloadingSpinner";
import { GlobalContext } from "contextStates/Global";
import classes from "./deletePostDialog.module.css";
import { useQueryClient } from "@tanstack/react-query";
import { fetchQueryWithPartialKeys } from "utils/misc";
import {
  useParams,
  useHistory,
} from "react-router-dom";
import CustomTextField from "components/FollowEditButton/CustomTextField";
import { PaddingComponent } from "components/UI/Components/Components";

const ReportPostDialog = ({
  postId,
  handleDialogToggle,
  openDialog,
  queryKeys,
}) => {
  const history = useHistory();
  const { id: idParam, type: typeParam } = useParams();
  const { handleErrorSnackbar, handleSuccessSnackbar } = useContext(GlobalContext);
  const [reason, setReason] = useState("");
  const queryClient = useQueryClient();
  const onSuccess = () => {
    handleDialogToggle();
    handleSuccessSnackbar("Post successfully reported")
    fetchQueryWithPartialKeys({ queryClient, queryKeys });

    // if trying to delete a post from post details page
    if (typeParam === "post" && postId === parseInt(idParam)) {
      const [, parentId, parentType] = queryKeys?.[0] || [];
      // if it has parent post/feed item, redirect to that
      if (!!parentId && !!parentType) {
        history.replace(`/${parentType}/${parentId}`);
      } else {
        // else redirect to /home
        queryClient.refetchQueries(["my_feed"]);
        history.replace("/home");
      }
    }
  };
  const onError = (err) => {
    handleErrorSnackbar(err, "Error reporting post");
  };

  const { mutate: reportPostMutation, isPending: isLoading } = useReportPostMutation({
    onSuccess,
    onError,
  });

  const handleDeleteConfirmation = () => {
    reportPostMutation({ postId, reason });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogToggle}
      classes={{ paper: classes.deleteDialog }}
      //
      sx={{
        ".MuiPaper-root": {
          background: "var(--base)",
        },
      }}>
      <DialogTitle>
        <div className={classes.dialogTitle}>Report post</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className={classes.dialogContent}>
            Why do you want to report this post?
          </div>
        </DialogContentText>
        <PaddingComponent height="16px" />
        <CustomTextField
          variant="outlined"
          name="reason"
          placeholder="Reason"
          size="large"
          fullWidth
          multiline
          rows={2}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>

      {isLoading ? (
        <DialogContent>
          <Spinner
            style={{
              stroke: "var(--primary-color)",
              background: "transparent",
              width: "20px",
              height: "20px",
              display: "block",
              margin: "auto",
            }}
          />
        </DialogContent>
      ) : (
        <DialogActions>
          <button
            onClick={handleDialogToggle}
            className={`${classes.dialogButton} ${classes.cancelButton}`}>
            Cancel
          </button>
          <button
            onClick={handleDeleteConfirmation}
            autoFocus
            className={`${classes.dialogButton} ${classes.confirmButton}`}>
            Report
          </button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ReportPostDialog;
