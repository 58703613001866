import React from "react";
import {
  CustomRow,
  PaddingComponent,
  Divider,
  CustomColumn,
  PriceChange,
} from "components/UI/Components/Components";
import { LabeledValue } from "components/Pages/AppModule/RightSideBar/components/LabeledValue";
import { HoldingsIcon } from "components/Pages/AppModule/RightSideBar/components/HoldingsIcon";
import ZxText from "zxComponents/common/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { IMAGE_TYPES } from "components/UI/Image";

const ProtocolHoldingsItem = ({ data }) => (
  <>
    <>
      <CustomRow
        width="100%"
        justifyContent="space-between"
        overflowX="visible"
        overflowY="visible">
        <CustomColumn overflowX="visible" overflowY="visible">
          <HoldingsIcon
            src={data?.display_image}
            chain={data?.str_chain_id}
            type={data?.type}
            str={data?.display_name}
            amount={data?.token_balance?.display_value}
            imageType={IMAGE_TYPES.CONTRACT}
          />
        </CustomColumn>
        <CustomColumn alignItems="end">
          <CustomRow justifyContent="start" alignItems="end" gap="4px">
            <ZxText>{data?.total_usd_value?.display_value || "-"} </ZxText>
            {data?.total_usd_value_delta && (
              <PriceChange
                value={data?.total_usd_value_delta.value}
                displayValue={data?.total_usd_value_delta.display_value}
                fontWeight="400"
              />
            )}
          </CustomRow>
          <ZxText style={TSTYLES.secondary1}>
            {data?.holding?.usd_value_invested?.display_value}
          </ZxText>
        </CustomColumn>
      </CustomRow>
    </>
    {Object.keys(data?.holding?.holdings_by_label || {}).map((label, index) => {
      const { holdings } = data?.holding?.holdings_by_label?.[label] || {};
      if (label === "Lending") {
        return (
          <>
            <PaddingComponent padding="4px 0">
              <Divider borderStyle="dashed" />
            </PaddingComponent>
            {holdings?.borrowed?.map((borrowed, borrowedIndex) => (
              <CustomRow
                gap="8px"
                width="100%"
                alignItems="center"
                key={index + "borrowed" + borrowedIndex}>
                <LabeledValue
                  label="Borrowed"
                  value={borrowed?.balance?.display_value}
                  flexGrow={1}
                  alignItems="flex-end"
                />
                <LabeledValue
                  label=""
                  value={borrowed?.usd_value?.display_value}
                  flexGrow={1}
                  flexShrink={0}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                />
              </CustomRow>
            ))}
            {holdings?.supplied?.map((supplied, suppliedIndex) => (
              <CustomRow
                gap="8px"
                width="100%"
                alignItems="center"
                key={index + "supplied" + suppliedIndex}>
                <LabeledValue
                  label="Supplied"
                  value={supplied?.balance?.display_value}
                  flexGrow={1}
                  alignItems="flex-end"
                />
                <LabeledValue
                  label=""
                  value={supplied?.usd_value?.display_value}
                  flexGrow={1}
                  flexShrink={0}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                />
              </CustomRow>
            ))}
          </>
        );
      } else if (label === "Staking") {
        return holdings?.map((staked, stakedIndex) => (
          <CustomRow
            gap="8px"
            width="100%"
            alignItems="center"
            key={index + "staked" + stakedIndex}>
            <LabeledValue
              label="Staked"
              value={staked?.balance?.map((b) => b?.display_value).join(", ")}
              flexGrow={1}
              alignItems="flex-end"
            />
            <LabeledValue
              label=""
              value={staked?.usd_value?.display_value}
              flexGrow={1}
              flexShrink={0}
              alignItems="flex-end"
              justifyContent="flex-end"
            />
          </CustomRow>
        ));
      } else if (label === "DEX") {
        return holdings?.map((pooled, pooledIndex) => (
          <CustomRow
            gap="8px"
            width="100%"
            alignItems="center"
            key={index + "pooled" + pooledIndex}>
            <LabeledValue
              label="LP"
              value={pooled?.balance?.map((b) => b?.display_value).join(", ")}
              flexGrow={1}
              alignItems="flex-end"
            />
            <LabeledValue
              label=""
              value={pooled?.usd_value?.display_value}
              flexGrow={1}
              flexShrink={0}
              alignItems="flex-end"
              justifyContent="flex-end"
            />
          </CustomRow>
        ));
      }
      return null;
    })}
  </>
);

export default ProtocolHoldingsItem;
