import {
  CustomColumn,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import noResult from "assets/svg/Search/no_result.svg";
import classes from "./searchResults.module.css";

const SearchNoResult = () => {
  return (
    <CustomColumn alignItems="center">
      <PaddingComponent height="42px" />
      <img
        src={noResult}
        alt="No result found"
        className={classes.noResultImage}
      />
      <PaddingComponent height="8px" />
      <CustomText
        text="No results found"
        fontSize="15px"
        color="var(--text-2)"
      />
      <PaddingComponent height="4px" />
      <CustomText
        text="Try searching with another keyword"
        fontSize="11px"
        color="var(--text-3)"
      />
      <PaddingComponent height="30px" />
    </CustomColumn>
  );
};

export default SearchNoResult;
