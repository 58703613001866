import React from "react";
import { useQuery } from "@tanstack/react-query";

import ContentRenderer from "components/FeedItemV2/ContentRenderer";
import { getGroupedActivity } from "api/feed.api";
import { QueryKeyConstants } from "utils/constants";
import GhostFeedItem from "../GhostFeedItem/GhostFeedItem";

const ParentPost = ({
  parent,
  isGroupedActivityDetails = false,
  noPadding = false,
}) => {
  const { data: feedItem, isLoading } = useQuery({
    queryFn: () =>
      getGroupedActivity({
        id: parent?.id,
        type: parent?.type,
      }),
    queryKey: [
      QueryKeyConstants.GROUPED_ACTIVITY,
      parent?.id?.toString(),
      parent?.type,
    ],
    enabled: !!parent?.id && !!parent?.type,
    retry: 2,
    staleTime: 1000 * 60 * 5,
  });

  if (!!parent && isLoading) {
    return <GhostFeedItem isGroupActivityShimmer />;
  }

  if (!feedItem) {
    return null;
  }
  return (
    <ContentRenderer
      groupedActivity={feedItem}
      nested
      hideEngagements
      showThreadLine
      isParent
      isGroupedActivityDetails={isGroupedActivityDetails}
      noPadding={noPadding}
    />
  );
};

export default ParentPost;
