import { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  useReactFlow,
  Background,
  Controls,
  useNodesState,
  useEdgesState,
} from "reactflow";
import "reactflow/dist/style.css";
import "./InviteGraph.css";

import dagre from "dagre";
import { Plus } from "@phosphor-icons/react";

const nodeWidth = 150;
const nodeHeight = 80;
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

export const toEdge = (edge, index) => {
  return {
    id: index,
    type: "smoothstep",
    source: edge.source + "",
    target: edge.destination + "",
    focusable: false,
  };
};

const toggleNode = (node, nodes, edges, newState) => {
  for (let edge of edges) {
    if (edge.source === node.id) {
      const targetNode = nodes.find((n) => n.id === edge.target);
      edge.hidden = newState;
      if (targetNode.type === "output") {
        targetNode.hidden = newState;
      } else if (targetNode.type === "default") {
        // eslint-disable-next-line no-unused-vars
        const { nodes: newNodes, edges: newEdges } = toggleNode(
          targetNode,
          nodes,
          edges,
          newState
        );
        const updatedNode = newNodes.find((n) => n.id === targetNode.id);
        updatedNode.hidden = newState;
      }
    }
  }
  return { nodes, edges };
};

const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const isInviteNode = node.id === "0";
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    if (isInviteNode) {
      node.position = {
        x: nodeWithPosition.x - 30,
        y: nodeWithPosition.y - 40,
      };
    } else {
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }
    return node;
  });

  return { nodes, edges };
};

const FlowchartRenderer = ({
  queryData,
  identifier,
  getNodeItem,
  plusIconProps = { onClick: () => {}, count: 0 },
}) => {
  const { setViewport, fitView } = useReactFlow();
  const [zoomedIn, setZoomedIn] = useState(false);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const zoomToCurrentUser = useCallback(() => {
    setZoomedIn(true);
    let userNode = nodes.find((node) => node.is_current_user);
    setViewport(
      {
        x: -userNode?.position.x + 300,
        y: -userNode?.position.y + 100,
        zoom: 1,
      },
      { duration: 800 }
    );
  }, [setViewport, nodes, setZoomedIn]);

  const onNodeClick = useCallback(
    (event, node) => {
      if (node.id === "0") {
        plusIconProps?.onClick?.();
      } else if (node.hideable) {
        const newState = node.is_expanded;
        let { nodes: newNodes, edges: newEdges } = toggleNode(
          node,
          nodes,
          edges,
          newState
        );
        const targetNodeIndex = nodes.findIndex((n) => n.id === node.id);
        const targetNode = newNodes[targetNodeIndex];
        targetNode.is_expanded = !targetNode.is_expanded;
        targetNode.data = {
          label: getNodeItem(
            queryData?.nodes[targetNodeIndex],
            targetNode.is_expanded
          ),
        };
        setEdges([...newEdges]);
        setNodes([...newNodes]);
      }
    },
    [queryData, edges, nodes, plusIconProps, setEdges, setNodes, getNodeItem]
  );

  useEffect(() => {
    if (!queryData) return;

    const toNode = (node, isIdentityNode) => {
      return {
        id: node.id + "",
        type: isIdentityNode
          ? "default"
          : node.child_nodes_count
          ? "default"
          : "output",
        style: { padding: 0 },
        hideable: node.child_nodes_count,
        selectable: false,
        is_expanded: node.is_expanded,
        is_current_user: isIdentityNode,
        data: {
          label: getNodeItem(node, node.is_expanded, isIdentityNode),
        },
      };
    };

    const defaultNodes =
      queryData?.nodes?.map((node) => {
        const isIdentityNode = parseInt(node.id) === parseInt(identifier);
        return toNode(node, isIdentityNode);
      }) || [];
    const defaultEdges = queryData?.edges?.map(toEdge) || [];

    const inviteCodeNode = {
      id: "0",
      type: "output",
      style: { width: 60, height: 60, padding: 0 },
      selectable: false,
      data: {
        label: (
          <div className="invite-graph_add">
            <Plus size={24} weight="bold" color="var(--primary-color)" />
            <div className="invite-graph_add-count">{plusIconProps?.count}</div>
          </div>
        ),
      },
    };

    const inviteCodeEdge = {
      source: identifier + "",
      target: "0",
      type: "smoothstep",
      focusable: false,
    };

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      defaultNodes.concat(plusIconProps?.count ? inviteCodeNode : []),
      defaultEdges.concat(plusIconProps?.count ? inviteCodeEdge : [])
    );

    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
    fitView();
    setZoomedIn(false);
  }, [queryData, setNodes, setEdges, identifier, plusIconProps, getNodeItem, fitView, setZoomedIn]);

  useEffect(() => {
    if (!queryData) return;
    if (zoomedIn) return;
    const timeout = setTimeout(
     zoomToCurrentUser,
      2000
    );
    return () => clearTimeout(timeout);
  }, [queryData, zoomedIn, zoomToCurrentUser]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      edgesUpdatable={false}
      nodesConnectable={false}
      onNodeClick={onNodeClick}
      minZoom={0.3}
      fitView>
      <Controls showInteractive={false} />
      <Background color="#aaa" gap={16} />
    </ReactFlow>
  );
};

export default FlowchartRenderer;
