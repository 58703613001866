import { CustomColumn } from "components/UI/Components/Components";
import { ThemeContext } from "contextStates/Theme.context";
import React, { useContext } from "react";
import { TSTYLES } from "zxStyles/constants";
import ZxText from "../ZxText/ZxText";
import Image, { IMAGE_TYPES } from "components/UI/Image";

const ZxEmptyState = ({
  state,
  text = "No activity",
  size = 100,
  padding = "16px",
}) => {
  const { theme } = useContext(ThemeContext);

  const src = state[theme] ?? "/empty_state.svg";
  return (
    <CustomColumn
      alignItems="center"
      justifyContent="center"
      gap="16px"
      width="100%"
      padding={padding}>
      <Image
        src={src}
        alt="Empty State"
        style={{ width: size, height: "auto" }}
        imageType={IMAGE_TYPES.NEW_FALLBACK}
      />
      <ZxText style={TSTYLES.secondary1}>{text}</ZxText>
    </CustomColumn>
  );
};

export default ZxEmptyState;
