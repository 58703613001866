import TransactionInternalCallsTile from "./TransactionInternalCallsTile";

const TransactionCallsTree = ({ txnData, internalTxn, index, callNo }) => {
  return (
    <div className="transaction-calls-tree">
      <TransactionInternalCallsTile
        action={internalTxn?.action}
        internalTxn={internalTxn}
        txnData={txnData}
      />
      {internalTxn.children.map((nestedInternalTxn, index) => {
        return (
          <TransactionCallsTree
            key={`internal-txn-${index}`}
            internalTxn={nestedInternalTxn}
            txnData={txnData}
            index={index}
          />
        );
      })}
    </div>
  );
};

export default TransactionCallsTree;
