/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useRef,
  useState,
  useContext,
  useEffect,
} from "react";
import { useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "contextStates/AuthContext";
import { useLocation } from "react-router-dom";
import { intersection } from "lodash";
import { QUERY_KEYS } from "utils/query_utils";
import { QueryKeyConstants } from "utils/constants";
import { logSentryEvent } from "utils/sentry";
import { getUniqueId } from "utils/event_tracking";

export const ProfileContext = createContext({});
const ProfileContextProvider = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const identifier = useRef(null);
  const profileData = useRef(null);
  const allWallets = useRef(null);
  const queryClient = useQueryClient();
  const selectedActiveChains = useRef([]);
  const selectedActiveTokens = useRef([]);
  const selectedActiveProtocols = useRef([]);
  const selectedGroupIds = useRef(
    new URLSearchParams(location.search)?.getAll("groups").map(parseInt)
  );
  const selectedWalletAddresses = useRef(null);
  const updatePortfolioTab = useRef(() => {});
  const updatePortfolioValue = useRef(false);
  const updateActivityTab = useRef(() => {});
  const updateActivityValue = useRef(false);
  const updateNetworthCard = useRef(() => {});
  const updateNetworthCardValue = useRef(false);
  const [trigger, setTrigger] = useState(getUniqueId());

  const holdingsTotalUsdValue = useRef({});

  const isAccessTabClicked = useRef(false);
  const setIsAccessTabClicked = (value) => (isAccessTabClicked.current = value);

  var profileQuery = null;
  var bundleQueryKey = null;
  var tokenHoldingsQueryKey = null;
  var protocolHoldingsQueryKey = null;
  var graphSummaryQueryKey = null;
  var nftHoldingsQueryKey = null;
  var networthQueryKey = null;
  const { identityDetails } = useContext(AuthContext);
  const [isLoggedInUserProfile, setIsLoggedInUserProfile] = useState(
    profileData.id === identityDetails?.current?.identity?.id
  );

  function setIdentifier(identifierVal) {
    identifier.current = identifierVal;
    profileQuery = ["profile", identifier.current];
    bundleQueryKey = ["bundle", identifier];
    tokenHoldingsQueryKey = QUERY_KEYS.TOKEN_HOLDINGS({
      identifier: identifier.current,
      forProtocolTreasury: false,
      slowPath: false,
    });
    protocolHoldingsQueryKey = ["protocol-holdings", identifier.current];
    graphSummaryQueryKey = ["activitiesSummary", identifier.current];
    nftHoldingsQueryKey = ["get_nft_holdings", identifier.current];
    networthQueryKey = ["networth", identifier.current];
  }

  function setProfileData(data) {
    if (profileData.current?.details?.id !== data?.details?.id) {
      holdingsTotalUsdValue.current = {};
    }
    profileData.current = data;
    setIsLoggedInUserProfile(
      data?.details?.id === identityDetails?.current?.identity?.id
    );
  }
  const bundle = queryClient.getQueryData([
    QueryKeyConstants.MY_WALLETS,
    identifier.current,
    true,
  ]);
  function setSelectedGroupIds(groupIds, newAllWallets) {
    if (Array.isArray(groupIds)) {
      if (groupIds.length > 0) {
        selectedGroupIds.current = groupIds;
        let newWalletAddresses = [];
        newAllWallets?.forEach((wallet) => {
          if (intersection(wallet.group_ids, groupIds).length > 0) {
            newWalletAddresses.push(wallet.address);
          }
        });
        selectedWalletAddresses.current = newWalletAddresses;
      } else {
        selectedGroupIds.current = [];
        selectedWalletAddresses.current = null;
        // allWallets?.map((wallet) => wallet.address) || [];
      }
    }
    allWallets.current = newAllWallets;
    updatePortfolioTab.current?.(!updatePortfolioValue.current);
    updateActivityTab.current?.(!updateActivityValue.current);
    setTrigger(getUniqueId());
  }

  function setSelectedChains(chains) {
    selectedActiveChains.current = [...chains];
    updatePortfolioTab.current?.(!updatePortfolioValue.current);
    updateActivityTab.current?.(!updateActivityValue.current);
  }

  function setSelectedTokens(tokens, protocols) {
    selectedActiveTokens.current = [...tokens];
    selectedActiveProtocols.current = [...protocols];
    updatePortfolioTab.current?.(!updatePortfolioValue.current);
    updateActivityTab.current?.(!updateActivityValue.current);
  }

  function setUpdatePortfolioTabRenderFunction(render, value) {
    updatePortfolioTab.current = render;
    updatePortfolioValue.current = value;
  }

  function setUpdateActivitiesTabRenderFunction(render, value) {
    updateActivityTab.current = render;
    updateActivityValue.current = value;
  }

  function setUpdateNetworthCardRenderFunction(render, value) {
    updateNetworthCard.current = render;
    updateNetworthCardValue.current = value;
  }

  function setHoldingsTotalUsdValue(value) {
    holdingsTotalUsdValue.current = {
      ...holdingsTotalUsdValue.current,
      ...value,
    };
    updateNetworthCard.current?.(!updateNetworthCardValue?.current);
  }

  const getSelectedActiveTokensWithoutNativeTokens = () => {
    return selectedActiveTokens.current.filter((token) =>
      // TODO fix after solana integration
      token?.contract_address?.startsWith("0x")
    );
  };
  const updateProfile = async () => {
    queryClient.invalidateQueries({
      queryKey: ["profile", identifier.current],
    });
    const bundleKey = [QueryKeyConstants.MY_WALLETS, identifier.current, true];
    queryClient.invalidateQueries({ queryKey: bundleKey });
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.TOKEN_HOLDINGS({
        identifier: identifier.current,
        forProtocolTreasury: false,
        slowPath: true,
      }),
    });
    queryClient.invalidateQueries({
      queryKey: ["protocol-holdings", identifier.current, false, true],
    });
    queryClient.invalidateQueries(graphSummaryQueryKey);
    queryClient.invalidateQueries({
      queryKey: ["get_profile_nft_holdings", []],
    });
    queryClient.invalidateQueries({
      queryKey: ["networth", identifier.current],
    });
    queryClient.invalidateQueries({
      queryKey: ["networth_graph", identifier.current],
    });
  };

  const invalidateProfileDetails = async () => {
    queryClient.invalidateQueries({
      queryKey: ["profile", identifier.current],
    });
  };
  const removeWalletFromProfileCache = (address) => {
    try {
      const queryKey = [QueryKeyConstants.MY_WALLETS, identifier.current, true];
      const bundle = queryClient.getQueryData(queryKey);
      if (bundle) {
        const oldWallets = bundle?.data?.data?.bundle?.wallets;
        const newWallets = oldWallets.filter((w) => w.address !== address);
        const newBundle = {
          ...bundle,
          data: {
            ...bundle.data,
            data: {
              ...bundle.data.data,
              bundle: {
                ...bundle.data.data.bundle,
                wallets: newWallets,
              },
            },
          },
        };
        queryClient.setQueryData({
          queryKey: queryKey,
          data: newBundle,
        });
      }
    } catch (err) {
      console.error({ err });
      logSentryEvent(err, {
        msg: "Error in removeWalletFromProfileCache",
      });
    }
  };
  return (
    <ProfileContext.Provider
      value={{
        invalidateProfileDetails,
        profileData,
        setProfileData,
        allWallets,
        updateProfile,
        identifier,
        setIdentifier,
        setUpdatePortfolioTabRenderFunction,
        setUpdateActivitiesTabRenderFunction,
        setUpdateNetworthCardRenderFunction,
        selectedActiveChains,
        setSelectedChains,
        setSelectedTokens,
        selectedActiveTokens,
        selectedActiveProtocols,
        selectedWalletAddresses,
        selectedGroupIds,
        setSelectedGroupIds,
        getSelectedActiveTokensWithoutNativeTokens,
        isLoggedInUserProfile,
        setHoldingsTotalUsdValue,
        holdingsTotalUsdValue,

        // Access Tab
        isAccessTabClicked,
        setIsAccessTabClicked,

        // wallet
        removeWalletFromProfileCache,
      }}>
      {props.children}
    </ProfileContext.Provider>
  );
};
export default ProfileContextProvider;
