import React from "react";
import {
  useChannelStateContext,
  useChatContext,
  Avatar,
} from "stream-chat-react";
import "./MessagingChannelHeader.css";
import { TypingIndicator } from "../TypingIndicator/TypingIndicator";
import { HamburgerIcon } from "../../assets";
import ChannelMenu from "../ChannelMenu/ChannelMenu";
import UserAvatar from "components/UserAvatar/UserAvatar";
import {
  CustomColumn,
  CustomRow,
  CustomText,
} from "components/UI/Components/Components";
import { useState } from "react";
import { Dialog } from "@mui/material";
import EditGroupPopup from "../EditGroupPopup/EditGroupPopup";
import { ChannelTypes, isGroupChannelType } from "../../helpers";
import usePageTitle from "customHooks/usePageTitle";

const MessagingChannelHeader = (props) => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const members = Object.values(channel.state?.members || {}).filter(
    (member) => member.user?.id !== client.user?.id
  );

  const dmUser = Object.values(channel.state?.members || {}).find(
    (member) => member.user?.id !== client.user?.id
  );

  const channelImage = isGroupChannelType(channel.data.channel_type)
    ? channel.data.image
    : dmUser?.user?.image;

  const dmUserProfile = {
    identity_id: parseInt(dmUser?.user_id.replace("sc-identity-", "")),
    username: dmUser?.user?.username,
    display_name: dmUser?.user?.name,
    display_picture: dmUser?.user?.image,
    is_verified: true,
  };
  const onlineMembersCount =
    members.filter((member) => member.user?.online)?.length || 0;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  usePageTitle({
    title:
      channel?.data?.channel_type !== ChannelTypes.DM
        ? channel?.data?.name
        : dmUserProfile?.display_name
          ? `${dmUserProfile?.display_name} - Messaging`
          : "Messages - 0xPPL",
  });

  return (
    <div className="messaging__channel-header">
      <div
        id="mobile-nav-icon"
        className={`${props.theme}`}
        onClick={() => props.toggleMobile()}>
        <HamburgerIcon />
      </div>
      {channel.data.channel_type === ChannelTypes.DM && (
        <CustomRow gap="8px" alignItems="center" padding="0 0 0 16px">
          <UserAvatar
            size="32px"
            actor={dmUserProfile}
            isOnline={dmUser?.user?.online}
            lastActive={dmUser?.user?.last_active}
          />
        </CustomRow>
      )}
      {isGroupChannelType(channel.data.channel_type) && (
        <CustomRow
          gap="8px"
          alignItems="center"
          padding="0 0 0 16px"
          width="95%"
          flex="1"
          cursor="pointer"
          onClick={openModal}>
          <Avatar image={channelImage} name={channel?.data.name} size={36} />
          <CustomColumn alignItems="start">
            <div className="channel-header__name">{channel?.data.name}</div>
            <CustomText
              text={`${members.length + 1} members, ${
                onlineMembersCount + 1
              } online`}
              color="var(--text-2)"
              lineHeight="1rem"
              fontSize="11px"
            />
          </CustomColumn>
        </CustomRow>
      )}
      <div className="messaging__channel-header__right">
        <TypingIndicator />
        {/* {channel.data.channel_type === ChannelTypes.GROUP && (
          <CustomRow gap="4px" alignItems="center" padding="0 8px">
            {members?.map((member) => (
              <UserAvatar
                imageOnly
                actor={{
                  identity_id: parseInt(
                    member?.user_id.replace("sc-identity-", "")
                  ),
                  display_name: member?.user?.name,
                  display_picture: member?.user?.image,
                }}
                size="small"
                isOnline={member?.user?.online}
                lastActive={member?.user?.last_active}
              />
            ))}
          </CustomRow>
        )} */}
        <ChannelMenu channel={channel} openGroupInfoModal={openModal} />
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              backgroundColor: "var(--base)",
            },
          }}
          maxWidth={"xs"}
          fullWidth
          open={isModalOpen}
          onClose={closeModal}>
          <EditGroupPopup onClose={closeModal} channel={channel} />
        </Dialog>
      </div>
    </div>
  );
};

export default React.memo(MessagingChannelHeader);
