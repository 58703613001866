import classes from "./Components.module.css";

const Verifiedtag = ({ verified }) => {
  return (
    <div className={classes.verifiedContainer}>
      <span>VERIFIED</span>
    </div>
  );
};

export default Verifiedtag;
