import { getProfileData } from "api/profile.api";
import withLoader from "components/core/withLoader";
import { useEffect, useState } from "react";
import classes from "./ProfileHeader.module.css";
import ProfileInfo from "./ProfileInfo";
import { useContext } from "react";
import { ProfileContext } from "contextStates/ProfileContext";
import { createPortal } from "react-dom";
import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import FollowerFollowingRightPanel from "../FollowerFollowing/FollowerFollowingRightPanel";
import { useParams, useHistory } from "react-router-dom";
import ProfileHeaderShimmer from "./ProfileHeaderShimmer";
import { ProfileTypes } from "../ProfileConstants";
import {
  BITCOIN_SUPPORTED_ROUTES,
  COSMOS_SUPPORTED_ROUTES,
  TON_SUPPORTED_ROUTES,
} from "../constants";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import { Switch, Route } from "react-router-dom";
import MutualFollowersRightPanel from "../MutualFollowers/MutualFollowersRightPanel";
import ExplorerCard from "./ExplorerCard";
import usePageTitle from "customHooks/usePageTitle";
import { useQuery } from "@tanstack/react-query";
import { getGraphKey } from "utils/misc";
import { fetchGraphData } from "api/graph.api";
import { computeUrl } from "components/V2/Explorer/utils/explorer_utils";
import { ONE_DAY } from "utils/constants";
const TABLIST = [
  "activities",
  "portfolio",
  "nfts",
  "wallets",
  "deployed_contracts",
  "bundle",
];
const ProfileHeader = ({
  queryData,
  identifier,
  setProfileType,
  refetch,
  profileType,
}) => {
  let { active_tab } = useParams();
  const [graphQueryKey] = useState("");
  const history = useHistory();
  const isIdentityPage = queryData?.data?.data?.type === "identity";
  useEffect(() => {
    if (profileType === ProfileTypes.COSMOS) {
      if (COSMOS_SUPPORTED_ROUTES.includes(active_tab)) {
        return;
      }
      history.replace(`/${identifier}/portfolio`);
    } else if (profileType === ProfileTypes.BITCOIN) {
      if (BITCOIN_SUPPORTED_ROUTES.includes(active_tab)) {
        return;
      }
      history.replace(`/${identifier}/portfolio`);
    } else if (profileType === ProfileTypes.TON) {
      if (TON_SUPPORTED_ROUTES.includes(active_tab)) {
        return;
      }
      history.replace(`/${identifier}/portfolio`);
    } else {
      if (active_tab && !TABLIST.includes(active_tab)) {
        history.replace(`/${identifier}/activities`);
        return;
      }
      if (!active_tab) {
        history.replace(`/${identifier}/activities`);
      }
    }
  }, [history, identifier, profileType, active_tab]);
  const { setTitle } = useContext(LeftSideHeaderContext);
  useEffect(() => {
    setTitle(queryData?.data?.data?.details?.display_name);
  }, [queryData, setTitle]);
  usePageTitle({
    title: queryData
      ? `${queryData?.data?.data?.details?.display_name} - 0xPPL`
      : "Profile - 0xPPL",
  });

  const { setProfileData } = useContext(ProfileContext);
  setProfileData(queryData?.data?.data);
  const frontendShareablePath = computeUrl({
    identifiers: identifier?.toString(),
    buildIdentityNodes: true,
  });
  useQuery({
    queryKey: getGraphKey({
      identifiers:
        queryData?.data?.data?.details?.id ??
        queryData?.data?.data?.details?.username ??
        identifier,
      isIdentityNodes: true,
      isContractsGraph: false,
    }),
    queryFn: ({ signal }) => fetchGraphData(frontendShareablePath, signal),
    staleTime: ONE_DAY,
  });

  const profileInfo = queryData?.data?.data?.details;
  useEffect(() => {
    if (profileType !== queryData?.data?.data.type) {
      setProfileType?.(queryData?.data?.data.type);
    }
  }, [queryData?.data.data.type, setProfileType, profileType]);
  const followerCount =
    queryData?.data?.data?.details?.follower_count?.display_value;
  const followingCount =
    queryData?.data?.data?.details?.following_count?.display_value;
  const onClose = () => {
    history.push(`/${identifier}/${active_tab}`);
  };
  return (
    <div className={classes.profile_header}>
      <section className={classes.main}>
        <ProfileInfo
          info={profileInfo}
          profileType={queryData?.data?.data.type}
          refetch={refetch}
          isIdentityPage={isIdentityPage}
          graphQueryKey={graphQueryKey}
        />
      </section>
      <Switch>
        <Route path="/:identifier/:active_tab/following">
          {createPortal(
            <RightPanel onClose={onClose}>
              <FollowerFollowingRightPanel
                identityId={profileInfo?.id}
                isFollowers={false}
                count={followingCount}
              />
            </RightPanel>,
            document.getElementById("rightPanel")
          )}
        </Route>
        <Route path="/:identifier/:active_tab/followers">
          {createPortal(
            <RightPanel onClose={onClose}>
              <FollowerFollowingRightPanel
                identityId={profileInfo?.id}
                isFollowers={true}
                count={followerCount}
                onClose={() => {
                  history.push(`/${identifier}/${active_tab}`);
                }}
              />
            </RightPanel>,
            document.getElementById("rightPanel")
          )}
        </Route>
        <Route path="/:identifier/:active_tab/mutual">
          {createPortal(
            <RightPanel>
              <MutualFollowersRightPanel
                identityId={profileInfo?.id}
                isFollowers={true}
                onClose={() => {
                  history.push(`/${identifier}/${active_tab}`);
                }}
              />
            </RightPanel>,
            document.getElementById("rightPanel")
          )}
        </Route>
      </Switch>
    </div>
  );
};

export const ExplorerWidget = ({ queryData, identifier, profileType }) => {
  const isIdentityPage = queryData.data?.data?.type === "identity";
  const graphQueryKey = `graph-data-${identifier}`;
  return (
    <ExplorerCard
      isIdentityPage={isIdentityPage}
      graphQueryKey={graphQueryKey}
      identifier={identifier}
      showSummary={
        profileType !== ProfileTypes.COSMOS &&
        profileType !== ProfileTypes.SOLANA
      }
      title={
        profileType === ProfileTypes.COSMOS
          ? "EXPLORE VISUALLY"
          : "TOTAL VALUE TRANSFERRED"
      }
    />
  );
};

export default withLoader(ProfileHeader, getProfileData, {
  queryKey: (props) => ["profile", props.identifier],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  CustomLoader: ProfileHeaderShimmer,
  renderNotFound: true,
  errorSize: "150px",
});
