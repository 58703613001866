import { Search } from "@mui/icons-material";
import { Box } from "@mui/material";
import { signedRequest } from "api/api";
import OnboardingBg from "components/V2/NewOnboarding/OnboardingBg";
import { GlobalContext } from "contextStates/Global";
import { useCallback, useContext, useEffect, useState } from "react";
import PeopleList from "./PeopleList";
import styles from "./topProfiles.module.css";
import useNavigateNewOnboarding from "components/V2/NewOnboarding/NavigateOnboarding";
import {
  CustomColumn,
  CustomSkeleton,
  SpinnerComponent,
} from "components/UI/Components/Components";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { trackEvent } from "utils/event_tracking";
import { moveOnboardingState } from "api/waitlist.api";

const TopProfiles = ({
  path,
  updatePath,
  prevPath,
  currentStep,
  nextStep,
  isTwitterProfiles = false,
  isPeopleInteractedWith = false,
  isTopProfiles,
  title,
  description,
}) => {
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const [userProfiles, setUserProfiles] = useState(null);
  const [filteredProfiles, setFilteredProfiles] = useState([]); // For storing filtered profiles
  const [, setError] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [navigateOnboarding, setNavigateOnboarding] = useState(false);
  const loadingNavigation = useNavigateNewOnboarding(
    navigateOnboarding,
    setNavigateOnboarding
  );

  const fetch = useCallback(() => {
    signedRequest({
      method: "get",
      path: path,
    })
      .then((data) => {
        setUserProfiles(data.data.data);
        setFilteredProfiles(data.data.data); // Initial load set both userProfiles and filteredProfiles
        setIsLoading(false);
      })
      .catch((err) => {
        handleErrorSnackbar(err);
        setError(true);
        setIsLoading(false);
      });
  }, [handleErrorSnackbar, path]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const onProceed = async () => {
    trackEvent(
      isTopProfiles
        ? "onboarding_top_profile_continue_clicked"
        : "onboarding_follow_friends_continue_clicked"
    );
    setIsButtonLoading(true);
    const payload = isTwitterProfiles
      ? {
          ids: selectedIds,
          current_step: currentStep,
        }
      : {
          identity_ids: selectedIds,
          current_step: currentStep,
        };
    try {
      await signedRequest({
        method: "post",
        path: updatePath,
        bodyText: JSON.stringify(payload),
      });
      if (isTwitterProfiles) {
        await moveOnboardingState({
          currentStep: "follow_friends",
        });
      }
      setIsButtonLoading(false);
      setNavigateOnboarding(true);
    } catch (err) {
      setIsButtonLoading(false);
      handleErrorSnackbar(err);
    }
  };

  const isDisabled = isTwitterProfiles ? false : selectedIds?.length < 3;
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (!userProfiles) return;
    const lowercasedQuery = searchQuery?.toLowerCase();
    if (lowercasedQuery.length === 0) {
      setFilteredProfiles(userProfiles);
    } else {
      const searchResults = userProfiles.filter((profile) => {
        return profile?.display_name?.toLowerCase().includes(lowercasedQuery);
      });
      setFilteredProfiles(searchResults);
    }
  }, [searchQuery, userProfiles, setFilteredProfiles]);

  const handleSelect = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  return (
    <BaseWrapper
      mixpanelEvent={`${
        isTopProfiles ? "OnboardingTopProfiles" : "OnboardingFriends"
      }`}>
      <OnboardingBg>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.headerContent}>
              <h1 className={styles.title}>{title}</h1>
              <p className={styles.subtitle}>{description}</p>
              <div className={styles.searchContainer}>
                <Box className={styles.searchBox}>
                  <Search className={styles.searchIcon} />
                  <input
                    type="text"
                    placeholder="Search for people onchain"
                    className={styles.searchInput}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                </Box>
              </div>
            </div>
          </div>
          <div className={styles.listContainer}>
            {isLoading ? (
              <CustomColumn gap="12px">
                {[1, 2, 3, 4, 5].map((item, index) => (
                  <CustomSkeleton
                    key={index}
                    width="100%"
                    height="60px"
                    variant="rectangle"
                  />
                ))}
              </CustomColumn>
            ) : (
              <PeopleList
                peopleList={filteredProfiles ?? []}
                selectedIds={selectedIds}
                onSelected={handleSelect}
                onUnselected={handleSelect}
              />
            )}
          </div>
          <button
            onClick={onProceed}
            className={`${styles.proceedButton} ${isDisabled || isButtonLoading || loadingNavigation ? styles.disabled : ""}`}
            disabled={isDisabled}>
            {isButtonLoading || loadingNavigation ? (
              <SpinnerComponent color="#fff" size={24} />
            ) : isTwitterProfiles ? (
              "Continue"
            ) : selectedIds?.length < 3 ? (
              `Follow ${selectedIds?.length} / 3 Notables`
            ) : (
              `Continue`
            )}
          </button>
          {/* <LoaderButton
            onClick={onProceed}
            checkLogin={false}
            disabled={isDisabled}
            height="46px"
            className={styles.proceedButton}>
            {`Follow Notables`}
          </LoaderButton> */}
        </div>
      </OnboardingBg>
    </BaseWrapper>
  );
};

export default TopProfiles;
