import React from "react";
import SkeletonElement from "shared/GhostLoader/GhostElement/GhostElement";
import Shimmer from "shared/GhostLoader/Shimmer/Shimmer";

const GhostSourceCode = () => {
  return (
    <div className="ghost-source-code contract-code__source-code--item">
      <div className="file">
        <SkeletonElement type="label" />
        <SkeletonElement type="label" />
      </div>
      <Shimmer />
    </div>
  );
};

export default GhostSourceCode;
