import {
  useFetchTokenSwapTableData,
  useGetTokenChartData,
  useGetTokenFolloweePNLs,
  useGetTokenPageBasicDetails,
  useGetTokenPageDetails,
  useTokenPagePnl,
} from "api/token_page.api";
import useTopHolders from "components/V2/Token/TopUsers/useTopHolders";
import { PAGE_TYPES } from "components/V2/TokenPage/token_page_utils";
import { useAuthContext } from "contextStates/AuthContext";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { formatUnixTimestamp } from "utils/misc";

// create context
const ZxTokenPageContext = createContext();
// create provider
export const ZxTokenPageContextProvider = ({ children, address, chain }) => {
  const tokenPageDetails = useGetTokenPageDetails({ chain, address });
  const chartDetails = useGetTokenChartData({ chain, address });
  const chartMarkers = useRef([]);
  const [swapFilter, setSwapFilter] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(null);
  const { identityDetails } = useAuthContext();
  const history = useHistory();
  useTopHolders(address, chain);
  const username = identityDetails?.current?.identity?.username;
  useEffect(() => {
    const tokenPageType = [
      PAGE_TYPES.TOKEN,
      PAGE_TYPES.LPTOKEN,
      PAGE_TYPES.HYBRIDTOKEN,
      PAGE_TYPES.MULTITOKEN,
      "unknown",
      "Unknown",
    ];
    if (
      chartDetails.data &&
      !tokenPageType.includes(chartDetails.data?.contract_type)
    ) {
      history.push(`/c/${chain}/${address}/contract`);
    }
  }, [chartDetails, address, chain, history]);
  const getMarkers = () => {
    return chartMarkers;
  };
  const { data: filteredUserPnl } = useTokenPagePnl({
    chainId: chain,
    contractAddress: address,
    identifier:
      swapFilter?.identity_id ??
      swapFilter?.id ??
      swapFilter?.username ??
      swapFilter?.address ??
      null,
    isMyHoldings: false,
  });
  const updateSwapFilter = (filter) => {
    setSwapFilter(filter);
  };
  const { data: myHoldings } = useTokenPagePnl({
    chainId: chain,
    contractAddress: address,
    identifier: username ?? null,
  });
  const tokenProfile = useGetTokenPageBasicDetails({
    chain,
    address,
  });
  const followeePnls = useGetTokenFolloweePNLs({ chain, address });
  const { data: transfers } = useFetchTokenSwapTableData({
    queryKey: "recent_swaps",
    chainId: chain,
    contractAddress: address,
    type: "recent",
    filter: swapFilter,
  });
  useEffect(() => {
    try {
      const widget = window.tvWidget;
      const chart = widget?.activeChart();
      if (!swapFilter || !transfers) {
        chartMarkers.current = [];
        if (chart) {
          chart?.clearMarks();
        }
        return;
      }
      const allRows = transfers?.pages?.flatMap(
        (page) => page?.recent_transfer_data
      );
      const val = [];

      allRows.forEach((t) => {
        const type = t.type.toLowerCase();
        if (
          type === "buy" ||
          type === "sell" ||
          type === "received" ||
          type === "sent"
        ) {
          const isBuy = type === "buy" || type === "received";
          const price = t.price_bought_at?.value;
          val.push({
            timestamp: t.time_stamp,
            action: type,
            color: isBuy ? "green" : "red",
            type: type,
            price: price,
            amount: t.amount.value,
            text: [`$${price} \n`, formatUnixTimestamp(t.time_stamp)],
            label: isBuy ? "B" : "S",
            id: `${t.time_stamp}_${type}`,
          });
        }
      });
      chartMarkers.current = val;

      if (chart && val && val.length > 0 && chart) {
        chart.refreshMarks();
      }
    } catch (err) {
      console.log({ err });
    }
  }, [transfers, swapFilter]);
  const updateTokenPrice = (price) => {
    setTokenPrice(price);
  };
  return (
    <ZxTokenPageContext.Provider
      value={{
        address,
        chain,
        tokenPageDetails,
        chartDetails,
        tokenProfile,
        followeePnls,
        swapFilter,
        updateSwapFilter,
        tokenPrice,
        updateTokenPrice,
        filteredUserPnl,
        getMarkers,
        myHoldings,
      }}>
      {children}
    </ZxTokenPageContext.Provider>
  );
};

export default ZxTokenPageContext;
export const useZxTokenPageContext = () => {
  return useContext(ZxTokenPageContext);
};
