import classes from "./NetWorthCard.module.css";
import { useQuery } from "@tanstack/react-query";
import {
  getNetworthData,
  getNetworthGraphData,
  updateNetWorthPreferences,
} from "api/profile.api";
import withLoader from "components/core/withLoader";
import ProfileCardShimmer from "./ProfileCardShimmer";
import {
  CustomRow,
  CustomText,
  PriceChange,
  SpinnerComponent,
} from "components/UI/Components/Components";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { CircularProgress } from "@mui/material";
import Graph from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/Graph";
import GraphLoader from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";
import Refetch from "components/UI/Refetch/Refetch";
import { useContext, useEffect, useState } from "react";
import { ProfileContext } from "contextStates/ProfileContext";
import NetworthChartModal from "../NetworthChartModal/NetworthChartModal";
import CustomDropdownButton from "components/UI/Components/CustomDropDown";
import {
  Chart,
  DEFAULT_NETWORTH_PREFERENCES,
  NETWORTH_PREFERENCES,
} from "utils/constants";
import { QUERY_KEYS } from "utils/query_utils";
import { Checkbox } from "../../../../../node_modules/@mui/material/index";
import { GlobalContext } from "contextStates/Global";
import { AuthContext } from "contextStates/AuthContext";
import useTokenHoldings from "../ProfileDetails/Portfolio/TokenHoldings/useTokenHoldings";
import useFetchProtocolHoldings from "customHooks/fetch/useFetchProtocolHoldings";
import useProfileNftHoldings from "../ProfileDetails/NFTHoldings/useProfileNftHoldings";
import { useBundleInfo } from "components/Pages/AppModule/RightSideBar/apis/useBundleInfo";

const excludedDurations = [
  Chart.DURATION["3M"],
  Chart.DURATION["1Y"],
  Chart.DURATION["ALL"],
];

const NetWorthCard = ({ queryData, identifier, refetch }) => {
  const { profileData } = useContext(ProfileContext);
  const { identityDetails } = useContext(AuthContext);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const preferences = profileData.current?.details?.net_worth_preferences;
  const { data: tokenHoldingsData } = useTokenHoldings({
    identifier,
    forProtocolTreasury: false,
    enabled: false,
  });
  const { data: protocolHoldings } = useFetchProtocolHoldings({
    identifier: identifier,
    forProtocolTreasury: false,
    enabled: false,
  });
  const { data: nftHoldings } = useProfileNftHoldings({
    identifier,
    selectedGroupIds: [],
    enabled: false,
  });
  const { refetch: refetchBundles } = useBundleInfo({
    identifier,
    enabled: false,
  });
  const netWorth = queryData?.data?.data ?? {};
  const {
    setUpdateNetworthCardRenderFunction,
    // holdingsTotalUsdValue
  } = useContext(ProfileContext);
  const [isGraphModalOpen, setIsGraphModalOpen] = useState(false);
  const [graphDuration, setGraphDuration] = useState(Chart.DURATION["7D"]);

  const {
    data: queryGraphData,
    isLoading: isGraphLoading,
    isFetching: isGraphFetching,
    isError: isGraphError,
    refetch: refetchGraphData,
  } = useQuery({
    queryKey: QUERY_KEYS.NETWORTH_GRAPH({
      identity: identifier,
      duration: graphDuration,
    }),
    queryFn: () =>
      getNetworthGraphData({
        identifier,
        forProtocolTreasury: false,
        duration: Chart.DURATION_DAYS[graphDuration],
      }),
    staleTime: 0,
  });

  const toggleGraphModal = () => {
    setIsGraphModalOpen(!isGraphModalOpen);
  };

  const [updateNetworthCard, setUpdateNetworthCard] = useState(
    preferences.includes(NETWORTH_PREFERENCES.NFT) ?? null
  );

  useEffect(() => {
    setUpdateNetworthCardRenderFunction(
      setUpdateNetworthCard,
      updateNetworthCard
    );
    return () => setUpdateNetworthCardRenderFunction(null, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isNftIncluded, setIsNftIncluded] = useState(
    preferences.includes(NETWORTH_PREFERENCES.NFT)
  );
  const updatePreference = async (addNft) => {
    setIsLoading(true);
    try {
      const sources = [
        ...DEFAULT_NETWORTH_PREFERENCES,
        ...(addNft ? [NETWORTH_PREFERENCES.NFT] : []),
      ];
      await updateNetWorthPreferences({
        sources,
        identifier: identityDetails.current?.identity?.id,
      });
      refetch();
      refetchGraphData();
      refetchBundles();
    } catch (err) {
      handleErrorSnackbar(new Error("Failed to update preferences"));
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={`${classes.net_worth_card}`}>
      {netWorth && netWorth?._Any && queryGraphData && isGraphModalOpen && (
        <NetworthChartModal
          netWorth={netWorth?._Any}
          isOpen={isGraphModalOpen}
          toggle={toggleGraphModal}
          showLink={false}
          identifier={identifier}
          initialData={queryGraphData}
          refetchNetWorth={refetchGraphData}
        />
      )}
      <div className={classes.net_worth_info}>
        <div className={classes.top}>
          <CustomRow justifyContent="space-between" alignItems="center">
            <div className={classes.title}>Net Worth</div>

            {!netWorth?._Any?.is_fully_computed && (
              <PPLxTooltip title="Computing the net worth" arrow>
                <CircularProgress
                  style={{ color: "var(--text-3)" }}
                  size={12}
                />
              </PPLxTooltip>
            )}
            <CustomDropdownButton
              title={graphDuration}
              items={Object.values(Chart.DURATION).filter(
                (item) => !excludedDurations.includes(item)
              )}
              customClass={classes.dropdownButton}
              customClassList={classes.dropdownList}
              color={"var(--text-2)"}
              onSelectItem={(item, _, e) => {
                e?.stopPropagation();
                setGraphDuration(item);
              }}
            />
          </CustomRow>
          <div className={classes.value}>
            <div className={classes.value_number}>
              {netWorth?.["_Any"]?.total_usd_value?.display_value}
            </div>
            <div className={classes.value_change}>
              <PriceChange
                value={queryGraphData?.total_percent_change?.value}
                displayValue={
                  queryGraphData?.total_percent_change?.display_value
                }
                showHyphenForNull={false}
              />
            </div>
          </div>
        </div>
        <div className={classes.categories}>
          {Object.entries(NETWORTH_PREFERENCES).map(([key, value]) => {
            const isDefault = DEFAULT_NETWORTH_PREFERENCES.includes(value);
            const isNft = value === NETWORTH_PREFERENCES.NFT;
            const isToken = value === NETWORTH_PREFERENCES.Token;
            const displayText = isNft
              ? nftHoldings?.total_usd_value?.display_value ??
                nftHoldings?.total_usd_value ??
                "~$0"
              : isToken
                ? tokenHoldingsData?.total_usd_value
                : protocolHoldings?.total_usd_value?.display_value;
            return (
              <div key={value} className={classes.category}>
                <div className={classes.category_title}>
                  <Checkbox
                    checked={isNft ? isNftIncluded : isDefault}
                    onChange={(e) => {
                      if (isNft) {
                        setIsNftIncluded(e.target.checked);
                        updatePreference(e.target.checked);
                      }
                    }}
                    size="small"
                    defaultChecked={isDefault}
                    classes={{
                      root: classes.filterCheckbox,
                    }}
                    disabled={isDefault || isLoading}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        marginRight: "2px",
                        padding: "0px",
                      },
                      "& .MuiTypography-root": { fontSize: "14px" },
                      "& .Mui-checked": { color: "var(--primary-color)" },
                    }}
                  />
                </div>
                <div className={classes.category_value}>
                  {key}
                  <CustomText
                    text={displayText}
                    color="var(--text-1)"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="17px"
                    letterSpacing="-0.2px"
                  />
                  {isLoading && isNft && (
                    <SpinnerComponent size={14} color="var(--primary-color)" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.graphContainer} onClick={toggleGraphModal}>
        {isGraphLoading ? (
          <GraphLoader height={164} />
        ) : isGraphError ? (
          <div className={classes.graphError}>
            <Refetch
              onClick={() => {
                refetchGraphData();
              }}
              containerClass={classes.graph_component_refetch}
              isLoading={isGraphFetching}
            />
          </div>
        ) : queryGraphData != null ? (
          <div className={classes.graph}>
            <Graph
              graphData={queryGraphData}
              margin={{ left: 0, right: 0, bottom: 0, top: 0 }}
              parentWidth={350}
              parentHeight={100}
              isXAxisVisible={false}
              isYAxisVisible={false}
              horizontalGridLines={0}
              verticalGridLines={0}
              gradientFill={true}
              hideHover={false}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withLoader(NetWorthCard, getNetworthData, {
  queryKey: (props) => ["networth", props.identifier?.toString()],
  CustomLoader: ProfileCardShimmer,
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  showRefetch: false,
  renderNotFound: false,
  containerClass: classes.container_class,
});
