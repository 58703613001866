import React, { useEffect } from "react";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import styles from "./BioDetails.module.css";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";
import { useForm } from "react-hook-form";
import { Divider, PaddingComponent } from "components/UI/Components/Components";
import ZxInput from "zxComponents/common/ZxInput/ZxInput";
import GenericButton from "components/V2/GenericButton/GenericButton";
import Image from "components/UI/Image";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
const BioCompletedForm = ({
  actor,
  generatedBio,
  goBack,
  regenerateBio,
  isGeneratingBio,
  retries,
  onSubmit,
  isUpdatingProfile = false,
}) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    initialValues: {
      bio: generatedBio,
    },
  });
  useEffect(() => {
    setValue("bio", generatedBio);
  }, [generatedBio, setValue]);
  const hasError = errors?.bio?.message || errors?.bio?.type;
  const moveToNextStep = (values) => {
    onSubmit(values);
  };
  return (
    <form
      style={{ position: "relative" }}
      onSubmit={handleSubmit(moveToNextStep)}>
      <ZxFlex dir="column" align="flex-start" width="100%" gap="8px">
        <ZxFlex justify="space-between" align="flex-start" width="100%">
          <ZxActor
            disableLink
            actor={actor}
            showBio={false}
            showFollowButton={false}
            nameLength={32}
            dpClassName={styles.bioDp}
            dpBorderRadius="12px"
            showNetworth={false}
            showAlias={true}
            size="80px"
            infoRowGap="2px"
            columnJustify="center"
            showFollowCounts
            actorRowGap="12px"
            overrideVerifiedTag
          />
          <ExternalLinks links={actor?.external_links} />
        </ZxFlex>
        <ZxFlex
          className={`${styles.completedBioContainer} ${hasError ? styles.errorBorder : ""}`}
          dir="column">
          <ZxInput
            isTextArea
            placeholder="Your profile bio"
            name="bio"
            register={register}
            validation={{
              validate: (v) => v.length > 0 || "Bio cannot be empty",
            }}
            width="568px"
            textAreaRows={3}
            textAreaResize="none"
            noBorderOnFocus
            disabled={isGeneratingBio}
            error={errors.bio}
            focusOnMount
            errorTextClassName={styles.errorText}
          />
          <PaddingComponent padding="0 16px 16px 16px" width="100%">
            <ZxFlex dir="column" align="flex-end">
              <Divider margin="0 0 8px 0" borderColor="var(--border-light)" />
              <GenericButton
                disabled={isGeneratingBio || retries >= 3}
                onClick={() => {
                  regenerateBio({ bio: watch("bio"), regenerate: true });
                }}
                color="var(--primary-color)"
                padding="8px"
                borderRadius="12px"
                background="var(--primary-color10)">
                <ZxFlex>
                  <Image src="/svg/ai_star.svg" alt="regenerate" />
                  <ZxText color="primary" style={TSTYLES.bodyLabel}>
                    {isGeneratingBio
                      ? "Generating a new bio"
                      : `Regenerate bio (${retries} / 3)`}
                  </ZxText>
                </ZxFlex>
              </GenericButton>
            </ZxFlex>
          </PaddingComponent>
        </ZxFlex>
      </ZxFlex>
      <ZxFlex
        justify="space-between"
        gap="12px"
        width="100%"
        className={styles.completeButtons}>
        <GenericButton
          color="var(--text-1)"
          width="100%"
          background="var(--elevation-3)"
          padding="12px 16px"
          onClick={goBack}
          disabled={isGeneratingBio || isUpdatingProfile}
          fontSize="15px"
          borderRadius="8px">
          Back
        </GenericButton>
        <GenericButton
          color="var(--text-white)"
          borderRadius="8px"
          padding="12px 16px"
          fontSize="15px"
          type="submit"
          disabled={
            hasError ||
            isGeneratingBio ||
            isUpdatingProfile ||
            !watch("bio")?.length
          }
          width="100%">
          {isUpdatingProfile ? "Updating bio..." : "Save & Continue"}
        </GenericButton>
      </ZxFlex>
    </form>
  );
};

export default BioCompletedForm;
