import classes from "./footer.module.css";
import {
  ArrowRight,
  ArrowLeft,
  CaretDown,
  CaretUp,
} from "@phosphor-icons/react";

const Footer = ({
  smallTokensCount,
  containsSmallTokens,
  isSmallTokensVisible,
  setIsSmallTokensVisible,
  rightPane = false,
}) => {
  if (!containsSmallTokens || smallTokensCount === 0) {
    return null;
  }
  if (isSmallTokensVisible) {
    return (
      <div className={classes.footer}>
        <div className={classes.label}>
          Hide holdings below 1% of total networth
        </div>
        <button
          className={classes.buttonLabel}
          onClick={() => setIsSmallTokensVisible(false)}>
          Hide
          <span className={classes.arrow}>
            {rightPane ? <CaretUp size={13} /> : <ArrowLeft size={13} />}
          </span>
        </button>
      </div>
    );
  }

  return (
    <div className={classes.footer}>
      <div className={classes.label}>
        {smallTokensCount} more holdings below 1% of total networth
      </div>
      <button
        className={classes.buttonLabel}
        onClick={() => setIsSmallTokensVisible(true)}>
        Show
        <span className={classes.arrow}>
          {rightPane ? <CaretDown size={13} /> : <ArrowRight size={13} />}
        </span>
      </button>
    </div>
  );
};

export default Footer;
