import Image, { IMAGE_TYPES } from "components/UI/Image";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import ConditionalLink from "shared/ConditionalLink";
import classes from "./positionCategories.module.css";
import { protocolTypeToLabel } from "utils/misc.js";

export const PositionCategoryWrapper = ({
  label,
  content,
  hasRemaining = false,
}) => {
  return (
    <div className={classes.positionCategory}>
      <PPLxTooltip title={label}>
        <div className={`${classes.label} `}>{`${label}: `}</div>
      </PPLxTooltip>
      {content}
      {hasRemaining ? <div className={classes.remainingItems}>...</div> : ""}
    </div>
  );
};

export const PositionInfo = ({ token_url, token_icon, balance }) => {
  return (
    <ConditionalLink
      to={{
        pathname: token_url,
      }}
      className={classes.position}>
      <div className={classes.icon}>
        <Image
          src={token_icon}
          alt="pool-token-icon"
          imageType={IMAGE_TYPES.TOKEN}
        />
      </div>
      <PPLxTooltip title={balance}>
        <div className={`${classes.balance} ${classes.textOverflow}`}>
          {balance}
        </div>
      </PPLxTooltip>
    </ConditionalLink>
  );
};

export const Position = ({ pool, balance }) => {
  if (!pool || !balance) return null;
  // merge pool and balance arrays into a single array of objects to properly display pairs
  // TODO(shaheer): ask backend to send pool and balance in a single array
  const mergedArray = pool.slice(0, 2).map((obj, i) => ({
    ...obj,
    ...balance[i],
  }));

  return (
    <div className={classes.positionPair}>
      {mergedArray?.map((item, index) => (
        <PositionInfo
          key={`position-${item?.token_icon}-${index}`}
          token_icon={item?.token_icon}
          balance={item?.display_value}
          token_url={item?.token_url}
        />
      ))}
    </div>
  );
};

const LendingPositions = ({ positions }) => {
  return (
    <>
      {Object.entries(positions || {}).map(([label, holdings], index) => {
        if (holdings?.length === 0) {
          return null;
        }
        if (label === "health_factor") {
          return (
            <PositionCategoryWrapper
              label="Health Factor"
              key={`lending-position-${label}-${index}`}
              content={holdings?.display_value}
            />
          );
        }
        return (
          <PositionCategoryWrapper
            label={protocolTypeToLabel(label)}
            key={`lending-position-${label}-${index}`}
            hasRemaining={holdings?.length - 3 > 0}
            content={
              <div className={classes.positionPair}>
                {holdings?.slice?.(0, 3)?.map((item, index) => {
                  return (
                    <PositionInfo
                      key={`position-info-${item?.token_icon}-${index}`}
                      token_icon={item?.token_icon}
                      balance={item?.balance?.display_value}
                      token_url={item?.token_url}
                    />
                  );
                })}
              </div>
            }
          />
        );
      })}
    </>
  );
};

const PerpetualPositions = ({ type, positions }) => {
  return (
    <PositionCategoryWrapper
      key="perpetual-position"
      label={protocolTypeToLabel(type)}
      content={positions?.slice(0, 3).map((item, index) => {
        return (
          <div className={classes.positionPair}>
            <PositionInfo
              key={`position-${type}-${item?.index?.token_symbol}-${index}`}
              token_icon={item?.index?.token_icon}
              balance={`${item?.index?.token_symbol} | ${item?.leverage}x ${
                item?.is_long ? "Long" : "Short"
              }`}
              token_url={item?.index?.token_url}
            />
          </div>
        );
      })}
    />
  );
};

export const PositionCategory = ({ type, positions }) => {
  if (["Lending", "LeveragedFarming"].includes(type)) {
    return <LendingPositions positions={positions} />;
  } else if (type === "Perpetuals") {
    return <PerpetualPositions positions={positions} type={type} />;
  }
  return (
    <PositionCategoryWrapper
      label={protocolTypeToLabel(type)}
      content={positions?.slice(0, 3).map((item, index) => {
        const key = `position-${type}-${Object.keys(
          item?.pool?.[0] || {}
        )?.[0]?.toString()}-${index}`;

        return <Position key={key} pool={item?.pool} balance={item?.balance} />;
      })}
    />
  );
};

export const PositionCategories = ({
  positions,
  categoryColumnWidth,
  wrap,
  maxWidth = "100%",
}) => {
  return (
    <div
      className={classes.positionCategories}
      style={{
        ...(categoryColumnWidth
          ? { width: categoryColumnWidth, overFlowX: "auto" }
          : {}),
        ...(wrap ? { flexWrap: "wrap", height: "auto" } : {}),
        maxWidth,
      }}>
      {Object.entries(positions || {}).map(([label, details], index) => {
        let transformedLabel = protocolTypeToLabel(label);

        if (label === "DEX" && details?.holdings?.[0]?.range) {
          transformedLabel = "V3 Liquidity Pool";
        }
        if (details?.holdings.length === 0) {
          return null;
        }
        const key = `position-${label}-${index}`;
        return (
          <PositionCategory
            key={key}
            type={transformedLabel}
            positions={details?.holdings}
          />
        );
      })}
    </div>
  );
};
