import { RenderActivity } from "components/GroupFeedItem/GroupFeedItem.components";
import {
  PaddingComponent,
  CustomColumn,
  CustomRow,
  TypeTag,
  FlexGrow,
} from "components/UI/Components/Components";
import { getShortDotFormatAddress } from "utils/profile_utils";
import { epochToReadable, getTimeString } from "utils/misc";
import classes from "./ActivityDetails.module.css";
import ExternalLinks from "../Profile/ProfileHeader/ExternalLinks";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import { ActorInfo } from "components/FeedItemV2/ActivityHeader";
import { ActivityAvatar } from "components/FeedItemV2/GroupedActivityWrapper";
import PerpGraphDetails from "components/FeedItemV2/PerpGraphDetails";
import React from "react";
import { getPolyMarketConditionIds } from "../../Feed/util";
import { PolyMarketFeedContext } from "../../Feed/FeedItemsList";
import { PolyMarketActivities } from "../../FeedItemV2/PolyMarketActivities";

const ActivityDetailsHeader = ({
  activityData,
  contentId,
  isSolana = false,
}) => {
  return (
    <CustomColumn>
      <CustomRow>
        <ActivityAvatar actor={activityData?.actor} onActorUpdate={() => {}} />

        <PaddingComponent width="20px" />
        <FlexGrow>
          <CustomColumn>
            <ActorInfo actor={activityData?.actor} onActorUpdate={() => {}} />

            <PaddingComponent height="4px" />
            {!!activityData?.timestamp && !!activityData?.actor?.address && (
              <CustomRow gap="8px" className={classes.detailsRow}>
                <span className={classes.subtitle}>
                  {`${getTimeString(
                    activityData?.timestamp
                  )} · ${epochToReadable(
                    activityData?.timestamp
                  )} · ${getShortDotFormatAddress({
                    address: activityData?.actor?.address,
                  })}`}
                </span>
                <TypeTag text={"Transaction"}></TypeTag>
              </CustomRow>
            )}
          </CustomColumn>
        </FlexGrow>
        {!activityData?.is_simulated && (
          <ExternalLinks
            isVerticalDivider={false}
            links={activityData?.activities?.[0]?.transactions?.[0]}
          />
        )}
      </CustomRow>
      {activityData?.summary && activityData?.summary?.summary && (
        <PaddingComponent paddingTop="20px">
          <FeedDescription
            nativeTokensInfo={{
              ...activityData?.summary?.native_tokens,
              ...(activityData?.native_tokens_dict ?? {}),
            }}
            templateString={activityData?.summary?.summary}
            heroNames={activityData?.hero_names}
            profiles={activityData?.profiles}
            chainProfiles={activityData?.chain_profiles}
          />
        </PaddingComponent>
      )}
      <PerpGraphDetails
        isShowing={true}
        groupedActivity={activityData}
        overrideActivity={activityData?.activities?.[0] ?? null}
        paddingTop="20px"
        overrideType={isSolana ? "sol_activity_group" : "activity_group"}
        overrideId={contentId ?? activityData?.group_id}
      />
      {!!activityData?.activities && !!activityData.activities?.[0] && (
        <>
          <PaddingComponent paddingTop="20px">
            <RenderActivity
              activity={activityData.activities?.[0]}
              profiles={activityData.profiles}
              methodIds={activityData.method_ids}
              index={0}
              groupedActivity={activityData}
              isBackgroundColor={false}
              isActivityDetails
            />
          </PaddingComponent>
          <PolyMarketFeedContext.Provider
            value={{ conditionIds: getPolyMarketConditionIds([activityData]) }}>
            <PolyMarketActivities
              groupedActivity={activityData}
              showSingleActivity={false}
            />
          </PolyMarketFeedContext.Provider>
        </>
      )}
    </CustomColumn>
  );
};
export default ActivityDetailsHeader;
