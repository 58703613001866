import classes from "./Contracts.module.css";
import ContractItem from "./ContractItem";

const Contracts = ({ contracts, profiles }) => {
  return (
    <div className={classes.contracts}>
      {contracts.map((contract) => {
        return (
          <ContractItem
            contractProfile={
              profiles[`${contract.chain_id}_${contract.address}`]
            }
            key={`${contract.chain_id}_${contract.address}`}
            label={contract.label}
            tvl={contract.tvl}
          />
        );
      })}
    </div>
  );
};

export default Contracts;
