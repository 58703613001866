import { CustomRow, CursorDiv } from "components/UI/Components/Components";
import { CaretUp, CaretDown, CaretRight } from "@phosphor-icons/react";
import ZxText from "../../../../zxComponents/common/ZxText";
import { TSTYLES } from "../../../../zxStyles/constants";

const HomeComponentsHeader = ({
  text,
  isNotExpandable = false,
  isExpanded,
  setIsExpanded,
  onNavigate,
}) => {
  return (
    <CursorDiv
      width="100%"
      onClick={() => {
        if (isNotExpandable) {
          onNavigate?.();
          return;
        }
        setIsExpanded(!isExpanded);
      }}>
      <CustomRow width="100%" justifyContent="space-between">
        <ZxText style={TSTYLES.title2}>{text}</ZxText>
        {isNotExpandable ? (
          <CaretRight size={18} color="var(--primary-color)" />
        ) : isExpanded ? (
          <CaretUp size={18} color="var(--text-2)" />
        ) : (
          <CaretDown size={18} color="var(--text-2)" />
        )}
      </CustomRow>
    </CursorDiv>
  );
};

export default HomeComponentsHeader;
