import React, { useContext } from "react";
import { logChatPromiseExecution } from "stream-chat";
import {
  MessageList,
  MessageInput,
  Thread,
  Window,
  useChannelActionContext,
  useChannelStateContext,
  useMessageInputContext,
} from "stream-chat-react";
import { useHistory } from "react-router-dom";
import { customRenderText, isGroupChannelType } from "../../helpers";

import { MessagingChannelHeader, MessagingInput } from "../../components";

import { GiphyContext } from "../../MessagingApp";
import ChannelInvitedActions from "../ChannelInvitedActions/ChannelInvitedActions";
import { FlexGrow } from "../../../Profile/ProfileDetails/wallets/AddWallet/AddWalletComponent";
import useDraftAPI from "../MessagingInput/useDraftAPI";
import { MessagingContext } from "contextStates/MessagingContext";
import { CustomSkeleton } from "components/UI/Components/Components";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxQuotedMessagePreview from "./ZxQuotedMessagePreview";

export const ChannelInner = (props) => {
  const { theme, toggleMobile } = props;
  const history = useHistory();
  const { giphyState, setGiphyState } = useContext(GiphyContext);
  const { sendMessage } = useChannelActionContext();
  const { channel, quotedMessage } = useChannelStateContext();
  const { parent } = useMessageInputContext();
  const shouldShowQuotedMessage =
    /* prevent answering to one thread in another thread */
    Boolean(quotedMessage) && quotedMessage.parent_id === parent?.id;
  const { isUserLoading } = useContext(MessagingContext);
  const {
    getMainInputDraft,
    getThreadInputDraft,
    handleInputChange,
    handleThreadInputChange,
    overrideSubmitHandler: _channelInnerOverrideSubmitHandler,
    removeDraftAfterSubmit,
  } = useDraftAPI();
  const overrideSubmitHandler = (message, channelCid) => {
    removeDraftAfterSubmit(message, channelCid);
    let updatedMessage;

    if (message.attachments?.length && message.text?.startsWith("/giphy")) {
      const updatedText = message.text.replace("/giphy", "");
      updatedMessage = { ...message, text: updatedText };
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`;
      updatedMessage = { ...message, text: updatedText };
    }

    if (sendMessage) {
      const newMessage = updatedMessage || message;
      const parentMessage = newMessage.parent;

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      };

      const sendMessagePromise = sendMessage(messageToSend);
      logChatPromiseExecution(sendMessagePromise, "send message");
    }

    setGiphyState(false);
  };

  const actions = ["edit", "quote", "delete", "flag", "mute"];
  const showInviteActions =
    channel?.state?.membership?.invited &&
    !(
      channel?.state?.membership?.invite_accepted_at ||
      channel?.state?.membership?.invite_rejected_at
    );

  if (showInviteActions && isGroupChannelType(channel.data.channel_type)) {
    return (
      <Window>
        <FlexGrow />
        <ChannelInvitedActions channel={channel} />
      </Window>
    );
  }
  return (
    <>
      <Window>
        {!isUserLoading ? (
          <>
            <MessagingChannelHeader theme={theme} toggleMobile={toggleMobile} />
            <MessageList
              messageActions={actions}
              onlySenderCanEdit
              renderText={customRenderText}
              onUserClick={(e, user) => {
                history.push(`/${user.username}`);
              }}
            />
            {showInviteActions ? (
              <ChannelInvitedActions channel={channel} />
            ) : (
              <ZxFlex
                dir="column"
                align="flex-start"
                width="100%"
                gap="0"
                style={{ marginTop: "12px" }}>
                {shouldShowQuotedMessage && (
                  <ZxQuotedMessagePreview message={quotedMessage} />
                )}
                <ZxFlex width="100%" flex="1">
                  <MessageInput
                    focus
                    maxRows={6}
                    grow
                    overrideSubmitHandler={overrideSubmitHandler}
                    additionalTextareaProps={{ onChange: handleInputChange }}
                    getDefaultValue={getMainInputDraft}
                  />
                </ZxFlex>
              </ZxFlex>
            )}
          </>
        ) : (
          <CustomSkeleton
            width="100%"
            height="120px"
            style={{
              marginTop: "-24px",
            }}
          />
        )}
      </Window>
      <Thread
        additionalMessageInputProps={{
          additionalTextareaProps: { onChange: handleThreadInputChange },
          getDefaultValue: getThreadInputDraft,
          overrideSubmitHandler: _channelInnerOverrideSubmitHandler,
        }}
        Input={MessagingInput}
      />
    </>
  );
};
