import {
  CustomColumn,
  CustomRow,
  Divider,
} from "components/UI/Components/Components";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import ZxBadge from "zxComponents/common/ZxBadge";
import ZxText from "zxComponents/common/ZxText/ZxText";
import styles from "./WalletAccessPage.module.scss";
import { TSTYLES } from "zxStyles/constants";
import ConditionalLink from "shared/ConditionalLink";
import { getUniqueId } from "utils/event_tracking";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import { CaretRight, DotOutline } from "@phosphor-icons/react";
import ZxModal from "zxComponents/common/ZxModal/ZxModal";
import { Plus } from "@phosphor-icons/react";
import StaticSearchBar from "components/UI/Components/StaticSearchBar";
import { AuthContext } from "contextStates/AuthContext";
import { useGetProfileFollowing } from "api/profile.api";
import GenericFallBackUI from "components/ErrorBoundary/GenericFallBackUI";
import { TransactionShimmer } from "components/Feed/GroupedActivityDetails/GroupActivityDetailsShimmer";
import { isProfileVerified } from "utils/misc";

export const WalletFollowersModal = ({ isOpen, onClose }) => {
  const [search, setSearch] = React.useState("");
  const { identityDetails } = useContext(AuthContext);
  const id = identityDetails?.current?.identity?.id;

  const { data, isLoading, isError } = useGetProfileFollowing({
    identityId: id,
  });
  const profiles = data?.data?.data?.filter((p) => isProfileVerified(p)) ?? [];
  const filteredProfiles =
    search?.length > 0
      ? profiles.filter((profile) =>
          profile?.display_name?.toLowerCase().includes(search.toLowerCase())
        )
      : profiles;
  const history = useHistory();
  return (
    <ZxModal
      isOpen={isOpen}
      onClose={onClose}
      title="Send private wallet request">
      <StaticSearchBar
        setSearchTerm={setSearch}
        title="Search people you follow"
      />
      {isError && <GenericFallBackUI />}
      {isLoading && [1, 2, 3, 4].map((row) => <TransactionShimmer key={row} />)}
      <CustomColumn
        className={styles.followingContainer}
        overflowX="scroll"
        overflowY="scroll">
        {filteredProfiles.map((row, idx) => (
          <div
            key={row.id}
            className={styles.followingRow}
            onClick={() => {
              history.push(`/${id}/wallets/manage-access/${row?.id}`);
              onClose();
            }}>
            <CustomRow alignItems="center" justifyContent="space-between">
              <ZxActor actor={row} showFollowButton={false} nameLength={30} />
              <CaretRight size={16} color="var(--text-2)" weight="bold" />
            </CustomRow>
            <Divider margin="12px 0" />
          </div>
        ))}
      </CustomColumn>
    </ZxModal>
  );
};

export const WalletAccessPageHeader = ({
  title,
  variant,
  text,
  hideBadge = false,
  showNewRequest = false,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const toggleModal = () => setIsModalOpen((prev) => !prev);
  return (
    <div>
      <WalletFollowersModal isOpen={isModalOpen} onClose={toggleModal} />
      <CustomRow
        className={styles.header}
        alignItems="center"
        justifyContent="space-between">
        <CustomRow
          className={styles.headerTitle}
          gap="16px"
          alignItems="center">
          <ZxText style={TSTYLES.title1} fontSize="15px">
            {title}
          </ZxText>
        </CustomRow>
        {!hideBadge && <ZxBadge variant={variant}>{text}</ZxBadge>}
        {showNewRequest && (
          <CustomRow
            gap="8px"
            alignItems="center"
            onClick={toggleModal}
            className={styles.newRequestBtn}>
            <Plus color="var(--primary-color)" size={16} />
            <ZxText color="primary" style={TSTYLES.title2}>
              New request
            </ZxText>
          </CustomRow>
        )}
      </CustomRow>

      <Divider margin="16px 0 0 0" />
    </div>
  );
};

export const WalletSharedRow = ({
  profiles,
  row,
  identifier,
  hideBadge = false,
}) => {
  const profile = profiles?.[row.identity_id] ?? {};
  const walletCount = row?.num_wallets_shared_with_me ?? 0;
  const networth = row?.shared_with_me_net_worth?.display_value ?? "$0";
  const renderWalletAndNetworth = ({ walletCount, networth }) => {
    return (
      <CustomRow gap="2px" alignItems="center">
        <ZxText style={TSTYLES.body2} color="text-2" fontSize="12px">
          {walletCount} wallets
        </ZxText>
        <DotOutline size={16} color="var(--text-2)" weight="fill" />
        <ZxText style={TSTYLES.body2} color="text-2" fontSize="12px">
          {networth}
        </ZxText>
      </CustomRow>
    );
  };
  const isSent = row?.is_sent;
  return (
    <ConditionalLink
      key={getUniqueId()}
      to={`/${identifier}/wallets/manage-access/${row.identity_id}`}>
      <div className={styles.requestRow}>
        <CustomRow gap="8px">
          <ZxActor
            actor={profile}
            showFollowButton={false}
            dpBorderRadius="8px"
            showNetworth={false}
            infoRowGap="0"
            nameLength={20}
            secondLineRender={() =>
              renderWalletAndNetworth({ walletCount, networth })
            }
          />
          {!hideBadge && (
            <ZxBadge variant={isSent ? "warning" : "success"}>
              {isSent ? "Sent" : "Received"}
            </ZxBadge>
          )}
        </CustomRow>
        <CaretRight size={16} color="var(--text-2)" />
      </div>
      <Divider margin="16px 0" />
    </ConditionalLink>
  );
};
