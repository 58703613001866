import classes from "./PnlTokenList.module.css";
import { useContext, useEffect, useState } from "react";
import Image from "components/UI/Image";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
} from "components/UI/Components/Components";
import { GlobalContext } from "contextStates/Global";
import PnlChart from "./PnlChart";
import { getGroupedActivity } from "api/feed.api";
import { useQuery } from "@tanstack/react-query";
import { ONE_DAY, QueryKeyConstants } from "utils/constants";
import withNullableError from "zxComponents/common/withNullableError/index";
import ZxUserTokenGroup from "zxComponents/feed/ZxUserTokenGroup/ZxUserTokenGroup";

const PnlTokenExpandedList = ({ groupedActivity, showRightPanel = true }) => {
  const { setUserTokenTransferDetails } = useContext(GlobalContext);
  const [selectedToken, setSelectedToken] = useState(null);
  const [pnlTokens, setPnlTokens] = useState([]);
  const isUserTokenGroup =
    groupedActivity?.type === "user_token_group" ||
    groupedActivity?.type === "sol_user_token_group";
  useEffect(() => {
    if (groupedActivity) {
      const filteredList = groupedActivity?.user_token_pnl?.filter((item) => {
        return item.open_pnl?.display_value || item.closed_pnl?.display_value;
      });
      if (!filteredList || filteredList?.length === 0) return;
      const pnlList = filteredList?.map((item) => {
        return {
          ...item,
          address: item.token_address,
          profile: groupedActivity?.profiles[item.token_address],
        };
      });

      if (pnlList) {
        setPnlTokens(pnlList);
        setSelectedToken(pnlList[0]);
      }
    }
  }, [groupedActivity]);
  const actorAddress = groupedActivity?.actor?.address;
  const actor =
    groupedActivity?.identities?.[actorAddress] ??
    groupedActivity?.profiles?.[actorAddress] ??
    groupedActivity?.actor;
  return (
    <>
      <div className={classes.pnlExpandedList}>
        {selectedToken && (isUserTokenGroup ? pnlTokens.length > 1 : true) && (
          <>
            <div className={classes.pnlListTokenSelector}>
              {pnlTokens.map((pnlData, index) => {
                const tokenData = pnlData?.profile;
                const isSelected =
                  selectedToken?.token_address === pnlData?.token_address &&
                  selectedToken?.chain_id === pnlData?.chain_id;
                if (!tokenData) return null;
                return (
                  <CustomRow
                    gap="4px"
                    key={"pnl-token" + index}
                    onClick={() => setSelectedToken(pnlData)}
                    className={`${classes.tokenSelectorItem} ${
                      isSelected ? classes.selectedTokenSelectorItem : ""
                    }`}>
                    <Image
                      imageType="small_token"
                      src={tokenData?.token_details?.logo}
                      className={classes.tokenImageSmall}
                    />
                    <CustomText
                      text={
                        tokenData?.token_details?.symbol ??
                        tokenData?.display_name
                      }
                      textOverflow="ellipsis"
                      fontWeight={isSelected ? "600" : "500"}
                      fontSize="13px"
                      color="var(--text-1)"
                      overflow="hidden"
                      whiteSpace="nowrap"
                    />
                  </CustomRow>
                );
              })}
            </div>
            {!isUserTokenGroup && (
              <ZxUserTokenGroup
                groupedActivity={groupedActivity}
                selectedToken={selectedToken}
                profiles={groupedActivity?.profiles}
              />
            )}
            {showRightPanel && (
              <CustomRow
                gap="8px"
                alignItems="flex-end"
                justifyContent="flex-end"
                flexGrow={1}>
                <button
                  className={classes.transactionsButton}
                  onClick={() => {
                    const token = {
                      tokenData: {
                        ...selectedToken,
                        ...selectedToken?.profile,
                        chain_id: selectedToken?.profile?.address_chain,
                        contract_address: selectedToken?.token_address,
                        token_metadata: {
                          symbol: selectedToken?.profile?.token_details?.symbol,
                        },
                      },
                      actor: {
                        id: groupedActivity.actor.identity_id,
                        details: actor,
                      },
                    };
                    setUserTokenTransferDetails(token);
                  }}>
                  View all {selectedToken?.profile?.token_details?.symbol}{" "}
                  transfers
                </button>
              </CustomRow>
            )}
          </>
        )}
      </div>
      {selectedToken && (
        <CustomColumn>
          {!isUserTokenGroup ||
            (isUserTokenGroup && pnlTokens.length > 1 && (
              <Divider margin="0 0 12px 0" />
            ))}
          <PnlChart
            selectedToken={selectedToken}
            identifier={
              groupedActivity?.actor?.identity_id ??
              groupedActivity?.actor?.id ??
              groupedActivity?.actor?.address
            }
          />
        </CustomColumn>
      )}
    </>
  );
};

const Wrapper = ({ type, id, groupedActivity, showRightPanel = true }) => {
  const { data: groupedActivityData } = useQuery({
    queryFn: () =>
      getGroupedActivity({
        id,
        type,
      }),
    queryKey: [
      QueryKeyConstants.GROUPED_ACTIVITY_FROM_BLOB,
      id?.toString(),
      type,
    ],
    staleTime: ONE_DAY,
    enabled: !!id && !!type && !groupedActivity,
  });
  if (!groupedActivityData && !groupedActivity) return null;
  return (
    <PnlTokenExpandedList
      groupedActivity={groupedActivity ? groupedActivity : groupedActivityData}
      showRightPanel={showRightPanel}
    />
  );
};

export default withNullableError(Wrapper);
