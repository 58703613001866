import { useState } from "react";

export const useGalleryModal = () => {
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
  const [currentNftIndex, setCurrentNftIndex] = useState(null);

  const handleGalleryModal = (index) => {
    setCurrentNftIndex(index);
    setIsGalleryModalOpen(true);
  };

  return {
    handleGalleryModal,
    isGalleryModalOpen,
    setIsGalleryModalOpen,
    currentNftIndex,
  };
};
