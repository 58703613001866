import { signedApiOtherRequest } from "api/api";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { GlobalContext } from "contextStates/Global";
import { AuthContext } from "contextStates/AuthContext";
import styled from "styled-components";
import Loader from "components/UI/Loader/Loader";
import { trackEvent } from "utils/event_tracking";

const TwitterCallback = () => {
  const history = useHistory();
  const [queryParams] = useState(
    new URLSearchParams(history?.location?.search)
  );

  const oauth_token = queryParams.get("oauth_token");
  const oauth_verifier = queryParams.get("oauth_verifier");
  const denied = queryParams.get("denied");

  const { isUserAuthenticated } = useContext(AuthContext);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const requestFired = useRef(false);
  useEffect(() => {
    const navigateCallback = (path, identity, success) => {
      const suffix = success
        ? "?twitter_success=true"
        : "?twitter_success=false";
      if (identity?.identity?.onboarding_state === "complete") {
        const link = `${identity?.identity?.link}/edit${suffix}`;
        trackEvent("profile_edit_connect_socials_twitter_success");
        history.replace(link);
      } else {
        trackEvent("ob_connect_socials_twitter_success");
        history.replace(path);
      }
    };
    if (denied) {
      handleErrorSnackbar(null, "Please authorize twitter to proceed");
      trackEvent("ob_connect_socials_twitter_denied");
      isUserAuthenticated({
        isNavigating: true,
        navigateCallback: (path, profile) =>
          navigateCallback(path, profile, false),
      });
    }
    if (oauth_token && oauth_verifier && !requestFired.current) {
      trackEvent("twitter_authorization_success");
      trackEvent("ob_connect_socials_twitter_callback_received");
      requestFired.current = true;
      signedApiOtherRequest({
        method: "post",
        path: "twitter/callback",
        bodyText: JSON.stringify({
          oauth_token,
          oauth_verifier,
        }),
      })
        .then(async (data) => {
          await isUserAuthenticated({
            navigateCallback: (path, profile) =>
              navigateCallback(path, profile, true),
            forceWaitlist: true,
            isNavigating: true,
          });
        })
        .catch((err) => {
          trackEvent("ob_connect_socials_twitter_failed");
          isUserAuthenticated({
            isNavigating: true,
            navigateCallback: (path, profile) =>
              navigateCallback(path, profile, false),
          });
          handleErrorSnackbar(err, "Failed to Authorize.");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [denied, oauth_token, oauth_verifier]);
  // TODO hit api with params.oauth_token and params.oauth_verifier

  return (
    <CenterFullWidthDiv>
      <Loader />
    </CenterFullWidthDiv>
  );
};

export default TwitterCallback;

const CenterFullWidthDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
