import React from 'react';

import './MessagingThread.css';

import { IconButton } from '@mui/material';
import { X } from '@phosphor-icons/react';

const MessagingThreadHeader = ({ closeThread, thread }) => {
  const getReplyCount = () => {
    if (!thread?.reply_count) return '';
    if (thread.reply_count === 1) return '1 reply';
    return `${thread.reply_count} Replies`;
  };

  return (
    <div className="custom-thread-header">
      <div className="custom-thread-header__left">
        <p className="custom-thread-header__left-title">Thread</p>
        <p className="custom-thread-header__left-count">{getReplyCount()}</p>
      </div>
      <IconButton onClick={closeThread}>
        <X size={16} color="var(--text-1)" />
      </IconButton>
    </div>
  );
};

export default MessagingThreadHeader;
