import React, { useState } from "react";
import brokenNftIcon from "assets/svg/profile/broken_nft.svg";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import IPFSMediaPreview from "./IPFSMediaPreview";
import {
  conditionalClass,
  getMediaType,
  transformedIPFSLink,
} from "utils/misc";
import classes from "./NFTPreview.module.css";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { Play } from "@phosphor-icons/react";

const NFTPreview = ({ media, audioPath, type, rightPane = false }) => {
  const mediaPath = transformedIPFSLink(media) || media;

  const imageWrapperClass = `${classes.imageWrapper} ${
    !!type && classes[`${type}Type`]
  } ${conditionalClass(rightPane, classes.rightPane)}`;

  const nftImageClass = !!mediaPath
    ? classes.nftImage
    : ` ${classes.nftImage} ${classes.fallbackImage}`;

  if (getMediaType(media) === "ipfs") {
    return (
      <div className={imageWrapperClass}>
        <IPFSMediaWrapper link={mediaPath} nftImageClass={nftImageClass} />
      </div>
    );
  }

  if (getMediaType(media) === "video") {
    return (
      <div className={imageWrapperClass}>
        <div className={classes.playButton}>
          <PlayCircleFilledIcon fontSize="small" />
        </div>
        <VideoPreview
          hideControls
          mediaPath={media}
          nftImageClass={nftImageClass}
          nftFallbackImage={classes.fallbackImage}
        />
      </div>
    );
  }

  if (audioPath) {
    return (
      <div className={imageWrapperClass}>
        <div className={classes.playButton}>
          <Play size="1rem" weight="fill" />
        </div>
        <AudioPreview
          hideControls
          audioPath={audioPath}
          mediaPath={mediaPath}
          nftImageClass={nftImageClass}
          nftFallbackImage={classes.fallbackImage}
        />
      </div>
    );
  }

  return (
    <div className={imageWrapperClass}>
      <Image
        imageType={IMAGE_TYPES.NFT}
        className={nftImageClass}
        src={mediaPath}
        fallbackClassName={classes.fallbackImage}
      />
    </div>
  );
};

const VideoPreview = ({
  mediaPath,
  nftImageClass,
  nftFallbackImage,
  hideControls = false,
}) => {
  const [isError, setIsError] = useState(false);
  if (isError)
    return (
      <div className={`${nftImageClass} ${nftFallbackImage}`}>
        <img src={brokenNftIcon} alt="broken nft " />
      </div>
    );

  return (
    <div className={nftImageClass}>
      <video
        {...(!hideControls ? { controls: true } : {})}
        onError={() => setIsError(true)}>
        <source src={mediaPath} type="video/mp4" />
      </video>
    </div>
  );
};

const AudioPreview = ({
  audioPath,
  mediaPath,
  nftImageClass,
  nftFallbackImage,
  hideControls = false,
}) => {
  const [isError, setIsError] = useState(false);
  if (isError)
    return (
      <div className={`${nftImageClass} ${nftFallbackImage}`}>
        <img src={brokenNftIcon} alt="broken nft " />
      </div>
    );

  return (
    <div className={nftImageClass}>
      <audio
        {...(!hideControls ? { controls: true } : {})}
        onError={() => setIsError(true)}>
        <source src={audioPath} />
      </audio>
      <Image
        imageType={IMAGE_TYPES.NFT}
        className={nftImageClass}
        src={mediaPath}
        fallbackClassName={classes.fallbackImage}
      />
    </div>
  );
};

const IPFSMediaWrapper = ({ link, nftImageClass }) => {
  return (
    <IPFSMediaPreview
      className={nftImageClass}
      ipfsLink={link}
      nftImageClass={nftImageClass}
      fallbackClassName={classes.fallbackImage}
    />
  );
};

export default NFTPreview;
