import { useParams } from "react-router-dom";
import {
  ChatText,
  UsersFour,
  //Hash,
  XCircle,
  Chats,
  ChatDots,
} from "@phosphor-icons/react";
import { CustomRow } from "components/UI/Components/Components";
import CustomLinkTabBar from "components/UI/Components/CustomLinkTabBar";
import { useChatContext } from "stream-chat-react";
import { useEffect, useState, useCallback } from "react";

import { ChannelTypes, getAllChannelTypes } from "../../helpers";

const MessagingTabs = () => {
  const { active_tab } = useParams();
  const { client, setActiveChannel } = useChatContext();
  const currentStep = active_tab || "all";
  const onClick = () => {
    setActiveChannel(null);
  };
  const [shouldShowBlocked, setShouldShowBlocked] = useState(false);
  const [shouldShowRequests, setShouldShowRequests] = useState(false);

  const checkBlockedChannels = useCallback(async () => {
    const check = async () => {
      const allChannels = await client.queryChannels(
        {
          type: "messaging",
          members: { $in: [client.userID] },
          channel_type: { $in: [ChannelTypes.DM] },
          hidden: true,
        },
        {},
        { watch: false }
      );
      setShouldShowBlocked(allChannels?.length > 0);
    };

    check();
    client.on((event) => {
      if (event?.type === "user.banned") {
        setShouldShowBlocked(true);
      }
      if (event?.type === "user.unbanned") {
        setTimeout(check, 3000);
      }
    });
  }, [client]);

  const checkPendingRequests = useCallback(async () => {
    const check = async () => {
      const allChannels = await client.queryChannels(
        {
          type: "messaging",
          members: { $in: [client.userID] },
          channel_type: { $in: getAllChannelTypes() },
          invite: "pending",
        },
        {},
        { watch: false }
      );
      setShouldShowRequests(allChannels?.length > 0);
    };
    check();
    client.on((event) => {
      if (
        event?.type === "notification.invited" ||
        event?.type === "notification.invite_rejected" ||
        event?.type === "notification.invite_accpeted" ||
        event?.type === "user.banned"
      ) {
        setTimeout(check, 3000);
      }
    });
  }, [client]);

  useEffect(() => {
    checkBlockedChannels();
    checkPendingRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomLinkTabBar
      currentStep={"/messages/" + currentStep}
      tabsList={[
        {
          title: (
            <CustomRow gap="4px" padding="4px 8px" onClick={onClick}>
              <ChatText size={20} />{" "}
              <span className="headerTab">All Messages</span>
            </CustomRow>
          ),
          link: "/messages/all",
        },
        // {
        //   title: (
        //     <CustomRow gap="4px" padding="4px 8px" onClick={onClick}>
        //       <Hash size={20} /> <CustomText text="Channels" />
        //     </CustomRow>
        //   ),
        //   link: "channels",
        // },
        {
          title: (
            <CustomRow gap="4px" padding="4px 8px" onClick={onClick}>
              <UsersFour size={20} /> <span className="headerTab">Groups</span>
            </CustomRow>
          ),
          link: "/messages/groups",
        },
        {
          title: (
            <CustomRow gap="4px" padding="4px 8px" onClick={onClick}>
              <Chats size={20} />{" "}
              <span className="headerTab">Direct Messages</span>
            </CustomRow>
          ),
          link: "/messages/dms",
        },
        ...(shouldShowBlocked
          ? [
              {
                title: (
                  <CustomRow gap="4px" padding="4px 8px" onClick={onClick}>
                    <XCircle size={20} />{" "}
                    <span className="headerTab">Blocked</span>
                  </CustomRow>
                ),
                link: "/messages/blocked",
              },
            ]
          : []),
        ...(shouldShowRequests
          ? [
              {
                title: (
                  <CustomRow
                    gap="4px"
                    padding="4px 8px"
                    onClick={onClick}
                    className="requestTabContainer">
                    <ChatDots size={20} />{" "}
                    <span className="headerNotificationDot"></span>
                    <CustomRow>
                      <span className="headerTab">Requests</span>
                    </CustomRow>
                  </CustomRow>
                ),
                link: "/messages/requests",
              },
            ]
          : []),
      ]}
    />
  );
};

export default MessagingTabs;
