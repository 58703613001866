import { CustomColumn } from "components/UI/Components/Components";
import Loader from "components/UI/Loader/Loader";
import { useHistory } from "react-router";
import React, { useCallback, useEffect } from "react";
import useQueryParams from "customHooks/useQueryParams";
import { logSentryEvent } from "utils/sentry";
import { getMobileOperatingSystem } from "utils/misc";
import { MobileOSTypes } from "utils/constants";
import { getCookiesAndMoveUser } from "api/waitlist.api";

const HelloPage = () => {
  const params = useQueryParams();
  const uid = params.get("code");
  const history = useHistory();
  const [isError, setIsError] = React.useState(false);
  const [text, setText] = React.useState("Fetching your details");
  const isCalled = React.useRef(false);
  const getHelloRoute = useCallback(async () => {
    try {
      const type = getMobileOperatingSystem();
      const isAndroid = type === MobileOSTypes.ANDROID;
      const isIOS = type === MobileOSTypes.IOS;
      if (isAndroid) {
        try {
          setText("Redirecting you to the 0xPPL app");
          await getCookiesAndMoveUser(uid);
        } catch (err) {
          logSentryEvent(err, {
            msg: "Error in moving user to app download",
          });
        } finally {
          window.location.href =
            "https://appdistribution.firebase.dev/i/3c2072cb6bba46b1";
        }
      } else if (isIOS) {
        window.location.href =
          "https://apps.apple.com/in/app/0xppl/id1658303976";
      } else {
        window.location.href = "https://0xppl.com";
      }
    } catch (err) {
      logSentryEvent(err, {
        message: "Error in getting UID for QR code",
      });
      setIsError(true);
    }
  }, [uid]);
  useEffect(() => {
    if (!uid) {
      history.push("/onboarding/intro");
    }
    if (isError) {
      history.push("/onboarding/intro");
    }
    if (!isCalled.current) {
      isCalled.current = true;
      getHelloRoute();
    }
  }, [uid, history, isError, getHelloRoute]);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "20px",
        color: "var(--text-1)",
      }}>
      <CustomColumn gap="12px">
        {text}
        <Loader />
      </CustomColumn>
    </div>
  );
};

export default HelloPage;
