import ProtocolHoldings from "./ProtocolHoldings/ProtocolHoldings";
import TokenHoldings from "./TokenHoldings/TokenHoldings";
import classes from "./Portfolio.module.css";
import NFTHoldings from "components/V2/Profile/ProfileDetails/NFTHoldings/NFTHoldings";
// import ProfileGraphs from "./ProfileGraphs/ProfileGraphs";

const RightPanelPortfolio = ({
  identifier,
  hideGraph = false,
  forProtocolTreasury = false,
  isCosmos = false,
}) => {
  return (
    <div className={classes.portfolio}>
      {/* temp commenting the graph for use case */}
      {/* {!hideGraph && (
        <ProfileGraphs
          forProtocolTreasury={forProtocolTreasury}
          selectedActiveChains={selectedActiveChains.current}
          selectedActiveTokens={selectedActiveTokens.current}
          selectedActiveProtocols={selectedActiveProtocols.current}
          identifier={identifier}
        />
      )} */}
      <TokenHoldings
        forProtocolTreasury={forProtocolTreasury}
        identifier={identifier}
        rightPane={true}
        isCosmos={isCosmos}
      />
      <ProtocolHoldings
        forProtocolTreasury={forProtocolTreasury}
        identifier={identifier}
        rightPane={true}
      />
      <NFTHoldings identifier={identifier} rightPane={true} />
    </div>
  );
};

export default RightPanelPortfolio;
