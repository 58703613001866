import { useCallback, useEffect, useMemo, useState } from "react";
import classes from "./ScreenerResults.module.css";
import { Funnel } from "@phosphor-icons/react";
import ScreenerFiltersRightPanel from "./ScreenerFiltersRightPanel";
import { CustomRow } from "components/UI/Components/Components";
import { createPortal } from "react-dom";
import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import { updateQueryParams } from "utils/misc";
import { useHistory, useLocation } from "react-router-dom";

const ScreenerFilter = ({
  filterOnlyWallets,
  filterKnownNames,
  setFilterKnownNames,
  setFilterOnlyWallets,
}) => {
  const [isRightPaneOpen, setIsRightPaneOpen] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const initialFilters = useMemo(
    () => [
      {
        label: "Show only wallets",
        description: "By default shows all",
        value: filterOnlyWallets,
        pendingValue: filterOnlyWallets,
        setValue: setFilterOnlyWallets,
      },
      {
        label: "Show only names",
        description: "By default shows all",
        value: filterKnownNames,
        pendingValue: filterKnownNames,
        setValue: setFilterKnownNames,
      },
    ],
    [
      filterKnownNames,
      filterOnlyWallets,
      setFilterKnownNames,
      setFilterOnlyWallets,
    ]
  );

  const [appliedFilters, setAppliedFilters] = useState(initialFilters);

  const areFiltersApplied = useMemo(() => {
    return appliedFilters.some((filter) => filter.pendingValue);
  }, [appliedFilters]);

  const location = useLocation();
  const history = useHistory();

  const handleApplyChanges = useCallback(() => {
    setIsLoading(true);
    setAppliedFilters((filters) => {
      const newFilters = [...filters];
      newFilters.forEach((filter) => {
        filter.value = filter.pendingValue;
      });
      return newFilters;
    });

    appliedFilters.forEach((filter, index) => {
      initialFilters[index].setValue(filter.pendingValue);
    });

    updateQueryParams({
      location,
      history,
      updates: {
        send_only_eoas: appliedFilters[0]?.pendingValue,
        filter_known_names: appliedFilters[1]?.pendingValue,
      },
    });
    // add timeout to display loader on click
    setTimeout(() => {
      setIsLoading(false);
      setIsRightPaneOpen(false);
    }, 500);
  }, [appliedFilters, location, history, initialFilters]);

  const handleToggle = useCallback((index, value) => {
    setAppliedFilters((filters) => {
      const newFilters = [...filters];
      newFilters.forEach((filter) => {
        filter.pendingValue = false;
      });
      newFilters[index].pendingValue = value;
      return newFilters;
    });
  }, []);

  const handleReset = useCallback(() => {
    setAppliedFilters((filters) => {
      const newFilters = [...filters];
      newFilters.forEach((filter) => {
        filter.pendingValue = false;
      });
      return newFilters;
    });
  }, []);

  const filterCount = appliedFilters.reduce((count, filter) => {
    return count + (filter.value ? 1 : 0);
  }, 0);

  useEffect(() => {
    if (!isRightPaneOpen) {
      setAppliedFilters((filters) =>
        filters.map((filter) => ({
          ...filter,
          pendingValue: filter.value,
        }))
      );
    }
  }, [isRightPaneOpen]);

  return (
    <div>
      <div className={classes.cursorDiv}>
        <CustomRow gap="4px" alignItems="center">
          <Funnel size={15} color="var(--text-2)" />
          <span
            className={classes.editColumnsText}
            onClick={() => {
              setIsRightPaneOpen(true);
            }}>
            Filters
          </span>

          {!!filterCount && (
            <button className={classes.filterBadge}>{filterCount}</button>
          )}
        </CustomRow>
      </div>
      {createPortal(
        <RightPanel onClose={() => setIsRightPaneOpen(false)}>
          {isRightPaneOpen && (
            <ScreenerFiltersRightPanel
              onClose={() => setIsRightPaneOpen(false)}
              filters={appliedFilters}
              handleToggle={handleToggle}
              handleApplyChanges={handleApplyChanges}
              handleReset={handleReset}
              areFiltersApplied={areFiltersApplied}
              isLoading={isLoading}
            />
          )}
        </RightPanel>,
        document.getElementById("rightPanel")
      )}
    </div>
  );
};

export default ScreenerFilter;
