import SearchBarMini from "components/V2/Messaging/components/Search/SearchBarMini";
import { CustomRow, CustomText } from "components/UI/Components/Components";
import Image from "components/UI/Image";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import { useChatContext } from "stream-chat-react";
import CreateGroupButton from "../CreateGroupButton/CreateGroupButton";
import React from "react";

const ChatSearch = ({
  onSelect,
  type,
  placeholder = "Search",
  hideOutline = true,
  size = "medium",
  onlyUsersWhoFollowMe = false,
}) => {
  const { setActiveChannel } = useChatContext();

  if (type === "groups") {
    return (
      <>
        <SearchBarMini
          placeholder={placeholder + " groups"}
          searchQueryKey="search-messaging-groups"
          useChatClient={true}
          SearchItemComponent={({ result, setIsSearchInputFocused }) => {
            const profile = result?.data;
            return (
              <CustomRow
                gap="6px"
                padding="8px 24px"
                cursor="pointer"
                alignItems="center"
                className="chatSearchItem"
                onClick={() => {
                  // onSelect(profile);
                  setActiveChannel(result);
                  setIsSearchInputFocused(false);
                }}>
                <Image
                  className="actorAvatar"
                  src={profile?.image}
                  alt={profile?.name}
                />
                <CustomText fontSize="13px" text={profile?.name} />
              </CustomRow>
            );
          }}
        />
        <CreateGroupButton />
      </>
    );
  } else {
    return (
      <SearchBarMini
        placeholder={placeholder + " users"}
        source={
          "identity flag:users_only flag:skip_me" +
          (onlyUsersWhoFollowMe ? " flag:follows_me" : "")
        }
        searchQueryKey="search-messaging-users"
        hideOutline={hideOutline}
        size={size}
        SearchItemComponent={({ result, setIsSearchInputFocused }) => {
          const profile = result?.data;
          return (
            <CustomRow
              gap="6px"
              padding="8px 24px"
              cursor="pointer"
              alignItems="center"
              className="chatSearchItem"
              onClick={() => {
                onSelect(profile);
                setIsSearchInputFocused(false);
              }}>
              <Image
                className="actorAvatar"
                src={profile.display_picture}
                alt={profile.display_name}
              />
              <CustomText fontSize="13px" text={profile.display_name} />
              <VerifiedBadge profile={profile} />
            </CustomRow>
          );
        }}
      />
    );
  }
};

export default ChatSearch;
