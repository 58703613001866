import { useEffect, useRef, useCallback } from "react";

const observeElements = (observer) => {
  const elements = document.querySelectorAll(".feed-activity");
  elements.forEach((element) => observer.observe(element));
  if (elements.length === 0) {
    setTimeout(() => {
      observeElements(observer);
    }, 1000);
  }
};

const unObserveElements = (observer) => {
  const elements = document.querySelectorAll(".feed-activity");
  elements.forEach((element) => observer.unobserve(element));
};

let trackedItems = {};

const addToTrackingMemo = (itemId) => {
  const item = trackedItems[itemId];
  if (item) {
    item.endTime = new Date().getTime() - item.startTime;
    item.processed = false;
  } else {
    trackedItems[itemId] = {
      startTime: new Date().getTime(),
    };
  }
};
const updateTimeForItem = (itemId) => {
  const item = trackedItems[itemId];
  if (!item) return;
  const { startTime } = item;
  if (item && startTime && !item.processed) {
    item.endTime = new Date().getTime() - startTime;
    item.processed = true;
    return (item.endTime = new Date().getTime() - startTime);
  }

  return;
};

/**
 * Custom hook to track visibility of elements and batch API calls.
 * @param {function} apiCall - Function to call the API to record visibility
 * @returns {void}
 */
function useVisibilityTracker(apiCall) {
  const timerRef = useRef(null);

  // Function to batch and send API calls
  const sendBatch = useCallback(() => {
    const itemsToSend = trackedItems;
    if (Object.keys(itemsToSend).length > 0) {
      const params = [];
      const newItems = {};
      Object.keys(itemsToSend).forEach((itemId) => {
        if (itemsToSend[itemId]) {
          const type = itemId.split("-")[1];
          const id = itemId.split("-")[0];
          const finalTime =
            itemsToSend[itemId].endTime ||
            new Date().getTime() - itemsToSend[itemId].startTime;
          params.push({
            time_spent: finalTime,
            type,
            id,
          });
          if (!itemsToSend[itemId].processed) {
            newItems[itemId] = {
              ...itemsToSend[itemId],
              processed: false,
              endTime: null,
              startTime: new Date().getTime(),
            };
          }
        }
      });
      trackedItems = newItems;
      if (params.length > 0 && process.env.NODE_ENV !== "development") {
        apiCall(params);
      }
    }
  }, [apiCall]);

  useEffect(() => {
    // Set up the timer to send batches every 5 seconds
    timerRef.current = setInterval(sendBatch, 5000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [sendBatch]);

  const handleVisibilityChange = useCallback((entry) => {
    const itemId = entry.target.getAttribute("data-id");
    const isItemBiggerthan80VH =
      entry.boundingClientRect.height > window.innerHeight * 0.8;
    if (entry.isIntersecting || isItemBiggerthan80VH) {
      addToTrackingMemo(itemId);
    }
    if (!entry.isIntersecting && !isItemBiggerthan80VH) {
      updateTimeForItem(itemId);
    }
  }, []);

  useEffect(() => {
    let timeOutId = null;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(handleVisibilityChange);
      },
      { threshold: 0.75 } // Adjust threshold as needed
    );
    timeOutId = setTimeout(() => {
      observeElements(observer);
    }, 1000);
    return () => {
      clearTimeout(timeOutId);
      unObserveElements(observer);
    };
  });
}

export default useVisibilityTracker;
