import React, { useCallback, useContext, useEffect, useState } from "react";

import "./MessagingChannelList.css";
import { SkeletonLoader } from "./SkeletonLoader";
import { generateDMId, isInviteNeededForUser } from "api/messaging.api";
import { AuthContext } from "contextStates/AuthContext";
import { useChatContext } from "stream-chat-react";
import { useParams } from "react-router-dom";
import { getProfileData } from "api/profile.api";
import ChatSearch from "../Search/ChatSearch";
import { ChannelTypes } from "../../helpers";
import { MessagingContext } from "contextStates/MessagingContext";
import { useQuery } from "@tanstack/react-query";
import { ONE_DAY } from "utils/constants";

const MessagingChannelList = ({ children, error = false, loading, type }) => {
  const { identityDetails } = useContext(AuthContext);
  const { updateUserLoading } = useContext(MessagingContext);
  const { client, setActiveChannel } = useChatContext();
  const { channel_id } = useParams();
  const [loadingChannel, setLoadingChannel] = useState(true);
  const { data: profileData, isLoading } = useQuery({
    queryKey: ["messaging_profile", { identifier: channel_id }],
    queryFn: () => getProfileData({ identifier: channel_id }),
    enabled: !!channel_id && Number.isInteger(parseInt(channel_id)),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: ONE_DAY,
  });
  const initiateDM = useCallback(
    async (profile) => {
      const dmId = await generateDMId({ otherIdentity: profile.id });
      const shoudInvite = await isInviteNeededForUser({ userId: profile.id });
      let channel;
      if (shoudInvite) {
        channel = client.channel("messaging", dmId?.id, {
          channel_type: ChannelTypes.DM,
          members: [
            profile.stream_chat_details.id,
            identityDetails.current.identity.stream_chat_details.id,
          ],
          invites: [profile.stream_chat_details.id],
        });
      } else {
        channel = client.channel("messaging", dmId?.id, {
          channel_type: ChannelTypes.DM,
          members: [
            profile.stream_chat_details.id,
            identityDetails.current.identity.stream_chat_details.id,
          ],
        });
      }
      await channel.watch();
      setActiveChannel(channel);
    },
    [client, setActiveChannel, identityDetails]
  );
  useEffect(() => {
    const initiateDMWithProfile = async () => {
      try {
        await initiateDM(profileData?.data?.data?.details);
      } catch (err) {
      } finally {
        setLoadingChannel(false);
        updateUserLoading(false);
      }
    };
    if (isLoading) {
      updateUserLoading(true);
    }
    if (profileData) {
      initiateDMWithProfile();
    }
  }, [isLoading, profileData, initiateDM, updateUserLoading]);
  useEffect(() => {
    const initiateDMWithProfile = async () => {
      // check if channel_id is a profile id
      if (!Number.isInteger(parseInt(channel_id))) {
        const channel = await client.channel("messaging", channel_id);
        if (channel) {
          await channel.watch();
          setActiveChannel(channel);
          setLoadingChannel(false);
        }
      }
    };
    if (channel_id) {
      initiateDMWithProfile();
    } else {
      setLoadingChannel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div>
        <ChatSearch onSelect={initiateDM} type={type} />
      </div>

      {error && (
        <div className="messaging__channel-list__message">
          Error loading conversations, please try again momentarily.
        </div>
      )}
      <div className="messaging__channel-list">
        {loading || loadingChannel ? (
          <div className="messaging__channel-list__message">
            <SkeletonLoader />
          </div>
        ) : (
          children
        )}
      </div>
    </>
  );
};

export default React.memo(MessagingChannelList);
