import React, { useEffect, useState } from "react";
import NftPageAvatar from "../NftPageAvatar/NftPageAvatar";
import {
  CustomColumn,
  CustomRow,
  Divider,
  PaddingComponent,
  PriceChange,
  SpinnerComponent,
} from "components/UI/Components/Components";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import WatchlistTokenButton from "components/V2/Watchlist/WatchlistTokenButton";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { NftKeyValue } from "../CollectionsPage/CollectionsPage.utils";
import TrendingTokenChart from "components/TrendingActivities/TrendingTokenChart";
import { abbreviateNumber } from "utils/misc";
import styles from "./ZxNftHeader.module.scss";
import ZxModal from "zxComponents/common/ZxModal/ZxModal";
import ZxCommonChart from "zxComponents/common/ZxCommonChart/ZxCommonChart";
import { useGetCollectionFloorPriceChart } from "api/nft.api";
import { CaretRight } from "@phosphor-icons/react";
const EXTRA_INFO = [
  {
    label: "Chain",
    value: (data) => data.contract_details?.[0]?.chain_id,
  },
  {
    label: "Created",
    value: (data) => {
      const date = new Date(data.contract_details?.[0]?.created_at * 1000);
      if (date.toString() === "Invalid Date") return "-";
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();
      return `${month} ${year}`;
    },
  },
];

const INFO_ITEMS = [
  {
    label: "Top bid",
    value: (data) => data.top_bid?.token_value?.display_value ?? "-",
  },
  {
    label: "24h Volume",
    value: (data) => data.volume_details?.volume?.display_value ?? "-",
  },
  {
    label: "Holders (Unique)",
    value: (data) => data?.distinct_owner_count ?? "-",
  },
  {
    label: "Supply",
    value: (data) => data?.distinct_nft_count ?? "-",
  },
];

const GRAPH_DURATIONS = {
  DURATION: {
    "1D": "1D",
    "1W": "1W",
    "1M": "1M",
    "3M": "3M",
    "6M": "6M",
  },
  DURATION_DAYS: {
    "1D": 1,
    "1W": 7,
    "1M": 30,
    "3M": 90,
    "6M": 180,
  },
};

const ZxNftHeader = ({ details, collectionId, collection }) => {
  const [isGraphVisible, setIsGraphVisible] = React.useState(false);
  const [graphDuration, setGraphDuration] = React.useState(
    GRAPH_DURATIONS.DURATION_DAYS["1D"]
  );
  const { display_picture, name, external_links, description } = details;
  const { floor_price, marketplace_links } = collection ?? {};
  const [initialGraphData, setInitialGraphData] = useState(
    collection?.price_chart ?? []
  );
  const [initialPriceChange, setInitialPriceChange] = useState(
    collection?.["price_change_24h"] ?? {
      display_value: null,
      value: null,
      direction: 0,
    }
  );
  useEffect(() => {
    if (collection?.price_chart) {
      setInitialGraphData(collection?.price_chart);
    }
    if (collection?.["price_change_24h"]) {
      setInitialPriceChange(
        collection?.["price_change_24h"] ?? {
          display_value: null,
          value: null,
          direction: 0,
        }
      );
    }
  }, [collection]);
  const {
    data: graphData,
    isLoading: isGraphDataLoading,
    isFetching: isGraphDataFetching,
    isError,
  } = useGetCollectionFloorPriceChart({
    collectionId,
    duration: graphDuration,
    initialData: {
      price_history: initialGraphData,
      price_change: initialPriceChange,
    },
  });
  const { price_change: priceChange } = graphData;
  const floorPrice = floor_price?.token_value?.display_value ?? "";
  const nativeSymbol = floor_price?.token_symbol;
  const nativeChainLogo = floor_price?.token_logo_url ?? null;
  const renderFloorPriceInfo = () => {
    return (
      <CustomColumn gap="8px">
        <ZxText style={TSTYLES.title1} fontSize={12} color="text-2">
          Floor Price
        </ZxText>
        <CustomRow alignItems="center" gap="12px">
          <Image
            src={nativeChainLogo}
            width={20}
            height={20}
            imageType={IMAGE_TYPES.TOKEN}
          />
          <ZxText style={TSTYLES.title1} fontSize={16}>
            {floorPrice}
          </ZxText>
          {isGraphDataFetching ? (
            <SpinnerComponent color="var(--text-2)" size={20} />
          ) : (
            <PriceChange
              value={priceChange?.value}
              displayValue={priceChange?.display_value}
              direction={priceChange?.direction}
            />
          )}
        </CustomRow>
      </CustomColumn>
    );
  };
  return (
    <CustomColumn gap="12px">
      <CustomRow justifyContent="space-between" alignItems="flex-end">
        <NftPageAvatar src={display_picture} />
        <CustomRow gap="8px">
          <ExternalLinks links={{ ...external_links, ...marketplace_links }} />
          <WatchlistTokenButton
            iconWrapper
            isNft
            collectionId={collectionId}
            isWatchlisted={collection?.is_watchlisted}
          />
        </CustomRow>
      </CustomRow>
      <CustomColumn gap="16px">
        <ZxText style={TSTYLES.title1} fontSize={20}>
          {name}
        </ZxText>
        <ZxText style={TSTYLES.body1} fontSize={14} lineHeight={2}>
          {description}
        </ZxText>
        <Divider />
      </CustomColumn>
      <PaddingComponent padding="8px 0 8px 0">
        <CustomColumn
          alignItems="flex-start"
          justifyContent="space-between"
          gap="24px">
          <CustomRow gap="8px">
            {details &&
              EXTRA_INFO.map((info, idx) => {
                return (
                  <NftKeyValue
                    key={idx}
                    label={info.label}
                    value={info.value(collection)}
                    showSeparator={idx !== EXTRA_INFO.length - 1}
                  />
                );
              })}
          </CustomRow>
          <div
            onClick={() => setIsGraphVisible(true)}
            className={styles.graphContainer}
            style={{ cursor: "pointer", marginBottom: "16px" }}>
            <CustomColumn gap="8px" className={styles.floorPriceContainer}>
              <CustomRow alignItems="flex-start" gap="4px">
                <Image
                  src={nativeChainLogo}
                  width={20}
                  height={20}
                  imageType={IMAGE_TYPES.TOKEN}
                />
                <CustomRow
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%">
                  <CustomColumn>
                    <CustomRow gap="4px" alignItems="center">
                      <ZxText style={TSTYLES.title2} fontSize={18}>
                        {floorPrice}
                      </ZxText>
                      <PriceChange
                        value={initialPriceChange?.value}
                        displayValue={initialPriceChange?.display_value}
                        direction={initialPriceChange?.direction}
                      />
                    </CustomRow>
                    <ZxText style={TSTYLES.body1} fontSize={12} color="text-2">
                      Floor Price
                    </ZxText>
                  </CustomColumn>
                  <CaretRight size={16} color={"var(--text-2)"} />
                </CustomRow>
              </CustomRow>
              <PaddingComponent width="400px">
                <TrendingTokenChart
                  graphData={initialGraphData}
                  width={400}
                  height={48}
                />
              </PaddingComponent>
            </CustomColumn>
            <div className={styles.infoPills}>
              {INFO_ITEMS.map((info, idx) => {
                return (
                  <CustomColumn key={idx} gap="4px" className={styles.infoPill}>
                    <ZxText style={TSTYLES.title1} fontSize="15px">
                      {info.value(collection)}
                    </ZxText>
                    <ZxText style={TSTYLES.body1} color="text-2">
                      {info.label}
                    </ZxText>
                  </CustomColumn>
                );
              })}
            </div>
          </div>
        </CustomColumn>
      </PaddingComponent>

      <ZxModal
        width="820px"
        title={`${name}`}
        isOpen={isGraphVisible}
        onClose={() => setIsGraphVisible(false)}>
        <ZxCommonChart
          isLoading={isGraphDataLoading || isGraphDataFetching}
          isError={isError}
          currentDuration={graphDuration}
          setDuration={setGraphDuration}
          width={780}
          height={280}
          leftHeader={renderFloorPriceInfo}
          graphData={graphData?.price_history?.map((p) => [p[0] * 1000, p[1]])}
          durations={GRAPH_DURATIONS}
          formatter={(val) => {
            return `${abbreviateNumber(val)} ${nativeSymbol}`;
          }}
        />
      </ZxModal>
    </CustomColumn>
  );
};

export default ZxNftHeader;
