import {
  BuySellActionText,
  CustomRow,
  CustomText,
  InfoBoxContainer,
  PaddingComponent,
} from "components/UI/Components/Components";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { getTrimmedString, referenceTimeAgo } from "utils/misc";
import { Link } from "react-router-dom";

import styles from "./TokenSwapCard.module.css";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";

const TokenSwapCard = ({ swap, symbol, txLink }) => {
  if (!swap || !swap.actor || !swap.token_details) return null;
  const displayPicture = swap.actor.display_picture;
  const displayName = swap.actor.display_name;
  const swapAmount = swap.token_details.amount?.display_value ?? null;
  const swapValue =
    swap.token_details?.current_usd_value?.display_value ?? null;
  const action = swap.token_details.action;
  const { timestamp } = swap;
  const timeDisplay = referenceTimeAgo({
    date: timestamp * 1000,
    referenceTime: new Date(),
  });
  return (
    <Link to={txLink}>
      <InfoBoxContainer
        className={styles["card-swap"]}
        padding="20px 16px"
        background="var(--elevation-1)">
        <PaddingComponent className={styles["swap-content"]}>
          <Image
            src={displayPicture}
            imageType={IMAGE_TYPES.AVATAR}
            className={styles["swap-avatar"]}
            alt={displayName}
            name={displayName}
          />
          <CustomRow alignItems="center" gap="4px">
            <CustomText
              text={getTrimmedString(displayName, 12)}
              fontWeight="500"
              fontSize="13px"
              lineHeight="19.5px"
              color={"var(--text-1)"}
              className={styles["swap-actor-name"]}
            />
            <VerifiedBadge profile={swap.actor} />
          </CustomRow>

          <BuySellActionText
            action={action}
            className={styles["swap-action"]}
          />
          {swapAmount ? (
            <div className={styles["swap-token-info"]}>
              <CustomText
                color="var(--text-1)"
                lineHeight="19.5px"
                fontWeight="400"
                fontSize="13px"
                text={`${getTrimmedString(symbol, 8)} ${swapAmount}`}
              />
            </div>
          ) : (
            <PaddingComponent height="20px" />
          )}
          {swapValue ? (
            <CustomRow
              gap="4px"
              alignItems="flex-end"
              justifyContent="flex-start">
              <CustomText
                text="Value"
                color="var(--text-2)"
                fontSize="11px"
                lineHeight="14px"
              />
              <CustomText
                text={`${swapValue}`}
                color="var(--text-1)"
                fontSize="13px"
                lineHeight="17px"
              />
            </CustomRow>
          ) : (
            <PaddingComponent height="18px" />
          )}
          <CustomText
            text={`${timeDisplay} ${timeDisplay === "just now" ? "" : "ago"}`}
            color="var(--text-3)"
            fontWeight="400"
            fontSize="11px"
            lineHeight="16.5px"
            className={styles["swap-time"]}
          />
        </PaddingComponent>
      </InfoBoxContainer>
    </Link>
  );
};

export default TokenSwapCard;
