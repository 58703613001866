import React from "react";
import { ReactComponent as SignatureIcon } from "assets/svg/feed/signature_icon.svg";
import classes from "./gnosisSigners.module.css";
import PersonProfile from "components/UI/PersonProfile/PersonProfile";
import {
  CustomColumn,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";

const GnosisSigners = ({
  signers,
  multisigs,
  profiles,
  isTransaction = false,
  isUpperBorder = true,
  isBottomBorder = true,
}) => {
  if (signers?.length === 0 || profiles == null) {
    return null;
  }
  return (
    <CustomColumn key={`gnosis-signers`}>
      {isUpperBorder && <Divider />}
      <div
        className={`${classes.signature} ${
          isTransaction ? classes.isTransaction : ""
        }`}
        onClick={(e) => e.stopPropagation()}>
        <div className={classes.signatureIcon}>
          <SignatureIcon />
        </div>
        <div className={classes.content}>
          <div className={classes.label}>
            {isTransaction ? "Signed by" : "Includes txns signed by"}{" "}
          </div>
          <GnosisActorsNames actors={signers} profiles={profiles} />
          {multisigs?.length > 0 && (
            <>
              <div className={classes.label}>on behalf of</div>
              <GnosisActorsNames actors={multisigs} profiles={profiles} />
            </>
          )}
        </div>
      </div>
      {isBottomBorder && <PaddingComponent height="8px" />}
      {isBottomBorder && <Divider />}
    </CustomColumn>
  );
};

const GnosisActorsNames = ({ actors, profiles }) => {
  return (
    <div className={classes.actorNamesWrapper}>
      {actors.slice(0, 3).map((address, index) => (
        <span
          className={classes.nameWrapper}
          key={`gnosis-profile-${address}-${index}`}>
          <PersonProfile
            profile={profiles[address]}
            key={`gnosis-signer-name-${profiles[address]?.display_name}-${index}`}
          />
          {actors?.length > 1 &&
            index + 1 !== actors.slice(0, 3).length &&
            ", "}
        </span>
      ))}
      {actors?.length > 3 && `+ ${actors?.length - 3} more`}
    </div>
  );
};

export default GnosisSigners;
