import { ClickAwayListener } from "@mui/material";
import { useContext, useState } from "react";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import buttonClasses from "components/UI/Buttons/buttons.module.css";
import {
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import { CustomText } from "components/UI/Components/Components";
import { CircularProgress } from "@mui/material";
import { deleteWalletGroup } from "api/profile.api";
import { GlobalContext } from "contextStates/Global";
import { Trash } from "@phosphor-icons/react";

const DeleteBundle = ({ group, onSuccess, onlyIcon }) => {
  const [showPopUpCard, setShowPopUpCard] = useState(false);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowPopUpCard(false);
      }}>
      <div>
        <PPLxHoverCard
          open={showPopUpCard}
          content={
            <WalletBundleForm
              group={group}
              onSuccess={onSuccess}
              onClose={() => {
                setShowPopUpCard(false);
              }}
            />
          }
          placement="bottom">
          <button
            onClick={() => {
              setShowPopUpCard(true);
            }}
            className={
              onlyIcon
                ? buttonClasses.transparentButton
                : `${buttonClasses.errorButton} ${buttonClasses.errorRounded}`
            }>
            {onlyIcon ? (
              <Trash width={16} height={16} color="var(--text-2)" />
            ) : (
              "Delete Group"
            )}
          </button>
        </PPLxHoverCard>
      </div>
    </ClickAwayListener>
  );
};

export const WalletBundleForm = ({ group, onSuccess, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleErrorSnackbar } = useContext(GlobalContext);

  const onDeleteClick = () => {
    setIsLoading(true);

    deleteWalletGroup({ groupId: group.id })
      .then((res) => {
        onSuccess();
      })
      .catch((e) => {
        handleErrorSnackbar(e);
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  };

  return (
    <PaddingComponent padding="20px" width="300px">
      <PaddingComponent padding="0 0 8px 0">
        <CustomText
          text={`Delete group ${group?.group_name}?`}
          color="var(--text-1)"
          fontSize="13px"
          fontWeight="600"
        />
      </PaddingComponent>
      <CustomRow gap="16px" padding="8px 0 8px 0">
        <button onClick={onClose} className={buttonClasses.cancelButton}>
          <span>Cancel</span>
        </button>
        <button
          onClick={onDeleteClick}
          disabled={isLoading}
          className={buttonClasses.errorButton}>
          {isLoading ? (
            <div>
              <CircularProgress color="inherit" size={12} />
            </div>
          ) : (
            "Delete"
          )}
        </button>
      </CustomRow>
    </PaddingComponent>
  );
};

export default DeleteBundle;
