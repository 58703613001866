import React from "react";

import {
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import CollectionCard from "./CollectionCard";
import { useHistory } from "react-router-dom";
import { uniqueId } from "lodash";

const ExploreCollectionsTab = ({ collections }) => {
  const history = useHistory();

  if (collections?.length === 0) {
    return null;
  }
  return (
    <div>
      <PaddingComponent height="16px" />
      <CustomRow gap="16px" flexWrap="wrap">
        {collections?.map((item) => {
          return (
            <CollectionCard
              key={uniqueId()}
              imageUrl={item?.image_url}
              name={item?.name}
              onClick={() => {
                history.push(`/collection/${item?.collection_id}`);
              }}
            />
          );
        })}
      </CustomRow>
    </div>
  );
};

export default ExploreCollectionsTab;
