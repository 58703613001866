import React from "react";
import styles from "./ZxImageStack.module.scss";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import ConditionalLink from "shared/ConditionalLink";
const ZxImageStack = ({
  pictures,
  customClass,
  width = "24px",
  height = "24px",
  showHover = false,
}) => {
  return (
    <div className={styles.picturesContainer}>
      {pictures.map((picture, index) => {
        if (showHover) {
          return (
            <ProfileHoverCard key={index} actor={picture}>
              <ConditionalLink to={picture?.link ?? null} style={{ height }}>
                <Image
                  key={index}
                  src={picture?.src}
                  imageType={IMAGE_TYPES.AVATAR}
                  name={picture?.name ?? ""}
                  alt={picture?.alt ?? ""}
                  style={{ zIndex: index, width, height }}
                  className={`${styles.userImage} ${
                    index !== 0 && styles.secondaryImages
                  }`}
                />
              </ConditionalLink>
            </ProfileHoverCard>
          );
        }
        return (
          <Image
            key={index}
            src={picture?.src}
            imageType={IMAGE_TYPES.AVATAR}
            name={picture?.name ?? ""}
            alt={picture?.alt ?? ""}
            style={{ zIndex: index, width, height }}
            className={`${styles.userImage} ${
              index !== 0 && styles.secondaryImages
            }`}
          />
        );
      })}
    </div>
  );
};

export default ZxImageStack;
