import { useHistory, useLocation } from "react-router-dom";
import {
  useEffect,
  useCallback,
  useMemo,
  useContext,
  createContext,
  useState,
} from "react";
import { GraphExplorer } from "./components/GraphExplorer";

import { computeUrl, nullParseInt } from "./utils/explorer_utils";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

export const GraphExplorerContext = createContext({ urlData: {} });

/**
 * Explorer wrapper that feeds initialState from url
 * @returns Explorer hooked to data from urls
 */
const NavigatableExplorer = () => {
  const { setTitle } = useContext(LeftSideHeaderContext);

  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  );

  useEffect(() => {
    setTitle("Explorer");
  }, [setTitle]);

  const urlData = useMemo(() => {
    const identifiers =
      queryParams.get("identifiers") || queryParams.get("identifier");
    const selectedChains = queryParams.get("chain_ids")?.split(",") || [];
    const buildIdentityNodes =
      queryParams.get("build_identity_nodes") !== "false";
    const start_ts = nullParseInt(queryParams.get("start_ts"));
    const end_ts = nullParseInt(queryParams.get("end_ts"));
    const tokenAddresses =
      queryParams.get("emitting_contract_addresses")?.split(",") || [];
    const showContracts = queryParams.get("show_contracts") === "true";
    const tokenTuples = (() => {
      try {
        return JSON.parse(queryParams.get("chain_id_addr_or_symbol_tuples"));
      } catch (e) {
        return [];
      }
    })();

    return {
      identifiers,
      selectedChains,
      buildIdentityNodes,
      start_ts,
      end_ts,
      tokenAddresses,
      showContracts,
      timeRange: [start_ts, end_ts],
      tokenTuples,
    };
  }, [queryParams]);

  const [isContractGraph, setIsContractGraph] = useState(urlData.showContracts);

  const updateUrl = useCallback(
    ({
      identifiers,
      isContractGraph,
      selectedActiveTokens,
      selectedActiveChains,
      timeRange,
      buildIdentityNodes,
      sliderTimeRange,
      tokenTuples,
      filterChains,
    }) => {
      const frontendShareablePath = computeUrl({
        identifiers,
        selectedActiveTokens,
        selectedActiveChains,
        timeRange,
        isContractGraph,
        buildIdentityNodes,
        sliderTimeRange,
        tokenTuples,
        filterChains,
      });
      const currentPath = history.location.pathname;

      if (currentPath !== `/explorer${frontendShareablePath}`) {
        history.replace(`/explorer${frontendShareablePath}`);
      }
    },
    [history]
  );

  return (
    <BaseWrapper mixpanelEvent="NavigateExplorer">
      <GraphExplorerContext.Provider
        value={{ urlData, isContractGraph, setIsContractGraph }}>
        <GraphExplorer
          queryKey={location.state?.queryKey}
          urlData={urlData}
          isInitialFromPrefetch={history.location.state?.initial}
          updateUrl={updateUrl}
        />
      </GraphExplorerContext.Provider>
    </BaseWrapper>
  );
};

export default NavigatableExplorer;
