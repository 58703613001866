import IconWithChain from "components/Pages/Screener/IconWithChain";
import {
  PaddingComponent,
  PriceChange,
} from "components/UI/Components/Components";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { abbreviateNumber, getTrimmedString } from "utils/misc";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import {
  ArrowDownRight,
  DotsThreeVertical,
} from "../../../../../../../node_modules/@phosphor-icons/react/dist/index";
import { IMAGE_TYPES } from "components/UI/Image";

export const getTokenHoldingsColumns = ({
  holdings,
  classes,
  chainProfiles,
  tradableTokensList,
  rightPane,
  isLoggedInUserProfile,
  open,
  handleOpen,
  handleClose,
  setIsSendPanelOpen,
  setCurrentTokenData,
}) => {
  return [
    {
      title: "TOKEN",
      width: "18%",
      align: "flex-start",
      render: (token) => {
        const { token_metadata, chain_id } = token;
        const { logo, symbol } = token_metadata;
        return (
          <ZxFlex>
            <IconWithChain
              src={logo}
              iconHeight="16px"
              iconWidth="16px"
              showImageFullHeightWidth
              chain={chain_id}
              chainHeight="8px"
              chainWidth="8px"
              imageType={IMAGE_TYPES.TOKEN}
            />
            <ZxText fontSize="14px">{getTrimmedString(symbol, 8)}</ZxText>
          </ZxFlex>
        );
      },
    },
    {
      title: "24H PRICE",
      width: "20%",
      align: "flex-end",
      render: (token) => {
        const { day_price_change_percent: change } = token;
        return (
          <ZxFlex justify="space-between">
            <ZxText tstyle={TSTYLES.bodyLabel} fontSize="15px">
              {token?.usd_price?.display_value}
            </ZxText>

            <ZxFlex style={{ minWidth: "40px" }}>
              {change ? (
                <PriceChange
                  value={change?.value}
                  displayValue={change?.display_value}
                  fontSize="11px"
                />
              ) : (
                <ZxFlex width="100%" justify="center">
                  <ZxText>-</ZxText>
                </ZxFlex>
              )}
            </ZxFlex>
          </ZxFlex>
        );
      },
    },
    {
      title: "AVERAGE",
      width: "15%",
      align: "flex-end",
      render: (token) => {
        return (
          <ZxText fontSize="14px">
            {token?.avg_buy_price?.display_value ?? "-"}
          </ZxText>
        );
      },
    },
    {
      title: "AMOUNT",
      width: "15%",
      align: "flex-end",
      render: (token) => {
        return (
          <ZxText fontSize="14px">
            {token?.token_balance?.display_value ?? "-"}{" "}
          </ZxText>
        );
      },
    },
    {
      title: "INVESTED",
      width: "15%",
      align: "flex-end",
      render: (token) => {
        return (
          <ZxText fontSize="14px">
            {token?.usd_value_invested?.display_value ?? "-"}{" "}
          </ZxText>
        );
      },
    },
    {
      title: "USD VALUE",
      width: "20%",
      align: "flex-end",
      render: (token) => {
        const { chain_id: chain, contract_address: address } = token;
        const valueInvested = token?.usd_value_invested?.value ?? null;
        const valueCurrent = token?.total_usd_value?.value ?? null;
        const canCalculatePnl = valueInvested && valueCurrent;
        const pnlDiff = valueCurrent - valueInvested;
        const pnlData = {
          value: pnlDiff <= 0 ? -1 : 1,
          percentage: `$${abbreviateNumber(
            parseFloat(Math.abs(pnlDiff).toFixed(2))
          )}`,
        };
        const isTokenSendable = chainProfiles?.[token?.chain_id]?.eth_chain_id;
        const isOpen = open && open[`${chain}_${address}`];
        return (
          <ZxFlex>
            <ZxText fontSize="14px">
              {token?.total_usd_value?.display_value ?? "-"}{" "}
            </ZxText>
            <ZxFlex style={{ minWidth: "50px" }}>
              {pnlData && canCalculatePnl && pnlData?.value ? (
                <PriceChange
                  value={pnlData.value}
                  displayValue={pnlData.percentage}
                  fontSize="11px"
                />
              ) : (
                <ZxFlex fontSize="14px" justify="center">
                  <ZxText>-</ZxText>
                </ZxFlex>
              )}
            </ZxFlex>
            {isLoggedInUserProfile && !rightPane && (
              <PaddingComponent className={classes.hoverCardAnchor}>
                <PPLxHoverCard
                  open={isOpen}
                  onClose={() => handleClose({ chain, address })}
                  onOpen={() => handleOpen({ chain, address })}
                  delay={0}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -14],
                          },
                        },
                      ],
                    },
                  }}
                  content={
                    <div className={classes.hoverCard}>
                      <div
                        className={classes.tokenActionsBar}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClose({ chain, address });
                        }}>
                        {
                          <PPLxTooltip
                            disableInteractive
                            title={
                              isTokenSendable
                                ? ""
                                : "Chain not supported right now"
                            }>
                            <button
                              className={`${classes.tokenActionButton} ${
                                !isTokenSendable ? classes.disabled : ""
                              }`}
                              onClick={() => {
                                if (isTokenSendable) {
                                  setIsSendPanelOpen(true);
                                  setCurrentTokenData(token);
                                }
                              }}>
                              <ArrowDownRight
                                size="18"
                                style={{ transform: "rotate(-90deg)" }}
                              />
                              <span>Send {token?.token_metadata?.symbol}</span>
                            </button>
                          </PPLxTooltip>
                        }
                      </div>
                    </div>
                  }>
                  <DotsThreeVertical size={18} color="var(--text-2)" />
                </PPLxHoverCard>
              </PaddingComponent>
            )}
          </ZxFlex>
        );
      },
    },
  ];
};
