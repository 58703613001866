import { getConnectedPlatforms } from "api/feed.api";
import { useQuery } from "@tanstack/react-query";
import { extractLinks } from "@draft-js-plugins/linkify";
import { flatMap, uniq } from "lodash";
import { AuthContext } from "contextStates/AuthContext";
import { useContext } from "react";

export const useFetchConnectPlatformsCrossPost = () => {
  const { isUserLoggedIn } = useContext(AuthContext);
  const {
    data: connectedPlatforms,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["cross-post-connect-platforms"],
    queryFn: getConnectedPlatforms,
    retry: 2,
    retryOnMount: false,
    staleTime: 1000 * 60 * 60,
    enabled: isUserLoggedIn,
  });

  return { connectedPlatforms, refetch, isFetching };
};

export const getBackendPostContent = (rawContentState) => {
  let text = "";
  // Loop through the blocks and handle mentions
  rawContentState.blocks.forEach((block) => {
    let blockText = "";
    // Iterate through each character in the block text
    let startIdx = 0;
    block.entityRanges.forEach(({ key, offset, length }) => {
      if (offset > 0 && offset > startIdx) {
        // Embed raw text in between two blocks
        blockText += block.text.substring(startIdx, offset);
      }
      startIdx = offset + length;
      const entity = rawContentState.entityMap[key];
      if (entity.type === "mention") {
        blockText += formatMention(entity);
      } else {
        // Unhandled entity type like emojis
        blockText += block.text.substring(offset, offset + length);
      }
    });

    // append end part of the string post the last mention/entity
    blockText += block.text.substring(startIdx);

    // Add a newline for multi-line posts
    blockText += " {{break_line}}";
    text += blockText;
  });
  return text;
};

export const formatMention = (entity) => {
  // Replace the mention with the desired format
  if (entity.data.mention.type === "address") {
    const address = entity.data.mention.data.address;
    return `{{address||${address}}}`;
  } else if (entity.data.mention.type === "identity") {
    const identityId = entity.data.mention.data.id;
    return `{{identity||${identityId}}}`;
  } else if (entity.data.mention.type === "token") {
    const address = entity.data.mention.data.address;
    return `{{address||${address}}}`;
  } else if (entity.data.mention.__loading) {
    // Just remove the accidentally selected component
    // TODO(Shaheer): Figure out a way to disable selecting of loading component on hitting enter
    return "";
  } else {
    // Unknown mention type, passthrough text
    const mentionText = entity.data.mention.name;
    return `@${mentionText}`;
  }
};

export const formatLinksInString = (links, text) => {
  if (links?.length === 0) {
    return text;
  }

  const conditionallyAddHttps = (link) => {
    return !link?.startsWith("http") ? `https://${link}` : link;
  };

  return links.reduce((acc, link) => {
    return acc.replaceAll(
      link,
      `{{link||${link}||${conditionallyAddHttps(link)}}}`
    );
  }, text);
};

export const getFlattenedUniqueUrlsFromRawLinks = (rawLinks) => {
  return uniq(flatMap(rawLinks, (rawLink) => rawLink.raw));
};

export const getFormattedPostContentFromEditorForBackend = ({ content }) => {
  const text = getBackendPostContent(content);
  const textForLinks = text.replaceAll("{{break_line}}", "\n");
  const links = extractLinks(textForLinks);
  const flattened = getFlattenedUniqueUrlsFromRawLinks(links);
  const result = formatLinksInString(flattened, text);
  return result;
};
