import React, { useEffect } from "react";
import ProfileWallets from "./ProfileWallets";
import BundleSuggestion from "./BundleSuggestion";
import RemovedByYou from "./RemovedByYou";
import { useGetProfileWallets } from "api/profile.api";
import classes from "./WalletsPage.module.css";
import { createPortal } from "react-dom";
import { useParams, useHistory, Switch, Route } from "react-router-dom";
import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import WalletDetails from "./WalletDetails/WalletDetails";
import OtherUserBundleDetails from "./WalletDetails/OtherUserBundle/OtherUserBundleDetails";
import { useContext } from "react";
import { GlobalContext } from "contextStates/Global";
import { AuthContext } from "contextStates/AuthContext";
import {
  ManageBundlesPanel,
  BundleDetailsPanel,
} from "./WalletBundles/ManageBundles";
import { useQuery } from "@tanstack/react-query";
import { getReceivedConnectionRequests } from "api/profile.api";
import { PaddingComponent } from "components/UI/Components/Components";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { AddWalletPanel, EditWalletPanel } from "./AddWallet/AddWallet";
import Loader from "components/UI/Loader/Loader";
import GenericFallBackUI from "components/ErrorBoundary/GenericFallBackUI";

const WalletsPage = () => {
  const { identityDetails } = useContext(AuthContext);
  let { identifier } = useParams();

  const { channel } = useContext(GlobalContext);
  const history = useHistory();
  const query = useGetProfileWallets({ identifier });
  const { data: queryData, refetch, isLoading, isError } = query;
  const { data: connectionRequetsData } = useQuery({
    queryKey: ["connection_requests_received"],
    queryFn: getReceivedConnectionRequests,
    enabled: !!identityDetails?.current?.identity?.id,
  });
  const bundleData = queryData?.data?.data;
  useEffect(() => {
    const pusherEvent = bundleData?.pusher_key;
    if (pusherEvent) {
      channel?.unbind(pusherEvent);
      channel?.bind(pusherEvent, () => {
        refetch();
      });
    }
    return () => {
      if (pusherEvent) channel?.unbind(pusherEvent);
    };
  }, [channel, bundleData?.pusher_key, refetch]);
  if (isError) {
    return <GenericFallBackUI />;
  }
  if (isLoading || !queryData) return <Loader />;

  const onClose = () => {
    history.length > 2
      ? history.goBack()
      : history.push(`/${identifier}/wallets`);
  };

  return (
    <BaseWrapper mixpanelEvent="Profile/Wallets">
      <div className={classes.wallets}>
        <PaddingComponent padding="0 16px">
          <ProfileWallets
            refetch={refetch}
            bundleData={bundleData}
            pendingRequestsCount={connectionRequetsData?.details?.length}
          />
        </PaddingComponent>
        <PaddingComponent padding="0 16px">
          <BundleSuggestion
            identity_id={bundleData.id}
            refetch={refetch}
            identityBundleData={bundleData}
          />
        </PaddingComponent>
        <PaddingComponent padding="0 16px">
          <RemovedByYou bundleData={bundleData} refetch={refetch} />
        </PaddingComponent>
        <PaddingComponent height="25vh" />

        <Switch>
          <Route path={"/:identifier/wallets/manage-groups/:selectedGroupId"}>
            {createPortal(
              <RightPanel onClose={onClose}>
                <BundleDetailsPanel
                  bundleData={bundleData}
                  refetch={refetch}
                  onClose={onClose}
                />
              </RightPanel>,
              document.getElementById("rightPanel")
            )}
          </Route>
          <Route path={"/:identifier/wallets/manage-groups"}>
            {createPortal(
              <RightPanel onClose={onClose}>
                <ManageBundlesPanel bundleData={bundleData} refetch={refetch} />
              </RightPanel>,
              document.getElementById("rightPanel")
            )}
          </Route>
          <Route path={"/:identifier/wallets/add-wallet/:tab?"}>
            {createPortal(
              <RightPanel
                onClose={() => {
                  history.replace(`/${identifier}/wallets`);
                }}>
                <AddWalletPanel
                  bundleData={bundleData}
                  refetch={refetch}
                  loggedInUserDetails={identityDetails?.current?.identity}
                />
              </RightPanel>,
              document.getElementById("rightPanel")
            )}
          </Route>
          <Route path={"/:identifier/wallets/edit-wallet/:address"}>
            {createPortal(
              <RightPanel
                onClose={() => {
                  history.replace(`/${identifier}/wallets`);
                }}>
                <EditWalletPanel
                  bundleData={bundleData}
                  refetch={refetch}
                  loggedInUserDetails={identityDetails?.current?.identity}
                />
              </RightPanel>,
              document.getElementById("rightPanel")
            )}
          </Route>
          <Route path={"/:identifier/wallets/followers"} />
          <Route path={"/:identifier/wallets/following"} />
          <Route path={"/:identifier/wallets/:address/:bundle_identity_id?"}>
            {createPortal(
              <RightPanel onClose={onClose} overrideWidth="640px">
                <RightPanelComponent />
              </RightPanel>,
              document.getElementById("rightPanel")
            )}
          </Route>
        </Switch>
      </div>
    </BaseWrapper>
  );
};

const RightPanelComponent = () => {
  const { identifier, address, bundle_identity_id } = useParams();
  return bundle_identity_id ? (
    <OtherUserBundleDetails
      identifier={identifier}
      viewerPerspectiveId={bundle_identity_id}
    />
  ) : (
    <WalletDetails identifier={identifier} address={address} />
  );
};

export default WalletsPage;
