import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const useDuplicateHistoryBlocker = () => {
  const history = useHistory();
  useEffect(() => {
    history.block(
      (location, action) =>
        action !== "PUSH" ||
        getLocationId(location) !== getLocationId(history.location)
    );
  }, [history]);
};

const getLocationId = ({ pathname, search, hash }) =>
  pathname + (search ? "?" + search : "") + (hash ? "#" + hash : "");

export default useDuplicateHistoryBlocker;
