import React from "react";

const ZxFlex = ({
  dir = "row",
  gap = "4px",
  align = "center",
  justify = "flex-start",
  children,
  className = "",
  width = "auto",
  flex = "0 0 auto",
  alignSelf = "auto",
  justifySelf = "auto",
  cursor = null,
  ...rest
}) => {
  const cursorProps = cursor ? { cursor } : {};
  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: dir,
        gap,
        alignItems: align,
        justifyContent: justify,
        width,
        flex,
        alignSelf,
        justifySelf,
        ...cursorProps,
      }}
      {...rest}>
      {children}
    </div>
  );
};

export default ZxFlex;
