import ScreenerQuery from "./ScreenerQuery";
import classes from "./Screener.module.css";
import { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useScreenData } from "api/screener.api";
import GraphLoader from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";
import ScreenerResultsPage from "./ScreenerResults/ScreenerResultsPage";
import {
  CustomColumn,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import { Command } from "@phosphor-icons/react";
import RightPanel from "../AppModule/RightPanel/RightPanel";
import ExampleRightPanel from "./QueryExamples/ExampleRightPanel";
import { createPortal } from "react-dom";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { AuthContext } from "contextStates/AuthContext";

// Net worth on Ethereum > $100M
const DEFAULT_SESSION_ID = "2df54210-d112-48db-9b8c-49656c986dba";

const Screener = () => {
  const { setTitle } = useContext(LeftSideHeaderContext);
  const { isSuperuser } = useContext(AuthContext);
  const history = useHistory();
  useEffect(() => {
    setTitle("Screener");
  }, [setTitle]);
  useEffect(() => {
    if (!isSuperuser) {
      history.push("/home");
    }
  }, [isSuperuser, history]);

  const [showExamplesRightPanel, setShowExamplesRightPanel] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const nonDefaultSessionId = queryParams.get("session_id");
  const filterOutExchangeWallets =
    queryParams.get("filter_out_exchange_wallets") === "true";
  const filterKnownTwitters =
    queryParams.get("filter_known_twitters") === "true";
  const isDefaultSessionId = nonDefaultSessionId == null;
  const sessionId = nonDefaultSessionId ?? DEFAULT_SESSION_ID;
  const [queryData, setQueryData] = useState(null);
  const sendOnlyEoas = queryParams.get("send_only_eoas") === "true";
  const [filterOnlyWallets, setFilterOnlyWallets] = useState(sendOnlyEoas);
  const [filterKnownNames, setFilterKnownNames] = useState(
    queryParams.get("filter_known_names") === "true"
  );
  const { data, isLoading } = useScreenData({
    sessionId,
    filterOnlyWallets,
    filterKnownNames,
    filterOutExchangeWallets,
    filterKnownTwitters,
  });

  if (queryData) {
    return (
      <BaseWrapper mixpanelEvent="Screener">
        <CustomColumn>
          <ScreenerResultsPage
            setData={setQueryData}
            queryTokens={queryData.query_tokens}
            data={queryData}
            filterOnlyWallets={filterOnlyWallets}
            setFilterKnownNames={setFilterKnownNames}
            filterKnownNames={filterKnownNames}
            setFilterOnlyWallets={setFilterOnlyWallets}
          />
        </CustomColumn>
      </BaseWrapper>
    );
  }

  return (
    <BaseWrapper mixpanelEvent="Screener">
      <CustomColumn>
        {data?.data && !isDefaultSessionId ? (
          <ScreenerResultsPage
            setData={setQueryData}
            queryTokens={data.data.query_tokens}
            data={data.data}
            filterOnlyWallets={filterOnlyWallets}
            setFilterKnownNames={setFilterKnownNames}
            filterKnownNames={filterKnownNames}
            setFilterOnlyWallets={setFilterOnlyWallets}
          />
        ) : (
          <div
            className={classes.container}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}>
            <div className={classes.heading}>
              <h1 className={classes.title}>Create a Query</h1>
              <div className={classes.informationParent}>
                <div className={classes.information}>
                  Screener is used to do advanced search on users across chains.
                </div>
                <div
                  className={classes.commands}
                  onClick={(e) => {
                    setShowExamplesRightPanel(true);
                    e.stopPropagation();
                  }}>
                  <PaddingComponent paddingTop="2px" paddingRight="2px">
                    <Command size={16} />
                  </PaddingComponent>
                  <div>List of Available Commands</div>
                </div>
              </div>
              <PaddingComponent paddingBottom="12px" />
              <Divider />
            </div>
            <PaddingComponent paddingBottom="16px" />
            {isLoading && (
              <div>
                <GraphLoader />
              </div>
            )}
            {!isLoading && (
              <div>
                <ScreenerQuery source="mainPage" setData={setQueryData} />
              </div>
            )}
            {showExamplesRightPanel &&
              createPortal(
                <RightPanel
                  onClose={() => {
                    setShowExamplesRightPanel(false);
                  }}>
                  <ExampleRightPanel
                    onClose={() => {
                      setShowExamplesRightPanel(false);
                    }}
                  />
                </RightPanel>,
                document.getElementById("rightPanel")
              )}
          </div>
        )}
      </CustomColumn>
    </BaseWrapper>
  );
};

export default Screener;
