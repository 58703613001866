import React, { useState } from "react";
import styles from "./InviteCodeInput.module.css";
import { CustomColumn, CustomRow } from "components/UI/Components/Components";
import LoaderButton from "components/UI/LoaderButton/LoaderButton";
import { trackEvent } from "utils/event_tracking";
import { isValidInviteCode } from "api/waitlist.api";
import ZxText from "zxComponents/common/ZxText/ZxText";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { Info } from "@phosphor-icons/react";
const InviteCodeInput = ({ onClose, onSuccess }) => {
  const [inviteCode, setInviteCode] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const isDisabled = !inviteCode || inviteCode.length < 8;
  const handleSubmit = async () => {
    setIsError(false);
    trackEvent("onboarding_invite_code_submit");
    setIsButtonLoading(true);
    let code = inviteCode;
    // removing the code from the link if they have pasted the whole link
    if (inviteCode.includes("code=")) {
      code = inviteCode.split("code=")[1];
      setInviteCode(code);
    }
    try {
      const resp = await isValidInviteCode({ inviteCode: code });
      const isValid = resp.is_valid;
      if (isValid) {
        onSuccess(code);
      } else {
        setIsError(true);
      }
    } catch (err) {
      setIsError(true);
    } finally {
      setIsButtonLoading(false);
    }
  };
  return (
    <CustomColumn className={styles.container} gap="20px">
      <CustomColumn gap="4px">
        <input
          type="text"
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
          placeholder="Enter code here..."
          className={styles.input}
        />
        {isError && (
          <CustomRow gap="4px">
            <ZxText color="error">Invite code seems invalid!</ZxText>
            <PPLxTooltip
              arrow
              placement="top"
              title={
                <ul>
                  <li>Invite code format is PRAS-123456</li>
                  <li>
                    You can also paste the full link shared by your friend.
                  </li>
                </ul>
              }>
              <Info color="var(--text-2)" weight="fill" size={16} />
            </PPLxTooltip>
          </CustomRow>
        )}
      </CustomColumn>
      <LoaderButton
        onClick={handleSubmit}
        height={48}
        borderRadius={12}
        checkLogin={false}
        disabled={isDisabled}
        loadingText={"Verifying..."}
        className={`${styles.continueButton} ${
          isDisabled ? styles.disabled : ""
        }`}
        loading={isButtonLoading}>
        Submit
      </LoaderButton>
    </CustomColumn>
  );
};

export default InviteCodeInput;
