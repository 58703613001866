import { logo } from "utils/assets";
import { backendURL } from "api/api";

const postTypes = [
  "activity_group",
  "user_token_group",
  "sol_user_token_group",
  "sol_activity_group",
  "post",
  "lens",
  "farcaster",
  "trending",
  "welcome",
  "mirror",
  "snapshot",
  "proposal",
  "whale_alert",
  "screener_activity",
  "profile_created",
];
const chains = [
  "Ethereum",
  "Polygon",
  "BSC",
  "Avalanche",
  "Gnosis",
  "Heco",
  "Fantom",
  "Arbitrum",
  "Optimism",
  "Moonriver",
  "Cronos",
  "Aurora",
  "Moonbeam",
  "Boba",
  "EVMOS",
  "zkSync",
  "PolygonzkEVM",
  "Base",
  "HyperlaneTest",
  "MantaTest",
  "PanGu",
  "ZoraChain",
];
const chainTabRoutes = ["activities", "protocols", "overview"];

export const wcProjectId = "71eb1025659f4763befb6053c2bdb5ab";
export const WCMetadata = {
  name: "0xPPL",
  description: "Connect with 0xPPL",
  url: "www.0xppl.com/",
  icons: [logo],
};
export const mixpanelProjectToken = "9767498b11c1718d1052a6f285a8d323";

export const POST_TYPES = "(" + postTypes.join("|") + ")";
export const CHAINS = "(" + chains.join("|") + ")";
export const CHAIN_TAB_ROUTES = "(" + chainTabRoutes.join("|") + ")";
export const MEDIA_POST_TYPES =
  "(" + [...postTypes, "undefined"].join("|") + ")"; // To handle trending and other missing group type cases

export const ApiEndpoints = {
  API_URL: "https://api.github.com",
  GET_FOLLOWERS_LIST: "/api/0xppl/get_identity_followers",
  GET_FOLLOWING_LIST: "/api/0xppl/get_identity_following",
  UPDATE_NOTIFICATION_PREFERENCE: "api/0xppl/update_notification_preference",
  GET_FILTERS_DATA: "/api/v4/get_filters_data",
};

export const QueryKeyConstants = {
  MY_IDENTITY: "my_identity",
  PROFILE: "profile",
  MY_WALLETS: "my_wallets",
  TXN_TRACES: "txn_traces",
  TXN_TOKEN_TRANSFERS: "txn_token_transfers",
  ACTIVITY_BY_TXN_ID: "activity_by_txn_id",
  ACTIVITY_BY_ID: "activity_by_id",
  FOLLOWER_FOLLOWING_LIST: "followers_following_list",
  GROUPED_ACTIVITY: "groupedActivity",
  GROUPED_ACTIVITY_FROM_BLOB: "groupedActivityFromBlob",
  NEWS_DETAILS: "newsDetails",
  WELCOME_POST: "welcomePost",
  ENGAGEMENTS: "engagements",
  SLOW_FEED_ITEM: "slowFeedItem",
  COMMENTS: "comments",
};

export const Validation = {
  TWITTER_MAX_LENGTH: 260,
  FARCASTER_MAX_LENGTH: 260,
  LENS_MAX_LENGTH: 4000,
  POST_MAX_LENGTH: 4000,
  COMMENT_MAX_LENGTH: 4000,
  MIN_LENGTH: 1,
};

export const NoBackButtonPaths = ["/home"];
export const GIPHY_API_KEY = "5bmQ2nIPQ7s9eBTzTKn6PyuRaw6u0Z7s";

export const appDownloadLink = `${backendURL}/api/v4/hello`;

export const MobileOSTypes = {
  IOS: "ios",
  ANDROID: "android",
  UNKNOWN: "unknown",
};

export const COMMUNITY_TABS = {
  recommended: "Recommended",
  newOn0xPPL: "New on 0xPPL",
  network: "From Your Network",
  profiles: "Profiles",
};

export const Chart = {
  DURATION: {
    "1D": "1D",
    "7D": "7D",
    "1M": "1M",
    "3M": "3M",
    "1Y": "1Y",
    ALL: "ALL",
  },
  DURATION_DAYS: {
    "1D": 1,
    "7D": 7,
    "1M": 30,
    "3M": 90,
    "1Y": 365,
    ALL: "max",
  },
};

const TRENDING_PAGE_ROUTES = [
  "details",
  "holdings",
  "leaderboard",
  "liquidity",
  "discussion",
];

export const TRENDING_ROUTES = "(" + TRENDING_PAGE_ROUTES.join("|") + ")";

//change always second value for testing
const enableOnboardingTest = true; // change me to allow testing onboarding routes
export const isTestingOnboarding =
  process.env.NODE_ENV !== "production" ? enableOnboardingTest : false;

export const USER_PREFENCES = {
  MY_HOLDINGS_ACTIVE_TAB: "my-holdings-active-tab",
  CROSS_POST_SELECTED_PLATFORMS: "cross-post-selected-platforms",
  SHOW_NFT_MOVED_TO_PORTFOLIO_BANNER: "show-nft-moved-to-portfolio-banner",
};

export const TOP_USERS_TAB_KEYS = [
  {
    title: "Top Holders",
    key: "top-holders",
  },
  {
    title: "Top Sellers",
    key: "top-sellers",
  },
  {
    title: "Top Buyers",
    key: "top-buyers",
  },
];

export const TOP_USERS_TABS_LIST = TOP_USERS_TAB_KEYS.map((t) => t.title);

export const WATCHLIST_CATEGORIES = {
  TOKEN: 1,
  COIN: 2,
  NFT: 3,
};

export const WATCHLIST_SOURCES = {
  TOP_TOKENS: 2,
  USER_ADDED: 1,
  USER_SOLD: 3,
};

export const DYNAMIC_WALLET_ENVIRONMENT_ID =
  "76d2a1bf-27fe-417c-aeeb-4218502e11da";

export const NETWORTH_PREFERENCES = {
  Token: 1,
  Protocol: 2,
  NFT: 3,
};

export const DEFAULT_NETWORTH_PREFERENCES = [
  NETWORTH_PREFERENCES.Token,
  NETWORTH_PREFERENCES.Protocol,
];
export const CHART_RESOLUTIONS = ["1", "5", "15", "1h", "4h", "12h", "1D"];
export const CHART_RESOLUTIONS_MAP = [
  {
    resolution: "1",
    label: "1m",
  },
  {
    resolution: "5",
    label: "5m",
  },
  {
    resolution: "15",
    label: "15m",
  },
  {
    resolution: "1h",
    label: "1h",
  },
  {
    resolution: "4h",
    label: "4h",
  },
  {
    resolution: "12h",
    label: "12h",
  },
  {
    resolution: "1D",
    label: "1D",
  },
];

export const DOWNLOAD_APP_DEEPLINK = "https://zeroxppl.page.link/jkhx";

export const getResolutionFromString = (resolution) => {
  try {
    const suffix = resolution.slice(-1);
    if (isNaN(parseInt(suffix))) {
      switch (suffix) {
        case "h":
          return 60 * parseInt(resolution.slice(0, -1)) * 60;
        case "D":
          return 1440 * parseInt(resolution.slice(0, -1)) * 60;
        case "W":
          return 10080 * parseInt(resolution.slice(0, -1)) * 60;
        case "M":
          return 43200 * parseInt(resolution.slice(0, -1)) * 60;
        default:
          return 60;
      }
    } else {
      return parseInt(resolution) * 60;
    }
  } catch (err) {
    return 60;
  }
};

export const getBirdEyeResolution = (resolution) => {
  const minutes = ["1", "5", "15"];
  const hours = ["60", "240", "720", "1440"];
  if (minutes.includes(resolution)) {
    return `${resolution}m`;
  }
  if (hours.includes(resolution)) {
    return `${parseInt(resolution) / 60}H`;
  }
  return resolution;
};

export const ONE_DAY = 24 * 60 * 60 * 1000;

export const FEED_TABS = [
  {
    title: "All feed",
    link: "/home",
  },
  {
    title: "Posts",
    link: "?feed=posts",
  },
  {
    title: "Transactions",
    link: "?feed=transactions",
  },
  {
    title: "Trending",
    link: "?feed=trending",
  },
];

export const PRICE_DELTA_DURATIONS = {
  3600: {
    label: "1H",
    value: 1 * 60 * 60,
  },
  21600: {
    label: "6H",
    value: 6 * 60 * 60,
  },
  43200: {
    label: "12H",
    value: 12 * 60 * 60,
  },
  86400: {
    label: "24H",
    value: 24 * 60 * 60,
  },
};

export const MOBILE_ONBOARDING_THRESHOLD = 576;

export const NATIVE_SYMBOL_TO_ADDRESS_MAP = {
  Ethereum: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  BSC: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  Aurora: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  Arbitrum: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  Avalanche: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  Base: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  Fantom: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  Gnosis: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  Optimism: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  Polygon: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  Solana: "So11111111111111111111111111111111111111112",
};

export const CODEX_NETWORKS = [
  {
    id: 1088,
    name: "Metis",
  },
  {
    id: 5000,
    name: "Mantle",
  },
  {
    id: 8217,
    name: "Klaytn",
  },
  {
    id: 204,
    name: "opBNB",
  },
  {
    id: 888,
    name: "Wanchain",
  },
  {
    id: 109,
    name: "Shibarium",
  },
  {
    id: 40,
    name: "Telos",
  },
  {
    id: 80001,
    name: "Polygon Mumbai",
  },
  {
    id: 42220,
    name: "Celo",
  },
  {
    id: 9001,
    name: "Evmos",
  },
  {
    id: 84532,
    name: "Base Sepolia",
  },
  {
    id: 2000,
    name: "Dogechain",
  },
  {
    id: 66,
    name: "OEC",
  },
  {
    id: 1313161554,
    name: "Aurora",
  },
  {
    id: 383353,
    name: "CheeseChain",
  },
  {
    id: 106,
    name: "Velas",
  },
  {
    id: 169,
    name: "Manta",
  },
  {
    id: 1,
    name: "Ethereum",
  },
  {
    id: 11155111,
    name: "Ethereum Sepolia",
  },
  {
    id: 42170,
    name: "Arbitrum Nova",
  },
  {
    id: 88888,
    name: "Chiliz",
  },
  {
    id: 42262,
    name: "Oasis Emerald",
  },
  {
    id: 531,
    name: "Sei",
  },
  {
    id: 59144,
    name: "Linea",
  },
  {
    id: 39797,
    name: "Energi",
  },
  {
    id: 4321,
    name: "Echos",
  },
  {
    id: 2001,
    name: "Milkomeda",
  },
  {
    id: 168587773,
    name: "Blast Sepolia",
  },
  {
    id: 80085,
    name: "Berachain Artio",
  },
  {
    id: 80084,
    name: "Berachain bArtio",
  },
  {
    id: 80094,
    name: "Bera",
  },
  {
    id: 11124,
    name: "Abstract Testnet",
  },
  {
    id: 130,
    name: "Unichain",
  },
  {
    id: 534352,
    name: "Scroll",
  },
  {
    id: 324,
    name: "zkSync",
  },
  {
    id: 82,
    name: "Meter",
  },
  {
    id: 137,
    name: "Polygon",
  },
  {
    id: 8453,
    name: "Base",
  },
  {
    id: 24,
    name: "KardiaChain",
  },
  {
    id: 246,
    name: "Energy Web",
  },
  {
    id: 10000,
    name: "Smartbch",
  },
  {
    id: 3000,
    name: "Echelon",
  },
  {
    id: 250,
    name: "Fantom",
  },
  {
    id: 81457,
    name: "Blast",
  },
  {
    id: 1101,
    name: "Polygon zkEVM",
  },
  {
    id: 333000333,
    name: "MELD",
  },
  {
    id: 128,
    name: "Heco",
  },
  {
    id: 713715,
    name: "Sei Arctic",
  },
  {
    id: 5,
    name: "Goerli",
  },
  {
    id: 34443,
    name: "Mode",
  },
  {
    id: 333999,
    name: "Polis",
  },
  {
    id: 336,
    name: "Shiden",
  },
  {
    id: 4689,
    name: "IoTeX",
  },
  {
    id: 100,
    name: "xDai",
  },
  {
    id: 1284,
    name: "Moonbeam",
  },
  {
    id: 111188,
    name: "re.al",
  },
  {
    id: 728126428,
    name: "Tron",
  },
  {
    id: 1285,
    name: "Moonriver",
  },
  {
    id: 1992,
    name: "Sanko Sepolia",
  },
  {
    id: 666666666,
    name: "Degen Chain",
  },
  {
    id: 5112,
    name: "Ham",
  },
  {
    id: 1399811149,
    name: "Solana",
  },
  {
    id: 480,
    name: "World Chain",
  },
  {
    id: 55,
    name: "ZYX",
  },
  {
    id: 660279,
    name: "Xai",
  },
  {
    id: 56,
    name: "Binance Smart Chain",
    altName: "BSC",
  },
  {
    id: 70,
    name: "Hoo Smart Chain",
  },
  {
    id: 43114,
    name: "Avalanche",
  },
  {
    id: 288,
    name: "Boba",
  },
  {
    id: 20,
    name: "Elastos",
  },
  {
    id: 1666600000,
    name: "Harmony",
  },
  {
    id: 53935,
    name: "Avalanche DFK",
  },
  {
    id: 1116,
    name: "Core",
  },
  {
    id: 820,
    name: "Callisto",
  },
  {
    id: 1513,
    name: "Story Iliad",
  },
  {
    id: 1996,
    name: "Sanko",
  },
  {
    id: 369,
    name: "Pulsechain",
  },
  {
    id: 321,
    name: "KuCoin Community Chain",
  },
  {
    id: 42161,
    name: "Arbitrum",
  },
  {
    id: 25,
    name: "Cronos",
  },
  {
    id: 33139,
    name: "ApeChain",
  },
  {
    id: 592,
    name: "Astar",
  },
  {
    id: 10,
    name: "Optimism",
  },
  {
    id: 57,
    name: "Syscoin",
  },
  {
    id: 1030,
    name: "Conflux",
  },
  {
    id: 7700,
    name: "Canto",
  },
  {
    id: 122,
    name: "Fuse",
  },
];
