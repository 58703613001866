import { CustomRow } from "components/UI/Components/Components";
import React from "react";
import styles from "./ZxTabs.module.scss";
import { Tabs, Tab } from "@mui/material";
import ZxText from "../ZxText/ZxText";
const ZxTabs = ({
  tabs,
  onChange,
  activeTab,
  prefix = null,
  isPrimaryTabs = false,
}) => {
  if (isPrimaryTabs) {
    return (
      <Tabs
        value={activeTab}
        onChange={onChange}
        sx={{
          "& .MuiTab-root": {
            padding: "0",
            minHeight: "24px",
            marginBottom: "8px",
            marginRight: "24px",
          },
          "&.MuiTabs-root": {
            padding: "0",
            minHeight: "0",
          },
          "& .MuiTabs-indicator": {
            borderRadius: "3px 3px 0 0",
            height: "3px",
            backgroundColor: "var(--primary-color)",
          },
        }}>
        {tabs.map((t) => {
          if (t.render) {
            const isActive = t.key === activeTab;
            const tab = t.render({ isActive });
            return <Tab key={t.key} label={tab} value={t.key}></Tab>;
          }
          return (
            <Tab
              key={t.key}
              label={() => {
                return <ZxText>{t.key}</ZxText>;
              }}
              value={t.key}
            />
          );
        })}
      </Tabs>
    );
  }
  return (
    <CustomRow gap="12px">
      {tabs.map((t) => {
        const isSelected = t.key === activeTab;
        return (
          <button
            key={t.key}
            onClick={() => onChange(t.key)}
            className={`${styles.tab} ${isSelected ? styles.selected : ``}`}>
            {t.prefix ? t.prefix() : null}
            <p>{t.label}</p>
            {t.postfix ? t.postfix() : null}
          </button>
        );
      })}
    </CustomRow>
  );
};

export default ZxTabs;
