import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
  PaddingComponent,
} from "components/UI/Components/Components";

export const CollectionPageHeaderLoader = () => {
  return (
    <CustomColumn>
      <CustomSkeleton height="180px" variant="rectangular" borderRadius={0} />
      <div style={{ position: "relative" }}>
        <CustomRow
          justifyContent="space-between"
          style={{
            marginLeft: "16px",
            position: "absolute",
            bottom: "16px",
            width: "95%",
          }}
          alignItems="flex-end">
          <CustomSkeleton
            height="120px"
            width="120px"
            variant="rectangular"
            borderRadius={4}
          />
          <CustomRow alignItems="center" gap="8px">
            {[1, 2, 3, 4, 5].map((item) => (
              <CustomSkeleton
                key={item}
                height="32px"
                width="32px"
                variant="rectangular"
                borderRadius={2}
              />
            ))}
          </CustomRow>
        </CustomRow>
      </div>
      <CustomColumn style={{ marginTop: "0px", padding: "16px" }} gap="4px">
        <CustomSkeleton
          height="32px"
          width="200px"
          variant="rectangular"
          borderRadius={2}
        />
        <CustomSkeleton
          height="24px"
          width="80px"
          variant="rectangular"
          borderRadius={2}
        />
        <CustomSkeleton
          height="72px"
          width="280px"
          variant="rectangular"
          borderRadius={2}
        />
        <PaddingComponent paddingTop="12px">
          <CustomRow alignItems="center" gap="8px">
            {[1, 2, 3, 4].map((item) => (
              <CustomSkeleton
                key={item}
                height="80px"
                width="150px"
                variant="rectangular"
                borderRadius={2}
              />
            ))}
          </CustomRow>
        </PaddingComponent>
      </CustomColumn>
    </CustomColumn>
  );
};

export const NftListLoader = () => {
  return (
    <CustomRow gap="16px" flexWrap="wrap" justifyContent="space-between">
      {Array.from(Array(12).keys()).map((item) => (
        <CustomSkeleton
          height="172px"
          width="172px"
          variant="rectangular"
          borderRadius={2}
          key={item}
        />
      ))}
    </CustomRow>
  );
};

export const NftDetailsLoader = () => {
  return (
    <CustomColumn>
      <CustomRow gap="16px">
        <CustomSkeleton
          variant="rectangular"
          width="330px"
          height="330px"
          borderRadius={8}
        />
        <CustomColumn gap="16px">
          <CustomSkeleton
            variant="rectangular"
            width="300px"
            height="40px"
            borderRadius={2}
          />

          <CustomSkeleton
            variant="rectangular"
            width="300px"
            height="24px"
            borderRadius={2}
          />
          <CustomSkeleton
            variant="rectangular"
            width="300px"
            height="24px"
            borderRadius={2}
          />
          <CustomSkeleton
            variant="rectangular"
            width="300px"
            height="24px"
            borderRadius={2}
          />
          <CustomRow alignItems="center" gap="8px">
            {[1, 2, 3, 4, 5].map((item) => (
              <CustomSkeleton
                key={item}
                height="48px"
                width="48px"
                variant="rectangular"
                borderRadius={2}
              />
            ))}
          </CustomRow>
        </CustomColumn>
      </CustomRow>
    </CustomColumn>
  );
};

export const NftActivitiesLoader = () => {
  return (
    <CustomColumn gap="16px">
      {[1, 2, 3, 4, 5, 6, 7].map((item) => {
        return (
          <CustomColumn gap="8px" key={item}>
            <CustomRow gap="40px" alignItems="flex-end" key={item}>
              <CustomRow gap="16px">
                <CustomSkeleton
                  variant="rectangular"
                  width="40px"
                  height="40px"
                  borderRadius={2}
                />
                <CustomColumn gap="8px">
                  <CustomSkeleton
                    variant="rectangular"
                    width="100px"
                    height="20px"
                    borderRadius={1}
                  />
                  <CustomRow gap="8px">
                    <CustomSkeleton
                      variant="rectangular"
                      width="150px"
                      height="20px"
                      borderRadius={1}
                    />
                    <CustomSkeleton
                      variant="rectangular"
                      width="150px"
                      height="20px"
                      borderRadius={1}
                    />
                  </CustomRow>
                </CustomColumn>
              </CustomRow>
              <CustomSkeleton
                variant="rectangular"
                width="250px"
                height="20px"
                borderRadius={1}
              />
            </CustomRow>
          </CustomColumn>
        );
      })}
    </CustomColumn>
  );
};
