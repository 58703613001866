import PeopleItem from "./PeopleItem";
import styles from "./peopleList.module.css";
import ProfileItemGhost from "./ProfileItemGhost";

export const PeopleListGhost = () => {
  return (
    <div className={styles.people_list}>
      {Array.from({ length: 10 }).map((profile, index) => (
        <ProfileItemGhost key={index} />
      ))}
    </div>
  );
};

export const PeopleInteractedList = ({
  currentIndex,
  peopleList,
  profiles,
  nativeTokensInfo,
  onFollow,
}) => {
  if (currentIndex >= peopleList.length) return <></>;
  const profile = peopleList[currentIndex];
  return (
    <div className={styles.people_list_horizontal}>
      <PeopleItem
        onFollow={onFollow}
        isShowingFollowButton={isShowingFollowButton({
          profile,
        })}
        isShowingTxns={true}
        isShowingCheckBox={false}
        isShowingTransactionsSummary={true}
        profile={profile}
        profiles={profiles}
        isShowingCursor={false}
        nativeTokensInfo={nativeTokensInfo}
        key={`${
          profile?.address
        }-${currentIndex}-${"onboarding-people-interacted"}`}
      />
    </div>
  );
};

const PeopleList = ({
  peopleList,
  selectedIds,
  onSelected,
  onUnselected,
  isTwitterProfiles,
  profiles,
  nativeTokensInfo,
}) => {
  return (
    <div className={styles.people_list}>
      {peopleList.map((profile, index) => (
        //showing checbox when isPeopleInteractedWith is false that is twitter profiles and top profiles
        //showing follow button when isPeopleInteractedWith is true that is people interacted with
        <PeopleItem
          isShowingCheckBox={true}
          isShowingTransactionsSummary={false}
          isSelected={selectedIds.includes(profile?.id || profile?.address)}
          profile={profile}
          profiles={profiles}
          isShowingCursor={true}
          nativeTokensInfo={nativeTokensInfo}
          onSelected={onSelected}
          onUnselected={onUnselected}
          key={`${profile.id}-${index}-${
            isTwitterProfiles ? "twitter" : "chain"
          }`}
        />
      ))}
    </div>
  );
};

const isShowingFollowButton = ({ profile }) => {
  if (profile?.id != null) {
    return true;
  }
  return false;
};
export default PeopleList;
