import React, { useEffect } from "react";
import styles from "./WelcomePosts.module.scss";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import { useHistory } from "react-router-dom";
import {
  CustomColumn,
  CustomRow,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { getTrimmedString } from "utils/misc";
import { PostGeneratedComment } from "components/FeedItemV2/WelcomePostsList/WelcomePostActions";
import ActivityEngagements from "components/ActivityEngagements/ActivityEngagements";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import welcomeAnimation from "assets/animations/welcome_post_empty_animation.json";
import ConditionalLink from "shared/ConditionalLink";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import { getEngagementsQueryKey } from "components/ActivityEngagements/utils";
import { getEngagements } from "api/feed.api";
import { useQuery } from "@tanstack/react-query";
import Lottie from "react-lottie";
import { useInView } from "react-intersection-observer";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import { TimeLabel } from "components/FeedItemV2/ActivityHeader";
const defaultAnimationOptions = {
  loop: false,
  autoplay: true,
  animationData: welcomeAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const EmptyWelcome = ({ name }) => {
  const [ref, inView] = useInView();
  const [isPlaying, setIsPlaying] = React.useState(false);
  useEffect(() => {
    if (inView) {
      setIsPlaying(true);
    } else setIsPlaying(false);
  }, [inView]);
  return (
    <div className={styles.emptyContainer} ref={ref}>
      <Lottie
        options={defaultAnimationOptions}
        height={135}
        width={280}
        isStopped={!isPlaying}
      />
      <div className={styles.welcomeText}>
        <ZxText style={TSTYLES.body1} color="text-2" align="center">
          Give a warm welcome to {getTrimmedString(name, 12)}!
        </ZxText>
      </div>
    </div>
  );
};
const WelcomePostCard = ({ post, moveToNext }) => {
  const history = useHistory();
  const comments =
    (post?.show_suggestions && post?.welcome_message_suggestions) ?? null;
  const { data: engagements, refetch } = useQuery({
    queryKey: getEngagementsQueryKey(post.type, post.id),
    queryFn: () => getEngagements({ type: post.type, id: post.id }),
    initialData: post.engagements,
    enabled: !!post.id,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const handleSuccess = () => {
    refetch();
    moveToNext();
  };
  const replies = engagements?.replies?.count ?? 0;
  return (
    <div
      className={styles.cardContainer}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/welcome/${post.id}`);
      }}>
      <ZxActor actor={post.actor} showBio bioLength={48} />
      <Divider />
      {replies === 0 ? (
        <EmptyWelcome name={post.actor?.display_name} />
      ) : (
        <div className={styles.threadsContainer}>
          {engagements?.replies?.threads
            ?.slice(0, 3)
            ?.reverse()
            ?.map((rep, idx) => {
              return (
                <CustomColumn gap="8px" key={idx}>
                  <PaddingComponent paddingTop={idx === 0 ? "0" : "8px"}>
                    <CustomRow
                      gap="8px"
                      alignItems="center"
                      justifyContent="space-between">
                      <CustomRow gap="8px" alignItems="center">
                        <ConditionalLink to={rep.actor?.link ?? null}>
                          <ProfileHoverCard actor={rep.actor}>
                            <Image
                              src={rep.actor.display_picture}
                              imageType={IMAGE_TYPES.SMALL_AVATAR}
                              className={styles.commentActor}
                              alt={rep.actor.display_name}
                            />
                          </ProfileHoverCard>
                        </ConditionalLink>
                        <ZxText style={TSTYLES.body1} cursor="pointer">
                          <FeedDescription
                            templateString={rep.post?.contents}
                            identities={post.identities}
                            profiles={post.profiles}
                            enableSeeMore
                            showMoreLength={14}
                            trimExtra
                            fontSize="12px"
                          />
                        </ZxText>
                      </CustomRow>
                      <TimeLabel timestamp={rep.timestamp} />
                    </CustomRow>
                  </PaddingComponent>
                  <Divider />
                </CustomColumn>
              );
            })}
        </div>
      )}

      {comments && comments.length > 0 && (
        <div className={styles.commentsContainer}>
          {comments.map((cm, idx) => {
            return (
              <PostGeneratedComment
                key={idx}
                comment={cm}
                contentType={post.type}
                contentId={post.id}
                onSuccess={handleSuccess}
              />
            );
          })}
        </div>
      )}

      <ActivityEngagements
        engagements={engagements}
        hideCount
        showCurrEngagaments={false}
        postDetails={post}
        contentId={post.id}
        type={post.type}
        showPnlComments={false}
        showCommentCount={true}
        queryKey={getEngagementsQueryKey(post.type, post.id)}
      />
    </div>
  );
};

export default WelcomePostCard;
