import React from "react";
import styles from "./ZxNftDetails.module.scss";
import {
  CustomColumn,
  CustomRow,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { X } from "@phosphor-icons/react";
import { useSearchNftsInCollection } from "components/V2/CollectionDetails/collectionsUtils";
import { NftDetailsLoader } from "../ZxNftLoaders/ZxNftLoaders";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import ConditionalLink from "shared/ConditionalLink";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import ZxTabs from "zxComponents/common/ZxTabs/ZxTabs";
import ZxNftTraits from "../ZxNftTraits/ZxNftTraits";
import ZxNftActivities from "../ZxNftActivities/ZxNftActivities";
import { getTrimedAddress } from "utils/misc";
import GenericFallBackUI from "components/ErrorBoundary/GenericFallBackUI";
import ShareButton from "components/ActivityEngagements/ShareButton";
import { Export } from "@phosphor-icons/react";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import SimilarNFTs from "./SimilarNFTs";
const DETAILS_TABS = [
  {
    key: "traits",
    label: "Traits",
  },
  {
    key: "activity",
    label: "Activity",
  },
  {
    key: "similar",
    label: "Similar NFTs",
  },
];
const ZxNftDetails = ({
  nftId,
  chainId,
  contractAddress,
  isModal = true,
  onClose = () => {},
  activePageTab = null,
}) => {
  const {
    data: nft,
    isLoading,
    isError,
  } = useSearchNftsInCollection({
    tokenId: nftId,
    contractAddress,
    chainId,
    listingsOnly: false,
  });
  const collection = nft?.collection ?? {};
  const ownerAddress = nft?.owners?.[0];
  const owner = nft?.profiles?.[ownerAddress] ?? {
    display_name: getTrimedAddress(ownerAddress),
    display_picture: null,
    link: null,
  };
  const collectionId = collection?.collection_id;
  const lastPrice =
    nft?.collection?.floor_price?.token_value?.display_value ?? "-";
  const tokenLogo = nft?.collection?.floor_price?.token_logo_url ?? null;
  const [activeTab, setActiveTab] = React.useState(DETAILS_TABS[0].key);
  const updateTab = (tab) => {
    setActiveTab(tab);
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case "traits":
        return (
          <ZxNftTraits
            traits={nft?.traits}
            onClose={onClose}
            collectionId={collectionId}
          />
        );
      case "activity":
        return (
          <ZxNftActivities
            tokenId={nftId}
            contractAddress={contractAddress}
            chainId={chainId}
            isNft={true}
          />
        );
      case "similar":
        return (
          <SimilarNFTs
            contractAddress={contractAddress}
            chainId={chainId}
            tokenId={nftId}
            isModal={isModal}
            onNftClick={() => {
              setActiveTab("traits");
            }}
          />
        );
      default:
        return null;
    }
  };
  if (isError) {
    return (
      <PaddingComponent padding="200px 0 0 0">
        <GenericFallBackUI />
      </PaddingComponent>
    );
  }
  const shareLink = isModal
    ? `/collection/${collection?.collection_id}${activePageTab ? `/${activePageTab}` : ""}/${chainId}/${contractAddress}${nftId ? `/${nftId}` : ""}`
    : `/nft/${chainId}/${contractAddress}${nftId ? `/${nftId}` : ""}`;
  return (
    <div
      className={styles.container}
      style={{ padding: isModal ? "32px" : "8px" }}>
      {isModal && (
        <>
          <CustomRow justifyContent="space-between" alignItems="center">
            <ZxText style={TSTYLES.title1} fontSize={"18px"}>
              NFT Details
            </ZxText>
            <X size={16} color="var(--text-2)" onClick={onClose} fill="bold" />
          </CustomRow>
          <Divider margin="16px 0 16px 0" />
        </>
      )}
      {isLoading || !nft ? (
        <NftDetailsLoader />
      ) : (
        <CustomColumn overflowX="visible" overflowY="visible">
          {/* Info */}
          <CustomRow gap="28px" overflowX="visible" overflowY="visible">
            <div className={styles.nftWrapper}>
              <Image
                imageType={IMAGE_TYPES.NFT}
                src={nft?.image_url}
                alt={nft?.name}
                className={styles.nftImage}
              />
            </div>
            <div className={styles.nftDetails}>
              <CustomColumn gap="4px">
                <ConditionalLink
                  to={
                    isModal ? null : `/collection/${collection.collection_id}`
                  }>
                  <CustomRow gap="8px" alignItems="center">
                    <Image
                      imageType={IMAGE_TYPES.AVATAR}
                      name={collection?.name}
                      src={collection.image_url}
                      className={styles.collectionAvatar}
                    />
                    <ZxText style={TSTYLES.secondaryTitle} fontSize={"16px"}>
                      {collection?.name}
                    </ZxText>
                  </CustomRow>
                </ConditionalLink>
                <CustomColumn gap="8px" justifyContent="center">
                  <CustomRow justifyContent="space-between">
                    <ZxText style={TSTYLES.title1} fontSize={"18px"}>
                      {nft?.name}
                    </ZxText>
                    <PaddingComponent padding="0 8px">
                      <ShareButton
                        customShareLink={shareLink}
                        className={styles.shareButton}
                        shouldDirectlyCopy
                        icon={<Export size={16} color="var(--text-2)" />}
                      />
                    </PaddingComponent>
                  </CustomRow>
                </CustomColumn>

                <Divider margin="8px 0 16px 0" />
                {/* Owners */}
                <CustomColumn gap="4px">
                  <ZxText
                    style={TSTYLES.title2}
                    fontSize={"14px"}
                    color="text-2">
                    Owned by
                  </ZxText>
                  <ConditionalLink to={owner?.link}>
                    <ProfileHoverCard actor={owner}>
                      <CustomRow gap="4px" alignItems="center">
                        <Image
                          src={owner?.display_picture}
                          className={styles.avatar}
                        />

                        <ZxText style={TSTYLES.title1} fontSize={"15px"}>
                          {owner?.display_name}{" "}
                        </ZxText>
                      </CustomRow>
                    </ProfileHoverCard>
                  </ConditionalLink>
                </CustomColumn>

                <Divider margin="16px 0" borderColor="var(--border-light)" />
                {/* Prices */}
                <CustomColumn gap="4px">
                  <CustomRow gap="4px" alignItems="center">
                    <Image
                      src={tokenLogo}
                      className={styles.avatar}
                      alt="Token Logo"
                      imageType={IMAGE_TYPES.TOKEN}
                    />
                    <ZxText style={TSTYLES.title1} fontSize={"16px"}>
                      {lastPrice}
                    </ZxText>
                  </CustomRow>
                  <ZxText
                    style={TSTYLES.title2}
                    fontSize={"14px"}
                    color="text-2">
                    Floor price
                  </ZxText>
                </CustomColumn>
                <Divider margin="16px 0" borderColor="var(--border-light)" />
                <ExternalLinks links={nft?.marketplace_links ?? {}} />
              </CustomColumn>
            </div>
          </CustomRow>
        </CustomColumn>
      )}
      <PaddingComponent padding="24px 0">
        <ZxTabs
          tabs={DETAILS_TABS}
          onChange={updateTab}
          activeTab={activeTab}
        />
        <PaddingComponent padding="12px 0">
          {renderTabContent()}
        </PaddingComponent>
      </PaddingComponent>
    </div>
  );
};

export default withErrorBoundary(ZxNftDetails);
