import { ReactComponent as CrossIcon } from "assets/svg/profile/cross.svg";
import classes from "./ScreenerRightPanel.module.css";
import { useState, useContext } from "react";
import EditBasicColumns from "./components/EditBasicColumns";
import DefaultColumns from "./components/DefaultColumns";
import InputColumns from "./components/InputColumns";
import { runQuery } from "api/screener.api";
import { useCallback } from "react";
import { useEffect } from "react";
import { CustomColumn } from "components/UI/Components/Components";
import { CircularProgress } from "@mui/material";
import { GlobalContext } from "contextStates/Global";

const ScreenerRightPanel = ({
  setData,
  data,
  setIsEditing,
  additionalColumnsData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowersSelected, setIsFollowersSelected] = useState(false);
  const [isFollowingSelected, setIsFollowingSelected] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const [inputColumnsList, setInputColumnsList] = useState([
    {
      title: "Holdings of token",
      isActive: false,
      placeholder: "Enter token",
      fieldsList: [],
      source: "token",
      query: "Holdings of {{token}}",
    },
    {
      title: "Holdings in Protocol",
      isActive: false,
      placeholder: "Enter Protocol",
      fieldsList: [],
      source: "protocol",
      query: "Holdings in {{protocol}}",
    },
    {
      title: "Withdrew from Protocol",
      isActive: false,
      placeholder: "Enter Protocol",
      fieldsList: [],
      source: "protocol",
      query: "Withdrew from {{protocol}}",
    },
    {
      title: "Withdrew token from Protocol",
      isActive: false,
      placeholder: "Enter Token",
      placeholder2: "Enter Protocol",
      placeholderBetween: "from",
      fieldsList: [],
      isDoubleInputFields: true,
      doubleFieldsList: [],
      source: "token",
      source2: "protocol",
      query: "Withdrew {{token}} from {{protocol}}",
    },
    {
      title: "Deposits into Protocol",
      isActive: false,
      placeholder: "Enter Protocol",
      fieldsList: [],
      source: "protocol",
      query: "Deposits into {{protocol}}",
    },
    {
      title: "Deposits of token into Protocol",
      isActive: false,
      placeholder: "Enter Token",
      placeholder2: "Enter Protocol",
      placeholderBetween: "into",
      fieldsList: [],
      isDoubleInputFields: true,
      doubleFieldsList: [],
      source: "token",
      source2: "protocol",
      query: "Deposits of {{token}} into {{protocol}}",
    },
  ]);

  const updateFollowingState = useCallback((val) => {
    const isAlreadyContains = additionalColumnsData.current.find(
      (item) => item.query.title === "Following"
    );
    if (val && !isAlreadyContains) {
      additionalColumnsData.current.push({
        query: {
          title: "Following",
          type: "static",
        },
      });
    }
    if (!val) {
      additionalColumnsData.current = additionalColumnsData.current.filter(
        (item) => item.query.title !== "Following"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFollowersState = useCallback((val) => {
    const isAlreadyContains = additionalColumnsData.current.find(
      (item) => item.query.title === "Followers"
    );

    if (val && !(isAlreadyContains ?? false)) {
      additionalColumnsData.current.push({
        query: {
          title: "Followers",
          type: "static",
        },
      });
    }
    if (!val) {
      additionalColumnsData.current = additionalColumnsData.current.filter(
        (item) => item.query.title !== "Followers"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateInputColumnsState = useCallback(() => {
    //iterate the list and update addionalColumnsData for query api call
    inputColumnsList.forEach((item) => {
      if (item.isActive) {
        //first remove the existing data from additionalColumnsData
        //and then add the new data
        additionalColumnsData.current = additionalColumnsData.current.filter(
          (existingItem) => existingItem.query.title !== item.query
        );
        //iterate for 1input fields list
        item.fieldsList.forEach((field) => {
          const dataValue =
            item.source === "token"
              ? {
                  token: field,
                }
              : {
                  protocol: field,
                };
          // Check if the data already exists in additionalColumnsData
          const dataExists = additionalColumnsData.current.some(
            (existingItem) =>
              existingItem.query.title === item.query &&
              existingItem.query.type === "dynamic" &&
              JSON.stringify(existingItem.data) === JSON.stringify(dataValue)
          );
          if (dataExists) return;
          additionalColumnsData.current.push({
            query: {
              title: item.query,
              type: "dynamic",
            },
            data: dataValue,
          });
        });

        //iterate for double input fields list
        item.doubleFieldsList?.forEach((data) => {
          const dataValue = {
            token: data.option1,
            protocol: data.option2,
          };
          // Check if the data already exists in additionalColumnsData
          const dataExists = additionalColumnsData.current.some(
            (existingItem) =>
              existingItem.query.title === item.query &&
              existingItem.query.type === "dynamic" &&
              JSON.stringify(existingItem.data) === JSON.stringify(dataValue)
          );
          if (dataExists) return;
          //add if doesnt exist
          additionalColumnsData.current.push({
            query: {
              title: item.query,
              type: "dynamic",
            },
            data: dataValue,
          });
        });
      } else {
        additionalColumnsData.current = additionalColumnsData.current.filter(
          (existingItem) => existingItem.query.title !== item.query
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputColumnsList]);

  useEffect(() => {
    if (isDataUpdated) return;
    additionalColumnsData.current.forEach((item) => {
      if (item.query.title === "Following") {
        setIsFollowingSelected(true);
        return;
      }
      if (item.query.title === "Followers") {
        setIsFollowersSelected(true);
        return;
      }
      if (item.query.type === "dynamic") {
        const index = inputColumnsList.findIndex(
          (column) => column.query === item.query.title
        );
        if (index !== -1) {
          setInputColumnsList((prevState) => {
            const newState = [...prevState];
            newState[index].isActive = true;
            // Check if the fieldData already exists in the fieldsList
            const isDoubleFieldsList = Object.keys(item.data).length > 1;
            if (isDoubleFieldsList) {
              const doubleFieldData = {
                option1: item.data.token,
                option2: item.data.protocol,
              };
              // Check if the data already exists in additionalColumnsData
              const dataExists = newState[index].doubleFieldsList.some(
                (existingItem) =>
                  JSON.stringify(existingItem) ===
                  JSON.stringify(doubleFieldData)
              );
              if (!dataExists) {
                newState[index].doubleFieldsList.push(doubleFieldData);
              }
            } else {
              const fieldData = item.data[newState[index].source];
              if (!newState[index].fieldsList.includes(fieldData)) {
                newState[index].fieldsList.push(fieldData);
              }
            }
            return newState;
          });
        }
      }
    });
    setIsDataUpdated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onApply = useCallback(async () => {
    setIsLoading(true);
    runQuery({
      inputTokens: data.query_tokens,
      additionalColumns: additionalColumnsData.current,
    })
      .then((data) => {
        setIsLoading(false);
        if (data.data.status === "error") {
          handleErrorSnackbar(null, data.data.status);
          // setIsError(true);
          // setErrorStatus(data.data.status);
          // setErrorRange(data.data.data.error_ranges);
        } else {
          setData(data.data);
          setIsEditing(false);
          additionalColumnsData.current = data?.data?.additional_columns ?? [];
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleErrorSnackbar(null, "Oops! Something went wrong.");
      });
  }, [
    data.query_tokens,
    additionalColumnsData,
    setData,
    setIsEditing,
    handleErrorSnackbar,
  ]);
  return (
    <div className={classes.container}>
      <CustomColumn>
        <div className={classes.rightPanel}>
          <div
            onClick={() => {
              setIsEditing(false);
            }}>
            <CrossIcon className={classes.crossIcon} />
          </div>
          <span className={classes.title}>Edit Columns</span>
          <DefaultColumns />
          <EditBasicColumns
            updateFollowersState={updateFollowersState}
            updateFollowingState={updateFollowingState}
            isFollowersSelected={isFollowersSelected}
            isFollowingSelected={isFollowingSelected}
            setIsFollowersSelected={setIsFollowersSelected}
            setIsFollowingSelected={setIsFollowingSelected}
          />
          <InputColumns
            updateInputColumnsState={updateInputColumnsState}
            inputColumnsList={inputColumnsList}
            setInputColumnsList={setInputColumnsList}
          />
        </div>
        <div onClick={onApply} className={classes.applyButton}>
          {isLoading ? (
            <div>
              <CircularProgress size={20} color="inherit" />
            </div>
          ) : (
            "Apply Changes"
          )}
        </div>
      </CustomColumn>
    </div>
  );
};

export default ScreenerRightPanel;
