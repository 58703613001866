import { signedRequest } from "api/api";
import { XIcon } from "assets/svg/left_sidebar/CustomSvgIcons";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  FlexGrow,
  InfoBox,
  PaddingComponent,
} from "components/UI/Components/Components";
import LoaderButton from "components/UI/LoaderButton/LoaderButton";
import useNavigateNewOnboarding from "components/V2/NewOnboarding/NavigateOnboarding";
import OnboardingBg from "components/V2/NewOnboarding/OnboardingBg";
import Copyable from "components/ValueType/Copyable/Copyable";
import { GlobalContext } from "contextStates/Global";
import { useContext, useState } from "react";
import classes from "./TweetAboutUs.module.css";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { SpinnerComponent } from "components/UI/Components/Components";
import { useHistory } from "react-router-dom";

const TweetAboutUs = ({ prevPath }) => {
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const [isSkipLoading, setIsSkipLoading] = useState(false);
  const [twitterLoading, setTwitterLoading] = useState(false);
  const [navigateOnboarding, setNavigateOnboarding] = useState(false);
  const loading = useNavigateNewOnboarding(
    navigateOnboarding,
    setNavigateOnboarding
  );
  const history = useHistory();

  const onProceed = async (type = "tweet") => {
    if (type === "skip") {
      setIsSkipLoading(true);
    } else {
      setTwitterLoading(true);
    }
    return signedRequest({
      method: "post",
      path: "/api/0xppl/move_to_next_onboarding_state",
      bodyText: JSON.stringify({
        current_state: "tweet_abt_0xppl",
      }),
    })
      .then((data) => {
        const currentStep = data?.data?.data?.onboarding_state?.current;
        if (data.data.status === "ok") {
          history.push(`/onboarding/${currentStep}`);
          return;
        }
        handleErrorSnackbar("Something went wrong, please try again.");
      })
      .catch((err) => {
        setIsSkipLoading(false);
        handleErrorSnackbar(err);
      })
      .finally(() => {
        if (isSkipLoading) setIsSkipLoading(false);
        if (twitterLoading) setTwitterLoading(false);
      });
  };

  const tweetText = `I just signed up for @0xppl_
The social network for Crypto natives!
Why should you join?

✅  Onchain analysis
✅  Portfolio management
✅  Graph explorer
✅  Wallet tracking

Explore all you want.
Built for degens by degens.

Join now 0xppl.com`;
  const copyText = tweetText.replace(/\n/g, "\n");
  const urlText = encodeURIComponent(tweetText.replace(/<br\/>/g, "\n"));

  return (
    <BaseWrapper mixpanelEvent="OnboardingTweetAboutUs">
      <OnboardingBg>
        <div className={classes.indicatorRow}>
          <div className={classes.container}>
            <PaddingComponent height="24px" />
            <CustomColumn>
              <CustomText
                text="Send positive vibes!"
                fontSize="28px"
                fontWeight="600"
                fontFamily="Roboto"
                color="#1b1f22"
                textAlign="left"
              />
              <CustomText
                text="Let your twitter friends know about 0xPPL! Tweet about us! 💙"
                fontSize="15px"
                fontWeight="400"
                fontFamily="Roboto"
                color="#687684"
                textAlign="left"
              />
            </CustomColumn>
            <PaddingComponent height="24px" />
            <InfoBox
              background="rgba(104, 118, 132, 0.05)"
              padding="16px 24px"
              borderWidth="0px"
              radius="12px">
              <CustomRow alignItems="flex-start" justifyContent="space-between">
                <FlexGrow>
                  <span
                    className={classes.tweetText}
                    dangerouslySetInnerHTML={{ __html: tweetText }}
                  />
                </FlexGrow>
                <Copyable content={copyText} showOnHover={false} />
              </CustomRow>
            </InfoBox>
            <PaddingComponent height="30px" />
            <LoaderButton
              onClick={() => {
                window.open(
                  `https://twitter.com/intent/tweet?text=${urlText}`,
                  "_blank"
                );
                onProceed();
              }}
              checkLogin={false}
              loading={(loading || twitterLoading) && !isSkipLoading}
              style={{ backgroundColor: "#1b1f22" }}
              className={classes.tweetButtonContainer}>
              <XIcon color="white" />
              <CustomText
                text="Tweet about 0xPPL"
                fontSize="17px"
                fontWeight="600"
                fontFamily="Roboto"
                color="#ffffff"
              />
            </LoaderButton>
            <PaddingComponent height="20px" />
            <button
              className={classes.skipLink}
              onClick={() => {
                onProceed("skip");
              }}>
              {isSkipLoading ? (
                <SpinnerComponent color="var(--primary-color" size={24} />
              ) : (
                <>Skip & Continue</>
              )}
            </button>
          </div>
        </div>
      </OnboardingBg>
    </BaseWrapper>
  );
};

export default TweetAboutUs;
