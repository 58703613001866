import classes from "./TransactionsRightPanel.module.css";
import {
  CustomTextSpan,
  Divider,
  InfoBox,
  PaddingComponent,
} from "components/UI/Components/Components";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import BasicInfoRowTile from "components/BasicInfoRowTile/BasicInfoRowTile";
import { getShortDotFormatAddress } from "utils/profile_utils";
import Copyable from "components/ValueType/Copyable/Copyable";
import { getChainMetaDataWithProfile, weiToMainToken } from "utils/misc";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import InputData from "./InputData";
import AdditionalDetails from "./AdditionalDetails";
import TransactionStatus from "./TransactionStatus";
import TransactionTokenTransfers from "./TokenTransfers/TransactionTokenTransfers";

const TransactionDetails = ({ activityData, isRightPane = false }) => {
  if (activityData == null) {
    return;
  }
  const activity = activityData?.activities?.[0];
  const profiles = activityData?.profiles;
  const transaction =
    activity?.transactions == null ? null : activity?.transactions?.[0];
  const chainIcon = getChainMetaDataWithProfile({
    chainId: transaction?.chain_id,
    chainProfiles: activityData?.chain_profiles,
  });
  const blockNumber =
    transaction?.block_number ??
    transaction?.data?.block_number ??
    activityData?.block_number;
  const transactionIndex =
    transaction?.data?.transaction_index ?? activityData?.transaction_index;
  return (
    <div className={classes.container}>
      <InfoBox>
        <span className={classes.infoText}>Info</span>
        <PaddingComponent height={"10px"}></PaddingComponent>
        <Divider />
        {transaction?.description && (
          <div>
            <PaddingComponent height={"16px"}></PaddingComponent>
            <FeedDescription
              templateString={transaction?.description}
              profiles={activityData.profiles}
              actor={activityData.actor}
              nativeTokensInfo={null}
              methodIds={activityData.method_ids}
              heroNames={activityData.hero_names}
            />
            <PaddingComponent height={"16px"}></PaddingComponent>
            <Divider />
          </div>
        )}
        <PaddingComponent height={"16px"}></PaddingComponent>
        <div className={classes.detailsInfoContainer}>
          <BasicInfoRowTile title="Txn identifier">
            <div className={classes.detailsTextRow}>
              <span className={classes.tableRowValueText}>
                {getShortDotFormatAddress({
                  address: activityData.tx_id ?? transaction?.tx_id,
                  numbersIncluding: 4,
                })}
              </span>
              <Copyable
                content={activityData.tx_id ?? transaction?.tx_id}></Copyable>
            </div>
          </BasicInfoRowTile>
          {transaction?.data?.fee?.display_value && (
            <BasicInfoRowTile title="Txn Fee">
              <div className={classes.detailsTextRow}>
                <Image
                  className={classes.chainIcon}
                  src={chainIcon.icon}
                  alt="Chain Icon"
                  imageType={IMAGE_TYPES.CHAIN}
                />
                <span
                  className={
                    classes.tableRowValueText
                  }>{`${transaction?.data?.fee?.display_value} ${activity?.chain_symbol}`}</span>
                {transaction?.data?.fee_usd?.display_value && (
                  <CustomTextSpan color="var(--text-3)">
                    {transaction?.data?.fee_usd?.display_value}
                  </CustomTextSpan>
                )}
              </div>
            </BasicInfoRowTile>
          )}
          <TransactionStatus
            blockTimestamp={transaction?.block_timestamp}
            confirmationsCount={activityData?.confirmations_count}
            transactionData={activityData}
          />

          {!!transaction?.data?.value && !!activity?.chain_symbol && (
            <BasicInfoRowTile
              title="Value"
              value={weiToMainToken(
                transaction?.data?.value,
                activity?.chain_symbol
              )}
            />
          )}
          {!!blockNumber && (
            <BasicInfoRowTile title="Block No">
              <div className={classes.detailsTextRow}>
                <span className={classes.tableRowValueText}>{blockNumber}</span>
                <Copyable content={blockNumber}></Copyable>
              </div>
            </BasicInfoRowTile>
          )}
          {transactionIndex !== null && transactionIndex !== undefined && (
            <BasicInfoRowTile title="Txn Index">
              <div className={classes.detailsTextRow}>
                <span className={classes.tableRowValueText}>
                  {transactionIndex}
                </span>
                <Copyable content={transactionIndex}></Copyable>
              </div>
            </BasicInfoRowTile>
          )}
        </div>
      </InfoBox>
      <InputData data={transaction?.data?.input_data} profiles={profiles} />
      <TransactionTokenTransfers
        isRightPane={isRightPane}
        activityData={activityData}
        activityId={activity.id}
      />
      <AdditionalDetails data={transaction?.data?.additional_details} />
    </div>
  );
};

export default TransactionDetails;
