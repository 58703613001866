export const getCdnUrl = (assetPath) => {
  return "https://d43skd0e52j71.cloudfront.net/" + assetPath;
};

export const remapAssets = (assetPath) => {
  if (!assetPath?.endsWith(".svg")) {
    return require(`assets/${assetPath.replace("assets/", "")}`).default;
  }
  assetPath = assetPath.replace("assets/svg", "static");
  return getCdnUrl(assetPath);
};

export const lensIcon = getCdnUrl("assets/svg/external/lens.svg");
export const farcasterIcon = getCdnUrl("assets/svg/external/farcaster.svg");
export const ethereumIcon = getCdnUrl("static/chain_eth.svg");
export const polygonIcon = getCdnUrl("static/chain_poly.svg");
export const bscIcon = getCdnUrl("static/chain_bsc.svg");
export const avalancheIcon = getCdnUrl("static/chain_avax.svg");
export const gnosisIcon = getCdnUrl("static/chain_gnosis.svg");
export const hecoIcon = getCdnUrl("static/chain_heco.svg");
export const fantomIcon = getCdnUrl("static/chain_ftm.svg");
export const arbitrumIcon = getCdnUrl("static/chain_arbitrum.svg");
export const optimismIcon = getCdnUrl("static/chain_optimism.svg");
export const moonriverIcon = getCdnUrl("static/chain_moonriver.svg");
export const cronosIcon = getCdnUrl("static/chain_cronos.svg");
export const auroraIcon = getCdnUrl("static/chain_aoa.svg");
export const evmosIcon = getCdnUrl("static/chain_evmos.svg");
export const bobaIcon = getCdnUrl("static/chain_boba.svg");
export const moonbeamIcon = getCdnUrl("static/chain_moonbeam.svg");
export const zkSyncIcon = getCdnUrl("static/chain_zksync.svg");
export const polyZk = getCdnUrl("static/chain_polyzk.svg");
export const baseIcon = getCdnUrl("static/chain_base.svg");
export const hyperlaneTest = getCdnUrl("static/chain_hyperlanetest.svg");
export const mantaTest = getCdnUrl("static/chain_mantatest.svg");
export const panGuTest = getCdnUrl("static/chain_pangu.svg");
export const zoraChainIcon = getCdnUrl("static/chain_zora.svg");
export const lineaIcon = getCdnUrl("static/chain_linea.svg");
export const mantaIcon = getCdnUrl("static/chain_manta.svg");
export const metisIcon = getCdnUrl("static/chain_metis.svg");
export const blastIcon = getCdnUrl("static/chain_blast.svg");
export const degenChainIcon = getCdnUrl("static/chain_degen.svg");
export const solanaIcon = getCdnUrl("static/chain_solana.svg");
export const bitcoinIcon = getCdnUrl("static/chain_bitcoin.svg");
export const toncoinIcon = getCdnUrl("static/chain_ton.svg");
export const logo = getCdnUrl("assets/logo.svg");
export const logoFull = getCdnUrl("assets/logo_full.svg");
export const mirrorxyzIcon = getCdnUrl("static/png/mirror_xyz.png");
export const abstractIcon = getCdnUrl("static/chain_abstract.svg");
export const beraIcon = getCdnUrl("static/chain_bera.svg");
