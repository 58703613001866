import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import React from "react";
import { useParams } from "react-router-dom";
import styles from "./WalletAccessPage.module.scss";
import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
  Divider,
  PaddingComponent,
  SpinnerComponent,
} from "components/UI/Components/Components";
import { Info, X } from "@phosphor-icons/react";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import ZxBadge from "zxComponents/common/ZxBadge";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import useWalletAccess from "./useWalletAccess";
import ZxBanner from "zxComponents/common/ZxBanner/ZxBanner";
import { getNetWorthV2 } from "utils/profile_utils";
import { Copy, Dot, EyeClosed } from "@phosphor-icons/react";
import { getTrimedAddress } from "utils/misc";
import Copyable from "components/ValueType/Copyable/Copyable";
import ZxCheckBox from "zxComponents/common/ZxCheckbox/ZxCheckBox";
import GenericButton from "components/V2/GenericButton/GenericButton";
import { WalletAccessPageHeader } from "./WalletAccessPage.utils";
import WalletManagePage from "./WalletManagePage";
import Image, { IMAGE_TYPES } from "components/UI/Image";

const STATUS_MAP = {
  pending: {
    text: "SENT",
    variant: "warning",
  },
  not_sent: {
    text: "Send request",
    variant: "warning",
  },
  received: {
    text: "Received",
    variant: "success",
  },
  accepted: {
    text: "Mutual Sharing",
    variant: "primary",
  },
};

const WalletAccessPage = ({ manage }) => {
  const { identifier } = useParams();
  const walletValues = useWalletAccess({
    identifier,
  });
  return manage ? (
    <WalletManagePage {...walletValues} />
  ) : (
    <WalletRequestPage {...walletValues} />
  );
};
const WalletRequestLoader = () => {
  return (
    <CustomColumn gap="16px">
      <WalletAccessPageHeader hideBadge title="Share wallet request" />
      <PaddingComponent padding="0" width="100%">
        <CustomRow gap="16px" width="100%" justifyContent="space-between">
          <CustomSkeleton
            width="45%"
            height="60px"
            variant="rectangular"
            borderRadius="8px"
          />
          <CustomSkeleton
            gap="8px"
            width="45%"
            height="60px"
            variant="rectangular"
            borderRadius="8px"
          />
        </CustomRow>
      </PaddingComponent>
      <CustomColumn gap="16px">
        {[1, 2, 3, 4, 5].map((row, idx) => {
          return (
            <CustomSkeleton
              key={row}
              height="20px"
              variant="rectangular"
              width="100%"
              borderRadius="4px"
            />
          );
        })}
      </CustomColumn>
    </CustomColumn>
  );
};
const WalletRequestPage = ({
  profile,
  isProfileLoading,
  self,
  privateWallets,
  selectedWallets,
  addOrRemoveSingleWallet,
  isUpdatePending,
  isRevokePending,
  sendOrUpdateRequest,
  connectionData,
  cancelOrRevokeAccess,
  checkForChanges,
  isAcceptPending,
  isCancelPending,
  acceptRequest,
  cancelRequest,
  isConnectionBeingFetched,
  isConnectionLoading,
}) => {
  if (isProfileLoading || isConnectionLoading) {
    return (
      <div className={styles.container}>
        <WalletRequestLoader />
      </div>
    );
  }
  const isPending =
    connectionData?.status === "pending" && connectionData?.is_sent;
  const isNotSent = connectionData?.status === "not_sent";
  const isReceived =
    connectionData?.status === "pending" && connectionData?.is_sent === false;
  const isAccepted = connectionData?.status === "accepted";
  const status =
    STATUS_MAP[isReceived ? "received" : connectionData?.status] ??
    STATUS_MAP.not_sent;
  const otherWalletsCount =
    connectionData?.shared_wallets_by_them_data?.num_wallets;
  return (
    <div className={styles.container}>
      <div>
        <CustomRow
          className={styles.header}
          alignItems="center"
          justifyContent="space-between">
          <CustomRow
            className={styles.headerTitle}
            gap="16px"
            alignItems="center">
            <ZxText style={TSTYLES.title1} fontSize="15px">
              {"Share wallet request"}
            </ZxText>
          </CustomRow>
          <ZxBadge variant={status.variant}>{status.text}</ZxBadge>
        </CustomRow>

        <Divider margin="16px 0" />

        {/* Actors */}

        <PaddingComponent padding="16px" className={styles.actors}>
          <CustomRow alignItems="center" gap="16px">
            <CustomRow className={styles.actor} justifyContent="space-between">
              <CustomRow gap="8px" alignItems="center">
                <ZxActor
                  actor={self}
                  size="28px"
                  showNetworth={false}
                  showFollowButton={false}
                  displayNameStyle="body"
                  nameLength={12}
                />
                <ZxText style={TSTYLES.secondary2} fontSize="12px">
                  You
                </ZxText>
              </CustomRow>
              <div className={styles.walletCount}>
                <EyeClosed size={16} color="var(--text-2)" />
                <ZxText style={TSTYLES.title2} fontSize="12px" color="text-2">
                  {selectedWallets.length}
                </ZxText>
              </div>
            </CustomRow>
            <X size={16} weight="bold" />
            <CustomRow className={styles.actor} justifyContent="space-between">
              <ZxActor
                actor={profile}
                size="28px"
                showNetworth={false}
                showFollowButton={false}
                displayNameStyle="body"
                nameLength={12}
              />
              <div
                className={`${styles.walletCount} ${otherWalletsCount > 0 ? styles.otherActor : ``}`}>
                <EyeClosed
                  size={16}
                  color={
                    otherWalletsCount > 0
                      ? "var(--text-white)"
                      : "var(--text-2)"
                  }
                />
                <ZxText
                  style={TSTYLES.title2}
                  fontSize="12px"
                  color={otherWalletsCount > 0 ? "white" : "text-2"}>
                  {isReceived || isAccepted
                    ? connectionData?.shared_wallets_by_them_data?.num_wallets
                    : "-"}
                </ZxText>
              </div>
            </CustomRow>
          </CustomRow>
        </PaddingComponent>
      </div>

      {(isReceived || isAccepted) && (
        <CustomColumn gap="8px">
          <ZxText style={TSTYLES.secondaryLabel} textTransform="uppercase">
            {profile?.display_name}'s wallets
          </ZxText>
          <PaddingComponent
            padding="8px"
            borderRadius="8px"
            backgroundColor="var(--elevation-1)">
            <CustomRow alignItems="center" justifyContent="space-between">
              <CustomRow alignItems="center" gap="8px">
                <Image
                  src={"/svg/wallet_color.svg"}
                  imageType={IMAGE_TYPES.AVATAR}
                />
                <ZxText style={TSTYLES.body2} fontSize="13px">
                  {connectionData?.shared_wallets_by_them_data?.num_wallets}{" "}
                  private wallets
                </ZxText>
              </CustomRow>

              <ZxText style={TSTYLES.title2} fontSize="13px">
                {
                  connectionData?.shared_wallets_by_them_data?.net_worth
                    ?.display_value
                }
              </ZxText>
            </CustomRow>
          </PaddingComponent>
        </CustomColumn>
      )}

      {/* Wallets */}
      <ZxText style={TSTYLES.title2}>Choose wallets to share</ZxText>

      <CustomColumn
        className={styles.wallet}
        gap="8px"
        overflowX="scroll"
        overflowY="scroll">
        {privateWallets.map((wallet, idx) => {
          const isSelected = selectedWallets.includes(wallet.address);
          return (
            <PaddingComponent
              padding="12px"
              key={idx}
              onClick={() => {
                if (
                  !isUpdatePending &&
                  !isRevokePending &&
                  !isConnectionBeingFetched
                )
                  addOrRemoveSingleWallet(wallet.address);
              }}
              className={`${styles.walletRow} ${isSelected ? styles.selected : ``} ${isRevokePending || isUpdatePending || isConnectionBeingFetched ? styles.disabled : ``} `}>
              <CustomRow
                justifyContent="space-between"
                overflowX="visible"
                overflowY="visible">
                <CustomRow gap="8px" alignItems="center">
                  <ZxCheckBox
                    isChecked={isSelected}
                    onChange={() => {}}
                    label={null}
                  />
                  <CustomColumn
                    overflowX="visible"
                    overflowY="visible"
                    gap="4px">
                    <ZxText style={TSTYLES.title2} fontSize="13px">
                      {wallet.wallet_name}
                    </ZxText>
                    <CustomRow
                      gap="2px"
                      overflowX="visible"
                      overflowY="visible"
                      alignItems="center">
                      <EyeClosed size={16} color="var(--text-2)" />
                      <ZxText style={TSTYLES.secondary2} fontSize="11px">
                        {getTrimedAddress(wallet.address)}
                      </ZxText>
                      <Copyable
                        iconSize="16px"
                        content={wallet.address}
                        copyIcon={<Copy size={16} color="var(--text-2)" />}
                      />
                      <Dot color="var(--text-2)" size={16} />
                      <ZxText style={TSTYLES.secondary2} fontSize="11px">
                        Private
                      </ZxText>
                    </CustomRow>
                  </CustomColumn>
                </CustomRow>
                <ZxText style={TSTYLES.title2} fontSize="13px">
                  {getNetWorthV2(wallet)}
                </ZxText>
              </CustomRow>
            </PaddingComponent>
          );
        })}
      </CustomColumn>

      {/* Banner */}
      <ZxBanner
        icon={<Info size={16} color="var(--warning)" weight="fill" />}
        textColor="var(--warning-text)"
        background="var(--warning-light)">
        This is a read only access of wallets. Holdings of the wallets cannot be
        modified by the others. You can anytime revoke wallet share access.
      </ZxBanner>

      {/* Submit */}
      {isNotSent && (
        <GenericButton
          color="var(--text-white)"
          borderRadius="8px"
          onClick={sendOrUpdateRequest}
          disabled={
            selectedWallets.length === 0 ||
            isUpdatePending ||
            isConnectionBeingFetched
          }>
          {isUpdatePending ? (
            <SpinnerComponent color="var(--text-white)" size={16} />
          ) : (
            "Send request"
          )}
        </GenericButton>
      )}

      {(isPending || isAccepted) && (
        <div className={styles.buttonRow}>
          <GenericButton
            borderRadius="8px"
            disabled={
              isRevokePending || isUpdatePending || isConnectionBeingFetched
            }
            onClick={cancelOrRevokeAccess}
            color="var(--error)"
            background="var(--elevation-2)">
            {isRevokePending ? (
              <SpinnerComponent color="var(--error)" size={16} />
            ) : (
              "Revoke Access"
            )}
          </GenericButton>
          <GenericButton
            borderRadius="8px"
            onClick={sendOrUpdateRequest}
            disabled={
              isUpdatePending ||
              !checkForChanges() ||
              selectedWallets?.length === 0
            }
            color="var(--text-white)">
            {isUpdatePending ? (
              <SpinnerComponent color="var(--text-white)" size={16} />
            ) : (
              "Save changes"
            )}
          </GenericButton>
        </div>
      )}

      {/* Accept an invite */}
      {isReceived && (
        <div className={styles.buttonRow}>
          <GenericButton
            borderRadius="8px"
            disabled={
              isCancelPending || isAcceptPending || isConnectionBeingFetched
            }
            onClick={cancelRequest}
            color="var(--error)"
            background="var(--elevation-2)">
            {isCancelPending ? (
              <SpinnerComponent color="var(--error)" size={16} />
            ) : (
              "Reject"
            )}
          </GenericButton>
          <GenericButton
            borderRadius="8px"
            background="var(--success)"
            onClick={acceptRequest}
            disabled={isAcceptPending || selectedWallets?.length === 0}
            color="var(--text-white)">
            {isAcceptPending ? (
              <SpinnerComponent color="var(--text-white)" size={16} />
            ) : (
              "Accept & Share"
            )}
          </GenericButton>
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(WalletAccessPage);
