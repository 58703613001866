import {
  InfoBox,
  PaddingComponent,
  CustomColumn,
  Divider,
  CustomRow,
  CustomSkeleton,
} from "components/UI/Components/Components";
import { useEffect, useState, useContext, Fragment } from "react";
import HomeComponentsHeader from "../HomeComponentsHeader";
import WatchlistTile from "./WatchlistTile";
import SearchBarMini from "components/UI/Components/SearchBarMini";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { fetchWatchlist, reorderWatchlist } from "api/watchlist.api";
import emptyWatchlistImage from "assets/svg/empty_watchlist.svg";
import classes from "./Watchlist.module.css";
import { GlobalContext } from "contextStates/Global";
import WatchlistCoingeckoSearchItem from "./WatchlistCoingeckoSearchItem";
import withLoader from "components/core/withLoader";
import { trackEvent } from "utils/event_tracking";
import ZxText from "../../../../../zxComponents/common/ZxText";
import { TSTYLES } from "../../../../../zxStyles/constants";

const Watchlist = ({ queryData, refetch, isFetching }) => {
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const [isExpanded, setIsExpanded] = useState(true);

  const [watchlist, setWatchlist] = useState(queryData || []);

  useEffect(() => {
    if (queryData) {
      setWatchlist(queryData);
      trackEvent("fetched_watchlist");
    }
  }, [queryData]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const newItems = Array.from(watchlist);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    const reorderedListTuple = newItems.map((item, index) => {
      return [item.category, item?.identifier, index + 1];
    });
    reorderWatchlist({ watchlistList: reorderedListTuple })
      .then(() => {})
      .catch((err) => {
        handleErrorSnackbar(err, "Failed to reorder watchlist");
      });
    setWatchlist(newItems);
  }

  const onRemove = (idx) => {
    const newItems = Array.from(watchlist);
    newItems.splice(idx, 1);
    setWatchlist(newItems);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="watchlist-items">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <InfoBox
              padding="16px"
              radius="8px"
              borderColor="var(--elevation-1)">
              <CustomColumn alignItems="flex-start" width="100%">
                <HomeComponentsHeader
                  text="My Watchlist"
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                />
                {isExpanded ? (
                  <>
                    <PaddingComponent padding="16px 0px" width="100%">
                      <SearchBarMini
                        placeholder="Add Tokens"
                        source="token"
                        searchQueryKey="search-token-watchlist"
                        refetch={refetch}
                        watchlistItems={watchlist}
                        SearchItemComponent={WatchlistCoingeckoSearchItem}
                        useCoingecko={true}
                        trackingFunction={(items, query) => {
                          trackEvent("watchlist_search_results", {
                            search_results: items.length,
                            search_query: query,
                            search_items: items.map(
                              (item) => item?.display_name
                            ),
                          });
                        }}
                      />
                    </PaddingComponent>
                    <Divider borderColor="var(--border-light)" />
                    <div className={classes.watchlistItems}>
                      {watchlist?.length > 0 ? (
                        <>
                          <CustomRow
                            gap="16px"
                            width="100%"
                            padding="16px 0"
                            alignItems="space-between"
                            justifyContent="space-between">
                            <ZxText
                              style={TSTYLES.secondaryLabel}
                              fontSize={10}>
                              NAME/M.CAP
                            </ZxText>
                            <ZxText
                              style={TSTYLES.secondaryLabel}
                              fontSize={10}>
                              PRICE/24H
                            </ZxText>
                          </CustomRow>
                          <Divider borderColor="var(--border-light)" />
                          {watchlist?.map((_, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={"draggable-" + index}
                                index={index}>
                                {(provided) => (
                                  <WatchlistTile
                                    refetch={refetch}
                                    index={index}
                                    draggableProps={provided.draggableProps}
                                    dragHandleProps={provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    watchlistItem={watchlist[index]}
                                    key={index}
                                    isFetching={isFetching}
                                    onRemove={onRemove}
                                  />
                                )}
                              </Draggable>
                            );
                          })}
                        </>
                      ) : (
                        <CustomColumn
                          className={classes.emptyState}
                          padding="20px"
                          background={`url(${emptyWatchlistImage}) no-repeat bottom/cover`}>
                          <ZxText>No tokens added to your watchlist</ZxText>
                        </CustomColumn>
                      )}
                    </div>
                  </>
                ) : null}
              </CustomColumn>
            </InfoBox>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const WatchlistLoader = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <InfoBox padding="16px" radius="4px">
      <HomeComponentsHeader
        text="My Watchlist"
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
      <CustomColumn width="100%">
        {[1, 2, 3, 4, 5].map((_, index) => {
          return (
            <PaddingComponent padding="12px 0" key={index}>
              <CustomRow gap="16px" alignItems="center">
                <CustomSkeleton variant="circular" height="24px" width="28px" />
                <CustomSkeleton variant="text" height="32px" width="100%" />
              </CustomRow>
            </PaddingComponent>
          );
        })}
      </CustomColumn>
    </InfoBox>
  );
};

export default withLoader(Watchlist, fetchWatchlist, {
  queryKey: () => ["watchlist"],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 2,
  renderNotFound: false,
  showRefetch: false,
  CustomLoader: WatchlistLoader,
});
