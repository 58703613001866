import { createChart } from "lightweight-charts";
import { abbreviateNumber } from "utils/misc";

export const tsCutoff = (days) => {
  if (days === "max") {
    return 0;
  }
  const now = new Date();
  const startOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );

  const cutoff = new Date(startOfToday);
  cutoff.setDate(cutoff.getDate() - days);
  return cutoff.getTime();
};

export const transformChartData = (data, isSeconds) => {
  const modifiedData = data?.map(([time, value]) => ({
    time: isSeconds ? time : time / 1000,
    value: value,
  }));

  //sort data if not
  return modifiedData.sort((a, b) => a.time - b.time);
};

export const transformOhlcChartData = (data, isSeconds) => {
  const modifedData = data?.map?.(([time, open, high, low, close]) => ({
    time: isSeconds ? time : time / 1000,
    open,
    high,
    low,
    close,
  }));
  //sort data if not not in ascending order
  return modifedData.sort((a, b) => a.time - b.time);
};

export const createNewChartInstance = (chartContainer, config, mini) =>
  createChart(chartContainer.current, {
    ...config,
    width: chartContainer.current.clientWidth,
    height: mini ? 70 : 300,
    timeScale: { timeVisible: true },
  });

export const getChartStats = (tokenChartData) => {
  const [latestTs, latestPrice] =
    tokenChartData.prices[tokenChartData.prices.length - 1];
  const dayAgoTs = latestTs - 24 * 60 * 60 * 1000;
  // find the price at the start of the day
  const dayAgoPrice = tokenChartData.prices.reduce(
    (prevPrice, [ts, price]) => {
      if (ts <= dayAgoTs) {
        return price;
      }
      return prevPrice;
    },
    tokenChartData.prices[0][1] // oldest price from this chart
  );

  const pricePercentageChange = Math.abs(
    ((latestPrice - dayAgoPrice) / dayAgoPrice) * 100
  );
  const changeDisplayValue = `${abbreviateNumber(pricePercentageChange)}%`;
  const changeDict = {
    value: pricePercentageChange,
    display_value: changeDisplayValue,
    direction: latestPrice >= dayAgoPrice ? 1 : -1,
  };
  const priceDollarValue = `$${abbreviateNumber(latestPrice)}`;
  return {
    available: true,
    priceUsd: priceDollarValue,
    priceChange: changeDict,
  };
};

export const sortGraphDataByTime = (data, type = "asc") => {
  if (data.length === 0) return data;
  return data.sort((a, b) => {
    const aTime = a.x;
    const bTime = b.x;
    if (type === "asc") {
      return aTime - bTime;
    } else {
      return bTime - aTime;
    }
  });
};
