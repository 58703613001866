import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import classes from "./ConnectionButton.module.css";
import { EyeClosed } from "@phosphor-icons/react";
import { createPortal } from "react-dom";
import { useContext, useState } from "react";
import { AuthContext } from "contextStates/AuthContext";
import {
  useGetWalletConnectionsWithProfile,
  useGetWalletRequests,
} from "api/profile.api";
import ConnectPanel from "components/V2/Profile/ProfileDetails/wallets/ManageAccess/ConnectPanel";
import { GlobalContext } from "contextStates/Global";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { useHistory, useParams } from "react-router-dom";
import { trackEvent } from "utils/event_tracking";
import { ProfileContext } from "contextStates/ProfileContext";

const getRequestStatusClass = (status, data) => {
  switch (status) {
    case "pending":
      if (data.is_sent) return classes.requestPending;
      return "";
    case "accepted":
      return classes.requestAccepted;
    case "not_sent":
      return classes.connectable;
    default:
      return "";
  }
};

const ConnectionButton = ({ identity }) => {
  const { identityDetails } = useContext(AuthContext);
  const { isAccessTabClicked, setIsAccessTabClicked } =
    useContext(ProfileContext);
  const { featureFlags } = useContext(GlobalContext);
  const identifier = identityDetails?.current?.identity?.id;
  const history = useHistory();
  const { identifier: profileId } = useParams();
  const [isConnectPanelOpen, setIsConnectPanelOpen] = useState(
    history.location.search?.includes("requestConnection")
  );
  const { data: connectionData } = useGetWalletConnectionsWithProfile({
    self: identityDetails?.current?.identity?.id,
    profile: identity?.id,
    enabled:
      !!identifier &&
      identity?.id &&
      identity?.is_verified &&
      identifier !== identity?.id,
  });

  const { data: requestConnections } = useGetWalletRequests();
  const receivedCount = requestConnections?.received?.details?.length ?? 0;
  const sentCount = requestConnections?.sent?.details?.length ?? 0;
  const unreadCount = receivedCount + sentCount;
  const isVerified = identity?.is_user_account || identity?.user_since != null;
  if (!isVerified) return null;
  // check flag
  if (!featureFlags.is_showing_private_wallets) return null;

  if (identity?.id !== identifier && !identity?.followed) return null;

  if (identity?.id === identifier) {
    return (
      <PPLxTooltip arrow title={`Manage wallet access`}>
        <span
          className={`${classes.connectButton} ${getRequestStatusClass(
            connectionData?.status
          )}`}
          onClick={() => {
            if (identifier === identity?.id) {
              setIsAccessTabClicked(true);
              history.push(`/${identifier}/wallets/manage-access`);
            }
          }}>
          <EyeClosed weight="bold" width={16} height={16} />
          <span>Share Private Wallet</span>
          {unreadCount > 0 && !isAccessTabClicked.current && (
            <span className={`${classes.count}`}>{unreadCount}</span>
          )}
        </span>
      </PPLxTooltip>
    );
  }

  return (
    <>
      <PPLxTooltip
        arrow
        title={
          connectionData?.status === "accepted"
            ? `You both are now sharing private wallets mutually. Check out their portfolio below for their updated holdings.`
            : connectionData?.status === "pending"
              ? `Wallet sharing request sent to ${identity?.display_name}`
              : `Share private wallets with ${identity.display_name}`
        }>
        <button
          className={`${classes.connectButton} ${getRequestStatusClass(
            connectionData?.status,
            connectionData
          )}`}
          onClick={() => {
            trackEvent("wallet_share");
            history.push(`/${profileId}/wallets/request`);
          }}>
          <EyeClosed weight="bold" width={16} height={16} />
          <span>
            {connectionData?.status === "accepted"
              ? "Sharing Wallet"
              : connectionData?.status === "pending"
                ? "Request Sent"
                : "Share Wallet"}
          </span>
        </button>
      </PPLxTooltip>

      {createPortal(
        <RightPanel
          onClose={() => {
            setIsConnectPanelOpen(false);
          }}>
          {isConnectPanelOpen && (
            <ConnectPanel
              identity={identity}
              setIsConnectPanelOpen={setIsConnectPanelOpen}
              loggedInUserDetails={identityDetails?.current?.identity}
            />
          )}
        </RightPanel>,
        document.getElementById("rightPanel")
      )}
    </>
  );
};

export default ConnectionButton;
