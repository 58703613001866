import Image, { IMAGE_TYPES } from "components/UI/Image";
import classes from "./styles.module.css";

import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import ExternalLinks from "components/V2/Profile/ProfileHeader/ExternalLinks";

import { AddToWalletButton } from "./Components";
import {
  GasPump,
  AlignCenterVertical,
  CaretUp,
  CaretDown,
} from "@phosphor-icons/react";

import ConditionalLink from "shared/ConditionalLink";

const ChainInfo = ({ metadata }) => {
  if (metadata == null) {
    return null;
  }

  const {
    logo: icon,
    chain_title: chainTitle,
    description: chainDescription,
    add_to_wallet,
  } = metadata;

  return (
    <div className={classes.protocol_header}>
      <section className={classes.info}>
        <div className={classes.container}>
          <Image
            src={icon}
            alt={chainTitle}
            imageType={IMAGE_TYPES.CONTRACT}
            className={classes.image}
          />

          <div className={classes.details}>
            <div className={classes.top}>
              <div className={classes.name}>{chainTitle}</div>
              <div className={classes.contract_tag}>Chain</div>
            </div>
            <CustomRow gap="10px">
              <CustomText text={metadata?.short_description} />
            </CustomRow>
          </div>

          <div className={classes.socials}>
            <ExternalLinks links={metadata?.external_links} />
            <PaddingComponent paddingLeft="8px" />
          </div>
        </div>
      </section>

      <>
        <PaddingComponent paddingTop="16px">
          <FeedDescription templateString={chainDescription} />
        </PaddingComponent>
        <PaddingComponent paddingTop="16px">
          <CustomColumn gap="15px">
            <Divider />
            <CustomRow alignItems="center" justifyContent="space-between">
              <CustomRow alignItems="center" gap="8px">
                {metadata?.brige && (
                  <ConditionalLink
                    sameTab={false}
                    to={{ pathname: metadata?.brige?.link }}>
                    <CustomRow
                      background="var(--base)"
                      borderRadius="40px"
                      border="1px solid var(--border-light)"
                      padding="6px 8px"
                      alignItems="center"
                      gap="5px">
                      <AlignCenterVertical size={20} color="var(--text-2)" />
                      <CustomText text="Bridge" />
                    </CustomRow>
                  </ConditionalLink>
                )}

                {metadata?.gas_fee && (
                  <CustomRow
                    borderRadius="40px"
                    border="1px solid var(--border-light)"
                    padding="6px 8px"
                    alignItems="center"
                    gap="5px">
                    <GasPump weight="fill" size={20} color="var(--text-2)" />
                    <CustomText
                      fontWeight="600"
                      text={metadata?.gas_fee?.value}
                    />
                    <CustomText
                      lineHeight="130%"
                      text={metadata?.gas_fee?.unit}
                    />
                  </CustomRow>
                )}
                {metadata?.total_value_locked && (
                  <CustomRow
                    borderRadius="40px"
                    border="1px solid var(--border-light)"
                    padding="6px 8px"
                    alignItems="center"
                    gap="5px">
                    <CustomText text="Total Value Locked:" />
                    <CustomText
                      fontWeight="600"
                      text={metadata?.total_value_locked?.display_value}
                    />
                    {metadata?.total_value_locked?.change?.direction >= 0 ? (
                      <CaretUp size={9} color="var(--success)" weight="fill" />
                    ) : (
                      <CaretDown size={9} color=" var(--error)" weight="fill" />
                    )}
                    <CustomText
                      color={
                        metadata?.total_value_locked?.change?.direction >= 0
                          ? "var(--success)"
                          : "var(--error)"
                      }
                      text={metadata?.total_value_locked?.change?.display_value}
                      fontWeight="700"
                    />
                  </CustomRow>
                )}
              </CustomRow>
              {add_to_wallet && <AddToWalletButton payload={add_to_wallet} />}
            </CustomRow>
            <Divider />
          </CustomColumn>
        </PaddingComponent>
      </>

      {/* <PaddingComponent height="16px" /> */}
      {/* <CustomRow alignItems="center" gap="6px">
        <CustomText text={`Details last updated 6 hrs ago`} />
        <ArrowClockwise size={14} color="var(--text-2)" weight="fill" />
      </CustomRow> */}
    </div>
  );
};

export default ChainInfo;
