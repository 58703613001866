import React from "react";
import { XYPlot, LineSeries, AreaSeries, GradientDefs } from "react-vis";
import classes from "./trendingItemChartPreview.module.css";
const TrendingItemChartPreview = ({
  graphData,
  width,
  height,
  color,
  isMobileSize = false,
  stopColor1,
  stopColor2,
  stopOpacity1,
  stopOpacity2,
  id = "CoolGradient",
}) => {
  if (!graphData) {
    return null;
  }
  const timeSeries = graphData.map((data) => {
    return {
      x: data[0],
      y: data[1],
    };
  });

  // const color = "var(--primary-color)";

  return (
    <div className={classes.chartPreview}>
      <XYPlot
        width={width}
        height={height}
        margin={{
          right: 0,
          left: 0,
          ...(isMobileSize ? { top: 0, bottom: 15 } : { top: 5, bottom: 5 }),
        }}>
        <GradientDefs>
          <linearGradient id={id} x1="0" x2="0" y1="0" y2="1">
            <stop
              offset="0%"
              stopColor={stopColor1}
              stopOpacity={stopOpacity1}
            />
            <stop
              offset="100%"
              stopColor={stopColor2}
              stopOpacity={stopOpacity2}
            />
          </linearGradient>
        </GradientDefs>
        <AreaSeries
          curve={"curveMonotoneX"}
          data={timeSeries}
          fill={`url(#${id})`}
          stroke="var(--base)"
        />
        <LineSeries
          data={timeSeries}
          curve={"curveMonotoneX"}
          color={color}
          strokeWidth={1}
          style={{
            stroke: color,
            fill: "none",
          }}
        />
      </XYPlot>
    </div>
  );
};

export default TrendingItemChartPreview;
