import classes from "./InfoTile.module.css";
import rightCircle from "assets/svg/profile/right-circle.svg";
import infoIcon from "assets/svg/profile/info.svg";
import infoNeutral from "assets/svg/profile/info_neutral.svg";
import Spinner from "shared/buttonloadingSpinner";
import buttonClasses from "components/UI/Buttons/buttons.module.css";

export const INFO_TILE_TYPE = {
  INFO: "info",
  SUCCESS: "success",
  NEUTRAL: "neutral",
};
const InfoTile = ({
  infoTileType,
  text,
  buttonText,
  buttonOnClick,
  isButtonLoading,
}) => {
  var indicatorClass = classes.neutral;
  var icon = infoNeutral;
  var textColor = classes.textColorNeutral;
  var iconColor = classes.iconColorNeutral;

  if (infoTileType === INFO_TILE_TYPE.INFO) {
    indicatorClass = classes.info;
    icon = infoIcon;
    iconColor = classes.iconColorInfo;
    textColor = classes.textColorInfo;
  } else if (infoTileType === INFO_TILE_TYPE.SUCCESS) {
    indicatorClass = classes.success;
    icon = rightCircle;
    textColor = classes.textColorSuccess;
    iconColor = classes.iconColorSuccess;
  }

  return (
    <div className={classes.box}>
      <div className={classes.contentBox}>
        <div className={classes.iconTextRow}>
          <img className={`${iconColor}`} src={icon} alt={infoTileType}></img>
          <span className={`${classes.text} ${textColor}`}>{text}</span>
        </div>
        {buttonText && (
          <button
            onClick={buttonOnClick}
            className={buttonClasses.primaryButton}>
            {isButtonLoading ? (
              <Spinner
                style={{
                  stroke: "white",
                  background: "transparent",
                  width: "40px",
                  height: "20px",
                  alignSelf: "center",
                }}
              />
            ) : (
              buttonText
            )}
          </button>
        )}
      </div>
      <div className={`${classes.indicator} ${indicatorClass}`}></div>
    </div>
  );
};
export default InfoTile;
