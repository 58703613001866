import React, { useState } from "react";
import { getTrendingContractDetails } from "api/trending.api";

import styles from "./TrendingDetailsPage.module.css";
import { Divider, PaddingComponent } from "components/UI/Components/Components";
import withLoader from "components/core/withLoader";
import {
  TrendingDetailsDescription,
  TrendingDetailsHeaderV2,
  TrendingDetailsLoader,
  TrendingTokenPriceWrapper,
  getTokenDetails,
} from "./trending_details_utils";
import TokenDetailsSideBar from "components/UI/TokenDetailsSideBar/TokenDetailsSideBar";
import CustomLinkTabBar from "components/UI/Components/CustomLinkTabBar";
import { TOP_USERS_TAB_KEYS } from "utils/constants";
import AuditFlags from "components/Trending/AuditFlags";
import TopUsers from "../Token/TopUsers/TopUsers";
import TimelineGraphWrapper from "../ChartGraph/TimelineGraphWrapper";
import NftSaleCard from "../NftSaleCard/NftSaleCard";
import TokenSwapCard from "../TokenSwapCard/TokenSwapCard";
import TrendingTokensListSmall from "../TrendingTokensListSmall/TrendingTokensListSmall";
import { FeedContainer } from "components/UI/Components/ResizeFeedComponent";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const TrendingDetailsPage = ({
  chainId,
  contractAddress,
  queryData: contractDetails,
  refetch,
}) => {
  const metadata = contractDetails.trending_metadata;
  const profile = metadata.token_profile;
  const isNft = !profile.is_token;
  const displayPicture = profile?.display_picture ?? null;
  const displayName = profile?.display_name ?? "";
  const description = metadata?.description ?? "";
  const tokenProfileLink = profile
    ? `${profile.address_link}`
    : window.location.href;
  const tokenSymbol = profile.token_details?.symbol ?? "";
  const tokenDetails = contractDetails.additional_info.additional_details ?? [];
  const chainProfile =
    contractDetails.trending_metadata?.chain_profiles[chainId] ?? null;
  const nativeChainDetails = chainProfile?.native_token_details ?? {
    name: "",
    symbol: "",
    logo: "",
  };
  const coinGeckoSlug = contractDetails.coingecko_slug;
  const { symbol: nativeChainSymbol, logo: nativeChainLogo } =
    nativeChainDetails;
  const {
    negative: redFlags,
    positive: greenFlags,
    neutral: neutralFlags,
    profiles: auditProfiles,
  } = contractDetails.additional_info?.audit ?? {
    negative: [],
    positive: [],
    neutral: [],
  };
  const isWatchListed =
    contractDetails.additional_info?.is_watchlisted ?? false;
  const isSellEnabled =
    contractDetails.additional_info?.is_sell_enabled ?? false;
  const priceData = metadata.token_details?.["24h_price_history"] ?? [];
  const priceDisplay = metadata.token_details?.price?.display_value ?? null;
  const hasPriceData = priceData.length > 0;
  const priceChangeDisplay =
    metadata.token_details?.["24h_percent_change"]?.display_value ?? "";
  const priceChangeValue =
    metadata.token_details?.["24h_percent_change"]?.value;
  const floorPrice = metadata.nft_details?.floor_price?.display_value ?? null;

  const buyUrl = contractDetails.buyUrl ?? null;
  const sellUrl = contractDetails.sellUrl ?? null;

  const isSolana = chainId?.toLowerCase() === "solana";

  const headerProps = {
    displayPicture,
    displayName,
    tokenSymbol,
    tokenProfileLink,
    isNft,
    chainId,
    contractAddress,
    coinGeckoSlug,
    buyUrl,
    sellUrl,
    isWatchListed,
    isSellEnabled,
    refetch,
  };
  const descriptionProps = {
    displayName,
    displayPicture,
    description,
  };
  const [tokenSellerTab, setTokenSellerTab] = useState(0);
  const [tokenPriceInfoTab, setTokenPriceInfoTab] = useState(0);
  const TOKEN_SELLER_TAB_KEYS = {
    title: isNft ? "Recent Sales" : "Recent Swaps",
    key: isNft ? "recent_sales" : "recent_swaps",
  };
  const TOKEN_SELLER_TABS = [TOKEN_SELLER_TAB_KEYS, ...TOP_USERS_TAB_KEYS].map(
    (t) => t.title
  );
  const priceChartKey = hasPriceData ? { "Price chart": "price-chart" } : {};
  const TOKEN_PRICE_TAB_KEYS = isSolana
    ? priceChartKey
    : {
        ...priceChartKey,
        "TVL Info": "tvl-info",
      };
  const TOKEN_PRICE_TABS = Object.keys(TOKEN_PRICE_TAB_KEYS);
  const renderHolderTabContent = () => {
    switch (tokenSellerTab) {
      case 0: {
        return (
          <div className={styles["recent-swaps-container"]}>
            {contractDetails.recent_swaps.map((swp, idx) => {
              const txLink = swp.transaction_details.tx_link;
              const nftProps = isNft
                ? {
                    displayPicture: swp.nft_image,
                    nativeChainLogo,
                    nativeChainSymbol,
                    action: swp.token_details?.action,
                    displayValue:
                      swp.token_details?.amount?.display_value ?? null,
                    txLink,
                    timestamp: swp.timestamp,
                    actorAvatar: swp.actor?.display_picture ?? null,
                    swapSymbol: swp.token_details?.symbol,
                  }
                : null;
              const tokenProps = !isNft
                ? {
                    swap: swp,
                    symbol: tokenSymbol,
                    txLink,
                  }
                : null;
              return isNft ? (
                <NftSaleCard
                  key={idx}
                  {...nftProps}
                  className={styles["sale-card"]}
                />
              ) : (
                <TokenSwapCard key={idx} {...tokenProps} />
              );
            })}
          </div>
        );
      }
      case 1:
      case 2:
      case 3:
        return (
          <TopUsers
            address={contractAddress}
            chainId={chainId}
            borderPosition="bottom"
            showTabBar={false}
            customTab={TOKEN_SELLER_TABS[tokenSellerTab]}
            giveTopPadding={true}
            size={"REGULAR"}
          />
        );
      default:
        return null;
    }
  };

  const renderPriceTabContent = () => {
    const tab = TOKEN_PRICE_TAB_KEYS[TOKEN_PRICE_TABS[tokenPriceInfoTab]];
    switch (tab) {
      case "price-chart": {
        const props = {
          priceData,
          priceDisplay,
          hasPriceData,
          priceChangeDisplay,
          priceChangeValue,
          chainId,
          contractAddress,
          isNft,
          floorPrice,
          nativeChainSymbol,
        };
        return <TrendingTokenPriceWrapper {...props} />;
      }
      case "tvl-info": {
        if (isSolana) return null;
        return (
          <TimelineGraphWrapper
            protocolStats={contractDetails.statistics?.timeline}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <BaseWrapper mixpanelEvent="TrendingDetailsPage">
      <div className={styles["page-container"]}>
        <FeedContainer>
          <PaddingComponent className={styles["page-content"]}>
            <TrendingDetailsHeaderV2 {...headerProps} />
            <Divider />
            <PaddingComponent padding="16px">
              <TrendingDetailsDescription {...descriptionProps} />
              {/* Token price tabs */}
              <div className={styles["price-tab-container"]}>
                <CustomLinkTabBar
                  activeTab={tokenPriceInfoTab}
                  setActiveTab={setTokenPriceInfoTab}
                  tabsList={TOKEN_PRICE_TABS}
                  isLinkTabBar={false}
                />
                {renderPriceTabContent()}
              </div>
              {/* Token buyer/seller tabs */}
              {!isSolana && (
                <div className={styles["holder-tab-container"]}>
                  <CustomLinkTabBar
                    activeTab={tokenSellerTab}
                    setActiveTab={setTokenSellerTab}
                    tabsList={TOKEN_SELLER_TABS}
                    isLinkTabBar={false}
                  />
                  <PaddingComponent
                    className={styles["holder-tab-content"]}
                    paddingTop="12px"
                    paddingBottom="12px"
                    paddingLeft="0">
                    {renderHolderTabContent()}
                  </PaddingComponent>
                </div>
              )}
            </PaddingComponent>
            {/* Audit / flags */}
            <PaddingComponent
              className={styles["flags-container"]}
              padding="4px 16px">
              {/* Green flags */}
              <AuditFlags
                data={greenFlags}
                type="green"
                profiles={auditProfiles}
              />
              {/* Neutral flags */}
              <AuditFlags
                data={neutralFlags}
                type="neutral"
                profiles={auditProfiles}
              />
              {/* Red flags */}
              <AuditFlags data={redFlags} type="red" profiles={auditProfiles} />
            </PaddingComponent>
          </PaddingComponent>
        </FeedContainer>
        <PaddingComponent
          className={styles["page-right-bar"]}
          padding="16px 20px">
          <TokenDetailsSideBar
            list={getTokenDetails({
              isNft,
              tokenDetails,
              floorPrice,
              nativeChainDetails,
              nativeChainSymbol,
            })}
            symbol={tokenSymbol}
            title={isNft ? "NFT Details" : "Token Details"}
            isNft={isNft}
          />
          <Divider />
          <TrendingTokensListSmall />
        </PaddingComponent>
      </div>
    </BaseWrapper>
  );
};

export default withLoader(TrendingDetailsPage, getTrendingContractDetails, {
  queryKey: ({ chainId, contractAddress }) => [
    "trending_token",
    chainId,
    contractAddress,
  ],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  CustomLoader: TrendingDetailsLoader,
});
