import { useCallback, useContext } from "react";
import { signedRequest } from "./api";
import { useMutation } from "@tanstack/react-query";
import { GlobalContext } from "contextStates/Global";
import {
  WALLET_CHAIN_MISCONF,
  signAndSendTransaction,
} from "contextStates/auth_utils";
import { getChainIds } from "api/contract.api";
import { useQuery } from "@tanstack/react-query";

export const useReadContractMutation = ({
  setIsSuccess,
  setIsError,
  setErrorMessage,
  setShowAddToWallet,
  setResponse,
  isRead,
  ethChainId,
  chainId,
}) => {
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const readContractMutation = useCallback(
    async ({
      chainId,
      contractAddress,
      functionName,
      argumentNames,
      argumentValues,
    }) => {
      const response = await signedRequest({
        method: "post",
        path: `/api/v4/read_or_write_contract_function_value`,
        bodyText: JSON.stringify({
          read_or_write: isRead ? "read" : "write",
          chain_id: chainId,
          contract_address: contractAddress,
          function_name: functionName,
          argument_names: argumentNames,
          argument_values: argumentValues,
        }),
      });

      return response.data;
    },
    [isRead]
  );

  const signTransaction = (txn) => {
    const transaction = { ...txn, ethChainId, chainId };
    signAndSendTransaction(transaction).catch((error) => {
      if (error?.message === WALLET_CHAIN_MISCONF) {
        const msg = `Wallet does not have the chain ${transaction.chainId} (chain id: ${transaction.ethChainId}) configured`;
        handleErrorSnackbar?.(null, msg);
        setShowAddToWallet(true);
      } else {
        handleErrorSnackbar(null, error?.message);
      }
    });
  };
  return useMutation({
    mutationFn: readContractMutation,
    onSuccess: (response) => {
      setIsSuccess(true);
      setResponse(response.data);
      if (!!response.data.to_sign_txn) {
        signTransaction(response.data.to_sign_txn);
      }
    },
    onError: (err) => {
      const responseMessage = err?.response?.data?.errors?.errors;
      setIsError(true);
      handleErrorSnackbar?.(null, responseMessage ?? err?.message);
      setErrorMessage?.(responseMessage ?? err?.message);
    },
  });
};

export const useSimulationMutation = ({
  setIsSuccess,
  setIsError,
  setErrorMessage,
  setSimulationResponse,
}) => {
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const simulationMutation = useCallback(
    async ({
      chainId,
      contractAddress,
      functionName,
      argumentNames,
      argumentValues,
      simulatedWallet,
    }) => {
      const response = await signedRequest({
        method: "post",
        path: `/api/v4/simulate_read_or_write_contract_function`,
        bodyText: JSON.stringify({
          chain_id: chainId,
          function_name: functionName,
          argument_names: argumentNames,
          argument_values: argumentValues,
          sender: simulatedWallet,
          receiver: contractAddress,
        }),
      });

      return response.data;
    },
    []
  );
  return useMutation({
    mutationFn: simulationMutation,
    onSuccess: (response) => {
      setIsSuccess(true);
      setSimulationResponse(response.data);
    },
    onError: (err) => {
      const responseMessage = err?.response?.data?.errors?.errors;
      setIsError(true);
      handleErrorSnackbar?.(null, responseMessage ?? err?.message);
      setErrorMessage?.(responseMessage ?? err?.message);
    },
  });
};

export const useFetchChainIds = () => {
  const { data: chainIds } = useQuery({
    queryKey: ["get_chain_ids"],
    queryFn: getChainIds,
    retry: 2,
    retryOnMount: false,
  });

  return { chainIds };
};
