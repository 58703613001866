import { BookmarkSimple, Export, Heart } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { getCommentSuggestionsOnPnlInFeed, getEngagements } from "api/feed.api";
import { PostGeneratedComment } from "components/FeedItemV2/WelcomePostsList/WelcomePostActions";
import {
  CustomColumn,
  CustomRow,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import { AuthContext } from "contextStates/AuthContext";
import { useContext, useEffect, useMemo, useState } from "react";
import CommentButton from "./CommentButton";
import CurrentEngagements from "./CurrentEngagements/CurrentEngagements";
import ReactionButton from "./Reactions";
import RepostButton from "./RepostButton";
import ShareButton from "./ShareButton";
import classes from "./activityEngagements.module.css";
import { getEngagementsQueryKey, useInteractionMutation } from "./utils";
import {
  ArrowsCounterClockwise,
  ChatTeardropText,
} from "@phosphor-icons/react";
import { ONE_DAY, QueryKeyConstants } from "utils/constants";
import Comments from "components/Feed/GroupedActivityDetails/Comments";
import { QUERY_KEYS } from "utils/query_utils";
import { createPortal } from "react-dom";
import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import { isEngagement, shuffleArray } from "utils/misc";
import UsersListRightPanel from "components/Feed/GroupedActivityDetails/UsersListRightPanel/UsersListRightPanel";
import useQueryParams from "customHooks/useQueryParams";
import { useQueryClient } from "@tanstack/react-query";

const ActivityEngagements = ({
  groupedActivity,
  engagements: initialEngagements,
  contentId,
  hideItems = {},
  hideCount = false,
  hideLikeLabel = false,
  noPadding = false,
  className,
  isActivityDetails = false,
  hasRoundedButtons = false,
  contentTimestamp,
  type,
  setLikesCount,
  setBookmarksCount,
  postAncestors,
  customShareLink,
  shouldDirectlyCopy = false,
  showPnlComments = false,
  combinedPnlPercentage,
  conditionalLink,
  showCurrEngagaments = true,
  showDivider = true,
  postDetails,
  showComments = false,
  hideReactions = false,
  showCommentCount = false,
}) => {
  const queryKey = getEngagementsQueryKey(type, contentId);
  const { data: engagements } = useQuery({
    queryKey,
    queryFn: async () => {
      return getEngagements({
        id: contentId,
        type: type,
      });
    },
    staleTime: ONE_DAY,
    initialData: initialEngagements,
    enabled: !!contentId && !!type,
  });
  const queryClient = useQueryClient();
  useEffect(() => {
    if (groupedActivity?.type === "post" && !isActivityDetails) {
      queryClient.setQueryData(
        [
          QueryKeyConstants.ENGAGEMENTS,
          groupedActivity?.id?.toString(),
          groupedActivity?.type,
        ],
        groupedActivity?.engagements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedActivity, isActivityDetails]);
  const [pnlCommentsVisible, setPnlCommentsVisible] = useState(showPnlComments);
  const { identityDetails } = useContext(AuthContext);

  useEffect(() => {
    if (showPnlComments && engagements?.replies?.count) {
      const involved_identity_ids = engagements?.replies?.threads?.map(
        (thread) => thread.actor?.identity_id
      );
      if (
        !involved_identity_ids?.includes(identityDetails?.current?.identity?.id)
      ) {
        setPnlCommentsVisible(true);
      }
    } else {
      setPnlCommentsVisible(showPnlComments);
    }
  }, [
    showPnlComments,
    identityDetails,
    engagements?.replies,
    pnlCommentsVisible,
  ]);

  const actionButtonsInfoLeft = [
    {
      icon: <Heart size={isActivityDetails ? 18 : 20} color="var(--text-2)" />,
      iconFill: (
        <Heart
          size={isActivityDetails ? 18 : 20}
          color="var(--error)"
          weight="fill"
        />
      ),
      count: engagements?.likes?.count,
      needsLogin: true,
      type: "likes",
    },
    {
      icon: (
        <ChatTeardropText
          size={isActivityDetails ? 18 : 20}
          color="var(--text-2)"
        />
      ),

      count: engagements?.replies?.count,
      needsLogin: true,
      type: "comments",
    },
  ];
  const actionButtonsInfoRight = [
    {
      icon: (
        <ArrowsCounterClockwise
          size={isActivityDetails ? 18 : 20}
          color="var(--text-2)"
        />
      ),
      count: engagements?.reposts?.count,
      needsLogin: true,
      type: "reposts",
    },
    {
      icon: (
        <BookmarkSimple
          size={isActivityDetails ? 18 : 20}
          color="var(--text-2)"
        />
      ),
      iconFill: (
        <BookmarkSimple
          size={isActivityDetails ? 18 : 20}
          color="var(--accent-1)"
          weight="fill"
        />
      ),
      count: engagements?.bookmarks?.count,
      needsLogin: true,
      type: "bookmarks",
    },
    {
      icon: <Export size={isActivityDetails ? 18 : 20} color="var(--text-2)" />,
      type: "share",
    },
  ];
  const buttonClassMap = {
    comments: classes.commentButton,
    likes: classes.likeButton,
    reposts: classes.shareButton,
    bookmarks: classes.bookmarkButton,
    share: classes.shareButton,
  };
  const identifier = identityDetails?.current?.identity?.id;
  const { data: pnlCommentSuggestions } = useQuery({
    queryKey: QUERY_KEYS.PNL_GENERATED_COMMENTs({ identifier }),
    queryFn: () => getCommentSuggestionsOnPnlInFeed(),
    enabled: pnlCommentsVisible,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: ONE_DAY,
  });

  const pnlCommentSuggestionsBucket = Object.keys(pnlCommentSuggestions ?? {})
    ?.map((b) => parseInt(b))
    ?.sort((a, b) => +b - +a)
    .find((b) => parseInt(combinedPnlPercentage) >= b);
  const isEngagementsEmpty = !(
    !!engagements?.likes?.count ||
    !!engagements?.replies?.count ||
    !!engagements?.reposts?.count ||
    !!engagements?.bookmarks?.count
  );
  const hasComments = !!engagements?.replies?.count;
  const showCurrentEngagements =
    showCurrEngagaments && (isEngagementsEmpty || showPnlComments);
  const { engagement_type } = useQueryParams();
  return (
    <>
      {isEngagementsEmpty && pnlCommentsVisible && (
        <PaddingComponent height="8px" />
      )}
      {!isEngagementsEmpty && groupedActivity?.type === "post" && (
        <PaddingComponent height="12px" />
      )}
      {showCurrEngagaments && (
        <CustomColumn overflowY="visible" overflowX="visible">
          <CustomRow
            className={classes.currentEngagements}
            overflowX="visible"
            overflowY="visible">
            <CurrentEngagements
              contentId={contentId}
              type={type}
              setPnlCommentsVisible={setPnlCommentsVisible}
            />
          </CustomRow>
          {!isEngagementsEmpty && (
            <Divider
              margin={`12px 0 ${pnlCommentsVisible ? "8px" : "4px"} 0`}
            />
          )}
        </CustomColumn>
      )}
      {pnlCommentsVisible && (
        <CustomColumn>
          {!showCurrentEngagements && <Divider margin="8px 0 12px 0" />}
          <div className={classes.generatedCommentsRow}>
            {shuffleArray(
              pnlCommentSuggestions?.[pnlCommentSuggestionsBucket]
            )?.map((comment, index) => (
              <PostGeneratedComment
                key={index}
                comment={comment}
                contentId={contentId}
                contentType={type}
              />
            ))}
          </div>
          <Divider margin={`8px 0 4px 0`} />
        </CustomColumn>
      )}

      <div
        className={`${classes.activityEngagements} ${
          type === "news" && !isActivityDetails && classes.padding
        } ${className}`}>
        <CustomRow
          alignItems={"center"}
          className={classes.engagementsRow}
          overflowX="visible"
          overflowY="visible"
          gap="8px">
          {actionButtonsInfoLeft.map((item) => {
            return (
              !hideItems[item.type] && (
                <InteractionButtonWrapper
                  key={`${contentId}-action-button-${item.type}`}
                  className={buttonClassMap[item.type]}
                  icon={item.icon}
                  iconFill={item.iconFill}
                  contentId={contentId}
                  postDetails={postDetails}
                  engagements={engagements?.[item.type]}
                  count={item.count}
                  currentIdentityId={identityDetails?.current?.identity?.id}
                  type={item.type}
                  needsLogin={item.needsLogin}
                  hideCount={hideCount}
                  hideLikeLabel={hideLikeLabel}
                  isActivityDetails={isActivityDetails}
                  hasRoundedButtons={hasRoundedButtons}
                  contentType={type}
                  contentTimestamp={contentTimestamp}
                  setLikesCount={setLikesCount}
                  setBookmarksCount={setBookmarksCount}
                  queryKey={queryKey}
                  postAncestors={postAncestors}
                  customShareLink={customShareLink}
                  shouldDirectlyCopy={shouldDirectlyCopy}
                  conditionalLink={conditionalLink}
                  hideReactions={hideReactions}
                  showCommentCount={showCommentCount}
                />
              )
            );
          })}
        </CustomRow>
        <CustomRow gap="8px" alignItems="flex-start" flexShrink="0">
          {actionButtonsInfoRight.map((item) => {
            return (
              !hideItems[item.type] && (
                <InteractionButtonWrapper
                  key={`${contentId}-action-button-${item.type}`}
                  className={buttonClassMap[item.type]}
                  icon={item.icon}
                  iconFill={item.iconFill}
                  contentId={contentId}
                  postDetails={postDetails}
                  engagements={engagements?.[item.type]}
                  count={item.count}
                  currentIdentityId={identityDetails?.current?.identity?.id}
                  type={item.type}
                  needsLogin={item.needsLogin}
                  hideCount={hideCount}
                  isActivityDetails={isActivityDetails}
                  hasRoundedButtons={hasRoundedButtons}
                  contentType={type}
                  contentTimestamp={contentTimestamp}
                  setLikesCount={setLikesCount}
                  setBookmarksCount={setBookmarksCount}
                  queryKey={queryKey}
                  postAncestors={postAncestors}
                  customShareLink={customShareLink}
                  shouldDirectlyCopy={shouldDirectlyCopy}
                  conditionalLink={conditionalLink}
                />
              )
            );
          })}
        </CustomRow>
      </div>
      {hasComments && showComments && showDivider && (
        <Divider margin="4px 0 8px 0" />
      )}
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ display: showComments ? "block" : "none" }}>
        {hasComments && showComments && (
          <Comments
            groupedActivity={groupedActivity ?? postDetails}
            postDetails={postDetails ?? groupedActivity}
          />
        )}
      </div>

      {createPortal(
        <RightPanel>
          {isEngagement({
            engagement_type,
          }) && (
            <UsersListRightPanel
              id={contentId}
              type={type}
              engagements={engagements}
              engagementType={engagement_type}
              identities={postDetails?.identities}
            />
          )}
        </RightPanel>,
        document.getElementById("rightPanel")
      )}
    </>
  );
};

export const InteractionButton = ({
  className,
  isButtonActive = false,
  handleClick,
  hideCount = false,
  icon,
  iconFill,
  count,
  needsLogin,
  hasRoundedButtons,
  text,
  activeText,
}) => {
  const { isUserLoggedIn, setShowLoginPopup } = useContext(AuthContext);

  return (
    <button
      className={`${classes.activityActionButton} ${className} `}
      onClick={(e) => {
        if (needsLogin && !isUserLoggedIn) {
          setShowLoginPopup(true);
          e.stopPropagation();
          return;
        }
        handleClick(e);
      }}>
      <div
        className={`${classes.iconWrapper} ${
          hasRoundedButtons ? classes.hasRoundedButtons : ""
        }`}>
        {isButtonActive ? iconFill ?? icon : icon}
        {text && (
          <div
            style={{
              color: activeText ? "var(--primary-color)" : "var(--text-2)",
              fontWeight: "500",
              fontSize: "13px",
            }}>
            {text}
          </div>
        )}
      </div>
      {!hideCount && <div className={classes.count}>{count}</div>}
    </button>
  );
};

export const InteractionButtonWrapper = ({
  contentId,
  className,
  icon,
  iconFill,
  engagements,
  count = 0,
  currentIdentityId,
  type,
  hideCount = false,
  hideLikeLabel = false,
  isActivityDetails = false,
  contentType,
  contentTimestamp,
  needsLogin = false,
  setLikesCount,
  setBookmarksCount,
  queryKey,
  postAncestors,
  hasRoundedButtons = false,
  customShareLink,
  shouldDirectlyCopy = false,
  onSuccess,
  postDetails,
  hideReactions = false,
  showCommentCount = false,
}) => {
  const isBookmark = type === "bookmarks";
  const isLike = type === "likes";
  const initialEngagementState = useMemo(() => {
    if (isBookmark) {
      return engagements?.is_bookmarked;
    } else if (isLike) {
      return engagements?.is_liked;
    }
  }, [engagements, isBookmark, isLike]);

  const [isButtonActive, setIsButtonActive] = useState(
    !!initialEngagementState
  );
  const [emojiId, setEmojiId] = useState(
    engagements?.is_liked ? engagements?.selected_emoji_id || 1 : null
  );

  const { handleInteractionToggle } = useInteractionMutation({
    contentId,
    isActive: !isButtonActive,
    currentIdentityId,
    isActivityDetails,
    contentType,
    interactionType: type,
    setIsButtonActive,
    setEmojiId,
    setLikesCount,
    setBookmarksCount,
    queryKey,
    onSuccess,
    oldEmojiId: emojiId,
  });

  const handleClick = (event, emojiId) => {
    event.stopPropagation();
    if (!contentId) return;
    if (!["likes", "bookmarks"].includes(type)) {
      return;
    }

    setIsButtonActive((prev) => !prev);

    if (handleInteractionToggle) {
      handleInteractionToggle({
        isActive: !isButtonActive,
        emojiId,
        contentId,
        type,
      });
    }
  };

  useEffect(() => {
    if (!!initialEngagementState) {
      setIsButtonActive(count !== 0);
    }
  }, [count, initialEngagementState]);

  if (type === "share") {
    return (
      <ShareButton
        className={className}
        icon={icon}
        contentId={contentId}
        contentType={contentType}
        needsLogin={needsLogin}
        hasRoundedButtons={hasRoundedButtons}
        customShareLink={customShareLink}
        shouldDirectlyCopy={shouldDirectlyCopy}
      />
    );
  }

  if (type === "reposts") {
    return (
      <RepostButton
        className={className}
        icon={icon}
        count={engagements?.replies?.count ?? 0}
        contentId={contentId}
        contentType={contentType}
        contentTimestamp={contentTimestamp}
        needsLogin={needsLogin}
        postAncestors={postAncestors}
        hideCount={hideCount}
      />
    );
  }

  if (type === "comments") {
    return (
      <CommentButton
        className={className}
        icon={icon}
        count={count}
        contentId={contentId}
        postDetails={postDetails}
        contentType={contentType}
        needsLogin={needsLogin}
        postAncestors={postAncestors}
        hideCount={hideCount}
        showCommentCount={showCommentCount}
      />
    );
  }

  if (type === "likes") {
    return (
      <>
        <ReactionButton
          hideReactions={hideReactions}
          className={className}
          isButtonActive={isButtonActive}
          handleClick={handleClick}
          hideCount={hideCount}
          icon={icon}
          iconFill={iconFill}
          hideLikeLabel={hideLikeLabel}
          count={count}
          type={type}
          needsLogin={needsLogin}
          hasRoundedButtons={hasRoundedButtons}
          setEmojiId={setEmojiId}
          emojiId={emojiId}
          handleInteractionToggle={handleInteractionToggle}
        />
      </>
    );
  }

  return (
    <>
      <InteractionButton
        className={className}
        isButtonActive={isButtonActive}
        handleClick={handleClick}
        hideCount={hideCount}
        icon={icon}
        iconFill={iconFill}
        count={count}
        type={type}
        needsLogin={needsLogin}
        hasRoundedButtons={hasRoundedButtons}
      />
    </>
  );
};

export default ActivityEngagements;
