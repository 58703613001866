import withLoader from "components/core/withLoader";
import classes from "./SelectedEdge.module.css";
import { getLinkData } from "api/graph.api";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Ghost from "./Ghost";
// import BundleSuggestionHoverCard from "components/GroupFeedItem/components/Bundle/BundleSuggestionHoverCard";
import { Divider, PaddingComponent } from "components/UI/Components/Components";
import ProfileHoverCard from "../Profile/ProfileHoverCard/ProfileHoverCard";
import ConditionalLink from "shared/ConditionalLink";
import { getTrimedAddress, timeAgo } from "utils/misc";

export const ValueStyle = {
  border: 0,
  padding: "6px 0",
  marginTop: "16px",
};

export const HeaderStyle = {
  border: 0,
  fontF: "Roboto",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "150%",
  /* identical to box height, or 18px */

  letterSpacing: "0.08em",
  textTransform: "uppercase",
  backgroundColor: "var(--base)",

  /* Text II */
  padding: "8px 0",

  color: "var(--text-2)",
};

export const RowStyle = {
  backgroundColor: "var(--base)",
  "&:hover": {
    backgroundColor: "var(--elevation-2)",
  },
  padding: "0 8px",
};

export const MiniProfile = ({ profile, marginLeft, showAddress = false }) => {
  return (
    <ProfileHoverCard actor={profile} onActorUpdate={() => {}}>
      <Link
        className={classes.mini_profile}
        style={{ marginLeft: marginLeft ? "8px" : "0" }}
        to={profile?.link}
        target="_blank">
        <Image
          src={profile?.display_picture}
          alt={profile?.display_name}
          className={classes.image}
        />
        <div className={classes.miniProfileText}>
          {profile?.display_name?.slice(0, 18) ?? "-"}
          {profile?.display_name?.length > 18 ? "..." : ""}
          {showAddress && profile?.address
            ? `: ${profile?.address?.slice(2, 5)}`
            : ""}
        </div>
      </Link>
    </ProfileHoverCard>
  );
};

export const TableProfile = ({
  profile,
  marginLeft,
  address = null,
  disableHover = false,
  abbreviateLength = 10,
  link = profile?.link,
  width = "fit-content",
}) => {
  const rootComponent = (
    <ConditionalLink
      sameTab={false}
      to={link}
      className={classes.mini_profile}
      style={{ marginLeft: marginLeft ? "8px" : "0", width }}>
      <Image
        src={profile?.display_picture}
        alt={profile?.display_name}
        className={classes.image}
        name={profile?.display_name}
        imageType={IMAGE_TYPES.AVATAR}
      />
      <div className={classes.table_info}>
        <div>
          {" "}
          {profile?.display_name.slice(0, abbreviateLength) ?? "-"}
          {profile?.display_name?.length > abbreviateLength ? "..." : ""}
        </div>
        {address && (
          <div className={classes.address}>{getTrimedAddress(address)}</div>
        )}
      </div>
    </ConditionalLink>
  );

  if (disableHover) {
    return rootComponent;
  }

  return (
    <ProfileHoverCard actor={profile} onActorUpdate={() => {}}>
      {rootComponent}
    </ProfileHoverCard>
  );
};

export const Value = ({ transaction, metadata }) => {
  const token =
    metadata[transaction.chain_str]?.[transaction.emitting_contract_address];
  return (
    <div className={classes.value_cell}>
      <div className={classes.top}>
        <span className={classes.value}>
          {token && transaction.decimal_adjusted_value?.display_value}{" "}
          {token?.symbol || token?.name || ""}
        </span>
      </div>
      <div className={classes.bottom}>
        <span className={classes.amount}>
          {transaction.usd_value?.display_value}
          {" · "}
          {timeAgo(transaction.block_timestamp * 1000, "trimmed")}
        </span>
      </div>
    </div>
  );
};

export const NFT = ({ transaction, metadata }) => {
  return (
    <div className={classes.nft}>
      <div className={classes.nft_text}>
        NFT {" · "} {timeAgo(transaction.block_timestamp * 1000, "trimmed")}
      </div>
      <Image
        src={transaction.nft_image_url}
        alt={transaction.nft_name}
        className={classes.nft_image}
        imageType={IMAGE_TYPES.NFT}
      />
    </div>
  );
};

export const getProfileAddressArray = (profile) => {
  return (
    profile?.bundle?.address_infos?.map((info) => info.address) || [
      profile.address,
    ]
  );
};

const Transactions = ({
  transfers,
  source,
  target,
  metadata,
  profiles,
  identifiers,
}) => {
  const sourceAddresses = getProfileAddressArray(source.data.details);
  const targetAddresses = getProfileAddressArray(target.data.details);
  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        paddingBottom: "12px",
        backgroundColor: "var(--base)",
      }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              borderBottom: "1px solid var(--text-2-10)",
            }}>
            <TableCell sx={{ ...HeaderStyle }}>From</TableCell>
            <TableCell align="left" sx={{ ...HeaderStyle }}>
              To
            </TableCell>
            <TableCell align="right" sx={{ ...HeaderStyle }}>
              Value
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transfers?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                border: 0,
                ...RowStyle,
                cursor: "pointer",
              }}
              onClick={() => {
                const isSolana = row.chain_str?.toLowerCase() === "solana";
                if (isSolana) {
                  window.open(`/tx/${row.tx_signature}`);
                  return;
                }
                window.open(
                  `/tx_id?chain_id=${row.chain_str}&tx_idx=${row.tx_idx}&actor=${row.to_address}&block_number=${row.block_number}`,
                  "_blank"
                );
              }}>
              <TableCell component="th" scope="row" sx={{ ...ValueStyle }}>
                <TableProfile
                  profile={
                    sourceAddresses.includes(row.from_address)
                      ? source.data.details
                      : {
                          display_name: getTrimedAddress(row.from_address),
                          address: row.from_address,
                          address_link: `/${row.from_address}`,
                          display_picture: null,
                        }
                  }
                  address={row.from_address}
                />
              </TableCell>
              <TableCell align="left" sx={{ ...ValueStyle }}>
                <TableProfile
                  profile={
                    targetAddresses.includes(row.from_address)
                      ? source.data.details
                      : target.data.details
                  }
                  address={row.to_address}
                />
              </TableCell>
              <TableCell align="right" sx={{ ...ValueStyle }}>
                {row.token_id ? (
                  <NFT transaction={row} metadata={metadata} />
                ) : (
                  <Value transaction={row} metadata={metadata} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// const BundleSuggestionSection = ({
//   relationship_data,
//   identityDetails,
//   profiles,
//   fetchGraph,
//   showConfidence,
//   showSubHeading,
//   title,
//   source,
//   target,
// }) => {
//   const keyAddress = Object.keys(relationship_data)?.[0];
//   const addressPairWiseSummary = relationship_data[keyAddress];

//   return (
//     <div className={classes.relationship_data}>
//       <PaddingComponent paddingBottom="20px">
//         <BundleSuggestionHoverCard
//           isBoundaryBox
//           showActions
//           actor={identityDetails.data.details}
//           source={source}
//           target={target}
//           methodIds={{}}
//           profiles={profiles}
//           relatedData={{
//             ...addressPairWiseSummary,
//             address: keyAddress,
//             related_with: identityDetails.data.details.address,
//             related_with_identity_id: identityDetails.data.details.id,
//           }}
//           showViewTransfers={false}
//           nativeTokensInfo={{}}
//           key={keyAddress}
//           fetchGraph={fetchGraph}
//           showSubHeading={showSubHeading}
//           showConfidence={showConfidence}
//           title={title}
//           disableDiscard
//         />
//       </PaddingComponent>
//     </div>
//   );
// };

const SelectedEdge = ({
  link,
  profile,
  queryData,
  fetchGraph,
  identifiers,
}) => {
  const {
    link_data,
    relationship_data,
    display_summary: showTextualsummary,
    // relationship_direction: relationshipDirection,
  } = queryData;
  const { transfers, metadata } = link_data;
  const keyAddress = Object.keys(relationship_data)?.[0];
  const addressPairWiseSummary = relationship_data[keyAddress];
  // const TARGET_TO_SOURCE = 1;

  return (
    <div className={classes.selected_edge}>
      <div className={classes.header}>
        <div>Showing transfers between</div>
        <MiniProfile profile={link.source.data.details} marginLeft />
        <div>and</div>
        <MiniProfile profile={link.target.data.details} marginLeft={false} />
      </div>

      {showTextualsummary && link_data?.textual_summary?.length > 0 && (
        <>
          <div className={classes.summary}>{link_data.textual_summary}</div>
          <PaddingComponent paddingTop="8px" paddingBottom="8px">
            <Divider />
          </PaddingComponent>
        </>
      )}

      {!addressPairWiseSummary?.are_related && (
        <div
          className={classes.divider}
          style={{ marginTop: "16px", marginBottom: "8px" }}></div>
      )}

      {/* <BundleSuggestionSection
        source={link.source.data.details}
        target={link.target.data.details}
        identityDetails={
          relationshipDirection === TARGET_TO_SOURCE ? link.target : link.source
        }
        profiles={{
          [link.source.data.details.address]: link.source.data.details,
          [link.target.data.details.address]: link.target.data.details,
        }}
        relationship_data={relationship_data}
        fetchGraph={fetchGraph}
        showConfidence={showTextualsummary}
        showSubHeading={showTextualsummary}
        title={showTextualsummary ? "Secondary Wallet Suggestion" : "Summary"}
      /> */}

      <div className={classes.top_holders}>
        <Transactions
          identifiers={identifiers}
          transfers={transfers}
          metadata={metadata}
          source={link.source}
          target={link.target}
        />
      </div>
    </div>
  );
};

const SelectedEdgeGhost = () => {
  return (
    <div className={classes.selected_edge}>
      <Ghost />
    </div>
  );
};

export default withLoader(SelectedEdge, getLinkData, {
  queryKey: (props) => [
    "SelectedEdge",
    props.link.source.id,
    props.link.target.id,
    props.startTime,
    props.endTime,
    ...props.tokenTuples,
    ...props.filterChains,
  ],
  CustomLoader: SelectedEdgeGhost,
});
