import React from "react";
import SkeletonElement from "shared/GhostLoader/GhostElement/GhostElement";
import Shimmer from "shared/GhostLoader/Shimmer/Shimmer";

const GhostByteCode = ({ background }) => {
  return (
    <div
      className={`ghost-byte-code contract-code__byte-code--wrapper background-${
        background ?? ""
      }`}>
      <SkeletonElement type="text" />
      <SkeletonElement type="text" />
      <SkeletonElement type="text" />
      <SkeletonElement type="text" />
      <SkeletonElement type="text" />
      <SkeletonElement type="text" />
      <Shimmer />
    </div>
  );
};

export default GhostByteCode;
