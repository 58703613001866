import styled from "styled-components/macro";

export const FeedContainer = styled.div`
  margin: auto;
  width: 100%;
  min-height: calc(100vh - 96px);
  margin-top: 16px;
  background-color: var(--base);
  border-radius: 8px;
  padding: ${(props) => (props.padding ? props.padding : "16px")};
  // Media Query for larger screens
  @media (min-width: 769px) {
    width: 800px;
  }
  @media (max-width: 768px) {
    width: 100vw;
    padding: 40px 16px;
  }
`;

export const RightBarContainer = styled.div`
  padding: 0 16px;
  width: 332px;
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`;
