import classes from "../../Settings.module.css";
import BasicInfoRowTile from "components/BasicInfoRowTile/BasicInfoRowTile";
import {
  CustomRow,
  Divider,
  InfoBox,
  CustomText,
} from "components/UI/Components/Components";

const BasicInfo = ({ userData }) => {
  return (
    <div className={classes.detailsContainer}>
      <CustomRow
        width="100%"
        justifyContent="space-between"
        alignItems="center">
        <span className={classes.heading}>Basic Info</span>
      </CustomRow>
      <InfoBox>
        <div className={classes.basicInfoColumn}>
          <BasicInfoRowTile
            title="Name"
            value={userData?.identity?.display_name}
          />
          <BasicInfoRowTile
            title="Username"
            value={userData?.identity?.username}
          />
          <BasicInfoRowTile
            title="Email"
            value={userData?.identity?.profile_email}
          />
          <Divider />
          <CustomText text="Note: User details are not editable at the moment." />
        </div>
      </InfoBox>
    </div>
  );
};

export default BasicInfo;
