import { getPriceDelta } from "components/V2/Token/TokenChart/ChartDataPriceUtils";
import { useQuery } from "@tanstack/react-query";
import {
  CustomColumn,
  CustomSkeleton,
  CustomText,
  PaddingComponent,
  PriceChange,
} from "components/UI/Components/Components";

import styles from "../TokenDetails/TokenDetails.module.css";
import { QUERY_KEYS } from "utils/query_utils";
import withNullableError from "zxComponents/common/withNullableError/index";

const TokenPrices = ({ slug, chainId, contractAddress }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_PRICE_PILLS({ chainId, contractAddress }),
    queryFn: () => getPriceDelta({ chainId, contractAddress }),
    retry: 1,
    staleTime: 1000 * 60 * 60,
    enabled: true,
    refetchInterval: 10000,
  });
  if (isLoading) {
    return <CustomSkeleton height="80px" width="100%" />;
  }
  const { priceChanges } = data ?? {};
  if (isError || (!isLoading && !priceChanges)) return null;
  return (
    <PaddingComponent padding="16px" className={styles.pricesContainer}>
      {priceChanges.map((change, index) => {
        return (
          <CustomColumn
            gap="4px"
            key={index}
            justifyContent="center"
            alignItems="center"
            className={`${styles.pricePill} ${
              index !== 0 ? styles.borderLeft : ``
            }`}>
            <CustomText
              text={change.label}
              color="var(--text-2)"
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              letterSpace="0.2px"
              textAlign="center"
            />
            <PriceChange
              displayValue={change.displayValue}
              value={change.direction ?? 0}
              showCaret={false}
              fontWeight="600"
            />
          </CustomColumn>
        );
      })}
    </PaddingComponent>
  );
};
export default withNullableError(TokenPrices);
