import {
  CustomColumn,
  CustomRow,
  CustomText,
  FlexGrow,
  InfoBox,
} from "components/UI/Components/Components";
import classes from "./GettingStarted.module.css";
import { CaretRight, CheckCircle } from "@phosphor-icons/react";

const GettingStartedTile = ({
  title,
  subtitle,
  onTap,
  isCompleted = false,
  Icon,
  bgColorIcon,
  iconColor,
}) => {
  return (
    <div
      onClick={onTap}
      style={{ cursor: "pointer", order: isCompleted ? 1 : 0 }}>
      <InfoBox padding="15px 16px">
        <CustomRow gap="16px" alignItems="center">
          <div
            className={classes.iconContainer}
            style={{
              backgroundColor: bgColorIcon,
            }}>
            <Icon size={24} color={iconColor} />
          </div>
          <FlexGrow>
            <CustomColumn alignItems="flex-start" gap="8px">
              <CustomText
                text={title}
                fontSize="16px"
                fontWeight="500"
                letterSpacing="-0.32px"
                color="var(--text-1)"
              />
              <CustomText
                text={subtitle}
                fontSize="13px"
                letterSpacing="-0.26px"
              />
            </CustomColumn>
          </FlexGrow>
          {isCompleted ? (
            <CheckCircle size={24} weight="fill" color="var(--success)" />
          ) : (
            <InfoBox
              radius="12px"
              padding="0"
              width="24px"
              height="24px"
              alignItems="center"
              justifyContent="center">
              <CaretRight size={16} color="var(--primary-color)" />
            </InfoBox>
          )}
        </CustomRow>
      </InfoBox>
    </div>
  );
};

export default GettingStartedTile;
