import { useCallback, useContext, useEffect, useState } from "react";
import UniversalGraph from "../UniversalGraph";

import GraphLoader from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";
import SelectedNode from "../SelectedNode";
import SelectedEdge from "../SelectedEdge";
import FiltersButton from "../Filters/ExplorerFilterButton";
import CustomSwitchButton from "components/UI/Components/CustomSwitch";
import ExplorerTimeline from "../ExplorerTimeline";
import { getRootNodes, TIMELINE_HEIGHT } from "../utils/explorer_utils";
import classes from "../Explorer.module.css";
import { ExplorerHeaderInput } from "../ExplorerHeaderInput";
import SelectedNodeTransfers from "../SelectedNodeTransfers";
import { PaddingComponent } from "components/UI/Components/Components";
import { GraphExplorerContext } from "../NavigatableExplorer";
import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import { EMPTY_STATES } from "zxStyles/constants";
import usePageTitle from "customHooks/usePageTitle";

export const GraphExplorerChildUI = ({
  chainData,
  isIdentityGraph,
  setIsIdentityGraph,
  selectedNode,
  setSelectedNode,
  selectedLink,
  setSelectedLink,
  selectLinkId,
  hoveredEdge,
  setHoveredEdge,
  timelines,
  isTimelineLoading,
  startTime,
  endTime,
  timeRange,
  fetchGraph,
  handleChange,
  onTimeRangeCommited,
  isLoading,
  isFetching,
  width,
  explorerHeight,
  identifiers,
  setIdentifiers,
  selectedActiveChains,
  setSelectedActiveChains,
  selectedActiveTokens,
  setSelectedActiveTokens,
  sliderStart,
  pickTs,
  sliderEnd,
  getUpdatedIdentifiers,
  nodeSize,
  tokenTuples,
  setTokenTuples,
  filterChains,
  setFilterChains,
}) => {
  const { isContractGraph, setIsContractGraph } =
    useContext(GraphExplorerContext);
  const [identifiersAddedList, setIdentifiersAddedList] = useState([]);
  useEffect(() => {
    const nodes = getRootNodes(chainData?.data?.graph?.nodes ?? []);
    setIdentifiersAddedList(nodes?.map((e) => e?.data?.details));
  }, [chainData]);
  usePageTitle({ title: "Graph Explorer - 0xPPL" });
  const onAddingIdentifier = useCallback(
    (key, val) => {
      setIdentifiers((prev) => {
        const updatedIdentifiers = getUpdatedIdentifiers();
        const identifier = val?.data?.id ?? val?.data?.address;
        if (updatedIdentifiers.includes(identifier)) {
          return updatedIdentifiers;
        }
        return `${updatedIdentifiers},${identifier}`;
      });
      setIdentifiersAddedList((prev) => [...prev, val?.data]);
    },
    [setIdentifiers, setIdentifiersAddedList, getUpdatedIdentifiers]
  );
  const onRemoveIdentifier = useCallback(
    (val) => {
      const updatedIdentifiers = getUpdatedIdentifiers();
      setIdentifiers((prev) => {
        const identifier = val?.id ?? val?.address;
        if (updatedIdentifiers.includes(identifier)) {
          //remove identifier
          const newIdentifiers = updatedIdentifiers
            .split(",")
            .filter((id) => id !== identifier.toString());
          return newIdentifiers.join(",");
        }
        return updatedIdentifiers;
      });
      const modifiedList = identifiersAddedList.filter((e) => e !== val);
      setIdentifiersAddedList(modifiedList);
    },
    [
      setIdentifiers,
      setIdentifiersAddedList,
      identifiersAddedList,
      getUpdatedIdentifiers,
    ]
  );

  return (
    <div className={classes.explorer}>
      <div className={classes.top_bar}>
        <div className={classes.results_for_container}>
          <div className={classes.results_for}>Showing Results for: </div>
          <ExplorerHeaderInput
            isGraphLoading={isLoading || isFetching}
            profilesList={identifiersAddedList}
            source="profile"
            isIdentityGraph={isIdentityGraph}
            canRemove={identifiersAddedList.length > 1}
            onRemove={onRemoveIdentifier}
            onAddValue={onAddingIdentifier}
          />
        </div>
        <div className={classes.top_bar_right}>
          <div className={classes.wallet_identity_slider}>
            <div
              className={
                isContractGraph ? classes.selected : classes.not_selected
              }>
              Show Contracts
            </div>
            <CustomSwitchButton
              toggle={isContractGraph}
              setToggle={() => {
                setIsContractGraph(!isContractGraph);
              }}
            />
            <div
              className={
                isIdentityGraph ? classes.selected : classes.not_selected
              }>
              Identity
            </div>
            <CustomSwitchButton
              toggle={!isIdentityGraph}
              setToggle={() => {
                setIsIdentityGraph(!isIdentityGraph);
              }}
            />
            <div
              className={
                !isIdentityGraph ? classes.selected : classes.not_selected
              }>
              Wallets
            </div>
          </div>

          <div className={classes.horizontal_divider}></div>
          <FiltersButton
            onSuccess={fetchGraph}
            identifiers={identifiers}
            selectedActiveChains={selectedActiveChains}
            setSelectedActiveChains={setSelectedActiveChains}
            selectedActiveTokens={selectedActiveTokens}
            setSelectedActiveTokens={setSelectedActiveTokens}
            tokenTuples={tokenTuples}
            setTokenTuples={setTokenTuples}
            filterChains={filterChains}
            setFilterChains={setFilterChains}
          />
        </div>
      </div>

      <div className={classes.body}>
        <div
          className={classes.selected_panel}
          style={{
            height: explorerHeight,
            overflowY: "auto",
          }}>
          {selectedNode && (
            <>
              <SelectedNode
                node={selectedNode}
                startTime={pickTs(startTime, sliderStart)}
                endTime={pickTs(endTime, sliderEnd)}
                selectedActiveChains={selectedActiveChains}
                selectedActiveTokens={selectedActiveTokens}
                setHoveredEdge={setHoveredEdge}
                hoveredEdge={hoveredEdge}
              />
              <PaddingComponent padding="0 20px 0 20px">
                <SelectedNodeTransfers
                  sliderStart={sliderStart}
                  sliderEnd={sliderEnd}
                  identifier={selectedNode?.id}
                  startTime={pickTs(startTime, sliderStart)}
                  endTime={pickTs(endTime, sliderEnd)}
                  selectedActiveChains={selectedActiveChains}
                  selectedActiveTokens={selectedActiveTokens}
                  tokenTuples={tokenTuples}
                  setTokenTuples={setTokenTuples}
                  filterChains={filterChains}
                  setFilterChains={setFilterChains}
                  setHoveredEdge={setHoveredEdge}
                  isGraph={true}
                  isGraphEmpty={
                    !chainData?.data?.graph ??
                    chainData?.data?.graph?.nodes?.length === 0
                  }
                />
              </PaddingComponent>
            </>
          )}
          {selectedLink && (
            <SelectedEdge
              identifiers={identifiers}
              link={selectedLink}
              profile={!isIdentityGraph}
              startTime={pickTs(startTime, sliderStart)}
              endTime={pickTs(endTime, sliderEnd)}
              selectedActiveChains={selectedActiveChains}
              selectedActiveTokens={selectedActiveTokens}
              tokenTuples={tokenTuples}
              filterChains={filterChains}
              fetchGraph={fetchGraph}
            />
          )}
          {chainData?.data?.graph?.nodes?.length === 0
            ? null
            : !selectedNode &&
              !selectedLink && <GraphLoader height={explorerHeight} />}
        </div>
        {chainData?.data?.graph?.nodes?.length !== 0 ? (
          <MiniGraph
            isFetching={isFetching}
            isLoading={isLoading}
            width={width - 650}
            height={explorerHeight}
            data={chainData?.data?.graph}
            setSelectedLink={setSelectedLink}
            setSelectedNode={setSelectedNode}
            selectedNode={selectedNode}
            selectedLink={selectedLink}
            selectLinkId={selectLinkId}
            setHoveredEdge={setHoveredEdge}
            hoveredEdge={hoveredEdge}
            nodeSize={nodeSize}
          />
        ) : (
          <ZxEmptyState state={EMPTY_STATES.CHART} text="No data available" />
        )}
      </div>

      {!isTimelineLoading && timelines?.length > 0 && (
        <ExplorerTimeline
          timelines={timelines}
          sliderStart={sliderStart}
          sliderEnd={sliderEnd}
          onTimeRangeCommited={onTimeRangeCommited}
          handleChange={handleChange}
          timeRange={timeRange}
          width={width}
          height={TIMELINE_HEIGHT}
        />
      )}
    </div>
  );
};

export const MiniGraph = ({
  isLoading,
  isFetching,
  data,
  height,
  width,
  setSelectedLink,
  setSelectedNode,
  selectedNode,
  selectedLink,
  selectLinkId,
  setHoveredEdge,
  hoveredEdge,
  mini,
  nodeSize,
}) => {
  if (isLoading || isFetching)
    return <GraphLoader width={width} height={height} />;
  return (
    <UniversalGraph
      graph={data}
      id="explorer-graph"
      height={height}
      width={width}
      setSelectedLink={setSelectedLink}
      setSelectedNode={setSelectedNode}
      selectedNode={selectedNode}
      selectedLink={selectedLink}
      selectLink={selectLinkId}
      setHoveredEdge={setHoveredEdge}
      hoveredEdge={hoveredEdge}
      mini={mini}
      nodeSize={nodeSize}
    />
  );
};
