import React from "react";
import styles from "./GenericButton.module.css";
const GenericButton = ({
  background = "var(--primary-color)",
  borderRadius = "4px",
  color = "var(--text-1)",
  children,
  customClass = null,
  onClick,
  disabled = false,
  padding = "10px 32px",
  type = "button",
  width = "auto",
  fontSize = "13px",
}) => {
  return (
    <button
      style={{ background, borderRadius, color, padding, width, fontSize }}
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`${styles.button} ${customClass ? customClass : ``} ${disabled ? styles.disabled : ``}`}>
      {children}
    </button>
  );
};

export default GenericButton;
