import classes from "./ContractHeader.module.css";
import { getForContractHeader } from "api/contract.api";
import withLoader from "components/core/withLoader";
import ContractInfo from "./ContractInfo/ContractInfo";
import { ProtocolHeaderGhost } from "components/V2/Protocol/ProtocolHeader/ProtocolHeader";

const ContractHeader = ({
  queryData,
  chain_id,
  address,
  tokenChartStats,
  forNative = false,
  refetch,
}) => {
  return (
    <div className={classes.contract_header}>
      <section className={classes.info}>
        <ContractInfo
          info={queryData}
          forNative={forNative}
          refetch={refetch}
          tokenChartStats={tokenChartStats}
        />
      </section>
    </div>
  );
};

export default withLoader(ContractHeader, getForContractHeader, {
  queryKey: ({ chain_id, address, forNative = false }) => [
    "contract",
    chain_id,
    address,
    forNative,
  ],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  renderNotFound: true,
  refetchContainerClass: classes.refetch_container,
  CustomLoader: ProtocolHeaderGhost,
});
