import {
  BuySellActionText,
  CustomColumn,
  CustomRow,
  CustomSkeleton,
  CustomText,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import styles from "./TokenPageTransfersTable.module.css";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import { abbreviateNumber, getTrimmedString } from "utils/misc";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import ConditionalLink from "shared/ConditionalLink";
import { Funnel, CaretRight, X } from "@phosphor-icons/react";
import {
  getFollowerSwapDetails,
  getTokenSwapDetails,
} from "api/token_page.api";
import Popup from "components/UI/Popup/Popup";
import React, { useCallback, useMemo, useState } from "react";
import GenericButton from "components/V2/GenericButton/GenericButton";
import { TimeLabel } from "components/FeedItemV2/ActivityHeader";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import StaticSearchBar from "components/UI/Components/StaticSearchBar";
import { useQuery } from "@tanstack/react-query";
import { getSearchResults } from "api/search.api";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
export const SwapActorCell = ({
  actor,
  isActiveFilter,
  onSelect,
  onRemoveFilter,
  filter,
}) => {
  const renderMemo = useMemo(() => {
    const { display_picture: avatar, display_name: name } = actor;
    return (
      <div className={styles.actorCell}>
        <ProfileHoverCard actor={actor}>
          <ConditionalLink
            to={actor.link ?? actor?.address_link}
            sameTab={false}>
            <CustomRow
              justifyContent="flex-start"
              alignItems="center"
              gap="4px"
              className={styles.actor}>
              <Image
                src={avatar}
                imageType={IMAGE_TYPES.AVATAR}
                className={styles.actorImage}
                name={name}
                alt={name}
              />
              <CustomText
                text={getTrimmedString(name, 13)}
                className={styles.actorName}
                color="var(--text-1)"
                fontSize="13px"
                letterSpacing="-0.26px"
                lineHeight="20px"
              />
              <VerifiedBadge profile={actor} />
            </CustomRow>
          </ConditionalLink>
        </ProfileHoverCard>

        {!filter && (
          <Funnel
            size={16}
            className={styles.filterIcon}
            color="var(--text-3)"
            onClick={() => {
              !isActiveFilter ? onSelect(actor.address) : onRemoveFilter();
            }}
          />
        )}
      </div>
    );
  }, [actor, filter, isActiveFilter, onSelect, onRemoveFilter]);
  return renderMemo;
};

const TableText = ({ text, color = "var(--text-1)" }) => {
  return (
    <CustomText
      text={text ?? "-"}
      fontWeight="400"
      lineHeight="20px"
      letterSpacing="-0.26px"
      fontSize="13px"
      color={color}
    />
  );
};

export const FilterUserPopup = ({ filter, updateFilter, onClose }) => {
  const [user, setUser] = useState(filter ?? "");
  return (
    <Popup
      title="Filter by address"
      onClose={onClose}
      horizontalPadding="20px"
      titleFontSize="18px"
      titleAlign="left"
      width="480px"
      isDivider>
      <PaddingComponent padding="8px 0 0 0">
        <CustomColumn gap="16px">
          <input
            type="text"
            value={user}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setUser(user.trim());
                updateFilter(user.trim());
                onClose();
              }
            }}
            onChange={(e) => setUser(e.target.value)}
            className={styles.filterInput}
          />
          <CustomRow gap="16px" justifyContent="flex-end">
            <GenericButton
              onClick={() => {
                updateFilter("");
                setUser("");
                onClose();
              }}
              background="var(--elevation-2)"
              customClass={styles.filterButtons}
              disabled={!user || user.length === 0}
              color="var(--text-2)">
              Clear
            </GenericButton>
            <GenericButton
              color="#fff"
              customClass={styles.filterButtons}
              onClick={() => {
                updateFilter(user);
                onClose();
              }}>
              Apply
            </GenericButton>
          </CustomRow>
        </CustomColumn>
      </PaddingComponent>
    </Popup>
  );
};

export const getTableColumns = ({ filter, updateFilter, type }) => {
  return [
    {
      title: (
        <CustomRow alignItems="center" className={styles.userHeader}>
          <span className={styles.userLabel}>{"USER"}</span>
          <CustomRow
            style={{ cursor: filter ? "pointer" : "auto" }}
            onClick={() => {
              if (filter) {
                updateFilter("", type);
              }
            }}>
            {!!filter && (
              <ZxText
                color="text-2"
                style={TSTYLES.body3}
                fontSize="11px"
                cursor="pointer">
                Clear
              </ZxText>
            )}
            <Funnel
              weight={filter ? "fill" : "regular"}
              size={16}
              color={filter ? "var(--text-2" : "var(--text-1)"}
            />
          </CustomRow>
        </CustomRow>
      ),
      width: "200px",
      align: "flex-start",
      render: (swap, { profiles }) => {
        const actor = profiles[swap.user_address];
        return (
          <SwapActorCell
            data={swap}
            actor={actor}
            filter={filter}
            isActiveFilter={filter && actor.address === filter}
            onSelect={() => {
              updateFilter(actor, type);
            }}
            onRemoveFilter={() => {
              updateFilter("", type);
            }}
          />
        );
      },
    },
    {
      title: "TYPE",
      width: "100px",
      align: "flex-start",
      render: (swap) => {
        return <BuySellActionText action={swap.type} fontWeight="400" />;
      },
    },
    {
      title: "AMOUNT",
      width: "120px",
      align: "flex-end",
      render: (swap) => {
        return <TableText text={swap.amount?.display_value} />;
      },
    },
    {
      title: "USD VALUE",
      width: "130px",
      align: "flex-end",
      render: (swap) => {
        const price = swap?.price_bought_at?.value ?? 0;
        const amount = swap?.amount?.value ?? 0;
        const usdValue = price && amount ? price * amount : null;
        return (
          <TableText
            text={price && amount ? `$${abbreviateNumber(usdValue)}` : `-`}
          />
        );
      },
    },
    {
      title: "PRICE",
      width: "100px",
      align: "flex-end",
      render: (swap) => {
        return <TableText text={swap.price_bought_at?.display_value} />;
      },
    },
    {
      title: "DATE",
      width: "120px",
      align: "flex-start",
      render: (swap) => {
        return (
          <ConditionalLink
            to={swap.transaction_link}
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}>
            <CustomRow gap="8px" alignItems="center" width="100%">
              <TimeLabel timestamp={swap.time_stamp} showYearWithMonth />
            </CustomRow>
            <CaretRight color="var(--text-2)" />
          </ConditionalLink>
        );
      },
    },
  ];
};

export const getTransfersData = async ({
  retryCount = 0,
  params,
  prevData,
  type,
  stopLoop = false,
}) => {
  if (retryCount >= 5) {
    return prevData;
  }
  retryCount += 1;
  const data =
    type === "recent"
      ? await getTokenSwapDetails(params)
      : await getFollowerSwapDetails(params);
  const transfers = data?.transfer_data ?? data?.recent_transfer_data ?? [];
  if (stopLoop) {
    return data;
  }
  if (transfers.length === 0) {
    let count = 0;
    let returnData = data;
    let cursor = data?.end_cursor;
    for (let i = 0; i < 5; i++) {
      if (count > 5) {
        break;
      }
      count++;
      const newParams = { ...params, pageParam: { start_cursor: cursor } };
      try {
        const tryData =
          type === "recent"
            ? await getTokenSwapDetails(newParams)
            : await getFollowerSwapDetails(newParams);
        if (
          tryData?.transfer_data?.length > 0 ||
          tryData?.recent_transfer_data?.length > 0
        ) {
          returnData = tryData;
          break;
        }
        cursor = tryData?.end_cursor;
      } catch (err) {}
    }
    return returnData;
  } else {
    return data;
  }
};

export const TableNextPageLoader = ({ loading }) => {
  if (!loading) return null;
  return (
    <PaddingComponent className={styles.nextLoading} padding="0 8px">
      {[1, 2, 3, 4].map((_, index) => (
        <CustomSkeleton key={index} height="40px" width="100%" />
      ))}
    </PaddingComponent>
  );
};

export const TokenPageSearchBar = ({ updateFilter, filter, profiles }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const updateSearchTerm = (term) => {
    setSearchTerm(term);
  };
  const { data, isFetching, isLoading } = useQuery({
    queryKey: ["tokenPageSearch", searchTerm],
    queryFn: ({ signal }) =>
      getSearchResults({
        queryKey: [searchTerm, `${searchTerm} source:Profile`],
        signal,
      }),
    staleTime: 0,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: searchTerm.length > 0,
  });
  const renderSearchProfiles = useCallback(() => {
    if (!filter) return null;
    let avatar = filter?.display_picture;
    let name = filter?.display_name;
    if (filter.searchForProfile) {
      try {
        const keys = Object.keys(profiles ?? {});
        const profile =
          profiles?.[
            keys.find(
              (k) =>
                profiles[k].address === filter.address ||
                profiles[k].id?.toString() === filter.address ||
                profiles[k].username === filter.address ||
                profiles[k]?.identity_id?.toString() === filter.address
            )
          ];
        if (profile) {
          avatar = profile.display_picture;
          name = profile.display_name;
        }
      } catch (err) {
        console.error({ err });
      }
    }
    return (
      <ZxFlex
        gap="4px"
        onClick={() => updateFilter(null, "recent")}
        className={styles.searchpill}>
        <Image
          src={avatar}
          imageType={IMAGE_TYPES.AVATAR}
          name={name}
          className={styles.actorImage}
          alt={name}
        />
        <CustomText
          text={getTrimmedString(name, 10)}
          className={styles.actorName}
          color="var(--text-2)"
          fontSize="12px"
        />
        <X size={16} />
      </ZxFlex>
    );
  }, [filter, updateFilter, profiles]);
  const renderResults = ({ toggleFocus }) => {
    if (data?.length === 0) {
      return (
        <div className={styles.noResults}>
          <ZxText>No results</ZxText>
        </div>
      );
    }
    return (
      data?.length > 0 &&
      searchTerm.length > 0 &&
      !isFetching &&
      !isLoading && (
        <div className={styles.searchResults}>
          {data.map((d, idx) => {
            const profile = d?.data;
            const isLast = idx === data.length - 1;
            return (
              <ZxFlex dir="column" align="flex-start" key={profile?.address}>
                <CustomRow
                  gap="6px"
                  padding="8px 12px"
                  cursor="pointer"
                  style={{ cursor: "pointer" }}
                  width="100%"
                  alignItems="center"
                  onClick={() => {
                    updateFilter(profile, "recent");
                    setSearchTerm("");
                    toggleFocus(false);
                  }}>
                  <Image
                    className="actorAvatar"
                    src={profile?.display_picture}
                    alt={profile?.display_name}
                    name={profile?.display_name}
                    imageType={IMAGE_TYPES.AVATAR}
                  />
                  <CustomText fontSize="13px" text={profile?.display_name} />
                  <VerifiedBadge profile={profile} />
                </CustomRow>
                {!isLast && <Divider margin="0px" />}
              </ZxFlex>
            );
          })}
        </div>
      )
    );
  };
  return (
    <div className={styles.searchContainer}>
      <StaticSearchBar
        title="Search for profiles or addresses"
        margin="0"
        borderRadius="4px"
        setSearchTerm={updateSearchTerm}
        searchTerm={searchTerm}
        background="var(--base)"
        border="1px solid var(--border-dark)"
        disabled={!!filter}
        prefix={renderSearchProfiles()}
        resetOnDisable
        delay={10}
        renderResults={renderResults}
        isLoading={isLoading || isFetching}
        hasResults={data?.length > 0}
      />
    </div>
  );
};
