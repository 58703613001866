import React, { useState } from "react";
import { CustomText } from "../../UI/Components/Components";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import {
  CustomColumn,
  CustomRow,
  FlexGrow,
} from "../../V2/Profile/ProfileDetails/wallets/AddWallet/AddWalletComponent";
import classes from "./Scheduler.module.css";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import CustomTextField from "../../V2/Messaging/components/Search/CustomTextField";

const applySelectedHourAndMinute = (currentDate, selectedDate) => {
  const newDate = new Date(currentDate);
  newDate.setHours(selectedDate.getHours());
  newDate.setMinutes(selectedDate.getMinutes());
  return newDate;
};
const getAllWeekDayFromDate = (_date, selectedDate) => {
  const date = applySelectedHourAndMinute(_date, selectedDate);
  const weekDay = date.getDay();
  const weekStart = new Date(date);
  weekStart.setDate(date.getDate() - weekDay);
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6);
  const days = [];
  for (let i = 0; i < 7; i++) {
    const newDate = new Date(weekStart);
    newDate.setDate(weekStart.getDate() + i);
    days.push(newDate);
  }
  return days;
};

const getMonthShortNameFromDate = (date) => {
  return date.toLocaleString("default", { month: "short" });
};

const getShortDayNameFromDate = (date) => {
  return date.toLocaleString("default", { weekday: "short" });
};

export const padNumber = (num) => {
  return num < 10 ? `0${num}` : "" + num;
};

const amOrPm = (hours) => {
  return hours >= 12 ? "PM" : "AM";
};

const isValidScheduleDate = (date) => {
  return date > new Date();
};

const getNextDay = () => {
  return new Date(new Date().setHours(new Date().getHours() + 1));
};

const getHours = (date) => {
  const hours = date.getHours();
  if (hours > 12) {
    return padNumber(hours % 12);
  }
  return padNumber(hours);
};

const getAutoCompleteSlotProps = () => ({
  paper: {
    sx: {
      "& .MuiAutocomplete-listbox": {
        "& .MuiAutocomplete-option": {
          backgroundColor: "var(--base)",
          color: "var(--text-1)",
        },
      },
    },
  },
});
export const Scheduler = ({ callbacks, date }) => {
  const [currentDate, setCurrentDate] = useState(date || getNextDay());
  const [selectedDate, _setSelectedDate] = useState(date || getNextDay());
  const days = getAllWeekDayFromDate(currentDate, selectedDate);
  const hours = getHours(selectedDate);
  const minutes = padNumber(selectedDate.getMinutes());
  const period = amOrPm(selectedDate.getHours());
  const setSelectedDate = (date) => {
    if (isValidScheduleDate(date)) {
      _setSelectedDate(date);
    } else {
      _setSelectedDate(new Date(date.setDate(date.getDate() + 1)));
    }
  };
  const setHours = (hours) => {
    let newDate = new Date(selectedDate);
    newDate.setHours(parseInt(hours));
    setSelectedDate(newDate);
  };

  const setMinutes = (minutes) => {
    let newDate = new Date(selectedDate);
    newDate.setMinutes(parseInt(minutes));
    setSelectedDate(newDate);
  };

  const setPeriod = (period) => {
    const hours = selectedDate.getHours();
    let newDate = new Date(selectedDate);
    if (period === "AM" && hours >= 12) {
      newDate.setHours(hours - 12);
    } else if (period === "PM" && hours < 12) {
      newDate.setHours(hours + 12);
    }
    setSelectedDate(newDate);
  };
  const leftDisabled = days[0] < new Date();

  return (
    <div className={classes.container}>
      <CustomText
        text="Schedule post (IST)"
        color="var(--text-1)"
        fontSize="17px"
        fontWeight="500"
        textAlign="left"
      />
      <div className={classes.weekContainer}>
        <CustomColumn>
          <div className={classes.dayName}></div>
          <div className={classes.caretContainer}>
            <CaretLeft
              style={{
                opacity: leftDisabled ? 0.2 : 1,
              }}
              size={24}
              onClick={() => {
                if (days[0] > new Date()) {
                  let newDate = new Date(currentDate);
                  newDate.setDate(currentDate.getDate() - 7);
                  setCurrentDate(newDate);
                }
              }}
            />
          </div>
        </CustomColumn>
        {days.map((day) => {
          const selected = selectedDate?.getDate() === day.getDate();
          const disabled = day < new Date();
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
              onClick={() => {
                if (!disabled) {
                  let newDate = new Date(selectedDate);
                  newDate.setDate(day.getDate());
                  setSelectedDate(newDate);
                }
              }}>
              <div className={classes.dayName}>
                <CustomText
                  text={getShortDayNameFromDate(day)}
                  fontSize="14px"
                />
              </div>
              <CustomColumn
                margin="10px 10px"
                justifyContent="center"
                alignItems="center">
                <div
                  className={classes.day}
                  data-disabled={disabled}
                  data-selected={selected}>
                  <CustomText
                    text={day.getDate()}
                    fontSize="20px"
                    fontWeight="500"
                    color={selected ? "var(--base)" : "var(--text-1)"}
                  />
                  <CustomText
                    text={getMonthShortNameFromDate(day)}
                    color={selected ? "var(--base)" : "var(--text-1)"}
                  />
                </div>
              </CustomColumn>
            </div>
          );
        })}
        <CustomColumn>
          <div className={classes.dayName}></div>
          <div className={classes.caretContainer}>
            <CaretRight
              size={24}
              onClick={() => {
                let newDate = new Date(currentDate);
                newDate.setDate(currentDate.getDate() + 7);
                setCurrentDate(newDate);
              }}
            />
          </div>
        </CustomColumn>
      </div>
      <div className={classes.timePicker}>
        <CustomColumn>
          <CustomText text="Hour" textAlign="left" />
          <Autocomplete
            value={hours}
            disableClearable
            autoSelect
            slotProps={getAutoCompleteSlotProps()}
            options={Array.from({ length: 12 }, (_, i) => padNumber(i + 1))}
            renderInput={(params) => <CustomTextField {...params} label="" />}
            onChange={(_e, val) => setHours(val)}
          />
        </CustomColumn>
        <CustomColumn>
          <CustomText text="Minutes" textAlign="left" />
          <Autocomplete
            disableClearable
            value={minutes}
            autoSelect
            slotProps={getAutoCompleteSlotProps()}
            options={Array.from({ length: 60 }, (_, i) => padNumber(i))}
            renderInput={(params) => <CustomTextField {...params} label="" />}
            onChange={(_e, val) => setMinutes(val)}
          />
        </CustomColumn>
        <CustomColumn>
          <CustomText text="Period" textAlign="left" />
          <Autocomplete
            value={period}
            disableClearable
            slotProps={getAutoCompleteSlotProps()}
            options={["AM", "PM"]}
            renderInput={(params) => <CustomTextField {...params} label="" />}
            onChange={(_e, val) => setPeriod(val)}
          />
        </CustomColumn>
      </div>
      <FlexGrow />
      <CustomRow
        padding="10px"
        justifyContent="space-between"
        alignItems="center">
        <button
          className={classes.clearButton}
          onClick={callbacks.showScheduledTab}>
          Scheduled Posts
        </button>

        <CustomRow gap="8px">
          <button
            className={classes.clearButton}
            onClick={() => {
              callbacks.clearDate();
            }}>
            Clear All
          </button>
          <button
            className={classes.button}
            text="Confirm"
            onClick={(e) => {
              e.preventDefault();
              callbacks.onConfirm(selectedDate);
            }}
            disabled={!selectedDate}>
            Confirm
          </button>
        </CustomRow>
      </CustomRow>
    </div>
  );
};
