import {
  CustomColumn,
  CustomRow,
  Divider,
} from "components/UI/Components/Components";
import React from "react";
import { ActivityAvatar, FeedContent } from "./GroupedActivityWrapper";
import { ActorInfo, TimeLabel } from "./ActivityHeader";
import ActivityExtraInfo from "./ActivityExtraInfo";

export const TransactionActivities = ({
  transactions,
  refetchGroupedActivity,
  isHovering,
}) => {
  return (
    <div>
      {transactions?.map((item, index) => {
        return (
          <div key={`${index}-${item.timestamp}`}>
            <CustomColumn padding="16px 20px">
              <CustomRow width="100%" justifyContent="space-between">
                <CustomRow alignItems="center" gap="12px">
                  <ActivityAvatar
                    actor={item.actor}
                    onActorUpdate={refetchGroupedActivity}
                    mini
                  />
                  <ActorInfo
                    actor={item.actor}
                    type={"news"}
                    onActorUpdate={refetchGroupedActivity}
                    mini
                  />
                </CustomRow>
                <CustomRow alignItems="center" gap="12px">
                  <TimeLabel timestamp={item.timestamp} />
                  <ActivityExtraInfo
                    chains={Object.keys(item?.chain_profiles)}
                    chainProfiles={item?.chain_profiles}
                    showSettings={false}
                  />
                </CustomRow>
              </CustomRow>
              <FeedContent
                groupedActivity={item}
                description={item.description}
                isHovering={isHovering}
                profiles={item.profiles}
                refetchGroupedActivity={refetchGroupedActivity}
              />
            </CustomColumn>
            <Divider />
          </div>
        );
      })}
    </div>
  );
};
