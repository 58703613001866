import { useGetTokenFolloweePNLs } from "api/token_page.api";
import React, { useState } from "react";
import ZxTable from "zxComponents/common/ZxTable/ZxTable";
import { getTokenFolloweePNLTableColumns } from "./ZxTokenFolloweePNLTable.utils";
import ZxTokenFilterSearch from "../ZxTokenFilterSearch/ZxTokenFilterSearch";

const ZxTokenFolloweePNLTable = ({
  chain,
  address,
  filter = null,
  updateFilter = () => {},
}) => {
  const { data: followeePnls, isLoading: isFolloweePnlsLoading } =
    useGetTokenFolloweePNLs({ chain, address });
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const toggleSearchModal = (v = null) => {
    setIsSearchModalOpen(v === null ? !isSearchModalOpen : v);
  };
  const rows = Object.entries(followeePnls?.identities_to_pnl ?? {}).map(
    ([key, value]) => ({ ...value, id: key })
  );
  return (
    <>
      <ZxTable
        isTokenPageTable
        data={rows ?? []}
        commonProps={{ profiles: followeePnls?.identity_profiles }}
        isLoading={isFolloweePnlsLoading}
        columns={getTokenFolloweePNLTableColumns({
          filter,
          updateFilter,
          toggleSearchModal,
        })}
      />
      <ZxTokenFilterSearch
        filter={filter}
        onApply={updateFilter}
        isOpen={isSearchModalOpen}
        onClose={toggleSearchModal}
      />
    </>
  );
};

export default ZxTokenFolloweePNLTable;
