import React, { memo, useContext, useState } from "react";
import PPLxAccordion from "shared/0xUI/PPLxAccordion/PPLxAccordion";
import PPLxNameValuePair from "shared/0xUI/PPLxNameValuePair/PPLxNameValuePair";
import GhostSourceCode from "./GhostByteCode/GhostSourceCode";
import GhostByteCode from "./GhostByteCode/GhostByteCode";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco, dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { ThemeContext } from "contextStates/Theme.context";
import Copyable from "components/ValueType/Copyable/Copyable";
import {
  CustomRow,
  CustomText,
  InfoBoxContainer,
  PaddingComponent,
} from "components/UI/Components/Components";
import { ToggleSwitch } from "components/V2/ActivityDetails/Transactions/ParseToggleSwitch";
import DownloadButton from "components/UI/Components/DownloadButton/DownloadButton";

const ContractCode = ({
  contractDetails,
  isDetailsLoading,
  type,
  isSolana,
}) => {
  const { theme } = useContext(ThemeContext);
  const highlighterTheme = theme === "light" ? docco : dracula;
  const [isReadAsProxy, setIsReadAsProxy] = useState(false);
  const abi = !isReadAsProxy
    ? contractDetails?.abi
    : contractDetails?.proxy_abi;
  const abiDownloadFilename =
    (contractDetails?.contract_name ||
      contractDetails?.profile?.address ||
      "contract") + ".json";

  let parsedAbi;
  try {
    // abi is JSON for solana contracts
    if (typeof abi === "string") parsedAbi = JSON.parse(abi);
    else parsedAbi = abi;
  } catch (err) {
    parsedAbi = {};
  }

  return (
    <div className="contract-code">
      {type === "source-code" && contractDetails?.source_code ? (
        <div className="contract-code__source-code section">
          {Object.entries(contractDetails?.source_code?.sources)?.map(
            ([key, { content }], index) => {
              const totalFiles = Object.keys(
                contractDetails?.source_code?.sources
              )?.length;

              const splittedPath = key?.split("/");
              const fileName = splittedPath[splittedPath?.length - 1];

              return (
                <div className="contract-code__source-code--item" key={index}>
                  <PPLxAccordion
                    title={`File ${index + 1} of ${totalFiles} : ${fileName}`}>
                    <SyntaxHighlighter
                      language="solidity"
                      style={highlighterTheme}
                      showLineNumbers={true}
                      customStyle={{ fontSize: "12px" }}>
                      {content}
                    </SyntaxHighlighter>
                  </PPLxAccordion>
                </div>
              );
            }
          )}
        </div>
      ) : isDetailsLoading ? (
        [...Array(3).keys()].map((index) => <GhostSourceCode key={index} />)
      ) : null}

      {type === "byte-code" && contractDetails?.byte_code?.length ? (
        <div className="contract-code__byte-code section">
          <PPLxNameValuePair name="BYTE CODE" noFlex>
            <div className="contract-code__byte-code--wrapper">
              {contractDetails?.byte_code}
            </div>
          </PPLxNameValuePair>
        </div>
      ) : isDetailsLoading ? (
        <GhostByteCode />
      ) : null}

      {type === "abi" && contractDetails?.abi ? (
        <div className="contract-code__abi section">
          <PaddingComponent height="16px" />
          <InfoBoxContainer
            contentPaddingTop="0px"
            header={
              <CustomRow alignItems="center" justifyContent="space-between">
                <CustomText
                  text={isSolana ? "Contract IDL" : "Contract ABI"}
                  fontSize="13px"
                  color="var(--text-1)"
                  fontWeight="600"></CustomText>

                <CustomRow
                  alignItems="center"
                  justifyContent="space-between"
                  gap="16px">
                  <ToggleSwitch
                    enabled={isReadAsProxy}
                    setIsEnabled={setIsReadAsProxy}
                    rightText="Proxy ABI"
                    isDisabled={!contractDetails?.proxy_abi}
                  />

                  <CustomText text=" | " color="var(--text-3)"></CustomText>
                  <CustomRow
                    alignItems="center"
                    justifyContent="space-between"
                    gap="10px">
                    <Copyable content={abi} />
                    <DownloadButton
                      fileName={`${abiDownloadFilename}`}
                      content={abi}
                    />
                  </CustomRow>
                </CustomRow>
              </CustomRow>
            }>
            <SyntaxHighlighter
              language="javascript"
              style={highlighterTheme}
              showLineNumbers={true}
              customStyle={{ fontSize: "12px" }}>
              {JSON.stringify(parsedAbi, undefined, 2)}
            </SyntaxHighlighter>
          </InfoBoxContainer>
        </div>
      ) : isDetailsLoading ? (
        <GhostByteCode />
      ) : null}

      {/* {type === "settings" && contractDetails?.source_code?.settings ? (
        <div className="contract-code__settings section">
          <PPLxNameValuePair name="SETTINGS" noFlex>
            <AceEditor
              placeholder="Settings"
              mode="csharp"
              theme="xcode"
              name="source-code"
              fontSize={10}
              highlightActiveLine={true}
              height="200px"
              width="100%"
              value={JSON.stringify(
                contractDetails?.source_code?.settings,
                null,
                4
              )}
            />
          </PPLxNameValuePair>
        </div>
      ) : isDetailsLoading ? (
        <GhostByteCode background="white" />
      ) : null} */}
    </div>
  );
};

export default memo(ContractCode);
