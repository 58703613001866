import React, { useEffect, useState } from "react";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  PaddingComponent,
  TransparentButton,
} from "components/UI/Components/Components";
import classes from "./screenerFiltersRightPanel.module.css";
import { ReactComponent as CrossIcon } from "assets/svg/profile/cross.svg";
import CustomSwitchButton from "components/UI/Components/CustomSwitch";
import { CircularProgress } from "@mui/material";
const ScreenerFiltersRightPanel = ({
  onClose,
  filters,
  handleToggle,
  handleApplyChanges,
  handleReset,
  areFiltersApplied,
  isLoading,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleApplyChangesWithState = () => {
    handleApplyChanges();
  };

  const handleResetWithState = () => {
    handleReset();
    if (!areFiltersApplied) {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    const activeFilterCount = filters.reduce((count, filter) => {
      return count + (filter.pendingValue || filter.value ? 1 : 0);
    }, 0);

    setIsDisabled(activeFilterCount === 0);
  }, [filters]);

  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <div onClick={onClose}>
          <CrossIcon className={classes.crossIcon} />
        </div>
        <span className={classes.title}>
          <CustomRow justifyContent="space-between" alignItems="center">
            Filters
            <TransparentButton onClick={handleResetWithState}>
              <CustomText
                text="Reset All"
                fontSize="14px"
                color="var(--primary-color)"
              />
            </TransparentButton>
          </CustomRow>
        </span>
      </div>
      <PaddingComponent padding="24px 20px">
        {filters.map((filter, index) => (
          <div key={index}>
            <FilterRow
              filter={filter}
              index={index}
              handleToggle={handleToggle}
            />
            <PaddingComponent height="16px" />
            <Divider />
            <PaddingComponent height="16px" />
          </div>
        ))}
      </PaddingComponent>

      <button
        className={`${classes.submitButton} ${
          isDisabled ? classes.disabled : ""
        }`}
        {...(!isDisabled ? { onClick: handleApplyChangesWithState } : {})}>
        {isLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          "Apply changes"
        )}
      </button>
    </div>
  );
};

const FilterRow = ({ filter, index, handleToggle }) => {
  return (
    <CustomRow justifyContent="space-between" alignItems="center" width="100%">
      <CustomColumn gap="4px" alignItems="flex-start">
        <CustomText text={filter.label} fontSize="14px" color="var(--text-1)" />
        <CustomText
          text={filter.description}
          fontSize="12px"
          color="var(--text-2)"
        />
      </CustomColumn>
      <CustomSwitchButton
        isPrimaryColored={true}
        toggle={filter.pendingValue}
        setToggle={(value) => handleToggle(index, value)}
        activeSliderBackground="var(--primary-color)"
        activeSliderCircle="var(--text-white)"
      />
    </CustomRow>
  );
};

export default ScreenerFiltersRightPanel;
