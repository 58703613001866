import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const LandingPage = () => {
  return (
    <BaseWrapper mixpanelEvent="LandingPage">
      <div>
        <iframe
          title={"Landing Page"}
          src="https://fluid-hands-315974.framer.app/"
          style={{ width: "100%", height: "100vh", border: "none" }}
        />
      </div>
    </BaseWrapper>
  );
};

export default LandingPage;
