import React from "react";
import { getPaginatedActivitiesPath } from "api/api";
import FeedItemsList from "components/Feed/FeedItemsList";

const PaginatedActivities = ({
  activeChainId = "",
  type,
  identifier,
  activitiesForContract = false,
  selectedActiveChains = [],
  selectedActiveTokens = [],
  selectedActiveProtocols = [],
  selectedFeedType = null,
  selectedDateRange = null,
  chainType = "",
  exclude_activity_groups_with_hex_actor = false,
  isProfilePage = false,
  passPreferredActor = false,
}) => {
  const path = getPaginatedActivitiesPath(
    type,
    identifier,
    activeChainId,
    activitiesForContract,
    selectedActiveChains,
    selectedActiveTokens,
    selectedActiveProtocols,
    selectedFeedType,
    selectedDateRange,
    chainType,
    exclude_activity_groups_with_hex_actor
  );

  return (
    <FeedItemsList
      key={path}
      path={path}
      isProfilePage={true}
      passPreferredActor={passPreferredActor}
    />
  );
};
export default PaginatedActivities;
