import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "contextStates/Global";
import { signedApiV4Request } from "api/api";
import classes from "./TelegramLoginButton.module.css";
import { trackEvent } from "utils/event_tracking";

/**
 * To test this component in development mode, you need to launch react via the following command:-
 * sudo HTTPS=true PORT=443 yarn start
 */
const TelegramLoginButton = ({
  onAuth = (data) => {},
  onSuccess = () => {},
  onError = () => {},
  customRender = null,
  isTelegramConnected = null,
}) => {
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const [isConnected, setIsConnected] = useState(
    isTelegramConnected === null ? false : isTelegramConnected
  );

  const disconnectSession = (resolve, reject) => {
    signedApiV4Request({
      method: "get",
      path: "disconnect_telegram",
    })
      .then((res) => {
        trackEvent("telegram_disconnect_successful");
        setIsConnected(false);
        resolve(res);
      })
      .catch((err) => {
        trackEvent("telegram_disconnect_error");
        handleErrorSnackbar(null, "Failed to disconnect to Telegram!");
        reject(err);
      });
  };

  useEffect(() => {
    if (isTelegramConnected === null) {
      signedApiV4Request({
        method: "get",
        path: "is_telegram_connected",
      })
        .then((res) => {
          setIsConnected(res?.data?.data ?? false);
        })
        .catch((err) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bot_id =
    process.env.NODE_ENV === "production" ? 6484480658 : 6591359471;
  return (
    <div
      onClick={() => {
        return new Promise((resolve, reject) => {
          if (isConnected) {
            trackEvent("disconnect_telegram_btn_clicked");
            disconnectSession(resolve, reject);
            return;
          }
          window.Telegram.Login.auth(
            { bot_id, request_access: "write" },
            function onAuth(user) {
              if (user) {
                trackEvent("connect_telegram_btn_clicked");
                signedApiV4Request({
                  method: "post",
                  path: "telegram_callback",
                  bodyText: JSON.stringify({
                    user,
                  }),
                })
                  .then((res) => {
                    onSuccess?.(res);
                    trackEvent("telegram_connect_successful");
                    setIsConnected(true);
                    handleSuccessSnackbar?.("Telegram connected!");
                    resolve(res);
                  })
                  .catch((err) => {
                    trackEvent("telegram_connect_error");
                    setIsConnected(false);
                    handleErrorSnackbar(null, "Could not connect to Telegram!");
                    reject(err);
                  });
                return;
              }
              console.error({ user });
              handleErrorSnackbar(null, "Failed to connect to Telegram!");
            }
          );
        });
      }}>
      {customRender ? (
        customRender()
      ) : (
        <div className={classes.telegramBtn}>
          {isConnected ? "Disconnect Telegram" : "Connect Telegram"}
        </div>
      )}
    </div>
  );
};

export default TelegramLoginButton;
