import PerpetualPositionBody from "./PerpetualPositionBody";
import {
  Table,
  TableHeader,
  TableCell,
  TableTitle,
} from "components/UI/Table/Table";

const PerpetualPositionDetails = ({ label, holdings, totalUsdValue }) => {
  return (
    <Table>
      <TableTitle
        noPadding
        title={label}
        subTitle={<div>Total Value: {totalUsdValue}</div>}
      />
      <TableHeader noPadding>
        <TableCell alignLeft>Pair</TableCell>
        <TableCell alignLeft>Size</TableCell>
        <TableCell alignLeft style={{ flexBasis: "30%" }}>
          Entry price
        </TableCell>
        <TableCell alignLeft>PNL</TableCell>
        <TableCell alignRight>Value</TableCell>
      </TableHeader>
      <PerpetualPositionBody holdings={holdings} />
    </Table>
  );
};

export default PerpetualPositionDetails;
