import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import { FeedContainer } from "components/UI/Components/ResizeFeedComponent";
import { TOP_USERS_TAB_KEYS } from "utils/constants";
import styles from "./TokenPage.module.css";
import { fetchTopHolders } from "../Token/TopUsers/useTopHolders";
import { fetchTopBuyersAndSellers } from "../Token/TopUsers/useTopBuyersAndSellers";
import { useContext, useState } from "react";
import { updateContract } from "api/contract.api";
import { GlobalContext } from "contextStates/Global";
import TokenDescription from "./TokenDescription/TokenDescription";
import PartOfProfile, {
  PartOfProfileTypes,
} from "components/UI/PartOfProfile/PartOfProfile";
import ZxModal from "zxComponents/common/ZxModal/ZxModal";
import ZxInput from "zxComponents/common/ZxInput/ZxInput";
import { useForm } from "react-hook-form";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import GenericButton from "../GenericButton/GenericButton";
import {
  Globe,
  User,
  XLogo,
} from "../../../../node_modules/@phosphor-icons/react/dist/index";

export const TOKEN_PAGE_TOP_TABS = [
  {
    title: "Price Chart",
    key: "price_chart",
  },
  {
    title: "TVL Info",
    key: "tvlinfo",
  },
  {
    title: "Source Code",
    key: "source_code",
  },
  {
    title: "ABI",
    key: "abi",
  },
  {
    title: "Read Contract",
    key: "read_contract",
  },
  {
    title: "Write Contract",
    key: "write_contract",
  },
  {
    title: "Liquidity",
    key: "liquidity",
  },
  {
    title: "Activities",
    key: "activities",
  },
];

export const TOKEN_PAGE_SWAP_TABS = [
  {
    title: "Recent Swaps",
    key: "recent_swaps",
  },
  {
    title: "Followee Swaps",
    key: "follower_swaps",
  },
  ...TOP_USERS_TAB_KEYS,
];

export const TokenPageRightBarLoader = () => {
  return (
    <PaddingComponent
      className={`${styles.rightBar} ${styles.whiteBg}}`}
      padding="16px">
      <CustomSkeleton width="100%" height="120px" variant="rectangular" />
      <CustomSkeleton width="100%" height="60px" />
      <CustomSkeleton width="100%" height="60px" />
      <CustomRow gap="16px">
        <CustomSkeleton width="100px" height="60px" />
        <CustomSkeleton width="100px" height="60px" />
      </CustomRow>
      <PaddingComponent height="24px" />
      <CustomRow flexWrap="wrap" gap="16px" justifyContent="space-between">
        {[1, 2, 3, 4, 5, 6].map((_, index) => (
          <CustomSkeleton
            key={index}
            width="47%"
            height="80px"
            variant="rectangular"
          />
        ))}
      </CustomRow>
      <PaddingComponent height="24px" />
      <CustomSkeleton width="100%" height="60px" />
    </PaddingComponent>
  );
};

export const TokenPageHeaderLoader = () => {
  return (
    <PaddingComponent className={styles["loader-header"]} padding="16px">
      <CustomSkeleton
        width="60px"
        height="60px"
        className={styles["loader-header-avatar"]}
      />
      <div className={styles["loader-header-buttons"]}>
        <CustomSkeleton width="32px" height="50px" />
        <CustomSkeleton width="120px" height="50px" />
        <CustomSkeleton width="50px" height="50px" />
        <CustomSkeleton width="50px" height="50px" />
      </div>
    </PaddingComponent>
  );
};
export const TokenPageTabsLoader = () => {
  return (
    <PaddingComponent className={styles["loader-tabs"]} padding="16px">
      {[1, 2, 3, 4, 5, 6].map((_, index) => (
        <CustomSkeleton key={index} width="100%" height="40px" />
      ))}
    </PaddingComponent>
  );
};
export const TokenPageDescriptionLoader = () => {
  return (
    <PaddingComponent className={styles["loader-description"]} padding="16px">
      <CustomSkeleton width="30%" height="48px" />
      {[1, 2, 3, 4].map((_, index) => (
        <CustomSkeleton key={index} width="100%" height="32px" />
      ))}
    </PaddingComponent>
  );
};
export const TokenPageLoader = () => {
  return (
    <PaddingComponent className={`${styles["container"]} `}>
      <FeedContainer>
        <PaddingComponent
          className={`${styles.pageContent} ${styles.loadingContainer}`}
          padding="16px">
          <TokenPageHeaderLoader />
          <Divider />
          <TokenPageDescriptionLoader />
          <TokenPageTabsLoader />
          <Divider />
          <PaddingComponent className={styles["loader-charts"]} padding="16px">
            <CustomSkeleton
              width="100%"
              height="300px"
              className={styles["loader-chart"]}
            />
          </PaddingComponent>
        </PaddingComponent>
      </FeedContainer>
      <TokenPageRightBarLoader />
    </PaddingComponent>
  );
};

// export const
export const PAGE_TYPES = {
  TOKEN: "FungibleToken",
  LPTOKEN: "LPToken",
  MULTITOKEN: "MultiToken",
  HYBRIDTOKEN: "HybridToken",
  CONTRACT: "Contract",
  COLLECTION: "collection",
  NFT: "NFT",
};

export const fetchInitialPageData = ({
  chainId,
  contractAddress,
  pageType,
  queryClient,
  isUserLoggedIn,
  isSolana,
  isTokenPage,
}) => {
  if (pageType === PAGE_TYPES.TOKEN) {
    // follower swaps
    // top holders
    queryClient.fetchQuery({
      queryKey: ["top_token_holders", contractAddress, chainId],
      queryFn: () =>
        fetchTopHolders({ address: contractAddress, chain_id: chainId }),
      enabled: isTokenPage && !!contractAddress && !!chainId,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30,
    });
    // top buyers and sellers
    queryClient.fetchQuery({
      queryKey: ["top_token_buyers_sellers", contractAddress, chainId, 1],
      queryFn: () =>
        fetchTopBuyersAndSellers({
          address: contractAddress,
          chain_id: chainId,
          currentTimeframe: 1,
        }),

      enabled: !!contractAddress && !!chainId,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30,
    });
  }
  return;
};

export const EditContractPopup = ({
  onClose,
  links,
  chainId,
  contractAddress,
  refetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    defaultValues: {
      name: "",
      website: links?.website ?? "",
      twitter: links?.twitter ?? "",
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const hasErrors = Object.keys(errors).length > 0;
  const onUpdate = (values) => {
    if (hasErrors) return;
    const { name, twitter, website } = values;
    setIsLoading(true);
    return updateContract({
      chainId: chainId,
      address: contractAddress,
      website,
      name,
      twitter,
    })
      .then((data) => {
        refetch();
        onClose();
        handleSuccessSnackbar("Contract details updated successfully");
      })
      .catch((err) => {
        const message =
          err?.response?.data?.message ?? "Could not update contract details";
        handleErrorSnackbar?.(null, message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <ZxModal
      onClose={onClose}
      isOpen={true}
      title="Edit Contract Details"
      width="480px">
      <form onSubmit={handleSubmit(onUpdate)}>
        <ZxFlex dir="column" gap="12px" align="flex-start">
          <ZxInput
            flexProps={{
              dir: "column",
              align: "flex-start",
              gap: "8px",
            }}
            label="Name"
            error={errors?.name}
            name="name"
            register={register}
            placeholder="Enter contract name"
            width="446px"
            inputPrefix={
              <PaddingComponent padding="0 8px 0 0">
                <ZxFlex align="center">
                  <User size={16} color="var(--text-2)" />
                </ZxFlex>
              </PaddingComponent>
            }
            validation={{
              message: "Name is required",
              validate: (v) => {
                if (!v) return "Name is required";

                return true;
              },
            }}
          />
          <ZxInput
            flexProps={{
              dir: "column",
              align: "flex-start",
              gap: "8px",
            }}
            label="Website"
            name="website"
            width="446px"
            error={errors?.website}
            register={register}
            placeholder="Enter website"
            validation={{
              message: "Website is required",
              validate: (v) => {
                if (!v) return "Website is required";
                return true;
              },
            }}
            inputPrefix={
              <PaddingComponent padding="0 8px 0 0">
                <ZxFlex align="center">
                  <Globe size={16} color="var(--text-2)" />
                </ZxFlex>
              </PaddingComponent>
            }
          />
          <ZxInput
            flexProps={{
              dir: "column",
              align: "flex-start",
              gap: "8px",
            }}
            label="Twitter"
            name="twitter"
            width="446px"
            register={register}
            placeholder="Enter twitter handle"
            inputPrefix={
              <PaddingComponent padding="0 8px 0 0">
                <ZxFlex align="center">
                  <XLogo size={16} color="var(--text-2)" />
                </ZxFlex>
              </PaddingComponent>
            }
          />

          <PaddingComponent padding="12px 0 0 0">
            <GenericButton
              type="submit"
              disabled={hasErrors || isLoading}
              padding="8px 16px"
              color="var(--text-white)">
              {isLoading ? "Updating..." : "Update"}
            </GenericButton>
          </PaddingComponent>
        </ZxFlex>
      </form>
    </ZxModal>
  );
};

export const ContractCollectionUI = ({
  profile,
  isTokenPage,
  isContractPage,
  description,
  pageType,
}) => {
  if (isTokenPage) {
    return null;
  }
  if (!profile?.protocol_details) {
    return null;
  }
  const protocol = profile.protocol_details;

  return (
    <PaddingComponent padding="0 16px">
      <CustomColumn>
        {isContractPage && description.length > 0 && (
          <PaddingComponent padding="16px 0">
            <TokenDescription type={pageType} description={description} />
          </PaddingComponent>
        )}
        {protocol.link && (
          <PartOfProfile
            link={protocol.link}
            name={protocol.name}
            icon={protocol.icon}
            profileType={PartOfProfileTypes.CONTRACT}
            showPadding={false}
          />
        )}
      </CustomColumn>
    </PaddingComponent>
  );
};
