import React, { useEffect, useState, useRef, useMemo, useContext } from "react";
import { ColorType } from "lightweight-charts";
import { abbreviateNumber, getCssVariable } from "utils/misc";
import { ThemeContext } from "contextStates/Theme.context";
import classes from "./graphV2.module.css";
import { CustomRow, CustomText } from "components/UI/Components/Components";
import CustomSwitchButton from "components/UI/Components/CustomSwitch";
import Tooltip from "./Tooltip";
import {
  createNewChartInstance,
  transformChartData,
  transformOhlcChartData,
} from "./chartUtils";
import { getConsolidatedMarkers } from "utils/chart_utils";

const TOOLTIP_LEFT_OFFSET = 80;
const TOOLTIP_TOP_OFFSET = 90;

const GraphV2 = ({
  data,
  name,
  isSeconds = false,
  markers,
  mini = false,
  setIsLineChart,
  isLineChart = true,
  disableLineCandleToggle = false,
  customDisplayFunction = null,
  gradientFill = false,
}) => {
  const transformedData = useMemo(
    () =>
      isLineChart
        ? transformChartData(data, isSeconds)
        : transformOhlcChartData(data, isSeconds),
    [data, isLineChart, isSeconds]
  );
  if (!transformedData) {
    return null;
  }

  return (
    <GraphComponent
      data={transformedData}
      name={name}
      markers={markers}
      mini={mini}
      isLineChart={isLineChart}
      setIsLineChart={setIsLineChart}
      disableLineCandleToggle={disableLineCandleToggle}
      customDisplayFunction={customDisplayFunction}
      gradientFill={gradientFill}
    />
  );
};

const GraphComponent = ({
  data,
  name,
  markers,
  mini,
  isLineChart,
  disableLineCandleToggle = false,
  customDisplayFunction,
  setIsLineChart,
  gradientFill,
}) => {
  const { storedTheme } = useContext(ThemeContext);
  const tooltipRef = useRef(null);
  const [currentPoint, setCurrentPoint] = useState(null);
  const chartContainerRef = useRef();
  const last = data[data.length - 1];
  const first = data[0];
  const isNegative = last.value < first.value;
  //sort markers in asc order
  markers?.sort((a, b) => a.time - b.time);
  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({
        width: chartContainerRef.current.clientWidth,
      });
    };
    const colors = {
      backgroundColor: getCssVariable("--base"),
      lineColor: getCssVariable(isNegative ? "--error" : "--success"),
      textColor: getCssVariable("--text-3"),
      areaTopColor: getCssVariable(
        isNegative ? "--error-light" : "--success-light"
      ),
      areaBottomColor: getCssVariable(
        isNegative ? "--error-lighter" : "--success-lighter"
      ),
      gridColor: getCssVariable("--elevation-2"),
    };

    const chart = createNewChartInstance(
      chartContainerRef,
      {
        layout: {
          background: { type: ColorType.Solid, color: colors.backgroundColor },
          textColor: colors.textColor,
        },
        grid: {
          vertLines: {
            color: colors.gridColor,
            visible: !mini,
          },
          horzLines: {
            color: colors.gridColor,
            visible: !mini,
          },
        },
        localization: {
          priceFormatter: (price) =>
            customDisplayFunction
              ? customDisplayFunction(price)
              : abbreviateNumber(price),
        },
      },
      mini
    );

    let seriesInstance;
    if (isLineChart) {
      seriesInstance = chart.addLineSeries({
        color: colors.lineColor,
        lineStyle: 0,
        lineWidth: 1,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 3,
        crosshairMarkerBorderColor: colors.lineColor,
      });
      if (gradientFill) {
        seriesInstance = chart.addAreaSeries({
          topColor: colors.areaTopColor,
          bottomColor: colors.areaBottomColor,
          lineColor: colors.lineColor,
          lineWidth: 0,
        });
      }
    } else {
      seriesInstance = chart.addCandlestickSeries({
        upColor: getCssVariable("--success"),
        downColor: getCssVariable("--error"),
        borderUpColor: getCssVariable("--success"),
        borderDownColor: getCssVariable("--error"),
        wickUpColor: getCssVariable("--success"),
        wickDownColor: getCssVariable("--error"),
      });
    }

    seriesInstance.setData(data);
    if (markers) {
      const summaryMarkers = getConsolidatedMarkers(data, markers);
      seriesInstance.setMarkers(summaryMarkers.sort((a, b) => a.time - b.time));
    }
    chart.priceScale("right").applyOptions({
      borderColor: colors.gridColor,
      visible: !mini,
      scaleMargins: {
        bottom: 0,
        top: 0,
      },
    });
    chart.timeScale().applyOptions({
      borderColor: colors.gridColor,
      visible: !mini,
      autoScale: true,
    });

    chart.timeScale().fitContent();

    chart.timeScale().subscribeVisibleTimeRangeChange((range) => {
      if (range) {
        chart.timeScale().applyOptions({
          ...(range.from === first.time ? { fixLeftEdge: true } : {}),
          ...(range.to === last.time ? { fixRightEdge: true } : {}),
        });
      }
    });

    chart.subscribeCrosshairMove((param) => {
      const data = param.seriesData.get(seriesInstance);
      setCurrentPoint(data);
      const price = data?.value ?? data?.close;
      const x = param?.point?.x;
      const y = seriesInstance.priceToCoordinate(price);
      if (tooltipRef.current && x && y) {
        tooltipRef.current.style.left = `${x - TOOLTIP_LEFT_OFFSET}px`;
        tooltipRef.current.style.top = `${y - TOOLTIP_TOP_OFFSET}px`;
      }
    });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
  }, [
    data,
    first,
    isNegative,
    last,
    storedTheme,
    markers,
    mini,
    isLineChart,
    customDisplayFunction,
    gradientFill,
  ]);

  return (
    <div
      className={classes.graphContainer}
      style={{
        height: mini ? "70px" : "300px",
      }}>
      {!mini && setIsLineChart !== undefined && !disableLineCandleToggle && (
        <div className={classes.attribution}>
          <CustomRow gap="6px" alignItems="center">
            <CustomText text="Candle" />
            <CustomSwitchButton
              toggle={isLineChart}
              setToggle={setIsLineChart}
            />
            <CustomText text="Line" />
          </CustomRow>
        </div>
      )}

      {!mini && (
        <Tooltip
          currentPoint={currentPoint}
          ref={tooltipRef}
          name={name}
          isLineChart={isLineChart}
          customDisplayFunction={customDisplayFunction}
        />
      )}
      <div ref={chartContainerRef} />
    </div>
  );
};

export default GraphV2;
