import { useEffect } from "react";

const usePageTitle = ({ title }) => {
  useEffect(() => {
    document.title = title;
    return () => (document.title = "0xPPL");
  }, [title]);
};

export default usePageTitle;
