import ErrorBoundary from "./ErrorBoundary";

const withErrorBoundary = (WrappedComponent, containerClass) => {
  const WithErrorBoundary = (props) => {
    return (
      <ErrorBoundary containerClass={containerClass}>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };

  return WithErrorBoundary;
};

export default withErrorBoundary;
