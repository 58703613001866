import React, { useContext } from "react";
import {
  Grid,
  SearchBar,
  SearchContext,
  SearchContextManager,
} from "@giphy/react-components";
import giphyIcon from "assets/svg/powered_by_giphy.png";
import { GIPHY_API_KEY } from "utils/constants";

import classes from "./GifPopUp.module.css";

const GifSearchExperience = ({ onSelect }) => (
  <div className={classes.gifContainer}>
    <SearchContextManager apiKey={GIPHY_API_KEY}>
      <GifPopUp onSelect={onSelect} />
    </SearchContextManager>
  </div>
);

const GifPopUp = ({ onSelect }) => {
  const { fetchGifs, searchKey } = useContext(SearchContext);

  return (
    <div>
      <SearchBar />
      <div className={classes.gifGrid}>
        <Grid
          noLink
          key={searchKey}
          columns={3}
          width={400}
          noResultsMessage="No GIFs found"
          fetchGifs={fetchGifs}
          onGifClick={(e) => onSelect(e)}
          hideAttribution={true}
        />
      </div>
      <img
        style={{ marginTop: "12px", height: "16px" }}
        src={giphyIcon}
        alt="giphyIcon"
      />
    </div>
  );
};

export default GifSearchExperience;
