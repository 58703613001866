import React, { useContext, useEffect } from "react";

import { prefetchTrendingAdditionalInfo } from "./trendingUtils";
import { prefetchNetworth } from "api/profile.api";
import { useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "contextStates/AuthContext";
import TrendingDetails from "./TrendingDetails";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const TrendingDetailsWrapper = ({ identifier, chainId }) => {
  const { identityDetails } = useContext(AuthContext);
  const currentUserIdentifier = identityDetails?.current?.identity?.id;

  const queryClient = useQueryClient();

  useEffect(() => {
    prefetchNetworth({ identifier: currentUserIdentifier, queryClient });
    prefetchTrendingAdditionalInfo({ identifier, chainId, queryClient });
  }, [chainId, currentUserIdentifier, identifier, queryClient]);

  return (
    <BaseWrapper mixpanelEvent="TrendingDetailsWrapper">
      <TrendingDetails
        chainId={chainId}
        identifier={identifier}
        currentUserIdentifier={currentUserIdentifier}
      />
    </BaseWrapper>
  );
};

export default TrendingDetailsWrapper;
