import React, { useCallback, useContext, useEffect, useRef } from "react";

import {
  getTableColumns,
  TokenPageSearchBar,
} from "./TokenPageTransfersTable.utils";
import styles from "./TokenPageTransfersTable.module.css";
import ZxTable from "zxComponents/common/ZxTable/ZxTable";
import { PaddingComponent } from "components/UI/Components/Components";
import { TokenPageContext } from "../TokenPageContext";
import { useTokenPagePnl } from "api/token_page.api";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import ZxUserTokenGroup from "zxComponents/feed/ZxUserTokenGroup/ZxUserTokenGroup";
// import { useTokenPagePnl } from "api/token_page.api";
// import PnlValue from "components/Pages/AppModule/RightSideBar/components/PnlValue";
// import SearchBarMini from "components/UI/Components/SearchBarMini";

// eslint-disable-next-line no-unused-vars

const REFETCH_INTERVAL = 10 * 1000;
const MINIMUM_ROWS = 20;
const MAX_REQUESTS = 10;
const TokenPageTransfersTable = ({ chainId, contractAddress, type }) => {
  const loopStarted = useRef(false);
  const networkCallRef = useRef(0);
  const { getData, getFilter, updateFilter } = useContext(TokenPageContext);
  const {
    data: qData,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage, // boolean
    isLoading, // boolean,
    isFetchingNextPage,
    isError,
    refetch,
  } = getData(type);
  const filter = getFilter(type);
  const timer = useRef(null);
  const filterPnl = useTokenPagePnl({
    chainId,
    contractAddress,
    identifier:
      filter?.identity_id ??
      filter?.id ??
      filter?.username ??
      filter?.address ??
      null,
  });
  const tableColumns = getTableColumns({
    filter,
    updateFilter,
    type,
  });
  const getTableProps = useCallback(() => {
    if (!qData?.pages) return { data: null, commonProps: {} };
    const { data, profiles } = qData.pages.reduce(
      (acc, page) => {
        acc.data.push(
          ...(page.transfer_data ?? page.recent_transfer_data ?? [])
        );
        Object.assign(acc.profiles, page.address_profiles);
        return acc;
      },
      { data: [], profiles: {} }
    );
    return {
      data,
      commonProps: {
        profiles,
      },
    };
  }, [qData]);

  const { data, commonProps } = getTableProps();
  const fetchNewData = useCallback(() => {
    timer.current = setTimeout(async () => {
      try {
        await fetchPreviousPage();
      } catch (error) {
      } finally {
        fetchNewData(); // Continue fetching new data
      }
    }, REFETCH_INTERVAL);
  }, [fetchPreviousPage]);
  useEffect(() => {
    if (data && !loopStarted.current) {
      fetchNewData();
      loopStarted.current = true;
    }
  }, [data, type, fetchNewData]);

  // cleanup - keep this seperate to avoid side effects
  useEffect(() => {
    return () => {
      clearTimeout(timer?.current);
      loopStarted.current = false;
      networkCallRef.current = 0;
    };
  }, [chainId, contractAddress]);

  // clear old timer and start new one
  useEffect(() => {
    if (filter !== null) {
      clearTimeout(timer?.current);
      loopStarted.current = false;
      networkCallRef.current = 0;
    }
  }, [filter, fetchNewData]);

  // on new page, fetch minimum rows
  useEffect(() => {
    const rows = data ? data.length : null;
    if (
      rows &&
      rows < MINIMUM_ROWS &&
      networkCallRef.current < MAX_REQUESTS &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
      networkCallRef.current = networkCallRef.current + 1;
    }
  }, [data, type, fetchNextPage, hasNextPage, isFetchingNextPage]);
  return (
    <>
      {type === "recent" && (
        <TokenPageSearchBar
          updateFilter={updateFilter}
          filter={filter}
          profiles={commonProps.profiles}
        />
      )}
      {filterPnl.data &&
        Object.keys(filterPnl?.data)?.filter((k) => !!filterPnl?.data[k])
          ?.length > 0 &&
        filter && (
          <PaddingComponent padding="12px 0 16px 0">
            <ZxUserTokenGroup
              selectedToken={filterPnl?.data}
              showAttribution={false}
            />
          </PaddingComponent>
        )}
      <ZxTable
        columns={tableColumns}
        stylesheet={styles}
        isLoading={isLoading}
        data={data}
        commonProps={commonProps}
        infiniteScroll
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isError={isError}
        refetch={refetch}
        showRefetchOnError
        emptyText={type === "recent" ? "No transfers found" : "No data found"}
      />
      <PaddingComponent height="24px" />
    </>
  );
};

export default withErrorBoundary(TokenPageTransfersTable);
