import PlayStoreIcon from "assets/svg/play_store.svg";
import AppStoreIcon from "assets/svg/app_store.svg";
import {
  CustomButtonStyle,
  CustomRow,
  PaddingComponent,
  CustomText,
} from "./Components";
import classes from "./Components.module.css";

export const PlayStoreButton = () => {
  return (
    <CustomButtonStyle background="var(--elevation-2)">
      <CustomRow alignItems="center">
        <img
          className={classes.buttonImage}
          src={PlayStoreIcon}
          alt="Play Store Icon"
        />
        <PaddingComponent width="4px" />
        <CustomText color="var(--text-1)" fontWeight="500" text="Play Store" />
      </CustomRow>
    </CustomButtonStyle>
  );
};

export const AppStoreButton = () => {
  return (
    <CustomButtonStyle background="var(--elevation-2)">
      <CustomRow alignItems="center">
        <img
          className={classes.buttonImage}
          src={AppStoreIcon}
          alt="App Store Icon"
        />
        <PaddingComponent width="4px" />
        <CustomText color="var(--text-1)" fontWeight="500" text="App Store" />
      </CustomRow>
    </CustomButtonStyle>
  );
};
