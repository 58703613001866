/* eslint-disable no-undef */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import getDataFeed from "./Datafeed";
import styles from "./TradingViewGraph.module.css";
import { ThemeContext } from "contextStates/Theme.context";
import GraphLoader from "../Profile/ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";
import { Divider, PaddingComponent } from "components/UI/Components/Components";
import TVPriceHeader from "../TVPriceHeader/TVPriceHeader";
import { abbreviateNumber } from "utils/misc";
import { getCdnUrl } from "utils/assets";
import withLoader from "components/core/withLoader";
import { getTokenMetadata } from "api/contract.api";
import {
  CHART_RESOLUTIONS_MAP,
  NATIVE_SYMBOL_TO_ADDRESS_MAP,
} from "utils/constants";
import { getUniqueId } from "utils/event_tracking";
import { chains } from "contextStates/auth_utils";
import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import { EMPTY_STATES } from "zxStyles/constants";
const TV_LIBRARY_SRC = getCdnUrl("tradingview/charting_library/");
const TV_SCRIPT_SRC = getCdnUrl(
  "tradingview/charting_library/charting_library.js"
);
const getTvScriptElement = () => {
  return document.querySelector(`script[src="${TV_SCRIPT_SRC}"]`);
};

const renderToolbarResolutions = ({ setSwitchPrice }) => {
  const widget = window.tvWidget;
  if (!widget) return;
  const container = document.querySelector("#trading_view_graph_react");
  const iframe = container.querySelector("iframe");
  if (!iframe) return;
  CHART_RESOLUTIONS_MAP.forEach((res) => {
    const button = widget.createButton();
    const baseStyle = "cursor:pointer; padding: 0 6px";
    const uuid = getUniqueId();
    button.setAttribute("title", res.label);
    button.setAttribute("data-resolution", res.resolution);
    button.setAttribute("id", uuid);
    button.addEventListener("click", function (e) {
      widget.activeChart().setResolution(res.resolution);
      localStorage.setItem("tv_resolution", res.resolution);
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      const buttons = iframeDoc.querySelectorAll(".resolution-buttons");
      buttons.forEach((el) => {
        el.style = `${baseStyle}`;
      });
      button.style = `${baseStyle}; color: #0648D7; border-radius;font-weight:600`;
    });
    button.style = `${baseStyle}`;
    button.classList.add("resolution-buttons");
    button.textContent = res.label;
  });
  const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
  const buttons = iframeDoc.querySelectorAll(".resolution-buttons");
  const currentResolution = widget.activeChart().resolution();

  buttons.forEach((el) => {
    const map = CHART_RESOLUTIONS_MAP.find(
      (res) => res.resolution === currentResolution
    );
    if (el.textContent === currentResolution || el.textContent === map?.label) {
      el.style = `cursor:pointer; padding: 0 6px; color: #0648D7; border-radius;font-weight:600`;
    }
  });
  // Price & Market Cap
  let pricePreference = localStorage.getItem("tv_price_preference");
  if (!pricePreference) {
    pricePreference = "price";
    localStorage.setItem("tv_price_preference", "price");
  }
  const togglePrice = widget.createButton();
  const activePriceStyle = `color:#0648D7;`;
  const isPriceActive = pricePreference === "price";
  togglePrice.setAttribute("title", "Toggle between price & market Cap");
  togglePrice.innerHTML = `<p> <span style=${isPriceActive ? activePriceStyle : ""}>Price</span> &nbsp;/&nbsp; <span style=${!isPriceActive ? activePriceStyle : ""}>Market Cap</span></p>`;
  togglePrice.style = `cursor:pointer; padding: 0 6px`;
  togglePrice.addEventListener("click", function (e) {
    const currentPreference = localStorage.getItem("tv_price_preference");
    const newPreference =
      currentPreference === "price" ? "market_cap" : "price";
    localStorage.setItem("tv_price_preference", newPreference);
    togglePrice.innerHTML = `<p> <span style=${newPreference === "price" ? activePriceStyle : ""}>Price</span> &nbsp;/&nbsp; <span style=${newPreference !== "price" ? activePriceStyle : ""}>Market Cap</span></p>`;
    setSwitchPrice(getUniqueId());
  });
};

const initiateWidget = ({
  datafeed,
  theme,
  onReadyCallback,
  ref,
  height = 500,
  fullscreen = false,
  setSwitchPrice,
}) => {
  if (window.TradingView) {
    let resolution = localStorage.getItem("tv_resolution");
    if (!resolution) {
      resolution = "5m";
      localStorage.setItem("tv_resolution", "5m");
    }
    const heightWidth = fullscreen ? {} : { height, width: "100%" };
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    window.tvWidget = new TradingView.widget({
      fullscreen,
      ...heightWidth,
      symbol: "...", // Default symbol
      interval: resolution,
      custom_font_family: "'Roboto', sans-serif",
      container: ref.current, // Reference to an attribute of the DOM element
      datafeed: datafeed,
      library_path: TV_LIBRARY_SRC,
      custom_css_url: `custom_styles.css`,
      timezone: timezone === "Asia/Calcutta" ? "Asia/Kolkata" : timezone,
      theme,
      "priceScale.right.formatter": (price) => {
        return abbreviateNumber(price.toFixed(4));
      },
      custom_formatters: {
        priceFormatterFactory: () => {
          return {
            format: (price) => {
              return abbreviateNumber(price, false, true);
            },
          };
        },
      },
      overrides: {
        "scalesProperties.showStudyLastValue": false,
      },

      enabled_features: [
        "two_character_bar_marks_labels",
        "seconds_resolution",
        "timezone_menu",
        "create_volume_indicator_by_default",
      ],
      disabled_features: [
        "context_menus",
        "edit_buttons_in_legend",
        "left_toolbar",
        "timeframes_toolbar",
        "main_series_scale_menu",
        "use_localstorage_for_settings",
        "control_bar",
        "display_market_status",
        "popup_hints",
        "header_symbol_search",
        "header_quick_search",
        "header_screenshot",
        "header_settings",
        "symbol_info",
        "header_compare",
        "header_indicators",
        "symbol_search_hot_key",

        "header_resolutions",
      ],
    });
    const widget = window.tvWidget;
    if (widget) {
      widget?.onChartReady(() => {
        onReadyCallback();
        widget?.chart().applyOverrides({
          "priceScale.right.formatter": (price) => {
            return abbreviateNumber(price.toFixed(2));
          },
        });
      });
      widget
        ?.headerReady()
        .then(() => renderToolbarResolutions({ setSwitchPrice }));
    }
  }
};
const ChartsPage = ({
  queryData,
  chain,
  address,
  showPriceHeader = false,
  showSymbolInHeader = false,
  markers = null,
  getMarkers = () => null,
  headerPadding = "0 16px",
  headerDivider = false,
  chartPrice = false,
  setChartPrice = () => {},
  isTokenPage = false,
  overrideTheme = null,
  chartHeight = 500,
  isFullScreen = false,
  isNativeToken = false,
}) => {
  const [localChartPrice, setLocalChartPrice] = useState(null);
  const [switchPrice, setSwitchPrice] = React.useState(getUniqueId());
  const chartPreference = useRef(localStorage.getItem("tv_price_preference"));
  const updatePrice = (price) => {
    chartPrice !== false ? setChartPrice(price) : setLocalChartPrice(price);
  };
  const { coingecko_slug: slug, symbol } = queryData;
  const {
    gecko_terminal_base_or_quote: terminalBase,
    gecko_terminal_network: terminalNetwork,
    gecko_terminal_pool: terminalPool,
  } = queryData;
  const terminalKeys = useMemo(
    () => [terminalBase, terminalNetwork, terminalPool],
    [terminalBase, terminalNetwork, terminalPool]
  );
  const chartRef = useRef(null);
  const [isPriceEmpty, setIsPriceEmpty] = React.useState(false);
  const [isChartInitialized, setIsChartInitialized] = React.useState(false);
  const [isChartLoading, setIsChartLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const { theme } = useContext(ThemeContext);
  const chartTheme = useRef(theme);
  const socket = useRef(null);
  const socketListener = useRef(null);
  useEffect(() => {
    setLocalChartPrice(null);
  }, [chain, address, setLocalChartPrice]);
  useEffect(() => {
    try {
      if (!address || !chain) throw new Error("Address or chain not provided");
      const isNativeAddress =
        address.toUpperCase() === "SOL"
          ? true
          : chains.find((c) => c.token === address);

      const birdEyeAddress = isNativeAddress
        ? NATIVE_SYMBOL_TO_ADDRESS_MAP[chain] ?? address === "ETH"
          ? NATIVE_SYMBOL_TO_ADDRESS_MAP["Ethereum"]
          : address
        : address;
      const datafeed = getDataFeed({
        chain,
        address: birdEyeAddress,
        slug,
        symbol,
        setEmpty: setIsPriceEmpty,
        terminalKeys,
        setError: setIsError,
        markers,
        getMarkers,
        updatePrice,
        isNativeToken,
        metadata: queryData,
        socket,
        socketListener,
      });
      const scriptSrc = TV_SCRIPT_SRC;
      const existingScript = getTvScriptElement();
      if (!existingScript && !isPriceEmpty && !isError && !isChartInitialized) {
        console.log("Script does not exist, creating script");
        const script = document.createElement("script");
        script.src = scriptSrc;
        script.async = true;
        // Add load event listener to initiate the widget
        script.onload = () => {
          // eslint-disable-next-line no-undef
          initiateWidget({
            datafeed,
            theme,
            onReadyCallback: () => setIsChartLoading(false),
            ref: chartRef,
            height: chartHeight,
            fullscreen: isFullScreen,
            setSwitchPrice,
          });
        };
        document.head.appendChild(script);
        setIsChartInitialized(true);
      } else if (
        existingScript &&
        !isPriceEmpty &&
        !isError &&
        (!isChartInitialized ||
          chartTheme.current !== theme ||
          chartPreference.current !== switchPrice)
      ) {
        console.log(
          "Script exists, but widget not initialized or theme changed"
        );
        chartTheme.current = theme;
        chartPreference.current = switchPrice;
        if (window.TradingView) {
          initiateWidget({
            datafeed,
            theme: overrideTheme ? overrideTheme : theme,
            onReadyCallback: () => setIsChartLoading(false),
            ref: chartRef,
            height: chartHeight,
            fullscreen: isFullScreen,
            setSwitchPrice,
          });
          setIsChartInitialized(true);
        }
      } else {
        // Your widget initialization code goes here
        console.log("Script already exists and widget initialized");
        if (window.TradingView && !isTokenPage) {
          initiateWidget({
            datafeed,
            theme: overrideTheme ? overrideTheme : theme,
            onReadyCallback: () => setIsChartLoading(false),
            ref: chartRef,
            height: chartHeight,
            fullscreen: isFullScreen,
            setSwitchPrice,
          });
          setIsChartInitialized(true);
        }
      }
    } catch (err) {
      console.error({ err });
      setIsError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    address,
    terminalKeys,
    getMarkers,
    chain,
    slug,
    theme,
    symbol,
    isPriceEmpty,
    isError,
    isChartInitialized,
    chartTheme,
    markers,
    isTokenPage,
    overrideTheme,
    switchPrice,
  ]);
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const socketNow = socket.current;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const socketListenerNow = socketListener.current;
      setIsChartInitialized(false);
      if (socketNow) {
        socketNow.close();
      }
      if (socketListenerNow) {
        window.removeEventListener("visibilityChange", socketListenerNow);
      }
      if (window.tvWidget) {
        window.tvWidget.remove();
      }
    };
  }, [chain, address, socket, socketListener]);
  if (isError) {
    return (
      <ZxEmptyState
        state={EMPTY_STATES.ERROR}
        text="Could not load chart data"
      />
    );
  }
  const displayPrice = chartPrice === false ? localChartPrice : chartPrice;
  return (
    <PaddingComponent padding="0">
      <PaddingComponent padding={headerPadding}>
        {showPriceHeader && (
          <TVPriceHeader
            chartPrice={displayPrice}
            slug={slug}
            chain={chain}
            address={address}
            poolAddress={terminalPool}
            symbol={symbol}
            showSymbolInHeader={showSymbolInHeader}
            isTokenPage={isTokenPage}
            marketCap={displayPrice * queryData?.mcap_multiplier}
            isNativeToken={isNativeToken}
          />
        )}
        {headerDivider && <Divider margin="8px 0" />}
      </PaddingComponent>
      {isChartLoading && <GraphLoader height={600} />}
      <div
        ref={chartRef}
        id="trading_view_graph_react"
        style={{
          display: isChartLoading ? "none" : "block",
          height: chartHeight,
        }}
      />
      {/* {!isChartInitialized && <GraphLoader height={600} />} */}
    </PaddingComponent>
  );
};

export default withLoader(
  ChartsPage,
  (props) => {
    if (props.metadata) {
      return props.metadata;
    }
    return getTokenMetadata({ address: props.address, chainId: props.chain });
  },
  {
    queryOptions: () => {
      return {
        staleTime: 1000 * 60 * 5,
      };
    },
    queryKey: ({ address, chain }) => ["tokenMetadata", address, chain],
    retry: 2,
    CustomLoader: () => <GraphLoader height={600} />,
    containerClass: styles.empty,
  }
);
