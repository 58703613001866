import React from "react";
import classes from "./groupedActivityDetailsEngagements.module.css";
import ActivityEngagements from "components/ActivityEngagements/ActivityEngagements";
import { useLocation } from "react-router-dom";
import CurrentEngagements from "components/ActivityEngagements/CurrentEngagements/CurrentEngagements";
import { ONE_DAY } from "utils/constants";
import { useQuery } from "@tanstack/react-query";
import { getEngagements } from "api/feed.api";
import { getEngagementsQueryKey } from "components/ActivityEngagements/utils";
const GroupedActivityDetailsEngagements = ({
  contentId,
  type,
  isLoading = false,
  hideEngagements = false,
  contentTimestamp,
}) => {
  const engagementsQueryKey = getEngagementsQueryKey(type, contentId);
  const { data: engagements } = useQuery({
    queryKey: engagementsQueryKey,
    queryFn: async () => {
      return getEngagements({
        id: contentId,
        type: type,
      });
    },
    staleTime: ONE_DAY,
    enabled: !!contentId && !!type,
  });

  const { state } = useLocation();

  const location = useLocation();
  const baseUrl = location.pathname;

  if (isLoading) return null;
  if (!engagements || hideEngagements) {
    return null;
  }

  const showCurrentEngagements =
    !!engagements?.likes?.count ||
    !!engagements?.replies?.count ||
    !!engagements?.reposts?.count ||
    !!engagements?.bookmarks?.count;
  return (
    <div className={classes.groupedActivityEngagements}>
      {showCurrentEngagements && (
        <div className={classes.currentEngagements}>
          <CurrentEngagements
            type={type}
            contentId={contentId}
            bookmarkLink={
              baseUrl.includes(`/bookmarks`) ? baseUrl : `${baseUrl}/bookmarks`
            }
            repostLink={
              baseUrl.includes(`/reposts`) ? baseUrl : `${baseUrl}/reposts`
            }
            reactionsLink={
              baseUrl.includes(`/likes`) ? baseUrl : `${baseUrl}/likes`
            }
          />
        </div>
      )}
      <div className={classes.activityEngagementWrapper}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
            position: "relative",
          }}>
          <ActivityEngagements // only for like button
            className={classes.actionButtons}
            contentId={contentId}
            type={type}
            contentTimestamp={contentTimestamp}
            engagements={engagements}
            hideCount
            hideItems={{
              comments: true,
              bookmarks: true,
              share: true,
              reposts: true,
            }}
            index={state?.index}
            pageIndex={state?.pageIndex}
            isActivityDetails
            queryKey={engagementsQueryKey}
            showCurrEngagaments={false}
            showDivider={false}
          />
        </div>
        <ActivityEngagements
          className={classes.actionButtons}
          contentId={contentId}
          type={type}
          contentTimestamp={contentTimestamp}
          engagements={engagements}
          hideCount
          hideItems={{
            comments: true,
            likes: true,
          }}
          index={state?.index}
          pageIndex={state?.pageIndex}
          isActivityDetails
          queryKey={engagementsQueryKey}
          showCurrEngagaments={false}
          showDivider={false}
          customShareLink={location.pathname}
        />
      </div>
    </div>
  );
};

export default GroupedActivityDetailsEngagements;
