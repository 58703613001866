import React from "react";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";
import brokenImageIcon from "assets/svg/feed/nft_fallback_no_bg.svg";
import { transformedIPFSLink } from "utils/misc";
import classes from "./GroupActivityMediaPreview.module.css";
React.lazy(() => import("@google/model-viewer/dist/model-viewer"));
function getImageHeight(
  originalWidth,
  originalHeight,
  maxHeight = 400,
  minHeight = 200,
  maxWidth = 400
) {
  const aspectRatio = originalWidth / originalHeight;
  let newHeight = originalHeight;
  let newWidth = originalWidth;
  // Adjust if height is greater than maxHeight
  if (originalHeight > maxHeight || originalWidth > maxWidth) {
    newHeight = maxHeight;
    newWidth = maxHeight * aspectRatio;
  }
  // adjust if width is greater than maxWidth
  // Adjust if height is less than minHeight
  if (newHeight < minHeight) {
    newHeight = minHeight;
    newWidth = minHeight * aspectRatio;
  }

  return { width: newWidth, height: newHeight };
}
export const GroupActivityGalleryMediaPreview = ({
  type = "image",
  mediaItem,
  setType,
  index,
  itemsLength,
  isRepost = false,
}) => {
  let height = "400px";
  let borderRadius = "0px";
  let width = "auto";
  if (itemsLength >= 4) {
    height = "200px";
    if (index === 0) {
      borderRadius = "6px 0px 0px 0px";
    } else if (index === 1) {
      borderRadius = "0px 6px 0px 0px";
    } else if (index === 2) {
      borderRadius = "0px 0px 0px 6px";
    } else {
      borderRadius = "0px 0px 6px 0px";
    }
  } else if (itemsLength === 3) {
    if (index === 0) {
      height = "400px";
      borderRadius = "6px 0px 0px 6px";
    } else if (index === 1) {
      borderRadius = "0px 6px 0px 0px";
      height = "199px";
    } else {
      borderRadius = "0px 0px 6px 0px";
      height = "199px";
    }
  } else if (itemsLength === 2) {
    if (index === 0) {
      const { height: calcHeight, width: calcWidth } =
        mediaItem?.width && mediaItem?.height
          ? getImageHeight(mediaItem?.width, mediaItem?.height, 400, 320)
          : { width: "auto", height: "auto" };
      height = calcHeight;
      width = calcWidth;
      borderRadius = "6px 0px 0px 6px";
    } else {
      const { height: calcHeight, width: calcWidth } =
        mediaItem?.width && mediaItem?.height
          ? getImageHeight(mediaItem?.width, mediaItem?.height, 400, 320)
          : { width: "auto", height: "auto" };
      height = calcHeight;
      width = calcWidth;
      borderRadius = "6px 0px 0px 6px";
      borderRadius = "0px 6px 6px 0px";
    }
  } else if (itemsLength === 1) {
    const { height: calcHeight } =
      mediaItem?.width && mediaItem?.height
        ? getImageHeight(mediaItem?.width, mediaItem?.height, 400)
        : { width: "auto", height: "auto" };
    height = calcHeight;
    width = "auto";
    borderRadius = "6px";
  } else {
    height = "200px";
    width = "200px";
    borderRadius = "6px";
  }

  const styles = {
    height: height,
    borderRadius: borderRadius,
    width: width,
    objectFit: "contain",
  };
  return (
    <GroupActivityMediaPreview
      type={type}
      isScaled={itemsLength < 3}
      mediaItem={mediaItem}
      index={index}
      itemsLength={itemsLength}
      setType={setType}
      styles={styles}
    />
  );
};

export const GroupActivityMediaPreview = ({
  type = "image",
  mediaItem,
  setType,
  styles,
  itemsLength,
  isScaled = false,
}) => {
  const src = mediaItem?.scaled_src?.length
    ? mediaItem?.scaled_src
    : mediaItem?.src;
  const mediaSrc = transformedIPFSLink(src) || src;
  // Use model/video tags if they are substrings of `type` else fallback to image tag
  const tagToUse = type?.includes("model")
    ? "model"
    : type?.includes("video")
      ? "video"
      : "image";

  return (
    <div
      style={{
        position: "relative",
        flexShrink: 0,
        overflow: "hidden",
        maxHeight: "400px",
        maxWidth: "758px",
        ...styles,
      }}>
      {tagToUse === "image" && (
        <img
          className={classes.mediaTypeImage}
          src={mediaSrc}
          alt={mediaItem?.alt}
          style={{
            objectFit: itemsLength === 1 ? "contain" : "cover",
          }}
          onError={(e) => {
            if (type?.includes("broken")) {
              e.target.src = brokenImageIcon;
              e.target.className += ` ${classes.fallbackImage}`;
              e.target.style.objectFit = "contain";
            } else {
              setType?.("video");
            }
          }}
        />
      )}
      {tagToUse === "model" && (
        // display 3d object poster on feed item
        <div className={classes.mediaPlaceholder}>
          <div className={`${classes.mediaPlaceholder} ${classes.label}`}>
            <ThreeDRotationIcon fontSize="large" />
          </div>
          {mediaItem?.poster ? (
            <img
              className={`${classes.mediaTypeImage} ${classes.mediaPlaceholder}`}
              src={mediaItem.poster}
              alt={mediaItem.alt}
            />
          ) : (
            <model-viewer
              camera-controls
              reveal="interaction"
              poster={mediaItem?.poster}
              src={mediaSrc}
              alt={mediaItem?.alt}
              style={{ width: "100%", height: "100%" }}></model-viewer>
          )}
        </div>
      )}
      {tagToUse === "video" && (
        // display video poster on feed item
        <div className={classes.mediaPlaceholder}>
          <div className={`${classes.mediaPlaceholder} ${classes.label}`}>
            <PlayCircleFilledIcon fontSize="large" />
          </div>
          {mediaItem?.poster ? (
            <img
              className={`${classes.mediaTypeVideo} ${classes.mediaPlaceholder}`}
              src={mediaItem.poster}
              alt={mediaItem.alt}
            />
          ) : (
            <video
              className={`${classes.mediaTypeVideo}`}
              onError={() => {
                setType?.("image broken");
              }}>
              <source src={mediaSrc} type="video/mp4" />
            </video>
          )}
        </div>
      )}
    </div>
  );
};
