import React from "react";
import {
  CustomRow,
  CustomText,
  InfoBoxContainer,
  PaddingComponent,
} from "components/UI/Components/Components";
import { ReactComponent as RedFlag } from "assets/svg/trending/redFlag.svg";
import { ReactComponent as GreenFlag } from "assets/svg/trending/greenFlag.svg";
import { ReactComponent as NeutralFlag } from "assets/svg/trending/neutralFlag.svg";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";

import styles from "./AuditFlags.module.css";
import ToggleShowMore from "components/ToggleShowMore/ToggleShowMore";

const AUDIT_MAP = {
  neutral: {
    text: "Neutral",
    bgColor: "var(--elevation-2)",
    color: "var(--text-color)",
    flag: <NeutralFlag />,
  },
  red: {
    text: "Red",
    bgColor: "var(--error-light)",
    color: "var(--error)",
    flag: <RedFlag />,
  },
  green: {
    text: "Green",
    bgColor: "var(--success-light)",
    color: "var(--success)",
    flag: <GreenFlag />,
  },
};

const AuditFlags = ({ data, profiles, type = "red" }) => {
  if (data?.length === 0) {
    return null;
  }
  const { text, bgColor, color, flag } =
    AUDIT_MAP[type] ?? AUDIT_MAP["neutral"];
  return (
    <InfoBoxContainer
      padding="0"
      headerBackground={bgColor}
      headerPaddingBottom="0px"
      contentPaddingTop="0px"
      className={styles["audit-flags"]}
      header={
        <PaddingComponent padding="12px 16px">
          <CustomRow alignItems="center" gap="8px">
            {flag}
            <CustomText
              text={`${data?.length} ${text} ${
                data?.length > 1 ? "flags" : "flag"
              }`}
              color={color}
              fontSize="15px"
              fontWeight="600"
            />
          </CustomRow>
        </PaddingComponent>
      }>
      <PaddingComponent padding="12px 16px">
        <ol>
          <ToggleShowMore
            itemsToShow={4}
            buttonColor="var(--primary-color)"
            showCount={false}>
            {data?.map((item, index) => (
              <li key={`audit-flag-${index}`}>
                <FeedDescription
                  templateString={item}
                  profiles={profiles}
                  fontSize="14px"
                />
              </li>
            ))}
          </ToggleShowMore>
        </ol>
      </PaddingComponent>
    </InfoBoxContainer>
  );
};

export default AuditFlags;
