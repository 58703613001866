import Image, { IMAGE_TYPES } from "components/UI/Image";
import classes from "./ProfileItem.module.css";
import FollowEditButton from "components/FollowEditButton/FollowEditButton";
import Bio from "components/V2/Profile/ProfileHeader/Bio";
import lensIcon from "assets/svg/lens_white.svg";
import FollowedByFollowers from "./FollowedByFollowers";
import InteractionFooter from "./InteractionFooter";
import MatchedFromFooter from "./MatchedFromFooter";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Handshake } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";

import {
  CustomSkeleton,
  InfoBox,
  PaddingComponent,
  MaterialIconWrapper,
  CustomRow,
  FlexBox,
  CustomButton,
} from "components/UI/Components/Components";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import ConditionalLink from "shared/ConditionalLink";
import { getTrimmedString } from "utils/misc";

export const ProfileItemTypes = {
  twitter: "Twitter",
  "0xppl": "0xPeople",
  lens: "Lens",
  searchResult: "searchResult",
};

export const ProfileItemGhost = () => {
  return (
    <div className={classes.ghost_container}>
      <PaddingComponent paddingRight="20px">
        <CustomSkeleton variant="circular" height={40} width={40} />
      </PaddingComponent>
      <div className={classes.profile_details}>
        <CustomSkeleton width={124} />
        <div className={classes.bio}>
          <CustomSkeleton variant="text" />
        </div>
      </div>
    </div>
  );
};

const ProfileItem = ({
  profile,
  profileItemType,
  details,
  identity_details = {},
  refetchFeedOnFollowOrUnfollow = false,
  profiles,
  identities,
  nativeTokensInfo,
  resultType,
  chainData,
  imageType = IMAGE_TYPES.AVATAR,
  hideNetWorth = false,
  requestConnection = false,
  isFeedItem = false,
  icon,
  showNetWorth = false,
  type,
}) => {
  const history = useHistory();
  const SUPPORTED_ITEMS = [
    "follow_of_followed",
    "onchain_interaction",
    "matched_from",
  ];

  const list = details?.reasons.filter((item) =>
    SUPPORTED_ITEMS.includes(item.type)
  );

  const linkSuffix = requestConnection ? "/activities?requestConnection" : "";
  const isVerified =
    profile?.is_user_account ||
    profile?.user_since != null ||
    profile?.is_verified;
  return (
    <div
      className={`${classes.container} ${
        isFeedItem && classes["feed-container"]
      }`}>
      <InfoBox
        padding={
          list?.length === 0 ? "14px 20px 16px 20px" : "14px 20px 10px 20px"
        }>
        <div
          className={`${classes.headerRow} ${
            isFeedItem && classes.feedHeaderRow
          }`}>
          <ProfileHoverCard actor={profile}>
            <ConditionalLink to={profile?.link + linkSuffix}>
              <div className={classes.image_container}>
                <Image
                  src={profile?.display_picture}
                  alt={profile?.display_name}
                  className={classes.profile_image}
                  imageType={imageType}
                  name={profile?.display_name}
                />
                {icon && <div className={classes.emojiIcon}>{icon}</div>}
                {profileItemType === ProfileItemTypes.searchResult &&
                  !!chainData && (
                    <div className={classes.chainIconContainer}>
                      <Image
                        src={chainData.overlayIcon}
                        className={`${classes.search_chain_icon} `}
                        alt={chainData.name}
                      />
                    </div>
                  )}
                {profileItemType === ProfileItemTypes.twitter && (
                  <div className={classes.twitter_icon}>
                    <MaterialIconWrapper
                      icon={TwitterIcon}
                      size="11px"
                      color="white"
                    />
                  </div>
                )}
                {profileItemType === ProfileItemTypes.lens && (
                  <div className={classes.lensIconContainer}>
                    <img
                      src={lensIcon}
                      alt="lens-icon"
                      className={classes.lensIcon}
                    />
                  </div>
                )}
              </div>
            </ConditionalLink>
          </ProfileHoverCard>
          <FlexBox flex="1">
            <div className={classes.profile_details}>
              <div className={classes.profile_details_top}>
                <ProfileHoverCard actor={profile}>
                  <ConditionalLink
                    to={profile?.link + linkSuffix}
                    className={classes.profile_name}>
                    <div>
                      {isFeedItem
                        ? getTrimmedString(profile?.display_name, 12)
                        : profile?.display_name}
                    </div>
                    <VerifiedBadge profile={profile} showTooltip />
                  </ConditionalLink>
                </ProfileHoverCard>
                {profileItemType === ProfileItemTypes.searchResult &&
                  !!resultType && (
                    <div className={classes.net_worth}>{resultType}</div>
                  )}
                {!hideNetWorth && (
                  <div className={classes.net_worth}>
                    {profile?.net_worth?._Any?.total_usd_value?.display_value}
                  </div>
                )}
              </div>
              {!isFeedItem && profile?.bio && (
                <div className={classes.bio}>
                  <Bio bio={profile?.bio} maxCharacters={200} />
                </div>
              )}
            </div>
          </FlexBox>

          {requestConnection ? (
            <div className={classes.follow_button}>
              <CustomButton
                disabled={!isVerified}
                onClick={() => {
                  history.push(profile?.link + linkSuffix);
                }}
                text={
                  <CustomRow gap="4px" alignItems="center">
                    <Handshake width={16} height={16} />
                    <span>Share Wallet</span>
                  </CustomRow>
                }
              />
            </div>
          ) : resultType === "address" || resultType === "identity" ? (
            <div className={classes.follow_button}>
              <FollowEditButton
                small={isFeedItem}
                name={profile?.display_name}
                bio={profile?.bio}
                twitter={profile?.socials?.twitter}
                socials={profile?.socials}
                followed={profile?.followed}
                showEdit={false}
                onUpdateCallback={() => {}}
                address={profile?.address}
                identityId={profile?.id}
                disabled={profile?.disable_follow}
                refetchFeedOnFollowOrUnfollow={refetchFeedOnFollowOrUnfollow}
                followDirectly={profile?.follow_directly}
              />
            </div>
          ) : null}
        </div>
        {isFeedItem && profile?.bio ? (
          <div className={classes.bio}>
            <Bio bio={profile?.bio} maxCharacters={200} clampBio={isFeedItem} />
          </div>
        ) : isFeedItem ? (
          <PaddingComponent height="60px" />
        ) : null}

        {details?.reasons?.length > 0 ? (
          details?.reasons
            .filter((item) => SUPPORTED_ITEMS.includes(item.type))
            .map((item, idx) => {
              switch (item.type) {
                case "follow_of_followed":
                  return (
                    <FollowedByFollowers
                      reason={item}
                      identity_details={identity_details}
                      key={`followed-by-followers-${idx}`}
                      wrapLines={isFeedItem}
                      trimItem={isFeedItem}
                      maxLengthPerItem={12}
                    />
                  );
                case "onchain_interaction":
                  return (
                    <InteractionFooter
                      text={item?.reason}
                      profiles={profiles}
                      identities={identities}
                      nativeTokensInfo={nativeTokensInfo}
                      noTopMargin={idx > 0}
                      key={`interaction-footer-${idx}`}
                    />
                  );
                case "matched_from":
                  return (
                    <MatchedFromFooter
                      searchHints={item?.search_hints}
                      key={`matched-by-${idx}`}
                    />
                  );
                default:
                  return null;
              }
            })
        ) : isFeedItem ? (
          <PaddingComponent height="56px" />
        ) : null}
      </InfoBox>
    </div>
  );
};

export default ProfileItem;
