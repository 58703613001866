import React from "react";
import GhostFeedItem from "components/Feed/GhostFeedItem/GhostFeedItem";
// import EndOfContent from "shared/EndOfContent/EndOfContent";
import Refetch from "components/UI/Refetch/Refetch";
import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import { EMPTY_STATES } from "zxStyles/constants";

const StatusRenderer = ({
  isLoading,
  isSuccess,
  isError,
  refetch,
  isFetching,
  hasNextPage,
  activities,
  showShimmer = false,
  message = null,
  isHomePageFeedItem,
}) => {
  if (isLoading || isFetching || showShimmer) {
    return [...Array(10).keys()].map((i) => (
      <GhostFeedItem
        isHomePageFeedItem={isHomePageFeedItem}
        key={i}
        index={i}></GhostFeedItem>
    ));
  }

  if (isError) {
    return (
      <Refetch
        margin="16px 0"
        onClick={() => {
          refetch();
        }}
      />
    );
  }

  if (isSuccess && activities?.length === 0) {
    return <ZxEmptyState state={EMPTY_STATES.POSTS} text="No activity" />;
  }

  // remove end of content
  // if (activities?.length && !hasNextPage) {
  //   return <EndOfContent message={message} />;
  // }
  return null;
};

export default StatusRenderer;
