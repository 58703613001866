import classes from "./AddWallet.module.css";
import {
  CustomButton,
  CustomText,
  PaddingComponent,
} from "../../../../../UI/Components/Components";
import { CustomColumn, CustomRow, FlexGrow } from "./AddWalletComponent";
import { HelpSection } from "./WalletType";
import { WalletTypeCheckbox } from "./WalletTypeCheckbox";
import React, { useContext, useEffect } from "react";
import { AddWalletContext } from "./AddWallet";
import { TextField } from "@mui/material";
import { Plugs, Textbox, ClipboardText } from "@phosphor-icons/react";
import DynamicWalletButton from "../../../../../DynamicWalletButton/DynamicWalletButton";
import { trackEvent } from "../../../../../../utils/event_tracking";
import { isWalletValid } from "../../../../../../utils/wallet_validator";
import { GlobalContext } from "../../../../../../contextStates/Global";
import { getCdnUrl } from "../../../../../../utils/assets";

export const CustomLabel = ({ label }) => {
  return (
    <>
      <CustomText
        text={label}
        textAlign="left"
        fontWeight="500"
        fontSize="17px"
      />
      <PaddingComponent height="8px" />
    </>
  );
};
export const NonShrinkingTextField = React.forwardRef((props, ref) => {
  const { value, label, ...rest } = props;

  return (
    <TextField
      variant="outlined"
      disableUnderline={true}
      autoComplete="off"
      sx={{
        ".Mui-disabled": {
          color: "var(--text-1) !important",
          "-webkitTextFillColor": "var(--text-2) !important",
          cursor: "not-allowed",
        },
        "& .MuiOutlinedInput-root": {
          color: "var(--text-1) !important",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--elevation-2)",
            backgroundColor: "var(--elevation-1)",
            borderRadius: 2,
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--primary-color)",
              backgroundColor: "transparent",
            },
          },
          "& .MuiInputLabel-outlined": {
            backgroundColor: "var(--elevation-1)",
            "&.Mui-disabled": {
              borderColor: "transparent",
            },
          },
        },
        "& .MuiOutlinedInput-root:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--elevation-2)",
            backgroundColor: "var(--elevation-1)",
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--primary-color)",
              backgroundColor: "transparent",
            },
          },
        },
      }}
      value={value}
      inputRef={ref}
      {...rest}
    />
  );
});
export const ManualWalletAddressForm = () => {
  const { address, setAddress, name, setName, session, nameRef, addressRef } =
    useContext(AddWalletContext);
  const handlePaste = async (e) => {
    const clipText = await navigator.clipboard.readText();
    setAddress(clipText);
  };
  let showAddressError = false;
  let addressHelperText = "";
  if (address.length !== 0) {
    showAddressError = !isWalletValid(address);
  }
  if (showAddressError) {
    addressHelperText = "Invalid Address";
  }
  useEffect(() => {
    if (addressRef.current && !session) {
      addressRef.current.focus();
    } else if (nameRef.current) {
      nameRef.current.focus();
    }
  }, [addressRef, nameRef, session]);
  return (
    <CustomColumn className={classes.connectForm}>
      <PaddingComponent minHeight="16px" />
      <CustomLabel label={"Add wallet address"} />
      <CustomRow gap="16px" alignItems="start">
        <FlexGrow>
          <NonShrinkingTextField
            name={"address"}
            placeholder={"Wallet address"}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            fullWidth
            ref={addressRef}
            error={showAddressError}
            helperText={addressHelperText}
            disabled={session}
          />
        </FlexGrow>
        {!session && (
          <CustomButton
            radius="8px"
            height="50px"
            onClick={handlePaste}
            text={
              <CustomRow gap="5px">
                <ClipboardText size={20} />
                <CustomText
                  text="Paste"
                  color="white"
                  fontSize="15px"
                  fontWeight="500"
                />
              </CustomRow>
            }
          />
        )}
      </CustomRow>
      <PaddingComponent minHeight="24px" />
      <CustomLabel label={"Add wallet name"} />
      <CustomRow>
        <FlexGrow>
          <NonShrinkingTextField
            name={"name"}
            ref={nameRef}
            placeholder={"Wallet name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            fullWidth
          />
        </FlexGrow>
      </CustomRow>
    </CustomColumn>
  );
};

const ConnectWallet = ({ setActiveTab }) => {
  const {
    connectionType,
    nameRef,
    setConnectionType,
    isStep2Valid,
    setAddress,
    setSession,
    session,
    address,
    isWalletAlreadyInBundle,
  } = useContext(AddWalletContext);
  const { handleSuccessSnackbar, handleErrorSnackbar } =
    useContext(GlobalContext);
  let shouldShowAddressForm = connectionType === "manual";
  if (!shouldShowAddressForm && session) {
    shouldShowAddressForm = true;
  }
  const checkForExistingWallets = (address) => {
    if (isWalletAlreadyInBundle(address)) {
      handleErrorSnackbar(null, "Wallet already exists in the bundle");
      return true;
    }
    return false;
  };
  return (
    <div className={classes.formWrapper}>
      <div className={classes.form}>
        <CustomText
          fontSize="17px"
          fontWeight="500"
          color="inherit"
          text="Connect wallet or add address"
          textAlign="left"
        />
        <PaddingComponent minHeight={"16px"} />
        <DynamicWalletButton
          disabled={session}
          onSuccess={(resp) => {
            if (resp.userAddress) {
              if (checkForExistingWallets(resp.userAddress)) {
                return;
              } else {
                setSession(resp);
                setAddress(resp.userAddress);
                setConnectionType("connected");
                handleSuccessSnackbar("Wallet connected successfully");
                if (nameRef.current) {
                  setTimeout(() => {
                    nameRef.current.focus();
                  }, 100);
                }
              }
            }
          }}
          onError={(error) => {
            console.log(error);
          }}
          customRender={() => {
            return (
              <WalletTypeCheckbox
                isToggleable={!session}
                className={classes.option}
                isActive={connectionType === "connected"}
                setIsActive={() => {
                  // setConnectionType("connected");
                }}>
                <CustomRow gap="16px" alignItems="center">
                  <Plugs size={36} weight="duotone" />
                  <CustomColumn>
                    <CustomRow alignItems="center" gap="4px">
                      <CustomText
                        fontSize="17px"
                        fontWeight="500"
                        color="inherit"
                        text="Connect wallet"
                        textAlign="left"
                      />
                      <CustomRow
                        background="var(--recommended-tag)"
                        padding="0 4px"
                        borderRadius="4px">
                        <CustomText
                          fontSize="13px"
                          fontWeight="500"
                          text="RECOMMENDED"
                          textAlign="left"
                          color="white"
                        />
                      </CustomRow>
                    </CustomRow>
                    <CustomText
                      fontSize="15px"
                      fontWeight="400"
                      color="inherit"
                      text="Connect your EVM or Solana wallet"
                      textAlign="left"
                    />
                  </CustomColumn>
                </CustomRow>
              </WalletTypeCheckbox>
            );
          }}
        />

        <PaddingComponent minHeight={"16px"} />
        <WalletTypeCheckbox
          isToggleable={!session}
          className={classes.option}
          isActive={connectionType === "manual"}
          setIsActive={() => {
            setConnectionType("manual");
          }}>
          <CustomRow gap="16px" alignItems="center">
            <Textbox size={36} weight="duotone" />
            <CustomColumn padding="0 30px 0 0">
              <CustomText
                fontSize="17px"
                fontWeight="500"
                color="inherit"
                text="Add address manually"
                textAlign="left"
              />
              <CustomText
                fontSize="15px"
                fontWeight="400"
                color="inherit"
                text="You can add EVM, Solana, Bitcoin, Ton or Cosmos wallet"
                textAlign="left"
              />
            </CustomColumn>
          </CustomRow>
        </WalletTypeCheckbox>
        <PaddingComponent minHeight={"16px"} />
        {shouldShowAddressForm && <ManualWalletAddressForm />}
        <FlexGrow />
        <HelpSection
          icon={getCdnUrl(
            "assets/onboarding/add-wallet/thumb-up-front-color.png"
          )}
          subTitle="Connecting your wallet is always safer and we provide a verified badge too!"
          title="Why is connecting better?"
        />
        <PaddingComponent minHeight={"16px"} />
      </div>
      <div className={classes.footer}>
        <CustomRow gap="24px">
          <CustomButton
            className={classes.nextButton}
            text="Back"
            radius="8px"
            background="var(--elevation-1)"
            color="var(--primary-color)"
            onClick={() => {
              setActiveTab(0);
            }}
          />
          <CustomButton
            className={classes.nextButton}
            text="Next"
            radius="8px"
            disabled={!isStep2Valid}
            onClick={() => {
              trackEvent("complete_choose_connect_or_manual_step", {
                is_edit: false,
                is_connect_wallet: session !== null,
              });
              if (checkForExistingWallets(address)) return;
              setActiveTab(2);
            }}
          />
        </CustomRow>
      </div>
    </div>
  );
};

export default ConnectWallet;
