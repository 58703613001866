import Copyable from "components/ValueType/Copyable/Copyable";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import classes from "./TransactionLogs.module.css";
import {
  PaddingComponent,
  CustomColumn,
  CustomRow,
  Divider,
  FlexBox,
  RedirectLink,
} from "components/UI/Components/Components";
import { getShortDotFormatAddress } from "utils/profile_utils";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";

const LogsTopicsTile = ({ index, topic }) => {
  const toCopy = typeof topic === "object" ? topic?.address : topic;
  return (
    <CustomRow>
      <PaddingComponent paddingRight="24px">
        <span className={classes.argTableText}>{index}</span>
      </PaddingComponent>
      <FlexBox overflow="auto" flex="5">
        <CustomRow>
          {typeof topic === "object" ? (
            <ProfileHoverCard actor={topic}>
              <RedirectLink link={topic?.address_link}>
                <CustomRow gap="6px">
                  <Image
                    imageType={
                      topic?.is_token ? IMAGE_TYPES.TOKEN : IMAGE_TYPES.AVATAR
                    }
                    className={classes.contractIcon}
                    src={topic?.display_picture}
                    fallbackClassName={classes.fallbackImage}
                    name={topic?.display_name}
                  />
                  <span className={classes.highlightedText}>
                    {topic?.display_name}
                  </span>
                </CustomRow>
              </RedirectLink>
            </ProfileHoverCard>
          ) : (
            <span className={classes.argTableText}>
              {getShortDotFormatAddress({
                address: topic,
              })}
            </span>
          )}
          <Copyable content={toCopy}></Copyable>
        </CustomRow>
      </FlexBox>
    </CustomRow>
  );
};

const LogsUnparsedAdditionalDetails = ({ log }) => {
  if (!log?.topics) return null;
  return (
    <CustomColumn gap="10px">
      <PaddingComponent padding="2px 0px 2px 0px">
        <Divider />
      </PaddingComponent>
      <span className={classes.heading}>Topics</span>
      {log?.topics?.map((topic, index) => {
        return (
          <LogsTopicsTile
            key={`log-topic-${index}`}
            index={index}
            topic={topic}
          />
        );
      })}
      <PaddingComponent padding="2px 0px 2px 0px">
        <Divider />
      </PaddingComponent>
      <span className={classes.heading}>Data</span>
      <CustomRow>
        <span className={classes.argTableText}>
          {getShortDotFormatAddress({
            address: log?.data,
          })}
        </span>
        <Copyable content={log?.data}></Copyable>
      </CustomRow>
    </CustomColumn>
  );
};

export default LogsUnparsedAdditionalDetails;
