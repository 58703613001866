import { ReactComponent as Celebrate } from "assets/svg/reactions/celebrate.svg";
import { ReactComponent as ChaChing } from "assets/svg/reactions/cha-ching.svg";
import { ReactComponent as Curious } from "assets/svg/reactions/curious.svg";
import { ReactComponent as Fire } from "assets/svg/reactions/fire.svg";
import { ReactComponent as Like } from "assets/svg/reactions/like.svg";
import { ReactComponent as Rocket } from "assets/svg/reactions/rocket.svg";
import { ReactComponent as Sad } from "assets/svg/reactions/sad.svg";
import { ReactComponent as Haha } from "assets/svg/reactions/haha.svg";
import { ReactComponent as Rip } from "assets/svg/reactions/rip.svg";

import LikeAnim from "assets/gif/reactions/like.gif";
import ChaChingAnim from "assets/gif/reactions/cha-ching.gif";
import CuriousAnim from "assets/gif/reactions/curious.gif";
import CelebrateAnim from "assets/gif/reactions/celebrate.gif";
import SadAnim from "assets/gif/reactions/sad.gif";
import HahaAnim from "assets/gif/reactions/haha.gif";
import RipAnim from "assets/gif/reactions/rip.gif";

// no animations for these, using the same svg for both
import RocketAnimSub from "assets/svg/reactions/rocket.svg";
import FireAnimSub from "assets/svg/reactions/fire.svg";

const getAnimatedElement = (imageLink) => (
  <img src={imageLink} alt="liked" width={24} height={24} />
);

export const reactionData = [
  {
    icon: <Like />,
    id: 1,
    title: "Like",
    animatedIcon: getAnimatedElement(LikeAnim),
    order: 1,
  },
  {
    icon: <ChaChing />,
    id: 2,
    title: "Cha-Ching",
    animatedIcon: getAnimatedElement(ChaChingAnim),
    order: 2,
  },
  {
    icon: <Celebrate />,
    id: 3,
    title: "Wohoo",
    animatedIcon: getAnimatedElement(CelebrateAnim),
    order: 4,
  },
  {
    icon: <Fire />,
    id: 4,
    title: "Fire",
    animatedIcon: getAnimatedElement(FireAnimSub),
    order: 5,
  },
  {
    icon: <Curious />,
    id: 5,
    title: "Curious",
    animatedIcon: getAnimatedElement(CuriousAnim),
    order: 6,
  },
  {
    icon: <Sad />,
    id: 6,
    title: "Sad",
    animatedIcon: getAnimatedElement(SadAnim),
    order: 7,
  },
  {
    icon: <Rocket />,
    id: 7,
    title: "To the moon",
    animatedIcon: getAnimatedElement(RocketAnimSub),
    order: 8,
  },
  {
    icon: <Haha />,
    id: 8,
    title: "Haha",
    animatedIcon: getAnimatedElement(HahaAnim),
    order: 3,
  },
  {
    icon: <Rip />,
    id: 9,
    title: "RIP",
    animatedIcon: getAnimatedElement(RipAnim),
    order: 9,
  },
];
