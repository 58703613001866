import {
  Container,
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import Image from "components/UI/Image";
import React from "react";
import classes from "./styles.module.css";
import { getTrimmedString } from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";
import { ArrowSquareOut } from "@phosphor-icons/react";
import Draggable from "components/Draggable";

export const TopDapps = ({ dapps }) => {
  return (
    <>
      <CustomColumn
        margin="16px 0"
        background="var(--base)"
        borderRadius="10px"
        border="1px solid var(--border-dark)">
        <CustomRow
          alignItems="center"
          justifyContent="space-between"
          padding="16px 20px">
          <CustomText
            text="Top DApps"
            color="var(--text-1)"
            fontSize="15px"
            fontWeight="600"
          />
        </CustomRow>
        <PaddingComponent padding="0px 20px">
          <Divider />
        </PaddingComponent>
        <PaddingComponent height="20px" />
        <DappsContainer dapps={dapps} />
      </CustomColumn>
    </>
  );
};

export const DappsContainer = ({ dapps }) => {
  return (
    <Draggable>
      <CustomRow
        overflowX="auto"
        gap="10px"
        padding="0 0 16px 0"
        margin="0px 0px 20px 20px">
        {dapps?.map((item, index) => {
          return (
            <DappCard
              key={item.id}
              lastDapp={index === dapps.length - 1}
              dapp={item}
            />
          );
        })}
        <PaddingComponent padding="0 16px 0 0" />
      </CustomRow>
    </Draggable>
  );
};

export const DappCard = ({ dapp, lastDapp = false }) => {
  return (
    <CustomColumn
      flexShrink="0"
      borderRadius="10px"
      width="240px"
      height="265px"
      border="1px solid var(--border-dark)">
      <Image src={dapp?.banner_uri} className={classes.dappImage} />

      <Container height="100%">
        <CustomText
          text={dapp?.name}
          fontSize="15px"
          fontWeight="500"
          color="var(--text-1)"
          textAlign="left"
          textTransform="capitalize"
        />
        <PaddingComponent height="4px" />
        <CustomColumn height="77%" justifyContent="space-between">
          <CustomText
            textAlign="left"
            maxLines={2}
            text={getTrimmedString(dapp?.description, 60)}
          />
          <PaddingComponent height="4px" />
          <CustomRow justifyContent="space-between">
            <DappTag text={dapp?.dappType} />
            <ConditionalLink sameTab={false} to={{ pathname: dapp?.link }}>
              <CustomRow alignItems="center" gap="2px">
                <ArrowSquareOut size={14} color="var(--text-2)" weight="bold" />
                <CustomText
                  text="Launch App"
                  fontWeight="600"
                  fontSize="12px"
                />
              </CustomRow>
            </ConditionalLink>
          </CustomRow>
        </CustomColumn>
      </Container>
    </CustomColumn>
  );
};

export const DappTag = ({ text }) => {
  return (
    <CustomText
      fontSize="12px"
      borderRadius="6px"
      background="var(--primary-color60)"
      fontWeight="600"
      lineHeight="130%"
      color="var(--base)"
      padding="1px 6px"
      text={text}
    />
  );
};
