import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const ZxTriggerNextPage = ({
  shouldFetch,
  height = 700,
  width = 0,
  style,
  fetchNextPage,
}) => {
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView && shouldFetch) {
      fetchNextPage();
    }
  }, [inView, shouldFetch, fetchNextPage]);
  return (
    <div
      ref={ref}
      className={"trigger-next-page"}
      style={{
        height,
        width,
        position: "absolute",
        bottom: 0,
        left: 0,
        ...style,
      }}></div>
  );
};

export default ZxTriggerNextPage;
