import { DEFAULT_NFT_LISTING_FILTER } from "zxComponents/nft/ZxNft.utils";
import { Chart, ONE_DAY } from "./constants";

export const QUERY_KEYS = {
  ENGAGEMENTS_RIGHT_PANEL: ({ type, contentId }) => [
    "engagements_right_panel",
    type,
    contentId,
  ],
  NETWORTH_GRAPH: ({ identity, duration = Chart.DURATION["7D"] }) => [
    "networth_graph",
    identity?.toString(),
    duration,
  ],
  TOKEN_HOLDINGS: ({ identifier, forProtocolTreasury, slowPath }) => [
    "get_token_holdings",
    identifier,
    forProtocolTreasury,
    slowPath,
  ],
  TOKEN_PAGE_USER_DETAILS: ({ chainId, contractAddress }) => [
    "token_page_user_details",
    chainId,
    contractAddress,
  ],
  TOKEN_PAGE_PRICE_PILLS: ({ chainId, contractAddress }) => [
    "token_page_price_pills",
    chainId,
    contractAddress,
  ],
  TOKEN_PAGE_PNL: ({ identifier, chainId, contractAddress }) => [
    "token_page_pnl",
    identifier,
    chainId,
    contractAddress,
  ],
  TOKEN_PAGE_FOLLOWEE_PNL: ({ chainId, contractAddress }) => [
    "token_page_followee_pnl",
    chainId,
    contractAddress,
  ],
  ONBOARING_INTERACTION: ({ wallet }) => ["onboarding_interaction", wallet],
  ONBOARDING_CONNECT_WALLET_STEP: () => ["onboarding_connect_wallet_step"],
  FEED_TRENDING_CONTRACTS: ({ identifier }) => [
    "feed_trending_contracts",
    identifier,
  ],
  FEED_TOP_PNL: ({ identifier }) => ["feed_top_pnl", identifier],
  FEED_SUGGESTIONS: ({ identifier }) => ["feed_suggestions", identifier],
  PNL_GENERATED_COMMENTs: ({ identifier }) => [
    "generated_comments",
    identifier,
  ],
  TOKEN_PAGE_METADATA: ({ chainId, contractAddress }) => [
    "token_page_metadata",
    chainId,
    contractAddress,
  ],
  TOKEN_PAGE_CHART_DATA: ({ chainId, contractAddress }) => [
    "token_page_chart_data",
    chainId,
    contractAddress,
  ],
  TOKEN_PAGE_BASIC_DETAILS: ({ chainId, contractAddress }) => [
    "token_page_basic_details",
    chainId,
    contractAddress,
  ],
  TOKEN_PAGE_CONTRACT_DETAILS: ({ chainId, contractAddress }) => [
    "token_page_contract_details",
    chainId,
    contractAddress,
  ],
  TOKEN_PAGE_TOKEN_DETAILS: ({ chainId, contractAddress }) => [
    "token_page_token_details",
    chainId,
    contractAddress,
  ],
  TOKEN_PAGE_CORED_DETAILS: ({ chainId, contractAddress }) => [
    "token_page_core_details",
    chainId,
    contractAddress,
  ],
  TOKEN_PAGE_STATS: ({ chainId, contractAddress }) => [
    "token_page_stats",
    chainId,
    contractAddress,
  ],
  TOKEN_PAGE_PRICE_CHANGE: ({ chainId, contractAddress }) => [
    "token_page_price_change",
    chainId,
    contractAddress,
  ],
  WELCOME_POSTS: () => ["welcome_posts"],
  FEED_GLOBAL_TRENDING: () => ["feed_global_trending"],
  TOP_NFT_HOLDINGS: ({ identifier }) => ["top_nft_holdings", identifier],
  TOP_USER_PNL: ({ identifier }) => ["top_user_pnl", identifier],
  MUTUAL_HOLDINGS: ({ identifier }) => ["mutual_holdings", identifier],
  PROFILE_TOP_ACTIVITY: ({ identifier, type }) => [
    "profile_top_activity",
    identifier,
    type,
  ],
  INVITE_CODE_VALIDATION: ({ inviteCode }) => [
    "invite_code_validation",
    inviteCode,
  ],
  NFT_COLLECTION_PAGE: (collectionId) => ["nft_collection_page", collectionId],
  NFT_COLLECTION_ACTIVITIES: (collectionId) => [
    "nft_collection_activities",
    collectionId,
  ],
  NFT_TOKEN_TRANSFERS: ({ chainId, contractAddress, tokenId }) => [
    "nft_token_activities",
    chainId,
    contractAddress,
    tokenId,
  ],
  NFT_COLLECTION_FLOOR_PRICE_CHART: (collectionId, duration) => [
    "nft_collection_floor_price_chart",
    collectionId,
    duration,
  ],
  NFT_COLLECTION_PAGE_NFTS: ({
    collectionId,
    listingsOnly = false,
    sort = DEFAULT_NFT_LISTING_FILTER,
    filters = {},
  }) =>
    listingsOnly
      ? ["get_nfts_in_collection", collectionId, listingsOnly, sort]
      : ["get_nfts_in_collection", collectionId, listingsOnly, sort, filters],
  SOCIAL_CIRCLE: () => ["social_circle"],
  SIMILAR_NFTS: ({ contractAddress, chainId, tokenId }) => [
    "similar_nfts",
    contractAddress,
    chainId,
    tokenId,
  ],
  TRANSACTION_CHAINS_SUPPORTED: () => ["transaction_chains_supported"],
  TOKEN_PRICE_CHART: ({ chainId, contractAddress }) => [
    "token_price_chart",
    chainId,
    contractAddress,
  ],
};

export const getQueryKeyOptions = ({
  refetchOnMount = false,
  refetchOnWindowFocus = false,
  staleTime = ONE_DAY,
  retry = 2,
}) => {
  return {
    refetchOnMount,
    refetchOnWindowFocus,
    staleTime,
    retry,
  };
};
