import React, { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";

export const TriggerNextPage = ({ fetchNextPage }) => {
  const [isRendering, setIsRendering] = useState(true);
  const elementRef = useRef(null);
  useEffect(() => {
    const triggerNextPage = () => {
      if (elementRef.current && inView(elementRef.current)) {
        fetchNextPage();
      }
    };
    const debouncedTriggerNextPage = debounce(triggerNextPage, 10);
    if (!isRendering) {
      window.addEventListener("scroll", debouncedTriggerNextPage);
      debouncedTriggerNextPage();
    }
    return () => {
      window.removeEventListener("scroll", debouncedTriggerNextPage);
    };
  }, [isRendering, fetchNextPage]);
  useEffect(() => {
    setIsRendering(false);
  }, []);
  return (
    <div style={{ backgroundColor: "red", opacity: 1, position: "relative" }}>
      <div
        ref={elementRef}
        style={{
          position: "absolute",
          backgroundColor: "blue",
          top: -1000,
          height: 1000,
        }}></div>
    </div>
  );
};

export const TriggerNextPageInContainer = ({
  fetchNextPage,
  width = 0,
  height = 1000,
}) => {
  const [isRendering, setIsRendering] = useState(true);
  const elementRef = useRef(null);
  const scrollElement = elementRef.current?.parentNode?.parentNode;
  useEffect(() => {
    const triggerNextPage = () => {
      if (elementRef.current && inView2(elementRef.current, scrollElement)) {
        fetchNextPage();
      }
    };
    const debouncedTriggerNextPage = debounce(triggerNextPage, 10);
    if (!isRendering) {
      if (scrollElement) {
        scrollElement.addEventListener("scroll", debouncedTriggerNextPage);
        debouncedTriggerNextPage();
      }
    }
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", debouncedTriggerNextPage);
      }
    };
  }, [isRendering, fetchNextPage, scrollElement]);
  useEffect(() => {
    setIsRendering(false);
  }, []);
  return (
    <div style={{ backgroundColor: "red", opacity: 1, position: "relative" }}>
      <div
        ref={elementRef}
        style={{
          position: "absolute",
          backgroundColor: "blue",
          top: -height,
          height,
          width,
        }}></div>
    </div>
  );
};

function inView(element) {
  var box = element.getBoundingClientRect() ?? null;
  return inViewBox(box);
}

function inView2(element, scrollElement) {
  const box = element?.getBoundingClientRect() ?? null;
  const box2 = scrollElement?.getBoundingClientRect() ?? null;
  return box && box2 && box.top < box2.bottom;
}

function inViewBox(box) {
  if (!box) return false;
  return !(box.bottom < 0 || box.top > getWindowSize().h);
}

function getWindowSize() {
  return {
    w:
      document.body.offsetWidth ||
      document.documentElement.offsetWidth ||
      window.innerWidth,
    h:
      document.body.offsetHeight ||
      document.documentElement.offsetHeight ||
      window.innerHeight,
  };
}
