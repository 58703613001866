import Image, { IMAGE_TYPES } from "components/UI/Image";
import { stripTags } from "utils/misc";
import styles from "./peopleItem.module.css";
import checkBoxClasses from "./CustomCheckBox.module.css";
import FollowEditButton from "components/FollowEditButton/FollowEditButton";
import { ArrowUpRight } from "@phosphor-icons/react";
import {
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import UserInteractedTxnDetails from "./UserInteractedTxnDetails";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import twitterIcon from "assets/svg/twitter.svg";
import farcasterIcon from "assets/svg/farcaster.svg";
import lensIcon from "assets/svg/lens.svg";

const iconMap = {
  twitter: twitterIcon,
  lens: lensIcon,
  farcaster: farcasterIcon,
};

const PeopleItem = ({
  profile,
  onSelected,
  onUnselected,
  isSelected,
  isShowingFollowButton = false,
  isShowingCheckBox = true,
  isShowingTxns = false,
  isShowingTransactionsSummary = false,
  profiles,
  isShowingCursor,
  nativeTokensInfo,
  onFollow,
}) => {
  const bio = profile?.bio;

  const toggleSelect = () => {
    if (!isSelected) {
      if (onSelected) {
        onSelected(profile?.id || profile?.address);
      }
    } else {
      if (onUnselected) {
        onUnselected(profile?.id || profile?.address);
      }
    }
  };

  const networth = profile?.net_worth?._Any?.total_usd_value?.display_value;

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    toggleSelect();
  };

  return (
    <div className={styles.peopleItemUserInteracted}>
      <div
        onClick={toggleSelect}
        style={{
          alignItems: bio ? "flex-start" : "center",
        }}
        className={`${styles.people_item} ${
          isSelected ? styles.selected : ""
        } ${isShowingCursor ? styles.people_item_cursor : ""}`}>
        <Image
          style={{
            marginTop: bio ? 4 : 0,
          }}
          src={profile.display_picture}
          imageType={IMAGE_TYPES.AVATAR}
          className={styles.image}
          name={profile?.display_name || profile?.title}
          alt={profile?.display_name || profile?.title}
        />
        <div className={styles.content}>
          <div className={styles.header}>
            <span className={styles.title}>
              {profile?.display_name || profile?.title}
            </span>
            <VerifiedBadge profile={profile} size="17px" showTooltip />
            {networth && <span className={styles.networth}>{networth}</span>}
            <FollowSource profile={profile} />
          </div>

          {bio && (
            <p className={styles.body}>
              {stripTags(bio || "").slice(0, 100)}
              {bio?.length > 100 && "..."}
            </p>
          )}
          {profile?.active_since && (
            <span className={styles.active_since}>
              {"Active onchain since " + profile?.active_since}
            </span>
          )}
          {isShowingTransactionsSummary &&
            profile?.onboarding_summary_details?.reasons?.[0].reason && (
              <PaddingComponent paddingTop="10px">
                <CustomRow alignItems="center">
                  <ArrowUpRight size={16} color="var(--text-2)" />
                  <PaddingComponent width="4px" />
                  <span className={styles.transactionSummary}>
                    <FeedDescription
                      isNavigating={false}
                      templateString={
                        profile?.onboarding_summary_details?.reasons?.[0].reason
                      }
                      profiles={profiles}
                      nativeTokensInfo={nativeTokensInfo}
                      fontColor="inherit"
                      fontSize="inherit"
                    />
                  </span>
                </CustomRow>
              </PaddingComponent>
            )}
        </div>
        {isShowingCheckBox && (
          <div
            style={{
              marginTop: bio ? 15 : 0,
            }}
            className={styles.radio_button}>
            <input
              size={25}
              className={checkBoxClasses.checkbox}
              type="checkbox"
              disabled={false}
              readOnly
              checked={isSelected}
              onClick={handleCheckboxClick}
            />
          </div>
        )}
        {isShowingFollowButton && (
          <PaddingComponent paddingTop="8px">
            <FollowEditButton
              customTextClass={styles.followButtonText}
              name={profile?.display_name}
              bio={profile?.bio}
              twitter={profile?.socials.twitter}
              socials={profile?.socials}
              followed={profile?.followed}
              showEdit={false}
              onUpdateCallback={() => {}}
              address={profile?.address}
              identityId={profile?.id}
              disabled={profile?.disable_follow}
              followDirectly={profile.follow_directly}
            />
          </PaddingComponent>
        )}
      </div>
      {isShowingTxns && (
        <UserInteractedTxnDetails profile={profile} onFollow={onFollow} />
      )}
    </div>
  );
};

const FollowSource = ({ profile }) => {
  if (!profile.follower_source || profile.follower_source.length === 0) {
    return null;
  }
  return (
    <div style={{ display: "flex", gap: 4 }}>
      {profile.follower_source.map((item) => {
        return <img src={iconMap[item]} alt={item} style={{ width: 15 }} />;
      })}
    </div>
  );
};
export default PeopleItem;
