import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./ZxComment.module.scss";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import ZxActor from "../ZxActor/ZxActor";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import ActivityEngagements from "components/ActivityEngagements/ActivityEngagements";
import { getEngagementsQueryKey } from "components/ActivityEngagements/utils";
import CurrentEngagements from "components/ActivityEngagements/CurrentEngagements/CurrentEngagements";
import ZxText from "../ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { CustomColumn, CustomRow } from "components/UI/Components/Components";
import PostSettings from "components/FeedItemV2/PostSettings";
import { NftTransfers } from "components/GroupFeedItem/GroupFeedItem.components";
import hackPostToActivitiesSchema from "components/FeedItemV2/hackPostToActivitiesSchema";
import { Circle } from "@phosphor-icons/react";
import { stopEventPropagation } from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";
let timer;
const getActorObject = (actor, comment, identities = {}) => {
  if (actor?.display_name && actor?.display_picture) {
    return actor;
  }
  const identity =
    comment?.identities?.[actor?.identity_id ?? actor?.id] ??
    identities?.[actor?.identity_id ?? actor?.id];
  if (identity?.display_name && identity?.display_picture) {
    return identity;
  }
  const profile = comment?.profiles?.[actor?.address];
  if (profile?.display_name && profile?.display_picture) {
    return profile;
  } else {
    return actor;
  }
};
const MAX_COMMENT_LEVEL = 1;
const ZxComment = ({
  comment,
  post,
  openCommentModal,
  commentLevel = 0,
  currentIdentityId,
  parentComment = null,
  readOnly = false,
  refetchPostEngagements = () => {},
  link = null,
  identities,
  engagements,
  isLastComment,
  profiles,
  nftMentions = null,
}) => {
  const history = useHistory();
  const engagementsKey = getEngagementsQueryKey(comment.type, comment.id);
  const actor = getActorObject(comment?.actor ?? {}, comment, {
    ...post?.identities,
    ...engagements?.identities,
    ...identities,
  });
  const [hideReplies, setHideReplies] = React.useState(true);
  const { display_name, display_picture } = actor ?? {};

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  if (!actor) {
    return null;
  }

  const hasChildComments =
    engagements?.replies?.count > 0 && commentLevel <= MAX_COMMENT_LEVEL;
  const creatorIdentity = actor?.id;
  const toggleHideReplies = () => {
    if (hasChildComments && engagements?.replies?.threads?.length === 0) {
      refetchPostEngagements();
    }
    if (isLastComment && hasChildComments && hideReplies) {
      timer = setTimeout(() => {
        // scroll to #last-comment
        const element = document.getElementById("last-comment");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 200);
    }
    setHideReplies((prev) => !prev);
  };
  const renderToggleReplies = (e) => {
    stopEventPropagation(e);
    if (engagements?.replies?.count === 0) return null;
    return (
      <CustomRow
        alignItems="center"
        gap="8px"
        onClick={(e) => {
          e.stopPropagation();
          toggleHideReplies();
        }}
        className={styles.toggleContainer}>
        <Circle size={6} weight="fill" color="var(--text-3)" />
        <ZxText style={TSTYLES.bodyLabel} color="text-2" cursor="pointer">
          {hideReplies ? "Show" : "Hide"} {engagements?.replies?.count}{" "}
          {engagements?.replies?.count > 1 ? "replies" : "reply"}
        </ZxText>
      </CustomRow>
    );
  };
  return (
    <div
      className={`${styles.container} ${commentLevel > 0 ? styles.padtop : ``}`}>
      <ConditionalLink to={actor?.link ?? null}>
        <Image
          src={display_picture ?? null}
          imageType={IMAGE_TYPES.AVATAR}
          name={display_name}
          alt={display_name}
          className={styles.avatar}
        />
      </ConditionalLink>
      <div className={styles.commentColumn}>
        <div
          onClick={(e) => {
            stopEventPropagation(e);
            if (link) {
              history.push(link);
            }
          }}>
          <div className={styles.comment}>
            <div className={styles.content}>
              <ZxActor
                actor={actor}
                nameLength={25}
                hideAvatar
                showAlias={false}
                showNetworth={false}
                showFollowButton={false}
                displayNameStyle="body"
                timestamp={comment?.post?.timestamp}
              />
              <CustomColumn gap="8px">
                <FeedDescription
                  templateString={comment?.post?.contents}
                  identities={{
                    ...post?.identities,
                    ...engagements?.identities,
                    ...identities,
                  }}
                  profiles={profiles}
                  chainProfiles={post?.chain_profiles}
                  heroNames={post?.hero_names}
                  mainProtocolData={post?.protocols}
                  methodIds={post?.method_ids}
                  actor={actor}
                  nativeTokensInfo={
                    post?.summary?.native_tokens || post?.native_tokens_dict
                  }
                  hideVerifiedTag={true}
                  addressPairWiseSummary={post?.address_pairwise_summary}
                  nftPrices={post?.summary?.nft_prices}
                  nftCollectionMentions={nftMentions}
                  enableSeeMore
                />
                <NftTransfers
                  groupedActivity={hackPostToActivitiesSchema(
                    comment,
                    post?.identities,
                    post?.profiles
                  )}
                  onClick={(e) => e.stopPropagation()}
                  showGallery={true}
                  maxNftsCount={4}
                  isRepost={false}
                />
              </CustomColumn>
            </div>
            {!readOnly && (
              <CustomRow
                alignItems="center"
                className={styles.commentActions}
                onClick={stopEventPropagation}>
                <ActivityEngagements
                  hideCount={true}
                  hideReactions={true}
                  showDivider={false}
                  hideLikeLabel={true}
                  hideItems={{
                    bookmarks: true,
                    comments: true,
                    share: true,
                    reposts: true,
                  }}
                  noPadding={true}
                  isActivityDetails={false}
                  contentId={comment.id}
                  type={comment.type}
                  engagements={engagements}
                  showCurrEngagaments={false}
                  queryKey={getEngagementsQueryKey(comment.type, comment.id)}
                />
                <PostSettings
                  isComment={true}
                  postId={comment.id}
                  queryKeys={engagementsKey}
                  isLoggedInUserPost={creatorIdentity === currentIdentityId}
                  onDelete={refetchPostEngagements}
                />
              </CustomRow>
            )}
          </div>
        </div>
        <div className={styles.engagements}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              commentLevel === 0
                ? openCommentModal(comment)
                : openCommentModal(parentComment);
            }}>
            <ZxText style={TSTYLES.bodyLabel} color="text-2" cursor="pointer">
              Reply
            </ZxText>
          </div>
          <CurrentEngagements
            size="small"
            hideComments
            hideRightSection
            contentId={comment.id}
            type={comment.type}
          />
          <CustomRow>{renderToggleReplies()}</CustomRow>
        </div>
        {hasChildComments && !hideReplies ? (
          <div className={styles.childComments}>
            {engagements?.replies?.threads?.map((childComment) => (
              <ZxComment
                key={`comment-${childComment.id}`}
                comment={childComment}
                post={post}
                link={link}
                openCommentModal={openCommentModal}
                commentLevel={commentLevel + 1}
                parentComment={comment}
                currentIdentityId={currentIdentityId}
                engagements={childComment.engagements}
                refetchPostEngagements={refetchPostEngagements}
                identities={identities}
                profiles={profiles}
              />
            ))}
            <div id="last-comment" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ZxComment;
