import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ReactComponent as ExpandMoreIcon } from "assets/svg/profile/expand_more_icon.svg";

const ProtocolAccordion = ({ summary, details }) => {
  const accordionStyles = {
    background: "none",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  };
  const accordionSummaryStyles = {
    alignItems: "center",
    minHeight: "24px",
    margin: 0,
    padding: "16px  20px 8px 20px",
    gap: "12px",
    background: "var(--base)",
    "&.Mui-expanded": {
      background: "var(--elevation-1)",
    },
    ".MuiAccordionSummary-content": {
      margin: "0px",
    },
  };
  return (
    <Accordion
      disableGutters
      square
      TransitionProps={{ unmountOnExit: true }}
      sx={accordionStyles}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        sx={accordionSummaryStyles}>
        {summary}
      </AccordionSummary>
      <AccordionDetails
        sx={{ background: "var(--elevation-1)", padding: "4px 16px 16px" }}>
        {details}
      </AccordionDetails>
    </Accordion>
  );
};
export default ProtocolAccordion;
