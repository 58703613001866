import { ThemeContext } from "contextStates/Theme.context";
import { useContext } from "react";
import lightIcon from "assets/svg/header/ic_light_theme.svg";
import darkIcon from "assets/svg/header/dark.svg";
import { ReactComponent as SystemIcon } from "assets/svg/header/ic_system_theme.svg";

const Theme = ({ className }) => {
  const { storedTheme, setStoredTheme } = useContext(ThemeContext);
  const toggleTheme = () => {
    if (storedTheme === "light") {
      setStoredTheme("dark");
    } else if (storedTheme === "dark") {
      setStoredTheme("system");
    } else {
      setStoredTheme("light");
    }
  };
  return (
    <div onClick={toggleTheme} className={className}>
      {storedTheme === "light" && <img src={lightIcon} alt="light" />}
      {storedTheme === "dark" && <img src={darkIcon} alt="dark" />}
      {storedTheme === "system" && <SystemIcon />}
    </div>
  );
};

export default Theme;
