import React from "react";
import styles from "./TokenPageRightBar.module.scss";
import { TokenInfo } from "./TokenPageRightBar.utils";
import { PaddingComponent } from "components/UI/Components/Components";
import ZxTokenStats from "zxComponents/token/ZxTokenStats/ZxTokenStats";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import { NewTwitterRectangleIcon } from "hugeicons-react";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { ZxTokenPageMyHoldings } from "../ZxTokenPage.utils";
import { abbreviateNumber } from "utils/misc";
const TokenPageRightBar = ({
  token,
  tokenPageDetails,
  deployedAt,
  chain,
  address,
  tokenType,
  poolAddress,
  tokenPrice,
  chartDetails,
}) => {
  const fdv =
    tokenPrice && chartDetails?.fdv_multiplier
      ? `$${abbreviateNumber(tokenPrice * chartDetails?.fdv_multiplier)}`
      : "-";
  const liquidity = tokenPageDetails?.total_liquidity?.display_value;
  return (
    <div className={styles.container}>
      <ZxTokenPageMyHoldings token={token} />
      {/* token info */}

      <ZxTokenStats
        chain={chain}
        address={address}
        tokenType={tokenType}
        poolAddress={poolAddress}
        tokenPrice={tokenPrice}
        fdv={fdv}
        liquidity={liquidity}
      />

      <div className={styles.card}>
        <TokenInfo
          deployer={tokenPageDetails?.creation_details?.profile}
          token={token}
          tokenPageDetails={tokenPageDetails}
          deployedAt={deployedAt}
        />{" "}
      </div>
      <div className={styles.card}>
        <a
          href={`https://x.com/search?q=${token?.symbol}`}
          target="_blank"
          rel="noreferrer noopener"
          style={{ width: "100%" }}>
          <ZxFlex dir="column" width="100%">
            <ZxFlex justify="space-between" width="100%">
              <ZxFlex gap="8px">
                <NewTwitterRectangleIcon
                  color="var(--text-2)"
                  size={16}
                  strokeWidth="2px"
                />
                <ZxText fontSize="13px">Search X </ZxText>
              </ZxFlex>
              {/* CA */}

              <ZxFlex gap="12px">
                <a
                  href={`https://x.com/search?q=$${token?.symbol}`}
                  target="_blank"
                  rel="noreferrer noopener">
                  <PaddingComponent
                    padding="4px 8px"
                    borderRadius="4px"
                    backgroundColor="var(--primary-color10)">
                    <ZxText style={TSTYLES.title1} fontSize="13px">
                      ${token?.symbol}
                    </ZxText>
                  </PaddingComponent>
                </a>
                <a
                  href={`https://x.com/search?q=${token?.profile?.address}`}
                  target="_blank"
                  rel="noreferrer noopener">
                  <PaddingComponent
                    padding="4px 8px"
                    borderRadius="4px"
                    backgroundColor="var(--primary-color10)">
                    <ZxText style={TSTYLES.title1} fontSize="13px">
                      CA
                    </ZxText>
                  </PaddingComponent>
                </a>
              </ZxFlex>
            </ZxFlex>
            {/* CREATOR */}
          </ZxFlex>
        </a>
      </div>
    </div>
  );
};

export default TokenPageRightBar;
