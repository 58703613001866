import React from "react";
import classes from "./postError.module.css";
// import { ReactComponent as BubblesIcon } from "assets/svg/feed/error_bubbles.svg";
// import { ReactComponent as ErrorIcon } from "assets/svg/Landing/circle_tick_error.svg";
const PostError = () => {
  return (
    <div className={classes.errorWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.errorIconWrapper}></div>
        <div className={classes.errorMessage}>
          Oops! An error occurred, but no worries! Let's give it another shot
        </div>
      </div>
      <div className={classes.backgroundWrapper}>{/* <BubblesIcon /> */}</div>
    </div>
  );
};

export default PostError;
