import { isAllAuthValidKeyAvailable } from "contextStates/auth_utils";
import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

export const SemiProtectedRoute = ({ component: Component, ...otherProps }) => {
  if (!isAllAuthValidKeyAvailable()) {
    return (
      <Route>
        <Redirect to="/" />
      </Route>
    );
  }

  return (
    <Route
      {...otherProps}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};
