import React from "react";
import classes from "components/V2/ActivityDetails/Transactions/TransactionsRightPanel.module.css";
import { ReactComponent as CrossIcon } from "assets/svg/profile/cross.svg";
import ProtocolInfo from "./ProtocolInfo";
import { PoolIcons } from "./GenericHoldingDetails";
import {
  CustomRow,
  InfoBoxContainer,
  PaddingComponent,
} from "components/UI/Components/Components";
import { epochToReadable, getTrimmedString } from "utils/misc";
import { Link } from "react-router-dom";
import LayersIcon from "@mui/icons-material/Layers";
import { ReactComponent as CoinGeckoIcon } from "assets/svg/feed/coingecko_icon.svg";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { getBalanceSymbols } from "../Portfolio.utils";

const ProtocolHoldingsRightPanelHeader = ({
  currentProtocolItem,
  currentPositionItem,
  firstTransactionTimestamp,
  txnsCount,
  currentPositionLabel,
  onClose,
}) => {
  const { protocol_icon_url, protocol_name, chain_id, link, coingecko_url } =
    currentProtocolItem || {};
  const balanceString = getBalanceSymbols(currentPositionItem.balance);
  return (
    <div className={classes.header}>
      <div onClick={onClose}>
        <CrossIcon className={classes.crossIcon} />
      </div>
      <span className={classes.title}>
        <CustomRow alignItems="center" gap="10px" style={{ overflow: "unset" }}>
          <CustomRow alignItems="center" style={{ overflow: "unset" }}>
            <PoolIcons
              balances={currentPositionItem.balance}
              pools={currentPositionItem.pool}
              height="24px"
              width="24px"
              style={{ marginRight: "-5px", zIndex: 1 }}
            />
            <ProtocolInfo
              protocol={{ protocol_icon_url, protocol_name, chain_id }}
              hideLabel
              height="24px"
              width="24px"
            />
          </CustomRow>
          Holding Details
        </CustomRow>
        <PaddingComponent height="8px" />
        {firstTransactionTimestamp && (
          <div className={classes.subText}>
            First transaction on {epochToReadable(firstTransactionTimestamp)}
          </div>
        )}
      </span>
      <InfoBoxContainer
        header={
          <div className={classes.smallPrimaryLabel}>
            {currentPositionLabel}
          </div>
        }>
        <CustomRow justifyContent="space-between" alignItems="center">
          <CustomRow alignItems="center" gap="8px">
            <PoolIcons
              balances={currentPositionItem.balance}
              pools={currentPositionItem.pool}
            />
            <PPLxTooltip
              title={balanceString.length > 30 ? balanceString : null}>
              {getTrimmedString(balanceString, 30)}
            </PPLxTooltip>
          </CustomRow>
          <div className={classes.usdValue}>
            {currentPositionItem.usd_value?.display_value}
          </div>
        </CustomRow>
      </InfoBoxContainer>
      <PaddingComponent height="16px" />
      <InfoBoxContainer
        hasHeaderBorder={!!link?.length || !!coingecko_url?.length}
        {...(!!link?.length || !!coingecko_url?.length
          ? {}
          : { contentPaddingTop: 0, headerPaddingBottom: 0 })}
        header={
          <CustomRow alignItems="center" gap="8px">
            <ProtocolInfo
              protocol={{ protocol_icon_url, protocol_name, chain_id }}
              height="16px"
              width="16px"
              isRightPane
            />
            <div className={classes.subText}>{`on ${chain_id}`}</div>
          </CustomRow>
        }>
        {(!!link?.length || !!coingecko_url?.length) && (
          <CustomRow alignItems="center">
            <span className={classes.externalLinks}>Links: </span>
            <PaddingComponent paddingRight="16px"></PaddingComponent>
            <CustomRow>
              {link && (
                <Link
                  to={link}
                  target="_blank"
                  className={classes.view_protocol}>
                  <LayersIcon className={classes.layers_icon} />
                  <div className={classes.view_protocol_text}>
                    Protocol Page
                  </div>
                </Link>
              )}
              <PaddingComponent paddingRight="16px"></PaddingComponent>
              {!!coingecko_url && (
                <a
                  href={coingecko_url}
                  className={classes.linkItem}
                  target="_blank"
                  rel="noreferrer">
                  <CoinGeckoIcon />
                  CoinGecko
                </a>
              )}
            </CustomRow>
          </CustomRow>
        )}
      </InfoBoxContainer>
      <PaddingComponent height="24px" />
      <div className={classes.smallPrimaryLabel}>Transactions</div>
      <div className={classes.subText}>Showing {txnsCount} transactions</div>
      <PaddingComponent height="12px" />
    </div>
  );
};

export default ProtocolHoldingsRightPanelHeader;
