import React, { useContext } from "react";
import classes from "./ScheduledPostList.module.css";
import { getPendingPosts, usePendingPostMutation } from "../../../api/feed.api";
import withLoader from "../../core/withLoader";
import { ONE_DAY } from "../../../utils/constants";
import noScheduledPostGraphic from "assets/svg/no_scheduled_posts.svg";
import noScheduledPostGraphicDark from "assets/svg/no_scheduled_posts_dark.svg";
import {
  getDateInFormat,
  getFileTypeFromDataString,
  SUPPORTED_IMAGE_FORMATS,
} from "./NewPost.utils";
import {
  CalendarPlus,
  PenNib,
  PencilSimple,
  Trash,
  Spinner,
} from "@phosphor-icons/react";
import { NewPostContext } from "./NewPostV2";
import FeedDescription from "../../GroupFeedItem/components/FeedDescription";
import { ThemeContext } from "../../../contextStates/Theme.context";

export const PostPreview = ({ post, isDraft }) => {
  const firstThread = post.thread[0];
  const showImages = firstThread?.images?.length > 0;
  const { editPost } = useContext(NewPostContext);
  const { mutateAsync, isPending } = usePendingPostMutation();
  return (
    <div className={classes.postContainer}>
      <div className={classes.date}>
        {isDraft ? (
          <>
            <PenNib size={20} />
            <span>
              Drafted on: {getDateInFormat(new Date(post.updated_at * 1000))}
            </span>
          </>
        ) : (
          <>
            <CalendarPlus size={20} />
            <span>
              Scheduled for:{" "}
              {getDateInFormat(new Date(post.scheduled_time * 1000))}
            </span>
          </>
        )}
      </div>
      <div className={classes.truncate}>
        <FeedDescription
          templateString={firstThread?.contents}
          identities={post.identities_data}
          profiles={post.addresses_data}
        />
      </div>
      {showImages && (
        <div className={classes.imagesContainer}>
          {firstThread?.images?.map((result, index) => {
            const type = getFileTypeFromDataString(result);
            const supportedFormat = SUPPORTED_IMAGE_FORMATS.includes(type);
            return (
              <div
                key={index}
                className={`${classes.imagePreviewWrapper} ${
                  !supportedFormat ? classes.errorImage : ``
                }`}>
                <img
                  src={result}
                  alt="Preview"
                  className={classes.imagePreview}
                />
              </div>
            );
          })}
        </div>
      )}
      <div className={classes.actions}>
        <button
          type="button"
          onClick={(e) => {
            editPost(post);
          }}>
          <PencilSimple size={16} color="var(--primary-color)" />
        </button>
        <button
          onClick={() => {
            mutateAsync({ id: post.pending_post_id });
          }}>
          {isPending ? (
            <Spinner size={16} color="var(--error)" />
          ) : (
            <Trash size={16} color="var(--error)" />
          )}
        </button>
      </div>
    </div>
  );
};

const ScheduledPostListInner = ({ queryData }) => {
  const { theme } = useContext(ThemeContext);
  if (queryData.scheduled_threads.length === 0) {
    return (
      <div className={classes.container}>
        <div className={classes.noDataContainer}>
          <img
            src={
              theme !== "dark"
                ? noScheduledPostGraphic
                : noScheduledPostGraphicDark
            }
            alt="No scheduled posts"
          />
          <span>No scheduled posts</span>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      {queryData.scheduled_threads.map((post) => {
        return <PostPreview key={post.pending_post_id} post={post} />;
      })}
    </div>
  );
};

export const ScheduledPostList = withLoader(
  ScheduledPostListInner,
  getPendingPosts,
  {
    queryKey: () => ["pendingPosts"],
    queryOptions: () => {
      return { staleTime: ONE_DAY };
    },
  }
);
