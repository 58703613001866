import { CustomColumn, CustomRow } from "components/UI/Components/Components";
import React from "react";
import ZxText from "../ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import styles from "./ZxBuySellBar.module.scss";
import { abbreviateNumber } from "utils/misc";
const ZxBuySellBar = ({ buyCount, sellCount }) => {
  return (
    <CustomColumn gap="8px">
      <CustomRow justifyContent="space-between">
        <CustomColumn>
          <ZxText style={TSTYLES.secondaryLabel} textTransform="uppercase">
            Buys
          </ZxText>
          <ZxText style={TSTYLES.bodyLabel}>
            {abbreviateNumber(buyCount)}
          </ZxText>
        </CustomColumn>
        <CustomColumn alignItems="flex-end">
          <ZxText style={TSTYLES.secondaryLabel} textTransform="uppercase">
            Sells
          </ZxText>
          <ZxText style={TSTYLES.bodyLabel}>
            {abbreviateNumber(sellCount)}
          </ZxText>
        </CustomColumn>
      </CustomRow>
      <div className={styles.bar}>
        <div
          className={styles.buy}
          style={{ width: `${(buyCount / (buyCount + sellCount)) * 100}%` }}
        />
        <div
          className={styles.sell}
          style={{ width: `${(sellCount / (buyCount + sellCount)) * 100}%` }}
        />
      </div>
    </CustomColumn>
  );
};

export default ZxBuySellBar;
