import React, { useContext, useEffect } from "react";
import {
  useNftInfiniteQuery,
  useSearchNftsInCollection,
} from "components/V2/CollectionDetails/collectionsUtils";
import { NFTItem } from "components/V2/Profile/ProfileDetails/NFTHoldings/NftItem";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import ZxTriggerNextPage from "zxComponents/common/ZxTriggerNextPage/ZxTriggerNextPage";
import { NftListLoader } from "../ZxNftLoaders/ZxNftLoaders";
import GenericFallBackUI from "components/ErrorBoundary/GenericFallBackUI";
import { CollectionPageContext } from "../CollectionPageContext/CollectionPageContext";
import StaticSearchBar from "components/UI/Components/StaticSearchBar";
import styles from "./ZxNftCollectionList.module.scss";
import ZxSortButton from "zxComponents/common/ZxSortButton/ZxSortButton";
import { NFT_PAGE_ALL_FILTERS, NFT_PAGE_LISTING_FILTERS } from "../ZxNft.utils";
import { getUniqueId } from "utils/event_tracking";
import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import { EMPTY_STATES } from "zxStyles/constants";
import ZxFilterButton from "zxComponents/common/ZxFilterButton/ZxFilterButton";
import { getTrimmedString } from "utils/misc";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { XCircle } from "../../../../node_modules/@phosphor-icons/react/dist/index";
const ZxNftCollectionList = ({
  collectionId,
  collection,
  listingsOnly,
  activeTab,
}) => {
  const {
    getSearchAndSort,
    updateSearch,
    updateSort,
    filters,
    updateFilters,
    removeFromFilters,
  } = useContext(CollectionPageContext);
  const { search, sort } = getSearchAndSort(activeTab);

  const { data, hasNextPage, isFetching, fetchNextPage, isLoading, isError } =
    useNftInfiniteQuery({ collectionId, listingsOnly, sort, filters });
  const firstItem = collection?.contract_details?.[0] ?? {};
  const chainId = firstItem.chain_id;
  const contractAddress = firstItem.contract_address;
  const {
    data: searchResults,
    isLoading: isSearchLoading,
    isError: isSearchError,
  } = useSearchNftsInCollection({
    tokenId: search,
    contractAddress,
    chainId,
    listingsOnly,
    enabled: !!search,
  });
  useEffect(() => {
    updateSearch(activeTab, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, collectionId]);
  const onSearchChange = (val) => {
    updateSearch(activeTab, val);
  };
  const onSortChange = (val) => {
    updateSort(activeTab, val);
  };
  const SearchAndSort = () => {
    return (
      <div
        className={`${styles.filterContainer} ${!listingsOnly ? styles.allContainer : ``}`}>
        {listingsOnly || !Object?.keys(filters)?.length ? (
          <StaticSearchBar
            defaultValue={search}
            setSearchTerm={onSearchChange}
          />
        ) : (
          <CustomRow className={styles.filterPills} overflowX="visible">
            {Object.keys(filters).map((key) => {
              return filters[key].map((val, idx) => {
                return (
                  <PPLxTooltip
                    title={val}
                    key={`${val}_${idx}`}
                    arrow
                    placement="top">
                    <div
                      className={styles.filterPill}
                      onClick={() => {
                        removeFromFilters(key, val);
                      }}>
                      {getTrimmedString(val, 12)}
                      <XCircle size={16} color="var(--text-2)" weight="fill" />
                    </div>
                  </PPLxTooltip>
                );
              });
            })}
          </CustomRow>
        )}
        <ZxSortButton
          options={
            listingsOnly ? NFT_PAGE_LISTING_FILTERS : NFT_PAGE_ALL_FILTERS
          }
          onChange={onSortChange}
          selectedOption={sort}
        />
        {!listingsOnly && (
          <ZxFilterButton
            options={collection?.traits ?? {}}
            isMultiLevel
            selectedOptions={filters}
            isActive={Object.keys(filters).length > 0}
            onApply={(val) => {
              updateFilters(val);
            }}
          />
        )}
      </div>
    );
  };
  if (isError)
    return (
      <>
        <SearchAndSort />
        <GenericFallBackUI />
      </>
    );
  if (isLoading || !data) {
    return (
      <>
        <SearchAndSort />
        <NftListLoader />
      </>
    );
  }
  const isEmpty = !data?.pages?.some((page) => {
    const key = listingsOnly ? "listings" : "nfts";
    return page?.[key]?.length > 0;
  });
  if ((isEmpty && !isSearchLoading) || (search && !contractAddress)) {
    return (
      <>
        <SearchAndSort />
        <ZxEmptyState state={EMPTY_STATES.NFT} text="No NFTs" size={200} />
      </>
    );
  }
  if (isError) return <GenericFallBackUI />;
  const renderSearchResults = () => {
    if (isSearchError) {
      return (
        <ZxEmptyState
          state={EMPTY_STATES.NFT}
          text="NFT not found"
          size={200}
        />
      );
    }
    if (isSearchLoading) {
      return <NftListLoader />;
    }

    if (searchResults) {
      const nft = searchResults;
      const tokenId =
        nft.token_id && nft?.token_id?.length ? `/${nft.token_id}` : "";
      return (
        <NFTItem
          hasGalleryModal={false}
          nftItem={nft}
          overrideLink={`/collection/${collectionId}/${activeTab}/${nft.chain_id}/${nft.contract_address}${tokenId}`}
          key={getUniqueId}
          displayValueOverride={nft?.price?.display_value}
          chainId={nft.chain_id}
          collectionDetails={collection}
          style={{
            borderRadius: "8px",
            cursor: "pointer",
            maxWidth: "170px",
            minWidth: "170px",
          }}
        />
      );
    }
    if (searchResults?.empty) {
      return (
        <ZxEmptyState state={EMPTY_STATES.NFT} text="No results" size={200} />
      );
    }
  };
  return (
    <CustomColumn style={{ position: "relative" }}>
      <SearchAndSort />
      {!search ? (
        <CustomRow gap="16px" flexWrap="wrap" justifyContent="space-between">
          {data?.pages?.map((page) => {
            const key = listingsOnly ? "listings" : "nfts";
            return page?.[key]?.map((nft, idx) => {
              const tokenId = nft.token_id ? `/${nft.token_id}` : "";
              return (
                <NFTItem
                  hasGalleryModal={false}
                  nftItem={nft}
                  overrideLink={`/collection/${collectionId}/${activeTab}/${nft.chain_id}/${nft.contract_address}${tokenId}`}
                  key={idx}
                  displayValueOverride={
                    !nft?.price?.display_value
                      ? ""
                      : listingsOnly
                        ? nft?.price?.display_value
                        : `Last sale - ${nft?.price?.display_value}`
                  }
                  chainId={nft.chain_id}
                  collectionDetails={collection}
                  style={{
                    borderRadius: "8px",
                    cursor: "pointer",
                    maxWidth: "170px",
                    minWidth: "170px",
                  }}
                />
              );
            });
          })}
        </CustomRow>
      ) : (
        renderSearchResults()
      )}
      {!search && (
        <ZxTriggerNextPage
          shouldFetch={data && hasNextPage && !isFetching && fetchNextPage}
          fetchNextPage={fetchNextPage}
          style={{ width: "0", background: "red" }}
        />
      )}
      {isFetching && !isLoading && (
        <PaddingComponent padding="16px 0">
          <NftListLoader />
        </PaddingComponent>
      )}
    </CustomColumn>
  );
};

export default ZxNftCollectionList;
