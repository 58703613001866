import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "components/UI/Table/Table";
import withLoader from "components/core/withLoader";
import React, { useState } from "react";
import { getLatestBlocks } from "./api";
import { getTimeString, getTrimedAddress, timeAgo } from "utils/misc";
import Copyable from "components/ValueType/Copyable/Copyable";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import classes from "./styles.module.css";
import LoaderButton from "components/UI/LoaderButton/LoaderButton";
import { BlocksTableShimmer } from "./Shimmers/AllBlocksPageShimmer";

const Blocks = ({ chain_id, chainType, queryData }) => {
  const [blocks, setBlocks] = useState(queryData);

  const getNextData = async () => {
    try {
      const data = await getLatestBlocks({
        chain_id: chain_id,
        start_block_number: blocks[0]?.number?.value + 1,
        chainType: chainType,
      });
      setBlocks(data);
    } catch (error) {
      setBlocks((prev) => {
        return [...prev];
      });
    }
  };

  const getPrevData = async () => {
    try {
      const data = await getLatestBlocks({
        chain_id: chain_id,
        end_block_number: blocks[blocks.length - 1]?.number?.value - 1,
        chainType: chainType,
      });
      setBlocks(data);
    } catch (error) {
      setBlocks((prev) => {
        return [...prev];
      });
    }
  };
  return (
    <>
      <CustomColumn
        margin="16px 0"
        background="var(--base)"
        borderRadius="10px"
        border="1px solid var(--border-dark)">
        <CustomRow
          padding="16px 20px"
          alignItems="center"
          justifyContent="space-between">
          <CustomText
            text="Recent Blocks"
            color="var(--text-1)"
            fontSize="15px"
            fontWeight="600"
          />
          <CustomRow gap="10px">
            <LoaderButton
              children={"Older"}
              className={classes.navigateButtons}
              onClick={getPrevData}
            />
            <LoaderButton
              children={"Newer"}
              className={classes.navigateButtons}
              onClick={getNextData}
            />
          </CustomRow>
        </CustomRow>
        <Divider />
        <Table>
          <TableHeader paddingTop background>
            <TableCell alignLeft>Block No</TableCell>
            <TableCell alignLeft>Hash</TableCell>

            <TableCell alignLeft>Created At</TableCell>
            <TableCell>Tx count</TableCell>
            <TableCell>Gas Consumed</TableCell>
          </TableHeader>
          <BlocksList blocks={blocks} />
        </Table>
      </CustomColumn>
      <PaddingComponent height="36px" />
    </>
  );
};

export default withLoader(Blocks, getLatestBlocks, {
  queryKey: ({ chain_id, chainType }) => [
    "LatestBlock",
    chain_id,
    chainType,
    { chain_id },
  ],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 2,
  renderNotFound: false,
  CustomLoader: BlocksTableShimmer,
});

const BlocksList = ({ blocksCount, blocks }) => {
  return (
    <>
      {blocks?.slice(0, 20).map((item, index) => {
        return (
          <SinglBlock key={`block-number-${item?.number?.value}`} item={item} />
        );
      })}
    </>
  );
};

const SinglBlock = ({ item }) => {
  return (
    <TableRow>
      <TableCell alignLeft>
        {item?.number?.display_value} <Copyable content={item?.number?.value} />{" "}
      </TableCell>
      <TableCell alignLeft>{getTrimedAddress(item?.hash)}</TableCell>
      <TableCell alignLeft>
        <CustomText
          color="var(--text-2)"
          text={
            <PPLxTooltip title={getTimeString(item?.timestamp)} arrow>
              <span>{`${timeAgo(item?.timestamp * 1000)} ago`}</span>
            </PPLxTooltip>
          }
        />
      </TableCell>
      <TableCell alignLeft>{item?.tx_count}</TableCell>
      <TableCell alignLeft>
        {item?.gas?.native_token_value?.display_value
          ? `${item?.gas?.native_token_value?.display_value}`
          : item?.gas?.value?.display_value
          ? `${item?.gas?.value?.display_value}`
          : "-"}
      </TableCell>
    </TableRow>
  );
};
