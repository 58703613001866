import buttonClasses from "components/UI/Buttons/buttons.module.css";
import formClasses from "components/UI/PopUpForm/PopUpForm.module.css";
import { useState } from "react";
import classes from "components/V2/Profile/ProfileDetails/Filters/Filters.module.css";
import {
  CustomRow,
  FlexBox,
  PaddingComponent,
} from "components/UI/Components/Components";
import ExplorerRowsFilterList from "./ExplorerRowsFilterList";

const ExplorerRowsFilterPopup = ({
  selected,
  setSelected,
  profiles,
  setShowPopup,
}) => {
  const [currentSelected, setCurrentSelected] = useState(selected);
  const applyDisabled = selected === currentSelected;
  const clearDisabled = selected.length === 0;

  const onApply = () => {
    setSelected(currentSelected);
    setShowPopup(false);
  };
  const onClear = () => {
    setCurrentSelected([]);
    setSelected([]);
    setShowPopup(false);
  };

  return (
    <div className={formClasses.popup_form}>
      <div className={classes.formPadding}>
        <ExplorerRowsFilterList
          selected={currentSelected}
          setSelected={setCurrentSelected}
          profiles={profiles}
        />
      </div>
      <PaddingComponent paddingBottom="20px" />
      <div className={formClasses.divider}></div>
      <CustomRow
        className={classes.buttonPadding}
        justifyContent="center"
        gap="20px">
        <FlexBox flex="1">
          <button
            className={`${buttonClasses.popupButton} ${
              applyDisabled ? buttonClasses.disabled : ""
            }`}
            onClick={onApply}
            disabled={applyDisabled}>
            <div>Apply</div>
          </button>
        </FlexBox>
        <FlexBox flex="1">
          <button
            className={`${buttonClasses.errorButton} ${
              clearDisabled ? buttonClasses.disabled : ""
            }`}
            onClick={onClear}
            disabled={clearDisabled}>
            Clear
          </button>
        </FlexBox>
      </CustomRow>
    </div>
  );
};

export default ExplorerRowsFilterPopup;
