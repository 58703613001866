import classes from "../TransactionsRightPanel.module.css";

import { Skeleton } from "@mui/material";

const TransactionInternalCallsGhost = () => {
  return (
    <div className={classes.container}>
      <Skeleton animation="wave" height={50} variant="text" />
      <Skeleton animation="wave" height={50} variant="text" />
      <Skeleton animation="wave" height={50} variant="text" />
      <Skeleton animation="wave" height={50} variant="text" />
      <Skeleton animation="wave" height={50} variant="text" />
      <Skeleton animation="wave" height={50} variant="text" />
      <Skeleton animation="wave" height={50} variant="text" />
    </div>
  );
};

export default TransactionInternalCallsGhost;
