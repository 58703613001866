import { signedRequest } from "./api";
import { useQuery } from "@tanstack/react-query";

export const getScreenerData = async ({
  sessionId,
  filterOnlyWallets = true,
  filterKnownNames = false,
  filterOutExchangeWallets = false,
  filterKnownTwitters = false,
}) => {
  return signedRequest({
    method: "post",
    path: "api/screener/loadsession",
    bodyText: JSON.stringify({
      session_id: sessionId,
      send_only_eoas: filterOnlyWallets,
      filter_known_names: filterKnownNames,
      filter_out_exchange_wallets: filterOutExchangeWallets,
      filter_known_twitters: filterKnownTwitters,
    }),
  });
};

export const runQuery = async ({ inputTokens, additionalColumns }) => {
  return signedRequest({
    method: "post",
    path: "/api/screener/runquery",
    bodyText: JSON.stringify({
      input_tokens: inputTokens,
      additional_columns: additionalColumns,
    }),
  });
};

export const getTrendingQueries = async () => {
  return signedRequest({
    method: "get",
    path: "/api/screener/get_trending_queries",
  });
};

export const getExampleQueries = async () => {
  return signedRequest({
    method: "get",
    path: "/api/screener/get_example_queries",
  });
};

export const useScreenData = ({
  sessionId,
  filterOnlyWallets = true,
  filterKnownNames = false,
  filterOutExchangeWallets = false,
  filterKnownTwitters = false,
}) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [
      "screener",
      sessionId,
      filterOnlyWallets,
      filterKnownNames,
      filterOutExchangeWallets,
      filterKnownTwitters,
    ],
    queryFn: () =>
      getScreenerData({
        sessionId,
        filterOnlyWallets,
        filterKnownNames,
        filterOutExchangeWallets,
        filterKnownTwitters,
      }),
    staleTime: 1000 * 60 * 5,
  });

  return { data: data, isLoading, isError, refetch };
};
