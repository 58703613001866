import { useMemo, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchRecentOrTopHoldings } from "api/profile.api";
import { ONE_DAY } from "utils/constants";

const useMyHoldings = ({
  identifier: identifierAnyType,
  showRecent = true,
}) => {
  const identifier = useMemo(
    () => (identifierAnyType ? `${identifierAnyType}` : null),
    [identifierAnyType]
  );

  const getQueryKey = (showRecent, identifier) => {
    if (showRecent) {
      return ["get_recent_holdings", identifier];
    } else {
      return ["get_top_holdings", identifier];
    }
  };

  const getEndpoint = (showRecent) => {
    return showRecent
      ? "/api/v4/get_recent_holdings"
      : "/api/v4/get_top_holdings";
  };

  const queryKey = useCallback(
    () => getQueryKey(showRecent, identifier),
    [showRecent, identifier]
  );

  const query = useQuery({
    queryKey: queryKey(),
    queryFn: () =>
      fetchRecentOrTopHoldings({
        identifier,
        endpoint: getEndpoint(showRecent),
      }),
    enabled: identifier?.length > 0 ?? false,
    retry: 3,
    retryOnMount: false,
    staleTime: ONE_DAY,
    refetchInterval: 1000 * 60 * 1,
    refetchOnWindowFocus: false,
  });

  // prefetch other tab
  const queryClient = useQueryClient();
  queryClient.prefetchQuery({
    queryKey: getQueryKey(!showRecent, identifier),
    queryFn: () =>
      fetchRecentOrTopHoldings({
        identifier,
        endpoint: getEndpoint(!showRecent),
      }),
    staleTime: 1000 * 60 * 5,
  });

  return { ...query };
};

export default useMyHoldings;
