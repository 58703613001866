import React from "react";
import styles from "./ZxActor.module.scss";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import ZxText from "../ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import { getTrimmedString, stopEventPropagation } from "utils/misc";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import FollowEditButton from "components/FollowEditButton/FollowEditButton";
import ConditionalLink from "shared/ConditionalLink";
import { TimeLabel } from "components/FeedItemV2/ActivityHeader";
import ZxFlex from "../ZxFlex/ZxFlex";
const ZxActor = ({
  actor,
  showNetworth = true,
  size = "40px",
  nameLength = 8,
  timestamp = null,
  onUpdateCallback = null,
  showBio = false,
  showFollowButton = true,
  dpBorderRadius = "50%",
  showAlias = false,
  infoRowGap = "4px",
  hideAvatar = false,
  displayNameStyle = "title",
  disableLink = false,
  darkFollowButton = false,
  ignoreLoginCheck = false,
  secondLineRender = null,
  bioLength = 64,
  columnJustify = "center",
  overrideSrc = null,
  dpClassName = "",
  dpFontSize = null,
  showFollowCounts = false,
  actorRowGap = "8px",
  overrideVerifiedTag = false,
  hideVerifiedTag = false,
}) => {
  const {
    display_name: name,
    display_picture: dp,
    username,
    identity_link,
  } = actor ?? {};
  const fontSize = dpFontSize ? { fontSize: dpFontSize } : {};
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <CustomRow gap={actorRowGap} alignItems="center">
          {!hideAvatar && (
            <ConditionalLink
              to={
                disableLink
                  ? null
                  : username
                    ? `/${username}`
                    : identity_link ?? actor?.link ?? null
              }>
              <ZxFlex align="flex-end">
                <Image
                  imageType={IMAGE_TYPES.AVATAR}
                  name={name}
                  alt={name}
                  src={overrideSrc ?? dp}
                  className={dpClassName}
                  style={{
                    width: size,
                    height: size,
                    borderRadius: dpBorderRadius,
                  }}
                />
              </ZxFlex>
            </ConditionalLink>
          )}
          <CustomColumn gap={infoRowGap} justifyContent={columnJustify}>
            <CustomRow alignItems="center" gap="4px">
              <ProfileHoverCard actor={actor}>
                <ConditionalLink
                  to={
                    disableLink
                      ? null
                      : username
                        ? `/${username}`
                        : identity_link ?? actor?.link ?? null
                  }>
                  <ZxText
                    style={
                      displayNameStyle === "title"
                        ? TSTYLES.title2
                        : TSTYLES.bodyLabel
                    }
                    cursor="pointer"
                    underlineOnHover
                    {...fontSize}>
                    {getTrimmedString(name ?? actor?.name, nameLength)}
                  </ZxText>
                </ConditionalLink>
              </ProfileHoverCard>
              {!hideVerifiedTag && (
                <VerifiedBadge
                  profile={actor}
                  isRenderOnly={overrideVerifiedTag}
                />
              )}
              {timestamp && (
                <PaddingComponent paddingLeft="2px">
                  <TimeLabel timestamp={timestamp} showFullDateTime={false} />
                </PaddingComponent>
              )}
            </CustomRow>
            {showNetworth && (
              <ZxText style={TSTYLES.secondaryTitle}>
                {actor.net_worth?.["_Any"]?.total_usd_value?.display_value}
              </ZxText>
            )}
            {secondLineRender && secondLineRender()}
            {showAlias && (
              <ZxText style={TSTYLES.secondary1} color="text-2">
                @{actor.username}
              </ZxText>
            )}
            {showFollowCounts && (
              <PaddingComponent padding="12px 0 0 0">
                <ZxFlex gap="4px">
                  <ZxFlex gap="4px">
                    <ZxText style={TSTYLES.bodyLabel} color="text-1">
                      {actor?.follower_count?.display_value}
                    </ZxText>
                    <ZxText color="text-2">followers</ZxText>
                  </ZxFlex>
                  <span className={styles.dot}></span>
                  <ZxFlex gap="4px">
                    <ZxText style={TSTYLES.bodyLabel} color="text-1">
                      {actor?.following_count?.display_value}
                    </ZxText>
                    <ZxText color="text-2">following</ZxText>
                  </ZxFlex>
                </ZxFlex>
              </PaddingComponent>
            )}
          </CustomColumn>
        </CustomRow>
        {showFollowButton && (
          <div onClick={stopEventPropagation}>
            <FollowEditButton
              actor={actor}
              showEdit={false}
              name={actor?.display_name}
              twitter={actor?.socials?.twitter}
              socials={actor?.socials}
              bio={actor?.bio}
              followed={actor?.followed}
              address={actor?.address}
              identityId={actor?.identity_id || actor?.id}
              profileType={actor?.profile_type}
              onUpdateCallback={() => {}}
              disabled={actor.disable_follow}
              followDirectly={actor.follow_directly}
              darkButton={darkFollowButton}
              ignoreLoginCheck={ignoreLoginCheck}
            />
          </div>
        )}
      </div>
      {showBio && (
        <div className={styles.bio}>
          {actor.bio ? (
            <ZxText style={TSTYLES.body1}>
              {getTrimmedString(actor.bio, bioLength)}
            </ZxText>
          ) : (
            <PaddingComponent height="34px" />
          )}
        </div>
      )}
    </div>
  );
};

export default ZxActor;
