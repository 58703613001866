import {
  Divider,
  InfoBox,
  TableHeader,
  Table,
  TableRowTile,
} from "components/UI/Components/Components";
import classes from "../../Settings.module.css";
import AddWallet from "components/V2/Profile/ProfileDetails/wallets/components/AddWallet";
import MyWalletRowTile from "./MyWalletRowTile";
import withLoader from "components/core/withLoader";
import { getMyWallets } from "api/profile.api";
import { QueryKeyConstants } from "utils/constants";

const MyWallets = ({ queryData, refetch }) => {
  const bundleData = queryData?.data?.data;
  return (
    <div className={classes.detailsContainer}>
      <div className={classes.walletsHeaderRow}>
        <div>
          <span className={classes.heading}>Wallets</span>
          <span className={classes.walletCountText}>
            {" ("}
            {bundleData?.bundle?.wallets?.length}
            {")"}
          </span>
        </div>
        <AddWallet
          refetch={refetch}
          identityId={bundleData?.id}
          groups={bundleData?.groups}></AddWallet>
      </div>
      <InfoBox>
        <Table>
          <TableHeader
            items={[
              { name: "ADDRESS", flex: 2 },
              { name: "NETWORTH", flex: 2 },
              { name: "STATUS", flex: 2 },
              { name: "ACTIONS", alignment: "right", flex: 2 },
            ]}
          />
          {bundleData?.bundle?.wallets?.map((addressInfo, index) => (
            <TableRowTile key={index}>
              <MyWalletRowTile
                bundleData={bundleData}
                addressInfo={addressInfo}
                index={index}
                refetch={refetch}
              />
            </TableRowTile>
          ))}
        </Table>
        <Divider />
      </InfoBox>
    </div>
  );
};

export default withLoader(MyWallets, getMyWallets, {
  queryKey: (props) => [QueryKeyConstants.MY_WALLETS, props.identifier],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  renderNotFound: false,
});
