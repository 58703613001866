import React, { useMemo, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import ExplorerRowsFilterPopup from "./ExplorerRowsFilterPopup";
import { Funnel } from "@phosphor-icons/react";
import styled from "styled-components";

const FunnelButton = styled.button`
  display: flex;
  padding: 0;
  background: none;
  color: inherit;
`;

const ExplorerRowsFilter = ({ addresses, selected, setSelected, profiles }) => {
  const [showPopUpCard, setShowPopUpCard] = useState(false);

  const relevantProfiles = useMemo(
    () =>
      Object.entries(profiles)
        .filter(([address, _]) => addresses.has(address))
        .map(([_, profile]) => profile),
    [addresses, profiles]
  );

  if (showPopUpCard) {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setShowPopUpCard(false);
        }}>
        <div>
          <PPLxHoverCard
            open={showPopUpCard}
            content={
              <ExplorerRowsFilterPopup
                selected={selected}
                setSelected={setSelected}
                profiles={relevantProfiles}
                setShowPopup={setShowPopUpCard}
              />
            }
            placement="bottom">
            <Filter selected={selected} setShowPopUpCard={setShowPopUpCard} />
          </PPLxHoverCard>
        </div>
      </ClickAwayListener>
    );
  }
  return <Filter selected={selected} setShowPopUpCard={setShowPopUpCard} />;
};

const Filter = React.forwardRef(({ selected, setShowPopUpCard }, ref) => (
  <FunnelButton
    ref={ref}
    onClick={() => {
      setShowPopUpCard((prevShowPopupCard) => !prevShowPopupCard);
    }}>
    <Funnel size={16} weight={selected.length > 0 ? "fill" : "regular"} />
  </FunnelButton>
));

export default ExplorerRowsFilter;
