import {
  CustomText,
  PaddingComponent,
  CustomButton,
  CustomBackButton,
  SpinnerComponent,
} from "../../../../../UI/Components/Components";
import { HelpSection } from "./WalletType";
import classes from "./AddWallet.module.css";
import { useContext, useState } from "react";
import { AddWalletContext } from "./AddWallet";
import { FolderPlus } from "@phosphor-icons/react";
import { NonShrinkingTextField } from "./ConnectWallet";
import { Button, Checkbox } from "@mui/material";
import {
  addRemoveAddressFromBundle,
  createWalletGroup,
  verifyWalletApi,
} from "../../../../../../api/profile.api";
import { GlobalContext } from "../../../../../../contextStates/Global";
import { useHistory, useParams } from "react-router-dom";
import { CustomColumn, CustomRow, FlexGrow } from "./AddWalletComponent";
import { trackEvent } from "../../../../../../utils/event_tracking";
import { getCdnUrl } from "../../../../../../utils/assets";

export const BundleRow = ({ bundle }) => {
  const { selectedBundles, setSelectedBundles } = useContext(AddWalletContext);
  const walletCount = bundle.num_private_wallets + bundle.num_public_wallets;
  const suffix = walletCount === 1 ? "Wallet" : "Wallets";
  const checked = selectedBundles.includes(bundle.id);
  if (bundle?.group_name === "0xPPL Wallet") return null;
  return (
    <>
      <label>
        <CustomRow
          gap={"40px"}
          padding="3px 12px"
          background={checked ? "rgba(6, 72, 215, 0.12)" : "var(--elevation-1)"}
          justifyContent="space-between"
          alignItems="center"
          borderRadius={"8px"}>
          <CustomRow gap="5px">
            <CustomText
              text={bundle.group_name}
              color="var(--text-1)"
              fontSize="15px"
            />
            <CustomText
              text={" • " + walletCount + " " + suffix}
              color="var(--text-2)"
            />
          </CustomRow>
          <Checkbox
            style={{
              color: checked ? "var(--primary-color)" : "var(--elevation-3)",
            }}
            checked={checked}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedBundles([...selectedBundles, bundle.id]);
              } else {
                setSelectedBundles(
                  selectedBundles.filter((item) => item !== bundle.id)
                );
              }
            }}
          />
        </CustomRow>
      </label>

      <PaddingComponent minHeight="16px" />
    </>
  );
};

export const AddBundleForm = ({ setShowNewBundleForm }) => {
  const { refetch } = useContext(AddWalletContext);
  const [bundleName, setBundleName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const onClick = () => {
    setIsLoading(true);
    createWalletGroup({ name: bundleName })
      .then(() => {
        handleSuccessSnackbar("Bundle created successfully");
        setIsLoading(true);
        refetch().then(() => {
          setShowNewBundleForm(false);
        });
      })
      .catch((e) => {
        setIsLoading(false);
        handleErrorSnackbar(e);
      });
  };
  if (isLoading) {
    return (
      <CustomColumn height="100%">
        <div className={classes.form}>
          <SpinnerComponent color={"var(--primary-color)"} size={40} />
        </div>
      </CustomColumn>
    );
  }
  return (
    <CustomColumn height="100%">
      <div className={classes.form}>
        <CustomRow gap={"12px"}>
          <CustomBackButton
            onClick={() => {
              setShowNewBundleForm(false);
            }}
            isShow={true}
          />
          <CustomText
            fontSize="17px"
            fontWeight="500"
            color="inherit"
            text="New bundle"
            textAlign="left"
          />
        </CustomRow>
        <PaddingComponent minHeight="16px" />
        <NonShrinkingTextField
          name={"address"}
          placeholder={"Bundle name"}
          value={bundleName}
          onChange={(e) => setBundleName(e.target.value)}
          required
          fullWidth
        />
        <CustomRow padding="8px" justifyContent="end">
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              fontWeight: 500,
              "&.Mui-disabled": {
                color: "var(--primary-color60)",
              },
            }}
            onClick={onClick}
            disabled={bundleName === "" || isLoading}>
            Save Bundle
          </Button>
        </CustomRow>
      </div>
    </CustomColumn>
  );
};

const AddToBundle = ({ setActiveTab, setShowSuccessAnimation }) => {
  const {
    bundleData,
    isStep3Valid,
    selectedBundles,
    walletType,
    address,
    loggedInUserDetails,
    name,
    session,
    refetch,
    connectionType,
  } = useContext(AddWalletContext);
  const [isLoading, setIsLoading] = useState(false);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const history = useHistory();
  const params = useParams();
  const [showNewBundleForm, setShowNewBundleForm] = useState(false);
  if (showNewBundleForm) {
    return <AddBundleForm setShowNewBundleForm={setShowNewBundleForm} />;
  }
  const finalizeAddingWallet = async () => {
    setIsLoading(true);
    try {
      await addRemoveAddressFromBundle({
        address: address,
        is_private: walletType !== "public",
        group_ids: selectedBundles,
        identity_id: loggedInUserDetails.id,
        reason: name || "-",
      });
      refetch();
      if (session) {
        await verifyWalletApi({
          tokenSerialized: session.tokenSerialized,
          signature: session.tokenSignature,
          walletType: session.walletType,
        });
      }
      setShowSuccessAnimation(true);
      setIsLoading(false);
      trackEvent("complete_add_wallet_flow", {
        is_edit: false,
        is_public: walletType === "public",
        is_connect_wallet: connectionType === "connected",
        group_count: selectedBundles.length,
      });
      setTimeout(() => {
        history.replace(`/${params.identifier}/wallets`);
      }, 2000);
    } catch (e) {
      const message =
        e.response?.data?.errors?.errors ?? "Failed to add wallet";
      handleErrorSnackbar(null, message);
      setIsLoading(false);
    }
  };
  return (
    <div className={classes.formWrapper}>
      <div className={classes.form}>
        <CustomText
          fontSize="17px"
          fontWeight="500"
          text="Add wallet to bundle(s)"
          textAlign="left"
          color="var(--text-1)"
        />
        <PaddingComponent minHeight={"24px"} />
        <CustomRow
          gap={"40px"}
          padding="12px 21px 12px 12px"
          background="var(--elevation-1)"
          justifyContent="space-between"
          cursor="pointer"
          borderRadius={"8px"}
          onClick={() => {
            setShowNewBundleForm(true);
          }}>
          <CustomText
            text="New bundle"
            fontSize="15px"
            fontWeight="500"
            color="var(--primary-color)"
          />
          <FolderPlus size={24} color="var(--primary-color)" />
        </CustomRow>
        <PaddingComponent minHeight="16px" />
        {Object.values(bundleData.groups || {}).map((item) => {
          return <BundleRow bundle={item} key={item.id} />;
        })}
        <FlexGrow />
        <HelpSection
          icon={getCdnUrl(
            "assets/onboarding/add-wallet/fav-folder-front-color.png"
          )}
          subTitle="Organize your wallets! Create bundles to group and track wallets with ease."
          title="What are bundles?"
        />
        <PaddingComponent minHeight={"24px"} />
      </div>
      <div className={classes.footer}>
        <CustomRow gap="24px">
          <CustomButton
            className={classes.nextButton}
            text="Back"
            radius="8px"
            background="var(--elevation-1)"
            color="var(--primary-color)"
            onClick={() => {
              setActiveTab(1);
            }}
          />
          <CustomButton
            className={classes.nextButton}
            text="Done"
            radius="8px"
            isLoading={isLoading}
            disabled={!isStep3Valid || isLoading}
            onClick={() => {
              finalizeAddingWallet();
            }}
          />
        </CustomRow>
      </div>
    </div>
  );
};

export default AddToBundle;
