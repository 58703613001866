import {
  CustomBackButton,
  CustomButton,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import { useChatContext } from "stream-chat-react";
import { useHistory } from "react-router-dom";
import {
  addMembersToGroup,
  addWalletsToGroup,
  blockUser,
  getGroupInfo,
} from "api/messaging.api";
import classes from "./ChannelInvitedActions.module.css";
import AvatarWithPresence from "../Avatars/AvatarWithPresence";
import React, { useContext, useEffect, useState } from "react";
import { getCdnUrl } from "../../../../../utils/assets";
import { HelpSection } from "../../../Profile/ProfileDetails/wallets/AddWallet/WalletType";
import { AuthContext } from "../../../../../contextStates/AuthContext";
import { PickWalletForGroups } from "../CreateGroupPanel/GroupType";
import { CreateGroupContext } from "../CreateGroupPanel/useGroupCreate";
import {
  CustomColumn,
  CustomRow,
} from "../../../Profile/ProfileDetails/wallets/AddWallet/AddWalletComponent";
import { GlobalContext } from "../../../../../contextStates/Global";
import { ChannelTypes } from "../../helpers";

const WalletListContainer = ({ identifier, setShowWalletList }) => {
  const history = useHistory();
  const { identityDetails } = useContext(AuthContext);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const { selectedWallets, channel } = useContext(CreateGroupContext);
  const [isLoading, setIsLoading] = useState(false);
  const acceptInvite = async () => {
    setIsLoading(true);
    try {
      if (selectedWallets.length > 0) {
        await addWalletsToGroup({
          groupId: channel.id,
          wallets: selectedWallets,
          identity: identityDetails.current.identity.id,
        });
      }
      await addMembersToGroup({
        groupId: channel.id,
        members: [identityDetails.current.identity.stream_chat_details.id],
        groupType: channel.data.channel_type,
      });
      await channel.acceptInvite();
      setIsLoading(false);
      setShowWalletList(false);
      history.push("/messages/groups/" + channel.id);
    } catch (e) {
      handleErrorSnackbar(e);
      setIsLoading(false);
    }
  };
  return (
    <div className={classes.walletListWrapper}>
      <div className={classes.walletList}>
        <CustomColumn gap="8px">
          <CustomRow gap="8px">
            <CustomBackButton
              onClick={() => {
                setShowWalletList(false);
              }}
              isShow={true}
            />
            <CustomText
              text="Show your wallets to the group"
              fontSize="17px"
              fontWeight="600"
              color="var(--text-1)"
              textAlign="left"
            />
          </CustomRow>
          <CustomText
            text="Exchange view access of your wallets with the group"
            fontSize="13px"
            fontWeight="400"
            color="var(--text-2)"
            textAlign="left"
          />
          <PaddingComponent minHeight="8px" />
          <PickWalletForGroups
            identifier={identityDetails?.current?.identity?.id}
          />
          <PaddingComponent minHeight="8px" />
          <CustomButton
            text="Done"
            height="58px"
            radius="8px"
            isLoading={isLoading}
            onClick={acceptInvite}
          />
        </CustomColumn>
      </div>
    </div>
  );
};
const AdminInfo = ({ identifier, owner, channel }) => {
  const [walletCount, setWalletCount] = useState("");
  const [netWorth, setNetWorth] = useState("");
  useEffect(() => {
    getGroupInfo({ groupId: channel.id }).then((resp) => {
      const userId = owner.user_id.substring(12);
      if (resp.members_to_wallets[userId]) {
        setWalletCount(resp.members_to_wallets[userId].wallets.length);
        setNetWorth(
          resp.members_to_wallets[userId].net_worth.display_value || 0
        );
      }
    });
  }, [owner, channel.id]);
  return (
    <CustomRow
      background="var(--elevation-1)"
      padding="16px"
      borderRadius="8px"
      width="100%"
      justifyContent="space-between"
      alignItems="center">
      <CustomRow gap="6px">
        <CustomRow justifyContent="center" alignItems="center">
          <AvatarWithPresence
            image={owner.user.image}
            name={owner.user.name}
            size={32}
            isGroup={false}
            user={owner.user}
          />
        </CustomRow>
        <CustomColumn>
          <CustomText
            text={owner.user.name}
            fontWeight="500"
            fontSize="15px"
            color="--text-1"
            textAlign="left"
          />
          {walletCount && (
            <CustomRow gap="4px">
              <CustomText
                text={`${walletCount} wallets`}
                fontWeight="400"
                fontSize="12px"
                textAlign="left"
              />
              <CustomText
                text="•"
                fontWeight="400"
                fontSize="12px"
                textAlign="left"
              />
              <CustomText
                text={netWorth}
                fontWeight="400"
                fontSize="12px"
                textAlign="left"
              />
            </CustomRow>
          )}
        </CustomColumn>
      </CustomRow>
      <div
        style={{
          backgroundColor: "var(--primary-color20)",
          padding: "5px 10px",
          borderRadius: 4,
        }}>
        <CustomText
          text="Admin"
          fontSize="12px"
          fontWeight="400"
          color="var(--primary-color)"
        />
      </div>
    </CustomRow>
  );
};

const StreamingGroupInvitedActions = ({ channel }) => {
  const history = useHistory();
  const { client } = useChatContext();
  const [showWalletList, setShowWalletList] = useState(false);
  const members = Object.values(channel.state.members).filter(
    ({ user }) => user.id !== client.userID
  );
  let owner = Object.values(channel.state.members).find((member) => {
    return member.role === "owner";
  });
  if (!owner) {
    owner = {
      user: channel.data.created_by,
    };
  }
  if (showWalletList) {
    return <WalletListContainer setShowWalletList={setShowWalletList} />;
  }
  return (
    <CustomRow alignItems="center" justifyContent="center">
      <CustomColumn
        padding="20px"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        gap="8px">
        <CustomRow>
          <AvatarWithPresence
            image={channel.data.image}
            name={channel.data.name}
            size={100}
            isGroup={true}
            user={members[0]?.user}
          />
        </CustomRow>
        <CustomRow gap="4px">
          <CustomText text="✨ You’ve been invited to join a" fontSize="15px" />
          <img
            src={getCdnUrl("assets/messaging/dollar-front-color.png")}
            alt="group"
            width="18px"
            height="18px"
          />
          <CustomText text="Trade talk" fontWeight="600" fontSize="15px" />
          <CustomText text="group ✨" fontSize="15px" />
        </CustomRow>
        <CustomText
          text={channel.data.name}
          fontWeight="600"
          fontSize="20px"
          color="var(--text-1)"
        />
        <CustomText text={channel.data.member_count + " members"} />
        <PaddingComponent minHeight="8px" />
        <AdminInfo identifier={owner.user.id} owner={owner} channel={channel} />
        <PaddingComponent minHeight="4px" />
        <HelpSection
          icon={getCdnUrl("assets/messaging/dollar-front-color.png")}
          title={"What is Trade Talk?"}
          subTitle={
            "Go beyond the usual messaging and share wallets to stream live transactions from everyone in the group"
          }
          bgColor="var(--info-background)"
        />
        <PaddingComponent minHeight="4px" />
        <CustomButton
          text={"Accept and choose wallets"}
          radius="8px"
          fontSize="17px"
          fontWeight="600"
          height="58px"
          onClick={() => {
            setShowWalletList(true);
          }}
          style={{ width: "100%" }}
        />
        <CustomButton
          text={"Reject invite"}
          radius="8px"
          fontSize="17px"
          fontWeight="600"
          height="58px"
          background="var(--error-background)"
          color={"var(--error)"}
          style={{ width: "100%" }}
          onClick={async () => {
            await channel.rejectInvite();
            await channel.removeMembers([client.userID]);
            history.push("/messages/all/");
          }}
        />
      </CustomColumn>
    </CustomRow>
  );
};

const ChannelInvitedActions = ({ channel }) => {
  const { client } = useChatContext();
  const history = useHistory();
  const [selectedWallets, setSelectedWallets] = useState([]);

  const onAccept = async () => {
    await channel.acceptInvite();
    history.push("/messages/all/" + channel.id);
  };

  const onReject = async () => {
    await channel.rejectInvite();
    await channel.removeMembers([client.userID]);
    history.push("/messages/all/");
  };

  const onBlock = async () => {
    const dmUser = Object.values(channel.state?.members || {}).find(
      (member) => member.user?.id !== client.user?.id
    );
    await blockUser({
      groupId: channel.id,
      userId: dmUser?.user_id.replace("sc-identity-", ""),
    });
    await channel.markRead();
    await channel.hide();
  };
  if (channel.data.channel_type === ChannelTypes.STREAMING_GROUP) {
    return (
      <CreateGroupContext.Provider
        value={{ selectedWallets, setSelectedWallets, channel }}>
        <StreamingGroupInvitedActions channel={channel} />
      </CreateGroupContext.Provider>
    );
  }

  return (
    <CustomRow alignItem="center" justifyContent="center" width="100%">
      <CustomColumn minWidth="300px" gap="8px" padding="16px">
        <CustomButton onClick={onAccept} text="Accept"></CustomButton>
        <button className={classes.secondaryButton} onClick={onReject}>
          Reject
        </button>
        <button className={classes.secondaryButton} onClick={onBlock}>
          Block
        </button>
      </CustomColumn>
    </CustomRow>
  );
};

export default ChannelInvitedActions;
