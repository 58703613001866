import React, { useState, useContext } from "react";
import CustomTextField from "components/FollowEditButton/CustomTextField";
import LoaderButton from "components/UI/LoaderButton/LoaderButton";
import { getGroupedActivity } from "api/feed.api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FeedItemV2 from "components/FeedItemV2/FeedItemV2";
import {
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import { AuthContext } from "contextStates/AuthContext";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const DevSummaryDetails = () => {
  const { isSuperuser } = useContext(AuthContext);
  const [groupId, setGroupId] = useState("");
  const [groupType, setGroupType] = useState("activity_group");
  const [viewerId, setViewerId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [groupActivityResponse, setGroupActivityResponse] = useState(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await getGroupedActivity({
        id: groupId,
        type: groupType,
      });
      setGroupActivityResponse(response);
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!isSuperuser) {
    return null;
  }

  return (
    <BaseWrapper mixpanelEvent="DevSummaryDetails">
      <>
        <PaddingComponent height="16px" />
        <CustomRow gap="16px" alignItems="center">
          <CustomTextField
            variant="outlined"
            label="Group Id"
            value={groupId}
            required
            onChange={(e) => setGroupId(e.target.value)}
          />
          <CustomTextField
            variant="outlined"
            label="Group Type"
            value={groupType}
            required
            onChange={(e) => setGroupType(e.target.value)}
          />
          <CustomTextField
            variant="outlined"
            label="Viewer Id"
            value={viewerId}
            onChange={(e) => setViewerId(e.target.value)}
          />
          <LoaderButton onClick={handleSubmit} loading={isLoading}>
            Continue
          </LoaderButton>
          <PaddingComponent height="10px" />
        </CustomRow>
        {groupActivityResponse && (
          <GroupDetails groupActivityResponse={groupActivityResponse} />
        )}
      </>
    </BaseWrapper>
  );
};

const GroupDetails = ({ groupActivityResponse }) => {
  const values = [
    {
      name: "Group Id",
      value: groupActivityResponse?.id,
    },
    {
      name: "Group Type",
      value: groupActivityResponse?.type,
    },
    {
      name: "Group UUID",
      value: groupActivityResponse?.group_uid,
    },
    {
      name: "Num Wallets",
      value: groupActivityResponse?.num_wallets,
    },
    {
      name: "Group Created At",
      value: groupActivityResponse?.timestamp,
    },
    {
      name: "Summary Identifier",
      value: groupActivityResponse?.summary?.identifier,
    },
    {
      name: "Summary Engine",
      value: groupActivityResponse?.summary?.engine,
    },
  ];
  return (
    <>
      <DenseTable rows={values} />
      <FeedItemV2
        groupedActivity={groupActivityResponse}
        hideEngagements
        hideStats
        nested
        clickable={false}
        showFullDateTime
        isGroupedActivityDetails={true}
      />
      {groupActivityResponse?.activities?.map((activity) => {
        return <ActivitiesTable key={activity.id} activity={activity} />;
      })}
    </>
  );
};

const ActivitiesTable = ({ activity }) => {
  const chains =
    Object.values(activity?.chain_profiles || {}).map((chainProfile) => {
      return chainProfile?.name;
    }) || [];
  const chainProfiles = chains.join(", ");
  const methods =
    Object.keys(activity?.method_ids || {}).map((method) => {
      const name = activity?.method_ids[method]?.display_name;
      return `${method} : ${name}`;
    }) || [];
  const methodIds = methods.join(", ");
  const transactions =
    activity?.transactions.map((txn) => {
      return txn?.tx_id;
    }) || [];
  const transactionIds = transactions.join(", ");
  const values = [
    {
      name: "Category",
      value: activity?.category,
    },
    {
      name: "Identifier",
      value: activity?.actor?.address || activity?.actor?.identifier,
    },
    {
      name: "Chain id",
      value: activity?.chain_id,
    },
    {
      name: "Chain Symbol",
      value: activity?.chain_symbol,
    },
    {
      name: "Description Template",
      value: activity?.description_template,
    },
    {
      name: "Id",
      value: activity?.id,
    },
    {
      name: "Timestamp",
      value: activity?.timestamp,
    },
    {
      name: "Chain profiles",
      value: chainProfiles,
    },
    {
      name: "Method Ids",
      value: methodIds,
    },
    {
      name: "Transactions",
      value: transactionIds,
    },
  ];
  return (
    <PaddingComponent paddingBottom="40px">
      <DenseTable rows={values} />
    </PaddingComponent>
  );
};

const DenseTable = ({ rows }) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, maxWidth: 800 }}
        size="small"
        aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row" style={{ minWidth: "40%" }}>
                {row.name}
              </TableCell>
              <TableCell align="left">
                <div
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    maxHeight: "2.4em",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}>
                  {row.value}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DevSummaryDetails;
