import WalletRowComponent, {
  TableRowItem,
} from "./components/WalletRowComponent";
import { getNetWorthV2 } from "utils/profile_utils";
import {
  SourcedBy,
  TableRowTextComponent,
} from "./components/WalletComponents";
import AddressComponent from "components/UI/Components/AddressComponent";
import { getLinkForWallet } from "utils/profile_utils";
import WalletVerifiedBadge from "components/V2/Profile/ProfileDetails/wallets/components/WalletVerifiedBadge";
import WalletPrivacy from "components/V2/Profile/ProfileDetails/wallets/components/WalletPrivacy";
import { CustomRow, CustomText } from "components/UI/Components/Components";
import { useContext } from "react";
import { ProfileContext } from "contextStates/ProfileContext";
import { GlobalContext } from "contextStates/Global";
import WalletMenu from "components/V2/Profile/ProfileDetails/wallets/components/WalletMenu";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";

const ProfileWalletRowTile = ({
  bundleData,
  wallet,
  identities,
  index,
  refetch,
}) => {
  const networth = getNetWorthV2(wallet) ?? "";
  const { featureFlags } = useContext(GlobalContext);
  const { isLoggedInUserProfile } = useContext(ProfileContext);
  const groupNames = wallet?.group_ids?.map(
    (id) => bundleData?.groups?.[id]?.group_name
  );
  let shortGroupName =
    groupNames?.join(",")?.length > 20
      ? groupNames?.join(", ")?.slice(0, 17).concat("...")
      : groupNames?.join(", ");

  return (
    <WalletRowComponent link={getLinkForWallet(wallet.address)}>
      <TableRowItem flex={3}>
        <CustomRow alignItems="center" gap="4px">
          {featureFlags?.is_showing_private_wallets && (
            <WalletPrivacy wallet={wallet} size={16} />
          )}

          <AddressComponent index={index + 1} address={wallet?.address} />
          <WalletVerifiedBadge wallet={wallet} size={16} />
        </CustomRow>
      </TableRowItem>
      <TableRowItem flex={2}>
        <TableRowTextComponent
          isTooltip={true}
          text={wallet?.reason ? wallet?.reason : "-"}
        />
      </TableRowItem>
      <TableRowItem flex={2}>
        <TableRowTextComponent text={networth}></TableRowTextComponent>
      </TableRowItem>
      {isLoggedInUserProfile ? (
        <TableRowItem flex={2}>
          <PPLxTooltip
            arrow
            placement="bottom-start"
            delay={500}
            title={groupNames?.join(", ")}>
            <div>
              <CustomText
                text={shortGroupName}
                fontSize="15px"
                color="var(--text-1)"
              />
            </div>
          </PPLxTooltip>
        </TableRowItem>
      ) : (
        <TableRowItem flex={2}>
          <SourcedBy wallet={wallet} identities={identities} />
        </TableRowItem>
      )}
      <TableRowItem
        alignment={"right"}
        flex={"1 0 0%"}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <CustomRow
          alignItems="center"
          justifyContent="flex-end"
          gap="8px"
          height="24px">
          <WalletMenu
            wallet={wallet}
            bundleData={bundleData}
            refetch={refetch}
          />
        </CustomRow>
      </TableRowItem>
    </WalletRowComponent>
  );
};

export default ProfileWalletRowTile;
