import classes from "./extraNfts.module.css";
import { NFTInfo } from "./NftItem";
import NFTPreview from "./NFTPreview";
import { conditionalClass } from "utils/misc";

export const ExtraNFTs = ({
  remainingItems,
  remainingItemsCount,
  setIsExpanded = () => {},
  rightPane = false,
  customTitle = "",
  customSubTitle = "",
  isExpanded = false,
  hideImageForSmallScreens = true,
  ...props
}) => {
  return (
    <div
      className={`${classes.nftItem} ${classes.extraNfts} ${conditionalClass(
        rightPane,
        classes.rightPane
      )} ${conditionalClass(isExpanded, classes.expanded)}`}
      key={`extra-item`}
      {...props}>
      <div
        className={`${classes.extraImages} ${conditionalClass(
          hideImageForSmallScreens,
          classes.hideImage
        )}`}>
        {remainingItems
          .slice(0, 4)
          .map((remainingItem, index) =>
            remainingItem ? (
              <NFTPreview
                key={`${remainingItem.name}-${index}-item`}
                media={
                  remainingItem?.previews?.image_small_url ??
                  remainingItem.image_url
                }
                audioPath={remainingItem.audio_url}
                name={remainingItem.name}
                type="extraImage"
              />
            ) : (
              <div
                className={classes.placeholder}
                key={`placeholder-${index}-item`}
              />
            )
          )}
      </div>
      <NFTInfo
        name={
          customTitle ||
          (rightPane
            ? `${remainingItemsCount} more NFT's`
            : `+${remainingItemsCount} more`)
        }
        {...(!!customTitle ? { nftsCount: remainingItemsCount } : {})}
        customSubTitle={
          <ViewAllButton
            setIsExpanded={setIsExpanded}
            customSubTitle={customSubTitle}
            isExpanded={isExpanded}
          />
        }
        setIsExpanded={setIsExpanded}
        isExpanded={isExpanded}
        extraNfts
        rightPane={rightPane}
      />
    </div>
  );
};

export const ViewAllButton = ({
  isExpanded,
  setIsExpanded,
  customSubTitle = "",
}) => {
  return (
    <button
      className={classes.viewAll}
      onClick={() => setIsExpanded((prev) => !prev)}>
      {customSubTitle ? (
        customSubTitle
      ) : (
        <div className={classes.viewAllLabel}>{`View all`}</div>
      )}
    </button>
  );
};
