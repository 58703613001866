import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import FeedItemV2 from "components/FeedItemV2/FeedItemV2";
import GroupedActivityDetailsEngagements from "./GroupedActivityDetailsEngagements";
import GovernanceVotes from "./GovernanceVote";
import StickyTabs from "components/StickyTabs/StickyTabs";
import {
  getGroupedActivityFromBlob,
  useGetEngagements,
  useGetGroupActivityFromBlob,
  useGetGroupActivityPaginated,
} from "api/feed.api";
import Transactions from "./Transactions";
import Comments from "./Comments";
import TrendingActivityTabDetails from "./TrendingActivityTabDetails";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import { AuthContext } from "contextStates/AuthContext";
import { addIdentity } from "../mutators";
import { GroupActivityCommentsAndTransactionsShimmer } from "./GroupActivityDetailsShimmer";
import { ONE_DAY, QueryKeyConstants } from "utils/constants";
import { getCommentCount, transformGroupedActivityQuery } from "utils/misc";
import GovernanceResults from "./GovernanceResults";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import { activityGroupHash } from "components/FeedItemV2/group_feed_utils";
import { CustomColumn } from "components/UI/Components/Components";
import { createPortal } from "react-dom";
import { Drawer } from "@mui/material";
import TokenHoldingsRightPanel from "components/V2/Profile/ProfileDetails/Portfolio/TokenHoldings/TokenHoldingsRightPanel";
import { GlobalContext } from "contextStates/Global";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import GhostFeedItem from "../GhostFeedItem/GhostFeedItem";
import { getPolyMarketConditionIds } from "../util";
import { PolyMarketFeedContext } from "../FeedItemsList";
import { useQuery } from "@tanstack/react-query";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import GenericFallBackUI from "components/ErrorBoundary/GenericFallBackUI";
import { removeTradingView } from "utils/trading_view_utils";

const ActivityDetailsV2 = ({ refetch, ...props }) => {
  const { active_tab } = useParams();
  const history = useHistory();
  const tabUpdated = useRef(false);
  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetching } =
    useGetGroupActivityPaginated({
      id: props?.id,
      type: props?.type,
    });
  const [grouped, setGrouped] = useState(null);
  useEffect(() => {
    if (data?.pages?.length > 0 && !grouped) {
      setGrouped(data.pages[0]?.data);
    }
  }, [data, grouped]);
  useEffect(() => {
    return () => removeTradingView();
  }, []);
  const { setTitle } = useContext(LeftSideHeaderContext);
  const { userTokenTransferDetails, setUserTokenTransferDetails } =
    useContext(GlobalContext);
  useEffect(() => {
    setTitle("Activity Details");
  }, [setTitle]);
  const { data: engagements } = useGetEngagements({
    id: props?.id,
    type: props?.type,
  });

  let match = useRouteMatch();
  const { data: commentGroup } = useGetGroupActivityFromBlob({
    id: props?.id,
    type: props?.type,
  });
  const activityEngagements = engagements;
  const commentCount = getCommentCount(grouped, engagements);
  const hideTransactions = ![
    "activity_group",
    "whale_alert",
    "sol_activity_group",
    "user_token_group",
    "sol_user_token_group",
  ].includes(props?.type);
  const activeTab =
    active_tab ?? (hideTransactions ? "comments" : "transactions");
  const isUserTokenGroup = [
    "user_token_group",
    "sol_user_token_group",
  ].includes(props?.type);
  useEffect(() => {
    if (
      !tabUpdated.current &&
      commentCount > 0 &&
      activeTab !== "comments" &&
      grouped
    ) {
      tabUpdated.current = true;
      history.replace(
        `/${isUserTokenGroup ? props?.type : grouped?.type}/${grouped?.id}/comments`
      );
    }
    if (activeTab === "comments") {
      tabUpdated.current = true;
    }
  }, [
    commentCount,
    grouped,
    activeTab,
    history,
    isUserTokenGroup,
    props?.type,
  ]);
  const { identityDetails } = useContext(AuthContext);
  const showVotes = ["snapshot", "proposal"].includes(grouped?.type);

  const identities = useMemo(
    () => ({
      ...addIdentity(
        commentGroup?.identities,
        identityDetails?.current?.identity
      ),
      ...activityEngagements?.identities,
    }),
    [activityEngagements?.identities, commentGroup?.identities, identityDetails]
  );
  return (
    <>
      <StickyTabs
        activeTab={activeTab}
        transactionsCount={null}
        commentsCount={commentCount}
        voteCount={grouped?.votes?.length ?? 0}
        resultsCount={grouped?.top_choices?.length}
        {...(grouped?.type === "trending"
          ? {
              referenceActivitiesCount: grouped?.reference_activities?.length,
            }
          : { hideReferenceActivities: true })}
        hideTransactions={hideTransactions}
        showVotes={showVotes}
        showResults={showVotes}
      />
      {active_tab === "comments" && (
        <Comments
          comments={activityEngagements?.replies?.threads}
          contentId={props?.id}
          type={props?.type}
          postDetails={commentGroup}
          identities={identities}
          profiles={commentGroup?.profiles}
          parentId={commentGroup?.id}
          showNewComment
          // for likes and bookmarks
          queryKey={[...transformGroupedActivityQuery(props?.id, props?.type)]}
          // since grouped activity doesn't have ancestors, just pass the current query key
          queryKeys={[
            transformGroupedActivityQuery(props?.id, props?.type, "", false),
          ]}
        />
      )}
      {isLoading && active_tab !== "comments" ? (
        <GroupActivityCommentsAndTransactionsShimmer />
      ) : (
        <>
          {" "}
          {["reference_activities", "reference_threads"].includes(
            active_tab
          ) && (
            // TODO(shaheer): add support for twitter threads when the twitter API issue gets fixed
            <TrendingActivityTabDetails
              activeTab={active_tab}
              referenceActivities={grouped?.reference_activities}
            />
          )}
          {active_tab === "votes" && (
            <GovernanceVotes
              votes={grouped?.votes}
              profiles={grouped?.profiles}
            />
          )}
          {active_tab === "results" && (
            <GovernanceResults results={grouped?.top_choices} />
          )}
          {!hideTransactions && (
            <Switch>
              <Route path={`${match.path}/transactions/:activityId?`}>
                <Transactions
                  groupedActivity={data}
                  paginated
                  isError={isError}
                  hasNextPage={hasNextPage}
                  fetchNextPage={fetchNextPage}
                  isFetching={isFetching}
                />
              </Route>
            </Switch>
          )}
        </>
      )}
      {createPortal(
        <Drawer
          anchor="right"
          open={userTokenTransferDetails?.tokenData}
          onClose={() => setUserTokenTransferDetails({})}>
          <TokenHoldingsRightPanel
            currentTokenData={userTokenTransferDetails?.tokenData}
            actorData={userTokenTransferDetails?.actor}
            identifier={
              userTokenTransferDetails?.actor?.id ??
              userTokenTransferDetails?.actor?.details?.identity_id ??
              userTokenTransferDetails?.actor?.details?.address
            }
            onClose={() => setUserTokenTransferDetails({})}
          />
        </Drawer>,
        document.getElementById("rightPanel")
      )}
    </>
  );
};

const FeedItemV2Wrapper = ({ id, type }) => {
  const {
    data: groupedActivity,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: [QueryKeyConstants.GROUPED_ACTIVITY_FROM_BLOB, id, type],
    queryFn: () => {
      console.log("GroupedActivityDetails.js: getGroupedActivityFromBlob");
      return getGroupedActivityFromBlob({ id, type });
    },
    enabled: !!id && !!type,
    staleTime: ONE_DAY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const grouped = groupedActivity;
  const activityEngagements = grouped?.engagements;
  const groupHash = useMemo(() => activityGroupHash(grouped), [grouped]);
  const initialFetchDone = useRef(false);
  useEffect(() => {
    if (!initialFetchDone.current) {
      initialFetchDone.current = true;
      refetch();
    }
  }, [refetch]);
  if (isLoading) {
    return <GhostFeedItem isGroupActivityShimmer />;
  }

  if (isError) {
    return <GenericFallBackUI />;
  }
  return (
    <div>
      <PolyMarketFeedContext.Provider
        value={{ conditionIds: getPolyMarketConditionIds([grouped]) }}>
        <FeedItemV2
          refetchActivityDetailsPage={refetch}
          groupedActivity={grouped}
          hideEngagements
          hideStats
          nested
          clickable={false}
          showFullDateTime
          isGroupedActivityDetails
        />
      </PolyMarketFeedContext.Provider>
      <GroupedActivityDetailsEngagements
        engagements={activityEngagements}
        contentId={grouped?.id}
        contentTimestamp={groupHash}
        type={grouped?.type}
        identities={grouped?.identities}
        queryKey={[
          ...transformGroupedActivityQuery(grouped?.id, grouped?.type),
        ]}
      />
    </div>
  );
};

const FeedItemV2WrapperWithLoader = withErrorBoundary(FeedItemV2Wrapper);

const Wrapper = (props) => {
  return (
    <BaseWrapper mixpanelEvent="GroupedActivityDetails">
      <CustomColumn>
        <FeedItemV2WrapperWithLoader {...props} />
        <ActivityDetailsV2 {...props} />
      </CustomColumn>
    </BaseWrapper>
  );
};

export default Wrapper;
