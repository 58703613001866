import { useMemo } from "react";
import { abbreviateNumber } from "utils/misc";
import { isTokenIncludedForSelectedProtocols } from "utils/profile_utils";
import { find, sortBy, each } from "lodash";

const getHoldingsTotalUsdValue = (type, holdings) => {
  if (type === "Lending") {
    const addedValue =
      holdings?.supplied?.reduce((acc, holding) => {
        return acc + (holding?.usd_value?.value || 0);
      }, 0) ?? 0;
    const removedValue =
      holdings?.borrowed?.reduce((acc, holding) => {
        return acc + (holding?.usd_value?.value || 0);
      }, 0) ?? 0;

    return {
      value: addedValue - removedValue,
      display_value: `$${abbreviateNumber(addedValue - removedValue)}`,
    };
  } else if (type === "Staking" || type === "DEX") {
    const sum =
      holdings?.reduce((acc, holding) => {
        return acc + (holding?.usd_value?.value || 0);
      }, 0) ?? 0;
    return {
      value: sum,
      display_value: `$${abbreviateNumber(sum)}`,
    };
  }
};

const combineHoldingsByLabel = (found, holding, address) => {
  const oldHoldings = found?.holdings_by_label;
  const newHoldings = holding?.holdings_by_label;
  const allHoldings = { ...oldHoldings, ...newHoldings };
  for (const key in allHoldings) {
    if (newHoldings[key]) {
      const holdingByLabel = oldHoldings[key];
      if (!holdingByLabel) {
        found.holdings_by_label[key] = { ...newHoldings[key] };
        continue;
      }
      const total_usd_value = { ...(newHoldings[key]?.total_usd_value || {}) };
      holdingByLabel.total_usd_value = holdingByLabel.total_usd_value || {
        value: 0,
        display_value: "$0",
      };
      holdingByLabel.total_usd_value.value += total_usd_value?.value || 0;
      holdingByLabel.total_usd_value.display_value =
        "$" + abbreviateNumber(holdingByLabel?.total_usd_value?.value);

      function mergeObjects(obj1, obj2) {
        const isOb1Array = Array.isArray(obj1);
        const isOb2Array = Array.isArray(obj2);
        if (isOb1Array && isOb2Array) return [...obj1, ...obj2];
        if (isOb1Array && !isOb2Array) return obj1;
        const result = {};

        // Get all unique keys from both objects
        const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

        keys.forEach((key) => {
          const value1 = obj1[key];
          const value2 = obj2[key];

          // Both values are arrays, concatenate them
          if (Array.isArray(value1) && Array.isArray(value2)) {
            result[key] = value1.concat(value2);
          } else if (key === "total_usd_value") {
            result[key] = {
              value: (value1?.value || 0) + (value2?.value || 0),
              display_value: `$${abbreviateNumber(
                (value1?.value || 0) + (value2?.value || 0)
              )}`,
            };
          }
          // If one value is an array, preserve it
          else if (Array.isArray(value1)) {
            result[key] = value1;
          } else if (Array.isArray(value2)) {
            result[key] = value2;
          }
          // Values are not arrays, skip with a warning
          else {
            console.warn(
              `Skipping key "${key}" as it does not have array values in both objects.`
            );
          }
        });
        return result;
      }
      const combinedLabelHoldings = mergeObjects(
        found.holdings_by_label[key].holdings,
        holding.holdings_by_label[key].holdings
      );
      if (found.holdings_by_label[key].holdings) {
        found.holdings_by_label[key].holdings = combinedLabelHoldings;
        if (!found.holdings_by_label[key].holdings_by_address) {
          found.holdings_by_label[key].holdings_by_address = {};
        }
        found.holdings_by_label[key].holdings_by_address[address] = {
          holdings: combinedLabelHoldings,
          total_usd_value: getHoldingsTotalUsdValue(key, combinedLabelHoldings),
        };
        found.holdings_by_label[key].holdings_by_address[address].holdings =
          combinedLabelHoldings;
      } else {
        found.holdings_by_label[key].holdings =
          holding.holdings_by_label[key].holdings;
        found.holdings_by_label[key].holdings_by_address[address].holdings =
          holding.holdings_by_label[key].holdings;
        found.holdings_by_label[key].holdings_by_address[
          address
        ].total_usd_value = holding.holdings_by_label[key].total_usd_value;
      }
    } else {
      found.holdings_by_label[key] = {
        ...oldHoldings[key],
      };
    }
  }
};

const transfromHoldingsByLabel = (holding) => {
  const oldHoldings = holding?.holdings_by_label;
  for (const key in oldHoldings) {
    const holdingByLabel = oldHoldings[key];
    if (
      Object.keys(holdingByLabel?.holdings_by_address ?? {})?.length === 0 &&
      holdingByLabel.holdings &&
      holdingByLabel.holdings.length > 0
    ) {
      holdingByLabel.total_usd_value = getHoldingsTotalUsdValue(
        key,
        holdingByLabel.holdings
      );
      holdingByLabel.holdings_by_address = {
        [holding.address_ids[0]]: {
          holdings: holdingByLabel.holdings,
          total_usd_value: holdingByLabel.total_usd_value,
        },
      };
      continue;
    }
    Object.keys(holdingByLabel.holdings_by_address || {}).forEach((address) => {
      holdingByLabel.holdings_by_address[address].total_usd_value =
        getHoldingsTotalUsdValue(
          key,
          holdingByLabel.holdings_by_address[address]
        );
      holdingByLabel.holdings_by_address[address].holdings =
        holdingByLabel.holdings_by_address[address];
    });
  }
};

const useProtocolHoldingsFilter = ({
  protocolHoldings,
  selectedActiveChains,
  selectedActiveTokens,
  selectedActiveProtocols,
  selectedWalletAddresses,
}) => {
  const filteredData = useMemo(() => {
    let holdingsData = [...(protocolHoldings?.holdings || [])];
    const holdingsByAddressData = protocolHoldings?.holdings_by_address || {};

    each(holdingsByAddressData, (value, key) => {
      if (value.polymarket_holding) {
        value.holdings = [value.polymarket_holding];
      }
    });

    if (holdingsByAddressData) {
      const mappedAddresses = selectedWalletAddresses;
      if (mappedAddresses) {
        const newHoldingsData = [];
        for (let i = 0; i < mappedAddresses.length; i++) {
          const address = mappedAddresses[i];
          const currentHoldings = holdingsByAddressData[address]?.holdings;

          for (let j = 0; j < currentHoldings?.length; j++) {
            const holding = currentHoldings[j];
            let found = find(newHoldingsData, {
              chain_id: holding.chain_id,
              protocol_name: holding.protocol_name,
            });
            if (found) {
              if (found.total_usd_value) {
                found.total_usd_value.value =
                  (found.total_usd_value?.value || 0) +
                  (holding.total_usd_value?.value || 0);
                found.total_usd_value.display_value =
                  "$" + abbreviateNumber(found.total_usd_value.value);
              } else {
                found.total_usd_value = { ...holding.total_usd_value };
              }
              combineHoldingsByLabel(found, holding, address);
              // found.holdings_by_label = {
              //   ...found?.holdings_by_label,
              //   ...structuredClone(holding?.holdings_by_label),
              // };
              found.address_ids.push(address);
            } else {
              let modifiedHolding = structuredClone(holding);
              modifiedHolding.address_ids = [address];
              transfromHoldingsByLabel(modifiedHolding);
              newHoldingsData.push(modifiedHolding);
            }
          }
        }
        holdingsData = [...newHoldingsData];
      }
    }
    const chainFilteredHoldings = holdingsData?.filter((holding) =>
      selectedActiveChains?.length > 0
        ? selectedActiveChains.includes(holding.chain_id)
        : true
    );

    const chainAndTokenFilteredHoldings = chainFilteredHoldings.filter(
      (holding) =>
        selectedActiveTokens?.length > 0 || selectedActiveProtocols?.length > 0
          ? isTokenIncludedForSelectedProtocols({
              selectedActiveTokens,
              selectedActiveProtocols,
              holding,
            })
          : true
    );

    const filteredUsdValue = chainAndTokenFilteredHoldings.reduce(
      (acc, holding) => acc + (holding?.total_usd_value?.value || 0),
      0
    );

    chainAndTokenFilteredHoldings.forEach((holding) => {
      holding.relative_percentage_across_chains = {
        value: (holding.total_usd_value?.value * 100) / filteredUsdValue,
        display_value:
          ((holding.total_usd_value?.value * 100) / filteredUsdValue).toFixed(
            2
          ) + "%",
      };
    });

    const abbreviatedFilteredUsdValue = abbreviateNumber(filteredUsdValue);

    return {
      ...protocolHoldings,
      holdings: sortBy(
        chainAndTokenFilteredHoldings,
        (holding) => -holding.total_usd_value?.value
      ),
      filteredUsdValue: `$${abbreviatedFilteredUsdValue}`,
    };
  }, [
    selectedActiveChains,
    protocolHoldings,
    selectedActiveProtocols,
    selectedActiveTokens,
    selectedWalletAddresses,
  ]);

  return filteredData;
};

export default useProtocolHoldingsFilter;
