import { useRef, useState, useEffect, useCallback } from "react";

const installListener = (callback) => {
  const controller = new AbortController();
  window.addEventListener("mouseup", callback, { signal: controller.signal });
  return controller;
};

/***
 * Component that adds horizontal and vertical drag functionality to its children
 */
const Draggable = ({
  rootClass = "",
  children,
  setWasScrolled,
  setIsScrolling,
}) => {
  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [sessionWasScrolled, setSessionWasScrolled] = useState(false);
  const abortSignal = useRef(null);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  const handleDragStart = (e) => {
    if (!ourRef.current) return;
    const slider = ourRef.current.children[0];
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.body.style.cursor = "grabbing";
  };
  const handleDragEnd = useCallback(() => {
    setIsMouseDown(false);
    setWasScrolled?.(sessionWasScrolled);
    setSessionWasScrolled(false);
    setIsScrolling?.(false);

    if (!ourRef.current) return;
    document.body.style.cursor = "default";
  }, [setIsScrolling, setWasScrolled, sessionWasScrolled]);

  useEffect(() => {
    abortSignal.current = installListener(handleDragEnd);
    return () => {
      abortSignal.current?.abort();
    };
  }, [abortSignal, handleDragEnd]);

  const handleDrag = (e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current.children[0];
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = x - mouseCoords.current.startX;
    const walkY = y - mouseCoords.current.startY;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    if (Math.abs(walkX) > 5 || Math.abs(walkY) > 5) {
      setSessionWasScrolled(true);
      setIsScrolling?.(true);
    }
  };

  return (
    <div
      ref={ourRef}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
      className={rootClass}
      style={{
        display: "flex",
        overflowX: "scroll",
      }}>
      {children}
    </div>
  );
};

export default Draggable;
