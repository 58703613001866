import { Divider } from "components/UI/Components/Components";
import React from "react";
import withNullableError from "zxComponents/common/withNullableError/index";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { ArrowRight01Icon } from "hugeicons-react";
import ZxImageStack from "zxComponents/common/ZxImageStack/ZxImageStack";
import ConditionalLink from "shared/ConditionalLink";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import { getTrimmedString } from "utils/misc";
const TokenFolloweePNL = ({ data, isLoading, onView, onFilter, symbol }) => {
  const actors = Object.keys(data?.identities_to_pnl ?? {}) ?? [];
  const eligibleActors = actors?.slice(0, 5);
  const profiles = eligibleActors.map((actor) => data.identity_profiles[actor]);
  const pictures = profiles.map((profile) => {
    return {
      src: profile.display_picture,
      alt: profile.display_name,
      name: profile.display_name,
      ...profile,
    };
  });
  const restCount = actors?.length - 3;
  if (!eligibleActors?.length) return null;
  const prefix =
    eligibleActors?.length < actors?.length
      ? `and ${restCount} others also traded ${symbol}`
      : "traded " + symbol;
  return (
    <ZxFlex dir="column" align="flex-start" gap="0">
      <ZxFlex
        justify="space-between"
        width="100%"
        cursor="pointer"
        onClick={onView}>
        <ZxFlex justify="flex-start" gap="8px">
          <ZxImageStack
            pictures={pictures}
            width="24px"
            height="24px"
            showHover
          />
          <ZxFlex gap="4px">
            {profiles.map((profile, idx) => {
              const isLast = profile === profiles[profiles.length - 1];
              return (
                <ConditionalLink to={profile?.link} key={idx}>
                  <ProfileHoverCard actor={profile}>
                    <ZxFlex>
                      <ZxText style={TSTYLES.title1} fontSize="13px">
                        {getTrimmedString(profile?.display_name, 18)}{" "}
                        {isLast ? "" : ","}
                      </ZxText>
                    </ZxFlex>
                  </ProfileHoverCard>
                </ConditionalLink>
              );
            })}
          </ZxFlex>
          <ZxText color="text-2" style={TSTYLES.body1} fontSize="13px">
            {prefix}
          </ZxText>
        </ZxFlex>
        <ZxFlex onClick={onView} cursor="pointer">
          <ArrowRight01Icon size={16} color="var(--text-2)" />
        </ZxFlex>
      </ZxFlex>
      <Divider margin="8px 0" />
    </ZxFlex>
  );
};

export default withNullableError(TokenFolloweePNL);
