import {
  CustomColumn,
  CustomRow,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import React, { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CaretDoubleRight,
  ArrowBendDownRight,
  CheckCircle,
} from "@phosphor-icons/react";
import classes from "./readContract.module.css";
import {
  useFetchChainIds,
  useReadContractMutation,
  useSimulationMutation,
} from "api/contract_details.api";
import Spinner from "shared/buttonloadingSpinner";
import Popup from "components/UI/Popup/Popup";
import { GlobalContext } from "contextStates/Global";
import { addWalletChain } from "contextStates/auth_utils";
import { createPortal } from "react-dom";
import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import TransactionsRightPanel from "components/V2/ActivityDetails/Transactions/TransactionsRightPanel";
import styled from "styled-components";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";

const ReadContractFormItem = ({
  inputs,
  funcName,
  outputs,
  requestParams,
  isRead,
  simulatedWallet,
}) => {
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { chainIds } = useFetchChainIds();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showAddToWallet, setShowAddToWallet] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [response, setResponse] = useState(null);
  const [simulationResponse, setSimulationResponse] = useState(null);
  const [loadingButtonType, setLoadingButtonType] = useState(null);

  const { mutate: readContractMutation, isPending: isLoading } =
    useReadContractMutation({
      setIsSuccess,
      setIsError,
      setShowAddToWallet,
      setResponse,
      setErrorMessage,
      isRead: isRead,
      chainId: requestParams.chainId,
      ethChainId: requestParams.ethChainId,
    });
  const { mutate: simulationMutation, isPending: isSimulationLoading } =
    useSimulationMutation({
      setIsSuccess,
      setIsError,
      setErrorMessage,
      setSimulationResponse,
    });

  const addToWalletPayload = useMemo(
    () => chainIds?.[requestParams.chainId]?.add_to_wallet,
    [chainIds, requestParams.chainId]
  );

  const onSubmit = async (data) => {
    setIsSuccess(false);
    setIsError(false);
    readContractMutation({
      ...requestParams,
      functionName: funcName,
      argumentNames: Object.keys(data),
      argumentValues: Object.values(data),
    });
  };

  const onSimulate = async (data) => {
    setIsSuccess(false);
    setIsError(false);
    simulationMutation({
      ...requestParams,
      functionName: funcName,
      argumentNames: Object.keys(data),
      argumentValues: Object.values(data),
      simulatedWallet,
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
      <CustomColumn>
        <InputFields {...{ inputs, funcName, register, errors }} />
        <PaddingComponent height="8px" />
        <Button
          isLoading={isLoading || isSimulationLoading}
          isError={isError}
          errorMessage={errorMessage}
          isRead={isRead}
          showSimulated={!isRead && simulatedWallet != null}
          loadingButtonType={loadingButtonType}
          onSubmit={(buttonType) => {
            setLoadingButtonType(buttonType);
            if (buttonType === "simulate") {
              handleSubmit(onSimulate)();
            } else {
              handleSubmit(onSubmit)();
            }
          }}
        />
        {simulationResponse &&
          createPortal(
            <RightPanel
              onClose={() => {
                setSimulationResponse(null);
              }}>
              <TransactionsRightPanel
                activityId={simulationResponse?.activity_id}
                onClose={() => {
                  setSimulationResponse(null);
                }}
              />
            </RightPanel>,
            document.getElementById("rightPanel")
          )}
        {showAddToWallet && (
          <Popup onClose={() => setShowAddToWallet(false)}>
            <h2 className={classes.popup_title}>
              Looks like {requestParams.chainId} is not configured in your
              wallet
            </h2>
            <p className={classes.popup_note}>
              Please add {requestParams.chainId} | chain id:
              {requestParams.ethChainId} to your wallet to submit the
              transaction.
            </p>
            <button
              onClick={() => {
                addWalletChain(addToWalletPayload)
                  .then((value) => {
                    handleSuccessSnackbar?.(value);
                    setShowAddToWallet(false);
                  })
                  .catch((err) => {
                    handleErrorSnackbar?.(
                      null,
                      `Failed to add chain to wallet with error: ${err?.message}`
                    );
                  });
              }}
              className={classes.add_to_wallet_button}>
              Add to wallet
            </button>
          </Popup>
        )}

        {isRead && (
          <>
            <PaddingComponent height="8px" />
            <CustomRow alignItems="center" gap="4px">
              <ArrowBendDownRight size={16} />
              {`${outputs?.map((output) => output.type)?.join(",")} `}
            </CustomRow>
          </>
        )}
        <ResponseWrapper
          {...{
            isSuccess,
            response: (
              <FeedDescription
                templateString={response?.result?.toString()}
                profiles={response?.profile}
              />
            ),
            isRead,
            inputs,
            funcName,
          }}
        />
        <PaddingComponent height="4px" />
      </CustomColumn>
    </form>
  );
};

const FormInput = ({ param, register, errors }) => {
  const paramName = useMemo(
    () => param?.name || "empty_key_input",
    [param?.name]
  );
  const label = useMemo(() => param?.name || "input", [param?.name]);

  return (
    <>
      <label>{`${label} <${param.type}>`}</label>
      <PaddingComponent height="6px" />
      <input
        className={classes.input}
        {...register(paramName, { required: true })}
        name={paramName}
        placeholder={label}
      />
      <PaddingComponent height="4px" />
      {errors?.[paramName]?.type === "required" && (
        <p className={classes.errorMessage}>{`${paramName} cannot be empty`}</p>
      )}
      <PaddingComponent height="8px" />
    </>
  );
};

const StyledButton = styled.button`
  color: var(--text-white);
  border-radius: 40px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  width: ${(props) => props.width};
  padding: 3px 8px;
  background: ${(props) =>
    props.simulatedButton ? "var(--warning);" : "var(--primary-color)"};
`;

const CustomSpinner = styled(Spinner)`
  stroke: var(--text-white);
  background: transparent;
  width: 30px;
  height: 19.5px;
  align-self: "center";
`;

const Button = ({
  isLoading,
  isError,
  errorMessage,
  isRead,
  loadingButtonType,
  showSimulated,
  onSubmit,
}) => {
  const buttonLabel = isRead ? "Query" : "Write";

  return (
    <CustomRow alignItems="center" gap="8px">
      <StyledButton
        onClick={() => {
          onSubmit("submit");
        }}
        width="58px"
        disabled={isLoading}>
        {loadingButtonType === "submit" && isLoading ? (
          <CustomSpinner />
        ) : (
          buttonLabel
        )}
      </StyledButton>
      {showSimulated && (
        <StyledButton
          simulatedButton
          width="78px"
          onClick={() => {
            onSubmit("simulate");
          }}
          disabled={isLoading}>
          {loadingButtonType === "simulate" && isLoading ? (
            <CustomSpinner />
          ) : (
            "Simulate"
          )}
        </StyledButton>
      )}
      {isError && (
        <div className={classes.errorMessage}>
          {errorMessage ?? "Error: Something went wrong"}
        </div>
      )}
    </CustomRow>
  );
};

export default ReadContractFormItem;

const InputFields = ({ inputs, funcName, register, errors }) => {
  return inputs?.map((param, paramIndex) => (
    <FormInput
      key={`param-${funcName}-${paramIndex}`}
      register={register}
      errors={errors}
      param={param}
    />
  ));
};
export const ResponseWrapper = ({
  isSuccess,
  response,
  isRead,
  inputs,
  funcName,
}) => {
  const paramTypes = useMemo(
    () => inputs.map(({ type }) => type).join(", "),
    [inputs]
  );

  if (!isSuccess || !isRead) {
    return null;
  }

  return (
    <div>
      <PaddingComponent height="8px" />
      <Divider />
      <PaddingComponent height="8px" />
      <CustomRow alignItems="center" gap="4px">
        <div
          className={
            classes.responseTitle
          }>{`[ ${funcName}(${paramTypes}) method response ]`}</div>
        <CheckCircle size={16} className={classes.successIcon} />
      </CustomRow>
      <div>
        <CustomRow alignItems="center" gap="4px">
          <CaretDoubleRight size={16} />
          <div className={classes.responseContent}>{response}</div>
        </CustomRow>
      </div>
    </div>
  );
};
