import React, { useContext, useEffect } from "react";

import { getDuration, getTrimedAddress } from "utils/misc";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import classes from "./activityHeader.module.css";
import ActivityExtraInfo from "./ActivityExtraInfo";
import { formatUnixTimestamp } from "../../utils/misc";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import { farcasterIcon, lensIcon } from "utils/assets";
import { mirrorxyzIcon } from "utils/assets";
import snapshotIcon from "assets/svg/feed/snapshot.svg";
import ConditionalLink from "shared/ConditionalLink";
import TrendingTokenChart from "components/TrendingActivities/TrendingTokenChart";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import { CustomText } from "components/UI/Components/Components";
import { AuthContext } from "contextStates/AuthContext";

const ActivityHeader = ({
  actor,
  timestamp,
  involvedChains,
  chainProfiles,
  summary,
  showFullDateTime,
  postId,
  creatorIdentity,
  type,
  showSettings = true,
  queryKeys,
  onActorUpdate,
  sourceUrl = null,
  isGroupedActivityDetails,
  isHoveringForGptEdit,
  setIsHoveringForGptEdit,
  trendingPriceChartData,
  category,
  groupedActivity,
  post,
}) => {
  const { identityDetails, isSuperuser } = useContext(AuthContext);
  return (
    <div className={classes.activityHeader}>
      <ActorInfo
        actor={actor}
        timestamp={timestamp}
        type={type}
        showFullDateTime={showFullDateTime}
        onActorUpdate={onActorUpdate}
        sourceUrl={sourceUrl}
        trendingPriceChartData={trendingPriceChartData}
        isGroupedActivityDetails={isGroupedActivityDetails}
      />
      <ActivityExtraInfo
        post={post}
        chains={involvedChains}
        chainProfiles={chainProfiles}
        type={type}
        postId={postId}
        groupedActivity={groupedActivity}
        creatorIdentity={creatorIdentity}
        showSettings={
          showSettings &&
          (identityDetails?.current?.identity?.id === actor?.identity_id ||
            isSuperuser)
        }
        queryKeys={queryKeys}
        isGroupedActivityDetails={isGroupedActivityDetails}
        isHoveringForGptEdit={isHoveringForGptEdit}
        setIsHoveringForGptEdit={setIsHoveringForGptEdit}
        category={category}
      />
    </div>
  );
};

export const ActorInfo = ({
  actor,
  timestamp,
  showFullDateTime,
  type,
  sourceUrl,
  onActorUpdate,
  trendingPriceChartData,
  mini = false,
  isGroupedActivityDetails,
  trimWidth = false,
  trimLength = null,
}) => {
  return (
    <div
      className={classes.actorDetailsHeader}
      onClick={(e) => e.stopPropagation()}>
      <ProfileHoverCard actor={actor} onActorUpdate={onActorUpdate}>
        <ConditionalLink
          className={classes.actorInfoLink}
          to={actor?.identity_link || actor?.link}>
          <div
            className={`${classes.actorName} ${
              trimWidth && classes.trimWidth
            } ${mini && classes.mini}`}>
            {!trimLength
              ? actor?.display_name
              : getTrimedAddress(actor?.display_name, trimLength)}
          </div>
          <VerifiedBadge profile={actor} />
        </ConditionalLink>
      </ProfileHoverCard>
      {type === "news" && isGroupedActivityDetails && (
        <CustomText text="News ·" color="var(--text-3)" />
      )}
      {!!timestamp && (
        <TimeLabel timestamp={timestamp} showFullDateTime={showFullDateTime} />
      )}
      {type === "lens" && <LensProtocol sourceUrl={sourceUrl} />}
      {type === "farcaster" && <FarcasterProtocol sourceUrl={sourceUrl} />}
      {type === "mirror" && <MirrorXYZ sourceUrl={sourceUrl} />}
      {type === "snapshot" && <GovernanceProposal sourceUrl={sourceUrl} />}
      {type === "trending" && trendingPriceChartData && (
        <TrendingTokenChart graphData={trendingPriceChartData} />
      )}
    </div>
  );
};
let interval;
export const TimeLabel = ({
  timestamp,
  showFullDateTime,
  showYearWithMonth,
  className,
  color = "var(--text-2)",
  fontSize = "13px",
  fontWeight = "400",
  showSecondsCount = false,
}) => {
  const timeLabel = getDuration(timestamp, showYearWithMonth);
  const isIntervalSet = React.useRef(false);
  const formattedDate = formatUnixTimestamp(timestamp);
  const [seconds, setSeconds] = React.useState(0);
  useEffect(() => {
    if (timeLabel?.toLowerCase() === "now" && !isIntervalSet.current) {
      interval = setInterval(() => {
        setSeconds(Math.floor(Date.now() / 1000) - timestamp);
      }, 1000);
      isIntervalSet.current = true;
      return () => clearInterval(interval);
    } else {
      clearInterval(interval);
    }
  }, [timeLabel, showSecondsCount, timestamp]);
  return (
    <div
      className={`${classes.timeLabel} ${className ?? ""}`}
      style={{
        color,
        fontSize,
        fontWeight,
      }}>
      {showFullDateTime ? (
        <span>{formattedDate}</span>
      ) : (
        <PPLxTooltip title={formattedDate} arrow>
          <span>
            {timeLabel?.toLowerCase() === "now" && showSecondsCount
              ? seconds
              : timeLabel}
          </span>
        </PPLxTooltip>
      )}
    </div>
  );
};

const LensProtocol = ({ sourceUrl }) => {
  return (
    <ConditionalLink
      sameTab={false}
      to={{ pathname: sourceUrl }}
      className={classes.lens}>
      <div className={classes.divider}>·</div>
      <img src={lensIcon} alt="lens" />
      <div className={classes.label}>Lens</div>
    </ConditionalLink>
  );
};
const FarcasterProtocol = ({ sourceUrl }) => {
  return (
    <ConditionalLink
      sameTab={false}
      to={{ pathname: sourceUrl }}
      className={classes.farcaster}>
      <div className={classes.divider}>·</div>
      <img src={farcasterIcon} alt="farcaster" />
      <div className={classes.label}>Farcaster</div>
    </ConditionalLink>
  );
};
const MirrorXYZ = ({ sourceUrl }) => {
  return (
    <ConditionalLink
      sameTab={false}
      to={{ pathname: sourceUrl }}
      className={classes.mirror}>
      <div className={classes.divider}>·</div>
      <img src={mirrorxyzIcon} alt="mirror.xyz" />
      <div className={classes.label}>mirror.xyz</div>
    </ConditionalLink>
  );
};
const GovernanceProposal = ({ sourceUrl }) => {
  return (
    <ConditionalLink
      sameTab={false}
      to={{ pathname: sourceUrl }}
      className={classes.snapshot}>
      <div>·</div>
      <img src={snapshotIcon} alt="snapshot.org" />
      <div>Governance </div>
    </ConditionalLink>
  );
};

export default ActivityHeader;
