import { getEncodedHeaders } from "components/UI/QrCode/QrCode";
import { signedApiRequest, signedRequest } from "./api";
import { useMutation } from "@tanstack/react-query";

export const getOnboardingData = async ({
  identifier,
  invite_code = null,
  withError = false,
}) => {
  const inviteCode = invite_code ?? localStorage.getItem("inviteCode");
  const body = JSON.stringify({
    invite_code: inviteCode,
    can_connect_multiple_socials: true,
  });
  if (!withError) {
    return signedApiRequest({
      method: "post",
      path: "onboarding_interaction",
      bodyText: body,
    });
  } else {
    try {
      const data = await signedApiRequest({
        method: "post",
        path: "onboarding_interaction",
        bodyText: body,
      });
      return data;
    } catch (err) {
      return {
        is_error: true,
        ...err,
      };
    }
  }
};

export const getOnboardingStepDetails = async () => {
  return signedApiRequest({
    method: "post",
    path: "get_onboarding_step_details",
  });
};

export const getOnboardingWalletChecklist = async () => {
  return signedApiRequest({
    method: "post",
    path: "onboarding_wallet_checklist",
  });
};

export const getOnboardingNetWorth = async ({ identifier }) => {
  const res = await signedApiRequest({
    method: "post",
    path: "get_updated_net_worth",
    bodyText: JSON.stringify({
      identifier,
    }),
  });
  const networth = res.data.data[identifier];
  return networth;
};

export const joinWaitlist = async ({ name, email, twitter, linkedIn } = {}) => {
  return signedApiRequest({
    method: "post",
    path: "waitlist_interaction",
    bodyText: JSON.stringify({
      name: name,
      email: email,
      twitter: twitter,
      linkedIn: linkedIn,
    }),
  });
};

export const authorizeWithInviteCode = async ({ inviteCode }) => {
  return signedApiRequest({
    method: "post",
    path: "authorize_with_invite_code",
    bodyText: JSON.stringify({
      invite_code: inviteCode,
    }),
  });
};

export const changeWallet = async ({ nonce } = {}) => {
  return signedApiRequest({
    method: "post",
    path: "waitlist_interaction",
    bodyText: JSON.stringify({
      nonce: nonce,
    }),
  });
};

export const moveOnboardingState = async ({ currentStep }) => {
  const res = await signedApiRequest({
    method: "post",
    path: "move_to_next_onboarding_state",
    bodyText: JSON.stringify({
      current_state: currentStep,
    }),
  });
  return res.data.data;
};

export const isValidInviteCode = async ({ inviteCode }) => {
  const res = await signedRequest({
    method: "post",
    path: "/api/access_control/is_invite_code_valid",
    bodyText: JSON.stringify({
      code: inviteCode,
    }),
  });
  return res.data.data;
};

export const getLensConnectChallenge = async ({ address }) => {
  const res = await signedRequest({
    method: "post",
    path: "/api/lens/request_login",
    bodyText: JSON.stringify({
      address,
    }),
  });
  return res.data.data.challenge;
};

export const lensCallback = async ({ address, signature }) => {
  const res = await signedRequest({
    method: "post",
    path: "/api/lens/lens_callback",
    bodyText: JSON.stringify({
      address,
      signature,
    }),
  });
  return res.data.data;
};

export const updateCookiesForIdentity = async () => {
  const cookies = getEncodedHeaders();
  if (!cookies) {
    return;
  }
  const res = await signedRequest({
    method: "post",
    path: "/api/v4/update_cookies_for_identity",
    bodyText: JSON.stringify({
      cookies,
    }),
  });
  const uid = res.data.data.uuid;
  if (uid) {
    localStorage.setItem(`user_cookies_uid`, uid);
  }
  return null;
};

export const getCookiesAndMoveUser = async (uuid) => {
  if (!uuid) {
    throw new Error("UID is required");
  }
  const res = await signedRequest({
    method: "post",
    path: "/api/v4/get_cookies_for_identity",
    bodyText: JSON.stringify({
      uuid,
    }),
  });
  const btoaCookies = res?.data?.data?.cookies;
  const decodedCookies = atob(btoaCookies);
  const parsedCookies = JSON.parse(decodedCookies);
  const {
    sessionPrivateKey,
    tokenSerialized,
    tokenSignature,
    userAddress,
    sessionAddress,
    walletType,
  } = parsedCookies;
  localStorage.setItem("sessionPrivateKey", sessionPrivateKey);
  localStorage.setItem("tokenSerialized", JSON.stringify(tokenSerialized));
  localStorage.setItem("tokenSignature", tokenSignature);
  localStorage.setItem("userAddress", userAddress);
  localStorage.setItem("sessionAddress", sessionAddress);
  localStorage.setItem("walletType", walletType);
  await moveOnboardingState({ currentStep: "download_app" });
  return parsedCookies;
};

export const generateBio = async ({
  interests,
  title,
  workplace,
  bio,
  regenerate = false,
}) => {
  return signedApiRequest({
    method: "post",
    path: "generate_bio_from_data",
    bodyText: JSON.stringify({
      interests,
      job_title: title,
      work_place: workplace,
      regenerate,
      bio,
    }),
  });
};

export const useGenerateBioMutation = ({ onSuccess, onError }) => {
  const mutation = useMutation({
    mutationFn: (values) => generateBio({ ...values }),

    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      onError(error);
    },
  });
  return mutation;
};

export const sendWalletConnectedEvent = async ({ address }) => {
  try {
    await signedApiRequest({
      method: "post",
      path: "wallet_connected",
      bodyText: JSON.stringify({ address }),
    });
  } catch (err) {
    console.log("Error sending wallet connected event", err);
  }
};
