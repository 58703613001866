import React from "react";
import styles from "./ZxSortButton.module.scss";
import ZxText from "../ZxText/ZxText";
import { CustomColumn, CustomRow } from "components/UI/Components/Components";
import { CaretDown, Check } from "@phosphor-icons/react";
import { Menu, MenuItem } from "@mui/material";
import { CaretUp } from "../../../../node_modules/@phosphor-icons/react/dist/index";
// import { ClickAwayListener } from "../../../../node_modules/@mui/material/index";
const ZxSortButton = ({ options, onChange, selectedOption }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <div className={styles.container} onClick={handleClick}>
        <CustomRow alignItems="center" justifyContent="space-between">
          <ZxText color="text-2" fontSize={12}>
            {selectedOption?.label ??
              options.find((o) => o.key === selectedOption)?.label}
          </ZxText>
          {anchorEl ? (
            <CaretUp size={16} color="var(--text-3)" />
          ) : (
            <CaretDown size={16} color="var(--text-3)" />
          )}
        </CustomRow>
      </div>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        sx={{
          ".MuiPaper-root": {
            backgroundColor: "var(--background)",
            width: "160px",
            // boxShadow: "none",
            marginTop: "4px",
          },
        }}
        onClose={handleClose}
        value={selectedOption}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {options.map((option, index) => {
          const isSelected = selectedOption === option.key;
          return (
            <MenuItem
              key={index}
              value={option.key}
              selected={isSelected}
              sx={{
                ".MuiMenuItem-root": {
                  padding: "0",
                },
              }}
              onClick={() => {
                onChange(option.key);
                handleClose();
              }}>
              <CustomColumn>
                <div className={styles.menuItem}>
                  <ZxText color="text-2" fontSize={12}>
                    {option.label}
                  </ZxText>
                  {isSelected && (
                    <Check size={12} color="var(--success)" weight="bold" />
                  )}
                </div>
                {/* {index !== options.length - 1 && <Divider margin="8px 0 0 0" />} */}
              </CustomColumn>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default ZxSortButton;
