import classes from "./SelectedNode.module.css";
import FollowEditButton from "components/FollowEditButton/FollowEditButton";
import { Link } from "react-router-dom";
import Bio from "../Profile/ProfileHeader/Bio";
import { shortStartingAddress } from "utils/misc";
import TopTokenCard from "./TopTokenCard";
import UserAvatar from "components/UserAvatar/UserAvatar";
import Copyable from "components/ValueType/Copyable/Copyable";
import { getNetworthData } from "api/profile.api";
import withLoader from "components/core/withLoader";
import { CustomColumn } from "components/UI/Components/Components";

const SelectedNode = ({ node, selectedActiveTokens }) => {
  const info = node.data?.details;
  const profile = node.data?.details;
  const name = info?.display_name;
  const link = info?.link;
  const bio = info?.bio;
  const address = node.data?.details?.address;
  const identity_id = info.id;
  return (
    <div className={classes.selected_node}>
      <div className={classes.selected_node_header}>
        <UserAvatar actor={info} />
        <FollowEditButton
          name={name}
          bio={bio}
          onUpdateCallback={() => {}}
          followed={profile.followed}
          socials={profile?.socials}
          address={address}
          identityId={identity_id}
          showEdit={false}
          disabled={info?.disable_follow}
          followDirectly={info?.follow_directly}
        />
      </div>
      <Bio bio={bio} />

      <div className={classes.active_since}>
        <CustomColumn>
          <div className={classes.active_since_left}>
            <div>
              <div className={classes.bundle_cta}>
                <span className={classes.single_wallet}>
                  {shortStartingAddress(
                    profile?.address ||
                      profile?.bundle?.address_infos?.[0].address
                  )}{" "}
                </span>

                {profile.bundle?.address_infos?.length > 1 ? (
                  <Link
                    to={`${link}/wallets`}
                    target="_blank"
                    className={`${classes.padding_left} ${classes.aliases}`}>
                    {"+"}
                    {profile.bundle?.address_infos?.length - 1}{" "}
                    {profile.bundle?.address_infos?.length - 1 > 1
                      ? "Wallets"
                      : "Wallet"}
                  </Link>
                ) : null}
              </div>
            </div>
            <Copyable
              content={
                profile?.address || profile?.bundle?.address_infos?.[0].address
              }
            />
            <NetworthApiComp identifier={node.id} />
          </div>

          <div className={classes.follower_info}>
            <>
              <span className={classes.count}>
                {profile?.following_count?.value}{" "}
              </span>
              <span className={classes.text}>following</span>
            </>
            <span className={classes.inline_margin}></span>
            <>
              <span className={classes.count}>
                {profile?.follower_count?.value}{" "}
              </span>
              <span className={classes.text}>followers</span>
            </>
          </div>
        </CustomColumn>
      </div>

      <TopTokenCard
        identifier={node.id}
        selectedActiveTokens={selectedActiveTokens}
      />
    </div>
  );
};
const NetworthDetails = ({ queryData }) => {
  const networth = queryData?.data?.data?._Any?.total_usd_value?.display_value;
  return <div className={classes.networth}>{networth}</div>;
};

const NetworthApiComp = withLoader(NetworthDetails, getNetworthData, {
  queryKey: (props) => ["networth", props.identifier],
  CustomLoader: () => null,
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  renderNotFound: false,
});

export default SelectedNode;
