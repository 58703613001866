import { CODEX_NETWORKS } from "utils/constants";
import { getUniqueId } from "utils/event_tracking";
import {
  CHART_INIT_SETTINGS,
  CHARTS_BACKEND_URLS,
  CODEX_SOCKET_QUERY,
} from "./TradingView.constants";
import {
  constructMarkers,
  fetchBackendBars,
  // fetchBirdeyeBars,
  fetchCodexBars,
} from "./TradingView.utils";

const METADATA = {};
const isChartsPage = window.location.href.includes("/charts/");
let visibilityHandler = null;

// WebSocket Connection
function connectToWebSocket({
  address,
  callback,
  chain,
  resolution,
  updatePrice,
}) {
  const poolAddress = METADATA.POOL_ADDRESS;
  const tokenType = METADATA.TOKEN_TYPE;
  const ws = new WebSocket(CHARTS_BACKEND_URLS.CODEX_SOCKET);
  const chainId = CODEX_NETWORKS.find(
    (n) => n.name === chain || n.altName?.toLowerCase() === chain?.toLowerCase()
  )?.id;
  const isPriceInUSD = localStorage.getItem("tv_price_preference") === "price";
  const multiplier = isPriceInUSD ? 1 : METADATA.MCAP_MULTIPLIER;
  ws.onopen = () => {
    // console.log("WebSocket connection opened");
    // if (!isSolana) {
    //   return;
    // }
    // // Subscribe to price data for birdeye
    // const payload = JSON.stringify({
    //   type: "SUBSCRIBE_PRICE",
    //   data: {
    //     queryType: "simple",
    //     chartType: getBirdEyeResolution(resolution),
    //     address: address,
    //     currency: "usd",
    //   },
    // });
    // ws.send(payload);
  };

  ws.onmessage = async (event) => {
    try {
      const message = JSON.parse(event.data);
      const data = JSON.parse(event.data);
      // const isSolana = chain.toLowerCase() === "solana";
      // check for codex ack
      const isDataAvailable = data?.payload?.data?.onBarsUpdated;
      if (data.type === "connection_ack") {
        ws.send(
          JSON.stringify({
            payload: {
              query: CODEX_SOCKET_QUERY,
              operationName: "UpdateAggregateBatch",
              extensions: {},
              variables: {
                pairId: `${poolAddress}:${chainId}`,
                quoteToken: tokenType,
                statsType: "FILTERED",
              },
            },
            type: "subscribe",
            id: getUniqueId(),
          })
        );
      } else if (!!isDataAvailable) {
        const barsObject = message.payload.data.onBarsUpdated?.aggregates;
        const barKey = `r${resolution}`;
        const data = barsObject[barKey];
        const { usd } = data;
        const { o, h, l, c, t, volume } = usd;
        const lastBar = {
          time: t * 1000,
          open: o * multiplier,
          high: h * multiplier,
          low: l * multiplier,
          close: c * multiplier,
          volume: volume,
        };
        updatePrice(c);
        callback(lastBar);
      }
    } catch (error) {
      console.error("WebSocket message processing error:", error);
    }
  };

  ws.onerror = (error) => {
    console.error("WebSocket error:", error);
  };

  ws.onclose = () => {
    console.log("WebSocket connection closed");
  };

  return ws;
}

let ws = null;

// function openWebSocket({
//   address,
//   onRealtimeCallback,
//   resolution,
//   chain,
//   updatePrice,
// }) {
//   if (
//     ws &&
//     (ws.readyState !== WebSocket.CLOSED ||
//       ws.readyState === WebSocket.CONNECTING)
//   ) {
//     return; // WebSocket is already open or in the process of opening
//   }

//   ws = connectToWebSocket({
//     address: address,
//     callback: onRealtimeCallback,
//     resolution,
//     chain,
//     updatePrice,
//   });
// }
let focussing = false;
// DataFeed Implementation
function getDataFeed({
  address,
  chain,
  symbol,
  slug,
  setEmpty,
  terminalKeys,
  onError,
  updatePrice,
  getMarkers,
  isNativeToken,
  metadata,
  socket,
  socketListener,
}) {
  return {
    onReady(callback) {
      const fdvMultiplier = metadata?.fdv_multiplier ?? 1;
      const mcapMultiplier = metadata?.mcap_multiplier ?? 1;
      const tokenName = metadata?.symbol;
      const poolAddress = metadata?.gecko_terminal_pool ?? null;
      const tokenType = metadata?.token_type ?? null;
      METADATA.SYMBOL = tokenName;
      METADATA.FDV_MULTIPLIER = fdvMultiplier;
      METADATA.MCAP_MULTIPLIER = mcapMultiplier;
      METADATA.POOL_ADDRESS = poolAddress;
      METADATA.TOKEN_TYPE = tokenType;
      setTimeout(() => {
        callback({
          supports_search: false,
          supports_group_request: false,
          supported_resolutions: [
            "1",
            "5",
            "15",
            "30",
            "60",
            "240",
            "720",
            "12h",
            "1D",
          ],
          supports_marks: true,
          supports_timescale_marks: false,
          supports_time: true,
        });
      }, 0);
    },

    resolveSymbol(_, onSymbolResolvedCallback) {
      setTimeout(() => {
        return onSymbolResolvedCallback({
          ticker: symbol ?? "-",
          name: symbol ?? "-",
          ...CHART_INIT_SETTINGS,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      }, 0);
    },
    getMarks: async (
      symbolInfo,
      startDate,
      endDate,
      onDataCallback,
      resolution
    ) => {
      const markers = getMarkers()?.current ?? [];
      const finalMarkers = constructMarkers({ markers, startDate, resolution });
      onDataCallback(finalMarkers);
    },

    async getBars(symbolInfo, resolution, periodParams, onHistoryCallback) {
      const { from, to, firstDataRequest, countBack } = periodParams;
      const chartPricePreference = localStorage.getItem("tv_price_preference");
      const isChartInUSD = !chartPricePreference
        ? true
        : chartPricePreference === "price";
      const getData = async ({ to }) => {
        const bars = await fetchCodexBars({
          address,
          resolution,
          from,
          to: to,
          chain,
          countBack,
          poolAddress: METADATA.POOL_ADDRESS,
          tokenType: METADATA.TOKEN_TYPE,
          isChartInUSD,
          mcapMultiplier: METADATA.MCAP_MULTIPLIER,
          isFirstRequest: firstDataRequest,
        });
        return bars;
      };

      try {
        const isNativeTokenPage =
          window.location.href.includes("/native_token/") || isNativeToken;
        if (isNativeTokenPage) {
          throw new Error("Native token page");
        }

        window.addEventListener("visibilitychange", async () => {
          if (document.hidden) {
            focussing = false;
            return;
          }
          if (focussing || isNativeTokenPage) return;
          else {
            focussing = true;
            // const bars = await getData({ to: Math.ceil(Date.now() / 1000) });
            // if (bars && bars?.length > 0) {
            //   onHistoryCallback(bars, { noData: false });
            //   const price = isChartInUSD
            //     ? bars[bars.length - 1].close
            //     : bars[bars.length - 1].close / METADATA.MCAP_MULTIPLIER;
            //   updatePrice(price);
            // }
          }
        });
        const bars = await getData({ to });

        if (bars?.length === 0 || !bars) {
          setEmpty(true);
          onHistoryCallback([], { noData: true });
          return;
        }
        onHistoryCallback(bars, { noData: false });
        const price = isChartInUSD
          ? bars[bars.length - 1].close
          : bars[bars.length - 1].close / METADATA.MCAP_MULTIPLIER;
        if (firstDataRequest) {
          updatePrice(price);
        }
      } catch (error) {
        console.log({ error });
        try {
          const bars = await fetchBackendBars({
            to,
            from,
            resolution,
            countBack,
            updatePrice,
            chain,
            address,
            slug,
            setEmpty,
            firstDataRequest,
            onHistoryCallback,
            terminalKeys,
            isChartInUSD,
            mcapMultiplier: METADATA.MCAP_MULTIPLIER,
          });
          if (!bars) throw new Error("No data");
          if (bars?.length > 0) {
            setEmpty(false);
          }
          if (bars?.length === 0) {
            return onHistoryCallback([], {
              noData: true,
            });
          }
          const lastPrice = bars[bars.length - 1].close;

          if (firstDataRequest) {
            updatePrice(
              isChartInUSD ? lastPrice : lastPrice / METADATA.MCAP_MULTIPLIER
            );
          }
          onHistoryCallback(bars, {
            noData: false,
          });
        } catch (error) {
          console.log("[getBars]: Get error", error, onError);
          // setError(true);
          onError?.("Error fetching data. Please try again later.");
          onHistoryCallback([], {
            noData: true,
          });
        }
      }
    },

    subscribeBars(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      resetCallback
    ) {
      if (
        ws &&
        ws.readyState === WebSocket.OPEN &&
        ws.readyState !== WebSocket.CONNECTING
      ) {
        ws.close();
      }
      ws = connectToWebSocket({
        address: address,
        callback: onRealtimeCallback,
        resolution,
        chain,
        updatePrice,
      });
      socket.current = ws;
      visibilityHandler = () => {
        if (!document.hidden) {
          resetCallback();
          const widget = window.tvWidget;
          try {
            widget?.activeChart()?.resetData();
          } catch (e) {
            console.log("Error resetting data", e);
          }
        }
        if (document.hidden) {
          if (ws && !isChartsPage) {
            ws.close();
          }
        }
      };
      window.addEventListener("visibilitychange", visibilityHandler);
      socketListener.current = visibilityHandler;
    },

    unsubscribeBars() {},
  };
}

export default getDataFeed;
