import { macos } from "platform-detect";
import React, { useEffect, useRef, useState } from "react";
import styles from "./searchInput.module.css";
import Mousetrap from "mousetrap";
import AnimatedSearchLoader from "./AnimatedSearchLoader";
import SearchPlaceholder from "./SearchPlaceholder";
import { debounce } from "lodash";
import { MagnifyingGlass, X } from "@phosphor-icons/react";

const SearchInput = ({
  isSearchInputFocused,
  setIsSearchInputFocused,
  setSearchTerm,
  setFilters,
  searchTerm,
  isSearchLoading,
  inputRef,
  noPlaceholderAnimation,
}) => {
  const [inputValue, setInputValue] = useState("");

  const delayedQuery = useRef(
    debounce((q) => {
      setSearchTerm(q);
    }, 200)
  ).current;

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    delayedQuery(value);
  };

  const clearInput = () => {
    setInputValue("");
    setSearchTerm("");
    setFilters((old) =>
      old.map((filter) => ({ ...filter, isSelected: false }))
    );
    inputRef.current.focus();
  };

  useEffect(() => {
    if (inputRef.current)
      Mousetrap.bind(["command+k", "ctrl+k"], (e) => {
        // prevent the default browser behavior, in this case, adding the "space" character to the input value
        e.preventDefault();
        inputRef.current.focus();
      });

    return () => {
      Mousetrap.unbind(["command+k", "ctrl+k"]);
    };
  }, [inputRef]);

  const shortcutKey = macos ? "⌘ + K" : "Ctrl + K";

  return (
    <div
      className={`${styles.searchInputContainer} ${
        isSearchInputFocused ? styles.focus : ""
      }`}>
      <MagnifyingGlass size={16} color="var(--text-3)" />
      <div className={styles.searchInput}>
        <input
          type="text"
          value={inputValue}
          ref={inputRef}
          onChange={handleInputChange}
          onFocus={() => setIsSearchInputFocused(true)}
          {...(noPlaceholderAnimation ? { placeholder: "Search" } : {})}
        />

        {!noPlaceholderAnimation && !inputValue && <SearchPlaceholder />}
      </div>
      <div className={styles.searchOptions}>
        {isSearchLoading && !!searchTerm?.length && <AnimatedSearchLoader />}
        {inputValue && isSearchInputFocused && (
          <X
            size={16}
            color="var(--text-2)"
            weight="bold"
            onClick={clearInput}
            className={styles.clearIcon}
          />
        )}
        {!isSearchInputFocused && (
          <div className={styles.shortcutKey}>{shortcutKey}</div>
        )}

        {/* TODO: uncomment when advanced filters UI is built */}
        {/* <button className={styles.advancedFiltersButton}>
          <img
            className={styles.filtersIcon}
            src={filtersIcon}
            alt="advanced filters icon"
          />
        </button> */}
      </div>
    </div>
  );
};

export default SearchInput;
