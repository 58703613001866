import classes from "./ExampleRightPanel.module.css";
import { X } from "@phosphor-icons/react";
import queries from "../QueryObject";
import RenderQueryTitle from "./RenderQueryTitle";
const ExampleRightPanel = ({ onClose }) => {
  return (
    <div className={classes.example_right_panel}>
      <X
        size={24}
        weight="fill"
        onClick={onClose}
        color="var(--text-2)"
        className={classes.close_button}
      />
      <div className={classes.heading}>List of Available Commands</div>
      <div className={classes.sub_heading}>{queries.length} Commands</div>
      <div className={classes.command_list}>
        {queries.map((query, index) => {
          return (
            <div className={classes.query_card} key={index}>
              <div className={classes.title}>
                <RenderQueryTitle title={`${query.title}`} />
              </div>
              <div className={classes.description}>{query.description}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExampleRightPanel;
