import polyActivityClasses from "./PolyMarketActivity.module.css";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
} from "../UI/Components/Components";
import ZxText from "../../zxComponents/common/ZxText";
import { TSTYLES } from "../../zxStyles/constants";
import React, { useContext } from "react";
import _ from "lodash";
import { PolyMarketFeedContext } from "../Feed/FeedItemsList";
import { usePolyMarketStatus } from "../../api/feed.api";
import { useHistory } from "react-router-dom";
import { getPlurals, handleFeedItemClick } from "../../utils/misc";

const getTagColor = (side) => {
  const colorMap = {
    BUY: "rgba(7, 157, 57, 0.10)",
    SELL: "rgba(233, 88, 68, 0.10)",
  };
  return colorMap[side] || "var(--primary-color10)";
};
const getTextColor = (side) => {
  const colorMap = {
    BUY: "rgba(7, 157, 57, 1)",
    SELL: "rgba(233, 88, 68, 1)",
  };
  return colorMap[side] || "var(--primary-color)";
};

const PolyActivityTrade = ({ activity }) => {
  let tagColor = getTagColor(activity.side);
  return (
    <div className={polyActivityClasses.row2}>
      <CustomRow justifyContent="space-between" alignItems="center">
        <CustomRow gap="6px" alignItems="center">
          <div
            className={polyActivityClasses.side}
            style={{ backgroundColor: tagColor }}>
            <ZxText
              style={TSTYLES.body3}
              fontSize={11}
              color={getTextColor(activity.side)}>
              {activity.side}
            </ZxText>
          </div>
          {activity.share_price !== null && (
            <>
              <div className={polyActivityClasses.dot}></div>
              <ZxText
                style={TSTYLES.secondary1}
                fontSize={13}
                color={"--text-2"}>
                {activity.outcome} {activity.share_price?.display_value}
              </ZxText>
            </>
          )}
          <div className={polyActivityClasses.dot}></div>
          <ZxText style={TSTYLES.secondary1} fontSize={13} color={"--text-2"}>
            {activity.share_size.display_value} shares
          </ZxText>
        </CustomRow>
        <ZxText style={TSTYLES.secondaryTitle}>
          {activity.usdc_value?.display_value}
        </ZxText>
      </CustomRow>
    </div>
  );
};
const PolyMarketStatus = ({ activity }) => {
  const polymarketContext = useContext(PolyMarketFeedContext);
  const { data: polymarketData } = usePolyMarketStatus(
    polymarketContext.conditionIds
  );
  const dataForGivenActivity = polymarketData?.[activity.condition_id];
  if (!dataForGivenActivity) {
    return null;
  }

  const yesLiquidity =
    _.get(dataForGivenActivity, "outcome_detail.Yes.liquidity.display_value") ||
    0;

  const noLiquidity =
    _.get(dataForGivenActivity, "outcome_detail.No.liquidity.display_value") ||
    0;

  const yesPercentage =
    _.get(
      dataForGivenActivity,
      "outcome_detail.Yes.percentage.display_value"
    ) || 0;

  const noPercentage =
    _.get(dataForGivenActivity, "outcome_detail.No.percentage.display_value") ||
    0;

  const yesWidth =
    _.get(dataForGivenActivity, "outcome_detail.Yes.percentage.value") || 0;

  const noWidth =
    _.get(dataForGivenActivity, "outcome_detail.No.percentage.value") || 0;

  return (
    <>
      <CustomRow justifyContent="space-between">
        <CustomColumn alignItems="start" gap="8px">
          <ZxText style={TSTYLES.secondary1} fontSize={10}>
            YES
          </ZxText>
          <CustomRow alignItems="center" gap="4px">
            <ZxText style={TSTYLES.secondaryTitle} fontSize={13} color="text-1">
              {yesLiquidity}
            </ZxText>
            <ZxText style={TSTYLES.secondaryTitle} fontSize={11}>
              {yesPercentage}
            </ZxText>
          </CustomRow>
        </CustomColumn>
        <CustomColumn alignItems="end" gap="8px">
          <ZxText style={TSTYLES.secondary1} fontSize={10} align="right">
            NO
          </ZxText>
          <CustomRow alignItems="center" gap="4px">
            <ZxText
              style={TSTYLES.secondaryTitle}
              fontSize={13}
              align="right"
              color="text-1">
              {noLiquidity}
            </ZxText>
            <ZxText style={TSTYLES.secondaryTitle} fontSize={11} align="right">
              {noPercentage}
            </ZxText>
          </CustomRow>
        </CustomColumn>
      </CustomRow>
      <PaddingComponent height="8px" />
      <CustomRow gap="4px">
        <div
          className={polyActivityClasses.yesLine}
          style={{
            width: yesWidth + "%",
          }}></div>
        <div
          className={polyActivityClasses.noLine}
          style={{
            width: noWidth + "%",
          }}></div>
      </CustomRow>
    </>
  );
};
export const PolyMarketActivity = ({ activity }) => {
  return (
    <div className={polyActivityClasses.container}>
      <CustomRow gap="12px" alignItems="center">
        <div className={polyActivityClasses.icon}>
          <img
            className={polyActivityClasses.activityIcon}
            src={activity.icon}
            alt=""
          />
          <img
            className={polyActivityClasses.chainIcon}
            alt=""
            src="https://d43skd0e52j71.cloudfront.net/images/5de14a74967b4900a10d03fd10ebdb98.png"
          />
        </div>
        <ZxText style={TSTYLES.body1} fontSize={14}>
          {activity.title}
        </ZxText>
      </CustomRow>
      <PolyActivityTrade activity={activity} />
      <PolyMarketStatus activity={activity} />
    </div>
  );
};
export const PolyMarketActivities = ({
  groupedActivity,
  showSingleActivity,
}) => {
  const history = useHistory();
  const polyMarketActivities = groupedActivity?.activities
    ?.flatMap((activity) => _.get(activity, "activity_contexts.polymarket"))
    .filter(Boolean);
  const polyMarketActivityTransfers =
    polyMarketActivities?.flatMap((item) => item.polymarket_trade_orders) || [];
  const displayTransfers = polyMarketActivityTransfers.slice(
    0,
    showSingleActivity ? 1 : Infinity
  );
  if (!displayTransfers || displayTransfers?.length === 0) return null;
  return (
    <CustomColumn gap="16px" margin="0 0 12px 0">
      {displayTransfers &&
        displayTransfers.map((order) => (
          <PolyMarketActivity activity={order} />
        ))}
      {showSingleActivity && polyMarketActivityTransfers.length > 1 && (
        <button
          type="button"
          className={polyActivityClasses.moreButton}
          onClick={() => {
            handleFeedItemClick({
              history,
              contentType: groupedActivity.type,
              contentId: groupedActivity.id,
              link: groupedActivity.link,
            });
          }}>
          +{polyMarketActivityTransfers.length - 1} more{" "}
          {getPlurals(polyMarketActivityTransfers.length - 1, "bet")}
        </button>
      )}
    </CustomColumn>
  );
};
