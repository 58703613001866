import { ProfileTypes } from "../ProfileConstants";
import classes from "./TopBar.module.css";
import FiltersButton from "./Filters/FiltersButton";
import { useContext } from "react";
import { ProfileContext } from "../../../../contextStates/ProfileContext";
import { useParams } from "react-router-dom";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";
import { getDeployedContracts } from "api/profile.api";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import {
  BITCOIN_SUPPORTED_ROUTES,
  COSMOS_SUPPORTED_ROUTES,
  TON_SUPPORTED_ROUTES,
} from "../constants";
import { trackEvent } from "utils/event_tracking";
import { AuthContext } from "contextStates/AuthContext";

const TopBar = ({ identifier, profileType }) => {
  const location = useLocation();

  let { active_tab } = useParams();
  const { identityDetails } = useContext(AuthContext);
  const { profileData } = useContext(ProfileContext);
  const currentStep = active_tab || "Activities";
  const { data } = useQuery({
    queryKey: ["deployed-contracts", identifier],
    queryFn: () => getDeployedContracts({ identifier }),
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });

  let tabsList = [
    {
      title: "Activity",
      link: `/${identifier}/activities${location.search}`,
      _endpoint: "activities",
    },
    {
      title: "Portfolio",
      link: `/${identifier}/portfolio${location.search}`,
      _endpoint: "portfolio",
    },
  ];
  tabsList = tabsList.filter((tab) => {
    if (profileType === ProfileTypes.COSMOS) {
      return COSMOS_SUPPORTED_ROUTES.includes(tab._endpoint);
    }
    if (profileType === ProfileTypes.BITCOIN) {
      return BITCOIN_SUPPORTED_ROUTES.includes(tab._endpoint);
    }
    if (profileType === ProfileTypes.TON) {
      return TON_SUPPORTED_ROUTES.includes(tab._endpoint);
    }
    return true;
  });

  if (profileType === ProfileTypes.IDENTITY) {
    tabsList.push({
      title: "Wallets",
      link: `/${identifier}/wallets${location.search}`,
    });
  }

  if (
    [
      ProfileTypes.ADDRESS,
      ProfileTypes.SOLANA,
      ProfileTypes.COSMOS,
      ProfileTypes.BITCOIN,
      ProfileTypes.TON,
    ].includes(profileType) &&
    profileData.current?.details?.related_identities?.identities?.length > 0
  ) {
    tabsList.push({
      title: "Bundle",
      link: `/${identifier}/bundle`,
    });
  }

  if (data?.contracts_deployed?.length > 0) {
    tabsList.push({
      title: "Deployed Contracts",
      link: `/${identifier}/deployed_contracts`,
    });
  }

  return (
    <CustomLinkTabBar
      tabPadding="8px 0"
      tabGap="36px"
      onClickTrackingEvent={(tab) => {
        trackEvent(`${tab}_tab_clicked`, {
          profileType,
          viewer_identifier: identityDetails.current?.identity?.id,
          profile_identifier: identifier,
        });
      }}
      zIndex={TABBAR_ZINDEX.HIGH}
      currentStep={`/${identifier}/${currentStep}${location.search}`}
      tabsList={tabsList}>
      {profileType !== ProfileTypes.COSMOS && (
        <div className={classes.chain}>
          <FiltersButton identifier={identifier} key={active_tab} />
        </div>
      )}
    </CustomLinkTabBar>
  );
};

export default TopBar;
