import { MinusCircle, CheckCircle } from "@phosphor-icons/react";
import { getOnboardingWalletChecklist } from "api/waitlist.api";
import { PaddingComponent } from "components/UI/Components/Components";
import CustomDivider from "components/UI/CustomComponents/CustomDivider";
import { AuthContext } from "contextStates/AuthContext";
import { useContext, useEffect, useState, useCallback } from "react";
import { getTrimedAddress } from "utils/misc";
import Shimmer from "../../../../shared/GhostLoader/Shimmer/Shimmer";
import { BoldContentParser } from "../NewOnboarding.utils";
import OnboardingBg from "../OnboardingBg";
import styles from "./FailedWallet.module.css";
import DynamicWalletButton from "components/DynamicWalletButton/DynamicWalletButton";
import { addRemoveAddressFromBundle, verifyWalletApi } from "api/profile.api";
import ConditionalLink from "shared/ConditionalLink";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { GlobalContext } from "contextStates/Global";
import { useQuery } from "@tanstack/react-query";
import { getOnboardingData } from "api/waitlist.api";
import { trackEvent } from "utils/event_tracking";
import { useHistory } from "react-router-dom";
import Image, { IMAGE_TYPES } from "components/UI/Image";

const FailedWallet = () => {
  const history = useHistory();

  const [walletChecklist, setWalletChecklist] = useState([]);
  const { identityDetails } = useContext(AuthContext);
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);

  const { data: userData, refetch } = useQuery({
    queryKey: ["user-data-onboarding"],
    queryFn: getOnboardingData,
    enabled: true,
    retryOnMount: false,
    retry: 0,
  });

  useEffect(() => {
    const curStep = userData?.data?.data?.current_step;
    if (curStep == null) {
      return;
    }
    if (curStep === "complete") {
      const id = userData?.data?.data?.user_details?.identity_id;
      history.push(`/${id}/portfolio`);
      return;
    }
  }, [userData, history]);

  const fetchWalletChecklist = useCallback(async () => {
    try {
      const response = await getOnboardingWalletChecklist();
      const data = await response?.data;
      if (data?.status === "ok" && data?.data?.wallet_checklist) {
        setWalletChecklist(data?.data?.wallet_checklist);
      }
    } catch (error) {
      console.error("Error fetching wallet checklist:", error);
    }
  }, [setWalletChecklist]);

  useEffect(() => {
    fetchWalletChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVerifyWallet = async (session) => {
    if (session) {
      try {
        if (userData?.data?.data?.wallets?.includes(session.userAddress)) {
          handleErrorSnackbar(null, "Wallet already exists");
          return;
        }
        await addRemoveAddressFromBundle({
          address: session.userAddress,
          identity_id: identityDetails.current?.identity?.id,
          is_private: false,
          reason: "Onboarding Wallet",
        });
        await verifyWalletApi({
          tokenSerialized: session.tokenSerialized,
          signature: session.tokenSignature,
          walletType: session.walletType,
        });
        handleSuccessSnackbar("Wallet added successfully");
        refetch();
        await fetchWalletChecklist();
        refetch();
        trackEvent("retry_another_wallet_btn_success");
      } catch (err) {
        handleErrorSnackbar(null, "Failed to verify wallet");
        trackEvent("retry_another_wallet_btn_failed");
      }
    }
  };

  const getWalletsCount = () => {
    const count = userData?.data?.data?.wallets?.length;
    if (count > 1) {
      return count - 1;
    }
    return null;
  };

  return (
    <BaseWrapper mixpanelEvent="OnboardingWalletChecklist">
      <OnboardingBg stepperColor={"#E24C4C"}>
        <div className={styles.main}>
          <div className={styles.title}>
            <h1>{`Whoops!`}</h1>
            <h2>{`Try another wallet`}</h2>
          </div>
          <div className={styles.walletInfo}>
            {!identityDetails.current?.identity ? (
              <Shimmer />
            ) : (
              <div className={styles.wallet}>
                <div className={styles.walletProfile}>
                  <Image
                    src={identityDetails.current?.identity?.display_picture}
                    alt="Avatar"
                    className={styles.avatar}
                    imageType={IMAGE_TYPES.AVATAR}
                    name={identityDetails?.current?.identity?.display_name}
                  />

                  <span className={styles.walletName}>
                    {identityDetails.current?.identity?.display_name}
                  </span>
                </div>
                <div>
                  <span className={styles.walletAddress}>
                    {getTrimedAddress(identityDetails.current?.address)}
                  </span>
                  {getWalletsCount() && (
                    <span
                      className={
                        styles.walletCount
                      }>{`+${getWalletsCount()}`}</span>
                  )}
                </div>
              </div>
            )}
          </div>
          <CustomDivider />

          <div className={styles.walletRestrictions}>
            <h3>{`Why can’t you proceed?`}</h3>
            <ul className={styles.points}>
              {walletChecklist.length > 0 ? (
                walletChecklist.map((item, index) => (
                  <li key={index} className={styles.restrictionItem}>
                    <PaddingComponent paddingTop="6px">
                      {item.status ? (
                        <CheckCircle weight="fill" size={20} color="#2CBB6C" />
                      ) : (
                        <MinusCircle weight="fill" size={20} color="#FF5C5C" />
                      )}
                    </PaddingComponent>
                    <div
                      className={
                        !item.status
                          ? styles.contentText
                          : styles.contentTextDone
                      }>
                      <BoldContentParser text={item.label} />
                    </div>
                  </li>
                ))
              ) : (
                <div className={styles.shimmerCont}>
                  <Shimmer />
                </div>
              )}
            </ul>
          </div>

          <div className={styles.retrySection}>
            <DynamicWalletButton
              isLogin={false}
              isOnboarding
              setOpenReplayTagOnConnect={true}
              onSuccess={onVerifyWallet}
              setLocalStorageKeys={false}
              className={styles.retryButtonContainer}
              onClick={() => {
                trackEvent("retry_another_wallet_btn_clicked");
              }}
              customRender={() => {
                return (
                  <button className={styles.retryButton}>
                    Retry with another wallet
                  </button>
                );
              }}
            />
            <ConditionalLink
              to={"/onboarding/talk_to_us"}
              className={styles.talkToUs}>
              Talk to Us
            </ConditionalLink>
          </div>
        </div>
      </OnboardingBg>
    </BaseWrapper>
  );
};

export default FailedWallet;
