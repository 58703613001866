import { useEffect, useRef } from "react";

//default DOMelement is window
const useEventListener = (eventType, callback, DOMelement = window) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const handler = (e) => callbackRef.current(e);

  useEffect(() => {
    if (!DOMelement) return;
    DOMelement.addEventListener(eventType, handler);

    return () => DOMelement.removeEventListener(eventType, handler);
  }, [eventType, DOMelement]);
};

export default useEventListener;
