import { useContext } from "react";
import { getTrimedAddress } from "utils/misc";
import { AuthContext } from "contextStates/AuthContext";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import {
  CursorDiv,
  CustomRow,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import { SignOut } from "@phosphor-icons/react";

const AddressConnectedInfoOnb = ({ visible }) => {
  const { getUserAddress, logout } = useContext(AuthContext);
  const shortAddress = getTrimedAddress(getUserAddress());
  if (!shortAddress || !visible) {
    return null;
  }
  return (
    <PaddingComponent>
      <CustomRow alignItems="center" gap="4px">
        <div
          style={{
            height: "12px",
            width: "12px",
            borderRadius: "4px",
            background: "rgba(7, 157, 57, 1)",
          }}
        />
        <CustomText
          text={shortAddress}
          color="rgba(104, 118, 132, 1)"
          lineHeight="160%"
          fontSize="13px"
        />
        <PPLxTooltip title="Disconnect" arrow>
          <CursorDiv
            onClick={() => {
              logout();
              localStorage.clear();
            }}>
            <SignOut size={16} color="rgba(104, 118, 132, 0.6)" />
          </CursorDiv>
        </PPLxTooltip>
      </CustomRow>
    </PaddingComponent>
  );
};

export default AddressConnectedInfoOnb;
