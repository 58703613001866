import classes from "./WelcomePostsList.module.css";
import { useContext, useCallback } from "react";
import { AuthContext } from "contextStates/AuthContext";
import {
  CustomRow,
  CursorDiv,
  SpinnerComponent,
} from "components/UI/Components/Components";
import { Plus, ChatTeardropDots } from "@phosphor-icons/react";
import CommentButton from "components/ActivityEngagements/CommentButton";
import { InteractionButtonWrapper } from "components/ActivityEngagements/ActivityEngagements";
import { Heart } from "@phosphor-icons/react";
import {
  stopEventPropagation,
  transformGroupedActivityQuery,
} from "utils/misc";
import { useNewCommentMutation } from "api/feed.api";
import { getEngagementsQueryKey } from "components/ActivityEngagements/utils";

import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";

const WelcomePostActions = ({ groupedActivity, gotoNextPost }) => {
  const { identityDetails } = useContext(AuthContext);
  let suggestions = groupedActivity?.welcome_message_suggestions;
  const showSuggestions = groupedActivity?.show_suggestions;
  if (!showSuggestions) {
    return null;
  }
  if (suggestions.length > 2) {
    suggestions = suggestions.slice(0, 2);
  }

  return (
    <CustomRow
      alignItems="center"
      gap="6px"
      className={classes.newCommentWrapper}>
      <InteractionButtonWrapper // like button
        contentId={groupedActivity.id}
        engagements={groupedActivity?.engagements?.likes}
        onSuccess={(data) => {
          gotoNextPost?.(data);
        }}
        hideCount={true}
        icon={<Heart size={20} color="var(--text-2)" />}
        type={"likes"}
        contentType={groupedActivity.type}
        currentIdentityId={identityDetails?.current?.identity?.id}
        needsLogin={true}
        hasRoundedButtons={false}
        queryKey={getEngagementsQueryKey(
          groupedActivity.type,
          groupedActivity.id
        )}
      />
      <CustomRow gap="6px" className={classes.commentsWrapper}>
        {suggestions?.map((comment, index) => {
          return (
            <PostGeneratedComment
              key={index}
              comment={comment}
              contentId={groupedActivity.id}
              contentType={groupedActivity.type}
              onSuccess={gotoNextPost}></PostGeneratedComment>
          );
        })}
        <CommentButton
          contentId={groupedActivity.id}
          contentType={groupedActivity.type}
          onSuccess={(data) => gotoNextPost?.(data)}>
          <CursorDiv>
            <div className={classes.addCommentButton}>
              <Plus size={12} color="var(--text-3)" />
            </div>
          </CursorDiv>
        </CommentButton>
      </CustomRow>
    </CustomRow>
  );
};

export const PostGeneratedComment = ({
  contentId,
  contentType,
  comment,
  onSuccess,
}) => {
  const { isUserLoggedIn, setShowLoginPopup } = useContext(AuthContext);
  const { mutate: newCommentMutation, isPending: isLoading } =
    useNewCommentMutation({
      setIsSuccess: () => {},
      setIsError: () => {},
      contentId,
      type: contentType,
      queryKeys: [transformGroupedActivityQuery(contentId, contentType)],
    });

  let shorterComment = comment;
  if (shorterComment.length > 32) {
    shorterComment = comment.slice(0, 32) + "...";
  }

  const onClick = useCallback(() => {
    if (!isUserLoggedIn) {
      setShowLoginPopup(true);
      return;
    }
    onSuccess?.();
    newCommentMutation({
      urls: [],
      contents: comment,
      id: contentId,
      images: [],
    });
  }, [
    comment,
    contentId,
    newCommentMutation,
    isUserLoggedIn,
    setShowLoginPopup,
    onSuccess,
  ]);
  return (
    <div
      className={classes.generatedCommentContainer}
      gap="4px"
      role="button"
      onClick={(e) => {
        stopEventPropagation(e);
        onClick(e);
      }}>
      {isLoading && <SpinnerComponent color="var(--text-1)" size={13} />}
      <ChatTeardropDots size={16} color="var(--text-2)" />
      <ZxText
        style={TSTYLES.body1}
        color="text-2"
        wrapper="span"
        width="max-content"
        cursor="pointer">
        {shorterComment}
      </ZxText>
    </div>
  );
};

export default WelcomePostActions;
