import PnlToken from "./PnlToken";
import classes from "./PnlTokenList.module.css";
import { useCallback, useEffect, useState } from "react";

import ConditionalLink from "shared/ConditionalLink";
import { getPlurals } from "utils/misc";
import withNullableError from "zxComponents/common/withNullableError/index";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { useQuery } from "@tanstack/react-query";
import { QueryKeyConstants } from "utils/constants";
import { PaddingComponent } from "components/UI/Components/Components";

const LIST_SIZE = 3;

export const getPnlPercentForVal = (val) => {
  const positiveBuckets = [50, 100, 200];
  const negativeBuckets = [-100, -50];
  if (val >= 0) {
    const index = Math.floor(Math.random() * positiveBuckets.length);
    return positiveBuckets[index];
  }
  return negativeBuckets[Math.floor(Math.random()) * negativeBuckets.length];
};

const PnlTokenList = ({
  groupedActivity,
  setShowPnlComments,
  setCombinedPnlPercentage,
  profiles = {},
  minHeight = "0px",
  link = null,
  listSize = LIST_SIZE,
  newSchema = false,
  showExtra = true,
  isHomePage = false,
  showFullLengthName = false,
}) => {
  const { data: slowItem } = useQuery({
    queryKey: [QueryKeyConstants.SLOW_FEED_ITEM, groupedActivity?.blob_id],
    queryFn: () => {},
    enabled: false,
  });
  const tokenProfiles = isHomePage
    ? groupedActivity?.user_token_pnl?.length > 0
      ? groupedActivity?.profiles
      : slowItem?.profiles
    : groupedActivity?.profiles;
  const [tradeList, setTradeList] = useState([]);
  const getPnlList = useCallback(() => {
    if (isHomePage && !slowItem && !groupedActivity?.user_token_pnl?.length)
      return [];
    if (!isHomePage && !groupedActivity) return [];
    if (!isHomePage && groupedActivity?.user_token_pnl?.length > 0)
      return groupedActivity?.user_token_pnl ?? [];
    if (
      isHomePage &&
      (slowItem?.user_token_pnl?.length > 0 ||
        groupedActivity?.user_token_pnl?.length > 0)
    )
      return slowItem?.user_token_pnl ?? groupedActivity?.user_token_pnl ?? [];
    return [];
  }, [groupedActivity, slowItem, isHomePage]);
  useEffect(() => {
    const pnlList = getPnlList();
    if (pnlList?.length > 0) {
      let finalVal = 0;
      const temp = [];
      pnlList?.forEach((item) => {
        const profile = tokenProfiles?.[item.token_address];
        const openVal = item.open_pnl?.value ?? 0;
        const closedVal = item.closed_pnl?.value ?? 0;
        const val = openVal + closedVal;
        finalVal += val;
        if (item.open_pnl?.value) {
          temp.push({
            ...item,
            pnl: item.open_pnl,
            pnlPercent: item.open_pnl_percent,
            profile,
            isOpen: true,
          });
        }
        if (item.closed_pnl?.value) {
          temp.push({
            ...item,
            pnl: item.closed_pnl,
            pnlPercent: item.closed_pnl_percent,
            profile,
            isOpen: false,
          });
        }
      });
      setCombinedPnlPercentage(getPnlPercentForVal(finalVal));
      setShowPnlComments(true);
      setTradeList(temp);
    }
  }, [
    setCombinedPnlPercentage,
    setShowPnlComments,
    tokenProfiles,
    slowItem,
    isHomePage,
    getPnlList,
  ]);
  if (tradeList?.length > 0) {
    const showRest = tradeList.length - listSize > 0;
    return (
      <div className={classes.pnlList} style={{ minHeight }}>
        {tradeList.slice(0, listSize).map((item, index) => {
          const profile = tokenProfiles?.[item.token_address];
          return (
            <ConditionalLink to={link} key={index}>
              <PnlToken
                key={index}
                profile={profile}
                newSchema={newSchema}
                pnl={item.pnl}
                pnlPercent={item.pnlPercent}
                isOpen={item.isOpen}
                showFullLengthName={showFullLengthName}
              />
            </ConditionalLink>
          );
        })}
        {showRest && showExtra && (
          <div className={classes.pnlListMore}>
            <ZxText style={TSTYLES.bodyLabel} color="primary" cursor="pointer">
              +{tradeList.length - listSize}&nbsp;more{" "}
              {getPlurals(tradeList.length - listSize, "trade")}
            </ZxText>
          </div>
        )}
      </div>
    );
  }
  return <PaddingComponent height={minHeight} />;
};

export default withNullableError(PnlTokenList);
