import styles from "./header.module.css";
import { useHistory } from "react-router-dom";
import Theme from "./Theme";
import SearchBar from "./components/SearchBar";
import { useContext, useEffect } from "react";
import { CaretLeft } from "@phosphor-icons/react";
import { useState, useRef } from "react";
import { GlobalContext } from "contextStates/Global";

import Avatar from "./Avatar";
import DevToolsButton from "components/V2/DevTools/DevToolsButton";
import { NoBackButtonPaths } from "utils/constants";
import { trackEvent } from "utils/event_tracking";
import AppDownloadButton from "components/V2/Onboarding/components/AppDownloadButton";
import { ThemeContext } from "contextStates/Theme.context";
import DynamicWalletProvider from "components/DynamicWalletProvider/DynamicWalletProvider";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";

const Header = () => {
  const { ribbonData, windowWidth } = useContext(GlobalContext);
  const { theme } = useContext(ThemeContext);
  const [isChanged, setIsChanged] = useState(false);
  const history = useHistory();
  var historyLocationKey = useRef(null);
  const [routeStackCount, setRouteStackCount] = useState(0);
  const { isLeftSideBarOpen } = useContext(LeftSideHeaderContext);
  useEffect(() => {
    if (historyLocationKey.current == null) {
      historyLocationKey.current = history.location.key;
      if (history.location.state?.isChanged === true) {
        setIsChanged(true);
      }
    }
  }, [history.location.key, history.location.state?.isChanged]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      trackEvent(`NAVIGATION`, {
        action: action,
        path: location.pathname,
      });
      if (action === "PUSH" || action === "REPLACE") {
        setRouteStackCount((prev) => prev + 1);
      } else if (action === "POP") {
        setRouteStackCount((prev) => prev - 1);
      }
    });
    return unlisten;
  }, [history, isChanged]);

  const canGoBack = !NoBackButtonPaths.includes(history.location.pathname);

  const goBack = () => {
    if (!canGoBack) {
      return;
    }
    if (routeStackCount > 0 && history.length > 1) {
      history.goBack();
    } else {
      history.replace("/home");
    }
  };

  return (
    <DynamicWalletProvider>
      <div
        className={`${styles.header} ${!isLeftSideBarOpen ? styles.biggerSearch : ""}`}
        style={{
          background: theme === "dark" ? "transparent" : "var(--base)",
        }}>
        <div className={styles.backAndHeaderContent}>
          <div className={styles.header_content}>
            <div className={styles.searchContainer}>
              {windowWidth > 768 && <SearchBar />}
            </div>
            <div className={styles.right_bar}>
              <AppDownloadButton />
              <DevToolsButton />
              <Theme className={styles.theme} />
              <Avatar />
            </div>
          </div>
        </div>
        <div className={styles.backButton} onClick={goBack}>
          {canGoBack && (
            <CaretLeft
              size={24}
              color="var(--text-1)"
              className={styles.arrow_back_icon}
            />
          )}
        </div>
        {windowWidth < 768 && (
          <SearchBar noPlaceholderAnimation={windowWidth < 425} />
        )}
        {ribbonData && (
          <div className={styles.ribbon_content}>{ribbonData}</div>
        )}
      </div>
    </DynamicWalletProvider>
  );
};

export default Header;
