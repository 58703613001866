import { Confidence } from "components/UI/Confidence/Confidence";
import styles from "./BundleSuggestion.module.css";
import FeedDescription from "../FeedDescription";
import {
  CustomColumn,
  CustomRow,
  PaddingComponent,
  Divider,
  FlexGrow,
  RedirectLink,
  InfoBox,
  CursorDiv,
  CustomCircularProgress,
} from "components/UI/Components/Components";
import { IconTextButton } from "components/UI/Components/IconTextButton";
import BundleButton from "./BundleButton";
import { signedApiRequest } from "api/api";
import { useContext, useState } from "react";
import { GlobalContext } from "contextStates/Global";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FollowEditButton from "components/FollowEditButton/FollowEditButton";

const BundleSuggestionHoverCard = ({
  relatedData,
  profiles,
  methodIds,
  isAdminView,
  actor,
  nativeTokensInfo,
  isBoundaryBox = false,
  showSubHeading = true,
  showConfidence = true,
  showViewTransfers = true,
  title = "Secondary Wallet Suggestion",
  disableDiscard = false,
  padding = "12px 16px",
  showActions = false,
  refetchGroupedActivity,
  fetchGraph,
  source,
  target,
}) => {
  const [discarding, setDiscarding] = useState(false);
  const [show, setShow] = useState(true);
  const [identityId, setIdentityId] = useState(false);
  const [triggerBundle, setTriggerBundle] = useState(false);
  const { handleErrorSnackbar, setSnackBarState } = useContext(GlobalContext);

  if (!show) {
    return null;
  }

  const onBundle = () => {
    if (refetchGroupedActivity) {
      refetchGroupedActivity();
    }
    if (fetchGraph) {
      fetchGraph();
    }
    setShow(false);
  };

  const onDiscard = () => {
    setDiscarding(true);
    const data = {
      address_a: actor.address,
      address_b: relatedData.address,
    };

    signedApiRequest({
      path: "discard_wallet_relationship",
      method: "post",
      bodyText: JSON.stringify(data),
    })
      .then((data) => {
        setShow(false);

        setSnackBarState({
          open: true,
          message: "Discarded",
          type: "success",
        });
      })
      .catch((error) => {
        setDiscarding(false);

        handleErrorSnackbar(error, "Failed to Bundle");
      });
  };
  const followAndBundle = (data) => {
    setIdentityId(data?.id);
    setTriggerBundle(true);
  };

  return (
    <div className={styles.hoverCard}>
      <InfoBox
        padding={padding}
        background="auto"
        borderWidth={isBoundaryBox ? "1px" : "0px"}>
        <CustomColumn>
          <CustomRow alignItems="center">
            <FlexGrow>
              <CustomColumn>
                <span className={styles.title}>{title}</span>
                <PaddingComponent height="4px" />
                {showConfidence && (
                  <div
                    className={`${styles.confidence_box} ${styles.confidence_border}`}>
                    <Confidence
                      confidence={relatedData?.confidence}
                      withText={true}
                    />
                  </div>
                )}
              </CustomColumn>
            </FlexGrow>
            {(actor?.identity_id || actor?.id) && showActions && (
              <CustomRow gap="16px">
                <PaddingComponent width="8px" />
                <div className={styles.verticalDivider} />
                <BundleButton
                  identityId={actor.id || actor.identity_id}
                  address={relatedData.address}
                  onSuccess={onBundle}
                />
                {!disableDiscard && <div className={styles.verticalDivider} />}
              </CustomRow>
            )}
            {!source?.identity_id &&
              !source?.id &&
              source?.address &&
              !target?.identity_id &&
              !target?.id &&
              target?.address &&
              showActions && (
                <CustomRow gap="16px">
                  <PaddingComponent width="8px" />
                  <div className={styles.verticalDivider} />

                  <FollowEditButton
                    name={actor?.display_name}
                    bio={actor?.bio}
                    twitter={actor?.socials?.twitter}
                    socials={actor?.socials}
                    showEdit={false}
                    onUpdateCallback={followAndBundle}
                    address={actor?.address}
                    identityId={actor?.id}>
                    <BundleButton
                      identityId={actor.id || actor.identity_id || identityId}
                      triggerBundle={triggerBundle}
                      address={relatedData.address}
                      onSuccess={onBundle}
                    />
                  </FollowEditButton>
                  {!disableDiscard && (
                    <div className={styles.verticalDivider} />
                  )}
                </CustomRow>
              )}
            {!disableDiscard && (
              <>
                <PaddingComponent width="16px" />
                {discarding ? (
                  <CustomCircularProgress />
                ) : (actor?.identity_id || actor?.id || actor?.address) &&
                  showActions ? (
                  <CursorDiv
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (discarding) return;
                      onDiscard();
                    }}>
                    <Box
                      component={CloseIcon}
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "18px",
                        color: "var(--text-2)",
                      }}
                    />
                  </CursorDiv>
                ) : null}
              </>
            )}
          </CustomRow>
          {!!relatedData?.relationship_summary?.length && (
            <>
              <PaddingComponent height="10px" />
              <Divider />
              <PaddingComponent height="10px" />
              {showSubHeading && <span className={styles.text}>Reasons:</span>}
              <PaddingComponent height="0px" />
              <ul className={styles.list}>
                {relatedData.relationship_summary.map((text, index) => {
                  return (
                    <li className={styles.list_item} key={index}>
                      <div className={styles.dot}>•</div>
                      <FeedDescription
                        fontSize="13px"
                        templateString={text}
                        profiles={profiles}
                        methodIds={methodIds}
                        isAdminView={isAdminView}
                        actor={actor}
                        nativeTokensInfo={nativeTokensInfo}
                      />
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          {showViewTransfers && (
            <RedirectLink
              link={`/explorer?identifier=${
                relatedData?.related_with_identity_id ||
                relatedData?.related_with
              },${relatedData?.address}`}>
              <IconTextButton text="View Transfers" />
            </RedirectLink>
          )}
        </CustomColumn>
      </InfoBox>
    </div>
  );
};

export default BundleSuggestionHoverCard;
