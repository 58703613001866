import { CustomColumn, CustomRow } from "components/UI/Components/Components";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import classes from "components/Pages/AppModule/RightSideBar/RightBar.module.css";
import { getTrimmedString } from "utils/misc";
import * as PropTypes from "prop-types";
import ZxText from "zxComponents/common/ZxText";
import React from "react";
import { TSTYLES } from "zxStyles/constants";
import { IMAGE_TYPES } from "components/UI/Image";

export function HoldingsIcon(props) {
  return (
    <CustomRow
      gap="4px"
      alignItems="center"
      overflowX="visible"
      overflowY="visible">
      <IconWithChain
        src={props.src}
        chain={props.chain}
        iconClassName={`${classes.holdingsIcon} ${
          props.type === "NFT" ? classes.holdingsIconNFT : ""
        }`}
        showImageFullHeightWidth
        iconHeight="24px"
        iconWidth="24px"
        chainHeight="10px"
        chainWidth="10px"
        chainRightDistance="-4px"
        imageType={props.imageType ?? IMAGE_TYPES.TOKEN}
      />
      <CustomColumn gap="4px">
        <CustomRow>
          <ZxText style={TSTYLES.title2} fontSize={12}>
            {getTrimmedString(props.str, 15)}
          </ZxText>
        </CustomRow>
        <ZxText>{props.amount} </ZxText>
      </CustomColumn>
    </CustomRow>
  );
}

HoldingsIcon.propTypes = {
  src: PropTypes.any,
  chain: PropTypes.any,
  type: PropTypes.any,
  str: PropTypes.any,
};
