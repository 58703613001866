import { signedRequest } from "./api";
import { ChannelTypes } from "../components/V2/Messaging/helpers";

export const generateGroupId = async () => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/generate_group_id",
  });

  return response.data;
};

export const generateDMId = async ({ otherIdentity }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/generate_dm_id",
    bodyText: JSON.stringify({ other_identity: otherIdentity }),
  });

  return response.data;
};

export const getGroupDetails = async ({ groupId }) => {
  const response = await signedRequest({
    method: "post",
    path: `/api/0xppl/get_group_ids_details`,
    bodyText: JSON.stringify({ group_ids: [groupId] }),
  });

  return response.data.data;
};

export const upsertGroupDetails = async ({ groupId, name, image }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/upsert_group_details_view",
    bodyText: JSON.stringify({
      group_id: groupId,
      name: name,
      display_picture: image,
    }),
  });

  return response.data.data;
};

export const blockUser = async ({ groupId, userId }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/hard_ban_member_from_chat",
    bodyText: JSON.stringify({ group_id: groupId, user: parseInt(userId) }),
  });

  return response.data;
};

export const unBlockUser = async ({ groupId, userId }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/unban_member_from_chat",
    bodyText: JSON.stringify({ group_id: groupId, user: parseInt(userId) }),
  });

  return response.data;
};

export const makeGroupModerator = async ({ groupId, userId }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/make_group_moderator",
    bodyText: JSON.stringify({ group_id: groupId, user: userId }),
  });

  return response.data;
};

export const makeGroupMember = async ({ groupId, userId }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/make_group_member",
    bodyText: JSON.stringify({ group_id: groupId, user: userId }),
  });

  return response.data;
};

export const isInviteNeededForUser = async ({ userId }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/is_invite_needed_for_user",
    bodyText: JSON.stringify({ user: userId }),
  });

  return response.data?.data;
};

export const addMembersToGroup = async ({ groupId, members, groupType }) => {
  if (!groupId || !members || !members.length || !groupType) {
    throw new Error("groupId and members are required");
  }
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/add_members_to_group",
    bodyText: JSON.stringify({
      group_id: groupId,
      type:
        groupType === ChannelTypes.STREAMING_GROUP ? "streaming" : "message",
      member_ids: members.map((m) => {
        const split = m.split("-");
        return parseInt(split[split.length - 1]);
      }),
    }),
  });
  return response.data?.data;
};

export const removeMembersFromGroup = async ({ groupId, members }) => {
  if (!groupId || !members || !members.length) {
    throw new Error("groupId and members are required");
  }
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/remove_members_from_group",
    bodyText: JSON.stringify({
      group_id: groupId,
      type: "streaming",
      member_ids: members.map((m) => {
        const split = m.split("-");
        return parseInt(split[split.length - 1]);
      }),
    }),
  });
  return response.data?.data;
};

export const addWalletsToGroup = async ({ groupId, wallets, identity }) => {
  if (!groupId) {
    throw new Error("groupId is required");
  }
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/add_wallet_to_group",
    bodyText: JSON.stringify({
      group_id: groupId,
      user: identity,
      wallets: [...wallets],
    }),
  });
  return response.data?.data;
};

export const getGroupInfo = async ({ groupId }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/get_group_info",
    bodyText: JSON.stringify({ group_id: groupId }),
  });
  return response.data?.data;
};

export const inviteMembersToGroup = async ({
  groupId,
  members,
  identity_id,
  created_at,
}) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/invite_member_to_group",
    bodyText: JSON.stringify({
      group_id: groupId,
      receiver_ids: members.map((m) => {
        const split = m.split("-");
        return parseInt(split[split.length - 1]);
      }),
      inviter_id: "" + identity_id,
      created_at: Math.round(new Date(created_at).getTime() / 1000),
    }),
  });
  return response.data?.data;
};

export const removePendingMembersFromGroup = async ({ groupId, members }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/0xppl/remove_pending_member",
    bodyText: JSON.stringify({
      group_id: groupId,
      member_ids: members.map((m) => {
        const split = m.split("-");
        return parseInt(split[split.length - 1]);
      }),
    }),
  });
  return response.data?.data;
};
