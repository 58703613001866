import React, { useContext, useState } from "react";
import { Modal } from "@mui/material";

import classes from "./NetworthChartModal.module.css";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  PaddingComponent,
  PriceChange,
  SpinnerComponent,
} from "components/UI/Components/Components";
import { getCssVariable } from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";
import { ArrowUpRight, X } from "@phosphor-icons/react";
import {
  Chart,
  DEFAULT_NETWORTH_PREFERENCES,
  NETWORTH_PREFERENCES,
} from "utils/constants";
import {
  getNetworthGraphData,
  updateNetWorthPreferences,
} from "api/profile.api";
import { useQuery } from "@tanstack/react-query";
import EmptyStateUI from "components/ErrorBoundary/EmptyStateUI";
import { QUERY_KEYS } from "utils/query_utils";
import Graph from "../ProfileDetails/Portfolio/ProfileGraphs/Graph";
import CustomDropdownButton from "components/UI/Components/CustomDropDown";
import GraphLoader from "../ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";
import useTokenHoldings from "../ProfileDetails/Portfolio/TokenHoldings/useTokenHoldings";
import useFetchProtocolHoldings from "customHooks/fetch/useFetchProtocolHoldings";
import useProfileNftHoldings from "../ProfileDetails/NFTHoldings/useProfileNftHoldings";
import { Checkbox } from "../../../../../node_modules/@mui/material/index";
import { ProfileContext } from "contextStates/ProfileContext";
import { useBundleInfo } from "components/Pages/AppModule/RightSideBar/apis/useBundleInfo";
import { AuthContext } from "contextStates/AuthContext";
import { GlobalContext } from "contextStates/Global";
import withNullableError from "zxComponents/common/withNullableError/index";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import GenericButton from "components/V2/GenericButton/GenericButton";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
const excludedDurations = [
  Chart.DURATION["3M"],
  Chart.DURATION["1Y"],
  Chart.DURATION["ALL"],
];
const NetworthChartModal = ({
  netWorth,
  isOpen,
  toggle,
  showLink = false,
  identifier,
  initialData = [],
  refetchNetWorth,
}) => {
  const [duration, setDuration] = useState(Chart.DURATION["7D"]);
  const { identityDetails } = useContext(AuthContext);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const { profileData } = useContext(ProfileContext);
  const [isPreferenceLoading, setIsPreferenceLoading] = useState(false);
  const preferences =
    profileData?.current?.details?.net_worth_preferences ?? [];
  const { data: tokenHoldingsData } = useTokenHoldings({
    identifier: identityDetails.current?.identity?.username ?? null,
    forProtocolTreasury: false,
    enabled: false,
  });
  const [isNftIncluded, setIsNftIncluded] = useState(
    preferences.includes(NETWORTH_PREFERENCES.NFT)
  );

  const { data: protocolHoldings } = useFetchProtocolHoldings({
    identifier: identifier,
    forProtocolTreasury: false,
    enabled: false,
  });
  const { data: nftHoldings } = useProfileNftHoldings({
    identifier,
    selectedGroupIds: [],
    enabled: false,
  });
  const {
    data: graphData,
    isLoading,
    isFetching,
    isError,
    refetch: refetchGraphData,
  } = useQuery({
    queryKey: QUERY_KEYS.NETWORTH_GRAPH({
      identity: identifier,
      duration: Chart.DURATION[duration],
    }),
    queryFn: () =>
      getNetworthGraphData({
        identifier,
        duration: Chart.DURATION_DAYS[duration],
      }),
    retry: 2,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const { refetch: refetchBundles } = useBundleInfo({
    identifier,
    enabled: false,
  });
  const updatePreference = async (addNft) => {
    setIsPreferenceLoading(true);
    try {
      const sources = [
        ...DEFAULT_NETWORTH_PREFERENCES,
        ...(addNft ? [NETWORTH_PREFERENCES.NFT] : []),
      ];
      await updateNetWorthPreferences({
        sources,
        identifier: identityDetails.current?.identity?.id,
      });
      refetchNetWorth();
      refetchGraphData();
      refetchBundles();
    } catch (err) {
      console.log({ err });
      handleErrorSnackbar(new Error("Failed to update preferences"));
    } finally {
      setIsPreferenceLoading(false);
    }
  };
  return (
    <Modal open={isOpen} onClose={toggle}>
      <div className={classes.modalContainer}>
        <PaddingComponent className={classes.modalHeader} padding="16px 20px">
          <CustomRow justifyContent="space-between" alignItems="center">
            <CustomText
              fontWeight="600"
              fontSize="17px"
              lineHeight="26px"
              letterSpacing="-0.02em"
              text="Networth"
              color="var(--text-1)"
            />
            <X
              color={getCssVariable("--text-1")}
              onClick={toggle}
              size={16}
              style={{ cursor: "pointer" }}
            />
          </CustomRow>
        </PaddingComponent>
        <Divider />
        <PaddingComponent className={classes.modalPrice} padding="16px 20px">
          <CustomRow
            gap="8px"
            width="100%"
            justifyContent="space-between"
            alignItems="center">
            <CustomColumn gap="6px" alignItems="flex-start" width="100%">
              <CustomText
                text="TOTAL NETWORTH"
                color="var(--text-2)"
                fontSize="12px"
                lineHeight="16px"
                letterSpacing="0.08em"
                fontWeight="500"
              />
              <CustomRow gap="16px">
                <CustomText
                  text={netWorth?.total_usd_value?.display_value}
                  color="var(--text-1)"
                  fontWeight="600"
                  fontSize="28px"
                  lineHeight="30px"
                />
                {!isFetching && graphData ? (
                  <PriceChange
                    value={graphData?.total_percent_change?.value}
                    displayValue={
                      graphData?.total_percent_change?.display_value
                    }
                  />
                ) : (
                  <SpinnerComponent size={14} color="var(--primary-color)" />
                )}

                <div className={classes.categories}>
                  {Object.entries(NETWORTH_PREFERENCES).map(([key, value]) => {
                    const isDefault =
                      DEFAULT_NETWORTH_PREFERENCES.includes(value);
                    const isNft = value === NETWORTH_PREFERENCES.NFT;
                    const isToken = value === NETWORTH_PREFERENCES.Token;
                    const displayText = isNft
                      ? (nftHoldings?.total_usd_value?.display_value ??
                        nftHoldings?.total_usd_value ??
                        "~$0")
                      : isToken
                        ? tokenHoldingsData?.total_usd_value
                        : protocolHoldings?.total_usd_value?.display_value;
                    return (
                      <div key={value} className={classes.category}>
                        <div className={classes.category_title}>
                          <Checkbox
                            checked={isNft ? isNftIncluded : isDefault}
                            onChange={(e) => {
                              if (isNft) {
                                setIsNftIncluded(e.target.checked);
                                updatePreference(e.target.checked);
                              }
                            }}
                            size="small"
                            defaultChecked={isDefault}
                            classes={{
                              root: classes.filterCheckbox,
                            }}
                            disabled={isDefault || isLoading}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                marginRight: "2px",
                                padding: "0px",
                              },
                              "& .MuiTypography-root": { fontSize: "14px" },
                              "& .Mui-checked": {
                                color: "var(--primary-color)",
                              },
                            }}
                          />
                        </div>
                        <div className={classes.category_value}>
                          {key}
                          <CustomText
                            text={displayText}
                            color="var(--text-1)"
                            fontSize="12px"
                            fontWeight="500"
                            lineHeight="17px"
                            letterSpacing="-0.2px"
                          />
                          {isPreferenceLoading && isNft && (
                            <SpinnerComponent
                              size={14}
                              color="var(--primary-color)"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </CustomRow>
            </CustomColumn>
            {showLink && (
              <ZxFlex>
                <ConditionalLink to={`/${identifier}/portfolio`}>
                  <GenericButton padding="12px" borderRadius="20px">
                    <ZxFlex>
                      <ArrowUpRight
                        color="var(--text-white)"
                        size={16}
                        weight="bold"
                      />
                      <ZxText color="white" style={TSTYLES.bodyLabel}>
                        View Portfolio
                      </ZxText>
                    </ZxFlex>
                  </GenericButton>
                </ConditionalLink>
              </ZxFlex>
            )}
          </CustomRow>
        </PaddingComponent>
        <Divider />
        <PaddingComponent
          padding="8px 16px 0 16px"
          className={classes.graphContainer}>
          <CustomDropdownButton
            listPositionClass={classes.dropdownListPosition}
            title={duration}
            items={Object.values(Chart.DURATION).filter(
              (item) => !excludedDurations.includes(item)
            )}
            customClass={classes.dropdownButton}
            customClassList={classes.dropdownList}
            color={"var(--text-2)"}
            onSelectItem={(item, _, e) => {
              e?.stopPropagation();
              setDuration(item);
            }}
          />
          <PaddingComponent height="12px" />
          {!isError ? (
            isLoading ? (
              <GraphLoader height={500} />
            ) : (
              <Graph
                graphData={graphData}
                margin={{ left: 0, right: 0, bottom: 0, top: 0 }}
                parentWidth={800}
                parentHeight={400}
                isXAxisVisible={true}
                isYAxisVisible={true}
                horizontalGridLines={0}
                verticalGridLines={0}
                gradientFill={true}
                hideHover={false}
              />
            )
          ) : (
            <EmptyStateUI text="Chart not available" />
          )}
        </PaddingComponent>
      </div>
    </Modal>
  );
};

export default withNullableError(NetworthChartModal);
