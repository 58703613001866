import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableTitle,
} from "components/UI/Table/Table";
import { TokenBalance, TokenInfoWrapper } from "./TokenInfoWrapper";
import classes from "./lendingHoldingDetails.module.css";
import holdingClasses from "./holdingDetails.module.css";
import { CustomText } from "components/UI/Components/Components";
import { getTrimedAddress } from "utils/misc";
import { Rewards } from "./V3LiquidityProviderDetails";

export const LendingTokenInfo = ({ lendingItem }) => {
  return (
    <TokenInfoWrapper
      token_url={lendingItem?.token_url}
      token_icon={lendingItem?.token_icon}
      info={<TokenBalance balance={lendingItem.balance} />}
    />
  );
};
export const LendingHoldingDetails = ({ label, address, value }) => {
  const hasSupplied = value?.holdings?.supplied?.length > 0;
  const hasBorrowed = value?.holdings?.borrowed?.length > 0;
  return (
    <>
      <div className={holdingClasses.expandedProtocol}>
        <LendingTitle
          title={label}
          address={address}
          healthFactor={value?.holdings?.health_factor}
          totalUsdValue={value?.total_usd_value?.display_value}
        />
        {hasSupplied && (
          <LendingDetailsTable
            type="supplied"
            data={value.holdings.supplied}
            rewards={value.rewards}
          />
        )}
        {hasBorrowed && (
          <LendingDetailsTable
            type="borrowed"
            data={value.holdings.borrowed}
            rewards={value.holdings.rewards}
          />
        )}
      </div>
    </>
  );
};

export const LendingDetailsTable = ({ type, data, rewards }) => {
  if (data.length === 0) return null;
  return (
    <Table>
      <TableHeader>
        <TableCell alignLeft>{type}</TableCell>
        <TableCell alignRight>APY</TableCell>
        <TableCell alignRight style={{ flexBasis: "35%" }}>
          Rewards
        </TableCell>

        <TableCell alignRight style={{ flexBasis: "20%" }}>
          Invested
        </TableCell>

        <TableCell alignRight>Value</TableCell>
      </TableHeader>
      {data.map((lendingItem, index) => (
        <TableRow key={`lending-protocol-${index}`}>
          <TableCell alignLeft>
            <LendingTokenInfo lendingItem={lendingItem} />
          </TableCell>
          <TableCell alignRight>
            <LendingAPY apy={lendingItem?.apy} type={type} />
          </TableCell>

          <TableCell alignRight style={{ flexBasis: "35%" }}>
            <Rewards rewards={rewards} />
          </TableCell>

          <TableCell alignRight style={{ flexBasis: "20%" }}>
            {lendingItem?.usd_value_invested?.display_value ?? "-"}
          </TableCell>

          <TableCell alignRight>
            {lendingItem?.usd_value?.display_value}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

const LendingTitle = ({ title, address, healthFactor, totalUsdValue }) => {
  return (
    <TableTitle
      title={title + (address ? " (" + getTrimedAddress(address) + ")" : "")}
      noPadding
      subTitle={
        <>
          {!!healthFactor && (
            <div className={classes.healthFactor}>
              Health Factor: {healthFactor?.display_value}
            </div>
          )}
          <div className={classes.totalUsdValue}>
            Total Value: {totalUsdValue}
          </div>
        </>
      }></TableTitle>
  );
};
const LendingAPY = ({ apy, type }) => {
  if (!apy || apy.value === 0) return <MissingLendingAPY />;

  let direction = "";
  if (apy.value > 0) direction = "positiveApy";
  else direction = "negativeApy";

  return (
    <div className={`${classes.apy} ${classes[direction]} ${classes[type]}`}>
      {apy?.display_value ?? "-"}
    </div>
  );
};

const MissingLendingAPY = () => {
  return <CustomText color="var(--text-2)" text="-" />;
};

export default LendingHoldingDetails;
