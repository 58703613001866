import React from "react";
import {
  RenderActivities,
  NftTransfers,
} from "../GroupFeedItem/GroupFeedItem.components";
import classes from "./groupedActivityWrapper.module.css";

const RenderFallbackActivities = ({
  groupedActivity,
  isAdminView,
  showOpenInNewTab,
  adminOptions,
  hideBundleSuggestions = false,
  showGallery,
  type,
  maxNftsCount,
  isRepost = false,
}) => {
  return (
    <div className={classes.fallbackActivitiesContainer}>
      <RenderActivities
        key={groupedActivity.id}
        showNftImages={false}
        showGallery={false}
        activities={groupedActivity.activities}
        profiles={groupedActivity.profiles}
        identities={groupedActivity.identities}
        methodIds={groupedActivity.method_ids}
        isAdminView={isAdminView}
        showOpenInNewTab={showOpenInNewTab}
        adminOptions={adminOptions}
        groupedActivity={groupedActivity}
        hideBundleSuggestions={hideBundleSuggestions}
        mainProtocolData={groupedActivity?.protocols}
        type={type}
      />
      <NftTransfers
        showNftsFromTransactionInGallery
        groupedActivity={groupedActivity}
        onClick={(e) => e.stopPropagation()}
        showGallery={showGallery}
        maxNftsCount={maxNftsCount}
        isRepost={isRepost}
      />
    </div>
  );
};

export default RenderFallbackActivities;
