import { getPerpTradesList } from "components/FeedItemV2/PerpGraphDetails";
import React, { useEffect, useState } from "react";
import ZxGmxItem from "./ZxGmxItem";

const hasGmx = (groupedActivity) => {
  if (!groupedActivity) return false;
  return (
    Object.values(getPerpTradesList({ groupedActivity }) ?? {})?.length > 0
  );
};
const combinedPnlPercentage = (groupedActivity) => {
  return Object.values(getPerpTradesList({ groupedActivity }) ?? {})?.reduce(
    (acc, item) => {
      const perpTrade = item?.[0]?.perp_trade;
      if (!perpTrade) return 0;
      const closedPnl = perpTrade?.closed_pnl_percent?.value ?? 0;
      const openPnl = perpTrade?.open_pnl_percent?.value ?? 0;
      return acc + closedPnl + openPnl;
    },
    0
  );
};
const ZxGmxItemWrapper = ({
  groupedActivity,
  profiles,
  onItemClicked,
  setShowPnlComments,
  setCombinedPnlPercentage,
}) => {
  const [hasGmxPerp, setHasGmxPerp] = useState(hasGmx(groupedActivity));
  useEffect(() => {
    if (groupedActivity) {
      setHasGmxPerp(hasGmx(groupedActivity));
    }
  }, [groupedActivity]);
  useEffect(() => {
    if (hasGmxPerp) {
      setShowPnlComments(true);
      const perc = combinedPnlPercentage(groupedActivity);
      if (perc) setCombinedPnlPercentage(perc);
    }
  }, [
    hasGmxPerp,
    setShowPnlComments,
    groupedActivity,
    setCombinedPnlPercentage,
  ]);
  if (!hasGmx(groupedActivity)) return null;

  return (
    Object.values(getPerpTradesList({ groupedActivity }) ?? {})?.length > 0 &&
    Object.values(getPerpTradesList({ groupedActivity }) ?? {})?.map(
      (item, index) => {
        const perpTrade = item?.[0]?.perp_trade;
        return (
          <div key={index}>
            <ZxGmxItem
              isOpen={true}
              trade={perpTrade}
              profiles={profiles}
              onLinkClick={onItemClicked}
            />
          </div>
        );
      }
    )
  );
};

export default ZxGmxItemWrapper;
