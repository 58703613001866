import classes from "./ProfileDetails.module.css";
import {
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom";
import TopBar from "./TopBar";
import Portfolio from "./Portfolio/Portfolio";
import { ProfileTypes } from "../ProfileConstants";
import WalletsPage from "./wallets/WalletsPage";
import DeployedContracts from "./DeployedContracts/DeployedContracts";
import BundleAddressTab from "./BundleAddressTab/BundleAddressTab";
import ProfileActivities from "./Activities/ProfileActivities";
import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "contextStates/ProfileContext";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useTokenHoldings from "./Portfolio/TokenHoldings/useTokenHoldings";
import { handleFeedItemClick, parseFilterParams } from "utils/misc";
import ZxText from "../../../../zxComponents/common/ZxText";
import { EMPTY_STATES, TSTYLES } from "../../../../zxStyles/constants";
import { CustomRow, PaddingComponent } from "../../../UI/Components/Components";
import FeedItemV2 from "../../../FeedItemV2/FeedItemV2";
import GhostFeedItem from "../../../Feed/GhostFeedItem/GhostFeedItem";
import { ArrowRight } from "@phosphor-icons/react";
import { getUniqueId } from "../../../../utils/event_tracking";
import { TopPnlOverview } from "../../../../zxComponents/feed/TopPnl/TopPnl";
import { TopNFTHoldings } from "./NFTHoldings/NFTHoldings";
import * as PropTypes from "prop-types";
import FeedPost from "../../../FeedItemV2/FeedPost";
import _ from "lodash";
import { signedRequest } from "api/api";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "utils/query_utils";
import { ONE_DAY } from "utils/constants";
import { transformNewFeedItem } from "api/feed.api";
import SelectableDivWithDelay from "components/SelectableDivWithDelay/SelectableDivWithDelay";
import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import { ThemeContext } from "contextStates/Theme.context";
import GroupedActivityWrapper from "components/FeedItemV2/GroupedActivityWrapper";

const ProfileDetails = ({ identifier, profileType }) => {
  let match = useRouteMatch();
  const location = useLocation();

  const { setSelectedChains, setSelectedTokens } = useContext(ProfileContext);

  var { data: tokenHoldingsData } = useTokenHoldings({
    identifier,
    forProtocolTreasury: false,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selectedParamChains = params.get("chains")?.split(",") ?? [];
    const selectedParamTokens = parseFilterParams(params.get("tokens"));
    const selectedParamProtocols = parseFilterParams(params.get("protocols"));

    const selectedTokens =
      tokenHoldingsData?.holdings?.filter((holding) => {
        return selectedParamTokens?.some((sel) => {
          return (
            sel.contract_address === holding.contract_address &&
            sel.chain_id === holding.chain_id
          );
        });
      }) ?? [];

    if (selectedParamTokens?.length || selectedParamProtocols?.length) {
      setSelectedTokens(selectedTokens, selectedParamProtocols);
    }
    if (selectedParamChains) {
      setSelectedChains(selectedParamChains);
    }
  }, [
    location.search,
    setSelectedChains,
    setSelectedTokens,
    tokenHoldingsData?.holdings,
  ]);
  useEffect(() => {
    return () => {
      setSelectedChains([]);
      setSelectedTokens([], []);
    };
  }, [setSelectedChains, setSelectedTokens]);
  return (
    <div className={classes.profile_details}>
      <div className={classes.profile_body}>
        <PaddingComponent padding="0 16px 4px 16px" className={classes.tabbar}>
          <TopBar identifier={identifier} profileType={profileType} />
        </PaddingComponent>
        {/* TODO */}
        {/* dont forget to add in AppModule file isProfilePage function for new tabs */}
        <div>
          <Switch>
            <Route path={`${match.path}/activities`}>
              <ProfileActivities identifier={identifier} />
            </Route>
            <Route path={`${match.path}/portfolio`}>
              <Portfolio
                identifier={identifier}
                isCosmos={profileType === ProfileTypes.COSMOS}
              />
            </Route>
            {profileType === ProfileTypes.IDENTITY && (
              <Route
                path={`${match.path}/wallets/:address?/:bundle_identity_id?`}>
                <div className={classes.related_wallets}>
                  <WalletsPage identifier={identifier} />
                </div>
              </Route>
            )}
            <Route path={`${match.path}/deployed_contracts`}>
              <DeployedContracts identifier={identifier} />
            </Route>
            <Route path={`${match.path}/bundle`}>
              <BundleAddressTab />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};
export const Grid = ({
  children,
  gap,
  padding,
  direction = "horizontal",
  style = {},
  rows,
  columns,
  className,
  debug = false,
  ...rest
}) => {
  if (!columns && direction === "horizontal") {
    columns = Array.from({ length: children.length }).map(() => "auto");
  } else if (!rows && direction === "vertical") {
    rows = Array.from({ length: children.length }).map(() => "auto");
  }
  if (!rows) {
    rows = ["auto"];
  }
  if (!columns) {
    columns = ["auto"];
  }

  return (
    <div
      className={className}
      style={{
        padding,
        display: "grid",
        gridTemplateColumns: columns.join(" "),
        gridTemplateRows: rows.join(" "),
        gap,
        ...style,
      }}
      {..._.omit(rest, "rows", "columns", "direction")}>
      {children}
    </div>
  );
};
const SingleRecentActivity = ({
  isTransactions = false,
  data,
  isLoading,
  isError,
}) => {
  const history = useHistory();
  if (isError) {
    return null;
  }
  if (isLoading || !data) {
    return (
      <PaddingComponent padding="4px 16px">
        <GhostFeedItem key="recent-transactions" />
      </PaddingComponent>
    );
  }
  const filteredData = data
    ? data.filter((item) => item.type !== "welcome")
    : [];
  return (
    <Grid gap={20} padding="0 20px 20px">
      {filteredData.length > 0 ? (
        data
          .filter((item) => item.type !== "welcome")
          .sort((a, b) => b.timestamp - a.timestamp)
          .slice(0, 1)
          .map((item) => {
            const actor = item.actor;
            const actorProfile =
              item.identities?.[actor?.identity_id] ??
              item.profiles?.[actor?.address] ??
              actor;
            const Comp = isTransactions
              ? FeedItemV2
              : item?.type === "profile_created"
                ? FeedItemV2
                : item?.type === "farcaster"
                  ? GroupedActivityWrapper
                  : FeedPost;
            return (
              <SelectableDivWithDelay
                key={getUniqueId()}
                clickable
                onClick={(e) => {
                  handleFeedItemClick({
                    contentId: item.id,
                    contentType: item.type,
                    link: item.link,
                    history,
                    event: e,
                    clickable: true,
                    noRedirection: false,
                  });
                }}>
                <Comp
                  noPadding={true}
                  groupedActivity={item}
                  post={item}
                  actor={actorProfile}
                  profiles={item.profiles || {}}
                  identities={item.identities}
                  smallThumbnail={true}
                  hideStats={true}
                  hideEngagements={true}
                  showSettings={false}
                  isProfilePage={true}
                  clickable={true}
                  isHomePageFeedItem={false}
                  isGroupedActivityDetails={false}
                />
              </SelectableDivWithDelay>
            );
          })
      ) : (
        <ZxEmptyState state={EMPTY_STATES.POSTS} size={80} padding="8px" />
      )}
    </Grid>
  );
};

export function ViewAllX({ isLast = false, ...props }) {
  return (
    <Grid
      style={{
        padding: 16,
        borderTop: "1px solid var(--border-dark)",
        // borderBottom: !isLast ? "6px solid var(--border-dark)" : "none",
        justifyItems: "center",
        // marginBottom: isLast ? 0 : 16,
        cursor: "pointer",
      }}
      onClick={props.onClick}>
      <CustomRow gap="4px" alignItems="center">
        <ZxText
          color="primary"
          wrapper="span"
          style={TSTYLES.title2}
          cursor="pointer">
          {props.label}
        </ZxText>
        <ArrowRight size={15} weight="bold" color="var(--primary-color)" />
      </CustomRow>
    </Grid>
  );
}

ViewAllX.propTypes = { onClick: PropTypes.func };
const RecentActivities = ({ isAddress = false }) => {
  const tabs = ["Posts", "Transactions"];
  const { theme } = useContext(ThemeContext);
  const params = useParams();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState("Posts");
  const { identifier } = useContext(ProfileContext);
  useEffect(() => {
    if (isAddress) {
      setCurrentTab("Transactions");
    }
  }, [isAddress, identifier]);
  const getActivity = async ({ isTransactions = false }) => {
    const path = `/api/0xppl/get_activities_v1?identifier=${identifier.current}&is_onchain=${isTransactions}&is_offchain=${!isTransactions}&is_replies=false&page_size=3&activities_for_contract=false`;
    const data = await signedRequest({
      path,
      method: "post",
    });
    const isV1 = data.data?.metadata?.version === 1;
    if (isV1) {
      const { transformedData: rdata } = transformNewFeedItem({
        feedResponse: data,
      });
      return rdata;
    }
    return data.data.data ?? [];
  };
  const getQueryInfo = ({ isTransactions = false }) => {
    return {
      queryKey: QUERY_KEYS.PROFILE_TOP_ACTIVITY({
        identifier: identifier.current,
        type: isTransactions ? "transactions" : "posts",
      }),
      queryFn: () => getActivity({ isTransactions }),
      staleTime: ONE_DAY,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      enabled: !!identifier.current,
    };
  };
  const {
    data: postData,
    isLoading,
    isError,
  } = useQuery(getQueryInfo({ isTransactions: false }));
  const {
    data: transactionData,
    isLoading: transactionIsLoading,
    isError: transactionIsError,
  } = useQuery(getQueryInfo({ isTransactions: true }));

  return (
    <>
      <Grid direction="vertical" gap={8} style={{ paddingTop: 16 }}>
        <PaddingComponent padding="0 16px">
          <ZxText style={TSTYLES.title1} fontSize="18px">
            Recent Activity
          </ZxText>
          <Grid
            columns={["auto", "auto", "1fr"]}
            gap={10}
            style={{ marginTop: 16 }}>
            {tabs.map((item) => {
              let className = classes.capsule;
              if (item === currentTab) {
                className += " " + classes.selected;
              }
              if (theme === "dark") {
                className += " " + classes.dark;
              }
              return (
                <div
                  className={className}
                  key={getUniqueId()}
                  onClick={() => {
                    setCurrentTab(item);
                  }}>
                  {item}
                </div>
              );
            })}
          </Grid>
        </PaddingComponent>
        {currentTab === "Posts" ? (
          <SingleRecentActivity
            isTransactions={false}
            data={postData}
            isLoading={isLoading}
            isError={isError}
          />
        ) : null}
        {currentTab === "Transactions" ? (
          <SingleRecentActivity
            isTransactions={true}
            data={transactionData}
            isLoading={transactionIsLoading}
            isError={transactionIsError}
          />
        ) : null}
        <ViewAllX
          label="View all activities"
          onClick={() => {
            history.push(`/${params.identifier}/activities`);
          }}
        />
      </Grid>
    </>
  );
};

export const ProfileOverview = ({ identifier, identity }) => {
  const { profileData } = useContext(ProfileContext);
  const isAddress = profileData?.current?.type === "address";
  return (
    <>
      <RecentActivities isAddress={isAddress} />
      <TopPnlOverview key={getUniqueId()} identity={identity} />
      <TopNFTHoldings
        identifier={identifier}
        identity={identity}
        isProfileOverview
      />
    </>
  );
};

export default ProfileDetails;
