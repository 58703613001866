import classes from "components/V2/Profile/ProfileDetails/Filters/Filters.module.css";
import ChainRowTile from "components/V2/Profile/ProfileDetails/Filters/ChainRowTile";
import withLoader from "components/core/withLoader";
import { getFiltersData } from "api/profile.api";

const ExplorerChainFilterPopUp = ({
  queryData,
  chainsSelected,
  setChainsSelected,
}) => {
  return (
    <div className={classes.chainFiltersList}>
      <span className={`${classes.title} ${classes.horizontalPadding}`}>
        TOP CHAINS
      </span>
      {Object.keys(queryData.data.data.chain_ids)
        .sort((c, idx) => {
          const chain = queryData.data.data.chain_ids[idx];
          const isSelected = chainsSelected.includes(chain);
          return isSelected ? 1 : -1;
        })
        .map((entry, index) => {
          const chain = queryData.data.data.chain_ids[entry];
          return (
            <div key={`chain-filter-${entry}-${index}`}>
              <ChainRowTile
                chainsSelected={chainsSelected}
                setChainsSelected={setChainsSelected}
                chain={chain}
                index={index}
              />
            </div>
          );
        })}
    </div>
  );
};

export default withLoader(ExplorerChainFilterPopUp, getFiltersData, {
  queryKey: (props) => ["explorer-chains", props.identifiers],
  CustomLoader: () => null,
  queryOptions: () => {
    return { staleTime: 1000 * 60 * 5 };
  },
  showRefetch: false,
  renderNotFound: false,
});
