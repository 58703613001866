import Image from "components/UI/Image";
import classes from "./MobilePush.module.css";
import { X } from "@phosphor-icons/react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box } from "@mui/material";
import BroderedQRCode from "components/UI/QrCode/BorderedQrCode";

const MobilePushNotificationTile = ({ handleClick }) => {
  return (
    <div className={classes.mobilePushNotificationTile}>
      <div className={classes.content}>
        <div>
          <div className={classes.headingElement}>
            <div className={classes.headingDiv}>
              <span className={classes.headingText}>
                Never miss an update on 0xPPL Get our mobile app!
              </span>
            </div>
            <Box
              component={AutoAwesomeIcon}
              sx={{
                fontSize: "24px",
                color: "var(--primary-color)",
              }}
            />
          </div>
          <div className={classes.qrElement}>
            <span className={classes.scanQRText}>
              Scan QR to download and log into our mobile app
            </span>
          </div>
          <div className={classes.qrcode}>
            <BroderedQRCode />
          </div>
        </div>
        <Image
          className={classes.mobilePreviewImage}
          src="https://d43skd0e52j71.cloudfront.net/static/png/mobile-preview.png"
          alt="mobile-preview"
        />
      </div>
      <button onClick={handleClick} className={classes.cross}>
        <X size={11} />
      </button>
    </div>
  );
};

export default MobilePushNotificationTile;
