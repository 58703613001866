import { useEffect, useState } from "react";
import { getOnboardingData, updateCookiesForIdentity } from "api/waitlist.api";
import { useHistory } from "react-router-dom";
import { isTestingOnboarding } from "utils/constants";
import { logSentryEvent } from "utils/sentry";

const useNavigateNewOnboarding = (
  navigateOnboarding,
  setNavigateOnboarding
) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const navigateToOnboardingStep = async (history) => {
    try {
      const onboardingData = await getOnboardingData({ identifier: null });
      const identity = onboardingData?.data?.data?.user_details?.identity_id;
      const existingUid = localStorage.getItem(`user_cookies_uid`);
      if (identity && !existingUid) {
        try {
          await updateCookiesForIdentity();
        } catch (err) {
          logSentryEvent(err, {
            msg: "Error in getting UID for QR code",
          });
        }
      }
      const curStep = onboardingData?.data?.data?.current_step;
      const currRoute = `/onboarding/${curStep}`;
      if (isTestingOnboarding) return;
      history.push(currRoute);
    } catch (err) {
      const desirableRootPath = "/";
      history.push(desirableRootPath);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (navigateOnboarding) {
      const navigate = async () => {
        setLoading(true);
        try {
          await navigateToOnboardingStep(history);
        } catch (err) {
          if (err.response?.status === 400) {
            await navigateToOnboardingStep(history);
          }
        } finally {
          setNavigateOnboarding(false);
          setLoading(false);
        }
      };

      navigate();
    }
  }, [navigateOnboarding, history, setNavigateOnboarding]);

  return loading;
};

export default useNavigateNewOnboarding;
