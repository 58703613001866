import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import classes from "components/V2/Contract/ContractPage.module.css";
import ContractHeader from "components/V2/Contract/ContractHeader/ContractHeader";
import TokenTab from "components/V2/Token/TokenTab";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { removeTradingView } from "utils/trading_view_utils";

const NativeTokenDetails = ({ scrollableRef }) => {
  const { chain_id, address } = useParams();
  useEffect(() => {
    return () => removeTradingView();
  }, []);
  return (
    <BaseWrapper mixpanelEvent="NativeTokenDetails">
      <div className={classes.contract}>
        <ContractHeader chain_id={chain_id} address={address} forNative />
        <TokenTab address={address} chainId={chain_id} showAllDetails={false} />
      </div>
    </BaseWrapper>
  );
};

export default NativeTokenDetails;
