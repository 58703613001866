import WalletRowComponent, {
  TableRowItem,
} from "./components/WalletRowComponent";
import addIcon from "assets/svg/add.svg";
import deleteIcon from "assets/svg/delete.svg";
import { useContext, useMemo, useState } from "react";
import { GlobalContext } from "contextStates/Global";
import { ConfidenceTableStyle } from "../../../../UI/Confidence/Confidence";
import { addRemoveAddressFromBundle } from "api/profile.api";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import { getLinkForWallet } from "utils/profile_utils";
import AddressComponent from "components/UI/Components/AddressComponent";
import BundleSuggestionHoverCard from "components/GroupFeedItem/components/Bundle/BundleSuggestionHoverCard";
import { ProfileContext } from "contextStates/ProfileContext";
import {
  TableRowTextComponent,
  ActionsComponent,
  SourcedBy,
} from "./components/WalletComponents";

const BundleSuggestionRowTile = ({
  bundle,
  index,
  refetch,
  identityBundleData,
}) => {
  const networth =
    bundle?.net_worth?.["_Any"]?.total_usd_value?.display_value ?? "-";

  const [isDiscarding, setIsDiscarding] = useState(false);
  const [isBundling, setIsBundling] = useState(false);
  const { handleErrorSnackbar, setSnackBarState } = useContext(GlobalContext);
  const { updateProfile } = useContext(ProfileContext);

  const relatedData = useMemo(() => {
    return {
      ...bundle,
      related_with: identityBundleData.id,
    };
  }, [bundle, identityBundleData]);

  const onDiscard = () => {
    setIsDiscarding(true);

    addRemoveAddressFromBundle({
      address: bundle.address,
      reason: "",
      identity_id: identityBundleData.id,
      addOrRemove: "Remove",
    })
      .then((data) => {
        setIsDiscarding(false);
        refetch();
        setSnackBarState({
          open: true,
          message: "Discarded",
          type: "success",
        });
      })
      .catch((error) => {
        setIsDiscarding(false);
        handleErrorSnackbar(error, "Failed to Bundle");
      });
  };

  const onBundle = () => {
    setIsBundling(true);
    addRemoveAddressFromBundle({
      address: bundle.address,
      reason: "",
      identity_id: identityBundleData.id,
    })
      .then((resp) => {
        setIsBundling(false);
        refetch();
        if (updateProfile) {
          updateProfile();
        }
        setSnackBarState({
          open: true,
          message: "Bundle Suggestion added",
          type: "success",
        });
      })
      .catch((error) => {
        setIsBundling(false);
        handleErrorSnackbar(error, "Failed to Bundle");
      });
  };

  return (
    <WalletRowComponent link={getLinkForWallet(bundle?.address)}>
      <TableRowItem flex={2}>
        <AddressComponent index={index + 1} address={bundle?.address} />
      </TableRowItem>
      <TableRowItem flex={2}>
        <TableRowTextComponent text={networth}></TableRowTextComponent>
      </TableRowItem>
      <TableRowItem flex={2}>
        <SourcedBy
          wallet={bundle}
          identities={identityBundleData.identity_details}
        />
      </TableRowItem>
      <TableRowItem flex={3}>
        <PPLxHoverCard
          content={
            <BundleSuggestionHoverCard
              actor={identityBundleData.actor}
              relatedData={relatedData}
              profiles={identityBundleData.profiles}
              nativeTokensInfo={identityBundleData.native_tokens_dict}
              isAdminView={false}
              methodIds={null}
              isShowingButtons={false}
              templateString={bundle.relationship_summary}
            />
          }
          style={{
            paddingBottom: "5px",
          }}>
          <div>
            <ConfidenceTableStyle
              fontSize="15px"
              tableStyle={true}
              confidenceText={bundle.confidence ?? ""}
              withText={true}></ConfidenceTableStyle>
          </div>
        </PPLxHoverCard>
      </TableRowItem>
      <TableRowItem flex={2} alignment="right">
        <ActionsComponent
          actionIcon1IsLoading={isBundling}
          actionIcon2IsLoading={isDiscarding}
          actionIconOnClick2={onDiscard}
          actionIconOnClick={onBundle}
          actionIcon2={deleteIcon}
          actionIconAlt2={"Discard Bundle"}
          actionIcon={addIcon}
          actionIconAlt={"Add Bundle"}></ActionsComponent>
      </TableRowItem>
    </WalletRowComponent>
  );
};

export default BundleSuggestionRowTile;
