import {
  CustomRow,
  PaddingComponent,
} from "components/UI/Components/Components";
import Image from "components/UI/Image";
import React from "react";
import ZxText from "../ZxText/ZxText";

const TradingViewAttribution = () => {
  return (
    <PaddingComponent
      padding="12px 8px"
      borderRadius="8px"
      style={{ border: "1px solid var(--elevation-2)" }}>
      <CustomRow gap="8px">
        <PaddingComponent
          style={{
            padding: "8px",
            height: "48px",
            borderRadius: "100px",
            border: "1px solid var(--elevation-2)",
            display: "flex",
            alignItems: "center",
          }}>
          <Image
            src={"/svg/tradingview_logo.svg"}
            alt="Trading View Logo"
            style={{}}
          />
        </PaddingComponent>
        <ZxText color="text-2" fontSize={12}>
          The charting solution is provided by TradingView, a platform for
          traders and investors with advanced analytical tools which helps track{" "}
          <a
            href="https://www.tradingview.com/symbols/BTCUSD/"
            target="_blank"
            style={{ color: "var(--primary-color)", opacity: 0.7 }}
            rel="noreferrer">
            BTC/USD
          </a>{" "}
          price and more.
        </ZxText>
      </CustomRow>
    </PaddingComponent>
  );
};

export default TradingViewAttribution;
