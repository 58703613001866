import { useCallback, useEffect, useRef } from "react";

const useTriggerNextPaginatedActivitiesPage = ({
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage = async () => {},
}) => {
  const fetching = useRef(false); // <--- useRef to persist fetching state

  useEffect(() => {
    // Fetch first page unconditionally
    fetchNextPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetching.current && !isFetchingNextPage) {
      // Done fetching, allow next fetch
      fetching.current = false;
    }
  }, [isFetchingNextPage]); // <--- watch for fetching state

  const myTriggerNextPage = useCallback(() => {
    if (!fetching.current) {
      // <--- only fetch if not currently fetching
      const triggered = triggerNextPage(fetchNextPage, hasNextPage);
      if (triggered) {
        fetching.current = true;
      }
      return triggered;
    }
    return false;
  }, [fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (isFetchingNextPage) {
      return;
    }
    // Run once immediately to test if condition is already met for loading next page
    const triggered = myTriggerNextPage();
    if (triggered) {
      return;
    }
    // Otherwise, install a scroll listener
    window.addEventListener("scroll", myTriggerNextPage);

    return () => {
      window.removeEventListener("scroll", myTriggerNextPage);
    };
  }, [myTriggerNextPage, isFetchingNextPage]);
};

/**
 * Conditionally triggers next page when we hit bottom(-ish) of the page
 * @returns whether or not the next page was triggered
 */
const triggerNextPage = (fetchNextPage, hasNextPage) => {
  const scrollPosition = window.innerHeight + window.pageYOffset;
  const endPosition = document.body.scrollHeight;
  if (endPosition - scrollPosition <= 1500) {
    if (hasNextPage) {
      fetchNextPage();
      return true;
    }
  }
  return false;
};
export default useTriggerNextPaginatedActivitiesPage;
