import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./ZxNftTraits.module.scss";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
} from "components/UI/Components/Components";
const ZxNftTraits = ({ traits, collectionId }) => {
  const history = useHistory();
  if (!traits) {
    return (
      <CustomRow className={styles.container}>
        {Array.from({ length: 8 }).map((_, index) => (
          <CustomSkeleton
            key={index}
            height="80px"
            variant="rectangular"
            className={styles.trait}
          />
        ))}
      </CustomRow>
    );
  }
  return (
    <div className={styles.container}>
      {traits.map((trait, index) => {
        return (
          <div
            key={index}
            className={styles.trait}
            onClick={() => {
              history.push(
                `/collection/${collectionId}/all?trait=${trait.trait_type}&trait_value=${trait.value}`
              );
            }}>
            <CustomColumn>
              <ZxText
                style={TSTYLES.bodyLabel}
                textTransform="uppercase"
                fontSize="11px"
                color="text-2">
                {trait.trait_type}
              </ZxText>
              <ZxText style={TSTYLES.body} color="primary">
                {trait.value}
              </ZxText>
            </CustomColumn>
            <ZxText style={TSTYLES.title2} color="text-2">
              {trait.percentage ?? Math.floor(Math.random() * 100)} %
            </ZxText>
          </div>
        );
      })}
    </div>
  );
};

export default ZxNftTraits;
