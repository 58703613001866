import classes from "./TokenHoldings.module.css";
import useTokenHoldings from "./useTokenHoldings";
import Header from "./Header";
import Footer from "./Footer";
import useSmallBalanceFilter from "./useSmallBalanceFilter";
import TokenHoldingsRenderer from "./TokenHoldingsRenderer";
import useTokenHoldingsFilter from "./useTokenHoldingsFilter";
import { useMemo, useEffect } from "react";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";

const TokenHoldings = ({
  identifier,
  activeChainId,
  selectedActiveChains,
  selectedActiveTokens,
  selectedActiveProtocols,
  selectedWalletAddresses,
  setHoldingsTotalUsdValue,
  forProtocolTreasury,
  rightPane = false,
  isCosmos = false,
}) => {
  const { data, isError, isLoading, isFetching, refetch } = useTokenHoldings({
    identifier,
    forProtocolTreasury,
  });
  const filteredData = useTokenHoldingsFilter({
    data,
    selectedActiveChains,
    selectedActiveTokens,
    selectedActiveProtocols,
    selectedWalletAddresses,
  });
  const {
    chainTokenHoldings,
    containsSmallTokens,
    isSmallTokensVisible,
    setIsSmallTokensVisible,
    smallTokensCount,
  } = useSmallBalanceFilter(filteredData?.holdings, activeChainId, refetch);

  const emptyCondition =
    identifier?.length && chainTokenHoldings?.length === 0 && !isLoading;

  const tradableTokensList = useMemo(() => {
    const list = [];
    const chainProfiles = data?.chain_profiles || {};
    const supportedChains = [
      "Arbitrum",
      "Avalanche",
      "BSC",
      "Ethereum",
      "Fantom",
      "Optimism",
      "Polygon",
      "xDai",
      "Aurora",
      "ZkSync Era",
      "Polygon zkEVM",
      "Base",
    ];
    data?.holdings?.forEach((h) => {
      if (
        supportedChains?.includes(h?.chain_id) &&
        chainProfiles?.[h?.chain_id]?.eth_chain_id
      ) {
        list.push({
          address: h?.contract_address,
          chainId: chainProfiles?.[h?.chain_id]?.eth_chain_id,
          name: h?.token_metadata.name,
          symbol: h?.token_metadata.symbol,
          decimals: h?.token_metadata.decimals,
          logoURI: h?.token_metadata?.logo,
        });
      }
    });
    return list;
  }, [data]);

  useEffect(() => {
    setHoldingsTotalUsdValue?.({
      token: {
        value: data?.total_usd_value,
        display_value: data?.total_usd_value,
      },
    });
  }, [data, setHoldingsTotalUsdValue]);

  return (
    <div className={classes.tokenHoldings}>
      <Header
        totalUsdValue={filteredData?.filteredUsdValue}
        lastUpdatedAt={!rightPane ? filteredData?.last_updated_at : ""}
        refetch={refetch}
        isFetching={isFetching}
      />

      <TokenHoldingsRenderer
        chainTokenHoldings={chainTokenHoldings}
        activeChainId={activeChainId}
        filteredData={filteredData}
        refetch={refetch}
        isError={isError}
        isLoading={isLoading}
        emptyCondition={emptyCondition}
        rightPane={rightPane}
        identifier={identifier}
        isCosmos={isCosmos}
        chainProfiles={data?.chain_profiles}
        tradableTokensList={tradableTokensList}
      />

      <Footer
        isSmallTokensVisible={isSmallTokensVisible}
        containsSmallTokens={containsSmallTokens}
        setIsSmallTokensVisible={setIsSmallTokensVisible}
        smallTokensCount={smallTokensCount}
        rightPane={rightPane}
      />
    </div>
  );
};

export default withErrorBoundary(TokenHoldings);
