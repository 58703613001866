import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { ClickAwayListener } from "@mui/material";
import useCopyToClipboard from "customHooks/useCopyToClipboard";
import classes from "./shareButton.module.css";
import { InteractionButton } from "./ActivityEngagements";
import Copyable from "components/ValueType/Copyable/Copyable";

const ShareButton = ({
  className,
  icon,
  contentId,
  contentType,
  needsLogin,
  hasRoundedButtons,
  customShareLink,
  shouldDirectlyCopy = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    if (shouldDirectlyCopy) {
      copyText();
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = () => {
    copyText();
    handleMenuClose();
  };

  var link = "";
  if (!!customShareLink) {
    link = `${window.location.origin}${customShareLink}`;
  } else {
    link = `${window.location.origin}/${contentType}/${contentId}`;
  }
  const [, copyText] = useCopyToClipboard(link);
  return (
    <ClickAwayListener onClickAway={handleMenuClose}>
      <div
        className={classes.activityActionButton}
        onClick={(e) => e.stopPropagation()}>
        <InteractionButton
          className={className}
          handleClick={handleMenuOpen}
          icon={
            shouldDirectlyCopy ? (
              <Copyable
                content={link}
                copyIcon={icon}
                title="Share"
                disableHoverEffects
              />
            ) : (
              icon
            )
          }
          type="share"
          needsLogin={needsLogin}
          hasRoundedButtons={hasRoundedButtons}
          hideCount
        />

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          classes={{ paper: classes.menu }}>
          <MenuItem onClick={handleCopy} disableRipple>
            <div className={classes.copyButton}>
              {icon}
              Copy link
            </div>
          </MenuItem>
        </Menu>
      </div>
    </ClickAwayListener>
  );
};

export default ShareButton;
