import { Avatar, useChatContext } from "stream-chat-react";
import {
  CustomButton,
  CustomRow,
  PaddingComponent,
  CustomColumn,
  CustomText,
  Divider,
} from "components/UI/Components/Components";
import CustomTextField from "../Search/CustomTextField";
import { useState, useRef, useCallback, useContext, useEffect } from "react";
import ChatSearch from "../Search/ChatSearch";
import classes from "./EditGroupPopup.module.css";
import { IconButton } from "@mui/material";
import {
  X,
  ChatText,
  UserCircleGear,
  UserCircle,
  UserCircleMinus,
  DotsThreeVertical,
} from "@phosphor-icons/react";
import { AuthContext } from "contextStates/AuthContext";
import {
  generateDMId,
  generateGroupId,
  upsertGroupDetails,
  makeGroupModerator,
  makeGroupMember,
  getGroupInfo,
  inviteMembersToGroup,
  removePendingMembersFromGroup,
  removeMembersFromGroup,
} from "api/messaging.api";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "../../../../../../node_modules/@mui/material/index";
import { GlobalContext } from "contextStates/Global";
import { Menu, MenuItem } from "@mui/material";
import {
  Image,
  SignOut,
} from "../../../../../../node_modules/@phosphor-icons/react/dist/index";
import { ChannelTypes, isStreamingGroupChannelType } from "../../helpers";
import ConditionalLink from "shared/ConditionalLink";
import ZxText from "zxComponents/common/ZxText/ZxText";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";

const EditGroupPopup = ({ channel, onClose }) => {
  const groupId = channel?.id;
  const { client, setActiveChannel } = useChatContext();
  const { identityDetails, isSuperuser } = useContext(AuthContext);
  const [groupName, setGroupName] = useState(channel?.data?.name || "");
  const [groupImage, setGroupImage] = useState(
    channel?.data?.image ? { result: channel?.data?.image } : ""
  );
  const [memberToWalletMap, setMemberToWalletMap] = useState({});
  const [members, setMembers] = useState(channel?.state?.members || {});
  const history = useHistory();
  const fileInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  useEffect(() => {
    const memberArray = Object.values(members);
    if (
      isStreamingGroupChannelType(channel?.data?.channel_type) &&
      groupId &&
      memberArray.length > 0
    ) {
      getGroupInfo({ groupId }).then((resp) => {
        const memberToWalletMap = memberArray.reduce((acc, member) => {
          const userId = member.user_id?.substring(12);
          if (resp.members_to_wallets[userId]) {
            acc[member.user_id] = {
              walletCount: resp.members_to_wallets[userId].wallets.length,
              netWorth: resp.members_to_wallets[userId].net_worth.display_value,
            };
          }
          return acc;
        }, {});
        setMemberToWalletMap(memberToWalletMap);
      });
    }
  }, [members, groupId, channel?.data?.channel_type]);

  const initiateDM = useCallback(
    async ({ streamChatId }) => {
      const dmId = await generateDMId({
        otherIdentity: parseInt(streamChatId.replace("sc-identity-", "")),
      });
      const channel = client.channel("messaging", dmId?.id, {
        channel_type: ChannelTypes.DM,
        members: [
          streamChatId,
          identityDetails.current?.identity.stream_chat_details.id,
        ],
      });
      await channel.watch();
      return channel.id;
    },
    [client, identityDetails]
  );

  const updateGroup = async () => {
    let upsertObj = {};
    if (groupName?.trim() === "") {
      return;
    } else {
      upsertObj.name = groupName;
    }
    if (groupImage?.result) {
      upsertObj.image = groupImage?.result;
    }
    if (Object.keys(members).length === 0) {
      return;
    } else {
      upsertObj.members = members;
    }

    setIsLoading(true);
    const upsertRes = await upsertGroupDetails({
      groupId: groupId,
      ...upsertObj,
    });

    await channel.updatePartial({
      set: { name: groupName, image: upsertRes.display_picture },
    });

    const addedMembers = Object.keys(members).filter(
      (member) => !channel.state.members[member]
    );
    const removedMembers = Object.keys(channel.state.members).filter(
      (member) => !members[member]
    );
    if (channel.data.channel_type === ChannelTypes.STREAMING_GROUP) {
      if (addedMembers.length) {
        await channel.inviteMembers(addedMembers);
        await inviteMembersToGroup({
          groupId,
          members: addedMembers,
          identity_id: identityDetails.current.identity.id,
          created_at: channel.data.created_at,
        });
      }
      if (removedMembers.length) {
        await removeMembersFromGroup({ groupId, members: removedMembers });
        await channel.removeMembers(removedMembers);
        await removePendingMembersFromGroup({
          groupId,
          members: removedMembers,
        });
      }
    } else {
      if (addedMembers.length) await channel.addMembers(addedMembers);
      if (removedMembers.length) await channel.removeMembers(removedMembers);
    }

    setIsLoading(false);
    setActiveChannel(channel);
    onClose?.();
  };
  const createGroup = useCallback(async () => {
    if (groupName?.trim() === "") {
      return;
    }
    if (Object.keys(members).length === 0) {
      return;
    }

    setIsLoading(true);
    const newGroup = await generateGroupId();
    const upsertRes = await upsertGroupDetails({
      groupId: newGroup?.id,
      name: groupName,
      image: groupImage?.result,
    });
    const channel = client.channel("messaging", newGroup?.id, {
      name: groupName,
      image: upsertRes?.display_picture,
      channel_type: ChannelTypes.GROUP,
      members: [
        ...Object.keys(members),
        identityDetails.current.identity.stream_chat_details.id,
      ],
    });
    await channel.watch();
    setActiveChannel(channel);
    setIsLoading(false);
    onClose?.();
  }, [
    client,
    setActiveChannel,
    groupName,
    groupImage,
    members,
    identityDetails,
    onClose,
  ]);

  const addMember = (profile) => {
    let newMembers = { ...members };
    newMembers[profile?.stream_chat_details?.id] = {
      user_id: profile?.stream_chat_details?.id,
      status: "invited",
      user: {
        id: profile?.stream_chat_details?.id,
        name: profile.display_name,
        image: profile?.display_picture,
      },
    };
    setMembers(newMembers);
  };

  const removeMember = async (streamChatId) => {
    let newMembers = { ...members };
    await removeMembersFromGroup({ groupId, members: [streamChatId] });
    await channel.removeMembers([streamChatId]);
    delete newMembers[streamChatId];
    setMembers(newMembers);
    if (Object.keys(newMembers).length === 0) {
      setMemberToWalletMap({});
      // delete group
      if (groupId) {
        await client.channel("messaging", groupId).delete();
        onClose?.();
        history.push("/messages/all");
      }
    }
  };

  const addAdmin = useCallback(
    async (streamChatId) => {
      if (groupId) {
        if (channel.state.members[streamChatId]) {
          setIsLoading(true);
          await makeGroupModerator({
            groupId,
            userId: streamChatId.replace("sc-identity-", ""),
          });
          setIsLoading(false);
        } else {
          handleErrorSnackbar?.(null, "User not in group, update group first");
        }
      } else {
        handleErrorSnackbar?.(null, "Create group first");
      }
    },
    [channel, handleErrorSnackbar, groupId]
  );

  const removeAdmin = useCallback(
    async (streamChatId) => {
      if (groupId) {
        if (channel.state.members[streamChatId]) {
          setIsLoading(true);
          await makeGroupMember({
            groupId,
            userId: streamChatId.replace("sc-identity-", ""),
          });

          setIsLoading(false);
        } else {
          handleErrorSnackbar?.(null, "User not in group, update group first");
        }
      } else {
        handleErrorSnackbar?.(null, "Create group first");
      }
    },
    [channel, handleErrorSnackbar, groupId]
  );

  const currentUserStreamChatId =
    identityDetails.current?.identity.stream_chat_details.id;

  const isCurrentUserAdmin = ["owner", "moderator"].includes(
    channel?.state?.members[currentUserStreamChatId]?.role
  );

  const showUserEditOptions = !groupId || (groupId && isCurrentUserAdmin);
  return (
    <>
      <CustomRow padding="12px 12px 4px 12px" flexShrink={0}>
        {groupId ? (
          isCurrentUserAdmin ? (
            <CustomRow flexGrow="1" alignItems="center">
              <CustomText
                text="Edit Group"
                fontSize="17px"
                color="var(--text-1)"></CustomText>
            </CustomRow>
          ) : (
            <CustomRow gap="4px" flexGrow="1" alignItems="center">
              <div className={classes.groupAvatarWrapper}>
                <Avatar name={groupName} image={groupImage?.result} />
              </div>
              <CustomText
                text={groupName}
                fontSize="16px"
                color="var(--text-1)"
                whiteSpace="nowrap"></CustomText>
            </CustomRow>
          )
        ) : (
          <CustomRow flexGrow="1" alignItems="center">
            <CustomText text="Create Group" fontSize="17px"></CustomText>
          </CustomRow>
        )}

        <CustomRow gap="8px" alignItems="center" flexShrink={0}>
          <IconButton onClick={onClose}>
            <X size={16} color="var(--text-2)" />
          </IconButton>
        </CustomRow>
      </CustomRow>
      <CustomColumn>
        <Divider margin="0 0 8px 0" />
        {showUserEditOptions && (
          <CustomColumn padding="8px 20px" flexShrink={0}>
            {showUserEditOptions ? (
              <CustomRow gap="8px">
                <div className={classes.groupAvatarWrapper}>
                  <label for="group-image">
                    {groupImage?.result ? (
                      <img
                        src={groupImage?.result}
                        className={classes.groupAvatar}
                        alt={groupName}></img>
                    ) : (
                      <div className={classes.groupAvatarFallback}>
                        <Image size={20} color="var(--text-2)" />
                      </div>
                    )}
                  </label>

                  <input
                    id="group-image"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={(e) => {
                      const files = Array.from(e.target.files);
                      if (files.length === 1) {
                        const readerPromises = files.map((file) => {
                          return new Promise((resolve) => {
                            const reader = new FileReader();
                            reader.onload = () => {
                              resolve({ file, result: reader.result });
                            };
                            reader.readAsDataURL(file);
                          });
                        });

                        Promise.all(readerPromises).then((results) => {
                          setGroupImage(results[0]);
                        });
                        e.target.value = null;
                      }
                    }}></input>
                </div>

                <CustomTextField
                  variant="outlined"
                  placeholder="Group Name"
                  fullWidth
                  size="small"
                  paddingLeft={"0px"}
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  required
                />
              </CustomRow>
            ) : (
              ""
            )}
          </CustomColumn>
        )}
        <CustomColumn padding="8px 20px">
          {showUserEditOptions && (
            <PaddingComponent padding="0 0 12px 0">
              <ChatSearch
                onSelect={addMember}
                placeholder="Search users to add"
                type="dms"
                hideOutline={false}
                size="small"
                onlyUsersWhoFollowMe={!isSuperuser}
              />
            </PaddingComponent>
          )}
          <CustomColumn
            gap="12px"
            minHeight="100px"
            overflowY="auto"
            className={classes.membersContainer}>
            {Object.keys(members)
              ?.sort(function (a, b) {
                const memberA = members[a].user.name.toLowerCase(),
                  memberB = members[b].user.name.toLowerCase();
                if (memberA < memberB) {
                  return -1;
                }
                if (memberA > memberB) {
                  return 1;
                }
                return 0;
              })
              .map((id) => {
                const isAdmin = ["owner", "moderator"].includes(
                  members[id]?.role
                );
                const isInvited =
                  members[id]?.invited && members[id]?.status === "pending";
                const hasNetworth = memberToWalletMap[id] !== undefined;
                const member = members[id];
                const username = member?.user?.username ?? null;
                const link = username ? `/${username}` : null;
                return (
                  <CustomRow
                    gap="4px"
                    alignItems="center"
                    width="100%"
                    flexShrink={0}
                    flex="1">
                    <ConditionalLink to={link}>
                      <Avatar
                        name={members[id]?.user?.name}
                        image={members[id]?.user?.image}
                        size={32}
                      />
                    </ConditionalLink>
                    <CustomColumn gap="0px">
                      <CustomRow
                        gap="8px"
                        alignItems="center"
                        flexShrink={0}
                        width="100%"
                        flex="1"
                        justifyContent="space-between">
                        <CustomText
                          text={members[id]?.user?.name}
                          color="var(--text-1)"></CustomText>
                        <CustomText
                          text={
                            isAdmin ? (
                              <CustomRow
                                gap="4px"
                                alignItems="center"
                                fontSize="10px"
                                background="var(--primary-color10)"
                                borderRadius="4px"
                                padding="0px 8px">
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "var(--primary-color60)",
                                    fontWeight: 500,
                                  }}>
                                  Admin
                                </span>
                              </CustomRow>
                            ) : (
                              ""
                            )
                          }></CustomText>
                        {isInvited && (
                          <div
                            style={{
                              background: "var(--warning-light)",
                              borderRadius: "4px",
                            }}>
                            <CustomText
                              text="Request sent"
                              fontSize="12px"
                              padding="8px"
                              color="var(--warning)"
                            />
                          </div>
                        )}
                      </CustomRow>
                      {hasNetworth && (
                        <CustomRow>
                          <CustomText
                            text={`${memberToWalletMap[id].walletCount} wallets • ${memberToWalletMap[id].netWorth}`}
                          />
                        </CustomRow>
                      )}
                    </CustomColumn>
                    {currentUserStreamChatId !== id ? (
                      <CustomRow
                        gap="4px"
                        alignItems="center"
                        flexGrow={1}
                        justifyContent="flex-end">
                        <IconButton
                          onClick={async () => {
                            onClose?.();
                            const channelId = await initiateDM({
                              streamChatId: id,
                            });
                            history.push(`/messages/all/${channelId}`);
                          }}>
                          <ChatText size={16} color="var(--text-2)" />
                        </IconButton>
                        <MemberMenu
                          showUserEditOptions={showUserEditOptions}
                          id={id}
                          groupId={groupId}
                          isCurrentUserAdmin={isCurrentUserAdmin}
                          isAdmin={isAdmin}
                          removeAdmin={removeAdmin}
                          addAdmin={addAdmin}
                          removeMember={removeMember}
                        />
                      </CustomRow>
                    ) : (
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          flex: "1",
                        }}
                        onClick={() => removeMember(id)}>
                        <ZxFlex
                          alignSelf="flex-end"
                          justify="flex-end"
                          flex="1">
                          <SignOut size={16} color="var(--error)" />
                          <ZxText color="error">Exit group</ZxText>
                        </ZxFlex>
                      </div>
                    )}
                  </CustomRow>
                );
              })}
          </CustomColumn>
        </CustomColumn>

        {showUserEditOptions && (
          <CustomColumn padding="8px 20px 16px" flexShrink={0}>
            <CustomButton
              radius="8px"
              disabled={groupName?.trim() === ""}
              onClick={groupId ? updateGroup : createGroup}
              text={
                <CustomRow gap="4px" alignItems="center">
                  {isLoading && <CircularProgress color="inherit" size={12} />}
                  <span>
                    {groupId ? "Save and update group" : "Create Group"}
                  </span>
                </CustomRow>
              }></CustomButton>
          </CustomColumn>
        )}
      </CustomColumn>
    </>
  );
};

const MemberMenu = ({
  showUserEditOptions,
  id,
  groupId,
  isCurrentUserAdmin,
  isAdmin,
  removeAdmin,
  addAdmin,
  removeMember,
}) => {
  const anchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(null);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  if (!showUserEditOptions) return null;
  return (
    <>
      <IconButton ref={anchorRef} size="small" onClick={handleMenuOpen}>
        <DotsThreeVertical size={20} color="var(--text-1)" />
      </IconButton>
      <Menu
        sx={{
          "& .MuiMenu-paper": {
            backgroundColor: "var(--navBg)",
          },
          "& .MuiMenuItem-root:hover": {
            backgroundColor: "var(--primary-color10)",
          },
        }}
        anchorEl={anchorRef?.current}
        open={menuOpen}
        onClose={handleMenuClose}
        disableScrollLock={true}
        dense>
        {groupId && isCurrentUserAdmin && (
          <MenuItem
            onClick={() => {
              isAdmin ? removeAdmin(id) : addAdmin(id);
            }}>
            <CustomRow gap="8px" alignItems="center">
              {isAdmin ? (
                <>
                  <UserCircle size={12} color="var(--text-2)" />
                  <CustomText text="Remove admin" color="var(--text-2)" />
                </>
              ) : (
                <>
                  <UserCircleGear size={12} color="var(--text-2)" />
                  <CustomText text="Make admin" color="var(--text-2)" />
                </>
              )}
            </CustomRow>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            removeMember(id);
          }}>
          <CustomRow gap="8px" alignItems="center">
            <UserCircleMinus size={12} color="var(--text-2)" />
            <CustomText text="Remove member" color="var(--text-2)" />
          </CustomRow>
        </MenuItem>
      </Menu>
    </>
  );
};

export default EditGroupPopup;
