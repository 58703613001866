import classes from "./TransactionTokenTransfer.module.css";
import { Skeleton } from "@mui/material";
import {
  Divider,
  InfoBox,
  PaddingComponent,
} from "components/UI/Components/Components";
import { TransactionTokenTransferHeader } from "./TransactionTokenTransferHeader";
import { Table } from "components/UI/Table/Table";
const AddressDetails = () => {
  return (
    <div className={classes.addressRowTile}>
      <Skeleton
        className={classes.profileImage}
        animation="wave"
        variant="rectangular"
        height={24}
        width={24}
      />
      <div className={classes.addressColumnTile}>
        <Skeleton width={100} animation="wave" variant="text" />
        <Skeleton width={100} animation="wave" variant="text" />
      </div>
    </div>
  );
};

const AmountDetails = () => {
  return (
    <div className={classes.amountTileColumn}>
      <div className={classes.amountRowTile}>
        <Skeleton width={30} animation="wave" variant="text" />
        <Skeleton
          className={classes.amountTokenImage}
          animation="wave"
          variant="rectangular"
          height={24}
          width={24}
        />
        <Skeleton width={60} animation="wave" variant="text" />
      </div>
      <Skeleton width={30} animation="wave" variant="text" />
    </div>
  );
};
const TransactionTokenTransferGhostTile = () => {
  return (
    <div className={classes.container}>
      <AddressDetails isRightAligned={true} />
      <AddressDetails />
      <AmountDetails />
    </div>
  );
};

const TokenTransferGhostItems = () => {
  return (
    <div className={classes.container}>
      <InfoBox>
        <Skeleton width={"100%"} animation="wave" variant="text" />
        <PaddingComponent height="12px"></PaddingComponent>
        <Table>
          <TransactionTokenTransferHeader isShimmer />
        </Table>
        <Divider />
        <PaddingComponent height="16px"></PaddingComponent>
        {[...Array(3)].map((tranfer, index) => (
          <TransactionTokenTransferGhostTile
            key={`transaction-token-transfer-${index}`}
          />
        ))}
        <Divider />
      </InfoBox>
    </div>
  );
};

export const TokenTransferPageGhostItems = () => {
  return (
    <div className={classes.container}>
      <InfoBox>
        {[...Array(3)].map((tranfer, index) => (
          <TransactionTokenTransferGhostTile
            key={`transaction-token-transfer-${index}`}
          />
        ))}
        <Divider />
      </InfoBox>
    </div>
  );
};

export default TokenTransferGhostItems;
