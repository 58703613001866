import React, { useMemo } from "react";
import { RenderActivity } from "components/GroupFeedItem/GroupFeedItem.components";
import RightPanel from "components/Pages/AppModule/RightPanel/RightPanel";
import { createPortal } from "react-dom";
import TransactionsRightPanel from "../../V2/ActivityDetails/Transactions/TransactionsRightPanel";
import {
  CustomColumn,
  PaddingComponent,
} from "components/UI/Components/Components";
import { useHistory, useParams } from "react-router-dom";
import GenericFallBackUI from "components/ErrorBoundary/GenericFallBackUI";
import ZxTriggerNextPage from "zxComponents/common/ZxTriggerNextPage/ZxTriggerNextPage";
import { TransactionShimmer } from "./GroupActivityDetailsShimmer";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";

const Transactions = ({
  groupedActivity,
  paginated = false,
  isError = false,
  hasNextPage = false,
  fetchNextPage = () => {},
  isFetching = false,
}) => {
  const { activityId: transactionId, type } = useParams();
  const history = useHistory();
  const pages = groupedActivity?.pages?.filter((p) => p.data) ?? [];
  const firstActivity = pages?.[0]?.data ?? null;
  const components = useMemo(() => {
    const mapActivities = (grouped) => {
      return grouped?.activities?.map((activity, index) => (
        <div
          key={`activity-details-transaction-${activity.id}-${index}`}
          onClick={(e) => {
            history.replace(
              `/${type}/${firstActivity.group_id}/transactions/${activity.id}`
            );
          }}
          style={{
            cursor: "pointer",
          }}>
          <RenderActivity
            key={`activity-details-transaction-${activity.id}`}
            activity={activity}
            profiles={grouped.profiles}
            methodIds={grouped.method_ids}
            index={index}
            groupedActivity={{ ...grouped, activities: [activity] }}
            isActivityDetails
          />
        </div>
      ));
    };
    return !paginated ? (
      mapActivities(groupedActivity)
    ) : (
      <CustomColumn style={{ position: "relative" }}>
        {groupedActivity?.pages.map((grouped) => mapActivities(grouped.data))}

        <ZxTriggerNextPage
          height={100}
          style={{ width: "20px" }}
          fetchNextPage={fetchNextPage}
          shouldFetch={hasNextPage && !isFetching}
        />

        {isFetching && (
          <CustomColumn gap="16px">
            {[...Array(5)].map((_, index) => (
              <TransactionShimmer key={index} />
            ))}
          </CustomColumn>
        )}
      </CustomColumn>
    );
  }, [
    groupedActivity,
    firstActivity,
    history,
    type,
    paginated,
    fetchNextPage,
    isFetching,
    hasNextPage,
  ]);
  if (isError) {
    return <GenericFallBackUI />;
  }
  if (!firstActivity)
    return paginated && hasNextPage ? (
      <>
        {isFetching && (
          <CustomColumn gap="16px">
            {[...Array(5)].map((_, index) => (
              <TransactionShimmer key={index} />
            ))}
          </CustomColumn>
        )}
        <ZxTriggerNextPage
          height={100}
          style={{ width: "20px" }}
          fetchNextPage={fetchNextPage}
          shouldFetch={hasNextPage && !isFetching}
        />
      </>
    ) : null;
  return (
    <PaddingComponent paddingTop="16px" id="details_transactions">
      {components}
      {createPortal(
        <RightPanel
          onClose={() => {
            history.replace(`/${type}/${firstActivity.group_id}/transactions`);
          }}>
          {isShowingTransactionRightPanel({ transactionId }) && (
            <TransactionsRightPanel
              onClose={() => {
                history.replace(
                  `/${type}/${firstActivity.group_id}/transactions`
                );
              }}
              groupedActivity={firstActivity}
              activityId={transactionId}
            />
          )}
        </RightPanel>,
        document.getElementById("rightPanel")
      )}
    </PaddingComponent>
  );
};

const isShowingTransactionRightPanel = ({ transactionId }) => {
  if (
    transactionId != null &&
    !["likes", "bookmarks", "reposts"].includes(transactionId)
  ) {
    return true;
  }
  return false;
};

export default withErrorBoundary(Transactions);
