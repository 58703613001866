import React, { useContext, useState } from "react";

import { createPortal } from "react-dom";
import TokenHoldingsRightPanel from "./TokenHoldingsRightPanel";
import classes from "./TokenHoldings.module.css";
import SendTokenPanel from "components/V2/Profile/ProfileDetails/Portfolio/SendTokenPanel/SendTokenPanel";
import { Drawer } from "../../../../../../../node_modules/@mui/material/index";
import { ProfileContext } from "contextStates/ProfileContext";
import { SendTokenContext } from "contextStates/SendTokenContext";
import ZxTable from "zxComponents/common/ZxTable/ZxTable";
import { getTokenHoldingsColumns } from "./TokenHoldings.utils";

const Content = ({
  holdings,
  activeChainId,
  rightPane,
  identifier,
  chainProfiles,
  isCosmos,
  openTradingDesk,
  tradableTokensList,
  refetch,
}) => {
  const { isLoggedInUserProfile } = useContext(ProfileContext);

  return (
    <HoldingsList
      holdings={holdings}
      rightPane={rightPane}
      activeChainId={activeChainId}
      identifier={identifier}
      chainProfiles={chainProfiles}
      isCosmos={isCosmos}
      openTradingDesk={openTradingDesk}
      tradableTokensList={tradableTokensList}
      isLoggedInUserProfile={isLoggedInUserProfile}
      refetch={refetch}
    />
  );
};

export const HoldingsList = React.memo(
  ({
    holdings,
    rightPane,
    identifier,
    chainProfiles,
    isCosmos,
    openTradingDesk,
    tradableTokensList,
    isLoggedInUserProfile,
    refetch,
  }) => {
    const [isRightPaneOpen, setIsRightPaneOpen] = useState(false);
    const [isSendPanelOpen, setIsSendPanelOpen] = useState(false);
    const [currentTokenData, setCurrentTokenData] = useState(null);
    const { profileData } = useContext(ProfileContext);
    const [open, setOpen] = useState({});
    const handleClose = ({ chain, address }) => {
      setOpen((old) => {
        const newOpen = { ...old };
        delete newOpen[`${chain}_${address}`];
        return newOpen;
      });
    };

    const handleOpen = ({ chain, address }) => {
      setOpen((old) => ({ [`${chain}_${address}`]: true }));
    };
    return (
      <>
        <ZxTable
          columns={getTokenHoldingsColumns({
            holdings,
            classes,
            tradableTokensList,
            chainProfiles,
            isLoggedInUserProfile,
            rightPane,
            open,
            handleOpen,
            handleClose,
            setCurrentTokenData,
            setIsSendPanelOpen,
          })}
          data={holdings}
          emptyText="No tokens"
          hideBorders
          maxHeight={holdings?.length > 100 ? "1000px" : "100%"}
          minHeight="40px"
          hasNextPage={false}
          rowClassName={classes.tokenRow}
          onRowClick={(token) => {
            setCurrentTokenData(token);
            setIsRightPaneOpen(true);
          }}
          rowPadding="8px 12px"
        />
        {createPortal(
          <Drawer
            anchor="right"
            open={isRightPaneOpen}
            onClose={() => setIsRightPaneOpen(false)}>
            <TokenHoldingsRightPanel
              currentTokenData={currentTokenData}
              identifier={identifier ?? profileData?.current?.details?.id}
              onClose={() => setIsRightPaneOpen(false)}
            />
          </Drawer>,
          document.getElementById("rightPanel")
        )}
        {createPortal(
          <Drawer
            anchor="right"
            open={isSendPanelOpen}
            onClose={() => setIsSendPanelOpen(false)}>
            <SendTokenContext.Provider value={{ isLoggedInUserProfile: true }}>
              <SendTokenPanel
                refetch={refetch}
                defaultToken={currentTokenData}
                onClose={() => setIsSendPanelOpen(false)}
              />
            </SendTokenContext.Provider>
          </Drawer>,
          document.getElementById("rightPanel")
        )}
      </>
    );
  }
);

export default Content;
