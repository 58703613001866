import React, { useContext, useRef, useState } from "react";
import classes from "./CreateGroupPanel.module.css";
import {
  CustomButton,
  CustomText,
  PaddingComponent,
} from "../../../../UI/Components/Components";
import {
  CustomColumn,
  CustomRow,
  FlexGrow,
} from "../../../Profile/ProfileDetails/wallets/AddWallet/AddWalletComponent";
import { NonShrinkingTextField } from "../../../Profile/ProfileDetails/wallets/AddWallet/ConnectWallet";
import { Avatar } from "stream-chat-react";
import { PlusCircle } from "@phosphor-icons/react";
import { CreateGroupContext } from "./useGroupCreate";

export const GroupName = ({ setShowSuccessAnimation, onClose }) => {
  const {
    groupName,
    setGroupName,
    isStep3Valid,
    groupImage,
    setGroupImage,
    createGroup,
  } = useContext(CreateGroupContext);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className={classes.formWrapper}>
      <div className={classes.form}>
        <CustomColumn>
          <CustomText
            text="Add group name and image"
            fontSize="17px"
            fontWeight="600"
            color="var(--text-1)"
            textAlign="left"
          />
          <CustomText
            text="Choose a name and upload an image for your group"
            fontSize="14px"
            fontWeight="400"
            textAlign="left"
          />
        </CustomColumn>
        <PaddingComponent minHeight="16px" />
        <NonShrinkingTextField
          placeholder="Enter group name"
          required
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <PaddingComponent minHeight="16px" />
        <FlexGrow>
          <label for="group-image" className={classes.uploadLabel}>
            <CustomColumn justifyContent="center" alignItems="center" gap="6px">
              {groupImage?.result ? (
                <Avatar
                  name={groupName}
                  image={groupImage?.result}
                  size={400}
                />
              ) : (
                <>
                  <PlusCircle size={40} color="var(--primary-color)" />
                  <CustomText
                    text={"Upload group image"}
                    fontSize="15px"
                    fontWeight="600"
                    color="var(--text-1)"
                  />
                  <CustomText text="Choose a profile photo of your group" />
                </>
              )}
            </CustomColumn>
          </label>
          <input
            id="group-image"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={(e) => {
              const files = Array.from(e.target.files);
              if (files.length === 1) {
                const readerPromises = files.map((file) => {
                  return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                      resolve({ file, result: reader.result });
                    };
                    reader.readAsDataURL(file);
                  });
                });

                Promise.all(readerPromises).then((results) => {
                  setGroupImage(results[0]);
                });
                e.target.value = null;
              }
            }}></input>
        </FlexGrow>
        <PaddingComponent minHeight="16px" />
      </div>
      <div className={classes.footer}>
        <CustomRow>
          <CustomButton
            className={classes.nextButton}
            text="Create Group"
            height="58px"
            radius="8px"
            fontSize="17px"
            isLoading={isLoading}
            disabled={!isStep3Valid || isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                await createGroup();
                setShowSuccessAnimation(true);
                setTimeout(() => {
                  setIsLoading(false);
                  onClose();
                }, 2000);
              } catch (e) {
                setIsLoading(false);
              }
            }}
          />
        </CustomRow>
      </div>
    </div>
  );
};
