import { getNetWorthV2 } from "utils/profile_utils";
import { addRemoveAddressFromBundle } from "api/profile.api";
import addIcon from "assets/svg/add.svg";
import WalletRowComponent, {
  TableRowItem,
} from "./components/WalletRowComponent";
import React, { useState } from "react";

import {
  TableRowTextComponent,
  ActionsComponent,
  SourcedBy,
} from "./components/WalletComponents";
import AddressComponent from "components/UI/Components/AddressComponent";

import { useContext } from "react";
import { ProfileContext } from "contextStates/ProfileContext";
import { getLinkForWallet } from "utils/profile_utils";

const RemovedByYouRowTile = ({
  bundleData,
  identities,
  wallet,
  index,
  refetch,
}) => {
  const [isAddBackAddressLoading, setIsAddBackAddressLoading] = useState(false);
  const { updateProfile } = useContext(ProfileContext);
  const networth = getNetWorthV2(wallet) ?? "";

  const onAddBackAddressToBundle = () => {
    setIsAddBackAddressLoading(true);
    addRemoveAddressFromBundle({
      address: wallet?.address,
      reason: "",
      identity_id: bundleData.id,
    })
      .then((resp) => {
        setIsAddBackAddressLoading(false);
        refetch();
        if (updateProfile) {
          updateProfile();
        }
      })
      .catch((err) => {
        setIsAddBackAddressLoading(false);
      });
  };
  return (
    <WalletRowComponent link={getLinkForWallet(wallet?.address)}>
      <TableRowItem flex={2}>
        <AddressComponent index={index + 1} address={wallet?.address} />
      </TableRowItem>
      <TableRowItem flex={2}>
        <TableRowTextComponent text={networth}></TableRowTextComponent>
      </TableRowItem>
      <TableRowItem flex={2}>
        <SourcedBy wallet={wallet} identities={identities} />
      </TableRowItem>
      <TableRowItem flex={3}>
        <TableRowTextComponent
          isTooltip={true}
          text={wallet.reason || "-"}></TableRowTextComponent>
      </TableRowItem>
      <TableRowItem alignment={"right"} flex={2}>
        <ActionsComponent
          actionIcon1IsLoading={isAddBackAddressLoading}
          actionIconOnClick={onAddBackAddressToBundle}
          actionIcon={addIcon}
          actionIconAlt={"Add back Wallet"}></ActionsComponent>
      </TableRowItem>
    </WalletRowComponent>
  );
};

export default RemovedByYouRowTile;
