import { useContext } from "react";
import { Menu, MenuItem } from "@mui/material";
import { Trash, Warning } from "@phosphor-icons/react";
import { GlobalContext } from "contextStates/Global";

import classes from "./postSettingsMenu.module.css";

const PostSettingsMenu = ({
  anchorEl,
  handleMenuClose,
  handleDeleteClick,
  handleReportClick,
  handleBoostClick,
  isLoggedInUserPost,
}) => {
  const showBoostBtn =
    useContext(GlobalContext).featureFlags["show_boost_button"];
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      classes={{ paper: classes.menu }}>
      {isLoggedInUserPost && (
        <MenuItem onClick={handleDeleteClick} disableRipple>
          <div className={classes.menuItem}>
            <Trash size={14} />
            Delete
          </div>
        </MenuItem>
      )}
      <MenuItem onClick={handleReportClick} disableRipple>
        <div className={classes.menuItem}>
          <Warning size={14} />
          Report
        </div>
      </MenuItem>
      {showBoostBtn && (
        <MenuItem onClick={handleBoostClick} disableRipple>
          <div className={classes.menuItem}>
            <Warning size={14} />
            Boost
          </div>
        </MenuItem>
      )}
    </Menu>
  );
};
export default PostSettingsMenu;
