import React, { useEffect, useRef, useState } from "react";
import styles from "./StaticSearchBar.module.css";
import { debounce } from "lodash";
import { MagnifyingGlass } from "@phosphor-icons/react";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import { CustomSkeleton } from "./Components";
import { ClickAwayListener } from "../../../../node_modules/@mui/material/index";

const StaticSearchBar = ({
  setSearchTerm,
  searchCallback,
  title = "Search",
  defaultValue = "",
  margin = "0 0 8px 0",
  borderRadius = "8px",
  background = "var(--elevation-1)",
  border = "1px solid transparent",
  delay = 400,
  setLoading,
  prefix = null,
  disabled = false,
  resetOnDisable = false,
  isLoading = false,
  renderResults = null,
  hasResults = false,
  resultsPosition = "absolute",
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false);
  const toggleFocus = (val) => {
    setIsFocused(val);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (setLoading) setLoading(true);
    delayedQuery(value);
  };
  const delayedQuery = useRef(
    debounce((q) => {
      setSearchTerm(q);
    }, delay)
  ).current;
  useEffect(() => {
    if (disabled && resetOnDisable) {
      setInputValue("");
    }
  }, [disabled, resetOnDisable, setSearchTerm]);
  return (
    <ClickAwayListener onClickAway={() => toggleFocus(false)}>
      <div
        className={`${styles.searchInputContainer} `}
        style={{ margin, borderRadius, background, border }}>
        <div
          className={`${styles.searchInput} ${disabled ? styles.disabled : ""}`}>
          <MagnifyingGlass size={18} color="var(--text-2)" />
          {prefix}
          <input
            type="text"
            placeholder={disabled ? "" : title}
            value={inputValue}
            onChange={handleInputChange}
            disabled={disabled}
            onFocus={() => toggleFocus(true)}
          />
        </div>
        {isLoading && renderResults && (
          <div
            className={styles.searchResults}
            style={{ position: resultsPosition }}>
            <ZxFlex dir="column" gap="8px">
              {[1, 2, 3, 4].map((_, index) => (
                <CustomSkeleton
                  key={index}
                  height="32px"
                  width="100%"
                  variant="rectangular"
                  borderRadius="4px"
                />
              ))}
            </ZxFlex>
          </div>
        )}
        {!isLoading && renderResults && isFocused && hasResults && (
          <div
            className={styles.searchResults}
            style={{ position: resultsPosition }}>
            {renderResults({ toggleFocus })}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default StaticSearchBar;
