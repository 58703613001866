import { BellSimple } from "@phosphor-icons/react";

import classes from "./NotificationsPage.module.css";
import NotificationUnreadIcon from "./NotificationUnreadIcon";

const NotificationIcon = ({ onNotificationPage = false }) => {
  return (
    <div className={`${classes.icon_with_chain}`} style={{ top: 2 }}>
      <BellSimple
        size={20}
        weight="duotone"
        color={`${
          onNotificationPage ? "var(--primary-color)" : "var(--text-2)"
        }`}
      />
      <NotificationUnreadIcon onNotificationPage={onNotificationPage} isMounted={true} />
    </div>
  );
};

export default NotificationIcon;
