import { useQuery } from "@tanstack/react-query";
import { signedRequest } from "./api";
import { COMMUNITY_TABS, ONE_DAY } from "utils/constants";
import { QUERY_KEYS } from "utils/query_utils";
import { getProfileData } from "./profile.api";

export const getCommunityProfiles = async ({ tab } = {}) => {
  if (tab === COMMUNITY_TABS.recommended) {
    return await getRecommendedProfiles();
  }
  if (tab === COMMUNITY_TABS.newOn0xPPL) {
    return await getNewOn0xPPL();
  }
  return await getTopProfiles();
};

export const getTopProfiles = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/0xppl/all_top_identities",
  });
  return response.data;
};

export const getRecommendedProfiles = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/0xppl/get_recommended_profiles",
  });
  return response.data;
};

export const getNewOn0xPPL = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/0xppl/new_on_0xppl",
  });
  return response.data;
};
export const getTwitterProfiles = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/twitter/get_twitter_profiles_to_follow",
  });
  return response.data.data;
};

export const getNetworkProfiles = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/0xppl/from_your_network",
  });
  return response.data.data;
};

export const getAccountsInteractedWith = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/0xppl/get_top_accounts_address_interacted_with",
  });
  return response.data.data;
};

export const prefetchTopProfiles = async ({ queryClient }) => {
  await queryClient.prefetchQuery({
    queryKey: ["topProfiles"],
    queryFn: () => getTopProfiles(),
    staleTime: 1000 * 60 * 5,
  });
};

export const prefetchRecommendedProfiles = async ({ queryClient }) => {
  await queryClient.prefetchQuery({
    queryKey: ["recommendedProfiles"],
    queryFn: () => getRecommendedProfiles(),
    staleTime: 1000 * 60 * 5,
  });
};

export const prefetchNetworkProfiles = async ({ queryClient }) => {
  await queryClient.prefetchQuery({
    queryKey: ["NetworkProfileList"],
    queryFn: () => getNetworkProfiles(),
    staleTime: 1000 * 60 * 5,
  });
};

export const prefetchMyProfile = async ({ queryClient, identifier }) => {
  await queryClient.prefetchQuery({
    queryKey: ["profile", identifier],
    queryFn: () => getProfileData({ identifier }),
    staleTime: ONE_DAY,
    enabled: !!identifier,
  });
};

export const getSocialCircle = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/0xppl/get_social_circle_recommendations",
  });
  return response.data.data;
};

export const useGetSocialCircle = () => {
  return useQuery({
    queryKey: QUERY_KEYS.SOCIAL_CIRCLE(),
    queryFn: getSocialCircle,
    staleTime: ONE_DAY,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 2,
  });
};
