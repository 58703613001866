import { signedRequest } from "./api.js";

export const fetchWatchlist = async () => {
  const resp = await signedRequest({
    method: "get",
    path: "/api/v4/get_watchlist",
  });

  return resp.data.data;
};

export const addToWatchlist = async ({
  address,
  chainId,
  tokenSlug,
  collectionId,
  isNft,
}) => {
  const params = {};
  if (!isNft) {
    if (tokenSlug) {
      params.token_slug = tokenSlug;
    }
    if (chainId && address) {
      params.chain_id = chainId;
      params.token_address = address;
    }
  } else {
    if (collectionId) {
      params.collection_id = collectionId;
    }
    if (chainId && address) {
      params.chain_id = chainId;
      params.nft_address = address;
    }
  }

  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/add_to_watchlist",
    bodyText: JSON.stringify({
      ...params,
    }),
  });

  return resp.data;
};

export const reorderWatchlist = async ({ watchlistList }) => {
  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/reorder_watchlist",
    bodyText: JSON.stringify({
      category_identifier_rank_tuples: watchlistList,
    }),
  });

  return resp.data;
};

export const removeFromWatchlist = async (params) => {
  const resp = await signedRequest({
    method: "post",
    path: "/api/v4/remove_from_watchlist",
    bodyText: JSON.stringify({
      ...params,
    }),
  });
  return resp.data;
};
