import React, { useState } from "react";

import classes from "./PostSettings.module.css";
import PostSettingsMenu from "./PostSettingsMenu";
import DeletePostDialog from "./DeletePostDialog";
import ReportPostDialog from "./ReportPostDialog";
import BoostPostDialog from "./BoostPostDialog";
import { DotsThreeVertical } from "@phosphor-icons/react";

const PostSettings = ({
  postId,
  queryKeys,
  isLoggedInUserPost,
  groupedActivity,
  onDelete = () => {},
  isComment = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [openBoostDialog, setOpenBoostDialog] = useState(false);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <button className={classes.settingsIconWrapper} onClick={handleMenuOpen}>
        <DotsThreeVertical size={20} color="var(--text-2)" />
      </button>
      <PostSettingsMenu
        anchorEl={anchorEl}
        isLoggedInUserPost={isLoggedInUserPost}
        handleMenuClose={handleMenuClose}
        handleDeleteClick={() => {
          setOpenDeleteDialog(true);
          handleMenuClose();
        }}
        handleReportClick={() => {
          setOpenReportDialog(true);
          handleMenuClose();
        }}
        handleBoostClick={() => {
          setOpenBoostDialog(true);
          handleMenuClose();
        }}
      />

      <DeletePostDialog
        postId={postId}
        isComment={isComment}
        handleDialogToggle={() => {
          setOpenDeleteDialog(!openDeleteDialog);
        }}
        openDialog={openDeleteDialog}
        queryKeys={queryKeys}
        onDelete={onDelete}
      />
      <ReportPostDialog
        postId={postId}
        handleDialogToggle={() => {
          setOpenReportDialog(!openReportDialog);
        }}
        openDialog={openReportDialog}
        queryKeys={queryKeys}
      />
      <BoostPostDialog
        postId={postId}
        isPost={!!postId}
        activityId={groupedActivity?.id}
        handleDialogToggle={() => {
          setOpenBoostDialog(!openBoostDialog);
        }}
        openDialog={openBoostDialog}
        queryKeys={queryKeys}
      />
    </div>
  );
};

export default PostSettings;
