import classes from "../ScreenerRightPanel.module.css";
import {
  PaddingComponent,
  CustomColumn,
  CustomRow,
  FlexGrow,
} from "components/UI/Components/Components";
import CheckboxText from "./CheckboxText";

const DefaultColumns = () => {
  return (
    <CustomColumn>
      <PaddingComponent paddingBottom="16px">
        <CustomRow alignItems="center" gap="16px">
          <span className={classes.header}>DEFAULT</span>
          <FlexGrow>
            <div className={classes.centerDivider} />
          </FlexGrow>
        </CustomRow>
      </PaddingComponent>
      <CheckboxText
        isActive={true}
        isToggleable={false}
        text="Name & Wallet Address"
      />
      <PaddingComponent height="20px" />
      <CheckboxText isActive={true} isToggleable={false} text="Networth" />
      <PaddingComponent height="42px" />
    </CustomColumn>
  );
};

export default DefaultColumns;
