import { CustomRow, CustomText } from "components/UI/Components/Components";
import React from "react";
import ProgressCircle from "components/UI/Components/ProgressCircle";

const PostProgressCircle = ({ wordsLength, total = 260 }) => {
  if (wordsLength > total) {
    const overlimit = wordsLength - total;
    return (
      <CustomRow padding="0px 8px 0px 4px" alignItems="center">
        <CustomText
          text={`-${overlimit}`}
          color="var(--error)"
          lineHeight="100%"
        />
      </CustomRow>
    );
  }
  return (
    <CustomRow padding="0px 8px 0px 4px" alignItems="center">
      <ProgressCircle
        progress={wordsLength / total}
        radius={7}
        strokeWidth={3}
      />
    </CustomRow>
  );
};
export default PostProgressCircle;
