import React, { Fragment } from "react";
import classes from "./activityStats.module.css";
import rightChevronIcon from "assets/svg/feed/chevron_right.svg";

const ActivityStats = ({
  activitiesCount,
  totalWallets,
  totalProtocols,
  hasSummary = true,
}) => {
  const activityStatsData = [
    totalWallets && {
      text: `${totalWallets} ${generateLabel("wallets", totalWallets)}`,
      className: classes.activityStatsItem,
    },
    totalProtocols && {
      text: `${totalProtocols} ${generateLabel("protocols", totalProtocols)}`,
      className: classes.activityStatsItem,
    },
    activitiesCount && {
      text: `${activitiesCount} ${generateLabel(
        "transactions",
        activitiesCount
      )}`,
      className: classes.activityStatsItem,
    },
  ]?.filter((item) => item);

  // Hide the ActivityStats component if walletsCount is 1 and activitiesCount,totalProtocols is 0 or 1
  if (
    totalWallets === 1 &&
    (activitiesCount === 0 || activitiesCount === 1) &&
    (totalProtocols === 0 || totalProtocols === 1)
  ) {
    return null;
  }

  return (
    <div className={classes.activityStats}>
      <div className={classes.stats}>
        {activityStatsData.map((item, index) => {
          if (!item) return null;
          return (
            <Fragment key={`activity-stats-${index}`}>
              <span className={classes.activityStatsItem}>{item.text}</span>
              {index !== activityStatsData.length - 1 && (
                <div className={classes.divider}>•</div>
              )}
            </Fragment>
          );
        })}
      </div>
      {!hasSummary ? (
        <button className={classes.expandActivities}>
          View all
          <div className={classes.rightChevronIcon}>
            <img src={rightChevronIcon} alt="go to details" />
          </div>
        </button>
      ) : (
        <div className={classes.rightChevronIcon}>
          <img src={rightChevronIcon} alt="go to details" />
        </div>
      )}
    </div>
  );
};

const generateLabel = (label, count) => {
  if (count === 1) {
    return label.slice(0, -1);
  }
  return label;
};

export default ActivityStats;
