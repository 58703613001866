import { getChainBlocksInfo } from "api/contract.api";
import {
  CustomColumn,
  CustomRow,
  CustomText,
} from "components/UI/Components/Components";
import Image from "components/UI/Image";
import withLoader from "components/core/withLoader";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import React, { useContext, useEffect } from "react";
import classes from "./styles.module.css";
import BlocksTable from "./BlocksTable";
import { AllBlocksPageShimmer } from "./Shimmers/AllBlocksPageShimmer";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const AllBlocksPage = ({ chainId, chainType, queryData }) => {
  const { setTitle } = useContext(LeftSideHeaderContext);

  useEffect(() => {
    setTitle("Block Info");
  }, [setTitle]);

  return (
    <BaseWrapper mixpanelEvent="AllBlocksPage">
      <CustomColumn gap="16px" margin="36px 0 0 0">
        <CustomText
          color="var(--text-1)"
          fontWeight="600"
          fontSize="20px"
          text={`${queryData?.chain_profile?.name} Block Info`}
          textAlign="left"
        />
        <CustomRow justifyContent="space-between" alignItems="center">
          <CustomRow gap="16px">
            {queryData?.stats.slice(0, 3).map((item) => {
              return (
                <TransactionStats
                  key={item?.key}
                  title={item?.name}
                  value={item?.value?.display_value}
                />
              );
            })}
          </CustomRow>
          <Image
            className={classes.transactionPageLogo}
            src={queryData?.chain_profile?.logo_uri}
          />
        </CustomRow>
        <BlocksTable chain_id={chainId} chainType={chainType} />
      </CustomColumn>
    </BaseWrapper>
  );
};

export const TransactionStats = ({ title, value }) => {
  return (
    <CustomColumn
      width="188px"
      height="74px"
      border="1px solid var(--border-dark)"
      background="var(--base)"
      borderRadius="10px"
      padding="14px 16px"
      gap="2px">
      <CustomText textAlign="left" lineHeight="130%" text={title} />
      <CustomText
        fontSize="20px"
        fontWeight="600"
        lineHeight="130%"
        textAlign="left"
        text={value}
        color="var(--text-1)"
      />
    </CustomColumn>
  );
};

export default withLoader(AllBlocksPage, getChainBlocksInfo, {
  queryKey: ({ chainId, chainType }) => ["chainBlocks", chainId, chainType],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 2,
  renderNotFound: false,
  CustomLoader: AllBlocksPageShimmer,
});
