import classes from "./MyHoldingsRightPanel.module.css";
import { useEffect, useState } from "react";
import {
  CustomRow,
  CustomText,
  InfoBox,
  PaddingComponent,
  CustomColumn,
  Divider,
  TransparentButton,
  CircleAvatar,
  PriceChange,
} from "components/UI/Components/Components";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
  SIZE,
} from "components/UI/Components/CustomLinkTabBar";
import { Link } from "react-router-dom";
import { ReactComponent as CrossIcon } from "assets/svg/profile/cross.svg";
import { PositionCategories } from "components/V2/Profile/ProfileDetails/Portfolio/ProtocolHoldings/PositionCategories";
import AllHoldings from "./AllHoldings";
import UsersTokenTransfersList from "./UsersTokenTransfersList";
// import LiveTransactions from "./LiveTransactions";
import Image from "components/UI/Image";
import PnlValue from "./PnlValue";
import { getMarkers } from "utils/chart_utils";
import TopUsers from "components/V2/Token/TopUsers/TopUsers";
import TradingViewGraph from "components/V2/TradingViewGraph/TradingViewGraph";
import { useTradingViewMarkers } from "utils/trading_view_utils";

const MyHoldingsRightPanel = ({
  onClose,
  activeTab,
  setActiveTab,
  holdingsData,
  identifier,
  selectedHolding,
  setSelectedHolding: setSelectedHoldingParent,
}) => {
  const [selectedProtocolToken, setSelectedProtocolToken] = useState(null);
  const [allTokenTransfers, setAllTokenTransfers] = useState([]);
  const { getChartMarkers, updateMarkers } = useTradingViewMarkers();
  const getCurrentTokenAddress = () => {
    return selectedHolding?.type === "Token"
      ? selectedHolding?.token_address
      : selectedProtocolToken
        ? selectedProtocolToken
        : selectedHolding?.tokens[0];
  };
  const getCurrentTokenName = () => {
    if (selectedHolding?.type === "Token") {
      return selectedHolding?.display_name;
    } else {
      const index = selectedHolding?.tokens.indexOf(selectedProtocolToken);
      return selectedHolding?.token_slugs[index];
    }
  };

  const getCurrentTokenSlug = () => {
    if (selectedHolding?.type === "Token") {
      return selectedHolding?.token_slug;
    } else {
      const index = selectedHolding?.tokens.indexOf(selectedProtocolToken);
      return selectedHolding?.token_slugs[index];
    }
  };

  const setSelectedHolding = (holding) => {
    setSelectedHoldingParent(holding);
    if (holding.type === "Protocol") {
      let token = holding.tokens[0];
      setSelectedProtocolToken(token);
    } else {
      setSelectedProtocolToken(null);
    }
  };
  useEffect(() => {
    if (selectedHolding?.type === "Protocol" && !selectedProtocolToken) {
      setSelectedProtocolToken(selectedHolding?.tokens[0] ?? null);
    }
  }, [selectedHolding, selectedProtocolToken]);
  const isSolana = selectedHolding?.str_chain_id?.toLowerCase() === "solana";
  const isBitcoin = selectedHolding?.str_chain_id?.toLowerCase() === "bitcoin";
  return (
    <div className={classes.rightPanel}>
      <Header
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onClose={onClose}
      />
      <Divider borderColor="var(--border-dark)" />
      <AllHoldings
        holdingsData={holdingsData?.holdings}
        chainProfiles={holdingsData?.chain_profiles}
        selectedHolding={selectedHolding}
        setSelectedHolding={setSelectedHolding}
      />
      <Divider borderColor="var(--border-dark)" />

      <CustomColumn flexGrow={1} overflowY="auto">
        {selectedHolding?.type === "Protocol" ? (
          <CurrentProtocolHolding
            holding={selectedHolding}
            selectedProtocolToken={selectedProtocolToken}
            setSelectedProtocolToken={setSelectedProtocolToken}
          />
        ) : (
          <>
            <CurrentHolding
              holding={selectedHolding}
              chainProfiles={holdingsData?.chain_profiles}
            />
            <PaddingComponent padding="8px 20px">
              <PnlValue selectedToken={selectedHolding} showSendButton />
            </PaddingComponent>
          </>
        )}
        <Divider margin="8px 16px 0 16px" />
        <ChartAndPosition
          identifier={identifier}
          name={getCurrentTokenName()}
          slug={getCurrentTokenSlug()}
          allTokenTransfers={allTokenTransfers}
          chainId={selectedHolding?.str_chain_id}
          tokenAddress={selectedHolding?.token_address ?? selectedProtocolToken}
          getChartMarkers={getChartMarkers}
        />
      </CustomColumn>
      {selectedHolding?.is_native_token ? (
        <PaddingComponent padding="16px">
          <UsersTokenTransfersList
            identifier={identifier}
            holding={selectedHolding}
            setAllTokenTransfers={setAllTokenTransfers}
            onNewData={(data) => {
              if (data?.transfers) {
                updateMarkers(
                  getMarkers({
                    recentTokenTransfers: data.transfers,
                    identifier,
                  })
                );
              }
            }}
            tokenAddress={getCurrentTokenAddress()}
          />
        </PaddingComponent>
      ) : (
        <CustomColumn gap="8px" padding="0 20px">
          <CustomRow gap="8px" width="100%">
            <UsersTokenTransfersList
              identifier={identifier}
              holding={selectedHolding}
              setAllTokenTransfers={setAllTokenTransfers}
              onNewData={(data) => {
                if (data?.transfers) {
                  updateMarkers(
                    getMarkers({
                      recentTokenTransfers: data.transfers,
                      identifier,
                    })
                  );
                }
              }}
              tokenAddress={getCurrentTokenAddress()}
            />
          </CustomRow>
          {selectedHolding?.type !== "Protocol" && !isSolana && !isBitcoin && (
            <TopUsers
              address={getCurrentTokenAddress()}
              chainId={selectedHolding?.str_chain_id}
              borderPosition="bottom"
              giveTopPadding
            />
          )}
        </CustomColumn>
      )}
    </div>
  );
};

const Header = ({ activeTab, setActiveTab, onClose }) => {
  return (
    <CustomRow flexShrink={0} padding="16px 20px 0px" alignItems="center">
      <CustomRow flexGrow={1} alignItems="flex-end">
        <CustomLinkTabBar
          zIndex={TABBAR_ZINDEX.REGULAR}
          isEnabled
          isLinkTabBar={false}
          activeTab={activeTab}
          setActiveTab={(index) => {
            setActiveTab(index);
          }}
          size={SIZE.LARGE}
          tabsList={["Top Holdings", "Recent Holdings"]}
        />
      </CustomRow>
      <CustomColumn flexShrink={0} alignItems="center" justifyContent="center">
        <TransparentButton onClick={onClose}>
          <CrossIcon></CrossIcon>
        </TransparentButton>
        <PaddingComponent height="8px" />
      </CustomColumn>
    </CustomRow>
  );
};

const CurrentHolding = ({ holding, chainProfiles }) => {
  return (
    <PaddingComponent padding="16px 20px 0px">
      <InfoBox
        alignItems="center"
        background="var(--elevation-1)"
        borderWidth="0px">
        <CustomRow width="100%" flexShrink={0}>
          <CustomRow gap="8px" alignItems="center" flexGrow={1}>
            <Link
              to={
                holding?.token_details?.link
                  ? holding?.token_details?.link
                  : "/c/" +
                    holding?.str_chain_id +
                    "/" +
                    holding?.token_address +
                    "/token"
              }
              target="_blank"
              className={classes.view_protocol}>
              <CustomRow gap="4px" alignItems="center">
                <CircleAvatar src={holding?.display_image}></CircleAvatar>
                <CustomText
                  fontSize="15px"
                  color="var(--text-1)"
                  text={
                    holding?.token_details?.symbol || holding?.display_name
                  }></CustomText>
              </CustomRow>
            </Link>
            <CustomText text="on" color="var(--text-2)" />
            <Link
              to={"/" + holding?.str_chain_id}
              target="_blank"
              className={classes.view_protocol}>
              <CustomRow gap="4px" alignItems="center">
                <CircleAvatar
                  src={
                    chainProfiles?.[holding?.str_chain_id]?.logo_uri
                  }></CircleAvatar>
                <CustomText
                  fontSize="15px"
                  color="var(--text-1)"
                  text={holding?.str_chain_id}></CustomText>
              </CustomRow>
            </Link>
          </CustomRow>
          <CustomRow gap="8px" alignItems="center">
            <PriceChange
              value={holding?.price_change_24hr?.value}
              displayValue={
                holding?.price_change_24hr?.display_value
              }></PriceChange>
            <CustomText
              fontSize="15px"
              color="var(--text-1)"
              text={holding?.price_usd?.display_value}></CustomText>
          </CustomRow>
        </CustomRow>
      </InfoBox>
    </PaddingComponent>
  );
};

const CurrentProtocolHolding = ({
  holding,
  selectedProtocolToken,
  setSelectedProtocolToken,
}) => {
  return (
    <PaddingComponent padding="16px 20px 0px">
      <InfoBox alignItems="center">
        <CustomRow width="100%" flexShrink={0}>
          <CustomRow gap="8px" alignItems="center" flexGrow={1}>
            <Link
              to={"/p/" + holding?.display_name}
              target="_blank"
              className={classes.view_protocol}>
              <CustomRow gap="4px" alignItems="center">
                <CircleAvatar src={holding?.display_image}></CircleAvatar>
                <CustomText
                  fontSize="15px"
                  color="var(--text-1)"
                  text={holding?.display_name}></CustomText>
              </CustomRow>
            </Link>
            <PositionCategories
              positions={holding?.holding?.holdings_by_label}
              categoryColumnWidth={"500px"}
              wrap={true}
            />
          </CustomRow>
        </CustomRow>
      </InfoBox>
      <div className={classes.tokenSelectorList}>
        {holding?.tokens?.map((token, tokenIndex) => {
          const isSelected = selectedProtocolToken === token;
          return (
            <CustomRow
              gap="4px"
              key={"protocol-token" + tokenIndex}
              onClick={() => setSelectedProtocolToken(token)}
              className={`${classes.tokenSelectorItem} ${
                isSelected ? classes.selectedTokenSelectorItem : ""
              }`}>
              <Image
                imageType="small_token"
                src={holding?.token_icons?.[tokenIndex]}
                className={classes.tokenImageSmall}
              />
              <CustomText
                text={holding?.token_slugs?.[tokenIndex]}
                textOverflow="ellipsis"
                fontWeight={isSelected ? "600" : "500"}
                fontSize="13px"
                color="var(--text-1)"
                overflow="hidden"
                whiteSpace="nowrap"
              />
            </CustomRow>
          );
        })}
      </div>
    </PaddingComponent>
  );
};

const ChartAndPosition = ({ chainId, tokenAddress, getChartMarkers }) => {
  return (
    <PaddingComponent padding="16px 20px">
      <TradingViewGraph
        address={tokenAddress}
        chain={chainId}
        showPriceHeader
        getMarkers={getChartMarkers}
      />
    </PaddingComponent>
  );
};

export default MyHoldingsRightPanel;
