import { CaretRight } from "@phosphor-icons/react";
import { getNetworthGraphData } from "api/profile.api";
import { useQuery } from "@tanstack/react-query";
import {
  CustomRow,
  CustomText,
  Divider,
  InfoBox,
  PaddingComponent,
  PriceChange,
  SpinnerComponent,
} from "components/UI/Components/Components";
import CustomDropdownButton from "components/UI/Components/CustomDropDown";
import Refetch from "components/UI/Refetch/Refetch";
import NetworthChartModal from "components/V2/Profile/NetworthChartModal/NetworthChartModal";
import Graph from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/Graph";
import GraphLoader from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";
import { AuthContext } from "contextStates/AuthContext";
import { Fragment, useContext, useMemo, useState } from "react";
import ConditionalLink from "shared/ConditionalLink";
import { Chart } from "utils/constants";
import { useNetworth } from "../apis/useNetworth";
import classes from "./TotalNetworth.module.css";
import { QUERY_KEYS } from "utils/query_utils";
import ZxActor from "zxComponents/common/ZxActor/ZxActor";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { trackEvent } from "utils/event_tracking";

const excludedDurations = [
  Chart.DURATION["3M"],
  Chart.DURATION["1Y"],
  Chart.DURATION["ALL"],
];

const TotalNetworth = () => {
  const { identityDetails } = useContext(AuthContext);
  const identifier = identityDetails.current?.identity?.username;
  const { data, isFetching } = useNetworth({
    identifier: identifier,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [graphDuration, setGraphDuration] = useState(Chart.DURATION["7D"]);
  const {
    data: queryGraphData,
    isLoading: isGraphLoading,
    isFetching: isGraphFetching,
    isError: isGraphError,
    refetch: refetchGraphData,
  } = useQuery({
    queryKey: QUERY_KEYS.NETWORTH_GRAPH({
      identity: identifier,
      duration: graphDuration,
    }),
    queryFn: () =>
      getNetworthGraphData({
        identifier,
        forProtocolTreasury: false,
        duration: Chart.DURATION_DAYS[graphDuration],
      }),
    staleTime: 0,
  });
  const durationDropdown = useMemo(() => {
    return (
      <CustomDropdownButton
        title={graphDuration}
        items={Object.values(Chart.DURATION).filter(
          (item) => !excludedDurations.includes(item)
        )}
        customClass={classes.dropdownButton}
        customClassList={classes.dropdownList}
        fontSize={"12px"}
        color={"var(--text-2)"}
        size="small"
        onSelectItem={(item) => {
          setGraphDuration(item);
        }}
      />
    );
  }, [graphDuration]);
  return (
    <Fragment>
      <NetworthChartModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        identifier={identifier}
        graphData={queryGraphData}
        refetchNetWorth={refetchGraphData}
        netWorth={data?.data?.data?.["_Any"]}
        showLink={true}
      />
      <InfoBox padding="16px" radius="8px" borderColor="var(--elevation-1)">
        <ConditionalLink
          to={`/${identityDetails.current?.identity?.username}/activities`}
          onClickTrackingEvent={() => {
            trackEvent("networth_home_card_clicked");
          }}>
          <CustomRow justifyContent="space-between">
            <ZxActor
              actor={identityDetails.current?.identity}
              showBio={false}
              showFollowButton={false}
              nameLength={15}
              infoRowGap="1px"
              dpBorderRadius="4px"
              showAlias
              disableLink
            />
            <CaretRight size={16} color="var(--text-3)" weight="bold" />
          </CustomRow>
        </ConditionalLink>
        <Divider margin="12px 0" />
        <CustomRow justifyContent="space-between" alignItems="center">
          <ZxText style={TSTYLES.secondaryLabel}>TOTAL NETWORTH</ZxText>
          {durationDropdown}
        </CustomRow>
        <NetworthDetails
          networthData={data?.data?.data?.["_Any"]?.total_usd_value}
          priceChange={queryGraphData?.total_percent_change}
        />
        {isFetching && data != null && (
          <PaddingComponent>
            <SpinnerComponent />
          </PaddingComponent>
        )}
        <PaddingComponent height="8px" />
        {isGraphLoading ? (
          <GraphLoader height={40} />
        ) : queryGraphData != null ? (
          <div style={{ cursor: "pointer" }}>
            {isGraphLoading ? (
              <GraphLoader height={40} />
            ) : isGraphError ? (
              <Refetch
                onClick={() => {
                  refetchGraphData();
                }}
                containerClass={classes.graph_component_refetch}
                isLoading={isGraphFetching}
              />
            ) : queryGraphData != null ? (
              <div className={classes.graph} onClick={toggleModal}>
                <Graph
                  graphData={queryGraphData}
                  margin={{ left: 0, right: 0, bottom: 0, top: 0 }}
                  parentWidth={266}
                  parentHeight={80}
                  isXAxisVisible={false}
                  isYAxisVisible={false}
                  horizontalGridLines={0}
                  verticalGridLines={0}
                  gradientFill={true}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </InfoBox>
    </Fragment>
  );
};
const NetworthDetails = ({ networthData, priceChange }) => {
  const value = networthData?.display_value;
  return (
    <CustomRow gap="8px">
      <CustomText
        letterSpacing="-0.6px"
        text={value}
        fontWeight="600"
        color="var(--text-1)"
        fontSize="20px"
      />
      {priceChange && priceChange?.value && (
        <PriceChange
          value={priceChange?.value}
          displayValue={priceChange?.display_value}
        />
      )}
    </CustomRow>
  );
};

export default TotalNetworth;
