import classes from "./TopUsers.module.css";
import { useState } from "react";
import CustomLinkTabBar, {
  SIZE,
} from "components/UI/Components/CustomLinkTabBar";
import useTopHolders from "./useTopHolders";
import useTopBuyersAndSellers from "./useTopBuyersAndSellers";
import {
  TOP_USERS_TABS_LIST as TABLIST,
  TOP_USERS_TAB_KEYS as TABKEYS,
} from "utils/constants";
import {
  CustomRow,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import { TableProfile } from "components/V2/Explorer/SelectedEdge";
import { Funnel } from "@phosphor-icons/react";
import ZxTable from "zxComponents/common/ZxTable/ZxTable";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";

const DURATION = {
  "1D": 1,
  "1W": 7,
  "1M": 30,
};

const getColumns = ({ tab, showFilter, updateFilter, filter }) => {
  const getProfileColumn = () => {
    return {
      title: "RANK / NAME",
      align: "flex-start",
      key: "rank",
      width: "220px",
      render: (row, { index }) => {
        const profile = row?.actor_details;
        return (
          <CustomRow gap="12px">
            <CustomText
              fontSize="13px"
              text={index + 1}
              color="var(--text-2)"
              width="20px"
            />
            <CustomRow>
              <TableProfile
                profile={profile}
                abbreviateLength={10}
                width="125px"
              />
              {showFilter && (
                <Funnel
                  color="var(--text-2)"
                  weight={filter === row.address ? "fill" : "regular"}
                  style={{ cursor: "pointer", justifySelf: "flex-start" }}
                  onClick={() => {
                    updateFilter(profile);
                  }}
                />
              )}
            </CustomRow>
          </CustomRow>
        );
      },
    };
  };
  if (tab === "top-holders") {
    return [
      getProfileColumn(),
      {
        title: "Supply %",
        align: "flex-end",
        key: "balance",
        width: "140px",
        render: (row) => {
          return (
            <CustomText
              fontSize="13px"
              text={`${row?.percent_of_supply?.display_value}%`}
              textAlign="left"
              color="var(--text-1)"
            />
          );
        },
      },
      {
        title: "Balance",
        align: "flex-end",
        key: "balance",
        width: "180px",
        render: (row) => {
          return (
            <CustomText
              fontSize="13px"
              color="var(--text-2)"
              text={row?.balance?.display_value}
              textAlign="right"
            />
          );
        },
      },
      {
        title: "Invested",
        align: "flex-end",
        key: "invested",
        width: "120px",
        render: (row) => {
          return (
            <CustomText
              fontSize="13px"
              color="var(--text-1)"
              text={row?.usd_invested?.display_value ?? "-"}
              textAlign="right"
            />
          );
        },
      },
      {
        title: "USD Value",
        align: "flex-end",
        key: "value",
        width: "120px",
        render: (row) => {
          return (
            <CustomText
              fontSize="13px"
              color="var(--text-1)"
              text={row?.usd_value?.display_value}
              textAlign="right"
            />
          );
        },
      },
    ];
  }
  if (tab === "top-sellers") {
    return [
      getProfileColumn(),
      {
        title: "SOLD VALUE / AMOUNT",
        align: "flex-end",
        width: "280px",
        render: (row) => {
          return (
            <CustomRow gap="4px">
              <CustomText
                text={row.trade_usd_value?.display_value ?? "-"}
                fontWeight="400"
                color="var(--text-1)"
              />
              <CustomText text={"/"} color="var(--text-2)" />
              <CustomText
                text={row.trade_amount?.display_value ?? "-"}
                color="var(--text-2)"
              />
            </CustomRow>
          );
        },
      },
      {
        title: "CURRENT VALUE / BALANCE",
        width: "280px",
        align: "flex-end",
        render: (row) => {
          return (
            <CustomRow gap="4px">
              <CustomText
                text={row.usd_value?.display_value ?? "-"}
                fontWeight="400"
                color="var(--text-1)"
              />
              /
              <CustomText
                text={row.balance?.display_value ?? "-"}
                color="var(--text-2)"
              />
            </CustomRow>
          );
        },
      },
    ];
  }
  if (tab === "top-buyers") {
    return [
      getProfileColumn(),
      {
        title: "AMOUNT",
        align: "flex-end",
        key: "balance",
        width: "280px",
        render: (row) => {
          return (
            <CustomText
              fontSize="13px"
              text={row.balance?.display_value ?? "-"}
              color="var(--text-2)"
            />
          );
        },
      },
      {
        title: "USD Value",
        align: "flex-end",
        key: "value",
        width: "280px",
        render: (row) => {
          return (
            <CustomText
              fontSize="13px"
              text={row.trade_usd_value?.display_value}
              color="var(--text-1)"
            />
          );
        },
      },
    ];
  }
};

const TopUsers = ({
  address,
  chainId,
  borderPosition = "top",
  showTabBar = true,
  customTab = null,
  giveTopPadding = false,
  size = SIZE.LARGE,
  noPadding = false,
  showFilter = false,
  updateFilter = () => {},
  filter = null,
  disableDuration = false,
}) => {
  const [tab, setTab] = useState(0);
  const [duration, setDuration] = useState(DURATION["1D"]);
  const {
    data: topHolders,
    isLoading: isTopHoldersLoading,
    isFetching: isTopHoldersFetching,
    isError: isErrorInTopHolders,
    refetch: refetchTopHolders,
  } = useTopHolders(address, chainId);

  const {
    topBuyers,
    topSellers,
    isLoading: isTopSellersBuyersLoading,
    isFetching: isTopSellersBuyersFetching,
    isError: isErrorInTopSellersBuyer,
    refetch: refetchTopSellersBuyers,
  } = useTopBuyersAndSellers(address, chainId, duration);
  const loaderForTopHolders = isTopHoldersLoading || isTopHoldersFetching;
  const loaderForTopSellersBuyers =
    isTopSellersBuyersLoading || isTopSellersBuyersFetching;

  const activeTab = customTab ? TABLIST.findIndex((t) => t === customTab) : tab;
  const tabKey = TABKEYS[activeTab].key;
  const filterProps = { filter, updateFilter, showFilter };
  const getTableMetaProps = () => {
    return {
      data:
        tabKey === "top-holders"
          ? topHolders
          : tabKey === "top-buyers"
            ? topBuyers ?? []
            : topSellers ?? [],
      isLoading:
        tabKey === "top-holders"
          ? loaderForTopHolders
          : loaderForTopSellersBuyers,
      isError:
        tabKey === "top-holders"
          ? isErrorInTopHolders
          : isErrorInTopSellersBuyer,
      refetch:
        tabKey === "top-holders" ? refetchTopHolders : refetchTopSellersBuyers,
    };
  };
  return (
    <PaddingComponent
      className={!noPadding ? classes.top_users : ``}
      paddingTop={noPadding ? 0 : giveTopPadding ? "8px" : 0}>
      {showTabBar && (
        <PaddingComponent padding="8px 0">
          <CustomLinkTabBar
            activeTab={activeTab}
            tabsList={TABLIST}
            isLinkTabBar={false}
            setActiveTab={(index) => {
              setTab(index);
            }}
            isBorderBottom={false}
            position={borderPosition}
            size={size}></CustomLinkTabBar>
        </PaddingComponent>
      )}
      {!disableDuration && tabKey !== "top-holders" && (
        <PaddingComponent paddingBottom="8px">
          <CustomRow gap="8px">
            {Object.keys(DURATION).map((key) => {
              const isActive = duration === DURATION[key];
              return (
                <div
                  key={key}
                  className={`${classes.durationPill} ${isActive ? classes.activeDuration : ``}`}
                  onClick={() => {
                    setDuration(DURATION[key]);
                  }}>
                  <CustomText
                    fontWeight="500"
                    text={key}
                    color={!isActive ? "var(--text-2)" : "var(--primary-color)"}
                  />
                </div>
              );
            })}
          </CustomRow>
        </PaddingComponent>
      )}
      <ZxTable
        columns={getColumns({ tab: tabKey, ...filterProps })}
        {...getTableMetaProps()}
        infiniteScroll={false}
      />
    </PaddingComponent>
  );
};

export default withErrorBoundary(TopUsers);
