import { CustomRow, CustomText } from "components/UI/Components/Components";
import classes from "./graphV2.module.css";

import { forwardRef } from "react";
import { abbreviateNumber, formatUnixTimestamp } from "utils/misc";

const Tooltip = forwardRef(
  ({ currentPoint, name, isLineChart, customDisplayFunction = null }, ref) => {
    if (!currentPoint) {
      return null;
    }
    if (!isLineChart) {
      return (
        <div
          ref={ref}
          className={`${classes.tooltipContainer} ${classes.ohlc}`}>
          {!!name && <div className={classes.name}>{name}</div>}

          <CustomRow gap="2px" alignItems="baseline">
            <div className={classes.name}>Open:</div>
            <CustomText
              text={`$${abbreviateNumber(currentPoint?.open)}`}
              fontSize="16px"
              fontWeight="600"
              color="var(--text-1)"
            />
          </CustomRow>

          <CustomRow gap="1px" alignItems="baseline">
            <div className={classes.name}>High:</div>
            <CustomText
              text={`$${abbreviateNumber(currentPoint?.high)}`}
              fontSize="16px"
              fontWeight="600"
              color="var(--text-1)"
            />
          </CustomRow>

          <CustomRow gap="1px" alignItems="baseline">
            <div className={classes.name}>Low:</div>
            <CustomText
              text={`$${abbreviateNumber(currentPoint?.low)}`}
              fontSize="16px"
              fontWeight="600"
              color="var(--text-1)"
            />
          </CustomRow>

          <CustomRow gap="1px" alignItems="baseline">
            <div className={classes.name}>Close:</div>
            <CustomText
              text={`$${abbreviateNumber(currentPoint?.close)}`}
              fontSize="16px"
              fontWeight="600"
              color="var(--text-1)"
            />
          </CustomRow>

          <div className={classes.timeStamp}>
            {formatUnixTimestamp(currentPoint?.time)}
          </div>
        </div>
      );
    }
    return (
      <div ref={ref} className={classes.tooltipContainer}>
        {!!name && <div className={classes.name}>{name}</div>}
        <div className={classes.price}>
          {customDisplayFunction
            ? customDisplayFunction(currentPoint?.value)
            : `$${abbreviateNumber(currentPoint?.value)}`}
        </div>
        <div className={classes.timeStamp}>
          {formatUnixTimestamp(currentPoint?.time)}
        </div>
      </div>
    );
  }
);

export default Tooltip;
