import { ClickAwayListener } from "@mui/material";
import React, { useRef, useState } from "react";
import searchIcon from "assets/svg/header/search_icon.svg";
import styles from "./FilterSearchBar.module.css";
import { debounce } from "lodash";

const FiltersSearchBar = ({
  isSearchInputFocused,
  setIsSearchInputFocused,
  setSearchTerm,
  title = "Search Tokens",
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    delayedQuery(value);
  };
  const delayedQuery = useRef(
    debounce((q) => {
      setSearchTerm(q);
    }, 200)
  ).current;

  return (
    <ClickAwayListener onClickAway={() => setIsSearchInputFocused(false)}>
      <div
        className={`${styles.searchInputContainer} ${
          isSearchInputFocused ? styles.focus : ""
        }`}>
        <div className={styles.searchInput}>
          <img
            className={styles.searchIcon}
            src={searchIcon}
            alt="search icon"
          />
          <input
            type="text"
            placeholder={title}
            value={inputValue}
            // ref={inputRef}
            onChange={handleInputChange}
            onFocus={() => setIsSearchInputFocused(true)}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default FiltersSearchBar;
