import { AuthContext } from "contextStates/AuthContext";
import { ThemeContext } from "contextStates/Theme.context";
import { useContext, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom";
import { SemiProtectedRoute } from "shared/semiprotected.route";
import { isTestingOnboarding } from "utils/constants";
import TopProfiles from "../Onboarding/components/TopProfiles";
import TweetAboutUs from "../Onboarding/components/TweetAboutUs";
import BioDetails from "./BioDetails/BioDetails";
import ConnectWallet from "./ConnectWallet/ConnectWallet";
import ConnectedWallets from "./ConnectedWallets/ConnectedWallets";
import DownloadApp from "./DownloadApp/DownloadApp";
import FailedWallet from "./FailedWallet/FailedWallet";
import InfoSlider from "./InfoSlider/InfoSlider";
import InviteCode from "./InviteCode/InviteCode";
import SocialLogins from "./SocialLogins/SocialLogins";
import TalkToUs from "./TalkToUs/TalkToUs";
import WalletLoader from "./WalletLoader/WalletLoader";
import useQueryParams from "customHooks/useQueryParams";
import Loader from "components/UI/Loader/Loader";
import {
  CustomColumn,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";

import styles from "./NewOnboarding.module.css";
import useNavigateNewOnboarding from "./NavigateOnboarding";
import DynamicWalletProvider from "components/DynamicWalletProvider/DynamicWalletProvider";
import { trackEvent } from "utils/event_tracking";
import FeatureGate from "../FeatureGate/FeatureGate";
const CONNECT_TYPES = {
  LOGIN: "login",
  SIGNUP: "signup",
};
const NewOnboarding = () => {
  const [connectType, setConnectType] = useState(CONNECT_TYPES.LOGIN);
  const { identityDetails, isUserLoggedIn, isUserAuthenticated } =
    useContext(AuthContext);
  const { setStoredTheme, theme } = useContext(ThemeContext);
  const params = useQueryParams();
  const [navigateOnboarding, setNavigateOnboarding] = useState(false);
  useNavigateNewOnboarding(navigateOnboarding, setNavigateOnboarding);

  let match = useRouteMatch();
  let location = useLocation();
  const history = useHistory();
  const currentStep = location.pathname
    .replace(match.path, "")
    .replace("/", "");

  useEffect(() => {
    if (currentStep === "complete") {
      isUserAuthenticated();
      history.push("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, history]);

  useEffect(() => {
    if (theme !== "light") {
      setStoredTheme("light");
    }
  }, [setStoredTheme, theme]);

  useEffect(() => {
    if (isTestingOnboarding) {
      return;
    }
    const currentStep = identityDetails?.current?.onboarding_state?.current;
    const identity_id = identityDetails?.current?.identity?.id;
    if (currentStep !== undefined && identity_id) {
      if (currentStep === "complete") {
        trackEvent("User logged in", null);
        history.push(`/${identity_id}/portfolio`);
        return;
      } else {
        const currRoute = `/onboarding/${currentStep}`;
        history.push(currRoute);
      }
    }
  }, [identityDetails, history, isUserLoggedIn]);

  const isRedirected = params?.get("redirect") === "true";
  useEffect(() => {
    if (isRedirected) {
      setNavigateOnboarding(true);
    }
  }, [isRedirected]);
  if (isRedirected) {
    return (
      <CustomColumn gap="24px">
        <PaddingComponent className={styles.redirectContainer}>
          <Loader />
        </PaddingComponent>
        <CustomText
          text="Your onboarding is incomplete. Redirecting you now..."
          color="var(--text-1)"
          fontWeight="500"
          fontSize="17px"
          lineHeight="25px"
        />
      </CustomColumn>
    );
  }

  return (
    <DynamicWalletProvider>
      <FeatureGate featureName="onboarding">
        <Switch>
          <Route path={`${match.path}/intro`}>
            <InfoSlider
              connectType={connectType}
              setConnectType={setConnectType}
            />
          </Route>
          <Route path={`${match.path}/connect_wallet`}>
            <ConnectWallet />
          </Route>
          <Route path={`${match.path}/connected_wallets`}>
            <ConnectedWallets />
          </Route>
          <SemiProtectedRoute path={`${match.path}/connect_socials`}>
            <SocialLogins />
          </SemiProtectedRoute>
          <SemiProtectedRoute path={`${match.path}/complete_profile`}>
            <BioDetails />
          </SemiProtectedRoute>
          <SemiProtectedRoute path={`${match.path}/add_invite_code`}>
            <InviteCode />
          </SemiProtectedRoute>
          <SemiProtectedRoute path={`${match.path}/follow_notables`}>
            <TopProfiles
              prevPath="/onboarding/manual_setup"
              key={`top-profiles-follow`}
              path="/api/0xppl/all_top_identities"
              updatePath="/api/0xppl/follow_multiple_identities"
              currentStep="follow_notables"
              nextStep={"/onboarding/follow_friends"}
              title="Follow Notables"
              description="Connect with top profiles onchain"
              isPeopleInteractedWith={false}
              isTopProfiles
            />
          </SemiProtectedRoute>
          <SemiProtectedRoute path={`${match.path}/follow_friends`}>
            <TopProfiles
              prevPath="/onboarding/pending_top_profiles_follow"
              key={`twitter-profiles-follow`}
              title="Follow From Your Network"
              path="/api/0xppl/get_profiles_to_follow"
              updatePath="/api/twitter/follow_multiple_twitters"
              currentStep="follow_friends"
              nextStep="/onboarding/tweet_abt_0xppl"
              isTwitterProfiles={true}
            />
          </SemiProtectedRoute>
          <SemiProtectedRoute path={`${match.path}/tweet_abt_0xppl`}>
            <TweetAboutUs prevPath="pending_twitter_profiles_follow" />
          </SemiProtectedRoute>
          <SemiProtectedRoute path={`${match.path}/download_app`}>
            <DownloadApp />
          </SemiProtectedRoute>
          <SemiProtectedRoute path={`${match.path}/wallet_checklist`}>
            <FailedWallet />
          </SemiProtectedRoute>
          <SemiProtectedRoute path={`${match.path}/wallet_loader`}>
            <WalletLoader />
          </SemiProtectedRoute>
          <Route path={`${match.path}/talk_to_us`}>
            <TalkToUs />
          </Route>
          <Route path={`${match.path}/`}>
            <Redirect to={`${match.path}/intro`} />
          </Route>
        </Switch>
      </FeatureGate>
    </DynamicWalletProvider>
  );
};

export default NewOnboarding;
