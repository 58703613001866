import classes from "./TransactionInternalCalls.module.css";
import {
  InfoBox,
  PaddingComponent,
  CustomColumn,
  CustomRow,
} from "components/UI/Components/Components";
import { useCallback, useState } from "react";
import ChevronUpIcon from "assets/svg/chevron_up.svg";
import ChevronDownIcon from "assets/svg/chevron_down.svg";
import FeedDescription from "components/GroupFeedItem/components/FeedDescription";
import TransactionInternalCallAdditionalDetails from "./TransactionInternalCallAdditionalDetails";

const getTraceAddressString = (traceAddress) => {
  var tracesStr = "";
  traceAddress?.forEach((trace) => {
    tracesStr = `${tracesStr}_${trace}`;
  });
  return tracesStr;
};

const TransactionInternalCallsTile = ({
  action,
  internalTxn,
  txnData,
  index,
  type,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const changeExpanded = useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded, setExpanded]);
  const profile = getContractProfile(txnData, action);
  const shouldExpand = profile?.address_type !== "External";

  return (
    <PaddingComponent paddingBottom="10px">
      <CustomRow gap="4px">
        {!isExpanded &&
          internalTxn?.traceAddress?.map((trace, index) => {
            return (
              <div
                className={`${classes.borderSideContainer} ${
                  index === 0 ? "" : classes.borderSide
                }`}></div>
            );
          })}

        <InfoBox
          radius={
            internalTxn?.traceAddress.length === 0 || isExpanded
              ? "6px"
              : "0px 6px 6px 0px"
          }>
          <CustomColumn>
            <div
              onClick={changeExpanded}
              className={classes.tileContentContainer}>
              <span className={classes.subtitle}>{`${
                internalTxn?.type
              }${getTraceAddressString(internalTxn?.traceAddress)}`}</span>
              <div className={classes.templatingContainer}>
                <FeedDescription
                  templateString={action?.description ?? ""}
                  profiles={txnData?.profiles}
                  actor={null}
                  nativeTokensInfo={null}
                  methodIds={null}
                />
              </div>
              {shouldExpand && (
                <img
                  src={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                  alt={"expand"}
                />
              )}
            </div>
            {shouldExpand && isExpanded && (
              <TransactionInternalCallAdditionalDetails
                action={action}
                profile={profile}
                profiles={txnData?.profiles}
              />
            )}
          </CustomColumn>
        </InfoBox>
      </CustomRow>
    </PaddingComponent>
  );
};

const getContractProfile = (txnData, action) => {
  const address = action?.to;
  if (!address) return null;
  if (txnData?.profiles[address]) {
    return txnData?.profiles[address];
  }
  //else iterate through the profiles and find the one with the same address by comparing in lower case
  const profiles = Object.values(txnData?.profiles);
  for (let i = 0; i < profiles.length; i++) {
    if (profiles[i].address.toLowerCase() === address.toLowerCase()) {
      return profiles[i];
    }
  }
};

export default TransactionInternalCallsTile;
