import React from "react";
import { ActivityDetailsGhost } from "../ActivityDetails/ActivityDetailsGhost";

export const NewsDetailsGhost = () => {
  return (
    <ActivityDetailsGhost
      tabsList={[
        {
          title: "Transactions",
          link: "transactions",
        },
        {
          title: "Twitter",
          link: "twitter",
        },
      ]}
    />
  );
};
