import { useEffect, useState } from "react";
import { MOBILE_ONBOARDING_THRESHOLD } from "utils/constants";

// hook to get the width and height of the window
const useScreenRes = () => {
  const [screenRes, setScreenRes] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenRes({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    ...screenRes,
    isMobile: screenRes.width < MOBILE_ONBOARDING_THRESHOLD,
  };
};

export default useScreenRes;
