import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import classes from "./UserAvatar.module.css";
import { Link } from "react-router-dom";
import Image from "components/UI/Image";
import { ReactComponent as FarcasterIcon } from "assets/svg/feed/farcaster.svg";
import { ReactComponent as LensIcon } from "assets/svg/feed/lens.svg";
import ReactTimeago from "react-timeago";
import VerifiedBadge from "components/UI/Components/VerifiedBadge";
import { CustomRow } from "components/UI/Components/Components";

export const AVATAR_SIZES = {
  SMALL: "small",
  REGULAR: "regular",
  LARGE: "large",
};

export const AVATAR_TYPE = {
  USER: "user",
  LENS: "lens",
  FARCASTER: "farcaster",
};

const UserAvatar = ({
  actor,
  onActorUpdate = () => {},
  type,
  imageOnly = false,
  isOnline = false,
  lastActive,
  size = AVATAR_SIZES.REGULAR,
}) => {
  const display_name = actor?.display_name;

  return (
    <ProfileHoverCard actor={actor} onActorUpdate={onActorUpdate}>
      <Link
        to={
          actor?.username
            ? `/${actor?.username}`
            : actor.identity_link || actor.link || "/" + actor.identity_id
        }
        onClick={(e) => e.stopPropagation()}
        className={classes.user_avatar}>
        <div className={classes.avatarWrapper}>
          <Image
            className={classes.avatar}
            src={actor.display_picture}
            alt={actor.display_name}
          />
          {type === AVATAR_TYPE.FARCASTER && (
            <div className={classes.avatarBadge}>
              <FarcasterIcon className={classes.repliedBadge} />
            </div>
          )}
          {type === AVATAR_TYPE.LENS && (
            <div className={classes.avatarBadge}>
              <LensIcon className={classes.repliedBadge} />
            </div>
          )}
          {isOnline && <div className={classes.presence} />}
        </div>

        {!imageOnly && (
          <div className={classes.info}>
            <div className={classes.name}>
              {display_name}
              <VerifiedBadge profile={actor} />
            </div>
            <CustomRow alignItems="center" gap="4px">
              <div className={classes.aliases}>
                {actor?.username?.length > 0 && (
                  <span className={classes.alias}>
                    {"@"}
                    {actor?.username}
                    {" · "}
                  </span>
                )}
                {Object.keys(actor?.aliases || [])
                  .slice(0, 3)
                  .map((alias, index) => {
                    return (
                      <span key={index} className={classes.alias}>
                        {alias}
                        {index !== Object.keys(actor?.aliases).length - 1 &&
                          ", "}
                      </span>
                    );
                  })}
              </div>
              {lastActive ? (
                <div className={classes.lastActive}>
                  <ReactTimeago date={lastActive} />
                </div>
              ) : (
                ""
              )}
            </CustomRow>
          </div>
        )}
      </Link>
    </ProfileHoverCard>
  );
};
export default UserAvatar;
