import Mousetrap from "mousetrap";
import { useEffect } from "react";

export const useBindKeys = ({ handleNextImage, handlePrevImage, onClose }) => {
  useEffect(() => {
    // Bind right arrow to go to next image
    Mousetrap.bind("right", () => {
      handleNextImage();
    });

    // Bind left arrow to go to previous image
    Mousetrap.bind("left", () => {
      handlePrevImage();
    });

    // Bind escape to close the modal
    Mousetrap.bind("esc", () => {
      onClose();
    });

    return () => {
      // Clean up function to unbind all the key events when the component is unmounted
      Mousetrap.unbind("right");
      Mousetrap.unbind("left");
      Mousetrap.unbind("esc");
    };
  }, [handleNextImage, handlePrevImage, onClose]); // Empty dependencies array ensures this effect runs once on mount and cleans up on unmount
};
