import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { signedRequest } from "api/api";

const fetchProfileNftsHoldings = async ({ identifier, selectedGroupIds }) => {
  let endpoint = `/api/v4/get_wallet_nft_holdings?identifier=${identifier}`;
  if (selectedGroupIds?.length > 0) {
    const groups = selectedGroupIds.join(",");
    endpoint = `${endpoint}&group_ids=${groups}`;
  }
  const res = await signedRequest({
    method: "get",
    path: endpoint,
  });

  return res?.data?.data;
};

const useProfileNftHoldings = ({ identifier, selectedGroupIds, enabled }) => {
  const queryOptions = useMemo(
    () => ({
      enabled: enabled && !!identifier,
      retry: 3,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }),
    [identifier, enabled]
  );

  const query = useQuery({
    queryKey: ["get_profile_nft_holdings", identifier, selectedGroupIds],
    queryFn: (props) =>
      fetchProfileNftsHoldings({
        ...props,
        identifier,
        selectedGroupIds,
      }),
    ...queryOptions,
  });

  return query;
};

export default useProfileNftHoldings;
