import classes from "./TopTokenCard.module.css";
import useTokenHoldings from "../Profile/ProfileDetails/Portfolio/TokenHoldings/useTokenHoldings";
import NetWorthCardToken from "./NetWorthCardToken";
import Ghost from "./Ghost";
import useSmallBalanceFilter from "components/V2/Profile/ProfileDetails/Portfolio/TokenHoldings/useSmallBalanceFilter";

const TokenCard = ({ title, data, chainProfiles }) => {
  const { chainTokenHoldings } = useSmallBalanceFilter(data?.holdings, "");

  return (
    <div className={`${classes.net_worth_card}`}>
      <div className={classes.top}>
        <div className={classes.title}>{title}</div>
        <div className={classes.bar}></div>
      </div>
      <div className={classes.bottom}>
        {chainTokenHoldings &&
          chainTokenHoldings.length > 0 &&
          chainTokenHoldings.slice(0, 5).map((token, index) => {
            return token.total_usd_value?.value > 0 ? (
              <div key={`networth-key-${index}`}>
                <NetWorthCardToken
                  token={token}
                  chainProfiles={chainProfiles}
                />
              </div>
            ) : null;
          })}
      </div>
    </div>
  );
};

const TopTokenCard = ({ identifier, selectedActiveTokens }) => {
  const { data, isLoading } = useTokenHoldings({
    identifier,
    maxHoldings: 5,
    forProtocolTreasury: false,
  });
  // filter out tokens that are in selectedActiveTokens
  const highlightedTokens = data?.holdings.filter((token) => {
    return selectedActiveTokens.includes(token.contract_address);
  });
  if (isLoading) {
    return <Ghost />;
  }

  const components = [];
  if (highlightedTokens?.length > 0) {
    components.push(
      <TokenCard
        holdings={highlightedTokens}
        chainProfiles={data?.chain_profiles}
        key="highlighted-tokens"
        title="Selected Tokens"
      />
    );
  }
  components.push(
    <TokenCard
      data={data}
      chainProfiles={data?.chain_profiles}
      key="top-tokens"
      title="Top Tokens"
    />
  );

  return <>{components}</>;
};

export default TopTokenCard;
