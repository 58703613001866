import React from "react";
import Modal from "@mui/material/Modal";
import { ReactComponent as CloseModalIcon } from "assets/svg/feed/close_modal_icon.svg";
import classes from "./newCommentModal.module.css";
import {
  ancestorsToQueryKeys,
  transformGroupedActivityQuery,
} from "utils/misc";
import NewRepost from "components/Feed/NewPost/Components/NewRepost";

const NewRepostModal = ({
  isModalOpen = false,
  setIsModalOpen = () => {},
  contentId,
  contentType,
  postAncestors,
  contentTimestamp,
}) => {
  const handleClose = () => setIsModalOpen?.(false);
  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <div className={classes.modalContainer}>
        <div className={classes.modalTitleWrapper} id="modal-modal-title">
          <button className={classes.close} onClick={handleClose}>
            <CloseModalIcon />
          </button>
          <div className={classes.title}>Repost</div>
        </div>
        <div className={classes.modalContentWrapper}>
          <NewRepost
            contentId={contentId}
            contentType={contentType}
            isEditorFocused={true}
            onSuccess={handleClose}
            onDiscard={handleClose}
            isModal
            queryKeys={[
              transformGroupedActivityQuery(
                contentId,
                contentType,
                contentTimestamp
              ),
              ...ancestorsToQueryKeys(postAncestors),
            ]}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewRepostModal;
