import React, { useContext } from "react";
import classes from "./DraftPostList.module.css";
import withLoader from "../../core/withLoader";
import { getPendingPosts } from "api/feed.api";
import { ONE_DAY } from "utils/constants";
import { PostPreview } from "./ScheduledPostList";
import noDraftGraphic from "assets/svg/no_drafts.svg";
import noDraftGraphicDark from "assets/svg/no_drafts_dark.svg";
import { ThemeContext } from "../../../contextStates/Theme.context";

export const DraftPostListInner = ({ queryData }) => {
  const { theme } = useContext(ThemeContext);
  if (queryData.draft_threads.length === 0) {
    return (
      <div className={classes.container}>
        <div className={classes.noDataContainer}>
          <img
            src={theme !== "dark" ? noDraftGraphic : noDraftGraphicDark}
            alt="No drafts"
          />
          <span>No drafts</span>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      {queryData.draft_threads.map((post) => {
        return <PostPreview key={post.id} post={post} isDraft={true} />;
      })}
    </div>
  );
};

export const DraftPostList = withLoader(DraftPostListInner, getPendingPosts, {
  queryKey: () => ["pendingPosts"],
  queryOptions: () => {
    return { staleTime: ONE_DAY };
  },
});
