import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const InputColumnTextField = styled(TextField)({
  "& ::placeholder": {
    padding: "0px",
    color: "var(--text-3)",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "150%",
    letterSpacing: "-0.02em",
    opacity: "1",
  },
  "& input": {
    padding: "0px",
    color: "var(--text-2)",
    fontWeight: "500",
    fontSize: "15px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    lineHeight: "150%",
    letterSpacing: "-0.02em",
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    "& MuiInputBase-input": {
      fontWeight: "500",
    },
  },
});

export default InputColumnTextField;
