import { useMemo, useRef, useState } from "react";

const useFilterSmallProtocolHoldings = ({
  protocolHoldings,
  activeChainId,
}) => {
  const smallTokensCount = useRef(0);
  const containsSmallProtocols = useMemo(
    () =>
      protocolHoldings?.holdings?.some((item) => {
        if (activeChainId)
          return (
            item?.chain_id === activeChainId &&
            item?.relative_percentage?.value < 1
          );
        else return item?.relative_percentage_across_chains?.value < 1;
      }),
    [activeChainId, protocolHoldings?.holdings]
  );

  const [isSmallProtocolsVisible, setIsSmallProtocolsVisible] = useState(false);

  const filteredProtocolHoldings = useMemo(() => {
    const percentageKey = activeChainId
      ? "relative_percentage"
      : "relative_percentage_across_chains";
    const holdings = protocolHoldings?.holdings?.filter((item) => {
      let relevantItem = true;

      if (activeChainId) {
        relevantItem = item?.chain_id === activeChainId;
      }

      if (!isSmallProtocolsVisible) {
        relevantItem = relevantItem && item[percentageKey]?.value > 1;
      }
      return relevantItem;
    });

    if (!isSmallProtocolsVisible)
      smallTokensCount.current =
        protocolHoldings?.holdings?.length - holdings?.length;

    return holdings;
  }, [activeChainId, isSmallProtocolsVisible, protocolHoldings?.holdings]);

  return {
    filteredProtocolHoldings,
    containsSmallProtocols,
    isSmallProtocolsVisible,
    setIsSmallProtocolsVisible,
    smallTokensCount: smallTokensCount.current,
  };
};

export default useFilterSmallProtocolHoldings;
