import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
  CustomText,
  Divider,
  FlexGrow,
  PaddingComponent,
  PriceChange,
} from "components/UI/Components/Components";
import { CaretRight } from "@phosphor-icons/react";
import classes from "../RightBar.module.css";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import { getTrimmedString } from "utils/misc";
import TokenHoldingsItem from "components/Pages/AppModule/RightSideBar/components/TokenHoldingsItem";
import ProtocolHoldingsItem from "components/Pages/AppModule/RightSideBar/components/ProtocolHoldingsItem";
import { LabeledValue } from "components/Pages/AppModule/RightSideBar/components/LabeledValue";

const ProtocolDelta = ({ data, hasProtocolDelta }) => {
  if (!hasProtocolDelta || !data?.total_usd_value_delta) {
    return null;
  }
  const change = data?.total_usd_value_delta;
  return (
    <PriceChange
      value={change.value}
      displayValue={change.display_value}
      fontWeight="400"
    />
  );
};

const MyHoldingsItem = ({ data, onClick }) => {
  return (
    <CustomColumn
      width="100%"
      onClick={() => onClick?.(data)}
      cursor="pointer"
      gap="4px"
      className={classes.holdingsItem}
      key={data?.str_chain_id + "_" + data?.address}
      padding="12px 0">
      {data?.type === "Token" && <TokenHoldingsItem data={data} />}
      {/*{data?.type === "NFT" && <NFTHoldingsItem data={data} />}*/}
      {data?.type === "Protocol" && <ProtocolHoldingsItem data={data} />}
    </CustomColumn>
  );
};

export const MyHoldingsItemOld = ({ data, onClick }) => {
  const hasProtocolDelta =
    data?.type === "Protocol" && data?.total_usd_value_delta;
  return (
    <CustomColumn
      width="100%"
      onClick={() => onClick?.(data)}
      cursor="pointer"
      gap="4px"
      className={classes.holdingsItem}
      key={data?.str_chain_id + "_" + data?.address}
      padding="12px 0">
      <CustomRow
        gap="8px"
        width="100%"
        alignItems="center"
        overflowX="visible"
        overflowY="visible"
        margin="0 0 4px 0">
        <CustomRow
          alignItems="center"
          justifyContent="space-between"
          width="100%">
          <CustomColumn>
            <CustomRow
              gap="4px"
              alignItems="flex-start"
              overflowX="visible"
              overflowY="visible">
              <IconWithChain
                src={data?.display_image}
                chain={data?.str_chain_id}
                iconClassName={`${classes.holdingsIcon} ${
                  data?.type === "NFT" ? classes.holdingsIconNFT : ""
                }`}
                showImageFullHeightWidth
                iconHeight="24px"
                iconWidth="24px"
                chainHeight="10px"
                chainWidth="10px"
                chainRightDistance="-4px"
              />
              <CustomText
                text={getTrimmedString(data?.display_name, 15)}
                fontSize="15px"
                lineHeight="1rem"
                color="var(--text-1)"
              />
              <CaretRight
                size={12}
                color="var(--text-2)"
                style={{ marginTop: 2 }}
              />
            </CustomRow>
          </CustomColumn>
          <ProtocolDelta data={data} hasProtocolDelta={hasProtocolDelta} />
        </CustomRow>
        {data?.type === "Token" && (
          <LabeledValue
            label="Invested"
            value={data?.usd_value_invested?.display_value}
            flexGrow={1}
            flexShrink={0}
            alignItems="flex-end"
            justifyContent="flex-end"
          />
        )}
      </CustomRow>
      {data?.type === "Token" && (
        <CustomRow gap="8px" width="100%" alignItems="center">
          <LabeledValue
            label="Amt."
            value={data?.token_balance?.display_value}
            flexGrow={1}
            alignItems="flex-end"
          />

          <LabeledValue
            label="Value"
            value={data?.total_usd_value?.display_value}
            change={data?.total_usd_value_delta}
            flexGrow={1}
            flexShrink={0}
            alignItems="flex-end"
            justifyContent="flex-end"
          />
        </CustomRow>
      )}
      {data?.type === "NFT" && (
        <CustomRow gap="8px" width="100%" alignItems="center">
          <LabeledValue
            label="NFTs"
            value={data?.nft_count}
            flexGrow={1}
            alignItems="flex-end"
          />

          <LabeledValue
            label="Value"
            value={data?.total_usd_value?.display_value}
            change={data?.total_usd_value_delta}
            flexGrow={1}
            flexShrink={0}
            alignItems="flex-end"
            justifyContent="flex-end"
          />
        </CustomRow>
      )}
      {data?.type === "Protocol" &&
        Object.keys(data?.holding?.holdings_by_label || {}).length > 0 && (
          <>
            {Object.keys(data?.holding?.holdings_by_label || {}).map(
              (label, index) => {
                const { holdings } =
                  data?.holding?.holdings_by_label?.[label] || {};
                if (label === "Lending") {
                  return (
                    <>
                      <PaddingComponent padding="4px 0">
                        <Divider borderStyle="dashed" />
                      </PaddingComponent>
                      {holdings?.borrowed?.map((borrowed, borrowedIndex) => (
                        <CustomRow
                          gap="8px"
                          width="100%"
                          alignItems="center"
                          key={index + "borrowed" + borrowedIndex}>
                          <LabeledValue
                            label="Borrowed"
                            value={borrowed?.balance?.display_value}
                            flexGrow={1}
                            alignItems="flex-end"
                          />
                          <LabeledValue
                            label="Value"
                            value={borrowed?.usd_value?.display_value}
                            flexGrow={1}
                            flexShrink={0}
                            alignItems="flex-end"
                            justifyContent="flex-end"
                          />
                        </CustomRow>
                      ))}
                      {holdings?.supplied?.map((supplied, suppliedIndex) => (
                        <CustomRow
                          gap="8px"
                          width="100%"
                          alignItems="center"
                          key={index + "supplied" + suppliedIndex}>
                          <LabeledValue
                            label="Supplied"
                            value={supplied?.balance?.display_value}
                            flexGrow={1}
                            alignItems="flex-end"
                          />
                          <LabeledValue
                            label="Value"
                            value={supplied?.usd_value?.display_value}
                            flexGrow={1}
                            flexShrink={0}
                            alignItems="flex-end"
                            justifyContent="flex-end"
                          />
                        </CustomRow>
                      ))}
                    </>
                  );
                } else if (label === "Staking") {
                  return holdings?.map((staked, stakedIndex) => (
                    <CustomRow
                      gap="8px"
                      width="100%"
                      alignItems="center"
                      key={index + "staked" + stakedIndex}>
                      <LabeledValue
                        label="Staked"
                        value={staked?.balance
                          ?.map((b) => b?.display_value)
                          .join(", ")}
                        flexGrow={1}
                        alignItems="flex-end"
                      />

                      <LabeledValue
                        label="Value"
                        value={staked?.usd_value?.display_value}
                        flexGrow={1}
                        flexShrink={0}
                        alignItems="flex-end"
                        justifyContent="flex-end"
                      />
                    </CustomRow>
                  ));
                } else if (label === "DEX") {
                  return holdings?.map((pooled, pooledIndex) => (
                    <CustomRow
                      gap="8px"
                      width="100%"
                      alignItems="center"
                      key={index + "pooled" + pooledIndex}>
                      <LabeledValue
                        label="LP"
                        value={pooled?.balance
                          ?.map((b) => b?.display_value)
                          .join(", ")}
                        flexGrow={1}
                        alignItems="flex-end"
                      />
                      <LabeledValue
                        label="Value"
                        value={pooled?.usd_value?.display_value}
                        flexGrow={1}
                        flexShrink={0}
                        alignItems="flex-end"
                        justifyContent="flex-end"
                      />
                    </CustomRow>
                  ));
                }
                return null;
              }
            )}
          </>
        )}
      {data?.type === "Token" && (
        <CustomRow gap="8px" width="100%" alignItems="center">
          <LabeledValue
            label="Avg."
            value={data?.avg_buy_price?.display_value}
            flexGrow={1}
            alignItems="flex-end"
          />
          <LabeledValue
            label="Price"
            value={data?.price_usd?.display_value}
            change={data?.price_change_24hr}
            flexGrow={1}
            flexShrink={0}
            alignItems="flex-end"
            justifyContent="flex-end"
          />
        </CustomRow>
      )}
    </CustomColumn>
  );
};

export const MyHoldingsItemShimmer = ({ lines = 4 }) => {
  return (
    <>
      {Array.from({ length: lines }, (v, i) => i).map((_, idx) => (
        // Fixing key error here
        <CustomColumn width="100%" cursor="pointer" key={idx}>
          <PaddingComponent height="12px" />
          <CustomRow
            gap="8px"
            width="100%"
            alignItems="center"
            overflowX="auto"
            className={classes.holdingsItem}>
            <CustomSkeleton variant="circular" height={24} width={24} />
            <FlexGrow>
              <CustomSkeleton width="100%" />
            </FlexGrow>
            <FlexGrow>
              <CustomSkeleton width="100%" />
            </FlexGrow>
          </CustomRow>
          <PaddingComponent height="12px" />
          <Divider borderColor="var(--border-light)" />
        </CustomColumn>
      ))}
    </>
  );
};

export default MyHoldingsItem;
