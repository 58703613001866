import {
  CustomColumn,
  CustomSkeleton,
  InfoBox,
  PaddingComponent,
} from "components/UI/Components/Components";
import GhostFeedItem from "components/Feed/GhostFeedItem/GhostFeedItem";
import classes from "./groupActivityDetailsV2.module.css";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";
import { useParams } from "react-router-dom";
import Comments from "./Comments";
import GroupedActivityDetailsEngagements from "./GroupedActivityDetailsEngagements";

export const TransactionShimmer = () => {
  return (
    <InfoBox padding="14px 20px">
      <CustomSkeleton width="360px" />
      <CustomSkeleton width="96px" />
    </InfoBox>
  );
};

const GroupActivityDetailsShimmer = ({ type }) => {
  const isShowingTransactions = [
    "activity_group",
    "whale_alert",
    "sol_activity_group",
  ].includes(type);
  const isTrending = type === "trending";
  const { active_tab } = useParams();
  const tabsList = [
    {
      title: <CustomSkeleton width="96px" />,
      link: "comments",
    },
  ];
  if (isShowingTransactions) {
    tabsList.push({
      title: <CustomSkeleton width="96px" />,
      link: "transactions",
    });
  }
  if (isTrending) {
    tabsList.push({
      title: <CustomSkeleton width="96px" />,
      link: "reference_activities",
    });
  }
  return (
    <div className={classes.container}>
      <CustomColumn>
        <GhostFeedItem isGroupActivityShimmer />
        <GroupedActivityDetailsEngagements isLoading />
        <CustomLinkTabBar
          isEnabled={false}
          currentStep={active_tab}
          tabsList={tabsList}
          zIndex={TABBAR_ZINDEX.HIGH}
        />
        {active_tab === "comments" ? (
          <Comments comments={[]} />
        ) : (
          <PaddingComponent paddingTop="20px">
            <TransactionShimmer />
          </PaddingComponent>
        )}
      </CustomColumn>
    </div>
  );
};

export const GroupActivityCommentsAndTransactionsShimmer = ({ type }) => {
  const isShowingTransactions = [
    "activity_group",
    "whale_alert",
    "sol_activity_group",
  ].includes(type);
  const isTrending = type === "trending";
  const { active_tab } = useParams();
  const tabsList = [
    {
      title: <CustomSkeleton width="96px" />,
      link: "comments",
    },
  ];
  if (isShowingTransactions) {
    tabsList.push({
      title: <CustomSkeleton width="96px" />,
      link: "transactions",
    });
  }
  if (isTrending) {
    tabsList.push({
      title: <CustomSkeleton width="96px" />,
      link: "reference_activities",
    });
  }
  return (
    <div className={classes.container}>
      <CustomColumn>
        <CustomLinkTabBar
          isEnabled={false}
          currentStep={active_tab}
          tabsList={tabsList}
          zIndex={TABBAR_ZINDEX.HIGH}
        />
        {active_tab === "comments" ? (
          <Comments comments={[]} />
        ) : (
          <PaddingComponent paddingTop="20px">
            <TransactionShimmer />
          </PaddingComponent>
        )}
      </CustomColumn>
    </div>
  );
};

export default GroupActivityDetailsShimmer;
