import { useCallback } from "react";
import classes from "./ConnectPanel.module.css";
import PrivateWalletsList from "./PrivateWalletsList";
import { useQuery } from "@tanstack/react-query";
import {
  sendPrivateWalletsSharingRequest,
  getPrivateWalletsSharingRequest,
  cancelPrivateWalletsSharingRequest,
  acceptPrivateWalletsSharingRequest,
} from "api/profile.api";
import { ReactComponent as InfoIcon } from "assets/svg/profile/info.svg";
import {
  CustomRow,
  InfoBox,
  RightPanelCrossIcon,
  CustomText,
} from "components/UI/Components/Components";
import { useContext, useMemo } from "react";
import { GlobalContext } from "contextStates/Global";

const ConnectPanel = ({
  identity,
  setIsConnectPanelOpen,
  loggedInUserDetails,
  onSuccess,
}) => {
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const getConnectionData = useCallback(
    () => getPrivateWalletsSharingRequest({ toIdentityId: identity?.id }),
    [identity?.id]
  );
  const { data: connectionData, refetch: refetchConnectionData } = useQuery({
    queryKey: ["connection", loggedInUserDetails?.id, identity?.id],
    queryFn: getConnectionData,
    enabled:
      !!loggedInUserDetails?.id &&
      identity?.id &&
      identity?.is_verified &&
      loggedInUserDetails?.id !== identity?.id,
    refetchOnWindowFocus: false,
  });

  const isPendingSentRequest = useMemo(
    () =>
      connectionData?.sender_identity_id === loggedInUserDetails?.id &&
      connectionData?.status === "pending",
    [connectionData, loggedInUserDetails]
  );

  const sendOrUpdateConnection = ({ selectedWallets }) =>
    sendPrivateWalletsSharingRequest({
      toIdentityId: identity?.id,
      walletAddresses: selectedWallets,
    })
      .then(() => {
        refetchConnectionData();
        handleSuccessSnackbar(
          connectionData?.status === "not_sent"
            ? "Connection request sent"
            : "Connection updated"
        );
        onSuccess?.();
      })
      .catch((e) => {
        handleErrorSnackbar(e);
      })
      .finally(() => {
        setIsConnectPanelOpen(false);
      });

  const rejectConnection = () =>
    cancelPrivateWalletsSharingRequest({ toIdentityId: identity?.id })
      .then(() => {
        refetchConnectionData();
        const isSentRequest =
          connectionData?.sender_identity_id === loggedInUserDetails?.id;
        handleSuccessSnackbar(
          `Connection request ${isSentRequest ? "cancelled" : "rejected"}`
        );
        onSuccess?.();
      })
      .catch((e) => {
        handleErrorSnackbar(e);
      })
      .finally(() => {
        setIsConnectPanelOpen(false);
      });

  const acceptConnection = ({ selectedWallets }) =>
    acceptPrivateWalletsSharingRequest({
      toIdentityId: identity?.id,
      walletAddresses: selectedWallets,
    })
      .then(() => {
        refetchConnectionData();
        handleSuccessSnackbar("Connection request accepted");
        onSuccess?.();
      })
      .catch((e) => {
        handleErrorSnackbar(e);
      })
      .finally(() => {
        setIsConnectPanelOpen(false);
      });

  return (
    <div className={classes.rightPanel}>
      <div className={classes.header}>
        <RightPanelCrossIcon
          onClick={() => {
            setIsConnectPanelOpen(false);
          }}></RightPanelCrossIcon>
        <span
          className={
            classes.title
          }>{`${loggedInUserDetails?.display_name} x ${identity?.display_name}`}</span>
        <span className={classes.subtitle}>
          Exchange view access of Private Wallets
        </span>
      </div>
      <div>
        <InfoBox
          margin="16px 20px"
          background={
            isPendingSentRequest ? "var(--base)" : "var(--elevation-2)"
          }
          width="calc(100% - 40px)">
          <CustomRow gap="8px">
            <CustomRow flexShrink="0" padding="2px 0">
              <InfoIcon
                width={18}
                height={18}
                color={
                  isPendingSentRequest ? "var(--warning)" : "var(--text-3)"
                }></InfoIcon>
            </CustomRow>
            <CustomText
              textAlign="left"
              color={isPendingSentRequest ? "var(--warning)" : "var(--text-2)"}
              text={
                isPendingSentRequest
                  ? "Your request is sent. Waiting for the other party to reveal at least one of their private wallets."
                  : "Access to your private wallets is contingent upon the other party revealing at least one of their private wallets."
              }></CustomText>
          </CustomRow>
        </InfoBox>
        <PrivateWalletsList
          connectionData={connectionData}
          sendOrUpdateConnection={sendOrUpdateConnection}
          rejectConnection={rejectConnection}
          acceptConnection={acceptConnection}
          loggedInUserDetails={loggedInUserDetails}
          connectionIdentity={identity}
        />
      </div>
    </div>
  );
};

export default ConnectPanel;
