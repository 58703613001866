import CheckboxText from "./CheckboxText";
import {
  PaddingComponent,
  CustomColumn,
  CustomRow,
} from "components/UI/Components/Components";
import { useCallback } from "react";
import classes from "../ScreenerRightPanel.module.css";
import ColumnSearchField from "./ColumnSearchField";
import IconWithChain from "../../IconWithChain";
import { ReactComponent as CrossIcon } from "assets/svg/profile/cross.svg";

export const ItemTile = ({
  option,
  source,
  onRemove,
  isDivider = true,
  isCross = true,
  trailingText,
}) => {
  const name =
    source === "token"
      ? `${option?.data?.token_details?.symbol}`
      : option?.data?.title || option?.data?.display_name;

  return (
    <PaddingComponent paddingTop="4px">
      <CustomRow gap="4px" alignItems="center" justifyContent="center">
        <IconWithChain
          src={option?.data?.display_picture}
          alt={option?.data?.display_name || option?.data?.display_name}
          chain={option?.data?.address_chain}
        />
        <span className={classes.tokenText}>{name}</span>
        <PaddingComponent width="2px" />
        {trailingText && (
          <span className={classes.tokenText}>{trailingText}</span>
        )}
        {isCross && (
          <div style={{ display: "flex" }} onClick={onRemove}>
            <CrossIcon className={classes.inputFieldIcon} />
          </div>
        )}

        {isDivider && <PaddingComponent width="4px" />}
        {isDivider && <div className={classes.verticalDivider} />}
      </CustomRow>
    </PaddingComponent>
  );
};

export const InputField = ({
  fieldsList,
  source,
  setInputColumnsList,
  placeholder,
  onAddValue,
  onRemove,
}) => {
  return (
    <div className={classes.inputBox}>
      {fieldsList.map((option) => (
        <ItemTile
          option={option}
          source={source}
          onRemove={() => {
            onRemove(option);
          }}
        />
      ))}
      <ColumnSearchField
        placeholder={fieldsList.length !== 0 ? "+ Add more" : placeholder}
        source={source}
        height="20px"
        onAddValue={onAddValue}
      />
    </div>
  );
};

export const isSecondOption = (doubleFieldsList) => {
  const isNotEmpty = doubleFieldsList.length !== 0;
  const lastElement = isNotEmpty
    ? doubleFieldsList[doubleFieldsList.length - 1]
    : null;
  const isSecondOption =
    lastElement?.option1 != null && lastElement?.option2 == null;
  return isSecondOption;
};

export const isNextOption = (doubleFieldsList) => {
  const isNotEmpty = doubleFieldsList.length !== 0;
  const lastElement = isNotEmpty
    ? doubleFieldsList[doubleFieldsList.length - 1]
    : null;
  const isSecondOption = lastElement?.option2 != null;
  return isSecondOption;
};

export const DoubleInputField = ({
  inputColumn,
  onAddDoubleFieldValue,
  onRemoveDoubleFieldListItem,
}) => {
  //if list is empty then show first input
  //else get last element and check if option1 is there,if yes then show second input
  return (
    <div className={classes.inputBox}>
      {inputColumn.doubleFieldsList.map((data) => (
        <CustomRow alignItems="center" justifyContent="center">
          <ItemTile
            isCross={false}
            isDivider={false}
            option={data.option1}
            source={inputColumn.source}
            trailingText={inputColumn.placeholderBetween}
            onRemove={() => {
              // onRemove(option);
            }}
          />
          {/* {data.option1 && (
            <span className={classes.tokenText}>
              {inputColumn.placeholderBetween}
            </span>
          )} */}
          {data.option2 && (
            <ItemTile
              option={data.option2}
              source={inputColumn.source2}
              onRemove={() => {
                onRemoveDoubleFieldListItem(data);
              }}
            />
          )}
        </CustomRow>
      ))}
      <div>
        <ColumnSearchField
          placeholder={
            inputColumn.doubleFieldsList.length !== 0
              ? isSecondOption(inputColumn.doubleFieldsList)
                ? inputColumn.placeholder2
                : "+ Add more"
              : inputColumn.placeholder
          }
          source={
            isSecondOption(inputColumn.doubleFieldsList)
              ? inputColumn.source2
              : inputColumn.source
          }
          onAddValue={onAddDoubleFieldValue}
        />
      </div>
    </div>
  );
};

const InputColumnTile = ({
  inputColumn,
  inputColumnsList,
  setInputColumnsList,
}) => {
  const onAddValue = useCallback(
    (key, value) => {
      inputColumn.fieldsList.push(value);
      setInputColumnsList([...inputColumnsList]);
    },
    [inputColumn.fieldsList, inputColumnsList, setInputColumnsList]
  );

  const onAddDoubleFieldValue = useCallback(
    (key, value) => {
      if (isSecondOption(inputColumn.doubleFieldsList)) {
        inputColumn.doubleFieldsList[inputColumn.doubleFieldsList.length - 1][
          "option2"
        ] = value;
        setInputColumnsList([...inputColumnsList]);
        return;
      }
      inputColumn.doubleFieldsList.push({
        option1: value,
        option2: null,
      });
      setInputColumnsList([...inputColumnsList]);
    },
    [inputColumn.doubleFieldsList, inputColumnsList, setInputColumnsList]
  );

  const onRemove = useCallback(
    (option) => {
      const newFieldsList = inputColumn.fieldsList?.filter(
        (item) => item !== option
      );
      inputColumn.fieldsList = newFieldsList;
      setInputColumnsList([...inputColumnsList]);
    },
    [inputColumnsList, setInputColumnsList, inputColumn]
  );

  const onRemoveDoubleFieldListItem = useCallback(
    (data) => {
      const newFieldsList = inputColumn.doubleFieldsList?.filter(
        (item) => item !== data
      );
      inputColumn.doubleFieldsList = newFieldsList;
      setInputColumnsList([...inputColumnsList]);
    },
    [inputColumn, inputColumnsList, setInputColumnsList]
  );

  return (
    <PaddingComponent paddingBottom="16px">
      <CustomColumn>
        <CheckboxText
          isActive={inputColumn.isActive}
          setIsActive={() => {
            inputColumn.isActive = !inputColumn.isActive;
            setInputColumnsList([...inputColumnsList]);
          }}
          text={inputColumn.title}
        />
        {inputColumn.isActive && (
          <CustomColumn>
            {inputColumn.isDoubleInputFields ? (
              <DoubleInputField
                inputColumn={inputColumn}
                onAddDoubleFieldValue={onAddDoubleFieldValue}
                onRemoveDoubleFieldListItem={onRemoveDoubleFieldListItem}
              />
            ) : (
              <InputField
                fieldsList={inputColumn.fieldsList}
                source={inputColumn.source}
                placeholder={inputColumn.placeholder}
                onAddValue={onAddValue}
                onRemove={onRemove}
              />
            )}
          </CustomColumn>
        )}
      </CustomColumn>
    </PaddingComponent>
  );
};

export default InputColumnTile;
