import { defaultQuery } from "./DefaultQuery";
import queries from "./QueryObject";

const processData = (queries) => {
  const result = [];

  queries.forEach((query, index) => {
    // consider operator only if it's not the first query
    if (index !== 0) {
      result.push({
        query: query?.operator,
        data: undefined,
      });
    }

    const queryResult = {
      query: query?.query?.query,
      data: query?.query?.params,
    };

    result.push(queryResult);

    // Push for Comparison Operator
    const { operator, value } = query?.query?.compOperator || {};
    if (operator && value) {
      result.push({
        query: {
          title: operator,
          type: "static",
        },
      });
      result.push({
        query: {
          title: value,
          type: "static",
        },
      });
    }

    const { chain, protocol } = query?.query?.filters || {};
    if (chain?.data && Object.keys(chain.data).length > 0) {
      result.push({
        query: chain.query,
        data: { chain: { data: chain.data } },
      });
    }
    if (protocol?.data && Object.keys(protocol.data).length > 0) {
      result.push({
        query: protocol.query,
        data: { protocol: protocol.data },
      });
    }
  });
  return result;
};

export const reversePorcessedData = (data) => {
  const addedQueries = [defaultQuery()];
  let index = 0;

  const queryOptions = queries.slice(0, -5).map((query) => query.title);

  data.forEach((q) => {
    if (q?.query?.title === "AND" || q?.query?.title === "OR") {
      addedQueries.push(defaultQuery());
      index++;
      addedQueries[index].operator = q.query;
    } else if (q?.query?.title === ">" || q?.query?.title === "<") {
      addedQueries[index].query.compOperator.operator = q.query.title;
    } else if (q?.query?.title === "on {{protocol}}") {
      addedQueries[index].query.filters.protocol.data = q.data.protocol;
      addedQueries[index].query.filters.protocol.query = q.query;
    } else if (q?.query?.title === "on {{chain}}") {
      addedQueries[index].query.filters.chain.data = q.data.chain.data;
      addedQueries[index].query.filters.chain.query = q.query;
    } else if (queryOptions.includes(q?.query?.title)) {
      addedQueries[index].query.query = q?.query;
      addedQueries[index].query.params = q?.data;
    } else {
      addedQueries[index].query.compOperator.value = q.query.title;
    }
  });

  return addedQueries;
};

export default processData;
