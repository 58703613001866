import { useMutation } from "@tanstack/react-query";

import { signedRequest } from "./api";

export const getTrendingContractDetails = async ({
  chainId,
  contractAddress,
}) => {
  const [response, statisticsData] = await Promise.all([
    signedRequest({
      method: "post",
      path: `/api/v4/get_trending_contract_details`,
      bodyText: JSON.stringify({
        chain_id: chainId,
        contract_address: contractAddress,
      }),
    }),
    signedRequest({
      method: "post",
      path: `/api/v4/get_contract_details`,
      bodyText: JSON.stringify({
        chain_id: chainId,
        contract_address: contractAddress,
      }),
    }),
  ]);

  return {
    ...response.data.data,
    statistics: statisticsData.data.data.statistics,
  };
};

export const useAddRemoveFromWatchlistMutation = ({
  slug = "",
  action = "add",
  chainId,
  contractAddress,
  onSuccess,
  onError,
  isNft = false,
  collectionId = null,
  ...props
}) => {
  const addRemoveFromWatchlist = ({
    chainId,
    contractAddress,
    slug = "",
    action,
    collectionId,
    isNft,
  }) => {
    const endpoint =
      action === "add"
        ? "/api/v4/add_to_watchlist"
        : "/api/v4/remove_from_watchlist";
    const params = isNft
      ? { collection_id: collectionId }
      : {
          chain_id: chainId,
          token_address: contractAddress,
          token_slug: slug,
          collection_id: collectionId,
        };
    return signedRequest({
      method: "post",
      path: endpoint,
      bodyText: JSON.stringify({
        ...params,
      }),
    });
  };

  return useMutation({
    mutationFn: () =>
      addRemoveFromWatchlist({
        action,
        chainId,
        contractAddress,
        slug,
        collectionId,
        isNft,
      }),
    onSuccess: () => {
      onSuccess?.();
    },
    onError,
  });
};

export const getTrendingCards = async ({ pageSize = 10 }) => {
  const response = await signedRequest({
    method: "post",
    path:
      "/api/v4/get_trending_cards?page_size=" + pageSize + "&ignore_swaps=true",
  });

  return response.data.data;
};
