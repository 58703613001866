import classes from "./header.module.css";
import { CustomRow } from "components/UI/Components/Components";
import { CircularProgress } from "@mui/material";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import TimeAgo from "react-timeago";
import englishStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

const Header = ({
  totalUsdValue,
  lastUpdatedAt,
  isFullyComputed,
  refetch,
  isFetching,
}) => {
  const formatter = buildFormatter(englishStrings);
  return (
    <div className={classes.header}>
      <div className={classes.labelContainer}>
        <div className={classes.label}>NFT Holdings</div>
        <div className={classes.totalUsdValue}>{totalUsdValue}</div>
      </div>
      <CustomRow alignItems="center" gap="8px">
        {lastUpdatedAt > 0 && (
          <PPLxTooltip
            title={
              !isFullyComputed
                ? "Computing the holdings"
                : "Recompute the holdings"
            }
            arrow
            placement="top">
            <button
              className={classes.updatedAt}
              disabled={isFetching || !isFullyComputed}
              style={{ cursor: "cursor" }}
              onClick={refetch}>
              {(isFetching || !isFullyComputed) && (
                <CircularProgress
                  style={{ color: "var(--text-3)", marginTop: "2px" }}
                  size={10}
                />
              )}
              <TimeAgo date={lastUpdatedAt * 1000} formatter={formatter} />
            </button>
          </PPLxTooltip>
        )}
      </CustomRow>
    </div>
  );
};

export default Header;
