import { useContext, useEffect, useState } from "react";
import GalleryContextProvider from "contextStates/Gallery";
import FeedItemsList from "./FeedItemsList";
import NewPost from "./NewPost/NewPost";
import NewPostV2 from "./NewPost/NewPostV2";
import { GlobalContext } from "contextStates/Global";
import CustomLinkTabBar, {
  TABBAR_ZINDEX,
} from "components/UI/Components/CustomLinkTabBar";
import useQueryParams from "customHooks/useQueryParams";
import { AuthContext } from "contextStates/AuthContext";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import useTokenHoldings from "components/V2/Profile/ProfileDetails/Portfolio/TokenHoldings/useTokenHoldings";
import useFetchProtocolHoldings from "customHooks/fetch/useFetchProtocolHoldings";
import { FEED_TABS } from "utils/constants";
import { PaddingComponent } from "components/UI/Components/Components";
import classes from "./Feed.module.css";
import usePageTitle from "customHooks/usePageTitle";
const Feed = () => {
  const { featureFlags } = useContext(GlobalContext);
  const { identityDetails } = useContext(AuthContext);
  const params = useQueryParams();
  const [filter, setFilter] = useState(params?.get("feed") || "");
  const identity = identityDetails.current?.identity?.username ?? null;
  usePageTitle({ title: "Home - 0xPPL" });
  useTokenHoldings({
    identifier: identity ? identity : null,
    forProtocolTreasury: false,
    onlyFireSlowPath: true,
    maxHoldings: null,
  });
  useFetchProtocolHoldings({
    identifier: identity ? identity : null,
    forProtocolTreasury: false,
  });

  useEffect(() => {
    let key = params?.get("feed");
    setFilter(key || "");
  }, [setFilter, params]);
  return (
    <BaseWrapper mixpanelEvent="Feed">
      <GalleryContextProvider>
        <PaddingComponent
          padding="0 16px"
          className={classes.tabbar}
          style={{ borderBottom: `1px solid var(--border-light)` }}>
          <CustomLinkTabBar
            sticky
            maxWidthTabs={true}
            tabsList={FEED_TABS}
            zIndex={TABBAR_ZINDEX.HIGH}
            isCentered={false}
            currentStep={
              FEED_TABS.find((tab) =>
                filter.length
                  ? tab.link === `?feed=${filter}`
                  : tab.link === "/home"
              )?.link ?? "/home"
            }
            isLinkTabBar={true}
            isBorderBottom={false}
            tabPadding="16px 0 8px 0"
            tabGap="48px"
          />
        </PaddingComponent>
        {featureFlags.show_scheduled_post ? (
          <NewPostV2 isHomePage={true} />
        ) : (
          <NewPost isHomePage={true} />
        )}
        <div className="feed">
          {params?.get("feed") !== "trending" ? (
            <FeedItemsList
              key={featureFlags.show_new_feed ? "new_feed" : "global_feed"}
              activeTab={
                featureFlags.show_new_feed ? "new_feed" : "global_feed"
              }
              isHomePageFeedItem={true}
              filter={filter}
            />
          ) : (
            <FeedItemsList
              key="trending"
              activeTab="trending"
              isHomePageFeedItem={true}
              filter={filter}
            />
          )}
        </div>
      </GalleryContextProvider>
    </BaseWrapper>
  );
};

export default Feed;
