import classes from "./ProtocolDetails.module.css";
import withLoader from "components/core/withLoader";
import { getProtocolDetails } from "api/contract.api";
import TopBar from "./TopBar";
import {
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom";
import Contracts from "./Contracts";
import ProtocolPortfolio from "./ProtocolPortfolio";
import PaginatedActivities from "components/PaginatedActivities/PaginatedActivities";
import TokenTab from "../../Token/TokenTab";
import {
  CustomColumn,
  CustomSkeleton,
} from "components/UI/Components/Components";
export const ProtocolDetailsGhost = () => {
  return (
    <div className={classes.protocol_details_ghost}>
      <TopBar isEnabled={false} hasToken={true} />
      <CustomColumn gap="6px">
        <CustomSkeleton height={60} />
        <CustomSkeleton height={60} />
        <CustomSkeleton height={60} />
        <CustomSkeleton height={60} />
        <CustomSkeleton height={60} />
      </CustomColumn>
    </div>
  );
};

const ProtocolDetails = ({ queryData, protocol, scrollableRef }) => {
  let match = useRouteMatch();
  return (
    <div className={classes.protocol_details}>
      <TopBar hasToken={queryData.token} />
      <div>
        <Switch>
          <Route path={`${match.path}/contracts`}>
            <Contracts
              contracts={queryData.contracts}
              profiles={queryData.profiles}
            />
          </Route>
          {queryData.token && (
            <Route path={`${match.path}/token`}>
              <TokenTab
                address={queryData.token.address}
                chainId={queryData.token.chain_id}
              />

              {/* <Home
                address={queryData.token.address}
                chain_id={queryData.token.chain_id}
              /> */}
            </Route>
          )}
          <Route path={`${match.path}/tvlinfo`}>
            <ProtocolPortfolio
              protocol={protocol}
              protocolStats={queryData.protocol_stats}
              tvl={queryData.tvl}
              scrollableRef={scrollableRef}
            />
          </Route>
          <Route path={`${match.path}/activities`}>
            <PaginatedActivities
              identifier={protocol}
              type="protocol"
              scrollableRef={scrollableRef}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default withLoader(ProtocolDetails, getProtocolDetails, {
  queryKey: (props) => ["protocol", props.protocol],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  renderNotFound: true,
  CustomLoader: ProtocolDetailsGhost,
});
