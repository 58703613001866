import { useFetchFeed } from "api/feed.api";
import classes from "./NotificationsPage.module.css";
import { AuthContext } from "contextStates/AuthContext";
import { useContext, useEffect, useState } from "react";

const NotificationUnreadIcon = ({
  onNotificationPage = false,
  isMounted = false,
}) => {
  const [unreadNotificationsCount, setUnreadNotificationsCount] =
    useState(null);
  const { isUserLoggedIn } = useContext(AuthContext);
  const { data } = useFetchFeed({
    isNotifications: true,
    enabled: isUserLoggedIn && unreadNotificationsCount === null,
  });

  const { pages: queryData } = data || {};

  useEffect(() => {
    setUnreadNotificationsCount(queryData?.[0]?.data?.unread_count);
  }, [queryData]);

  useEffect(() => {
    if (unreadNotificationsCount > 0 && onNotificationPage) {
      setUnreadNotificationsCount(0);
    }
  }, [onNotificationPage, unreadNotificationsCount]);

  if (unreadNotificationsCount > 0 && !onNotificationPage) {
    return (
      <div
        className={`${classes.notificationDot} ${
          isMounted ? classes.mounted : ""
        }`}>
        {unreadNotificationsCount}
      </div>
    );
  }
  return null;
};

export default NotificationUnreadIcon;
