import {
  CustomRow,
  CustomText,
  GradientDivider,
  PaddingComponent,
  TransparentButton,
} from "components/UI/Components/Components";
import { ReactComponent as WhaleIcon } from "assets/svg/trending/whale.svg";
import { Star, ListDashes } from "@phosphor-icons/react";
import classes from "./trendingTokenDetails.module.css";
import TransferItem from "./TransferItem";

const TrendingTile = ({
  transfers,
  type,
  profiles,
  width = "auto",
  borderRadius = "6px",
  border = "1px solid var(--border-light)",
  padding = "",
  visibleTransfersCount = 2,
  showHeader = true,
  handleViewAll = () => {},
}) => {
  if (!["whale", "celebrity", "latest"].includes(type) || !transfers) {
    return null;
  }

  const tileData = {
    whale: {
      icon: <WhaleIcon />,
      title: "WHALE ALERTS",
      color: "var(--accent-1)",
    },
    celebrity: {
      icon: <Star size={14} color="var(--primary-color)" weight="fill" />,
      title: "CELEBRITY TRANSACTIONS",
      color: "var(--primary-color)",
    },
    latest: {
      icon: <ListDashes size={14} color="var(--text-2)" weight="bold" />,
      title: "LIVE TRANSACTIONS",
      color: "var(--text-2)",
    },
  };

  const slicedTransfers = transfers?.slice(0, visibleTransfersCount);
  return (
    <div
      className={`${classes.trendingTile} ${
        type === "latest" ? classes.fadingGradient : ""
      }`}
      style={{ borderRadius, border, padding }}>
      {showHeader ? (
        <div className={classes.tileHeader}>
          <CustomRow gap="8px" alignItems="center">
            <div className={classes.tileIcon}>{tileData?.[type].icon}</div>
            <CustomText
              fontSize="15px"
              fontWeight="600"
              text={tileData?.[type].title}
              color={tileData?.[type].color}
              whiteSpace="pre"
            />
            <GradientDivider startColor={tileData?.[type].color} />

            {type === "latest" && (
              <TransparentButton onClick={handleViewAll}>
                <CustomText
                  fontSize="15px"
                  fontWeight="600"
                  text="VIEW ALL"
                  color="var(--primary-color)"
                  whiteSpace="pre"
                />
              </TransparentButton>
            )}
          </CustomRow>
          <PaddingComponent height="12px" />
        </div>
      ) : (
        ""
      )}
      <div className={classes.tileContent} style={{ width }}>
        {slicedTransfers?.map((transfer, index) => (
          <>
            <TransferItem
              transfer={transfer}
              key={`${type}-transfer-${index}`}
              profiles={profiles}
            />
            {index !== slicedTransfers?.length && (
              <PaddingComponent height="8px" />
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default TrendingTile;
