import networthClasses from "./NetWorthCard.module.css";
import { CustomRow, CustomSkeleton } from "components/UI/Components/Components";
const ProfileCardShimmer = () => {
  return (
    <div
      className={networthClasses.net_worth_card}
      style={{
        background: "var(--background)",
      }}>
      <div className={networthClasses.shimmerBox}>
        <div>
          <CustomSkeleton width={64} />
          <CustomSkeleton width={96} height={32} />
        </div>
        <CustomSkeleton width={40} />
      </div>
    </div>
  );
};

export const ProfileCardShimmerV2 = () => {
  return (
    <CustomRow justifyContent="space-between">
      <div>
        <CustomSkeleton width={64} />
        <CustomSkeleton width={96} height={32} />
        <CustomSkeleton width={40} />
      </div>
      <div>
        <CustomSkeleton width={96} />
        <CustomSkeleton width={96} />
      </div>
    </CustomRow>
  );
};

export default ProfileCardShimmer;
