import React, { useCallback, useContext, useEffect, useState } from "react";
import { useGetPendingPosts, usePostMutation } from "api/feed.api";
import PostSuccess from "./Components/PostSuccess";
import PostError from "./Components/PostError";
import PostCommentRenderer from "./PostCommentRenderer";
import PostCommentRendererV2 from "./PostCommentRendererV2";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "contextStates/Global";
import { AuthContext } from "contextStates/AuthContext";
import NewPostModal from "../../ActivityEngagements/NewPostModal";
import { useForm } from "react-hook-form";
import { getFileTypeFromDataString } from "./NewPost.utils";
import { PenNib } from "../../../../node_modules/@phosphor-icons/react/dist/index";
import { CustomRow } from "components/UI/Components/Components";
import { USER_PREFENCES } from "../../../utils/constants";

export const NewPostContext = React.createContext();
const NewPostV2 = ({ isHomePage = false }) => {
  const { getUserPreference } = useContext(GlobalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const [lastPostData, setLastPostData] = useState(null);
  const [isScheduling, setIsScheduling] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [activeTab, setActiveTab] = React.useState(0);
  const [pendingPostId, setPendingPostId] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [editorRawContent, setEditorRawContent] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState(
    getUserPreference(USER_PREFENCES.CROSS_POST_SELECTED_PLATFORMS) || []
  );
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const { data: pendingPosts } = useGetPendingPosts({ enabled: isModalOpen });
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      threads: [{ name: "" }],
    },
  });

  const editPost = (post) => {
    form.reset();
    setActiveTab(0);
    setPendingPostId(post.pending_post_id);
    if (post.scheduled_time) {
      setScheduledDate(new Date(post.scheduled_time * 1000));
    } else {
      setScheduledDate(null);
    }
    if (post.raw_data) {
      setEditorRawContent(post.raw_data);
    } else {
      setEditorRawContent([]);
    }
    const previewImages = post.thread.map((item) => {
      return item.images.map((image) => {
        return {
          result: image,
          type: getFileTypeFromDataString(image),
        };
      });
    });
    setPreviewImages(previewImages);
    if (post.platforms) {
      setSelectedPlatforms(post.platforms);
    }
    if (post.platforms_channel) {
      setSelectedChannelId(post.platforms_channel.farcaster);
    }
    const threadValues = post.thread.map((item) => {
      return { name: item.contents };
    });
    form.reset({
      threads: threadValues,
    });
  };

  const cleanUp = () => {
    setPendingPostId(null);
    setScheduledDate(null);
    setIsScheduling(false);
    setPreviewImages([]);
    setSelectedPlatforms(
      getUserPreference(USER_PREFENCES.CROSS_POST_SELECTED_PLATFORMS) || []
    );
    setSelectedChannelId(null);
    setActiveTab(0);
    form.reset({
      threads: [{ name: "" }],
    });
  };

  const onPostSuccess = (data) => {
    setLastPostData(data);
    setIsModalOpen(false);
    cleanUp();
  };
  const isLensFeatureEnabled =
    useContext(GlobalContext).featureFlags["is_lens_cross_post_enabled"];
  const { isUserLoggedIn, setShowLoginPopup } = useContext(AuthContext);
  const { mutate: postMutation, isPending: isLoading } = usePostMutation({
    setIsSuccess,
    setIsError,
    onSuccess: onPostSuccess,
  });
  const onSubmit = useCallback(
    ({ threads, selectedPlatforms, channelId, draft = false }) => {
      if (!isUserLoggedIn) {
        setShowLoginPopup(true);
        return;
      }
      postMutation({
        threads,
        selectedPlatforms: isLensFeatureEnabled
          ? selectedPlatforms
          : selectedPlatforms.filter((platform) => platform !== "lens"),
        channelId,
        scheduledDate,
        draft,
        pendingPostId,
        editorRawContent,
      });
    },
    [
      postMutation,
      isLensFeatureEnabled,
      isUserLoggedIn,
      setShowLoginPopup,
      scheduledDate,
      pendingPostId,
      editorRawContent,
    ]
  );

  useEffect(() => {
    if (isHomePage) {
      const open = history?.location?.search?.includes("newPost");
      if (open) {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          history.replace("/home");
        }, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  useEffect(() => {
    let currentStateTimeout;
    if (isSuccess || isError) {
      // on submit, hide the success/error message after 2.5 seconds
      currentStateTimeout = setTimeout(() => {
        isSuccess && setIsSuccess(false);
        isError && setIsError(false);
      }, 2500);
    }
    return () => {
      clearTimeout(currentStateTimeout);
    };
  }, [isError, isSuccess]);

  if (isSuccess) {
    if (lastPostData.data.pending_post_type === "draft") {
      return <PostSuccess message="Draft saved successfully" />;
    } else if (lastPostData.data.pending_post_type === "scheduled") {
      return <PostSuccess message="Post scheduled successfully" />;
    }
    return <PostSuccess />;
  }
  let tabsList = ["Post"];
  if (pendingPosts) {
    tabsList = [
      "Post",
      `Scheduled (${pendingPosts.scheduled_threads.length})`,
      `Draft (${pendingPosts.draft_threads.length})`,
    ];
  }

  return (
    <div style={{ marginTop: "12px" }}>
      {isError && <PostError />}
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setEditorRawContent([]);
          setIsModalOpen(true);
        }}>
        <PostCommentRenderer
          isDummy={true}
          isHomePage={isHomePage}
          isNewPost={true}
          isLoading={isLoading}
          isEditorFocused={false}
          onSubmit={onSubmit}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          isError={isError}
          setIsError={setIsError}
          placeholder={
            <CustomRow alignItems="center" gap="8px">
              <PenNib size={16} color="var(--text-3" />
              What's on your mind today?
            </CustomRow>
          }
          showNewPostIcon
          commentSuccessResponse={lastPostData}
          resetCommentSuccessResponse={() => setLastPostData(null)}
        />
      </div>

      {isModalOpen && (
        <NewPostModal
          isModalOpen={isModalOpen}
          handleClose={() => {
            if (isScheduling) {
              setIsScheduling(false);
              return;
            }
            if (
              form.getFieldState("threads").isDirty ||
              previewImages.length > 0
            ) {
              setActiveTab(0);
              setOpenDiscardModal(true);
            } else {
              cleanUp();
              setIsModalOpen(false);
              form.reset();
            }
          }}
          activeTab={activeTab}
          tabsList={tabsList}
          isScheduling={isScheduling}
          setActiveTab={setActiveTab}>
          <NewPostContext.Provider
            value={{
              isModalOpen,
              setIsModalOpen,
              setActiveTab,
              isLoading,
              form,
              previewImages,
              setPreviewImages,
              editPost,
              editorRawContent,
              setEditorRawContent,
              openDiscardModal,
              setOpenDiscardModal,
              selectedPlatforms,
              setSelectedPlatforms,
              selectedChannelId,
              setSelectedChannelId,
            }}>
            <PostCommentRendererV2
              isHomePage={isHomePage}
              isNewPost={true}
              isLoading={isLoading}
              onSubmit={onSubmit}
              isSuccess={isSuccess}
              setIsSuccess={setIsSuccess}
              isError={isError}
              setIsError={setIsError}
              placeholder="Share the next big thing..."
              showNewPostIcon
              setIsScheduling={setIsScheduling}
              isScheduling={isScheduling}
              commentSuccessResponse={lastPostData}
              scheduledDate={scheduledDate}
              setScheduledDate={setScheduledDate}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              isModal={true}
              onDiscard={() => {
                setIsModalOpen(false);
                cleanUp();
              }}
              resetCommentSuccessResponse={() => setLastPostData(null)}
            />
          </NewPostContext.Provider>
        </NewPostModal>
      )}
    </div>
  );
};

export default NewPostV2;
