import classes from "./ExplorerCard.module.css";
import React from "react";
import { Link } from "react-router-dom";
import { getActivitiesSummary } from "api/profile.api";
import { GraphExplorer } from "components/V2/Explorer/components/GraphExplorer";
import { CustomRow, CustomColumn } from "components/UI/Components/Components";
import { CircularProgress } from "@mui/material";
import PPLxTooltip from "shared/0xUI/PPLxTooltip/PPLxTooltip";
import { useQuery } from "@tanstack/react-query";
import { ONE_DAY } from "utils/constants";

const ExplorerCard = ({
  identifier,
  showSummary = true,
  title = "TOTAL VALUE TRANSFERRED",
  isIdentityPage = true,
  username = null,
}) => {
  const { data: queryData } = useQuery({
    queryKey: ["graph-summary", username ?? identifier],
    queryFn: () => getActivitiesSummary({ identifier: username ?? identifier }),
    staleTime: ONE_DAY,
  });
  const summaryData = queryData?.data?.data;
  const isShowingSummary =
    showSummary && summaryData?.total_usd_value_transfer != null;

  const urlData = {
    identifiers: identifier,
    buildIdentityNodes: isIdentityPage,
  };
  const [isHovering, setIsHovering] = React.useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div className={classes.explorer_card_container}>
      <div className={`${classes.graph_container} `}>
        <GraphExplorer
          key={`min-graph-${identifier}`}
          graph_node_count_threshold={10}
          graph_depth_threshold={2}
          urlData={urlData}
          mini
          nodeSize={35}
        />
      </div>
      <div className={classes.explorer_card_wrapper}>
        <Link
          className={`${classes.explorer_card}`}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          to={{
            pathname: `/explorer`,
            search: `?identifiers=${identifier}&initial=true&build_identity_nodes=${isIdentityPage}`,
          }}>
          {isShowingSummary && (
            <CustomColumn height="100%" alignItems="flex-start">
              <CustomRow justifyContent="flex-start" alignItems="flex-start">
                {isShowingSummary && (
                  <div
                    className={classes.title}
                    style={
                      isShowingSummary
                        ? {
                            background: isHovering
                              ? "var(--primary-color-05)"
                              : "var(--base-80)",
                          }
                        : { fontSize: "18px", fontWeight: "600" }
                    }>
                    {title}
                  </div>
                )}
                {isShowingSummary && !summaryData?.is_fully_computed && (
                  <PPLxTooltip title="Computing the summary" arrow>
                    <CircularProgress
                      style={{ color: "var(--text-3)" }}
                      size={12}
                    />
                  </PPLxTooltip>
                )}
              </CustomRow>
              {isShowingSummary && (
                <span
                  className={classes.value}
                  style={{
                    background: isHovering
                      ? "var(--primary-color-05)"
                      : "var(--base-80)",
                  }}>
                  {summaryData?.total_usd_value_transfer?.display_value}
                </span>
              )}
            </CustomColumn>
          )}
        </Link>
      </div>
    </div>
  );
};
export default ExplorerCard;
