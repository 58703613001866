import {
  CustomColumn,
  CustomRow,
  CustomText,
  FlexGrow,
} from "components/UI/Components/Components";
import GettingStartedTile from "./GettingStartedTile";
import {
  QrCode,
  Wallet,
  Note,
  UsersThree,
  NotePencil,
  TelegramLogo,
  Handshake,
  Users,
} from "@phosphor-icons/react";
import Popup from "components/UI/Popup/Popup";
import { ProgressBar } from "../UserJourney";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "contextStates/AuthContext";
import { trackEvent } from "utils/event_tracking";

const GettingStartedPopUp = ({
  setShowGettingStartedPopUp,
  userProfileOnboardingStatus,
  completedSteps,
  totalSteps,
  showDownloadAppPopUp,
}) => {
  const { identityDetails } = useContext(AuthContext);
  const history = useHistory();

  return (
    <Popup
      onClose={() => setShowGettingStartedPopUp(false)}
      bgColor="var(--base)"
      title="Getting started 🚀"
      crossSize={24}
      isDivider>
      <Header completedSteps={completedSteps} totalSteps={totalSteps} />
      <CustomColumn
        gap="12px"
        maxHeight="60vh"
        overflowY={"scroll"}
        padding="12px 0">
        <GettingStartedTile
          title="Download 0xppl mobile app"
          subtitle="We are available on Android & iOS"
          onTap={() => {
            trackEvent("kickstart_download_app");
            showDownloadAppPopUp?.();
            setShowGettingStartedPopUp(false);
          }}
          Icon={QrCode}
          bgColorIcon="#E6F4FB"
          iconColor="#6AB0EB"
          isCompleted={userProfileOnboardingStatus?.has_downloaded_mobile_app}
        />
        <GettingStartedTile
          title="Link your wallets (Keep it private or public)"
          subtitle="+ Add a new wallet"
          onTap={() => {
            trackEvent("kickstart_add_wallet");
            history.push(
              `/${identityDetails?.current?.identity?.id}/wallets?openAddWallet`
            );
            setShowGettingStartedPopUp(false);
          }}
          Icon={Wallet}
          bgColorIcon="#F5E9EF"
          iconColor="#CA6E94"
          isCompleted={userProfileOnboardingStatus?.has_linked_wallets}
        />
        <GettingStartedTile
          title="Add / Edit your bio from your profile"
          subtitle="Adding a bio helps others understand who you are easily."
          onTap={() => {
            trackEvent("kickstart_add_bio");
            history.push(
              `/${identityDetails?.current?.identity?.id}/activities?openEditProfile`
            );
            setShowGettingStartedPopUp(false);
          }}
          Icon={Note}
          bgColorIcon="#FCF2ED"
          iconColor="#E89F71"
          isCompleted={userProfileOnboardingStatus?.has_added_bio}
        />
        <GettingStartedTile
          title="Follow your favourite creators (min 10 people)"
          subtitle="See all activities of your followers including transactions"
          onTap={() => {
            trackEvent("kickstart_follow");
            history.push(`/community/network`);
            setShowGettingStartedPopUp(false);
          }}
          bgColorIcon="#E8F4F6"
          iconColor="#67ABB3"
          Icon={UsersThree}
          isCompleted={userProfileOnboardingStatus?.num_profiles_followed >= 10}
        />
        <GettingStartedTile
          title="Create a cross platform post from 0xppl"
          subtitle="You can now post on twitter, farcaster and lens using 0xppl"
          onTap={() => {
            trackEvent("kickstart_cross_post");
            history.push(`/home?newPost`);
            setShowGettingStartedPopUp(false);
          }}
          Icon={NotePencil}
          bgColorIcon="#ECE8F6"
          iconColor="#9A82D5"
          isCompleted={userProfileOnboardingStatus?.has_cross_posted}
        />
        <GettingStartedTile
          title="Add your telegram handle"
          subtitle="We share some exciting feeds on telegram for your delight"
          onTap={() => {
            trackEvent("kickstart_telegram_connect");
            history.push(`/settings`);
            setShowGettingStartedPopUp(false);
          }}
          Icon={TelegramLogo}
          bgColorIcon="#E4F9FB"
          iconColor="#60C8D1"
          isCompleted={userProfileOnboardingStatus?.is_telegram_connected}
        />
        <GettingStartedTile
          title="Share private wallet with friends"
          subtitle="Connect with friends to share private wallets mutually"
          onTap={() => {
            trackEvent("kickstart_private_wallets");
            history.push(
              `/${identityDetails?.current?.identity?.id}/wallets/manage-access`
            );
            setShowGettingStartedPopUp(false);
          }}
          Icon={Handshake}
          bgColorIcon="#E4F9FB"
          iconColor="#60C8D1"
          isCompleted={userProfileOnboardingStatus?.has_shared_private_wallets}
        />
        <GettingStartedTile
          title="Get your friends on 0xPPL"
          subtitle="Discover Alpha, Ape, Shill, FUD together"
          onTap={() => {
            trackEvent("kickstart_refer_friends");
            history.push(`/refer-friends`);
            setShowGettingStartedPopUp(false);
          }}
          Icon={Users}
          bgColorIcon="#E4F9FB"
          iconColor="#60C8D1"
          isCompleted={userProfileOnboardingStatus?.is_invite_code_used}
        />
      </CustomColumn>
    </Popup>
  );
};

const Header = ({ completedSteps, totalSteps }) => {
  return (
    <CustomRow gap="16px" alignItems="center" margin="0 0 4px 0">
      <ProgressBar completedSteps={completedSteps} totalSteps={totalSteps} />
      <FlexGrow>
        <CustomColumn gap="8px" alignItems="flex-start">
          <CustomText
            text={`${completedSteps || "0"} / ${totalSteps} tasks completed`}
            fontSize="15px"
            fontWeight="500"
            letterSpacing="-0.3px"
            color="var(--text-1)"
          />
          <CustomText
            text="Become a power user now! You’ll be set up in no time."
            letterSpacing="-0.26px"
            fontSize="13px"
          />
        </CustomColumn>
      </FlexGrow>
    </CustomRow>
  );
};

export default GettingStartedPopUp;
