import React, { useContext, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import withLoader from "components/core/withLoader";
import ActivityDetailsComponent from "./ActivityDetailsComponent";
import { ActivityDetailsGhost } from "./ActivityDetailsGhost";
import { QueryKeyConstants } from "utils/constants";
import { fetchActivityByTxnHash } from "api/feed.api";
import { LeftSideHeaderContext } from "contextStates/LeftSideHeaderContext";
import { transformGroupedActivityQuery } from "utils/misc";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";

const TransactionActivityDetails = ({ tx_hash, chain_id, chain_type }) => {
  const { setTitle } = useContext(LeftSideHeaderContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    setTitle("Transaction Details");
  }, [setTitle]);

  return (
    <BaseWrapper mixpanelEvent="TransactionActivityDetails">
      <IntermediateActivityDetailsLoader
        txnHash={tx_hash}
        chainId={chain_id}
        chainType={chain_type}
        queryClient={queryClient}
      />
    </BaseWrapper>
  );
};

const IntermediateActivityDetails = ({ queryData: activityData }) => {
  return (
    <ActivityDetailsComponent
      hideEngagements={true}
      activityId={activityData?.activities?.[0].id}
    />
  );
};

const fetchAndMutateChildQueryKey = async (txnHash, chainId, queryClient) => {
  const activityData = await fetchActivityByTxnHash(txnHash, chainId);
  const activityId = activityData?.activities?.[0].id;
  const queryKey = transformGroupedActivityQuery(activityId, "transaction");
  // Initialize queryClient with this data
  queryClient.setQueryData(queryKey, activityData);

  return activityData;
};

const IntermediateActivityDetailsLoader = withLoader(
  IntermediateActivityDetails,
  (props) =>
    fetchAndMutateChildQueryKey(
      props.txnHash,
      props?.chainId,
      props?.queryClient
    ),
  {
    queryKey: (props) => [
      QueryKeyConstants.ACTIVITY_BY_TXN_ID,
      props.txnHash,
      props.chainId,
    ],
    queryOptions: () => {
      return { staleTime: 1000 * 60 * 5 };
    },
    renderNotFound: false,
    CustomLoader: ActivityDetailsGhost,
  }
);

export default TransactionActivityDetails;
