import React from "react";
import rightArrow from "assets/svg/right-arrrow.svg";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import WalletRowComponent, {
  TableRowItem,
} from "../components/WalletRowComponent";
import { getFormattedMonthNameDate } from "utils/profile_utils";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import { getLinkForWalletsBundle } from "utils/profile_utils";
import { TableRowTextComponent } from "../components/WalletComponents";
import classes from "../components/WalletsComponents.module.css";

const CommunitySourceWalletRowTile = ({ bundleData, wallet, sourcedByKey }) => {
  const sourcedBy = wallet?.sourced_by[sourcedByKey];
  const profile = bundleData?.identity_details[sourcedByKey];
  const createdAt = getFormattedMonthNameDate({
    date: sourcedBy.created_at,
    monthFormat: "short",
  });

  return (
    <WalletRowComponent
      link={getLinkForWalletsBundle({
        address: wallet.address,
        identityPerspective: sourcedByKey,
      })}>
      <TableRowItem flex={4}>
        <ProfileHoverCard actor={profile}>
          <div className={classes.avatarName}>
            <Image
              className={classes.avatar}
              src={profile.display_picture}
              imageType={IMAGE_TYPES.AVATAR}
              alt={"Profile Pic"}
            />
            <span className={classes.titleText}>{profile.display_name}</span>
          </div>
        </ProfileHoverCard>
      </TableRowItem>
      <TableRowItem flex={4}>
        <TableRowTextComponent
          text={sourcedBy.reason ? sourcedBy.reason : "-"}
          isTooltip></TableRowTextComponent>
      </TableRowItem>
      <TableRowItem alignment={"right"} flex={3}>
        <div className={classes.textIcon}>
          <TableRowTextComponent
            text={createdAt ?? "-"}></TableRowTextComponent>
          <img src={rightArrow} alt="right-arrow"></img>
        </div>
      </TableRowItem>
    </WalletRowComponent>
  );
};

export default CommunitySourceWalletRowTile;
