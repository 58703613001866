import React, { useCallback, useContext } from "react";
import { ImageDropzone } from "react-file-utils";
import {
  ChatAutoComplete,
  EmojiPicker,
  AttachmentPreviewList,
  useChannelStateContext,
  useMessageInputContext,
} from "stream-chat-react";

import { GiphyContext } from "../../MessagingApp";
import { LightningBoltSmall } from "../../assets";

import "./MessagingInput.css";
import { IconButton } from "@mui/material";
import { PaperPlaneRight, Smiley, Image } from "@phosphor-icons/react";

const GiphyIcon = () => (
  <div className="giphy-icon__wrapper">
    <LightningBoltSmall />
    <p className="giphy-icon__text">GIPHY</p>
  </div>
);

const MessagingInput = () => {
  const { giphyState, setGiphyState } = useContext(GiphyContext);
  const { acceptedFiles, maxNumberOfFiles, multipleUploads } =
    useChannelStateContext();

  const messageInput = useMessageInputContext();

  const onChange = useCallback(
    (event) => {
      const { value } = event.target;
      const deletePressed =
        event.nativeEvent?.inputType === "deleteContentBackward";

      if (messageInput.text.length === 1 && deletePressed) {
        setGiphyState(false);
      }

      if (
        !giphyState &&
        messageInput.text.startsWith("/giphy") &&
        !messageInput.numberOfUploads
      ) {
        event.target.value = value.replace("/giphy", "");
        setGiphyState(true);
      }

      messageInput.handleChange(event);
    },
    [giphyState, messageInput.numberOfUploads, messageInput.text] // eslint-disable-line
  );

  return (
    <div className="str-chat__messaging-input">
      <ImageDropzone
        accept={acceptedFiles}
        handleFiles={messageInput.uploadNewFiles}
        multiple={multipleUploads}
        disabled={
          (maxNumberOfFiles !== undefined &&
            messageInput.numberOfUploads >= maxNumberOfFiles) ||
          giphyState
        }>
        <AttachmentPreviewList />
        <div className="messaging-input__container">
          <div className="messaging-input__input-wrapper">
            {giphyState && !messageInput.numberOfUploads && <GiphyIcon />}

            <ChatAutoComplete
              onChange={onChange}
              rows={2}
              placeholder="Send a message"
            />
          </div>
          <IconButton>
            <label
              htmlFor="message-file-upload"
              style={{ width: 20, height: 20 }}>
              <Image
                size={20}
                color="var(--text-1)"
                style={{ display: "block" }}
              />
              <input
                type="file"
                accept="image/*"
                id="message-file-upload"
                style={{ display: "none" }}
                onChange={(event) => {
                  messageInput.uploadNewFiles(event.target.files);
                }}
              />
            </label>
          </IconButton>
          <IconButton
            onClick={messageInput.openEmojiPicker}
            ref={messageInput.emojiPickerRef}>
            <Smiley size={20} color="var(--text-1)" />
          </IconButton>
          <IconButton
            onClick={messageInput.handleSubmit}
            className="messaging-input__send-button">
            <PaperPlaneRight size={20} color="var(--primary-color)" />
          </IconButton>
        </div>
      </ImageDropzone>
      <EmojiPicker />
    </div>
  );
};

export default React.memo(MessagingInput);
