import CustomTextField from "components/FollowEditButton/CustomTextField";
import {
  getSummaryPromptData,
  updateSummaryResponseText,
} from "./group_feed_utils";
import { useEffect, useState, useRef, useCallback } from "react";
import buttonClasses from "components/UI/Buttons/buttons.module.css";
import spinnerClasses from "components/FollowEditButton/FollowForm/FollowForm.module.css";
import { CircularProgress } from "@mui/material";

const SummaryEditComponent = ({
  summaryPromptId,
  setSummaryPromptId,
  refetchActivityDetailsPage,
}) => {
  const [promptSummary, setPromptSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const promptText = useRef(null);
  useEffect(() => {
    if (!summaryPromptId) return;
    getSummaryPromptData({
      promptId: summaryPromptId,
    }).then((data) => {
      const curatedResponse = data?.data?.data?.curated_response_text;
      promptText.current = data?.data?.data?.prompt_text;
      setPromptSummary(curatedResponse);
    });
  }, [summaryPromptId]);

  const saveSummary = useCallback(() => {
    setIsLoading(true);
    updateSummaryResponseText({
      promptId: summaryPromptId,
      curatedResponseText: promptSummary,
    })
      .then((resp) => {
        setIsLoading(false);
        setSummaryPromptId("");
        refetchActivityDetailsPage();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [
    promptSummary,
    refetchActivityDetailsPage,
    summaryPromptId,
    setSummaryPromptId,
  ]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}>
      <CustomTextField
        rows={5}
        multiline
        size="small"
        placeholder="Edit Summary"
        fullWidth
        value={promptSummary}
        onChange={(e) => {
          setPromptSummary(e.target.value);
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: "20px",
        }}>
        <button
          style={{
            width: "100px",
            height: "24px",
          }}
          className={buttonClasses.cancelButton}
          onClick={() => {
            setSummaryPromptId("");
          }}>
          Cancel
        </button>
        <button
          onClick={saveSummary}
          style={{
            background: "var(--primary-color)",
            width: "100px",
            height: "24px",
            color: "white",
          }}
          className={buttonClasses.cancelButton}>
          {isLoading ? (
            <div className={spinnerClasses.spinner_container}>
              <CircularProgress color="inherit" size={12} />
            </div>
          ) : (
            <div>Save</div>
          )}
        </button>
      </div>
      <span style={{ color: "var(--text-1)" }}>Prompt</span>
      <span style={{ color: "var(--text-2)" }}>{promptText.current}</span>
    </div>
  );
};

export default SummaryEditComponent;
