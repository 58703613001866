import {
  InfoBox,
  PaddingComponent,
  CustomColumn,
  CustomRow,
  Divider,
  FlexBox,
} from "components/UI/Components/Components";
import { useCallback, useState } from "react";
import ChevronUpIcon from "assets/svg/chevron_up.svg";
import ChevronDownIcon from "assets/svg/chevron_down.svg";
import classes from "./TransactionLogs.module.css";
import LogsParsedAdditionalDetails from "./LogParsedAdditionalDetails";
import LogsUnparsedAdditionalDetails from "./LogUnparsedAdditionalDetails";
import { useEffect } from "react";
import ProfileRenderer from "components/UI/Components/ProfileRenderer";

const LogsAdditionalDetails = ({ log, isParsed }) => {
  if (isParsed) {
    return <LogsParsedAdditionalDetails log={log} />;
  }

  return <LogsUnparsedAdditionalDetails log={log} />;
};

const LogsTile = ({ log, isParsed, expandedIndexesList }) => {
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expandedIndexesList.current.includes(log.logIndex)) {
      setExpanded(true);
    }
  }, [expandedIndexesList, log.logIndex]);
  const changeExpanded = useCallback(() => {
    setExpanded(!isExpanded);
    if (isExpanded) {
      expandedIndexesList.current = [
        ...expandedIndexesList.current,
        log.logIndex,
      ];
    } else {
      expandedIndexesList.current = expandedIndexesList.current.filter(
        (index) => index !== log.logIndex
      );
    }
  }, [isExpanded, setExpanded, expandedIndexesList, log.logIndex]);

  return (
    <PaddingComponent paddingBottom={"10px"}>
      <InfoBox>
        <CustomColumn gap="10px">
          <div onClick={changeExpanded}>
            <CustomRow gap="16px">
              <span className={classes.heading}>{log.logIndex}</span>
              <FlexBox flex="1">
                <span className={classes.title}>
                  {log.event_signature ?? log?.topics?.[0]}
                </span>
              </FlexBox>
              <img
                src={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                alt={"expand"}
              />
            </CustomRow>
          </div>
          <PaddingComponent padding="2px 0px 2px 0px">
            <Divider />
          </PaddingComponent>
          <CustomRow gap="6px">
            <span className={classes.subtitle}>Emitted by:</span>
            <PaddingComponent paddingRight="2px" />
            <ProfileRenderer profile={log?.address} copyable="address" />
          </CustomRow>
          {isExpanded && (
            <LogsAdditionalDetails log={log} isParsed={isParsed} />
          )}
        </CustomColumn>
      </InfoBox>
    </PaddingComponent>
  );
};

export default LogsTile;
