import React from "react";
import {
  CustomColumn,
  CustomRow,
  PriceChange,
} from "components/UI/Components/Components";
import { HoldingsIcon } from "components/Pages/AppModule/RightSideBar/components/HoldingsIcon";
import ZxText from "zxComponents/common/ZxText";
import { TSTYLES } from "zxStyles/constants";
import { getTrimmedString } from "utils/misc";
import { IMAGE_TYPES } from "components/UI/Image";

const TokenHoldingsItem = ({ data }) => (
  <>
    <CustomRow
      width="100%"
      justifyContent="space-between"
      overflowX="visible"
      overflowY="visible">
      <CustomColumn overflowX="visible" overflowY="visible">
        <HoldingsIcon
          src={data?.display_image}
          imageType={IMAGE_TYPES.TOKEN}
          chain={data?.str_chain_id}
          type={data?.type}
          str={getTrimmedString(data?.display_name, 15)}
          amount={`${data?.token_balance?.display_value}  ${data?.token_details?.symbol}`}
        />
      </CustomColumn>
      <CustomColumn alignItems="end">
        <CustomRow justifyContent="start" alignItems="end" gap="4px">
          <ZxText>{data?.total_usd_value?.display_value || "-"} </ZxText>
          {data?.price_change_24hr && (
            <PriceChange
              value={data?.price_change_24hr.value}
              displayValue={data?.price_change_24hr.display_value}
              fontWeight="400"
            />
          )}
        </CustomRow>
        <ZxText style={TSTYLES.secondary1}>
          {data?.usd_value_invested?.display_value || "-"}{" "}
        </ZxText>
      </CustomColumn>
    </CustomRow>
  </>
);

export default TokenHoldingsItem;
