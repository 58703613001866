import React, { useEffect, useMemo, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./LocalTrendingFeedItem.module.scss";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import Image from "components/UI/Image";
import ZxBadge from "zxComponents/common/ZxBadge";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import ConditionalLink from "shared/ConditionalLink";
import { getUniqueId } from "utils/event_tracking";
import { useFetchTokenSwapTableData } from "api/token_page.api";
import withNullableError from "zxComponents/common/withNullableError/index";
const LocalTrendingTransfers = ({ inView, isDark, chain, address }) => {
  const { data: transfers } = useFetchTokenSwapTableData({
    queryKey: "recent_swaps",
    chainId: chain,
    contractAddress: address,
    type: "recent",
    filter: null,
    enabled: chain && address && inView,
  });

  const [visibleTransferIndex, setVisibleTransferIndex] = useState(0);
  const [visibleTransfer, setVisibleTransfer] = useState(null);

  const { rows: allRows, profiles } = useMemo(() => {
    let profiles = {};
    const rows =
      transfers?.pages?.flatMap((page) => {
        profiles = { ...profiles, ...page?.address_profiles };
        return page?.recent_transfer_data;
      }) || [];
    return { rows, profiles };
  }, [transfers]);

  useEffect(() => {
    if (!allRows.length) return;

    const interval = setInterval(() => {
      setVisibleTransferIndex((prevIndex) =>
        prevIndex + 1 < allRows.length ? prevIndex + 1 : 0
      );
    }, 2000); // Change every 2 seconds

    return () => clearInterval(interval);
  }, [allRows]);

  useEffect(() => {
    setVisibleTransfer(allRows[visibleTransferIndex]);
  }, [visibleTransferIndex, allRows]);

  if (!transfers || !inView) return null;

  const renderTransfer = (transfer) => {
    if (!transfer) return null;

    const profile = profiles?.[transfer?.user_address];
    const isBuy =
      transfer?.type?.toLowerCase() === "buy" ||
      transfer?.type?.toLowerCase() === "received";

    const className = isBuy
      ? isDark
        ? styles.profit_dark
        : styles.profit_light
      : isDark
        ? styles.loss_dark
        : styles.loss_light;

    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={getUniqueId()}
          initial={{ opacity: 0.5, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}>
          <ZxFlex
            className={`${styles.transferContainer} ${className}`}
            gap="8px">
            <Image
              src={profile?.display_picture}
              name={profile?.display_name}
              className={styles.transferProfileImage}
            />
            <ZxBadge variant={isBuy ? "success" : "burn"}>
              {transfer?.type?.toUpperCase()}
            </ZxBadge>
            <ZxText style={TSTYLES.title1} fontSize="14px">
              {transfer?.usd_value?.display_value}
            </ZxText>
          </ZxFlex>
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <ConditionalLink to={visibleTransfer?.transaction_link} sameTab={false}>
      {visibleTransfer && renderTransfer(visibleTransfer)}
    </ConditionalLink>
  );
};

export default withNullableError(LocalTrendingTransfers);
