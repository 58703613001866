import React from "react";

const ZxDot = ({ color = "var(--text-3)", width = "4px" }) => {
  return (
    <div
      style={{
        width: width,
        height: width,
        borderRadius: "50%",
        backgroundColor: color,
      }}
    />
  );
};

export default ZxDot;
