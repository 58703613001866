import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { getPathMetaTags } from "utils/misc";

const MetaTags = (props) => {
  const { pathname } = useLocation();
  let pathMetaTags;
  if (!Object.keys(props)?.length) {
    pathMetaTags = getPathMetaTags(pathname);
  }
  const metaTitle = props?.title || pathMetaTags?.title;
  const metaProperties = props?.properties || pathMetaTags?.properties;

  return metaProperties || metaTitle ? (
    <Helmet>
      <title>{metaTitle} · 0xPPL</title>
      {metaProperties
        ? Object.entries(metaProperties).map(([key, value], index) => (
            <meta key={index} name={key} content={value} />
          ))
        : null}
    </Helmet>
  ) : null;
};

export default MetaTags;
