import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "components/UI/Table/Table";
import {
  InfoBoxContainer,
  PaddingComponent,
} from "components/UI/Components/Components";
import classes from "components/V2/Profile/ProfileDetails/Portfolio/TokenHoldings/header.module.css";

const GovernanceResult = ({ choice }) => {
  return (
    <TableRow alignToFlexStart>
      <TableCell alignLeft>{choice?.label}</TableCell>
      <TableCell alignLeft>{choice?.percentage}</TableCell>
      <TableCell alignRight>{choice.voting_power}</TableCell>
    </TableRow>
  );
};

const GovernanceResults = ({ results }) => {
  return (
    <div>
      <PaddingComponent height="16px" />
      <InfoBoxContainer
        header={
          <div className={classes.header}>
            <div className={classes.labelContainer}>
              <div className={classes.label}>Results</div>
              <div className={classes.totalUsdValue}>{results?.length}</div>
            </div>
          </div>
        }>
        {/* // choice?.label} • ${choice?.voting_power} • ${choice?.percentage */}
        <Table>
          <TableHeader noBorder>
            <TableCell alignLeft>CHOICE</TableCell>
            <TableCell alignLeft>PERCENTAGE</TableCell>
            <TableCell alignRight>TOTAL VOTE POWER</TableCell>
          </TableHeader>
          {results?.map((choice, index) => (
            <GovernanceResult
              key={`governance-vote-${index}`}
              choice={choice}
            />
          ))}
        </Table>
      </InfoBoxContainer>
    </div>
  );
};

export default GovernanceResults;
