import { ClickAwayListener } from "@mui/material";
import { useCallback, useState, useContext } from "react";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import classes from "../../../../../../components/FollowEditButton/FollowEditButton.module.css";
import { AuthContext } from "contextStates/AuthContext";
import AddWalletForm from "./AddWalletForm";
import buttonClasses from "components/UI/Buttons/buttons.module.css";
import { CursorDiv } from "components/UI/Components/Components";
import { useHistory } from "react-router-dom";
import { trackEvent } from "utils/event_tracking";

const AddWallet = ({
  identityId,
  refetch,
  button,
  groups,
  selectedGroupIds = [],
}) => {
  const history = useHistory();
  const [showPopUpCard, setShowPopUpCard] = useState(
    history.location.search?.includes("openAddWallet")
  );
  const { isUserLoggedIn, identityDetails, setShowLoginPopup } =
    useContext(AuthContext);

  const isLoggedInUserProfile =
    identityDetails.current?.identity?.id === identityId;

  const onAddClick = useCallback(() => {
    if (!isUserLoggedIn) {
      setShowLoginPopup(true);
      return;
    }
    trackEvent("add_wallet_clicked");
    setShowPopUpCard(true);
  }, [isUserLoggedIn, setShowLoginPopup]);
  if (showPopUpCard) {
    return (
      <div className={classes.follow_edit_button}>
        <ClickAwayListener
          onClickAway={() => {
            setShowPopUpCard(false);
          }}>
          <div>
            <PPLxHoverCard
              open={showPopUpCard}
              content={
                <AddWalletForm
                  refetch={refetch}
                  setShowPopUpCard={setShowPopUpCard}
                  identityId={identityId}
                  groups={groups}
                  selectedGroupIds={selectedGroupIds}
                  isLoggedInUserProfile={isLoggedInUserProfile}
                />
              }
              placement="bottom">
              {button ? (
                <CursorDiv onClick={onAddClick}>{button}</CursorDiv>
              ) : (
                <button
                  onClick={onAddClick}
                  className={buttonClasses.primaryButton}>
                  Add Wallet
                </button>
              )}
            </PPLxHoverCard>
          </div>
        </ClickAwayListener>
      </div>
    );
  }
  if (button) {
    return <CursorDiv onClick={onAddClick}>{button}</CursorDiv>;
  }

  return (
    <button onClick={onAddClick} className={buttonClasses.primaryButton}>
      Add Wallet
    </button>
  );
};

export default AddWallet;
