import PaginatedActivities from "components/PaginatedActivities/PaginatedActivities";
import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "contextStates/ProfileContext";
import classes from "./ProfileActivities.module.css";
import AppliedFiltersWrapper from "../Filters/AppliedFiltersWrapper";
import FeedFilters from "components/Feed/FeedFilters/FeedFilters";
import { GlobalContext } from "contextStates/Global";
import BaseWrapper from "components/Pages/AppModule/BaseWrapper";
import { PaddingComponent } from "../../../../UI/Components/Components";

const ProfileActivities = ({ identifier }) => {
  const [updateActivitiesTab, setUpdateActivitiesTab] = useState(false);
  const { featureFlags, handleErrorSnackbar } = useContext(GlobalContext);
  const [dateRange, setDateRange] = useState(null);
  const [selectedFeedType, setSelectedFeedType] = useState([
    "transactions",
    "posts",
  ]);
  const { profileData } = useContext(ProfileContext);
  const isAddressPage =
    profileData?.current?.type === "address" ||
    profileData?.current?.type === "solana_address";
  const onFilterChange = (param) => {
    setSelectedFeedType((prev) => {
      if (prev.length === 1 && prev.includes(param)) {
        handleErrorSnackbar?.(null, "At least one filter must be selected.");
        return prev;
      }
      return prev.includes(param)
        ? prev.filter((item) => item !== param)
        : [...prev, param];
    });
  };

  const {
    selectedActiveChains,
    selectedActiveProtocols,
    getSelectedActiveTokensWithoutNativeTokens,
    setUpdateActivitiesTabRenderFunction,
  } = useContext(ProfileContext);

  useEffect(() => {
    setUpdateActivitiesTabRenderFunction(
      setUpdateActivitiesTab,
      updateActivitiesTab
    );
    return () => setUpdateActivitiesTabRenderFunction(null, null);
  }, [setUpdateActivitiesTabRenderFunction, updateActivitiesTab]);

  return (
    <BaseWrapper mixpanelEvent="Profile/Activities">
      <div className={classes.profileActivities}>
        <div className={classes.profileActivitiesFilters}>
          {featureFlags?.show_filters && (
            <FeedFilters
              onChange={onFilterChange}
              onDateRangeChange={setDateRange}
              selectedFeedType={selectedFeedType}
            />
          )}
        </div>
        <PaddingComponent padding="8px 16px">
          <AppliedFiltersWrapper identifier={identifier} />
        </PaddingComponent>
        <PaddingComponent padding="0 16px">
          <PaginatedActivities
            selectedActiveChains={selectedActiveChains.current}
            selectedActiveTokens={getSelectedActiveTokensWithoutNativeTokens()}
            selectedActiveProtocols={selectedActiveProtocols.current}
            selectedFeedType={selectedFeedType}
            selectedDateRange={dateRange}
            isProfilePage={true}
            identifier={identifier}
            passPreferredActor={isAddressPage}
          />
        </PaddingComponent>
      </div>
    </BaseWrapper>
  );
};

export default ProfileActivities;
