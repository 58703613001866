import React, { useContext, useEffect } from "react";
import ZxHorizontalScrollWrapper from "zxComponents/common/ZxHorizontalScrollWrapper/ZxHorizontalScrollWrapper";
import styles from "./TopPnl.module.scss";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "contextStates/AuthContext";
import { QUERY_KEYS } from "utils/query_utils";
import {
  getCommentSuggestionsOnPnlInFeed,
  getTopPnlForFeed,
  getTopPnlForIdentity,
} from "api/feed.api";
import { useLocation } from "react-router-dom";
import TopPnlCard from "./TopPnlCard";
import { HorizontalScrollButtons } from "components/Feed/HorizontalScrollButtons";
import { FEED_CARDS, OVERVIEW_CARDS } from "../ZxFeed.utils";
import withNullableError from "zxComponents/common/withNullableError/index";
import { ThemeContext } from "contextStates/Theme.context";

import { ONE_DAY } from "utils/constants";
import { RightBarCardContainer } from "components/Pages/AppModule/RightSideBar/components/ProfileRightCards";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import { PaddingComponent } from "components/UI/Components/Components";
import {
  CaretCircleLeft,
  CaretCircleRight,
} from "../../../../node_modules/@phosphor-icons/react/dist/index";

const TopPnl = () => {
  const { identityDetails } = useContext(AuthContext);
  const identifier = identityDetails?.current?.identity?.id;
  const { isLoading, isError, data } = useQuery({
    queryKey: QUERY_KEYS.FEED_TOP_PNL({ identifier }),
    queryFn: () => getTopPnlForFeed(),
    staleTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
  });
  const { data: pnlComments } = useQuery({
    queryKey: QUERY_KEYS.PNL_GENERATED_COMMENTs({ identifier }),
    queryFn: () => getCommentSuggestionsOnPnlInFeed(),
    enabled: true,
    retry: 1,
    staleTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const elRef = React.useRef();
  if (isError || (!isLoading && !data) || data?.length === 0) {
    return null;
  }
  const isReady = !isLoading && data;

  return (
    <ZxHorizontalScrollWrapper
      isLoading={isLoading}
      scrollRef={elRef}
      itemCount={data?.items?.length ?? 0}
      meta={FEED_CARDS.PNL}>
      <div className={`${styles.container}`} ref={elRef}>
        {isReady &&
          data.map((item, idx) => {
            return (
              <TopPnlCard
                theme={theme}
                key={idx}
                card={item}
                location={location}
                comments={pnlComments}
                minWidth={FEED_CARDS.PNL.width}
                minHeight={FEED_CARDS.PNL.height}
              />
            );
          })}
      </div>
      {isReady && data?.length > 2 && (
        <HorizontalScrollButtons
          wrapperEl={elRef}
          postWidth={FEED_CARDS.PNL.width}
        />
      )}
    </ZxHorizontalScrollWrapper>
  );
};

export const TopPnlOverview = withNullableError(({ identity }) => {
  const { isLoading, isError, data } = useQuery({
    queryKey: QUERY_KEYS.TOP_USER_PNL({ identifier: identity }),
    queryFn: () => getTopPnlForIdentity({ identity }),
    staleTime: ONE_DAY,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
    enabled: !!identity,
  });
  const [pages, setPages] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  useEffect(() => {
    if (data) {
      const result = [];
      for (let i = 0; i < data.length; i += 4) {
        result.push(data.slice(i, i + 4));
      }
      setPages(result);
    }
  }, [data]);
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  if (isError || (!isLoading && !data)) {
    return null;
  }
  const isReady = !isLoading && data;
  if (!data || data?.length === 0) {
    return null;
  }

  const NextPrevPage = () => {
    if (pages.length <= 1) return null;
    const goBack = () => {
      setCurrentPage((prev) => (prev === 0 ? pages.length - 1 : prev - 1));
    };
    const goForward = () => {
      setCurrentPage((prev) => (prev === pages.length - 1 ? 0 : prev + 1));
    };
    return (
      <ZxFlex dir="row" gap="4px">
        <CaretCircleLeft
          color="var(--text-2)"
          onClick={goBack}
          size={20}
          style={{ cursor: "pointer" }}
        />
        <CaretCircleRight
          color="var(--text-2)"
          onClick={goForward}
          size={20}
          style={{ cursor: "pointer" }}
        />
      </ZxFlex>
    );
  };
  return (
    <RightBarCardContainer
      title="Top PnL trades"
      footer={null}
      titleRender={NextPrevPage}
      containerPadding="12px"
      footerLink={null}>
      <ZxFlex dir="column" gap="4px">
        {isReady &&
          pages?.length > 0 &&
          pages[currentPage]?.slice(0, 4).map((item, idx) => {
            return (
              <TopPnlCard
                cardPadding={0}
                theme={theme}
                key={idx}
                card={item}
                location={location}
                minWidth={OVERVIEW_CARDS.PNL.width}
                minHeight={20}
                hideActor
                tokenListHeight={20}
                showExtra={false}
                showNetworth={false}
                avatarSize={28}
                hideEngagements
                hidePnlComments
                baseBackground
              />
            );
          })}
        <PaddingComponent height="4px" />
      </ZxFlex>
    </RightBarCardContainer>
  );
});

export default withNullableError(TopPnl);
