import classes from "./GraphLoader.module.css";
const GraphLoader = ({
  width = "100%",
  height = 250,
  color = "var(--text-1)",
}) => {
  return (
    <div className={classes.container} style={{ width, height, color }}>
      <div className={classes["graph-loading"]}>
        <span className={classes["graph-loading__bar"]}></span>
        <span className={classes["graph-loading__bar"]}></span>
        <span className={classes["graph-loading__bar"]}></span>
        <span className={classes["graph-loading__bar"]}></span>
        <span className={classes["graph-loading__bar"]}></span>
      </div>
      <div className={classes.line}></div>
    </div>
  );
};

export default GraphLoader;
