import React, { forwardRef } from "react";
import classes from "./table.module.css";
import { Link } from "react-router-dom";

export const Table = ({ children, noPaddingBottom = false, ...props }) => {
  return (
    <div
      className={`${classes.table} ${
        noPaddingBottom ? classes.noPaddingBottom : ""
      }`}
      {...props}>
      {children}
    </div>
  );
};

export const TableHeader = ({
  children,
  noPadding,
  noBorder = false,
  background,
  paddingTop,
  noMarginBottom,
  halfMarginBottom,
  ...props
}) => {
  const className = `${classes.tableRow} 
  ${classes.tableHeader} 

  ${noPadding ? classes.noPadding : ""} ${noBorder ? classes.noBorder : ""}   ${
    noMarginBottom ? classes.noMarginBottom : ""
  }  ${background ? classes.background : ""} ${
    paddingTop ? classes.paddingTop : ""
  }
  `;

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

export const TableRow = ({
  children,
  noPadding,
  noMarginBottom,
  alignToBaseline,
  alignToFlexStart,
  border = false,
  className = "",
  ...props
}) => {
  const classNames = `${classes.tableRow} 
  ${noPadding ? classes.noPadding : ""} 
  ${noMarginBottom ? classes.noMarginBottom : ""} 
  ${alignToBaseline ? classes.alignToBaseline : ""}
  ${alignToFlexStart ? classes.alignToFlexStart : ""}
  ${border ? classes.rowBorder : ""}
  ${className}
  `;

  if (!!props?.to) {
    return (
      <Link className={classNames} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};

export const TableCell = forwardRef(
  (
    {
      children,
      alignLeft,
      alignRight,
      alignCenter,
      shrink,
      longRow,
      smallRow,
      ...props
    },
    ref
  ) => {
    const className = `${classes.tableCell} 
  ${alignRight ? classes.alignRight : ""} 
  ${alignLeft ? classes.alignLeft : ""}
  ${alignCenter ? classes.alignCenter : ""}
  ${shrink ? classes.shrink : ""}
  ${longRow ? classes.longRow : ""}
  ${smallRow ? classes.smallRow : ""}
  `;

    return (
      <div className={className} {...props} ref={ref}>
        {children}
      </div>
    );
  }
);
export const TableTitle = ({
  children,
  title,
  subTitle,
  noPadding = false,
  noBorder = false,
  ...props
}) => {
  return (
    <TableHeader noBorder={noBorder} noPadding={noPadding}>
      <div className={classes.tableTitle} {...props}>
        {title && <div className={classes.mainTitle}>{title}</div>}
        {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
        {children}
      </div>
    </TableHeader>
  );
};
