import React, { useContext, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import "./Sass/App.scss";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { GlobalContext } from "./contextStates/Global";
import ThemeContextProvider from "./contextStates/Theme.context";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Routes from "./components/Routes/Routes";
import SnackbarPopUp from "./shared/Snackbar/Snackbar";
import AuthContextProvider from "./contextStates/AuthContext";
import GlobalContextProvider from "./contextStates/Global";
import LeftSideHeaderContextProvider from "./contextStates/LeftSideHeaderContext";
import MessagingContextProvider from "contextStates/MessagingContext";
import UserContextProvider from "contextStates/User";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location]);
};

function GoogleAnalytics() {
  usePageTracking();
  return null;
}

function App() {
  const { snackBarState } = useContext(GlobalContext);
  const queryClient = new QueryClient();

  useEffect(() => {
    if (navigator?.appVersion?.indexOf("Win") !== -1) {
      // Add "windows" class to html
      document.documentElement.classList.add("windows");
    }
    // else if (navigator?.appVersion?.indexOf("Macintosh") !== -1) {
    //   document.documentElement.classList.add("mac");
    // }
  }, []);
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeContextProvider>
          <ErrorBoundary fullPage>
            <BrowserRouter>
              <GoogleAnalytics />
              <HelmetProvider>
                <GlobalContextProvider>
                  <AuthContextProvider>
                    <MessagingContextProvider>
                      <LeftSideHeaderContextProvider>
                        <UserContextProvider>
                          <Routes />
                        </UserContextProvider>
                        <SnackbarPopUp snackBarState={snackBarState} />
                        {process.env.NODE_ENV !== "production" && (
                          <ReactQueryDevtools initialIsOpen={false} />
                        )}
                      </LeftSideHeaderContextProvider>
                    </MessagingContextProvider>
                  </AuthContextProvider>
                </GlobalContextProvider>
              </HelmetProvider>
            </BrowserRouter>
          </ErrorBoundary>
        </ThemeContextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
