import { useRef, useMemo, useState } from "react";

const useSmallBalanceFilter = (originalHoldings, activeChainId) => {
  const [containsSmallTokens, setContainsSmallTokens] = useState(false);
  const [isSmallTokensVisible, setIsSmallTokensVisible] = useState(false);
  const smallTokensCount = useRef(0);
  
  const chainTokenHoldings = useMemo(() => {
    const holdings = originalHoldings?.filter((item) => {
      const tokenWeight = activeChainId
        ? item?.relative_percentage?.value
        : item?.relative_percentage_across_chains?.value;

      if (!containsSmallTokens && (!tokenWeight || tokenWeight < 1))
        setContainsSmallTokens(true);

      let condition = true;

      if (activeChainId) {
        condition = item?.chain_id === activeChainId;
      }

      condition = condition && tokenWeight > 1;
      return condition;
    });
    if (holdings?.length === 0) {
      setContainsSmallTokens(false);
    }
    smallTokensCount.current = originalHoldings?.length - holdings?.length;
    return holdings?.length === 0 || isSmallTokensVisible
      ? originalHoldings
      : holdings;
  }, [
    activeChainId,
    containsSmallTokens,
    isSmallTokensVisible,
    originalHoldings,
  ]);

  return {
    chainTokenHoldings,
    isSmallTokensVisible,
    setIsSmallTokensVisible,
    containsSmallTokens,
    smallTokensCount: smallTokensCount.current,
  };
};

export default useSmallBalanceFilter;
