import React from "react";
import styled from "styled-components";

const CustomSwitchButton = ({
  toggle,
  setToggle,
  isDisabled = false,
  isPrimaryColored = true,
}) => {
  return (
    <Switch
      toggle={toggle}
      isPrimaryColored={isPrimaryColored}
      disabled={isDisabled}>
      <Input
        {...{}}
        checked={toggle}
        disabled={isDisabled}
        type="checkbox"
        onChange={() => {
          if (!isDisabled) {
            setToggle(!toggle);
          }
        }}
      />
      <Slider
        toggle={toggle}
        disabled={isDisabled}
        isPrimaryColored={isPrimaryColored}
        onClick={() => {}}
      />
    </Switch>
  );
};

export default CustomSwitchButton;

const Slider = styled.span`
  position: absolute;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ toggle, activeSliderBackground }) =>
    toggle ? activeSliderBackground : "var(--elevation-1)"};
  border-radius: 20px;
  border: 1px solid var(--border-dark);
  transition: 0.4s;

  &:before {
    content: "";
    position: absolute;
    left: 3px;
    right: 0px;
    bottom: 1px;
    top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: ${({ toggle, isPrimaryColored }) =>
      isPrimaryColored && toggle ? "white" : "var(--text-3)"};
    transition: 0.4s;
  }
`;
const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: var(--primary-color);
  }

  &:checked + ${Slider}:before {
    transform: translateX(16px);
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px var(--primary-color);
  }

  &:disabled + ${Slider} {
    pointer-events: none;
  }
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background-color: ${({ toggle, isPrimaryColored }) =>
    toggle && isPrimaryColored ? "var(--primary-color)" : "var(--base)"};
  border-radius: 15px;
  transition: 0.4s;

  & ${Input} {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;
