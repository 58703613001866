import classes from "./NetWorthCardToken.module.css";
import IconWithChain from "components/Pages/Screener/IconWithChain";
import { IMAGE_TYPES } from "components/UI/Image";

const NetWorthCardToken = ({ token, networth = null, relativePercentage }) => {
  return (
    <div className={classes.net_worth_card_token}>
      <IconWithChain
        src={token?.token_metadata?.logo}
        alt={token?.token_metadata?.symbol}
        chain={token?.chain_id}
        imageType={IMAGE_TYPES.TOKEN}
      />
      <div className={classes.text_container}>
        <span className={classes.name} title={token.token_metadata.name}>
          {" "}
          {token.token_metadata.symbol.slice(0, 20)}
          {token.token_metadata.symbol.length > 20 && "..."}{" "}
          {token.total_usd_value?.display_value}
          {` (${relativePercentage}%)`}
        </span>
      </div>
    </div>
  );
};

export default NetWorthCardToken;
