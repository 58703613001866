import React, { useCallback, useRef } from "react";
import styles from "./ZxTable.module.scss";
import {
  CustomSkeleton,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import EmptyStateUI from "components/ErrorBoundary/EmptyStateUI";
import { useVirtualizer } from "@tanstack/react-virtual";
import { getUniqueId } from "utils/event_tracking";
import Refetch from "components/UI/Refetch/Refetch";
import ZxTriggerNextPage from "../ZxTriggerNextPage/ZxTriggerNextPage";

const ZxTable = ({
  columns,
  isLoading = false,
  isFetchingNextPage = false,
  data = null,
  commonProps,
  maxHeight = "400px",
  minHeight = "400px",
  infiniteScroll = false,
  fetchNextPage = () => {},
  hasNextPage,
  isError,
  showRefetchOnError = false,
  refetch = null,
  emptyText = "No data found",
  columnHeight = 35,
  hideBorders = false,
  onRowClick = null,
  rowClassName = "",
  theadClassName = "",
  rowPadding = "8px 16px",
  isTokenPageTable = false,
}) => {
  const tableRef = useRef();
  const rowVirtualizer = useVirtualizer({
    count: data?.length ?? 25,
    getScrollElement: () => tableRef.current,
    estimateSize: () => columnHeight,
    overscan: 20,
  });
  const renderLoading = useCallback(() => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
      <div key={index} className={`${styles.trow} `}>
        {columns.map((column, index) => (
          <CustomSkeleton
            height="40px"
            key={index}
            width={column.width}
            className={styles.skeletonCell}
          />
        ))}
      </div>
    ));
  }, [columns]);
  const renderRows = useCallback(() => {
    if (!data) return null;
    if (data.length === 0) {
      return (
        <div className={styles.empty}>
          <EmptyStateUI text={emptyText} emptyTransactions />
        </div>
      );
    }
    const vitems = rowVirtualizer.getVirtualItems();
    return (
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}>
        {vitems.map((virtualItem, idx) => {
          const row = data[virtualItem.index];
          const isLast = virtualItem.index === data.length - 1;
          // const {
          //   animate,
          //   animation,
          //   animationTiming,
          //   preAnimationClass,
          //   animationDelay,
          // } = row;
          const key = getUniqueId();
          // if (animationDelay) {
          //   setTimeout(() => {
          //     const element = document.getElementById(`row-${key}`);
          //     // remove the animation class after the animation is done
          //     if (element) {
          //       element.classList.add(animation);
          //       // element.classList.remove(preAnimationClass);
          //     }
          //   }, animationDelay);
          // }
          // if (animationTiming) {
          //   setTimeout(() => {
          //     const element = document.getElementById(`row-${key}`);
          //     // remove the animation class after the animation is done
          //     if (element) {
          //       element.classList.remove(animation);
          //       if (
          //         preAnimationClass &&
          //         element.classList.contains(preAnimationClass)
          //       ) {
          //         element.classList.remove(preAnimationClass);
          //       }
          //     }
          //   }, animationTiming + animationDelay);
          // }

          return (
            <div
              key={`${virtualItem.key}`}
              id={`row-${key}`}
              className={`${styles.trow} ${rowClassName}`}
              onClick={() => {
                if (onRowClick) {
                  onRowClick(row);
                }
              }}
              style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
                borderBottom:
                  hideBorders && isLast
                    ? "none"
                    : "1px solid var(--border-dark)",
                // backgroundColor: animate ? "red" : "transparent",
                cursor: onRowClick ? "pointer" : "default",
                display: "flex",
                alignItems: "center",
              }}>
              {columns.map((column, index) => {
                const isFirstColumn = index === 0;
                return (
                  <div
                    key={`${column.key}-${getUniqueId()}`}
                    className={styles.trowCell}
                    style={{
                      width: column.width,
                      justifyContent: column.align ?? "flex-start",
                      borderLeft: isFirstColumn
                        ? "none"
                        : hideBorders
                          ? "none"
                          : "1px solid var(--border-dark)",
                      padding: rowPadding,
                      minHeight: columnHeight,
                      display: "flex",
                      alignItems: "center",
                    }}>
                    {column.render
                      ? column.render(row, {
                          index: virtualItem.index,
                          ...commonProps,
                        })
                      : row[column.key]}
                  </div>
                );
              })}
            </div>
          );
        })}
        <div
          key={"loading-" + getUniqueId()}
          style={{
            position: "absolute",
            top: `${rowVirtualizer.getTotalSize()}px`,
            width: "100%",
          }}>
          {fetchNextPage &&
            !isFetchingNextPage &&
            infiniteScroll &&
            hasNextPage &&
            data.length > 3 && (
              <ZxTriggerNextPage
                fetchNextPage={fetchNextPage}
                shouldFetch={true}
                width={2}
                height={1000}
              />
            )}
          {hasNextPage &&
            !isLoading &&
            infiniteScroll &&
            isFetchingNextPage &&
            renderLoading()}
        </div>
      </div>
    );
  }, [
    data,
    isLoading,
    infiniteScroll,
    columns,
    commonProps,
    rowVirtualizer,
    fetchNextPage,
    isFetchingNextPage,
    renderLoading,
    hasNextPage,
    emptyText,
    hideBorders,
    onRowClick,
    rowClassName,
    rowPadding,
    columnHeight,
  ]);

  if (!data && !isLoading && isError && refetch && showRefetchOnError) {
    return (
      <PaddingComponent padding="16px 0">
        <Refetch onClick={refetch} />
      </PaddingComponent>
    );
  }
  return (
    <div
      className={`${styles.table} ${hideBorders ? styles.hideBorders : ""} ${isTokenPageTable ? styles.tokenPageTable : ""}`}
      style={{ minHeight }}>
      <div
        className={`${styles.thead} ${hideBorders ? styles.hideBorders : ""} `}>
        {columns.map((column, index) => {
          const isFirstColumn = index === 0;
          return (
            <div
              key={index}
              className={`${styles.theadCell} ${hideBorders ? styles.hideBorders : ""} ${theadClassName}`}
              style={{
                width: column.width,
                justifyContent: column.align ?? "flex-start",
                borderLeft: isFirstColumn
                  ? "none"
                  : hideBorders
                    ? "none"
                    : "1px solid var(--border-dark)",
                padding: rowPadding,
              }}>
              {column.title}
            </div>
          );
        })}
      </div>
      {hideBorders && <Divider />}
      <div
        className={`${styles.tableBody} ${hideBorders ? styles.hideBorders : ""}`}
        style={{ maxHeight: maxHeight, minHeight: minHeight }}
        ref={tableRef}>
        {isLoading ? renderLoading(columns, true) : renderRows()}
      </div>
    </div>
  );
};

export default ZxTable;
