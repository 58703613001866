import classes from "components/V2/Token/TokenChart/TokenChart.module.css";
import { useState, useMemo } from "react";
import GraphV2 from "components/V2/Token/TokenChart/GraphV2";
import GraphLoader from "components/V2/Profile/ProfileDetails/Portfolio/ProfileGraphs/GraphLoader";
import Refetch from "components/UI/Refetch/Refetch";
import { Circle } from "@phosphor-icons/react";
import { Chart } from "utils/constants";
import { tsCutoff } from "components/V2/Token/TokenChart/chartUtils";

const TrendingLiveChart = ({
  headerContent,
  chartData,
  isLoading = false,
  isError = false,
  refetch = () => {},
  tokenName,
}) => {
  const [duration, setDuration] = useState(Chart.DURATION["1D"]);

  const tokenChartData = useMemo(() => {
    if (!chartData) return [];

    const cutoffTs = tsCutoff(Chart.DURATION_DAYS[duration]);

    return chartData.filter(([timestamp]) => timestamp * 1000 >= cutoffTs);
  }, [chartData, duration]);

  if (!tokenChartData) return null;
  return (
    <div className={classes.profile_graphs}>
      <div className={classes.header}>
        <div className={classes.left_panel}>{headerContent}</div>
        <div className={classes.right_panel}>
          <div className={classes.live}>
            <Circle size={5} color="var(--primary-color)" weight="fill" />
            Live
          </div>
          <span className={classes.duration}></span>

          {Object.keys(Chart.DURATION).map((key) => {
            return (
              <span
                className={`${classes.duration} ${
                  Chart.DURATION[key] === duration
                    ? classes.duration_active
                    : ""
                }`}
                onClick={() => setDuration(Chart.DURATION[key])}
                key={key}>
                {Chart.DURATION[key]}
              </span>
            );
          })}
        </div>
      </div>
      <div className={classes.graph_component}>
        {tokenChartData?.length && (
          <GraphV2 data={tokenChartData} isSeconds name={tokenName} />
        )}
        {isLoading && <GraphLoader />}
        {isError && (
          <Refetch
            onClick={() => {
              refetch();
            }}
            containerClass={classes.graph_component}
          />
        )}
      </div>
    </div>
  );
};

export default TrendingLiveChart;
