import { useCallback, useRef } from "react";

export const updateChartMarkers = (type = "clear") => {
  try {
    const widget = window.tvWidget;
    const chart = widget?.activeChart();
    if (chart) {
      if (type === "clear") {
        chart.clearMarks();
      } else {
        chart.refreshMarks();
      }
    }
  } catch (err) {
    console.error({ err });
    return;
  }
};

export const removeTradingView = () => {
  try {
    const widget = window.tvWidget;
    if (widget) {
      widget.remove();
    }
  } catch (err) {
    console.error({ err });
    return;
  }
};

export const useTradingViewMarkers = () => {
  const markers = useRef([]);
  const getChartMarkers = useCallback(() => {
    return markers;
  }, [markers]);
  const updateMarkers = (newMarkers) => {
    markers.current = newMarkers;
    updateChartMarkers("refresh");
  };
  const clearMarkers = () => {
    markers.current = [];
    updateChartMarkers("clear");
  };
  return { markers, getChartMarkers, updateMarkers, clearMarkers };
};
