import {
  useGetNftCollectionActivities,
  useGetNftTokenTransfers,
} from "api/nft.api";
import React from "react";
import { useHistory } from "react-router-dom";
import { NftActivitiesLoader } from "../ZxNftLoaders/ZxNftLoaders";
import styles from "./ZxNftActivities.module.scss";
import withErrorBoundary from "components/ErrorBoundary/withErrorBoundary";
import {
  CustomColumn,
  CustomRow,
  Divider,
  PaddingComponent,
} from "components/UI/Components/Components";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { EMPTY_STATES, TSTYLES } from "zxStyles/constants";
import { getTrimedAddress, stopEventPropagation } from "utils/misc";
import ConditionalLink from "shared/ConditionalLink";
import { ArrowRight } from "@phosphor-icons/react";
import { TimeLabel } from "components/FeedItemV2/ActivityHeader";
import ZxTriggerNextPage from "zxComponents/common/ZxTriggerNextPage/ZxTriggerNextPage";
import { SaleActor } from "./ZxNftActivities.utils";
import GenericFallBackUI from "components/ErrorBoundary/GenericFallBackUI";
import ZxEmptyState from "zxComponents/common/ZxEmptyState/ZxEmptyState";
import {
  BurntAddress,
  MintedAddress,
} from "components/V2/ActivityDetails/Transactions/TokenTransfers/TransactionTokenTransferTile";
import ZxBadge from "zxComponents/common/ZxBadge";
import { CaretRight } from "../../../../node_modules/@phosphor-icons/react/dist/index";

const ZxNftActivities = ({
  collectionId = null,
  activeTab,
  chainId = null,
  contractAddress = null,
  tokenId = null,
  isNft = false,
}) => {
  const history = useHistory();
  const collectionQuery = useGetNftCollectionActivities({
    collectionId,
    enabled: !isNft,
  });
  const tokenQuery = useGetNftTokenTransfers({
    chainId,
    contractAddress,
    tokenId,
    enabled: isNft,
  });
  const query = collectionId ? collectionQuery : tokenQuery;
  const {
    data: activities,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isError,
  } = query;
  const flattenedTransfers =
    activities?.pages?.flatMap((page) => page?.transfers) ??
    activities?.transfers ??
    [];
  let flattenedProfiles = {};

  // eslint-disable-next-line no-unused-expressions
  flattenedProfiles = activities?.pages
    ? activities?.pages?.reduce((acc, page) => {
        return { ...acc, ...page?.profiles };
      }, {})
    : activities?.profiles;
  if (isError) {
    return <GenericFallBackUI />;
  }
  const isEmpty = activities && flattenedTransfers.length === 0;
  return isLoading && collectionId ? (
    <NftActivitiesLoader />
  ) : (
    <div className={styles.container}>
      {!isEmpty ? (
        flattenedTransfers.map((act, idx) => {
          const isLast = idx === flattenedTransfers?.length - 1;
          const nft = act?.nft;
          const name = nft?.name;

          const fromActor = flattenedProfiles?.[act?.from_address] ?? {
            display_name: getTrimedAddress(act?.from_address),
            display_picture: null,
            address: act?.from_address,
          };
          const toActor = flattenedProfiles?.[act?.to_address] ?? {
            display_name: getTrimedAddress(act?.to_address),
            display_picture: null,
            address: act?.to_address,
          };
          const isMint = act?.event_type === "mint";
          const isBurn = act?.event_type === "burn";
          const price = act?.sale_price?.token_value?.display_value ?? null;
          return (
            <CustomColumn key={`${idx}`}>
              <ConditionalLink
                className={`${styles.row} ${isNft ? styles.nftRow : ``}`}
                sameTab={false}
                to={`${act?.transaction_link ?? `/tx/${act?.transaction}`}`}>
                {!isNft && (
                  <div
                    onClick={(e) => {
                      if (isNft) {
                        return;
                      }
                      stopEventPropagation(e);
                      const tokenId = nft?.token_id ? `/${nft?.token_id}` : "";
                      history.push(
                        `/collection/${collectionId}/${activeTab}/${nft?.chain_id}/${nft?.contract_address}${tokenId}`
                      );
                    }}>
                    <CustomRow gap="8px" alignItems="flex-end">
                      <div className={styles.imageContainer}>
                        <Image
                          src={nft?.image_url}
                          alt={name}
                          imageType={IMAGE_TYPES.NFT}
                        />
                      </div>
                      <ZxText fontSize="15px">{name}</ZxText>
                    </CustomRow>
                  </div>
                )}
                <CustomColumn gap="8px">
                  <ZxBadge variant={act?.event_type}>{act?.event_type}</ZxBadge>
                  <CustomRow>
                    <CustomRow gap="16px" alignItems="center">
                      <PaddingComponent style={{ minWidth: "100px" }}>
                        {isMint ? (
                          <MintedAddress showArrow={false} />
                        ) : (
                          <SaleActor actor={fromActor} />
                        )}
                      </PaddingComponent>
                      <ArrowRight
                        size={16}
                        weight="bold"
                        color="var(--text-1)"
                      />
                      {isBurn ? (
                        <BurntAddress />
                      ) : (
                        <SaleActor actor={toActor} />
                      )}
                    </CustomRow>
                  </CustomRow>
                </CustomColumn>
                <div className={styles.priceTimeContainer}>
                  <TimeLabel timestamp={act?.timestamp} showYearWithMonth />
                  <ZxText style={TSTYLES.title2} fontSize={"13px"}>
                    {price ?? "--"}
                  </ZxText>
                </div>
                <PaddingComponent paddingLeft="16px">
                  <CaretRight size={16} color="var(--text-2)" />
                </PaddingComponent>
              </ConditionalLink>

              {!isLast ? (
                <Divider margin="16px 0" />
              ) : (
                <ZxTriggerNextPage
                  shouldFetch={hasNextPage && !isFetching && fetchNextPage}
                  fetchNextPage={() => {
                    fetchNextPage();
                  }}
                />
              )}
            </CustomColumn>
          );
        })
      ) : (
        <ZxEmptyState
          state={EMPTY_STATES.POSTS}
          text="No activities"
          size={150}
        />
      )}

      {isFetching && <NftActivitiesLoader />}
    </div>
  );
};

export default withErrorBoundary(ZxNftActivities);
