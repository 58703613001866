import classes from "./CustomDivider.module.css";

const CustomDivider = ({ reverse = false }) => {
  if (reverse) {
    return (
      <div className={classes.container}>
        <div className={classes.divider}></div>
        <div className={classes.squareRotate}></div>
        <div className={classes.square}></div>
        <div className={classes.circle}></div>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <div className={classes.circle}></div>
      <div className={classes.square}></div>
      <div className={classes.squareRotate}></div>
      <div className={classes.divider}></div>
    </div>
  );
};

export default CustomDivider;
