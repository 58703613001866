import { useQuery } from "@tanstack/react-query";
import { getContractDetails, getCoreContractDetails } from "api/contract.api";
import { getContractInfo, getTokenPageData } from "api/token_page.api";
import { ONE_DAY } from "utils/constants";
import { QUERY_KEYS } from "utils/query_utils";
const useTokenPageDetails = ({ chainId, contractAddress }) => {
  const metadata = useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_METADATA({ chainId, contractAddress }),
    queryFn: () => getContractInfo({ chainId, contractAddress }),
    retry: 2,
    staleTime: ONE_DAY,
    enabled: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const contractDetails = useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_CONTRACT_DETAILS({
      chainId,
      contractAddress,
    }),
    queryFn: () =>
      getContractDetails({ chain_id: chainId, address: contractAddress }),
    retry: 2,
    staleTime: ONE_DAY,
    enabled: true,
  });
  const tokenDetails = useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_TOKEN_DETAILS({ chainId, contractAddress }),
    queryFn: () => getTokenPageData({ chainId, contractAddress }),
    retry: 2,
    staleTime: ONE_DAY,
    enabled: true,
    refetchInterval: 10000,
  });
  const coreDetails = useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_CORED_DETAILS({ chainId, contractAddress }),
    queryFn: () =>
      getCoreContractDetails({ chain_id: chainId, address: contractAddress }),
    retry: 2,
    staleTime: ONE_DAY,
    enabled: true,
  });
  const isAllLoading =
    metadata.isLoading && contractDetails.isLoading && tokenDetails.isLoading;
  return {
    metadata,
    contractDetails,
    tokenDetails,
    isAllLoading,
    coreDetails,
  };
};

export default useTokenPageDetails;
