import { getNftIndices } from "components/GroupFeedItem/GroupFeedItem.components";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

export const GalleryContext = createContext();

const GalleryContextProvider = ({ children }) => {
  const history = useHistory();
  const [activeMediaInfo, setActiveMediaInfo] = useState({
    src: "",
    alt: "",
    styles: "",
    content_type: "",
  });

  const [groupedActivity, setGroupedActivity] = useState(null);
  const [activityId, setActivityId] = useState(null);
  const [index, setIndex] = useState(null);
  const urlFallbackRef = useRef(null);
  const nftIndices = useMemo(
    () =>
      getNftIndices(groupedActivity ?? []).filter(
        ({ activityIdx }) =>
          activityId == null ||
          groupedActivity?.activities[activityIdx].id === activityId
      ),
    [activityId, groupedActivity]
  );

  // zoom in/out handler
  const [zoom, setZoom] = useState(1);

  //selected image i.e. clicked from feed images / pressed next or prev / on click of thumbnails
  const handleActiveMediaInfo = useCallback(
    (newIndex) => {
      const { activityIdx, mediaIdx } = nftIndices[newIndex] ?? {};
      setIndex(newIndex);
      if (groupedActivity && activityIdx != null && mediaIdx != null) {
        setActiveMediaInfo(
          groupedActivity?.activities[activityIdx].media[mediaIdx]
        );
        setZoom(1);
        const url = activityId
          ? `/activity_details/${activityId}/media/${mediaIdx}`
          : `/${groupedActivity.type}/${groupedActivity.id}/media/${activityIdx}/${mediaIdx}`;
        urlFallbackRef.current = activityId
          ? `/activity_details/${activityId}`
          : `/${groupedActivity.type}/${groupedActivity.id}`;

        const location = history.location;
        if (location.pathname !== url) {
          history.replace({ pathname: url, state: location.state });
        }
      }
    },
    [activityId, groupedActivity, history, nftIndices]
  );

  useEffect(() => {
    if (index != null) handleActiveMediaInfo(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleActiveMediaInfo, index]);

  return (
    <GalleryContext.Provider
      value={{
        activeMediaInfo,
        zoom,
        index,
        groupedActivity,
        setActiveMediaInfo,
        handleActiveMediaInfo,
        setGroupedActivity,
        setActivityId,
        setZoom,
        nftIndices,
        urlFallbackRef,
      }}>
      {children}
    </GalleryContext.Provider>
  );
};

export default GalleryContextProvider;
