import {
  CustomColumn,
  CustomRow,
  CustomText,
  PaddingComponent,
} from "components/UI/Components/Components";
import React, { useContext } from "react";
import styles from "./TokenDetails.module.css";
import Copyable from "components/ValueType/Copyable/Copyable";
import TokenPrices from "../TokenPriceChangeBar/TokenPriceChangeBar";
import ConditionalLink from "shared/ConditionalLink";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import usePageTitle from "customHooks/usePageTitle";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "utils/query_utils";
import { getPriceDelta } from "components/V2/Token/TokenChart/ChartDataPriceUtils";
import { abbreviateNumber } from "utils/misc";
import { TokenPageContext } from "../TokenPageContext";

const DeployerPill = ({ deployer }) => {
  if (!deployer || !deployer?.address) return null;
  return (
    <PaddingComponent className={styles.addressContainer}>
      <CustomText
        text="Deployer"
        color="var(--text-2)"
        fontSize="12px"
        fontWeight="400"
        lineHeight="20px"
        textAlign="left"
        letterSpacing="0.8px"
        textTransform="uppercase"
      />

      <Copyable content={deployer?.address}>
        <ProfileHoverCard actor={deployer}>
          <ConditionalLink to={deployer?.link ?? deployer?.address_link}>
            <CustomRow alignItems="center" gap="8px">
              <Image
                src={deployer?.display_picture}
                imageType={IMAGE_TYPES.AVATAR}
                alt={deployer?.display_name}
                name={deployer?.display_name}
                className={styles.deployerImage}
              />
              <CustomText
                text={deployer?.display_name ?? "-"}
                color="var(--text-1)"
                fontSize="16px"
                fontWeight="500"
                lineHeight="20px"
                textAlign="left"
              />
            </CustomRow>
          </ConditionalLink>
        </ProfileHoverCard>
      </Copyable>
    </PaddingComponent>
  );
};

const TokenDetails = ({
  slug,
  chainId,
  contractAddress,
  deployer,
  visibleTokenDetails = [],
  tokenDetails,
}) => {
  const name = tokenDetails?.data?.token_metadata?.name;
  const symbol = tokenDetails?.data?.token_metadata?.symbol;
  const { chartPrice } = useContext(TokenPageContext);
  const { data: priceBar } = useQuery({
    queryKey: QUERY_KEYS.TOKEN_PAGE_PRICE_PILLS({
      chainId,
      contractAddress,
    }),
    queryFn: () => getPriceDelta({ chainId, contractAddress }),
    retry: 1,
    staleTime: 1000 * 60 * 60,
  });
  const currentPrice = chartPrice;
  const marketCap =
    currentPrice && priceBar?.mcapMultiplier
      ? priceBar?.mcapMultiplier * currentPrice
      : null;
  const fdv =
    priceBar?.fdvMultiplier && currentPrice
      ? priceBar?.fdvMultiplier * currentPrice
      : null;
  const title =
    marketCap || name
      ? ` ${symbol} ${currentPrice ? `$${abbreviateNumber(currentPrice, false, true)}` : marketCap && !isNaN(marketCap) ? `$${abbreviateNumber(marketCap, false, true)}` : ""} - ${name ?? ""} `
      : "0xPPL";
  usePageTitle({ title });
  const renderDisplayList = () => {
    return (
      <div className={styles.pillsContainer}>
        {visibleTokenDetails?.map((pill, index) => {
          if (pill.label === "Market cap") {
            return (
              <PaddingComponent key={index} className={styles.detailsPill}>
                <CustomText
                  text={pill.label}
                  color="var(--text-2)"
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="16px"
                  letterSpacing="0.8px"
                  textAlign="left"
                  textTransform="uppercase"
                />
                <CustomText
                  text={
                    marketCap && !isNaN(marketCap)
                      ? `$${abbreviateNumber(marketCap)}`
                      : "-"
                  }
                  color="var(--text-1)"
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="20px"
                  textAlign="left"
                />
              </PaddingComponent>
            );
          }
          if (pill.label === "Fdv") {
            return (
              <PaddingComponent key={index} className={styles.detailsPill}>
                <CustomText
                  text={pill.label}
                  color="var(--text-2)"
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="16px"
                  letterSpacing="0.8px"
                  textAlign="left"
                  textTransform="uppercase"
                />
                <CustomText
                  text={fdv && !isNaN(fdv) ? `$${abbreviateNumber(fdv)}` : "-"}
                  color="var(--text-1)"
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="20px"
                  textAlign="left"
                />
              </PaddingComponent>
            );
          }
          if (pill.label === "Token address" || pill.label === "Deployer")
            return null;
          return (
            <PaddingComponent key={index} className={styles.detailsPill}>
              <CustomText
                text={pill.label}
                color="var(--text-2)"
                fontSize="12px"
                fontWeight="400"
                lineHeight="16px"
                letterSpacing="0.8px"
                textAlign="left"
                textTransform="uppercase"
              />
              <CustomText
                text={pill.display_value}
                color="var(--text-1)"
                fontSize="16px"
                fontWeight="500"
                lineHeight="20px"
                textAlign="left"
              />
            </PaddingComponent>
          );
        })}
      </div>
    );
  };
  return (
    <CustomColumn gap="16px">
      <CustomText
        text="Token Details"
        color="var(--text-1)"
        fontSize="16px"
        fontWeight="500"
        lineHeight="20px"
        textAlign="left"
      />
      <TokenPrices
        slug={slug}
        chainId={chainId}
        contractAddress={contractAddress}
      />
      {renderDisplayList()}
      <DeployerPill deployer={deployer} />
    </CustomColumn>
  );
};

export default TokenDetails;
