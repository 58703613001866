import {
  CustomColumn,
  CustomRow,
  CustomSkeleton,
  CustomText,
  PaddingComponent,
  PriceChange,
} from "components/UI/Components/Components";
import React from "react";
import { abbreviateNumber } from "utils/misc";
import ZxText from "zxComponents/common/ZxText/ZxText";
import { TSTYLES } from "zxStyles/constants";
import ZxFlex from "zxComponents/common/ZxFlex/ZxFlex";
import ZxDot from "zxComponents/common/ZxDot";
import { useGetTokenPriceChange } from "api/token_page.api";
const TVPriceHeader = ({
  chain,
  address,
  showSymbolInHeader = false,
  symbol,
  prices,
  chartPrice,
  isTokenPage = false,
  marketCap = null,
  poolAddress,
  isNativeToken = false,
}) => {
  const {
    data: priceBar,
    isError: isPriceBarError,
    isLoading,
  } = useGetTokenPriceChange({
    chain,
    address: poolAddress,
  });
  if (!chartPrice && !priceBar && !isLoading) return null;
  if (!chartPrice && !priceBar && !isPriceBarError)
    return (
      <CustomRow>
        <CustomSkeleton width="200px" height="36px" />
      </CustomRow>
    );
  const priceDiff = priceBar
    ? {
        direction: priceBar?.priceChange24 < 0 ? -1 : 1,
        displayValue: `${abbreviateNumber(
          priceBar?.priceChange24 * 100,
          false,
          false
        )}%`,
      }
    : null;
  const displayValue =
    isTokenPage && marketCap && !isNativeToken ? marketCap : chartPrice;
  return (
    <PaddingComponent>
      <CustomColumn gap="0">
        {!!marketCap && !!isTokenPage && !isNativeToken && (
          <ZxText style={TSTYLES.secondaryLabel} color="text-2">
            MARKET CAP
          </ZxText>
        )}
        <CustomRow gap="8px" alignItems="center">
          {showSymbolInHeader && (
            <CustomText
              color="var(--text-1)"
              textAlign="left"
              fontSize={isTokenPage ? "36px" : "24px"}
              fontWeight="600"
              text={symbol}
            />
          )}
          {!!chartPrice && (
            <ZxText
              style={TSTYLES.title1}
              fontSize={isTokenPage ? "36px" : "24px"}>
              ${abbreviateNumber(displayValue, false, false, true)}
            </ZxText>
          )}

          {!!priceDiff && !!priceDiff?.direction && chartPrice && (
            <PriceChange
              value={priceDiff?.direction ?? 0}
              displayValue={`${priceDiff.displayValue}`}
              isNewStyle
              arrowSize={20}
              fontSize="15px"
              fontWeight="500"
            />
          )}
          {isTokenPage && !!marketCap && !isNativeToken && (
            <ZxFlex gap="8px">
              <ZxDot />
              <ZxText color="text-2" fontSize="15px">
                ${abbreviateNumber(chartPrice, false, true)}
              </ZxText>
            </ZxFlex>
          )}
        </CustomRow>
      </CustomColumn>
    </PaddingComponent>
  );
};

export default TVPriceHeader;
