import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../../../contextStates/AuthContext";
import { AddBundleForm, BundleRow } from "./AddToBundle";
import classes from "./AddWallet.module.css";
import {
  CustomButton,
  CustomText,
  PaddingComponent,
} from "../../../../../UI/Components/Components";
import { WalletTypeOptions } from "./WalletType";
import { CustomRow, FlexGrow } from "./AddWalletComponent";
import { NonShrinkingTextField } from "./ConnectWallet";
import DynamicWalletButton from "../../../../../DynamicWalletButton/DynamicWalletButton";
import { FolderPlus } from "@phosphor-icons/react";
import { AddWalletContext } from "./AddWallet";
import { useHistory, useParams } from "react-router-dom";
import { GlobalContext } from "../../../../../../contextStates/Global";
import { addRemoveAddressFromBundle } from "../../../../../../api/profile.api";
import { trackEvent } from "../../../../../../utils/event_tracking";

export const EditWalletForm = () => {
  const {
    setWalletType,
    walletType,
    address,
    name,
    setName,
    bundleData,
    wallet,
    refetch,
  } = useContext(AddWalletContext);
  const { verifyWallet } = useContext(AuthContext);

  const [showNewBundleForm, setShowNewBundleForm] = useState(false);
  if (showNewBundleForm) {
    return <AddBundleForm setShowNewBundleForm={setShowNewBundleForm} />;
  }
  return (
    <div className={classes.form}>
      <CustomText
        text="Wallet type"
        fontSize="17px"
        fontWeight="500"
        textAlign="left"
        color="var(--text-1)"
      />
      <PaddingComponent minHeight="16px" />
      <WalletTypeOptions
        walletType={walletType}
        size="small"
        setWalletType={setWalletType}
      />
      <PaddingComponent minHeight="16px" />
      <CustomText
        text="Wallet address"
        fontSize="17px"
        fontWeight="500"
        textAlign="left"
        color="var(--text-1)"
      />
      <PaddingComponent minHeight="16px" />
      <CustomRow gap={"16px"} alignItems="stretch">
        <FlexGrow>
          <NonShrinkingTextField
            name={"address"}
            placeholder={"Wallet address"}
            value={address}
            disabled
            fullWidth
          />
        </FlexGrow>
        <DynamicWalletButton
          onSuccess={(session) =>
            verifyWallet({
              address: wallet.address,
              session,
              onSuccess: refetch,
            })
          }
          customRender={() => {
            return (
              <CustomButton
                radius="8px"
                height="50px"
                disabled={wallet?.is_verified}
                text={
                  <CustomRow gap="5px">
                    <CustomText
                      text="Verify"
                      color="white"
                      fontSize="15px"
                      fontWeight="500"
                    />
                  </CustomRow>
                }
              />
            );
          }}
          disabled={wallet?.is_verified}
        />
      </CustomRow>
      <PaddingComponent minHeight="16px" />
      <CustomText
        text="Wallet name"
        fontSize="17px"
        fontWeight="500"
        textAlign="left"
        color="var(--text-1)"
      />
      <PaddingComponent minHeight="16px" />
      <NonShrinkingTextField
        name={"name"}
        placeholder={"Wallet name"}
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
      />
      <PaddingComponent minHeight="16px" />
      <CustomText
        text="Edit wallet bundle(s)"
        fontSize="17px"
        fontWeight="500"
        textAlign="left"
        color="var(--text-1)"
      />
      <PaddingComponent minHeight="16px" />
      <CustomRow
        gap={"40px"}
        padding="12px 21px 12px 12px"
        background="var(--elevation-1)"
        justifyContent="space-between"
        cursor="pointer"
        borderRadius={"8px"}
        onClick={() => {
          setShowNewBundleForm(true);
        }}>
        <CustomText
          text="New bundle"
          fontSize="15px"
          fontWeight="500"
          color="var(--primary-color)"
        />
        <FolderPlus size={24} color="var(--primary-color)" />
      </CustomRow>
      <PaddingComponent minHeight="16px" />
      {Object.values(bundleData.groups || {}).map((item) => {
        return <BundleRow bundle={item} key={item.id} />;
      })}
    </div>
  );
};
export const SaveWalletButton = ({
  isLoggedInUserProfile,
  setShowSuccessAnimation = () => {},
  editMode = true,
}) => {
  const params = useParams();
  const history = useHistory();
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const {
    isStep3Valid,
    selectedBundles,
    walletType,
    address,
    name,
    refetch,
    wallet,
    bundleData,
    isWalletAlreadyInBundle,
  } = useContext(AddWalletContext);
  const updateWallet = () => {
    if (editMode && isWalletAlreadyInBundle(address)) {
      handleErrorSnackbar(null, "Wallet already exists in the bundle");
      return;
    }
    setIsLoading(true);
    addRemoveAddressFromBundle({
      address: address,
      is_private: walletType !== "public",
      group_ids: selectedBundles,
      identity_id: bundleData.id,
      reason: name || "-",
    })
      .then((resp) => {
        if (isLoggedInUserProfile) {
          trackEvent("complete_add_wallet_flow", {
            is_edit: true,
            is_public: walletType === "public",
            is_connect_wallet: wallet.is_verified,
            group_count: selectedBundles.length,
          });
        } else {
          trackEvent("complete_add_wallet_flow", {
            is_edit: true,
            is_public: true,
            is_connect_wallet: false,
            group_count: 0,
          });
        }
        refetch().then(() => {
          setIsLoading(false);
          setShowSuccessAnimation(true);
          setTimeout(() => {
            history.replace(`/${params.identifier}/wallets`);
          }, 2000);
        });
      })
      .catch((e) => {
        if (e.response.data?.errors?.errors[0]?.msg) {
          handleErrorSnackbar(null, e.response.data?.errors?.errors[0].msg);
        } else if (typeof e.response.data?.errors?.errors === "string") {
          handleErrorSnackbar(null, e.response.data?.errors?.errors);
        } else {
          handleErrorSnackbar(e);
        }
        setIsLoading(false);
      });
  };
  return (
    <div className={classes.footer}>
      <CustomButton
        text="Save"
        height="50px"
        radius="8px"
        fontSize="15px"
        disabled={!isStep3Valid || isLoading}
        isLoading={isLoading}
        className={classes.nextButton}
        onClick={updateWallet}
      />
    </div>
  );
};
