import classes from "../WalletsPage.module.css";

const WalletHeader = ({ title, totalUsdValue, count, icon, iconRender }) => {
  return (
    <div className={classes.header}>
      <div className={classes.labelContainer}>
        {iconRender}
        {icon && (
          <div className={classes.iconContainer}>
            <img
              className={classes.icon}
              src={icon}
              alt={"Wallets Icon"}
              height="18"
              width="18"></img>
          </div>
        )}
        <div className={classes.label}>{title}</div>
        {totalUsdValue && (
          <div className={classes.totalUsdValue}>{totalUsdValue}</div>
        )}
        {count > 0 && <div className={classes.text_count}>{`(${count})`}</div>}
      </div>
    </div>
  );
};
export default WalletHeader;
