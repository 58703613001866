import FeedPost from "./FeedPost";
import React, { useEffect } from "react";
import FeedItemV2 from "./FeedItemV2";
import classes from "./feedItemV2.module.css";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeyConstants } from "utils/constants";

const ContentRenderer = React.memo(
  ({
    groupedActivity,
    profiles,
    identities,
    showReplies = false,
    solidBg = false,
    showSettings = true,
    showViewPost = false,
    smallThumbnail = false,
    trimDescription = false,
    trimLimit = 128,
    blockInteraction = false,
    isRepost = false,
    isHomePageFeedItem = false,
    isParent = false,
    clickable,
    nested = false,
    noMargin,
    hideEngagements,
    showThreadLine,
    noPadding = false,
    isGroupedActivityDetails = false,
    isProfilePage = false,
    ...otherProps
  }) => {
    profiles = profiles ?? groupedActivity.profiles;
    identities = identities ?? groupedActivity.identities;
    const ancestor = groupedActivity?.ancestors?.[0];
    const queryClient = useQueryClient();
    useEffect(() => {
      if (groupedActivity?.id && groupedActivity?.type !== "post") {
        queryClient.setQueryData(
          [
            QueryKeyConstants.GROUPED_ACTIVITY_FROM_BLOB,
            groupedActivity.id.toString(),
            groupedActivity.type,
          ],
          groupedActivity
        );
      }
      if (groupedActivity?.id && groupedActivity?.type === "post") {
        queryClient.setQueryData(
          [
            QueryKeyConstants.GROUPED_ACTIVITY,
            groupedActivity.id.toString(),
            groupedActivity.type,
          ],
          groupedActivity
        );
      }
    }, [groupedActivity, queryClient]);

    return groupedActivity.type === "post" ? (
      <div
        className={` ${
          (!!isHomePageFeedItem && classes.homePageGroupedActivity) || ""
        }
          ${(!!nested && classes.nested) || ""}
          ${
            (!!isParent && isHomePageFeedItem && classes.isParentContainer) ||
            ""
          }
          `}
        style={{ padding: 0 }}>
        {ancestor && isHomePageFeedItem ? (
          <ContentRenderer
            groupedActivity={ancestor}
            isParent={true}
            solidBg={false}
            isHomePageFeedItem
            showThreadLine={true}
            hasParent={false}
          />
        ) : (
          ""
        )}
        <FeedPost
          post={groupedActivity}
          profiles={profiles}
          identities={identities}
          showReplies={showReplies}
          solidBg={solidBg}
          showSettings={showSettings}
          showViewPost={showViewPost}
          trimDescription={trimDescription}
          trimLimit={trimLimit}
          smallThumbnail={smallThumbnail}
          blockInteraction={blockInteraction}
          isRepost={isRepost}
          isParent={isParent}
          clickable={isHomePageFeedItem || isRepost}
          hasParent={!!ancestor}
          isHomePageFeedItem={isHomePageFeedItem}
          hideEngagements={hideEngagements}
          showThreadLine={showThreadLine}
          isProfilePage={isProfilePage}
          {...otherProps}
        />
      </div>
    ) : (
      <FeedItemV2
        groupedActivity={groupedActivity}
        profiles={profiles}
        identities={identities}
        showReplies={showReplies}
        solidBg={solidBg}
        showSettings={showSettings}
        showViewPost={showViewPost}
        smallThumbnail={smallThumbnail}
        hideStats={groupedActivity.type === "welcome"}
        trimDescription={trimDescription}
        trimLimit={trimLimit}
        blockInteraction={blockInteraction}
        isRepost={isRepost}
        isHomePageFeedItem={isHomePageFeedItem}
        isParent={isParent}
        hasParent={!!ancestor}
        hideEngagements={hideEngagements}
        isGroupedActivityDetails={isGroupedActivityDetails}
        showThreadLine={showThreadLine}
        noPadding={noPadding}
        {...otherProps}
      />
    );
  }
);

export default ContentRenderer;
