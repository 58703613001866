import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import Image, { IMAGE_TYPES } from "components/UI/Image";
import classes from "./CollectionHeader.module.css";
import OverflowText from "components/UI/OverflowText";
import ConditionalLink from "shared/ConditionalLink";
const CollectionHeader = ({
  collection,
  chainProfiles,
  totalNfts,
  rightPane = false,
}) => {
  const paddedTotalNfts = totalNfts.toString().padStart(2, "0");
  return (
    <ConditionalLink
      className={classes.nftCollectionHeader}
      to={`/collection/${collection?.collection_id}/nfts`}>
      <ProfileHoverCard actor={collection.profile}>
        <div className={classes.info}>
          <div className={classes.avatar}>
            <NFTAvatar
              chainId={collection.chain_id}
              chainProfiles={chainProfiles}
              avatar={collection.image_url}
              name={collection.name}
            />
          </div>
          <div className={classes.name}>
            {rightPane ? (
              <OverflowText text={collection.name} limit={48} />
            ) : (
              collection.name
            )}
          </div>
        </div>
      </ProfileHoverCard>
      <div className={classes.borderWrapper}></div>
      <div className={classes.stats}>
        <div className={classes.totalItems}>{`${paddedTotalNfts} items`}</div>
        {collection.total_value_usd && (
          <>
            <div className={classes.divider}>•</div>
            <div className={classes.totalUsdValue}>
              {collection.total_value_usd?.display_value}
            </div>
          </>
        )}
      </div>
    </ConditionalLink>
  );
};

const NFTAvatar = ({ avatar, chainProfiles, chainId, name }) => {
  const chainProfile = chainProfiles?.[chainId];
  const chainIcon = chainProfile?.icon || chainProfile?.logo_uri;
  return (
    <div className={classes.nftAvatar}>
      <Image
        imageType={IMAGE_TYPES.TOKEN}
        className={classes.avatar}
        src={avatar}
        alt={`${name} avatar`}
      />
      {chainIcon && (
        <img
          className={classes.chainIcon}
          src={chainIcon}
          alt={`${chainId} chain`}
        />
      )}
    </div>
  );
};
export default CollectionHeader;
